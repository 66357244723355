import React, { Component, useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import "./Modal.css";
import PromptModal from "./PromptModal";
import AlertModal from "./AlertModal";
import MultiSelectModal from "./MultiSelectModal";
import Place from "../../Models/Place";
import Common, { POI_IMAGES, ICON_NAMES, COLORS } from "../../Common/Common";

class IconsDialog extends Component {
  constructor(props) {
    super(props);
    let iconList = [];
    for (var key in POI_IMAGES) {
      iconList.push({
        id: key,
        name: POI_IMAGES[key].name,
        label: POI_IMAGES[key].name,
        value: POI_IMAGES[key].req,
      });
    }
    if (window.guide_obj !== null && typeof window.guide_obj !== "undefined") {
      const list_to_display = window.guide_obj.getCustomIconsToDisplay();
      iconList = iconList.concat(list_to_display);
    }
    // window.guide_obj.icons.forEach((icon) => {
    //   iconList.push({
    //     id: icon.id,
    //     name: icon.name,
    //     label: icon.name,
    //     value: icon.url,
    //   });
    // });
    iconList.sort((a, b) => (a.name > b.name ? 1 : -1));
    this.state = {
      iconList: iconList,
      filteredIconList: iconList,
      searchTextValue: "",

      group: "",
      list_to_display: [],
      searchText: "",

      PromptModalShow: false,
      PromptModalTitle: "Enter Value",
      PromptModalMessage: "",
      PromptModalButtons: [],
      PromptModalInputs: [],

      AlertModalShow: false,
      AlertModalTitle: "",
      AlertModalMessage: "",
      AlertModalButtons: [],
    };
  }

  MyAlert = (title, message, buttons) => {
    this.setState({
      AlertModalTitle: title,
      AlertModalMessage: message,
      AlertModalButtons: buttons,
      AlertModalShow: true,
    });
  };

  // componentDidMount() {
  //   console.log("componentDidMount");
  //   this.getList();
  // }

  // getList = () => {
  //   let list_to_display = [];
  //   if (window.guide_obj !== null && typeof window.guide_obj !== "undefined")
  //     list_to_display = window.guide_obj.getGroupsToDisplay();
  //   this.setState({ list_to_display: list_to_display });
  // };
  // _group = null;
  // _group_obj = null;

  // addModifyGroup = (add, group) => {
  //   this._group_obj = group;
  //   if (group != null) this._group = group.name;
  //   this.setState({
  //     PromptModalShow: true,
  //     PromptModalTitle: "Group",
  //     PromptModalMessage: add ? "Enter Group Name" : "Edit Group Name",
  //     PromptModalButtons: [
  //       { Title: "OK", Method: add ? this.AddGroupCallback : this.ModifyGroupCallback },
  //       { Title: "CANCEL", Method: null },
  //     ],
  //     PromptModalInputs: [{ name: "Group Name", value: add ? "" : group.name }],
  //   });
  // };

  // ModifyGroupCallback = (inputs) => {
  //   if (inputs === null) return;
  //   this._group_obj.name = inputs[0].value;
  //   window.guide_obj.modifyGroup(this._group_obj, this._group_obj);
  //   this.getList();
  //   if (this.props.update_display !== null) this.props.update_display();
  // };

  // AddGroupCallback = (inputs) => {
  //   if (inputs === null) return;
  //   window.guide_obj.addGroup(inputs[0].value);
  //   this.getList();
  //   if (this.props.update_display !== null) this.props.update_display();
  // };

  // deleteGroup = (group) => {
  //   this.MyAlert("Confirmation", "Confirm Delete Group?", [
  //     { Title: "OK", Method: () => this._deleteGroup(group) },
  //     { Title: "CANCEL", Method: null },
  //   ]);
  // };

  // _deleteGroup = (group) => {
  //   window.guide_obj.deleteGroup(group);
  //   this.getList();
  // };

  // chooseGroupIcon = (group) => {
  //   this._group_obj = group;
  //   this.props.navigation.navigate("IconsDialog", { callback: this.chooseGroupIconCallback });
  // };

  // chooseGroupIconCallback = (poi_img) => {
  //   this._group_obj.value.poi_img = poi_img;
  //   window.guide_obj.modifyGroup(this._group_obj.value, this._group_obj.value);
  //   this.getList();
  // };

  addModifyGroup = (add, icon) => {
    this._group_obj = icon;
    if (icon != null) this._group = icon.name;
    this.setState({
      PromptModalShow: true,
      PromptModalTitle: "Group",
      PromptModalMessage: add ? "Enter icon Name and URL" : "Edit icon Name and URL",
      PromptModalButtons: [
        { Title: "OK", Method: add ? this.AddIconCallback : this.ModifyIconCallback },
        { Title: "CANCEL", Method: null },
      ],
      PromptModalInputs: [
        { name: "Group Name", value: add ? "" : icon.name },
        { name: "Group Name", value: add ? "" : icon.url },
      ],
    });
  };

  selectedIcon = (icon) => {
    console.log("selectedIcon");
    if (this.props.update_icon != null) this.props.update_icon(icon);
    this.props.onHide();
  };

  handleSearchChanged = (event) => {
    let searchText = event.target.value;
    this.setState({ searchText: searchText });
    const _searchText = searchText.toUpperCase();
    let new_list = [];
    this.state.iconList.forEach((icon) => {
      if (icon.name.toUpperCase().includes(_searchText)) new_list.push(icon);
    });
    this.setState({ filteredIconList: new_list });
  };

  render() {
    if (this.state.ShowMultiselect)
      return (
        <MultiSelectModal
          show={this.state.ShowMultiselect}
          onHide={() => this.setState({ ShowMultiselect: false })}
          MultiselectItems={this.state.MultiselectItems}
          MultiselectSelectedItems={this.state.MultiselectSelectedItems}
          MultiselectMode={this.state.MultiselectMode}
          MultiselectCallback={this.state.MultiselectCallback}
          MultiselectTitle={this.state.MultiselectTitle}
          MultiselectSearchText={this.state.MultiselectSearchText}
        />
      );
    else if (this.state.list_to_display == []) {
      this.getList();
      return null;
    }
    if (this.state.iconList == null) return;
    return (
      <div className="modal">
        <Modal
          {...this.props}
          size={window.innerWidth < 450 ? "lg" : "md"}
          dialogClassName="myModalClass"
          aria-labelledby="contained-modal-title-vcenter"
          centered={false}
          scrollable={true}
        >
          <Modal.Header closeButton>
            <div style={{ width: "100%" }}>
              <div className="sort">
                <div className="dialog-heading">
                  <div>
                    <h4 style={{ color: "#3598DB" }}>Map Icons</h4>
                  </div>
                </div>
                <div className="dialog-heading">
                  <input
                    type="text"
                    placeholder="Search"
                    value={this.state.searchText}
                    onChange={this.handleSearchChanged}
                  />
                  {/* {MultiselectMode === "multi" ? (
                    <i className={`fas fa-tasks ${show ? "" : "color"}`} onClick={handleSelectAll}></i>
                  ) : null} */}
                  {/* <i className={`fa fa-plus`} onClick={() => this.addIcon()}></i> */}
                </div>
                {/* <Modal.Title id="example-modal-sizes-title-sm">{MultiselectTitle}</Modal.Title> */}
              </div>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="multiselect-list">
              {this.state.filteredIconList.map((item, index) => (
                <div className="list-item-card cursor-pointer" key={index} onClick={() => this.selectedIcon(item)}>
                  <div className="items-left">
                    <img style={{ cursor: "pointer", maxHeight: "32px" }} src={item.value} />
                    <span className="text-size-1-0">{item.label}</span>
                    {/* <div className="items-right" key={index}>
                      <i
                        title="Delete"
                        className={`fa fa-trash-alt`}
                        style={{ color: "red" }}
                        onClick={() => this.deleteGroup(item)}
                      ></i>
                      <span> </span>
                      <i
                        title="Change Name"
                        className={`fa fa-edit color-blue`}
                        onClick={() => this.addModifyGroup(false, item)}
                      ></i>
                    </div> */}
                  </div>
                </div>
              ))}
            </div>
          </Modal.Body>
        </Modal>
        <PromptModal
          show={this.state.PromptModalShow}
          onHide={() => this.setState({ PromptModalShow: false })}
          PromptModalTitle={this.state.PromptModalTitle}
          PromptModalMessage={this.state.PromptModalMessage}
          PromptModalButtons={this.state.PromptModalButtons}
          PromptModalInputs={this.state.PromptModalInputs}
        />
        <AlertModal
          show={this.state.AlertModalShow}
          onHide={() => this.setState({ AlertModalShow: false })}
          AlertModalTitle={this.state.AlertModalTitle}
          AlertModalMessage={this.state.AlertModalMessage}
          AlertModalButtons={this.state.AlertModalButtons}
        />
      </div>
    );
  }
}

export default IconsDialog;
