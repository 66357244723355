import { createSlice } from "@reduxjs/toolkit";
import img from "../assets/images/img1.jpg";
import { about_html } from "../Data/About";
import { faqs_html } from "../Data/Faqs";
import { guidelines_html } from "../Data/Guidelines";
import { help_document_html } from "../Data/DocumentationWeb";
import { help_videos_html } from "../Data/VideosWeb";
import { tickets_html } from "../Data/TicketsWeb";
import { announce_html } from "../Data/Announcement";
import { urlSchemeToFunctions } from "../CommonWeb/Common";
import Common, { AUTHORIZATION_LEVEL } from "../Common/Common";
import OpenTripMapPlaces from "../Models/OpenTripMap";
import GooglePlaces from "../Models/GooglePlaces";
import Attractions from "../Models/Attractions";
import Wikipedia from "../Models/Wikipedia";
import Flickr from "../Models/Flickr";

const initial_state = {
  places: [],
  //markers: [],
  //polylines: [],
  events: [],
  schedule: [],
  homeScreen: "",
  selectedPlace: null,
  MapCenterPlace: null,
  SortBy: 0,
  SearchText: "",
  FilterVisited: false,
  FilterFavorites: false,
  DatesFilter: [],
  PlacesFilter: [],
  loc: { latitude: 0, longitude: 0 },
  GroupsFilter: [],
  CategoriesFilter: [],
  Section: null,
  SearchResultsPlacesList: [],
  FilterSearched: "all",
  ShowHiddenPlaces: false,
  ScheduleName: "Main",
  ScheduleNames: [{ label: "Main", value: "Main" }],
  FilterNotVisited: false,
  FilterMustSee: false,
  SchedulesFilter: [],
  GroupsHide: [],
  DistanceFilter: -1,
  user_info_id: null,
  user_info: {
    distance_unit: "kilometers",
    date_format: "Month first",
    edit_mode: false,
    email: "guest@gmail.com",
    first_name: "guest",
    last_name: "user",
    uid: "guest",
    create_date: null,
    modify_date: null,
    travel_payouts_marker: "",
    last_guide_id: "",
    last_guide_time: "",
    payment_info: "",
    affiliate_program: false,
    default_duration: 30,
    event_time_rounding: 30,
  },
  creator_info: {
    distance_unit: "miles",
    date_format: "Month first",
    edit_mode: "Off",
    email: null,
    first_name: null,
    last_name: null,
    uid: null,
    create_date: null,
    travel_payouts_marker: "",
  },
  owner_info: {
    distance_unit: "miles",
    date_format: "Month first",
    edit_mode: "Off",
    email: null,
    first_name: null,
    last_name: null,
    uid: null,
    create_date: null,
    travel_payouts_marker: "",
  },
  AppSettings: {
    guide_info_id: "IQrZiedo7oJWa9GgLR6L",
    guide_info: null,
    help_document: help_document_html,
    help_videos: help_videos_html,
    about: about_html,
    tickets: tickets_html,
    faqs: faqs_html,
    guidelines: guidelines_html,
    announce: announce_html,
    overview_video: "https://youtu.be/46LDuyGnTrY",
    travel_payouts_marker: "292121",
    travel_payouts_marker_2: "292121",
    travel_payouts_marker_3: "292121",
    percent_1: 75,
    percent_2: 25,
    percent_3: 0,
  },
  showPlaceMenu: false,
  ShowHTMLReader: false,
  ShowMultiselect: false,
  MultiselectItems: [],
  MultiselectSelectedItems: [],
  MultiselectCallback: null,
  MultiselectMode: "multi",
  MultiselectSearchText: "",
  MultiselectTitle: "Select",
  SelectedEvent: null,
  HTMLToSHow: "",
  HTMLTitle: "HTML Content",
  LoadingGuide: false,
  EventSearchText: "",
  CurrentLocation: { latitude: 0, longitude: 0 },
  HTMLStack: [],
  MapCenter: {
    lat: 43.7713786,
    lng: 11.2543177,
  },
  MapBounds: {
    north: 43,
    south: 44,
    west: 7,
    east: 8,
  },
  MapBoundsValue: null,
  GuideLoaded: false,
  noFiltering: true,
  fromLoc: null,
  toLoc: null,
  waypointsArray: [],
  showHiddenPlace: [],
  MenuModalMeta: [],
  Authorization: AUTHORIZATION_LEVEL.NONE,
  GuideEditMode: true,
  guide_meta: { guide_info: null, guide_info_id: null, guide_id: null },
  GuideSortOption: "Modified date",
  MenuModalArgs: {
    Show: false,
    Image: null,
    List: [],
    Heading: "",
    Place: null,
  },
  GuideStack: [],
  MapFullScreen: false,
  HomeFullScreen: false,
  PlaceTypeFilter: [],
  CurrentAppScreen: "Home", // "Home", "Map", "Places", "Schedule", Guides
  GuidesOrPlaces: "Places", //"Places", "Guides"
  GuideType: "Featured", //"Featured", "Mine"
  MobileMode: false,
  StreetViewLocation: null,
  DirectionsFromLocation: null,
  DirectionsWaypointsLocation: [],
  DirectionsToLocation: null,
  HideSchedule: false,
  HidePlaces: false,
  HideMap: false,
  HideHome: false,
  HideSideMenu: false,
  MapRef: null,
  GuideSettings: {
    mode: "edit", // "edit" "view" "custom_view"
    zoom: 5,
    hide_schedule: false,
    hide_hidden: false,
    wrap_text: false,
    places_list: {
      menu: false,
      info: true,
      url: false,
      attributes: true,
      center: false,
      filter: false,
      path_link: false,
    },
    places_map: {
      menu: true,
      info: false,
      url: false,
      attributes: false,
      center: false,
      filter: false,
      path_link: false,
    },
    places_map_hover: {
      menu: false,
      place: true,
      info: true,
      event: true,
      path_link: false,
    },
    event: {
      menu: true,
      details: false,
      center: false,
      filter: false,
    },
    schedule: {
      menu: true,
      itinerary: false,
      center: false,
      filter: false,
    },
    day: {
      menu: true,
      center: false,
      filter: false,
    },
    itinerary: {
      directions: true,
      info: true,
      photo: false,
      place_menu: true,
    },
  },
  ContentFontZoom: "120",
  GuideInfoArray: [],
  GuideInfoArrayMine: [],
  GuideSearchText: "",
  GuideCities: [],
  GuideCountries: [],
  GuideLanguages: [],
  GuideCitiesMine: [],
  GuideCountriesMine: [],
  GuideLanguagesMine: [],
  GuideCitiesSelected: [],
  GuideCountriesSelected: [],
  GuideLanguageSelected: "English",
  GuideCitiesMineSelected: [],
  GuideCountriesMineSelected: [],
  GuideLanguageMineSelected: "English",
  GuideOrderBy: "City",
  HighlightedPlaces: [],
  ShowOffcanvas: false,
  PlayEventsIndex: 0,
  SizeMapToFit: true,
  NoAds: false,
  TempValue: null,
  // // GooglePlaces: new GooglePlaces(),
  // Attractions: new Attractions(),
  // Wikipedia: new Wikipedia(),
  // Flickr: new Flickr(),
  //HTMLContent: "",
};
const slice1 = createSlice({
  name: "sections",
  initialState: initial_state,
  // InitialPlaces, //

  reducers: {
    setGuide: (state, action) => {
      if (window.guide_obj == null) return state;

      if (action.payload.guide != null) {
        state.FilterVisited = false;
        state.FilterNotVisited = false;
        state.FilterFavorites = false;
        state.ShowHiddenPlaces = false;
        state.FilterMustSee = false;
        state.SchedulesFilter = [];
        state.DatesFilter = [];
        state.PlacesFilter = [];
        state.GroupsFilter = [];
        state.CategoriesFilter = [];
        state.FilterSearched = "all";
        state.GroupsHide = [];
        state.SearchText = "";
        state.DistanceFilter = -1;
        state.showHiddenPlace = [];
        state.SearchResultsPlacesList = [];
        state.PlaceTypeFilter = [];
      }

      const res = window.guide_obj.preFilterPlaces(
        state.SearchText,
        state.FilterVisited,
        state.FilterFavorites,
        state.DatesFilter,
        state.PlacesFilter,
        state.CurrentLocation,
        state.GroupsFilter,
        state.CategoriesFilter,
        state.Section,
        state.SearchResultsPlacesList,
        state.FilterSearched,
        state.ShowHiddenPlaces,
        state.ScheduleName,
        state.FilterNotVisited,
        state.FilterMustSee,
        state.SchedulesFilter,
        state.GroupsHide,
        state.DistanceFilter,
        state.user_info,
        state.showHiddenPlace,
        state.PlaceTypeFilter
      );

      //console.log("res.filteredPlacesList", res.filteredPlacesList);

      let _places = window.guide_obj.sortPlaces(
        res.filteredPlacesList,
        state.SortBy,
        state.user_info,
        state.CurrentLocation
      );
      // _places.forEach((section) => {
      //   for (let p = 0; p < section.data.length; p++) section.data[p].place = section.data[p].place.ID;
      // });

      //console.log("_places", _places);
      state.places = _places;
      state.GuideSettings = window.guide_obj.guide_settings;
      if (typeof state.GuideSettings.places_map_hover === "undefined") state.GuideSettings.places_map_hover = {};
      if (typeof state.GuideSettings.places_map_hover.menu === "undefined")
        state.GuideSettings.places_map_hover.menu = false;
      if (typeof state.GuideSettings.places_map_hover.place === "undefined")
        state.GuideSettings.places_map_hover.place = false;
      if (typeof state.GuideSettings.places_map_hover.info === "undefined")
        state.GuideSettings.places_map_hover.info = false;
      if (typeof state.GuideSettings.places_map_hover.event === "undefined")
        state.GuideSettings.places_map_hover.event = false;
      // if (typeof state.GuideSettings.places_map_hover.path_link === "undefined")
      //   state.GuideSettings.places_map_hover.path_link = false;
      if (typeof state.GuideSettings.places_map.path_link === "undefined")
        state.GuideSettings.places_map.path_link = false;

      if (typeof state.GuideSettings.itinerary === "undefined") state.GuideSettings.itinerary = {};
      if (typeof state.GuideSettings.itinerary.directions === "undefined")
        state.GuideSettings.itinerary.directions = true;
      if (typeof state.GuideSettings.itinerary.info === "undefined") state.GuideSettings.itinerary.info = true;
      if (typeof state.GuideSettings.itinerary.photo === "undefined") state.GuideSettings.itinerary.photo = false;
      if (typeof state.GuideSettings.itinerary.place_menu === "undefined")
        state.GuideSettings.itinerary.place_menu = true;

      // console.log("window.guide_obj.guide_settings", window.guide_obj.guide_settings);

      if (action.payload.guide != null) {
        let html = window.guide_obj.document;
        if (html === null || html === "" || html === "<!-- Comment -->") {
          html = window.guide_obj.createGuideHTML();
        }
        //console.log(html);
        let htmlString = html;
        //let htmlString = urlSchemeToFunctions(html);
        state.HTMLStack = [];
        state.HTMLStack.push(htmlString);
        state.homeScreen = htmlString;
        if (action.payload.guide_meta != null && typeof action.payload.guide_meta !== "undefined")
          state.guide_meta = action.payload.guide_meta;
        const _Authorization = window.guide_obj.setAuthorization(
          action.payload.guide_meta.guide_info,
          state.user_info.uid
        );
        state.Authorization = _Authorization;
      } else if (action.payload.guide_meta === null || typeof action.payload.guide_meta !== "undefined") {
        state.guide_meta = {
          guide_info: window.guide_obj.attributes,
          guide_info_id: state.guide_meta.guide_info_id,
          guide_id: state.guide_meta.guide_id,
        };
      }

      const _MarkersToDisplay = res.filteredPlacesList;
      //const _region = res.MapBounds;
      //const _MapRegion = window.guide_obj.places.getMapRegion(_MarkersToDisplay, null);
      const _polylines = res.polylines;
      _polylines.forEach((polyline) => {
        polyline.path = polyline.coordinates.map((ll) => ({ lat: ll.latitude, lng: ll.longitude }));
      });

      state.markers = _MarkersToDisplay;
      state.polylines = _polylines;
      // if (_MarkersToDisplay.length !== 0) {
      let maxMins = window.guide_obj.places.getMaxMinLatLon(res.filteredPlacesList);
      //const max_mins = { max_lat: max_lat, max_lon: max_lon, min_lat: min_lat, min_lon: min_lon };
      let MapBounds = {
        north: maxMins.max_lat,
        south: maxMins.min_lat,
        west: maxMins.min_lon,
        east: maxMins.max_lon,
      };
      //console.log("################### MapBounds", state.MapBounds);
      state.MapCenter = {
        lat: (maxMins.max_lat + maxMins.min_lat) / 2,
        lng: (maxMins.max_lon + maxMins.min_lon) / 2,
      };
      if (typeof window.guide_obj.attributes.base_location === "undefined")
        window.guide_obj.attributes.base_location = { latitude: 0, longitude: 0 };
      if (_MarkersToDisplay.length === 0 && _polylines.length === 0) {
        state.MapCenter = {
          lat: window.guide_obj.attributes.base_location.latitude,
          lng: window.guide_obj.attributes.base_location.longitude,
        };
        MapBounds = {
          north: window.guide_obj.attributes.base_location.latitude + 5,
          south: window.guide_obj.attributes.base_location.latitude - 5,
          west: window.guide_obj.attributes.base_location.longitude - 5,
          east: window.guide_obj.attributes.base_location.longitude + 5,
        };
      }
      //if (action.payload.fitToBounds === true)
      state.MapBounds = MapBounds;
      if (state.GuidesOrPlaces === "Guides") {
        if (state.GuideType === "Featured") maxMins = Common.getMaxMinLatLon(state.GuideInfoArray);
        else maxMins = Common.getMaxMinLatLon(state.GuideInfoArrayMine);
        MapBounds = {
          north: maxMins.max_lat,
          south: maxMins.min_lat,
          west: maxMins.min_lon,
          east: maxMins.max_lon,
        };
        state.MapCenter = {
          lat: (maxMins.max_lat + maxMins.min_lat) / 2,
          lng: (maxMins.max_lon + maxMins.min_lon) / 2,
        };
      }
      if (action.payload.update_guide_attributes === true) {
        let html = window.guide_obj.document;
        if (html === null || html === "" || html === "<!-- Comment -->") {
          html = window.guide_obj.createGuideHTML();
        }
        //console.log(html);
        let htmlString = html;
        //let htmlString = urlSchemeToFunctions(html);
        state.HTMLStack = [];
        state.HTMLStack.push(htmlString);
        state.homeScreen = htmlString;

        state.guide_meta = {
          guide_info: window.guide_obj.attributes,
          guide_info_id: state.guide_meta.guide_info_id,
          guide_id: state.guide_meta.guide_id,
        };
      }
      if (
        (action.payload.guide != null || action.payload.fitToBounds === true) &&
        (state.SizeMapToFit || action.payload.override === true)
      )
        if (state.MapRef !== null)
          //if (window.mapRef !== null && typeof window.mapRef !== "undefined") window.mapRef.fitBounds(MapBounds);
          state.MapRef.fitBounds(MapBounds);

      //console.log("***************Center", state.MapCenter);
      // }
      state.schedule = window.guide_obj.schedule.getScheduleItems(
        state.EventSearchText,
        state.ScheduleName,
        window.guide_obj.places
      );
      if (state.schedule.length === 0 && state.ScheduleName !== "Main") {
        state.ScheduleName = "Main";
        state.schedule = window.guide_obj.schedule.getScheduleItems(
          state.EventSearchText,
          state.ScheduleName,
          window.guide_obj.places
        );
      }

      state.ScheduleNames = window.guide_obj.schedule.geScheduleNames();

      state.GuideLoaded = true;
      state.MapCenterPlace = null;

      state.noFiltering =
        state.FilterFavorites === false &&
        state.FilterSearched === "all" &&
        state.FilterMustSee === false &&
        state.ShowHiddenPlaces === false &&
        state.FilterVisited === false &&
        state.FilterNotVisited === false &&
        state.SchedulesFilter.length === 0 &&
        state.DatesFilter.length === 0 &&
        state.PlacesFilter.length === 0 &&
        state.GroupsFilter.length === 0 &&
        state.GroupsHide.length === 0 &&
        state.CategoriesFilter.length === 0 &&
        state.DistanceFilter === -1 &&
        state.PlaceTypeFilter.length === 0;

      const _Authorization = window.guide_obj.setAuthorization(state.guide_meta.guide_info, state.user_info.uid);
      state.Authorization = _Authorization;
      state.GuideEditMode =
        // state.GuideSettings.mode === "edit" &&
        state.user_info.edit_mode !== "Off" &&
        window.guide_obj.isAuthorized(_Authorization, AUTHORIZATION_LEVEL.COPY) === true;
      return state;
    },
    // setBaseLocation : (state: action) {
    //   window.guide_obj.attributes.base_location = action.payload.value;
    //   state.MenuModalArgs = action.payload.va

    //   this.attributes.base_location = {
    //     latitude: parseFloat(this.places.placesList[0].Lat),
    //     longitude: parseFloat(this.places.placesList[0].Longi),
    //   };
    // }
    setGuideSettings: (state, action) => {
      state.GuideSettings = action.payload.value;
      window.guide_obj.guide_settings = action.payload.value;
      state.GuideEditMode =
        // state.GuideSettings.mode === "edit" &&
        state.user_info.edit_mode !== "Off" &&
        window.guide_obj.isAuthorized(state.Authorization, AUTHORIZATION_LEVEL.COPY) === true;
      return state;
    },
    setNoAds: (state, action) => {
      state.NoAds = action.payload.value;
      return state;
    },
    setTempValue: (state, action) => {
      state.TempValue = action.payload.value;
      return state;
    },
    setHideSchedule: (state, action) => {
      state.HideSchedule = action.payload.value;
      return state;
    },
    setShowOffcanvas: (state, action) => {
      state.ShowOffcanvas = action.payload.value;
      return state;
    },
    setHidePlaces: (state, action) => {
      state.HidePlaces = action.payload.value;
      return state;
    },
    setHideMap: (state, action) => {
      state.HideMap = action.payload.value;
      return state;
    },
    setHideHome: (state, action) => {
      state.HideHome = action.payload.value;
      return state;
    },
    setHideSideMenu: (state, action) => {
      state.HideSideMenu = action.payload.value;
      return state;
    },
    setHighlightedPlaces: (state, action) => {
      state.HighlightedPlaces = action.payload.value;
      return state;
    },
    setGuideOrderBy: (state, action) => {
      state.GuideOrderBy = action.payload.value;
      return state;
    },
    setGuideCities: (state, action) => {
      state.GuideCities = action.payload.value;
      return state;
    },
    setGuideCountries: (state, action) => {
      state.GuideCountries = action.payload.value;
      return state;
    },
    setGuideLanguages: (state, action) => {
      state.GuideLanguages = action.payload.value;
      return state;
    },
    setGuideCitiesSelected: (state, action) => {
      state.GuideCitiesSelected = action.payload.value;
      return state;
    },
    setGuideCountriesSelected: (state, action) => {
      state.GuideCountriesSelected = action.payload.value;
      return state;
    },
    setGuideLanguageSelected: (state, action) => {
      state.GuideLanguageSelected = action.payload.value;
      return state;
    },
    setGuideInfoArray: (state, action) => {
      state.GuideInfoArray = action.payload.value;
      return state;
    },

    setGuideInfoArrayMine: (state, action) => {
      state.GuideInfoArrayMine = action.payload.value;
      return state;
    },
    setGuideCitiesMine: (state, action) => {
      state.GuideCitiesMine = action.payload.value;
      return state;
    },

    setGuideCountriesMine: (state, action) => {
      state.GuideCountriesMine = action.payload.value;
      return state;
    },
    setGuideLanguagesMine: (state, action) => {
      state.GuideLanguagesMine = action.payload.value;
      return state;
    },
    setGuideCitiesMineSelected: (state, action) => {
      state.GuideCitiesMineSelected = action.payload.value;
      return state;
    },
    setPlayEventsIndex: (state, action) => {
      state.PlayEventsIndex = action.payload.value;
      return state;
    },
    setGuideCountriesMineSelected: (state, action) => {
      state.GuideCountriesMineSelected = action.payload.value;
      return state;
    },
    setGuideLanguagesMineSelected: (state, action) => {
      state.GuideLanguagesMineSelected = action.payload.value;
      return state;
    },
    setGuideCitiesMineSeSelected: (state, action) => {
      state.GuideCitiesMineSeSelected = action.payload.value;
      return state;
    },
    setGuideCountriesMineSeSelected: (state, action) => {
      state.GuideCountriesMineSeSelected = action.payload.value;
      return state;
    },
    setGuideLanguageMineSelected: (state, action) => {
      state.GuideLanguageMineSelected = action.payload.value;
      return state;
    },
    setSizeMapToFit: (state, action) => {
      state.SizeMapToFit = action.payload.value;
      return state;
    },
    setContentFontZoom: (state, action) => {
      state.ContentFontZoom = action.payload.value;
      return state;
    },
    setMapRef: (state, action) => {
      state.MapRef = action.payload.value;
      return state;
    },
    setDirectionsFromLocation: (state, action) => {
      state.DirectionsFromLocation = action.payload.value;
      return state;
    },
    setDirectionsWaypointsLocation: (state, action) => {
      state.DirectionsWaypointsLocation = action.payload.value;
      return state;
    },
    setDirectionsToLocation: (state, action) => {
      state.DirectionsToLocation = action.payload.value;
      return state;
    },
    setMobileMode: (state, action) => {
      state.MobileMode = action.payload.value;
      return state;
    },
    setPlaceTypeFilter: (state, action) => {
      state.PlaceTypeFilter = action.payload.value;
      return state;
    },
    setCurrentAppScreen: (state, action) => {
      state.CurrentAppScreen = action.payload.value;
      return state;
    },
    setGuidesOrPlaces: (state, action) => {
      state.GuidesOrPlaces = action.payload.value;
      state.GuideType = action.payload.GuideType;
      return state;
    },
    setMenuModalArgs: (state, action) => {
      state.MenuModalArgs = action.payload.value;
      return state;
    },
    setMapFullScreen: (state, action) => {
      state.MapFullScreen = action.payload.value;
      return state;
    },
    setHomeFullScreen: (state, action) => {
      state.HomeFullScreen = action.payload.value;
      return state;
    },
    setUserInfoId: (state, action) => {
      state.user_info_id = action.payload.value;
      return state;
    },
    setSearchResultsPlacesList: (state, action) => {
      let new_value = [];
      action.payload.value.forEach((v) => {
        new_value.push(v);
      });
      state.SearchResultsPlacesList = new_value;
      return state;
    },
    addToSearchResultsPlacesList: (state, action) => {
      let new_array = state.SearchResultsPlacesList;
      action.payload.value.forEach((v) => {
        if (!new_array.includes((x) => x.Name === v.Name && x.Lat === v.Lat && x.Longi === v.Longi)) new_array.push(v);
      });

      state.SearchResultsPlacesList = new_array;
      return state;
    },
    addResultToGuide: (state, action) => {
      let _SearchResultsPlacesList = [...state.SearchResultsPlacesList];
      const index = _SearchResultsPlacesList.findIndex((x) => x.ID === action.payload.value);
      if (index === -1) return;
      if (_SearchResultsPlacesList[index].Category.includes("53")) _SearchResultsPlacesList[index].Category = [];
      window.guide_obj.places.addPlace(_SearchResultsPlacesList[index]);
      _SearchResultsPlacesList.splice(index, 1);
      state.SearchResultsPlacesList = _SearchResultsPlacesList;
      return state;
    },
    removeFromSearchResults: (state, action) => {
      let _SearchResultsPlacesList = [...state.SearchResultsPlacesList];
      const index = _SearchResultsPlacesList.findIndex((x) => x.ID === action.payload.value);
      if (index === -1) return;
      _SearchResultsPlacesList.splice(index, 1);
      state.SearchResultsPlacesList = _SearchResultsPlacesList;
      return state;
    },
    addAllResultsToGuide: (state, action) => {
      state.SearchResultsPlacesList.forEach((result) => {
        if (result.Category.length === 0) result.Category = ["53"]; //Search
        window.guide_obj.places.addPlace(result);
      });
      state.SearchResultsPlacesList = [];
      return state;
    },
    setMapBounds: (state, action) => {
      state.MapBounds = action.payload.value;
      return state;
    },
    setMapBoundsValue: (state, action) => {
      state.MapBoundsValue = action.payload.value;
      state.MapBounds = action.payload.value;
      console.log("setMapBoundsValue", action.payload.value);
      return state;
    },
    setStreetViewLocation: (state, action) => {
      state.StreetViewLocation = action.payload.value;
      return state;
    },
    getPlaceFromID: (state, action) => {
      state.place = window.guide_obj.places.getPlace(action.payload.guide_id);
      return state;
    },
    // setPlaces: (state, action) => {
    //   state.selectedPlace = action.payload.places;
    //   return state;
    // },
    setSelectedPlace: (state, action) => {
      state.selectedPlace = action.payload.place;
      return state;
    },
    setMapCenterPlace: (state, action) => {
      //console.log("setMapCenterPlace", action.payload.place);
      state.MapCenterPlace = action.payload.place;
      const maxMins = window.guide_obj.places.getMaxMinLatLon(null, action.payload.place);
      let MapBounds = {
        north: maxMins.max_lat,
        south: maxMins.min_lat,
        west: maxMins.min_lon,
        east: maxMins.max_lon,
      };
      // const MapBounds = {
      //   north: action.payload.place.Lat + 0.001,
      //   south: action.payload.place.Lat - 0.001,
      //   west: action.payload.place.Longi - 0.001,
      //   east: action.payload.place.Longi + 0.001,
      // };
      if (state.MapRef !== null && typeof state.MapRef !== "undefined") state.MapRef.fitBounds(MapBounds);
    },
    centerMapToLoc: (state, action) => {
      // let MapBounds = {
      //   north: action.payload.value.Lat + 0.001,
      //   south: action.payload.value.Lat - 0.001,
      //   west: action.payload.value.Longi - 0.001,
      //   east: action.payload.value.Longi + 0.001,
      // };
      // console.log("centerMapToLoc", MapBounds);
      //if (state.MapRef !== null && typeof state.MapRef !== "undefined") state.MapRef.fitBounds(MapBounds);
      const mapCenter = { lat: action.payload.value.Lat, lng: action.payload.value.Longi };
      if (state.MapRef !== null && typeof state.MapRef !== "undefined") state.MapRef.panTo(mapCenter);
    },
    centerMapToPlaces: (state, action) => {
      const maxMins = window.guide_obj.places.getMaxMinLatLon(action.payload.value, null);
      let MapBounds = {
        north: maxMins.max_lat + 0.0001,
        south: maxMins.min_lat - 0.0001,
        west: maxMins.min_lon - 0.0001,
        east: maxMins.max_lon + 0.0001,
      };
      if (state.MapRef !== null && typeof state.MapRef !== "undefined") state.MapRef.fitBounds(MapBounds);
    },
    setMapCenter: (state, action) => {
      state.MapCenter = action.payload.value;
      return state;
    },
    setHomeScreen: (state, action) => {
      //state.homeScreen = action.payload.value;
      let htmlString = action.payload.value;
      //let htmlString = urlSchemeToFunctions(action.payload.value);
      state.HTMLStack.push(htmlString);
      state.homeScreen = htmlString;
      return state;
    },
    setCurrentLocation: (state, action) => {
      state.CurrentLocation = action.payload.value;
      return state;
    },
    seEvents: (state, action) => {
      state.events = action.payload.events;
      return state;
    },
    setMarkers: (state, action) => {
      state.markers = action.payload.markers;
      return state;
    },
    setShowPlaceMenu: (state, action) => {
      state.showPlaceMenu = action.payload.value;
      return state;
    },
    setHTMLToSHow: (state, action) => {
      //console.log(action.payload.value);
      let htmlString = action.payload.value;
      //let htmlString = urlSchemeToFunctions(action.payload.value);
      state.HTMLStack.push(htmlString);
      //state.HTMLToSHow = htmlString;
      state.homeScreen = htmlString;
      return state;
    },
    resetHTMLScreen: (state, action) => {
      state.HTMLStack = [];
      state.HTMLStack.push(state.homeScreen);
    },
    setHTMLStack: (state, action) => {
      state.HTMLStack = action.payload.value;
      return state;
    },
    setHTMLTitle: (state, action) => {
      state.HTMLTitle = action.payload.value;
      return state;
    },
    popHTMLStack: (state) => {
      //console.log("popHTMLStack1", state.HTMLStack.length);
      window.getTime = function () {};
      let temp = state.HTMLStack;
      temp.pop();
      temp.pop();
      state.HTMLStack = temp;
      //console.log("popHTMLStack2", state.HTMLStack.length);
      return state;
    },
    clearHTMLStack: (state) => {
      state.HTMLStack = [];
      window.getTime = function () {};
      return state;
    },
    pushGuideStack: (state, action) => {
      window.getTime = function () {};
      let temp = state.GuideStack;
      temp.push(action.payload.value);
      state.GuideStack = temp;
      return state;
    },
    popGuideStack: (state) => {
      window.getTime = function () {};
      let temp = state.GuideStack;
      temp.pop();
      temp.pop();
      state.GuideStack = temp;
      return state;
    },
    resetHomeScreen: (state, action) => {
      window.getTime = function () {};
      let html = window.guide_obj.document;
      if (typeof html === "undefined" || html === null || html === "" || html === "<!-- Comment -->") {
        html = window.guide_obj.createGuideHTML();
      }
      let htmlString = html;
      state.HTMLStack = [];
      state.HTMLStack.push(htmlString);
      state.homeScreen = htmlString;
    },
    clearGuideStack: (state) => {
      state.GuideStack = [];
      return state;
    },
    setShowHTMLReader: (state, action) => {
      //state.ShowHTMLReader = action.payload.value;
      return state;
    },
    setShowMultiselect: (state, action) => {
      state.ShowMultiselect = action.payload.value;
    },
    setMultiselectItems: (state, action) => {
      state.MultiselectItems = action.payload.value;
    },
    setMultiselectSelectedItems: (state, action) => {
      state.MultiselectSelectedItems = action.payload.value;
    },
    setMultiselectCallback: (state, action) => {
      state.MultiselectCallback = action.payload.value;
    },
    setMultiselectMode: (state, action) => {
      state.MultiselectMode = action.payload.value;
    },
    setMultiselectSearchText: (state, action) => {
      state.MultiselectSearchText = action.payload.value;
    },
    setMultiselectTitle: (state, action) => {
      state.MultiselectTitle = action.payload.value;
    },

    ClearAllFilters: (state, action) => {
      state.FilterVisited = false;
      state.FilterNotVisited = false;
      state.FilterFavorites = false;
      state.ShowHiddenPlaces = false;
      state.FilterMustSee = false;
      state.SchedulesFilter = [];
      state.DatesFilter = [];
      state.PlacesFilter = [];
      state.GroupsFilter = [];
      state.CategoriesFilter = [];
      state.FilterSearched = "all";
      state.GroupsHide = [];
      state.SearchText = "";
      state.DistanceFilter = -1;
      state.showHiddenPlace = [];
      state.PlaceTypeFilter = [];
      return state;
    },
    SetFilterVisited: (state, action) => {
      state.FilterVisited = action.payload.value;
    },
    SetFilterNotVisited: (state, action) => {
      state.FilterNotVisited = action.payload.value;
    },
    SetFilterFavorites: (state, action) => {
      state.FilterFavorites = action.payload.value;
    },
    SetShowHiddenPlaces: (state, action) => {
      state.ShowHiddenPlaces = action.payload.value;
    },
    SetFilterMustSee: (state, action) => {
      state.FilterMustSee = action.payload.value;
    },
    SetSchedulesFilter: (state, action) => {
      state.SchedulesFilter = action.payload.value;
    },
    SetDatesFilter: (state, action) => {
      state.DatesFilter = action.payload.value;
    },
    SetPlacesFilter: (state, action) => {
      state.PlacesFilter = action.payload.value;
    },
    SetGroupsFilter: (state, action) => {
      state.GroupsFilter = action.payload.value;
    },
    SetCategoriesFilter: (state, action) => {
      state.CategoriesFilter = action.payload.value;
    },
    SetFilterSearched: (state, action) => {
      state.FilterSearched = action.payload.value;
    },
    SetGroupsHide: (state, action) => {
      state.GroupsHide = action.payload.value;
    },
    SetSearchText: (state, action) => {
      state.SearchText = action.payload.value;
    },
    SetGuideSearchText: (state, action) => {
      state.GuideSearchText = action.payload.value;
    },
    setEventSearchText: (state, action) => {
      state.EventSearchText = action.payload.value;
    },
    SetDistanceFilter: (state, action) => {
      state.DistanceFilter = action.payload.value;
    },
    SetLoadingGuide: (state, action) => {
      state.LoadingGuide = action.payload.value;
    },
    SetSortBy: (state, action) => {
      state.SortBy = action.payload.value;
    },
    setScheduleName: (state, action) => {
      state.ScheduleName = action.payload.value;
    },
    setSelectedEvent: (state, action) => {
      state.SelectedEvent = action.payload.value;
    },
    setUserInfo: (state, action) => {
      state.user_info = action.payload.value;
      //const _Authorization = window.guide_obj.setAuthorization(state.guide_meta.guide_info, action.payload.value.uid);
      //state.Authorization = _Authorization;
    },
    setOwnerInfo: (state, action) => {
      state.owner_info = action.payload.value;
    },
    setCreatorInfo: (state, action) => {
      state.creator_info = action.payload.value;
    },
    setAppSettings: (state, action) => {
      state.AppSettings = action.payload.value;
    },
    setToLoc: (state, action) => {
      state.toLoc = action.payload.value;
    },
    setFromLoc: (state, action) => {
      state.fromLoc = action.payload.value;
    },
    setWaypointsArray: (state, action) => {
      state.waypointsArray = action.payload.value;
    },
    setMenuModalMeta: (state, action) => {
      state.MenuModalMeta = action.payload.value;
    },
    setGuideInfo: (state, action) => {
      state.guide_info = action.payload.value;
    },
    setAuthorization: (state, action) => {
      const _Authorization = window.guide_obj.setAuthorization(action.payload.guide_info, action.payload.uid);
      state.Authorization = _Authorization;
      //console.log("setAuthorization", _Authorization, action.payload);
    },
    setGuideSortOption: (state, action) => {
      state.GuideSortOption = action.payload.value;
    },
    setShowHiddenPlace: (state, action) => {
      console.log("*******setShowHiddenPlace", action.payload.value);
      state.showHiddenPlace = action.payload.value;

      // const places = window.guide_obj.getPlacesToDisplay(
      //   state.SortBy,
      //   state.SearchText,
      //   state.FilterVisited,
      //   state.FilterFavorites,
      //   state.DatesFilter,
      //   state.PlacesFilter,
      //   state.CurrentLocation,
      //   state.GroupsFilter,
      //   state.CategoriesFilter,
      //   state.Section,
      //   state.SearchResultsPlacesList,
      //   state.FilterSearched,
      //   state.ShowHiddenPlaces,
      //   state.ScheduleName,
      //   state.FilterNotVisited,
      //   state.FilterMustSee,
      //   state.SchedulesFilter,
      //   state.GroupsHide,
      //   state.DistanceFilter,
      //   state.user_info,
      //   false,
      //   action.payload.value
      // );

      // state.places = places;
      //console.log(places);

      const res = window.guide_obj.preFilterPlaces(
        state.SearchText,
        state.FilterVisited,
        state.FilterFavorites,
        state.DatesFilter,
        state.PlacesFilter,
        state.CurrentLocation,
        state.GroupsFilter,
        state.CategoriesFilter,
        state.Section,
        state.SearchResultsPlacesList,
        state.FilterSearched,
        state.ShowHiddenPlaces,
        state.ScheduleName,
        state.FilterNotVisited,
        state.FilterMustSee,
        state.SchedulesFilter,
        state.GroupsHide,
        state.DistanceFilter,
        state.user_info,
        action.payload.value
      );
      const _MarkersToDisplay = res.filteredPlacesList;
      const _polylines = res.polylines;
      _polylines.forEach((polyline) => {
        polyline.path = polyline.coordinates.map((ll) => ({ lat: ll.latitude, lng: ll.longitude }));
      });
      state.markers = _MarkersToDisplay;
      state.polylines = _polylines;
    },
    // setHTMLContent: (state, action) => {
    //   state.HTMLContent = action.payload.value;
    // },

    selectOne: (state, action) => {
      let { id, checkedIndex, mainIndex } = action.payload;

      // //console.log("action", state[index]);
      state[mainIndex].places[id].detail[checkedIndex].checked =
        !state[mainIndex].places[id].detail[checkedIndex].checked;

      // state[id].detail[index].checked = !state[id].detail[index].checked;
      return state;
    },
    selectAll: (state, action) => {
      if (action.payload.show) {
        state[action.payload.id].detail.forEach((element) => {
          element.checked = true;
        });
      } else {
        state[action.payload.id].detail.forEach((element) => {
          element.checked = false;
        });
      }
    },
  },
});

export const { selectOne, selectAll } = slice1.actions;

export const slice1Actions = slice1.actions;

export default slice1.reducer;
