export const help_videos_html = `<!DOCTYPE html>
<html>
  <head>
    <meta http-equiv="content-type" content="text/html; charset=UTF-8">
    <title>Videos</title>
    <style type="text/css" target="_blank">
      <!--
      a:link { color: 0645AD; text-decoration: none}
      -->
    </style>
  </head>
  <body>
    <ol>
      <li><a href="https://youtu.be/I-29tclYmTo" target="_blank">What
          is it?</a></li>
      <li><a href="https://youtu.be/_vvXWceGm5E" target="_blank">Quick overview</a></li>
      <li><a href="https://youtu.be/pdBXedreFk4" target="_blank">login, register
          guest mode &amp; forgot password</a></li>
      <li><a href="https://youtu.be/TvcGjld9Uj8" target="_blank">Search and
          select guide</a></li>
      <li><a href="https://youtu.be/cIw-zweH24c" target="_blank">HTML intro</a></li>
      <li><a href="https://youtu.be/yPvxoHActpA" target="_blank">HTML editor</a></li>
      <li><a href="https://youtu.be/aojRULW89Fs" target="_blank">HTML links</a></li>
      <li><a href="https://youtu.be/eosacoMAI1A" target="_blank">HTML embedded</a></li>
      <li><a href="https://youtu.be/ThYQiFw_Zv8" target="_blank">Home screen</a></li>
      <li><a href="https://youtu.be/-Po86Gos0Pc" target="_blank">Add audio from
          google drive</a></li>
      <li><a href="https://youtu.be/lSp9wRyEoD0" target="_blank">Groups and
          categories</a></li>
      <li><a href="https://youtu.be/IoPctej5agw" target="_blank">Search for
          places</a></li>
      <li><a href="https://youtu.be/gzWbK5pR3b0" target="_blank">Search with
          Happy Cow</a></li>
      <li><a href="https://youtu.be/VWqZcim82yQ" target="_blank">Edit guide info</a></li>
      <li><a href="https://youtu.be/B1AQ2TbLC8A" target="_blank">Steps to create
          a guide (web version)</a></li>
      <li><a href="https://youtu.be/9V9NCdR93KA" target="_blank">Places Screen</a></li>
      <li><a href="https://youtu.be/HTKzukUYy40" target="_blank">Map Screen</a></li>
      <li><a href="https://youtu.be/5p47W9_pqwk" target="_blank">Schedules
          Screen</a></li>
      <li><a href="https://youtu.be/2Mafj0BEw9Y" target="_blank">Add YouTube
          video</a></li>
      <li><a href="https://youtu.be/CKiqDXaC964" target="_blank">Chapters and
          sections</a></li>
      <li><a href="https://youtu.be/qelajE2ybYA" target="_blank">Copy guide</a></li>
      <li><a href="https://youtu.be/ow-QFRYfx0k" target="_blank">Add places,
          paths and areas</a></li>
      <li><a href="https://youtu.be/3YzF9o00CoU" target="_blank">Create new
          empty guide</a></li>
      <li><a href="https://youtu.be/Yd_y91OJ9Vg" target="_blank">Images</a></li>
      <li><a href="https://youtu.be/y0bWQSDPTec" target="_blank">Ratings and
          reviews</a></li>
      <li><a href="https://youtu.be/wdOplNPx6Ms" target="_blank">Collaborating
          on the same guide</a></li>
    </ol>
  </body>
</html>`;
