import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import "./Modal.css";
import { Form, Button } from "react-bootstrap";
import AlertModal from "./AlertModal";
import ImageDialog from "./ImageDialog";
import IconsDialog from "./IconsDialog";
import MultiSelectModal from "./MultiSelectModal";
import HTMLEditorModal from "./HTMLEditorModal";
import HTMLEditorComponent from "./HTMLEditorComponent";
import MyToolTip from "./MyToolTip";

import Place from "../../Models/Place";
import Common, { Settings, SortByEnum, POI_IMAGES, COLORS } from "../../Common/Common";
import HTMLLinksModalClass from "./HTMLLinksModalClass";
import MenuModal from "../modals/MenuModal";
import PromptModal from "../modals/PromptModal";
import SectionsDialog from "../modals/SectionsDialog";

class PlaceEditModal extends Component {
  // const PlaceEditModal = (props) => {
  // const [name, setName] = useState(props.name);
  // const [multiSelect, setMultiSelect] = useState(false);

  constructor(props) {
    super(props);

    //const place = window.guide_obj.places.getPlace(this.props.id);
    const place = this.props.place;
    const mode = this.props.mode;
    const callback = this.props.callback;
    let type = this.props.type;
    let initialValueName = "";
    let initialValueInfo = "";
    let initialValueLat = "";
    let initialValueLongi = "";
    let initialValueAddress = "";
    let initialValueCity = "";
    let initialValueState = "";
    let initialValueCountry = "";
    let initialValuePhone = "";
    let initialValueEmail = "";
    let initialValueRating = "";
    let initialValueHlink = "";
    let initialCategory = [];
    let initialGroup = [];
    let initialPhoto = "";
    let initialPhoto_m = "";
    let initialPoints = null;
    let initialHidden = false;
    let initialMustSee = false;
    let initialVisited = false;
    let initialFavorite = false;
    let initialDuration = 30;
    let poi_img = "0";
    // let videoLink = "";
    // let videoLinkOffset = 0;
    // let pathLink = "";
    // let pathLinkName = "";
    let Link = { type: "none", value: "", offset: "0" };
    let LinkName = `no link: ${Link.value}`;
    // if (mode == "ADD") {
    //   const text = this.readTextFromClipboard();
    //   if (typeof text == "string") {
    //     if (text.includes("https://www.happycow.net/reviews/")) {
    //       const text_parts = text.split("/");
    //       const name_parts = text_parts[text_parts.length - 1].split("-");
    //       let name = "";
    //       for (let i = 0; i < name_parts.length - 2; i++) name = name + (i == 0) ? name_parts[i] : name_parts[i] + " ";
    //       initialValueName = name;
    //       initialValueHlink = `Happpy Cow|${text}`;
    //       this.copyTextToClipboard("");
    //     }
    //   }
    // }

    if (mode != "ADD") {
      initialValueName = place.Name;
      initialValueInfo = Common.createTextFromHTML(place.Info);
      initialValueLat = place.Lat;
      if (typeof initialValueLat != "string") initialValueLat = initialValueLat.toFixed(6);
      initialValueLongi = place.Longi;
      if (typeof initialValueLongi != "string") initialValueLongi = initialValueLongi.toFixed(6);
      initialValueAddress = place.Address;
      initialValueCity = place.City;
      initialValueState = place.State;
      initialValueCountry = place.Country;
      initialValuePhone = place.Phone;
      initialValueEmail = place.Email;
      initialValueRating = place.Rating.toString();
      initialValueHlink = place.Hlink;
      initialCategory = place.Category;
      initialGroup = place.Group;
      initialPhoto = place.Photo;
      initialPhoto_m = place.Photo_m;
      initialPoints = place.Points;
      initialDuration = 30;
      poi_img = place.poi_img;

      if (typeof place.Duration == "undefined") initialDuration = 30;
      else initialDuration = place.Duration;
      type = place.PType;
      if (typeof place.Hidden == "undefined") initialHidden = false;
      else initialHidden = place.Hidden;
      if (typeof place.MustSee == "undefined") initialMustSee = false;
      else initialMustSee = place.MustSee;
      if (typeof place.Visited == "undefined") initialVisited = false;
      else initialVisited = place.Visited;
      if (typeof place.Favorite == "undefined") initialFavorite = false;
      else initialFavorite = place.Favorite;
      if (typeof place.Link == "undefined") Link = { type: "none", value: "", offset: "0" };
      else Link = place.Link;
      if (Link.type === "path") {
        const path_place = window.guide_obj.places.getPlace(Link.value);
        if (path_place !== null) LinkName = `${place.Link.type} link: ${path_place.Name}`;
      } else if (Link.type === "section") {
        const section = window.guide_obj.findSection(place.Link.value);
        if (section !== null) LinkName = `${place.Link.type} link: ${section.Title}`;
      } else if (Link.type === "none")
        this.setState({ Link: { type: "none", value: "", offset: "0" }, LinkName: `no link:` });
      else LinkName = `${Link.type} link: ${Link.value}`;
    }
    this.state = {
      textValueName: initialValueName,
      textValueInfo: initialValueInfo,
      textValueInfoUpdate: initialValueInfo,
      textValueLat: initialValueLat,
      textValueLongi: initialValueLongi,
      textValueAddress: initialValueAddress,
      textValueCity: initialValueCity,
      textValueState: initialValueState,
      textValueCountry: initialValueCountry,
      textValuePhone: initialValuePhone,
      textValueEmail: initialValueEmail,
      textValueRating: initialValueRating,
      textValueDuration: initialDuration,
      Categories: initialCategory,
      Groups: initialGroup,
      textValueHlink: initialValueHlink,
      Photo: initialPhoto,
      Photo_m: initialPhoto_m,
      type: type,
      Points: initialPoints,
      Hidden: initialHidden,
      MustSee: initialMustSee,
      Visited: initialVisited,
      Favorite: initialFavorite,
      poi_img: poi_img,
      callback: callback,
      Link: Link,
      LinkName: LinkName,

      AlertModalShow: false,
      AlertModalTitle: "",
      AlertModalMessage: "",
      AlertModalButtons: [],

      MultiselectItems: [],
      MultiselectSelectedItems: [],
      MultiselectMode: "uni",
      MultiselectCallback: null,
      MultiselectTitle: "Select",
      MultiselectSearchText: "",
      ShowMultiselect: false,

      IconsDialogShow: false,
      ImageDialogShow: false,
      ShowHTMLEditorModal: false,
      ShowHTMLLinks: false,

      MenuModalShow: false,
      MenuModalImage: null,
      MenuModalList: [],
      MenuModalHeading: "",
      MenuModalPlace: null,

      PromptModalShow: false,
      PromptModalTitle: "Enter Value",
      PromptModalMessage: "",
      PromptModalButtons: [],
      PromptModalInputs: [],
      SectionsDialogShow: false,
    };
    this.handleChangeTextName = this.handleChangeTextName.bind(this);
    this.handleChangeTextInfo = this.handleChangeTextInfo.bind(this);
    this.handleChangeTextLat = this.handleChangeTextLat.bind(this);
    this.handleChangeTextLongi = this.handleChangeTextLongi.bind(this);
    this.handleChangeTextAddress = this.handleChangeTextAddress.bind(this);
    this.handleChangeTextCity = this.handleChangeTextCity.bind(this);
    this.handleChangeTextState = this.handleChangeTextState.bind(this);
    this.handleChangeTextCountry = this.handleChangeTextCountry.bind(this);
    this.handleChangeTextPhone = this.handleChangeTextPhone.bind(this);
    this.handleChangeTextPhone = this.handleChangeTextPhone.bind(this);
    this.handleChangeTextEmail = this.handleChangeTextEmail.bind(this);
    this.handleChangeTextRating = this.handleChangeTextRating.bind(this);
    this.handleChangeTextHlink = this.handleChangeTextHlink.bind(this);
    this.handleChangeTextPhoto = this.handleChangeTextPhoto.bind(this);
    this.handleChangeTextDuration = this.handleChangeTextDuration.bind(this);
    this.handleChangeVideoLink = this.handleChangeVideoLink.bind(this);
    this.handleChangeVideoLinkOffset = this.handleChangeVideoLinkOffset.bind(this);
    //https://www.happycow.net/reviews/a-casa-ca-fe-florence-150794
  }
  componentDidMount() {}
  handleChangeTextName = (newText) => {
    this.setState({ ...this.state, textValueName: newText });
  };
  handleChangeTextInfo = (newText) => {
    this.setState({ ...this.state, textValueInfo: newText });
  };
  handleChangeTextLat = (newText) => {
    this.setState({ ...this.state, textValueLat: newText });
  };
  handleChangeTextLongi = (newText) => {
    this.setState({ ...this.state, textValueLongi: newText });
  };
  handleChangeTextAddress = (newText) => {
    this.setState({ ...this.state, textValueAddress: newText });
  };
  handleChangeTextCity = (newText) => {
    this.setState({ ...this.state, textValueCity: newText });
  };
  handleChangeTextState = (newText) => {
    this.setState({ ...this.state, textValueState: newText });
  };
  handleChangeTextCountry = (newText) => {
    this.setState({ ...this.state, textValueCountry: newText });
  };
  handleChangeTextPhone = (newText) => {
    this.setState({ ...this.state, textValuePhone: newText });
  };
  handleChangeTextEmail = (newText) => {
    this.setState({ ...this.state, textValueEmail: newText });
  };
  handleChangeTextRating = (newText) => {
    if (isNaN(newText)) {
      if (newText != "") newText = "";
    }
    const rating = parseInt(newText);
    if (rating < 0 || rating > 5) newText = "";
    this.setState({ ...this.state, textValueRating: newText });
  };

  handleChangeVideoLinkOffset = (newText) => {
    if (isNaN(newText)) {
      if (newText !== "") newText = "";
    }
    let link = this.state.Link;
    link.offset = newText;
    this.setState({ ...this.state, Link: link });
  };

  handleChangeVideoLink = (newText) => {
    this.setState({ ...this.state, videoLink: newText });
  };

  handleChangeTextHlink = (newText) => {
    this.setState({ ...this.state, textValueHlink: newText });
  };
  handleChangeTextPhoto = (newText) => {
    //console.log("handleChangeTextPhoto", newText);
    this.setState({ Photo: newText });
  };
  handleChangeTextDuration = (newText) => {
    if (isNaN(newText)) {
      if (newText !== "") newText = "";
    }
    this.setState({ textValueDuration: newText });
  };

  // copyTextToClipboard = (text) => {
  //   Clipboard.setString(text);
  // };

  // readTextFromClipboard = async () => {
  //   const text = await Clipboard.getString();
  //   return text;
  // };

  MyAlert = (message) => {
    this.setState({
      AlertModalTitle: "Message",
      AlertModalMessage: message,
      AlertModalButtons: [{ Title: "OK", Method: null }],
      AlertModalShow: true,
    });
  };

  AddPlace = () => {
    if (this.state.textValueName == "") {
      this.MyAlert("Must specify Name");
      return;
    }
    if ((this.state.textValueLat == "" || isNaN(this.state.textValueLat)) && this.state.Points == null) {
      this.MyAlert("Must specify Latitude and Longitude");
      return;
    }
    if ((this.state.textValueLongi == "" || isNaN(this.state.textValueLongi)) && this.state.Points == null) {
      this.MyAlert("Must specify Latitude and Longitude");
      return;
    }
    if (this.state.textValueRating != "" && isNaN(this.state.textValueRating)) {
      this.MyAlert("Rating must be blank or a number");
      return;
    }
    const mode = this.props.mode;
    const old_place = this.props.place;
    //console.log("AddPlace", mode);
    let new_place = new Place(window.guide_obj.places);
    if (mode == "ADD") {
      new_place.Name = this.state.textValueName;
      new_place.Info = this.state.textValueInfoUpdate;
      new_place.Lat = parseFloat(this.state.textValueLat);
      new_place.Longi = parseFloat(this.state.textValueLongi);
      new_place.Address = this.state.textValueAddress;
      new_place.City = this.state.textValueCity;
      new_place.State = this.state.textValueState;
      new_place.Country = this.state.textValueCountry;
      new_place.Phone = this.state.textValuePhone;
      new_place.Email = this.state.textValueEmail;
      new_place.Rating = this.state.textValueRating == "" ? "" : parseFloat(this.state.textValueRating);
      new_place.Hlink = this.state.textValueHlink;
      new_place.Category = this.state.Categories;
      new_place.Group = this.state.Groups;
      new_place.Photo = this.state.Photo;
      new_place.Photo_m = this.state.Photo_m;
      new_place.PType = this.state.type;
      new_place.Points = this.state.Points;
      new_place.Hidden = this.state.Hidden;
      new_place.MustSee = this.state.MustSee;
      new_place.Visited = this.state.Visited;
      new_place.Favorite = this.state.Favorite;
      new_place.poi_img = this.state.poi_img;
      new_place.Duration = this.state.textValueDuration === "" ? 30 : parseInt(this.state.textValueDuration);
      //new_place.pathLink = this.state.pathLink;
      new_place.Link = this.state.Link;
      //new_place.pathLinkName = this.state.pathLinkName;
      //new_place.videoLink = this.state.videoLink;
      //new_place.videoLinkOffset = this.state.videoLinkOffset;

      //console.log("new_place", new_place);
      // this.props.onAddPlace(new_place);
      window.guide_obj.places.addPlace(new_place);
      if (this.state.callback != null) this.state.callback(new_place);
    } else {
      old_place.Name = this.state.textValueName;
      old_place.Info = this.state.textValueInfoUpdate;
      old_place.Lat = parseFloat(this.state.textValueLat);
      old_place.Longi = parseFloat(this.state.textValueLongi);
      old_place.Address = this.state.textValueAddress;
      old_place.City = this.state.textValueCity;
      old_place.State = this.state.textValueState;
      old_place.Country = this.state.textValueCountry;
      old_place.Phone = this.state.textValuePhone;
      old_place.Email = this.state.textValueEmail;
      old_place.Rating = this.state.textValueRating == "" ? "" : parseFloat(this.state.textValueRating);
      old_place.Hlink = this.state.textValueHlink;
      old_place.Category = this.state.Categories;
      old_place.Group = this.state.Groups;
      old_place.Photo = this.state.Photo;
      old_place.Photo_m = this.state.Photo_m;
      old_place.PType = this.state.type;
      old_place.Points = this.state.Points;
      old_place.Hidden = this.state.Hidden;
      old_place.MustSee = this.state.MustSee;
      old_place.Visited = this.state.Visited;
      old_place.Favorite = this.state.Favorite;
      old_place.poi_img = this.state.poi_img;
      old_place.Duration = this.state.textValueDuration === "" ? 30 : parseInt(this.state.textValueDuration);
      old_place.Link = this.state.Link;
      //old_place.pathLink = this.state.pathLink;
      //old_place.pathLinkName = this.state.pathLinkName;
      //old_place.videoLink = this.state.videoLink;
      //old_place.videoLinkOffset = this.state.videoLinkOffset;
      //console.log(old_place);
      window.guide_obj.places.modifyPlace(old_place, old_place.ID);
    }
    this.props.update_display();
    this.exitDialog();
  };

  selectCategories = () => {
    //console.log("selectCategories");
    let selectedCategories = [];
    let cats = window.guide_obj.categoryObjectArrayFromIDArray(this.state.Categories);
    cats.forEach((cat) =>
      selectedCategories.push({
        label: cat.name,
        value: cat,
      })
    );
    this.MultiSelect(
      window.guide_obj.getCategoriesToDisplay(),
      selectedCategories,
      "multi",
      this.selectCategoriesCallBack,
      "Select Categories"
    );
  };

  selectCategoriesCallBack = (Categories) => {
    let cats = [];
    Categories.forEach((Category) => {
      cats.push(Category.value.id);
    });
    this.setState({ ...this.state, Categories: cats });
  };

  selectGroups = () => {
    let selectedGroups = [];
    let groups = window.guide_obj.groupObjectArrayFromIDArray(this.state.Groups);
    groups.forEach((group) =>
      selectedGroups.push({
        label: group.name,
        value: group,
      })
    );
    this.MultiSelect(
      window.guide_obj.getGroupsToDisplay(),
      selectedGroups,
      "multi",
      this.selectGroupsCallBack,
      "Select Groups"
    );
  };

  selectGroupsCallBack = (Groups) => {
    //console.log(Groups);
    let groups = [];
    Groups.forEach((Group) => {
      groups.push(Group.value.id);
    });
    this.setState({ ...this.state, Groups: groups });
  };

  MultiSelect = (Items, SelectedItems, Mode, Callback, Title) => {
    console.log("MultiSelect");
    this.setState({
      MultiselectItems: Items,
      MultiselectSelectedItems: SelectedItems,
      MultiselectMode: Mode,
      MultiselectCallback: Callback,
      MultiselectTitle: Title,
      MultiselectSearchText: "",
      ShowMultiselect: true,
    });
  };

  showMapDialog = () => {
    const mode = this.props.navigation.getParam("mode", "");
    let initialCoordinate = {
      latitude: parseFloat(this.state.textValueLat),
      longitude: parseFloat(this.state.textValueLongi),
    };
    if (mode == "ADD") initialCoordinate = null;
    ////console.log(
    //   "this.state.textValueLat, this.state.textValueLongi",
    //   this.state.textValueLat,
    //   this.state.textValueLongi
    // );
    // if (
    //   !isNaN(this.state.textValueLat) &&
    //   !isNaN(this.state.textValueLongi) &&
    //   this.state.textValueLat != "" &&
    //   this.state.textValueLongi != ""
    // ) {
    //   initialCoordinate = {
    //     latitude: parseFloat(this.state.textValueLat),
    //     longitude: parseFloat(this.state.textValueLongi),
    //   };
    // }
    //console.log("initialCoordinate", initialCoordinate);
    window.guide_obj.onGetMapToDisplay();
    //console.log(initialCoordinate);
    //console.log("showMapDialog", this.state.type);
    this.props.navigation.navigate("MapDialog", {
      updateCoordinate: this.updateCoordinate,
      initialCoordinate: initialCoordinate,
      type: this.state.type,
      initialPoints: this.state.Points,
    });
  };

  updateCoordinate = (coordinate, path) => {
    if (path == null)
      this.setState({ textValueLat: coordinate.latitude.toFixed(6), textValueLongi: coordinate.longitude.toFixed(6) });
    else {
      this.setState({ Points: path });
    }
  };

  changeImage = () => {
    this.setState({
      ImageDialogShow: true,
      mode: "select",
      size: "medium",
      multi: false,
      updateImage: this.updateImageCallBack,
      name: this.state.textValueName,
      Photo: this.state.Photo,
      Photo_m: this.state.Photo_m,
    });
  };

  updateImageCallBack = (imageInfo) => {
    this.setState({ Photo: imageInfo[0].url, Photo_m: imageInfo[0].url_m });
  };

  editInfo = () => {
    this.setState({ ShowHTMLEditorModal: true });
    // this.props.navigation.navigate("HTMLEditor", { html: this.state.textValueInfo, callback: this.editInfoCallback });
  };

  editInfoCallback = (html) => {
    this.setState({ textValueInfoUpdate: html });
  };

  formatLatLong = (textValue) => {
    if (textValue == "") return "";
    return `${parseFloat(textValue).toFixed(6)}`;
  };

  updateHidden = () => {
    this.setState({ Hidden: !this.state.Hidden });
  };

  updateMustSee = () => {
    this.setState({ MustSee: !this.state.MustSee });
  };

  updateVisited = () => {
    this.setState({ Visited: !this.state.Visited });
  };

  updateFavorite = () => {
    this.setState({ Favorite: !this.state.Favorite });
  };

  changeIcon = () => {
    this.setState({ IconsDialogShow: true });
  };

  changeIconCallback = (poi_img) => {
    console.log(poi_img);
    let _poi_img = poi_img.id;
    if (typeof poi_img.obj !== "undefined") if (typeof poi_img.obj.url !== "undefined") _poi_img = poi_img.obj.url;
    this.setState({ poi_img: _poi_img });
  };

  HiddenChanged = () => {
    this.setState({ Hidden: !this.state.Hidden });
  };

  VisitedChanged = () => {
    this.setState({ Visited: !this.state.Visited });
  };

  FavoriteChanged = () => {
    this.setState({ Favorite: !this.state.Favorite });
  };

  MustSeeChanged = () => {
    this.setState({ MustSee: !this.state.MustSee });
  };

  // getImageURLString = (str) => {
  //   console.log("BEFORE", str);
  //   const url = Common.getImageURL(str);
  //   console.log("AFTER", url);
  //   return url;
  // };

  getImageURLString = (str) => {
    // console.log("BEFORE", str);
    const img = Common.getImageURL(str, Common.getFBPath(null, this.props.user_info), true);
    // console.log("AFTER", img);
    return img;
  };

  exitDialog = () => {
    if (typeof this.props.onHidePrevious != "undefined") this.props.onHidePrevious();
    this.props.onHide();
  };

  createPlaceLinkMenu = () => {
    let menuList = [
      {
        label: "Remove link",
        method: this.removeLink,
        icon: null,
        visible: true,
        checked: false,
        info: "Remove link from place",
      },
      {
        label: "Link to Path",
        method: this.setPathLink,
        icon: null,
        visible: true,
        checked: false,
        info: "Get link to a path which has time location info and associated video",
      },
      {
        label: "Link to Video",
        method: this.setVideoLink,
        icon: null,
        visible: true,
        checked: false,
        info: "Set a link to a video.",
      },
      {
        label: "Link to Audio",
        method: this.setAudioLink,
        icon: null,
        visible: true,
        checked: false,
        info: "Set a link to a audio.",
      },
      {
        label: "Link to Section (HTML)",
        method: this.selectSection,
        icon: null,
        visible: true,
        checked: false,
        info: "Set a link to a section which contains user customized HTML.",
      },
      {
        label: "Link to URL",
        method: this.setURLLink,
        icon: null,
        visible: true,
        checked: false,
        info: "Set a link to a URL i.e. web page e.g. https://google.com",
      },
    ];
    this.setState({ MenuModalList: menuList, MenuModalHeading: "Place Link Menu", MenuModalShow: true });
  };

  removeLink = () => {
    this.setState({ Link: { type: "none", value: "", offset: "0" }, LinkName: `no link:` });
  };

  setPathLink = () => {
    const places = window.guide_obj.places.getPlacesToDisplay(null, "PolyLine");
    this.MultiSelect(places, [], "uni", this.setPathLinkCallBack, "Places");
  };

  setPathLinkCallBack = (Places) => {
    if (Places.length < 1) return;
    const place = Places[0];
    console.log("pathLink", place.value, place.name);
    this.setState({ Link: { type: "path", value: place.value, offset: "0" }, LinkName: `path link: ${place.name}` });
  };

  setVideoLink = () => {
    this.setState({
      PromptModalShow: true,
      PromptModalTitle: "Video",
      PromptModalMessage: "Enter video URL",
      PromptModalButtons: [
        { Title: "OK", Method: this.setVideoLinkCallBack },
        { Title: "CANCEL", Method: null },
      ],
      PromptModalInputs: [{ name: "Video URL", value: "" }],
    });
  };

  setVideoLinkCallBack = (inputs) => {
    if (inputs.length < 1) return;
    console.log("video", inputs[0].value, inputs[0].name);
    this.setState({
      Link: { type: "video", value: inputs[0].value, offset: "0" },
      LinkName: `video link: ${inputs[0].value}`,
    });
  };

  setAudioLink = () => {
    this.setState({
      PromptModalShow: true,
      PromptModalTitle: "Audio",
      PromptModalMessage: "Enter audio URL",
      PromptModalButtons: [
        { Title: "OK", Method: this.setAudioLinkCallBack },
        { Title: "CANCEL", Method: null },
      ],
      PromptModalInputs: [{ name: "Audio URL", value: "" }],
    });
  };

  setAudioLinkCallBack = (inputs) => {
    if (inputs.length < 1) return;
    console.log("audio", inputs[0].value, inputs[0].name);
    this.setState({
      Link: { type: "audio", value: inputs[0].value, offset: "0" },
      LinkName: `audio link: ${inputs[0].value}`,
    });
  };

  setURLLink = () => {
    this.setState({
      PromptModalShow: true,
      PromptModalTitle: "URL",
      PromptModalMessage: "Enter URL",
      PromptModalButtons: [
        { Title: "OK", Method: this.setURLLinkCallBack },
        { Title: "CANCEL", Method: null },
      ],
      PromptModalInputs: [{ name: "URL", value: "" }],
    });
  };

  setURLLinkCallBack = (inputs) => {
    if (inputs.length < 1) return;
    console.log("url", inputs[0].value, inputs[0].name);
    this.setState({
      Link: { type: "url", value: inputs[0].value, offset: "0" },
      LinkName: `url link: ${inputs[0].value}`,
    });
  };

  selectSection = () => {
    this.setState({ SectionsDialogShow: true });
  };

  selectSectionCallBack = (section) => {
    console.log("section", section.ID, section.Title);
    this.setState({ Link: { type: "section", value: section.ID, offset: "0" }, LinkName: section.Title });
  };

  selectLink = () => {
    this.setState({ ShowHTMLLinks: true });
  };

  insertLinkCallback = (link) => {
    console.log("insertLinkCallback", link.text, link.url);
    this.setState({ textValueHlink: `${link.text}|${link.url}` });
  };

  render() {
    let placeType =
      this.props.place.PType === "Icon" ? "Location" : this.props.place.PType === "PolyLine" ? "Path" : "Area";
    if (this.state.ImageDialogShow)
      return (
        <ImageDialog
          show={this.state.ImageDialogShow}
          onHide={() => this.setState({ ImageDialogShow: false })}
          user_info={this.props.user_info}
          // callback={this.updateImageCallBack}
          mode={"select"}
          modify_only={"true"}
          updateImage={this.updateImageCallBack}
          size={"sm"}
          photo={this.state.Photo}
          photo_m={this.state.Photo_m}
          multi={false}
          exif={false}
          map_bounds={this.props.map_bounds}
          place={this.props.place}
        />
      );
    if (this.state.ShowMultiselect)
      return (
        <MultiSelectModal
          show={this.state.ShowMultiselect}
          onHide={() => this.setState({ ShowMultiselect: false })}
          MultiselectItems={this.state.MultiselectItems}
          MultiselectSelectedItems={this.state.MultiselectSelectedItems}
          MultiselectMode={this.state.MultiselectMode}
          MultiselectCallback={this.state.MultiselectCallback}
          MultiselectTitle={this.state.MultiselectTitle}
          MultiselectSearchText={this.state.MultiselectSearchText}
        />
      );
    if (this.state.PromptModalShow)
      return (
        <PromptModal
          show={this.state.PromptModalShow}
          onHide={() => this.setState({ PromptModalShow: false })}
          PromptModalTitle={this.state.PromptModalTitle}
          PromptModalMessage={this.state.PromptModalMessage}
          PromptModalButtons={this.state.PromptModalButtons}
          PromptModalInputs={this.state.PromptModalInputs}
        />
      );

    if (this.state.SectionsDialogShow)
      return (
        <SectionsDialog
          show={this.state.SectionsDialogShow}
          onHide={() => this.setState({ SectionsDialogShow: false })}
          update_display={null}
          mode={"select"}
          callback={this.selectSectionCallBack}
        />
      );

    return (
      <>
        <div className="modal">
          <Modal
            {...this.props}
            enforceFocus={false}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            //centered
            scrollable={true}
          >
            <Modal.Header>
              <div style={{ width: "100%" }}>
                <div className="sort">
                  <div className="dialog-heading">
                    <div className="dialog-heading  color-blue">
                      <div>
                        <h4>{`${placeType} Edit`}</h4>
                      </div>
                      <i className="fas fa-check" onClick={() => this.AddPlace()}></i>

                      <span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>

                      <div>
                        <i className={`fas fa-times`} onClick={() => this.exitDialog()}></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Header>
            <Modal.Body>
              <p className="attribute">Name</p>
              <Form.Group>
                <Form.Control
                  type="text"
                  value={this.state.textValueName}
                  onChange={(e) => this.handleChangeTextName(e.target.value)}
                />
                <MyToolTip
                  loc="left"
                  msg="This will mark the place as visited, and will allow you to filter by this attribute."
                >
                  <Form.Check
                    label="Visited"
                    type="checkbox"
                    id="autoSizingCheck2"
                    checked={this.state.Visited}
                    onChange={() => this.VisitedChanged()}
                  />
                  {/* <div className="country">
                      <p>Visited</p>
                    </div> */}
                </MyToolTip>
                <MyToolTip
                  loc="left"
                  msg="This will mark the place as a favorite, and will allow you to filter by this attribute."
                >
                  <Form.Check
                    label="Favorite"
                    type="checkbox"
                    id="autoSizingCheck2"
                    checked={this.state.Favorite}
                    onChange={() => this.FavoriteChanged()}
                  />
                  {/* <div className="country">
                      <p>Favorite</p>
                    </div> */}
                </MyToolTip>
                <MyToolTip
                  loc="left"
                  msg="This will mark the place as a 'Must See', and will allow you to filter by this attribute."
                >
                  <Form.Check
                    label="Must See"
                    type="checkbox"
                    id="MustSee"
                    checked={this.state.MustSee}
                    onChange={() => this.MustSeeChanged()}
                  />
                  {/* <div className="country">
                      <p>Must See</p>
                    </div> */}
                </MyToolTip>

                <MyToolTip
                  loc="left"
                  msg="This will mark the place as 'hidden'. The place will not be displayed in the map or list, but it can be displayed if desired (i.e. link to it) This will declutter the guide, but still provide additional information."
                >
                  <Form.Check
                    label="Hidden"
                    type="checkbox"
                    id="Hidden"
                    checked={this.state.Hidden}
                    onChange={() => this.HiddenChanged()}
                  />
                  {/* <div className="country">
                      <p>Hidden</p>
                    </div> */}
                </MyToolTip>
              </Form.Group>

              {/* <MyToolTip
                  loc="left"
                  msg="This will mark the place as visited, and will allow you to filter by this attribute."
                >
                  <p className="attribute">Favorite</p>
                </MyToolTip>
                <Form.Check
                  inline
                  type="radio"
                  label="true"
                  checked={this.state.Favorite === "On"}
                  onChange={() => this.FavoriteChanged()}
                  name="Favorite"
                  id="Favorite"
                />
                <Form.Check
                  inline
                  type="radio"
                  label="false"
                  checked={!this.state.Visited}
                  onChange={() => this.VisitedChanged()}
                  name="visitedf"
                  id="visitedf"
                />
                <MyToolTip
                  loc="left"
                  msg="This will mark the place as a favorite, and will allow you to filter by this attribute."
                >
                  <p className="attribute">Favorite</p>
                </MyToolTip>

                <Form.Check
                  inline
                  type="radio"
                  label="true"
                  checked={this.state.Favorite}
                  onChange={() => this.FavoriteChanged()}
                  name="Favorite"
                  id="Favorite"
                />
                <Form.Check
                  inline
                  type="radio"
                  label="false"
                  checked={!this.state.Favorite}
                  onChange={() => this.FavoriteChanged()}
                  name="Favoritef"
                  id="Favoritef"
                />
                <MyToolTip
                  loc="left"
                  msg="This will mark the place as a 'Must See', and will allow you to filter by this attribute."
                >
                  <p className="attribute">Must See</p>
                </MyToolTip>

                <Form.Check
                  inline
                  type="radio"
                  label="true"
                  checked={this.state.MustSee}
                  onChange={() => this.MustSeeChanged()}
                  name="must"
                  id="must2"
                />
                <Form.Check
                  inline
                  type="radio"
                  label="false"
                  checked={!this.state.MustSee}
                  onChange={() => this.MustSeeChanged()}
                  name="must"
                  id="must1"
                />
                <MyToolTip
                  loc="left"
                  msg="This will mark the place as 'hidden'. The place will not be displayed in the map or list, but it can be displayed if desired (i.e. link to it) This will declutter the guide, but still provide additional information."
                >
                  <p className="attribute">Hidden</p>
                </MyToolTip>

                <Form.Check
                  inline
                  type="radio"
                  label="true"
                  checked={this.state.Hidden}
                  onChange={() => this.HiddenChanged()}
                  name="formHorizontalRadios"
                  id="formHorizontalRadios2"
                />
                <Form.Check
                  inline
                  type="radio"
                  label="false"
                  checked={!this.state.Hidden}
                  onChange={() => this.HiddenChanged()}
                  name="formHorizontalRadios"
                  id="formHorizontalRadios1"
                /> */}
              {/* <div className="text-center location">
                  <div>
                    <p className="attribute">Latitude</p>
                    <p>{`${this.formatLatLong(this.state.textValueLat)}`}</p>
                  </div>
                  <div>
                    <p className="attribute">Longitude</p>
                    <p>{`${this.formatLatLong(this.state.textValueLongi)}`}</p>
                  </div>
                </div> */}
              <div className="image">
                <img
                  src={this.getImageURLString(this.state.Photo_m)}
                  alt=""
                  className="text-center rounded"
                  style={{ objectFit: "cover" }}
                  width={150}
                  height={150}
                />
              </div>
              <div>
                <div className="text-center">
                  <MyToolTip loc="middle" msg="This will change the image for the place.">
                    {/* <img src={POI_IMAGES[this.state.poi_img].req} /> */}
                    <Button className="mt-3" onClick={() => this.changeImage()}>
                      Change Image
                    </Button>
                  </MyToolTip>
                  {this.state.type === "Icon" ? (
                    <MyToolTip
                      loc="middle"
                      msg="This will change the icon displayed on the map. Note that the order of precedence is Place, category, group and default. If the place icon exists it will use it, otherwise it uses the category icon, otherwise it use the group icon otherwise it uses the default icon."
                    >
                      <img src={Place.getPlacePOIImage(this.state.poi_img)} />
                      <Button className="mt-3" onClick={() => this.changeIcon()}>
                        Change Map Icon
                      </Button>
                    </MyToolTip>
                  ) : null}
                </div>
                <p className="attribute">Address</p>
                <Form.Group>
                  <Form.Control
                    type="text"
                    value={this.state.textValueAddress}
                    onChange={(e) => this.handleChangeTextAddress(e.target.value)}
                  />
                </Form.Group>
                <p className="attribute">City</p>
                <Form.Group>
                  <Form.Control
                    type="text"
                    value={this.state.textValueCity}
                    onChange={(e) => this.handleChangeTextCity(e.target.value)}
                  />
                </Form.Group>
                <div>
                  <p className="attribute">State</p>
                  <Form.Group>
                    <Form.Control
                      type="text"
                      value={this.state.textValueState}
                      onChange={(e) => this.handleChangeTextState(e.target.value)}
                    />
                  </Form.Group>
                </div>
                <div>
                  <p className="attribute">Country</p>
                  <Form.Group>
                    <Form.Control
                      type="text"
                      value={this.state.textValueCountry}
                      onChange={(e) => this.handleChangeTextCountry(e.target.value)}
                    />
                  </Form.Group>
                </div>
                <div className="text-center">
                  <MyToolTip
                    loc="middle"
                    msg="Add the place into one or more groups. Groups are used to group the places based on a common characteristic (e.g. Places in a neighborhood) for filtering and display. Note that groups are at a higher level than categories"
                  >
                    <div onClick={this.selectGroups}>
                      <Button style={{ minWidth: "140px" }} className="mt-3">
                        Groups{" "}
                      </Button>
                    </div>
                  </MyToolTip>
                  <span>{`${window.guide_obj.groupNamesFromIDs(this.state.Groups)}`}</span>

                  <MyToolTip
                    loc="middle"
                    msg="Add the place into one or more categories. Categories are used to categorize the places based on a common characteristic (e.g. Italian restaurants) for filtering and display. Note that groups are at a higher level than categories"
                  >
                    <div onClick={this.selectCategories}>
                      <Button style={{ minWidth: "140px" }} className="mt-3">
                        Categories{" "}
                      </Button>
                    </div>
                  </MyToolTip>

                  <span>{`${window.guide_obj.categoryNamesFromIDs(this.state.Categories)}`}</span>
                </div>
                <div>
                  <p className="attribute">Phone</p>
                  <Form.Group>
                    <Form.Control
                      type="text"
                      value={this.state.textValuePhone}
                      onChange={(e) => this.handleChangeTextPhone(e.target.value)}
                    />
                  </Form.Group>
                </div>
                <div>
                  <p className="attribute">email</p>
                  <Form.Group>
                    <Form.Control
                      type="text"
                      value={this.state.textValueEmail}
                      onChange={(e) => this.handleChangeTextEmail(e.target.value)}
                    />
                  </Form.Group>
                </div>
                <div>
                  <p className="attribute">URLs</p>
                  <Form.Group>
                    <Form.Control
                      type="text"
                      value={this.state.textValueHlink}
                      onChange={(e) => this.handleChangeTextHlink(e.target.value)}
                    />
                  </Form.Group>
                  {/* {this.state.type === "Icon" ? ( */}
                  <div>
                    <MyToolTip loc="middle" msg="Select an HTML link">
                      <div onClick={this.createPlaceLinkMenu}>
                        <Button style={{ minWidth: "140px" }} className="mt-3">
                          Place Link{" "}
                        </Button>
                      </div>
                    </MyToolTip>
                    <span>{this.state.LinkName}</span>{" "}
                  </div>
                  {/* ) : null} */}
                  {this.state.Link.type === "video" ? (
                    <div>
                      {/* <p className="attribute">Video Link</p>
                      <Form.Group>
                        <Form.Control
                          type="text"
                          value={this.state.videoLink}
                          onChange={(e) => this.handleChangeVideoLink(e.target.value)}
                        />
                      </Form.Group> */}
                      <p className="attribute">Video Link Offset (seconds)</p>
                      <Form.Group>
                        <Form.Control
                          type="text"
                          value={this.state.Link.offset}
                          onChange={(e) => this.handleChangeVideoLinkOffset(e.target.value)}
                        />
                      </Form.Group>
                    </div>
                  ) : null}
                </div>
                <div>
                  <p className="attribute">Rating</p>
                  <Form.Group>
                    <Form.Control
                      type="text"
                      value={this.state.textValueRating}
                      onChange={(e) => this.handleChangeTextRating(e.target.value)}
                    />
                  </Form.Group>
                </div>
                <div>
                  <p className="attribute">Duration (minutes)</p>
                  <Form.Group>
                    <Form.Control
                      type="text"
                      value={this.state.textValueDuration}
                      onChange={(e) => this.handleChangeTextDuration(e.target.value)}
                    />
                  </Form.Group>
                </div>
              </div>
              <HTMLEditorComponent
                show={true}
                onHide={() => null}
                html={this.state.textValueInfo}
                callback={null}
                callback_rt={this.editInfoCallback}
                embedded={true}
                title={"Info"}
              />
            </Modal.Body>
          </Modal>
        </div>
        <AlertModal
          show={this.state.AlertModalShow}
          onHide={() => this.setState({ AlertModalShow: false })}
          AlertModalTitle={this.state.AlertModalTitle}
          AlertModalMessage={this.state.AlertModalMessage}
          AlertModalButtons={this.state.AlertModalButtons}
        />
        <IconsDialog
          show={this.state.IconsDialogShow}
          onHide={() => this.setState({ IconsDialogShow: false })}
          update_icon={this.changeIconCallback}
        />
        <HTMLLinksModalClass
          show={this.state.ShowHTMLLinks}
          onHide={() => this.setState({ ShowHTMLLinks: false })}
          callback={this.insertLinkCallback}
          user_info={this.props.user_info}
          guide_meta={this.props.guide_meta}
          guide_type={"Guide"}
        />
        {this.state.MenuModalShow ? (
          <MenuModal
            // ref={menuModalRef}
            id="MenuModal"
            show={this.state.MenuModalShow}
            onHide={() => this.setState({ MenuModalShow: false })}
            menu_modal_list={this.state.MenuModalList}
            menu_modal_heading={this.state.MenuModalHeading}
            menu_model_image={this.state.MenuModalImage}
            menu_modal_place={this.state.MenuModalPlace}
          />
        ) : null}
        {/* <MultiSelectModal
        show={multiSelect}
        onHide={() => setMultiSelect(false)}
        index={props.index}
        mainIndex={props.mainIndex}
        id={props.id}
      /> */}
      </>
    );
  }
}

export default PlaceEditModal;
