import Places from "./Places";
import Place from "./Place";
import GuideDocument, { NextPage } from "./Document";
import Group from "./Group";
import CustomIcon from "./CustomIcon";
import Category from "./Category";
import Schedule from "./Schedule";
import { html_entity_decode } from "../Common/Common_d";
import Common, { Settings, SortByEnum, labelChars, AUTHORIZATION_LEVEL, COLORS } from "../Common/Common";
import React, { Component } from "react";

import Chapter from "../Models/Chapter";
import { DateTime } from "luxon";
import { KMLTemplate } from "../Data/KMLTemplate";

export class GuideAttributes {
  constructor(guide_name, description, image, language, rating, countries, cities) {
    this.guide_id = "new";
    this.guide_name = guide_name;
    this.description = description;
    this.image = image;
    this.image_m = "";
    this.language = language;
    this.access = [];
    //this.price = "0";
    this.rating = rating;
    //this.ratings = "";
    //this.start_date = "";
    //this.end_date = "";
    this.owner_name = "";
    this.user_id = "";
    this.creator_id = "";
    this.countries = countries;
    this.cities = cities;
    this.mod = DateTime.now().toISO({ zone: "utc" }); //"2013-04-22 19:40:51";
    this.created = this.mod;
    this.read = this.mod;
    this.copy = this.mod;
    this.num_ratings = 0;
    this.times_read = 0;
    this.times_copied = 0;
    this.base_location = { latitude: 43.7, longitude: 11.2 };
    this.type = "";
  }

  decodeAttributes(attributes) {
    this.guide_id = attributes.guide_id;
    this.guide_name = attributes.guide_name;
    this.description = attributes.description;
    this.image = attributes.image;
    this.image_m = attributes.image_m;
    this.language = attributes.language;
    this.access = attributes.access;
    this.rating = attributes.rating;
    this.owner_name = attributes.owner_name;
    this.user_id = attributes.user_id;
    this.creator_id = attributes.creator_id;
    this.countries = attributes.countries;
    this.cities = attributes.cities;
    this.mod = DateTime.fromISO(attributes.mod, { zone: "utc" });
    this.created = DateTime.fromISO(attributes.created, { zone: "utc" });
    this.read = DateTime.fromISO(attributes.read, { zone: "utc" });
    this.copy = DateTime.fromISO(attributes.copy, { zone: "utc" });
    this.num_ratings = attributes.num_ratings;
    this.times_read = attributes.times_read;
    this.times_copied = attributes.times_copied;
    this.base_location = attributes.base_location;
    this.type = attributes.type;
    if (typeof this.type === "undefined") this.type = "";
    const index = this.access.findIndex((x) => x === "Featured");
    if (index !== -1) {
      //this.access = this.access.splice(index, 1);
      this.type = "Featured";
    }
  }
  modifyGuideAttributes(attributes) {
    //console.log("modifyGuideAttributes", attributes);
    this.guide_name = attributes.guide_name;
    this.description = attributes.description;
    this.image = attributes.image;
    this.image_m = attributes.image_m;
    this.language = attributes.language;
    this.access = attributes.access;
    this.rating = attributes.rating;
    this.mod = attributes.mod;
    this.countries = attributes.countries;
    this.cities = attributes.cities;
    this.base_location = attributes.base_location;
    this.type = attributes.type;
    if (typeof this.type === "undefined") this.type = "";

    // //console.log("modifyGuideAttributes", this.attributes);
  }
}

let _initial_groups = [
  { id: "0", name: "Accomodation", poi_img: "140", alias: [] },
  { id: "1", name: "Architecture", poi_img: "4", alias: [] },
  { id: "2", name: "Area", poi_img: "31", alias: [] },
  { id: "3", name: "Attraction", poi_img: "195", alias: [] },
  { id: "4", name: "City", poi_img: "16", alias: [] },
  { id: "5", name: "Entertainment", poi_img: "141", alias: [] },
  { id: "6", name: "Family & Kids", poi_img: "43", alias: [] },
  { id: "7", name: "Historical", poi_img: "85", alias: [] },
  { id: "8", name: "Landmark", poi_img: "129", alias: [] },
  { id: "9", name: "Museum", poi_img: "90", alias: [] },
  { id: "10", name: "Nearby", poi_img: "151", alias: [] },
  { id: "11", name: "Neighborhood", poi_img: "152", alias: [] },
  { id: "12", name: "Religion", poi_img: "30", alias: ["Religious sites"] },
  { id: "13", name: "Restaurant", poi_img: "139", alias: [] },
  { id: "14", name: "Shopping", poi_img: "127", alias: [] },
  { id: "15", name: "Sport", poi_img: "160", alias: ["Sports & outdoors", "Race course"] },
  { id: "16", name: "Transport", poi_img: "19", alias: [] },
  { id: "17", name: "Town", poi_img: "177", alias: [] },
  { id: "18", name: "State", poi_img: "197", alias: [] },
  { id: "19", name: "Country", poi_img: "164", alias: [] },
  { id: "20", name: "Highlight", poi_img: "204", alias: [] },
  { id: "21", name: "Photo", poi_img: "208", alias: [] },
  { id: "22", name: "Audio", poi_img: "209", alias: [] },
  { id: "23", name: "Video", poi_img: "210", alias: [] },
  { id: "24", name: "Info", poi_img: "211", alias: [] },
];
let _initial_categories = [
  { id: "0", name: "Accomodation", poi_img: "80", alias: [] },
  { id: "1", name: "Airport", poi_img: "2", alias: [] },
  { id: "2", name: "Appartment", poi_img: "80", alias: [] },
  { id: "3", name: "Arts", poi_img: "141", alias: [] },
  { id: "4", name: "Bank", poi_img: "8", alias: [] },
  { id: "5", name: "Beach", poi_img: "12", alias: [] },
  { id: "6", name: "Bridge", poi_img: "142", alias: [] },
  { id: "7", name: "Cemetery", poi_img: "29", alias: [] },
  { id: "8", name: "Church", poi_img: "30", alias: [] },
  { id: "9", name: "City", poi_img: "16", alias: [] },
  { id: "10", name: "Country", poi_img: "164", alias: [] },
  { id: "11", name: "Day Trip", poi_img: "147", alias: [] },
  { id: "12", name: "Entertainment", poi_img: "141", alias: [] },
  { id: "13", name: "Excursion", poi_img: "147", alias: ["Adventure"] },
  { id: "14", name: "Flight", poi_img: "2", alias: [] },
  { id: "15", name: "Historical", poi_img: "5", alias: [] },
  { id: "16", name: "Hotel", poi_img: "140", alias: [] },
  { id: "17", name: "Golf", poi_img: "145", alias: [] },
  { id: "18", name: "Kids", poi_img: "149", alias: [] },
  { id: "19", name: "Leisure", poi_img: "150", alias: [] },
  { id: "20", name: "Market", poi_img: "35", alias: [] },
  { id: "21", name: "Monument", poi_img: "85", alias: ["Memorial"] },
  { id: "22", name: "Museum", poi_img: "90", alias: [] },
  { id: "23", name: "Nearby", poi_img: "151", alias: [] },
  { id: "24", name: "Neighborhood", poi_img: "152", alias: [] },
  { id: "25", name: "Nightlife", poi_img: "153", alias: [] },
  { id: "26", name: "Outdoors", poi_img: "154", alias: [] },
  { id: "27", name: "Palace", poi_img: "25", alias: [] },
  { id: "28", name: "Park", poi_img: "3", alias: [] },
  { id: "29", name: "Restaurant", poi_img: "139", alias: [] },
  { id: "30", name: "Rest. Chinese", poi_img: "111", alias: [] },
  { id: "31", name: "Rest. Fast Food", poi_img: "148", alias: ["Food & drinks"] },
  { id: "32", name: "Rest. French", poi_img: "155", alias: [] },
  { id: "33", name: "Rest. Indian", poi_img: "114", alias: [] },
  { id: "34", name: "Rest. Italian", poi_img: "115", alias: [] },
  { id: "35", name: "Rest. Japanese", poi_img: "156", alias: [] },
  { id: "36", name: "Rest. Mexican", poi_img: "118", alias: [] },
  { id: "37", name: "Rest. Pizza", poi_img: "157", alias: [] },
  { id: "38", name: "Rest. Seafood", poi_img: "112", alias: [] },
  { id: "39", name: "Rest. Thai", poi_img: "119", alias: [] },
  { id: "40", name: "Rest. Vegan", poi_img: "120", alias: [] },
  { id: "41", name: "Rest. Vegeterian", poi_img: "120", alias: [] },
  { id: "42", name: "Shop", poi_img: "7", alias: [] },
  { id: "43", name: "Sport", poi_img: "160", alias: [] },
  { id: "44", name: "Square", poi_img: "31", alias: [] },
  { id: "45", name: "Street", poi_img: "122", alias: ["Streets and squares"] },
  { id: "46", name: "Theater", poi_img: "141", alias: [] },
  { id: "47", name: "Transport", poi_img: "19", alias: [] },
  { id: "48", name: "Trail", poi_img: "162", alias: [] },
  { id: "49", name: "Walk", poi_img: "136", alias: [] },
  { id: "50", name: "Architecture", poi_img: "4", alias: [] },
  { id: "51", name: "Arts & Culture", poi_img: "141", alias: ["Cultural sites"] },
  { id: "52", name: "Gelato", poi_img: "143", alias: [] },
  { id: "53", name: "Search", poi_img: "165", alias: [] },
  { id: "54", name: "Bakery", poi_img: "166", alias: [] },
  { id: "55", name: "Bar", poi_img: "11", alias: ["Bars & clubs"] },
  { id: "56", name: "Pub", poi_img: "9", alias: [] },
  { id: "57", name: "Rest. Meat", poi_img: "167", alias: [] },
  { id: "58", name: "Rest. Venezuela", poi_img: "168", alias: [] },
  { id: "59", name: "Town", poi_img: "177", alias: [] },
  { id: "60", name: "Cinema", poi_img: "181", alias: [] },
  { id: "61", name: "Dancing", poi_img: "182", alias: [] },
  { id: "62", name: "Wild Life", poi_img: "182", alias: ["Wildlife"] },
  { id: "63", name: "Fishing", poi_img: "184", alias: [] },
  { id: "64", name: "Geyser", poi_img: "185", alias: [] },
  { id: "65", name: "Glacier", poi_img: "186", alias: [] },
  { id: "66", name: "Hot Spring", poi_img: "188", alias: [] },
  { id: "67", name: "Music", poi_img: "191", alias: [] },
  { id: "68", name: "View", poi_img: "193", alias: ["Viewpoints"] },
  { id: "69", name: "Ruins", poi_img: "194", alias: [] },
  { id: "70", name: "Small City", poi_img: "196", alias: [] },
  { id: "71", name: "State", poi_img: "197", alias: [] },
  { id: "72", name: "Statue", poi_img: "198", alias: [] },
  { id: "73", name: "Volcano", poi_img: "200", alias: [] },
  { id: "74", name: "Synagogue", poi_img: "199", alias: [] },
  { id: "75", name: "Waterfall", poi_img: "201", alias: [] },
  { id: "76", name: "Wetlands", poi_img: "202", alias: [] },
  { id: "77", name: "River", poi_img: "121", alias: [] },
  { id: "78", name: "Lake", poi_img: "50", alias: [] },
  { id: "79", name: "Camping", poi_img: "22", alias: [] },
  { id: "80", name: "Castle", poi_img: "25", alias: [] },
  { id: "81", name: "Cathedral", poi_img: "26", alias: [] },
  { id: "82", name: "Cave", poi_img: "28", alias: ["Caverns & caves"] },
  { id: "83", name: "Coffee", poi_img: "33", alias: [] },
  { id: "84", name: "Store", poi_img: "35", alias: [] },
  { id: "85", name: "Desert", poi_img: "40", alias: [] },
  { id: "86", name: "Home", poi_img: "45", alias: [] },
  { id: "87", name: "Hospital", poi_img: "47", alias: [] },
  { id: "88", name: "Library", poi_img: "78", alias: [] },
  { id: "89", name: "Lighthouse", poi_img: "79", alias: ["Light house"] },
  { id: "90", name: "Marina", poi_img: "82", alias: [] },
  { id: "91", name: "Mosque", poi_img: "86", alias: [] },
  { id: "92", name: "Mountain", poi_img: "87", alias: [] },
  { id: "93", name: "Parking", poi_img: "104", alias: [] },
  { id: "94", name: "Playground", poi_img: "106", alias: [] },
  { id: "95", name: "Resort", poi_img: "110", alias: [] },
  { id: "96", name: "Road", poi_img: "122", alias: [] },
  { id: "97", name: "Taxi", poi_img: "128", alias: [] },
  { id: "98", name: "Toilets", poi_img: "130", alias: [] },
  { id: "99", name: "Train", poi_img: "131", alias: [] },
  { id: "100", name: "Tramway", poi_img: "132", alias: [] },
  { id: "101", name: "Zoo", poi_img: "138", alias: ["Zoos & aquariums"] },
  { id: "102", name: "Ice Cream", poi_img: "143", alias: [] },
  { id: "103", name: "Canyon", poi_img: "180", alias: [] },
  { id: "104", name: "Highlight", poi_img: "204", alias: ["Must see"] },
  { id: "105", name: "Attraction", poi_img: "195", alias: [] },
  { id: "106", name: "Landmark", poi_img: "129", alias: ["Sculptures"] },
  { id: "107", name: "Family & Kids", poi_img: "43", alias: [] },
  { id: "108", name: "Concerts & shows", poi_img: "141", alias: [] },
  { id: "109", name: "Religious site", poi_img: "30", alias: [] },
  { id: "110", name: "Shopping", poi_img: "127", alias: [] },
  { id: "111", name: "School", poi_img: "124", alias: ["Educational sites"] },
  { id: "112", name: "Geographical feature", poi_img: "193", alias: [] },
  { id: "113", name: "Island", poi_img: "205", alias: [] },
  { id: "114", name: "Building", poi_img: "206", alias: ["Convention center"] },
  { id: "115", name: "Casino", poi_img: "207", alias: ["Casinos & gambling"] },
  { id: "116", name: "Ski", poi_img: "125", alias: ["Ski resort"] },
  { id: "117", name: "Photo", poi_img: "208", alias: [] },
  { id: "118", name: "Audio", poi_img: "209", alias: [] },
  { id: "119", name: "Video", poi_img: "210", alias: [] },
  { id: "120", name: "Info", poi_img: "211", alias: [] },
  { id: "121", name: "Garden", poi_img: "212", alias: ["Gardens"] },
  { id: "122", name: "Forest", poi_img: "213", alias: [] },
  { id: "123", name: "Aquarium", poi_img: "214", alias: [] },
  { id: "124", name: "Theme park", poi_img: "215", alias: ["Amusement Park", "Water Park"] },
  { id: "125", name: "Stadium", poi_img: "126", alias: ["Sports & outdoors"] },
  { id: "126", name: "Kayaking", poi_img: "49", alias: [] },
  { id: "127", name: "Climbing", poi_img: "49", alias: ["Mountain climbing"] },
  { id: "128", name: "Biking", poi_img: "15", alias: ["Cycling"] },
  { id: "129", name: "Horse riding", poi_img: "15", alias: [] },
  { id: "130", name: "Boating", poi_img: "18", alias: [] },
  { id: "131", name: "Swimmming", poi_img: "12", alias: [] },
  { id: "132", name: "Surfing", poi_img: "219", alias: [] },
  { id: "133", name: "Horse Racing", poi_img: "15", alias: [] },
  { id: "134", name: "Farm", poi_img: "220", alias: [] },
  { id: "135", name: "Factory", poi_img: "221", alias: [] },
  { id: "136", name: "Science", poi_img: "222", alias: [] },
  { id: "137", name: "Science Museum", poi_img: "222", alias: [] },
  { id: "138", name: "Parades", poi_img: "141", alias: [] },
  { id: "139", name: "Carnivals", poi_img: "141", alias: [] },
  { id: "140", name: "Fair", poi_img: "141", alias: [] },
  { id: "141", name: "Wineries & vineyards", poi_img: "137", alias: [] },
  { id: "142", name: "Race course", poi_img: "126", alias: [] },
  { id: "143", name: "Sightseeing", poi_img: "147", alias: [] },
  { id: "144", name: "Art", poi_img: "223", alias: [] },
  { id: "145", name: "Art gallery", poi_img: "223", alias: [] },
  { id: "146", name: "Observatory", poi_img: "224", alias: [] },
  { id: "147", name: "Planetarium", poi_img: "224", alias: [] },
  { id: "148", name: "Wine", poi_img: "137", alias: [] },
  { id: "149", name: "Brewery", poi_img: "225", alias: [] },
  { id: "150", name: "Distillary", poi_img: "225", alias: [] },
  { id: "151", name: "Hiking trail", poi_img: "136", alias: [] },
  { id: "152", name: "Hiking", poi_img: "136", alias: [] },
  { id: "153", name: "Fort", poi_img: "25", alias: [] },
  { id: "154", name: "Monorail", poi_img: "131", alias: [] },
  { id: "155", name: "Scenic drive", poi_img: "193", alias: [] },
  { id: "156", name: "Sports Venue", poi_img: "126", alias: [] },
  { id: "157", name: "Landscape", poi_img: "212", alias: [] },
  { id: "158", name: "Ancient", poi_img: "194", alias: [] },
  { id: "159", name: "Festival", poi_img: "141", alias: [] },
  { id: "160", name: "Nature", poi_img: "154", alias: [] },
  { id: "161", name: "Culinary", poi_img: "148", alias: [] },
  { id: "162", name: "Food", poi_img: "148", alias: [] },
  { id: "163", name: "Spa", poi_img: "150", alias: [] },
  { id: "164", name: "Villa", poi_img: "25", alias: [] },
  { id: "165", name: "Canoeing", poi_img: "49", alias: [] },
  { id: "166", name: "Rafting", poi_img: "49", alias: [] },
  { id: "167", name: "Botanical", poi_img: "212", alias: [] },
];

let GuideSettings = {
  mode: "custom_view", // "edit" "view" "custom_view"
  zoom: 5,
  hide_schedule: false,
  hide_hidden: false,
  wrap_text: false,
  places_list: {
    menu: false,
    info: true,
    url: false,
    attributes: true,
    center: true,
    filter: false,
    path_link: false,
  },
  places_map: {
    menu: true,
    info: false,
    url: false,
    attributes: false,
    center: false,
    filter: false,
    path_link: false,
    number: true,
  },
  places_map_hover: {
    menu: false,
    place: true,
    info: true,
    event: true,
  },
  event: {
    menu: false,
    details: true,
    center: true,
    filter: false,
  },
  schedule: {
    menu: false,
    itinerary: true,
    center: false,
    filter: true,
  },
  day: {
    menu: false,
    center: false,
    filter: true,
  },
  itinerary: {
    directions: true,
    info: true,
    photo: true,
    place_menu: true,
  },
};
export default class Guide {
  constructor(guide_id) {
    this.guide_id = guide_id;
    this.places = new Places();
    this.schedule = new Schedule();
    this.chapters = [];
    //this.journal = "";
    this.document = "";
    this.groups = _initial_groups;
    this.categories = _initial_categories;
    this.attributes = new GuideAttributes("New", "New Guide", "", "English", 0, [], []);
    this.custom_icons = [];
    this.guide_settings = GuideSettings;
  }

  setGuideSettings(GuideSettings) {
    this.guide_settings = GuideSettings;
  }

  getCategoryByName(name, from) {
    if (name === null || typeof name === "undefined") return null;
    if (from === "guide") {
      for (let i = 0; i < this.categories.length; i++) {
        if (
          this.categories[i].name.toUpperCase() === name.toUpperCase() ||
          (this.categories[i].name + "s").toUpperCase() === name.toUpperCase()
        )
          return this.categories[i];
      }
      return null;
    } else if (from === "initial") {
      for (let i = 0; i < _initial_categories.length; i++) {
        if (
          _initial_categories[i].name.toUpperCase() === name.toUpperCase() ||
          (_initial_categories[i].name + "s").toUpperCase() === name.toUpperCase()
        )
          return _initial_categories[i];
        for (let j = 0; j < _initial_categories[i].alias.length; j++)
          if (_initial_categories[i].alias[j].toUpperCase() === name.toUpperCase()) return _initial_categories[i];
      }
      return null;
    }
    return null;
  }

  getGroupByName(name, from) {
    if (name === null || typeof name === "undefined") return null;
    if (from === "guide") {
      for (let i = 0; i < this.groups.length; i++) {
        if (
          this.groups[i].name.toUpperCase() === name.toUpperCase() ||
          (this.groups[i].name + "s").toUpperCase() === name.toUpperCase()
        )
          return this.groups[i];
      }
    } else if (from === "initial") {
      for (let i = 0; i < _initial_groups.length; i++) {
        if (
          _initial_groups[i].name.toUpperCase() === name.toUpperCase() ||
          (_initial_groups[i].name + "s").toUpperCase() === name.toUpperCase()
        )
          return _initial_groups[i];
        for (let j = 0; j < _initial_groups[i].alias.length; j++)
          if (_initial_groups[i].alias[j].toUpperCase() === name.toUpperCase()) return _initial_groups[i];
      }
    }
    return null;
  }

  getCustomIconsToDisplay() {
    let iconsList = [];
    for (let i = 0; i < this.custom_icons.length; i++) {
      iconsList.push({
        id: this.custom_icons[i].id,
        name: this.custom_icons[i].name,
        label: this.custom_icons[i].name,
        icon: this.custom_icons[i].poi_img,
        value: this.custom_icons[i].url,
        obj: this.custom_icons[i],
      });
    }
    iconsList = iconsList.sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1));
    return iconsList;
  }

  addCustomIcon(group_name) {
    const group = new CustomIcon(this.custom_icons, group_name);
    this.custom_icons.push(group);
  }

  modifyCustomIcon(oldGroup, newGroup) {
    let index = this.custom_icons.findIndex((x) => x.id == oldGroup.id);
    if (index != -1) {
      this.custom_icons[index].name = newGroup.name;
      this.custom_icons[index].poi_img = newGroup.poi_img;
      this.custom_icons[index].url = newGroup.url;
    }
  }

  deleteCustomIcon(group) {
    //console.log("deleteGroup", group);
    const index = this.custom_icons.findIndex((x) => x.id == group.id);
    if (index == -1) return;
    this.custom_icons.splice(index, 1);
    //console.log("deleteGroup index", index);
    //this.places.deletePlaceGroup(group.id);
  }

  modifyGuideAttributes(attributes) {
    //console.log("modifyGuideAttributes", attributes);
    this.attributes.guide_name = attributes.guide_name;
    this.attributes.description = attributes.description;
    this.attributes.image = attributes.image;
    this.attributes.image_m = attributes.image_m;
    this.attributes.language = attributes.language;
    this.attributes.access = attributes.access;
    this.attributes.mod = attributes.mod;
    this.attributes.rating = attributes.rating;
    this.attributes.countries = attributes.countries;
    this.attributes.cities = attributes.cities;
    this.base_location = attributes.base_location;
    this.type = attributes.type;
    if (typeof this.type === "undefined") this.type = "";

    // //console.log("modifyGuideAttributes", this.attributes);
  }

  setAuthorization(guide_info, uid) {
    //console.log("setAuthorization", "uid:", uid);
    if (uid === "guest") return AUTHORIZATION_LEVEL.GUEST;
    if (guide_info.user_id === uid) return AUTHORIZATION_LEVEL.MODIFY;
    let Auth = AUTHORIZATION_LEVEL.NONE;
    let ShareAll = AUTHORIZATION_LEVEL.NONE;
    if (typeof guide_info.access == "undefined") return AUTHORIZATION_LEVEL.NONE;
    const _access = guide_info.access;
    let highest_access = AUTHORIZATION_LEVEL.NONE;
    _access.forEach((access) => {
      if (access == "All_R") ShareAll = AUTHORIZATION_LEVEL.READ;
      else if (access == "All_C") ShareAll = AUTHORIZATION_LEVEL.COPY;
      else if (access == "All_M") ShareAll = AUTHORIZATION_LEVEL.MODIFY;
      if (access == uid) Auth = AUTHORIZATION_LEVEL.MODIFY;
      else if (access.includes(":")) {
        let access_parts = access.split(":");
        if (access_parts[1] == "R") {
          if (access_parts[0] == uid) Auth = AUTHORIZATION_LEVEL.READ;
        } else if (access_parts[1] == "C") {
          if (access_parts[0] == uid) Auth = AUTHORIZATION_LEVEL.COPY;
        } else if (access_parts[1] == "M") {
          if (access_parts[0] == uid) Auth = AUTHORIZATION_LEVEL.MODIFY;
        }
      }
      if (Auth == AUTHORIZATION_LEVEL.MODIFY) return Auth;
      if (highest_access == AUTHORIZATION_LEVEL.COPY && Auth == AUTHORIZATION_LEVEL.READ)
        Auth = AUTHORIZATION_LEVEL.COPY; //keep Copy because it's higher
      highest_access = Auth;
    });
    if (Auth == AUTHORIZATION_LEVEL.NONE) Auth = ShareAll;
    return Auth;
  }
  // "All"
  // "I Own",
  // "Shared by me: Read",
  // "Shared by me: Copy",
  // "Shared by me: Modify",
  // "Shared to me: Read",
  // "Shared to me: Copy",
  // "Shared to me: Modify",
  // "Shared by community: Read",
  // "Shared by community: Copy",
  // "Shared by community: Modify",
  // "Shared to community by me: Read",
  // "Shared to community by me: Copy",
  // "Shared to community by me: Modify",

  classifyAuthorization(guide_infos, uid) {
    //console.log("classifyAuthorization", guide_infos, uid);
    if (guide_infos == null) return guide_infos;
    //let _guide_infos = JSON.parse(JSON.stringify(guide_infos));
    let i_own = false;
    guide_infos.forEach((guide_info_data) => {
      let guide_info = guide_info_data.data;
      guide_info_data.data = this.classifyAuthorizationInfo(guide_info, uid);
    });
    return guide_infos;
  }
  classifyAuthorizationInfo(guide_info, uid) {
    //console.log("classifyAuthorizationInfo", guide_info, uid);
    let guide_info_copy = JSON.parse(JSON.stringify(guide_info));
    let i_own = false;
    // //console.log("guide_info_copy", guide_info_copy);
    guide_info_copy.categories = [];
    //guide_info_copy.accessRight = AUTHORIZATION_LEVEL.NONE;
    if (typeof guide_info_copy.access == "undefined") {
      // //console.log("guide_info_copy", guide_info_copy);
      guide_info_copy.categories.push("All");
      // //console.log("typeof guide_info_copy.access == "undefined");
      return guide_info_copy;
    }
    const access = guide_info_copy.access;
    // //console.log("access", access);

    guide_info_copy.access_summary = "";
    if (access.includes(uid)) {
      guide_info_copy.categories.push("Mine");
      guide_info_copy.access_summary = "Mine";
      i_own = true;
    }
    let highest_access = "None";
    access.forEach((access) => {
      if (access == "Featured") guide_info_copy.categories.push("Featured");
      if (access == "All_R")
        if (guide_info_copy.user_id == uid) {
          guide_info_copy.categories.push("I shared to community: Read");
          guide_info_copy.access_summary = "To community: Read";
        } else {
          guide_info_copy.categories.push("Shared by community: Read");
          guide_info_copy.access_summary = "From community: Read";
        }
      else if (access == "All_C")
        if (guide_info_copy.user_id == uid) {
          guide_info_copy.categories.push("I shared to community: Copy");
          guide_info_copy.access_summary = "To community: Copy";
        } else {
          guide_info_copy.categories.push("Shared by community: Copy");
          guide_info_copy.access_summary = "From community: Copy";
        }
      else if (access == "All_M")
        if (guide_info_copy.user_id == uid) {
          guide_info_copy.categories.push("I shared to community: Modify");
          guide_info_copy.access_summary = "To community: Modify";
        } else {
          guide_info_copy.categories.push("Shared by community: Modify");
          guide_info_copy.access_summary = "From community: Modify";
        }

      if (access.includes(":")) {
        let access_parts = access.split(":");
        if (access_parts[1] == "R") {
          if (access_parts[0] == uid) {
            guide_info_copy.categories.push("Shared to me: Read");
            guide_info_copy.access_summary = "To me: Read";
          } else if (i_own) guide_info_copy.categories.push("I shared to a few: Read"); // this.accessCategory("Shared by me: Read", access_parts[0], guide_info_copy); //
        } else if (access_parts[1] == "C") {
          if (access_parts[0] == uid) {
            guide_info_copy.categories.push("Shared to me: Copy");
            guide_info_copy.access_summary = "To me: Copy";
          } else if (i_own) guide_info_copy.categories.push("I shared to a few: Copy"); //this.accessCategory("Shared by me: Copy", access_parts[0], guide_info_copy);
        } else if (access_parts[1] == "M") {
          if (access_parts[0] == uid) {
            guide_info_copy.categories.push("Shared to me: Modify");
            guide_info_copy.access_summary = "To me: Modify";
          } else if (i_own) guide_info_copy.categories.push("I shared to a few: Modify"); //this.accessCategory("Shared by me: Modify", access_parts[0], guide_info_copy); //
        }
      }
    });
    return guide_info_copy;
  }

  accessCategory(access_type, user, owner, guide_info) {
    if (!(access_type in guide_info.categories.keys)) guide_info.categories.keys[access_type] = [];
    guide_info.categories.keys[access_type].push(user);
  }

  getImageURL(img) {
    let photo = Settings.ImageAssets + "/Icons/place.jpg";
    if (img !== "") {
      if (img.indexOf("http:") === -1) photo = Settings.webSite + Settings.ImageLoc + img;
      else photo = img;
    }
    return photo;
  }

  createNewGuide(guide_id, attributes) {
    let guide_obj = new Guide(guide_id);
    guide_obj.attributes = attributes;
    return guide_obj;
  }

  decodeGuide(guideString) {
    //console.log("Original guide size is:", guideString.length);
    var placesTag = "xyxyxPLACESxyxyx";
    var documentTag = "xyxyxJOURNALxyxyx";
    var scheduleTag = "xyxyxSCHEDULExyxyx";
    var placesStart = guideString.indexOf(placesTag);
    var documentStart = guideString.indexOf(documentTag);
    var scheduleStart = guideString.indexOf(scheduleTag);
    if (placesStart == -1 || documentStart == -1 || scheduleStart == -1) {
      this.display_message("Error in guide:" + placesStart + " " + documentStart + " " + scheduleStart);
      return;
    }
    this.places = new Places();
    this.places.decodePlaces(guideString, placesStart, documentStart, placesTag);
    //console.log("decode places");
    //this.journal = new GuideDocument(guideString, documentStart, scheduleStart, documentTag);
    this.document = "";
    //console.log("decode document:");
    this.schedule = new Schedule();
    this.schedule.decodeSchedule(guideString, scheduleStart, scheduleTag, null);
    //this.schedule.updateSchedule();
    //console.log("decode schedule");

    // if (this.places._placesOBJ == null) {
    //console.log("Error in guide places");
    //   return false;
    // }

    // if (this.guide_document._documentOBJ == null) {
    //console.log("Error in guide document");
    //   return false;
    // }

    // if (this.schedule._scheduleOBJ == null) {
    //console.log("Error in guide schedule");
    //   return false;
    // }

    // //console.log("decodeGuide");
    var attributesString = html_entity_decode(guideString.substring(0, placesStart).trim());
    //console.log("AtributeString:" + attributesString);
    window.attributesOBJ = JSON.parse(attributesString);
    if (Array.isArray(window.attributesOBJ)) this.attributes = window.attributesOBJ[0];
    else this.attributes = window.attributesOBJ;

    if (this.attributes.rating == "-1") this.attributes.rating = 0;
    if (typeof this.attributes.rating === "string") this.attributes.rating = parseFloat(this.attributes.rating);
    if (this.attributes.language == "2") this.attributes.language = "English";
    if (typeof this.attributes.countries == "undefined") this.attributes.countries = [];
    if (typeof this.attributes.cities == "undefined") this.attributes.cities = [];
    //if (typeof this.attributes.access == "undefined")
    this.attributes.access = ["All_C"];
    if (typeof this.attributes.image_m == "undefined") this.attributes.image_m = "";
    if (typeof this.attributes.creator_id == "undefined") this.attributes.creator_id = this.attributes.user_id;

    this.attributes.mod = DateTime.now().toISO({ zone: "utc" });
    this.attributes.created = this.attributes.mod;
    this.attributes.read = this.attributes.mod;
    this.attributes.copy = this.attributes.mod;
    this.attributes.num_ratings = 0;
    this.attributes.times_read = 0;
    this.attributes.times_copied = 0;

    // //console.log("decodeGuide", this.attributes);
    //this.categories = this.places.getCategoriesArray();

    this.categories = this.places.getCategoriesArray(_initial_categories);
    this.places.updatePlaceCategories(this.categories);
    this.groups = this.places.getGroupsArray(_initial_groups);
    this.places.updatePlaceGroups(this.groups);

    if (typeof this.attributes.base_location == "undefined") {
      this.attributes.base_location = { latitude: 0, longitude: 0 };
      if (this.places.placesList.length > 0)
        this.attributes.base_location = {
          latitude: parseFloat(this.places.placesList[0].Lat),
          longitude: parseFloat(this.places.placesList[0].Longi),
        };
      else this.attributes.base_location = { latitude: 0, longitude: 0 };
    } else {
      if (this.attributes.base_location.latitude == 0 && this.attributes.base_location.longitude == 0)
        if (this.places.placesList.length > 0)
          this.attributes.base_location = {
            latitude: parseFloat(this.places.placesList[0].Lat),
            longitude: parseFloat(this.places.placesList[0].Longi),
          };
    }
    return true;
  }

  decodeGuideFb(GuideJson, guideAttributes) {
    this.places = new Places();
    this.places.decodePlaces(null, null, null, null, GuideJson);
    this.schedule = new Schedule();
    this.schedule.decodeSchedule(null, null, null, GuideJson);

    //var attributesString = html_entity_decode(guideString.substring(0, placesStart).trim());

    let _attributes = {};
    this.attributes = new GuideAttributes("New", "New Guide", "", "English", 0, [], []);
    if (guideAttributes == null) {
      if (Array.isArray(GuideJson.attributes)) _attributes = GuideJson.attributes[0];
      else _attributes = GuideJson.attributes;
    } else _attributes = guideAttributes;
    this.attributes.decodeAttributes(_attributes);
    this.attributes.guide_id = this.guide_id;

    //console.log("decodeGuideFb", this.attributes);
    delete this.attributes.name;
    delete this.attributes.shared;
    delete this.attributes.share_all;
    delete this.attributes.group_read;
    delete this.attributes.group_copy;
    delete this.attributes.group_modify;

    this.groups = GuideJson.groups;
    if (this.groups.length > 0) {
      //console.log("typeof this.groups[0]", typeof this.groups[0]);
      if (typeof this.groups[0] == "string") {
        this.groups = this.places.getGroupsArray(_initial_groups);
        this.places.updatePlaceGroups(this.groups);
      }
    }

    this.categories = GuideJson.categories;
    if (this.categories.length > 0) {
      if (typeof this.categories[0] == "string") {
        this.categories = this.places.getCategoriesArray(_initial_categories);
        this.places.updatePlaceCategories(this.categories);
        //console.log("this.categories", this.categories);
      }
    }
    this.chapters = GuideJson.chapters;
    if (this.attributes.rating == "-1") this.attributes.rating = 0;
    if (this.attributes.language == "2") this.attributes.language = "English";
    if (typeof this.attributes.countries == "undefined") this.attributes.countries = [];
    if (typeof this.attributes.cities == "undefined") this.attributes.cities = [];
    //if (typeof GuideJson.journal != "undefined") this.journal = GuideJson.journal;
    if (typeof GuideJson.document != "undefined") this.document = GuideJson.document;
    if (typeof this.attributes.image_m == "undefined") this.attributes.image_m = "";
    if (typeof this.attributes.creator_id == "undefined") this.attributes.creator_id = this.attributes.user_id;
    if (typeof GuideJson.guide_settings != "undefined") this.guide_settings = GuideJson.guide_settings;

    if (typeof this.attributes.base_location == "undefined") {
      if (this.places.placesList.length > 0)
        this.attributes.base_location = {
          latitude: parseFloat(this.places.placesList[0].Lat),
          longitude: parseFloat(this.places.placesList[0].Longi),
        };
      else this.attributes.base_location = { latitude: 0, longitude: 0 };
    } else {
      if (this.attributes.base_location.latitude == 0 && this.attributes.base_location.longitude == 0)
        if (this.places.placesList.length > 0)
          this.attributes.base_location = {
            latitude: parseFloat(this.places.placesList[0].Lat),
            longitude: parseFloat(this.places.placesList[0].Longi),
          };
    }
    if (typeof GuideJson.custom_icons != "undefined") this.custom_icons = GuideJson.custom_icons;
    if (typeof GuideJson.guide_settings === "undefined") this.guide_settings = GuideSettings;
    if (typeof GuideJson.guide_settings.places_list.path_link === "undefined")
      this.guide_settings.places_list.path_link = false;
    if (typeof GuideJson.guide_settings.places_map.path_link === "undefined")
      this.guide_settings.places_map.path_link = false;
    if (typeof GuideJson.guide_settings.places_map.number === "undefined") this.guide_settings.places_map.number = true;
    if (typeof GuideJson.guide_settings.itinerary === "undefined")
      this.guide_settings.itinerary = {
        directions: true,
        info: true,
        photo: false,
        place_menu: true,
      };
    if (typeof GuideJson.guide_settings.places_map_hover === "undefined")
      this.guide_settings.places_map_hover = {
        menu: false,
        place: true,
        info: true,
        event: true,
      };
    // this.places.placesList.forEach((p) => {
    //   p.Info = "";
    //   p.URL = "";
    //   p.Hlink = "";
    // });
    return true;
  }

  // display_message(msg) {
  //   Alert.alert(
  //     "Message",
  //     msg,
  //     [
  //       {
  //         text: "OK",
  //         style: "cancel",
  //       },
  //     ],
  //     { cancelable: false }
  //   );
  // }
  downloadGuide() {
    //console.log(JSON.stringify({ guide_id: this.guide_id }));

    var request = new XMLHttpRequest();
    request.onreadystatechange = (e) => {
      if (request.readyState !== 4) {
        return;
      }

      if (request.status === 200) {
        if (request.responseText != null) {
          //console.log("Guide downloaded");
          this.decodeGuide(request.responseText);
          this.schedule.getPlaces_InSchedule();
        }
      } else {
        //console.warn("error downloading guide");
      }
    };

    request.open("GET", `http://www.liquidguides.com/database/download_GuideSections.php?guide_id=${this.guide_id}`);
    request.send();
  }
  addCategoryOLD(category) {
    this.categories.push(category);
  }

  addCategory(category_name) {
    const new_category = new Category(this.categories, category_name);
    this.categories.push(new_category);
  }

  modifyCategory(oldCategory, newCategory) {
    let index = this.categories.findIndex((x) => x.id == oldCategory.id);
    if (index != -1) {
      this.categories[index].name = newCategory.name;
      this.categories[index].poi_img = newCategory.poi_img;
    }
  }

  deleteCategory(category) {
    //console.log("deleteCategory", category);
    const index = this.categories.findIndex((x) => x.id == category.id);
    //console.log("deleteCategory index:", index);
    if (index == -1) return;
    this.categories.splice(index, 1);
    this.places.deletePlaceCategory(category.id);
  }

  categoryFromID(categoryID) {
    let index = this.categories.findIndex((x) => x.id == categoryID);
    if (index != -1) return this.categories[index];
    index = _initial_categories.findIndex((x) => x.id == categoryID);
    if (index != -1) return _initial_categories[index];
    return null;
  }

  categoryNameFromID(categoryID) {
    let index = this.categories.findIndex((x) => x.id == categoryID);
    if (index != -1) return this.categories[index].name;
    index = _initial_categories.findIndex((x) => x.id == categoryID);
    if (index != -1) return _initial_categories[index].name;
    return "No Category";
  }

  categoryNamesFromIDs(categoryIDs, sep) {
    //console.log("categoryNamesFromIDs", categoryIDs);
    if (!Array.isArray(categoryIDs)) categoryIDs = [categoryIDs];
    let names = "";
    if (sep == null) sep = ";";
    categoryIDs.forEach((id) => {
      let name = "NA";
      let index = this.categories.findIndex((x) => x.id == id);
      if (index !== -1) name = this.categories[index].name;
      else {
        index = _initial_categories.findIndex((x) => x.id == id);
        if (index !== -1) name = _initial_categories[index].name;
      }
      names += names === "" ? name : `${sep} ${name}`;
    });
    return names;
  }

  categoryObjectArrayFromIDArray(categoryIDs) {
    if (!Array.isArray(categoryIDs)) categoryIDs = [categoryIDs];
    let category_array = [];
    categoryIDs.forEach((id) => {
      let index = this.categories.findIndex((x) => x.id == id);
      if (index !== -1) category_array.push(this.categories[index]);
      else {
        index = _initial_categories.findIndex((x) => x.id == id);
        if (index !== -1) category_array.push(_initial_categories[index]);
      }
    });
    return category_array;
  }

  groupFromID(groupID) {
    let index = this.groups.findIndex((x) => x.id == groupID);
    if (index != -1) {
      //if (groupID == "13") //console.log(this.groups[index]);
      return this.groups[index];
    }
    return null;
  }

  groupNameFromID(groupID) {
    let index = this.groups.findIndex((x) => x.id == groupID);
    if (index != -1) return this.groups[index].name;
    return "No Group";
  }

  groupNameArrayFromIDArray(groupIDs) {
    if (!Array.isArray(groupIDs)) groupIDs = [groupIDs];
    let names = [];
    groupIDs.forEach((id) => {
      let name = "NA";
      let index = this.groups.findIndex((x) => x.id == id);
      if (index != -1) names.push(this.groups[index].name);
    });
    return names;
  }

  groupObjectArrayFromIDArray(groupIDs) {
    if (!Array.isArray(groupIDs)) groupIDs = [groupIDs];
    let group_array = [];
    groupIDs.forEach((id) => {
      let name = "NA";
      let index = this.groups.findIndex((x) => x.id == id);
      if (index != -1) group_array.push(this.groups[index]);
    });
    return group_array;
  }

  groupNamesFromIDs(groupIDs, sep) {
    if (!Array.isArray(groupIDs)) groupIDs = [groupIDs];
    //console.log("groupIDs", groupIDs);
    let names = "";
    if (sep == null) sep = ";";
    groupIDs.forEach((id) => {
      let name = "NA";
      let index = this.groups.findIndex((x) => x.id == id);
      if (index != -1) name = this.groups[index].name;
      names += names == "" ? name : `${sep} ${name}`;
    });
    return names;
  }

  addGroup(group_name) {
    const group = new Group(this.groups, group_name);
    //console.log(group, group_name);
    this.groups.push(group);
  }

  modifyGroup(oldGroup, newGroup) {
    let index = this.groups.findIndex((x) => x.id == oldGroup.id);
    if (index != -1) {
      this.groups[index].name = newGroup.name;
      this.groups[index].poi_img = newGroup.poi_img;
    }
  }

  deleteGroup(group) {
    //console.log("deleteGroup", group);
    const index = this.groups.findIndex((x) => x.id == group.id);
    if (index == -1) return;
    this.groups.splice(index, 1);
    //console.log("deleteGroup index", index);
    this.places.deletePlaceGroup(group.id);
  }

  addChapter(chapter_title) {
    let new_chapter = new Chapter(this.chapters, chapter_title);
    this.chapters.push(new_chapter);
  }

  modifyChapter(old_chapter, new_chapter_name) {
    //console.log("modifyChapter", old_chapter);
    const index = this.chapters.findIndex((x) => x.ID == old_chapter.ID);
    //console.log("modifyChapter", index);
    if (index == -1) return;
    this.chapters[index].Title = new_chapter_name;
  }

  replaceChapters(chapters) {
    let newChapters = [];
    chapters.forEach((chapter) => {
      newChapters.push(chapter.value);
    });
    // //console.log("newChapters", newChapters);
    this.chapters = newChapters;
  }

  deleteChapter(chapter_id) {
    // //console.log("deleteChapter", chapter_id);
    const index = this.chapters.findIndex((x) => x.ID == chapter_id);
    // //console.log("deleteChapter2", index);
    if (index == -1) return;
    this.chapters.splice(index, 1);
  }

  addSection(chapter_id, section) {
    //console.log("addSection", chapter_id, section);
    const index = this.chapters.findIndex((x) => x.ID == chapter_id);
    if (index == -1) {
      //console.log("addSection: Chapter not found", chapter_id, section);
      return;
    }
    this.chapters[index].sections.push(section);
    //console.log("addSection: Section added", chapter_id, section);
    //console.log("Added section", this.chapters[index].sections[this.chapters[index].sections.length - 1]);
  }

  modifySection(chapter_id, oldSection, newSection) {
    //console.log("oldSection", oldSection, "newSection", newSection);
    const index = this.chapters.findIndex((x) => x.ID == chapter_id);
    if (index == -1) return;
    //console.log("Found chapter");
    const index2 = this.chapters[index].sections.findIndex((x) => x.ID == oldSection.ID);
    if (index2 == -1) return;
    //console.log("Found section");
    this.chapters[index].sections[index2].Title = newSection.Title;
    this.chapters[index].sections[index2].PlaceIds = newSection.PlaceIds;
    this.chapters[index].sections[index2].Groups = newSection.Groups;
    this.chapters[index].sections[index2].Categories = newSection.Categories;
    this.chapters[index].sections[index2].Info = newSection.Info;
    this.chapters[index].sections[index2].html = newSection.html;
    //console.log("modifySection", this.chapters[index].sections[index2]);
  }

  replaceSections(chapter_id, sections) {
    // //console.log("Add section", chapter_id, section);
    const index = this.chapters.findIndex((x) => x.ID == chapter_id);
    if (index == -1) return;
    let newSections = [];
    sections.forEach((section) => {
      newSections.push(section.value);
    });
    // //console.log("newSections", newSections);
    this.chapters[index].sections = newSections;
    // //console.log("Added section", this.chapters[index].sections[this.chapters[index].sections.length - 1]);
  }

  deleteSection(chapter_id, section) {
    const index = this.chapters.findIndex((x) => x.ID == chapter_id);
    if (index == -1) return;
    const index2 = this.chapters[index].sections.findIndex((x) => x.ID == section.ID);
    // //console.log("deleteSection", index);
    if (index2 == -1) return;
    this.chapters[index].sections.splice(index2, 1);
  }

  findSection(section_id) {
    //console.log("findSection", this.chapters);
    //console.log("section_id", section_id);
    for (let i = 0; i < this.chapters.length; i++) {
      for (let j = 0; j < this.chapters[i].sections.length; j++) {
        if (this.chapters[i].sections[j].ID == section_id) return this.chapters[i].sections[j];
      }
    }
    // this.chapters.forEach((chapter) => {
    //   chapter.forEach((section) => {
    //     if ((section.ID = section_id)) return section;
    //   });
    // });
    return null;
  }

  getDocumentHTML() {
    if (typeof this.document == "undefined" || this.document == "") return this.createGuideHTML();
    return this.document;
  }
  // findSection(chapter_id, section_name) {
  //   const index = this.chapters.findIndex((x) => x.ID == chapter_id);
  //   if (index == -1) return null;
  //   const index2 = this.chapters[index].sections.findIndex((x) => x.Title == section_name);
  //   // //console.log("deleteSection", index);
  //   if (index2 == -1) return null;
  //   return this.chapters[index].sections[index2];
  // }

  getAllGroupsToDisplay() {
    let groupsArray = this.groups;
    let groupsList = [];
    if (groupsArray.length > 0) {
      if (typeof groupsArray[0] != "string") {
        for (let i = 0; i < groupsArray.length; i++) {
          groupsList.push({
            id: groupsArray[i].id,
            name: groupsArray[i].name,
            label: groupsArray[i].name,
            icon: groupsArray[i].poi_img,
            value: groupsArray[i],
          });
        }
        groupsList = groupsList.sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1));
      } else {
        groupsArray = Array.from(new Set(groupsArray)).sort();
        for (let i = 0; i < groupsArray.length; i++) {
          groupsList.push({
            id: i.toString(),
            name: groupsArray[i],
            label: groupsArray[i],
            value: groupsArray[i],
          });
        }
      }
    }
    //console.log("getAllGroupsToDisplay:", groupsList);
    return groupsList;
  }

  getGroupsToDisplay(filteredPlacesList, used) {
    let groupsIDArray = [];
    if (filteredPlacesList == null && used != true) {
      return this.getAllGroupsToDisplay();
    }
    //console.log("this.places.placesList", this.places.placesList);
    if (filteredPlacesList == null && used == true) filteredPlacesList = this.places.placesList;
    let id = 0;
    //console.log("this.placesList", this.placesList);
    for (let i = 0; i < filteredPlacesList.length; i++) {
      for (var j = 0; j < filteredPlacesList[i].Group.length; j++) {
        if (!groupsIDArray.includes(filteredPlacesList[i].Group[j])) groupsIDArray.push(filteredPlacesList[i].Group[j]);
      }
    }

    //console.log("groupsIDArray", groupsIDArray);
    groupsIDArray = Array.from(new Set(groupsIDArray));
    //console.log("groupsIDArray", groupsIDArray);
    let groupsObjArray = this.groupObjectArrayFromIDArray(groupsIDArray);
    //console.log("groupsObjArray", groupsObjArray);
    let groupsList = [];
    for (let i = 0; i < groupsObjArray.length; i++) {
      groupsList.push({
        id: groupsObjArray[i].id,
        name: groupsObjArray[i].name,
        label: groupsObjArray[i].name,
        icon: groupsObjArray[i].poi_img,
        value: groupsObjArray[i],
      });
    }
    groupsList = groupsList.sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1));
    //console.log("getGroupsToDisplay:", groupsList);
    return groupsList;
  }

  getAllCategoriesToDisplay() {
    let categoriesArray = this.categories;
    //console.log("getAllCategoriesToDisplay", categoriesArray);
    let categoriesList = [];
    if (categoriesArray.length > 0) {
      //console.log("categoriesArray[0]", categoriesArray[0]);
      if (typeof categoriesArray[0] != "string") {
        for (let i = 0; i < categoriesArray.length; i++) {
          categoriesList.push({
            id: categoriesArray[i].id,
            name: categoriesArray[i].name,
            label: categoriesArray[i].name,
            icon: categoriesArray[i].poi_img,
            value: categoriesArray[i],
          });
        }
        categoriesList = categoriesList.sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1));
      } else {
        categoriesArray = Array.from(new Set(categoriesArray)).sort();
        for (let i = 0; i < categoriesArray.length; i++) {
          categoriesList.push({
            id: i.toString(),
            name: categoriesArray[i],
            label: categoriesArray[i],
            value: categoriesArray[i],
          });
        }
      }
    }
    //console.log("getAllGroupsToDisplay:", categoriesList);
    return categoriesList;
  }

  getCategoriesToDisplay(filteredPlacesList, used) {
    let categoriesIDArray = [];
    if (filteredPlacesList == null && used !== true) {
      return this.getAllCategoriesToDisplay();
    }
    //console.log("this.places.placesList", this.places.placesList);
    if (filteredPlacesList == null && used === true) filteredPlacesList = this.places.placesList;
    //console.log("this.placesList", this.placesList);
    for (let i = 0; i < filteredPlacesList.length; i++) {
      for (var j = 0; j < filteredPlacesList[i].Category.length; j++) {
        //if (!categoriesIDArray.includes(filteredPlacesList[i].Category[j]))
        categoriesIDArray.push(filteredPlacesList[i].Category[j]);
      }
    }

    //console.log("categoriesIDArray", categoriesIDArray);
    categoriesIDArray = Array.from(new Set(categoriesIDArray));
    //console.log("categoriesIDArray", categoriesIDArray);
    let categoriesObjArray = this.categoryObjectArrayFromIDArray(categoriesIDArray);
    //console.log("categoriesObjArray", categoriesObjArray);
    let categoriesList = [];
    for (let i = 0; i < categoriesObjArray.length; i++) {
      categoriesList.push({
        id: categoriesObjArray[i].id,
        name: categoriesObjArray[i].name,
        label: categoriesObjArray[i].name,
        icon: categoriesObjArray[i].poi_img,
        value: categoriesObjArray[i],
      });
    }
    categoriesList = categoriesList.sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1));
    //console.log("getCategoriesToDisplay:", categoriesList);
    return categoriesList;
  }

  getCategoriesToDisplayOLD(filteredPlacesList, used) {
    let categoriesArray = [];
    if (filteredPlacesList == null && used !== true) {
      categoriesArray = this.categories;
    } else {
      if (used === true) filteredPlacesList = this.places.placesList;
      for (let i = 0; i < filteredPlacesList.length; i++) {
        if (filteredPlacesList[i] == null) continue;
        var cats = filteredPlacesList[i].getCategories();
        for (var j = 0; j < cats.length; ++j) {
          if (cats[j] === "HTML_CONTENT") continue;
          categoriesArray.push(cats[j]);
        }
      }
    }
    categoriesArray.push("New");
    categoriesArray = Array.from(new Set(categoriesArray)).sort();
    let categoriesList = [];
    let id = 1;
    for (let i = 0; i < categoriesArray.length; i++) {
      categoriesList.push({
        id: (id++).toString(),
        name: categoriesArray[i],
        label: categoriesArray[i],
        value: categoriesArray[i],
      });
    }
    //console.log("categoriesList:", categoriesList);
    return categoriesList;
  }

  getChaptersToDisplay() {
    let chaptersList = [];
    let id = 0;
    for (let i = 0; i < this.chapters.length; i++) {
      chaptersList.push({
        id: (id++).toString(),
        name: this.chapters[i].Title,
        label: this.chapters[i].Title,
        value: this.chapters[i],
      });
    }
    //chaptersList.sort((a, b) => (a.label.toUpperCase() > b.label.toUpperCase() ? 1 : -1));
    return chaptersList;
  }

  getSectionsToDisplay(chapter_id) {
    // //console.log("getSectionsToDisplay: chapter_id", chapter_id);
    const index = this.chapters.findIndex((x) => x.ID === chapter_id);
    if (index === -1) return [];
    // //console.log("getSectionsToDisplay", this.chapters[index].sections);
    let sectionsList = [];
    let id = 0;
    for (let i = 0; i < this.chapters[index].sections.length; i++) {
      sectionsList.push({
        id: (id++).toString(),
        name: this.chapters[index].sections[i].Title,
        label: this.chapters[index].sections[i].Title,
        value: this.chapters[index].sections[i],
      });
    }
    //sectionsList.sort((a, b) => (a.label.toUpperCase() > b.label.toUpperCase() ? 1 : -1));
    return sectionsList;
  }

  getChaptersSectionsToDisplay() {
    let sections = [];
    // //console.log(this.chapters);
    for (let j = 0; j < this.chapters.length; j++) {
      let section = { Title: this.chapters[j].Title, ID: this.chapters[j].ID, data: [], index: j + 1 };
      for (let i = 0; i < this.chapters[j].sections.length; i++) {
        let _sec = this.chapters[j].sections[i];
        _sec.Chapter = this.chapters[j].Title;
        _sec.ChapterID = this.chapters[j].ID;
        section.data.push(_sec);
      }
      sections.push(section);
    }
    // //console.log(sections);
    return sections;
  }

  getGroupAndCategoryPlaces(PlaceIds, GroupIDs, CategoryIDs) {
    //console.log(section);
    //console.log("getGroupAndCategoryPlaces", PlaceIds, GroupIDs, CategoryIDs);
    let places = this.places.getPlacesFromIDs(PlaceIds);
    if (GroupIDs.length === 0 && CategoryIDs.length === 0) return places;
    //console.log("getGroupAndCategoryPlaces 1");
    if (GroupIDs.length !== 0 && CategoryIDs.length === 0) {
      //console.log("getGroupAndCategoryPlaces 2");
      for (let i = 0; i < this.places.placesList.length; i++) {
        var place_groups = this.places.placesList[i].getGroups();
        for (let x = 0; x < GroupIDs.length; x++) {
          if (place_groups.includes(GroupIDs[x])) {
            //console.log("getGroupAndCategoryPlaces 3");
            if (!places.includes(this.places.placesList[i])) {
              //console.log("getGroupAndCategoryPlaces 4");
              places.push(this.places.placesList[i]);
              break;
            }
          }
        }
      }
      places = Array.from(new Set(places));
      return places;
    }
    if (GroupIDs.length === 0 && CategoryIDs.length !== 0) {
      for (let i = 0; i < this.places.placesList.length; i++) {
        var place_cats = this.places.placesList[i].getCategories();
        for (let y = 0; y < CategoryIDs.length; y++)
          if (place_cats.includes(CategoryIDs[y])) {
            if (!places.includes(this.places.placesList[i])) {
              // //console.log("place_cats", place_cats);
              places.push(this.places.placesList[i]);
              break;
            }
          }
      }
      places = Array.from(new Set(places));
      return places;
    }
    if (GroupIDs.length !== 0 && CategoryIDs.length !== 0) {
      for (let i = 0; i < this.places.placesList.length; i++) {
        var place_groups = this.places.placesList[i].getGroups();
        for (let x = 0; x < GroupIDs.length; x++) {
          if (place_groups.includes(GroupIDs[x])) {
            var place_cats = this.places.placesList[i].getCategories();
            for (let y = 0; y < CategoryIDs.length; y++)
              if (place_cats.includes(CategoryIDs[y])) {
                if (!places.includes(this.places.placesList[i])) {
                  places.push(this.places.placesList[i]);
                  break;
                }
              }
          }
        }
      }
      places = Array.from(new Set(places));
      return places;
    }
  }

  createMapHtml(placeIDs, GroupIDs, CategoryIDs, list) {
    //console.log("createMapHtml", placeIDs, GroupIDs, CategoryIDs);
    let places = this.getGroupAndCategoryPlaces(placeIDs, GroupIDs, CategoryIDs); //this.places.getPlacesFromIDs(placeIDs);
    let places_string = "";
    //console.log("createMapHtml", places);
    let html_doc = "";
    let path = "";
    let markers = "";
    // //console.log("places.length", places.length);
    let _index = 0;
    for (let m = 0; m < places.length; m++) {
      places_string += places_string == "" ? places[m].ID : "," + places[m].ID;
      let _path = places[m].getPath();
      if (_path == null) {
        if (markers == "")
          markers = `&markers=size:mid%7Ccolor:red%7Clabel:${labelChars[_index]}%7C${places[m].Lat},${places[m].Longi}`;
        else
          markers += `|&markers=size:mid%7Ccolor:red%7Clabel:${labelChars[_index]}%7C${places[m].Lat},${places[m].Longi}`;
        _index += 1;
      } else {
        if (places[m].PType == "Polygon") {
          const points = _path.split("|");
          if (points.length > 2) {
            if (points[1] != points[points.length - 1]) _path += "|" + points[1];
          }
        }
        let path_type = `&path=color:0xff0000ff|weight:2`;
        if (places[m].PType == "Polygon") path_type = "&path=fillcolor:0x0000FF33%7Ccolor:0x0000ff33%|weight:2";
        if (path == "") path = `${path_type}${_path}`;
        else path += `|${_path}`;
      }
    }
    places_string += "||";
    let static_map = `https://maps.googleapis.com/maps/api/staticmap?size=400x300&scale=2&maptype=roadmap${markers}${path}&key=AIzaSyAKxtGIewjOkmnY0FE9jD7hYbsHRVArJJc`;

    if (path != "" || markers != "")
      //html_doc += `<img src="${static_map}" alt="Map" width="500" height="400"></img><br/><br/>`;
      html_doc = `<p style="text-align:center;"><a href="maplink:${places_string}||"><img src="${static_map}" alt="Image" style="width:100%; max-width:700px; height:auto; border-radius:10%"></img></a></p>`;
    if (list == true) {
      for (let j = 0; j < places.length; j++) {
        if (places[j].PType != "Icon") html_doc += `<a href="placemenulink:${places[j].ID}">${places[j].Name}</a><br>`;
      }
      let _index = 0;
      for (let j = 0; j < places.length; j++) {
        if (places[j].PType == "Icon") {
          html_doc += `<a href="placemenulink:${places[j].ID}">${labelChars[_index]}. ${places[j].Name}</a><br>`;
          _index += 1;
        }
      }
    }
    return html_doc;
  }

  getPlacesListHTML(placeIDs, GroupIDs, CategoryIDs) {
    //let places = this.places.getPlacesFromIDs(placeIDs);
    let places = this.getGroupAndCategoryPlaces(placeIDs, GroupIDs, CategoryIDs);
    let html_doc = "<ol>";
    for (let i = 0; i < places.length; i++) {
      html_doc += `<li>${places[i].Name}</li>`;
    }
    html_doc += "</ol>";
    return html_doc;
  }

  getsectionHTML(section) {
    //const _places = this.places.getPlacesFromIDs(section.PlaceIds);
    const places = this.getGroupAndCategoryPlaces(section);
    let html_doc = "";
    let day = -1;
    html_doc += `<p><h3>${section.Title}</h3></p>`;
    html_doc += `<p>${section.Info}</p>`;
    let label = 1;
    let path = "";
    let markers = "";
    let places_string = "";
    // //console.log("places.length", places.length);
    for (let m = 0; m < places.length; m++) {
      places_string += places_string == "" ? places[m].ID : "," + places[m].ID;
      let _path = places[m].getPath();
      if (_path == null) {
        if (markers == "")
          markers = `&markers=size:mid%7Ccolor:red%7Clabel:${label.toString()}%7C${places[m].Lat},${places[m].Longi}`;
        else
          markers += `|&markers=size:mid%7Ccolor:red%7Clabel:${label.toString()}%7C${places[m].Lat},${places[m].Longi}`;
        label += 1;
      } else {
        if (places[m].PType == "Polygon") {
          const points = _path.split("|");
          if (points.length > 2) {
            if (points[1] != points[points.length - 1]) _path += "|" + points[1];
          }
        }
        let path_type = `&path=color:0xff0000ff|weight:2`;
        if (places[m].PType == "Polygon") path_type = "&path=fillcolor:0x0000FF33%7Ccolor:0x0000ff33%|weight:2";
        if (path == "") path = `${path_type}${_path}`;
        else path += `|${_path}`;
      }
    }
    places_string += "||";
    let static_map = `https://maps.googleapis.com/maps/api/staticmap?size=400x300&scale=2&maptype=roadmap${markers}${path}&key=AIzaSyAKxtGIewjOkmnY0FE9jD7hYbsHRVArJJc`;

    if (path != "" || markers != "")
      html_doc += `<a href="maplink:${places_string}||"><img src="${static_map}" alt="Map" style="width:100%; max-width:700px; height:auto; border-radius:10%"></img></a><br/><br/>`;
    html_doc += "<ol>";
    for (let j = 0; j < places.length; j++) {
      if (places[j].PType != "Icon") html_doc += `<a href="placemenulink:${places[j].ID}">${places[j].Name}</a><br>`;
    }
    let _index = 0;
    for (let j = 0; j < places.length; j++) {
      if (places[j].PType == "Icon") {
        html_doc += `<a href="placemenulink:${places[j].ID}">${labelChars[_index]}. ${places[j].Name}${
          places[j].MustSee == true ? "*" : ""
        }</a><br>`;
        _index += 1;
      }
    }

    html_doc += "</ol>";
    html_doc += "</p>";
    //</body></html>`;
    //console.log(html_doc);
    return html_doc;
  }

  isAuthorized(auth, action) {
    if (auth >= action) return true;
    else return false;
    // //console.log(auth, action);
    // if (action == AUTHORIZATION_LEVEL.COPY || action == AUTHORIZATION_LEVEL.MODIFY)
    //   if (auth.includes("Update") || auth.includes("Copy")) return true;
    //   else return false;
    // if (action == "Save")
    //   if (auth.includes("Update")) return true;
    //   else return false;
    // return false;
  }

  getChooseDatesList(ScheduleName) {
    if (ScheduleName == null) ScheduleName = "Main";
    let dates = [];
    let day = -1;
    let id = 1;
    for (let i = 0; i < this.schedule.events.length; i++) {
      const event = this.schedule.events[i];
      if (event.Itinerary != ScheduleName) continue;
      let date = new Date(event.Start);
      date.setHours(0, 0, 0, 0);
      let dateTime = date.getTime();
      if (day != date.getDay()) {
        day = date.getDay();
        const day_string = `${Settings.DAYS[date.getDay()]}, ${
          Settings.MONTHS[date.getMonth()]
        }, ${date.getDate()}, ${date.getFullYear()}`;
        dates.push({
          label: day_string,
          value: dateTime,
          id: (id++).toString(),
        });
      }
    }
    return dates;
  }

  getAllScheduledPlaces(ScheduleName, dateTime) {
    if (ScheduleName == null) ScheduleName = "Main";
    let scheduledPlaces = [];
    for (let i = 0; i < this.schedule.events.length; i++) {
      const event = this.schedule.events[i];
      if (event.Itinerary != ScheduleName) continue;
      if (typeof dateTime != "undefined" && dateTime != null) {
        const date = new Date(event.Start);
        const eventDateTime = date.setHours(0, 0, 0, 0);
        if (eventDateTime != dateTime) continue;
      }
      //console.log(dateTime);
      const places = event.get_places(this.places);
      for (let k = 0; k < places.length; k++) {
        let placeIncluded = scheduledPlaces.find((x) => x.ID == places[k].ID);
        if (!placeIncluded) {
          //place_copy = JSON.parse(JSON.stringify(places[k]));
          places[k].scheduled = [];
          scheduledPlaces.push(places[k]);
        }
        places[k].scheduled.push({ start: event.Start, end: event.End });
      }
    }
    return scheduledPlaces;
  }

  getPlaceSchedule(place) {
    let start_time = null;
    for (let i = 0; i < this.schedule.events.length; i++) {
      const event = this.schedule.events[i];
      const places = event.get_places(this.places);
      for (let k = 0; k < places.length; k++) {
        if (place.ID == places[k].ID) {
          return event.Start;
        }
      }
    }
    return start_time;
  }
  getPlacesinSchedule(dates, scheduleName) {
    //console.log("dates", dates, "scheduleName", scheduleName);
    let placesInSchedule = [];
    for (let i = 0; i < this.schedule.events.length; i++) {
      const event = this.schedule.events[i];
      let dateStart = new Date(event.Start);
      let dateStartTxt = `${dateStart.getDate()}-${dateStart.getMonth()}-${dateStart.getFullYear()}`;
      dateStart.setHours(0, 0, 0, 0);
      let dateStartTime = dateStart.getTime();
      let dateEnd = new Date(event.End);
      dateEnd.setHours(0, 0, 0, 0);
      let dateEndTime = dateStart.getTime();
      for (let j = 0; j < dates.length; j++) {
        if (scheduleName != null) {
          const _itinerary = typeof event.Itinerary == "undefined" ? "Main" : event.Itinerary;
          if (_itinerary != scheduleName) continue;
        }
        let dateFilterTime = dates[j].value;
        //let dateFilterTime = dateFilter.getTime();
        //console.log("Find time match", dateStartTime, dateEndTime, dateFilterTime);
        //console.log("dateStartTime", dateStartTime, "dateFilterTime", dateFilterTime);
        if (
          dateStartTime == dateFilterTime ||
          dateEndTime == dateFilterTime ||
          (dateStartTime < dateFilterTime && dateEndTime > dateFilterTime)
        ) {
          const places = event.get_places(this.places);
          //console.log("Found time match", places.length);
          for (let k = 0; k < places.length; k++) {
            placesInSchedule.push(places[k]);
          }
        }
      }
    }
    return placesInSchedule;
  }

  getPlacesByLabelValue(
    sortBy,
    searchText,
    FilterVisited,
    FilterFavorites,
    DatesFilter,
    PlacesFilter,
    currentLocation,
    GroupsFilter,
    CategoriesFilter,
    section,
    SearchPlacesResults,
    FilterSearched,
    ShowHiddenPlaces,
    ScheduleName,
    FilterNotVisited,
    FilterMustSee,
    SchedulesFilter,
    GroupsHide,
    DistanceFilter,
    user_info,
    showHiddenPlace,
    PlaceTypeFilter
  ) {
    let filteredPlacesList = this.preFilterPlaces(
      searchText,
      FilterVisited,
      FilterFavorites,
      DatesFilter,
      PlacesFilter,
      currentLocation,
      GroupsFilter,
      CategoriesFilter,
      section,
      SearchPlacesResults,
      FilterSearched,
      ShowHiddenPlaces,
      ScheduleName,
      FilterNotVisited,
      FilterMustSee,
      SchedulesFilter,
      GroupsHide,
      DistanceFilter,
      user_info,
      showHiddenPlace,
      PlaceTypeFilter
    )["filteredPlacesList"];
    return this.places.sortPlacesByLabelValue(filteredPlacesList);
  }

  getPlacesToDisplay(
    sortBy,
    searchText,
    FilterVisited,
    FilterFavorites,
    DatesFilter,
    PlacesFilter,
    currentLocation,
    GroupsFilter,
    CategoriesFilter,
    Section,
    SearchPlacesResults,
    FilterSearched,
    ShowHiddenPlaces,
    ScheduleName,
    FilterNotVisited,
    FilterMustSee,
    SchedulesFilter,
    GroupsHide,
    DistanceFilter,
    user_info,
    showHiddenPlace,
    PlaceTypeFilter
  ) {
    let filteredPlacesList = this.preFilterPlaces(
      searchText,
      FilterVisited,
      FilterFavorites,
      DatesFilter,
      PlacesFilter,
      currentLocation,
      GroupsFilter,
      CategoriesFilter,
      Section,
      SearchPlacesResults,
      FilterSearched,
      ShowHiddenPlaces,
      ScheduleName,
      FilterNotVisited,
      FilterMustSee,
      SchedulesFilter,
      GroupsHide,
      DistanceFilter,
      user_info,
      showHiddenPlace,
      PlaceTypeFilter
    )["filteredPlacesList"];
    return this.sortPlaces(filteredPlacesList, sortBy, user_info, currentLocation);
    // switch (sortBy) {
    //   case SortByEnum.NAME:
    //     return this.sortPlacesByName(filteredPlacesList);
    //   case SortByEnum.CATEGORY:
    //     return this.sortPlacesByCategory(filteredPlacesList);
    //   case SortByEnum.GROUP:
    //     return this.sortPlacesByGroup(filteredPlacesList);
    //   case SortByEnum.DISTANCE:
    //     return this.sortPlacesByDistance(filteredPlacesList, currentLocation, user_info.distance_unit);
    //   case SortByEnum.RATING:
    //     return this.sortPlacesByRating(filteredPlacesList);
    //   case SortByEnum.DATE:
    //     return this.sortPlacesByDate(filteredPlacesList);

    //   default:
    //     return this.sortPlacesByName(filteredPlacesList);
    // }
  }

  sortPlaces(filteredPlacesList, sortBy, user_info, currentLocation) {
    switch (sortBy) {
      case SortByEnum.NAME:
        return this.sortPlacesByName(filteredPlacesList);
      case SortByEnum.CATEGORY:
        return this.sortPlacesByCategory(filteredPlacesList);
      case SortByEnum.GROUP:
        return this.sortPlacesByGroup(filteredPlacesList);
      case SortByEnum.DISTANCE:
        return this.sortPlacesByDistance(filteredPlacesList, currentLocation, user_info.distance_unit);
      case SortByEnum.RATING:
        return this.sortPlacesByRating(filteredPlacesList);
      case SortByEnum.DATE:
        return this.sortPlacesByDate(filteredPlacesList);

      default:
        return this.sortPlacesByName(filteredPlacesList);
    }
  }

  sortPlacesByName(filteredPlacesList) {
    let letters = [];
    for (let i = 0; i < filteredPlacesList.length; i++) {
      if (filteredPlacesList[i].Name == null || filteredPlacesList[i].Name == "") continue;
      letters.push(filteredPlacesList[i].Name.charAt(0).toUpperCase());
    }
    letters = Array.from(new Set(letters)).sort();
    let PlacesToDisplay = [];
    //console.log(letters);
    for (let cat_index = 0; cat_index < letters.length; cat_index++) {
      PlacesToDisplay.push({ Title: letters[cat_index], data: [] });
    }
    let id = 0;
    for (let i = 0; i < filteredPlacesList.length; i++) {
      if (filteredPlacesList[i].Name == null || filteredPlacesList[i].Name == "") continue;
      var first_char = filteredPlacesList[i].Name.charAt(0).toUpperCase();
      let section = PlacesToDisplay.find((x) => x.Title == first_char);
      if (section == null) continue;
      section.data.push({
        id: (id++).toString(),
        place: filteredPlacesList[i],
      });
    }
    for (let i = 0; i < PlacesToDisplay.length; i++)
      PlacesToDisplay[i].data.sort((a, b) => (a.place.Name.toUpperCase() < b.place.Name.toUpperCase() ? -1 : 1));
    return PlacesToDisplay;
  }

  sortPlacesByCategory(filteredPlacesList) {
    let categories = this.getCategoriesToDisplay(filteredPlacesList);
    // //console.log(categories);
    let PlacesToDisplay = [];
    for (let cat_index = 0; cat_index < categories.length; cat_index++) {
      PlacesToDisplay.push({ id: categories[cat_index].id, Title: categories[cat_index].name, data: [] });
    }
    let id = 0;
    for (let i = 0; i < filteredPlacesList.length; i++) {
      var place_cats = filteredPlacesList[i].getCategories();
      for (var j = 0; j < place_cats.length; j++) {
        if (place_cats[j] == "HTML_CONTENT") continue;
        let section = PlacesToDisplay.find((x) => x.id == place_cats[j]);
        if (section == null) continue;
        section.data.push({
          id: (id++).toString(),
          place: filteredPlacesList[i],
        });
      }
    }
    for (let i = 0; i < PlacesToDisplay.length; i++)
      PlacesToDisplay[i].data.sort((a, b) => (a.place.Name.toUpperCase() < b.place.Name.toUpperCase() ? -1 : 1));

    return PlacesToDisplay;
  }

  sortPlacesByGroup(filteredPlacesList) {
    let groups = this.getGroupsToDisplay(filteredPlacesList);
    let PlacesToDisplay = [];
    for (let group_index = 0; group_index < groups.length; group_index++) {
      PlacesToDisplay.push({ id: groups[group_index].id, Title: groups[group_index].name, data: [] });
    }
    let id = 0;
    for (let i = 0; i < filteredPlacesList.length; i++) {
      var place_groups = filteredPlacesList[i].getGroups();
      for (var j = 0; j < place_groups.length; j++) {
        if (place_groups[j] == "HTML_CONTENT") continue;
        let section = PlacesToDisplay.find((x) => x.id == place_groups[j]);
        if (section == null) continue;
        section.data.push({
          id: (id++).toString(),
          place: filteredPlacesList[i],
        });
      }
    }
    for (let i = 0; i < PlacesToDisplay.length; i++)
      PlacesToDisplay[i].data.sort((a, b) => (a.place.Name.toUpperCase() < b.place.Name.toUpperCase() ? -1 : 1));
    return PlacesToDisplay;
  }

  fixed(num) {
    if (num === 0) return num;
    else if (num <= 1) return num.toFixed(2);
    else if (num > 10) return num.toFixed();
    else return num.toFixed(1);
  }

  sortPlacesByDistance(filteredPlacesList, currentLocation, distance_unit) {
    for (let i = 0; i < filteredPlacesList.length; i++) {
      let placeLocation = {
        latitude: parseFloat(filteredPlacesList[i].Lat),
        longitude: parseFloat(filteredPlacesList[i].Longi),
      };
      filteredPlacesList[i].Distance = Places.calculateDistance(currentLocation, placeLocation);
    }
    //console.log(filteredPlacesList);
    //filteredPlacesList = filteredPlacesList.sort(this.SortByDistanceMethod);

    let _distances = [
      0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 2, 3, 4, 5, 10, 20, 30, 40, 50, 100, 200, 300, 400, 500, 1000,
      2000, 5000, 10000, 99999999999,
    ];
    let distances = [];
    if (distance_unit === "miles") distance_unit = "mi";
    else distance_unit = "km";
    for (let i = 0; i < _distances.length; i++) {
      if (i < _distances.length - 2) distances.push(`${_distances[i]}-${_distances[i + 1]} ${distance_unit}`);
      else if (i == _distances.length - 2) distances.push(`${this.fixed(_distances[i])}+ ${distance_unit}`);
      //   if (i < _distances.length - 2)
      //   distances.push(
      //     `${_distances[i]}-${_distances[i + 1]} km (${this.fixed(_distances[i] * 0.62137119)}-${this.fixed(
      //       _distances[i + 1] * 0.62137119
      //     )} mi)`
      //   );
      // else if (i == _distances.length - 2)
      //   distances.push(`${this.fixed(_distances[i])}+ km (${this.fixed(_distances[i] * 0.62137119)}+ mi)`);
    }
    //console.log("distances", distances);
    // let distances = [
    //   "0-0.1 Km (0-0.06 mi)",
    //   "0.1-0.2 Km (0.06-0.12 mi)",
    //   "0.2-0.3 Km (0.12-0.19 mi)",
    //   "0.3-0.4 Km (0.19-0.25 mi)",
    //   "0.4-0.5 Km (0.25-0.31 mi)",
    //   "0.5-0.6 Km (0.31-0.37 mi)",
    //   "0.6-0.7 Km (0.37-0.43 mi)",
    //   "0.7-0.8 Km (0.43-0.5 mi)",
    //   "0.8-0.9 Km (0.5-0.56 mi)",
    //   "0.9-1 Km (0.56-0.62 mi)",
    //   "1-2 Km (0.62-1.2 mi)",
    //   "2-3 Km (1.2-1.9 mi)",
    //   "3-4 Km (1.9-2.4 mi)",
    //   "4-5 Km (2.4-3.1 mi)",
    //   "5-10 Km (3.1-6.2 mi)",
    //   "10-20 Km (6.2-12.4 mi)",
    //   "20-30 Km (12.4-18.6 mi)",
    //   "30-50 Km (18.6-31.1 mi)",
    //   "50-100 Km (31.1-62.1 mi)",
    //   "100-1,000 Km (62.1-621.4 mi)",
    //   "1,000+ Km (621.4+ mi)",
    // ];
    let PlacesToDisplay = [];
    for (let distance_index = 0; distance_index < distances.length; distance_index++) {
      PlacesToDisplay.push({ Title: distances[distance_index], data: [] });
    }
    let id = 0;
    let mi_adjust = 1;
    if (distance_unit == "mi") mi_adjust = 0.62137119;
    for (let i = 0; i < filteredPlacesList.length; i++) {
      let section = null;
      let distance = filteredPlacesList[i].Distance * mi_adjust;
      //adjust for distance = 0 place
      if (distance == 0) distance += 0.000001;
      //console.log(distance);
      for (let j = 0; j < _distances.length - 1; j++) {
        if (distance > _distances[j] && distance <= _distances[j + 1]) {
          section = PlacesToDisplay[j];
          break;
        }
      }

      if (section == null) continue;
      section.data.push({
        id: (id++).toString(),
        place: filteredPlacesList[i],
      });
    }

    // for (let i = 0; i < filteredPlacesList.length; i++) {
    //   let section = null;
    //   let distance = filteredPlacesList[i].Distance;
    //   //console.log(distance);
    //   if (distance < 0.25) section = PlacesToDisplay[0];
    //   else if (distance >= 0.25 && distance < 0.5) section = PlacesToDisplay[1];
    //   else if (distance >= 0.5 && distance < 0.75) section = PlacesToDisplay[2];
    //   else if (distance >= 0.75 && distance < 1) section = PlacesToDisplay[3];
    //   else if (distance >= 1 && distance < 2) section = PlacesToDisplay[4];
    //   else if (distance >= 2 && distance < 3) section = PlacesToDisplay[5];
    //   else if (distance >= 3 && distance < 5) section = PlacesToDisplay[6];
    //   else if (distance >= 5 && distance < 10) section = PlacesToDisplay[7];
    //   else if (distance >= 10 && distance < 30) section = PlacesToDisplay[8];
    //   else if (distance >= 30) section = PlacesToDisplay[9];
    //   if (section == null) continue;
    //   section.data.push({
    //     id: (id++).toString(),
    //     place: filteredPlacesList[i],
    //   });
    // }
    let lastWithData = PlacesToDisplay.length - 1;
    for (let i = PlacesToDisplay.length - 1; i >= 0; i--) {
      if (PlacesToDisplay[i].data.length != 0) break;
      lastWithData = i;
    }
    PlacesToDisplay = PlacesToDisplay.splice(0, lastWithData);
    let firstWithData = 0;
    for (let i = 0; i < PlacesToDisplay.length; i++) {
      if (PlacesToDisplay[i].data.length != 0) {
        firstWithData = i;
        break;
      }
    }
    PlacesToDisplay = PlacesToDisplay.splice(firstWithData, PlacesToDisplay.length);
    for (let i = 0; i < PlacesToDisplay.length; i++) {
      let data = PlacesToDisplay[i].data;
      data.sort((a, b) => a.place.Distance - b.place.Distance);
    }
    //console.log(PlacesToDisplay);
    return PlacesToDisplay;
  }

  sortPlacesByRating(filteredPlacesList, currentLocation) {
    //filteredPlacesList = filteredPlacesList.sort(this.SortByDistanceMethod);
    let ratings = ["4-5", "3-4", "2-3", "1-2", "0-1", "Unrated"];
    let PlacesToDisplay = [];
    for (let i = 0; i < ratings.length; i++) {
      PlacesToDisplay.push({ Title: ratings[i], data: [] });
    }
    let id = 0;
    for (let i = 0; i < filteredPlacesList.length; i++) {
      let section = null;
      let rating = filteredPlacesList[i].Rating;
      if (rating == 0) section = PlacesToDisplay[5];
      else if (rating >= 4) section = PlacesToDisplay[0];
      else if (rating >= 3 && rating < 4) section = PlacesToDisplay[1];
      else if (rating >= 2 && rating < 3) section = PlacesToDisplay[2];
      else if (rating >= 1 && rating < 2) section = PlacesToDisplay[3];
      else if (rating < 1 && rating < 2) section = PlacesToDisplay[4];
      if (section == null) continue;
      section.data.push({
        id: (id++).toString(),
        place: filteredPlacesList[i],
      });
    }
    for (let i = 0; i < 5; i++) {
      let data = PlacesToDisplay[i].data;
      data.sort((a, b) => b.place.Rating - a.place.Rating);
    }
    return PlacesToDisplay;
  }

  sortPlacesByDate(filteredPlacesList) {
    let dates = [];
    let dayStr = "";
    let section = null;
    let dayStrArr = null;
    for (let i = 0; i < filteredPlacesList.length; i++) {
      for (let j = 0; j < filteredPlacesList[i].scheduledDates.length; j++) {
        dayStrArr = filteredPlacesList[i].scheduledDates[j].split(":");
        dayStr = dayStrArr[0];
        if (!dates.includes(dayStr)) dates.push(dayStr);
      }
    }
    let PlacesToDisplay = [];
    for (let day_index = 0; day_index < dates.length; day_index++) {
      PlacesToDisplay.push({ Title: dates[day_index], data: [] });
    }
    PlacesToDisplay.push({ Title: "Not Scheduled", data: [] });
    //console.log(PlacesToDisplay);
    let id = 0;
    for (let i = 0; i < filteredPlacesList.length; i++) {
      if (filteredPlacesList[i].scheduledDates.length == 0) {
        section = PlacesToDisplay.find((x) => x.Title == "Not Scheduled");
        section.data.push({
          id: (id++).toString(),
          place: filteredPlacesList[i],
        });
      } else {
        for (let j = 0; j < filteredPlacesList[i].scheduledDates.length; j++) {
          //dayStr = Common.formatDate(filteredPlacesList[i].scheduledDates[j], false);
          dayStrArr = filteredPlacesList[i].scheduledDates[j].split(":");
          dayStr = dayStrArr[0];
          section = PlacesToDisplay.find((x) => x.Title == dayStr);
          if (section == null) continue;
          section.data.push({
            id: (id++).toString(),
            place: filteredPlacesList[i],
          });
        }
      }
    }
    for (let i = 0; i < PlacesToDisplay.length; i++) {
      if (PlacesToDisplay[i].Title == "Not Scheduled") continue;
      let data = PlacesToDisplay[i].data;
      data.sort((a, b) => new Date(a.place.start).getTime() - new Date(b.place.start).getTime());
    }
    return PlacesToDisplay;
  }

  preFilterPlaces(
    searchText,
    FilterVisited,
    FilterFavorites,
    DatesFilter,
    PlacesFilter,
    currentLocation,
    GroupsFilter,
    CategoriesFilter,
    Section,
    SearchPlacesResults,
    FilterSearched,
    ShowHiddenPlaces,
    ScheduleName,
    FilterNotVisited,
    FilterMustSee,
    SchedulesFilter,
    GroupsHide,
    DistanceFilter,
    user_info,
    showHiddenPlace,
    PlaceTypeFilter
    //separatePolylines
  ) {
    //console.log("GroupsFilter", GroupsFilter);
    //if (searchText == "") return this.places.placesList;
    //let polylinePlaces = [];
    //console.log("preFilterPlaces", showHiddenPlace);
    const seachTextCap = searchText.toUpperCase();
    let placesInSchedule = [];
    if (DatesFilter.length != 0) {
      placesInSchedule = this.getPlacesinSchedule(DatesFilter, ScheduleName);
      //console.log("placesInSchedule.length:", placesInSchedule.length);
    }
    let _ScheduledPlaces = [];

    if (SchedulesFilter.length !== 0) {
      for (let s = 0; s < SchedulesFilter.length; s++) {
        _ScheduledPlaces = _ScheduledPlaces.concat(this.getAllScheduledPlaces(SchedulesFilter[s].label));
      }
      //_ScheduledPlaces = Array.from(new Set(_ScheduledPlaces));
      //console.log("_ScheduledPlaces", _ScheduledPlaces);
    }
    let filteredPlacesList = [];
    let allPlaces = this.places.placesList;
    if (Section != null)
      allPlaces = this.getGroupAndCategoryPlaces(Section.PlaceIds, Section.Groups, Section.Categories);

    if (FilterSearched === "only_search") allPlaces = SearchPlacesResults;
    else if (FilterSearched === "all") allPlaces = allPlaces.concat(SearchPlacesResults);
    for (let i = 0; i < allPlaces.length; i++) {
      // if (separatePolylines == true && allPlaces[i].PType != "Icon") {
      //   polylinePlaces.push(allPlaces[i]);
      //   continue;
      // }
      //console.log("showHiddenPlace", showHiddenPlace);
      if (allPlaces[i].Hidden === true && ShowHiddenPlaces === false) continue;
      if (PlacesFilter.length !== 0) {
        if (!PlacesFilter.find((x) => x.ID === allPlaces[i].ID)) continue;
      }
      if (GroupsFilter.length !== 0 || GroupsHide.length !== 0) {
        var place_groups = allPlaces[i].getGroups();
        if (GroupsHide.length !== 0) {
          if (place_groups.some((r) => GroupsHide.indexOf(r) >= 0)) continue;
        }
        if (GroupsFilter.length !== 0) {
          if (!place_groups.some((r) => GroupsFilter.indexOf(r) >= 0)) continue;
        }
      }
      if (CategoriesFilter.length !== 0) {
        var place_cats = allPlaces[i].getCategories();
        //console.log("preFilterPlaces place_cats", place_cats);
        //console.log("place_cats, CategoriesFilter", place_cats, CategoriesFilter);
        if (!place_cats.some((r) => CategoriesFilter.indexOf(r) >= 0)) continue;
      }
      if (DatesFilter.length !== 0) {
        if (!placesInSchedule.find((x) => x.ID == allPlaces[i].ID)) continue;
      }
      if (FilterMustSee === true) if (allPlaces[i].MustSee !== true) continue;
      if (FilterFavorites === true) if (allPlaces[i].Favorite !== true) continue;
      if (FilterVisited === true) if (allPlaces[i].Visited !== true) continue;
      if (FilterNotVisited === true) if (allPlaces[i].Visited === true) continue;
      if (SchedulesFilter.length > 0) {
        if (!_ScheduledPlaces.find((x) => x.ID === allPlaces[i].ID)) continue;
      }
      if (PlaceTypeFilter !== null && typeof PlaceTypeFilter !== "undefined") {
        if (PlaceTypeFilter.length > 0) {
          let found = false;
          for (let m = 0; m < PlaceTypeFilter.length; m++) {
            if (PlaceTypeFilter[m].value === allPlaces[i].PType) {
              found = true;
              break;
            }
          }
          if (found !== true) continue;
        }
      }
      if (seachTextCap !== "") {
        const place_cats_string = this.categoryNamesFromIDs(allPlaces[i].getCategories()).toUpperCase();
        if (place_cats_string.includes(seachTextCap)) {
          filteredPlacesList.push(allPlaces[i]);
          continue;
        }
        //console.log("searchText", seachTextCap, place_cats_string);
        const place_groups_string = this.groupNamesFromIDs(allPlaces[i].getGroups()).toUpperCase();
        if (place_groups_string.includes(seachTextCap)) {
          filteredPlacesList.push(allPlaces[i]);
          continue;
        }
        if (allPlaces[i].Name.toUpperCase().includes(seachTextCap)) {
          filteredPlacesList.push(allPlaces[i]);
          continue;
        }
        continue;
      }
      filteredPlacesList.push(allPlaces[i]);
    }
    //console.log("*******showHiddenPlace", showHiddenPlace);
    for (let m = 0; m < showHiddenPlace.length; m++) {
      const hiddenInList = filteredPlacesList.findIndex((p) => p.ID === showHiddenPlace[m].ID);
      if (hiddenInList === -1) {
        const index = allPlaces.findIndex((p) => p.ID === showHiddenPlace[m].ID);
        if (index !== -1) filteredPlacesList.push(allPlaces[index]);
      }
    }

    //console.log("placesInSchedule", placesInSchedule);
    let polylines = [];
    if (currentLocation != null) {
      if (currentLocation.latitude === 0 && currentLocation.longitude === 0) {
        if (filteredPlacesList.length > 0)
          currentLocation = {
            latitude: parseFloat(filteredPlacesList[0].Lat),
            longitude: parseFloat(filteredPlacesList[0].Longi),
          };
      }
    }
    let allScheduledPlaces = this.getAllScheduledPlaces(ScheduleName);
    for (let i = 0; i < filteredPlacesList.length; i++) {
      //console.log("filteredPlacesList[i].Points", filteredPlacesList[i].Points);
      // A polyline or polygon
      if (filteredPlacesList[i].PType != "Icon") {
        let polyline = Place.getPolyline(filteredPlacesList[i]);
        if (polyline != null) {
          //console.log(polyline);
          polyline.place = filteredPlacesList[i];
          polylines.push(polyline);
        }
      }
      if (currentLocation != null) {
        let placeLocation = {
          latitude: parseFloat(filteredPlacesList[i].Lat),
          longitude: parseFloat(filteredPlacesList[i].Longi),
        };
        filteredPlacesList[i].Distance = Places.calculateDistance(currentLocation, placeLocation);
      }

      filteredPlacesList[i].scheduledDates = [];
      filteredPlacesList[i].inSchedule = false;
      const scheduledPlace = allScheduledPlaces.find((x) => x.ID === filteredPlacesList[i].ID);
      if (scheduledPlace) {
        filteredPlacesList[i].inSchedule = true;
        for (let j = 0; j < scheduledPlace.scheduled.length; j++) {
          const scheduledDate = `${Common.formatDateLuxon(scheduledPlace.scheduled[j].start)}: ${Common.formatTimeLuxon(
            scheduledPlace.scheduled[j].start
          )}-${Common.formatTimeLuxon(scheduledPlace.scheduled[j].end)}`;
          filteredPlacesList[i].scheduledDates.push(scheduledDate);
        }
      }
    }
    if (DistanceFilter > -1) {
      let _filteredPlacesList = [];
      let _polylines = [];
      let miles_adjust = 1;
      if (user_info.distance_unit == "miles") miles_adjust = 0.621371;
      //console.log("DistanceFilter > -1, miles_adjust: ", miles_adjust);
      for (let i = 0; i < filteredPlacesList.length; i++) {
        if (filteredPlacesList[i].Distance <= DistanceFilter / miles_adjust)
          _filteredPlacesList.push(filteredPlacesList[i]);
      }
      for (let i = 0; i < polylines.length; i++) {
        if (polylines[i].Distance <= DistanceFilter * miles_adjust) _polylines.push(polylines[i]);
      }
      //console.log("_filteredPlacesList", _filteredPlacesList);
      return { filteredPlacesList: _filteredPlacesList, polylines: _polylines };
    }
    //console.log("filteredPlacesList", filteredPlacesList);
    return { filteredPlacesList: filteredPlacesList, polylines: polylines };
  }

  createGuideHTML() {
    //console.log("createGuideHTML", this.attributes);
    if (typeof this.attributes == "undefined") return "";
    let html_doc = "";
    const photoTouse = this.attributes.image_m != "" ? this.attributes.image_m : this.attributes.image;
    //if (photoTouse !== null) {
    const img = Common.getImageURL(photoTouse, null, true);
    html_doc += `<p style="text-align:center;"><img src="${img}" alt="Image" style="width:100%; max-width:700px; height:auto; border-radius:10%" ></img></p>`;
    //}
    //console.log("html_doc", html_doc);
    html_doc += `<p><h3 style="text-align:center;">${this.attributes.guide_name}</h3></p>`;
    //console.log("html_doc", html_doc);
    let cur_chapter = "";
    if (typeof this.chapters != "undefined") {
      html_doc += `<ol style="font-size:130%">`;
      for (let i = 0; i < this.chapters.length; i++) {
        if (typeof this.chapters[i].sections == "undefined") continue;
        html_doc += `<li style="background-color: #FFFF00">${this.chapters[i].Title}</li>`;
        html_doc += `<ul style="list-style-type:none">`;
        for (let j = 0; j < this.chapters[i].sections.length; j++) {
          //console.log("this.chapters", `${this.chapters[i].Title},${this.chapters[i].sections[j].Title}`);
          html_doc += `<li><a href="sectionlink:${this.chapters[i].sections[j].ID}">${this.chapters[i].sections[j].Title}</a></li>`;
        }
        html_doc += "</ul>";
      }
      html_doc += "</ol>";
    }
    //console.log("html_doc", html_doc);
    return html_doc;
  }

  createKML() {
    let kml = KMLTemplate;
    let placemarks = "";
    let placemark = "";
    for (let i = 0; i < this.places.placesList.length; i++) {
      let path = this.places.placesList[i].getKMLPath();
      if (path === null) {
        placemark = `
        <Placemark>
            <name>${this.places.placesList[i].Name}</name>
            <styleUrl>#icon-1899-0288D1-nodesc</styleUrl>
            <Point>
              <coordinates>
                ${this.places.placesList[i].Longi},${this.places.placesList[i].Lat},0
              </coordinates>
              <description><![CDATA[${this.places.placesList[i].Info}]]>
              </description>
            </Point>
          </Placemark>`;
      } else {
        placemark = `
        <Placemark>
        <name>Line 1</name>
        <description>
        <![CDATA[${this.places.placesList[i].Info}]]>
        </description>
        <styleUrl>#line-E65100-7000-nodesc</styleUrl>
        <LineString>
          <tessellate>1</tessellate>
          <coordinates>
          ${path}
        </coordinates>
        </LineString>
      </Placemark>
        `;
      }
      placemarks += placemark;
    }
    kml = kml.replace("**KML_NAME**", this.attributes.guide_name);
    kml = kml.replace("**PLACEMARKS**", placemarks);

    return kml;
  }

  createHTMLItinerary(scheduleName, settings) {
    if (typeof settings === "undefined" || settings === null)
      settings = { info: true, photo: true, directions: true, place_menu: true };
    let isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    let font_size = isMobile ? "100%" : "100%";
    let h_size = "h3";
    let last_place = null;
    // if (window.web_app) h_size = "h5";
    if (scheduleName == null) scheduleName = "Main";
    scheduleName = scheduleName.replace(/%20/g, " ");
    // let html_doc = `<div style="font-size: ${font_size};"><p style="text-align:center;"><h2><a href="maplink:|||schedule=${scheduleName}">Schedule: ${scheduleName}</a></h2></p>`;
    let html_doc = `<p style="text-align:center;"><h2><a href="maplink:|||schedule=${scheduleName}">Itinerary: ${scheduleName}</a></h2></p>`;
    //let html_doc = `<p style="text-align:center;"><${h_size}>${scheduleName}</${h_size}></p>`;
    let day = -1;
    let dayNum = 0;

    this.schedule.events = this.schedule.events.sort(
      (a, b) => new Date(a.Start).getTime() - new Date(b.Start).getTime()
    );
    for (let i = 0; i < this.schedule.events.length; i++) {
      const event = this.schedule.events[i];
      const _scheduleName = typeof event.Itinerary == "undefined" ? "Main" : event.Itinerary;
      if (_scheduleName != scheduleName) continue;
      //let startDate = new Date(event.Start);
      var startDate = DateTime.fromISO(event.Start, { zone: "utc" });
      //let endDate = new Date(event.End);
      var endDate = DateTime.fromISO(event.End, { zone: "utc" });
      if (day != startDate.day) {
        dayNum += 1;
        let dateLink = new Date(event.Start);
        dateLink.setHours(0, 0, 0, 0);
        let dateLinkTime = dateLink.getTime();
        html_doc += `<p><h2><a href="maplink:|||day=${dateLinkTime}">Day ${dayNum}: ${Common.formatDateLuxon(
          startDate
        )}</a></h2></p>`;

        //html_doc += `<p><${h_size}>${Common.formatDateLuxon(startDate)}</${h_size}></p>`;
        day = startDate.day;

        let today_dateTime = DateTime.fromISO(event.Start, { zone: "utc" });
        //today_dateTime.setHours(0, 0, 0, 0);
        let dateTime = today_dateTime.valueOf();
        const places = this.getPlacesinSchedule(
          [{ label: Common.formatDateLuxon(startDate), value: dateTime }],
          scheduleName
        );
        //console.log("createHTMLItinerary, places:", places);
        let path = "";
        let markers = "";
        // //console.log("places.length", places.length);
        let places_string = "";
        let _index = 0;
        for (let m = 0; m < places.length; m++) {
          if (typeof places[m].Name === "undefined") continue;
          places_string += places_string == "" ? places[m].ID : "," + places[m].ID;
          let _path = places[m].getPath();
          if (_path == null) {
            if (markers == "")
              markers = `&markers=size:mid%7Ccolor:red%7Clabel:${labelChars[_index]}%7C${places[m].Lat},${places[m].Longi}`;
            else
              markers += `|&markers=size:mid%7Ccolor:red%7Clabel:${labelChars[_index]}%7C${places[m].Lat},${places[m].Longi}`;
            _index += 1;
          } else {
            if (places[m].PType == "Polygon") {
              const points = _path.split(":");
              if (points.length > 2) {
                if (points[1] != points[points.length - 1]) _path += ":" + points[1];
              }
            }
            let path_type = `&path=color:0xff0000ff|weight:2`;
            if (places[m].PType == "Polygon") path_type = "&path=fillcolor:0x0000FF33%7Ccolor:0x0000ff33%|weight:2";
            if (path == "") path = `${path_type}${_path}`;
            else path += `|${_path}`;
          }
        }
        places_string += "||";
        let static_map = `https://maps.googleapis.com/maps/api/staticmap?size=400x300&scale=2&maptype=roadmap${markers}${path}&key=AIzaSyAKxtGIewjOkmnY0FE9jD7hYbsHRVArJJc`;
        //console.log(path, markers);
        if (path != "" || markers != "")
          html_doc += `<a href="maplink:${places_string}||"><img src="${static_map}" alt="Map" style="width:100%; max-width:700px; height:auto; border-radius:10%"></img></a><br/><br/>`;
        // html_doc += `<p><strong>Places today:</strong></p>`;
        if (places.length > 0) html_doc += "<ol>";
        for (let j = 0; j < places.length; j++) {
          if (typeof places[j].Name === "undefined") continue;
          if (places[j].PType != "Icon")
            html_doc += `<a href="placemenulink:${places[j].ID}">${places[j].Name}${
              places[j].MustSee == true ? "*" : ""
            }</a><br>`;
        }
        _index = 0;
        for (let j = 0; j < places.length; j++) {
          if (typeof places[j].Name === "undefined") continue;
          if (places[j].PType == "Icon") {
            html_doc += `<a href="placemenulink:${places[j].ID}">${labelChars[_index]}. ${places[j].Name}${
              places[j].MustSee == true ? "*" : ""
            }</a><br>`;
            _index += 1;
          }
        }
        if (places.length > 0) html_doc += "</ol>";
      }
      const places = event.get_places(this.places);
      if (settings.directions === true) {
        if (last_place !== null) {
          if (places.length > 0 && typeof places[0].Name !== "undefined" && typeof last_place.Name !== "undefined")
            html_doc += `<a href="directionslink:${last_place.ID}|${places[0].ID}">Directions from: ${last_place.Name} to: ${places[0].Name}</a><br>`;
        }
      }
      if (places.length > 0) last_place = places[0];
      html_doc += `<br><strong>${Common.formatTimeLuxon(startDate)}-${Common.formatTimeLuxon(endDate)}</strong>`;
      html_doc += `<p><strong>${event.Subject}</strong></p>`;
      if (event.Body !== "" && typeof event.Body !== "undefined") html_doc += `${event.Body}<br/>`;
      // for (let j = 0; j < places.length; j++) {
      //   html_doc += `<a href="placemenulink:${places[j].ID}"><li>${places[j].Name}</li></a>`;
      // }

      // for (let j = 0; j <div places.length; j++) {
      //   if (places[j].PType != "Icon")
      //     html_doc += `<a href="placemenulink:${places[j].ID}">${places[j].Name}${
      //       places[j].MustSee == true ? "*" : ""
      //     }</a><br>`;
      // }
      if (settings.photo === true) {
        for (let j = 0; j < places.length; j++) {
          if (typeof places[j].Name === "undefined") continue;
          if (places[j].Info !== "" && typeof places[j].Info !== "undefined") html_doc += places[j].Info;
          else if (places[j].Photo_m != "") html_doc += Place.createImageHTML(places[j].Photo_m, null, "medium");
        }
      }
      if (settings.place_menu === true) {
        if (places.length > 0) {
          let _index = 0;
          html_doc += "<br><ol>";
          for (let j = 0; j < places.length; j++) {
            if (typeof places[j].Name === "undefined") continue;
            //if (places[j].PType == "Icon") {
            html_doc += `<a href="placemenulink:${places[j].ID}">${labelChars[_index]}. ${places[j].Name}${
              places[j].MustSee === true ? "*" : ""
            }</a><br>`;
            _index += 1;
            //}
          }
          html_doc += "</ol><br>";
        }
      }
      //html_doc += "</div>";
    }
    //html_doc += "</p>";
    //</body></html>`;
    //console.log(html_doc);
    return html_doc;
  }
}
