import React, { useState, Component } from "react";
import "../SchedulePanel/SchedulePanel.css";
import "./Modal.css";
import { Modal } from "react-bootstrap";
import AlertModal from "./AlertModal";
import PromptModal from "./PromptModal";
import HTMLEditorModal from "./HTMLEditorModal";
import Section from "../../Models/Section";
import MyToolTip from "./MyToolTip";

class SectionDialog extends Component {
  constructor(props) {
    super(props);
    let chapterSections = [];
    if (window.guide_obj != null) chapterSections = window.guide_obj.getChaptersSectionsToDisplay();
    this.state = {
      chapterSections: chapterSections,
      searchText: "",

      AlertModalShow: false,
      AlertModalTitle: "",
      AlertModalMessage: "",
      AlertModalButtons: [],
      ImageDialogShow: false,

      PromptModalShow: false,
      PromptModalTitle: "Enter Value",
      PromptModalMessage: "",
      PromptModalButtons: [],
      PromptModalInputs: [],

      HTMLEditorModalShow: false,
      HTMLEditorModalContent: "",
    };
  }

  MyAlert = (title, message, buttons) => {
    this.setState({
      AlertModalTitle: title,
      AlertModalMessage: message,
      AlertModalButtons: buttons,
      AlertModalShow: true,
    });
  };

  chapterClicked = (chapter) => {
    console.log("sectionHeaderClicked", chapter.Title);
  };

  sectionClicked = (section) => {
    console.log("sectionClicked", section.Title);
    if (this.props.mode === "select") {
      this.props.callback(section);
      this.props.onHide();
    }
  };

  handleSearchChanged = (event, text) => {
    let chapterSections = window.guide_obj.getChaptersSectionsToDisplay();
    let temp_list = [];
    const _searchText = event == null ? text : event.target.value.toUpperCase();
    chapterSections.forEach((chapter) => {
      if (chapter.Title.toUpperCase().includes(_searchText)) {
        temp_list.push(chapter);
        return;
      }
      let _chapter = JSON.parse(JSON.stringify(chapter));
      _chapter.data = [];
      chapter.data.forEach((section) => {
        if (section.Title.toUpperCase().includes(_searchText)) {
          _chapter.data.push(section);
        }
      });
      if (_chapter.data.length !== 0) temp_list.push(_chapter);
    });
    this.setState({ chapterSections: temp_list, searchText: event == null ? text : event.target.value });
  };

  deleteChapter = (chapter) => {
    this.MyAlert("Confirmation", "Confirm Delete Chapter?", [
      { Title: "OK", Method: () => this._deleteChapter(chapter) },
      { Title: "CANCEL", Method: null },
    ]);
  };

  _deleteChapter = (chapter) => {
    window.guide_obj.deleteChapter(chapter.ID);
    this.handleSearchChanged(null, this.state.searchText);
    this.props.update_display();
  };

  _chapter = null;
  addModifyChapter = (add, chapter) => {
    this._chapter = chapter;
    this.setState({
      PromptModalShow: true,
      PromptModalTitle: "Chapter",
      PromptModalMessage: add ? "Enter Chapter Name" : "Edit Chapter Name",
      PromptModalButtons: [
        { Title: "OK", Method: add ? this.AddChapterCallback : this.ModifyChapterCallback },
        { Title: "CANCEL", Method: null },
      ],
      PromptModalInputs: [{ name: "Group Name", value: add ? "" : chapter.Title }],
    });
  };

  ModifyChapterCallback = (inputs) => {
    window.guide_obj.modifyChapter(this._chapter, inputs[0].value);
    this.handleSearchChanged(null, this.state.searchText);
    this.props.update_display();
  };

  AddChapterCallback = (inputs) => {
    window.guide_obj.addChapter(inputs[0].value);
    this.handleSearchChanged(null, this.state.searchText);
    this.props.update_display();
  };

  deleteSection = (chapter, section) => {
    this.MyAlert("Confirmation", "Confirm Delete Section?", [
      { Title: "OK", Method: () => this._deleteSection(chapter, section) },
      { Title: "CANCEL", Method: null },
    ]);
  };

  _deleteSection = (chapter, section) => {
    window.guide_obj.deleteSection(chapter.ID, section);
    this.handleSearchChanged(null, this.state.searchText);
    this.props.update_display();
  };

  _section = null;
  addModifySection = (add, chapter, section) => {
    this._section = section;
    this._chapter = chapter;
    this.setState({
      PromptModalShow: true,
      PromptModalTitle: "Section",
      PromptModalMessage: add ? "Enter Section Name" : "Edit Section Name",
      PromptModalButtons: [
        { Title: "OK", Method: add ? this.AddSectionCallback : this.ModifySectionCallback },
        { Title: "CANCEL", Method: null },
      ],
      PromptModalInputs: [{ name: "Section Name", value: add ? "" : section.Title }],
    });
  };

  ModifySectionCallback = (inputs) => {
    let new_section = JSON.parse(JSON.stringify(this._section));
    new_section.Title = inputs[0].value;
    window.guide_obj.modifySection(this._chapter.ID, this._section, new_section);
    this.handleSearchChanged(null, this.state.searchText);
    this.props.update_display();
  };

  AddSectionCallback = (inputs) => {
    let new_section = new Section(window.guide_obj.chapters, inputs[0].value);
    new_section.Title = inputs[0].value;
    new_section.html = "";
    window.guide_obj.addSection(this._chapter.ID, new_section);
    this.handleSearchChanged(null, this.state.searchText);
    this.props.update_display();
  };

  editSection = (chapter, section) => {
    console.log("editSection", chapter, section);
    this._section = section;
    this._chapter = chapter;
    let html = section.html;
    if (typeof html == "undefined") html = "";
    this.setState({ HTMLEditorModalContent: html, HTMLEditorModalShow: true });
  };

  editSectionCallback = (html) => {
    let new_section = JSON.parse(JSON.stringify(this._section));
    new_section.html = html;
    window.guide_obj.modifySection(this._chapter.ID, this._section, new_section);
  };

  render() {
    if (window.guide_obj == null) return null;
    let chapterSections = window.guide_obj.getChaptersSectionsToDisplay();
    if (this.state.AlertModalShow)
      return (
        <AlertModal
          show={this.state.AlertModalShow}
          onHide={() => this.setState({ AlertModalShow: false })}
          AlertModalTitle={this.state.AlertModalTitle}
          AlertModalMessage={this.state.AlertModalMessage}
          AlertModalButtons={this.state.AlertModalButtons}
        />
      );
    if (this.state.PromptModalShow)
      return (
        <PromptModal
          show={this.state.PromptModalShow}
          onHide={() => this.setState({ PromptModalShow: false })}
          PromptModalTitle={this.state.PromptModalTitle}
          PromptModalMessage={this.state.PromptModalMessage}
          PromptModalButtons={this.state.PromptModalButtons}
          PromptModalInputs={this.state.PromptModalInputs}
        />
      );
    if (this.state.HTMLEditorModalShow)
      return (
        <HTMLEditorModal
          show={this.state.HTMLEditorModalShow}
          onHide={() => this.setState({ HTMLEditorModalShow: false })}
          html={this.state.HTMLEditorModalContent}
          callback={this.editSectionCallback}
          callback_rt={null}
        />
      );

    return (
      <div className="modal">
        <Modal
          {...this.props}
          size={window.innerWidth < 450 ? "lg" : "md"}
          // dialogClassName="myModalClass"
          aria-labelledby="contained-modal-title-vcenter"
          centered={false}
          scrollable={true}
        >
          <Modal.Header closeButton>
            <div style={{ width: "100%" }}>
              <div className="sort">
                <div className="dialog-heading">
                  <div>
                    <h4 className="color-blue">Sections</h4>
                  </div>
                </div>
                <div className="dialog-heading">
                  <input type="text" placeholder="Search" onChange={this.handleSearchChanged} />
                  {this.props.mode === "edit" ? (
                    <i
                      title="Add Chapter"
                      className={`fa fa-plus`}
                      onClick={() => this.addModifyChapter(true, null)}
                    ></i>
                  ) : null}
                </div>
              </div>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                // width: "20%",
                //maxHeight: "100vh",
                overflowY: "auto",
              }}
            >
              {chapterSections.map((chapter, i) => (
                <div key={i}>
                  <div className="items-left">
                    {this.props.mode === "edit" ? (
                      <div key={i}>
                        <i
                          title="Delete Chapter"
                          className={`fa fa-trash-alt`}
                          style={{ color: "red" }}
                          onClick={() => this.deleteChapter(chapter)}
                        ></i>
                        <span> </span>
                        <i
                          title="Change Chapter Name"
                          className={`fa fa-edit color-blue`}
                          onClick={() => this.addModifyChapter(false, chapter)}
                        ></i>
                        <span> </span>
                        <i
                          title="Add Section to chapter"
                          className={`fa fa-plus`}
                          onClick={() => this.addModifySection(true, chapter, null)}
                        ></i>
                        <span>&nbsp;</span>
                        <MyToolTip
                          loc="left"
                          no_div={true}
                          msg="Delete chapter, edit chapter name or add a new section. Chapters are like folders to organize sections. Sections are places to store HTML that can then be displayed."
                        ></MyToolTip>
                        <span className="schedule-date">{chapter.Title}</span>
                      </div>
                    ) : null}
                  </div>

                  {chapter.data.map((section, index) => (
                    <div
                      key={index}
                      className="list-item-card cursor-pointer"
                      onClick={() => this.sectionClicked(section)}
                    >
                      <div className="flex-container">
                        <div className="flex-items-middle truncate" key={index}>
                          {section.Title}
                          {/* <span className="schedule-date truncate">{section.Title}</span> */}
                        </div>
                        {this.props.mode === "edit" ? (
                          <div className="flex-items-right flex-container-right" key={index}>
                            <i
                              title="Delete section"
                              className={`fa fa-trash-alt`}
                              style={{ color: "red" }}
                              onClick={() => this.deleteSection(chapter, section)}
                            ></i>
                            <i
                              title="Change section name"
                              className={`fa fa-edit color-blue`}
                              onClick={() => this.addModifySection(false, chapter, section)}
                            ></i>
                            <i
                              title="Edit section HTML"
                              className={`far fa-file-code`}
                              onClick={() => this.editSection(chapter, section)}
                            ></i>
                            <MyToolTip
                              loc="left"
                              msg="Delete section, edit section name or edit section content (HTML). Sections are places to store HTML that can then be displayed."
                            ></MyToolTip>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </Modal.Body>
        </Modal>
        {/* <AlertModal
          show={this.state.AlertModalShow}
          onHide={() => this.setState({ AlertModalShow: false })}
          AlertModalTitle={this.state.AlertModalTitle}
          AlertModalMessage={this.state.AlertModalMessage}
          AlertModalButtons={this.state.AlertModalButtons}
        />
        <PromptModal
          show={this.state.PromptModalShow}
          onHide={() => this.setState({ PromptModalShow: false })}
          PromptModalTitle={this.state.PromptModalTitle}
          PromptModalMessage={this.state.PromptModalMessage}
          PromptModalButtons={this.state.PromptModalButtons}
          PromptModalInputs={this.state.PromptModalInputs}
        />
        <HTMLEditorModal
          show={this.state.HTMLEditorModalShow}
          onHide={() => this.setState({ ShowHTMLEditorModal: false })}
          html={this.state.HTMLEditorModalContent}
          callback={this.editSectionCallback}
        /> */}
      </div>
    );
  }
}

export default SectionDialog;
