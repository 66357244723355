import { html_entity_decode } from "../Common/Common_d";
import Place, { Place_Source } from "./Place";
import { getDistance, getPreciseDistance } from "geolib";
import { connect } from "react-redux";
import Common, { Settings, SortByEnum, COLORS } from "../Common/Common";
import Group from "./Group";
import Category from "./Category";
import { DateTime } from "luxon";

export default class Places {
  constructor() {
    this.placesList = [];
  }

  decodePlaces(guideString, placesStart, documentStart, placesTag, GuideJson) {
    let _placesOBJ = "";
    if (GuideJson != null) {
      for (let i = 0; i < GuideJson.places.placesList.length; i++) {
        var iplace = new Place(this);
        iplace.json_to_place(GuideJson.places.placesList[i], false);
        this.placesList.push(iplace);
      }
    } else {
      if (placesStart == -1 || documentStart == -1) _placesOBJ = null;
      else {
        //this.subgroup = false;
        var PlacesString = guideString
          .substring(placesStart + placesTag.length, documentStart)
          .replace('\\\\"', '\\"')
          .replace("\\r", "")
          .replace("\\t", "\t")
          .replace("\\n", "\n")
          .replace("\\/", "/")
          .replace("\\ ", '"')
          .replace("\\\n", "\n"); //.replace(/&quot;/g, '"');
        PlacesString = html_entity_decode(PlacesString);
        window.PlacesString = PlacesString;
        _placesOBJ = JSON.parse(PlacesString);
        //console.log("_placesOBJ", _placesOBJ);
        //this.placesList = _placesOBJ;
        //this.all_places = new Array();
        //this.attractions = new Array();
        //this.youtubes = new Array();
        //this.user_places = new Array();
        //this.sortBy = Sort_By_Enum.NAME;
        //this.placesList = new Array();
        for (var key in _placesOBJ) {
          //console.log("var key in _placesOBJ", key);
          //console.log(_placesOBJ[key].Category);
          //console.log(_placesOBJ[key].Group);
          if (_placesOBJ[key].Category.includes("HTML_CONTENT")) continue;
          if (_placesOBJ[key].Group.includes("HTML_CONTENT")) continue;
          var iplace = new Place(this);
          iplace.json_to_place(_placesOBJ[key], true);
          this.placesList.push(iplace);
        }
        //this.createBook();
        //if (guide_obj.cache_data == true) this.loadDataFromStorage();
        //this.combinePlaces();
        //this.Sort_Places();
      }
    }
  }
  static calculateDistance(p1, p2) {
    //console.log("calculateDistance", p1, p2);
    if (p1 == "undefined" || p2 == "undefined") return 0;
    if (p1.latitude == "undefined" || p2.latitude == "undefined") return 0;
    var dis = getDistance(
      { latitude: parseFloat(p1.latitude), longitude: parseFloat(p1.longitude) },
      { latitude: parseFloat(p2.latitude), longitude: parseFloat(p2.longitude) }
    );

    //console.log(p1, p2, dis);
    if (dis == "undefined") return 0;

    //console.log("calculateDistance", dis / 1000);
    return dis / 1000;
  }
  static calculateDistanceOld(p1, p2) {
    //console.log(p1, p2);
    var lat1 = p1.latitude;
    var lon1 = p1.longitude;
    var lat2 = p2.latitude;
    var lon2 = p2.longitude;
    var R = 6371000; // m
    var dLat = (lat2 - lat1).toRad();
    var dLon = (lon2 - lon1).toRad();
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1.toRad()) * Math.cos(lat2.toRad()) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    //console.log(d);
    return d;
  }

  addPlace(place) {
    //console.log("place before", place);
    var new_place = new Place(this);
    place.Source = Place_Source.USER;
    place.ID = new_place.ID;
    //console.log("addPlace PType", place.PType);

    //this.copyPlace(place, new_place);
    //place.getLatlongForPoly();
    try {
      place = Place.getLatlongForPoly(place);
    } catch (e) {
      console.log("Error in getLatlongForPoly");
      return null;
    }
    this.placesList.push(place);
    //console.log("addPlace", place);
    return place.ID;
  }

  modifyPlace(new_place, id) {
    if (id == null) id = new_place.ID;
    const i = this.placesList.findIndex((x) => x.ID == id);
    if (i == -1) return;
    // if (id == null) id = new_place.ID;
    // for (let i = 0; i < this.placesList.length; i++) {
    //   if (this.placesList[i].ID == id) {
    //     //this.copyPlace(new_place, this.placesList[i]);

    this.placesList[i].Name = new_place.Name;
    this.placesList[i].Rating = new_place.Rating;
    this.placesList[i].Lat = new_place.Lat;
    this.placesList[i].Longi = new_place.Longi;
    this.placesList[i].Category = new_place.Category;
    this.placesList[i].Group = new_place.Group;
    this.placesList[i].Address = new_place.Address;
    this.placesList[i].City = new_place.City;
    this.placesList[i].Country = new_place.Country;
    this.placesList[i].Phone = new_place.Phone;
    this.placesList[i].Visited = new_place.Visited;
    this.placesList[i].Favorite = new_place.Favorite;
    this.placesList[i].Email = new_place.Email;
    this.placesList[i].Hlink = new_place.Hlink;
    this.placesList[i].State = new_place.State;
    this.placesList[i].Photo = new_place.Photo;
    if (typeof new_place.Photo_m == "undefined") this.placesList[i].Photo_m = "";
    else this.placesList[i].Photo_m = new_place.Photo_m;
    this.placesList[i].Photo_m = new_place.Photo_m;
    this.placesList[i].Points = new_place.Points;
    this.placesList[i].PType = new_place.PType;
    //console.log("modifyPlace", this.placesList[i]);
    this.placesList[i].Source = new_place.Source;

    this.placesList[i].Hidden = new_place.Hidden;
    this.placesList[i].MustSee = new_place.MustSee;
    this.placesList[i].poi_img = new_place.poi_img;
    this.placesList[i].Info = new_place.Info;

    this.placesList[i].getLatlongForPoly();
    //this.placesList[i].URL = new_place.URL;
    return;
    //   }
    // }
  }

  updatePhoto(new_place, old_place_id) {
    const i = this.placesList.findIndex((x) => x.ID == old_place_id);
    //console.log("updatePhoto", i);
    if (i == -1) return;
    //console.log(`BEFORE: Photo=, ${this.placesList[i].Photo}, Photo_m=, ${this.placesList[i].Photo_m}`);
    if (new_place.Photo !== "" && typeof new_place.Photo != "undefined") this.placesList[i].Photo = new_place.Photo;
    if (new_place.Photo_m === "" || typeof new_place.Photo_m == "undefined")
      this.placesList[i].Photo_m = this.placesList[i].Photo;
    else this.placesList[i].Photo_m = new_place.Photo_m;
    //console.log(`AFTER: Photo=, ${this.placesList[i].Photo}, Photo_m=, ${this.placesList[i].Photo_m}`);
  }

  addToPlace(new_place, old_place_id) {
    const i = this.placesList.findIndex((x) => x.ID == old_place_id);
    if (i == -1) return;
    //console.log("Before", this.placesList[i]);
    // for (let i = 0; i < this.placesList.length; i++) {
    //   if (this.placesList[i].ID == id) {
    //     //this.copyPlace(new_place, this.placesList[i]);

    if (this.placesList[i].Name == "") this.placesList[i].Name = new_place.Name;
    if (this.placesList[i].Rating == "") this.placesList[i].Rating = new_place.Rating;
    this.placesList[i].Lat = new_place.Lat;
    this.placesList[i].Longi = new_place.Longi;
    if (this.placesList[i].Category == []) this.placesList[i].Category = new_place.Category;
    if (this.placesList[i].Group == []) this.placesList[i].Group = new_place.Group;
    if (this.placesList[i].Address == "") this.placesList[i].Address = new_place.Address;
    if (this.placesList[i].City == "") this.placesList[i].City = new_place.City;
    if (this.placesList[i].Country == "") this.placesList[i].Country = new_place.Country;
    if (this.placesList[i].Phone == "") this.placesList[i].Phone = new_place.Phone;
    //if(this.placesList[i].Visited == "") this.placesList[i].Visited = new_place.Visited;
    //if(this.placesList[i].Favorite == "") this.placesList[i].Favorite = new_place.Favorite;
    if (this.placesList[i].Email == "") this.placesList[i].Email = new_place.Email;

    if (new_place.Hlink !== "") {
      if (this.placesList[i].Hlink == "") this.placesList[i].Hlink = new_place.Hlink;
      else {
        this.placesList[i].Hlink += ";" + new_place.Hlink;
      }
    }

    if (this.placesList[i].State == "") this.placesList[i].State = new_place.State;
    if (this.placesList[i].Photo == "") this.placesList[i].Photo = new_place.Photo;
    if (typeof new_place.Photo_m == "undefined") this.placesList[i].Photo_m = "";
    else if (this.placesList[i].Photo_m == "") this.placesList[i].Photo_m = new_place.Photo_m;
    if (this.placesList[i].Photo_m == "") this.placesList[i].Photo_m = new_place.Photo_m;
    if (this.placesList[i].Points == "") this.placesList[i].Points = new_place.Points;
    //if(this.placesList[i].PType == "") this.placesList[i].PType = new_place.PType;
    //console.log("modifyPlace", if(this.placesList[i].Name == "") this.placesList[i]);
    //if(this.placesList[i].Source == "") this.placesList[i].Source = new_place.Source;
    //if(this.placesList[i].Name == "") this.placesList[i].getLatlongForPoly();

    //if(this.placesList[i].Hidden != true) this.placesList[i].Hidden = new_place.Hidden;
    //if(this.placesList[i].MustSee != true) this.placesList[i].MustSee = new_place.MustSee;
    if (this.placesList[i].Info == "") this.placesList[i].Info = new_place.Info;
    if (new_place.Info !== "") {
      if (this.placesList[i].Info == "") this.placesList[i].Info = new_place.Info;
      else {
        this.placesList[i].Info += new_place.Info;
      }
    }
    //if (this.placesList[i].URL == "") this.placesList[i].URL = new_place.URL;
    if (this.placesList[i].poi_img == "") this.placesList[i].poi_img = new_place.poi_img;
    //console.log("After", this.placesList[i]);
    return;
    // }
    // }
  }
  combineWithPlaces(_main_place, info_places) {
    const index = this.placesList.findIndex((x) => x.ID === _main_place.ID);
    if (index === -1) return;
    let main_place = this.placesList[index];
    for (let i = 0; i < info_places.length; i++) {
      if (info_places[i].value === main_place.ID) continue;
      const index2 = this.placesList.findIndex((x) => x.ID === info_places[i].value);
      if (index2 === -1) continue;
      this.combinePlaces(main_place, this.placesList[index2]);
    }
    for (let i = 0; i < info_places.length; i++) {
      if (info_places[i].value === main_place.ID) continue;
      const index3 = this.placesList.findIndex((x) => x.ID === info_places[i].value);
      if (index3 === -1) continue;
      this.placesList.splice(index3, 1);
    }
  }

  combinePlaces(main_place, info_place) {
    if (info_place.Name !== "") main_place.Name = info_place.Name;
    if (info_place.Rating !== "") main_place.Rating = info_place.Rating;
    if (info_place.Category !== []) main_place.Category = info_place.Category;
    if (info_place.Group !== []) main_place.Group = info_place.Group;
    if (info_place.Address !== "") main_place.Address = info_place.Address;
    if (info_place.City !== "") main_place.City = info_place.City;
    if (info_place.Country !== "") main_place.Country = info_place.Country;
    if (info_place.Phone !== "") main_place.Phone = info_place.Phone;

    if (info_place.Email !== "") main_place.Email = info_place.Email;
    //if (info_place.Photo !== "") main_place.Photo = info_place.Photo;
    if (info_place.Photo_m !== "") main_place.Photo_m = info_place.Photo_m;
    if (info_place.Points !== "") main_place.Points = info_place.Points;
    if (info_place.Info !== "") main_place.Info += info_place.Info;
    if (info_place.poi_img !== "") main_place.poi_img = info_place.poi_img;
    if (info_place.Hlink !== "") main_place.Hlink += info_place.Hlink;
    if (info_place.State !== "") main_place.State = info_place.State;
  }

  copyPlace(old_place, new_place) {
    new_place.Name = old_place.Name;
    new_place.Rating = old_place.Rating;
    new_place.Lat = old_place.Lat;
    new_place.Longi = old_place.Longi;
    new_place.Category = old_place.Category;
    new_place.Group = old_place.Group;
    new_place.Address = old_place.Address;
    new_place.City = old_place.City;
    new_place.Country = old_place.Country;
    new_place.Phone = old_place.Phone;
    new_place.Visited = old_place.Visited;
    new_place.Favorite = old_place.Favorite;
    new_place.Email = old_place.Email;
    new_place.Hlink = old_place.Hlink;
    new_place.Photo = old_place.Photo;
    if (typeof old_place.Photo_m == "undefined") new_place.Photo_m = "";
    else new_place.Photo_m = old_place.Photo_m;
    new_place.State = old_place.State;
    new_place.Points = old_place.Points;
    new_place.PType = old_place.PType;
    new_place.Source = old_place.Source;
    new_place.getLatlongForPoly();

    new_place.Hidden = old_place.Hidden;
    new_place.MustSee = old_place.MustSee;
    new_place.poi_img = old_place.poi_img;
    new_place.Info = old_place.Info;
    //new_place.URL = old_place.URL;
  }

  getGroupsArray(initial_groups) {
    //console.log("getGroupsArray Enter");
    let groupsArray = [];
    for (let i = 0; i < this.placesList.length; i++) {
      if (this.placesList[i] == null) continue;
      var groups = this.placesList[i].getGroups();
      for (var j = 0; j < groups.length; j++) {
        if (groups[j] == "HTML_CONTENT") continue;
        groupsArray.push(groups[j]);
      }
    }
    groupsArray = Array.from(new Set(groupsArray)).sort();
    let groupsList = initial_groups;
    for (let k = 0; k < groupsArray.length; k++) {
      let index = groupsList.findIndex((x) => x.name == groupsArray[k]);
      if (index != -1) continue;
      const new_group = new Group(groupsList, groupsArray[k]);
      groupsList.push(new_group);
    }
    groupsList.sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1));
    //console.log(groupsList);
    return groupsList;
  }

  // Note only use for external places where the ID cannot be used
  getSimilarPlaceInGuide(place) {
    for (let i = 0; i < this.placesList.length; i++) {
      const same =
        this.placesList[i].Lat === place.Lat &&
        this.placesList[i].Longi === place.Longi &&
        this.placesList[i].Name === place.Name;
      if (same) return this.placesList[i];
    }
    return null;
  }

  updatePlaceGroups(groupsList) {
    for (let i = 0; i < this.placesList.length; i++) {
      if (this.placesList[i] == null) continue;
      let groups = this.placesList[i].getGroups();
      //console.log("groups", groups);
      let groupIDs = [];
      for (var j = 0; j < groups.length; j++) {
        if (groups[j] == "HTML_CONTENT") continue;
        const index = groupsList.findIndex((x) => x.name == groups[j]);
        if (index != -1) groupIDs.push(groupsList[index].id);
      }
      //console.log("groupIDs", groupIDs);
      this.placesList[i].Group = groupIDs;
    }
  }

  deletePlaceGroup(group_id) {
    for (let i = 0; i < this.placesList.length; i++) {
      if (!Array.isArray(this.placesList[i].Group)) continue;
      this.placesList[i].Group = this.placesList[i].Group.filter((x) => x != group_id);
    }
  }

  getGroupsArrayOLD() {
    let groupsArray = [];
    //console.log("this.placesList", this.placesList);
    for (let i = 0; i < this.placesList.length; i++) {
      if (this.placesList[i] == null) continue;
      var groups = this.placesList[i].getGroups();
      for (var j = 0; j < groups.length; j++) {
        if (groups[j] == "HTML_CONTENT") continue;
        groupsArray.push(groups[j]);
      }
    }
    groupsArray = Array.from(new Set(groupsArray)).sort();
    return groupsArray;
  }

  // createPlaceGroups() {
  //   let groupIDs = [];
  //   for (let i = 0; i < this.places.placesList.length; i++) {
  //     if (this.places.placesList[i] == null) continue;
  //     let groups = this.places.placesList[i].getGroups();
  //     groupIDs = [];
  //     for (var j = 0; j < groups.length; j++) {
  //       if (groups[j] == "HTML_CONTENT") continue;
  //       const index = this.groupsList.findIndex((x) => x.name == groups[j]);
  //       if (index != -1) groupIDs.push(this.groupsList[index].name);
  //     }
  //   }
  //   this.places.placesList[i].Groups = groupIDs;
  // }

  // createPlaceGroups() {
  //   let groupIDs = [];
  //   for (let i = 0; i < this.placesList.length; i++) {
  //     if (this.placesList[i] == null) continue;
  //     let groups = this.placesList[i].getGroups();
  //     groupIDs = [];
  //     for (var j = 0; j < groups.length; j++) {
  //       if (groups[j] == "HTML_CONTENT") continue;
  //       const index = this.groupsList.findIndex((x) => x.name == groups[j]);
  //       if (index != -1) groupIDs.push(this.groupsList[index].name);
  //     }
  //   }
  //   this.placesList[i].Group = groupIDs;
  // }

  getCategoriesArray(initial_categories) {
    //console.log("getCategoriesArray this.placesList.length", this.placesList.length);
    let categoriesArray = [];
    for (let i = 0; i < this.placesList.length; i++) {
      //console.log("getCategoriesArray for loop");
      if (this.placesList[i] == null) continue;
      var categories = this.placesList[i].getCategories();
      //console.log("getCategoriesArray categories", categories);
      for (var j = 0; j < categories.length; j++) {
        if (categories[j] == "HTML_CONTENT") continue;
        categoriesArray.push(categories[j]);
      }
    }
    categoriesArray = Array.from(new Set(categoriesArray)).sort();
    //console.log("getCategoriesArray categoriesArray", categoriesArray);
    let categoriesList = initial_categories;
    //console.log(categoriesList);
    for (let k = 0; k < categoriesArray.length; k++) {
      let index = categoriesList.findIndex((x) => x.name == categoriesArray[k]);
      if (index != -1) continue;
      const new_category = new Category(categoriesList, categoriesArray[k]);
      categoriesList.push(new_category);
    }
    categoriesList.sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1));
    //console.log("getCategoriesArray categoriesList", categoriesList);
    return categoriesList;
  }

  updatePlaceCategories(categoriesList) {
    //console.log("updatePlaceCategories this.placesList", this.placesList);
    for (let i = 0; i < this.placesList.length; i++) {
      if (this.placesList[i] == null) continue;
      let categories = this.placesList[i].getCategories();
      //console.log("categories", categories);
      let categoryIDs = [];
      for (var j = 0; j < categories.length; j++) {
        if (categories[j] == "HTML_CONTENT") continue;
        const index = categoriesList.findIndex((x) => x.name == categories[j]);
        if (index != -1) categoryIDs.push(categoriesList[index].id);
      }
      //console.log("categoryIDs", categoryIDs);
      this.placesList[i].Category = categoryIDs;
    }
  }

  deletePlaceCategory(category_id) {
    for (let i = 0; i < this.placesList.length; i++) {
      if (!Array.isArray(this.placesList[i].Category)) continue;
      this.placesList[i].Category = this.placesList[i].Category.filter((x) => x != category_id);
    }
  }

  getCategoriesArrayOLD() {
    let filteredPlacesList = this.placesList;
    let categoriesArray = [];
    let id = 0;
    for (let i = 0; i < filteredPlacesList.length; i++) {
      if (filteredPlacesList[i] == null) continue;
      var cats = filteredPlacesList[i].getCategories();
      for (var j = 0; j < cats.length; ++j) {
        if (cats[j] == "HTML_CONTENT") continue;
        categoriesArray.push(cats[j]);
      }
    }
    categoriesArray = Array.from(new Set(categoriesArray)).sort();
    return categoriesArray;
  }

  setPlaceAttributes(all_places, places, attribute) {
    // for (let i = 0; i < this.placesList.length; i++) {
    //   const j = places.findIndex((x) => x.value == this.placesList[i].ID);
    //   this.placesList[i][attribute] = j === -1 ? false : true;
    //   const k = places.findIndex((x) => x.value == this.placesList[i].ID);
    //   this.placesList[i][attribute] = k === -1 ? false : true;
    // }

    // turn all to false
    for (let i = 0; i < all_places.length; i++) {
      const place = this.getPlace(all_places[i].value.ID);
      if (place !== -1) place[attribute] = false;
    }
    // turn some to true
    for (let i = 0; i < places.length; i++) {
      const place = this.getPlace(places[i].value.ID);
      if (place !== -1) place[attribute] = true;
    }
  }

  getPlacesToDisplay(filteredPlacesList, type) {
    let ptype = "all";
    if (typeof type !== "undefined" && type !== null) ptype = type;
    if (filteredPlacesList == null) filteredPlacesList = this.placesList;
    let placesList = [];
    let id = 0;
    for (let i = 0; i < filteredPlacesList.length; i++) {
      if (filteredPlacesList[i] == null || typeof filteredPlacesList[i].Name === "undefined") continue;
      if (ptype !== "all" && filteredPlacesList[i].PType !== ptype) continue;
      placesList.push({
        id: (id++).toString(),
        name: filteredPlacesList[i].Name,
        label: filteredPlacesList[i].Name,
        value: filteredPlacesList[i].ID,
        Favorite: filteredPlacesList[i].Favorite,
        Visited: filteredPlacesList[i].Visited,
        MustSee: filteredPlacesList[i].MustSee,
        Hidden: filteredPlacesList[i].Hidden,
      });
    }
    placesList.sort((a, b) => (a.label.toUpperCase() > b.label.toUpperCase() ? 1 : -1));
    return placesList;
  }

  togglePlaceAsFavorite(place) {
    for (let i = 0; i < this.placesList.length; i++) {
      if (this.placesList[i].ID == place.ID) {
        //console.log("this.placesList[i].Favorite", this.placesList[i].Favorite);
        this.placesList[i].Favorite = !this.placesList[i].Favorite;
        //console.log("this.placesList[i].Favorite", this.placesList[i].Favorite);
        return;
      }
    }
  }

  togglePlaceAsVisited(place) {
    for (let i = 0; i < this.placesList.length; i++) {
      if (this.placesList[i].ID == place.ID) {
        //console.log("this.placesList[i].Visited", this.placesList[i].Visited);
        this.placesList[i].Visited = !this.placesList[i].Visited;
        //console.log("this.placesList[i].Visited", this.placesList[i].Visited);
        return;
      }
    }
  }

  getChoosePlacesList(hide_hidden) {
    let ChoosePlacesList = [];
    for (let i = 0; i < this.placesList.length; i++) {
      var place_cats = this.placesList[i].getCategories();
      if (place_cats.includes("HTML_CONTENT")) continue;
      if (hide_hidden === true && this.placesList[i].Hidden === true) continue;
      ChoosePlacesList.push({
        label: this.placesList[i].Name,
        value: this.placesList[i],
      });
    }
    ChoosePlacesList.sort((a, b) => (a.label.toUpperCase() > b.label.toUpperCase() ? 1 : -1));
    return ChoosePlacesList;
  }

  getImageList() {
    let imagesList = [];
    let id = 1;
    for (let i = 0; i < this.placesList.length; i++) {
      let photo = this.placesList[i].Photo;
      let photo_m = this.placesList[i].Photo_m;
      if (photo == "" && photo_m == "") continue;
      if (photo == "") photo = photo_m;
      if (photo_m == "") photo_m = photo;
      const index = imagesList.findIndex((i) => i.url === photo);
      if (index !== -1) continue;
      imagesList.push({
        name: this.placesList[i].Name,
        url: photo,
        url_m: photo_m,
        id: (id++).toString(),
        folder: "",
        date: DateTime.now().toISO({ zone: "utc" }),
        exif_data: {},
        type: 0,
        selected: false,
      });
    }
    imagesList.sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1));
    return imagesList;
  }

  getChooseGroupsList() {
    let ChooseGroupsList = [];
    for (let i = 0; i < this.placesList.length; i++) {
      var place_cats = this.placesList[i].getCategories();
      if (place_cats.includes("HTML_CONTENT")) continue;
      ChooseGroupsList.push({
        label: this.placesList[i].Name,
        value: this.placesList[i],
      });
    }
    ChooseGroupsList.sort((a, b) => (a.label.toUpperCase() > b.label.toUpperCase() ? 1 : -1));
    return ChooseGroupsList;
  }

  sortPlacesByLabelValue(filteredPlacesList) {
    // filteredPlacesList.forEach((p) => {
    //   if (typeof p.Name == "undefined")//console.log(p);
    // });
    let PlacesByLabelValue = [];
    let sortedplaces = filteredPlacesList.sort((a, b) => (a.Name.toUpperCase() > b.Name.toUpperCase() ? 1 : -1));
    for (let i = 0; i < sortedplaces.length; i++) {
      if (sortedplaces[i].Name == null || sortedplaces[i].Name == "") continue;
      PlacesByLabelValue.push({
        label: sortedplaces[i].Name,
        value: sortedplaces[i],
      });
    }
    return PlacesByLabelValue;
  }

  SortPlacesByRating(b, a) {
    return b - a;
  }

  SortPlacesByDistance(b, a) {
    return a.Distance < b.Distance ? -1 : a.Distance > b.Distance ? 1 : 0;
  }

  SortPlacesByRating(b, a) {
    return b - a;
  }

  deletePlace(place) {
    for (let i = 0; i < this.placesList.length; i++) {
      if (this.placesList[i].ID == place.ID) {
        this.placesList.splice(i, 1);
        return;
      }
    }
  }

  deletePlaceById(place_id) {
    for (let i = 0; i < this.placesList.length; i++) {
      if (this.placesList[i].ID === place_id) {
        this.placesList.splice(i, 1);
        return;
      }
    }
  }

  getMarkersToDisplay(SearchText, FilterVisited, FilterFavorites, DatesFilter, PlacesFilter) {
    let placesInSchedule = [];
    if (DatesFilter.length != 0) {
      placesInSchedule = this.getPlacesinSchedule(DatesFilter);
      //console.log("placesInSchedule.length:", placesInSchedule.length);
    }
    let MarkersToDisplay = [];
    for (let i = 0; i < this.placesList.length; i++) {
      if (this.placesList[i].Category == "HTML_CONTENT") continue;
      let placeMarker = {
        latitude: parseFloat(this.placesList[i].Lat),
        longitude: parseFloat(this.placesList[i].Longi),
        place: this.placesList[i],
        key: (i + 1).toString(),
      };
      MarkersToDisplay.push(placeMarker);
    }
    return MarkersToDisplay;
  }

  getMarkersToDisplayOld() {
    let MarkersToDisplay = [];
    for (let i = 0; i < this.placesList.length; i++) {
      if (this.placesList[i].Category == "HTML_CONTENT") continue;
      let placeMarker = {
        latitude: parseFloat(this.placesList[i].Lat),
        longitude: parseFloat(this.placesList[i].Longi),
        place: this.placesList[i],
        key: (i + 1).toString(),
      };
      MarkersToDisplay.push(placeMarker);
    }
    return MarkersToDisplay;
  }

  getWeightedCenter(places, place) {
    let allPlaces = this.placesList;
    if (places != null && places.length != 0) allPlaces = places;
    if (place != null) allPlaces = [place];
    let totalLat = 0;
    let totalLon = 0;
    for (let i = 0; i < allPlaces.length; i++) {
      if (allPlaces[i].Category == "HTML_CONTENT") continue;
      const latlongs = allPlaces[i].getLatLongsForPlace();
      latlongs.forEach((latlong) => {
        const lat = parseFloat(latlong.Lat);
        const lon = parseFloat(latlong.Longi);
        totalLat += lat;
        totalLon += lon;
      });
    }
    return { lat: totalLat / allPlaces.length, lon: totalLon / allPlaces.length };
  }

  getMaxMinLatLon(places, place) {
    //console.log("getMaxMinLatLon", places, place);
    let allPlaces = this.placesList;
    if (places != null && places.length != 0) allPlaces = places;
    if (place != null) allPlaces = [place];
    let min_lat = 90;
    let min_lon = 180;
    let max_lat = -90;
    let max_lon = -180;
    for (let i = 0; i < allPlaces.length; i++) {
      if (allPlaces[i].Category == "HTML_CONTENT") continue;
      //if (allPlaces[i].Hidden == true) continue;
      const latlongs = Place.getLatLongsForPlace(allPlaces[i]);
      latlongs.forEach((latlong) => {
        const lat = parseFloat(latlong.Lat);
        const lon = parseFloat(latlong.Longi);
        if (lat < min_lat) min_lat = lat;
        if (lat > max_lat) max_lat = lat;
        if (lon < min_lon) min_lon = lon;
        if (lon > max_lon) max_lon = lon;
      });
    }
    // This adjusts the default zoom in map
    const delta = 0.0006;
    if (max_lat == min_lat) {
      min_lat -= delta;
      min_lon -= delta;
      max_lat += delta;
      max_lon += delta;
    }
    //console.log("max_lat:", max_lat, "max_lon:", max_lon, "min_lat:", min_lat, "min_lon:", min_lon);
    const max_mins = { max_lat: max_lat, max_lon: max_lon, min_lat: min_lat, min_lon: min_lon };
    return max_mins;
  }

  getMapCorners(places, delta, place, boundary) {
    let _places = [];
    places.forEach((_place) => {
      _places.push(_place);
    });
    //console.log(_places);
    const max_mins = this.getMaxMinLatLon(_places, null);
    const markers = [
      { latitude: max_mins.max_lat, longitude: max_mins.max_lon },
      { latitude: max_mins.max_lat, longitude: max_mins.min_lon },
      { latitude: max_mins.min_lat, longitude: max_mins.min_lon },
      { latitude: max_mins.min_lat, longitude: max_mins.max_lon },
    ];
    return markers;
  }

  getMapRegion(places, delta, place) {
    const max_mins = this.getMaxMinLatLon(places, place);
    let MapRegion = {
      latitude: max_mins.min_lat + (max_mins.max_lat - max_mins.min_lat) / 2,
      longitude: max_mins.min_lon + (max_mins.max_lon - max_mins.min_lon) / 2,
      latitudeDelta: delta != null ? delta : Math.abs(max_mins.max_lat - max_mins.min_lat) + 0.02,
      longitudeDelta: delta != null ? delta : Math.abs(max_mins.max_lon - max_mins.min_lon) + 0.02,
    };
    MapRegion.latitudeDelta = MapRegion.latitudeDelta; //+ 5 * (MapRegion.latitudeDelta / 50);
    MapRegion.longitudeDelta = MapRegion.longitudeDelta; //+ 5 * (MapRegion.longitudeDelta / 50);
    //console.log("MapRegion", MapRegion);
    return MapRegion;
  }

  getMapRegionFromBoundary(MapBoundaries) {
    let deltaLat = Math.abs(MapBoundaries.northEast.latitude - MapBoundaries.southWest.latitude);
    let deltaLon = Math.abs(MapBoundaries.northEast.longitude - MapBoundaries.southWest.longitude);
    let centerLat = (MapBoundaries.southWest.latitude + MapBoundaries.northEast.latitude) / 2;
    let centerLon = (MapBoundaries.southWest.longitude + MapBoundaries.northEast.longitude) / 2;

    const MapRegion = {
      latitude: centerLat,
      longitude: centerLon,
      latitudeDelta: deltaLat,
      longitudeDelta: deltaLon,
    };

    //console.log("getMapRegionFromBoundary", MapBoundaries, MapRegion);
    return MapRegion;
  }

  getCenterOfMapFromBoundary(MapBoundaries) {
    if (MapBoundaries == {}) return { latitude: 0, longitude: 0 };
    let deltaLat = Math.abs(Math.min(0.25, MapBoundaries.northEast.latitude - MapBoundaries.southWest.latitude)) / 2;
    let deltaLon = Math.abs(Math.min(0.18, MapBoundaries.northEast.longitude - MapBoundaries.southWest.longitude)) / 2;

    let centerLat = (MapBoundaries.northEast.latitude + MapBoundaries.southWest.latitude) / 2;
    let centerLon = (MapBoundaries.northEast.longitude + MapBoundaries.southWest.longitude) / 2;
    return { latitude: centerLat, longitude: centerLon };
  }

  getMapBoundary(places, delta, place) {
    const max_mins = this.getMaxMinLatLon(places, place);
    const mapBoundary = {
      northEast: { latitude: max_mins.max_lat, longitude: max_mins.max_lon },
      southWest: { latitude: max_mins.min_lat, longitude: max_mins.min_lon },
    };
    return mapBoundary;
  }

  getPlace(placeID, otherList) {
    //console.log("getPlace", placeID);
    for (let i = 0; i < this.placesList.length; i++) {
      var obj = this.placesList[i];
      if (obj === "undefined") continue;
      if (obj.ID === placeID) return obj;
    }
    if (typeof otherList !== "undefined")
      for (let i = 0; i < otherList.length; i++) {
        var obj = otherList[i];
        if (obj === "undefined") continue;
        if (obj.ID === placeID) return obj;
      }
    return null;
  }

  getPlaces() {
    let places = [];
    for (let j = 0; j < this.placesList.length; j++) {
      places.push(this.placesList[j]);
    }
    return places;
  }

  getPlacesFromIDs(IDs) {
    //console.log("getPlacesFromIDs");
    let places = [];
    for (let i = 0; i < IDs.length; i++) {
      for (let j = 0; j < this.placesList.length; j++) {
        if (this.placesList[j].ID == IDs[i]) places.push(this.placesList[j]);
      }
    }
    return places;
  }

  getPlacesFromName(name) {
    for (let j = 0; j < this.placesList.length; j++) {
      if (this.placesList[j].Name === name) return this.placesList[j];
    }
    return null;
  }

  getPlaceIndex(placeID) {
    if (placeID == null) return -1;
    for (var i = 0; i < this.placesList.length; i++) {
      var obj = this.placesList[i];
      if (obj.place().ID === placeID) return i;
    }
    return -1;
  }
}

var Sort_By_Enum = { NAME: 0, CATEGORY: 1, GROUP: 2, RATING: 3, DISTANCE: 4, ZONE: 5, LEVEL: 6 };
