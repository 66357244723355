import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import "./Modal.css";
import { useDispatch } from "react-redux";
import { slice1Actions } from "../../store/reducer";
import { useSelector } from "react-redux";

const PromptModal = (props) => {
  const dispatch = useDispatch();
  const [Inputs, SetInputs] = useState([]);

  useEffect(() => {
    SetInputs(props.PromptModalInputs);
  }, [props.PromptModalInputs]);

  const handleButtonClick = (button) => {
    if (button.Method != null) button.Method(Inputs);
    props.onHide();
  };

  const handleInputChanged = (e, input) => {
    const index = Inputs.findIndex((x) => (x.name = input.name));
    if (index === -1) return;
    Inputs[index].value = e.target.value;
    SetInputs(Inputs);
    //console.log(Inputs);
  };

  const onKeyPress = (event) => {
    if (event.charCode === 13) {
      props.PromptModalButtons.forEach((button) => {
        if (button.Title === "OK") handleButtonClick(button);
      });
    }
  };
  //console.log("Inputs", Inputs);

  return (
    <div className="modal">
      <Modal
        {...props}
        size="sm"
        dialogClassName="myModalClass"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
        // style={{ padding: "50px" }}
        onHide={() => props.onHide()}
      >
        <Modal.Body>
          <div className="sort">
            <h4 style={{ color: "#3598DB" }}>{props.PromptModalTitle}</h4>
            {/* <h6 style={{ color: "black" }}>
              {props.message}
            </h6> */}

            <p>{props.PromptModalMessage}</p>
            {Inputs.map((input, i) => (
              <div key={i} className="form-item">
                {/* <Form.Group>
                  <Form.Control
                    ref={(input) => input && input.focus()}
                    className="form-item"
                    as={props.area === true ? "textarea" : "text"}
                    // type="text"
                    value={input.value}
                    onChange={(e) => handleInputChanged(e, input)}
                    style={{ width: "250px", height: props.area === true ? "150px" : "50px" }}
                    //onKeyPress={onKeyPress}
                  />
                </Form.Group> */}
                {props.area ? (
                  <textarea
                    ref={(input) => input && input.focus()}
                    multiline={props.area}
                    rows={props.area === true ? 15 : 1}
                    className="form-item"
                    //type={"text"}
                    placeholder="Enter Text"
                    style={{ width: "250px" }}
                    defaultValue={input.value}
                    onChange={(e) => handleInputChanged(e, input)}
                    onKeyPress={onKeyPress}
                  ></textarea>
                ) : (
                  <input
                    ref={(input) => input && input.focus()}
                    className="form-item"
                    type="text"
                    placeholder="Enter Text"
                    style={{ width: "250px" }}
                    defaultValue={input.value}
                    onChange={(e) => handleInputChanged(e, input)}
                    onKeyPress={onKeyPress}
                  ></input>
                )}
              </div>
            ))}
            {props.PromptModalButtons.map((button, i) => (
              <Button
                key={i}
                variant="primary"
                style={{ width: "200px" }}
                className="my-2"
                onClick={() => handleButtonClick(button)}
              >
                {button.Title}
              </Button>
            ))}
            {/* <p>Change Start date</p>
            <p>Move one day</p>
            <p>Add one day</p>
            <p>Delete Schedule</p>
            <p>Rename Schedule</p>
            <p>Edit Schedule</p> */}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default PromptModal;
