import React, { useState } from "react";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import "./Modal.css";
import { useDispatch } from "react-redux";
import { slice1Actions } from "../../store/reducer";
import { useSelector } from "react-redux";
import AlertModal from "./AlertModal";

const MenuModal = (props) => {
  const [AlertModalShow, setAlertModalShow] = useState(false);
  const [AlertModalTitle, setAlertModalTitle] = useState("");
  const [AlertModalMessage, setAlertModalMessage] = useState("");
  const [AlertModalButtons, setAlertModalButtons] = useState([]);

  const dispatch = useDispatch();

  const handleClose = () => {
    props.onHide();
  };

  const handleMethod = (menu_item) => {
    props.onHide();
    if (menu_item.method !== null) menu_item.method(menu_item.label);
  };

  const myAlert = (message, title, buttons) => {
    setAlertModalMessage(message);
    setAlertModalTitle(title == null ? "Message" : title);
    setAlertModalButtons(buttons == null ? [{ Title: "OK", Method: null }] : buttons);
    setAlertModalShow(true);
  };

  // const renderTooltip = (menu_item) => <Tooltip id="button-tooltip">{menu_item.info}</Tooltip>;

  const displayMenuInfo = (menu_item) => {
    myAlert(menu_item.info, "Info");
  };

  if (AlertModalShow)
    return (
      <AlertModal
        show={AlertModalShow}
        onHide={() => setAlertModalShow(false)}
        // AlertModalTitle={AlertModalTitle}
        // AlertModalMessage={AlertModalMessage}
        // AlertModalButtons={AlertModalButtons}
        attributes={{
          onHide: () => setAlertModalShow(false),
          AlertModalTitle: AlertModalTitle,
          AlertModalMessage: AlertModalMessage,
          AlertModalButtons: AlertModalButtons,
        }}
      />
    );
  return (
    <div className="modal">
      <Modal
        {...props}
        animation={false}
        dialogClassName="myModalClass"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
        // style={{ padding: "50px", zIndex: "999999999" }}
        onHide={handleClose}
      >
        {/* <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-sm">{props.menu_modal_heading}</Modal.Title>
        </Modal.Header> */}
        <Modal.Body>
          <div className="sort">
            <div className="flex-container-middle">
              <h4 key="heading" style={{ color: "#3598DB" }}>
                {props.menu_modal_image} {props.menu_modal_heading}
              </h4>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <i title="Close" className={`fas fa-times color-black`} onClick={props.onHide}></i>
            </div>

            <h6 style={{ color: "#fdcb6e" }}>{props.menu_modal_info}</h6>
            <div className="multiselect-list">
              {props.menu_modal_list.map((menu_item, i) => (
                <div key={`container${i}`} className="flex-container">
                  <div className="flex-items-left" key={`left${i}`}>
                    {menu_item.label === "**SEPARATOR***" ? (
                      <div key={`divider${i}`} className="divider">
                        ---------------
                      </div>
                    ) : null}
                    {menu_item.label !== "**SEPARATOR***" && menu_item.visible ? (
                      <p key={`menu_item${i}`} onClick={() => handleMethod(menu_item)}>
                        {menu_item.checked ? menu_item.icon : null}
                        <span key={`menu_label${i}`}>{menu_item.label}</span>
                      </p>
                    ) : null}

                    {/* <h4 style={{ color: "#3598DB" }}>
                  <i className="far fa-calendar-alt" onClick={() => menu_item.method}></i> 
                </h4>
                <p onClick={handleShowUserInfo}>{menu_item.label}</p> */}
                  </div>
                  {typeof menu_item.info != "undefined" && menu_item.visible ? (
                    <div className="flex-items-right flex-container-right" key={`r${i}`}>
                      <OverlayTrigger
                        // zIndex="9999"
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={<Tooltip id="button-tooltip-2">{menu_item.info}</Tooltip>}
                      >
                        <i
                          // title="Info"
                          className={`fa fa-info`}
                          style={{ color: "#3598DB" }}
                          onClick={() => displayMenuInfo(menu_item)}
                        ></i>
                      </OverlayTrigger>
                    </div>
                  ) : null}
                </div>
              ))}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default MenuModal;
