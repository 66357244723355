import React, { useState } from "react";
import "./PlaceCard.css";
import PlaceModal from "../../modals/PlaceModal";
import Common, { isSearchResult } from "../../../Common/Common";
import { urlSchemeToFunctions } from "../../../CommonWeb/Common";
import PlaceMenu from "../../../CommonWeb/PlaceMenu";
import { useSelector } from "react-redux";
import Place, { Place_Source } from "../../../Models/Place";
import { useDispatch } from "react-redux";
import { slice1Actions } from "../../../store/reducer";
import PlaceEditModal from "../../modals/PlaceEditModal";
import ImageDialog from "../../modals/ImageDialog";
import GooglePlaces from "../../../Models/GooglePlaces";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid, regular, brands, icon } from "@fortawesome/fontawesome-svg-core/import.macro";

const PlaceCard = (props) => {
  const [placeModal, setPlaceModal] = useState(false);
  const [PlaceEditModalShow, setPlaceEditModalShow] = useState(false);
  const [ImageDialogShow, setImageDialogShow] = useState(false);
  let user_info = useSelector((state) => state.user_info);
  const dispatch = useDispatch();
  let MobileMode = useSelector((state) => state.MobileMode);
  let GuideEditMode = useSelector((state) => state.GuideEditMode);
  let GuideSettings = useSelector((state) => state.GuideSettings);
  let SearchResultsPlacesList = useSelector((state) => state.SearchResultsPlacesList);

  const getImageURLString = (place) => {
    if (place == null) return "";
    let str = place.Photo_m;
    if (str === "" || typeof str === "undefined") str = place.Photo;
    let img = Common.getImageURL(str, Common.getFBPath(window.guide_obj.guide_id, user_info), false);
    //if (!img.startsWith("data")) console.log(img);
    return img;
  };

  const formatDistance = (distance) => {
    let measure = "Km";
    if (props.user_info.distance_unit == "miles") {
      distance = distance * 0.62137119;
      measure = "miles";
    }
    return `${parseFloat(distance).toFixed(3)} ${measure}`;
  };

  function handleCenterInMap(e) {
    e.stopPropagation();
    const place = window.guide_obj.places.getPlace(props.i, SearchResultsPlacesList);
    if (place == null) return;
    dispatch(slice1Actions.centerMapToPlaces({ value: [place] }));
    //dispatch(slice1Actions.setShowHiddenPlace({ value: [place] }));
    //dispatch(slice1Actions.setGuide({ guide: null }));
    //dispatch(slice1Actions.setMapCenterPlace({ place: place }));

    //const _MapCenter = { lat: place.Lat, lng: place.Longi };
    //dispatch(slice1Actions.setMapCenter({ value: _MapCenter }));

    if (MobileMode) dispatch(slice1Actions.setCurrentAppScreen({ value: "Map" }));
    else window.location.href = "#map-start";
  }

  function handleShowPlaceAttributes(e) {
    e.stopPropagation();
    let htmlString = PlaceMenu.handleShowPlaceAttributes(props.i, SearchResultsPlacesList);
    htmlString = urlSchemeToFunctions(htmlString);
    // htmlString = urlSchemeToFunctions(htmlString);
    dispatch(slice1Actions.setHTMLTitle({ value: "Place Attributes" }));
    dispatch(slice1Actions.setHTMLToSHow({ value: htmlString }));
    dispatch(slice1Actions.setShowHTMLReader({ value: true }));
    window.location.href = "#home-start";
    var homeDiv = document.getElementById("home-content-start");
    if (MobileMode) dispatch(slice1Actions.setCurrentAppScreen({ value: "Home" }));
    else homeDiv.scrollIntoView();
  }

  function handleEditPlace(e) {
    e.stopPropagation();
    setPlaceEditModalShow(true);
    //props.onHide();
  }

  function handleShowPlaceMenu(e) {
    e.stopPropagation();
    setPlaceModal(true);
  }

  const PlaceEditCallback = () => {
    dispatch(slice1Actions.resetHomeScreen({ value: null }));
    dispatch(slice1Actions.setGuide({ guide: null }));
  };

  const placeSelected = (event) => {
    if (GuideSettings.mode !== "custom_view" || event.detail === 2) {
      setPlaceModal(true);
      return;
    }
    if (GuideSettings.mode === "custom_view") {
      const place = window.guide_obj.places.getPlace(props.i, SearchResultsPlacesList);
      if (GuideSettings.places_list.path_link) {
        window.handleMarkerPathLink(place);
        return;
      }
      if (GuideSettings.places_list.menu === true) setPlaceModal(true);
      else if (
        (GuideSettings.places_list.info === true && place.Info !== "" && !place.Info.endsWith("</style>")) ||
        (GuideSettings.places_list.info === true && GuideSettings.places_list.attributes !== true)
      ) {
        if (place !== null) {
          let htmlString = place.Info;
          if (typeof htmlString === "undefined") htmlString = "";
          htmlString = urlSchemeToFunctions(htmlString);
          dispatch(slice1Actions.setHTMLTitle({ value: "Place Info" }));
          dispatch(slice1Actions.setHTMLToSHow({ value: htmlString }));
          dispatch(slice1Actions.setShowHTMLReader({ value: true }));
          window.location.href = "#home-start";
          var homeDiv = document.getElementById("home-content-start");
          if (MobileMode) dispatch(slice1Actions.setCurrentAppScreen({ value: "Home" }));
          else homeDiv.scrollIntoView();
          if (MobileMode) return;
        }
      } else if (
        GuideSettings.places_list.info === true &&
        place.Info === "" &&
        !place.Info.endsWith("</style>") &&
        GuideSettings.places_list.attributes === true
      ) {
        let htmlString = PlaceMenu.handleShowPlaceAttributes(props.i, SearchResultsPlacesList);
        htmlString = urlSchemeToFunctions(htmlString);
        dispatch(slice1Actions.setHTMLTitle({ value: "Place Attributes" }));
        dispatch(slice1Actions.setHTMLToSHow({ value: htmlString }));
        dispatch(slice1Actions.setShowHTMLReader({ value: true }));
        window.location.href = "#home-start";
        var homeDiv = document.getElementById("home-content-start");
        if (MobileMode) dispatch(slice1Actions.setCurrentAppScreen({ value: "Home" }));
        else homeDiv.scrollIntoView();
        if (MobileMode) return;
      } else if (GuideSettings.places_list.attributes === true) {
        let htmlString = PlaceMenu.handleShowPlaceAttributes(props.i, SearchResultsPlacesList);
        htmlString = urlSchemeToFunctions(htmlString);
        dispatch(slice1Actions.setHTMLTitle({ value: "Place Attributes" }));
        dispatch(slice1Actions.setHTMLToSHow({ value: htmlString }));
        dispatch(slice1Actions.setShowHTMLReader({ value: true }));
        window.location.href = "#home-start";
        var homeDiv = document.getElementById("home-content-start");
        if (MobileMode) dispatch(slice1Actions.setCurrentAppScreen({ value: "Home" }));
        else homeDiv.scrollIntoView();
        if (MobileMode) return;
      } else if (GuideSettings.places_list.center === true) {
        const place = window.guide_obj.places.getPlace(props.i, SearchResultsPlacesList);
        if (place == null) return;
        dispatch(slice1Actions.centerMapToPlaces({ value: [place] }));
        // if (MobileMode) dispatch(slice1Actions.setCurrentAppScreen({ value: "Map" }));
        // else window.location.href = "#map-start";
      } else if (GuideSettings.places_list.filter === true) {
        if (place !== null) {
          dispatch(slice1Actions.ClearAllFilters({ value: null }));
          dispatch(slice1Actions.SetPlacesFilter({ value: [place] }));
          dispatch(slice1Actions.setGuide({ guide: null }));
        }
      } else if (GuideSettings.places_list.url === true) {
        PlaceMenu.handleShowPlaceUrl(props.i);
      }
      return;
    }
  };

  function showPlaceLink() {
    const place = window.guide_obj.places.getPlace(props.i, SearchResultsPlacesList);
    if (place == null) return "";
    window.handleMarkerPathLink(place);
  }

  function showPlaceURL(e) {
    e.stopPropagation();
    let htmlString = PlaceMenu.handleShowPlaceUrl(props.i);

    handleShowPlaceUrl(props.i);
  }

  const handleShowPlaceUrl = (id) => {
    const place = window.guide_obj.places.getPlace(id, SearchResultsPlacesList);
    if (place == null) return "";
    let link = place.getFirstHLink();
    if (link.includes("place_id=")) {
      const parts = link.split("=");
      console.log("parts", parts);
      GooglePlaces.getMoreDetails(getMoreDetailsCallback, parts[1]);
      return;
    }
    if (link !== "") {
      let win = window.open(link, "_blank");
      // win.focus();
    }
  };

  const getMoreDetailsCallback = (place_id, details) => {
    if (details === null) return;
    let hlink = "";
    console.log("getMoreDetailsCallback", details);
    if (typeof details.url != "undefined") {
      if (details.url.includes(";")) {
        const urls = details.url.split(";");
        hlink = `${urls[0]}`;
      } else hlink = `${details.url}`;
    }
    console.log("getMoreDetailsCallback", hlink);
    if (hlink !== "") {
      let win = window.open(hlink, "_blank");
      win.focus();
      return null;
    }
  };

  const updateImageCallBack = (imageInfo) => {
    let new_place = new Place(window.guide_obj.places);
    new_place.Photo = imageInfo[0].url;
    new_place.Photo_m = imageInfo[0].url_m;
    window.guide_obj.places.updatePhoto(new_place, props.i);
  };

  const updateImage = () => {
    setImageDialogShow(true);
  };

  return (
    <>
      <div id={props.id} className="place-card" onClick={(e) => placeSelected(e)}>
        <div>
          <img
            src={getImageURLString(props.place)}
            style={{ objectFit: "cover", minWidth: 80, maxWidth: 100 }}
            alt=""
            onClick={updateImage}
          />
        </div>
        <div>
          <p
            style={
              isSearchResult(props.place.Source)
                ? { fontWeight: "700", color: "green" }
                : { fontWeight: "700", color: "black" }
            }
          >
            {props.name}
          </p>
          <p>
            <span>Category: </span>
            {props.category}
          </p>
          <p>
            <span>Groups: </span>
            {props.groups}
          </p>
          <p>
            <span>Rating: </span>
            {props.rating === "" ? "N/A " : props.rating}
            {/* <span> Tags: </span> */}
            <span>&nbsp;</span>
            {props.place.MustSee === true ? <i title="Must See" className="fa fa-star color-black"></i> : null}
            <span>&nbsp;</span>
            {props.place.Favorite === true ? <i title="Favorite" className="fas fa-heart color-black"></i> : null}
            <span>&nbsp;</span>
            {props.place.Visited === true ? <i title="Visited" className="fas fa-check color-black"></i> : null}
            <span>&nbsp;</span>
            {props.place.scheduledDates.length > 0 ? (
              <i title="Scheduled" className="fa fa-calendar color-black"></i>
            ) : null}

            <span>&nbsp;</span>
            {/* {!isSearchResult(props.place.Source) ? ( */}
            <i
              title="Center on map"
              className="fa fa-crosshairs color-blue"
              onClick={handleCenterInMap}
              onMouseEnter={() => props.mouse_enter()}
              onMouseLeave={() => props.mouse_leave()}
            ></i>
            {/* ) : null} */}

            <span>&nbsp;&nbsp;&nbsp;</span>
            {/* {!isSearchResult(props.place.Source) ? ( */}
            <i title="Show Attributes" className="fa fa-info color-blue" onClick={handleShowPlaceAttributes}></i>
            {/* ) : null} */}

            <span>&nbsp;&nbsp;&nbsp;</span>
            {GuideEditMode && !isSearchResult(props.place.Source) ? (
              <i title="Edit Place" className="fa fa-pencil color-blue" onClick={handleEditPlace}></i>
            ) : null}
            <span>&nbsp;&nbsp;&nbsp;</span>
            {props.place.Link.type !== "none" && !isSearchResult(props.place.Source) ? (
              <i title="Place link" class="fas fa-external-link-alt color-blue" onClick={showPlaceLink}></i>
            ) : null}
          </p>
          <p>
            <span>Distance: </span>
            {`${formatDistance(props.distance)}`}

            <span>&nbsp;</span>
            <span>Type: </span>
            {props.place.PType === "Icon" ? (
              <i title="Location" className="fa fa-map-marker-alt color-black"></i>
            ) : null}
            {/* <span>&nbsp;</span> */}
            {props.place.PType === "PolyLine" ? (
              <i title="Path" className="fa fa-long-arrow-alt-up color-black"></i>
            ) : null}
            {props.place.PType === "Polygon" ? <i title="Area" className="fa fa-border-all color-black"></i> : null}
            <span>&nbsp;&nbsp;</span>
            <FontAwesomeIcon
              icon={solid("square-caret-down")}
              style={{ cursor: "pointer" }}
              className="color-blue"
              onClick={(e) => handleShowPlaceMenu(e)}
            ></FontAwesomeIcon>

            {/* <i title="Menu" className="fa fa-ellipsis-h color-blue" onClick={(e) => handleShowPlaceMenu(e)}></i> */}
          </p>
        </div>
      </div>
      {placeModal ? (
        <PlaceModal
          show={placeModal}
          onHide={() => setPlaceModal(false)}
          name={props.name}
          img={props.img}
          i={props.i}
          mainIndex={props.mainIndex}
          id={props.id}
          groups={window.guide_obj == null ? "" : window.guide_obj.groupNamesFromIDs(props.place.Group)}
          category={window.guide_obj == null ? "" : window.guide_obj.categoryNamesFromIDs(props.place.Category)}
          //img={getImageURLString(props.place)}
          place={props.place}
          user_info={props.user_info}
        />
      ) : null}
      {PlaceEditModalShow ? (
        <PlaceEditModal
          show={PlaceEditModalShow}
          onHide={() => setPlaceEditModalShow(false)}
          img={props.img}
          name={props.name}
          index={props.i}
          mainIndex={props.mainIndex}
          id={props.id}
          place={props.place}
          groups={window.guide_obj == null ? "" : window.guide_obj.groupNamesFromIDs(props.place.Group)}
          category={window.guide_obj == null ? "" : window.guide_obj.categoryNamesFromIDs(props.place.Category)}
          user_info={user_info}
          mode={"Edit"}
          update_display={PlaceEditCallback}
          onHidePrevious={props.onHide}
          map_bounds={props.map_bounds}
        />
      ) : null}
      {ImageDialogShow ? (
        <ImageDialog
          show={ImageDialogShow}
          onHide={() => setImageDialogShow(false)}
          user_info={user_info}
          // callback={this.updateImageCallBack}
          mode={"select"}
          modify_only={"true"}
          updateImage={updateImageCallBack}
          size={"sm"}
          photo={props.place.Photo}
          photo_m={props.place.Photo_m}
          multi={false}
          exif={false}
          map_bounds={props.map_bounds}
          place={props.place}
        />
      ) : null}
    </>
  );
};

export default PlaceCard;
