import { connect } from "react-redux";
import React, { Component, useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import "./Modal.css";
import PromptModal from "./PromptModal";
import AlertModal from "./AlertModal";
import MultiSelectModal from "./MultiSelectModal";
import IconsDialog from "./IconsDialog";
import Place from "../../Models/Place";

import User from "../../Models/User";

class UsersDialog extends Component {
  constructor(props) {
    super(props);
    let users = [];
    this.state = {
      visiblePrompt: false,
      Users: this.props.users,
      uidText: "",

      PromptModalShow: false,
      PromptModalTitle: "Enter Value",
      PromptModalMessage: "",
      PromptModalButtons: [],
      PromptModalInputs: [],

      AlertModalShow: false,
      AlertModalTitle: "",
      AlertModalMessage: "",
      AlertModalButtons: [],
    };
  }

  componentDidMount() {}

  MyAlert = (title, message, buttons) => {
    this.setState({
      AlertModalTitle: title,
      AlertModalMessage: message,
      AlertModalButtons: buttons,
      AlertModalShow: true,
    });
  };

  addUser = (inputs) => {
    if (this.state.uidText === "") {
      this.MyAlert("Message", "Must enter a User ID", [{ Title: "OK", Method: null }]);
      return;
    }
    if (this.state.uidText === this.props.user_info.uid) {
      this.MyAlert("Message", "Cannot add your own user ID", [{ Title: "OK", Method: null }]);
      return;
    }
    const index = this.state.Users.findIndex((x) => x.toUpperCase() === inputs[0].value.toUpperCase());
    if (index !== -1) {
      this.MyAlert("Message", "User is already in list", [{ Title: "OK", Method: null }]);
      return;
    }
    User.findUserByUID(this.state.uidText, this.findUserByUIDCallback);
  };

  uidChanged = (event) => {
    this.setState({ uidText: event.target.value });
  };

  findUserByUIDCallback = async (res) => {
    if (res.pass === true) {
      const user_info = res.res;
      let users = [...this.state.Users];
      users.push({
        label: `${user_info.first_name}, ${user_info.last_name}`,
        value: user_info.uid,
        name: user_info.uid,
      });
      this.setState({ Users: users });
    } else {
      this.MyAlert("Message", "User not found", [{ Title: "OK", Method: null }]);
      return;
    }
  };

  deleteUser = (user_value) => {
    this.MyAlert("Confirmation", "Confirm Delete User?", [
      { Title: "OK", Method: () => this._deleteUser(user_value) },
      { Title: "CANCEL", Method: null },
    ]);
  };

  _deleteUser = (user_value) => {
    let users = this.state.Users;
    const index = users.findIndex((c) => c.value === user_value);
    if (index === -1) return;
    users.splice(index, 1);
    this.setState({ Users: users });
  };

  returnUsers = () => {
    const callback = this.props.callback;
    if (callback != null) callback(this.state.Users);
    this.props.onHide();
  };

  render() {
    return (
      <div className="modal">
        <Modal
          {...this.props}
          dialogClassName="myModalClass"
          aria-labelledby="contained-modal-title-vcenter"
          centered={false}
          scrollable={true}
        >
          <Modal.Header closeButton>
            <div style={{ width: "100%" }}>
              <div className="sort">
                <div className="dialog-heading">
                  <div>
                    <h4 className="color-blue">Users</h4>
                  </div>
                </div>
                <div className="dialog-heading">
                  <input type="text" placeholder="Enter User ID" onChange={this.uidChanged} />
                  {/* {MultiselectMode === "multi" ? (
                      <i className={`fas fa-tasks ${show ? "" : "color"}`} onClick={handleSelectAll}></i>
                    ) : null} */}
                  <i className={`fa fa-plus`} onClick={() => this.addUser(true, null)}></i>
                  <i className="fas fa-check" onClick={() => this.returnUsers()}></i>
                </div>
                {/* <Modal.Title id="example-modal-sizes-title-sm">{MultiselectTitle}</Modal.Title> */}
              </div>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="multiselect-list">
              {this.state.Users.map((item, index) => (
                <div className="list-item-card" key={index}>
                  <div className="items-left">
                    {/* <img src={Place.getItemPOIImage(item.value)} onClick={() => this.ChangeIcon(item)} /> */}
                    <span className="text-size-1-0">{item.label}</span>
                    <div className="items-right" key={index}>
                      <i
                        title="Delete"
                        className={`fa fa-trash-alt`}
                        style={{ color: "red" }}
                        onClick={() => this.deleteUser(item.value)}
                      ></i>
                      <span> </span>
                      {/* <i
                        title="Change Name"
                        className={`fa fa-edit color-blue`}
                        onClick={() => this.addModifyGroup(false, item.label)}
                      ></i> */}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </Modal.Body>
        </Modal>
        <PromptModal
          show={this.state.PromptModalShow}
          onHide={() => this.setState({ PromptModalShow: false })}
          PromptModalTitle={this.state.PromptModalTitle}
          PromptModalMessage={this.state.PromptModalMessage}
          PromptModalButtons={this.state.PromptModalButtons}
          PromptModalInputs={this.state.PromptModalInputs}
        />
        <AlertModal
          show={this.state.AlertModalShow}
          onHide={() => this.setState({ AlertModalShow: false })}
          AlertModalTitle={this.state.AlertModalTitle}
          AlertModalMessage={this.state.AlertModalMessage}
          AlertModalButtons={this.state.AlertModalButtons}
        />
      </div>
    );
  }
}

export default UsersDialog;
