import React, { useState } from "react";
import "./SchedulePanel.css";
import EventModal from "../modals/EventModal";
import MenuModal from "../modals/MenuModal";
import MoveEvents from "../modals/MoveEvents";
import EventEdit from "../modals/EventEdit";

import { ReactReduxContext, useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { slice1Actions } from "../../store/reducer";
import Common from "../../Common/Common";
import { DateTime, Settings } from "luxon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid, regular, brands, icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { urlSchemeToFunctions } from "../../CommonWeb/Common";
import PlaceMenu from "../../CommonWeb/PlaceMenu";

const SchedulePanel = (props) => {
  const [eventModal, setEventModal] = useState(false);
  const [MenuModalShow, setMenuModalShow] = useState(false);
  const [MenuModalImage, setMenuModalImage] = useState(null);
  const [MenuModalList, setMenuModalList] = useState([]);
  const [MenuModalHeading, setMenuModalHeading] = useState("");
  const [MenuModalPlace, setMenuModalPlace] = useState(null);

  const [Mode, setMode] = useState("move_day");
  const [ParamDate, setParamDate] = useState(null);
  const [MoveEventsShow, setMoveEventsShow] = useState(false);
  const [ShowEventEdit, setShowEventEdit] = useState(false);
  const [EventEditMode, setShowEventMode] = useState("MODIFY");
  const [EventEditWhere, setShowEventWhere] = useState("after");
  const [dragEvent, setdragEvent] = useState(null);
  const [dragDay, setdragDay] = useState(null);
  const [dragEventDeleted, setdragEventDeleted] = useState(false);

  // const [list, setList] = useState([
  //   "The Call Of Ktulu",
  //   "For Whom The Bell Tolls",
  //   "The Day That Never Comes",
  //   "The Memory Remains",
  //   "Confusion",
  //   "Moth Into Flame",
  //   "The Outlaw Torn",
  //   "No Leaf Clover",
  //   "Halo on Fire",
  // ]);
  const dispatch = useDispatch();

  let schedule = useSelector((state) => state.schedule);
  let ScheduleName = useSelector((state) => state.ScheduleName);
  let ScheduleNames = useSelector((state) => state.ScheduleNames);
  let LoadingGuide = useSelector((state) => state.LoadingGuide);
  let EventSearchText = useSelector((state) => state.EventSearchText);
  let GuideEditMode = useSelector((state) => state.GuideEditMode);
  let GuideSettings = useSelector((state) => state.GuideSettings);
  let MobileMode = useSelector((state) => state.MobileMode);
  let event = useSelector((state) => state.SelectedEvent);

  let SearchResultsPlacesList = useSelector((state) => state.SearchResultsPlacesList);

  const clearEventSearchText = () => {
    //e.stopPropagation();
    dispatch(slice1Actions.setEventSearchText({ value: "" }));
    dispatch(slice1Actions.setGuide({ guide: null }));
  };

  const showEventMenu = (e, event) => {
    e.stopPropagation();
    dispatch(slice1Actions.setSelectedEvent({ value: event }));
    setEventModal(true);
  };

  const addHour = (e, event) => {
    e.stopPropagation();
    var EventDuration = DateTime.fromISO(event.End).diff(DateTime.fromISO(event.Start));
    event.Start = DateTime.fromISO(event.Start, { zone: "utc" }).plus({ minutes: 60 }).toISO({ zone: "utc" });
    event.End = DateTime.fromISO(event.Start, { zone: "utc" })
      .plus({ seconds: EventDuration / 1000 })
      .toISO({ zone: "utc" });
    window.guide_obj.schedule.modifyEvent(event);
    dispatch(slice1Actions.setGuide({ guide: null }));
  };

  const subtractHour = (e, event) => {
    e.stopPropagation();
    var EventDuration = DateTime.fromISO(event.End).diff(DateTime.fromISO(event.Start));
    event.Start = DateTime.fromISO(event.Start, { zone: "utc" }).minus({ minutes: 60 }).toISO({ zone: "utc" });
    event.End = DateTime.fromISO(event.Start, { zone: "utc" })
      .plus({ seconds: EventDuration / 1000 })
      .toISO({ zone: "utc" });
    window.guide_obj.schedule.modifyEvent(event);
    dispatch(slice1Actions.setGuide({ guide: null }));
  };

  const showDayMenu = (e, event) => {
    e.stopPropagation();
    sectionClickedMenu(event);
  };

  const addHourDay = (e, event) => {
    e.stopPropagation();
    var EventDuration = DateTime.fromISO(event.End).diff(DateTime.fromISO(event.Start));
    event.Start = DateTime.fromISO(event.Start, { zone: "utc" }).plus({ minutes: 60 }).toISO({ zone: "utc" });
    event.End = DateTime.fromISO(event.Start, { zone: "utc" })
      .plus({ seconds: EventDuration / 1000 })
      .toISO({ zone: "utc" });
    window.guide_obj.schedule.modifyEvent(event);
    dispatch(slice1Actions.setGuide({ guide: null }));
  };

  const subtractHourDay = (e, event) => {
    e.stopPropagation();
    var EventDuration = DateTime.fromISO(event.End).diff(DateTime.fromISO(event.Start));
    event.Start = DateTime.fromISO(event.Start, { zone: "utc" }).minus({ minutes: 60 }).toISO({ zone: "utc" });
    event.End = DateTime.fromISO(event.Start, { zone: "utc" })
      .plus({ seconds: EventDuration / 1000 })
      .toISO({ zone: "utc" });
    window.guide_obj.schedule.modifyEvent(event);
    dispatch(slice1Actions.setGuide({ guide: null }));
  };
  //let Selevent = null;

  const handleEditEvent = (e, event) => {
    e.stopPropagation();
    dispatch(slice1Actions.setSelectedEvent({ value: event }));
    //Selevent = event;
    setShowEventMode("MODIFY");
    setShowEventWhere("after");
    setShowEventEdit(true);
  };

  const EventEditCallback = () => {
    dispatch(slice1Actions.resetHomeScreen({ value: null }));
    dispatch(slice1Actions.setGuide({ guide: null }));
  };

  const handleEventClicked = (e, event) => {
    //console.log(event);
    if (GuideSettings.mode !== "custom_view" || e.detail === 2) {
      dispatch(slice1Actions.setSelectedEvent({ value: event }));
      setEventModal(true);
      return;
    }
    if (GuideSettings.event.menu === true) setEventModal(true);
    if (GuideSettings.event.details === true) {
      const htmlString = event.createHTML(window.guide_obj.places);
      dispatch(slice1Actions.setHTMLTitle({ value: "Event Details" }));
      dispatch(slice1Actions.setHTMLToSHow({ value: htmlString }));
      dispatch(slice1Actions.setShowHTMLReader({ value: true }));
      window.location.href = "#home-start";
      var homeDiv = document.getElementById("home-content-start");
      if (MobileMode) dispatch(slice1Actions.setCurrentAppScreen({ value: "Home" }));
      else homeDiv.scrollIntoView();
      if (MobileMode) return;
    }
    if (GuideSettings.event.center === true) {
      const places = event.get_places(window.guide_obj.places);
      if (places.length !== 0) {
        dispatch(slice1Actions.ClearAllFilters());
        dispatch(slice1Actions.centerMapToPlaces({ value: places }));
        if (MobileMode) dispatch(slice1Actions.setCurrentAppScreen({ value: "Map" }));
        else window.location.href = "#map-start";
      }
    }
    if (GuideSettings.event.filter === true) {
      const places = event.get_places(window.guide_obj.places);
      if (places.length !== 0) {
        dispatch(slice1Actions.ClearAllFilters());
        dispatch(slice1Actions.setShowHiddenPlace({ value: places }));
        dispatch(slice1Actions.SetPlacesFilter({ value: places }));
        dispatch(slice1Actions.setGuide({ guide: null, fitToBounds: true }));
        window.location.href = "#map-start";
      }
    }
  };

  const sectionClicked = (section) => {
    section_clicked = section;
    // showDayScheduleFiltered();
    // return;
    if (GuideSettings.mode !== "custom_view") {
      sectionClickedMenu(section);
      return;
    }
    if (GuideSettings.day.menu === true) sectionClickedMenu(section);
    if (GuideSettings.day.center === true) {
      showDayScheduleCentered();
    }
    if (GuideSettings.day.filter === true) {
      showDayScheduleFiltered();
    }
  };

  let section_clicked = null;
  const sectionClickedMenu = (section) => {
    section_clicked = section;
    let menuList = [
      {
        label: "Filter by this day",
        method: showDayScheduleFiltered,
        icon: null,
        visible: true,
        info: "Filter the guide places based on the places scheduled for this day.",
      },
      {
        label: "Move days events",
        method: moveOneDay,
        icon: null,
        visible: GuideEditMode,
        info: "Move all of the events scheduled for this day",
      },
      {
        label: "Set day start time",
        method: setDayStart,
        icon: null,
        visible: GuideEditMode,
        info: "Set the day start date and/or time",
      },
      {
        label: "Move all events starting",
        method: moveThisAndSubsequentDays,
        icon: null,
        visible: GuideEditMode,
        info: "Move all of the events scheduled for this day and all of days after this day to a new starting date and time. This will move multiple days.",
      },
      {
        label: "Delete this one day",
        method: deleteOneDay,
        icon: null,
        visible: GuideEditMode,
        info: "Delete all of the events scheduled for this day. Essentially deletes the day.",
      },
    ];

    //({ value: _MenuModalArgs }));
    setMenuModalList(menuList);
    setMenuModalHeading("Day Menu");
    setMenuModalImage(<i className="fa fa-user"></i>);
    setMenuModalPlace(null);
    setMenuModalShow(true);
  };

  const moveOneDay = () => {
    setMode("move_day");
    const label = section_clicked.Title;
    console.log(label);
    let date = new Date(label);
    date = Common.LuxonDateFromJSDate(date);
    console.log(date);
    setParamDate(date);
    setMoveEventsShow(true);
  };

  const setDayStart = () => {
    setMode("set_day_start");
    const label = section_clicked.Title;
    console.log(label);
    let date = new Date(label);
    date = Common.LuxonDateFromJSDate(date);
    date = date.plus({ hours: 9 });
    console.log(date);
    setParamDate(date);
    setMoveEventsShow(true);
  };

  const deleteOneDay = () => {
    setMode("delete_day");
    const label = section_clicked.Title;
    let date = new Date(label);
    date = Common.LuxonDateFromJSDate(date);

    window.guide_obj.schedule.deleteDay(date, ScheduleName);
    dispatch(slice1Actions.setGuide({ guide: null }));
    // setParamDate(date);
    // setMoveEventsShow(true);
  };

  const moveThisAndSubsequentDays = () => {
    setMode("move_all_days");
    const label = section_clicked.Title;
    let date = new Date(label);
    date = Common.LuxonDateFromJSDate(date);
    setParamDate(date);
    setMoveEventsShow(true);
  };

  const MoveEventsCallback = () => {
    dispatch(slice1Actions.setGuide({ guide: null }));
  };

  const showDayScheduleFiltered = () => {
    //console.log(section);
    const label = section_clicked.Title;
    const date = new Date(label);
    const dayTime = date.getTime();
    const day_entry = {
      label: label,
      value: dayTime,
      // id: "9999999",
    };
    const AllScheduledPlaces = window.guide_obj.getAllScheduledPlaces(ScheduleName, dayTime);
    let hiddenPlacesToShow = [];
    AllScheduledPlaces.forEach((p) => {
      if (p.Hidden === true) hiddenPlacesToShow.push(p);
    });
    dispatch(slice1Actions.setShowHiddenPlace({ value: hiddenPlacesToShow }));
    dispatch(slice1Actions.SetDatesFilter({ value: [day_entry] }));
    dispatch(slice1Actions.setGuide({ guide: null, fitToBounds: true }));
    dispatch(slice1Actions.setCurrentAppScreen({ value: "Map" }));
    window.location.href = "#map-start";
  };

  const showDayScheduleCentered = () => {
    //console.log(section);
    const label = section_clicked.Title;
    const date = new Date(label);
    const dayTime = date.getTime();
    const day_entry = {
      label: label,
      value: dayTime,
      // id: "9999999",
    };
    const AllScheduledPlaces = window.guide_obj.getAllScheduledPlaces(ScheduleName, dayTime);
    let hiddenPlacesToShow = [];
    AllScheduledPlaces.forEach((p) => {
      if (p.Hidden === true) hiddenPlacesToShow.push(p);
    });
    dispatch(slice1Actions.setShowHiddenPlace({ value: hiddenPlacesToShow }));
    if (AllScheduledPlaces.length !== 0) {
      dispatch(slice1Actions.ClearAllFilters());
      dispatch(slice1Actions.centerMapToPlaces({ value: AllScheduledPlaces }));
      if (MobileMode) dispatch(slice1Actions.setCurrentAppScreen({ value: "Map" }));
      else window.location.href = "#map-start";
    }
    dispatch(slice1Actions.setGuide({ guide: null, fitToBounds: true }));
    dispatch(slice1Actions.setCurrentAppScreen({ value: "Map" }));
    window.location.href = "#map-start";
  };

  function handleDeleteEvent(event) {
    window.guide_obj.schedule.deleteEvent(event);
    //dispatch(slice1Actions.setGuide({ guide: null }));
  }

  const handleDragStart = (e, index, event) => {
    //e.target.style.backgroundColor = "#ffff";
    setdragEvent(event);
  };

  const handleDragEnter = (e, index, event) => {};

  const handleDragLeave = (e) => {
    //e.target.style.backgroundColor = "#ffff";
  };

  const handleDrop = (e, event) => {
    if (dragEvent === null) return false;
    //e.target.style.backgroundColor = "#336699";
    window.guide_obj.schedule.reorderEvents(dragEvent, event, ScheduleName);
    dispatch(slice1Actions.setGuide({ guide: null }));
    setdragEvent(null);
  };

  const enterLeave = (event, enter) => {
    // if (enter === true) console.log("Enter", place.Name);
    // else console.log("Leave", place.Name);
    let places = event.Places;
    if (!Array.isArray(event.Places)) places = event.Places.split(",");
    if (enter === true) {
      dispatch(slice1Actions.setHighlightedPlaces({ value: places }));
      //     const place = window.guide_obj.places.getPlace(places[0]);
      //     if (place !== null) {
      //       var placeElement = document.getElementById(`PlaceCard${places[0]}`);
      //       if (placeElement !== null) {
      //         placeElement.scrollIntoView();
      //         placeElement.style.backgroundColor = "yellow";
      //       }
      //       //////
      //       let htmlString = PlaceMenu.handleShowPlaceAttributes(place.ID, SearchResultsPlacesList);
      //       htmlString = urlSchemeToFunctions(htmlString);
      //       dispatch(slice1Actions.setHTMLTitle({ value: "Place Attributes" }));
      //       dispatch(slice1Actions.setHTMLToSHow({ value: htmlString }));
      //       dispatch(slice1Actions.setShowHTMLReader({ value: true }));
      //       window.location.href = "#home-start";
      //       let homeDiv = document.getElementById("home-content-start");
      //       if (MobileMode) dispatch(slice1Actions.setCurrentAppScreen({ value: "Home" }));
      //       else if (homeDiv !== null) {
      //         dispatch(slice1Actions.centerMapToPlaces({ value: [place] }));
      //         homeDiv.scrollIntoView();
      //       }
      //       ///////
      //     }
      //   }
    } else dispatch(slice1Actions.setHighlightedPlaces({ value: [] }));
    //   if (places.length !== 0) {
    //     const place = window.guide_obj.places.getPlace(places[0]);
    //     if (place !== null) {
    //       placeElement = document.getElementById(`PlaceCard${places[0]}`);
    //       if (placeElement !== null) {
    //         placeElement.style.backgroundColor = "#ffffff";
    //       }
    //     }
    //   }
    //  }
    if (places.length !== 0) highlightPlace(places[0], enter);
  };

  const highlightPlace = (placeID, enter) => {
    const place = window.guide_obj.places.getPlace(placeID);
    if (place === null || typeof place.Name === "undefined") return;
    const placeElement = document.getElementById(`PlaceCard${placeID}`);
    if (enter === true) {
      if (placeElement !== null) {
        placeElement.scrollIntoView();
        placeElement.style.backgroundColor = "yellow";
      }
      let htmlString = PlaceMenu.handleShowPlaceAttributes(place.ID, SearchResultsPlacesList);
      htmlString = urlSchemeToFunctions(htmlString);
      dispatch(slice1Actions.setHTMLTitle({ value: "Place Attributes" }));
      dispatch(slice1Actions.setHTMLToSHow({ value: htmlString }));
      dispatch(slice1Actions.setShowHTMLReader({ value: true }));
      window.location.href = "#home-start";
      let homeDiv = document.getElementById("home-content-start");
      if (MobileMode) dispatch(slice1Actions.setCurrentAppScreen({ value: "Home" }));
      else if (homeDiv !== null) {
        dispatch(slice1Actions.centerMapToPlaces({ value: [place] }));
        homeDiv.scrollIntoView();
      }
    } else {
      dispatch(slice1Actions.setHighlightedPlaces({ value: [] }));
      if (placeElement !== null) {
        placeElement.style.backgroundColor = "#ffffff";
      }
    }
  };

  const handleDragStartDay = (e, index, dragDay) => {
    let dayBeforeDragDay = schedule[0];
    if (index > 0) dayBeforeDragDay = schedule[index - 1];
    let dayAfterDragDay = schedule[schedule.length - 1];
    if (index < schedule.length - 1) dayAfterDragDay = schedule[index + 1];
    setdragDay({ dragDay: dragDay, dayBeforeDragDay: dayBeforeDragDay, dayAfterDragDay: dayAfterDragDay });
    //console.log("handleDragStartDay", dragDay);
  };

  const handleDragEnterDay = (e, index, dropDay) => {};

  const handleDragLeaveDay = (e) => {
    //e.target.style.backgroundColor = "#ffff";
  };

  const handleDropDay = (e, dropIndex, dropDay) => {
    //e.target.style.backgroundColor = "#ffff";if (dragDay === null) return false;
    console.log("handleDragEnterDay");
    let dayAfterDropDay = schedule[schedule.length - 1];
    if (dropIndex < schedule.length - 1) dayAfterDropDay = schedule[dropIndex + 1];
    window.guide_obj.schedule.reorderDays(
      dragDay.dragDay,
      dragDay.dayBeforeDragDay,
      dragDay.dayAfterDragDay,
      dropDay,
      dayAfterDropDay,
      ScheduleName,
      dropIndex === schedule.length - 1
    );
    setdragDay(null);
    dispatch(slice1Actions.setGuide({ guide: null }));
  };

  const enterLeaveDay = (day, enter) => {
    // let places = event.Places;
    // if (!Array.isArray(event.Places)) places = event.Places.split(",");
    // if (enter === true) {
    //   dispatch(slice1Actions.setHighlightedPlaces({ value: places }));
    //   if (places.length !== 0) {
    //     const place = window.guide_obj.places.getPlace(places[0]);
    //     if (place !== null) {
    //       var placeElement = document.getElementById(`PlaceCard${places[0]}`);
    //       if (placeElement !== null) {
    //         placeElement.scrollIntoView();
    //         placeElement.style.backgroundColor = "yellow";
    //       }
    //     }
    //   }
    // } else {
    //   dispatch(slice1Actions.setHighlightedPlaces({ value: [] }));
    //   if (places.length !== 0) {
    //     const place = window.guide_obj.places.getPlace(places[0]);
    //     if (place !== null) {
    //       placeElement = document.getElementById(`PlaceCard${places[0]}`);
    //       if (placeElement !== null) {
    //         placeElement.style.backgroundColor = "#ffffff";
    //       }
    //     }
    //   }
    // }
  };

  const assignID = (event, index) => {
    let places = event.Places;
    if (!Array.isArray(event.Places)) places = event.Places.split(",");
    if (places.length !== 0) return `eventID${places[0]}`;
    else return `eventIndex${index}`;
  };
  return (
    <div
      id="ScheduleDiv"
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        // width: "20%",
        //maxHeight: "100vh",
        overflowY: "auto",
      }}
    >
      {!LoadingGuide && EventSearchText !== "" ? (
        <div style={{ background: "#fdcb6e" }} className="schedule-card" onClick={() => clearEventSearchText()}>
          <p>Filtered. Click to show all</p>
        </div>
      ) : null}
      {schedule.length === 0 && ScheduleNames.length > 1 && !LoadingGuide && EventSearchText === "" ? (
        <div className="schedule-card">
          <p>No Events in this schedule. Choose a different schedule or add events using the "+" icon above.</p>
        </div>
      ) : null}

      <a id="schedule-show"></a>
      {schedule.length === 0 && ScheduleNames.length === 1 && !LoadingGuide && EventSearchText === "" ? (
        <div className="schedule-card">
          <p>No Events in guide. Add them using the "+" icon above.</p>
        </div>
      ) : null}
      {schedule.map((_schedule, i) => (
        <div
          key={i}
          draggable
          onDragStart={(e) => handleDragStartDay(e, i, _schedule, true)}
          onDragEnter={(e) => handleDragEnterDay(e, i, _schedule)}
          onDragLeave={(e) => handleDragLeaveDay(e, _schedule)}
          onDrop={(e) => handleDropDay(e, i, _schedule)}
          onDragOver={(e) => e.preventDefault()}
        >
          <div
            onClick={() => sectionClicked(_schedule)}
            style={{ cursor: "pointer" }}
            onMouseEnter={() => enterLeaveDay(_schedule, true)}
            onMouseLeave={() => enterLeaveDay(_schedule, false)}
          >
            <div className="calendar-icon">
              {/* <i class="fa fa-arrows-v" style={{ cursor: "move" }}></i> */}
              <FontAwesomeIcon icon={solid("grip-vertical")} style={{ cursor: "move" }}></FontAwesomeIcon>
              <span>&nbsp;&nbsp;&nbsp;</span>
              {/* <i className="far fa-calendar-alt"></i> */}
              {/* <p className="schedule-date"> */}
              {`${_schedule.data[0].dayNum}: ${_schedule.Title}`}
              <span>&nbsp;&nbsp;&nbsp;</span>
              {/* <i title="Menu" className="fa fa-ellipsis-h color-blue" onClick={(e) => showDayMenu(e, _schedule)}></i> */}
              <FontAwesomeIcon
                icon={solid("square-caret-down")}
                style={{ cursor: "pointer" }}
                onClick={(e) => showDayMenu(e, _schedule)}
              ></FontAwesomeIcon>
              {/* <span>&nbsp;&nbsp;&nbsp;</span>
              <i title="Menu" className="fa fa-plus-circle color-blue" onClick={(e) => addHourDay(e, _schedule)}></i>

              <span>&nbsp;&nbsp;&nbsp;</span>
              <i
                title="Menu"
                className="fa fa-minus-circle color-blue"
                onClick={(e) => subtractHourDay(e, _schedule)}
              ></i> */}
            </div>
          </div>
          {_schedule.data.map((_event, index) => (
            <div
              id={assignID(_event, index)}
              key={index}
              className="schedule-card"
              onClick={(e) => handleEventClicked(e, _event)}
              draggable
              onDragStart={(e) => handleDragStart(e, index, _event, false)}
              onDragEnter={(e) => handleDragEnter(e, index, _event)}
              onDragLeave={(e) => handleDragLeave(e, _event)}
              onDrop={(e) => handleDrop(e, _event)}
              onDragOver={(e) => e.preventDefault()}
            >
              <div className="calendar-icon" style={{ cursor: "move" }}>
                {/* <i class="fa fa-arrows-v" style={{ cursor: "move" }}></i> */}
                <FontAwesomeIcon icon={solid("grip-vertical")} style={{ cursor: "move" }}></FontAwesomeIcon>
                {/* <i className="far fa-calendar-alt"></i> */}
              </div>
              <div className="schedule">
                <p style={{ fontWeight: "700" }}>
                  {`${Common.formatTimeLuxon(_event.Start, null)} - ${Common.formatTimeLuxon(_event.End, null)}`}
                  <span>&nbsp;&nbsp;&nbsp;</span>
                  <i
                    title="Edit Place"
                    className="fa fa-pencil color-blue"
                    onClick={(e) => handleEditEvent(e, _event)}
                  ></i>

                  <span>&nbsp;&nbsp;&nbsp;</span>
                  {/* <i title="Menu" className="fa fa-ellipsis-h color-blue" onClick={(e) => showEventMenu(e, _event)}></i> */}
                  <FontAwesomeIcon
                    icon={solid("square-caret-down")}
                    className="color-blue"
                    onClick={(e) => showEventMenu(e, _event)}
                  ></FontAwesomeIcon>
                  <span>&nbsp;&nbsp;&nbsp;</span>

                  <i
                    title="Center on map"
                    className="fa fa-crosshairs color-blue"
                    // onClick={handleCenterInMap}
                    onMouseEnter={() => enterLeave(_event, true)}
                    onMouseLeave={() => enterLeave(_event, false)}
                  ></i>

                  {/* <i title="Menu" className="fa fa-ellipsis-h color-blue" onClick={(e) => showEventMenu(e, _event)}></i> */}

                  {/* <span>&nbsp;&nbsp;&nbsp;</span>
                  <i title="Menu" className="fa fa-plus-circle color-blue" onClick={(e) => addHour(e, _event)}></i>

                  <span>&nbsp;&nbsp;&nbsp;</span>
                  <i
                    title="Menu"
                    className="fa fa-minus-circle color-blue"
                    onClick={(e) => subtractHour(e, _event)}
                  ></i> */}
                </p>
                <p>{`${typeof _event.order_index === "undefined" ? "" : _event.order_index} ${_event.Subject}`}</p>
                {/* <p>{`${_event.Subject}`}</p> */}
              </div>
            </div>
          ))}
        </div>
      ))}
      <EventModal show={eventModal} onHide={() => setEventModal(false)} />
      {MenuModalShow ? (
        <MenuModal
          id="MenuModal"
          show={MenuModalShow}
          onHide={() => setMenuModalShow(false)}
          menu_modal_list={MenuModalList}
          menu_modal_heading={MenuModalHeading}
          menu_model_image={MenuModalImage}
          menu_modal_place={MenuModalPlace}
        />
      ) : null}
      {MoveEventsShow ? (
        <MoveEvents
          show={MoveEventsShow}
          onHide={() => setMoveEventsShow(false)}
          ScheduleName={ScheduleName}
          ScheduleNames={ScheduleNames}
          mode={Mode}
          param_date={ParamDate}
          callback={MoveEventsCallback}
        />
      ) : null}
      {ShowEventEdit ? (
        <EventEdit
          show={ShowEventEdit}
          onHide={() => setShowEventEdit(false)}
          event={event}
          mode={EventEditMode}
          place={null}
          where={EventEditWhere}
          schedule_name={ScheduleName}
          callback={() => EventEditCallback()}
        />
      ) : null}
    </div>
  );
};

export default SchedulePanel;
