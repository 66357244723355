import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import "./Modal.css";
import { Form, Button } from "react-bootstrap";
import HTMLEditorComponent from "./HTMLEditorComponent";

import AlertModal from "./AlertModal";
import ImageDialog from "./ImageDialog";
import IconsDialog from "./IconsDialog";
import MultiSelectModal from "./MultiSelectModal";
import HTMLEditorModal from "./HTMLEditorModal";

import Place from "../../Models/Place";
import Common, { SortByEnum, POI_IMAGES, COLORS } from "../../Common/Common";
import Appointment from "../../Models/Event";
import { DateTime, Settings } from "luxon";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
Settings.defaultZoneName = "UTC";

class MoveEvents extends Component {
  constructor(props) {
    super(props);
    // mode
    // "move_day",
    // "move_all_days",
    // "change_start_date",
    // "delete_day",
    // "change_start_date";

    const mode = this.props.mode;
    const param_date = this.props.param_date;
    let initialStart = DateTime.fromISO(window.guide_obj.schedule.getFirsEventStartDate(this.props.ScheduleName), {
      zone: "utc",
    });
    let initialEnd = DateTime.fromISO(window.guide_obj.schedule.getFirsEventEndDate(this.props.ScheduleName), {
      zone: "utc",
    });
    //console.log("param_date", param_date);
    if (param_date != null) {
      initialStart = param_date;
      initialEnd = param_date;
    }

    this.state = {
      initialStart: initialStart,
      initialEnd: initialEnd,

      dateStart: initialStart,
      dateEnd: initialStart,
      modeStart: "date",
      showStart: false,

      dateFrom: initialEnd,
      modeEnd: "date",
      showEnd: false,
      mode: mode,
      param_date: param_date,

      AlertModalShow: false,
      AlertModalTitle: "",
      AlertModalMessage: "",
      AlertModalButtons: [],
    };
  }

  moveEvents() {
    //console.log("moveEvents");
    //console.log("this.state", this.state);
    //console.log("this.state.mode", this.state.mode);
    this.state.dateStart = DateTime.fromISO(this.state.dateStart, { zone: "utc" });
    this.state.dateEnd = DateTime.fromISO(this.state.dateEnd, { zone: "utc" });
    if (this.state.mode == "delete_day") {
      window.guide_obj.schedule.deleteDay(this.state.dateStart, this.props.ScheduleName);
      this.props.callback();
      this.props.onHide();
      return;
    }
    if (this.state.mode == "move_day") {
      window.guide_obj.schedule.moveDay(this.state.dateStart, this.state.dateEnd, this.props.ScheduleName);
      this.props.callback();
      this.props.onHide();
      return;
    }
    if (this.state.mode == "set_day_start") {
      window.guide_obj.schedule.setDayStart(this.state.dateEnd, this.props.ScheduleName);
      this.props.callback();
      this.props.onHide();
      return;
    }
    if (this.state.mode == "move_all_days") {
      window.guide_obj.schedule.moveAllDays(this.state.dateStart, this.state.dateEnd, this.props.ScheduleName);
      this.props.callback();
      this.props.onHide();
      return;
    }
    if (this.state.mode == "shift_events") {
      window.guide_obj.schedule.shiftAllEvents(this.state.dateStart, this.state.dateEnd, this.props.ScheduleName);
      this.props.callback();
      this.props.onHide();
      return;
    }

    const callback = this.props.callback;
    if (callback == null) {
      this.shouldComponentUpdateMyAlert("Callback is null");
      return;
    }

    console.log(
      "changeStartDate",
      this.state.initialStart,
      this.state.dateEnd,
      this.state.dateStart,
      this.props.ScheduleName
    );
    window.guide_obj.schedule.changeStartDate(
      //Common.adjustForTimeZone(this.state.dateStart, true),
      this.state.initialStart,
      this.state.dateEnd,
      this.props.ScheduleName
    );
    this.props.callback();
    this.props.onHide();
  }
  onChangeStart = (event) => {
    const date = Common.LuxonDateFromJSDate(event);
    this.setState({ ...this.state, dateStart: date });
  };

  showModeStart = (currentMode) => {
    this.setState({ ...this.state, showStart: true, modeStart: currentMode });
  };

  showDatepickerStart = () => {
    this.showModeStart("date");
  };

  showTimepickerStart = () => {
    this.showModeStart("time");
  };

  onChangeEnd = (event) => {
    const date = Common.LuxonDateFromJSDate(event);
    this.setState({ ...this.state, dateEnd: date });
  };
  showModeEnd = (currentMode) => {
    this.setState({ ...this.state, showEnd: true, modeEnd: currentMode });
  };

  showDatepickerEnd = () => {
    this.showModeEnd("date");
  };

  showTimepickerEnd = () => {
    this.showModeEnd("time");
  };

  MyAlert = (title, message, buttons) => {
    this.setState({
      AlertModalTitle: title,
      AlertModalMessage: message,
      AlertModalButtons: buttons,
      AlertModalShow: true,
    });
  };

  render() {
    let startDateTime = "";
    let endDateTime = "";
    if (typeof this.state.dateStart == "undefined") return;
    //console.log("render: this.state.dateStart", this.state.dateStart);
    if (this.state.dateStart != "")
      startDateTime = `${Common.formatDateLuxon(this.state.dateStart)} ${Common.formatTimeLuxon(
        this.state.dateStart,
        false
      )}`;
    if (this.state.dateEnd != "")
      endDateTime = `${Common.formatDateLuxon(this.state.dateEnd)} ${Common.formatTimeLuxon(
        this.state.dateEnd,
        false
      )}`;

    let heading = "Move events from one day";
    let from_heading = "Date From";
    let to_heading = "Date To";
    if (this.state.mode == "move_day") {
      heading = "Move events from one day";
      from_heading = "Date From";
      to_heading = "Date To";
    } else if (this.state.mode == "move_all_days") {
      heading = "Move all days from to";
      from_heading = "Date From";
      to_heading = "Date To";
    } else if (this.state.mode == "set_day_start") {
      heading = "Set the day start time";
      from_heading = "";
      to_heading = "Start Date/Time";
    } else if (this.state.mode == "delete_day") {
      heading = "Delete events from one day";
      from_heading = "Delete day";
      to_heading = "";
    } else if (this.state.mode == "change_start_date") {
      heading = "Change itinerary start date";
      from_heading = "";
      to_heading = "New Start Date";
    } else if (this.state.mode == "shift_events") {
      heading = "Shift events starting from";
      from_heading = "";
      to_heading = "Shift to Date";
    }
    console.log("this.state.mode", this.state.mode);
    return (
      <div className="modal">
        <Modal {...this.props} size="md" aria-labelledby="contained-modal-title-vcenter" centered scrollable={true}>
          <Modal.Header>
            <div style={{ width: "100%" }}>
              <div className="sort">
                <div className="dialog-heading">
                  <div className="dialog-heading">
                    <div>
                      <h4 style={{ color: "#3598DB" }}>{heading}</h4>
                    </div>
                    <i className="fas fa-check" onClick={() => this.moveEvents()}></i>

                    <span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>

                    <div>
                      <i className={`fas fa-times`} onClick={() => this.props.onHide()}></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div style={{ minHeight: 400 }}>
              {["move_day", "delete_day", "move_all_days", "shift_events"].includes(this.state.mode) ? (
                <div>
                  <div>
                    <p className="attribute">{from_heading}</p>
                    <DatePicker
                      selected={Common.JSDateFromLuxonDate(this.state.dateStart)}
                      onChange={(date) => this.onChangeStart(date)}
                      showTimeSelect
                      // locale="en-GB"
                      timeFormat="HH:mm"
                      timeIntervals={5}
                      timeCaption="time"
                      dateFormat="MMMM d, yyyy HH:mm"
                    />
                  </div>
                </div>
              ) : null}

              {["move_day", "change_start_date", "move_all_days", "set_day_start", "shift_events"].includes(
                this.state.mode
              ) ? (
                <div>
                  <p className="attribute">{to_heading}</p>
                  <DatePicker
                    selected={Common.JSDateFromLuxonDate(this.state.dateEnd)}
                    onChange={(date) => this.onChangeEnd(date)}
                    showTimeSelect
                    // locale="en-GB"
                    timeFormat="HH:mm"
                    timeIntervals={5}
                    timeCaption="time"
                    dateFormat="MMMM d, yyyy HH:mm"
                  />
                </div>
              ) : null}
            </div>
          </Modal.Body>
        </Modal>
        <AlertModal
          show={this.state.AlertModalShow}
          onHide={() => this.setState({ AlertModalShow: false })}
          AlertModalTitle={this.state.AlertModalTitle}
          AlertModalMessage={this.state.AlertModalMessage}
          AlertModalButtons={this.state.AlertModalButtons}
        />
      </div>
    );
  }
}
export default MoveEvents;
