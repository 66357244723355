import DataBase from "../dataBase/liquidGuides";
import { DateTime } from "luxon";

export default class User {
  constructor() {
    this.data = {
      distance_unit: "kilometers",
      date_format: "Month first",
      edit_mode: true,
      advanced_mode: false,
      email: "guest@gmail.com",
      first_name: "guest",
      last_name: "user",
      uid: "guest",
      create_date: "",
      modify_date: "",
      travel_payouts_marker: "",
      last_guide_id: "",
      last_guide_time: "",
      payment_info: "",
      affiliate_program: false,
    };
  }

  // data = () => {
  //   return this.data;
  // };

  static fixUser = (user) => {
    if (typeof user.distance_unit == "undefined") user.distance_unit = "miles";
    if (typeof user.date_format == "undefined") user.date_format = "Month first";
    if (typeof user.edit_mode == "undefined") user.edit_mode = "On";
    if (typeof user.advanced_mode == "undefined") user.advanced_mode = "Off";
    if (typeof user.email == "undefined") user.email = null;
    if (typeof user.first_name == "undefined") user.first_name = null;
    if (typeof user.last_name == "undefined") user.last_name = null;
    if (typeof user.uid == "undefined") user.uid = null;
    if (typeof user.create_date == "undefined") user.create_date = null;
    if (typeof user.modify_date == "undefined") user.modify_date = null;
    if (typeof user.travel_payouts_marker == "undefined") user.travel_payouts_marker = "";
    if (typeof user.last_guide_id == "undefined") user.last_guide_id = "";
    if (typeof user.last_guide_time == "undefined") user.last_guide_time = "";
    if (user.create_date === null || user.create_date === "") user.create_date = DateTime.now().toISO({ zone: "utc" });
    if (user.modify_date === null || user.modify_date === "") user.modify_date = DateTime.now().toISO({ zone: "utc" });
    return user;
  };

  _callBack = null;
  static addUser = (user, callback) => {
    //console.log("User: AddUser");
    this._callBack = callback;
    DataBase.addUser(user, callback);
  };

  static addUserCallback = (res) => {
    //console.log("User: this._callBack", this._callBack);
    this._callBack(res);
  };

  static updateUser = (user, user_id, callback) => {
    this._callBack = callback;
    DataBase.updateUser(user, user_id, this.updateUserCallback);
  };

  static updateUserCallback = (res) => {
    //if (res.pass == true) res.res = this.fixUser(res.res);
    this._callBack(res);
  };

  static readUser = (uid, callback) => {
    this._callBack = callback;
    DataBase.readUser(uid, this.readUserCallback);
  };

  static readUserCallback = (res) => {
    if (res.pass == true) res.res = this.fixUser(res.res);
    this._callBack(res);
  };

  static findUserByUID = (uid, callback) => {
    this._callBack = callback;
    DataBase.findUserByUID(uid, this.findUserByUIDCallback);
  };

  static findUserByUIDCallback = (res) => {
    if (res.pass == true) res.res = this.fixUser(res.res);
    this._callBack(res);
  };

  static findUserByFBID = (fb_id, callback) => {
    this._callBack = callback;
    DataBase.findUserByFBID(fb_id, this.findUserByFBIDCallback);
  };

  static findUserByFBIDCallback = (res) => {
    if (res.pass == true) res.res = this.fixUser(res.res);
    this._callBack(res);
  };

  static findUserByEmail = (email, callback) => {
    this._callBack = callback;
    DataBase.findUserByEmail(email, this.findUserByEmailCallback);
  };

  static findUserByEmailCallback = (res) => {
    if (res.pass == true) res.res = this.fixUser(res.res);
    this._callBack(res);
  };
}
