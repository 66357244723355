import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import Place from "../../Models/Place";
import "./Modal.css";
import { Form } from "react-bootstrap";
import store from "../../store/store";
import { selectOne } from "../../store/reducer";
import { useDispatch } from "react-redux";
import { slice1Actions } from "../../store/reducer";
import { useSelector } from "react-redux";

const MultiSelectModal = (props) => {
  const [show] = useState(true);
  const [SearchText, setSearchText] = useState("");

  const [LocalItems, setLocalItems] = useState(props.MultiselectItems);
  const [DisplayedItems, setDisplayedItems] = useState([]);
  const [LocalSelectedItems, setLocalSelectedItems] = useState(props.MultiselectSelectedItems);
  const [LocalSearchText, setLocalSearchText] = useState(props.MultiselectSearchText);
  const MultiselectMode = props.MultiselectMode;
  const MultiselectCallback = props.MultiselectCallback;
  //const MultiselectTitle = useSelector((state) => state.MultiselectTitle);
  const MultiselectTitle = props.MultiselectTitle;

  const dispatch = useDispatch();

  useEffect(() => {
    let MultiselectItems = JSON.parse(JSON.stringify(LocalItems));
    let MultiselectSelectedItems = JSON.parse(JSON.stringify(LocalSelectedItems));
    if (!Array.isArray(MultiselectItems)) MultiselectItems = [MultiselectItems];

    //if MultiselectItems is an array of strings
    if (MultiselectItems.length > 0 && typeof MultiselectItems[0] == "string") {
      let _MultiselectItems = [];
      MultiselectItems.forEach((item) => {
        _MultiselectItems.push({
          label: item,
          value: item,
        });
      });
      MultiselectItems = _MultiselectItems;
    }

    //if id is not in object, add id
    if (MultiselectItems.length > 0 && typeof MultiselectItems[0].id == "undefined") {
      let id = 0;
      MultiselectItems.forEach((item) => {
        item.id = (id++).toString();
      });
    }

    if (!Array.isArray(MultiselectSelectedItems)) MultiselectSelectedItems = [MultiselectSelectedItems];

    //if MultiselectSelectedItems is an array of strings
    if (MultiselectSelectedItems.length > 0 && typeof MultiselectSelectedItems[0] == "string") {
      let _MultiselectSelectedItems = [];
      MultiselectSelectedItems.forEach((item) => {
        _MultiselectSelectedItems.push({
          label: item,
          value: item,
        });
      });
      MultiselectSelectedItems = _MultiselectSelectedItems;
    }

    //if id is not in MultiselectSelectedItems object add id
    if (MultiselectSelectedItems.length > 0 && typeof MultiselectSelectedItems[0].id == "undefined") {
      MultiselectSelectedItems.forEach((item) => {
        const index = MultiselectItems.findIndex((x) => x.label === item.label);
        if (index !== -1) {
          item.id = MultiselectItems[index].id;
        }
      });
    }

    MultiselectItems.forEach((item) => {
      item.checked = false;
    });
    //console.log("***********************************");
    if (LocalSearchText !== "" && LocalSearchText !== null) {
      let temp_list = [];
      const _searchText = LocalSearchText.toUpperCase();
      MultiselectItems.forEach((item) => {
        if (item.label.toUpperCase().includes(_searchText)) temp_list.push(item);
      });
      MultiselectItems = temp_list;
    }

    //Check the items that are selected
    MultiselectSelectedItems.forEach((item) => {
      if (typeof item.id == "undefined") {
        const index = MultiselectItems.findIndex((x) => x.value === item.value);
        if (index !== -1) MultiselectItems[index].checked = true;
      } else {
        const index = MultiselectItems.findIndex((x) => x.id === item.id);
        if (index !== -1) MultiselectItems[index].checked = true;
      }
    });
    setLocalItems(MultiselectItems);
    setDisplayedItems(MultiselectItems);
    setLocalSelectedItems(MultiselectSelectedItems);
  }, []);

  function handleChange(value) {
    const index = LocalItems.findIndex((x) => x.value === value);
    if (index === -1) return;
    //console.log("**************handleChange");
    if (MultiselectMode === "uni") {
      setLocalSelectedItems([LocalItems[index]]);
      if (MultiselectCallback != null) MultiselectCallback([LocalItems[index]]);
      props.onHide();
      return;
    }
    // let _MultiselectItems = LocalItems;
    // let newSelected = [];
    LocalItems[index].checked = !LocalItems[index].checked;
    handleSearchChanged(LocalSearchText);
    // _MultiselectItems.forEach((item) => {
    //   if (item.checked === true) newSelected.push(item);
    // });

    // setLocalSelectedItems(newSelected);
    // setDisplayedItems(_MultiselectItems);
  }

  function handleOK() {
    let temp_list = [];
    LocalItems.forEach((item) => {
      if (item.checked === true) temp_list.push(item);
    });
    if (MultiselectCallback != null) MultiselectCallback(temp_list);
    props.onHide();
  }

  const handleSelectAll = () => {
    let count = 0;
    LocalItems.forEach((item) => {
      if (item.checked === true) count++;
    });
    let newVal = true;
    if (count > 0) newVal = false;
    LocalItems.forEach((item) => {
      item.checked = newVal;
    });
    let newSelected = [];
    LocalItems.forEach((item) => {
      if (item.checked === true) newSelected.push(item);
    });

    setLocalSelectedItems(newSelected);
    setDisplayedItems(LocalItems);
  };

  const handleSearchChanged = (searchText) => {
    setLocalSearchText(searchText);
    let temp_list = [];
    const _searchText = searchText.toUpperCase();
    LocalItems.forEach((item) => {
      if (item.label.toUpperCase().includes(_searchText) || searchText === "") temp_list.push(item);
    });
    setDisplayedItems(temp_list);
  };

  return (
    <div className="modal">
      <Modal
        {...props}
        size={window.innerWidth < 450 ? "lg" : "md"}
        dialogClassName="myModalClass"
        aria-labelledby="contained-modal-title-vcenter"
        centered={false}
        scrollable={true}
      >
        <Modal.Header closeButton>
          <div style={{ width: "100%" }}>
            <div className="sort">
              <div className="dialog-heading">
                <div>
                  <h4 style={{ color: "blue" }}>{MultiselectTitle}</h4>
                </div>
              </div>
              <div className="dialog-heading">
                <input
                  autoFocus
                  type="text"
                  placeholder="Search"
                  onChange={(e) => handleSearchChanged(e.target.value)}
                />
                {MultiselectMode === "multi" ? (
                  <i className={`fas fa-tasks ${show ? "" : "color"}`} onClick={handleSelectAll}></i>
                ) : null}
                {MultiselectMode !== "uni" ? <i className={`fa fa-check`} onClick={handleOK}></i> : null}
              </div>
              {/* <Modal.Title id="example-modal-sizes-title-sm">{MultiselectTitle}</Modal.Title> */}
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="multiselect-list">
            {DisplayedItems.map((element, index) => (
              <div className="list-item-card cursor-pointer" key={index} onClick={() => handleChange(element.value)}>
                <div className="flex-container cursor-pointer" key={index}>
                  <div>
                    {element.icon != null && typeof element.icon != "undefined" ? (
                      <div className="flex-item-left">
                        {Place.getItemPOIImage(element.value) == null ? (
                          element.icon
                        ) : (
                          <img alt="img" src={Place.getItemPOIImage(element.value)} />
                        )}
                      </div>
                    ) : null}
                  </div>
                  <div className="flex-item-middle truncate cursor-pointer">{element.label}</div>
                  {MultiselectMode === "multi" ? (
                    <div className="flex-item-left d-flex cursor-pointer" key={index}>
                      <input checked={element.checked} className="cursor-pointer" type="checkbox" />
                      {/* <Form>
                      <Form.Group className="text-center" controlId="ShowDetails">
                        <Form.Check
                          // label={element.label}
                          type="checkbox"
                          id={`autoSizingCheck${index}`}
                          checked={element.checked}
                          //onChange={() => handleChange(element.value)}
                        />
                      </Form.Group>
                    </Form> */}
                    </div>
                  ) : null}
                </div>
              </div>
            ))}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default MultiSelectModal;
