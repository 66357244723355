//import { MyHashTable } from "../Common/Helpers";
import { html_entity_decode } from "../Common/Common_d";
import Appointment from "./Event";
import Place, { Place_Source } from "./Place";
import Places from "./Places";
import Common, { Settings, SortByEnum, COLORS, MyHashTable } from "../Common/Common";
import { DateTime, Interval, Duration } from "luxon";

export default class Schedule {
  constructor() {
    this.events = new Array();
  }
  decodeSchedule(guideString, scheduleStart, scheduleTag, GuideJson) {
    let _scheduleOBJ = null;
    if (GuideJson != null) {
      for (let i = 0; i < GuideJson.schedule.events.length; i++) {
        var ievent = new Appointment(this);
        ievent.json_to_appointment(this, GuideJson.schedule.events[i], false);
        ievent.ID = this.create_id();
        if (ievent.Places === null) ievent.Places = "";
        this.events.push(ievent);
      }
      this.events.sort(compare);
      // let __shed = this.events.filter((x) => x.Itinerary == "One Day Tour");
      // console.log("__shed", __shed);
    } else {
      if (scheduleStart == -1) _scheduleOBJ = null;
      else {
        //console.log("Schedule");
        var ScheduleString = html_entity_decode(guideString.substring(scheduleStart + scheduleTag.length)); //.replace("\\\\\"", "\\\"").replace("\\r", "\r\n").replace("\\t", "\t").replace("\\n", "\n").replace("\\/", "/").replace("\\ ", "\"").replace("\\\n", "\n");
        _scheduleOBJ = JSON.parse(ScheduleString);
        this.events = new Array();
        for (var key in _scheduleOBJ) {
          var ievent = new Appointment(this);
          ievent.json_to_appointment(this, _scheduleOBJ[key], true);
          ievent.ID = this.create_id();
          this.events.push(ievent);
        }
        this.events.sort(compare);
        // let __shed = this.events.filter((x) => x.Itinerary == "One Day Tour");
        // console.log("__shed", __shed);
        //if (guide_obj.cache_data == true) this.loadDataFromStorage();
      }
    }
  }

  create_id() {
    var r = Math.floor(Math.random() * 1000000000 + 1);
    while (this.get_event(r) != null) r = Math.floor(Math.random() * 1000000000 + 1);
    return r.toString();
  }

  geScheduleNames() {
    let Itineraries = [{ label: "Main", value: "Main" }];
    for (let i = 0; i < this.events.length; i++) {
      if (typeof this.events[i].Itinerary == "undefined") continue;
      const Itinerary = this.events[i].Itinerary.replace(/%20/g, " ");
      const index = Itineraries.findIndex((x) => x.label == Itinerary);
      if (index != -1) continue;
      Itineraries.push({
        label: Itinerary,
        value: Itinerary,
      });
    }
    //Itineraries = Array.from(new Set(Itineraries));
    Itineraries.sort((a, b) => (a.label.toUpperCase() > b.label.toUpperCase() ? 1 : -1));
    //console.log(Itineraries);
    return Itineraries;
  }

  getStartEndDate(itinerary) {
    //console.log("getStartEndDate", itinerary);

    let filtered_events = [];
    for (let i = 0; i < this.events.length; i++) {
      const _itinerary = typeof this.events[i].Itinerary == "undefined" ? "Main" : this.events[i].Itinerary;
      if (_itinerary == itinerary) filtered_events.push(this.events[i]);
    }

    if (filtered_events.length == 0) return { startDate: DateTime.now(), endDate: DateTime.now() };
    let startDate = DateTime.fromISO("3000-11-24", { zone: "utc" });
    let endDate = DateTime.fromISO("1000-11-24", { zone: "utc" });
    for (let i = 0; i < filtered_events.length; i++) {
      if (DateTime.fromISO(filtered_events[i].Start, { zone: "utc" }) < startDate)
        startDate = DateTime.fromISO(filtered_events[i].Start, { zone: "utc" });
      if (DateTime.fromISO(filtered_events[i].End, { zone: "utc" }) > endDate)
        endDate = DateTime.fromISO(filtered_events[i].End, { zone: "utc" });
    }
    //console.log({ startDate: startDate, endDate: endDate });
    return { startDate: startDate, endDate: endDate };
  }

  getFirsEventStartDate(itinerary) {
    const startEndDate = this.getStartEndDate(itinerary);
    return startEndDate.startDate;
  }
  getFirsEventEndDate(itinerary) {
    const startEndDate = this.getStartEndDate(itinerary);
    return startEndDate.endDate;
  }
  // getISODateString(end) {
  //   startEndDate = this.getStartEndDate();
  //   let d = startEndDate.startDate;
  //   if (end == true) d = startEndDate.endDate;
  //   const d_ISO = d.toISOString();
  //console.log(d_ISO);
  //   if (d_ISO.includes("T")) {
  //     const parts = d_ISO.split("T");
  //     return parts[0];
  //   }
  //   return "";
  // }
  getPlaces_InSchedule(itinerary) {
    if (itinerary == null) itinerary = "Main";
    window.listPlaceTimes = new MyHashTable();
    window.listPlaceEvents = new MyHashTable();
    for (var i = 0; i < this.events.length; i++) {
      const _itinerary = typeof this.events[i].Itinerary == "undefined" ? "Main" : this.events[i].Itinerary;
      if (_itinerary != itinerary) continue;
      var placeIDString = this.events[i].Places;
      if (placeIDString == null) continue;
      if (placeIDString == "") continue;
      var placeIDs = placeIDString.split(",");
      for (var j = 0; j < placeIDs.length; j++) {
        if (placeIDs[j] == null) continue;
        if (!window.listPlaceTimes.hasItem(placeIDs[j])) {
          window.listPlaceTimes.setItem(placeIDs[j], new Array());
          window.listPlaceEvents.setItem(placeIDs[j], new Array());
        }
        var dates = window.listPlaceTimes.getItem(placeIDs[j]);
        var date = new Date(this.events[i].Start);
        dates.push(date);
        window.listPlaceTimes.setItem(placeIDs[j], dates);
        var events = window.listPlaceEvents.getItem(placeIDs[j]);
        events.push(this.events[i]);
        window.listPlaceEvents.setItem(placeIDs[j], events);
      }
    }
  }
  countTimesInSchedule() {
    let eventsCountArray = [];
    for (let i = 0; i < this.events.length; i++) {
      let placesArray = this.events[i].Places.split(",");
      placesArray.forEach((placeID) => {
        const index = eventsCountArray.findIndex((x) => x.placeID === placeID.trim());
        if (index === -1) {
          const new_entry = { placeID: placeID, count: 1 };
          eventsCountArray.push(new_entry);
        } else eventsCountArray[index].count += 1;
      });
    }
    return eventsCountArray;
  }

  findPlace_InSchedule(searchText, itinerary, place, _Places) {
    if (itinerary == null) itinerary = "Main";
    const events = this.getScheduleToDisplay2(searchText, itinerary, _Places);
    for (let i = 0; i < events.length; i++) {
      for (let j = 0; j < events[i].data.length; j++) {
        let placesString = events[i].data[j].Places;
        let placesArray = placesString.split(",");
        if (placesArray.includes(place.ID)) return { section: i, item: j };
      }
    }
    return null;
  }

  getEventsFromSchedule(itinerary) {
    let events = [];
    this.events.forEach((event) => {
      const _itinerary = typeof event.Itinerary == "undefined" ? "Main" : event.Itinerary;
      if (_itinerary === itinerary) events.push(event);
    });
    return events;
  }

  findEventByName(event_title, itinerary) {
    this.events.forEach((event) => {
      const _itinerary = typeof event.Itinerary == "undefined" ? "Main" : event.Itinerary;
      if (_itinerary === itinerary && event.title === event_title) return event;
    });
    return null;
  }
  getScheduleToDisplay(itinerary) {
    if (itinerary == null) itinerary = "Main";
    let eventsList = [];
    //console.log("this.all_places", this.all_places);
    this.events.forEach((event) => {
      const _itinerary = typeof event.Itinerary == "undefined" ? "Main" : event.Itinerary;
      if (_itinerary != itinerary) return;
      eventsList.push({
        id: event.ID.toString(),
        start: event.Start,
        end: event.End,
        title: event.Subject,
        summary: event.Body,
        event: event,
      });
    });
    //placesList.forEach((x) => //console.log(x.Name));
    eventsList.sort((a, b) =>
      new Date(a.start).getTime() < new Date(b.start).getTime()
        ? -1
        : new Date(a.start).getTime() > new Date(b.start).getTime()
        ? 1
        : 0
    );
    return eventsList;
  }

  getEventsToDisplay(itinerary) {
    if (itinerary == null) itinerary = "Main";
    let eventsList = [];
    this.events.forEach((event) => {
      const _itinerary = typeof event.Itinerary == "undefined" ? "Main" : event.Itinerary;
      if (_itinerary != itinerary) return;
      eventsList.push({
        id: event.ID.toString(),
        name: `${Common.formatDate(event.Start)}, ${Common.formatTimeLuxon(event.Start, null)}`,
        value: event,
        label: `${Common.formatDate(event.Start)}, ${Common.formatTimeLuxon(event.Start, null)}`,
        start: event.Start,
        end: event.End,
        title: event.Subject,
        summary: event.Body,
      });
    });
    eventsList.sort((a, b) =>
      new Date(a.start).getTime() < new Date(b.start).getTime()
        ? -1
        : new Date(a.start).getTime() > new Date(b.start).getTime()
        ? 1
        : 0
    );
    return eventsList;
  }

  // getChaptersSectionsToDisplay() {
  //   let sections = [];
  //   // //console.log(this.chapters);
  //   for (let j = 0; j < this.chapters.length; j++) {
  //     let section = { Title: this.chapters[j].Title, data: [], index: j + 1 };
  //     for (let i = 0; i < this.chapters[j].sections.length; i++) {
  //       let _sec = this.chapters[j].sections[i];
  //       _sec.Chapter = this.chapters[j].Title;
  //       _sec.ChapterID = this.chapters[j].ID;
  //       section.data.push(_sec);
  //     }
  //     sections.push(section);
  //   }
  //   // //console.log(sections);
  //   return sections;
  // }

  changeStartDate(oldStartDate, newStartDate, itinerary) {
    //console.log("oldStartDate, newStartDate", oldStartDate, newStartDate);
    if (itinerary == null) itinerary = "Main";
    //var timeDiff = newStartDate.getTime() - oldStartDate.getTime();
    var timeDiff = newStartDate.diff(oldStartDate);
    //console.log("changeStartDate", oldStartDate, newStartDate, timeDiff);

    // days difference
    //var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

    this.events.forEach((event) => {
      const _itinerary = typeof event.Itinerary == "undefined" ? "Main" : event.Itinerary;
      if (_itinerary != itinerary) return;
      event.Start = DateTime.fromISO(event.Start, { zone: "utc" })
        .plus({ seconds: timeDiff / 1000 })
        .toISO({ zone: "utc" });
      event.End = DateTime.fromISO(event.End, { zone: "utc" })
        .plus({ seconds: timeDiff / 1000 })
        .toISO({ zone: "utc" });
    });
    //console.log(this.events);
  }

  moveDay(oldStartDate, newStartDate, itinerary) {
    if (itinerary == null) itinerary = "Main";
    var timeDiff = newStartDate.diff(oldStartDate);
    //console.log(oldStartDate, newStartDate, timeDiff);
    this.events.forEach((event) => {
      const _itinerary = typeof event.Itinerary == "undefined" ? "Main" : event.Itinerary;
      if (_itinerary != itinerary) return;
      let startDate = DateTime.fromISO(event.Start, { zone: "utc" });
      if (
        startDate.year === oldStartDate.year &&
        startDate.month === oldStartDate.month &&
        startDate.day === oldStartDate.day &&
        startDate.hour >= oldStartDate.hour &&
        startDate.minute >= oldStartDate.minute
      ) {
        event.Start = DateTime.fromISO(event.Start, { zone: "utc" })
          .plus({ seconds: timeDiff / 1000 })
          .toISO({ zone: "utc" });
        event.End = DateTime.fromISO(event.End, { zone: "utc" })
          .plus({ seconds: timeDiff / 1000 })
          .toISO({ zone: "utc" });
      }
    });
    //console.log(this.events);
  }

  setDayStart(newStartDate, itinerary) {
    console.log("newStartDate", newStartDate);
    if (itinerary == null) itinerary = "Main";
    let sortedEvents = [];
    this.events.forEach((event) => {
      const _itinerary = typeof event.Itinerary == "undefined" ? "Main" : event.Itinerary;
      if (_itinerary !== itinerary) return;
      var event_lux = DateTime.fromISO(event.Start);
      console.log("newStartDate.day", newStartDate.day);
      console.log("event_lux.day", DateTime.fromISO(event.Start).day);
      if (newStartDate.day !== event_lux.day) return;
      if (newStartDate.month !== event_lux.month) return;
      if (newStartDate.year !== event_lux.year) return;
      sortedEvents.push(event);
    });
    console.log("sortedEvents", sortedEvents);

    sortedEvents = sortedEvents.sort((a, b) =>
      new Date(a.Start).getTime() < new Date(b.Start).getTime()
        ? -1
        : new Date(a.Start).getTime() > new Date(b.Start).getTime()
        ? 1
        : 0
    );
    var timeDiff = newStartDate.diff(DateTime.fromISO(sortedEvents[0].Start));

    sortedEvents.forEach((event) => {
      event.Start = DateTime.fromISO(event.Start, { zone: "utc" })
        .plus({ seconds: timeDiff / 1000 })
        .toISO({ zone: "utc" });
      event.End = DateTime.fromISO(event.End, { zone: "utc" })
        .plus({ seconds: timeDiff / 1000 })
        .toISO({ zone: "utc" });
    });
  }

  shiftDayEvents(oldStartDate, newStartDate, itinerary, exclude, down) {
    if (itinerary == null) itinerary = "Main";
    var timeDiff = newStartDate.diff(oldStartDate);
    //console.log(oldStartDate, newStartDate, timeDiff);
    let count = 0;
    this.events.forEach((event) => {
      const _itinerary = typeof event.Itinerary == "undefined" ? "Main" : event.Itinerary;
      if (_itinerary !== itinerary) return;
      let startDate = DateTime.fromISO(event.Start, { zone: "utc" });
      if (
        startDate.year === oldStartDate.year &&
        startDate.month === oldStartDate.month &&
        startDate.day === oldStartDate.day
      ) {
        if (down) {
          if (startDate >= oldStartDate && event.ID !== exclude.ID) {
            event.Start = DateTime.fromISO(event.Start, { zone: "utc" })
              .plus({ seconds: timeDiff / 1000 })
              .toISO({ zone: "utc" });
            event.End = DateTime.fromISO(event.End, { zone: "utc" })
              .plus({ seconds: timeDiff / 1000 })
              .toISO({ zone: "utc" });
          }
        } else {
          if (startDate <= oldStartDate && event.ID !== exclude.ID) {
            count++;
            if (count >= 2) return;
            event.Start = DateTime.fromISO(event.Start, { zone: "utc" })
              .minus({ seconds: timeDiff / 1000 })
              .toISO({ zone: "utc" });
            event.End = DateTime.fromISO(event.End, { zone: "utc" })
              .minus({ seconds: timeDiff / 1000 })
              .toISO({ zone: "utc" });
            //only do 2
            //return;
          }
        }
      }
    });
    //console.log(this.events);
  }

  shiftAllEvents(oldStartDate, newStartDate, itinerary) {
    if (itinerary == null) itinerary = "Main";
    var timeDiff = newStartDate.diff(oldStartDate);
    let op_events = [];
    this.events.forEach((event) => {
      const _itinerary = typeof event.Itinerary == "undefined" ? "Main" : event.Itinerary;
      if (_itinerary !== itinerary) return;
      let startDate = DateTime.fromISO(event.Start, { zone: "utc" });
      if (startDate >= oldStartDate) {
        op_events.push(event);
      }
    });
    op_events.forEach((event) => {
      const index = this.events.findIndex((x) => x.ID === event.ID);
      if (index === -1) return;
      this.events[index].Start = DateTime.fromISO(this.events[index].Start, { zone: "utc" })
        .plus({ seconds: timeDiff / 1000 })
        .toISO({ zone: "utc" });
      this.events[index].End = DateTime.fromISO(this.events[index].End, { zone: "utc" })
        .plus({ seconds: timeDiff / 1000 })
        .toISO({ zone: "utc" });
    });
    //console.log(this.events);
  }

  reorderEventsOLD(fromEvent, toEvent, itinerary) {
    if (itinerary == null) itinerary = "Main";
    let sortedEvents = [];
    this.events.forEach((event) => {
      const _itinerary = typeof event.Itinerary == "undefined" ? "Main" : event.Itinerary;
      if (_itinerary !== itinerary) return;
      sortedEvents.push(event);
    });
    sortedEvents = sortedEvents.sort((a, b) =>
      new Date(a.Start).getTime() < new Date(b.Start).getTime()
        ? -1
        : new Date(a.Start).getTime() > new Date(b.Start).getTime()
        ? 1
        : 0
    );
    const fromIndex = sortedEvents.findIndex((x) => x.ID === fromEvent.ID);
    const toIndex = sortedEvents.findIndex((x) => x.ID === toEvent.ID);
    if (fromIndex === -1 || toIndex === -1 || fromIndex === toIndex) return;
    let sooner = toIndex <= fromIndex ? true : false;
    var timeDiff = DateTime.fromISO(fromEvent.End).diff(DateTime.fromISO(fromEvent.Start));
    if (sooner) {
      sortedEvents[fromIndex].Start = DateTime.fromISO(sortedEvents[toIndex].Start, { zone: "utc" });
      sortedEvents[fromIndex].End = DateTime.fromISO(sortedEvents[toIndex].Start, { zone: "utc" })
        .plus({ seconds: timeDiff / 1000 })
        .toISO({ zone: "utc" });
      for (let x = toIndex; x < fromIndex; x++) {
        let calc = true;
        if (x !== 0 && x > toIndex + 1 && sortedEvents[x].Start >= sortedEvents[x - 1].End) calc = false;
        if (calc) {
          sortedEvents[x].Start = DateTime.fromISO(sortedEvents[x].Start, { zone: "utc" })
            .plus({ seconds: timeDiff / 1000 })
            .toISO({ zone: "utc" });
          sortedEvents[x].End = DateTime.fromISO(sortedEvents[x].End, { zone: "utc" })
            .plus({ seconds: timeDiff / 1000 })
            .toISO({ zone: "utc" });
        }
      }
    } else {
      sortedEvents[fromIndex].End = DateTime.fromISO(sortedEvents[toIndex].End, { zone: "utc" });
      sortedEvents[fromIndex].Start = DateTime.fromISO(sortedEvents[toIndex].End, { zone: "utc" })
        .minus({ seconds: timeDiff / 1000 })
        .toISO({ zone: "utc" });
    }
    for (let x = fromIndex; x < toIndex; x++) {
      let calc = true;
      if (x !== 0 && x > toIndex - 1 && sortedEvents[x].Start >= sortedEvents[x - 1].End) calc = false;
      if (calc) {
        sortedEvents[x].Start = DateTime.fromISO(sortedEvents[x].Start, { zone: "utc" })
          .minus({ seconds: timeDiff / 1000 })
          .toISO({ zone: "utc" });
        sortedEvents[x].End = DateTime.fromISO(sortedEvents[x].End, { zone: "utc" })
          .minus({ seconds: timeDiff / 1000 })
          .toISO({ zone: "utc" });
      }
    }
  }

  reorderEvents(fromEvent, toEvent, itinerary) {
    if (typeof fromEvent === "undefined" || typeof toEvent === "undefined") return;
    if (itinerary == null) itinerary = "Main";
    let sortedEvents = [];
    this.events.forEach((event) => {
      const _itinerary = typeof event.Itinerary == "undefined" ? "Main" : event.Itinerary;
      if (_itinerary !== itinerary) return;
      sortedEvents.push(event);
    });
    sortedEvents = sortedEvents.sort((a, b) =>
      new Date(a.Start).getTime() < new Date(b.Start).getTime()
        ? -1
        : new Date(a.Start).getTime() > new Date(b.Start).getTime()
        ? 1
        : 0
    );
    const fromIndex = sortedEvents.findIndex((x) => x.ID === fromEvent.ID);
    const toIndex = sortedEvents.findIndex((x) => x.ID === toEvent.ID);
    if (fromIndex === -1 || toIndex === -1 || fromIndex === toIndex) return;
    const move_to_later = toIndex > fromIndex;
    var fromEventDuration = DateTime.fromISO(fromEvent.End).diff(DateTime.fromISO(fromEvent.Start));

    sortedEvents[fromIndex].Start = DateTime.fromISO(sortedEvents[toIndex].End, { zone: "utc" });
    sortedEvents[fromIndex].End = DateTime.fromISO(sortedEvents[toIndex].End, { zone: "utc" })
      .plus({ seconds: fromEventDuration / 1000 })
      .toISO({ zone: "utc" });
    //if (move_to_later) {
    //console.log("*****move_to_later");
    for (let x = toIndex + 1; x < sortedEvents.length - 1; x++) {
      if (x === fromIndex) continue;
      //console.log("*****day", sortedEvents[x].Start, sortedEvents[toIndex].Start.day);
      if (sortedEvents[x].Start.day !== sortedEvents[toIndex].Start.day) continue;
      sortedEvents[x].Start = DateTime.fromISO(sortedEvents[x].Start, { zone: "utc" })
        .plus({ seconds: fromEventDuration / 1000 })
        .toISO({ zone: "utc" });
      sortedEvents[x].End = DateTime.fromISO(sortedEvents[x].End, { zone: "utc" })
        .plus({ seconds: fromEventDuration / 1000 })
        .toISO({ zone: "utc" });
    }
    //} //else {
    // for (let x = toIndex + 1; x < sortedEvents.length-1; x++) {
    //   if(sortedEvents[x].Start.day !== sortedEvents[toIndex].day) continue;
    //     sortedEvents[x].Start = DateTime.fromISO(sortedEvents[x].Start, { zone: "utc" })
    //       .plus({ seconds: fromEventDuration / 1000 })
    //       .toISO({ zone: "utc" });
    //     sortedEvents[x].End = DateTime.fromISO(sortedEvents[x].End, { zone: "utc" })
    //       .plus({ seconds: fromEventDuration / 1000 })
    //       .toISO({ zone: "utc" });
    // }
  }

  reorderEventsNew(drag_event, drop_event, itinerary) {
    if (typeof drag_event === "undefined" || typeof drop_event === "undefined") return;
    if (drag_event.ID === drop_event.ID) return;
    if (itinerary == null) itinerary = "Main";

    let sortedEvents = [];
    this.events.forEach((event) => {
      const _itinerary = typeof event.Itinerary == "undefined" ? "Main" : event.Itinerary;
      if (_itinerary !== itinerary) return;
      if (event.ID === drag_event.ID) return;
      sortedEvents.push(event);
    });
    sortedEvents = sortedEvents.sort((a, b) =>
      new Date(a.Start).getTime() < new Date(b.Start).getTime()
        ? -1
        : new Date(a.Start).getTime() > new Date(b.Start).getTime()
        ? 1
        : 0
    );
    const fromIndex = sortedEvents.findIndex((x) => x.ID === drag_event.ID);
    const toIndex = sortedEvents.findIndex((x) => x.ID === drop_event.ID);
    if (fromIndex === -1 || toIndex === -1 || fromIndex === toIndex) return;
    // const after_dropIndex = toIndex === sortedEvents.length - 1 ? toIndex : toIndex + 1;
    // const before_dragIndex = fromIndex === 0 ? 0 : fromIndex - 1;

    var duration_of_drag_event = DateTime.fromISO(drag_event.End).diff(DateTime.fromISO(drag_event.Start));
    // var gap_drop_after_drop = DateTime.fromISO(drop_event.End).diff(
    //   DateTime.fromISO(sortedEvents[after_dropIndex].Start)
    // );
    // let sooner = toIndex <= fromIndex ? true : false;
    //if (gap_drop_after_drop >= duration_of_drag_event) {
    for (let x = toIndex + 1; x < sortedEvents.length - 1; x++) {
      sortedEvents[x].Start = DateTime.fromISO(sortedEvents[x].Start, { zone: "utc" })
        .plus({ seconds: duration_of_drag_event / 1000 })
        .toISO({ zone: "utc" });
      sortedEvents[x].End = DateTime.fromISO(sortedEvents[x].End, { zone: "utc" })
        .plus({ seconds: duration_of_drag_event / 1000 })
        .toISO({ zone: "utc" });
    }
    sortedEvents[fromIndex].Start = sortedEvents[toIndex].End;
    sortedEvents[fromIndex].End = DateTime.fromISO(sortedEvents[fromIndex].Start, { zone: "utc" })
      .plus({ seconds: duration_of_drag_event / 1000 })
      .toISO({ zone: "utc" });
    //}
  }

  recalculateScheduleOLD(itinerary, event_time_rounding, event_default_duration, _Places) {
    if (this.events.length === 0) return;
    if (itinerary == null) itinerary = "Main";
    let sortedEvents = [];
    let rounding_to = event_time_rounding;
    if (typeof rounding_to === "undefined") rounding_to = 30;
    this.events.forEach((event) => {
      const _itinerary = typeof event.Itinerary == "undefined" ? "Main" : event.Itinerary;
      if (_itinerary !== itinerary) return;
      sortedEvents.push(event);
    });
    sortedEvents = sortedEvents.sort((a, b) =>
      new Date(a.Start).getTime() < new Date(b.Start).getTime()
        ? -1
        : new Date(a.Start).getTime() > new Date(b.Start).getTime()
        ? 1
        : 0
    );
    let travel_time = 0;
    let last_place = null;
    let cur_place = null;
    let dayDate = DateTime.fromISO(sortedEvents[0].Start);
    let thisDay = dayDate;
    let firstEventOfDay = true;
    for (let x = 0; x < sortedEvents.length; x++) {
      if (dayDate.day !== thisDay.day) {
        thisDay = dayDate;
        firstEventOfDay = true;
      } else firstEventOfDay = false;
      cur_place = null;
      const event = sortedEvents[x];
      let duration = 0;
      if (event.Places !== null && event.Places !== "") {
        let place_ids = event.Places.split(",");
        for (let p = 0; p < place_ids.length; p++) {
          const place = _Places.getPlace(place_ids[p]);
          if (place != null) {
            duration += place.Duration;
            cur_place = place;
          }
        }
        if (duration === 0) duration = event_default_duration;
      }
      travel_time = 0;
      if (x > 0 && x < sortedEvents.length && last_place !== null && cur_place != null) {
        const p1 = { latitude: last_place.Lat, longitude: last_place.Longi };
        const p2 = { latitude: cur_place.Lat, longitude: cur_place.Longi };
        let distance = Places.calculateDistance(p1, p2) * 1.2;
        travel_time = distance * 10;
        if (travel_time > 45) travel_time = distance * 2;
        if (rounding_to === 0) travel_time = 0;
        else travel_time = Math.max(1, Math.round(travel_time / rounding_to)) * rounding_to;
      }
      if (cur_place !== null) last_place = cur_place;
      if (dayDate.hour + travel_time / 60 + duration / 60 > 18) {
        dayDate = dayDate.plus({ hours: 24 });
        dayDate = dayDate.set({ hour: 11 });
        dayDate = dayDate.set({ minutes: 0 });
      }
      sortedEvents[x].Start = dayDate.toISO();
      if (!firstEventOfDay || travel_time > 30) dayDate = dayDate.plus({ minutes: travel_time });
      //event.Start = dayDate.toISO();
      dayDate = dayDate.plus({ minutes: duration });
      sortedEvents[x].End = dayDate.toISO();
      if (dayDate.hour >= 17) {
        dayDate = dayDate.plus({ hours: 24 });
        dayDate = dayDate.set({ hour: 11 });
        dayDate = dayDate.set({ minutes: 0 });
      }
    }
  }

  recalculateSchedule(itinerary, event_time_rounding, event_default_duration, _Places, geography) {
    if (this.events.length === 0) return;
    if (itinerary == null) itinerary = "Main";
    let sortedEvents = [];
    let rounding_to = event_time_rounding;
    if (typeof rounding_to === "undefined") rounding_to = 30;
    this.events.forEach((event) => {
      const _itinerary = typeof event.Itinerary == "undefined" ? "Main" : event.Itinerary;
      if (_itinerary !== itinerary) return;
      sortedEvents.push(event);
    });
    if (geography === true) {
      let eventPlaces = [];
      let hashMap = [];
      for (let x = 0; x < sortedEvents.length; x++) {
        const event = sortedEvents[x];
        if (event.Places !== null && event.Places !== "") {
          let place_ids = event.Places.split(",");
          const place = _Places.getPlace(place_ids[0]);
          eventPlaces.push(place);
          hashMap.push({ event: event, place_id: place.ID });
        }
      }
      let PlaceOrderByDistance = Common.getPlaceOrderByDistance(null, window.guide_obj, eventPlaces);
      sortedEvents = [];
      for (let i = 0; i < PlaceOrderByDistance.length; i++) {
        const index = hashMap.findIndex((x) => x.place_id === PlaceOrderByDistance[i].ID);
        if (index === -1) continue;
        sortedEvents.push(hashMap[index].event);
      }
    } else {
      sortedEvents = sortedEvents.sort((a, b) =>
        new Date(a.Start).getTime() < new Date(b.Start).getTime()
          ? -1
          : new Date(a.Start).getTime() > new Date(b.Start).getTime()
          ? 1
          : 0
      );
    }
    if (sortedEvents.length === 0) return;
    let travel_time = 0;
    let last_place = null;
    let cur_place = null;
    let dayDate = DateTime.fromISO(sortedEvents[0].Start);
    let thisDay = dayDate;
    let firstEventOfDay = true;
    for (let x = 0; x < sortedEvents.length; x++) {
      if (dayDate.day !== thisDay.day) {
        thisDay = dayDate;
        firstEventOfDay = true;
      } else firstEventOfDay = false;
      cur_place = null;
      const event = sortedEvents[x];
      let duration = 0;
      if (event.Places !== null && event.Places !== "") {
        let place_ids = event.Places.split(",");
        for (let p = 0; p < place_ids.length; p++) {
          const place = _Places.getPlace(place_ids[p]);
          if (place != null) {
            duration += place.Duration;
            cur_place = place;
          }
        }
        if (duration === 0) duration = event_default_duration;
      }
      travel_time = 0;
      if (x > 0 && x < sortedEvents.length && last_place !== null && cur_place != null) {
        const p1 = { latitude: last_place.Lat, longitude: last_place.Longi };
        const p2 = { latitude: cur_place.Lat, longitude: cur_place.Longi };
        let distance = Places.calculateDistance(p1, p2) * 1.2;
        travel_time = distance * 10;
        if (travel_time > 45) travel_time = distance * 2;
        if (rounding_to === 0) travel_time = 0;
        else travel_time = Math.max(1, Math.round(travel_time / rounding_to)) * rounding_to;
      }
      if (cur_place !== null) last_place = cur_place;
      if (dayDate.hour + travel_time / 60 + duration / 60 > 20) {
        dayDate = dayDate.plus({ hours: 24 });
        dayDate = dayDate.set({ hour: 11 });
        dayDate = dayDate.set({ minutes: 0 });
      }
      sortedEvents[x].Start = dayDate.toISO();
      if (!firstEventOfDay || travel_time > 30) dayDate = dayDate.plus({ minutes: travel_time });
      //event.Start = dayDate.toISO();
      dayDate = dayDate.plus({ minutes: duration });
      sortedEvents[x].End = dayDate.toISO();
      if (dayDate.hour >= 19) {
        dayDate = dayDate.plus({ hours: 24 });
        dayDate = dayDate.set({ hour: 11 });
        dayDate = dayDate.set({ minutes: 0 });
      }
    }
  }

  reorderDays(
    drag_day,
    day_before_drag_day,
    day_after_drag_day,
    drop_day,
    day_after_drop_day,
    itinerary,
    drop_is_last
  ) {
    //if (DateTime.fromISO(drag_day.date) === DateTime.fromISO(drop_day.date)) return;
    if (
      drag_day.date.day === drop_day.date.day &&
      drag_day.date.month === drop_day.date.month &&
      drag_day.date.year === drop_day.date.year
    )
      return;
    if (
      drag_day.date.day === drop_day.date.day + 1 &&
      drag_day.date.month === drop_day.date.month &&
      drag_day.date.year === drop_day.date.year
    )
      return;
    let gap_day = null;
    //let after_delta;
    //If day_after_drop_day is more than 1 day more than drop_day or drop_day is last_day
    //- move all events in drag_day to drop_day + 1 day)
    var drop_day_plus_1 = new DateTime(drop_day.date);
    drop_day_plus_1 = drop_day_plus_1.plus({ days: 1 });

    var day_after_drop_day_plus_1 = new DateTime(day_after_drop_day.date);
    day_after_drop_day_plus_1 = day_after_drop_day_plus_1.plus({ days: 1 });

    var drop_day_minus_1 = new DateTime(drop_day.date);
    drop_day_minus_1 = drop_day_minus_1.minus({ days: 1 });

    var day_after_drag_day_minus_1 = new DateTime(day_after_drag_day.date);
    day_after_drag_day_minus_1 = day_after_drag_day_minus_1.minus({ days: 1 });

    //let day_diff = day_after_drop_day.date.diff(drop_day.date, "days").days;
    //let more_than_one_day = day_after_drop_day.date.day - drop_day.date.day > 1;

    if ((day_after_drop_day !== null && day_after_drop_day.date.day - drop_day.date.day > 1) || drop_is_last) {
      this.shiftDayEvents(drag_day, drop_day_plus_1, itinerary, { ID: -1 }, true);
    } else {
      // - move all events from drag_day to some earlier year
      // - shift all events in days after drop_day by +1 day
      // - move all events in drag_day (pereviously moved to earlier year) to drop_day + 1 day)
      // - if any day after drop_day is more than 1 day from previous day
      //   - shift all days starting from gap_day by -1 day
      let save_date = drag_day.date;
      save_date = save_date.minus({ years: 100 });
      this.shiftDayEvents(drag_day.date, save_date, itinerary, { ID: -1 }, true);
      this.moveAllDays(day_after_drop_day.date, day_after_drop_day_plus_1, itinerary, drag_day.date);
      this.shiftDayEvents(save_date, drop_day_plus_1, itinerary, { ID: -1 }, true);
    }
    //   if drag_day < drop_day
    // - shift all days starting from day_before_drag_day by -1 day
    if (DateTime.fromISO(drag_day.date) < DateTime.fromISO(drop_day.date)) {
      this.moveAllDays(drop_day.date, drop_day_minus_1, itinerary);
    }
    if (DateTime.fromISO(drag_day.date) > DateTime.fromISO(drop_day.date)) {
      this.moveAllDays(day_after_drag_day.date, day_after_drag_day_minus_1, itinerary);
    }
  }

  moveAllDays(oldStartDate, newStartDate, itinerary, except) {
    if (itinerary == null) itinerary = "Main";
    var timeDiff = newStartDate.diff(oldStartDate);
    //console.log("moveAllDays", oldStartDate, newStartDate, timeDiff);
    this.events.forEach((event) => {
      const _itinerary = typeof event.Itinerary == "undefined" ? "Main" : event.Itinerary;
      if (_itinerary !== itinerary) return;
      let startDate = DateTime.fromISO(event.Start, { zone: "utc" });
      if (except !== null && typeof except !== "undefined") {
        if (startDate.year === except.year && startDate.month === except.month && startDate.day === except.day) return;
      }
      if (
        startDate.year >= oldStartDate.year &&
        startDate.month >= oldStartDate.month &&
        startDate.day >= oldStartDate.day
      ) {
        event.Start = DateTime.fromISO(event.Start, { zone: "utc" })
          .plus({ seconds: timeDiff / 1000 })
          .toISO({ zone: "utc" });
        event.End = DateTime.fromISO(event.End, { zone: "utc" })
          .plus({ seconds: timeDiff / 1000 })
          .toISO({ zone: "utc" });
      }
    });
    //console.log(this.events);
  }

  deleteDay(deleteDate, itinerary) {
    //console.log("deleteDay", deleteDate, itinerary);
    if (itinerary == null) itinerary = "Main";
    let new_events = [];
    this.events.forEach((event) => {
      const _itinerary = typeof event.Itinerary == "undefined" ? "Main" : event.Itinerary;
      // if (_itinerary !== itinerary) return;
      let startDate = DateTime.fromISO(event.Start, { zone: "utc" });
      if (
        startDate.year !== deleteDate.year ||
        startDate.month !== deleteDate.month ||
        startDate.day !== deleteDate.day ||
        event.Itinerary !== itinerary
      ) {
        new_events.push(event);
      }
    });
    //console.log("deleteDay", new_events);
    this.events = new_events;
  }

  getScheduleToDisplay2(_EventSearchText, t_scheduleName, _Places) {
    let EventSearchText = _EventSearchText.toUpperCase();
    let scheduleName = t_scheduleName;
    //console.log("getScheduleToDisplay2", EventSearchText, scheduleName);
    if (scheduleName == null) scheduleName = "Main";
    scheduleName = scheduleName.replace(/%20/g, " ");

    //console.log(this.events);
    let sortedEvents = this.events.sort((a, b) =>
      new Date(a.Start).getTime() < new Date(b.Start).getTime()
        ? -1
        : new Date(a.Start).getTime() > new Date(b.Start).getTime()
        ? 1
        : 0
    );
    //console.log("sortedEvents", sortedEvents);
    let day = -1;
    let dayNum = 0;
    let schedule = [];
    let new_date = null;
    let order_index = 1;
    for (let i = 0; i < sortedEvents.length; i++) {
      let event = sortedEvents[i];
      let _scheduleName = typeof event.Itinerary == "undefined" ? "Main" : event.Itinerary;
      _scheduleName = _scheduleName.replace(/%20/g, " ");
      if (_scheduleName !== scheduleName) continue;
      if (EventSearchText !== "") {
        if (!event.Subject.toUpperCase().includes(EventSearchText)) continue;
      }
      //console.log("getScheduleToDisplay2 event.Start", event.Start, _scheduleName, sortedEvents[i].Subject);
      let startDate = DateTime.fromISO(event.Start, { zone: "utc" });
      // console.log(
      //   "getScheduleToDisplay2 startDate: ",
      //   startDate,
      //   "event.Start: ",
      //   event.Start,
      //   "Common.formatDate(startDate): ",
      //   Common.formatDate(startDate)
      // );
      if (day !== startDate.day) {
        // New date found
        if (new_date != null) schedule.push(new_date);
        new_date = { Title: Common.formatDate(startDate), date: startDate, data: [], index: (i + 1).toString() };
        day = startDate.day;
        dayNum += 1;
      }
      event.dayNum = dayNum;
      if (new_date != null) {
        if (event.Places !== null && event.Places !== "" && typeof document !== "undefined") {
          let place_ids = event.Places.split(",");
          let num_places = 0;
          for (let p = 0; p < place_ids.length; p++) {
            const place = _Places.getPlace(place_ids[p]);
            if (place != null) {
              //if (place.PType === "Icon")
              num_places++;
            }
          }
          event.order_index = order_index.toString();
          order_index = order_index + num_places;
        }
        new_date.data.push(event);
      }
    }
    if (new_date != null) schedule.push(new_date);
    //console.log(schedule);
    return schedule;
  }

  getScheduleItems(EventSearchText, itinerary, _Places) {
    //console.log("getScheduleItems", itinerary);
    return this.getScheduleToDisplay2(EventSearchText, itinerary, _Places);

    if (itinerary == null) itinerary = "Main";
    let agenda = {};
    let allDates = [];
    for (let i = 0; i < this.events.length; i++) {
      const _itinerary = typeof this.events[i].Itinerary == "undefined" ? "Main" : this.events[i].Itinerary;
      if (_itinerary != itinerary) continue;
      if (EventSearchText != "") {
        if (!this.events[i].Subject.toUpperCase().includes(EventSearchText)) continue;
      }
      //console.log("getScheduleItems", this.events[i]);
      const date = new Date(this.events[i].Start);
      const strTime = date.toISOString().split("T")[0];
      //console.log("strTime", strTime);
      if (!allDates.includes(strTime)) {
        allDates.push(strTime);
        agenda[strTime] = [];
        //console.log(strTime);
      }
      const height = 60; // * (minutes / 15);
      agenda[strTime].push({
        event: this.events[i],
        height: height,
      });
    }
    //console.log("agenda", agenda);
    return agenda;
  }

  addEvent(event) {
    //console.log("Before", this.events.length);
    let new_event = new Appointment(this);
    new_event.copy(event);
    new_event.ID = this.create_id();
    this.events.push(new_event);
    this.events.sort(compare);
    return new_event;
    //console.log("After", this.events.length);
  }

  deleteEvent(event) {
    for (let i = 0; i < this.events.length; i++) {
      if (this.events[i].ID == event.ID) {
        this.events.splice(i, 1);
        return;
      }
    }
  }

  combineWithPreviousEvent(event, itinerary) {
    // event_index = -1;
    // for (let i = 0; i < this.events.length; i++) {
    //   if (this.events[i].ID == event.ID) {
    //     event_index = i;
    //     return;
    //   }
    // }
    console.log("combineWithPreviousEvent", event, itinerary);
    const event_index = this.events.findIndex((e) => e.ID === event.ID);
    if (event_index === -1) return;
    let prev_event_index = -1;
    let difference = 99999999999;
    const event_start_time = Common.LuxonDateTimeFromJSDate(new Date(this.events[event_index].Start));
    for (let i = 0; i < this.events.length; i++) {
      if (this.events[i].Itinerary !== itinerary) continue;
      if (this.events[i].ID === event.ID) continue;
      const cur_event_start_time = Common.LuxonDateTimeFromJSDate(new Date(this.events[i].Start));
      if (cur_event_start_time.day !== event_start_time.day) continue;
      if (cur_event_start_time.month !== event_start_time.month) continue;
      if (cur_event_start_time.year !== event_start_time.year) continue;
      if (cur_event_start_time.year > event_start_time.year) continue;
      //const new_diff = event_start_time.diff(cur_event_start_time);
      console.log("combineWithPreviousEvent", cur_event_start_time, event_start_time);
      //const interval = Interval.fromDateTimes(cur_event_start_time, event_start_time);
      const new_diff = event_start_time.toMillis() - cur_event_start_time.toMillis();
      console.log("combineWithPreviousEvent1", new_diff);
      if (new_diff > 0 && new_diff < difference) {
        console.log("combineWithPreviousEvent", new_diff);
        difference = new_diff;
        prev_event_index = i;
      }
    }
    if (prev_event_index === -1) return;
    console.log("combineWithPreviousEvent2", this.events[prev_event_index]);
    let placesString = this.events[event_index].Places;
    let placesArray = placesString.split(",");
    let prevplacesString = this.events[prev_event_index].Places;
    let prevplacesArray = prevplacesString.split(",");
    for (let j = 0; j < placesArray.length; j++) {
      if (!prevplacesArray.includes(placesArray[j])) {
        this.events[prev_event_index].Places += "," + placesArray[j];
      }
    }
    this.events[prev_event_index].Body += " " + this.events[event_index].Body;
    this.events[prev_event_index].End = this.events[event_index].End;
    this.events.splice(event_index, 1);
    console.log("combineWithPreviousEvent3", this.events[prev_event_index]);
  }

  combineWithEvents(event, events) {
    console.log("combineWithEvents1");
    let prev_event_index = this.events.findIndex((e) => e.ID === event.ID);
    if (prev_event_index === -1) return;
    const event_end_time = Common.LuxonDateTimeFromJSDate(new Date(this.events[prev_event_index].End));
    let event_end_time_index = prev_event_index;
    let prevplacesString = this.events[prev_event_index].Places;
    let prevplacesArray = prevplacesString.split(",");
    for (let i = 0; i < events.length; i++) {
      if (event.ID === events[i].id) continue;
      const event_index = this.events.findIndex((e) => e.ID === events[i].id);
      if (event_index === -1) continue;
      const _event_end_time = Common.LuxonDateTimeFromJSDate(new Date(this.events[event_index].End));
      if (_event_end_time > event_end_time) event_end_time_index = event_index;
      let placesString = this.events[event_index].Places;
      let placesArray = placesString.split(",");
      for (let j = 0; j < placesArray.length; j++) {
        if (!prevplacesArray.includes(placesArray[j])) {
          this.events[prev_event_index].Places += "," + placesArray[j];
        }
      }
      this.events[prev_event_index].Body += " " + this.events[event_index].Body;
      this.events[prev_event_index].End = this.events[event_end_time_index].End;
    }
    for (let i = 0; i < events.length; i++) {
      if (event.ID === events[i].id) continue;
      const delete_index = this.events.findIndex((e) => e.ID === events[i].id);
      if (delete_index === -1) continue;
      this.events.splice(delete_index, 1);
    }
  }

  modifyEvent(event) {
    for (let i = 0; i < this.events.length; i++) {
      if (this.events[i].ID == event.ID) {
        //this.events[i].copy(event);
        this.events[i].Subject = event.Subject;
        this.events[i].Body = event.Body;
        //remove timezone
        this.events[i].Start = event.Start; //Common.dateToString(event.Start); //event.Start; //Common.dateToString(event.Start); //
        this.events[i].End = event.End; //Common.dateToString(event.End); //event.End; //
        //console.log("modifyEvent", this.events[i]);
        this.events[i].Places = event.Places;
        this.events[i].Source = Place_Source.USER;
        if (typeof event.Itinerary != "undefined") this.events[i].Itinerary = event.Itinerary;
        break;
      }
    }
  }

  updateSchedule() {
    for (let i = 0; i < this.events.length; i++) {
      //console.log("this.events[i]", this.events[i]);
      this.events[i].Start = Common.LuxonDateTimeToString(
        Common.LuxonDateTimeFromJSDate(new Date(this.events[i].Start))
      ); //event.Start; //Common.dateToString(event.Start); //
      //console.log("this.events[i].Start", this.events[i].Start);
      this.events[i].End = Common.LuxonDateTimeToString(Common.LuxonDateTimeFromJSDate(new Date(this.events[i].End))); //event.End; //
      this.events[i].Places = this.events[i].Places;
      this.events[i].Source = Place_Source.USER;
      this.events[i].Itinerary = "Main";
    }
  }

  deleteAllEvents(itinerary) {
    if (itinerary == null) itinerary = "Main";
    let new_events = [];
    this.events.forEach((event) => {
      const _itinerary = typeof event.Itinerary == "undefined" ? "Main" : event.Itinerary;
      if (_itinerary !== itinerary) new_events.push(event);
    });
    this.events = new_events;
  }

  renameSchedule(oldItinerary, newItinerary, guide) {
    //console.log("renameSchedule", oldItinerary, newItinerary);
    this.events.forEach((event) => {
      if (event.Itinerary == oldItinerary) event.Itinerary = newItinerary;
    });

    //console.log("renameSchedule: ", this.events);

    guide.document = guide.document
      .split(`<a href="schedulelink:${oldItinerary}">`)
      .join(`<a href="schedulelink:${newItinerary}">`);
    guide.document = guide.document
      .split(`<a href="itinerarylink:${oldItinerary}">`)
      .join(`<a href="itinerarylink:${newItinerary}">`);

    for (let i = 0; i < guide.chapters.length; i++) {
      for (let j = 0; j < guide.chapters[i].sections.length; j++) {
        guide.chapters[i].sections[j].html = guide.chapters[i].sections[j].html
          .split(`<a href="schedulelink:${oldItinerary}">`)
          .join(`<a href="schedulelink:${newItinerary}">`);
        guide.chapters[i].sections[j].html = guide.chapters[i].sections[j].html
          .split(`<a href="itinerarylink:${oldItinerary}">`)
          .join(`<a href="itinerarylink:${newItinerary}">`);
      }
    }

    //console.log("renameSchedule: ", guide.document);
    //TODO rename schedule names in html
  }

  copySchedule(itinerary_from, itinerary_to) {
    //console.log("copySchedule", itinerary_from, itinerary_to);
    if (itinerary_to == null) itinerary_to = "Main";
    if (itinerary_from == itinerary_to) return;
    let new_events = [];
    this.events.forEach((event) => {
      const _itinerary = typeof event.Itinerary == "undefined" ? "Main" : event.Itinerary;
      if (_itinerary == itinerary_from) {
        let new_event = new Appointment(this);
        new_event.copy(event);
        new_event.Itinerary = itinerary_to;
        new_events.push(new_event);
      }
    });
    //console.log("new_events", new_events);
    //this.deleteAllEvents(itinerary_to);
    this.events = this.events.concat(new_events);
  }

  // delete_event(eventID) {
  //   var eventIndex = this.get_event_index(eventID);
  //   if (eventIndex == -1) return;
  //   this.events.splice(eventIndex, 1);
  //   this.getPlaces_InSchedule();
  //   storeData("LG_events", this.events);
  // }
  change_start_date(newdate) {
    if (this.events.length == 0) return;
    var schedDate = new Date(this.events[0].Start);
    var diff = newdate - schedDate;
    for (let i = 0; i < this.events.length; i++) {
      var event = this.events[i];
      var sdate = new Date(event.Start);
      var edate = new Date(event.End);
      sdate.setMilliseconds(sdate.getMilliseconds() + diff);
      edate.setMilliseconds(edate.getMilliseconds() + diff);
      event.Start = Common.dateToString(edate); // edate.toString(); //
      event.End = Common.dateToString(sdate); //sdate.toString(); //
    }
  }
  // get_first_event(itinerary) {
  //   if (itinerary == null) itinerary = "Main";
  //   if (this.events.length == 0) return null;
  //   return this.events[0];
  // }
  get_event(eventID) {
    for (var key in this.events) {
      var event = this.events[key];
      if (event.ID == eventID) return event;
    }
    return null;
  }
  // get_event_index(eventID) {
  //   for (let i = 0; i < this.events.length; i++) {
  //     var event = this.events[i];
  //     if (event.ID == eventID) return i;
  //   }
  //   return -1;
  // }
}
function compare(a, b) {
  var adate = new Date(a.Start);
  var bdate = new Date(b.Start);
  if (adate < bdate) return -1;
  if (adate > bdate) return 1;
  return 0;
}
