import { connect } from "react-redux";
import React, { Component, useState, useEffect } from "react";
import { Modal, Form, Button, Spinner } from "react-bootstrap";
import AlertModal from "./AlertModal";
import MyToolTip from "./MyToolTip";
import "./Modal.css";
import PromptModal from "./PromptModal";
import MultiSelectModal from "./MultiSelectModal";
import IconsDialog from "./IconsDialog";
import Place from "../../Models/Place";
import Places from "../../Models/Places";
import DataBase from "../../dataBase/liquidGuides";
import Common, { SortByEnum, POI_IMAGES, COLORS, AICategories, AIBaseCategories } from "../../Common/Common";
import Appointment from "../../Models/Event";
import { DateTime, Settings } from "luxon";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import GooglePlaces from "../../Models/GooglePlaces";
import GoogleSearch from "../../Models/GoogleSearch";
import GoogleTranslate from "../../Models/GoogleTranslate";
import { countries } from "../../Data/Countries";
import { languages, googleLanguages } from "../../Data/Languages";

class TripAI extends Component {
  constructor(props) {
    super(props);
    let city = "Florence";
    let country = "Italy";
    let attributes = window.guide_obj.attributes;
    if (attributes.cities.length > 0) {
      city = attributes.cities[0];
    }
    if (attributes.countries.length > 0) {
      country = attributes.countries[0];
    }
    let place = null;
    if (typeof this.props.id !== "undefined") {
      place = window.guide_obj.places.getPlace(this.props.id);
    }
    let location = null;
    if (typeof this.props.location !== "undefined") {
      location = this.props.location;
    }
    //console.log("this.props.location", this.props.location);
    //console.log("location", location);
    let curDate = DateTime.now().toISO({ zone: "utc" });
    curDate = DateTime.fromISO(curDate, { zone: "utc" });
    curDate = curDate.set({ hour: 9 });
    curDate = curDate.set({ minute: 0 });
    this.state = {
      wizardStep: 1,
      selectedQuestion: "When is the best time to visit Florence",
      selectedNumber: "1",
      selectedCity: city,
      selectedCountry: country,
      selectedLanguage: "English",
      selectedLanguageId: "en",
      citiesInSchedule: [],
      carStatus: "",
      proximity: "for",
      focusOn: "",
      exclude: "",
      include: "",
      includeOnly: false,
      place: place,
      location: location,
      UpdateMainHome: true,
      OptimizeSchedule: false,
      LatLong: "0,0",
      mood: "standard",
      radius: "50Km",
      Schedule: "",
      ScheduleText: "",
      Places: "",
      PlacesArray: [],
      PlacesFound: [],
      answer: "",
      SearchText: "",
      dateStart: curDate,
      AICategories: [],
      AIBaseCategories: [],

      AlertModalShow: false,
      activityIndicator: false,
      AlertModalTitle: "",
      AlertModalMessage: "",
      AlertModalButtons: [],

      PromptModalShow: false,
      PromptModalTitle: "Enter Value",
      PromptModalMessage: "",
      PromptModalButtons: [],
      PromptModalInputs: [],

      MultiselectItems: [],
      MultiselectSelectedItems: [],
      MultiselectMode: "uni",
      MultiselectCallback: null,
      MultiselectTitle: "Select",
      MultiselectSearchText: "",
      ShowMultiselect: false,
    };
  }
  componentDidMount() {
    let _AICategories = [];
    let _AIBaseCategories = [];
    try {
      _AICategories = JSON.parse(this.props.ai_categories);
    } catch (e) {
      console.log("Error parsing this.props.ai_categories");
      _AICategories = AICategories;
    }
    try {
      _AIBaseCategories = JSON.parse(this.props.ai_base_categories);
    } catch (e) {
      console.log("Error parsing this.props.ai_base_categories");
      _AIBaseCategories = AIBaseCategories;
    }
    this.setState({ AICategories: _AICategories, AIBaseCategories: _AIBaseCategories });
  }

  _user_info = null;
  MyAlert = (title, message) => {
    if (typeof message === "undefined") message = "";
    this.setState({
      AlertModalTitle: title,
      AlertModalMessage: message,
      AlertModalButtons: [{ Title: "OK", Method: null }],
      AlertModalShow: true,
    });
  };

  attractionsOptionChanged = (e) => {
    this.setState({ attractionsOption: e.target.value });
  };

  proximityChanged = (e) => {
    this.setState({ proximity: e.target.value });
  };

  carStatusChanged = (e) => {
    this.setState({ carStatus: e.target.value });
  };

  focusOnChanged = (e) => {
    this.setState({ focusOn: e.target.value });
  };

  numberChanged = (e) => {
    this.setState({ selectedNumber: e.target.value });
  };

  moodChanged = (e) => {
    this.setState({ mood: e.target.value });
  };

  radiusChanged = (e) => {
    this.setState({ radius: e.target.value });
  };

  LatLongChanged = (e) => {
    this.setState({ LatLong: e.target.value });
  };

  handleChangeSchedule = (e) => {
    this.setState({ Schedule: e.target.value });
  };

  handleChangePlaces = (e) => {
    this.setState({ Places: e.target.value });
  };

  questionChanged = (e) => {
    let quest = e.target.value;
    if (quest === "Choose a question") return;
    this.setState({ selectedQuestion: quest, answer: "", activityIndicator: true });
    this.askQuestion(quest, null);
  };
  askQuestion = (quest, callback) => {
    console.log("Question", quest);
    //this.MyAlert(`Question: ${quest}`);
    //quest = encodeURIComponent(quest);
    var url = `https://andiamotravelapp.com/api/travel_questionsGPT4.php?q="${quest}"`;
    let _this = this;
    fetch(url)
      .then(function (response) {
        return response.json();
      })
      .then(function (response) {
        if (response === null || typeof response === "undefined") {
          console.log("Error in AI response");
          _this.MyAlert("Error in AI response");
          _this.setState({ activityIndicator: false });
          return;
        }
        if (response.choices === null || typeof response.choices === "undefined") {
          console.log("Error in AI answer, choices", response);
          _this.MyAlert("Error in AI answer, choices");
          _this.setState({ activityIndicator: false });
          return;
        }
        if (response.choices.length === 0) {
          console.log("Error in AI answer, choices length", response);
          _this.MyAlert("Error in AI answer, choices length");
          _this.setState({ activityIndicator: false });
          return;
        }
        if (response.choices[0].message === null || typeof response.choices[0].message === "undefined") {
          console.log("Error in AI answer, message", response);
          _this.MyAlert("Error in AI answer, message");
          _this.setState({ activityIndicator: false });
          return;
        }
        if (
          response.choices[0].message.content === null ||
          typeof response.choices[0].message.content === "undefined"
        ) {
          console.log("Error in AI answer, message content", response);
          _this.MyAlert("Error in AI answer, message content");
          _this.setState({ activityIndicator: false });
          return;
        }
        let answer = response.choices[0].message.content;
        if (answer === null || typeof answer === "undefined") {
          console.log("Error in AI answer, text");
          _this.MyAlert("Error in AI answer, text");
          _this.setState({ activityIndicator: false });
          return;
        }
        //console.log("response", response);
        if (answer.startsWith("\n")) answer = answer.replace("\n", "");
        if (answer.startsWith("\n")) answer = answer.replace("\n", "");
        if (response.choices !== null) _this.setState({ answer: answer, activityIndicator: false });
        if (answer.startsWith("Sorry")) {
          const index = answer.indexOf("[");
          if (index !== -1) {
            answer = answer.substring(index);
          } else {
            _this.setState({ activityIndicator: false });
            _this.MyAlert("Error in AI response", answer);
            return;
          }
        }
        //console.log("QUESTION: ANSWER:", quest, "***", answer);
        if (callback !== null) callback(answer);
        //if (response.choices !== null) _this.setState({ answer: JSON.stringify(response), activityIndicator: false });
      })
      .catch(function (error) {
        console.log("Error in AI response", error);
        _this.MyAlert("Error in AI response", error.message);
        _this.setState({ answer: error, activityIndicator: false });
      });
  };

  items = [
    { label: "10-day itinerary for Florence Italy in json format with: time, place and description", params: "City" },
    { label: "When is the best time to visit Florence", params: "City" },
    { label: "Top 10 Places to Visit in Florence", params: "City" },
    { label: "Top 10 popular foods in Italy", params: "Country" },
    { label: "Top 10 dishes to try in Italy", params: "Country" },
    { label: "Top 10 local dishes to try in Florence", params: "City" },
    { label: "What is the phone number for police in Florence", params: "City" },
    { label: "Top 10 attractions to visit in Florence", params: "City" },
    { label: "Top 10 attractions to visit within 100Km from Florence", params: "City" },
    { label: "What are some phone numbers for taxis in Florence", params: "City" },
    { label: "How to dial the US from Italy", params: "Country2" },
    { label: "What is the current exchange rate from dollar", params: "Currency" },
    { label: "What is the currency in Italy", params: "Country" },
    { label: "Top 10 day trips from Florence", params: "City" },
    { label: "Top 10 cities to visit near Florence", params: "City" },
    { label: "Top 10 beaches near Florence", params: "City" },
    { label: "What are some phone numbers for ambulance in Florence", params: "City" },
    { label: "What are some phone numbers for ambulance in Florence", params: "City" },
    { label: "Top 10 neighborhoods to stay in Florence", params: "City" },
    { label: "What is the local language in Italy", params: "Country" },
    { label: "Top 10 cities to visit in Italy", params: "Country" },
    { label: "Top 10 scenic drives near Florence Italy", params: "City" },
    { label: "Top 10 national holidays in Italy", params: "Country" },
    { label: "Is today a national holiday in Italy", params: "Country" },
    { label: "Tell me about Florence Italy", params: "City" },
    { label: "Top 10 outdoor experiences near Florence Italy", params: "City" },
    { label: "Top 10 restaurants in Florence Italy", params: "City" },
    { label: "What are the open times for Florence Italy", params: "Place" },
    { label: "Describe a 10 day itinerary, with hours, for Florence Italy", params: "City" },
    { label: "Describe a 10 day itinerary, with hours, near Florence Italy", params: "City" },
  ];

  handleSearChanged = (event) => {
    //console.log(event.target.value);
    this.setState({ SearchText: event.target.value });
  };

  handledDestinationChanged = (event) => {
    this.setState({ selectedCity: event.target.value });
  };

  handledCountryChanged = (event) => {
    this.setState({ selectedCountry: event.target.value });
  };

  handleExcludeChanged = (event) => {
    this.setState({ exclude: event.target.value });
  };

  handleIncludeChanged = (event) => {
    this.setState({ include: event.target.value });
  };

  onChangeStart = (event) => {
    const dateStart = Common.LuxonDateFromJSDate(event);
    this.setState({ ...this.state, dateStart: dateStart });
  };

  Next = (number) => {
    // if (number === 2) {
    //   this.setState({ ...this.state, wizardStep: (number += 1) });
    //   return;
    // }
    if (number === 1) {
      this.setState({ activityIndicator: true });
      const _at = new GooglePlaces();
      _at.searchPlaces(
        `${this.state.LatLong}`,
        50000,
        null,
        //`${this.state.selectedCity}, ${this.state.selectedCountry}`,
        `${this.state.selectedCountry}`,
        this.searchGooglePlaceLatLong
      );

      return;
    }
    // if (number === 2) {
    //   this.setState({ activityIndicator: true });
    //   const quest = `detailed ${this.formQuestion()} Respond in json array format, with attributes: 'Date', 'Time', 'Attraction', 'Category', 'City', 'Description' and 'Duration' in minutes for each entry${
    //     this.state.selectedLanguage !== "English"
    //       ? ". Translate each description field to " + this.state.selectedLanguage
    //       : ""
    //   }.`;
    //   this.askQuestion(quest, this.scheduleReceived);
    //   return;
    // }
    if (number === 2) {
      this.setState({ activityIndicator: true });
      // let language =
      //   this.state.selectedLanguage === "English"
      //     ? ""
      //     : `. Translate into ${this.state.selectedLanguage} only the 'Description' field in the json`;

      let language = "";

      //const format = `Only provide a RFC8259 compliant JSON response following this format without deviation.
      const format = `Provide the response in RFC8259 compliant JSON, following this format.
    [{
      'Date': 'the date to visit the attraction, in Month Day format',
      'Time': 'the time to visit the attraction, in 24 hour format',
      'Duration': 'the amount of time to visit the attraction, in minutes',
      'Attraction': 'the name of the attraction',
      'City': 'the city in which the attraction is located',
      'Category': 'the category of the attraction',
      'Description': 'a description of the attraction'
    }]${language}`;

      const quest = `Detailed schedule for a ${this.formQuestion()} ${format}.`;
      this.askQuestion(quest, this.scheduleReceived);
      return;
    }

    if (number === 3) {
      this.setState({ activityIndicator: true });

      let places = [];
      try {
        let cities_in_schedule = [];
        this.setState({ PlacesFound: [] });

        let itinArray = this.getItinArray(this.state.Schedule);
        // let sched = JSON.parse(this.state.Schedule);
        // let itinArray = null;
        // if (Array.isArray(sched)) itinArray = sched;
        // else {
        //   // const keys = Object.keys(sched);
        //   // const itKey = keys[0];
        //   // itinArray = sched[itKey];
        //   // if (!Array.isArray(itinArray)) itinArray = Object.entries(itinArray);
        //   const keys = Object.keys(sched);
        //   let _itin = [];
        //   for (let k = 0; k < keys.length; k++) {
        //     let _array = sched[keys[k]];
        //     if (Array.isArray(_array))
        //       for (let x = 0; x < _array.length; x++) {
        //         _array[x].Date = keys[k];
        //         _itin.push(_array[x]);
        //       }
        //     else _itin.push(_array);
        //   }
        //   itinArray = _itin;
        // }
        let places_to_find = [];
        for (let i = 0; i < itinArray.length; i++) {
          if (["Lunch", "Dinner", "Breakfast"].includes(itinArray[i].Attraction)) {
            //this.addEvent(itinArray[i], null)
            continue;
          }
          if (places.includes(itinArray[i].Attraction)) {
            //this.addEvent(itinArray[i], null)
            continue;
          }
          places_to_find.push(itinArray[i]);
          places.push(itinArray[i].Attraction);
        }
        for (let i = 0; i < places_to_find.length; i++) {
          // if (["Lunch", "Dinner", "Breakfast"].includes(sched[itKey][i].Attraction)) {
          //   if (i === sched[itKey].length - 1) {
          //     this.setState({ wizardStep: 4, PlacesArray: places, activityIndicator: false }); //this.props.onHide();
          //     continue;
          //   }
          // }
          // if (places.includes(sched[itKey][i].Attraction)) {
          //   if (i === sched[itKey].length - 1) {
          //     this.setState({ wizardStep: 4, PlacesArray: places, activityIndicator: false }); //this.props.onHide();
          //     continue;
          //   }
          // }
          if (!cities_in_schedule.includes(places_to_find[i].City)) cities_in_schedule.push(places_to_find[i].City);
          this.googleTextSearch(
            `${places_to_find[i].Attraction}, near ${places_to_find[i].City} ${this.state.selectedCountry}`,
            i === places_to_find.length - 1,
            places_to_find[i]
          );
        }
        this.setState({ PlacesArray: places, citiesInSchedule: cities_in_schedule });
      } catch (e) {
        this.MyAlert("Error parsing schedule", this.state.Schedule);
        console.log("Error parsing schedule", e.message);
      }
      return;
    }
    // if (number === 4) {
    //   for(let i = 0; i < this.state.PlacesFound; i++){
    //     let place = this.state.PlacesFound[0];
    //     place.Category = [];
    //     window.guide_obj.places.addPlace(place);
    //   }
    //   return;
    // }
    this.setState({ ...this.state, wizardStep: (this.state.wizardStep += 1) });
  };

  getItinArray = (_sched) => {
    let sched = JSON.parse(_sched);
    let itinArray = null;
    if (Array.isArray(sched)) return sched;
    const keys = Object.keys(sched);
    if (keys.length === 1 && Array.isArray(sched[keys[0]])) return sched[keys[0]];
    let _itin = [];
    for (let k = 0; k < keys.length; k++) {
      let _array = sched[keys[k]];
      if (Array.isArray(_array))
        for (let x = 0; x < _array.length; x++) {
          _array[x].Date = keys[k];
          _itin.push(_array[x]);
        }
      else _itin.push(_array);
    }
    itinArray = _itin;
    return itinArray;
  };

  searchGooglePlaceLatLong = (google_places, label) => {
    if (google_places === null || google_places.length === 0) {
      this.MyAlert("Alert", "Error retrieving latitude, longitude");
      this.setState({ activityIndicator: false });
      return;
    }
    const _at = new GooglePlaces();
    _at.getQuickDetails(google_places, this.searchGooglePlaceLatLongFinal);
  };

  searchGooglePlaceLatLongFinal = (places) => {
    if (places.length < 1) {
      this.setState({ activityIndicator: false });
      return;
    }
    this.setState({ LatLong: `${places[0].Lat},${places[0].Longi}`, wizardStep: 2, activityIndicator: false });
    setTimeout(() => this.Next(2), 200);
  };

  parseSched = () => {
    let schedule = `${this.state.selectedNumber}-day ${this.state.selectedCity}, ${this.state.selectedCountry}`;
    let scheduleName = schedule;
    let scheds = window.guide_obj.schedule.geScheduleNames();
    for (let s = 0; s < 99999; s++) {
      scheduleName = `${schedule}${s === 0 ? "" : s}`;
      const index = scheds.findIndex((x) => x.label === scheduleName);
      if (index === -1) break;
    }

    try {
      let itinArray = this.getItinArray(this.state.Schedule);
      let dayNum = 0;
      let DateLabel = "XXXXX";
      if (itinArray.length > 0) {
        if (
          typeof itinArray[0].Time === "undefined" ||
          typeof itinArray[0].Attraction === "undefined" ||
          typeof itinArray[0].City === "undefined" ||
          typeof itinArray[0].Description === "undefined"
        ) {
          this.MyAlert("Data is not in expected format go back and try again", "");
          this.setState({ activityIndicator: false });
          return;
        }
      }
      for (let i = 0; i < itinArray.length; i++) {
        let event = new Appointment(window.guide_obj.schedule);
        event.Subject = itinArray[i].Attraction;
        event.Body = itinArray[i].Description;
        let existing_place = window.guide_obj.places.getPlacesFromName(itinArray[i].Attraction);
        if (existing_place !== null) {
          event.Places = existing_place.ID;
          console.log("itinArray[i].Attraction: found", itinArray[i].Attraction);
        } else {
          console.log("itinArray[i].Attraction: not found", itinArray[i].Attraction);
        }
        event.Itinerary = scheduleName;
        if (DateLabel !== itinArray[i].Date) {
          dayNum += 1;
          DateLabel = itinArray[i].Date;
        }
        let time = itinArray[i].Time;
        let timeParts = time.split(":");
        let hour = parseInt(timeParts[0]);
        if (timeParts[1].toUpperCase().includes("PM") && hour < 12) hour += 12;
        hour = hour + (dayNum - 1) * 24;
        event.Start = this.state.dateStart;
        event.Start = event.Start.set({ hour: hour });
        let eventDuration = 2;
        let oneEvent = false;
        if (typeof itinArray[i].Duration !== "undefined") {
          eventDuration = parseInt(itinArray[i].Duration) / 60.0;
        } else {
          if (i < itinArray.length - 1) {
            let beforeIndex = i === 0 ? 0 : i - 1;
            let afterIndex = i + 1;
            if (itinArray[beforeIndex].Date !== itinArray[i].Date && itinArray[afterIndex].Date !== itinArray[i].Date)
              oneEvent = true;

            let time = itinArray[i + 1].Time;
            let timeParts2 = time.split(":");
            let hour2 = parseInt(timeParts2[0]);
            if (time.toUpperCase().includes("PM") && hour2 < 12) hour2 += 12;
            hour2 = hour2 + (dayNum - 1) * 24;
            eventDuration = hour2 - hour;
            if (itinArray[afterIndex].Date !== itinArray[i].Date) eventDuration = 2;
            if (oneEvent) eventDuration = 8;
          }
        }
        event.End = this.state.dateStart;
        //event.End = event.End.set({ hour: hour + eventDuration });
        event.End = event.Start.plus({ hours: eventDuration });
        const new_event = window.guide_obj.schedule.addEvent(event);
        // GoogleTranslate.translate(
        //   itinArray[i].Description,
        //   this.state.selectedLanguageId,
        //   this.translateCallback,
        //   new_event
        // );
      }
    } catch (e) {
      this.MyAlert("Alert", "Error parsing schedule. Fix json or go back and try again");
      this.setState({ activityIndicator: false });
    }
  };
  translateCallback = (result, event) => {
    //console.log("translateCallback:", event);
    try {
      if (typeof result.data.translations === "undefined") return;
      console.log("translateCallback:", result.data.translations[0].translatedText);
      event.Body = result.data.translations[0].translatedText;
    } catch (e) {
      console.log("translateCallback: Error in translation");
    }
  };

  addEvent = (schedEntry, ID) => {
    try {
      let event = new Appointment(window.guide_obj.schedule);
      event.Subject = schedEntry.Place;
      event.Body = schedEntry.Description;
      let time = schedEntry.Time;
      let timeParts = time.split(":");
      let hour = parseInt(timeParts[0]);
      event.Start = this.state.dateStart;
      event.Start = event.Start.set({ hour: hour });
      event.End = this.state.dateStart;
      event.End = event.End.set({ hour: hour + 1 });
      if (ID !== null) event.Places = ID;
      event.Itinerary = `${this.state.selectedNumber}-day ${this.state.selectedCity}, ${this.state.selectedCountry}`;
      window.guide_obj.schedule.addEvent(event);
    } catch (e) {
      console.log("Error adding event", e.message);
    }
  };

  LatLongReceived = (latLong) => {
    try {
      let ll = JSON.parse(latLong);
      if (typeof ll.lat === "undefined") {
        if (typeof ll.latitude === "undefined") {
          console.log("Error retrieving latitude, longitude", latLong);
          this.MyAlert("ALert", "Error retrieving latitude, longitude");
          this.setState({ activityIndicator: false });
          return;
        } else this.setState({ LatLong: `${ll.latitude},${ll.longitude}`, wizardStep: 2, activityIndicator: false });
      }
      this.setState({ LatLong: `${ll.lat},${ll.lng}`, wizardStep: 2, activityIndicator: false });
      setTimeout(() => this.Next(2), 200);
    } catch (e) {
      console.log("Error retrieving latitude, longitude", e.message);
      console.log("Error retrieving latitude, longitude", latLong);
      this.MyAlert("ALert", "Error retrieving latitude, longitude");
      this.setState({ activityIndicator: false });
    }
  };

  scheduleReceived = (schedule) => {
    try {
      //let sched = JSON.parse(schedule);
      let sched_tex = "";
      let date_text = "XXXXXX";
      let itinArray = this.getItinArray(schedule);
      // if (Array.isArray(sched)) itinArray = sched;
      // else {
      //   const keys = Object.keys(sched);
      //   let _itin = [];
      //   for (let k = 0; k < keys.length; k++) {
      //     let _array = sched[keys[k]];
      //     if (Array.isArray(_array))
      //       for (let x = 0; x < _array.length; x++) {
      //         _array[x].Date = keys[k];
      //         _itin.push(_array[x]);
      //       }
      //     else _itin.push(_array);
      //   }
      //   itinArray = _itin;
      // }
      for (let i = 0; i < itinArray.length; i++) {
        if (itinArray[i].Date !== date_text) {
          date_text = itinArray[i].Date;
          sched_tex += `${itinArray[i].Date}\n`;
        }
        sched_tex += `${itinArray[i].Time}: `;
        sched_tex += `${itinArray[i].Duration} min, `;
        sched_tex += `${itinArray[i].Attraction}\n`;
        sched_tex += `${itinArray[i].City}\n`;
        if (typeof itinArray[i].Category !== "undefined") sched_tex += `${itinArray[i].Category}\n`;
        sched_tex += `${itinArray[i].Description}\n\n`;
      }
      this.setState({ Schedule: schedule, ScheduleText: sched_tex, wizardStep: 3, activityIndicator: false });
      console.log("schedule", schedule);
      setTimeout(() => this.Next(3), 200);
    } catch (e) {
      console.log("Error retrieving or parsing schedule", e.message);
      console.log("Error retrieving or parsing schedule", schedule);
      this.MyAlert("ALert", "Error retrieving or parsing schedule");
      this.setState({ activityIndicator: false });
    }
  };

  Previous = (number) => {
    this.setState({ ...this.state, wizardStep: (this.state.wizardStep -= 1) });
  };

  Cancel = () => {
    this.props.onHide();
  };

  Finish = () => {
    this.setState({ activityIndicator: true });
    let last_i = -1;
    for (let i = 0; i < this.state.PlacesFound.length; i++) {
      let place = this.state.PlacesFound[i];
      //place.Category = [];
      let existing_place = window.guide_obj.places.getPlacesFromName(this.state.PlacesFound[i].Name);
      if (existing_place === null) {
        const place_id = window.guide_obj.places.addPlace(place);
        this.searchGoogleImages(
          `${this.state.PlacesFound[i].Name} near ${this.state.PlacesFound[i].City}, ${this.state.selectedCountry}`,
          place_id,
          i === this.state.PlacesFound.length - 1
        );
        last_i = i;
      }
    }
    if (last_i !== this.state.PlacesFound.length - 1) {
      if (this.state.OptimizeSchedule) this.optimize();
      else this.parseSched();
      setTimeout(() => {
        this.updateUI(null, null);
      }, 3000);
    }
  };

  searchGoogleImages = (searchString, place_id, last) => {
    if (last) setTimeout(() => GoogleSearch.searchImages(searchString, this.processGoogleImagesLast, place_id), 3000);
    //GoogleSearch.searchImages(searchString, this.processGoogleImagesLast, place_id);
    else GoogleSearch.searchImages(searchString, this.processGoogleImages, place_id);
  };

  processGoogleImages = (imageList, place_id) => {
    if (imageList.length === 0) {
      console.log("processGoogleImages: error");
      return;
    }
    console.log("processGoogleImages: success");
    let place = window.guide_obj.places.getPlace(place_id);
    if (place === null) return;
    place.Photo = imageList[0].url;
    place.Photo_m = imageList[0].url_m;
    try {
      place.Info = Place.createImageHTML(place.Photo_m, null, "medium"); // + place.Info;
    } catch (e) {
      console.log("processGoogleImages: error", e.message);
    }
  };

  processGoogleImagesLast = (imageList, place_id) => {
    if (imageList.length === 0) {
      console.log("processGoogleImagesLast: error");
      if (this.state.OptimizeSchedule) this.optimize();
      else this.parseSched();
      setTimeout(() => {
        this.updateUI(null, null);
      }, 2000);
      return;
    }
    console.log("processGoogleImagesLast: success");
    let place = window.guide_obj.places.getPlace(place_id);
    if (place === null) {
      setTimeout(() => {
        this.updateUI(imageList[0].url, imageList[0].url_m);
      }, 2000);
      return;
    }
    place.Photo = imageList[0].url;
    place.Photo_m = imageList[0].url_m;
    try {
      place.Info = Place.createImageHTML(place.Photo_m, null, "medium");
    } catch (e) {
      console.log("processGoogleImagesLast: error", e.message);
    }
    if (this.state.OptimizeSchedule) this.optimize();
    else this.parseSched();
    setTimeout(() => {
      this.updateUI(imageList[0].url, imageList[0].url_m);
    }, 2000);
  };

  optimize = () => {
    const schedule = `${this.state.selectedNumber}-day ${this.state.selectedCity}, ${this.state.selectedCountry}`;
    let dayDate = this.state.dateStart;
    let thisDay = dayDate;
    let firstEventOfDay = true;
    let last_place = null;
    let PlaceOrderByDistance = Common.getPlaceOrderByDistance(null, window.guide_obj, this.state.PlacesFound);
    PlaceOrderByDistance.forEach((place) => {
      if (place === null) return;
      dayDate = this.addEventOptimized(place, schedule, dayDate, last_place, 30, firstEventOfDay);
      if (dayDate.day !== thisDay.day) {
        thisDay = dayDate;
        firstEventOfDay = true;
      } else firstEventOfDay = false;
      last_place = place;
    });
  };

  addEventOptimized = (place, Itinerary, dayDate, last_place, rounding_to, firstEventOfDay) => {
    let event = new Appointment(window.guide_obj.schedule);
    event.Subject = place.Name;
    event.Body = "";
    event.Itinerary = Itinerary;
    event.Places = place.ID;
    let travel_time = 0;
    if (last_place !== null) {
      const p1 = { latitude: last_place.Lat, longitude: last_place.Longi };
      const p2 = { latitude: place.Lat, longitude: place.Longi };
      let distance = Places.calculateDistance(p1, p2) * 1.2;
      travel_time = distance * 10;
      if (travel_time > 45) travel_time = distance * 2; //Math.min(distance * 2, 60);
      if (rounding_to === 0) travel_time = 0;
      else travel_time = Math.max(1, Math.round(travel_time / rounding_to)) * rounding_to;
    }
    //let total_time =
    if (!firstEventOfDay || travel_time > 30) dayDate = dayDate.plus({ minutes: travel_time });
    if (dayDate.hour + travel_time / 60 + place.Duration / 60 > 18) {
      dayDate = dayDate.plus({ hours: 24 });
      dayDate = dayDate.set({ hour: 11 });
      dayDate = dayDate.set({ minutes: 0 });
      event.Start = dayDate.toISO();
      dayDate = dayDate.plus({ minutes: place.Duration });
      event.End = dayDate.toISO();
      window.guide_obj.schedule.addEvent(event);
    } else {
      event.Start = dayDate.toISO();
      dayDate = dayDate.plus({ minutes: place.Duration });
      event.End = dayDate.toISO();
      window.guide_obj.schedule.addEvent(event);
    }
    if (dayDate.hour >= 17) {
      dayDate = dayDate.plus({ hours: 24 });
      dayDate = dayDate.set({ hour: 11 });
      dayDate = dayDate.set({ minutes: 0 });
    }
    return dayDate;
  };

  updateUI = (url, url_m) => {
    let guide_attributes = JSON.parse(JSON.stringify(window.guide_obj.attributes));
    if (!guide_attributes.countries.includes(this.state.selectedCountry))
      guide_attributes.countries.push(this.state.selectedCountry);
    for (let i = 0; i < this.state.citiesInSchedule.length; i++)
      if (!guide_attributes.cities.includes(this.state.citiesInSchedule[i]))
        guide_attributes.cities.push(this.state.citiesInSchedule[i]);

    if (guide_attributes.guide_name === "New Guide") {
      guide_attributes.guide_name = `${this.state.selectedNumber}-day ${this.state.selectedCity}, ${this.state.selectedCountry}`;
      guide_attributes.description = `${this.state.selectedNumber}-day trip to ${this.state.selectedCity}, ${this.state.selectedCountry}`;
    } else {
      guide_attributes.guide_name = `${guide_attributes.cities.join(", ")}`;
      guide_attributes.description = `Trip to ${guide_attributes.cities.join(
        ", "
      )} in ${guide_attributes.countries.join(", ")}`;
    }
    guide_attributes.language = this.state.selectedLanguage;
    const latln = this.state.LatLong.split(",");
    try {
      guide_attributes.base_location = { latitude: parseFloat(latln[0]), longitude: parseFloat(latln[1]) };
    } catch (e) {
      console.log("error Updating UI", e);
      this.MyAlert("Alert", "Error Updating UI");
      this.setState({ activityIndicator: false });
      setTimeout(() => this.finalUpdate(url_m, guide_attributes), 300);
    }

    if (url !== null) guide_attributes.image = url;
    if (url_m !== null) guide_attributes.image_m = url_m;
    // guide_attributes.access = ["Featured", "All_C"];
    // guide_attributes.type = "Featured";

    window.guide_obj.attributes.modifyGuideAttributes(guide_attributes);

    this.props.update_guide();
    setTimeout(() => this.finalUpdate(url_m, guide_attributes), 300);
  };

  finalUpdate = (url_m, guide_attributes) => {
    //this.props.update_ui("Main");
    if (url_m === null) {
      url_m = guide_attributes.image_m;
    }
    if (this.state.UpdateMainHome) {
      window.guide_obj.schedule.deleteAllEvents("Main");
      let scheds = window.guide_obj.schedule.geScheduleNames();
      for (let i = 0; i < scheds.length; i++) {
        if ("Main" === scheds[i].label) continue;
        window.guide_obj.schedule.copySchedule(scheds[i].label, "Main");
      }
      const html_itin_String = window.guide_obj.createHTMLItinerary("Main");

      let home_page = `<p style="text-align:center;"><img src="${url_m}" alt="Image" style="width:100%; max-width:700px; height:auto; border-radius:10%" ></img></p>`;
      home_page += `<p><h3 style="text-align:center;">${guide_attributes.guide_name}</h3></p></br>${html_itin_String}`;

      window.guide_obj.document = home_page;
    }
    if (this.state.UpdateMainHome) this.props.update_ui("Main");
    else
      this.props.update_ui(
        `${this.state.selectedNumber}-day ${this.state.selectedCity}, ${this.state.selectedCountry}`
      );
    this.setState({ activityIndicator: false });
    this.props.onHide();
  };

  googleTextSearch = (searchText, last, label) => {
    const _at = new GooglePlaces();
    if (last)
      setTimeout(
        () =>
          _at.searchPlaces(`${this.state.LatLong}`, 10000, null, searchText, this.searchGooglePlaceCallbackLast, label),
        2000
      );
    //_at.searchPlaces(`${this.state.LatLong}`, 10000, null, searchText, this.searchGooglePlaceCallbackLast, label);
    else _at.searchPlaces(`${this.state.LatLong}`, 10000, null, searchText, this.searchGooglePlaceCallback, label);
  };

  searchGooglePlaceCallback = (google_places, label) => {
    try {
      console.log("searchGooglePlaceCallback label", label);
      if (google_places == null || google_places.length == 0) {
        this.setState({ PlacesFound: [], Places: "", activityIndicator: false });
        console.log("searchGooglePlaceCallback: error", label.Attraction);
        return;
      }
      console.log("searchGooglePlaceCallback: success", label.Attraction);
      const _at = new GooglePlaces();
      // Change this for quick/long details
      //_at.getDetails(google_places, getGoogleDetailsCallback);
      _at.getQuickDetails(google_places, this.getGoogleDetailsCallback, label);
    } catch (e) {
      console.log("searchGooglePlaceCallback: error", e.message);
    }
  };

  searchGooglePlaceCallbackLast = (google_places, label) => {
    try {
      console.log("searchGooglePlaceCallbackLast label", label);
      if (google_places === null || google_places.length === 0) {
        console.log("searchGooglePlaceCallbackLast: error", label.Attraction);
        this.setState({ activityIndicator: false, wizardStep: 4 });
        setTimeout(() => this.Finish(), 2000);
        return;
      }
      console.log("searchGooglePlaceCallbackLast: success", label.Attraction);
      const _at = new GooglePlaces();
      // Change this for quick/long details
      //_at.getDetails(google_places, getGoogleDetailsCallback);
      //_at.getQuickDetails(google_places, this.getGoogleDetailsCallbackLast, label);
      setTimeout(() => _at.getQuickDetails(google_places, this.getGoogleDetailsCallbackLast, label), 2000);
    } catch (e) {
      console.log("searchGooglePlaceCallbackLast: error", e.message);
      this.setState({ activityIndicator: false, wizardStep: 4 });
      setTimeout(() => this.Finish(), 2000);
    }
  };

  getGoogleDetailsCallback = (places, label) => {
    console.log("getGoogleDetailsCallback label", label);
    //console.log("getGoogleDetailsCallback", places);
    if (places.length < 1) {
      console.log("getGoogleDetailsCallback: error", label.Attraction);
      return;
    }
    console.log("getGoogleDetailsCallback: success", label.Attraction);
    let places_found = this.state.PlacesFound;
    let placeFound = places[0];
    try {
      placeFound.Name = label.Attraction;
      placeFound.City = label.City;
      placeFound.Country = this.state.selectedCountry;
      placeFound.Category = [];
      let found = false;
      for (let i = 0; i < this.state.AICategories.length; i++) {
        if (this.state.AICategories[i].category === label.Category) {
          placeFound.Category = [this.state.AICategories[i].id];
          found = true;
          break;
        }
      }
      if (!found) {
        for (let i = 0; i < this.state.AIBaseCategories.length; i++) {
          if (label.Category.includes(this.state.AIBaseCategories[i].category)) {
            placeFound.Category = [this.state.AIBaseCategories[i].id];
            found = true;
            break;
          }
        }
      }
      if (!found) console.log(`Category not found: ${label.Category}`);
      placeFound.Duration = label.Duration;
    } catch (e) {
      console.log("getGoogleDetailsCallback: error", e.message);
    }
    //placeFound.Name = label;
    places_found.push(placeFound);
    this.setState({ PlacesFound: places_found });
  };

  getGoogleDetailsCallbackLast = (places, label) => {
    console.log("getGoogleDetailsCallbackLast label", label);
    //console.log("getGoogleDetailsCallback", places);
    if (places.length < 1) {
      this.setState({ activityIndicator: false, wizardStep: 4 });
      setTimeout(() => this.Finish(), 500);
      console.log("getGoogleDetailsCallbackLast: error", label.Attraction);
      return;
    }
    console.log("getGoogleDetailsCallbackLast: success", label.Attraction);
    let places_found = this.state.PlacesFound;
    let placeFound = places[0];
    try {
      placeFound.Name = label.Attraction;
      placeFound.City = label.City;
      placeFound.Country = this.state.selectedCountry;
      placeFound.Category = [];
      let found = false;
      for (let i = 0; i < this.state.AICategories.length; i++) {
        if (this.state.AICategories[i].category === label.Category) {
          placeFound.Category = [this.state.AICategories[i].id];
          found = true;
          break;
        }
      }
      if (!found) {
        for (let i = 0; i < this.state.AIBaseCategories.length; i++) {
          if (label.Category.includes(this.state.AIBaseCategories[i].category)) {
            placeFound.Category = [this.state.AIBaseCategories[i].id];
            found = true;
            break;
          }
        }
      }
      if (!found) console.log(`Category not found: ${label.Category}`);
      placeFound.Duration = label.Duration;
    } catch (e) {
      console.log("getGoogleDetailsCallbackLast: error", e.message);
      this.setState({ PlacesFound: places_found, activityIndicator: false, wizardStep: 4 });
      setTimeout(() => this.Finish(), 500);
      return;
    }
    //placeFound.Name = label;
    places_found.push(placeFound);
    this.setState({ PlacesFound: places_found, activityIndicator: false, wizardStep: 4 });
    setTimeout(() => this.Finish(), 500);
  };

  UpdateMainHomeChanged = () => {
    this.setState({ UpdateMainHome: !this.state.UpdateMainHome });
  };

  OptimizeScheduleChanged = () => {
    this.setState({ OptimizeSchedule: !this.state.OptimizeSchedule });
  };

  IncludeOnlyChanged = () => {
    this.setState({ includeOnly: !this.state.includeOnly });
  };

  formQuestion = () => {
    let base = `${this.state.selectedNumber} day${
      this.state.mood === "standard" ? "" : ` ${this.state.mood}`
    } vacation ${this.state.carStatus === "" ? "" : ` ${this.state.carStatus}`}${
      this.state.proximity === "" ? "" : ` ${this.state.proximity}`
    } ${this.state.selectedCity} ${this.state.selectedCountry} starting on ${this.state.dateStart.toFormat("LLL dd")}${
      this.state.focusOn !== "" ? `. Focus on ${this.state.focusOn}` : ""
    }${this.state.include !== "" ? `. Include ${this.state.includeOnly ? "only" : ""} ${this.state.include}` : ""}${
      this.state.exclude !== "" ? `. Exclude ${this.state.exclude}` : ""
    }.`;
    return base;
  };

  selectWhich = () => {
    let which = [
      "the top yyy cities in",
      "the top yyy small towns in",
      "the top xxx attractions in",
      "the top xxx beaches in",
      "the top xxx castles in",
      "the top xxx outdoor experiences in",
      "the top xxx scenic drives in",
    ];
    let num = parseInt(this.state.selectedNumber);

    for (let i = 0; i < which.length; i++) {
      which[i] = which[i].replace("yyy", Math.max(Math.round(num / 2), 1));
      which[i] = which[i].replace("xxx", num * 2);
    }

    this.MultiSelect(which, [], "uni", this.selectWhichCallBack, "Select Which");
  };

  selectWhichCallBack = (which) => {
    if (which.length > 0) this.setState({ selectedCity: which[0].value });
  };

  selectFocus = () => {
    let focus = [
      "sports",
      "beaches",
      "culture",
      "art",
      "camping",
      "city sightseeing",
      "resorts",
      "parks",
      "hiking",
      "personal growth",
      "activities",
      "mountains",
      "volunteering",
      "museums",
      "historical",
      "scenic drives",
      "food",
      "wine",
    ];
    focus = focus.sort((a, b) => (a > b ? 1 : -1));
    this.MultiSelect(focus, [], "multi", this.selectFocusCallBack, "Select Focus");
  };

  selectFocusCallBack = (focuses) => {
    if (focuses.length > 5) {
      this.MyAlert("Alert", "Error, you can select up to 5 things to focus on");
      return;
    }
    let focus = "";
    for (let i = 0; i < focuses.length; i++) {
      if (focus !== "") {
        if (i === focuses.length - 1) focus += " and ";
        else focus += ", ";
      }
      focus += focuses[i].value;
    }
    this.setState({ focusOn: focus });
  };

  selectCountry = () => {
    let _countries = [];
    const countries_sorted = countries.sort();
    let id = 1;
    countries_sorted.forEach((country) =>
      _countries.push({
        label: country,
        name: country,
        value: country,
        id: (id++).toString(),
      })
    );
    this.MultiSelect(_countries, [], "uni", this.selectedCountry, "Select Country");
  };

  selectedCountry = (countries) => {
    let country = "";
    if (countries.length < 1) country = "";
    else country = countries[0].value;
    this.setState({ selectedCountry: country });
  };

  // selectLanguage = () => {
  //   let _languages = [];
  //   const languages_sorted = languages.sort();
  //   let id = 1;
  //   languages_sorted.forEach((language) =>
  //     _languages.push({
  //       label: language,
  //       name: language,
  //       value: language,
  //       id: (id++).toString(),
  //     })
  //   );
  //   this.MultiSelect(_languages, [], "uni", this.selectedLanguage, "Select Language");
  // };

  selectLanguage = () => {
    let _languages = [];
    const languages_sorted = googleLanguages.sort((a, b) => (a.language > b.language ? 1 : -1));
    let id = 1;
    languages_sorted.forEach((language) =>
      _languages.push({
        label: language.language,
        name: language.language,
        value: language.id,
        id: (id++).toString(),
      })
    );
    this.MultiSelect(_languages, [], "uni", this.selectedLanguage, "Select Language");
  };

  selectedLanguage = (languages) => {
    let language = "English";
    let language_id = "en";
    if (languages.length > 0) {
      language = languages[0].label;
      language_id = languages[0].value;
    }
    this.setState({ selectedLanguage: language, selectedLanguageId: language_id });
  };

  MultiSelect = (Items, SelectedItems, Mode, Callback, Title) => {
    //console.log("MultiSelect");
    this.setState({
      MultiselectItems: Items,
      MultiselectSelectedItems: SelectedItems,
      MultiselectMode: Mode,
      MultiselectCallback: Callback,
      MultiselectTitle: Title,
      MultiselectSearchText: "",
      ShowMultiselect: true,
    });
  };

  render() {
    let question = `Detailed schedule for a ${this.formQuestion()}`;

    let static_map = `https://maps.googleapis.com/maps/api/staticmap?center=${this.state.LatLong}&zoom=5&size=400x400&maptype=roadmap&markers=size:mid%7Ccolor:red%7Clabel:X%7C${this.state.LatLong}&key=AIzaSyAKxtGIewjOkmnY0FE9jD7hYbsHRVArJJc`;
    let headerText = "Enter data";
    let wizardStepDisp = 1;
    if (this.state.wizardStep === 2) {
      wizardStepDisp = 1;
      headerText = "Get Schedule";
    }
    if (this.state.wizardStep === 3) {
      wizardStepDisp = 2;
      headerText = "Get places";
    }
    if (this.state.wizardStep === 4) {
      wizardStepDisp = 3;
      headerText = "get Place Info";
    }

    // if (_questions.findIndex((x) => x.label === "Choose a question") === -1)
    //   _questions.splice(0, 0, {
    //     label: "Choose a question",
    //     params: "None",
    //   });
    if (this.state.AlertModalShow)
      return (
        <AlertModal
          show={this.state.AlertModalShow}
          onHide={() => this.setState({ AlertModalShow: false })}
          AlertModalTitle={this.state.AlertModalTitle}
          AlertModalMessage={this.state.AlertModalMessage}
          AlertModalButtons={this.state.AlertModalButtons}
        />
      );
    if (this.state.PromptModalShow)
      return (
        <PromptModal
          show={this.state.PromptModalShow}
          onHide={() => this.setState({ PromptModalShow: false })}
          PromptModalTitle={this.state.PromptModalTitle}
          PromptModalMessage={this.state.PromptModalMessage}
          PromptModalButtons={this.state.PromptModalButtons}
          PromptModalInputs={this.state.PromptModalInputs}
        />
      );
    if (this.state.ShowMultiselect)
      return (
        <MultiSelectModal
          show={this.state.ShowMultiselect}
          onHide={() => this.setState({ ShowMultiselect: false })}
          MultiselectItems={this.state.MultiselectItems}
          MultiselectSelectedItems={this.state.MultiselectSelectedItems}
          MultiselectMode={this.state.MultiselectMode}
          MultiselectCallback={this.state.MultiselectCallback}
          MultiselectTitle={this.state.MultiselectTitle}
          MultiselectSearchText={this.state.MultiselectSearchText}
        />
      );
    return (
      <div className="modal">
        <Modal
          {...this.props}
          size="lg"
          minHeight="500px"
          center={false}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          scrollable={true}
        >
          <Modal.Header>
            <div style={{ width: "100%" }}>
              <div className="sort">
                <div className="dialog-heading">
                  <div className="dialog-heading">
                    <div>
                      <h4 style={{ color: "#3598DB" }}>{`Guide Creator step ${wizardStepDisp} of 3, ${headerText}`}</h4>
                    </div>
                    {/* <i className="fas fa-check" onClick={() => this.saveSettings()}></i> */}

                    {this.state.activityIndicator ? (
                      <div className="loading">
                        <Spinner animation="border" variant="primary" />
                      </div>
                    ) : null}
                    <div>
                      <i className={`fas fa-times`} onClick={() => this.props.onHide()}></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div>
              {this.state.wizardStep === 1 || this.state.wizardStep === 2 ? (
                <div>
                  <div>
                    <h7 style={{ color: "#3598DB" }}>Note: this can take 40-70+ seconds</h7>
                  </div>
                  <MyToolTip loc="left" msg="Update Main schedule and Home screen content.">
                    <Form.Check
                      label="Update main schedule and Home"
                      type="checkbox"
                      id="Update"
                      checked={this.state.UpdateMainHome}
                      onChange={() => this.UpdateMainHomeChanged()}
                    />
                  </MyToolTip>
                  {/* <MyToolTip loc="left" msg="Optimize schedule by minimizing travel between attractions.">
                    <Form.Check
                      label="Visit attractions in order of proximity"
                      type="checkbox"
                      id="Update"
                      checked={this.state.OptimizeSchedule}
                      onChange={() => this.OptimizeScheduleChanged()}
                    />
                  </MyToolTip> */}
                  <p>
                    <div className="flex-container-left">
                      <div className="flex-container-left">
                        <span className="attribute">Days:</span>
                        <Form.Control
                          as="select"
                          custom
                          onChange={this.numberChanged}
                          defaultValue={this.state.selectedNumber}
                        >
                          {[1, 2, 3, 4, 5, 6, 7].map((quest, idx) => (
                            <option value={quest.label}>{quest.toString()}</option>
                          ))}
                        </Form.Control>
                      </div>
                      <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                      <div className="flex-container-left">
                        <span className="attribute">Type:</span>
                        <Form.Control
                          as="select"
                          custom
                          onChange={this.moodChanged}
                          defaultValue={this.state.mood === "standard" ? "" : this.state.mood}
                        >
                          {[
                            "",
                            "beach",
                            "busy",
                            "camping",
                            "dog friendly",
                            "fast paced",
                            "kid friendly",
                            "pet friendly",
                            "relaxed",
                            "road trip",
                            "romantic",
                            "solo",
                            "vegan",
                            "vegetarian",
                          ].map((mood, idx) => (
                            <option value={mood}>{mood}</option>
                          ))}
                        </Form.Control>
                      </div>
                    </div>
                  </p>
                  <p>
                    <div className="flex-container-left">
                      <div className="flex-container-left">
                        <span className="attribute">Transportation:</span>
                        <Form.Control
                          as="select"
                          custom
                          onChange={this.carStatusChanged}
                          defaultValue={this.state.carStatus}
                        >
                          {[
                            "",
                            "with a car",
                            "without a car",
                            "by train",
                            "by bus",
                            "by bike",
                            "with a motorcycle",
                          ].map((carStatus, idx) => (
                            <option value={carStatus}>{carStatus}</option>
                          ))}
                        </Form.Control>
                      </div>
                      <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                      <div className="flex-container-left">
                        <span className="attribute">Proximity:</span>
                        <Form.Control
                          as="select"
                          custom
                          onChange={this.proximityChanged}
                          defaultValue={this.state.proximity}
                        >
                          {["for", "in", "near", "in or near"].map((proximity, idx) => (
                            <option value={proximity}>{proximity}</option>
                          ))}
                        </Form.Control>
                      </div>
                    </div>
                  </p>
                  <p>
                    <div className="flex-container-left">
                      <span className="attribute">City, place or region: </span>
                      {/* <p>Enter location(s)</p> */}
                      <input
                        type="text"
                        value={this.state.selectedCity}
                        placeholder="Enter city or place"
                        onChange={(e) => this.handledDestinationChanged(e)}
                      />
                      <span className="attribute">or:</span>

                      <Button className="mt-3" onClick={() => this.selectWhich()}>
                        Criteria
                      </Button>
                    </div>
                  </p>
                  <p>
                    <div className="flex-container-left">
                      {/* <span className="attribute">Country: </span>
                      <input
                        type="text"
                        value={this.state.selectedCountry}
                        placeholder="Enter country and/or region"
                        onChange={(e) => this.handledCountryChanged(e)}
                      /> */}
                      <Button className="mt-3" onClick={() => this.selectCountry()}>
                        Country
                      </Button>
                      <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                      <span className="attribute">{this.state.selectedCountry}</span>
                    </div>
                  </p>
                  <p>
                    <div className="flex-container-left">
                      {/* <span className="attribute">Focus:</span>
                      <Form.Control as="select" custom onChange={this.focusOnChanged} defaultValue={this.state.focusOn}>
                        {[
                          "",
                          "sports",
                          "beaches",
                          "culture",
                          "art",
                          "camping",
                          "city sightseeing",
                          "resorts",
                          "parks",
                          "hiking",
                          "personal growth",
                          "activities",
                          "mountains",
                          "volunteering",
                          "museums",
                          "historical",
                          "scenic drives",
                          "food",
                          "wine",
                        ].map((focusOn, idx) => (
                          <option value={focusOn}>{focusOn}</option>
                        ))}
                      </Form.Control> */}
                      <Button className="mt-3" onClick={() => this.selectFocus()}>
                        Focus on
                      </Button>
                      <span className="attribute">{this.state.focusOn}</span>
                    </div>
                  </p>
                  <p>
                    {/* <p>
                      <div className="flex-container-left">
                        <span className="attribute">Attractions:</span>
                        <Form.Control
                          as="select"
                          custom
                          onChange={this.attractionsOptionChanged}
                          defaultValue={this.state.attractionsOption}
                        >
                          {["", "Include", "Include only"].map((option, idx) => (
                            <option value={option}>{option}</option>
                          ))}
                        </Form.Control>
                      </div>
                    </p> */}
                    <div className="flex-container-left">
                      <span className="attribute">Include: &nbsp;&nbsp;</span>
                      <MyToolTip loc="left" msg="Include only the places listed">
                        <Form.Check
                          label="Only"
                          type="checkbox"
                          id="Update"
                          checked={this.state.includeOnly}
                          onChange={() => this.IncludeOnlyChanged()}
                        />
                      </MyToolTip>
                      <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                      <input
                        type="text"
                        value={this.state.include}
                        placeholder="List of cities, places or attractions"
                        onChange={(e) => this.handleIncludeChanged(e)}
                      />
                    </div>
                  </p>
                  <p>
                    <div className="flex-container-left">
                      <span className="attribute">Exclude: </span>
                      {/* <p>Enter location(s)</p> */}
                      <input
                        type="text"
                        value={this.state.exclude}
                        placeholder="List of cities, places or attractions"
                        onChange={(e) => this.handleExcludeChanged(e)}
                      />
                    </div>
                  </p>
                  <p>
                    <div className="flex-container-left">
                      <div className="flex-container-left">
                        <span className="attribute">Date: </span>
                        <DatePicker
                          selected={Common.JSDateFromLuxonDate(this.state.dateStart)}
                          onChange={(date) => this.onChangeStart(date)}
                          //showTimeSelect
                          // locale="en-GB"
                          //timeFormat="HH:mm"
                          //timeIntervals={5}
                          //timeCaption="time"
                          dateFormat="MMMM d, yyyy"
                        />
                      </div>
                      {/* <b>
                        <Button className="mt-3" onClick={() => this.selectLanguage()}>
                          Language
                        </Button>
                        <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        <span className="attribute">{this.state.selectedLanguage}</span>
                      </b> */}
                    </div>
                  </p>
                  <p>
                    <span className="attribute">{question}</span>
                  </p>
                  <Button className="mt-3" onClick={() => this.Cancel()}>
                    Cancel
                  </Button>
                  <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                  {!this.state.activityIndicator ? (
                    <Button className="mt-3" onClick={() => this.Next(this.state.wizardStep)}>
                      Create
                    </Button>
                  ) : null}
                </div>
              ) : null}
              {this.state.wizardStep === 2222 ? (
                <div>
                  <p>
                    <span className="attribute">{question}</span>
                  </p>
                  <p>
                    <span className="attribute">Note: map is for country and not city</span>
                  </p>
                  <p>
                    <div className="flex-container-left">
                      <span className="attribute">Check lat,lon: </span>
                      {/* <p>Enter location(s)</p> */}
                      <input
                        type="text"
                        value={this.state.LatLong}
                        placeholder="latitude,longitude"
                        onChange={(e) => this.LatLongChanged(e)}
                      />
                      <img
                        src={static_map}
                        alt=""
                        className="text-center rounded"
                        style={{ objectFit: "cover" }}
                        width={300}
                        height={300}
                      />
                    </div>
                  </p>
                  <p>
                    {!this.state.activityIndicator ? (
                      <Button className="mt-3" onClick={() => this.Previous(this.state.wizardStep)}>
                        Previous
                      </Button>
                    ) : null}
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <Button className="mt-3" onClick={() => this.Cancel()}>
                      Cancel
                    </Button>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    {!this.state.activityIndicator ? (
                      <Button className="mt-3" onClick={() => this.Next(this.state.wizardStep)}>
                        Next
                      </Button>
                    ) : null}
                  </p>
                </div>
              ) : null}
              {this.state.wizardStep === 3 ? (
                <div>
                  <p>
                    <span className="attribute">{question}</span>
                  </p>

                  {/* <p>
                    <div className="flex-container-left">
                      <span className="attribute">Radius:</span>
                      <Form.Control
                        as="select"
                        custom
                        onChange={this.radiusChanged}
                        defaultValue={this.state.selectedNumber}
                      >
                        {["1Km", "5KM", "10Km", "25Km", "50Km"].map((radius, idx) => (
                          <option value={radius}>{radius}</option>
                        ))}
                      </Form.Control>
                    </div>
                  </p> */}
                  <p>
                    <span className="attribute">Schedule:</span>
                    <Form.Group>
                      <Form.Control
                        as="textarea"
                        style={{ height: "200px" }}
                        value={this.state.ScheduleText}
                        //onChange={(e) => this.handleChangeSchedule(e)}
                      />
                    </Form.Group>
                  </p>
                  {!this.state.activityIndicator ? (
                    <Button className="mt-3" onClick={() => this.Previous(this.state.wizardStep)}>
                      Previous
                    </Button>
                  ) : null}
                  <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                  <Button className="mt-3" onClick={() => this.Cancel()}>
                    Cancel
                  </Button>
                  <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                  {!this.state.activityIndicator ? (
                    <Button className="mt-3" onClick={() => this.Next(this.state.wizardStep)}>
                      Next
                    </Button>
                  ) : null}
                </div>
              ) : null}
              {this.state.wizardStep === 4 ? (
                <div>
                  <p>
                    <span className="attribute">{question}</span>
                    <p>
                      <span className="attribute">Places:</span>
                      <Form.Group>
                        <Form.Control
                          as="textarea"
                          style={{ height: "200px" }}
                          value={this.state.PlacesArray.join(",")}
                          //onChange={(e) => this.handleChangePlaces(e)}
                        />
                      </Form.Group>
                    </p>
                  </p>
                  {!this.state.activityIndicator ? (
                    <Button className="mt-3" onClick={() => this.Previous(this.state.wizardStep)}>
                      Previous
                    </Button>
                  ) : null}
                  <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                  <Button className="mt-3" onClick={() => this.Cancel(this.state.wizardStep)}>
                    Cancel
                  </Button>
                  <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                  {!this.state.activityIndicator ? (
                    <Button className="mt-3" onClick={() => this.Finish(this.state.wizardStep)}>
                      Finish
                    </Button>
                  ) : null}
                </div>
              ) : null}
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default TripAI;
