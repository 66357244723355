export default class Section {
  constructor(chapters, title, chapter, placeIds, groups, categories, info) {
    this.Title = title;
    this.ID = "";
    this.html = "";
    // this.PlaceIds = placeIds;
    // this.Groups = groups;
    // this.Categories = categories;
    // this.Info = info;
    if (chapters != null) {
      var id = Math.floor(Math.random() * 1000000000 + 1);
      while (this.findSection(chapters, id) != null) id = Math.floor(Math.random() * 1000000000 + 1);
      this.ID = id.toString();
      // let i = 1;
      // do {
      //   id = Math.floor(Math.random() * 1000000000 + i);
      //   id++;
      // } while (this.findSection(chapters, id) != null);
      // this.ID = id.toString();
    }
  }
  findSection(chapters, section_id) {
    // chapters.forEach((chapter) => {
    //   if (typeof chapter != "array") return;
    //   chapter.forEach((section) => {
    //     if ((section.ID = section_id)) return section;
    //   });
    // });
    for (let i = 0; i < chapters.length; i++) {
      for (let j = 0; j < chapters[i].sections.length; j++) {
        if (chapters[i].sections[j].ID == section_id) return chapters[i].sections[j];
      }
    }
    return null;
  }
}
