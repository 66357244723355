export default class Chapters {
  constructor(chapters, title) {
    this.Title = title;
    this.ID = "";
    this.sections = [];
    if (chapters != null) {
      var id = Math.floor(Math.random() * 1000000000 + 1);
      while (chapters.findIndex((c) => c.ID == id) != -1) id = Math.floor(Math.random() * 1000000000 + 1);
      this.ID = id.toString();
      //let id = 1;
      // do {
      //   id = Math.floor(Math.random() * 1000000000 + 1);
      // } while (chapters.findIndex((c) => c.ID == id) != -1);
      // this.ID = id.toString();
    }
  }
}
