import React, { useState, useEffect } from "react";
import "./App.css";
import VerticalSidebar from "./components/VerticalSidebar/VerticalSidebar";
import Places from "./components/Places/Places";
import Home from "./components/HomePanel/Home/Home";
import store from "./store/store";
import { Provider } from "react-redux";
// import MySectionList from "./components/VirtualSectionList/VirtualSectionList";
import SchedulePanel from "./components/SchedulePanel/SchedulePanel";
import MobileHeader from "./components/HomePanel/MobileHeader/MobileHeader";
import HomeHeader from "./components/HomePanel/HomeHeader/HomeHeader";
import MobileFooter from "./components/HomePanel/MobileFooter/MobileFooter";
import PlacesHeader from "./components/PlacesHeader/PlacesHeader";
import GuidesHeader from "./components/GuidesHeader/GuidesHeader";
import Guides from "./components/Guides/Guides";
import GuideDownloadComponent from "./components/modals/GuideDownload/GuideDownloadComponent";

import ScheduleHeader from "./components/SchedulePanel/Header/ScheduleHeader";
import Map from "./components/Map/Map";
// import HTMLReader from "./components/modals/HTMLReader";
import MenuModal from "./components/modals/MenuModal";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { slice1Actions } from "./store/reducer";
import { useResizeDetector } from "react-resize-detector";
import { Helmet } from "react-helmet";
import DataBase from "./dataBase/liquidGuides";
import Guide, { GuideAttributes } from "./Models/Guide";
import { DateTime } from "luxon";
import Schedule from "./Models/Schedule";
import Offcanvas from "react-bootstrap/Offcanvas";
import DirectionsDialogComponent from "./components/modals/DirectionsDialogComponent";
//import ReactGA from "react-ga";
import ReactGA from "react-ga4";

function App() {
  // const [HTMLReaderModal, setHTMLReaderModal] = useState(false);
  // const [HTMLContent, setHTMLContent] = useState("");
  // const [displayNone, setDisplayNone] = useState(false);
  const [_MobileMode, setMobileMode] = useState(false);
  // const [CopyMode, setCopyMode] = useState("Schedule");
  // const [CopyGuideID, setCopyGuideID] = useState("xx");

  const dispatch = useDispatch();
  // let ShowHTMLReader = useSelector((state) => state.ShowHTMLReader);
  // let ShowMultiselect = useSelector((state) => state.ShowMultiselect);
  let GuideLoaded = useSelector((state) => state.GuideLoaded);
  let MapFullScreen = useSelector((state) => state.MapFullScreen);
  let HomeFullScreen = useSelector((state) => state.HomeFullScreen);

  let CurrentAppScreen = useSelector((state) => state.CurrentAppScreen);
  let GuidesOrPlaces = useSelector((state) => state.GuidesOrPlaces);
  let MobileMode = useSelector((state) => state.MobileMode);
  let user_info = useSelector((state) => state.user_info);
  let GuideSortOption = useSelector((state) => state.GuideSortOption);
  let GuideDownloadMode = useSelector((state) => state.GuideDownloadMode);
  let GuideOrderBy = useSelector((state) => state.GuideOrderBy);
  let GuideSettings = useSelector((state) => state.GuideSettings);
  let HideSchedule = useSelector((state) => state.HideSchedule);
  let HidePlaces = useSelector((state) => state.HidePlaces);
  let HideMap = useSelector((state) => state.HideMap);
  let HideHome = useSelector((state) => state.HideHome);
  let HideSideMenu = useSelector((state) => state.HideSideMenu);

  let GuideSearchText = useSelector((state) => state.GuideSearchText);
  let GuideCountriesSelected = useSelector((state) => state.GuideCountriesSelected);
  let GuideCitiesSelected = useSelector((state) => state.GuideCitiesSelected);
  let GuideLanguageSelected = useSelector((state) => state.GuideLanguageSelected);
  let GuideCountriesMineSelected = useSelector((state) => state.GuideCountriesMineSelected);
  let GuideCitiesMineSelected = useSelector((state) => state.GuideCitiesMineSelected);
  let GuideLanguageMineSelected = useSelector((state) => state.GuideLanguageMineSelected);

  let guide_meta = useSelector((state) => state.guide_meta);
  let GuideInfoArray = useSelector((state) => state.GuideInfoArray);
  let GuideInfoArrayMine = useSelector((state) => state.GuideInfoArrayMine);
  let GuideType = useSelector((state) => state.GuideType);
  let ScheduleNames = useSelector((state) => state.ScheduleNames);
  let ShowOffcanvas = useSelector((state) => state.ShowOffcanvas);

  //window.addEventListener("load", handleLoad);

  useEffect(() => {
    ReactGA.initialize("G-7Q09NGWY7P");
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
    // ReactGA.initialize("UA-252264346-1");
    // ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  window.loadGuide = loadGuide;
  function loadGuide(guide_info_id) {
    //const guide_info_id = "wHvagurBLMmZ6zt4Cw6z"; // Imperia
    //const guide_info_id = "IQrZiedo7oJWa9GgLR6L"; // Florence
    window.record_last_guide_read = true;
    dispatch(slice1Actions.SetLoadingGuide({ value: true }));
    setTimeout(timeoutFunc, 5000);
    DataBase.readGuideTransaction(guide_info_id, readGuideDataReturnNew, user_info.uid);
  }

  const timeoutFunc = () => {
    dispatch(slice1Actions.SetLoadingGuide({ value: false }));
  };

  const readGuideDataReturnNew = (res) => {
    let GuideObj = null;
    if (res.pass === true) {
      GuideObj = new Guide(res.guide_id);
      const guide_json = res.res.data;
      console.log(`Guide Size is: ${guide_json.length}`);
      var guide_obj = JSON.parse(guide_json);
      const decoded = GuideObj.decodeGuideFb(guide_obj, res.infoDoc);
      if (decoded === true) {
        GuideObj.schedule.getPlaces_InSchedule();
        window.guide_obj = GuideObj;
        dispatch(
          slice1Actions.setGuide({
            guide: "new",
            guide_meta: { guide_info: res.infoDoc, guide_info_id: res.guide_info_id, guide_id: res.guide_id },
          })
        );
        dispatch(slice1Actions.setCreatorInfo({ value: res.creator_info }));
        dispatch(slice1Actions.setOwnerInfo({ value: res.owner_info }));
        dispatch(slice1Actions.pushGuideStack({ value: res.guide_info_id }));
        dispatch(slice1Actions.setHideSchedule({ value: false }));
        dispatch(slice1Actions.setGuidesOrPlaces({ value: "Places", GuideType: "None" }));
        setTimeout(fitPlaces, 1000);
        if (window.record_last_guide_read === true && user_info.uid !== "guest") {
          window.record_last_guide_read = false;
          recordLastGuideRead(res.guide_info_id);
        }
        // dispatch(slice1Actions.setGuideInfo({ value: res.infoDoc }));
        // dispatch(slice1Actions.setAuthorization({ guide_info: res.infoDoc, uid: user_info.uid }));
        //dispatch(slice1Actions.setOwnerInfo({ value: res.user_info }));
        //console.log(res.owner_info);
      } else {
        console.log("decode failed");
      }
    } else {
      //console.log(res.res);
    }
    window.record_last_guide_read = false;
    dispatch(slice1Actions.SetLoadingGuide({ value: false }));
  };

  const recordLastGuideRead = (guide_info_id) => {
    let _user_info = JSON.parse(JSON.stringify(user_info));
    _user_info.last_guide_id = guide_info_id;
    _user_info.last_guide_time = DateTime.now().toISO({ zone: "utc" });
    dispatch(slice1Actions.setUserInfo({ value: _user_info }));
    DataBase.updateUser(_user_info, _user_info.uid, updateUserCallback);
  };

  const updateUserCallback = (res) => {
    console.log("updateUserCallback", res.pass);
  };
  const fitPlaces = (user) => {
    dispatch(slice1Actions.setGuide({ guide: null, fitToBounds: true }));
  };
  // const onAuthStateChanged = (user) => {
  //   console.log("onAuthStateChanged", user);
  //   if (user) {
  //     User.findUserByUID(user.uid, findUserByUIDCallback);
  //   } else {
  //     const parsed = queryString.parse(window.location.search);
  //     let guide_to_load = "IQrZiedo7oJWa9GgLR6L";
  //     if (typeof parsed.id != "undefined") guide_to_load = parsed.id;
  //     loadGuide(guide_to_load);
  //   }
  // };

  // let MultiselectItems = useSelector((state) => state.MultiselectItems);
  // let MultiselectSelectedItems = useSelector((state) => state.MultiselectSelectedItems);
  // let MultiselectMode = useSelector((state) => state.MultiselectMode);
  // let MultiselectSearchText = useSelector((state) => state.MultiselectSearchText);
  // let MultiselectTitle = useSelector((state) => state.MultiselectTitle);
  // let MultiselectCallback = useSelector((state) => state.MultiselectCallback);

  // if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
  //   MobileMode = true;
  // }
  // const { width, height, ref } = useResizeDetector();
  // useEffect(() => {
  //   //const isMobile = window.matchMedia("only screen and (max-width: 760px)").matches;
  //   MobileMode = width < 760 ? true : false;
  // }, [width]);
  const SetGuideSortOption = () => {};

  const handleChooseCopyMode = (guide_info_id) => {
    // setCopyGuideID(guide_info_id);
    window.CopyGuideID = guide_info_id;
    const copy_modes = [
      { label: "Schedule", value: "Schedule" },
      { label: "Places", value: "Places" },
    ];
    dispatch(slice1Actions.setMultiselectCallback({ value: ChooseCopyModeCallBack }));
    dispatch(slice1Actions.setMultiselectItems({ value: copy_modes }));
    dispatch(slice1Actions.setMultiselectSelectedItems({ value: "" }));
    dispatch(slice1Actions.setMultiselectMode({ value: "uni" }));
    dispatch(slice1Actions.setMultiselectSearchText({ value: "" }));
    dispatch(slice1Actions.setMultiselectTitle({ value: "Select What to Copy" }));
    dispatch(slice1Actions.setShowMultiselect({ value: true }));
  };

  const ChooseCopyModeCallBack = (copy_modes) => {
    if (copy_modes.length > 0) {
      window.copyMode = copy_modes[0].label;
      //setCopyMode(copy_modes[0].label);
      CopyFromGuideCallback(window.CopyGuideID);
    }
  };

  const CopyFromGuideCallback = (guide_info_id) => {
    console.log("CopyFromGuideCallback");
    dispatch(slice1Actions.SetLoadingGuide({ value: true }));
    setTimeout(timeoutFunc, 5000);
    DataBase.readGuideTransaction(guide_info_id, readGuideDataReturnCopy, user_info.uid);
  };

  const readGuideDataReturnCopy = (res) => {
    let GuideObj = null;
    if (res.pass === true) {
      GuideObj = new Guide(res.guide_id);
      const guide_json = res.res.data;
      console.log(`Guide Size is: ${guide_json.length}`);
      var guide_obj = JSON.parse(guide_json);
      const decoded = GuideObj.decodeGuideFb(guide_obj, res.infoDoc);
      if (decoded === true) {
        GuideObj.schedule.getPlaces_InSchedule();
        window.guide_obj_copy = GuideObj;
        if (window.copyMode === "Schedule") {
          handleChooseSchedule();
          // setTimeout(() => {
          //   handleChooseSchedule();
          // }, 100);
        } else {
          handleChoosePlaces();
          // setTimeout(() => {
          //   handleChoosePlaces();
          // }, 100);
        }
        // dispatch(
        //   slice1Actions.setGuide({
        //     guide: "new",
        //     guide_meta: { guide_info: res.infoDoc, guide_info_id: res.guide_info_id, guide_id: res.guide_id },
        //   })
        // );
        // dispatch(slice1Actions.setCreatorInfo({ value: res.creator_info }));
        // dispatch(slice1Actions.setOwnerInfo({ value: res.owner_info }));
        // dispatch(slice1Actions.pushGuideStack({ value: res.guide_info_id }));
        // dispatch(slice1Actions.setHideSchedule({ value: false }));
        // dispatch(slice1Actions.setGuidesOrPlaces({ value: "Places", GuideType: "None" }));
        // setTimeout(fitPlaces, 1000);
        // if (window.record_last_guide_read === true && user_info.uid !== "guest") {
        //   window.record_last_guide_read = false;
        //   recordLastGuideRead(res.guide_info_id);
        // }
      } else {
        console.log("decode failed");
      }
    } else {
      //console.log(res.res);
    }
    dispatch(slice1Actions.SetLoadingGuide({ value: false }));
  };

  const handleChooseSchedule = () => {
    const schedulenames = window.guide_obj_copy.schedule.geScheduleNames();
    dispatch(slice1Actions.setMultiselectCallback({ value: chooseScheduleCallBack }));
    dispatch(slice1Actions.setMultiselectItems({ value: schedulenames }));
    dispatch(slice1Actions.setMultiselectSelectedItems({ value: "" }));
    dispatch(slice1Actions.setMultiselectMode({ value: "uni" }));
    dispatch(slice1Actions.setMultiselectSearchText({ value: "" }));
    dispatch(slice1Actions.setMultiselectTitle({ value: "Select Schedule" }));
    dispatch(slice1Actions.setShowMultiselect({ value: true }));
  };

  const chooseScheduleCallBack = (schedules) => {
    if (schedules.length > 0) {
      console.log("schedules[0].label", schedules[0].label);
      copySchedule(schedules[0].label);
    }
  };

  const copySchedule = (schedule_name) => {
    //let places = window.guide_obj_copy.getAllScheduledPlaces(schedule_name);
    //let cur_places = window.guide_obj.getAllScheduledPlaces(schedule_name);
    let events = window.guide_obj_copy.schedule.getEventsFromSchedule(schedule_name);
    for (let i = 0; i < events.length; i++) {
      let new_place_ids = [];
      let event_place_ids = events[i].Places;
      event_place_ids = event_place_ids.split(",");
      for (let j = 0; j < event_place_ids.length; j++) {
        const event_place = window.guide_obj_copy.places.getPlace(event_place_ids[j].trim());
        if (event_place === null) continue;
        let new_id = "";
        const similar_place = window.guide_obj.places.getSimilarPlaceInGuide(event_place);
        if (similar_place !== null) new_id = similar_place.ID;
        else new_id = window.guide_obj.places.addPlace(event_place);
        new_place_ids.push(new_id);
      }
      events[i].Places = new_place_ids.join(",");
      window.guide_obj.schedule.addEvent(events[i]);
    }
    dispatch(slice1Actions.setHideSchedule({ value: false }));
    dispatch(slice1Actions.setScheduleName({ value: schedule_name }));
    dispatch(slice1Actions.setGuidesOrPlaces({ value: "Places", GuideType: "Mine" }));
    setTimeout(() => {
      refreshGuide();
    }, 100);
  };

  const refreshGuide = () => {
    dispatch(slice1Actions.setGuide({ guide: null, fitToBounds: true }));
  };

  const handleChoosePlaces = () => {
    const places = window.guide_obj_copy.places.getPlaces();
    let placesList = [];
    for (let i = 0; i < places.length; i++) {
      placesList.push({ label: places[i].Name, value: places[i] });
    }
    dispatch(slice1Actions.setMultiselectCallback({ value: choosePlacesCallBack }));
    dispatch(slice1Actions.setMultiselectItems({ value: placesList }));
    dispatch(slice1Actions.setMultiselectSelectedItems({ value: "" }));
    dispatch(slice1Actions.setMultiselectMode({ value: "multi" }));
    dispatch(slice1Actions.setMultiselectSearchText({ value: "" }));
    dispatch(slice1Actions.setMultiselectTitle({ value: "Select Places" }));
    dispatch(slice1Actions.setShowMultiselect({ value: true }));
  };

  const choosePlacesCallBack = (places) => {
    if (places.length > 0) {
      for (let i = 0; i < places.length; i++) {
        const similar_place = window.guide_obj.places.getSimilarPlaceInGuide(places[i].value);
        if (similar_place === null) {
          const place = window.guide_obj_copy.places.getPlace(places[i].value.ID);
          if (place !== null) window.guide_obj.places.addPlace(place);
        }
      }
      dispatch(slice1Actions.setHideSchedule({ value: false }));
      dispatch(slice1Actions.setGuidesOrPlaces({ value: "Places", GuideType: "Mine" }));
      setTimeout(() => {
        refreshGuide();
      }, 100);
    }
  };

  const GuideDownloadCallback = (guide_info_id, guide_id) => {
    _DownloadGuide(guide_info_id, guide_id);
  };

  const _DownloadGuide = (guide_info_id, guide_id, guide_array) => {
    console.log("GuideDownloadCallback id:", guide_info_id);
    loadGuide(guide_info_id);

    let _user_info = JSON.parse(JSON.stringify(user_info));
    _user_info.last_guide_id = guide_info_id;
    _user_info.last_guide_time = DateTime.now().toISO({ zone: "utc" });
    dispatch(slice1Actions.setUserInfo({ value: _user_info }));
    DataBase.updateUser(_user_info, _user_info.uid, updateUserCallback);
  };

  const SaveGuides = (guides, countries, cities, languages) => {
    if (GuideType === "Featured") {
      dispatch(slice1Actions.setGuideInfoArray({ value: JSON.parse(JSON.stringify(guides)) }));
      dispatch(slice1Actions.setGuideCountries({ value: countries }));
      dispatch(slice1Actions.setGuideCities({ value: cities }));
      dispatch(slice1Actions.setGuideLanguages({ value: languages }));
    } else {
      dispatch(slice1Actions.setGuideInfoArrayMine({ value: JSON.parse(JSON.stringify(guides)) }));
      dispatch(slice1Actions.setGuideCountriesMine({ value: countries }));
      dispatch(slice1Actions.setGuideCitiesMine({ value: cities }));
      dispatch(slice1Actions.setGuideLanguagesMine({ value: languages }));
    }
  };
  const reportWindowSize = (evt) => {
    //console.log("reportWindowSize", evt.currentTarget.innerWidth, evt.currentTarget.innerHeight);
    const minSize = Math.min(evt.currentTarget.innerWidth, evt.currentTarget.innerHeight);
    setMobileMode(minSize < 500 ? true : false);
    dispatch(slice1Actions.setMobileMode({ value: minSize < 500 ? true : false }));
  };

  const handleLoad = () => {
    window.addEventListener("resize", reportWindowSize);
    const minSize = Math.min(window.innerWidth, window.innerHeight);
    setMobileMode(minSize < 500 ? true : false);
    dispatch(slice1Actions.setMobileMode({ value: minSize < 500 ? true : false }));
    setTimeout(function () {
      // Hide the address bar!
      window.scrollTo(0, 1);
    }, 100);
  };

  let r = null;
  if (GuideSettings.hide_schedule === true || HideSchedule === true) {
    r = document.querySelector(":root");
    r.style.setProperty("--schedwidth", "0px");
    r.style.setProperty("--schedule-menu", "0px");
    r.style.setProperty("--scheduleheightfixed", "0px");
  } else {
    r = document.querySelector(":root");
    r.style.setProperty("--schedwidth", "250px");
    r.style.setProperty("--schedule-menu", "70px");
    r.style.setProperty("--scheduleheightfixed", "500px");
  }
  if (HidePlaces === true) {
    r = document.querySelector(":root");
    r.style.setProperty("--placeswidth", "0px");
    r.style.setProperty("--places-menu", "0px");
    r.style.setProperty("--placesheightfixed", "0px");
  } else {
    r = document.querySelector(":root");
    r.style.setProperty("--placeswidth", "350px");
    r.style.setProperty("--places-menu", "70px");
    r.style.setProperty("--placesheightfixed", "500px");
  }
  if (HideMap === true) {
    r = document.querySelector(":root");
    r.style.setProperty("--mapheight", "0px");
    r.style.setProperty("--mapheightfixed", "0px");
  } else {
    r = document.querySelector(":root");
    r.style.setProperty("--mapheight", "2fr");
    r.style.setProperty("--mapheightfixed", "500px");
  }
  if (HideHome === true) {
    r = document.querySelector(":root");
    r.style.setProperty("--homeheight", "0px");
    r.style.setProperty("--homeheightfixed", "0px");
  } else {
    r = document.querySelector(":root");
    r.style.setProperty("--homeheight", "3fr");
    r.style.setProperty("--homeheightfixed", "500px");
  }
  if (HideSideMenu === true) {
    r = document.querySelector(":root");
    r.style.setProperty("--sidemenupanel", "0px");
  } else {
    r = document.querySelector(":root");
    r.style.setProperty("--sidemenupanel", "80px");
  }

  window.addEventListener("load", handleLoad);

  window.web_app = true;
  window.mapRef = null;
  window.DirectionsWaypointsLocation = [];
  window.DirectionsFromLocation = null;
  window.DirectionsToLocation = null;
  window.LocationWatchID = null;
  //window.GuidesOrPlaces = "Places";
  //window.hiddenPlacesToShow = [];

  let side_panel = false;
  let grid_sel = "side";
  if (HomeFullScreen === true) grid_sel = "map";
  //console.log("MobileMode", MobileMode);
  if (MapFullScreen === true && _MobileMode === true)
    return (
      <div className={`app-mobile-full-screen`}>
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="mobile-web-app-capable" content="yes" />
        {CurrentAppScreen === "Map" ? (
          <div className="app-mobile-main-full-screen">
            {GuideLoaded ? <Map callback={GuideDownloadCallback} /> : null}
          </div>
        ) : null}
        {ShowOffcanvas ? (
          <Offcanvas
            show={ShowOffcanvas}
            onHide={() => dispatch(slice1Actions.setShowOffcanvas({ value: false }))}
            placement={"bottom"}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>Directions</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <DirectionsDialogComponent></DirectionsDialogComponent>
            </Offcanvas.Body>
          </Offcanvas>
        ) : null}
      </div>
    );

  if (_MobileMode === true)
    return (
      <div className={`app-mobile`}>
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="mobile-web-app-capable" content="yes" />
        {MapFullScreen === false ? (
          <div className="app-mobile-footer">
            <MobileFooter />
          </div>
        ) : null}
        {CurrentAppScreen === "Home" ? (
          <div className="app-mobile-header">
            <HomeHeader />
          </div>
        ) : null}
        {(CurrentAppScreen === "Places" || CurrentAppScreen === "Map") && GuidesOrPlaces === "Places" && !HidePlaces ? (
          <div className="app-mobile-header">
            <PlacesHeader />
          </div>
        ) : null}
        {GuidesOrPlaces === "Guides" && !HidePlaces ? (
          <div className="app-mobile-header">
            <GuidesHeader />
          </div>
        ) : null}
        {CurrentAppScreen === "Schedule" ? (
          <div className="app-mobile-header">
            <ScheduleHeader />
          </div>
        ) : null}
        {/* {CurrentAppScreen === "Map" ? (
        <div className="app-mobile-header">
        <MobileHeader />
        </div>
        ) : null} */}
        {CurrentAppScreen === "Home" ? (
          <div className="app-mobile-main">
            <Home />
          </div>
        ) : null}
        {CurrentAppScreen === "Places" ? (
          <div className="app-mobile-main">
            <Places />
          </div>
        ) : null}
        {GuidesOrPlaces === "Guides" ? (
          <div className="app-mobile-main">
            <GuideDownloadComponent
              user_info={user_info}
              guide_sort_option={GuideSortOption}
              set_guide_sort_option={SetGuideSortOption}
              callback={GuideDownloadCallback}
              copy_from_guide={handleChooseCopyMode}
              save_guides={SaveGuides}
              mode={GuideDownloadMode}
              guide_search_text={GuideSearchText}
              guide_meta={guide_meta}
              guide_order_by={GuideOrderBy}
              guide_info_array={GuideType === "Featured" ? GuideInfoArray : GuideInfoArrayMine}
              guide_countries_selected={GuideType === "Featured" ? GuideCountriesSelected : GuideCountriesMineSelected}
              guide_cities_selected={GuideType === "Featured" ? GuideCitiesSelected : GuideCitiesMineSelected}
              guide_language_selected={GuideType === "Featured" ? GuideLanguageSelected : GuideLanguageMineSelected}
              guide_type={GuideType}
            />
          </div>
        ) : null}
        {CurrentAppScreen === "Schedule" ? (
          <div className="app-mobile-main">
            <SchedulePanel />
          </div>
        ) : null}
        {CurrentAppScreen === "Map" ? (
          <div className="app-mobile-main">{GuideLoaded ? <Map callback={GuideDownloadCallback} /> : null}</div>
        ) : null}
        {ShowOffcanvas ? (
          <Offcanvas
            show={ShowOffcanvas}
            onHide={() => dispatch(slice1Actions.setShowOffcanvas({ value: false }))}
            placement={"bottom"}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>Directions</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <DirectionsDialogComponent></DirectionsDialogComponent>
            </Offcanvas.Body>
          </Offcanvas>
        ) : null}
      </div>
    );

  if (MapFullScreen === true)
    return (
      <div className={`app-grid-map-fullscreen`}>
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="mobile-web-app-capable" content="yes" />
        <div id="map-start" className="map-panelx">
          {GuideLoaded ? <Map callback={GuideDownloadCallback} /> : null}
        </div>
        {ShowOffcanvas ? (
          <Offcanvas
            show={ShowOffcanvas}
            onHide={() => dispatch(slice1Actions.setShowOffcanvas({ value: false }))}
            placement={"end"}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>Directions</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <DirectionsDialogComponent></DirectionsDialogComponent>
            </Offcanvas.Body>
          </Offcanvas>
        ) : null}
      </div>
    );
  return (
    <div className={`app-grid${side_panel === true ? "" : `-no-${grid_sel}`}`}>
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="mobile-web-app-capable" content="yes" />
      {/* <Helmet>
        <meta charSet="utf-8" />
        <title>Andiamo Places. Create Travel Guides and Journals for Free</title>
        <meta name="description" content="Create Travel Guides and Journals for Free" />
        <meta name="keywords" content="travel, guide, travel guide, travel blog, travel journal" />
        <meta
          property="og:image"
          content="https://firebasestorage.googleapis.com/v0/b/liquidguides-2ce60.appspot.com/o/fb_images%2FE2Fqqu9FWDgTdpOzdKQOKEdo6wA3%2F2022-02-23T11%3A59%3A45.636%2B01%3A00_m"
        />
      </Helmet> */}

      {GuidesOrPlaces === "Guides" && !HidePlaces ? (
        <div id="places-start" className="places-menu-panel">
          <GuidesHeader />
        </div>
      ) : null}
      {GuidesOrPlaces !== "Guides" && !HidePlaces ? (
        <div id="places-start" className="places-menu-panel">
          <PlacesHeader />
        </div>
      ) : null}
      <div id="home-start" className="main-menu-panel">
        {/* <HomeHeader /> */}
        <div className="sticky-header">
          <HomeHeader />
        </div>
      </div>
      <div id="schedule-start" className="schedule-menu-panel">
        <ScheduleHeader />
      </div>

      {GuidesOrPlaces === "Guides" ? (
        <div className="places-panel">
          <GuideDownloadComponent
            user_info={user_info}
            guide_sort_option={GuideSortOption}
            set_guide_sort_option={SetGuideSortOption}
            callback={GuideDownloadCallback}
            copy_from_guide={handleChooseCopyMode}
            save_guides={SaveGuides}
            mode={GuideDownloadMode}
            guide_search_text={GuideSearchText}
            guide_meta={guide_meta}
            guide_order_by={GuideOrderBy}
            guide_info_array={GuideType === "Featured" ? GuideInfoArray : GuideInfoArrayMine}
            guide_countries_selected={GuideType === "Featured" ? GuideCountriesSelected : GuideCountriesMineSelected}
            guide_cities_selected={GuideType === "Featured" ? GuideCitiesSelected : GuideCitiesMineSelected}
            guide_language_selected={GuideType === "Featured" ? GuideLanguageSelected : GuideLanguageMineSelected}
            guide_type={GuideType}
          />
        </div>
      ) : (
        <div className="places-panel">
          <Places />
        </div>
      )}
      <div className="home-panelx">
        <Home />
      </div>
      <div className="schedule-panelx">
        <SchedulePanel />
      </div>
      <div id="map-start" className="map-panelx">
        {GuideLoaded ? <Map callback={GuideDownloadCallback} /> : null}
      </div>
      <div className="side-menu-panel">{HideSideMenu !== true ? <VerticalSidebar /> : null}</div>
      {ShowOffcanvas ? (
        <Offcanvas
          show={ShowOffcanvas}
          onHide={() => dispatch(slice1Actions.setShowOffcanvas({ value: false }))}
          placement={"end"}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Directions</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <DirectionsDialogComponent></DirectionsDialogComponent>
          </Offcanvas.Body>
        </Offcanvas>
      ) : null}
      {/* {side_panel === true ? (
        <div className="side-menu-panel">
          <VerticalSidebar />
        </div>
      ) : null} */}

      {/* <div className="scroll-panel">
        <div className="scroll-icon">
          <i className="fa fa-arrows-v"></i>
        </div>
      </div> */}
      {/* <div className="bottom-menu-panel">
        <h1>Bottom Panel</h1>
      </div> */}
      {/* <HTMLReader show={ShowHTMLReader} onHide={() => dispatch(slice1Actions.setShowHTMLReader({ value: false }))} /> */}

      {/* <MultiSelectModal
        show={ShowMultiselect}
        onHide={() => dispatch(slice1Actions.setShowMultiselect({ value: false }))}
        MultiselectItems={MultiselectItems}
        MultiselectSelectedItems={MultiselectSelectedItems}
        MultiselectMode={MultiselectMode}
        MultiselectCallback={MultiselectCallback}
        MultiselectTitle={MultiselectTitle}
        MultiselectSearchText={MultiselectSearchText}
      /> */}
    </div>
  );
}

export default App;
