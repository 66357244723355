const urlLinks = [
  "urllink",
  "placemenulink",
  "placelink",
  "placemoreinfolink",
  "placeslink",
  "maplink",
  "streetviewlink",
  "sectionlink",
  "imagelink",
  "guidelink",
  "schedulelink",
  "itinerarylink",
  "videolink",
  "audiolink",
  //"https",
  "Https",
  "directionslink",
  "weatherlink",
];

//href="schedulelink:One%20Day%20Tour"
//href="javascript:customLink('schedulelink:One%20Day%20Tour');"

export function urlSchemeToFunctions(html) {
  //html = htmlScaleAdjust(html);

  let more = true;
  let max = 0;
  while (more === true && max < 1000) {
    max++;
    more = false;
    for (let i = 0; i < urlLinks.length; i++) {
      if (urlLinks[i] === "") continue;
      let index1 = html.indexOf(`"${urlLinks[i]}:`);
      if (index1 !== -1) {
        //console.log("found:", `"${urlLinks[i]}:`);
        if (urlLinks[i] === "https") {
          //console.log("substring", html.substring(index1 - 5, index1 - 1));
          if (html.substring(index1 - 5, index1 - 1) === "src") continue;
        }
        let index2 = html.indexOf('"', index1 + 1);
        //console.log(`index1=${index1}:index2=${index2}`);
        if (index2 > index1) {
          more = true;
          let link = html.substring(index1 + 1, index2);
          //console.log(`link:${link}:`);
          let link2 = link.replace(/:/, ";");

          // html = html.replace(link, `javascript:customLink('${link2}');`);
          html = html.replace(link, `#f" onclick="customLink('${link2}');`);
          // html = html.replace(`href="remove_this" `, "");
        }
      }
    }
  }
  //console.log("urlSchemeToFunctions, exit", html);
  return html;
}

export const getCSS = (html) => {
  let index1 = html.indexOf("<style>", 0);
  let index2 = html.indexOf("</style>", index1 + 1);
  let inline_style = "";
  let _html = html;
  if (index1 !== -1 && index2 !== -1) {
    inline_style = html.substring(index1 + 7, index2);
    _html = html.substring(index2 + 8);
  }
  const scale = htmlScaleFactor();
  let content_style = `a {color: #1913AB; text-decoration: none !important;} 
  a:visited {color: #661099;} a:active {color: red;}
  a:hover {color: purple; background: #AFB; } 
  .redtext {color: red;}`;
  //.home-resize-panel {transform-origin: top left; transform: scale(${scale.div}); font-size: ${scale.font};}
  content_style = content_style + inline_style;
  return { css: content_style, html: _html };
};

export function htmlScaleAdjust(html) {
  //if (html.includes("a {color: #1913AB; text-decoration: none !important;} ")) return html;
  let css_html = getCSS(html);
  // return "<style>" + css_html.css + "</style>" + css_html.html;

  console.log("window.innerWidth", window.innerWidth);
  let scale = "140%";
  if (window.innerWidth <= 280) scale = "240%";
  else if (window.innerWidth <= 320) scale = "208%";
  else if (window.innerWidth <= 375) scale = "190%";
  else if (window.innerWidth <= 414) scale = "160%";
  else if (window.innerWidth <= 540) scale = "140%";
  else if (window.innerWidth === 640) scale = "240%";
  else if (window.innerWidth <= 768) scale = "160%";
  else if (window.innerWidth <= 981) scale = "200%";
  else if (window.innerWidth <= 1024) scale = "180%";

  //console.log("urlSchemeToFunctions, enter", html);
  //if (html.includes("<html>")) html = html.replace("<html>", "<html font-size: 200%>");

  //if (!html.includes("<style>body {font-size: ")) {
  //html = `<style>body {font-size: ${scale}}</>` + html;

  //html = `<div class="usercontent"><style>body {font-size: ${scale};} .usercontent :not(img) {width: ${scale};}</style><br>${html}</div>`;

  //html = `<style>body {font-size: ${scale}; } .scalefix {width: ${scale};} ${css_html}</style>${html}`;

  // html = html.replace(/<p>/g, `<p class="scalefix">`);
  // html = html.replace(/<ul>/g, `<ul class="scalefix">`);
  // html = html.replace(/<div>/g, `<div class="scalefix">`);

  //html = html.replace(/<p style="text-align:center;">/g, `<p style="text-align:center;" class="scalefix">`);
  //html = html.replace(/<div><p/g, `<div class="scalefix">`);

  //html = html.replace(/<div>/g, `<p>`);

  //html = html.replace(/<\/div>/g, `</p>`);

  // html = html.replaceAll("<div></div>", "");
  // html = html.replaceAll("<div>", "");
  // html = html.replaceAll("</div>", "");
  //}
  return html;
}

export function htmlScaleFactor() {
  // return { font: "100%", div: 1.0 };
  console.log("window.innerWidth", window.innerWidth);
  let scale = "140%";
  if (window.innerWidth <= 280) scale = "240%";
  else if (window.innerWidth <= 320) scale = "208%";
  else if (window.innerWidth <= 375) scale = "190%";
  else if (window.innerWidth <= 414) scale = "160%";
  else if (window.innerWidth <= 540) scale = "140%";
  else if (window.innerWidth === 640) scale = "240%";
  else if (window.innerWidth <= 768) scale = "160%";
  else if (window.innerWidth <= 981) scale = "200%";
  else if (window.innerWidth <= 1024) scale = "180%";
  let div_scale = "0.714";
  if (window.innerWidth <= 280) div_scale = "0.417";
  else if (window.innerWidth <= 320) div_scale = "0.48";
  else if (window.innerWidth <= 375) div_scale = "0.52";
  else if (window.innerWidth <= 414) div_scale = "0.625";
  else if (window.innerWidth <= 540) div_scale = "0.714";
  else if (window.innerWidth === 640) div_scale = "0.417";
  else if (window.innerWidth <= 768) div_scale = "0.625";
  else if (window.innerWidth <= 981) div_scale = "0.5";
  else if (window.innerWidth <= 1024) div_scale = "0.714";

  return { font: scale, div: div_scale };
}
//<a href="#!" onClick={clickAction}>Link</a>
//<a href="#foo" onclick="customLink('sectionlink;769773062');"></a>
