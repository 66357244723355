import { connect } from "react-redux";
import React, { Component, useState, useEffect } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import AlertModal from "./AlertModal";
import "./Modal.css";
import PromptModal from "./PromptModal";
import MultiSelectModal from "./MultiSelectModal";
import IconsDialog from "./IconsDialog";
import Place from "../../Models/Place";
import DataBase from "../../dataBase/liquidGuides";
import Review from "../../Models/Review";
import Rating from "react-rating";

class ReviewDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rating: 3,
      comment: "",
      review: new Review(3, "", this.props.user_info),

      AlertModalShow: false,
      AlertModalTitle: "",
      AlertModalMessage: "",
      AlertModalButtons: [],
    };
    this.handleChangeComment = this.handleChangeComment.bind(this);
  }

  componentDidMount() {
    DataBase.reviewExists(this.props.guide_info_id, this.props.user_info.uid, this.reviewExistsCallback);
  }

  reviewExistsCallback = (res) => {
    if (res.pass == false) return;
    this.setState({ ...this.state, review: res.review, rating: res.review.rating, comment: res.review.comment });
  };

  submitReview = () => {
    //console.log("Rating submitted: " + this.state.rating, this.state.comment);
    let review = this.state.review;
    review.rating = this.state.rating;
    review.comment = this.state.comment;
    DataBase.addReviewTransaction(this.props.guide_info_id, review, this.submitReviewCallback);
    this.props.onHide();
  };

  submitReviewCallback = (res) => {
    if (res.pass == false) {
      //Alert.alert("Could not submit review... try again", res.error.MESSAGE);
      //console.log("Error submitting Review", res.error.MESSAGE);
      return;
    }
    //console.log("Review submitted successfully");
  };

  handleChangeComment = (newText) => {
    this.setState({ ...this.state, comment: newText });
  };

  ratingCompleted = (rating) => {
    //console.log("Rating is: " + rating);
    this.setState({ ...this.state, rating: rating });
  };

  render() {
    return (
      <div className="modal">
        <Modal {...this.props} size="md" aria-labelledby="contained-modal-title-vcenter" centered scrollable={true}>
          <Modal.Header>
            <div style={{ width: "100%" }}>
              <div className="sort">
                <div className="dialog-heading">
                  <div className="dialog-heading">
                    <div>
                      <h4 style={{ color: "#3598DB" }}>Rate Guide</h4>
                    </div>
                    <i className="fas fa-check" onClick={() => this.submitReview()}></i>

                    <span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>

                    <div>
                      <i className={`fas fa-times`} onClick={() => this.props.onHide()}></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div>
              <Rating
                // emptySymbol="fa fa-star-o fa-2x"
                // fullSymbol="fa fa-star fa-2x"
                fractions={2}
                initialRating={this.state.rating}
              />
              <p className="attribute">Comment</p>
              <Form.Group>
                <Form.Control
                  as="textarea"
                  // type="text"
                  value={this.state.comment}
                  onChange={(e) => this.handleChangeComment(e.target.value)}
                  style={{ height: "200px" }}
                />
              </Form.Group>
            </div>
          </Modal.Body>
        </Modal>
        <AlertModal
          show={this.state.AlertModalShow}
          onHide={() => this.setState({ AlertModalShow: false })}
          AlertModalTitle={this.state.AlertModalTitle}
          AlertModalMessage={this.state.AlertModalMessage}
          AlertModalButtons={this.state.AlertModalButtons}
        />
      </div>
    );
  }
}

export default ReviewDialog;
