import { connect } from "react-redux";
import React, { Component, useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import "./Modal.css";
import PromptModal from "./PromptModal";
import AlertModal from "./AlertModal";
import MultiSelectModal from "./MultiSelectModal";
import IconsDialog from "./IconsDialog";
import Place from "../../Models/Place";
import CustomIcon from "../../Models/CustomIcon";

class CustomIcons extends Component {
  constructor(props) {
    super(props);
    let list_to_display = [];
    if (window.guide_obj !== null && typeof window.guide_obj !== "undefined")
      list_to_display = window.guide_obj.getCustomIconsToDisplay();
    // this.setState({ list_to_display: list_to_display });
    this.state = {
      custom_icon: "",
      list_to_display: list_to_display,
      searchText: "",

      PromptModalShow: false,
      PromptModalTitle: "Enter Value",
      PromptModalMessage: "",
      PromptModalButtons: [],
      PromptModalInputs: [],
      PromptModalArea: false,

      AlertModalShow: false,
      AlertModalTitle: "",
      AlertModalMessage: "",
      AlertModalButtons: [],

      IconsDialogShow: false,
    };
  }

  componentDidMount() {
    console.log("componentDidMount");
    this.getList();
  }

  getList = () => {
    let list_to_display = [];
    if (window.guide_obj !== null && typeof window.guide_obj !== "undefined")
      list_to_display = window.guide_obj.getCustomIconsToDisplay();
    this.setState({ list_to_display: list_to_display });
  };

  MyAlert = (title, message, buttons) => {
    this.setState({
      AlertModalTitle: title,
      AlertModalMessage: message,
      AlertModalButtons: buttons,
      AlertModalShow: true,
    });
  };

  _custom_icon = null;
  _custom_icon_obj = null;
  getURL = (custom_icon) => {
    this._custom_icon_obj = custom_icon.obj;
    // if (custom_icon != null) this._custom_icon = custom_icon.name;
    this.setState({
      PromptModalTitle: "Custom Icon",
      PromptModalMessage: "Edit Icon URL",
      PromptModalButtons: [
        { Title: "OK", Method: this.getURLCallback },
        { Title: "CANCEL", Method: null },
      ],
      PromptModalInputs: [{ name: "Custom Icon url", value: custom_icon.obj.url }],
      PromptModalArea: true,
    });
    setTimeout(() => {
      this.showPromptModal();
    }, 100);
  };

  showPromptModal = () => {
    this.setState({
      PromptModalShow: true,
    });
  };

  getURLCallback = (inputs) => {
    if (inputs === null) return;

    let custom_icon = new CustomIcon();
    custom_icon.id = this._custom_icon_obj.id;
    custom_icon.name = this._custom_icon_obj.name;
    custom_icon.poi_img = this._custom_icon_obj.poi_img;
    custom_icon.url = inputs[0].value;

    window.guide_obj.modifyCustomIcon(custom_icon, custom_icon);
    this.getList();
    if (this.props.update_display !== null) this.props.update_display();
  };

  addModifyCustomIcon = (add, custom_icon) => {
    this._custom_icon_obj = custom_icon;
    if (custom_icon != null) this._custom_icon = custom_icon.name;
    this.setState({
      PromptModalTitle: "Custom Icon",
      PromptModalMessage: add ? "Enter Custom Icon Name" : "Edit Custom Icon Name",
      PromptModalButtons: [
        { Title: "OK", Method: add ? this.AddCustomIconCallback : this.ModifyCustomIconNameCallback },
        { Title: "CANCEL", Method: null },
      ],
      PromptModalInputs: [{ name: "Custom Icon Name", value: add ? "" : custom_icon.name }],
      PromptModalArea: false,
    });
    setTimeout(() => {
      this.showPromptModal();
    }, 100);
  };

  ModifyCustomIconNameCallback = (inputs) => {
    if (inputs === null) return;

    let custom_icon = new CustomIcon();
    custom_icon.id = this._custom_icon_obj.obj.id;
    custom_icon.name = inputs[0].value;
    custom_icon.poi_img = this._custom_icon_obj.obj.poi_img;
    custom_icon.url = this._custom_icon_obj.obj.url;

    window.guide_obj.modifyCustomIcon(custom_icon, custom_icon);
    this.getList();
    if (this.props.update_display !== null) this.props.update_display();
  };

  AddCustomIconCallback = (inputs) => {
    if (inputs === null) return;
    window.guide_obj.addCustomIcon(inputs[0].value);
    this.getList();
    if (this.props.update_display !== null) this.props.update_display();
  };

  deleteCustomIcon = (custom_icon) => {
    this.MyAlert("Confirmation", "Confirm Delete CustomIcon?", [
      { Title: "OK", Method: () => this._deleteCustomIcon(custom_icon) },
      { Title: "CANCEL", Method: null },
    ]);
  };

  _deleteCustomIcon = (custom_icon) => {
    window.guide_obj.deleteCustomIcon(custom_icon);
    this.getList();
  };

  chooseCustomIconIcon = (custom_icon) => {
    this._custom_icon_obj = custom_icon;
    this.props.navigation.navigate("IconsDialog", { callback: this.chooseCustomIconIconCallback });
  };

  chooseCustomIconIconCallback = (poi_img) => {
    let custom_icon = new CustomIcon();
    custom_icon.id = this._custom_icon_obj.obj.id;
    custom_icon.name = this._custom_icon_obj.obj.name;
    custom_icon.poi_img = poi_img;

    window.guide_obj.modifyCustomIcon(custom_icon, custom_icon);
    this.getList();
  };

  handleSearchChanged = (event) => {
    this.setState({ searchText: event.target.value });
    let list_to_display = window.guide_obj.getCustomIconsToDisplay();
    let temp_list = [];
    const _searchText = event.target.value.toUpperCase();
    for (let i = 0; i < list_to_display.length; i++)
      if (list_to_display[i].label.toUpperCase().includes(_searchText)) temp_list.push(list_to_display[i]);
    // list_to_display.forEach((item) => {
    //  if (item.label.toUpperCase().includes(_searchText)) temp_list.push(item);
    // });
    this.setState({ list_to_display: temp_list });
  };

  UpdateIcon = (icon) => {
    console.log(icon);
    this._custom_icon_obj.obj.poi_img = icon;
    window.guide_obj.modifyCustomIcon(this._custom_icon_obj.obj, this._custom_icon_obj.obj);
    this.props.update_display();
  };

  ChangeIcon = (custom_icon) => {
    this._custom_icon_obj = custom_icon;
    if (custom_icon != null) this._custom_icon = custom_icon.name;
    this.setState({ IconsDialogShow: true });
  };

  render() {
    // if (this.state.ShowMultiselect)
    //   return (
    //     <MultiSelectModal
    //       show={this.state.ShowMultiselect}
    //       onHide={() => this.setState({ ShowMultiselect: false })}
    //       MultiselectItems={this.state.MultiselectItems}
    //       MultiselectSelectedItems={this.state.MultiselectSelectedItems}
    //       MultiselectMode={this.state.MultiselectMode}
    //       MultiselectCallback={this.state.MultiselectCallback}
    //       MultiselectTitle={this.state.MultiselectTitle}
    //       MultiselectSearchText={this.state.MultiselectSearchText}
    //     />
    //   );
    // // else if (this.state.list_to_display == []) {
    // //   this.getList();
    // //   return null;
    // // }

    // let list_to_display = null;
    // if (window.guide_obj !== null && typeof window.guide_obj !== "undefined")
    //   list_to_display = window.guide_obj.getCustomIconsToDisplay();
    // if (list_to_display == null) return null;
    let list_to_display = this.state.list_to_display;
    if (list_to_display == null) {
      if (window.guide_obj !== null && typeof window.guide_obj !== "undefined")
        list_to_display = window.guide_obj.getCustomIconsToDisplay();
      if (list_to_display == null) return null;
    }
    return (
      <div className="modal">
        <Modal
          {...this.props}
          size={window.innerWidth < 450 ? "lg" : "md"}
          dialogClassName="myModalClass"
          aria-labelledby="contained-modal-title-vcenter"
          centered={false}
          scrollable={true}
        >
          <Modal.Header closeButton>
            <div style={{ width: "100%" }}>
              <div className="sort">
                <div className="dialog-heading">
                  <div>
                    <h4 className="color-blue">Custom Icons</h4>
                  </div>
                </div>
                <div className="dialog-heading">
                  <input
                    type="text"
                    placeholder="Search"
                    value={this.state.searchText}
                    onChange={this.handleSearchChanged}
                  />
                  {/* {MultiselectMode === "multi" ? (
                    <i className={`fas fa-tasks ${show ? "" : "color"}`} onClick={handleSelectAll}></i>
                  ) : null} */}
                  <i
                    title="Add custom_icon"
                    className={`fa fa-plus`}
                    onClick={() => this.addModifyCustomIcon(true, null)}
                  ></i>
                </div>
                {/* <Modal.Title id="example-modal-sizes-title-sm">{MultiselectTitle}</Modal.Title> */}
              </div>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="multiselect-list">
              {list_to_display.map((item, index) => (
                <div className="list-item-card" key={index}>
                  <div className="flex-container">
                    <div className="flex-items-left" key={index}>
                      <img style={{ maxHeight: "32px" }} src={item.obj.url} />
                    </div>
                    <div className="flex-item-middle truncate">
                      {item.label}
                      {/* <span className="text-size-1-0">{item.label}</span> */}
                    </div>
                    <div className="flex-item-right flex-container-right">
                      <i
                        title="Delete"
                        className={`fa fa-trash-alt`}
                        style={{ color: "red" }}
                        onClick={() => this.deleteCustomIcon(item)}
                      ></i>
                      <span> </span>
                      <i
                        title="Change Name"
                        className={`fa fa-edit color-blue`}
                        onClick={() => this.addModifyCustomIcon(false, item)}
                      ></i>
                      <span> </span>
                      <i
                        title="Change Image URL"
                        className={`fa fa-image color-blue`}
                        onClick={() => this.getURL(item)}
                      ></i>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </Modal.Body>
        </Modal>
        <PromptModal
          show={this.state.PromptModalShow}
          onHide={() => this.setState({ PromptModalShow: false })}
          PromptModalTitle={this.state.PromptModalTitle}
          PromptModalMessage={this.state.PromptModalMessage}
          PromptModalButtons={this.state.PromptModalButtons}
          PromptModalInputs={this.state.PromptModalInputs}
          area={this.state.PromptModalArea}
        />
        <AlertModal
          show={this.state.AlertModalShow}
          onHide={() => this.setState({ AlertModalShow: false })}
          AlertModalTitle={this.state.AlertModalTitle}
          AlertModalMessage={this.state.AlertModalMessage}
          AlertModalButtons={this.state.AlertModalButtons}
        />
        <IconsDialog
          show={this.state.IconsDialogShow}
          onHide={() => this.setState({ IconsDialogShow: false })}
          update_icon={this.UpdateIcon}
        />
      </div>
    );
  }
}

export default CustomIcons;
