import React, { useState } from "react";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import "./Modal.css";
import { useDispatch } from "react-redux";
import { slice1Actions } from "../../store/reducer";
import { useSelector } from "react-redux";
import { DISTANCES } from "../../Common/Common";
import MultiSelectModal from "./MultiSelectModal";
import AlertModal from "./AlertModal";
// import Common from "../../Common/Common";

const FilterModal = (props) => {
  const dispatch = useDispatch();
  let FilterFavorites = useSelector((state) => state.FilterFavorites);
  let FilterVisited = useSelector((state) => state.FilterVisited);
  let FilterNotVisited = useSelector((state) => state.FilterNotVisited);
  let FilterMustSee = useSelector((state) => state.FilterMustSee);
  let FilterSearched = useSelector((state) => state.FilterSearched);
  let PlacesFilter = useSelector((state) => state.PlacesFilter);
  let GroupsFilter = useSelector((state) => state.GroupsFilter);
  let GroupsHide = useSelector((state) => state.GroupsHide);

  let CategoriesFilter = useSelector((state) => state.CategoriesFilter);
  let DistanceFilter = useSelector((state) => state.DistanceFilter);

  let ShowHiddenPlaces = useSelector((state) => state.ShowHiddenPlaces);
  let SchedulesFilter = useSelector((state) => state.SchedulesFilter);
  let DatesFilter = useSelector((state) => state.DatesFilter);
  let noFiltering = useSelector((state) => state.noFiltering);
  let ScheduleName = useSelector((state) => state.ScheduleName);
  let ScheduleNames = useSelector((state) => state.ScheduleNames);

  let MultiselectItems = useSelector((state) => state.MultiselectItems);
  let MultiselectSelectedItems = useSelector((state) => state.MultiselectSelectedItems);
  let MultiselectMode = useSelector((state) => state.MultiselectMode);
  let MultiselectSearchText = useSelector((state) => state.MultiselectSearchText);
  let MultiselectTitle = useSelector((state) => state.MultiselectTitle);
  let MultiselectCallback = useSelector((state) => state.MultiselectCallback);
  let ShowMultiselect = useSelector((state) => state.ShowMultiselect);
  let PlaceTypeFilter = useSelector((state) => state.PlaceTypeFilter);
  let GuideSettings = useSelector((state) => state.GuideSettings);
  const [AlertModalShow, setAlertModalShow] = useState(false);
  const [AlertModalTitle, setAlertModalTitle] = useState("");
  const [AlertModalMessage, setAlertModalMessage] = useState("");
  const [AlertModalButtons, setAlertModalButtons] = useState([]);

  // const noFiltering =
  //   FilterFavorites === false &&
  //   FilterSearched === "all" &&
  //   FilterMustSee === false &&
  //   ShowHiddenPlaces === false &&
  //   FilterVisited === false &&
  //   FilterNotVisited === false &&
  //   SchedulesFilter.length === 0 &&
  //   DatesFilter.length === 0 &&
  //   PlacesFilter.length === 0 &&
  //   GroupsFilter.length === 0 &&
  //   GroupsHide.length === 0 &&
  //   CategoriesFilter.length === 0 &&
  //   DistanceFilter === -1;

  function handleFilter(FilterBy) {
    switch (FilterBy) {
      case "Only Favorites":
        dispatch(slice1Actions.SetFilterFavorites({ value: !FilterFavorites }));
        dispatch(slice1Actions.setGuide({ guide: null, fitToBounds: true }));
        break;
      case "Only Visited":
        dispatch(slice1Actions.SetFilterVisited({ value: !FilterVisited }));
        dispatch(slice1Actions.setGuide({ guide: null, fitToBounds: true }));
        break;
      case "Only Not Visited":
        dispatch(slice1Actions.SetFilterNotVisited({ value: !FilterNotVisited }));
        dispatch(slice1Actions.setGuide({ guide: null, fitToBounds: true }));
        break;
      case "Only Must See":
        dispatch(slice1Actions.SetFilterMustSee({ value: !FilterMustSee }));
        dispatch(slice1Actions.setGuide({ guide: null, fitToBounds: true }));
        break;
      case "Only Search Results":
        if (FilterSearched !== "only_search") dispatch(slice1Actions.SetFilterSearched({ value: "only_search" }));
        else dispatch(slice1Actions.SetFilterSearched({ value: "all" }));
        dispatch(slice1Actions.setGuide({ guide: null, fitToBounds: true }));
        break;
      case "Filter by Days":
        filterByDates();
        break;
      case "Filter by Schedules":
        FilterBySchedules();
        break;
      case "Filter by Places":
        filterByPlaces();
        break;
      case "Filter by Groups":
        filterByGroups();
        break;
      case "Filter by Categories":
        filterByCategories();
        break;
      case "Filter by Distance":
        filterByDistance();
        break;
      case "Hide Search Results":
        if (FilterSearched !== "no_search") dispatch(slice1Actions.SetFilterSearched({ value: "no_search" }));
        else dispatch(slice1Actions.SetFilterSearched({ value: "all" }));
        dispatch(slice1Actions.setGuide({ guide: null, fitToBounds: true }));
        break;
      case "Hide Specific Groups":
        hideByGroups();
        break;
      case "Show Hidden Places":
        dispatch(slice1Actions.SetShowHiddenPlaces({ value: !ShowHiddenPlaces }));
        dispatch(slice1Actions.setGuide({ guide: null, fitToBounds: true }));
        break;
      case "Filter by Place type":
        filterByPlaceType();
        break;
      case "Show All":
        dispatch(slice1Actions.ClearAllFilters());
        dispatch(slice1Actions.setGuide({ guide: null, fitToBounds: true }));
        break;
      default:
        break;
    }
    props.onHide();
  }

  const filterByPlaceType = () => {
    const options = [
      { name: "Location", label: "Location", value: "Icon" },
      { name: "Path", label: "Path", value: "PolyLine" },
      { name: "Area", label: "Area", value: "Polygon" },
    ];
    dispatch(slice1Actions.setMultiselectCallback({ value: filterByPlaceTypeCallBack }));
    dispatch(slice1Actions.setMultiselectItems({ value: options }));
    dispatch(slice1Actions.setMultiselectSelectedItems({ value: PlaceTypeFilter }));
    dispatch(slice1Actions.setMultiselectMode({ value: "multi" }));
    dispatch(slice1Actions.setMultiselectSearchText({ value: "" }));
    dispatch(slice1Actions.setMultiselectTitle({ value: "Select Place Type" }));
    dispatch(slice1Actions.setShowMultiselect({ value: true }));
  };

  const filterByPlaceTypeCallBack = (types) => {
    dispatch(slice1Actions.setPlaceTypeFilter({ value: types }));
    dispatch(slice1Actions.setGuide({ guide: null, fitToBounds: true }));
  };

  const filterByDates = () => {
    let _ChooseDatesList = window.guide_obj.getChooseDatesList(ScheduleName);
    dispatch(slice1Actions.setMultiselectCallback({ value: FilterDatesCallBack }));
    dispatch(slice1Actions.setMultiselectItems({ value: _ChooseDatesList }));
    dispatch(slice1Actions.setMultiselectSelectedItems({ value: DatesFilter }));
    dispatch(slice1Actions.setMultiselectMode({ value: "multi" }));
    dispatch(slice1Actions.setMultiselectSearchText({ value: "" }));
    dispatch(slice1Actions.setMultiselectTitle({ value: "Select Dates" }));
    dispatch(slice1Actions.setShowMultiselect({ value: true }));
  };

  const FilterDatesCallBack = (days) => {
    dispatch(slice1Actions.SetDatesFilter({ value: days }));
    dispatch(slice1Actions.setGuide({ guide: null, fitToBounds: true }));
  };

  const FilterBySchedules = () => {
    dispatch(slice1Actions.setMultiselectCallback({ value: FilterBySchedulesCallBack }));
    dispatch(slice1Actions.setMultiselectItems({ value: ScheduleNames }));
    dispatch(slice1Actions.setMultiselectSelectedItems({ value: SchedulesFilter }));
    dispatch(slice1Actions.setMultiselectMode({ value: "multi" }));
    dispatch(slice1Actions.setMultiselectSearchText({ value: "" }));
    dispatch(slice1Actions.setMultiselectTitle({ value: "Select Schedules" }));
    dispatch(slice1Actions.setShowMultiselect({ value: true }));
  };

  const FilterBySchedulesCallBack = (schedules) => {
    dispatch(slice1Actions.SetSchedulesFilter({ value: schedules }));
    if (schedules.length === 1) dispatch(slice1Actions.setScheduleName({ value: schedules[0].label }));
    dispatch(slice1Actions.setGuide({ guide: null, fitToBounds: true }));
  };

  const filterByPlaces = () => {
    //console.log("filterByPlaces1");
    let places = [];
    if (PlacesFilter != null) {
      PlacesFilter.forEach((place) => {
        places.push({ label: place.Name, value: place });
      });
    }
    let _ChoosePlacesList = window.guide_obj.places.getChoosePlacesList(GuideSettings.hide_hidden);
    //console.log("_ChoosePlacesList", _ChoosePlacesList);
    //console.log("places", places);
    dispatch(slice1Actions.setMultiselectCallback({ value: FilterPlacesCallBack }));
    dispatch(slice1Actions.setMultiselectItems({ value: _ChoosePlacesList }));
    dispatch(slice1Actions.setMultiselectSelectedItems({ value: places }));
    dispatch(slice1Actions.setMultiselectMode({ value: "multi" }));
    dispatch(slice1Actions.setMultiselectSearchText({ value: "" }));
    dispatch(slice1Actions.setMultiselectTitle({ value: "Select Places" }));
    dispatch(slice1Actions.setShowMultiselect({ value: true }));
    //console.log("filterByPlaces4");
  };

  const FilterPlacesCallBack = (_places) => {
    //console.log("FilterPlacesCallBack", _places);
    let places = [];
    if (_places != null) {
      _places.forEach((place) => {
        places.push(place.value);
      });
    }
    //console.log("FilterPlacesCallBack", places);
    dispatch(slice1Actions.SetPlacesFilter({ value: places }));
    dispatch(slice1Actions.setGuide({ guide: null, fitToBounds: true }));
  };

  const filterByGroups = () => {
    let _ChooseGroupsList = window.guide_obj.getGroupsToDisplay(null, true);
    let groupsObjArray = window.guide_obj.groupObjectArrayFromIDArray(GroupsFilter);
    let _selectedItems = [];
    for (let i = 0; i < groupsObjArray.length; i++) {
      _selectedItems.push({
        id: groupsObjArray[i].id,
        name: groupsObjArray[i].name,
        label: groupsObjArray[i].name,
        value: groupsObjArray[i],
      });
    }
    dispatch(slice1Actions.setMultiselectCallback({ value: FilterGroupsCallBack }));
    dispatch(slice1Actions.setMultiselectItems({ value: _ChooseGroupsList }));
    dispatch(slice1Actions.setMultiselectSelectedItems({ value: _selectedItems }));
    dispatch(slice1Actions.setMultiselectMode({ value: "multi" }));
    dispatch(slice1Actions.setMultiselectSearchText({ value: "" }));
    dispatch(slice1Actions.setMultiselectTitle({ value: "Select Groups" }));
    dispatch(slice1Actions.setShowMultiselect({ value: true }));
  };

  const FilterGroupsCallBack = (groups) => {
    //console.log("FilterGroupsCallBack", groups);
    let filterList = [];
    groups.forEach((group) => {
      filterList.push(group.value.id);
    });
    dispatch(slice1Actions.SetGroupsFilter({ value: filterList }));
    dispatch(slice1Actions.setGuide({ guide: null, fitToBounds: true }));
  };

  const filterByCategories = () => {
    let _ChooseCategoriesList = window.guide_obj.getCategoriesToDisplay(null, true);
    let categoriesObjArray = window.guide_obj.categoryObjectArrayFromIDArray(CategoriesFilter);
    let _selectedItems = [];
    for (let i = 0; i < categoriesObjArray.length; i++) {
      _selectedItems.push({
        id: categoriesObjArray[i].id,
        name: categoriesObjArray[i].name,
        label: categoriesObjArray[i].name,
        value: categoriesObjArray[i],
      });
    }
    dispatch(slice1Actions.setMultiselectCallback({ value: FilterCategoriesCallBack }));
    dispatch(slice1Actions.setMultiselectItems({ value: _ChooseCategoriesList }));
    dispatch(slice1Actions.setMultiselectSelectedItems({ value: _selectedItems }));
    dispatch(slice1Actions.setMultiselectMode({ value: "multi" }));
    dispatch(slice1Actions.setMultiselectSearchText({ value: "" }));
    dispatch(slice1Actions.setMultiselectTitle({ value: "Select Categories" }));
    dispatch(slice1Actions.setShowMultiselect({ value: true }));
  };

  const FilterCategoriesCallBack = (categories) => {
    let filterList = [];
    categories.forEach((category) => {
      filterList.push(category.value.id);
    });
    dispatch(slice1Actions.SetCategoriesFilter({ value: filterList }));
    dispatch(slice1Actions.setGuide({ guide: null, fitToBounds: true }));
  };

  const filterByDistance = () => {
    let distancesList = [];
    let id = 0;
    DISTANCES.forEach((distance) => {
      distancesList.push({
        label: `${distance.toString()} km/${(distance * 0.621371).toFixed(2)} mi`,
        value: distance,
        id: (id++).toString(),
      });
    });
    let selectedDistance = [];
    if (DistanceFilter !== -1)
      selectedDistance = [
        {
          label: `${DistanceFilter.toString()} km/${(DistanceFilter * 0.621371).toFixed(2)} mi`,
          value: DistanceFilter,
        },
      ];
    dispatch(slice1Actions.setMultiselectCallback({ value: FilterDistanceCallBack }));
    dispatch(slice1Actions.setMultiselectItems({ value: distancesList }));
    dispatch(slice1Actions.setMultiselectSelectedItems({ value: selectedDistance }));
    dispatch(slice1Actions.setMultiselectMode({ value: "uni" }));
    dispatch(slice1Actions.setMultiselectSearchText({ value: "" }));
    dispatch(slice1Actions.setMultiselectTitle({ value: "Select Distance" }));
    dispatch(slice1Actions.setShowMultiselect({ value: true }));
  };

  const FilterDistanceCallBack = (ret) => {
    dispatch(slice1Actions.SetDistanceFilter({ value: ret[0].value }));
    dispatch(slice1Actions.setGuide({ guide: null, fitToBounds: true }));
  };

  const hideByGroups = () => {
    let _ChooseGroupsList = window.guide_obj.getGroupsToDisplay(null, true);
    let groupsObjArray = window.guide_obj.groupObjectArrayFromIDArray(GroupsHide);
    let _selectedItems = [];
    for (let i = 0; i < groupsObjArray.length; i++) {
      _selectedItems.push({
        id: groupsObjArray[i].id,
        name: groupsObjArray[i].name,
        label: groupsObjArray[i].name,
        value: groupsObjArray[i],
      });
    }
    dispatch(slice1Actions.setMultiselectCallback({ value: HideGroupsCallBack }));
    dispatch(slice1Actions.setMultiselectItems({ value: _ChooseGroupsList }));
    dispatch(slice1Actions.setMultiselectSelectedItems({ value: _selectedItems }));
    dispatch(slice1Actions.setMultiselectMode({ value: "multi" }));
    dispatch(slice1Actions.setMultiselectSearchText({ value: "" }));
    dispatch(slice1Actions.setMultiselectTitle({ value: "Select Groups" }));
    dispatch(slice1Actions.setShowMultiselect({ value: true }));
  };

  const HideGroupsCallBack = (groups) => {
    let filterList = [];
    groups.forEach((group) => {
      filterList.push(group.value.id);
    });
    dispatch(slice1Actions.SetGroupsHide({ value: filterList }));
    dispatch(slice1Actions.setGuide({ guide: null, fitToBounds: true }));
  };

  const myAlert = (message, title, buttons) => {
    setAlertModalMessage(message);
    setAlertModalTitle(title == null ? "Message" : title);
    setAlertModalButtons(buttons == null ? [{ Title: "OK", Method: null }] : buttons);
    setAlertModalShow(true);
  };

  const displayMenuInfo = (menu_item_info) => {
    myAlert(menu_item_info, "Info");
  };

  if (ShowMultiselect)
    return (
      <MultiSelectModal
        show={ShowMultiselect}
        onHide={() => dispatch(slice1Actions.setShowMultiselect({ value: false }))}
        MultiselectItems={MultiselectItems}
        MultiselectSelectedItems={MultiselectSelectedItems}
        MultiselectMode={MultiselectMode}
        MultiselectCallback={MultiselectCallback}
        MultiselectTitle={MultiselectTitle}
        MultiselectSearchText={MultiselectSearchText}
      />
    );
  else
    return (
      <div className="modal">
        <Modal
          size="sm"
          {...props}
          dialogClassName="myModalClass"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          scrollable={true}
          // style={{ padding: "50px" }}
        >
          {/* <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-sm">Filter</Modal.Title>
          </Modal.Header> */}

          <Modal.Body>
            <div className="sort">
              <div className="flex-container-middle">
                <h4 className="color-blue">Places Filter</h4>
                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                <i title="Close" className={`fas fa-times color-black`} onClick={props.onHide}></i>
              </div>

              <div className="multiselect-list"></div>

              <div key={`container`} className="flex-container">
                <div className="flex-items-left" key={`left`}>
                  <p onClick={() => handleFilter("Only Favorites")}>
                    {FilterFavorites ? <i className="fas fa-check"></i> : null}
                    <span>Only Favorites</span>
                  </p>
                </div>
                <div className="flex-items-right flex-container-right" key={`r1`}>
                  <OverlayTrigger
                    // zIndex="9999"
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={<Tooltip id="button-tooltip-2">{"Only Show the places marked as 'Favorites'"}</Tooltip>}
                  >
                    <i
                      // title="Info"
                      className={`fa fa-info`}
                      style={{ color: "#3598DB" }}
                      onClick={() => displayMenuInfo("Only Show the places marked as 'Favorites'")}
                    ></i>
                  </OverlayTrigger>
                </div>
              </div>

              <div key={`container`} className="flex-container">
                <div className="flex-items-left" key={`left`}>
                  <p onClick={() => handleFilter("Only Visited")}>
                    {FilterVisited ? <i className="fas fa-check"></i> : null}
                    <span>Only Visited</span>
                  </p>
                </div>
                <div className="flex-items-right flex-container-right" key={`r1`}>
                  <OverlayTrigger
                    // zIndex="9999"
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={<Tooltip id="button-tooltip-2">{"Only Show the places marked as 'Visited'"}</Tooltip>}
                  >
                    <i
                      // title="Info"
                      className={`fa fa-info`}
                      style={{ color: "#3598DB" }}
                      onClick={() => displayMenuInfo("Only Show the places marked as 'Visited'")}
                    ></i>
                  </OverlayTrigger>
                </div>
              </div>

              <div key={`container`} className="flex-container">
                <div className="flex-items-left" key={`left`}>
                  <p onClick={() => handleFilter("Only Not Visited")}>
                    {FilterNotVisited ? <i className="fas fa-check"></i> : null}
                    <span>Only Not Visited</span>
                  </p>
                </div>
                <div className="flex-items-right flex-container-right" key={`r1`}>
                  <OverlayTrigger
                    // zIndex="9999"
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={<Tooltip id="button-tooltip-2">{"Only Show the places NOT marked as 'Visited'"}</Tooltip>}
                  >
                    <i
                      // title="Info"
                      className={`fa fa-info`}
                      style={{ color: "#3598DB" }}
                      onClick={() => displayMenuInfo("Only Show the places NOT marked as 'Visited'")}
                    ></i>
                  </OverlayTrigger>
                </div>
              </div>

              <div key={`container`} className="flex-container">
                <div className="flex-items-left" key={`left`}>
                  <p onClick={() => handleFilter("Only Must See")}>
                    {FilterMustSee ? <i className="fas fa-check"></i> : null}
                    <span>Only Must See</span>
                  </p>
                </div>
                <div className="flex-items-right flex-container-right" key={`r1`}>
                  <OverlayTrigger
                    // zIndex="9999"
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={<Tooltip id="button-tooltip-2">{"Only Show the places marked as 'Must See'"}</Tooltip>}
                  >
                    <i
                      // title="Info"
                      className={`fa fa-info`}
                      style={{ color: "#3598DB" }}
                      onClick={() => displayMenuInfo("Only Show the places marked as 'Must See'")}
                    ></i>
                  </OverlayTrigger>
                </div>
              </div>

              <div key={`container`} className="flex-container">
                <div className="flex-items-left" key={`left`}>
                  <p onClick={() => handleFilter("Only Search Results")}>
                    {FilterSearched === "only_search" ? <i className="fas fa-check"></i> : null}
                    <span>Only Search Results</span>
                  </p>
                </div>
                <div className="flex-items-right flex-container-right" key={`r1`}>
                  <OverlayTrigger
                    // zIndex="9999"
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                      <Tooltip id="button-tooltip-2">
                        {"Only show the Search Results. Makes it easier to see them."}
                      </Tooltip>
                    }
                  >
                    <i
                      // title="Info"
                      className={`fa fa-info`}
                      style={{ color: "#3598DB" }}
                      onClick={() => displayMenuInfo("Only show the Search Results. Makes it easier to see them.")}
                    ></i>
                  </OverlayTrigger>
                </div>
              </div>
              <div key={`container`} className="flex-container">
                <div className="flex-items-left" key={`left`}>
                  <p onClick={() => handleFilter("Filter by Days")}>
                    {DatesFilter.length !== 0 ? <i className="fas fa-check"></i> : null}
                    <span>Filter by Days</span>
                  </p>
                </div>
                <div className="flex-items-right flex-container-right" key={`r1`}>
                  <OverlayTrigger
                    // zIndex="9999"
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                      <Tooltip id="button-tooltip-2">{"Only Show the places scheduled for selected dates"}</Tooltip>
                    }
                  >
                    <i
                      // title="Info"
                      className={`fa fa-info`}
                      style={{ color: "#3598DB" }}
                      onClick={() => displayMenuInfo("Only Show the places scheduled for selected dates")}
                    ></i>
                  </OverlayTrigger>
                </div>
              </div>

              <div key={`container`} className="flex-container">
                <div className="flex-items-left" key={`left`}>
                  <p onClick={() => handleFilter("Filter by Schedules")}>
                    {SchedulesFilter.length !== 0 ? <i className="fas fa-check"></i> : null}
                    <span>Filter by Schedules</span>
                  </p>
                </div>
                <div className="flex-items-right flex-container-right" key={`r1`}>
                  <OverlayTrigger
                    // zIndex="9999"
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                      <Tooltip id="button-tooltip-2">{"Only Show the places scheduled for selected schedule"}</Tooltip>
                    }
                  >
                    <i
                      // title="Info"
                      className={`fa fa-info`}
                      style={{ color: "#3598DB" }}
                      onClick={() => displayMenuInfo("Only Show the places scheduled for selected schedule")}
                    ></i>
                  </OverlayTrigger>
                </div>
              </div>

              <div key={`container`} className="flex-container">
                <div className="flex-items-left" key={`left`}>
                  <p onClick={() => handleFilter("Filter by Places")}>
                    {PlacesFilter.length !== 0 ? <i className="fas fa-check"></i> : null}
                    <span>Filter by Places</span>
                  </p>
                </div>
                <div className="flex-items-right flex-container-right" key={`r1`}>
                  <OverlayTrigger
                    // zIndex="9999"
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={<Tooltip id="button-tooltip-2">{"Only show the selected places"}</Tooltip>}
                  >
                    <i
                      // title="Info"
                      className={`fa fa-info`}
                      style={{ color: "#3598DB" }}
                      onClick={() => displayMenuInfo("Only show the selected places")}
                    ></i>
                  </OverlayTrigger>
                </div>
              </div>

              <div key={`container`} className="flex-container">
                <div className="flex-items-left" key={`left`}>
                  <p onClick={() => handleFilter("Filter by Place type")}>
                    {PlaceTypeFilter.length !== 0 ? <i className="fas fa-check"></i> : null}
                    <span>Filter by Place type</span>
                  </p>
                </div>
                <div className="flex-items-right flex-container-right" key={`r1`}>
                  <OverlayTrigger
                    // zIndex="9999"
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                      <Tooltip id="button-tooltip-2">
                        {"Only show the places of a selected type i.e. Location, Path or Area"}
                      </Tooltip>
                    }
                  >
                    <i
                      // title="Info"
                      className={`fa fa-info`}
                      style={{ color: "#3598DB" }}
                      onClick={() =>
                        displayMenuInfo("Only show the places of a selected type i.e. Location, Path or Area")
                      }
                    ></i>
                  </OverlayTrigger>
                </div>
              </div>

              <div key={`container`} className="flex-container">
                <div className="flex-items-left" key={`left`}>
                  <p onClick={() => handleFilter("Filter by Groups")}>
                    {GroupsFilter.length !== 0 ? <i className="fas fa-check"></i> : null}
                    <span>Filter by Groups</span>
                  </p>
                </div>
                <div className="flex-items-right flex-container-right" key={`r1`}>
                  <OverlayTrigger
                    // zIndex="9999"
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                      <Tooltip id="button-tooltip-2">
                        {"Only show the places that belong to the selected groups"}
                      </Tooltip>
                    }
                  >
                    <i
                      // title="Info"
                      className={`fa fa-info`}
                      style={{ color: "#3598DB" }}
                      onClick={() => displayMenuInfo("Only show the places that belong to the selected groups")}
                    ></i>
                  </OverlayTrigger>
                </div>
              </div>

              <div key={`container`} className="flex-container">
                <div className="flex-items-left" key={`left`}>
                  <p onClick={() => handleFilter("Filter by Categories")}>
                    {CategoriesFilter.length !== 0 ? <i className="fas fa-check"></i> : null}
                    <span>Filter by Categories</span>
                  </p>
                </div>
                <div className="flex-items-right flex-container-right" key={`r1`}>
                  <OverlayTrigger
                    // zIndex="9999"
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                      <Tooltip id="button-tooltip-2">
                        {"Only show the places that belong to the selected categories"}
                      </Tooltip>
                    }
                  >
                    <i
                      // title="Info"
                      className={`fa fa-info`}
                      style={{ color: "#3598DB" }}
                      onClick={() => displayMenuInfo("Only show the places that belong to the selected categories")}
                    ></i>
                  </OverlayTrigger>
                </div>
              </div>

              <div key={`container`} className="flex-container">
                <div className="flex-items-left" key={`left`}>
                  <p onClick={() => handleFilter("Filter by Distance")}>
                    {DistanceFilter !== -1 ? <i className="fas fa-check"></i> : null}
                    <span>Filter by Distance</span>
                  </p>
                </div>
                <div className="flex-items-right flex-container-right" key={`r1`}>
                  <OverlayTrigger
                    // zIndex="9999"
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                      <Tooltip id="button-tooltip-2">{"Only show the places that are at a specified distance"}</Tooltip>
                    }
                  >
                    <i
                      // title="Info"
                      className={`fa fa-info`}
                      style={{ color: "#3598DB" }}
                      onClick={() => displayMenuInfo("Only show the places that are at a specified distance")}
                    ></i>
                  </OverlayTrigger>
                </div>
              </div>

              <div key={`container`} className="flex-container">
                <div className="flex-items-left" key={`left`}>
                  <p onClick={() => handleFilter("Hide Specific Groups")}>
                    {GroupsHide.length !== 0 ? <i className="fas fa-check"></i> : null}
                    <span>Hide Specific Groups</span>
                  </p>
                </div>
                <div className="flex-items-right flex-container-right" key={`r1`}>
                  <OverlayTrigger
                    // zIndex="9999"
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                      <Tooltip id="button-tooltip-2">{"Don't show places that belong to the selected groups"}</Tooltip>
                    }
                  >
                    <i
                      // title="Info"
                      className={`fa fa-info`}
                      style={{ color: "#3598DB" }}
                      onClick={() => displayMenuInfo("Don't show places that belong to the selected groups")}
                    ></i>
                  </OverlayTrigger>
                </div>
              </div>

              <div key={`container`} className="flex-container">
                <div className="flex-items-left" key={`left`}>
                  <p onClick={() => handleFilter("Hide Search Results")}>
                    {FilterSearched === "no_search" ? <i className="fas fa-check"></i> : null}
                    <span>Hide Search Results</span>
                  </p>
                </div>
                <div className="flex-items-right flex-container-right" key={`r1`}>
                  <OverlayTrigger
                    // zIndex="9999"
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={<Tooltip id="button-tooltip-2">{"Hide the search results."}</Tooltip>}
                  >
                    <i
                      // title="Info"
                      className={`fa fa-info`}
                      style={{ color: "#3598DB" }}
                      onClick={() => displayMenuInfo("Hide the search results.")}
                    ></i>
                  </OverlayTrigger>
                </div>
              </div>
              <div key={`container`} className="flex-container">
                <div className="flex-items-left" key={`left`}>
                  <p onClick={() => handleFilter("Show Hidden Places")}>
                    {ShowHiddenPlaces ? <i className="fas fa-check"></i> : null}
                    <span>Show Hidden Places</span>
                  </p>
                </div>
                <div className="flex-items-right flex-container-right" key={`r1`}>
                  <OverlayTrigger
                    // zIndex="9999"
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                      <Tooltip id="button-tooltip-2">
                        {
                          "Show the hidden places. Note: hidden places are places that we only want to show when needed, but are kept hidden to remove clutter"
                        }
                      </Tooltip>
                    }
                  >
                    <i
                      // title="Info"
                      className={`fa fa-info`}
                      style={{ color: "#3598DB" }}
                      onClick={() =>
                        displayMenuInfo(
                          "Show the hidden places. Note: hidden places are places that we only want to show when needed, but are kept hidden to remove clutter"
                        )
                      }
                    ></i>
                  </OverlayTrigger>
                </div>
              </div>

              <div key={`container`} className="flex-container">
                <div className="flex-items-left" key={`left`}>
                  <p onClick={() => handleFilter("Show All")}>
                    {noFiltering ? <i className="fas fa-check"></i> : null}
                    <span>Show All</span>
                  </p>
                </div>
                <div className="flex-items-right flex-container-right" key={`r1`}>
                  <OverlayTrigger
                    // zIndex="9999"
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                      <Tooltip id="button-tooltip-2">
                        {"Remove all of the filters and show all of the places, except for hidden places"}
                      </Tooltip>
                    }
                  >
                    <i
                      // title="Info"
                      className={`fa fa-info`}
                      style={{ color: "#3598DB" }}
                      onClick={() =>
                        displayMenuInfo(
                          "Remove all of the filters and show all of the places, except for hidden places"
                        )
                      }
                    ></i>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <AlertModal
          show={AlertModalShow}
          onHide={() => setAlertModalShow(false)}
          AlertModalTitle={AlertModalTitle}
          AlertModalMessage={AlertModalMessage}
          AlertModalButtons={AlertModalButtons}
        />
      </div>
    );
};

export default FilterModal;
