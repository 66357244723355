import React, { useState, Component } from "react";
import { Modal, ToggleButtonGroup, ToggleButton, ButtonGroup, Button, Form, Spinner } from "react-bootstrap";
import "../Modal.css";
import MultiSelectModal from "../MultiSelectModal";
import AlertModal from "../AlertModal";
import { useDispatch } from "react-redux";
import { slice1Actions } from "../../../store/reducer";
import { useSelector } from "react-redux";
import Common, {
  DISTANCES,
  Settings,
  SortByEnum,
  labelChars,
  AUTHORIZATION_LEVEL,
  COLORS,
} from "../../../Common/Common";
//import Guide from "../Models/Guide";
import GuideCard from "./GuideCard";

import DataBase from "../../../dataBase/liquidGuides";
import { DateTime } from "luxon";

class GuideDownloadComponent extends Component {
  constructor(props) {
    super(props);
    let mode = this.props.mode;
    let modify_only = this.props.modify_only === "true";
    const AccessRights = [
      // "Featured",
      "Mine",
      "I shared to a few: Read",
      "I shared to a few: Copy",
      "I shared to a few: Modify",
      "I shared to community: Read",
      "I shared to community: Copy",
      "I shared to community: Modify",
      "Shared to me: Read",
      "Shared to me: Copy",
      "Shared to me: Modify",
      "Shared by community: Read",
      "Shared by community: Copy",
      "Shared by community: Modify",
    ];
    let initialAccessRights = [];
    AccessRights.forEach((access) => {
      initialAccessRights.push({
        label: access,
        name: access,
        value: access,
      });
    });
    this.state = {
      visiblePrompt: false,
      category: "",
      add: true,
      defaultCategory: "",
      guides: [],
      guides_unfiltered: [],
      activityIndicator: false,
      Countries: [],
      Cities: [],
      AccessRights: [],
      Country: "All",
      City: "All",
      Access: "All",
      Origin: "All",
      //Origins: [],
      AccessTypes: [],
      SelectedAccess: initialAccessRights,
      mode: mode,
      Language: "All",
      searchText: "",
      modify_only: modify_only,
      AllLanguages: [],

      AlertModalShow: false,
      AlertModalTitle: "",
      AlertModalMessage: "",
      AlertModalButtons: [],
      user_info: { uid: this.props.user_info },

      MultiselectItems: [],
      MultiselectSelectedItems: [],
      MultiselectMode: "uni",
      MultiselectCallback: null,
      MultiselectTitle: "Select",
      MultiselectSearchText: "",
      ShowMultiselect: false,
    };
  }

  _Country = "All";
  _City = "All";
  //_Access = "Featured";
  _AccessRights = ["All"];
  //07/26
  //_Language = [];
  _Language = "All";

  AccessChosen = () => {
    if (this.state.SelectedAccess.length === this.state.AccessRights.length) return false;
    return true;
    // if (this.state.SelectedAccess.length > 1) return true;
    // if (this.state.SelectedAccess.findIndex((x) => x.label === "All") === -1) return true;
    // return false;
  };

  LanguageChosen = () => {
    if (this.state.Language.length != this.state.AllLanguages.length) return false;
    return true;
    // if (this.state.Language != "All") return true;
    // return false;
  };

  CountryChosen = () => {
    if (this.state.Country != "All") return true;
    return false;
  };

  CityChosen = () => {
    if (this.state.City != "All") return true;
    return false;
  };

  componentDidMount() {
    //this.setState({ activityIndicator: true });
    //setTimeout(this.timeoutFunc, 5000);
    window.addEventListener("modalOnShow", this.modalOnShow);
    this.modalOnShow();
  }

  modalOnShow = () => {
    // }
    // componentDidMount() {
    // let Origins = [];
    // ["All", "I Own", "I Shared", "Shared to me", "Community", "Featured"].forEach((access) => {
    //   Origins.push({
    //     label: access,
    //     name: access,
    //     value: access,
    //   });
    // });
    let accessRights = [];

    [
      // "Featured",
      "Mine",
      "I shared to a few: Read",
      "I shared to a few: Copy",
      "I shared to a few: Modify",
      "I shared to community: Read",
      "I shared to community: Copy",
      "I shared to community: Modify",
      "Shared to me: Read",
      "Shared to me: Copy",
      "Shared to me: Modify",
      "Shared by community: Read",
      "Shared by community: Copy",
      "Shared by community: Modify",
    ].forEach((access) => {
      accessRights.push({
        label: access,
        name: access,
        value: access,
      });
    });
    this.setState({ activityIndicator: true, AccessRights: accessRights });
    console.log("******componentDidMount");
    if (this.props.guide_type === "Featured") {
      //if (typeof this.props.guide_info_array !== "undefined") {
      if (this.props.guide_info_array.length > 0) {
        this.processGuideInfo(this.props.guide_info_array);
        this.setState({ activityIndicator: false });
        console.log("Guides Cached");
        return;
      }
      //}
    }
    DataBase.getAllGuideInfo(this.props.user_info.uid, this.readAllGuideInfoReturn, this.props.guide_type);
    console.log("Guides NOT Cached");
  };

  readAllGuideInfoReturn = (res) => {
    console.log("readAllGuideInfoReturn", res.pass);
    if (res.pass === false) return;
    let _guides = window.guide_obj.classifyAuthorization(res.res, this.props.user_info.uid);
    //console.log("_guides", _guides);guideInfos.forEach((guide_info) => {
    this.setState({ activityIndicator: false, guides_unfiltered: _guides });
    if (res.pass === true) {
      this.processGuideInfo(_guides);
    }
  };

  processGuideInfo = (guideInfos, searchText) => {
    let guides = [];
    console.log("****************processGuideInfo", guideInfos);
    if (searchText == null) searchText = this.state.searchText;
    const searchTextCap = searchText.toUpperCase();
    for (let g_i = 0; g_i < guideInfos.length; g_i++) {
      let guide_info = guideInfos[g_i]; //guideInfos.forEach((guide_info) => {
      //console.log("guide_info.data.categories", guide_info.data.categories);
      //console.log("guide_info.data.language, this._Language", guide_info.data.language, this._Language);
      //console.log("guide_info.data.countries, this._Country", guide_info.data.countries, this._Country);
      if (!Array.isArray(guide_info.data.countries)) guide_info.data.countries = [];
      if (!Array.isArray(guide_info.data.cities)) guide_info.data.cities = [];

      if (this.state.modify_only === true) {
        let found = false;
        for (let i = 0; i < guide_info.data.categories.length; i++) {
          if (guide_info.data.categories[i].includes("Modify") || guide_info.data.categories[i].includes("Mine")) {
            found = true;
            break;
          }
        }
        if (found === false) continue;
      }
      if (searchTextCap != "") {
        if (
          !(
            guide_info.data.guide_name.toUpperCase().includes(searchTextCap) ||
            guide_info.data.description.toUpperCase().includes(searchTextCap) ||
            guide_info.data.owner_name.toUpperCase().includes(searchTextCap) ||
            guide_info.data.guide_id.toUpperCase().includes(searchTextCap) ||
            guide_info.data.user_id.toUpperCase().includes(searchTextCap)
          )
        )
          continue;
      }
      //07/26/
      //if (this._Language.length != 0) if (!this._Language.includes(guide_info.data.language)) continue;
      if (guide_info.data.language != this._Language && this._Language != "All") continue;
      if (!guide_info.data.countries.includes(this._Country) && this._Country != "All") continue;
      if (!guide_info.data.cities.includes(this._City) && this._City != "All") continue;
      if (
        !guide_info.data.categories.some((r) => this._AccessRights.indexOf(r) >= 0) &&
        !this._AccessRights.includes("All")
      )
        continue;
      if (this.props.guide_type !== "Featured") {
        if (guide_info.data.type === "Featured") continue;
      }
      guides.push(guide_info);
      //});
    }
    //("this._sort_option", this._sort_option);
    if (this._sort_option === "Name")
      guides = guides.sort((a, b) => (a.data.guide_name.toUpperCase() > b.data.guide_name.toUpperCase() ? 1 : -1));
    else if (this._sort_option === "Rating") guides = guides.sort((a, b) => (a.data.rating < b.data.rating ? 1 : -1));
    else if (this._sort_option === "Times Reviewed")
      guides = guides.sort((a, b) => (a.data.num_ratings < b.data.num_ratings ? 1 : -1));
    else if (this._sort_option === "Times Read")
      guides = guides.sort((a, b) => (a.data.times_read < b.data.times_read ? 1 : -1));
    else if (this._sort_option === "Times Copied")
      guides = guides.sort((a, b) => (a.data.times_copied < b.data.times_copied ? 1 : -1));
    else if (this._sort_option === "Modified date") guides = guides.sort((a, b) => (a.data.mod < b.data.mod ? 1 : -1));
    else if (this._sort_option === "Created date")
      guides = guides.sort((a, b) => (a.data.created < b.data.created ? 1 : -1));
    else if (this._sort_option === "Country")
      guides = guides.sort((a, b) =>
        a.data.country.join(",").toUpperCase() > b.data.country.join(",").toUpperCase() ? 1 : -1
      );
    else if (this._sort_option === "City")
      guides = guides.sort((a, b) =>
        a.data.city.join(",").toUpperCase() > b.data.city.join(",").toUpperCase() ? 1 : -1
      );
    let _countries = [];
    let _cities = [];
    let _languages = [];

    guides = guides.sort((a, b) => (a.data.cities.join(",") < b.data.cities.join(",") ? -1 : 1));
    //let guides_html = "";
    guides.forEach((guide_info) => {
      // let countries_ = guide_info.data.countries.join(", ");
      // let cities_ = guide_info.data.cities.join(", ");
      // guides_html += `<li><a href="https://andiamoplaces.com?id=${guide_info.id}" target="_blank"><span>Travel Guide: ${guide_info.data.guide_name}; Cities: ${cities_}; Countries: ${countries_}</span></a></li>\n`;
      //console.log("guide_info.data.language", guide_info.data.language);
      if (typeof guide_info.data.language != "undefined")
        if (!_languages.includes(guide_info.data.language)) _languages.push(guide_info.data.language);
      if (typeof guide_info.data.countries != "undefined") _countries = _countries.concat(guide_info.data.countries);
      if (typeof guide_info.data.cities != "undefined") _cities = _cities.concat(guide_info.data.cities);
    });
    //console.log("guides_html", guides_html);
    _languages = _languages.sort();
    var languages_set = new Set(_languages);
    _languages = [...languages_set].sort();
    //07/26
    //_languages.unshift("All");
    //console.log("_languages", _languages);
    let languages = [];
    _languages.forEach((language) => {
      languages.push({
        label: language,
        name: language,
        value: language,
      });
    });
    var countries_set = new Set(_countries);
    _countries = [...countries_set].sort();
    // _countries.unshift("All");
    let countries = [];
    _countries.forEach((country) => {
      countries.push({
        label: country,
        name: country,
        value: country,
      });
    });
    var cities_set = new Set(_cities);
    _cities = [...cities_set].sort();
    //_cities.unshift("All");

    let cities = [];
    _cities.forEach((city) => {
      cities.push({
        label: city,
        name: city,
        value: city,
      });
    });

    //console.log("processGuideInfo", guides);
    if (this.props.save_guides !== null) this.props.save_guides(guides, countries, cities, languages);
    this.setState({
      guides: guides,
      Languages: languages,
      AllLanguages: languages,
      Countries: countries,
      Cities: cities,
    });
  };

  onChangeText = (event) => {
    this.setState({ searchText: event.target.value });
    this.processGuideInfo(this.state.guides_unfiltered, event.target.value);
  };

  cancelTextSearch = () => {
    this.setState({ searchText: "" });
    this.processGuideInfo(this.state.guides_unfiltered, "");
  };

  _attributes = null;
  _guide_id = null;

  selectedGuide = (res) => {
    console.log(res);
  };

  selectGuide = (item) => {
    //console.log("selectGuide", item);
    if (item == null) {
      //console.log("item === null");
      //this.props.onHide();
      return;
    }
    //console.log("item.id", item.id);
    let callback = this.props.callback;
    //console.log("callback", callback);
    if (callback != null)
      callback({ guide_info_id: item.id, guide_id: item.data.guide_id, guide_name: item.data.guide_name });
    //this.props.onHide();
    return;
  };

  readGuideDataReturn = (res) => {
    console.log("readGuideDataReturn", res.guide_id);
    //this.props.onsetActivityIndicator(false);
    // if (res.pass === true) {
    //   let GuideObj = new Guide(this.state.new_guide_id);
    //   const guide_json = res.res.data;
    //   var guide_obj = JSON.parse(guide_json);
    //   const decoded = GuideObj.decodeGuideFb(guide_obj, this._attributes);
    //   if (decoded === true) {
    //     GuideObj.schedule.getPlaces_InSchedule();
    //     this.props.onDownloadGuideFb(res.guide_id, GuideObj.attributes, GuideObj, this._attributes);
    //     let html = "";
    //     this.props.onModifyGuideAttributes(this._attributes);
    //     this.props.onUpdateDocHTML();
    //     this.props.onSetOwnerInfo(res.owner_info);
    //     this.props.onSetCreatorInfo(res.creator_info);

    //     let user_info = JSON.parse(JSON.stringify(this.props.user_info));
    //     user_info.last_guide_id = this._guide_id;
    //     user_info.last_guide_time = DateTime.now().toISO({ zone: "utc" });
    //     this.props.onSetUserInfo(user_info);
    //     console.log("readGuideDataReturn", user_info);
    //     DataBase.updateUser(user_info, this.props.user_info.uid, this.updateUserCallback);
    //     this.setState({ activityIndicator: false });
    //   } else {
    //     this.setState({ activityIndicator: false });
    //   }
    // } else {
    //   this.setState({ activityIndicator: false });
    // }
    // const callback = this.props.navigation.getParam("callback", null);
    // if (callback != null) callback(this._guide_id);
    // this.setState({ activityIndicator: false });
    // this.props.navigation.replace("MainScreen");
    // this.props.navigation.navigate("MainScreen");
  };

  updateUserCallback = (res) => {
    console.log("updateUserCallback", res.pass);
  };

  MyAlert = (title, message, buttons) => {
    this.setState({
      AlertModalTitle: title,
      AlertModalMessage: message,
      AlertModalButtons: buttons,
      AlertModalShow: true,
    });
  };

  copyFromGuide = (guide_info_id, guide_id) => {};

  deleteGuide = (guide_info_id, guide_id) => {
    if (guide_id === this.props.guide_meta.guide_id) {
      this.setState({ activityIndicator: false });
      this.MyAlert("Message", "Cannot delete guide that you are viewing", [{ Title: "OK", Method: () => null }]);
      return;
    }
    this.MyAlert("Confirmation", "Confirm Delete guide from cloud?", [
      { Title: "OK", Method: () => this.onDeleteOnlineGuide(guide_info_id, guide_id) },
      { Title: "CANCEL", Method: null },
    ]);
  };

  _deleteGuideID = "";
  onDeleteOnlineGuide = (guide_info_id, guide_id) => {
    //selectedAccess("onDeleteOnlineGuide", item);
    this._deleteGuideID = guide_id;
    DataBase.deleteGuideInfo(guide_info_id, this.deleteGuideInfoCallback);
  };

  deleteGuideInfoCallback = (res) => {
    if (res.pass === true) {
      DataBase.deleteGuideData(this._deleteGuideID, this.deleteGuideDataCallback);
    }
  };

  deleteGuideDataCallback = () => {
    this._deleteGuideID = null;
    DataBase.getAllGuideInfo(this.props.user_info.uid, this.readAllGuideInfoReturn, this.props.guide_type);
  };

  guideDetails = (item) => {
    console.log("guideDetails", item);
    // this.props.navigation.navigate("GuideEdit", {
    //   mode: "VIEW",
    //   guideAttributes: item.data,
    //   guide_info_id: item.id,
    // });
  };

  showCountries = (item) => {
    const guideAttributes = item.data;
    let countries = "";
    if (typeof guideAttributes["countries"] != "undefined") countries = guideAttributes["countries"].join(", ");
    return countries;
  };

  showCities = (item) => {
    const guideAttributes = item.data;
    let cities = "";
    if (typeof guideAttributes["cities"] != "undefined") cities = guideAttributes["cities"].join(", ");
    return cities;
  };
  selectLanguage = () => {
    this.MultiSelect(this.state.Languages, [this._Language], "uni", this.selectedLanguage, "Language");
  };

  selectedLanguage = (language) => {
    //7/26let languages = [];
    // language.foreach((lang) => {
    //   languages.push(lang.label);
    // });
    // this.setState({ Language: languages });
    // this._Language = languages;

    if (language.length < 1) {
      this.setState({ Language: "All" });
      this._Language = "All";
    } else {
      this.setState({ Language: language[0].label });
      this._Language = language[0].label;
    }
    //console.log("selectedLanguage", this._Language);

    this.processGuideInfo(this.state.guides_unfiltered);
  };

  selectCountry = () => {
    this.MultiSelect(this.state.Countries, [this._Country], "uni", this.selectedCountry, "Country");
  };

  selectedCountry = (country) => {
    if (country.length < 1) {
      this.setState({ Country: "All" });
      this._Country = "All";
    } else {
      this.setState({ Country: country[0].label });
      this._Country = country[0].label;
    }
    //console.log("selectedCountry", this._Country);
    this.processGuideInfo(this.state.guides_unfiltered);
  };

  selectCity = () => {
    this.MultiSelect(this.state.Cities, [this._City], "uni", this.selectedCity, "City or Place");
  };

  selectedCity = (city) => {
    if (city.length < 1) {
      this.setState({ City: "All" });
      this._City = "All";
    } else {
      this.setState({ City: city[0].label });
      this._City = city[0].label;
    }
    this.processGuideInfo(this.state.guides_unfiltered);
  };

  _sort_option = this.props.guide_sort_option;
  sortBy = () => {
    //console.log("this.state.Cities", this.state.Cities);
    const sort_options = [
      "Name",
      "Rating",
      "Times Reviewed",
      "Times Read",
      //"Times Copied",
      "Modified date",
      "Created date",
    ];
    let sort_options_list = [];
    sort_options.forEach((option) => {
      sort_options_list.push({
        label: option,
        value: option,
      });
    });
    const _selectedItems = [{ label: this._sort_option, value: this._sort_option }];
    //console.log("_selectedItems", _selectedItems);
    this.MultiSelect(sort_options_list, _selectedItems, "uni", this.selectedSortOption, "Sort Option");
  };

  selectedSortOption = (sort_option) => {
    //console.log("sort_option", sort_option);
    if (sort_option.length > 0) this._sort_option = sort_option[0].label;
    this.props.set_guide_sort_option(this._sort_option);
    this.processGuideInfo(this.state.guides_unfiltered);
  };

  selectAccessRights = () => {
    this.MultiSelect(this.state.AccessRights, this.state.SelectedAccess, "multi", this.selectedAccess, "Access Rights");
  };

  selectedAccess = (access) => {
    //console.log("selectedAccess", access);
    let _access = [];
    this._AccessRights = [];
    access.forEach((ac) => {
      _access.push(ac.label);
      this._AccessRights.push(ac.label);
    });
    //if (_access.includes("All")) _access = ["All"];
    //const Access = access.length === 1 ? access[0].label : "Multiple";
    this.setState({ SelectedAccess: _access });
    //this._AccessRights = _access;
    this.processGuideInfo(this.state.guides_unfiltered);
  };

  MultiSelect = (Items, SelectedItems, Mode, Callback, Title) => {
    console.log("MultiSelect");
    this.setState({
      MultiselectItems: Items,
      MultiselectSelectedItems: SelectedItems,
      MultiselectMode: Mode,
      MultiselectCallback: Callback,
      MultiselectTitle: Title,
      MultiselectSearchText: "",
      ShowMultiselect: true,
    });
  };

  // selectOrigins = () => {
  //   //selectedAccess("selectOrigins");
  //   this.props.navigation.navigate("MultiSelectDialog", {
  //     selectionList: this.state.Origins,
  //     selectedItems: [this.state.Origin],
  //     callBack: this.selectedOrigin,
  //     mode: "uni",
  //   });
  // };

  // selectedOrigin = (origin) => {
  //   this.setState({ Origin: origin[0].label });
  //   global.Origins = origin[0].label;
  // };

  handleSearchChanged = () => {};

  render() {
    let guides_to_display = Common.filterGuides(
      this.state.guides,
      this.props.guide_search_text,
      this.props.guide_countries_selected,
      this.props.guide_cities_selected,
      this.props.guide_language_selected,
      this.props.guide_order_by
    );
    console.log("guides_to_display", guides_to_display.length);
    console.log("this.state.guides", this.state.guides.length);
    if (this.state.ShowMultiselect)
      return (
        <MultiSelectModal
          show={this.state.ShowMultiselect}
          onHide={() => this.setState({ ShowMultiselect: false })}
          MultiselectItems={this.state.MultiselectItems}
          MultiselectSelectedItems={this.state.MultiselectSelectedItems}
          MultiselectMode={this.state.MultiselectMode}
          MultiselectCallback={this.state.MultiselectCallback}
          MultiselectTitle={this.state.MultiselectTitle}
          MultiselectSearchText={this.state.MultiselectSearchText}
        />
      );
    else if (this.state.activityIndicator)
      return (
        <div className="loading">
          <Spinner animation="border" variant="primary" />
        </div>
      );
    else
      return (
        <div
          id="PlacesDiv"
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            // width: "20%",
            //maxHeight: "100vh",
            overflowY: "auto",
          }}
        >
          <div style={{ width: "100%" }}>
            {guides_to_display.map((guide, index) => (
              <GuideCard
                key={index}
                name={guide.data.guide_name}
                rating={guide.data.rating}
                reviews={guide.data.num_ratings}
                read={guide.data.times_read}
                language={guide.data.language}
                countries={guide.data.countries}
                cities={guide.data.cities}
                access={guide.data.access_summary}
                image={guide.data.image}
                image_m={guide.data.image_m}
                id={guide.id}
                callback={this.props.callback}
                delete_callback={this.deleteGuide}
                copy_callback={this.props.copy_from_guide}
                info_callback={this.guideDetails}
                onHide={null}
                guide_info={guide.data}
                guide_info_id={guide.id}
                mode={"download"}
                type={"component"}
                guide_type={this.props.guide_type}
              />
            ))}
          </div>

          <AlertModal
            show={this.state.AlertModalShow}
            onHide={() => this.setState({ AlertModalShow: false })}
            AlertModalTitle={this.state.AlertModalTitle}
            AlertModalMessage={this.state.AlertModalMessage}
            AlertModalButtons={this.state.AlertModalButtons}
          />
        </div>
      );
  }
}

export default GuideDownloadComponent;
