export const help_document_html = `<!DOCTYPE html>
<html>
  <head>
    <meta http-equiv="content-type" content="text/html; charset=UTF-8">
    <title>Documentation</title>
    <style type="text/css"> a {color: #1913AB; text-decoration: none !important;} a:visited {color: #661099;} a:active {color: red;}
    a:hover {color: purple; background: #AFB; } .redtext {color: red;}</style>
  </head>
  <body><b>Introduction</b>
    <ol>
      <ol type="A">
      </ol>
      <li><b>Overview</b></li>
      <ol type="A">
        <li>Terminology</li>
        <ol type="a">
          <li>Travel Guide</li>
          Travel guides describe possible things to do and places to see on a
          trip with optional schedules.<br>
          <br>
          <li>Travel Journal</li>
          Travel journals document what was actually done and seen on a trip at
          specific times.<br>
          <br>
          <li>Travel Content or Content</li>
          The terms “Travel Guides &amp; Travel Journals” are sometimes referred
          to as “Travel Content” or “Guide” or just “Content”.<br>
          <br>
          <li>Original Content Creator</li>
          Anyone that creates a guide from zero (without copying an existing
          guide) by using "New Guide"<br>
          <br>
          <li>Content Owner</li>
          Anyone that creates a guide by copying an existing guide using "Copy
          Guide".<br>
          <br>
          <li>Secondary Content Creator</li>
          Anyone that creates a guide by copying an existing guide using "Copy
          Guide" and enhances the guide e.g. translates it to another language.<br>
          <br>
          <li>User</li>
          Anybody that is viewing a guide<br>
          <br>
          <li>Places</li>
          The term “Place” is used to generically refer to locations, paths, and
          areas, and all three appear together in the Places list. <br>
          <br>
          <li>Locations</li>
          Locations are points on the map e.g. hotel, attraction, etc. <br>
          <br>
          <li>Paths</li>
          Paths are lines on a map e.g. driving or walking directions<br>
          <br>
          <li>Areas</li>
          Areas are multiple locations on a map that define an area e.g.,
          neighborhoods. <br>
          <br>
          <li>Groups</li>
          Groups and categories together help to categorize places and enable
          searching and filtering. By convention, a group is at a higher level.
          For example, you can have a group be a neighborhood (e.g., downtown)
          and a category be a restaurant. So that you can easily find all the
          "restaurants downtown". A place can have multiple groups and multiple
          categories. For example, a second category can be Italian restaurant,
          so that you can easily find all restaurants, or all the "Italian
          restaurants downtown".<br>
          <br>
          <li>Categories</li>
          See Groups above.<br>
          <br>
          <li>Event</li>
          An event is an activity with; a start and end date and time. For
          example, “Lunch on July 12 between 12 and 1”.&nbsp; An event can have
          multiple places associated with it. An event can contain user
          specified HTML content. A guide can have multiple events. Events
          belong to a specific schedule.<br>
          <br>
          <li>Schedule</li>
          A Guide has one default schedule called “Main”, and other schedules
          can be added. A schedule contains one or more events. For example,
          “One-day Tour”, “Two-day Tour” etc.<br>
          <br>
          <li>Itinerary</li>
          An Itinerary is the HTML version of a schedule, which is scrollable,
          zoomable and interactive.<br>
          <br>
          <li>Chapters</li>
          A book contains chapters, which contain sections. Andiamo Places uses
          the book metaphor to organize HTML content. Sections are used to store
          HTML content and chapters are convenient folders to store the sections
          in. The chapters are for the benefit of the guide creator and the user
          does not really interact with them. The guide user sees the content
          which the guide creator makes available to the user via HTML and links
          to internal HTML (i.e. sections), or external HTML (i.e. URLs).<br>
          <br>
          <li>Sections</li>
          Sections are organized under chapters and is where the guide creator
          creates and stores HTML content that he later wants to show the user
          via HTML links. <br>
          <br>
        </ol>
        <li>Quick tour</li>
        <ol>
          <li>The Andiamo Places app allows anyone to create Travel Guides &amp;
            Travel Journals for Free. This is useful for trip planning,
            efficient trip execution, to document and archive what you did on
            the trip and to show your friends what you did on your trip.</li>
          <li>When you first launch the app, you will be in "Guest Mode" because
            you have not registered or logged in.</li>
          <li>In guest mode, you can view content and even make changes, but
            only locally because you cannot store any content in the cloud until
            we can identify who you are. Local content is lost when the app
            restarts.</li>
          <li>In guest mode, you cannot get commissions from travel tickets that
            you purchases, because we can't identify who you are.</li>
          <li>To gain full functionality, you can register and login for free.</li>
          <li>When logged in, you can create your own content by either copying
            an existing guide, using "Copy Guide" or creating a new guide, using
            "New Guide".</li>
          <li>When you create a guide using "New Guide", you are the primary
            content creator and the guide owner. When you copy someone else's
            guide, you are the owner of that guide, and if you make changes you
            are the secondary content creator.</li>
          <li>As the primary content creator, you benefit from a 60% commission
            on tickets purchased by anyone using your content, as owner you get
            10% of the commissions. Make sure that you register with
            TravelPayouts and enter the TravelPayouts marker into Andiamo Places</li>
          <li>To learn how to add content to a guide see section on <a href="#Create%20a%20New%20Guide">Create
              a New Guide</a></li>
          <li>The starting screen of the app is the Home screen. The Home screen
            contains HTML content that you can edit. It can be used as a
            launching pad for other HTML content e.g. Table Of&nbsp; Contents,
            or as the main HTML content e.g. Journal.</li>
          <li>You can view a list of the places in the"Places" screen. From here
            you can filter places, sort places or add new places.</li>
          <li>You can seethe places on a map in the"Map" screen. From here you
            can filter places, add new places, search for new places and
            interact with the map.</li>
          <li>You can see the schedules and events in the"Schedules" screen.
            From here you can filter events. add events, manage different
            schedules etc.</li>
          <li>You can purchase travel related tickets from the"Tickets" screen</li>
        </ol>
      </ol>
    </ol>
    <ol>
      <ol type="A">
        <ol>
        </ol>
        <li>Navigation</li>
        The bottom navigation bar is used to navigate between the main
        application screens: Home, Places, Map, Schedules, and Tickets.
        Navigation is also possible when clicking on some HTML links, or when
        selecting menu items. Note that for most dialogs, the back arrow at the
        upper left is to cancel and exit, the check mark is to make the changes
        and exit.<br>
        <br>
        <ol type="a">
        </ol>
      </ol>
      <li><b>Login</b></li>
      <ol type="A">
        <li>Guest Mode</li>
        When you launch the Andiamo Places mobile app. If you are not logged in,
        you are in guest mode. As a guest, you can do almost anything that a
        logged in user can do, but since we don’t know who you are, you cannot
        do anything that requires saving changes to the cloud. For example, Save
        guide, Copy guide, New guide and review guide.<br>
        <br>
        <li>Register</li>
        You do not need to register to view guides, however you do need to
        register and then login to create and save your own guides or to
        potentially receive commissions from purchasing travel tickets.<br>
        <br>
        <li>Login</li>
        Before you can login you need to register. You do not need to login to
        view guides; however, you do need to login to create and save your own
        guides or to potentially receive commissions from purchasing travel
        tickets.<br>
        <br>
        <li>Logout</li>
        You can logout to add security to the guides that you own.<br>
        <br>
        <li>Register with FB</li>
        You can register by using your Facebook credentials. Remember that if
        you register using the Facebook credentials, we cannot provide a forgot
        password feature.<br>
        <br>
        <li>Login with FB</li>
        You can login by using your Facebook credentials.<br>
        <br>
        <li>Forgot Password/Reset Password</li>
        If you registered by providing a password (i.e., did not use Facebook)
        and you forget your password, we can reset it. Remember that if you
        register using the Facebook credentials, we cannot provide a forgot
        password feature.<br>
        <br>
        <li>User details</li>
        The user details screen displays user information like user id, email,
        first name, last name and TravelPayouts marker.<br>
        <br>
        <li>Settings</li>
        From Settings you can set preferences like; distance unit, date format,
        edit mode, advanced mode and TravelPayouts marker.
      </ol>
      <li><b>Search</b></li>
      To search for guides. From the home screen click on the Guide icon and
      select “Search Guides”.<br>
      <br>
      <li><b>HTML Intro</b></li>
      A lot of the content uses HTML. You do not need to know HTML to add
      content because it gets added for you by just typing and clicking on
      options.<br>
      <br>
      <li><b>HTML Editor</b></li>
      The app provides a simple HTML editor, but you can also use a more
      sophisticated HTML editor and paste the HTML into the app.<br>
      <br>
      <li><b>HTML Links</b></li>
      App specific HTML links can be generated by simply typing text and
      clicking on the link desired e.g. link to a place menu.<br>
      <br>
      <li><b>HTML Embedded</b></li>
      HTML can be links to other content or HTML embedded on that page. Note
      that the advantage to links is that they are dynamic i.e., the content is
      created from what’s in the guide when clicked. Which means that they are
      always up to date.<br>
      <br>
      <li><b>Home Screen</b></li>
      The home screen (except for the top and bottom application bars) is HTML
      which is customizable by the user. The content creator can edit the HTML
      and add links or embedded content. Common uses for the “Home” page are;
      table of contents or main content of the journal. Note that HTML can link
      to other HTML by using links to sections.
      <ol type="A">
        <li>User</li>
        <ol>
          <li>Logout</li>
          <li>Reset Password</li>
          <li>User Details</li>
          <li>Settings</li>
          <li>Login</li>
          <li>Register</li>
          <br>
        </ol>
        <li>Guide</li>
        <ol type="a">
          <li>Search Guides</li>
          <li>New Guide</li>
          <li>Copy Guide</li>
          <li>Overwrite Guide</li>
          <li>Rate this Guide</li>
          <li>Upload Images</li>
          <li>Guide Info</li>
          <li>Email Guide Link</li>
          <li>Whatsapp Guide Link</li>
          <br>
        </ol>
        <li>Edit</li>
        <ol type="a">
          <li>Edit Home Screen</li>
          <li>Edit Chapters/Sections</li>
          <li>Edit Groups</li>
          <li>Edit Categories</li>
          <li>Set Favorites</li>
          <li>Set Visited</li>
          <li>Set Must See</li>
          <li>Set Hidden</li>
          <li>Edit Guide Info</li>
          <br>
        </ol>
        <li>Save</li>
          <br>
        <li>Info</li>
        <ol type="a">
          <li>About</li>
          <li>Help Videos</li>
          <li>FAQs</li>
          <li>Create a Guide</li>
          <li>Contact Us</li>
          <br>
        </ol>
      </ol>
      <li><b>Places Screen</b></li>
      The places screen contains a list of places (remember that includes paths
      and areas). From this screen one can filter by text, add new places,
      filter places and sort places. When the places are filtered, the filter
      icon color changes to yellow.
      <ol type="A">
        <li>Back arrow</li>
        <ol>
          <li>Go back to previous screen</li>
          <br>
        </ol>
        <li>Search</li>
        <ol>
          <li>Filters places in list by text</li>
        </ol>
        <li>Add</li>
        <ol>
          <li>Add place</li>
          <li>Add path</li>
          <li>Add area</li>
          <br>
        </ol>
        <li>Filter</li>
        <ol>
          <li>Only Favorites</li>
          <li>Only Visited</li>
          <li>Only Not Visited</li>
          <li>Only Must See</li>
          <li>Only Search Results</li>
          <li>Filter by days</li>
          <li>Filter by Schedules</li>
          <li>Filter by Places</li>
          <li>Filter by Groups</li>
          <li>Filter by Categories</li>
          <li>Filter by Distance</li>
          <li>Hide Search Results</li>
          <li>Hide Specific Groups</li>
          <li>Show hidden places</li>
          <li>Show All</li>
          <br>
        </ol>
        <li>Sort</li>
        <br>
      </ol>
      <li><b>Map Screen</b></li>
      The map screen displays the places on a map (remember that includes paths
      and areas). From this screen one can filter by text, add new places,
      filter places, and interact with the map. When the places are filtered,
      the filter icon color changes to yellow.
      <ol type="A">
        <li>Back arrow</li>
        <ol>
          <li>Go back to previous screen</li>
          <br>
        </ol>
        <li>Search</li>
        <ol>
          <li>Filters places in map by text</li>
          <br>
        </ol>
        <li>Add</li>
        <ol>
          <li>Add place</li>
          <li>Add path</li>
          <li>Add area</li>
          <li>Search Attractions</li>
          <li>Wikidata category search</li>
          <li>Wikidata text search</li>
          <li>Google category search</li>
          <li>Google text search</li>
          <li>Wikipedia Search</li>
          <li>Search Happy Cow</li>
          <br>
        </ol>
        <li>Filter</li>
        <ol>
          <li>Only Favorites</li>
          <li>Only Visited</li>
          <li>Only Not Visited</li>
          <li>Only Must See</li>
          <li>Only Search Results</li>
          <li>Filter by days</li>
          <li>Filter by Schedules</li>
          <li>Filter by Places</li>
          <li>Filter by Groups</li>
          <li>Filter by Categories</li>
          <li>Filter by Distance</li>
          <li>Hide Search Results</li>
          <li>Hide Specific Groups</li>
          <li>Show hidden places</li>
          <li>Show All</li>
          <br>
        </ol>
        <li>Menu</li>
        <ol>
          <li>Track my location</li>
          <li>Center Map To My Location</li>
          <li>Fit All Places</li>
          <li>Satellite View</li>
          <li>Remove Search Results</li>
          <br>
        </ol>
      </ol>
      <li><b>Schedule Screen</b></li>
      The schedules screen displays the events for the selected schedule. Events
      can be filtered by text; new events can be added and existing events can
      be modified.
      <ol type="A">
        <li>Back arrow</li>
        <li>Search</li>
        <ol>
          <li>Filter events by text</li>
        </ol>
        <li>Add</li>
        <ol>
          <li>Displays the add eventdialog</li>
        </ol>
        <li>Menu</li>
        <ol>
          <li>Show Itinerary</li>
          <li>Change Start Date</li>
          <li>Move One Day</li>
          <li>Move All Days</li>
          <li>Delete One day</li>
          <li>Copy Schedule</li>
          <li>Delete Schedule</li>
          <li>Rename Schedule</li>
          <br>
        </ol>
      </ol>
      <li><b>Tickets Screen</b></li>
      The tickets screen displays the various links for purchasing travel
      tickets. There is no obligation to buy, but buying tickets from here can
      earn you, the owner or the creator commission money and motivates content
      creators to create more and better content.<br>
      <br>
      <ol type="A">
        <ol>
        </ol>
      </ol>
      <li><b>How to</b> </li>
      <ol type="A">
        <li>Register</li>
        <ol type="a">
          <li>From Home screen, click on User icon</li>
          <li>If "Register" is not in the list then you are already logged in</li>
          <li>Click on "Register"</li>
          <li>You can either register via Facebook or directly through the app</li>
          <br>
        </ol>
        <li>Login</li>
        <ol type="a">
          <li>From Home screen, click on User icon</li>
          <li>If "Login" is not in the list then you are already logged in</li>
          <li>Click on "Login"</li>
          <li>You can either login via Facebook or directly through the app by
            entering email and password</li>
          <br>
        </ol>
        <li>Logout</li>
        <ol type="a">
          <li>From Home screen, click on User icon</li>
          <li>If "Logout" is not in the list then you are already logged out</li>
          <br>
        </ol>
        <li>Reset Password</li>
        <ol type="a">
          <li>From Home screen, click on User icon</li>
          <li>Click on "Reset Password"</li>
          <li>Enter your email address</li>
          <li>Click on "Forgot Password"</li>
          <li>Note that if you registered with Facebook this feature will not
            work, because we don't manage your password</li>
          <br>
        </ol>
        <li>Select a Guide to View</li>
        <ol type="a">
          <li>From the Home screen click on the Guide icon</li>
          <li>Click on "Search Guides"</li>
          <li>Select the desired filters</li>
          <li>Click on the download icon</li>
          <br>
        </ol>
        <li>Copy a Guide</li>
        <ol type="a">
          <li>From the Home screen click on the Guide icon</li>
          <li>Click on "Copy Guide"</li>
          <li>The new guide will be opened</li>
          <li>Edit the guide content</li>
          <br>
        </ol>
        <li>Create a New Guide</li>
        <ol type="a">
          <li>Copy an existing guide or create a new guide</li>
          <li>Edit guide info (at least: name, image and base location) </li>
          <li>Make a list of places (search the internet for suggestions. e.g. <a

              href="https://www.bing.com/travelguide?q=Florence&amp;l2sid=a5781efb-3e5e-9a41-5826-411172f05cc3&amp;form=WPTAB">bing
              travel</a>)</li>
          <li>Select the "Must See" places (from the places found, search
            internet for recommendations)</li>
          <li>Make a list of groups (from places list)</li>
          <li>Make a list of categories (from places list)</li>
          <li>Add/delete groups in guide. Select icon groups</li>
          <li>Add/delete categories in guide. Select icon for categories</li>
          <li>Add Locations to guide (from search, point on map, point of interest etc, </li>
          <li>Add Paths to guide from map</li>
          <li>Add Areas to guide from map</li>
          <li>Add groups, categories and "Must See" flag to places. Select icon
            only to override group and category icon.</li>
          <li>Note, you can use the 'Filter places in area" to expedite grouping and tagging</li>
          <li>Search for or create itineraries (e.g. from <a href="https://www.bing.com/travelguide?q=Florence&amp;l2sid=a5781efb-3e5e-9a41-5826-411172f05cc3&amp;form=WPTAB">bing
              travel</a>)</li>
          <li>Add events to schedule</li>
          <li>Add place(s) visited during each event</li>
          <li>Create Chapters and Sections (optional, used as HTML content)</li>
          <li>Edit Home screen (optional, can be used like a table of contents
            for quick links to data or as the main travel journal)</li>
          <li>Set access level for guide if you want to let others view guide</li>
          <li>Remember to click on "save" periodically to save changes to the
            guide.</li>
          <br>
        </ol>
        <li>Ovewrite Guide</li>
        <ol type="a">
          <ol>
          </ol>
          <li>Overwriting a guide is useful if you want to keep one updated,
            while making changes via copies made from that guide</li>
          <ol>
          </ol>
          <li>This can be useful if you are collaborating on a guide with other
            people, but since the last write overwrites previous changes, you
            need to coordinate when overwrites are allowed</li>
          <br>
          <ol>
          </ol>
        </ol>
        <li>Rate Guide</li>
        <ol type="a">
          <ol>
          </ol>
          <li>To review a Guide you first need to download it (i.e. search and
            view the guide)</li>
          <ol>
          </ol>
          <li>From the "Home" screen click on the "Guide" icon</li>
          <ol>
          </ol>
          <li>Select "Rate this Guide"</li>
          <ol>
          </ol>
          <li>Provide a numerical rating by dragging your finger on the stars</li>
          <ol>
          </ol>
          <li>Provide a text comment</li>
          <ol>
          </ol>
          <li>Click on "Done" when finished or back arrow to cancel</li>
          <ol>
          </ol>
          <li>Note that only one review is allowed per user. You can modify a
            review if you have already submitted one.</li>
          <br>
          <ol>
          </ol>
        </ol>
        <li>Add Place</li>
        <ol type="a">
          <li>You can add a place from the "Places", "Map" or "Schedule" screens</li>
          <li>From the "Map" screen click on the "+" icon</li>
          <li>Select "Add Place"</li>
          <li>Enter name</li>
          <li>Click on get location from map</li>
          <li>Select location by clicking on map or moving marker</li>
          <li>Click on the check mark</li>
          <li>Click on the check mark again</li>
          <br>
        </ol>
        <li>Send a browser viewable link to guide</li>
        <br>
        <ol type="a">
          <ol type="a">
          </ol>
        </ol>
        <li>Send email link</li>
        <ol type="a">
          <li>From the "Home" screen click on the "Guide" icon</li>
          <ol type="a">
          </ol>
          <li>Select "Email Guide Link"</li>
          <ol type="a">
          </ol>
          <li>This willl launch your email program with the URL link</li>
          <ol type="a">
          </ol>
          <li>Select who you want to send it to</li>
          <ol type="a">
            <br>
          </ol>
        </ol>
        <li>Send Whatsapp link</li>
        <ol type="a">
          <li>From the "Home" screen click on the "Guide" icon</li>
          <li>Select "Whatsapp Guide Link"</li>
          <li>This will launch your Whatsapp program with the URL link</li>
          <li>Select who you want to send it to</li>
          <br>
          <ol type="a">
            <ol type="i">
            </ol>
          </ol>
        </ol>
        <li>Add Path</li>
        <ol type="a">
          <li>Same as adding a Place, but select a path by clicking on several
            spots on map</li>
          <br>
        </ol>
        <li>Add Area</li>
        <ol type="a">
          <li>Same as adding a Place, but select an area by clicking on several
            spots on map</li>
          <br>
        </ol>
        <li>Add event</li>
        <ol type="a">
          <li>From the "Schedule" screen click on the "+" icon</li>
          <li>If adding to a new schedule click on "Create a new one"</li>
          <li>Provide a Subject</li>
          <li>Select starting and ending date and time</li>
          <li>Selecting an existing place or create and add a new one</li>
          <li>Provide optional Event Info</li>
          <li>Click on the check mark</li>
          <br>
        </ol>
        <li>Edit Home Screen</li>
        <ol type="a">
          <li>From the "Home" screen click on the "Edit" icon</li>
          <li>Select "Edit Home Screen"</li>
          <li>Make changes to the HTML</li>
          <li>Click on the check mark</li>
          <br>
        </ol>
        <li>Add TravelPayouts Marker</li>
        <ol type="a">
          <ol type="a">
          </ol>
          <li>Login to Andiamo Places</li>
          <ol type="a">
          </ol>
          <li>From the Home screen click on the "User" icon</li>
          <ol type="a">
          </ol>
          <li>Select setting</li>
          <ol type="a">
          </ol>
          <li>Enter TravelPayouts marker</li>
          <ol type="a">
          </ol>
          <li>Click on check mark</li>
          <br>
        </ol>
        <ol type="a">
          <ol type="a">
          </ol>
        </ol>
        <li>Upload Images</li>
        <ol type="a">
          <ol type="a">
          </ol>
          <li>There are 2 ways to upload images. Directly via "Upload Images"
            from "Guide" -&gt; "Upload Images" or indirectly whenever you choose
            an image from a folder on your phone or from your camera</li>
          <ol type="a">
          </ol>
          <li>Using the the first method, you first upload your photo and then
            in a second step you use it in your guide. In the second method, you
            choose your photo and it gets uploaded behind the scenes. To assign
            a name and folder, you still need to go to "Upload Images".</li>
          <ol type="a">
          </ol>
          <li>When you upload images they go into folders which you can name.</li>
          <ol type="a">
          </ol>
          <li>Before uploading you can choose the folder</li>
          <ol type="a">
          </ol>
          <li>After uploading you can set the image name (Not required)</li>
          <br>
        </ol>
        <li>Videos</li>
        <ol type="a">
          <li><a href="https://youtu.be/q5GzE2e0NRQ" target="_blank">What is it?</a></li>
          <li><a href="https://youtu.be/_vvXWceGm5E" target="_blank">Quick
              overview</a></li>
          <li><a href="https://youtu.be/pdBXedreFk4" target="_blank">login,
              register guest mode &amp; forgot password</a></li>
          <li><a href="https://youtu.be/TvcGjld9Uj8" target="_blank">Search and
              select guide</a></li>
          <li><a href="https://youtu.be/cIw-zweH24c" target="_blank">HTML intro</a></li>
          <li><a href="https://youtu.be/yPvxoHActpA" target="_blank">HTML editor</a></li>
          <li><a href="https://youtu.be/aojRULW89Fs" target="_blank">HTML links</a></li>
          <li><a href="https://youtu.be/eosacoMAI1A" target="_blank">HTML
              embedded</a></li>
          <li><a href="https://youtu.be/ThYQiFw_Zv8" target="_blank">Home screen</a></li>
          <li><a href="https://youtu.be/-Po86Gos0Pc" target="_blank">Add audio
              from google drive</a></li>
          <li><a href="https://youtu.be/lSp9wRyEoD0" target="_blank">Groups and
              categories</a></li>
          <li><a href="https://youtu.be/IoPctej5agw" target="_blank">Search for
              places</a></li>
          <li><a href="https://youtu.be/gzWbK5pR3b0" target="_blank">Search with
              Happy Cow</a></li>
          <li><a href="https://youtu.be/VWqZcim82yQ" target="_blank">Edit guide
              info</a></li>
          <li><a href="https://youtu.be/kMhp3bw10SI" target="_blank">Steps to
              create a guide (old UI)</a></li>
          <li><a href="https://youtu.be/9V9NCdR93KA" target="_blank">Places
              Screen</a></li>
          <li><a href="https://youtu.be/HTKzukUYy40" target="_blank">Map Screen</a></li>
          <li><a href="https://youtu.be/5p47W9_pqwk" target="_blank">Schedules
              Screen</a></li>
          <li><a href="https://youtu.be/2Mafj0BEw9Y" target="_blank">Add YouTube
              video</a></li>
          <li><a href="https://youtu.be/CKiqDXaC964" target="_blank">Chapters
              and sections</a></li>
          <li><a href="https://youtu.be/qelajE2ybYA" target="_blank">Copy guide</a></li>
          <li><a href="https://youtu.be/ow-QFRYfx0k" target="_blank">Add places,
              paths and areas</a></li>
          <li><a href="https://youtu.be/3YzF9o00CoU" target="_blank">Create new
              empty guide</a></li>
          <li><a href="https://youtu.be/Yd_y91OJ9Vg" target="_blank">Images</a></li>
          <li><a href="https://youtu.be/y0bWQSDPTec" target="_blank">Ratings and
              reviews</a></li>
          <li><a href="https://youtu.be/wdOplNPx6Ms" target="_blank">Collaborating
              on the same guide</a></li>
        </ol>
      </ol>
    </ol>
  </body>
</html>
`;
