import GooglePlaces from "../Models/GooglePlaces";
class PlaceMenu {
  static handleCenterInMap(id) {}

  static handleShowStreetView(id) {
    const place = window.guide_obj.places.getPlace(id);
    if (place == null) return null;
    let action = `http://maps.google.com/maps?q=&layer=c&cbll=${place.Lat},${place.Longi}&cbp=11,0,0,0,0`;
    let htmlString = `<!DOCTYPE html>
<html>
  <head>
    <title>StreetView</title>
    <meta http-equiv='Content-Type' content='text/html; charset=utf-8'/>
  </head>
  <body>
    <script type='text/javascript'>
      window.location.href = "${action}";
    </script>
  </body>
</html>`;
    let win = window.open("about:blank", "", "_blank");
    win.document.write(htmlString);
    win.focus();
  }

  static handleWeatherForecast(id) {
    const place = window.guide_obj.places.getPlace(id);
    if (place == null) return null;
    const _url = `https://www.wunderground.com/forecast/${place.Lat},${place.Longi}`;
    let win = window.open(_url, "_blank");
    win.focus();
  }

  static handleShowInGoogleMaps(id) {
    const place = window.guide_obj.places.getPlace(id);
    if (place == null) return null;
    let action = `http://maps.google.com/maps?q=${place.Lat},${place.Longi}`;
    let htmlString = `<!DOCTYPE html>
<html>
  <head>
    <title>StreetView</title>
    <meta http-equiv='Content-Type' content='text/html; charset=utf-8'/>
  </head>
  <body>
    <script type='text/javascript'>
      window.location.href = "${action}";
    </script>
  </body>
</html>`;
    let win = window.open("about:blank", "", "_blank");
    win.document.write(htmlString);
    win.focus();
  }

  static handleShowPlaceAttributes(id, SearchResultsPlacesList) {
    let place = window.guide_obj.places.getPlace(id);
    if (place == null && SearchResultsPlacesList !== null) {
      const index = SearchResultsPlacesList.findIndex((x) => x.ID === id);
      if (index !== -1) place = SearchResultsPlacesList[index];
    }
    if (place == null) return "";
    //console.log(place);
    try {
      let htmlString = place.createPlaceDetailsHTML(place.Info, null, window.guide_obj, place.scheduledDates);
      return htmlString;
    } catch (e) {
      return "";
    }
  }

  static handleShowPlaceUrl = (id) => {
    const place = window.guide_obj.places.getPlace(id);
    if (place == null) return "";
    let link = place.getFirstHLink();
    if (link.includes("place_id=")) {
      const parts = link.split("=");
      console.log("parts", parts);
      GooglePlaces.getMoreDetails(this.getMoreDetailsCallback, parts[1]);
      return;
    }
    if (link !== "") {
      let win = window.open(link, "_blank");
      win.focus();
      return null;
    }
  };

  static getMoreDetailsCallback = (place_id, details) => {
    if (details === null) return;
    let hlink = "";
    console.log("getMoreDetailsCallback", details);
    if (typeof details.url != "undefined") {
      if (details.url.includes(";")) {
        const urls = details.url.split(";");
        hlink = `${urls[0]}`;
      } else hlink = `${details.url}`;
    }
    console.log("getMoreDetailsCallback", hlink);
    if (hlink !== "") {
      let win = window.open(hlink, "_blank");
      win.focus();
      return null;
    }
  };

  static handleGoToYelp(id) {
    const place = window.guide_obj.places.getPlace(id);
    if (place == null) return "";
    let action = `https://www.yelp.com/search?find_loc=${place.Lat},${place.Longi}`;
    let htmlString = `<!DOCTYPE html>
<html>
  <head>
    <title>StreetView</title>
    <meta http-equiv='Content-Type' content='text/html; charset=utf-8'/>
  </head>
  <body>
    <script type='text/javascript'>
      window.location.href = "${action}";
    </script>
  </body>
</html>`;
    let win = window.open("about:blank", "", "_blank");
    win.document.write(htmlString);
    win.focus();
  }

  static handleGoToFoursquare(id) {
    const place = window.guide_obj.places.getPlace(id);
    if (place == null) return "";
    let action = `https://foursquare.com/explore?mode=url&ll=${place.Lat},${place.Longi}&q=Top%20Picks`;

    let htmlString = `<!DOCTYPE html>
<html>
  <head>
    <title>StreetView</title>
    <meta http-equiv='Content-Type' content='text/html; charset=utf-8'/>
  </head>
  <body>
    <script type='text/javascript'>
      window.location.href = "${action}";
    </script>
  </body>
</html>`;
    let win = window.open("about:blank", "", "_blank");
    win.document.write(htmlString);
    win.focus();
  }

  static handleSetAsCurrentLocation(id) {
    const place = window.guide_obj.places.getPlace(id);
    if (place == null) return;
    const location = { latitude: parseFloat(place.Lat), longitude: parseFloat(place.Longi) };
    return location;
  }

  static handleEditPlace() {}
}
export default PlaceMenu;
