import React, { useState, useEffect, Component } from "react";
import {
  Modal,
  ToggleButtonGroup,
  ToggleButton,
  ButtonGroup,
  Button,
  Row,
  Col,
  Form,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import "./Modal.css";
import MultiSelectModal from "./MultiSelectModal";
import AlertModal from "./AlertModal";
import { useDispatch } from "react-redux";
import { slice1Actions } from "../../store/reducer";
import { useSelector } from "react-redux";
import Common, { DISTANCES, Settings, SortByEnum, labelChars, AUTHORIZATION_LEVEL, COLORS } from "../../Common/Common";
//import Guide from "../Models/Guide";
import GuideCard from "./GuideDownload/GuideCard";

import DataBase from "../../dataBase/liquidGuides";
import { DateTime } from "luxon";
import Place from "../../Models/Place";

const DirectionsDialog = (props) => {
  const dispatch = useDispatch();
  // const [DirectionsFromPlace, setDirectionsFromPlace] = useState([]);
  // const [DirectionsToPlace, setDirectionsToPlace] = useState([]);
  // const [DirectionsWaypoints, setDirectionsWaypoints] = useState([]);
  const [which_one, set_which_one] = useState("from");

  const [MultiselectItems, setMultiselectItems] = useState([]);
  const [MultiselectSelectedItems, setMultiselectSelectedItems] = useState([]);
  const [MultiselectMode, setMultiselectMode] = useState("uni");
  //const [MultiselectCallback, setMultiselectCallback] = useState(null);
  const [MultiselectTitle, setMultiselectTitle] = useState("Select");
  const [MultiselectSearchText, setMultiselectSearchText] = useState("");
  const [ShowMultiselect, setShowMultiselect] = useState(null);
  const [AlertModalShow, setAlertModalShow] = useState(false);
  const [AlertModalTitle, setAlertModalTitle] = useState("");
  const [AlertModalMessage, setAlertModalMessage] = useState("");
  const [AlertModalButtons, setAlertModalButtons] = useState([]);
  const [TravelMode, setTravelMode] = useState("Walking");
  const [ShapeToCreate, setShapeToCreate] = useState("Path");
  const [DialogDetails, setDialogDetails] = useState(false);
  const [Operation, setOperation] = useState("Directions");

  let DirectionsFromLocation = useSelector((state) => state.DirectionsFromLocation);
  let DirectionsToLocation = useSelector((state) => state.DirectionsToLocation);
  let DirectionsWaypointsLocation = useSelector((state) => state.DirectionsWaypointsLocation);

  useEffect(() => {
    // if (fromLoc != null) setDirectionsFromLocation(fromLoc);
    // if (toLoc != null) setDirectionsToLocation(toLoc);
    // if (waypointsArray.length !== 0) DirectionsWaypointsLocation(waypointsArray);
    if (typeof window.DirectionsWaypointPlaces === "undefined") window.DirectionsWaypointPlaces = [];
    if (typeof window.DirectionsFromPlace === "undefined") window.DirectionsFromPlace = [];
    if (typeof window.DirectionsToPlace === "undefined") window.DirectionsToPlace = [];
    if (window.DirectionsFromLocation === null) {
      const _place = window.guide_obj.places.getPlace(props.id);
      if (_place !== null) {
        window.DirectionsFromLocation = { Name: _place.Name, Lat: _place.Lat, Longi: _place.Longi };
        dispatch(
          slice1Actions.setDirectionsFromLocation({
            value: { Name: _place.Name, Lat: _place.Lat, Longi: _place.Longi },
          })
        );
      }
    } else if (window.DirectionsToLocation === null) {
      const _place = window.guide_obj.places.getPlace(props.id);
      if (_place !== null) {
        if (
          window.DirectionsFromLocation.Name !== _place.Name ||
          window.DirectionsFromLocation.Lat !== _place.Lat ||
          window.DirectionsFromLocation.Longi !== _place.Longi
        ) {
          window.DirectionsToLocation = { Name: _place.Name, Lat: _place.Lat, Longi: _place.Longi };
          dispatch(
            slice1Actions.setDirectionsToLocation({
              value: { Name: _place.Name, Lat: _place.Lat, Longi: _place.Longi },
            })
          );
        }
      }
    }
  }, [props.id]);

  const selectPlaces = (which) => {
    set_which_one(which);
    let selectedPlaces = [];
    let Places =
      which === "waypoints"
        ? window.DirectionsWaypointPlaces
        : which === "from place"
        ? window.DirectionsFromPlace
        : window.DirectionsToPlace;
    Places.forEach((place) =>
      selectedPlaces.push({
        label: place.Name,
        name: place.Name,
        value: place.ID,
      })
    );
    setMultiselectItems(window.guide_obj.places.getPlacesToDisplay(null));
    setMultiselectSelectedItems(selectedPlaces);
    setMultiselectMode(which === "waypoints" ? "multi" : "uni");
    setMultiselectTitle(`Select ${which}`);
    setShowMultiselect(true);
  };

  const selectPlacesCallBack = (Places) => {
    let _places = [];
    Places.forEach((place) => {
      _places.push({
        Name: place.label,
        ID: place.value,
      });
    });
    if (which_one === "waypoints") {
      let locs = [];
      _places.forEach((place) => {
        const _place = window.guide_obj.places.getPlace(place.ID);
        if (_place !== null) locs.push({ Name: _place.Name, Lat: _place.Lat, Longi: _place.Longi });
      });
      window.DirectionsWaypointPlaces = _places;
      dispatch(slice1Actions.setDirectionsWaypointsLocation({ value: locs }));
      window.DirectionsWaypointsLocation = locs;
    } else if (which_one === "from place") {
      const _place = window.guide_obj.places.getPlace(_places[0].ID);
      if (_place !== null) {
        window.DirectionsFromPlace = _places;
        dispatch(
          slice1Actions.setDirectionsFromLocation({
            value: { Name: _place.Name, Lat: _place.Lat, Longi: _place.Longi },
          })
        );
        window.DirectionsFromLocation = { Name: _place.Name, Lat: _place.Lat, Longi: _place.Longi };
      }
    } else {
      const _place = window.guide_obj.places.getPlace(_places[0].ID);
      if (_place !== null) {
        window.DirectionsToPlace = _places;
        window.DirectionsToLocation = { Name: _place.Name, Lat: _place.Lat, Longi: _place.Longi };
        dispatch(
          slice1Actions.setDirectionsToLocation({
            value: { Name: _place.Name, Lat: _place.Lat, Longi: _place.Longi },
          })
        );
        window.DirectionsToLocation = { Name: _place.Name, Lat: _place.Lat, Longi: _place.Longi };
      }
    }
  };

  const createPath = () => {
    if (window.DirectionsFromLocation === null) {
      myAlert("Must select 'From' place");
      return;
    }
    if (window.DirectionsToLocation === null) {
      myAlert("Must select 'To' place");
      return;
    }
    shapeFromDirections("Path");
    exitDialog();
  };

  let _mapCoordinate = null;

  const shapeFromDirections = (shape) => {
    let name = `${window.DirectionsFromLocation.Name}-${window.DirectionsToLocation.Name}`;
    const directionsService = new window.google.maps.DirectionsService();
    const origin = { lat: window.DirectionsFromLocation.Lat, lng: window.DirectionsFromLocation.Longi };
    const destination = { lat: window.DirectionsToLocation.Lat, lng: window.DirectionsToLocation.Longi };
    let _wayp = [];
    if (window.DirectionsWaypointsLocation.length !== 0)
      for (let i = 0; i < window.DirectionsWaypointsLocation.length; i++) {
        _wayp.push({
          location: {
            lat: window.DirectionsWaypointsLocation[i].Lat,
            lng: window.DirectionsWaypointsLocation[i].Longi,
          },
          stopover: true,
        });
      }
    directionsService.route(
      {
        origin: origin,
        destination: destination,
        waypoints: _wayp,
        travelMode:
          TravelMode === "Walking"
            ? window.google.maps.TravelMode.WALKING
            : TravelMode === "Bicycling"
            ? window.google.maps.TravelMode.BICYCLING
            : window.google.maps.TravelMode.DRIVING, //DRIVING, TRANSIT, BICYCLING, WALKING
      },
      (result, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
          console.log("result", result);
          let points = "";
          for (var i = 0; i < result.routes[0].overview_path.length; i++) {
            if (points !== "") points += ":";
            points +=
              result.routes[0].overview_path[i].lng().toFixed(6) +
              "," +
              result.routes[0].overview_path[i].lat().toFixed(6);
          }
          let directions = "";
          for (var i = 0; i < result.routes[0].legs.length; i++) {
            for (var j = 0; j < result.routes[0].legs[i].steps.length; j++) {
              directions += result.routes[0].legs[i].steps[j].instructions + "<br/>";
            }
          }
          if (result.routes[0].legs.length > 0)
            directions += `Distance: ${result.routes[0].legs[0].distance.text}, Time: ${result.routes[0].legs[0].duration.text}`;
          console.log("directions", directions);
          _mapCoordinate = {
            Lat: result.routes[0].overview_path[0].lat(),
            Longi: result.routes[0].overview_path[0].lng(),
          };
          if (ShapeToCreate === "Path") addPlaceToMap("PolyLine", points, directions, name);
          else addPlaceToMap("Polygon", points);
        } else {
          console.error(`error fetching directions ${result}`);
        }
      }
    );
  };

  const addPlaceToMap = (PType, Points, directions, name) => {
    let shape = "New Place";
    if (PType === "Polygon") shape = "New Area";
    else if (PType === "PolyLine") shape = "New Path";
    let new_place = new Place(window.guide_obj.places);
    new_place.Name = `${shape}`;
    if (name != null) new_place.Name = name;
    // console.log("_mapCoordinate", _mapCoordinate);
    new_place.Lat = _mapCoordinate.Lat;
    new_place.Longi = _mapCoordinate.Longi;
    new_place.PType = PType;
    new_place.Points = Points;
    if (typeof directions !== "undefined") new_place.Info = directions;
    // console.log(new_place);
    //this.props.onAddPlace(new_place);
    window.guide_obj.places.addPlace(new_place);
    dispatch(slice1Actions.setShowHiddenPlace({ value: [new_place] }));
    dispatch(slice1Actions.setGuide({ guide: null }));
  };

  const getDirections = () => {
    if (window.DirectionsFromLocation === null) {
      myAlert("Must select 'From' place");
      return;
    }
    if (window.DirectionsToLocation === null) {
      myAlert("Must select 'To' place");
      return;
    }
    showDirections();
  };

  const showDirections = () => {
    let waypoints = "";
    if (window.DirectionsWaypointsLocation.length !== 0) {
      waypoints = "&waypoints=";
      for (let i = window.DirectionsWaypointsLocation.length - 1; i >= 0; i--) {
        waypoints += waypoints === "&waypoints=" ? "" : "|";
        waypoints += `${window.DirectionsWaypointsLocation[i].Lat},${window.DirectionsWaypointsLocation[i].Longi}`;
      }
    }
    let _url = `https://www.google.com/maps/dir/?api=1&destination=${window.DirectionsToLocation.Lat},${window.DirectionsToLocation.Longi}${waypoints}`;
    if (window.DirectionsFromLocation !== null)
      _url = `https://www.google.com/maps/dir/?api=1&origin=${window.DirectionsFromLocation.Lat},${window.DirectionsFromLocation.Longi}&destination=${window.DirectionsToLocation.Lat},${window.DirectionsToLocation.Longi}${waypoints}`;
    if (TravelMode === "Walking") _url = _url + "&travelmode=walking";
    else if (TravelMode === "Bicycling") _url = _url + "&travelmode=bicycling";
    else _url = _url + "&travelmode=driving";
    let win = window.open(_url, "_blank");
    win.focus();
    exitDialog();
  };

  const DeleteDirectionsMarkers = () => {
    window.DirectionsFromLocation = null;
    window.DirectionsToLocation = null;
    window.DirectionsWaypointsLocation = [];
    dispatch(slice1Actions.setDirectionsFromLocation({ value: null }));
    dispatch(slice1Actions.setDirectionsToLocation({ value: null }));
    dispatch(slice1Actions.setDirectionsWaypointsLocation({ value: [] }));
    dispatch(slice1Actions.setGuide({ guide: null }));
    window.DirectionsWaypointPlaces = [];
    window.DirectionsFromPlace = [];
    window.DirectionsToPlace = [];
  };

  const myAlert = (message, title, buttons) => {
    setAlertModalMessage(message);
    setAlertModalTitle(title == null ? "Message" : title);
    setAlertModalButtons(buttons == null ? [{ Title: "OK", Method: null }] : buttons);
    setAlertModalShow(true);
  };

  const displayList = (arr) => {
    if (arr === null) return "";
    let str = "";
    for (let i = 0; i < arr.length; i++) {
      if (str != "") str += ", ";
      str += arr[i];
    }
    return str;
  };

  const displayValueList = (arr) => {
    if (arr === null) return "";
    console.log("displayValueList", arr);
    let str = "";
    for (let i = 0; i < arr.length; i++) {
      if (str != "") str += ", ";
      str += arr[i].Name;
    }
    return str;
  };
  const displayMenuInfo = (menu_item_info) => {
    myAlert(menu_item_info, "Info");
  };
  const exitDialog = () => {
    if (typeof props.onHidePrevious != "undefined") props.onHidePrevious();
    props.onHide();
  };

  if (ShowMultiselect)
    return (
      <MultiSelectModal
        show={ShowMultiselect}
        onHide={() => setShowMultiselect(false)}
        MultiselectItems={MultiselectItems}
        MultiselectSelectedItems={MultiselectSelectedItems}
        MultiselectMode={MultiselectMode}
        MultiselectCallback={selectPlacesCallBack}
        MultiselectTitle={MultiselectTitle}
        MultiselectSearchText={MultiselectSearchText}
      />
    );
  else {
    let OKInfo = `Launches the Google maps web app and gets directions based on the to/from places, waypoints and ${TravelMode} directions`;
    if (Operation === "Draw")
      OKInfo = `Draw a ${ShapeToCreate} on the map using the to/from places, waypoints and ${TravelMode} directions`;
    return (
      <div className="modal" style={{ zIndex: 65537 }}>
        <Modal
          {...props}
          dialogClassName="myModalClass"
          aria-labelledby="contained-modal-title-vcenter"
          centered={false}
          scrollable={true}
          // style={{ minWidth: "350px" }}
          onHide={exitDialog}
        >
          <Modal.Header>
            <div style={{ width: "100%" }}>
              <div className="sort">
                <div className="dialog-heading color-blue">
                  <div>
                    <h4>Direction</h4>
                  </div>
                  {/* <div>
                      <i title="Exit" className={`fas fa-check color-blue`} onClick={getDirections}></i>
                    </div> */}
                  <div>
                    <i title="Exit" className={`fas fa-times color-blue`} onClick={exitDialog}></i>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div>
              {/* style={{ width: 100 }}> */}
              <div className="multiselect-item">
                <Form>
                  <Form.Group className="mb-3" controlId="ShowDetails">
                    <Form.Check
                      type="checkbox"
                      label="Show Details"
                      checked={DialogDetails}
                      onChange={() => {
                        setDialogDetails(!DialogDetails);
                      }}
                    />
                  </Form.Group>
                </Form>
              </div>
              <div className="flex-container-left" style={{ marginTop: 10 }}>
                <h5 className="form-item color-blue">Operation</h5>
              </div>
              <div className="flex-container-left">
                <Form>
                  <Form.Group className="mb-3" controlId="Operation">
                    <Row className="mb-3">
                      <Col>
                        <Form.Check
                          type="checkbox"
                          label="Directions"
                          checked={Operation === "Directions"}
                          onChange={() => setOperation("Directions")}
                        />
                      </Col>
                      <Col>
                        <Form.Check
                          type="checkbox"
                          label="Draw"
                          checked={Operation === "Draw"}
                          onChange={() => setOperation("Draw")}
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                </Form>
              </div>
              {Operation === "Draw" ? (
                <div>
                  <div className="flex-container-left" style={{ marginTop: 10 }}>
                    <h5 className="form-item color-blue">Draw Path or Area</h5>
                  </div>
                  <div className="flex-container-left">
                    <Form>
                      <Form.Group className="mb-3" controlId="ShowDetails">
                        <Row className="mb-3">
                          <Col>
                            <Form.Check
                              type="checkbox"
                              label="Path"
                              checked={ShapeToCreate === "Path"}
                              onChange={() => setShapeToCreate("Path")}
                            />
                          </Col>
                          <Col>
                            <Form.Check
                              type="checkbox"
                              label="Area"
                              checked={ShapeToCreate === "Area"}
                              onChange={() => setShapeToCreate("Area")}
                            />
                          </Col>
                        </Row>
                      </Form.Group>
                    </Form>
                  </div>
                  {/* <div className="flex-container-left" key={`r3`}>
                <Button className="form-item" variant="primary" size="sm" onClick={() => createPath()}>
                  Create Path
                </Button>
              </div> */}
                </div>
              ) : null}
              <div className="flex-container-left">
                <h5 className="form-item color-blue">Travel Mode</h5>
              </div>
              <div className="flex-container-left">
                <Form>
                  <Form.Group className="mb-3" controlId="ShowDetails">
                    <Row className="mb-3">
                      <Col>
                        <Form.Check
                          type="checkbox"
                          label="Walking"
                          checked={TravelMode === "Walking"}
                          onChange={() => setTravelMode("Walking")}
                        />
                      </Col>

                      <Col>
                        <Form.Check
                          type="checkbox"
                          label="Bicycling"
                          checked={TravelMode === "Bicycling"}
                          onChange={() => setTravelMode("Bicycling")}
                        />
                      </Col>

                      <Col>
                        <Form.Check
                          type="checkbox"
                          label="Driving"
                          checked={TravelMode === "Driving"}
                          onChange={() => setTravelMode("Driving")}
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                </Form>
              </div>
              {DialogDetails === true ? (
                <div>
                  <div className="flex-container-left" key={`r3`}>
                    <div className="text-center">
                      <Button
                        className="form-item"
                        variant="primary"
                        size="sm"
                        onClick={() => selectPlaces("from place")}
                      >
                        Select 'From' Place
                      </Button>
                    </div>
                    <span>&nbsp;&nbsp;</span>
                    <OverlayTrigger
                      // zIndex="9999"
                      placement="left"
                      delay={{ show: 250, hide: 400 }}
                      overlay={
                        <Tooltip id="button-tooltip-2">
                          {
                            "Select the place to start directions from. You can select from a list of places or from a place marker, a POI or any place on the map"
                          }
                        </Tooltip>
                      }
                    >
                      <i
                        // title="Info"
                        className={`fa fa-info`}
                        style={{ color: "#3598DB" }}
                        onClick={() =>
                          displayMenuInfo(
                            "Select the place to start directions from. You can select from a list of places or from a place marker, a POI or any place on the map"
                          )
                        }
                      ></i>
                    </OverlayTrigger>
                  </div>
                  <p>{window.DirectionsFromLocation === null ? "" : window.DirectionsFromLocation.Name}</p>
                  <div className="flex-container-left" key={`r3`}>
                    <div className="text-center">
                      <Button
                        className="form-item"
                        variant="primary"
                        size="sm"
                        onClick={() => selectPlaces("waypoints")}
                      >
                        Select Waypoints
                      </Button>
                    </div>
                    <span>&nbsp;&nbsp;</span>
                    <OverlayTrigger
                      // zIndex="9999"
                      placement="left"
                      delay={{ show: 250, hide: 400 }}
                      overlay={
                        <Tooltip id="button-tooltip-2">
                          {
                            "Optionally select one or more places along the way (waypoints). You can select from a list of places or from a place marker, a POI or any place on the map"
                          }
                        </Tooltip>
                      }
                    >
                      <i
                        // title="Info"
                        className={`fa fa-info`}
                        style={{ color: "#3598DB" }}
                        onClick={() =>
                          displayMenuInfo(
                            "Optionally select one or more places along the way (waypoints). You can select from a list of places or from a place marker, a POI or any place on the map"
                          )
                        }
                      ></i>
                    </OverlayTrigger>
                  </div>
                  <p>{displayValueList(window.DirectionsWaypointsLocation)}</p>
                  <div className="flex-container-left" key={`r3`}>
                    <div className="text-center">
                      <Button
                        className="form-item"
                        variant="primary"
                        size="sm"
                        onClick={() => selectPlaces("to place")}
                      >
                        Select 'To' Place
                      </Button>
                    </div>
                    <span>&nbsp;&nbsp;</span>
                    <OverlayTrigger
                      // zIndex="9999"
                      placement="left"
                      delay={{ show: 250, hide: 400 }}
                      overlay={
                        <Tooltip id="button-tooltip-2">
                          {
                            "Select the place to end directions. You can select from a list of places or from a place marker, a POI or any place on the map"
                          }
                        </Tooltip>
                      }
                    >
                      <i
                        // title="Info"
                        className={`fa fa-info`}
                        style={{ color: "#3598DB" }}
                        onClick={() =>
                          displayMenuInfo(
                            "Select the place to start directions from. You can select from a list of places or from a place marker, a POI or any place on the map"
                          )
                        }
                      ></i>
                    </OverlayTrigger>
                  </div>
                  <p>{window.DirectionsToLocation === null ? "" : window.DirectionsToLocation.Name}</p>
                  <div className="flex-container-left" key={`r3`}>
                    <div className="text-center">
                      <Button
                        className="form-item"
                        variant="primary"
                        size="sm"
                        onClick={() => DeleteDirectionsMarkers()}
                      >
                        Clear All
                      </Button>
                    </div>
                    <span>&nbsp;&nbsp;</span>
                    <OverlayTrigger
                      // zIndex="9999"
                      placement="left"
                      delay={{ show: 250, hide: 400 }}
                      overlay={
                        <Tooltip id="button-tooltip-2">
                          {"Removes the 'from' location, 'to' location and all of the waypoints"}
                        </Tooltip>
                      }
                    >
                      <i
                        // title="Info"
                        className={`fa fa-info`}
                        style={{ color: "#3598DB" }}
                        onClick={() =>
                          displayMenuInfo("Removes the 'from' location, 'to' location and all of the waypoints")
                        }
                      ></i>
                    </OverlayTrigger>
                  </div>
                </div>
              ) : null}
              {/* <div style={{ marginTop: 10 }}> */}
              <div className="flex-container-left" key={`r3`} style={{ marginTop: 10 }}>
                <div className="form-item">
                  <Button className="form-item" variant="success" size="sm" onClick={() => getDirections()}>
                    OK
                  </Button>
                </div>
                {/* </div> */}
                <span>&nbsp;&nbsp;</span>
                <OverlayTrigger
                  // zIndex="9999"
                  placement="left"
                  delay={{ show: 250, hide: 400 }}
                  overlay={<Tooltip id="button-tooltip-2">{`${OKInfo}`}</Tooltip>}
                >
                  <i
                    // title="Info"
                    className={`fa fa-info`}
                    style={{ color: "#3598DB" }}
                    onClick={() =>
                      displayMenuInfo(
                        "Launches the Google maps web app and gets directions based on the to, from, waypoints and travel mode, i.e. walking or driving."
                      )
                    }
                  ></i>
                </OverlayTrigger>
              </div>
              {/* <span>&nbsp;&nbsp;</span>
          <OverlayTrigger
            // zIndex="9999"
            placement="left"
            delay={{ show: 250, hide: 400 }}
            overlay={
              <Tooltip id="button-tooltip-2">
                {"Creates either a Path or an Area (based on option selected) from the directions data returned."}
              </Tooltip>
            }
          >
            <i
              // title="Info"
              className={`fa fa-info`}
              style={{ color: "#3598DB" }}
              onClick={() =>
                displayMenuInfo(
                  "Creates either a Path or an Area (based on option selected) from the directions data returned."
                )
              }
            ></i>
          </OverlayTrigger> */}
              <AlertModal
                show={AlertModalShow}
                onHide={() => setAlertModalShow(false)}
                AlertModalTitle={AlertModalTitle}
                AlertModalMessage={AlertModalMessage}
                AlertModalButtons={AlertModalButtons}
              />
            </div>
          </Modal.Body>
        </Modal>
        <AlertModal
          show={AlertModalShow}
          onHide={() => setAlertModalShow(false)}
          AlertModalTitle={AlertModalTitle}
          AlertModalMessage={AlertModalMessage}
          AlertModalButtons={AlertModalButtons}
        />
      </div>
    );
  }
};

export default DirectionsDialog;
