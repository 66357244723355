import React from "react";
import { Modal, Button } from "react-bootstrap";
import "./Modal.css";
import { useDispatch } from "react-redux";
import { slice1Actions } from "../../store/reducer";
import { useSelector } from "react-redux";
// import YouTube from "react-youtube";

const YouTubeDialog = (props) => {
  const dispatch = useDispatch();

  const handleButtonClick = (button) => {
    if (button.Method != null) button.Method(button.Title);
    onHide();
  };

  let AlertModalTitle = props.AlertModalTitle;
  let AlertModalMessage = props.AlertModalMessage;
  let onHide = props.onHide;
  let AlertModalButtons = props.AlertModalButtons;

  if (typeof props.attributes != "undefined") {
    AlertModalTitle = props.attributes.AlertModalTitle;
    AlertModalMessage = props.attributes.AlertModalMessage;
    onHide = props.attributes.onHide;
    AlertModalButtons = props.attributes.AlertModalButtons;
  }
  const closeModal = () => {
    onHide();
  };

  const onPlayerReady = (event) => {
    // access to player in all event handlers via event.target
    //event.target.seekTo(30, false);
    setTimeout(() => getTime(event.target), 9000);
  };
  const getTime = (player) => {
    //const playtime=player.getCurrentTime();
    player.pauseVideo();
  };

  const opts = {
    height: "390",
    width: "640",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
      start: 30,
    },
  };
  return (
    <div className="modal">
      <Modal
        {...props}
        animation={false}
        dialogClassName="myModalClass"
        aria-labelledby="contained-modal-title-vcenter"
        // style={{ padding: "50px" }}
        onHide={() => closeModal()}
      >
        <Modal.Body>
          {/* <YouTube videoId="63XqdEk8cb4" opts={opts} onReady={onPlayerReady} />; */}
          {/* <div>
            <span>Hello video!</span>
          </div> */}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default YouTubeDialog;
