export const about_html = `<!DOCTYPE html>
<html>
  <head>
    <meta http-equiv="content-type" content="text/html; charset=UTF-8">
    <title>about</title>
  </head>
  <body>
    <title>about</title>
    <p><b>Benefits</b></p>
    <ul>
      <li>Create Travel Content for free.</li>
      <li>Monetize your local and travel knowledge.</li>
      <li>Make money from affiliate marketing without a website.</li>
    </ul>
    <p><b>Highlights</b></p>
    <ul>
      <li>Search and view guides shared by others without registering or logging
        in.</li>
      <li>Login to create guides and keep them private.</li>
      <li>Share a link to your guide to anyone for viewing in a browser</li>
      <li>Make the guide viewable to a few or to all.</li>
      <li>Tell us how you want to get paid and get a commission whenever anyone
        makes a purchase while viewing your guide</li>
    </ul>
    <p><b>Example uses</b></p>
    <ul>
      <li>A travel blogger can provide a more interactive description of their
        travels.</li>
      <li>A travel blogger can extend their reach to mobile apps.</li>
      <li>A traveler can update the guide and turn it into a travel journal
        which they can keep as a memory and share it with others</li>
      <li>A travel agent can provide an interactive customized guide to their
        customers.</li>
      <li>A Hotel and vacation rental owners can provide a detailed guide to
        their guests with nearby restaurants, activities, etc., to make their
        stay more informed, efficient, and pleasant.</li>
      <li>Realtors can provide their customers with a detailed guide to the
        homes they will be visiting.</li>
      <li>A university can provide their students with a detailed guide to their
        campus.</li>
      <li>A community can provide their residents with a detailed guide to their
        community.</li>
      <li>A golf club can provide golfers with a detailed (hole by hole) guide
        to their golf course.</li>
      <li>An amusement park can provide customers with a detailed guide to the
        rides and activities.</li>
    </ul>
    <p><b>Features</b></p>
    <ul>
      <li>Create travel guides and journals for free on iOS, Android, browsers</li>
      <li>Make money from ticket sales commissions through your content</li>
      <li>Customize with HTML content, including places and events</li>
      <li>Interactive content: video, audio, images &amp; links</li>
      <li>Take photos and upload them into your guide from the app</li>
      <li>Track your current location on map</li>
      <li>Email links of your guide that are viewable on browsers</li>
      <li>Add locations (markers), paths (lines) and areas (polygons) on map</li>
      <li>Organize by: Categories and Groups</li>
      <li>Categorize by: Favorite, Visited, Must see, Rating, Hidden</li>
      <li>Filter: by Places, Groups, Categories, Favorite, Visited, Must see,
        etc.</li>
      <li>Sort: by Name, Category, Group, Rating &amp; Distance</li>
      <li>Search for places and add them to guide</li>
    </ul>
    <p>The Andiamo Places app, allows anyone to easily create interactive travel
      content, for free! You can also make money when you or other people
      purchase tickets while viewing your content. The two most common travel
      contents are; travel guides and travel journals. Travel guides document
      possible things to do and see in various places. Travel journals document
      a specific trip, and are a great way to remember what you did, and share
      it with others.
    </p>
    <p><b>Guest Content User<br>
      </b></p>
    <p>As a guest content user, you can view guides and travel journals for
      free, but you cannot create, copy, save or rate guides. Anyone can be a
      guest content user; there is no registration or login required, and you
      are not obligated to buy anything or forced to view ads. </p>
    <p><b>Logged In Content User</b><br>
    </p>
    <p>As a logged in Content user, in addition to viewing guides and journals,
      you can also create, copy, save or rate guides. </p>
    <p><b>Content Creator</b><b> (a.k.a. Original Content Creator)<br>
      </b></p>
    <p>A content creator, is anyone that creates a guide or a journal from
      scratch by selecting "New Guide". As a content creator, you can choose to;
      keep the content you create private, share it with a few people, or share
      it with everybody. You can share the content as read-only or allow others
      to copy it. Note that when content that you created is copied, you are
      still the original creator (but no longer the owner of that copy) and you
      continue to benefit (i.e. make money) from those copies if you choose to.
      Sharing it with copy privileges is therefore more advantageous to you,
      because you continue to make money on those copies and any copies made
      from those
copies.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
    <p><b>Content Owner (a.k.a. Secondary Content Creator)</b> </p>
    <p>A content owner is either a Content creator, or anyone that makes a copy
      of a guide made available by a content creator. When a content creator
      shares content and the user of the content creates a copy, they are then
      the owner of the copied content, but the Content creator is still
      remembered and never changes. As a content owner you are also a secondary
      content creator, i.e. you can customize or improve the original content.
      As the owner, you have all of the same privileges as the original guide
      creator (share, modify, delete etc.) on your copy of the guide.</p>
    <p><b>How the Payouts Work</b></p>
    <p>When anyone buys a ticket through the links provided by the app, the
      original content creator gets 70% of the commission for that purchase, and
      Andiamo Places gets 30%. The money will be paid monthly (but delayed by 2
      months) via bank transfer or paypal, as long as the minimum payout amount
      of $25 USD is reached. Make sure that you select the "Participate in
      Affiliate Program" option, and specify the payment method in user
      settings. </p>
    <p><b>Note that the commission feature is temporarily not available on iOS,
        but it will be available in the next version (April 2022)</b></p>
    <p> </p>
    <p><b>Ad Free</b></p>
    <p>The app is ad-free. There is no plan to add ads, but that could change if
      very few people willingly purchase tickets through the app by navigating
      to the "Tickets" screen.&nbsp; Note that, because of the flexibility of
      the app, a content creator or owner, could insert ads into their content.
      That is not allowed and could result in the guide being removed or the
      guide owner loosing their account.</p>
    <p><b>In Italian, "Andiamo" means "Let's go", so the app name translates to
        "Let's Go Places!</b></p>
    <p> We also refer to "Andiamo Places" as the "Andiamo Travel App" because
      one of the best suited applications is for creating travel guides and
      travel journals, however, Andiamo Places can also be used for creating any
      "document" where places, maps and/or schedules are an essential part of
      the information.&nbsp;&nbsp;&nbsp; </p>
    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </p>
    <p></p>
  </body>
</html>`;
