import React, { useState, Component } from "react";
import { Modal, ToggleButtonGroup, ToggleButton, ButtonGroup, Button, Form, Spinner } from "react-bootstrap";
import "./Modal.css";
import MultiSelectModal from "./MultiSelectModal";
import MenuModal from "./MenuModal";

import AlertModal from "./AlertModal";
import PromptModal from "./PromptModal";

// import firebase from "firebase/app";
// import "firebase/auth";
// import "firebase/database";
// import "firebase/firestore";
// import "firebase/functions";
// import "firebase/storage";

import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/database";
import "firebase/compat/firestore";
import "firebase/compat/functions";
import "firebase/compat/storage";

import Common, { Settings, SortByEnum, COLORS, AUTHORIZATION_LEVEL } from "../../Common/Common";
import DataBase from "../../dataBase/liquidGuides";
import { DateTime } from "luxon";
import Place from "../../Models/Place";
import Compressor from "compressorjs";
import FolderImage from "../../assets/images/folder-icon.jpg";
import FolderBackImage from "../../assets/images/folder-back-icon.jpg";
import ReactLoading from "react-loading";
import exifr from "exifr";
import Flickr from "../../Models/Flickr";
import WikiMedia from "../../Models/Wikimedia";
import GoogleSearch from "../../Models/GoogleSearch";

class ImageDialog extends React.Component {
  constructor(props) {
    super(props);
    const multi = this.props.multi === "multi";
    let initialFileName = this.props.name;
    let initialurlText = this.props.photo;
    let initialurlTextMedium = this.props.photo_m;
    if (typeof initialurlText === "undefined") initialurlText = "";
    if (typeof initialurlTextMedium === "undefined") initialurlTextMedium = "";
    let mode = this.props.mode;
    let initialSource = "Flickr";
    let initialDeviceOrSearch = "Device";
    if (mode === "upload") {
      initialSource = "Saved";
      initialDeviceOrSearch = "Search";
    } else {
      initialDeviceOrSearch = this.props.ImageDialogDeviceOther;
      initialSource = this.props.ImageDialogOtherSource;
    }
    let searchMediaString = "";
    if (typeof this.props.place !== "undefined") searchMediaString = this.props.place.Name;
    // override for now
    initialSource = "Saved";
    //console.log("mode, initialSource, initialDeviceOrSearch", mode, initialSource, initialDeviceOrSearch);
    this.state = {
      fileName: initialFileName,
      fileNameEntered: "",
      folderName: "",
      //fileSize: fileSize,
      url: null,
      fileList: [],
      visiblePrompt: false,
      searchText: "",
      Flickr: true,
      flickrFileList: [],
      wikimediaFileList: [],
      googleFileList: [],
      cloudFileList: [],
      cloudFileListAll: [],
      lastSearchArea: null,
      DeviceOrSearch: initialDeviceOrSearch,
      imageSource: initialSource,
      urlText: initialurlText,
      urlTextMedium: initialurlTextMedium,
      multi: multi,
      logging: "",
      mode: mode,
      ready: false,
      edit_mode: "Image Name", //Folder Name
      visiblePrompt: false,

      MenuModalShow: false,
      MenuModalList: [],
      MenuModalHeading: "",
      MenuModalImage: null,
      MenuModalPlace: null,
      defaultName: "",
      newName: "",
      folderFilter: "", //this.props.UploadFolder,

      AlertModalShow: false,
      AlertModalTitle: "",
      AlertModalMessage: "",
      AlertModalButtons: [],

      PromptModalShow: false,
      PromptModalTitle: "Enter Value",
      PromptModalMessage: "",
      PromptModalButtons: [],
      PromptModalInputs: [],

      MultiselectItems: [],
      MultiselectSelectedItems: [],
      MultiselectMode: "uni",
      MultiselectCallback: null,
      MultiselectTitle: "Select",
      MultiselectSearchText: "",
      ShowMultiselect: false,
      URLEnteredMedium: initialurlTextMedium,
      URLEntered: initialurlText,
      filesSelected: [],
      fileListFiltered: [],
      ScreenStatus: "",
      folderList: [],
      isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
      searchMediaString: searchMediaString,
    };
    this.handleChangefileName = this.handleChangefileName.bind(this);
    this.deleteFileMedium = this.deleteFileMedium.bind(this);
  }

  componentDidMount() {
    window.addEventListener("load", this.handleLoad);
    this.getUserImages();
  }

  handleLoad = () => {
    console.log("handleLoad");
    this.getUserImages();
  };

  getUserImages = () => {
    this.setState({ activityIndicator: true, filesSelected: [], ScreenStatus: `Get user images` });
    DataBase.getUserImages(this.props.user_info.uid, this.getFileMetadataCallback);
  };

  getFileMetadataCallback = (res) => {
    this.setState({ activityIndicator: true });
    //setTimeout(this.timeoutFunc, 25000);
    this.setState({ cloudFileList: [] });
    if (res.pass === false) {
      this.setState({ activityIndicator: false });
      console.log("Error reading images");
      return;
    }
    let fileListAll = [];
    let folderList = [];
    res.res.forEach((image) => {
      const meta = {
        name: image.data.name,
        folder: image.data.folder,
        id: image.id,
        url: image.data.url,
        url_m: image.data.url_m,
        date: image.data.date,
        exif_data: typeof image.data.exif_data === "undefined" ? {} : image.data.exif_data,
        type: 0,
      };
      if (image.data.url === "") {
        meta.type = 1;
        folderList.push(meta);
      } else fileListAll.push(meta);
    });

    const folders = this.getFolderNames(fileListAll, folderList);
    console.log("folderFilter", folders, this.state.folderFilter);
    if (!folders.includes(this.state.folderFilter)) {
      if (folders.length > 0) this.setState({ folderFilter: folders[0] });
      else this.setState({ folderFilter: "" });
    }
    console.log("folderFilter2", this.state.folderFilter);

    this.setState({ cloudFileListAll: fileListAll, folderList: folderList });
    this.FilterByFolder(fileListAll, this.state.ShowOnlyWithGPS, this);
    // //fileListAll.sort((a, b) => a.name.toUpperCase() > b.name.toUpperCase());
    // fileListAll.sort((a, b) => {
    //   if (a.name.toUpperCase() < b.name.toUpperCase()) return -1;
    //   return a.name.toUpperCase() > b.name.toUpperCase() ? 1 : 0;
    // });
    // //console.log("fileListAll", fileListAll);
    // this.setState({ cloudFileListAll: fileListAll });
    // //if (this.state.folderFilter != "") {
    // //}
    // // let _folderFilter = this.state.folderFilter;
    // let fileListFiltered = fileListAll.filter((file) => file.folder === this.state.folderFilter);
    // // Code to not show empty root directory
    // // if (fileListFiltered.length === 0) {
    // //   let folders = this.getFolderNames(fileListAll, this.state.folderList);
    // //   if (folders.length !== 0) fileListFiltered = fileListAll.filter((file) => file.folder === folders[0]);
    // //   this.setState({ folderFilter: folders[0] });
    // // }
    // setTimeout(() => {
    //   this.setState({ activityIndicator: false });
    // }, 1000);

    // this.setState({ cloudFileList: fileListFiltered, ready: true });
  };

  FilterByFolder = (_fileListAll, loc_flag, _this) => {
    let locFiltered = [];
    let fileListAll = _fileListAll;
    if (loc_flag) {
      for (let i = 0; i < _fileListAll.length; i++) {
        if (typeof _fileListAll[i].exif_data !== "undefined" && typeof _fileListAll[i].exif_data.date !== "undefined")
          locFiltered.push(_fileListAll[i]);
      }
      fileListAll = locFiltered;
    }
    let fileListFiltered = fileListAll.filter((file) => file.folder == _this.state.folderFilter);
    // if (fileListFiltered.length === 0) {
    //   let folders = _this.getFolderNames(fileListAll, this.state.folderList);
    //   if (folders.length !== 0) {
    //     fileListFiltered = fileListAll.filter((file) => file.folder == folders[0]);
    //     _this.setState({ folderFilter: folders[0] });
    //   }
    // }
    _this.setState({ cloudFileList: fileListFiltered, ready: true, activityIndicator: false, ScreenStatus: "" });
    //_this.props.navigation.setParams({ any_displayed: fileListFiltered.length > 0 });
  };

  getFolderNames = (files, folderList) => {
    if (folderList === null) folderList = this.state.folderList;
    let folders = [];
    files.forEach((file) => {
      folders.push(file.folder);
    });

    if (!this.state.ShowOnlyWithGPS) {
      folderList.forEach((folder) => {
        folders.push(folder.folder);
      });
    }
    //folders.push("/");
    const uniqueFolders = Array.from(new Set(folders)).sort();
    return uniqueFolders;
  };

  addFolderNames = (files) => {
    let folders = this.getFolderNames(this.state.cloudFileListAll, this.state.folderList);
    let folderFiles = [];
    folders.forEach((folder) => {
      if (this.state.folderFilter !== folder)
        folderFiles.push({
          name: folder,
          folder: folder,
          id: "",
          url: "",
          url_m: "",
          date: "",
          type: 1,
        });
    });
    files = folderFiles.concat(files);
    return files;
  };

  timeoutFunc = () => {
    this.setState({ activityIndicator: false });
    this.setState({ ready: true });
  };

  handleChangefileName = (newText) => {
    if (newText != "") {
      var re = /^[a-zA-Z0-9 +(),-]+$/;
      //console.log(re.test(newText));
      if (!re.test(newText)) return;
    }
    this.setState({ fileName: newText });
  };

  handleChangeURL = (newText) => {
    this.setState({ ...this.state, urlText: newText });
  };

  handleChangeURLMedium = (newText) => {
    this.setState({ ...this.state, urlTextMedium: newText });
  };

  isValid = (name) => {
    var rg1 = /^[^\\/:\*\?"<>\|]+$/; // forbidden characters \ / : * ? " < > |
    var rg2 = /^\./; // cannot start with dot (.)
    var rg3 = /^(nul|prn|con|lpt[0-9]|com[0-9])(\.|$)/i; // forbidden file names
    return rg1.test(name) && !rg2.test(name) && !rg3.test(name);
    // return function isValid(fname) {
    //   return rg1.test(fname) && !rg2.test(fname) && !rg3.test(fname);
    // };
  };

  fileExists = (name) => {
    let _fileList = this.state.fileList;
    const index = _fileList.findIndex((file) => file.name === name);
    return index > -1 ? true : false;
  };

  processImageFiles = (fileList) => {
    //setTimeout(this.timeoutFunc, 25000);
    let _filelist = [];
    const path = this.folderPath();
    fileList.forEach((file) => {
      this.setState({ activityIndicator: true });
      let meta_data = {
        date: DateTime.now().toISO({ zone: "utc" }),
        folder: "",
        name: file.name,
        url_m: file.url_m,
        url: file.url,
        user_id: this.props.user_info.uid,
      };
      _filelist.push(meta_data);
      DataBase.addImage(meta_data, this.addImageCallback);
      //console.log(file.name);
    });
  };

  addImageCallback = (res) => {
    //this.setState({ activityIndicator: false });
    //console.log(res);
  };

  getUserImagesCallback = (res) => {
    if (res.pass === false) return;
  };

  MyAlert = (title, message, buttons) => {
    this.setState({
      AlertModalTitle: title,
      AlertModalMessage: message,
      AlertModalButtons: buttons,
      AlertModalShow: true,
    });
  };

  getFileList = async () => {
    // Create a reference under which you want to list
    if (this.props.user_info.uid === "guest") {
      this.setState({ cloudFileList: [] });
      return;
    }
    let file_path = Common.getFBPath(this.props.guide_id, this.props.user_info, `fb_images`);
    if (file_path == null) this.MyAlert("Message", "Make sure that you are logged in", [{ Title: "OK", Method: null }]);

    //console.log(file_path);
    //var listRef = storageRef.child(file_path);
    let _fileList = [];
    let _fileList_m = [];
    var storageRef = firebase.storage().ref(file_path);

    storageRef.listAll().then((res) => {
      // for folders
      res.prefixes.forEach((folder) => {
        //console.log(folder);
      });
      // for files like images
      res.items.forEach((item) => {
        //console.log(item.name);
        if (!item.name.includes("_m"))
          _fileList.push({
            name: item.name,
            url: null,
            url_m: null,
          });
      });
      _fileList.forEach((file) => {
        const urls = this.getThumbnailUrl(_fileList, file.name, true);
        file.url = urls.url;
        file.url_m = urls.url_m;
      });
    });
  };

  getFilesCallback = (fileList) => {
    //console.log("fileList", fileList);
    let _fileList = [];
    fileList.forEach((file) => {
      _fileList.push({
        name: file,
        url: null,
      });
    });
    _fileList.forEach((file) => {
      this.getThumbnailUrl(_fileList, _fileList[0].name);
    });
    //_fileList.sort((a, b) => a.name.toUpperCase() > b.name.toUpperCase());
    _fileList.sort((a, b) => {
      if (a.name.toUpperCase() < b.name.toUpperCase()) return -1;
      return a.name.toUpperCase() > b.name.toUpperCase() ? 1 : 0;
    });
    //this.setState({ fileList: fileList });
    this.setState({ cloudFileList: fileList });
    //console.log("_fileList", fileList);
  };

  onChooseCameraPressInstance = () => {
    this.onChooseCameraPress(this);
  };

  onChooseCameraPress = async () => {};

  onChooseImagePressInstance = () => {
    this.onChooseImagePress(this);
  };

  onChooseImagePress = async () => {
    if (this.props.Authorization === AUTHORIZATION_LEVEL.GUEST) {
      this.MyAlert("In GUEST mode", "Must be logged in.", [{ Title: "OK", Method: null }]);
      return;
    }
  };

  _image_file_name = "";
  _image_file_name_m = "";

  processImage = async (uri_t, uri_m, exif_data, image_name, file_num, file_total, refresh) => {
    let folder_path = this.folderPath();
    if (folder_path == null) {
      this.MyAlert("Message", "Make sure that you are logged in", [{ Title: "OK", Method: null }]);
      return;
    }
    this.setState({ activityIndicator: true });
    //setTimeout(this.timeoutFunc, 25000);
    const name_t = DateTime.now().toISO({ zone: "utc" }).toString();
    this._image_file_name = name_t;
    var ref_t = firebase.storage().ref().child(`${folder_path}${name_t}`);
    await this.uploadImage(uri_t, folder_path, name_t, "")
      .then(() => {
        const name_m = DateTime.now().toISO({ zone: "utc" }).toString() + "_m";
        this._image_file_name_m = name_m;
        var ref_m = firebase.storage().ref().child(`${folder_path}${name_m}`);
        this.uploadImage(uri_m, folder_path, name_m, "")
          .then(() => {
            this.addMetaData(
              {
                date: DateTime.now().toISO({ zone: "utc" }),
                folder: this.state.folderFilter,
                name: image_name,
                url_m: null,
                url: null,
                user_id: this.props.user_info.uid,
                exif_data: typeof exif_data === "undefined" ? {} : exif_data,
              },
              ref_t,
              ref_m,
              refresh
            );
            const mode = this.props.mode;
            console.log("mode", mode);
            // if (mode != "upload")
            //   setTimeout(() => {
            //     this.AutoSelectDeviceImages();
            //   }, 1000);
            //else this.setState({ activityIndicator: false });
          })
          .catch((error) => {
            this.MyAlert("Error", error.message, [{ Title: "OK", Method: null }]);
            this.setState({ activityIndicator: false });
          });
      })
      .catch((error) => {
        this.MyAlert("Error", error.message, [{ Title: "OK", Method: null }]);
        this.setState({ activityIndicator: false });
      });
    this.setState({ ScreenStatus: `Uploaded: ${file_num} of ${file_total}` });
    // }
  };

  addMetaData = async (meta, ref, ref_m, refresh) => {
    //console.log(meta);
    const url = await ref.getDownloadURL();
    const url_m = await ref_m.getDownloadURL();
    meta.url = url;
    meta.url_m = url_m;
    setTimeout(() => {
      this.writeMetaToDB(meta, refresh);
    }, 1000);
  };

  writeMetaToDB = async (meta, refresh) => {
    //console.log(meta);
    await DataBase.addImage(meta, this.addImageCallback);
    const mode = this.props.mode;
    if (mode !== "upload") this.AutoSelectDeviceImages();
    else {
      if (refresh) this.getUserImages();
    }
  };

  AutoSelectDeviceImages = async () => {
    this.setState({ activityIndicator: true });
    //setTimeout(this.timeoutFunc, 25000);
    //console.log("Before getImageUrl");
    //const url = this.getImageUrl(folder_path, this.state.fileName, "", null);
    let ref = firebase.storage().ref(`${this.folderPath()}${this._image_file_name}`);
    const url = await ref.getDownloadURL();

    //console.log("url", url);
    //const url_m = this.getImageUrl(folder_path, this.state.fileName, "_m", null);

    ref = firebase.storage().ref(`${this.folderPath()}${this._image_file_name_m}`);
    const url_m = await ref.getDownloadURL();

    const selectedItem = { name: this.state.fileName, url: url, url_m: url_m };
    //console.log(selectedItem);
    const updateImage = this.props.updateImage; //this.props.navigation.getParam("updateImage", null);
    //console.log("AutoSelectDeviceImages selectedItem", selectedItem);
    if (updateImage != null) updateImage([selectedItem]);
    this.props.onHide();
    this.setState({ activityIndicator: false });
  };

  imageSaveCallback = (url) => {
    this.setState({ url: url });
    //console.log("imageSaveCallback", url);
  };

  uploadImage = async (uri, file_path, file_name, file_size) => {
    console.log("uploadImage", file_path, file_name, file_size);
    //const response = await fetch(uri);
    //const blob = await response.blob();
    var ref = firebase.storage().ref().child(`${file_path}${file_name}${file_size}`);
    return ref.put(uri);
    // ref.put(blob).then((snapshot) => {
    //   return snapshot.ref.getDownloadURL();
    // });
  };

  getImageUrl = async (folder_path, file_name, file_size, callback) => {
    //console.log("getImageUrl", folder_path, file_name, file_size);
    const ref = firebase.storage().ref(`${folder_path}${file_name}${file_size}`);
    const url = await ref.getDownloadURL();
    //console.log("getImageUrl", url);
    if (callback != null) callback(url);
    else return url;
  };

  getThumbnailUrl = async (files, file_name, also_m) => {
    //console.log("getThumbnailUrl", `${file_name}`);
    let url = null;
    let url_m = null;
    const ref = firebase.storage().ref(`${this.folderPath()}${file_name}`);
    url = await ref.getDownloadURL();
    const file = files.find((x) => x.name === file_name);
    if (file != null) file.url = url;
    if (also_m) {
      const ref_m = firebase.storage().ref(`${this.folderPath()}${file_name}_m`);
      url_m = await ref_m.getDownloadURL();
      //const file_m = files.find((x) => x.name === file_name);
      if (file != null) file.url_m = url_m;
    }
    this.setState({ cloudFileList: files });
    return { url: url, url_m: url_m };
  };

  folderPath = () => {
    let folder_path = Common.getFBPath(this.props.guide_id, this.props.user_info, "fb_images");
    if (folder_path == null) {
      //Alert.alert("Make sure that you are logged in");
      return null;
    }
    return folder_path;
  };

  imageFilePath = (file_name, size) => {
    let file_path = this.folderPath();
    if (file_path == null) {
      //Alert.alert("Make sure that you are logged in");
      return null;
    }
    file_path = `${file_path}${file_name}${size}`;
    return file_path;
  };

  // displayImageList = () => {
  //   return this.state.fileList.map((file_name) => {
  //     return (
  //       <View style={{ margin: 10 }}>
  //         <Image
  //           key={file_name}
  //           style={styles.image_t}
  //           source={{
  //             uri:
  //               "https://firebasestorage.googleapis.com/v0/b/liquidguides-2ce60.appspot.com/o/E2Fqqu9FWDgTdpOzdKQOKEdo6wA3%2Ffr9B6WP0Z9IXcrA3m2G9%2Ffb_images%2FBbbbb?alt=media&token=ff47155f-3b6d-4920-b356-07f4fffb115a",
  //           }}
  //         />
  //       </View>
  //     );
  //   });
  // };

  _selected_file = null;

  onDeleteFile = (event) => {
    this.MyAlert("Confirmation", "Confirm Delete Image?", [
      {
        Title: "OK",
        Method: () => this.deleteFiles(this.state.filesSelected),
      },
      { Title: "CANCEL", Method: null },
    ]);
  };

  deleteFiles = async (filesToDelete) => {
    this.setState({ ScreenStatus: `Deleting: ${filesToDelete.length} images` });
    for (let i = 0; i < filesToDelete.length; i++) {
      await this.deleteFile(
        filesToDelete[i],
        this.deleteFileMedium,
        this.deleteFileMediumCallback,
        i === filesToDelete.length - 1 ? this.cleanup : null
      );
      this.setState({ ScreenStatus: `Deleted: ${i + 1} of ${filesToDelete.length}` });
    }
    // }
    // this.deleteFile(this._selected_file, this.deleteFileMedium, this.deleteFileMediumCallback, this.cleanup);
  };

  deleteFile = async (file, _deleteFileMedium, deleteFileMediumCallback, cleanup) => {
    this.setState({ activityIndicator: true });
    //setTimeout(this.timeoutFunc, 25000);
    //console.log("deleteFile", event);
    const storageRef = firebase.storage().ref();
    var desertRef = firebase.storage().refFromURL(file.url);
    await desertRef
      .delete()
      .then(function () {
        _deleteFileMedium(file, deleteFileMediumCallback, cleanup);
      })
      .catch(function (error) {
        //this.setState({ activityIndicator: false });
        console.log("Error deleting thumbnail image", error.message);
        _deleteFileMedium(file, deleteFileMediumCallback, cleanup);
        //this.MyAlert("Error", error.message, [{ Title: "OK", Method: null }]);
      });
  };

  deleteFileMedium = async (file, deleteFileMediumCallback, cleanup) => {
    this.setState({ activityIndicator: true });
    const storageRef = firebase.storage().ref();
    var desertRef = firebase.storage().refFromURL(file.url_m);
    await desertRef
      .delete()
      .then(function () {
        deleteFileMediumCallback(file, cleanup);
      })
      .catch(function (error) {
        //this.setState({ activityIndicator: false });
        console.log("Error deleting medium image", error.message);
        deleteFileMediumCallback(file, cleanup);
        //this.MyAlert("Error", error.message, [{ Title: "OK", Method: null }]);
      });
  };

  // delete image metadata
  deleteFileMediumCallback = async (file, cleanup) => {
    await DataBase.deleteImage(file.id, cleanup);
  };

  cleanup = () => {
    console.log("***cleanup***");
    setTimeout(this.getUserImages, 300);
    //this.getUserImages();
  };

  renameFolder = () => {
    this.setState({
      edit_mode: "Folder Name",
      //PromptModalShow: true,
      PromptModalTitle: "Enter Value",
      PromptModalMessage: "Enter Folder Name",
      PromptModalButtons: [
        { Title: "OK", Method: this.renameFolderCallback },
        { Title: "CANCEL", Method: null },
      ],
      PromptModalInputs: [{ name: "Folder Name", value: this.state.filesSelected[0].folder }],
    });
    setTimeout(() => this.setState({ PromptModalShow: true }), 100);
  };

  renameFolderCallback = async (inputs) => {
    if (inputs === null) return;
    // if (this.state.filesSelected.length > 0) {
    //this.state.filesSelected.forEach((file) => {
    for (let i = 0; i < this.state.filesSelected.length; i++) {
      await this.changeAttributes(
        inputs[0].name,
        inputs[0].value,
        this.state.filesSelected[i],
        i === this.state.filesSelected.length - 1 ? this.cleanup : null,
        i + 1,
        this.state.filesSelected.length
      );
    }
    return;
    // }
    // this.changeAttributes(inputs[0].name, inputs[0].value, this._selected_file, this.cleanup);
  };

  changeAttributes = async (edit_mode, newName, meta, cleanup, file_num, total_files) => {
    // if (edit_mode === "Flickr Search") {
    //   this.searchFlickrImages(newName);
    //   this.setState({ visiblePrompt: false });
    //   return;
    // }
    const id = meta.id;
    delete meta.id;
    if (edit_mode === "Image Name") {
      meta.name = newName;
    } else if (edit_mode === "Folder Name") {
      meta.folder = newName;
    }
    await DataBase.updateImage(id, meta, cleanup);
    this.setState({ ScreenStatus: `Renaming: ${file_num} of ${total_files}` });
  };

  updateFileCallback = (res) => {
    if (res.pass === false) console.log("Error modifying image", res.error.message);
    this.getUserImages();
  };

  nameChanged = (text) => {
    this.setState({ newName: text });
  };

  // _sequentially = false;

  // renameImagesSequentially = () => {
  //   this._sequentially = true;
  //   this._renameImage(true);
  // }

  // renameImage = () => {
  //   this._renameImage(false);
  // }

  renameImage = () => {
    //this._sequentially = sequentially;
    if (this.state.filesSelected.length === 0) return;
    this.setState({
      edit_mode: "Image Name",
      //PromptModalShow: true,
      PromptModalTitle: "Enter Value",
      PromptModalMessage: "Enter Image Name",
      PromptModalButtons: [
        { Title: "OK", Method: this.renameImageCallback },
        { Title: "CANCEL", Method: null },
      ],
      PromptModalInputs: [{ name: "Image Name", value: this.state.filesSelected[0].name }],
    });
    setTimeout(() => this.setState({ PromptModalShow: true }), 100);
  };

  renameImageCallback = async (inputs) => {
    if (inputs === null) return;
    //if (this.state.filesSelected.length > 0) {
    let selected_files = this.state.filesSelected;
    try {
      selected_files = selected_files.sort((a, b) => {
        const at = DateTime.fromISO(a.exif_data.date);
        const bt = DateTime.fromISO(b.exif_data.date);
        return bt - at;
        //return at > bt ? -1 : at < bt ? 0 : 1;
      });
    } catch (e) {
      //console.log("Error:", e);
    }
    //console.log(selected_files.date);
    for (let i = 0; i < selected_files.length; i++) {
      let new_name = inputs[0].value;
      if (selected_files.length > 1 && this.state.edit_mode === "Image Name")
        new_name = `${new_name}${selected_files.length - i}`;
      await this.changeAttributes(
        inputs[0].name,
        new_name,
        selected_files[i],
        i === selected_files.length - 1 ? this.cleanup : null,
        i + 1,
        this.state.filesSelected.length
      );
    }
    // }
    // this.changeAttributes(inputs[0].name, inputs[0].value, this._selected_file, this.cleanup);
  };

  moveImageToFolder = () => {
    this.selectFolderMove();
  };

  handleShowImage = () => {
    const selected_file = this.state.filesSelected[0];
    const htmlString = Place.createImageHTML(selected_file.url_m, null, "medium");
    //this.props.navigation.navigate("HTMLViewer", { html: html, title: "Image", last_html: "" });
    let win = window.open("about:blank", "", "_blank");
    win.document.write(htmlString);
    win.focus();
  };

  handleShowImageInfo = async () => {
    const selected_file = this.state.filesSelected[0];
    // const [width_m, height_m] = await this.getImageSize(selected_file.url_m);
    // const [width_t, height_t] = await this.getImageSize(selected_file.url);

    let exif_string = "";
    if (typeof selected_file.exif_data !== "undefined") {
      if (typeof selected_file.exif_data.date !== "undefined") exif_string = `Name: ${selected_file.name}`;
      //exif_string += `, Created: ${this.displayDateTime(selected_file.exif_data.date)}`;
      exif_string += `, Created: ${DateTime.fromISO(selected_file.exif_data.date).toFormat("FF")}`;
      if (typeof selected_file.exif_data.latitude !== "undefined")
        exif_string += `, Location: ${selected_file.exif_data.latitude.toFixed(
          6
        )},${selected_file.exif_data.longitude.toFixed(6)}\n`;
      // exif_string += `, Size Image: width: ${width_m}, height: ${height_m}`;
      // exif_string += `, Size Thumbnail: width: ${width_t}, height: ${height_t}`;
    }
    if (exif_string === "") exif_string = "No exif data";

    //if (exif === "{}") exif = "No exif data";
    //console.log("exif_string: ", exif_string);
    this.MyAlert("Image Info", exif_string, [{ Title: "OK", Method: null }]);
  };

  displayDateTime = (d) => {
    // //2022:08:08 16:43:05
    // //new Date(2021, 2, 3, 10, 30, 50, 800);
    // const dd = d.replace(/:/g, ",").replace(" ", ",") + ",000";
    // const ddp = dd.split(",");
    // console.log("dd", dd);
    // var t = new Date(ddp[0], ddp[1], ddp[2], ddp[3], ddp[4], ddp[5], ddp[6]);
    // //var t = DateTime.fromISO(d);
    // // var datestring =
    // //   t.getDate() + "-" + (t.getMonth() + 1) + "-" + t.getFullYear() + " " + t.getHours() + ":" + t.getMinutes();

    //var datestring = t.toString();
    const formatted = `${Common.formatDate(d)} ${Common.formatTime(d)}`;
    return formatted;
  };
  _to_from = "";

  selectFolderAdd = () => {
    this._to_from = "add";
    this.selectFolder();
  };

  selectFolderMove = () => {
    this._to_from = "move";
    this.selectFolder();
  };

  SelectFolderView = () => {
    this._to_from = "view";
    this.selectFolder();
  };

  SelectFolderRename = () => {
    this._to_from = "rename";
    this.setState({
      edit_mode: "Folder Rename",
      visiblePrompt: true,
      defaultName: this.state.folderFilter,
      newName: "",
    });
  };

  selectFolder = () => {
    //console.log("this.state.cloudFileList", this.state.cloudFileList);
    let selected = "";
    if (this._to_from === "move") selected = this.state.filesSelected[0];
    else if (this._to_from === "view") selected = this.state.folderFilter;
    let _folders = [];
    const folders_sorted = this.getFolderNames(this.state.cloudFileListAll, this.state.folderList);
    let id = 1;
    folders_sorted.forEach((folder) =>
      _folders.push({
        label: folder,
        name: folder,
        value: folder,
        id: (id++).toString(),
      })
    );
    //console.log("selectFolder", _folders);
    this.MultiSelect(
      _folders,
      [selected], //[{ name: selected, label: selected, value: selected, id: "9999999" }],
      "uni",
      this.selectFolderCallBack,
      "Select folder"
    );
  };

  selectFolderCallBack = async (folders) => {
    if (folders.length < 1) return;

    if (this._to_from === "move") {
      let selected = this.state.filesSelected;
      if (selected.length === 0) selected.push(this._selected_file);
      // this.state.filesSelected.forEach((file) => {
      for (let ix = 0; ix < this.state.filesSelected.length; ix++) {
        const file = this.state.filesSelected[ix];
        if (folders[0].label === file.folder) continue;
        file.folder = folders[0].label;

        let meta = file;
        const id = meta.id;
        delete meta.id;

        meta.folder = folders[0].label;
        await DataBase.updateImage(id, meta, ix === this.state.filesSelected.length ? this.updateFileCallback : null);
        this.setState({ ScreenStatus: `Moved: ${ix + 1} of ${this.state.filesSelected.length}` });
      }
    } else if (this._to_from === "view") {
      this.setState({ folderFilter: folders[0].label });
    }
    this.getUserImages();
  };

  onEditMetadata = (item) => {
    if (item !== null) {
      if (item.type !== 0) {
        this.setState({ folderFilter: item.folder });
        this.getUserImages();
        return;
      }
      //console.log("onEditMetadata");
      this._selected_file = item;
      if (this.props.mode === "upload" && this.props.multi === true) {
        this.selectFile(item);
        return;
      }
      this.createImageMenu();
    }
    if (this.props.mode === "upload" && this.props.multi === false) {
      this.createImageMenu();
      return;
    }
  };

  createImageMenu = () => {
    //console.log("createImageMenu");
    if (this.props.multi === true && this.state.filesSelected.length === 0) {
      this.MyAlert("No Selection", "Select at least one image", [{ Title: "OK", Method: null }]);
      return;
    }
    let menuList = [
      // {
      //   label: this.state.filesSelected.length > 1 ? "Select Images" : "Select Image",
      //   method: this.returnSelectedFiles,
      //   icon: null,
      //   visible: this.state.filesSelected.length > 0 && this.props.mode === "select",
      // },
      {
        label: this.state.filesSelected.length > 1 ? "Delete Selected Images" : "Delete Image",
        method: this.onDeleteFile,
        icon: null,
        visible: this.state.filesSelected.length > 0 && this.props.mode === "upload",
      },
      {
        label: "Rename Image",
        method: this.renameImage,
        icon: null,
        visible: this.state.filesSelected.length === 1 && this.props.mode === "upload",
      },
      {
        label: "Rename Images Sequentially",
        method: this.renameImage,
        icon: null,
        visible: this.state.filesSelected.length > 1 && this.props.mode === "upload",
      },
      {
        label: this.state.filesSelected.length > 1 ? "Rename Folder of selected" : "Rename Folder",
        method: this.renameFolder,
        icon: null,
        visible: this.props.mode === "upload",
      },
      {
        label: this.state.filesSelected.length > 1 ? "Move selected to Folder" : "Move to Folder",
        method: this.moveImageToFolder,
        icon: null,
        visible: this.props.mode === "upload",
      },
      {
        label: "Display Image",
        method: this.handleShowImage,
        icon: null,
        visible: this.state.filesSelected.length === 1,
      },
      {
        label: "Display Image Info",
        method: this.handleShowImageInfo,
        icon: null,
        visible: this.state.filesSelected.length === 1,
      },
    ];
    this.setState({
      MenuModalShow: true,
      MenuModalList: menuList,
      MenuModalHeading: this.state.filesSelected.length > 1 ? "Images Menu" : "Image Menu",
      MenuModalImage: null,
      MenuModalPlace: null,
    });
  };

  returnSelectedFiles = () => {
    const updateImage = this.props.updateImage;
    if (updateImage !== null) updateImage(this.state.filesSelected);
    this.props.onHide();
  };

  getSelectedFile = () => {
    return this.state.filesSelected[0];
  };

  createMainMenu = () => {
    //console.log("createMainMenu");
    let menuList = [
      { label: "Select Folder", method: this.SelectFolderView, icon: null, visible: true },
      // {
      //   label: "Rename Folder",
      //   method: this.SelectFolderRename,
      //   icon: null,
      //   visible: true,
      // },
    ];
    this.setState({
      MenuModalShow: true,
      MenuModalList: menuList,
      MenuModalHeading: "Main Menu",
      MenuModalImage: null,
      MenuModalPlace: null,
    });
  };

  toggleModalMenu = () => {
    this.setState({ MenuModalShow: false });
  };

  _selectedItem = "";

  selectFile = (item) => {
    this._selectedItem = item;
    const updateImage = this.props.updateImage;
    const mode = this.props.mode;
    if (this._selectedItem.type !== 0 && this.state.imageSource === "Save") {
      this.setState({ folderFilter: this._selectedItem.folder });
      this.getUserImages();
      return;
    }
    if (mode === "upload" && this.props.multi === false) return;

    if (this.props.multi) {
      this.imageSelection(item);
      return;
    }
    const size = this.props.size;
    if (this.state.imageSource === "Guide") {
      if (updateImage != null) updateImage([this._selectedItem]);
      this.props.onHide();
      return;
    }
    if (this.state.imageSource === "Flickr") {
      if (updateImage != null) updateImage([this._selectedItem]);
      this.props.onHide();
      return;
    }
    if (updateImage === null) {
      this.props.onHide();
      return;
    }

    if (this.state.filesSelected.length > 0) this._selectedItem = this.state.filesSelected[0];
    if (updateImage !== null) updateImage([this._selectedItem]);
    this.props.onHide();
    return;
  };

  getImageURLCallback = (url) => {
    const updateImage = this.props.updateImage;
    this._selectedItem.url_m = url;
    if (updateImage != null) updateImage([this._selectedItem]);
    this.props.onHide();
  };

  selectURL = () => {
    if (this.state.multi === true) {
      if (this.state.urlText === "" || this.state.urlTextMedium === "") {
        this.MyAlert("Message", "Must supply 2 URLs", [{ Title: "OK", Method: null }]);
        return;
      }
    }
    let url = this.state.urlText;
    let url_m = this.state.urlTextMedium;
    if (url.includes("https://drive.google.com")) {
      const url_parts = url.split("/");
      url = `https://docs.google.com/uc?export=download&id=${url_parts[5]}`;
    }
    if (url_m.includes("https://drive.google.com")) {
      const url_parts = url_m.split("/");
      url_m = `https://docs.google.com/uc?export=download&id=${url_parts[5]}`;
    }
    const updateImage = this.props.updateImage; //this.props.navigation.getParam("updateImage", null);
    const image_info = { url: url, url_m: url_m };
    if (updateImage != null) updateImage([image_info]);
    this.props.onHide();
  };

  searchFlickr = () => {
    console.log("searchFlickr");
    this.setState({
      edit_mode: "Flickr Search",
      visiblePrompt: true,
      defaultName: this.state.fileName,
      newName: this.state.fileName,
    });
  };
  handleSearchTextChanged = (e) => {
    this.setState({ searchMediaString: e.target.value });
  };

  searchWikimediaImages = (fileName) => {
    this.setState({ activityIndicator: true });
    WikiMedia.searchImages(this.state.searchMediaString, this.processWikimediaImages);
  };

  processWikimediaImages = (_fileList) => {
    _fileList.sort((a, b) => {
      if (a.name.toUpperCase() < b.name.toUpperCase()) return -1;
      return a.name.toUpperCase() > b.name.toUpperCase() ? 1 : 0;
    });
    this.setState({ wikimediaFileList: _fileList, activityIndicator: false });
  };

  searchGoogleImages = (fileName) => {
    this.setState({ activityIndicator: true });
    GoogleSearch.searchImages(this.state.searchMediaString, this.processGoogleImages);
  };

  processGoogleImages = (_fileList) => {
    _fileList.sort((a, b) => {
      if (a.name.toUpperCase() < b.name.toUpperCase()) return -1;
      return a.name.toUpperCase() > b.name.toUpperCase() ? 1 : 0;
    });
    this.setState({ googleFileList: _fileList, activityIndicator: false });
  };

  updateMapRegion = (MapBoundaries) => {
    //console.log(MapBoundaries);
    this.setState({ lastSearchArea: MapBoundaries });
    this.searchStringSubmitted(MapBoundaries);
  };

  searchTextChanged = (text) => {
    this.setState({ searchText: text });
  };

  searchFlickrImages = (fileName) => {
    //const fileName = this.state.fileName;
    //this.setState({ fileName: fileNameEntered });
    if (!this.isValid(fileName) || fileName === "") {
      this.MyAlert("Message", "A valid search string is required", [{ Title: "OK", Method: null }]);
      return;
    }
    if (fileName.length < 3) {
      this.MyAlert("Message", "Search text must be at least 3 characters", [{ Title: "OK", Method: null }]);
      return;
    }
    this.setState({ fileName: fileName });
    this.searchStringSubmitted();
    // this.props.navigation.navigate("MapDialog", {
    //   updateCoordinate: this.updateMapRegion,
    //   initialCoordinate: this.state.lastSearchArea,
    //   type: "map_region",
    //   initialPoints: null,
    // });
  };
  searchStringSubmitted = (MapBoundaries) => {
    //console.log(
    //   "searchFlickrPhotos",
    //   `${MapBoundaries.southWest.longitude.toString()},${MapBoundaries.southWest.latitude.toString()},${MapBoundaries.northEast.longitude.toString()},${MapBoundaries.northEast.latitude.toString()}`
    // );
    this.setState({ activityIndicator: true });
    //setTimeout(this.timeoutFunc, 25000);
    let _Flickr = new Flickr();
    let places = _Flickr.searchPhotos(
      //`${MapBoundaries.southWest.longitude.toString()},${MapBoundaries.southWest.latitude.toString()},${MapBoundaries.northEast.longitude.toString()},${MapBoundaries.northEast.latitude.toString()}`,
      //"2.27,48.80,2.42,48.9",
      `${this.props.map_bounds.south.toString()},${this.props.map_bounds.west.toString()},${this.props.map_bounds.north.toString()},${this.props.map_bounds.east.toString()}`,
      //"45.43076,10.50423,48.34905207595349,13.124949",
      "", //"this.state.fileName",
      "50",
      this.searchFlickrPhotosCallBack
    );
  };
  searchFlickrPhotosCallBack = (flickr_places) => {
    console.log("searchFlickrPhotosCallBack", flickr_places);
    if (flickr_places == null) {
      this.setState({ activityIndicator: false });
      return;
    }
    let _fileList = [];
    flickr_places.forEach((photo) => {
      if (typeof photo.title === "undefined") return;
      const url_t =
        "http://farm" +
        photo.farm +
        ".static.flickr.com/" +
        photo.server +
        "/" +
        photo.id +
        "_" +
        photo.secret +
        "_" +
        "t.jpg";
      const url_m =
        "http://farm" +
        photo.farm +
        ".static.flickr.com/" +
        photo.server +
        "/" +
        photo.id +
        "_" +
        photo.secret +
        "_" +
        "z.jpg";
      _fileList.push({
        name: photo.title,
        url: url_t,
        url_m: url_m,
        folder: "",
        id: "",
        date: "",
        type: 0,
      });
    });
    //_fileList.sort((a, b) => a.name.toUpperCase() > b.name.toUpperCase());
    _fileList.sort((a, b) => {
      if (a.name.toUpperCase() < b.name.toUpperCase()) return -1;
      return a.name.toUpperCase() > b.name.toUpperCase() ? 1 : 0;
    });
    //this.setState({ fileList: _fileList });
    this.setState({ flickrFileList: _fileList, activityIndicator: false });
  };

  setPhotoSource = (value) => {
    if (this.state.imageSource === value) return;
    if (value === "Saved") {
      if (this.props.Authorization === AUTHORIZATION_LEVEL.GUEST) {
        this.MyAlert("Message", "In GUEST mode. Must be logged in.", [{ Title: "OK", Method: null }]);
        return;
      }
      if (this.state.cloudFileListAll.length === 0) {
        this.setState({ cloudFileList: [], filesSelected: [] });
        this.getUserImages();
      } else {
        this.FilterByFolder(this.state.cloudFileListAll, this.state.ShowOnlyWithGPS, this);
      }
    } else if (value === "Guide") {
      const displayFileList = window.guide_obj.places.getImageList();
      this.setState({ cloudFileList: displayFileList, filesSelected: [] });
      //this.props.navigation.setParams({ any_displayed: displayFileList.length > 0 });
    } else if (value === "Flickr") {
      this.setState({ cloudFileList: this.state.flickrFileList, filesSelected: [] });
    } else if (value === "GoogleSearch") {
      this.setState({ cloudFileList: this.state.googleFileList, filesSelected: [] });
    } else if (value === "Wikimedia") {
      this.setState({ cloudFileList: this.state.wikimediaFileList, filesSelected: [] });
    }
    this.setState({ imageSource: value, filesSelected: [] });
    //this.props.onSetImgDlgSrc(value);
    //console.log("imageSource", imageSource);
  };

  // setPhotoSource = (value) => {
  //   if (this.props.ImageDialogOtherSource === value) return;
  //   //console.log("setPhotoSource", value);
  //   if (value == "Saved") {
  //     if (this.props.Authorization == AUTHORIZATION_LEVEL.GUEST) {
  //       Alert.alert("In GUEST mode. Must be logged in.");
  //       return;
  //     }
  //     if (this.state.cloudFileListAll.length === 0) {
  //       this.setState({ cloudFileList: [] });
  //       this.getUserImages();
  //     } else {
  //       this.FilterByFolder(this.state.cloudFileListAll, this.state.ShowOnlyWithGPS, this);
  //     }
  //   } else if (value === "Guide") {
  //     const displayFileList = this.props.Guide.places.getImageList();
  //     this.setState({ cloudFileList: displayFileList });
  //     this.props.navigation.setParams({ any_displayed: displayFileList.length > 0 });
  //   } else if (value === "Flickr") {
  //     this.setState({ cloudFileList: this.state.flickrFileList });
  //     this.props.navigation.setParams({ any_displayed: this.state.flickrFileList.length > 0 });
  //   }
  //   this.props.navigation.setParams({ any_selected: false });
  //   this.setState({ imageSource: value });
  //   this.props.navigation.setParams({ _imageSource: value });
  //   this.props.onSetImgDlgSrc(value);
  // };

  DeviceOrSearch = (value) => {
    this.setState({ DeviceOrSearch: value });
    this.props.onSetImgDlgDev(value);
  };

  // FilesChosen = (event) => {
  //   //const base64 = this.getBase64(event.target.value);
  //   //console.log("FilesChosen", event.target.value);
  // };

  //console.log("Inputs", Inputs);
  uploadOld = async (e) => {
    // Convert the FileList into an array and iterate
    this.setState({ activityIndicator: true });
    let files = Array.from(e.target.files);
    files.map((file, index) => {
      // Define a new file reader
      let reader = new FileReader();

      // Create a new promise
      return new Promise((resolve) => {
        // Resolve the promise after reading file
        reader.onload = () => resolve(reader.result);

        // Read the file as text
        //reader.readAsText(file);
        if (file.name.toUpperCase().endsWith(".JPG") || file.name.toUpperCase().endsWith(".JPEG"))
          this.processImageFile(file, index === files.length - 1, index + 1, files.length);
      });
    });

    // At this point you'll have an array of results
    Promise.all(files);
    // setTimeout(() => {
    //   this.AutoSelectDeviceImages();
    // }, 1000);
    this.setState({ activityIndicator: false });
  };

  upload = async (e) => {
    // Convert the FileList into an array and iterate
    this.setState({ activityIndicator: true });
    let files = Array.from(e.target.files);
    for (let i = 0; i < files.length; i++) {
      //let reader = new FileReader();
      //const data = await files[i].text();
      await this.processImageFile(files[i], i === files.length - 1, i + 1, files.length);
      //this.setState({ ScreenStatus: `Uploaded: ${i + 1} of ${files.length}` });
    }

    // // At this point you'll have an array of results
    // let res = await Promise.all(files);
    // setTimeout(() => {
    //   this.AutoSelectDeviceImages();
    // }, 1000);
    // this.setState({ activityIndicator: false });
  };

  FilesChosen = async (e) => {
    // if (this.props.multi === false) {
    // }
    const image = e.target.files[0];
    console.log("image: ", image.name, image.type);
    if (!image.type.includes("image/")) {
      this.MyAlert("Message", "Only image files are allowed", [{ Title: "OK", Method: null }]);
      return;
    }
    await this.processImageFile(image, true, 1, 1);
  };

  processImageFile = async (image, refresh, file_num, file_total) => {
    this.setState({ activityIndicator: true });
    //setTimeout(this.timeoutFunc, 25000);
    const image_name = image.name.slice(0, -4);
    this.setState({ fileName: image_name });

    //exifr.gps(image).then(console.log).catch(console.error);
    let exif_data = {};
    let exif_output = await exifr.parse(image);
    // console.log("exif_output", exif_output);
    // this.MyAlert("Alert", `exif_output: ${JSON.stringify(exif_output)}`, [{ Title: "OK", Method: null }]);
    //console.log("exif_output length", JSON.stringify(exif_output).length);
    if (typeof exif_output !== "undefined" && exif_output !== null) {
      if (typeof exif_output.latitude !== "undefined" && exif_output.latitude !== null)
        exif_data.latitude = exif_output.latitude;
      if (typeof exif_output.longitude !== "undefined" && exif_output.longitude !== null)
        exif_data.longitude = exif_output.longitude;
      if (typeof exif_output.CreateDate !== "undefined" && exif_output.CreateDate !== null)
        exif_data.date = this.encodeDateTime(exif_output.DateTimeOriginal);
    }
    //console.log("exif_data", exif_data);
    await new Compressor(image, {
      quality: 0.6,
      resize: "cover",
      width: "800",
      success: (compressedResult_m) => {
        new Compressor(image, {
          quality: 0.6,
          resize: "cover",
          width: "150",
          success: (compressedResult) => {
            console.log("compressedResult", compressedResult);
            this.processImage(
              compressedResult,
              compressedResult_m,
              exif_data,
              image_name,
              file_num,
              file_total,
              refresh
            );
          },
        });
      },
    });
  };

  encodeDateTime = (d) => {
    console.log("encodeDateTime d:", d);
    // //var t = new Date(d.seconds * 1000);
    // let t = d;
    // let dateString = `${t.getFullYear()}:${
    //   t.getMonth() + 1
    // }:${t.getDate()} ${t.getHours()}:${t.getMinutes()}:${t.getSeconds()}`;

    const lux_time = DateTime.fromJSDate(d).toISO();

    console.log("encodeDateTime ltime:", lux_time);
    return lux_time;
  };

  getBase64 = (file) => {
    var reader = new FileReader();
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
    reader.readAsDataURL(file);
    reader.onload = function () {
      let encoded = reader.result.split(",");
      //you split this to get mimetype out of your base64
      //addForSale(Date.now().toString(10), {uDesc: n, date: d, uFile: encoded[1]});
      // I just used a timestamp as the ID
    };
  };

  getImageURLString = (file) => {
    if (file.type === 1) return FolderImage;
    if (file.type === -1) return FolderBackImage;
    const photo_m = file.url_m;
    const photo = file.url;
    //console.log("getImageURLString", file);
    let str = photo;
    if (str === "" || typeof str === "undefined") str = photo_m;
    let img = Common.getImageURL(str, Common.getFBPath(window.guide_obj.guide_id, this.props.user_info), false);
    //if (!img.startsWith("data")) console.log(img);
    return img;
  };

  MultiSelect = (Items, SelectedItems, Mode, Callback, Title) => {
    //console.log("MultiSelect");
    this.setState({
      MultiselectItems: Items,
      MultiselectSelectedItems: SelectedItems,
      MultiselectMode: Mode,
      MultiselectCallback: Callback,
      MultiselectTitle: Title,
      MultiselectSearchText: "",
      ShowMultiselect: true,
    });
  };

  shortenText = (_text, textLength, folder) => {
    let text = _text;
    if (folder) if (text === null || typeof text === "undefined" || text === "") text = "";
    if (text.length > textLength) {
      text = text.substring(0, textLength) + "...";
    }
    //return (folder && text !== "/" ? "/" : "") + text;
    return text;
  };

  onKeyPress = (event) => {
    if (event.charCode === 13) {
      this.returnURLEntered();
    }
  };

  onKeyPressForSearch = (event) => {
    if (event.charCode === 13) {
      this.searchTextEntered();
    }
  };

  returnURLEntered = () => {
    if (this.state.URLEnteredMedium === "") {
      this.MyAlert("Message", "Please enter a URL for the image", [{ Title: "OK", Method: null }]);
      return;
    }
    const updateImage = this.props.updateImage;
    if (updateImage != null) updateImage([{ url: this.state.URLEntered, url_m: this.state.URLEnteredMedium }]);
    this.props.onHide();
  };

  handleInputChangedMedium = (e) => {
    this.setState({ URLEnteredMedium: e.target.value });
  };

  handleInputChanged = (e) => {
    this.setState({ URLEntered: e.target.value });
  };

  imageSelection = (image) => {
    //e.stopPropagation();
    let fs = this.state.filesSelected;
    if (fs.findIndex((x) => x.id === image.id) === -1) fs.push(image);
    else {
      let nfs = [];
      for (let i = 0; i < fs.length; i++) if (fs[i].id !== image.id) nfs.push(fs[i]);
      fs = nfs;
    }
    this.setState({ filesSelected: fs });
  };

  imageSelectAll = () => {
    if (this.state.filesSelected.length > 0) {
      this.setState({ filesSelected: [] });
      return;
    }
    let nfs = [];
    this.state.cloudFileList.forEach((file) => {
      if (file.type === 0) if (nfs.findIndex((x) => x.id === file.id) === -1) nfs.push(file);
    });
    this.setState({ filesSelected: nfs });
  };

  imageSelected = (image) => {
    if (image.type !== 0) this.selectFile(image);
    return this.state.filesSelected.findIndex((x) => x.id === image.id) !== -1;
  };

  getStyle = (image) => {
    let style = {
      verticalAlign: "text-bottom",
      height: 100,
      objectFit: "cover",
    };
    if (image.type === 0 && this.imageSelected(image)) style.border = "3px solid green";
    return style;
  };

  addFolder = () => {
    this.setState({
      edit_mode: "Add Folder",
      //PromptModalShow: true,
      PromptModalTitle: "Folder Name",
      PromptModalMessage: "Enter Folder Name",
      PromptModalButtons: [
        { Title: "OK", Method: this.AddNewFolder },
        { Title: "CANCEL", Method: null },
      ],
      PromptModalInputs: [{ name: "Folder Name", value: "" }],
    });
    setTimeout(() => this.setState({ PromptModalShow: true }), 100);
  };

  AddNewFolder = (inputs) => {
    if (inputs === null) return;
    const newName = inputs[0].value;
    const folders = this.getFolderNames(this.state.cloudFileListAll, this.state.folderList);
    console.log(newName);
    if (folders.includes(newName)) {
      this.MyAlert("Alert", `Folder: ${newName} already exists. Choose another name.`, [{ Title: "OK", Method: null }]);
      return;
    }
    //this.MyAlert("Alert", `add folder: ${newName}`, [{ Title: "OK", Method: null }]);
    this.writeMetaToDB(
      {
        date: DateTime.now().toISO({ zone: "utc" }),
        folder: newName,
        name: newName,
        url_m: "",
        url: "",
        user_id: this.props.user_info.uid,
        exif_data: {},
      },
      true
    );
  };

  onDeleteFolder = (event) => {
    this.MyAlert("Delete folder", `Note, this will delete folder and all files in folder`, [
      { Title: "OK", Method: this.deleteFolder },
      { Title: "CANCEL", Method: null },
    ]);
  };

  deleteFolder = async () => {
    const FolderFilesList = this.getFolderFilesList();
    //console.log("FolderFilesList", FolderFilesList);
    if (FolderFilesList.length !== 0) await this.deleteFiles(FolderFilesList);
    const folders = this.getFolderMetaRecord();
    this.setState({ folderFilter: "" });
    if (folders.length > 0) await DataBase.deleteImage(folders[0].id, this.folderDeleted);
    //console.log("folders", folders);
  };

  folderDeleted = () => {
    console.log("***folderDeleted***");
    setTimeout(this.getUserImages, 2000);
  };

  getFolderFilesList = () => {
    let filesSelected = [];
    this.state.cloudFileList.forEach((x) => {
      if (x.folder === this.state.folderFilter && x.type === 0) filesSelected.push(x);
    });
    return filesSelected;
  };

  getFolderMetaRecord = () => {
    let filesSelected = [];
    this.state.cloudFileList.forEach((x) => {
      if (x.folder === this.state.folderFilter && x.type === 1) filesSelected.push(x);
    });
    this.state.folderList.forEach((x) => {
      if (x.folder === this.state.folderFilter && x.type === 1) filesSelected.push(x);
    });
    return filesSelected;
  };

  _render = 0;
  render() {
    //console.log("Render");
    const mode = this.props.mode;
    // let displayFileList = [];
    // if (mode === "upload" || this.state.imageSource != "Flickr") {
    //   displayFileList = this.state.cloudFileList;
    //   displayFileList = this.addFolderNames(displayFileList);
    //   //console.log(displayFileList);
    // } else displayFileList = this.state.flickrFileList;
    let displayFileList = this.state.cloudFileList;
    // if (this.state.imageSource === "Guide") displayFileList = window.guide_obj.places.getImageList();
    //console.log("displayFileList", displayFileList);
    if (typeof displayFileList === "undefined") displayFileList = [];
    if (this.state.imageSource === "URL") displayFileList = [];
    if (this.state.DeviceOrSearch === "Device") displayFileList = [];
    if (this.state.imageSource === "Flickr") displayFileList = this.state.flickrFileList;
    if (this.state.imageSource === "Wikimedia") displayFileList = this.state.wikimediaFileList;
    if (this.state.imageSource === "GoogleSearch") displayFileList = this.state.googleFileList;
    let method = this.selectFile;
    if (mode === "upload") method = this.onEditMetadata;
    //if (this.props.multi) method = this.imageSelected;
    //displayFileList.sort((a, b) => a.name.toUpperCase() > b.name.toUpperCase());

    //Uncomment if you want to display folders
    //displayFileList = this.addFolderNames(displayFileList);

    // displayFileList.sort((a, b) => {
    //   if (a.type === 1 && b.type === 1) {
    //     if (a.name.toUpperCase() < b.name.toUpperCase()) return -1;
    //     return a.name.toUpperCase() > b.name.toUpperCase() ? 1 : 0;
    //   }
    //   if (a.type === 1) return -1;
    //   if (b.type === 1) return 0;
    //   if (a.name.toUpperCase() < b.name.toUpperCase()) return -1;
    //   return a.name.toUpperCase() > b.name.toUpperCase() ? 1 : 0;
    // });

    if (this.state.imageSource === "Saved") {
      console.log("Sorting by date...");
      try {
        displayFileList = displayFileList.sort((a, b) => {
          const at = DateTime.fromISO(a.exif_data.date);
          const bt = DateTime.fromISO(b.exif_data.date);
          return at < bt ? -1 : at > bt ? 1 : 0;
        });
      } catch (e) {
        displayFileList = displayFileList.sort((a, b) => a.name.toUpperCase() > b.name.toUpperCase());
      }
    } else displayFileList = displayFileList.sort((a, b) => a.name.toUpperCase() > b.name.toUpperCase());

    let image_count = 0;
    displayFileList.forEach((file) => {
      if (file.type !== 1) image_count++;
    });

    console.log("this.state.imageSource", this.state.imageSource);
    console.log("displayFileList", displayFileList);
    // console.log(
    //   "mode, this.state.imageSource, this.state.DeviceOrSearch",
    //   mode,
    //   this.state.imageSource,
    //   this.state.DeviceOrSearch
    // );
    //console.log("After", this.state.cloudFileList);
    // if (this.state.ShowMultiselect)
    //   return (
    //   );
    // else
    return (
      <div className="modal" style={{ zIndex: 65537 }}>
        <Modal
          {...this.props}
          size="md"
          dialogClassName="myModalClass"
          aria-labelledby="contained-modal-title-vcenter"
          //centered={true}
          scrollable={true}
          onShow={this.modalOnShow}
          // style={{ minWidth: "350px" }}
          onHide={this.props.onHide}
        >
          <Modal.Header>
            <div style={{ width: "100%" }}>
              <div className="sort">
                <div className="dialog-heading">
                  <div>
                    <h4 style={{ color: "#3598DB" }}>Image Dialog</h4>
                  </div>
                  {mode === "select" && this.state.filesSelected.length > 0 ? (
                    <div className={"icon-with-text"}>
                      <i className="fas fa-check fa-2x color-blue" onClick={() => this.returnSelectedFiles()}></i>
                      {/* <p>Sort</p> */}
                    </div>
                  ) : null}
                  {(mode === "upload" && this.state.filesSelected.length > 0) ||
                  (mode === "select" && this.state.filesSelected.length === 1) ? (
                    <i title="OK" className={`fa fa fa-bars fa-2x`} onClick={() => this.createImageMenu()}></i>
                  ) : null}
                  {this.state.imageSource === "Saved" ? (
                    <div className={"icon-with-text"}>
                      <i
                        title="Change Folder"
                        className={`fas fa-folder fa-2x color-blue`}
                        onClick={this.SelectFolderView}
                      ></i>
                      {/* <p>Sort</p> */}
                    </div>
                  ) : null}
                  {mode === "upload" && this.state.imageSource === "Saved" && !this.state.isMobile ? (
                    <div>
                      <label for="file-upload" className="custom-file-upload">
                        <i title="Upload Image" className="fa fa-cloud-upload fa-2x"></i>
                      </label>
                      <input
                        accept="image/jpeg"
                        //capture="image"
                        type="file"
                        onChange={this.upload}
                        id="file-upload"
                        multiple
                      />
                    </div>
                  ) : null}
                  {mode === "upload" && this.state.imageSource === "Saved" && this.state.isMobile ? (
                    <div>
                      <label for="file-upload" className="custom-file-upload">
                        <i title="Upload Image" className="fa fa-cloud-upload fa-2x"></i>
                      </label>
                      <input
                        //accept="image/jpeg"
                        //capture="image"
                        type="file"
                        onChange={this.upload}
                        id="file-upload"
                        multiple
                      />
                    </div>
                  ) : null}
                  {this.state.filesSelected.length > 0 &&
                  this.props.multi === true &&
                  this.state.imageSource !== "URL" ? (
                    <div className={"icon-with-text"}>
                      <i
                        title="Unselect all"
                        className={`fa fa-check-square fa-2x`}
                        aria-hidden="true"
                        onClick={() => this.imageSelectAll()}
                      ></i>
                    </div>
                  ) : null}
                  {this.state.filesSelected.length === 0 &&
                  this.props.multi === true &&
                  this.state.imageSource !== "URL" ? (
                    <div className={"icon-with-text"}>
                      <i
                        title="Select all"
                        className={`fa fa-check-square fa-2x`}
                        aria-hidden="true"
                        onClick={() => this.imageSelectAll()}
                      ></i>
                      {/* <p>Sort</p> */}
                    </div>
                  ) : null}
                  <div>
                    <i title="Exit" className={`fas fa-times fa-2x color-blue`} onClick={this.props.onHide}></i>
                  </div>
                </div>
              </div>
              {this.state.imageSource === "Saved" ? (
                <div>
                  <span style={{ verticalAlign: "text-top", fontSize: "12px" }}>
                    {`Folder: ${this.shortenText(this.state.folderFilter, 40, true)} (${image_count})`}
                  </span>
                  <span>&nbsp;&nbsp;&nbsp;</span>
                  <i
                    title="Change Folder"
                    className={`fas fa-folder-plus fa-2x color-blue`}
                    onClick={this.addFolder}
                  ></i>
                  <span>&nbsp;&nbsp;&nbsp;</span>
                  <i
                    title="Change Folder"
                    className={`fas fa-folder-minus fa-2x color-blue`}
                    onClick={this.onDeleteFolder}
                  ></i>
                </div>
              ) : null}
              {/* {this.state.imageSource === "Saved" ? (
                <div className={"icon-with-text"}>
                  <i
                    title="Change Folder"
                    className={`fas fa-folder-plus fa-2x color-blue`}
                    onClick={this.SelectFolderView}
                  ></i>
                </div>
              ) : null} */}
              {this.state.activityIndicator ? (
                <div className="loading">
                  <Spinner animation="border" variant="primary" />
                  {/* <ReactLoading type={"spin"} color="#0000ff" height={"10%"} width={"10%"} /> */}
                </div>
              ) : null}{" "}
              {this.state.ScreenStatus.length > 0 ? (
                <span style={{ verticalAlign: "text-top", fontSize: "12px" }}>{`${this.state.ScreenStatus}`}</span>
              ) : null}
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="sort" style={{ width: "100%" }}>
              <input
                //accept="image/*,capture=camera"
                capture="camera"
                type="file"
                onChange={this.FilesChosen}
              />
              {mode !== "upload" && this.props.exif !== true ? (
                <div className="items-spaced" style={{ paddingBottom: 20 }}>
                  <Form.Check
                    label="Saved"
                    type="radio"
                    id="autoSizingCheckSaved"
                    checked={this.state.imageSource === "Saved"}
                    onChange={() => this.setPhotoSource("Saved")}
                  />
                  <span>&nbsp;&nbsp;&nbsp;</span>
                  {/* {this.props.exif !== true ? ( */}
                  <div className="items-spaced">
                    <Form.Check
                      label="URL"
                      type="radio"
                      id="autoSizingCheckURL"
                      checked={this.state.imageSource === "URL"}
                      onChange={() => this.setPhotoSource("URL")}
                    />
                    <span>&nbsp;&nbsp;&nbsp;</span>
                    <Form.Check
                      label="Guide"
                      type="radio"
                      id="autoSizingCheckGuide"
                      checked={this.state.imageSource === "Guide"}
                      onChange={() => this.setPhotoSource("Guide")}
                    />
                    <span>&nbsp;&nbsp;&nbsp;</span>
                    <Form.Check
                      label="Wikimedia"
                      type="radio"
                      id="autoSizingCheckSaved"
                      checked={this.state.imageSource === "Wikimedia"}
                      onChange={() => this.setPhotoSource("Wikimedia")}
                    />
                    <span>&nbsp;&nbsp;&nbsp;</span>
                    <Form.Check
                      label="Google"
                      type="radio"
                      id="autoSizingCheckSaved"
                      checked={this.state.imageSource === "GoogleSearch"}
                      onChange={() => this.setPhotoSource("GoogleSearch")}
                    />
                  </div>
                  {/* ) : null} */}
                </div>
              ) : null}

              {mode !== "upload" && this.state.imageSource === "Wikimedia" ? (
                <div>
                  <p>Enter search text</p>
                  <input
                    type="text"
                    value={this.state.searchMediaString}
                    placeholder="Enter search strings"
                    onChange={(e) => this.handleSearchTextChanged(e)}
                  />
                  {/* <textarea
                    ref={(input) => input}
                    multiline={false}
                    rows={1}
                    className="form-item"
                    //type={"text"}
                    placeholder="Enter search string"
                    style={{ width: "300px" }}
                    defaultValue={this.state.searchMediaString}
                    onChange={(e) => this.handleSearchTextChanged(e)}
                    onKeyPress={this.onKeyPressSearchText}
                  ></textarea> */}
                  <Button
                    key={"button"}
                    variant="primary"
                    style={{ width: "200px" }}
                    className="my-2"
                    onClick={() => this.searchWikimediaImages()}
                  >
                    Search
                  </Button>
                </div>
              ) : null}

              {mode !== "upload" && this.state.imageSource === "GoogleSearch" ? (
                <div>
                  <p>Enter search text</p>

                  <input
                    type="text"
                    value={this.state.searchMediaString}
                    placeholder="Enter search strings"
                    onChange={(e) => this.handleSearchTextChanged(e)}
                  />
                  {/* <textarea
                    ref={(input) => input}
                    multiline={false}
                    rows={1}
                    className="form-item"
                    //type={"text"}
                    placeholder="Enter search string"
                    style={{ width: "300px" }}
                    defaultValue={this.state.searchMediaString}
                    onChange={(e) => this.handleSearchTextChanged(e)}
                    onKeyPress={this.onKeyPressSearchText}
                  ></textarea> */}
                  <Button
                    key={"button"}
                    variant="primary"
                    style={{ width: "200px" }}
                    className="my-2"
                    onClick={() => this.searchGoogleImages()}
                  >
                    Search
                  </Button>
                </div>
              ) : null}
              {this.state.imageSource === "Saved" ||
              this.state.imageSource === "Guide" ||
              this.state.imageSource === "Flickr" ||
              this.state.imageSource === "Wikimedia" ||
              this.state.imageSource === "GoogleSearch" ? (
                <div className="image_dialog" style={{ width: "100%" }}>
                  {displayFileList.map((file, index) => (
                    <div>
                      <div style={{ width: 100, cursor: "pointer" }} onClick={() => method(file)}>
                        <img
                          src={this.getImageURLString(file, file)}
                          title={file.name}
                          style={this.getStyle(file)}
                          alt={`file.name`}
                        />
                        <div>
                          <p style={{ verticalAlign: "text-top", fontSize: "12px" }}>
                            {this.shortenText(file.name, 10)}
                            {/* {file.type === 0 && this.props.multi === true ? (
                              <input
                                checked={this.imageSelected(file)}
                                type="checkbox"
                                onClick={(e) => this.imageSelection(e, file)}
                              />
                            ) : null} */}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : null}
              {/* {this.state.imageSource === "Guide" ? (
                <div className="image_dialog" style={{ width: "100%", marginTop: 10 }}>
                  {displayFileList.map((file, index) => (
                    <div>
                      <div style={{ width: 100, cursor: "pointer" }} onClick={() => method(file)}>
                        <img
                          src={this.getImageURLString(file, file)}
                          title={file.name}
                          style={{ verticalAlign: "text-bottom", height: 100, objectFit: "cover" }}
                          alt={`file.name`}
                        />
                        <p style={{ verticalAlign: "text-top", fontSize: "12px" }}>{this.shortenText(file.name, 10)}</p>
                      </div>
                    </div>
                  ))}
                </div>
              ) : null} */}
              {this.state.imageSource === "URL" ? (
                <div className={`text-center color-blue`}>
                  <p>Enter medium image URL ~800px</p>
                  <textarea
                    ref={(input) => input}
                    multiline={true}
                    rows={10}
                    className="form-item"
                    //type={"text"}
                    placeholder="Medium image URL ~800px"
                    style={{ width: "300px" }}
                    defaultValue={this.state.urlTextMedium}
                    onChange={(e) => this.handleInputChangedMedium(e)}
                    onKeyPress={this.onKeyPress}
                  ></textarea>
                  <p>Enter small image URL ~150px</p>
                  <textarea
                    ref={(input) => input}
                    multiline={true}
                    rows={10}
                    className="form-item"
                    //type={"text"}
                    placeholder="Enter small image URL ~150px"
                    style={{ width: "300px" }}
                    defaultValue={this.state.urlText}
                    onChange={(e) => this.handleInputChanged(e)}
                    onKeyPress={this.onKeyPress}
                  ></textarea>
                  <Button
                    key={"button"}
                    variant="primary"
                    style={{ width: "200px" }}
                    className="my-2"
                    onClick={() => this.returnURLEntered()}
                  >
                    OK
                  </Button>
                </div>
              ) : null}
            </div>
          </Modal.Body>
        </Modal>
        <AlertModal
          show={this.state.AlertModalShow}
          onHide={() => this.setState({ AlertModalShow: false })}
          AlertModalTitle={this.state.AlertModalTitle}
          AlertModalMessage={this.state.AlertModalMessage}
          AlertModalButtons={this.state.AlertModalButtons}
        />
        <PromptModal
          show={this.state.PromptModalShow}
          onHide={() => this.setState({ PromptModalShow: false })}
          PromptModalTitle={this.state.PromptModalTitle}
          PromptModalMessage={this.state.PromptModalMessage}
          PromptModalButtons={this.state.PromptModalButtons}
          PromptModalInputs={this.state.PromptModalInputs}
        />
        <MenuModal
          show={this.state.MenuModalShow}
          onHide={() => this.setState({ MenuModalShow: false })}
          menu_modal_list={this.state.MenuModalList}
          menu_modal_heading={this.state.MenuModalHeading}
          menu_model_image={this.state.MenuModalImage}
          menu_modal_place={this.state.MenuModalPlace}
        />
        {this.state.ShowMultiselect ? (
          <MultiSelectModal
            show={this.state.ShowMultiselect}
            onHide={() => this.setState({ ShowMultiselect: false })}
            MultiselectItems={this.state.MultiselectItems}
            MultiselectSelectedItems={this.state.MultiselectSelectedItems}
            MultiselectMode={this.state.MultiselectMode}
            MultiselectCallback={this.state.MultiselectCallback}
            MultiselectTitle={this.state.MultiselectTitle}
            MultiselectSearchText={this.state.MultiselectSearchText}
          />
        ) : null}
      </div>
    );
  }
}

export default ImageDialog;
