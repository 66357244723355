import Common, { Settings, SortByEnum, POI_IMAGES, COLORS, isSearchResult } from "../Common/Common";
//import search_green from "../assets/images/search.png";
import search_green from "../assets/icons/search.png";
import default_icon from "../assets/icons/color_purple.png"; //"../assets/images/poi.png";
import { getDistance, getPreciseDistance } from "geolib";

export default class Place {
  constructor(places) {
    this.PType = "Icon";
    this.Lat = "";
    this.Longi = "";
    this.Category = [];
    this.Group = [];
    //this.Groups = ["New"];
    //this.Categories = ["New"];
    this.Rating = "";
    this.Favorite = false;
    this.Visited = false;
    this.Info = "";
    this.Photo = "";
    this.Photo_m = "";
    this.ID = "";
    this.Points = null;
    this.Address = "";
    this.Country = "";
    this.State = "";
    this.City = "";
    this.Phone = "";
    this.Name = "New";
    this.Open = "";
    this.Hlink = "";
    this.Link = { type: "none", value: "", offset: "0" };
    ///new
    this.Image = "";
    this.Email = "";
    //this.URL = "";
    this.Source = Place_Source.USER;
    this.Hidden = false;
    this.MustSee = false;
    this.poi_img = "0";
    this.Duration = 30;
    //this.PlaceLink = "none";
    ///Remove???
    //this.Opacity = "";
    // this.thick = "";
    // this.Tag = "";
    // this.Color = "";
    // this.obj = null;
    // this.Level = "None";
    // this.Zone = "None";
    // this.poi = null;
    // this.oneGroup = "New";
    // this.oneCategory = "New";
    // this.POV = "";
    // this.Pop = "";
    let placesList = places;
    if (places == null) {
      if (typeof window !== "undefined") {
        if (window.web_app === true) {
          placesList = window.guide_obj.places;
        }
      }
    }
    if (placesList != null) {
      var r = Math.floor(Math.random() * 1000000000 + 1);
      while (placesList.getPlace(r) != null) r = Math.floor(Math.random() * 1000000000 + 1);
      this.ID = r.toString();
    }
  }

  createIDFromPlacesObject(placesArray) {
    var r = Math.floor(Math.random() * 1000000000 + 1);
    while (placesArray.getPlace(r) != null) r = Math.floor(Math.random() * 1000000000 + 1);
    this.ID = r.toString();
    return this.ID;
  }

  createIDFromPlacesArray(placesArray) {
    while (true) {
      var r = Math.floor(Math.random() * 1000000000 + 1);
      let found = true;
      for (let i = 0; i < placesArray.length; i++) {
        if (placesArray[i].ID == r) {
          found = false;
          break;
        }
      }
      if (found) {
        this.ID = r.toString();
        return this.ID;
      }
    }
  }

  json_to_place(place_json, oldGuide) {
    //console.log("place_json", place_json);
    this.PType = place_json.PType;
    this.Category = place_json.Category;
    this.Group = place_json.Group;
    //this.Categories = place_json.Categories;
    this.Rating = place_json.Rating;
    if (typeof this.Rating == "undefined") this.Rating = "";
    // this.POV = place_json.POV;
    this.ID = place_json.ID;
    //this.Info = place_json.Info;
    this.Info = place_json.Info; //Common.createTextFromHTML(place_json.Info);
    if (typeof this.Info === "undefined") this.Info = "";
    // this.Pop = place_json.Pop;
    this.Photo = place_json.Photo;
    if (typeof place_json.Photo_m !== "undefined") this.Photo_m = place_json.Photo_m;
    else this.Photo_m = this.Photo;
    if (oldGuide) {
      if (this.Photo.toUpperCase().endsWith("_T.JPG")) {
        let suffix = this.Photo;
        this.Photo = `http://liquidguides.com/Media/Photos/${this.Photo}`;
        this.Photo_m = `http://liquidguides.com/Media/Photos/${suffix.replace("_t", "_m")}`;
      }
      //console.log("this.Photo", this.Photo);
      //console.log("this.Photo_m", this.Photo_m);
    }
    // Temporary fix
    // else if (!this.Photo.startsWith("http") && this.Photo !== "") {
    //   let suffix = this.Photo;
    //   this.Photo = `http://liquidguides.com/Media/Photos/${this.Photo}`;
    //   this.Photo_m = `http://liquidguides.com/Media/Photos/${suffix.replace("_t", "_m")}`;
    // }
    // Temporary fix
    // if (this.Photo === "http://liquidguides.com/Media/Photos/") this.Photo = "";
    // if (this.Photo_m === "http://liquidguides.com/Media/Photos/") this.Photo_m = "";
    this.Lat = place_json.Lat;
    this.Longi = place_json.Longi;
    if (typeof this.Lat == "string") this.Lat = parseFloat(this.Lat);
    if (typeof this.Longi == "string") this.Longi = parseFloat(this.Longi);
    this.Points = place_json.Points;
    //this.Opacity = place_json.Opacity;
    this.Address = place_json.Address;
    this.Country = place_json.Country;
    this.State = place_json.State;
    this.City = place_json.City;
    this.Phone = place_json.Phone;
    this.Name = place_json.Name;
    this.Favorite = place_json.Favorite;
    this.Visited = place_json.Visited;
    // this.thick = place_json.Thick;
    // this.Tag = place_json.Tag;
    // this.Color = place_json.Color;
    this.Image = place_json.Image;
    this.Open = place_json.Open;
    this.Hlink = place_json.Hlink;
    this.Hidden = typeof place_json.Hidden == "undefined" ? false : place_json.Hidden;
    this.MustSee = typeof place_json.MustSee == "undefined" ? false : place_json.MustSee;
    this.poi_img = typeof place_json.poi_img == "undefined" ? "0" : place_json.poi_img;
    this.Duration = typeof place_json.Duration == "undefined" ? 30 : place_json.Duration;

    this.Link = place_json.Link =
      typeof place_json.Link === "undefined" ? { type: "none", value: "", offset: "0" } : place_json.Link;
    if (typeof place_json.pathLink !== "undefined")
      this.Link = { type: "path", value: place_json.pathLink, offset: "0" };
    if (typeof place_json.videoLink !== "undefined")
      this.Link = { type: "video", value: place_json.videoLink, offset: "0" };
    if (typeof place_json.videoLinkOffset !== "undefined") this.Link.offset = place_json.videoLinkOffset;

    // if (typeof place_json.pathLink !== "undefined") this.pathLink = place_json.pathLink;
    // if (typeof place_json.videoLink !== "undefined") this.videoLink = place_json.videoLink;
    // if (typeof place_json.videoLinkOffset !== "undefined") this.videoLinkOffset = place_json.videoLinkOffset;

    // if (typeof place_json.audioLink !== "undefined") this.audioLink = place_json.audioLink;
    // if (typeof place_json.sectionLink !== "undefined") this.sectionLink = place_json.sectionLink;
    // if (typeof place_json.videoPLink !== "undefined") this.videoPLink = place_json.videoPLink;
    // if (typeof place_json.PlaceLink !== "undefined") this.placeLink = place_json.PlaceLink;
    // else this.PlaceLink = "none";

    ///////new
    this.Email = "";
    //this.URL = "";
    // this.obj = null;
    // this.Level = "None";
    // this.Zone = "None";
    this.Source = Place_Source.GUIDE;
    // this.poi = null;
    // this.oneGroup = "None";
    // this.oneCategory = "None";

    // if (this.Color == null) this.Color = "255,0,0";
    // else if (this.Color.indexOf(",") == -1) this.Color = "255,0,0";

    // if (this.Opacity == null) this.Opacity = "1";
    // else if (this.Opacity == "") this.Opacity = "1";

    // if (this.Thick == null) this.Thick = "3";
    // else if (this.Thick == "") this.Thick = "3";

    if (this.Category == null || this.Category == "") this.Category = [];
    if (this.Group == null || this.Group == "") this.Group = [];
    else if (this.Group.indexOf(":") != -1) {
      var cats = "";
      var groups = "";
      var gs = this.getGroups();
      for (var j = 0; j < gs.length; ++j) {
        if (gs[j].indexOf("c:") != -1) {
          var parts = gs[j].split(":");
          if (cats != "") cats += "|";
          cats += parts[1];
        } else {
          var parts = gs[j].split(":");
          if (groups != "") groups += "|";
          if (parts.length == 1) groups += parts[0];
          else {
            groups += parts[1];
            // if (parts[0] == "z") this.Zone = parts[1];
            // else if (parts[0] == "l") this.Level = parts[1];
          }
        }
      }
      this.Group = groups;
      this.Category = cats;
    }
    this.getLatlongForPoly();
    // if (this.PType == "PolyLine" || this.PType == "Polygon" || this.PType == "HandDrawing") {
    //   var lat = 0.0;
    //   var lon = 0.0;
    //   var total = 0.0;
    //   var lines = this.getLines();
    //   for (var x = 0; x < lines.length; x++) {
    //     var line = lines[x];
    //     for (var y = 0; y < line.length; y++) {
    //       if (y % 2) {
    //         lon += line[y];
    //         total += 1.0;
    //       } else lat += line[y];
    //     }
    //   }
    //   this.Lat = lat / total;
    //   this.Longi = lon / total;
    // }
  }

  static getLatlongForPoly(place) {
    //console.log("getLatlongForPoly1");
    if (place.PType == "PolyLine" || place.PType == "Polygon" || place.PType == "HandDrawing") {
      //console.log("getLatlongForPoly2");
      var lat = 0.0;
      var lon = 0.0;
      var total = 0.0;
      var lines = Place.getLines(place);
      //console.log("getLatlongForPoly3", lines);
      for (var x = 0; x < lines.length; x++) {
        // var line = lines[x];
        // for (var y = 0; y < line.length; y++) {
        lon += lines[x].Longi;
        lat += lines[x].Lat;
        // if (y % 2) {
        //   lon += line[y];
        total += 1.0;
        // } else lat += line[y];
        // }
      }
      place.Lat = lat / total;
      place.Longi = lon / total;
      //console.log("getLatlongForPoly4", place.Lat, place.Longi);
    }
    return place;
  }

  getLatlongForPoly() {
    //console.log("getLatlongForPoly1");
    if (this.PType == "PolyLine" || this.PType == "Polygon" || this.PType == "HandDrawing") {
      //console.log("getLatlongForPoly2");
      var lat = 0.0;
      var lon = 0.0;
      var total = 0.0;
      var lines = this.getLines();
      //console.log("getLatlongForPoly3", lines);
      for (var x = 0; x < lines.length; x++) {
        // var line = lines[x];
        // for (var y = 0; y < line.length; y++) {
        lon += lines[x].Longi;
        lat += lines[x].Lat;
        // if (y % 2) {
        //   lon += line[y];
        total += 1.0;
        // } else lat += line[y];
        // }
      }
      this.Lat = lat / total;
      this.Longi = lon / total;
      //console.log("getLatlongForPoly4", this.Lat, this.Longi);
    }
  }

  getLatlongForPoly() {
    //console.log("getLatlongForPoly1");
    if (this.PType == "PolyLine" || this.PType == "Polygon" || this.PType == "HandDrawing") {
      //console.log("getLatlongForPoly2");
      var lat = 0.0;
      var lon = 0.0;
      var total = 0.0;
      var lines = this.getLines();
      //console.log("getLatlongForPoly3", lines);
      for (var x = 0; x < lines.length; x++) {
        // var line = lines[x];
        // for (var y = 0; y < line.length; y++) {
        lon += lines[x].Longi;
        lat += lines[x].Lat;
        // if (y % 2) {
        //   lon += line[y];
        total += 1.0;
        // } else lat += line[y];
        // }
      }
      this.Lat = lat / total;
      this.Longi = lon / total;
      //console.log("getLatlongForPoly4", this.Lat, this.Longi);
    }
  }

  getLatLongsForPlace() {
    if (this.PType == "Icon") {
      let latLong = [];
      latLong.push({ Lat: this.Lat, Longi: this.Longi });
      return latLong;
    }

    if (this.PType == "PolyLine" || this.PType == "Polygon" || this.PType == "HandDrawing") {
      var lines = this.getLines();
      return lines;
    }
  }

  static getLatLongsForPlace(place) {
    if (place.PType === "Icon") {
      let latLong = [];
      latLong.push({ Lat: place.Lat, Longi: place.Longi });
      return latLong;
    }

    if (place.PType == "PolyLine" || place.PType == "Polygon" || place.PType == "HandDrawing") {
      var lines = Place.getLines(place);
      return lines;
    }
  }
  static createImagesHTML(imageURLs, fb_path, size, width) {
    let html_doc = "";
    for (let i = 0; i < imageURLs.length; i++) {
      html_doc += Place.createImageHTML(imageURLs[i].url_m, fb_path, size, width);
    }
    return html_doc;
  }

  static createImageHTML(imageURL, fb_path, size, width) {
    //console.log("createImageHTML", width);
    if (width == null) {
      if (size == "medium") width = "600";
      else width = "400";
    }
    //console.log("createImageHTML", width);
    const img = Common.getImageURL(imageURL, fb_path, true);
    let html_doc = `<p style="text-align:center;"><img src="${img}" alt="Image" style="width:100%; max-width:700px; height:auto; border-radius:10%"></img></p><br/>`;
    if (size == "medium")
      html_doc = `<p style="text-align:center;"><img src="${img}" alt="Image" style="width:100%; max-width:700px; height:auto; border-radius:10%"></img></p><br/>`;
    //console.log("createImageHTML", html_doc);
    return html_doc;
  }

  createPlaceDetailsHTML(text, fb_path, guide, scheduledDates) {
    if (this.Name === "undefined") return "";
    let isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    let font_size = isMobile ? "100%" : "100%";
    let path = this.getPath();
    if (this.PType === "Polygon") {
      const points = path.split("|");
      if (points.length > 2) {
        if (points[1] != points[points.length - 1]) path += "|" + points[1];
      }
    }
    let static_map = `https://maps.googleapis.com/maps/api/staticmap?center=${this.Lat},${this.Longi}&zoom=13&size=400x300&maptype=roadmap&markers=size:mid%7Ccolor:red%7Clabel:X%7C${this.Lat},${this.Longi}&key=AIzaSyAKxtGIewjOkmnY0FE9jD7hYbsHRVArJJc`;
    if (path != null) {
      static_map = `https://maps.googleapis.com/maps/api/staticmap?size=400x300&maptype=roadmap&path=color:0xff0000ff|weight:4${path}&key=AIzaSyAKxtGIewjOkmnY0FE9jD7hYbsHRVArJJc`;
      if (this.PType == "Polygon")
        static_map = `https://maps.googleapis.com/maps/api/staticmap?size=400x300&maptype=roadmap&path=fillcolor:0x0000FF33%7Ccolor:0x0000ff33%|weight:2${path}&key=AIzaSyAKxtGIewjOkmnY0FE9jD7hYbsHRVArJJc`;
    }
    const photoTouse = this.Photo_m !== "" ? this.Photo_m : this.Photo;
    const img = Common.getImageURL(photoTouse, fb_path, true);
    let html_doc = ""; //"<style> span { color: rgb(50, 141, 245); font-weight: 500;} </style>";
    // if (window.web_app == true) {
    //   html_doc = `<p><h5>${this.Name}</span></h5></p>`;
    //   html_doc += `<p style="text-align:center;"><img src="${img}" alt="Image" style="width:100%; max-width:700px; height:auto; border-radius:10%"></img></p>`;
    //   html_doc += `<p style="text-align:center;"><a href="maplink:${this.ID}||"><img src="${static_map}" alt="Map" width="300"></img></a></p>`;
    // } else {
    html_doc += `<p><h3>${this.Name}</h3></p>`;
    html_doc += `<p style="text-align:center;"><img src="${img}" alt="Image" style="width:100%; width:100%; max-width:700px; height:auto; border-radius:10%"></img></p>`;
    html_doc += `<p style="text-align:center;"><a href="maplink:${this.ID}||"><img src="${static_map}" alt="Map" style="width:100%; max-width:700px; height:auto;"></img></a></p>`;
    // }

    if (text != null) {
      text = text.replace(/\n/gi, "<br/>");
      html_doc += `<p>${text}</span></p>`;
    }
    if (this.Hlink !== "") {
      //console.log("this.Hlink", this.Hlink);
      const links = this.Hlink.split(";");
      links.forEach((link) => {
        if (link.includes("|")) {
          const link_parts = link.split("|");
          html_doc += `<a style="font-size: ${font_size};" href="urllink:${link_parts[1]}">${link_parts[0]}</a><br><br>`;
        } else html_doc += `<a style="font-size: ${font_size};" href="urllink:${link}">More information</a><br><br>`;
      });
      // if (this.Hlink.includes(";")) {
      //   const links = this.Hlink.split(";");
      //   links.forEach((link) => {
      //     if (link.includes("|")) {
      //       const link_parts = link.split("|");
      //       html_doc += `<a href="${urllink:link_parts[1]}">${link_parts[0]}</a><br><br>`;
      //     }
      //   });
      // } else if (this.Hlink.includes("|")) {
      //   const link_parts = this.Hlink.split("|");
      //console.log("link_parts", link_parts);
      //   for (let z = 0; z < link_parts.length / 2; z++) {
      //     html_doc += `<a href="urllink:${link_parts[z * 2 + 1]}">${link_parts[z * 2]}</a><br><br>`;
      //console.log("links", `<a href="urllink:${link_parts[z * 2 + 1]}">${link_parts[z * 2]}</a><br><br>`);
      //   }
      // } else html_doc += `<a href="urllink:${this.Hlink}">More information</a><br><br>`;
    }
    //if (this.URL != "") html_doc += `<a href="urllink:${this.URL}">Website</a><br><br>`;
    //console.log("this.Lat, this.Longi", this.Lat, this.Longi);
    html_doc += `<div style="font-size: ${font_size};">`;
    if (typeof parseFloat(this.Lat) !== "undefined")
      html_doc += `<span style="color: rgb(50, 141, 245);">Location:</span> <span>${parseFloat(this.Lat).toFixed(
        6
      )},${parseFloat(this.Longi).toFixed(6)}<br/>`;
    else html_doc += `<span style="color: rgb(50, 141, 245);">Location:Not applicable`;
    html_doc += `<span style="color: rgb(50, 141, 245);">Categories:</span> ${guide.categoryNamesFromIDs(
      this.Category,
      "<br/>"
    )}<br/>`;
    html_doc += `<span style="color: rgb(50, 141, 245);">Groups:</span> ${guide.groupNamesFromIDs(
      this.Group,
      "<br/>"
    )}<br/>`;
    html_doc += `<span style="color: rgb(50, 141, 245);">Address:</span> ${this.Address}<br/>`;
    html_doc += `<span style="color: rgb(50, 141, 245);">City:</span> ${this.City}<br/>`;
    html_doc += `<span style="color: rgb(50, 141, 245);">Country:</span> ${this.Country}<br/>`;
    html_doc += `<span style="color: rgb(50, 141, 245);">Phone:</span> ${this.Phone}<br/>`;
    html_doc += `<span style="color: rgb(50, 141, 245);">Visited:</span> ${this.Visited === true ? "Yes" : "No"}<br/>`;
    html_doc += `<span style="color: rgb(50, 141, 245);">Favorite:</span> ${
      this.Favorite === true ? "Yes" : "No"
    }<br/>`;
    html_doc += `<span style="color: rgb(50, 141, 245);">Must See:</span> ${this.MustSee === true ? "Yes" : "No"}<br/>`;
    html_doc += `<span style="color: rgb(50, 141, 245);">Hidden:</span> ${this.Hidden === true ? "Yes" : "No"}<br/>`;
    html_doc += `<span style="color: rgb(50, 141, 245);">Rating:</span> ${this.Rating}<br/>`;
    html_doc += `<span style="color: rgb(50, 141, 245);">Email:</span> ${this.Email}<br/>`;

    html_doc += `<span style="color: rgb(50, 141, 245);">Dates: </span>`;
    if (typeof scheduledDates != "undefined") {
      scheduledDates.forEach((date) => {
        html_doc += `${date}<br/>`;
      });
    }
    html_doc += `</p>`;
    html_doc += `</div>`;
    //</body></html>`;
    //console.log(html_doc);
    return html_doc;
  }

  getFirstHLink() {
    //console.log("this.Hlink", this.Hlink);
    if (this.Hlink == "") return "";
    const links = this.Hlink.split(";");
    if (!links[0].includes("|")) return links[0];
    return links[0].split("|")[1];
  }

  attaction_to_place(attraction_json) {
    this.Source = Place_Source.ATTRACTION;
    this.Lat = attraction_json.latitude;
    this.Longi = attraction_json.longitude;
    this.Name = attraction_json.name;
    //this.Info=attraction_json.description_long; //description_short
    this.Category = attraction_json.categories;
    this.Group = [];
    //this.Image=attraction_json.Image;
    if (attraction_json.photo_t_url == "") this.Photo = window.webSite + "/Icons/place.jpg";
    else this.Photo = window.webSite + "/Attractions/Photos/" + attraction_json.photo_t_url;
    if (attraction_json.photo_m_url == "") this.Photo_m = window.webSite + "/Icons/place.jpg";
    else this.Photo_m = window.webSite + "/Attractions/Photos/" + attraction_json.photo_m_url;
    var wphoto = "";
    if (attraction_json.photo_m_url != null && attraction_json.photo_m_url != "") {
      if (attraction_json.photo_m_width > 320) {
        attraction_json.photo_m_height = attraction_json.photo_m_height * (320 / attraction_json.photo_m_width);
        attraction_json.photo_m_width = 320;
      }
      if (attraction_json.photo_m_height > 480) {
        attraction_json.photo_m_width = attraction_json.photo_m_height * (480 / attraction_json.photo_m_height);
        attraction_json.photo_m_height = 480;
      }
      wphoto = `<img src="${this.Photo_m}" width="${attraction_json.photo_m_width}" height="${attraction_json.photo_m_height}"/>`;
    }
    if (attraction_json.photo_m_url != null && attraction_json.photo_m_url != "") {
      wphoto = `<img src="${this.Photo_m}" width="${attraction_json.photo_m_width}" height="${attraction_json.photo_m_height}"/>`;
    }
    var more_info =
      '<a href="urllink:' +
      window.webSite +
      "/database/get_attraction_description.php?language=1&id=" +
      attraction_json.id +
      '" >More info</a>';
    this.Info =
      "<html><head></head><body><p style='font-size:120%'>" +
      attraction_json.name +
      "<br>" +
      wphoto +
      "<br>" +
      attraction_json.description_short +
      "<br><br>" +
      more_info +
      "</p></body></html>";
  }

  youtube_to_place(youtube_json) {
    this.Source = Place_Source.YOUTUBE;
    this.Lat = youtube_json.geoCoordinates.latitude;
    this.Longi = youtube_json.geoCoordinates.longitude;
    this.Name = youtube_json.title;
    //        if (this.Name=="")
    //            this.Name = "Untitled";
    //        if (this.Name.length > 25)
    //            this.Name = this.Name.substring(0, 24);
    //this.Info=attraction_json.description_long; //description_short
    this.Category = [];
    this.Group = [];
    //this.Image=attraction_json.Image;
    this.Photo = youtube_json.thumbnail.sqDefault;
    this.Photo_m = youtube_json.thumbnail.hqDefault;
    if (this.Photo === "") this.Photo = window.webSite + "/Icons/place.jpg";
    if (this.Photo_m === "") this.Photo_m = window.webSite + "/Icons/place.jpg";
    var wphoto = "";
    if (this.Photo != null && this.Photo != "") {
      wphoto = `<img src="${this.Photo_m} width="480"/>`;
    }
    var videourl = "";
    if (youtube_json.player.mobile != "")
      videourl = '<a href="videolink:' + youtube_json.player.mobile + '" >Play video</span></a>';
    else if (youtube_json.player.default != "")
      //youtube_json.player.default for desktop
      videourl = '<a href="videolink:' + youtube_json.player.default + '" >Play video</span></a>';
    this.Info =
      "<html><head></head><body><p style='font-size:120%'>" +
      youtube_json.title +
      "<br>" +
      wphoto +
      "<br>" +
      videourl +
      "<br>" +
      youtube_json.description +
      "</p></body></html>";
  }

  copy(place) {
    this.Lat = place.Lat;
    this.PType = place.PType;
    this.Category = place.Category;
    this.Rating = place.Rating;
    // this.POV = place.POV;
    //this.ID = place.ID;
    this.Info = place.Info;
    // this.Pop = place.Pop;
    this.Photo = place.Photo;
    if (typeof place.Photo_m == "undefined") this.Photo_m = "";
    else this.Photo_m = place.Photo_m;
    this.Longi = place.Longi;
    this.Points = place.Points;
    //this.Opacity = place.Opacity;
    this.Address = place.Address;
    this.Country = place.Country;
    this.State = place.State;
    this.City = place.City;
    this.Phone = place.Phone;
    this.Name = place.Name;
    this.Group = place.Group;
    // this.thick = place.Thick;
    // this.Tag = place.Tag;
    // this.Color = place.Color;
    this.Image = place.Image;
    this.Open = place.Open;
    this.Hlink = place.Hlink;
    ///////new
    this.Email = place.Email;
    //this.URL = place.URL;
    // this.obj = place.obj;
    // this.Level = place.Level;
    // this.Zone = place.Zone;
    this.Source = place.Source;
    this.Duration = place.Duration;

    // this.poi = place.poi;
    // this.oneGroup = place.oneGroup;
    // this.oneCategory = place.oneCategory;
    this.Hidden = typeof place.Hidden == "undefined" ? false : place.Hidden;
    this.MustSee = typeof place.MustSee == "undefined" ? false : place.MustSee;
    this.poi_img = typeof place.poi_img == "undefined" ? "0" : place.poi_img;

    this.Link = place.Link = typeof place.Link === "undefined" ? { type: "none", value: "", offset: "0" } : place.Link;
    if (typeof place.pathLink !== "undefined") this.Link = { type: "path", value: place.pathLink, offset: "0" };
    if (typeof place.videoLink !== "undefined") this.Link = { type: "video", value: place.videoLink, offset: "0" };
    if (typeof place.videoLinkOffset !== "undefined") this.Link.offset = place.videoLinkOffset;

    // if (typeof place.audioLink !== "undefined") this.audioLink = place.audioLink;
    // if (typeof place.sectionLink !== "undefined") this.sectionLink = place.sectionLink;
    // if (typeof place.videoPLink !== "undefined") this.videoPLink = place.videoPLink;
    // if (typeof place.PlaceLink !== "undefined") this.PlaceLink = place.PlaceLink;
  }
  // getLineColor() {
  //   var color = this.Color;
  //   if (color.indexOf(",") == -1) color = "255,0,0";
  //   var opacity = "1";
  //   if (this.Opacity != null) opacity = this.Opacity;
  //   color = "rgba(" + color + "," + opacity.toString() + ")";
  //   return color;
  // }
  // getLineWidth() {
  //   var thickness = "3";
  //   if (this.Thick != null) thickness = this.Thick;
  //   var width = parseInt(thickness);
  //   return width;
  // }

  static getLines(place) {
    //console.log("getLines");
    if (typeof place === "undefined") return [];
    if (typeof place.Points === "undefined") return [];
    var lineList = [];
    var line = [];
    if (place.Points.indexOf(";") !== -1) {
      return this.getCompressedLines(place);
    }
    var lineStrings = [place.Points];
    if (place.Points.indexOf("|") != -1) lineStrings = place.Points.split("|");
    for (var j = 0; j < lineStrings.length; ++j) {
      if (lineStrings[j] == "") continue;
      line = [];
      var points = lineStrings[j].split(":");
      for (var i = 0; i < points.length; ++i) {
        if (points[i] == "") continue;
        if (points[i].indexOf(",") == -1) continue;
        var latLon = points[i].split(",");
        line.push({ Lat: parseFloat(latLon[1]), Longi: parseFloat(latLon[0]) });
        //line.push(parseFloat(latLon[1]));
        //line.push(parseFloat(latLon[0]));
      }
      if (place.PType == "Polygon" && line.length > 1) {
        line.push(line[0]);
        //line.push(line[1]);
      }
      lineList.push(line);
    }
    //}
    //console.log(lineList);
    return line;
  }

  static getCompressedLines(place) {
    //console.log("getCompressedLines");
    if (typeof place === "undefined") return [];
    if (typeof place.Points === "undefined") return [];
    //const base62 = require("base62/lib/ascii");
    const geohash = require("ngeohash");
    var lineList = [];
    var line = [];
    var lineStrings = [place.Points];
    if (place.Points.indexOf("|") != -1) lineStrings = place.Points.split("|");
    for (var j = 0; j < lineStrings.length; ++j) {
      if (lineStrings[j] == "") continue;
      line = [];
      var points = lineStrings[j].split(";");
      //console.log(points, points);
      for (var i = 0; i < points.length; ++i) {
        if (points[i] == "") continue;
        if (points[i].indexOf(",") == -1) continue;
        var linePoint = points[i].split(",");
        //console.log('linePoint', linePoint);
        var loc = geohash.decode(linePoint[0]);
        line.push({ Lat: loc.latitude, Longi: loc.longitude });
      }
      if (place.PType == "Polygon" && line.length > 1) {
        line.push(line[0]);
        //line.push(line[1]);
      }
      lineList.push(line);
    }
    //}
    //console.log("line", line);
    return line;
  }

  getLines() {
    if (typeof this.Points === "undefined") return [];
    var lineList = [];
    var line = [];
    //if (this.PType == "HandDrawing") {
    var lineStrings = [this.Points];
    if (lineStrings.indexOf("|") != -1) lineStrings = this.Points.split("|");
    for (var j = 0; j < lineStrings.length; ++j) {
      if (lineStrings[j] == "") continue;
      line = [];
      var points = lineStrings[j].split(":");
      for (var i = 0; i < points.length; ++i) {
        if (points[i] == "") continue;
        if (points[i].indexOf(",") == -1) continue;
        var latLon = points[i].split(",");
        line.push({ Lat: parseFloat(latLon[1]), Longi: parseFloat(latLon[0]) });
        //line.push(parseFloat(latLon[1]));
        //line.push(parseFloat(latLon[0]));
      }
      if (this.PType == "Polygon" && line.length > 1) {
        line.push(line[0]);
        //line.push(line[1]);
      }
      lineList.push(line);
    }
    //}
    //console.log(lineList);
    return line;
  }

  reverseLineDirection = () => {
    if (this.Points == null) return;
    let points_ar = this.Points.split(":");
    let points_ar_rev = points_ar.reverse();
    let new_points = points_ar_rev.join(":");
    this.Points = new_points;
  };

  // getLinesOLD() {
  //   var lineList = [];
  //   //if (this.PType == "HandDrawing") {
  //   var lineStrings = [this.Points];
  //   if (lineStrings.indexOf("|") != -1) lineStrings = this.Points.split("|");
  //   for (var j = 0; j < lineStrings.length; ++j) {
  //     if (lineStrings[j] == "") continue;
  //     var line = [];
  //     var points = lineStrings[j].split(":");
  //     for (var i = 0; i < points.length; ++i) {
  //       if (points[i] == "") continue;
  //       if (points[i].indexOf(",") == -1) continue;
  //       var latLon = points[i].split(",");
  //       line.push(parseFloat(latLon[1]));
  //       line.push(parseFloat(latLon[0]));
  //     }
  //     if (this.PType == "Polygon" && line.length > 1) {
  //       line.push(line[0]);
  //       line.push(line[1]);
  //     }
  //     lineList.push(line);
  //   }
  //   //}
  //   return lineList;
  // }

  getPolyline() {
    //console.log("getPolyline");
    if (this.Points != null) {
      let lineStrings = this.Points;
      if (lineStrings.indexOf(";") !== -1) {
        return this.getCompressedPolyline();
      }
      //A polyline
      if (lineStrings.indexOf("|") == -1) {
        let linePoints = lineStrings.split(":");
        let polyline = [];
        for (let j = 0; j < linePoints.length; ++j) {
          if (linePoints[j] == "") continue;
          if (linePoints[j].indexOf(",") == -1) continue;
          var latLon = linePoints[j].split(",");
          polyline.push({ latitude: parseFloat(latLon[1]), longitude: parseFloat(latLon[0]) });
        }
        return { coordinates: polyline };
      }
    }
    return null;
  }

  static getPolyline(place) {
    //console.log("getPolyline");
    if (place.Points != null) {
      let lineStrings = place.Points;
      if (lineStrings.indexOf(";") !== -1) {
        return Place.getCompressedPolyline(place);
      }
      //A polyline
      if (lineStrings.indexOf("|") == -1) {
        let linePoints = lineStrings.split(":");
        let polyline = [];
        for (let j = 0; j < linePoints.length; ++j) {
          if (linePoints[j] == "") continue;
          if (linePoints[j].indexOf(",") == -1) continue;
          var latLon = linePoints[j].split(",");
          polyline.push({ latitude: parseFloat(latLon[1]), longitude: parseFloat(latLon[0]) });
        }
        return { coordinates: polyline };
      }
    }
    return null;
  }

  getCompressedPolyline() {
    //console.log("getCompressedPolyline");
    if (this.Points != null) {
      //const base62 = require("base62/lib/ascii");
      const geohash = require("ngeohash");
      let lineStrings = this.Points;
      //A polyline
      try {
        if (lineStrings.indexOf("|") == -1) {
          let linePoints = lineStrings.split(";");
          let polyline = [];
          for (let j = 0; j < linePoints.length; ++j) {
            if (linePoints[j] == "") continue;
            if (linePoints[j].indexOf(",") == -1) continue;
            var linePoint = linePoints[j].split(",");
            var loc = geohash.decode(linePoint[0]);
            //var elapsed_time = base62.decode(linePoint[1]);
            polyline.push({ latitude: loc.latitude, longitude: loc.longitude });
          }
          //console.log("polyline", polyline);
          return { coordinates: polyline };
        }
      } catch {
        return null;
      }
    }
    return null;
  }

  static getCompressedPolyline(place) {
    //console.log("getCompressedPolyline");
    if (place.Points != null) {
      //const base62 = require("base62/lib/ascii");
      const geohash = require("ngeohash");
      let lineStrings = place.Points;
      //A polyline
      try {
        if (lineStrings.indexOf("|") == -1) {
          let linePoints = lineStrings.split(";");
          let polyline = [];
          for (let j = 0; j < linePoints.length; ++j) {
            if (linePoints[j] == "") continue;
            if (linePoints[j].indexOf(",") == -1) continue;
            var linePoint = linePoints[j].split(",");
            var loc = geohash.decode(linePoint[0]);
            //var elapsed_time = base62.decode(linePoint[1]);
            polyline.push({ latitude: loc.latitude, longitude: loc.longitude });
          }
          //console.log("polyline", polyline);
          return { coordinates: polyline };
        }
      } catch {
        return null;
      }
    }
    return null;
  }

  getTimedPath() {
    //console.log("getCompressedPolyline", this.Points);
    if (this.Points != null) {
      const base62 = require("base62/lib/ascii");
      const geohash = require("ngeohash");
      let lineStrings = this.Points;
      if (lineStrings.indexOf(";") == -1) return [];
      //A polyline
      try {
        if (lineStrings.indexOf("|") == -1) {
          let linePoints = lineStrings.split(";");
          //let polyline = [];
          let timedPath = [];
          for (let j = 0; j < linePoints.length; ++j) {
            if (linePoints[j] == "") continue;
            if (linePoints[j].indexOf(",") == -1) continue;
            var linePoint = linePoints[j].split(",");
            var loc = geohash.decode(linePoint[0]);
            var elapsed_time = base62.decode(linePoint[1]);
            var altitude = 0;
            if (linePoint.length > 2) {
              altitude = base62.decode(linePoint[2]);
              altitude = altitude / 10.0;
            }
            //polyline.push({ latitude: loc.latitude, longitude: loc.longitude });
            timedPath.push({
              latitude: loc.latitude,
              longitude: loc.longitude,
              elapsed_time: elapsed_time,
              altitude: altitude,
            });
          }
          //console.log("timedPath", timedPath);
          return timedPath;
        }
      } catch {
        return [];
      }
    }
    return [];
  }

  calculateDistance(p1, p2) {
    //console.log("calculateDistance", p1, p2);
    if (p1 == "undefined" || p2 == "undefined") return 0;
    if (p1.latitude == "undefined" || p2.latitude == "undefined") return 0;
    var dis = getDistance(
      { latitude: parseFloat(p1.latitude), longitude: parseFloat(p1.longitude) },
      { latitude: parseFloat(p2.latitude), longitude: parseFloat(p2.longitude) }
    );

    //console.log(p1, p2, dis);
    if (dis == "undefined") return 0;

    //console.log("calculateDistance", dis / 1000);
    return dis / 1000;
  }

  findClosestPointInPath(lat, long) {
    let timed_path = this.getTimedPath();
    let min_distance = 99999999999;
    let min_index = -1;
    for (let i = 0; i < timed_path.length; i++) {
      const p1 = { latitude: lat, longitude: long };
      const p2 = { latitude: timed_path[i].latitude, longitude: timed_path[i].longitude };
      const distance = this.calculateDistance(p1, p2);
      if (distance < min_distance) {
        min_distance = distance;
        min_index = i;
      }
    }
    if (min_index !== -1) return timed_path[min_index];
    else return null;
  }

  findClosestTimeInPath(search_time) {
    let timed_path = this.getTimedPath();
    //console.log("findClosestTimeInPath", search_time);
    let min_time_diff = 99999999999;
    let min_index = -1;
    for (let i = 0; i < timed_path.length; i++) {
      const time_diff = Math.abs(search_time - timed_path[i].elapsed_time);
      if (time_diff < min_time_diff) {
        min_time_diff = time_diff;
        min_index = i;
      }
    }
    if (min_index !== -1) {
      let found = timed_path[min_index];
      found.index = min_index;
      return found;
    } else return null;
  }

  getPath() {
    if (this.Points != null) {
      let lineStrings = this.Points;
      //A polyline
      if (lineStrings.indexOf("|") == -1) {
        if (lineStrings.indexOf(";") !== -1) return this.getCompressedPath();
        let linePoints = lineStrings.split(":");
        let path = "";
        for (let j = 0; j < linePoints.length; ++j) {
          if (linePoints[j] == "") continue;
          if (linePoints[j].indexOf(",") == -1) continue;
          var latLon = linePoints[j].split(",");
          path += "|" + latLon[1] + "," + latLon[0];
        }
        return path;
      }
    }
    return null;
  }

  getKMLPath() {
    if (this.Points != null) {
      let lineStrings = this.Points;
      //A polyline
      if (lineStrings.indexOf("|") == -1) {
        if (lineStrings.indexOf(";") !== -1) return this.getCompressedPath();
        let linePoints = lineStrings.split(":");
        let path = "";
        for (let j = 0; j < linePoints.length; ++j) {
          if (linePoints[j] == "") continue;
          if (linePoints[j].indexOf(",") == -1) continue;
          var latLon = linePoints[j].split(",");
          path += `${latLon[0]},${latLon[1]},0\n`;
        }
        return path;
      }
    }
    return null;
  }

  getCompressedPath() {
    if (this.Points != null) {
      const geohash = require("ngeohash");
      let lineStrings = this.Points;
      //A polyline
      if (lineStrings.indexOf("|") == -1) {
        let linePoints = lineStrings.split(";");
        let path = "";
        for (let j = 0; j < linePoints.length; ++j) {
          if (linePoints[j] == "") continue;
          if (linePoints[j].indexOf(",") == -1) continue;
          var linePoint = linePoints[j].split(",");
          var loc = geohash.decode(linePoint[0]);
          path += "|" + loc.latitude + "," + loc.longitude;
        }
        //console.log("getCompressedPath", path);
        return path;
      }
    }
    return null;
  }

  getCoordinates() {
    let coords = [];
    if (this.Points != null) {
      let lineStrings = this.Points;
      //A polyline
      if (lineStrings.indexOf("|") == -1) {
        let linePoints = lineStrings.split(":");
        //let path = "";
        for (let j = 0; j < linePoints.length; ++j) {
          if (linePoints[j] == "") continue;
          if (linePoints[j].indexOf(",") == -1) continue;
          var latLon = linePoints[j].split(",");
          coords.push({ latitude: parseFloat(latLon[1]), longitude: parseFloat(latLon[0]) });
          //path += "|" + latLon[1] + "," + latLon[0];
        }
        return coords;
      }
    }
    return null;
  }

  getCategories() {
    if (Array.isArray(this.Category)) return this.Category;
    var cats = new Array();
    var cat = this.Category;
    if (cat == null) cats[0] = "None";
    else if (cat == "") cats[0] = "None";
    else if (cat.indexOf("|") == -1) {
      cats[0] = cat;
    } else {
      var scats = cat.split("|");
      for (var j = 0; j < scats.length; ++j) {
        cats.push(scats[j]);
      }
    }
    //console.log(cats);
    return cats;
  }

  contains_Category(cat) {
    var cats = this.getCategories();
    for (var j = 0; j < cats.length; ++j) {
      if (cats[j] == cat) return true;
    }
    return false;
  }

  getGroups() {
    if (Array.isArray(this.Group)) return this.Group;
    var groups = new Array();
    var gp = this.Group;
    if (gp == null) groups[0] = "None";
    else if (gp == "") groups[0] = "None";
    else if (gp.indexOf("|") == -1) {
      groups[0] = gp;
    } else {
      var sgroups = gp.split("|");
      for (var j = 0; j < sgroups.length; ++j) {
        groups.push(sgroups[j]);
      }
    }
    return groups;
  }

  contains_Group(group) {
    var groups = this.getGroups();
    for (var j = 0; j < groups.length; ++j) {
      if (groups[j] == group) return true;
    }
    return false;
  }

  _getCustomIcon(guide, poi_img) {
    const index = guide.custom_icons.findIndex((c) => c.id === poi_img);
    if (index !== -1) return guide.custom_icons[poi_img].req;
    return default_icon;
  }

  getPOIImage(guide, poi_img) {
    //console.log("getPOIImage", this.Source, isSearchResult(this.Source));
    if (isSearchResult(this.Source) && this.Category.length === 0 && this.Group.length === 0) return search_green;
    if (poi_img != null && typeof poi_img !== "undefined") {
      if (Number.isNaN(parseFloat(poi_img))) return poi_img;
      if (poi_img != "0") {
        return POI_IMAGES[poi_img].req;
      }
    }
    if (Number.isNaN(parseFloat(this.poi_img))) return this.poi_img;
    if (this.poi_img != "0") {
      return POI_IMAGES[this.poi_img].req;
    }
    if (this.Category.length > 0) {
      for (let i = 0; i < this.Category.length; i++) {
        const category = guide.categoryFromID(this.Category[i]);
        if (category === null || typeof category === "undefined") return POI_IMAGES["1"].req;
        if (typeof category.url !== "undefined") return category.url;
        if (category.poi_img != "0") return POI_IMAGES[category.poi_img].req;
      }
    }
    if (this.Group.length > 0) {
      for (let i = 0; i < this.Group.length; i++) {
        const group = guide.groupFromID(this.Group[i]);
        if (group === null || typeof group === "undefined") return POI_IMAGES["1"].req;
        if (typeof group.url !== "undefined") return group.url;
        if (group.poi_img != "0") return POI_IMAGES[group.poi_img].req;
      }
    }
    return default_icon;
  }

  static getPOIImage(place, guide, poi_img) {
    //console.log("getPOIImage", this.Source, isSearchResult(this.Source));
    if (isSearchResult(this.Source) && this.Category.length === 0 && this.Group.length === 0) return search_green;
    if (poi_img != null && typeof poi_img !== "undefined") {
      if (Number.isNaN(parseFloat(poi_img))) return poi_img;
      if (poi_img != "0") {
        return POI_IMAGES[poi_img].req;
      }
    }
    if (Number.isNaN(parseFloat(place.poi_img))) return place.poi_img;
    if (place.poi_img != "0") {
      return POI_IMAGES[place.poi_img].req;
    }
    if (place.Category.length > 0) {
      for (let i = 0; i < place.Category.length; i++) {
        const category = guide.categoryFromID(place.Category[i]);
        if (category === null || typeof category === "undefined") return POI_IMAGES["1"].req;
        if (typeof category.url !== "undefined") return category.url;
        if (category.poi_img != "0") return POI_IMAGES[category.poi_img].req;
      }
    }
    if (place.Group.length > 0) {
      for (let i = 0; i < place.Group.length; i++) {
        const group = guide.groupFromID(place.Group[i]);
        if (group === null || typeof group === "undefined") return POI_IMAGES["1"].req;
        if (typeof group.url !== "undefined") return group.url;
        if (group.poi_img != "0") return POI_IMAGES[group.poi_img].req;
      }
    }
    return default_icon;
  }

  // static getGroupPOIImage(group) {
  //   if (group == null) return default_icon;
  //   if (group.poi_img == "0") return default_icon;
  //   if (typeof POI_IMAGES[group.poi_img] == "undefined") return default_icon;
  //   return POI_IMAGES[group.poi_img].req;
  // }

  static getItemPOIImageOLD(item) {
    if (item == null) return default_icon;
    if (item.poi_img == "0") return default_icon;
    if (typeof POI_IMAGES[item.poi_img] == "undefined") return default_icon;
    return POI_IMAGES[item.poi_img].req;
  }

  static getItemPOIImage(item) {
    //console.log("getItemPOIImage", item);
    if (item == null) return null;
    if (typeof item.url !== "undefined") return item.url;
    if (item.poi_img == "0") return null;
    if (typeof POI_IMAGES[item.poi_img] == "undefined") return null;
    return POI_IMAGES[item.poi_img].req;
  }

  static getPlacePOIImage(_poi_img) {
    let poi_img = this.poi_img;
    if (_poi_img != null && typeof _poi_img !== "undefined") poi_img = _poi_img;
    if (poi_img == "0") return POI_IMAGES["0"].req;
    if (typeof POI_IMAGES[poi_img] !== "undefined") return POI_IMAGES[poi_img].req;
    if (Number.isNaN(parseFloat(poi_img))) return poi_img;
    return POI_IMAGES["0"].req;
  }
  // getPOIImage(guide, _poi_img) {
  //   //console.log("getPOIImage", this.Source, isSearchResult(this.Source));
  //   if (isSearchResult(this.Source)) return search_green;
  //   let poi_img = _poi_img;
  //   if (poi_img === null || typeof poi_img === "undefined") poi_img = this.poi_img;
  //   if (poi_img === "0" && this.Category.length > 0) {
  //     for (let i = 0; i < this.Category.length; i++) {
  //       const category = guide.categoryFromID(this.Category[i]);
  //       if (category != null) {
  //         if (typeof category.url !== "undefined") poi_img = category.url;
  //         else poi_img = category.poi_img;
  //         break;
  //       }
  //     }
  //   }
  //   if (poi_img === "0" && this.Group.length > 0) {
  //     for (let i = 0; i < this.Group.length; i++) {
  //       const group = guide.groupFromID(this.Group[i]);
  //       if (group != null) {
  //         if (typeof group.url !== "undefined") poi_img = group.url;
  //         else poi_img = group.poi_img;
  //         break;
  //       }
  //     }
  //   }
  //   // poi_img is a URL, return it
  //   if (Number.isNaN(parseInt(poi_img))) return poi_img;

  //   const id_num = parseInt(poi_img);
  //   // Standard icon
  //   if (id_num < 5000) return poi_img !== "0" ? POI_IMAGES[poi_img].req : default_icon;
  //   // Custom icon
  //   return this._getCustomIcon(guide, poi_img);
  // }

  // // static getGroupPOIImage(group) {
  // //   if (group == null) return default_icon;
  // //   if (group.poi_img == "0") return default_icon;
  // //   if (typeof POI_IMAGES[group.poi_img] == "undefined") return default_icon;
  // //   return POI_IMAGES[group.poi_img].req;
  // // }

  // static getItemPOIImage(item) {
  //   if (item == null) return null;
  //   if (typeof item.url !== "undefined") return item.url;
  //   if (item.poi_img == "0") return null;

  //   if (Number.isNaN(parseInt(item.poi_img))) return item.poi_img;
  //   const id_num = parseInt(item.poi_img);
  //   // Standard icon
  //   if (id_num < 5000) return item.poi_img !== "0" ? POI_IMAGES[item.poi_img].req : default_icon;
  //   // Custom icon
  //   return this._getCustomIcon(guide, item.poi_img);

  //   if (typeof POI_IMAGES[item.poi_img] == "undefined") return null;
  //   return POI_IMAGES[item.poi_img].req;
  // }

  // static getPlacePOIImage(_poi_img) {
  //   let poi_img = this.poi_img;
  //   if (_poi_img != null && typeof _poi_img !== "undefined") poi_img = _poi_img;
  //   if (poi_img == "0") return POI_IMAGES["0"].req;
  //   if (typeof POI_IMAGES[poi_img] !== "undefined") return POI_IMAGES[poi_img].req;
  //   if (Number.isNaN(parseFloat(poi_img))) return poi_img;
  //   return POI_IMAGES["0"].req;
  // }
}

export var Place_Source = {
  GUIDE: 0,
  USER: 1,
  SEARCH: 2,
  ATTRACTION: 3,
  WIKIPEDIA: 4,
  YOUTUBE: 5,
  PANORAMIO: 6,
  OPENTRIPMAP: 7,
  GOOGLEPLACES: 8,
  FLICKR: 9,
};
