import React, { useState, useEffect, useRef } from "react";
import ReactDOMServer from "react-dom/server";
import ReactDOM from "react-dom";
import {
  GoogleMap,
  LoadScript,
  Marker,
  Polyline,
  Polygon,
  BicyclingLayer,
  StreetViewService,
  useJsApiLoader,
  DrawingManager,
  //OverlayView,
  StandaloneSearchBox,
  StreetViewPanorama,
  Data,
  TransitLayer,
} from "@react-google-maps/api";
import { InfoWindow, OverlayView } from "@react-google-maps/api";
import poi_img from "../../assets/icons/arch.png";
import { useSelector, Provider } from "react-redux";
import PlaceModal from "../modals/PlaceModal";
import MenuModal from "../modals/MenuModal";

import { useDispatch } from "react-redux";
import { slice1Actions } from "../../store/reducer";
import Common, { isSearchResult } from "../../Common/Common";
import Place from "../../Models/Place";
import GooglePlaces from "../../Models/GooglePlaces";
import AlertModal from "../modals/AlertModal";
import PromptModal from "../modals/PromptModal";
import KMLInput from "../modals/KMLInput";
import PlaceMenu from "../../CommonWeb/PlaceMenu";

//import store from "../../store/store";
import MapControl from "./MapControl";
import Attractions from "../../Models/Attractions";
import Wikipedia from "../../Models/Wikipedia";
import OpenTripMapPlaces from "../../Models/OpenTripMap";
import Places from "../../Models/Places";
import resize_icon from "../../assets/images/icon-resize-10.jpg";
import size_to_fit_icon from "../../assets/images/size-to-fit.jpg";
import dont_size_to_fit_icon from "../../assets/images/dont-size-to-fit.jpg";
import from_icon from "../../assets/images/from.png";
import to_icon from "../../assets/images/to.png";
import waypoint_icon from "../../assets/images/waypoint.png";
import letter_g from "../../assets/icons/letter_g.png";
import here_icon from "../../assets/images/here.png";
import reticle from "../../assets/images/reticle.png";
import DirectionsDialog from "../modals/DirectionsDialog";
import DirectionsDialogComponent from "../modals/DirectionsDialogComponent";
import { urlSchemeToFunctions } from "../../CommonWeb/Common";
import { otm_categories } from "../../Data/OTMCategories";
import AiDialog from "../modals/AiDialog";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Spinner } from "react-bootstrap";
import ImageDialog from "../modals/ImageDialog";
import Appointment from "../../Models/Event";
import { DateTime } from "luxon";
import YouTubeDialog from "../modals/YouTubeDialog";
import HTMLReader from "../modals/HTMLReader";
import Flickr from "../../Models/Flickr";
import AISearch from "../modals/AISearch";

const containerStyle = {
  width: "100%",
  height: "100%",
  // width: "85vw",
  // height: "85vh",
};

let optionsPolyline = {
  // strokeOpacity: 0.8,
  //strokeWeight: 2,
  fillColor: "#FFffff",
  fillOpacity: 0.35,
  clickable: true,
  draggable: false,
  editable: false,
  visible: true,
  radius: 30000,
  strokeColor: "rgba(255, 0, 0, 0.30)",
  lineDashPattern: [50, 10],
  strokeWidth: 6,
  strokeWeight: 6,

  zIndex: 2,
};

const optionsPolygon = {
  strokeColor: "rgba(0, 0, 255, 0.20)",
  strokeWidth: 10,
  strokeWeight: 10,
  fillColor: "rgba(0, 0, 255, 0.05)",
  //fillColor: "lightblue",
  fillOpacity: 0.2,
  //strokeColor: "blue",
  strokeOpacity: 1,
  //strokeWeight: 2,
  clickable: true,
  draggable: false,
  editable: false,
  geodesic: false,
  zIndex: 1,
};

function Map(props) {
  let markers = useSelector((state) => state.markers);
  let polylines = useSelector((state) => state.polylines);
  let MapCenter = useSelector((state) => state.MapCenter);
  let MapBounds = useSelector((state) => state.MapBounds);
  let MapCenterPlace = useSelector((state) => state.MapCenterPlace);
  let user_info = useSelector((state) => state.user_info);
  //let MenuModalArgs = useSelector((state) => state.MenuModalArgs);
  let SearchResultsPlacesList = useSelector((state) => state.SearchResultsPlacesList);
  let MapFullScreen = useSelector((state) => state.MapFullScreen);
  let StreetViewLocation = useSelector((state) => state.StreetViewLocation);
  let DirectionsFromLocation = useSelector((state) => state.DirectionsFromLocation);
  let DirectionsToLocation = useSelector((state) => state.DirectionsToLocation);
  let DirectionsWaypointsLocation = useSelector((state) => state.DirectionsWaypointsLocation);
  let MapRef = useSelector((state) => state.MapRef);
  let MobileMode = useSelector((state) => state.MobileMode);
  let GuideSettings = useSelector((state) => state.GuideSettings);
  let GuideInfoArray = useSelector((state) => state.GuideInfoArray);
  let GuideInfoArrayMine = useSelector((state) => state.GuideInfoArrayMine);
  let GuideType = useSelector((state) => state.GuideType);
  let GuideOrderBy = useSelector((state) => state.GuideOrderBy);
  let GuideSearchText = useSelector((state) => state.GuideSearchText);
  let GuideCountriesSelected = useSelector((state) => state.GuideCountriesSelected);
  let GuideCitiesSelected = useSelector((state) => state.GuideCitiesSelected);
  let GuideLanguageSelected = useSelector((state) => state.GuideLanguageSelected);
  let HighlightedPlaces = useSelector((state) => state.HighlightedPlaces);
  let GuidesOrPlaces = useSelector((state) => state.GuidesOrPlaces);
  //let GooglePlaces = useSelector((state) => state.GooglePlaces);
  let DatesFilter = useSelector((state) => state.DatesFilter);
  let SchedulesFilter = useSelector((state) => state.SchedulesFilter);
  let ScheduleName = useSelector((state) => state.ScheduleName);
  let AppSettings = useSelector((state) => state.AppSettings);
  let showHiddenPlace = useSelector((state) => state.showHiddenPlace);
  let SizeMapToFit = useSelector((state) => state.SizeMapToFit);
  const dispatch = useDispatch();

  const [placeModal, setPlaceModal] = useState(false);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [selectedShape, setSelectedShape] = useState(null);

  //const [mapRef, setMapRef] = useState(null);
  const [map, setMapRef] = useState(null);
  const [MyMap, setMyMapRef] = useState(null);
  const [MenuModalShow, setMenuModalShow] = useState(false);
  const [MenuModalImage, setMenuModalImage] = useState(null);
  const [MenuModalList, setMenuModalList] = useState([]);
  const [MenuModalHeading, setMenuModalHeading] = useState("");
  const [MenuModalPlace, setMenuModalPlace] = useState(null);
  const [_GooglePlaces, setGooglePlaces] = useState(null);
  const [_Attractions, setAttractions] = useState(null);
  const [_Wikipedia, setWikipedia] = useState(null);
  const [BikeLayer, setBikeLayer] = useState(false);
  const [_TransitLayer, setTransitLayer] = useState(false);
  const [AlertModalShow, setAlertModalShow] = useState(false);
  const [AlertModalTitle, setAlertModalTitle] = useState("");
  const [AlertModalMessage, setAlertModalMessage] = useState("");
  const [AlertModalButtons, setAlertModalButtons] = useState([]);
  const [EditShape, setEditShape] = useState(null);

  const [PromptModalShow, setPromptModalShow] = useState(false);
  const [PromptModalTitle, setPromptModalTitle] = useState("Enter Value");
  const [PromptModalMessage, setPromptModalMessage] = useState("");
  const [PromptModalButtons, setPromptModalButtons] = useState([]);
  const [PromptModalInputs, setPromptModalInputs] = useState([]);
  const [PromptModalCompact, setPromptModalCompact] = useState(null);
  const [SavedTextSearch, setSavedTextSearch] = useState("");
  const [ObjectsLoaded, setObjectsLoaded] = useState(false);
  const [DirectionsDialogShow, setDirectionsDialogShow] = useState(false);
  const [KMLInputShow, setKMLInputShow] = useState(false);
  const [OverlayViewContent, setOverlayViewContent] = useState("");
  const [OverlayViewShow, setOverlayViewShow] = useState(false);
  const [OverlayViewPosition, setOverlayViewPosition] = useState(null);
  const [LocationWatchID, setLocationWatchID] = useState(null);
  const [TrackedLocation, setTrackedLocation] = useState(null);
  const [AiDialogShow, setAiDialogShow] = useState(null);
  const [ClickLocation, setClickLocation] = useState({ Lat: 0, Longi: 0 });
  const [showInfoWindow, setShowInfoWindow] = useState(false);
  const [ImageDialogShow, setImageDialogShow] = useState(false);
  const [YouTubeDialogShow, setYouTubeDialogShow] = useState(false);
  const [HTMLReaderModal, setHTMLReaderModal] = useState(false);
  const [HTMLContent, setHTMLContent] = useState(false);

  // const [ShowOffcanvas, setShowOffcanvas] = useState(false);

  // const [_mapCoordinate, setMapCoordinate] = useState(null);
  const MyModal = useRef(null);
  const [AISearchShow, setAISearchShow] = useState(null);

  // let _GooglePlaces = new GooglePlaces();
  // let _Attractions = new Attractions();
  // let _Wikipedia = new Wikipedia();
  // if(!ObjectsLoaded){
  let _SearchResultsPlacesList = SearchResultsPlacesList;
  // }
  useEffect(() => {
    //console.log("*******************Map: useEffect");
    setGooglePlaces(new GooglePlaces());
    setAttractions(new Attractions());
    setWikipedia(new Wikipedia());
  }, []);

  // useEffect(() => {
  //   setGooglePlaces(new GooglePlaces());
  //   if (map != null && MyModal != null)
  //     map.controls[window.google.maps.ControlPosition.TOP_CENTER].push(MyModal.current);
  // }, [MyModal, map]);

  // const onLoad = React.useCallback(function callback(map) {
  //   const bounds = new window.google.maps.LatLngBounds();
  //   map.fitBounds(bounds);
  //   setMapRef(map)
  // }, [])

  //Location code
  // var options = {
  //   enableHighAccuracy: true,
  //   timeout: 5000,
  //   maximumAge: 5000,
  // };

  // function success(pos) {
  //   var crd = pos.coords;

  //   console.log("Your current position is:");
  //   console.log(`Latitude : ${crd.latitude}`);
  //   console.log(`Longitude: ${crd.longitude}`);
  //   console.log(`More or less ${crd.accuracy} meters.`);
  // }

  // function error(err) {
  //   console.warn(`ERROR(${err.code}): ${err.message}`);
  // }

  // navigator.geolocation.getCurrentPosition(success, error, options);

  const myAlert = (message) => {
    setAlertModalTitle("Message");
    setAlertModalMessage(message);
    setAlertModalButtons([{ Title: "OK", Method: null }]);
    setAlertModalShow(true);
  };

  const markerDblClicked = (marker) => {
    setSelectedShape({ place: marker });
    setSelectedPlace(marker);
    setPlaceModal(true);
    return;
  };

  // window.ShowPlaceMenu = (id) => {
  //   myAlert(id);
  // };
  const guideClicked = (marker, e) => {
    let x = 0;
    if (props.callback != null) props.callback(marker.id, marker.data.guide_id);
  };

  var mouseDownStart = 0;
  var mouseDownEnd = 0;

  const onMouseDownMarker = (marker, e) => {
    // const new_mouseDownStart = new Date().getTime();
    // if(new_mouseDownStart - mouseDownStart < 2000) {
    //   return
    // }
    mouseDownStart = new Date().getTime();
  };

  const onMouseUpMarker = (marker, e) => {
    mouseDownEnd = new Date().getTime();
    // console.log(`mouseDownStart mouseDownStart mouseDownEnd mouseDownEnd `);
    var longpress = mouseDownEnd - mouseDownStart < 500 ? false : true;
    //setSelectedShape({ place: marker });
    //setSelectedPlace(marker);
    //console.log("longpress", longpress);
    if (longpress) dragMarker(marker);
    else markerClicked(marker, e);
  };

  const markerRighClicked = (marker, e) => {
    if (GuideSettings.places_map.menu === true) {
      setSelectedShape({ place: marker });
      setSelectedPlace(marker);
      setPlaceModal(true);
    }
  };

  const markerClicked = (marker, e) => {
    // if (!MobileMode) {
    //   infoWindowClicked(marker, e);
    //   return;
    // }
    if (window.infowindow != null) {
      window.infowindow.close();
      window.infowindow = null;
      return;
    }
    window.infowindow = new window.google.maps.InfoWindow({
      pixelOffset: new window.google.maps.Size(0, -40),
    });
    window.infowindow.setPosition({ lat: marker.Lat, lng: marker.Longi });

    var div = document.createElement("div");
    div.innerHTML = marker.Name;
    div.onclick = function () {
      markerDblClicked(marker, e);
      //infoWindowClicked(marker, e);
    };
    window.infowindow.setContent(div);

    window.infowindow.open(map);
    infoWindowClicked(marker, e);
  };

  const infoWindowClicked = (marker, e) => {
    if (GuideSettings.mode !== "custom_view") {
      setSelectedShape({ place: marker });
      setSelectedPlace(marker);
      setPlaceModal(true);
      return;
    }
    if (GuideSettings.mode === "custom_view") {
      const place = marker;
      if (GuideSettings.places_map.menu === true) {
        setSelectedShape({ place: marker });
        setSelectedPlace(marker);
        setPlaceModal(true);
      }
      if (GuideSettings.places_map.path_link) {
        if (place.PType === "PolyLine") {
          const closest = marker.findClosestPointInPath(e.latLng.lat(), e.latLng.lng());
          if (closest !== null) {
            testVideo(place, closest.elapsed_time);
            return;
          }
        }
        window.handleMarkerPathLink(place);
        return;
      }

      if (
        (GuideSettings.places_map.info === true && place.Info !== "") ||
        (GuideSettings.places_map.info === true && GuideSettings.places_map.attributes !== true)
      ) {
        if (place !== null) {
          let htmlString = place.Info;
          if (typeof htmlString === "undefined") htmlString = "";
          htmlString = urlSchemeToFunctions(htmlString);
          dispatch(slice1Actions.setHTMLTitle({ value: "Place Info" }));
          dispatch(slice1Actions.setHTMLToSHow({ value: htmlString }));
          dispatch(slice1Actions.setShowHTMLReader({ value: true }));
          window.location.href = "#home-start";
          var homeDiv = document.getElementById("home-content-start");
          if (MobileMode) dispatch(slice1Actions.setCurrentAppScreen({ value: "Home" }));
          else if (homeDiv !== null) homeDiv.scrollIntoView();
        }
      } else if (
        GuideSettings.places_map.info === true &&
        place.Info === "" &&
        GuideSettings.places_map.attributes === true
      ) {
        let htmlString = PlaceMenu.handleShowPlaceAttributes(marker.ID, SearchResultsPlacesList);
        htmlString = urlSchemeToFunctions(htmlString);
        dispatch(slice1Actions.setHTMLTitle({ value: "Place Attributes" }));
        dispatch(slice1Actions.setHTMLToSHow({ value: htmlString }));
        dispatch(slice1Actions.setShowHTMLReader({ value: true }));
        window.location.href = "#home-start";
        homeDiv = document.getElementById("home-content-start");
        if (MobileMode) dispatch(slice1Actions.setCurrentAppScreen({ value: "Home" }));
        else if (homeDiv !== null) homeDiv.scrollIntoView();
      } else if (GuideSettings.places_map.attributes === true) {
        let htmlString = PlaceMenu.handleShowPlaceAttributes(marker.ID, SearchResultsPlacesList);
        htmlString = urlSchemeToFunctions(htmlString);
        dispatch(slice1Actions.setHTMLTitle({ value: "Place Attributes" }));
        dispatch(slice1Actions.setHTMLToSHow({ value: htmlString }));
        dispatch(slice1Actions.setShowHTMLReader({ value: true }));
        window.location.href = "#home-start";
        homeDiv = document.getElementById("home-content-start");
        if (MobileMode) dispatch(slice1Actions.setCurrentAppScreen({ value: "Home" }));
        else if (homeDiv !== null) homeDiv.scrollIntoView();
      }
      if (GuideSettings.places_map.center === true) {
        const place = marker;
        if (place == null) return;
        dispatch(slice1Actions.centerMapToPlaces({ value: [place] }));
        // if (MobileMode) dispatch(slice1Actions.setCurrentAppScreen({ value: "Map" }));
        // else window.location.href = "#map-start";
      }
      if (GuideSettings.places_map.filter === true) {
        if (place !== null) {
          dispatch(slice1Actions.ClearAllFilters({ value: null }));
          dispatch(slice1Actions.SetPlacesFilter({ value: [place] }));
          dispatch(slice1Actions.setGuide({ guide: null }));
        }
      }
      if (GuideSettings.places_map.url === true) {
        PlaceMenu.handleShowPlaceUrl(marker.ID);
      }
      return;
    }
  };

  const dragMarker = (place) => {
    var myLatlng = new window.google.maps.LatLng(place.Lat, place.Longi);
    var _marker = new window.google.maps.Marker({
      position: myLatlng,
      map: map,
      draggable: true,
      title: "Drag me!",
      icon: Place.getPOIImage(place, window.guide_obj, null), //getPOIImage(place),
    });
    _marker.setMap(map);
    setEditShape({ type: "Icon", shape: _marker, place: place });

    if (window.infowindow != null) {
      window.infowindow.close();
      window.infowindow = null;
      return;
    }
    window.infowindow = new window.google.maps.InfoWindow({
      pixelOffset: new window.google.maps.Size(0, -40),
    });
    window.infowindow.setPosition(myLatlng);
    window.infowindow.setContent("Drag me!");

    window.infowindow.open(map);

    window.google.maps.event.addListener(_marker, "dragend", () =>
      drag_end({ type: "Icon", shape: _marker, place: place })
    );
  };

  const drag_end = (es) => {
    //console.log("drag end");
    //if (EditShape === null) return;
    //console.log("drag end code");
    if (window.infowindow != null) {
      window.infowindow.close();
      window.infowindow = null;
      //return;
    }
    es.shape.setMap(null);
    es.place.Lat = es.shape.position.lat();
    es.place.Longi = es.shape.position.lng();
    dispatch(slice1Actions.setGuide({ guide: null }));
    setEditShape(null);
  };

  const polygonClicked = (polygon) => {
    setSelectedPlace(polygon.place);
    setSelectedShape(polygon);
    setPlaceModal(true);
  };

  const editPolygon = (polygon) => {
    if (EditShape !== null) {
      EditShape.shape.setMap(null);
    }
    let coordinates = [];
    polygon.coordinates.forEach((element) => {
      coordinates.push({ lat: element.latitude, lng: element.longitude });
    });
    const _polygon = new window.google.maps.Polygon({
      paths: coordinates,
      strokeColor: "#FF0000",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#FF0000",
      fillOpacity: 0.35,
      editable: true,
    });
    //console.log("init", _polygon.getPath());
    _polygon.setMap(map);
    setEditShape({ type: "Polygon", shape: _polygon, place: polygon.place });
  };

  window.handleMarkerPathLink = (place) => {
    if (place == null) return "";
    if (place.Link.type === "audio") testAudio(place.Link.value);
    else if (place.Link.type === "section") testSection(place.Link.value);
    else if (place.Link.type === "path") {
      let pathlink_place = window.guide_obj.places.getPlace(place.Link.value);
      if (pathlink_place === null) return;
      //console.log("pathlink_place", pathlink_place);
      const closest = pathlink_place.findClosestPointInPath(place.Lat, place.Longi);
      //console.log("closest", closest);
      if (closest !== null) {
        testVideo(pathlink_place, closest.elapsed_time);
      }
    }
  };

  const polylineClicked = (polyline, e) => {
    // console.log("polylineClicked", polyline.place.Points, e.latLng.lat(), e.latLng.lng());
    if (window.infowindow != null) {
      window.infowindow.close();
      window.infowindow = null;
      return;
    }
    infoWindowClicked(polyline.place, e);
    return;
    const closest = polyline.place.findClosestPointInPath(e.latLng.lat(), e.latLng.lng());
    //console.log("closest", closest);
    if (closest !== null) {
      testVideo(polyline.place, closest.elapsed_time);
      return;
    }
    // const _MapCenter = { Lat: e.latLng.lat(), Longi: e.latLng.lng() };
    // dispatch(slice1Actions.centerMapToLoc({ value: _MapCenter }));
    setSelectedPlace(polyline.place);
    setSelectedShape(polyline);
    setPlaceModal(true);
  };

  // const onPlayerStateChange = () => {
  //   myAlert("onPlayerStateChange");
  // };

  const testVideo = (pathlink_place, elapsed_time) => {
    // setYouTubeDialogShow(true);
    // return;
    // if(typeof pathlink_place.Link === "undefined") return;
    if (pathlink_place.Link.type !== "video") return;
    let video_url = pathlink_place.Link.value;
    if (typeof video_url === "undefined") return;
    if (video_url === "") return;

    let video_start = 0;
    let videoLinkOffset = 0;
    try {
      videoLinkOffset = pathlink_place.Link.offset;
      video_start = parseInt(videoLinkOffset);
    } catch (e) {}
    console.log("=========================testVideo", video_start);
    video_start += elapsed_time;
    //console.log("testVideo", video_start, elapsed_time);
    //if (video_url.includes("https://www.youtu") || video_url.includes("https://youtu")) {
    let video_url_parts = video_url.split("/");
    let yvideo = video_url_parts[video_url_parts.length - 1];
    yvideo = yvideo.replace("watch?v=", "");

    // let video_html = `<iframe id=MyYoutubeIframe" style="max-width: 100%; width: 640px; height: 480px;"
    //                       src="https://www.youtube.com/embed/${yvideo}?start=${video_start}&autoplay=1&mute=1" frameborder="0" allowfullscreen="allowfullscreen">
    //                   </iframe>`;

    // let video_html = `<div>This wil be rendered</div>
    //                   <script>
    //                     alert('testing')
    //                   </script>`;
    let random = Math.random().toString();
    let video_html = `<style>.container {
      position: relative;
      width: 100%;
      height: 0;
      padding-bottom: 56.25%;
  }
  .video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
  }
  
    </style>
    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/charts.css/dist/charts.min.css">
    <div class="container">
      <div id="player${random}" class="video"></div>
    </div>
    <!-- <div class="chart-container" style="position: relative; height:150px; width:100%;">
      <canvas id="myChart" style="width: 700; height: 150px;" height="150" width="800"></canvas>
    </div>-->
    <script src="https://www.youtube.com/iframe_api"></script>
    <!-- Write your comments here 
    <script src="https://cdn.jsdelivr.net/npm/chart.js"></script>
    <script src="https://cdnjs.cloudflare.com/ajax/libs/chartjs-plugin-annotation/2.1.0/chartjs-plugin-annotation.min.js"></script> -->
    <script id='ytscript${random}'>
          var player;
          if(window.YouTubeIframeAPIReady === true) {
            player = new YT.Player('player${random}', {
            height: '390',
            width: 'auto',
            videoId: '${yvideo}',
        playerVars: { 'autoplay': 1, 'controls': 1, 'start': ${video_start}, 'mute': 1 },
            events: {
              'onReady': onPlayerReady,
              'onStateChange': onPlayerStateChange
            }
          });
          }
        
          function onYouTubeIframeAPIReady() {
            window.YouTubeIframeAPIReady = true;
            player = new YT.Player('player${random}', {
              height: '390',
              width: 'auto',
              videoId: '${yvideo}',
          playerVars: { 'autoplay': 1, 'controls': 1, 'start': ${video_start}, 'mute': 1 },
              events: {
                'onReady': onPlayerReady,
                'onStateChange': onPlayerStateChange
              }
            });
          }
          function onPlayerReady(event) {
            event.target.playVideo();
            // const ctx = document.getElementById('myChart');
            // window.LatitudeChart = new Chart(ctx, {
            //   type: 'line',
            //   data: {
            //     labels: [],
            //     datasets: [{
            //       label: '',
            //       data: [],
            //       borderWidth: 1,
            //       tension: 0.1
            //     }]
            //   },
            // options: {
            //   tooltips: {
            //     enabled: false,
            //   },
            //   legend: {
            //     display: false
            //   },
            //   scales: {
            //     xAxes: [{display: false}],
            //     yAxes: [{display: false}],
            //   }
            // }
            // });
            // const time = player.getCurrentTime();
            // const state = player.getPlayerState();
            // window.TimeFromYoutube(time, ${pathlink_place.ID}, state, ${videoLinkOffset});
            // const new_vals = window.UpdateChart(time, ${pathlink_place.ID}, state, ${videoLinkOffset}, false);
            // chart.data = new_vals.data
            // chart.options = new_vals.options
            // chart.update("none");
            setTimeout(() => getTime(false), 1000);
          }

        function getTime(onlyLoc) {
            const time = player.getCurrentTime();
            const state = player.getPlayerState();
            window.TimeFromYoutube(time, ${pathlink_place.ID}, state, ${videoLinkOffset});
            // const new_vals = window.UpdateChart(time, ${pathlink_place.ID}, state, ${videoLinkOffset}, false);
            // window.LatitudeChart.data = new_vals.data
            // window.LatitudeChart.options = new_vals.options
            // window.LatitudeChart.update("none");
            setTimeout(() => getTime(true), 1000);
          }

          function onPlayerStateChange(event) {
            window.StateFromYoutube(event);
          }

    </script>`;

    // dispatch(slice1Actions.resetHomeScreen({ value: null }));
    // dispatch(slice1Actions.setHTMLTitle({ value: "Video" }));
    // dispatch(slice1Actions.setHTMLToSHow({ value: video_html }));
    // dispatch(slice1Actions.setShowHTMLReader({ value: true }));
    // window.location.href = "#home-start";
    // let homeDiv = document.getElementById("home-content-start");
    // homeDiv.scrollIntoView();

    setHTMLContent(video_html);
    setHTMLReaderModal(true);
  };

  const testAudio = (audio_url) => {
    let audioType = "audio/mpeg";
    if (audio_url.toUpperCase().endsWith("WAV")) audioType = "audio/wav";
    else if (audio_url.toUpperCase().endsWith("OGG")) audioType = "audio/ogg";
    else if (audio_url.toUpperCase().endsWith("MP3")) audioType = "audio/mpeg";
    else audioType = "audio/mp4";
    // const audio_html = `<audio style="max-width: 100%; width: 600px;" controls="controls" controlsList="nodownload" autoplay>
    //       <source src="${audio_url}" type="${audioType}">
    //     Audio format not supported use mp3, wav, m4a or ogg.
    //     </audio>`;

    const audio_html = `<audio style="max-width: 100%; width: 600px;" controls="controls" controlsList="nodownload" autoplay>
          <source src="${audio_url}" type="${audioType}">
        Audio format not supported use mp3, wav, m4a or ogg.
        </audio>`;
    // dispatch(slice1Actions.setHTMLTitle({ value: "Audio" }));
    // dispatch(slice1Actions.setHTMLToSHow({ value: audio_html }));
    // dispatch(slice1Actions.setShowHTMLReader({ value: true }));
    // window.location.href = "#home-start";
    // let homeDiv = document.getElementById("home-content-start");
    // homeDiv.scrollIntoView();
    setHTMLContent(audio_html);
    setHTMLReaderModal(true);
  };

  const testSection = (section_id) => {
    let section = window.guide_obj.findSection(section_id);
    if (section == null) {
      //Alert.alert("section not found");
      return;
    }
    let htmlString = urlSchemeToFunctions(section.html);
    dispatch(slice1Actions.setHTMLTitle({ value: section.Title }));
    dispatch(slice1Actions.setHTMLToSHow({ value: htmlString }));
    dispatch(slice1Actions.setShowHTMLReader({ value: true }));
    window.location.href = "#home-start";
    let homeDiv = document.getElementById("home-content-start");
    homeDiv.scrollIntoView();
    // setHTMLContent(htmlString);
    // setHTMLReaderModal(true);
  };

  const testURL = (url) => {
    let win = window.open(url, "_blank");
    //win = window.open("about:blank", "", "_blank");
    //win.document.write(_url);
    win.focus();
  };

  const closeHTMLReader = () => {
    setHTMLReaderModal(false);
    window.getTime = function () {};
  };

  const checkTime = () => {
    const elem = document.querySelector("#MyYoutubeIframe");
    if (elem === null) {
      console.log("elem is null");
      return;
    }
    const time = elem.getCurrentTime();
    console.log(time);
  };

  const editPolyline = (polyline) => {
    if (EditShape !== null) {
      EditShape.shape.setMap(null);
    }
    let coordinates = [];
    polyline.coordinates.forEach((element) => {
      coordinates.push({ lat: element.latitude, lng: element.longitude });
    });
    const _polyline = new window.google.maps.Polyline({
      path: coordinates,
      strokeColor: "#0000ff",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      // fillColor: "#0000ff",
      // fillOpacity: 0.35,
      editable: true,
    });
    //console.log("init", _polyline.getPath());
    _polyline.setMap(map);
    setEditShape({ type: "Polyline", shape: _polyline, place: polyline.place });
  };

  let boundsListen = null;
  const onMapLoad = React.useCallback(function callback(_map) {
    window.mapRef = _map;
    setMapRef(_map);
    setMyMapRef(_map);
    dispatch(slice1Actions.setMapRef({ value: _map }));

    window.google.maps.event.addListener(_map, "bounds_changed", function () {
      boundsListen = _map.getBounds();
      //  var ne = bounds.getNorthEast();
      //  var sw = bounds.getSouthWest();
      //do whatever you want with those bounds
    });

    // if (_map != null) {
    let _MapBounds = MapBounds;
    if (MapCenterPlace != null) {
      if (typeof MapCenterPlace.Lat == "string") MapCenterPlace.Lat = parseFloat(MapCenterPlace.Lat);
      if (typeof MapCenterPlace.Longi == "string") MapCenterPlace.Longi = parseFloat(MapCenterPlace.Longi);
      _MapBounds = {
        north: MapCenterPlace.Lat - 0.0007,
        south: MapCenterPlace.Lat + 0.0007,
        west: MapCenterPlace.Longi - 0.0007,
        east: MapCenterPlace.Longi + 0.0007,
      };
      //console.log("_MapBoundsMapCenterPlace", _MapBounds);
    }
    const centerControlDiv = document.createElement("div");
    CenterControl(centerControlDiv, _map);
    _map.controls[window.google.maps.ControlPosition.LEFT] = [centerControlDiv];

    const centerControlDiv2 = document.createElement("div");
    CenterControl2(centerControlDiv2, _map);
    _map.controls[window.google.maps.ControlPosition.RIGHT_TOP] = [centerControlDiv2];

    // const centerControlDiv3 = document.createElement("div");
    // CenterControl3(centerControlDiv3, _map);
    // _map.controls[window.google.maps.ControlPosition.LEFT_BOTTOM] = [centerControlDiv3];

    _map.fitBounds(_MapBounds);

    const symbolStart = {
      path: "M -2,0 0,-2 2,0 0,2 z",
      strokeColor: "#00F",
      fillColor: "#00F",
      fillOpacity: 1,
    };
    const symbolEnd = {
      path: "M -2,-2 2,2 M 2,-2 -2,2",
      strokeColor: "#292",
      strokeWeight: 4,
    };
    const symbolDirection = {
      path: "M -2,0 0,-2 2,0 -2, 0 z",
      strokeColor: "#F00",
      fillColor: "#F00",
      fillOpacity: 0.35,
    };
    optionsPolyline = {
      // strokeOpacity: 0.8,
      //strokeWeight: 2,
      fillColor: "#FFffff",
      fillOpacity: 0.35,
      clickable: true,
      draggable: false,
      editable: false,
      visible: true,
      radius: 30000,
      strokeColor: "rgba(255, 0, 0, 0.30)",
      lineDashPattern: [50, 10],
      strokeWidth: 6,

      zIndex: 2,
      icons: [
        {
          icon: symbolStart,
          offset: "0%",
        },
        // {
        //   icon: symbolDirection, //{ path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW },
        //   repeat: "50px",
        //   offset: "50%",
        // },
        {
          icon: symbolEnd,
          offset: "100%",
        },
      ],
    };
    var marker = new window.google.maps.Marker({
      map: _map,
      icon: {
        url: reticle,
        size: new window.google.maps.Size(41, 41),
        origin: new window.google.maps.Point(0, 0),
        anchor: new window.google.maps.Point(20, 20),
        //scaledSize: new window.google.maps.Size(25, 25)
      },
    });
    marker.bindTo("position", _map, "center");
  }, []);

  const shapeFromDirections = (shape) => {
    const directionsService = new window.google.maps.DirectionsService();
    const origin = { lat: window.DirectionsFromLocation.Lat, lng: window.DirectionsFromLocation.Longi };
    const destination = { lat: window.DirectionsToLocation.Lat, lng: window.DirectionsToLocation.Longi };
    directionsService.route(
      {
        origin: origin,
        destination: destination,
        travelMode: window.google.maps.TravelMode.WALKING, //DRIVING, TRANSIT, BICYCLING, WALKING
      },
      (result, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
          //console.log("result", result);
          let points = "";
          for (var i = 0; i < result.routes[0].overview_path.length; i++) {
            if (points !== "") points += ":";
            points +=
              result.routes[0].overview_path[i].lng().toFixed(6) +
              "," +
              result.routes[0].overview_path[i].lat().toFixed(6);
          }
          _mapCoordinate = {
            Lat: result.routes[0].overview_path[0].lat(),
            Longi: result.routes[0].overview_path[0].lng(),
          };
          if (shape === "Path") addPlaceToMap("PolyLine", _mapCoordinate, points);
          else addPlaceToMap("Polygon", null, points);
        } else {
          console.error(`error fetching directions ${result}`);
        }
      }
    );
  };

  const streetview = (_MapCenter) => {
    const this_map = new window.google.maps.Map(document.getElementById("map"), {
      center: _MapCenter,
      zoom: 14,
    });
    const panorama = new window.google.maps.StreetViewPanorama(document.getElementById("pano"), {
      position: _MapCenter,
      pov: {
        heading: 34,
        pitch: 10,
      },
    });

    this_map.setStreetView(panorama);
  };

  const getMapBounds = () => {
    let _mr = window.mapRef;
    let bounds = boundsListen;
    if (bounds === null) {
      if (_mr == null) _mr = map;
      if (_mr == null) _mr = MyMap;
      if (_mr == null) {
        dispatch(slice1Actions.SetLoadingGuide({ value: false }));
        return null;
      }
      bounds = _mr.getBounds();
      if (bounds == null) {
        dispatch(slice1Actions.SetLoadingGuide({ value: false }));
        return null;
      }
    }
    let ne = bounds.getNorthEast();
    let sw = bounds.getSouthWest();
    const _mb = {
      north: ne.lat(),
      south: sw.lat(),
      west: sw.lng(),
      east: ne.lng(),
    };
    return _mb;
  };

  const onBoundsChanged = () => {
    if (map == null) return;
    if (map.getBounds() == null) return;
    let ne = map.getBounds().getNorthEast();
    let sw = map.getBounds().getSouthWest();
    // console.log(ne.lat() + ";" + ne.lng());
    // console.log(sw.lat() + ";" + sw.lng());
    let _MapBounds = {
      north: ne.lat(),
      south: sw.lat(),
      west: sw.lng(),
      east: ne.lng(),
    };
    // console.log("onBoundsChanged", MapBounds);
    //console.log("onBoundsChanged", _MapBounds);
    const _mb = getMapBounds();
    if (_mb === null) return;
    if (_mb != null) dispatch(slice1Actions.setMapBoundsValue({ value: _mb }));
  };

  const getImageURLString = (str) => {
    let img = Common.getImageURL(str, Common.getFBPath(window.guide_obj.guide_id, user_info), false);

    //if (img === "google_places_logo") img = google_places_logo;
    //if (!img.startsWith("data:image")) console.log(img);
    return img;
  };
  let _mapCoordinate = null;
  let clickedLocation = null;

  const mapClicked = (e) => {
    if (window.infowindow != null) {
      window.infowindow.close();
      window.infowindow = null;
      return;
    }
    if (EditShape !== null) {
      if (EditShape.type === "Polygon") {
        const path = EditShape.shape.getPath();
        const points = getPointsFromPath(path);
        EditShape.place.Points = points;
        EditShape.shape.setMap(null);
        dispatch(slice1Actions.setGuide({ guide: null }));
      }
      if (EditShape.type === "Polyline") {
        const path = EditShape.shape.getPath();
        const points = getPointsFromPath(path);
        EditShape.place.Points = points;
        EditShape.shape.setMap(null);
        dispatch(slice1Actions.setGuide({ guide: null }));
      } else if (EditShape.type == "Icon") {
        //console.log(EditShape.shape);
        EditShape.shape.setMap(null);
        EditShape.place.Lat = EditShape.shape.position.lat();
        EditShape.place.Longi = EditShape.shape.position.lng();
        dispatch(slice1Actions.setGuide({ guide: null }));
      } else EditShape.shape.setMap(null);
      setEditShape(null);
      return;
    }
    // console.log("mapClicked", e);
    if (typeof e.placeId !== "undefined") {
      e.stop(); //stop infowindow
      onPoiClick(e);
      return;
    }
    _mapCoordinate = { Lat: e.latLng.lat(), Longi: e.latLng.lng() };
    createMapClickMenu();
    // //console.log(event.nativeEvent);
    // console.log("_mapCoordinate", _mapCoordinate);
    // //setMapCoordinate({ Lat: e.latLng.lat(), Longi: e.latLng.lng() });
    // createPlaceFromMap();
  };

  const createSearchMenu = () => {
    //console.log("createSearchMenu srl", _SearchResultsPlacesList.length);
    //console.log("SortBy", SortBy);
    const menuList = [
      // {
      //   label: "Attractions",
      //   method: searchAttractions,
      //   icon: null,
      //   checked: false,
      //   visible: true,
      //   info: "Search and display attractions on the map. You can then add one or more to the guide.",
      // },
      {
        label: "Google text search",
        method: googleTextSearch,
        icon: null,
        checked: false,
        visible: true,
        info: "Search by text and display places from google on the map. You can then add one or more to the guide.",
      },
      {
        label: "Google category search",
        method: googleCategorySearch,
        icon: <i className="fa fa-check"></i>,
        checked: false,
        visible: true,
        info: "Search by category and display places from google on the map. You can then add one or more to the guide.",
      },
      {
        label: "Wikipedia text search",
        method: wikipediaTextSearch,
        icon: null,
        checked: false,
        visible: true,
        info: "Search and display places from wikipedia on the map. You can then add one or more to the guide.",
      },
      {
        label: "Flickr text search",
        method: flickrTextSearch,
        icon: null,
        checked: false,
        visible: true,
        info: "Search and display places from wikimedia on the map. You can then add one or more to the guide.",
      },
      {
        label: "AI Search",
        method: () => setAISearchShow(true),
        icon: null,
        checked: false,
        visible: true,
        info: "Search and display places using artificial intelligence.",
      },
      // {
      //   label: "Wikidata category search",
      //   method: wikidataCategorySearch,
      //   icon: null,
      //   checked: false,
      //   visible: true,
      // },
      {
        label: "Search in Foursquare",
        method: goToFoursquare,
        icon: null,
        checked: false,
        visible: true,
        info: "Open the Foursquare web app and search. You will need to manually create places on the guide to add them",
      },
      {
        label: "Search in Yelp",
        method: goToYelp,
        icon: null,
        checked: false,
        visible: true,
        info: "Open the Yelp web app and search. You will need to manually create places on the guide to add them",
      },
      {
        label: "Search Happy Cow (vegan)",
        method: goToHappyCow,
        icon: null,
        visible: true,
        info: "Open the 'Happy Cow' web app and search. You will need to manually create places on the guide to add them",
      },
      {
        label: "Add all search results",
        method: AddAllSearchResults,
        icon: null,
        visible: true, //_SearchResultsPlacesList.length > 0,
        info: "Add all of the search results to the guide.",
      },
      {
        label: "Remove search results",
        method: RemoveSearchResults,
        icon: null,
        checked: false,
        visible: true, //_SearchResultsPlacesList.length > 0,
        info: "Remove all of the search results from the guide",
      },
    ];
    //console.log("menuList", menuList);
    setMenuModalList(menuList);
    setMenuModalHeading("Search Places Menu");
    setMenuModalImage(<i className="fas fa-search"></i>);
    setMenuModalPlace(null);
    setMenuModalShow(true);
  };
  const RemoveSearchResults = () => {
    dispatch(slice1Actions.setSearchResultsPlacesList({ value: [] }));
    dispatch(slice1Actions.setGuide({ guide: null }));
  };

  const FindSearchResult = (name, id) => {
    let index = -1;
    if (name != null) index = SearchResultsPlacesList.findIndex((x) => x.Name === name);
    else index = SearchResultsPlacesList.findIndex((x) => x.ID === id);
    if (index === -1) return null;
    return SearchResultsPlacesList[index];
  };

  const AddSearchResult = (place) => {
    dispatch(slice1Actions.addToSearchResultsPlacesList({ value: [place] }));
  };

  const AddAllSearchResults = (action) => {
    dispatch(slice1Actions.addAllResultsToGuide({ value: null }));
    dispatch(slice1Actions.setGuide({ guide: null }));
  };

  const goToYelp = () => {
    const centerRadius = getCenterAndRadius();
    let action = `https://www.yelp.com/search?find_loc=${centerRadius.center.latitude},${centerRadius.center.longitude}`;
    let htmlString = `<!DOCTYPE html>
<html>
  <head>
    <title>StreetView</title>
    <meta http-equiv='Content-Type' content='text/html; charset=utf-8'/>
  </head>
  <body>
    <script type='text/javascript'>
      window.location.href = "${action}";
    </script>
  </body>
</html>`;
    let win = window.open("about:blank", "", "_blank");
    win.document.write(htmlString);
    win.focus();
  };

  const goToFoursquare = () => {
    const centerRadius = getCenterAndRadius();
    let action = `https://foursquare.com/explore?mode=url&ll=${centerRadius.center.latitude},${centerRadius.center.longitude}&q=Top%20Picks`;

    let htmlString = `<!DOCTYPE html>
<html>
  <head>
    <title>StreetView</title>
    <meta http-equiv='Content-Type' content='text/html; charset=utf-8'/>
  </head>
  <body>
    <script type='text/javascript'>
      window.location.href = "${action}";
    </script>
  </body>
</html>`;
    let win = window.open("about:blank", "", "_blank");
    win.document.write(htmlString);
    win.focus();
  };

  const goToHappyCow = () => {
    const centerRadius = getCenterAndRadius();
    const _mb = getMapBounds();
    if (_mb === null) return;
    const lon_min = _mb.west.toFixed(6);
    const lon_max = _mb.east.toFixed(6);
    const lat_min = _mb.south.toFixed(6);
    const lat_max = _mb.north.toFixed(6);
    const bb = `${lat_min}%2C${lon_min}%2C${lat_max}%2C${lon_max}`;

    const action = `https://www.happycow.net/searchmap?s=3&location=&filters=vegan-vegetarian-vegfriendly&metric=mi&limit=81&order=default&lat=${centerRadius.center.latitude}&lng=${centerRadius.center.longitude}&zoom=15&page=1&bb=${bb}`;

    let htmlString = `<!DOCTYPE html>
    <html>
      <head>
        <title>StreetView</title>
        <meta http-equiv='Content-Type' content='text/html; charset=utf-8'/>
      </head>
      <body>
        <script type='text/javascript'>
          window.location.href = "${action}";
        </script>
      </body>
    </html>`;
    let win = window.open("about:blank", "", "_blank");
    win.document.write(htmlString);
    win.focus();
  };

  //let loadingFlag = false
  const loadingWithTimeout = () => {
    //loadingFlag = true;
    setTimeout(timeoutFunc, 30000);
    // setTimeout(() => dispatch(slice1Actions.SetLoadingGuide({ value: false })), 30000)
    dispatch(slice1Actions.SetLoadingGuide({ value: true }));
  };

  const timeoutFunc = () => {
    dispatch(slice1Actions.SetLoadingGuide({ value: false }));
  };

  const searchAttractions = () => {
    let _at = _Attractions;
    if (_Attractions == null) {
      _at = new Attractions();
      setAttractions(_at);
    }
    const _mb = getMapBounds();
    if (_mb === null) return;
    loadingWithTimeout();
    //console.log("getMapBounds", _mb);
    //return;
    _at.searchPlaces(
      {
        lon_min: _mb.west,
        lon_max: _mb.east,
        lat_min: _mb.south,
        lat_max: _mb.north,
      },
      "20",
      searchAttractionsCallback
    );
  };

  const searchAttractionsCallback = (attractions) => {
    if (attractions == null || attractions.length === 0) {
      //console.log("searchAttractionsCallback attractions", attractions);
      dispatch(slice1Actions.SetLoadingGuide({ value: false }));
      return;
    }
    //console.log("searchAttractionsCallback attractions.length", attractions.length);
    let _at = _Attractions;
    if (_Attractions == null) {
      _at = new Attractions();
      setAttractions(_at);
    }
    _at.getDetails(attractions, getAttractionsDetailsCallback);
  };

  const getAttractionsDetailsCallback = (places) => {
    dispatch(slice1Actions.setSearchResultsPlacesList({ value: places }));
    dispatch(slice1Actions.setGuide({ guide: null }));
    dispatch(slice1Actions.SetLoadingGuide({ value: false }));
  };

  const googleCategorySearch = () => {
    const items = GooglePlaces.getGoogleCategories();
    // let id = 0;
    // items.forEach((item) => {
    //   item.id = (id++).toString();
    // });
    //console.log("items", items);
    dispatch(slice1Actions.setMultiselectCallback({ value: googleCategoriesCallBack }));
    dispatch(slice1Actions.setMultiselectItems({ value: items }));
    dispatch(slice1Actions.setMultiselectSelectedItems({ value: [] }));
    dispatch(slice1Actions.setMultiselectMode({ value: "uni" }));
    dispatch(slice1Actions.setMultiselectSearchText({ value: "" }));
    dispatch(slice1Actions.setMultiselectTitle({ value: "Select Category" }));
    dispatch(slice1Actions.setShowMultiselect({ value: true }));
    // setMultiselectItems(items);
    // setMultiselectSelectedItems([]);
    // setMultiselectMode("uni");
    // //setMultiselectCallback(googleCategoriesCallBack);
    // setMultiselectTitle("Select Category");
    // setMultiselectSearchText("");
    // setShowMultiselect(true);
  };

  const googleCategoriesCallBack = (google_categories) => {
    if (google_categories == null) return;
    if (google_categories.length === 0) return;
    let cats = google_categories[0].value;
    cats = cats.replace(/ /g, "%20");
    loadingWithTimeout();
    const centerRadius = getCenterAndRadius();
    let _at = new GooglePlaces();
    _at.searchPlaces(
      `${centerRadius.center.latitude},${centerRadius.center.longitude}`,
      centerRadius.radius,
      cats,
      null,
      searchGooglePlaceCallback
    );
  };
  const googleTextSearch = () => {
    setPromptModalShow(true);
    setPromptModalTitle("Search");
    setPromptModalMessage("Google places");
    setPromptModalButtons([
      { Title: "Replace Search Results", Method: _googleTextSearchReplace },
      { Title: "Add to Search Results", Method: _googleTextSearchAdd },
      { Title: "Search in Google Maps", Method: SearchInGoogleMaps },
      { Title: "CANCEL", Method: cancelSearch },
    ]);
    setPromptModalInputs([{ name: "Search Text", value: SavedTextSearch }]);
  };

  const cancelSearch = () => {
    dispatch(slice1Actions.SetLoadingGuide({ value: false }));
  };

  const SearchInGoogleMaps = (inputs) => {
    if (inputs === null) return;
    const searchText = inputs[0].value;
    const centerRadius = getCenterAndRadius();
    let action = `//www.google.com/maps/search/${searchText}/@${centerRadius.center.latitude},${centerRadius.center.longitude},12z/data=!3m1!4b1`;
    // https://www.google.com/maps/search/pizza/@49.8180926,7.7054933,12z/data=!3m1!4b1
    let htmlString = `<!DOCTYPE html>
<html>
  <head>
    <title>Google search</title>
    <meta http-equiv='Content-Type' content='text/html; charset=utf-8'/>
  </head>
  <body>
    <script type='text/javascript'>
      window.location.href = "${action}";
    </script>
  </body>
</html>`;
    let win = window.open("about:blank", "", "_blank");
    win.document.write(htmlString);
    win.focus();
  };
  const getCenterAndRadius = () => {
    const _mb = getMapBounds();
    if (_mb === null) return { center: 0, radius: 0 };
    const distance_lat =
      Places.calculateDistance(
        {
          latitude: _mb.north,
          longitude: _mb.west,
        },
        {
          latitude: _mb.south,
          longitude: _mb.west,
        }
      ) * 1000;
    const distance_lon =
      Places.calculateDistance(
        {
          latitude: _mb.north,
          longitude: _mb.west,
        },
        {
          latitude: _mb.north,
          longitude: _mb.east,
        }
      ) * 1000;
    const radius = Math.min(distance_lat, distance_lon, 50000);
    const center = {
      latitude: (_mb.north + _mb.south) / 2,
      longitude: (_mb.west + _mb.east) / 2,
    };
    return { center: center, radius: radius };
  };

  const _googleTextSearchReplace = (inputs) => {
    addToSearch = false;
    _googleTextSearch(inputs);
  };
  const _googleTextSearchAdd = (inputs) => {
    addToSearch = true;
    _googleTextSearch(inputs);
  };

  const _googleTextSearch = (inputs) => {
    if (inputs === null) return;
    const searchText = inputs[0].value;
    setSavedTextSearch(searchText);
    //this.setState({ visiblePrompt: false, placeProvider: "google" });
    // if (this.state.searchText.length < 3) {
    //   Alert.alert("Search text must be at least 3 characters");
    //   return;
    // }
    //location, radius, type, query, callback;

    const _mb = getMapBounds();
    if (_mb === null) return;
    loadingWithTimeout();
    const distance_lat =
      Places.calculateDistance(
        {
          latitude: _mb.north,
          longitude: _mb.west,
        },
        {
          latitude: _mb.south,
          longitude: _mb.west,
        }
      ) * 1000;
    const distance_lon =
      Places.calculateDistance(
        {
          latitude: _mb.north,
          longitude: _mb.west,
        },
        {
          latitude: _mb.north,
          longitude: _mb.east,
        }
      ) * 1000;
    const radius = Math.min(distance_lat, distance_lon, 50000);
    //console.log("googleTextSearch", distance_lat, distance_lon, radius);
    //location, radius, type, query, callback
    let _at = _GooglePlaces;
    if (_GooglePlaces == null) {
      _at = new GooglePlaces();
      setGooglePlaces(_at);
    }
    _at.searchPlaces(
      `${((_mb.north + _mb.south) / 2).toString()},${((_mb.east + _mb.west) / 2).toString()}`,
      radius,
      null,
      searchText,
      searchGooglePlaceCallback
    );
  };

  const searchGooglePlaceCallback = (google_places) => {
    if (google_places == null || google_places.length == 0) {
      dispatch(slice1Actions.SetLoadingGuide({ value: false }));
      return;
    }
    //console.log("searchGooglePlaceCallback google_places", google_places);
    const _mb = getMapBounds();
    if (_mb === null) return;
    let filtered = [];
    google_places.forEach((pl) => {
      if (isBounded(_mb.north, _mb.west, _mb.south, _mb.east, pl.geometry.location.lat, pl.geometry.location.lng))
        filtered.push(pl);
    });
    // console.log("searchGooglePlaceCallback", google_places);
    // return;
    //console.log("searchGooglePlaceCallback google_places.length", google_places.length);
    //console.log("otm_places", otm_places);
    let _at = _GooglePlaces;
    if (_GooglePlaces == null) {
      _at = new GooglePlaces();
      setGooglePlaces(_at);
    }
    // Change this for quick/long details
    //_at.getDetails(google_places, getGoogleDetailsCallback);
    _at.getQuickDetails(filtered, getGoogleDetailsCallback);
  };

  const getGoogleDetailsCallback = (places) => {
    //console.log("getGoogleDetailsCallback", places);
    if (addToSearch === true) dispatch(slice1Actions.addToSearchResultsPlacesList({ value: places }));
    else dispatch(slice1Actions.setSearchResultsPlacesList({ value: places }));
    dispatch(slice1Actions.setGuide({ guide: null, fitToBounds: false }));
    dispatch(slice1Actions.SetLoadingGuide({ value: false }));
  };
  /*
   * top: north latitude of bounding box.
   * left: left longitude of bounding box (western bound).
   * bottom: south latitude of the bounding box.
   * right: right longitude of bounding box (eastern bound).
   * latitude: latitude of the point to check.
   * longitude: longitude of the point to check.
   */
  const isBounded = (top, left, bottom, right, latitude, longitude) => {
    /* Check latitude bounds first. */
    if (top >= latitude && latitude >= bottom) {
      /* If your bounding box doesn't wrap 
                     the date line the value
                     must be between the bounds.
                     If your bounding box does wrap the 
                     date line it only needs to be  
                     higher than the left bound or 
                     lower than the right bound. */
      if (left <= right && left <= longitude && longitude <= right) {
        return true;
      } else if (left > right && (left <= longitude || longitude <= right)) {
        return true;
      }
    }
    return false;
  };

  const wikidataCategorySearch = () => {
    let otm_categories_sorted = [...otm_categories];
    otm_categories_sorted = otm_categories_sorted.sort((a, b) =>
      a.label.toUpperCase() > b.label.toUpperCase() ? 1 : -1
    );
    dispatch(slice1Actions.setMultiselectCallback({ value: wikidataCategorySearchCallBack }));
    dispatch(slice1Actions.setMultiselectItems({ value: otm_categories_sorted }));
    dispatch(slice1Actions.setMultiselectSelectedItems({ value: [] }));
    dispatch(slice1Actions.setMultiselectMode({ value: "multi" }));
    dispatch(slice1Actions.setMultiselectSearchText({ value: "" }));
    dispatch(slice1Actions.setMultiselectTitle({ value: "Select Category" }));
    dispatch(slice1Actions.setShowMultiselect({ value: true }));
  };

  const wikidataCategorySearchCallBack = (otm_categories) => {
    dispatch(slice1Actions.setShowMultiselect({ value: false }));
    if (otm_categories.length == 0) return;
    let cats = "";
    otm_categories.forEach((cat) => {
      if (cats != "") cats += ",";
      cats += cat.value;
    });
    cats = cats.replace(/ /g, "%20");
    const _mb = getMapBounds();
    if (_mb === null) return;
    loadingWithTimeout();
    let _OpenTripMapPlaces = new OpenTripMapPlaces();
    _OpenTripMapPlaces.searchPlaces(
      {
        lon_min: _mb.west.toFixed(6),
        lon_max: _mb.east.toFixed(6),
        lat_min: _mb.south.toFixed(6),
        lat_max: _mb.north.toFixed(6),
      },
      "50",
      cats,
      "1",
      null,
      wikidataCategorySearchPlacesCallback
    );
  };

  const wikidataCategorySearchPlacesCallback = (otm_places) => {
    //console.log("otm_places", otm_places);
    if (otm_places == null || otm_places.length == 0) {
      dispatch(slice1Actions.SetLoadingGuide({ value: false }));
      return;
    }
    let _OpenTripMapPlaces = new OpenTripMapPlaces();

    // This code is to get places faster, but has less information e.g. no photos
    // let places = [];
    // otm_places.forEach((otm) => {
    //   let place = _OpenTripMapPlaces.parsePlaceWODetails(otm);
    //   places.push(place);
    // });
    // console.log("places", places);
    // dispatch(slice1Actions.setSearchResultsPlacesList({ value: places }));
    // dispatch(slice1Actions.setGuide({ guide: null }));
    // dispatch(slice1Actions.SetLoadingGuide({ value: false }));
    // return;

    _OpenTripMapPlaces.getDetails(otm_places, getWikidataDetailsCallback);
  };

  const getWikidataDetailsCallback = (places) => {
    //console.log("getWikidataDetailsCallback", places);

    dispatch(slice1Actions.setSearchResultsPlacesList({ value: places }));
    dispatch(slice1Actions.setGuide({ guide: null }));
    dispatch(slice1Actions.SetLoadingGuide({ value: false }));
  };

  const wikipediaTextSearch = () => {
    setPromptModalShow(true);
    setPromptModalTitle("Search");
    setPromptModalMessage("Wikipedia text");
    setPromptModalButtons([
      { Title: "Replace Search Results", Method: _wikipediaTextSearchPrompt },
      { Title: "Add to Search Results", Method: _wikipediaTextSearchPromptAdd },
      { Title: "CANCEL", Method: cancelSearch },
    ]);
    setPromptModalInputs([{ name: "Search Text", value: SavedTextSearch }]);
  };

  let addToSearch = false;

  const _wikipediaTextSearchPrompt = (inputs) => {
    let addToSearch = false;
    _wikipediaTextSearch(inputs);
  };

  const _wikipediaTextSearchPromptAdd = (inputs) => {
    let addToSearch = true;
    _wikipediaTextSearch(inputs);
  };

  const _wikipediaTextSearch = (inputs) => {
    if (inputs === null) return;
    const searchText = inputs[0].value;
    setSavedTextSearch(searchText);

    const _mb = getMapBounds();
    if (_mb === null) return;
    loadingWithTimeout();
    //console.log("PlacesHeader MapBounds", _mb);

    let deltaLat = Math.abs(Math.min(0.25, _mb.north - _mb.south)) / 2;
    let deltaLon = Math.abs(Math.min(0.18, _mb.east - _mb.west)) / 2;
    if (deltaLat > deltaLon) deltaLat = deltaLon;
    if (deltaLon > deltaLat) deltaLon = deltaLat;
    let centerLat = (_mb.north + _mb.south) / 2;
    let centerLon = (_mb.west + _mb.east) / 2;

    let _at = _Wikipedia;
    if (_Wikipedia == null) {
      _at = new Wikipedia();
      setGooglePlaces(_at);
    }
    _at.searchPlaces(
      `${(centerLat + deltaLat).toString()}|${(centerLon - deltaLon).toString()}|${(
        centerLat - deltaLat
      ).toString()}|${(centerLon + deltaLon).toString()}`,
      250,
      searchWikipediaCallback
    );
  };

  let _wikipedia_geosearch_results = [];
  let _wikepedia_pages = [];
  let _wiki_calls = 1;
  let _wiki_callbacks = 0;
  let _flickr_pages = [];

  const searchWikipediaCallback = (wikipedia_places) => {
    //console.log("searchWikipediaCallback", wikipedia_places);
    if (wikipedia_places === null) {
      dispatch(slice1Actions.SetLoadingGuide({ value: false }));
      myAlert("Try smaller search area");
      return;
    }
    if (typeof wikipedia_places["query"] == "undefined") {
      dispatch(slice1Actions.SetLoadingGuide({ value: false }));
      //console.log("searchWikipediaCallback ", wikipedia_places);
      const info =
        typeof wikipedia_places["error"]["info"] == "undefined"
          ? "Try smaller search area"
          : wikipedia_places["error"]["info"];
      dispatch(slice1Actions.SetLoadingGuide({ value: false }));
      myAlert(info);
      return;
    }

    if (wikipedia_places == null || wikipedia_places.length == 0) {
      //console.log("searchWikipediaCallback wikipedia_places, null or zero");
      dispatch(slice1Actions.SetLoadingGuide({ value: false }));
      myAlert("No results try a smaller or bigger area");
      return;
    }
    if (typeof wikipedia_places["query"] == "undefined") {
      dispatch(slice1Actions.SetLoadingGuide({ value: false }));
      return;
    }
    if (typeof wikipedia_places["query"]["geosearch"] == "undefined") {
      dispatch(slice1Actions.SetLoadingGuide({ value: false }));
      return;
    }
    _wikepedia_pages = [];
    _wikipedia_geosearch_results = wikipedia_places["query"]["geosearch"];
    //console.log("_wikipedia_geosearch_results.length", _wikipedia_geosearch_results.length);
    if (_wikipedia_geosearch_results.length == 0) {
      dispatch(slice1Actions.SetLoadingGuide({ value: false }));
      myAlert("No results try a different or smaller area");
      return;
    }
    _wiki_calls = _wikipedia_geosearch_results.length / 50;
    _wiki_callbacks = 0;
    //for (let i = 0; i < _wiki_calls; i++) {
    const number = _wikipedia_geosearch_results.length >= 50 ? 50 : _wikipedia_geosearch_results.length;
    let batch = _wikipedia_geosearch_results.slice(0, number);
    //console.log("batch", _wikipedia_geosearch_results.length, batch.length);
    let _at = new Wikipedia();
    _at.getDetails(batch, getWikipediaCallback);
    //}
  };

  const getWikipediaCallback = (places) => {
    let _at = new Wikipedia();
    const _places = _at.parsePlaces(_wikipedia_geosearch_results, places, SavedTextSearch);
    _wikepedia_pages = _wikepedia_pages.concat(_places);
    _wiki_callbacks += 1;
    if (_wiki_callbacks >= _wiki_calls) {
      processWikepediaPages();
      dispatch(slice1Actions.SetLoadingGuide({ value: false }));
      return;
    }
    const number =
      _wikipedia_geosearch_results.length >= 50 * (_wiki_callbacks + 1)
        ? 50
        : _wikipedia_geosearch_results.length - 50 * _wiki_callbacks;
    let batch = _wikipedia_geosearch_results.slice(_wiki_callbacks * 50, _wiki_callbacks * 50 + number);

    // let _at = _Wikipedia;
    // if (_at == null) {
    //   _at = new Wikipedia();
    //   //setGooglePlaces(_at);
    // }
    // let _at = new Wikipedia();
    _at.getDetails(batch, getWikipediaCallback);
  };

  const processWikepediaPages = () => {
    // _wikepedia_pages.concat(SearchResultsPlacesList);

    if (addToSearch === true) dispatch(slice1Actions.addToSearchResultsPlacesList({ value: _wikepedia_pages }));
    else dispatch(slice1Actions.setSearchResultsPlacesList({ value: _wikepedia_pages }));
    dispatch(slice1Actions.setGuide({ guide: null }));
    dispatch(slice1Actions.SetLoadingGuide({ value: false }));
  };

  const flickrTextSearch = () => {
    setPromptModalShow(true);
    setPromptModalTitle("Search");
    setPromptModalMessage("Flickr text");
    setPromptModalButtons([
      { Title: "Replace Search Results", Method: _flickrTextSearch },
      { Title: "Add to Search Results", Method: _flickrTextSearchAdd },
      { Title: "CANCEL", Method: cancelSearch },
    ]);
    setPromptModalInputs([{ name: "Search Text", value: SavedTextSearch }]);
  };

  const _flickrTextSearch = (inputs) => {
    let addToSearch = false;
    FlickrTextSearch(inputs);
  };

  const _flickrTextSearchAdd = (inputs) => {
    let addToSearch = true;
    FlickrTextSearch(inputs);
  };

  const FlickrTextSearch = (inputs) => {
    if (inputs === null) return;
    const searchText = inputs[0].value;
    setSavedTextSearch(searchText);
    const _mb = getMapBounds();
    if (_mb === null) return;
    loadingWithTimeout();

    //const fileName = this.state.fileName;
    //this.setState({ fileName: fileNameEntered });
    // if (!this.isValid(searchText)) {
    //   this.MyAlert("Message", "A valid search string is required", [{ Title: "OK", Method: null }]);
    //   return;
    // }
    // if (searchText.length < 3) {
    //   this.MyAlert("Message", "Search text must be at least 3 characters", [{ Title: "OK", Method: null }]);
    //   return;
    // }
    // this.setState({ fileName: searchText });
    // this.searchStringSubmitted();
    // this.setState({ activityIndicator: true });
    //setTimeout(this.timeoutFunc, 25000);
    let _Flickr = new Flickr();
    const map_bounds = getMapBounds();
    const centerRadius = getCenterAndRadius();
    if (map_bounds === null) map_bounds = MapBounds;
    centerRadius.radius = centerRadius.radius / 1000;
    if (centerRadius.radius > 32) centerRadius.radius = 32;
    console.log("centerRadius", centerRadius);
    let places = _Flickr.searchPhotos(
      //`${MapBoundaries.southWest.longitude.toString()},${MapBoundaries.southWest.latitude.toString()},${MapBoundaries.northEast.longitude.toString()},${MapBoundaries.northEast.latitude.toString()}`,
      //"2.27,48.80,2.42,48.9",
      `${map_bounds.south.toString()},${map_bounds.west.toString()},${map_bounds.north.toString()},${map_bounds.east.toString()}`,
      //"45.43076,10.50423,48.34905207595349,13.124949",
      searchText,
      "50",
      searchFlickrPhotosCallBack,
      centerRadius
    );
  };

  const searchFlickrPhotosCallBack = (flickr_photos) => {
    //console.log("searchFlickrPhotosCallBack", flickr_places);
    if (flickr_photos == null || flickr_photos.length == 0) {
      //console.log("searchFlickrPhotosCallBack flickr_places", flickr_places);
      dispatch(slice1Actions.SetLoadingGuide({ value: false }));
      return;
    }
    let _Flickr = new Flickr();
    // Don't get details
    // _Flickr.getDetails(flickr_places, getFlickrDetailsCallback);
    let places = _Flickr.parsePhotosNoDetails(flickr_photos);
    if (addToSearch === true) dispatch(slice1Actions.addToSearchResultsPlacesList({ value: places }));
    else dispatch(slice1Actions.setSearchResultsPlacesList({ value: places }));
    dispatch(slice1Actions.setGuide({ guide: null }));
    dispatch(slice1Actions.SetLoadingGuide({ value: false }));
  };

  const getFlickrDetailsCallback = (places) => {
    //console.log("getFlickrDetailsCallback", places);
    if (addToSearch === true) dispatch(slice1Actions.addToSearchResultsPlacesList({ value: places }));
    else dispatch(slice1Actions.setSearchResultsPlacesList({ value: places }));
    dispatch(slice1Actions.setGuide({ guide: null }));
    dispatch(slice1Actions.SetLoadingGuide({ value: false }));
  };
  // const searchFlickrPhotosCallBack = (flickr_places) => {
  //   console.log("searchFlickrPhotosCallBack", flickr_places);
  //   if (flickr_places == null) {
  //     this.setState({ activityIndicator: false });
  //     return;
  //   }
  //   let _fileList = [];
  //   flickr_places.forEach((photo) => {
  //     if (typeof photo.title === "undefined") return;
  //     const url_t =
  //       "http://farm" +
  //       photo.farm +
  //       ".static.flickr.com/" +
  //       photo.server +
  //       "/" +
  //       photo.id +
  //       "_" +
  //       photo.secret +
  //       "_" +
  //       "t.jpg";
  //     const url_m =
  //       "http://farm" +
  //       photo.farm +
  //       ".static.flickr.com/" +
  //       photo.server +
  //       "/" +
  //       photo.id +
  //       "_" +
  //       photo.secret +
  //       "_" +
  //       "z.jpg";
  //     _fileList.push({
  //       name: photo.title,
  //       url: url_t,
  //       url_m: url_m,
  //       folder: "",
  //       id: "",
  //       date: "",
  //       type: 0,
  //     });
  //   });
  //   //_fileList.sort((a, b) => a.name.toUpperCase() > b.name.toUpperCase());
  //   _fileList.sort((a, b) => {
  //     if (a.name.toUpperCase() < b.name.toUpperCase()) return -1;
  //     return a.name.toUpperCase() > b.name.toUpperCase() ? 1 : 0;
  //   });
  //   //this.setState({ fileList: _fileList });
  //   this.setState({ flickrFileList: _fileList, activityIndicator: false });
  //   dispatch(slice1Actions.SetLoadingGuide({ value: true }));
  // };

  const updateUI = () => {
    dispatch(slice1Actions.setGuide({ guide: null, fitToBounds: true }));
  };

  const SetScheduleName = (scheduleName) => {
    dispatch(slice1Actions.ClearAllFilters());
    dispatch(slice1Actions.setScheduleName({ value: scheduleName }));
    dispatch(slice1Actions.SetSchedulesFilter({ value: [{ label: scheduleName }] }));
    dispatch(slice1Actions.setGuide({ guide: null }));
  };

  const createMapMenu = () => {
    let menuList = [
      {
        label: "Get current location",
        method: getCurrentLocation,
        icon: null,
        visible: true,
        checked: false,
        info: "Display the latitude and longitude of the current location.",
      },
      {
        label: "Center to current location",
        method: centerToCurrentLocation,
        icon: null,
        visible: true,
        checked: false,
        info: "Display the latitude and longitude of the current location.",
      },
      {
        label: "Track my location",
        method: trackMyLocation,
        icon: <i className="fa fa-check"></i>,
        visible: true,
        checked: window.LocationWatchID !== null,
        info: "Move the map and display my location with a marker",
      },
      // {
      //   label: "Bicycle layer",
      //   method: showBikeLayer,
      //   icon: null,
      //   visible: true,
      //   info: "Show the bicycle paths on the map.",
      // },
      // {
      //   label: "Transit layer",
      //   method: showTransitLayer,
      //   icon: null,
      //   visible: true,
      //   info: "Show the transit info (bus etc.) on the map.",
      // },
      {
        label: "Fit all Places",
        method: FitToBounds,
        icon: null,
        visible: true,
        checked: false,
        info: "Make all of the places visible on the map.",
      },
      {
        label: "Get directions",
        method: showDirections,
        icon: null,
        visible: true,
        checked: false,
        info: "Get directions from google web app. Note you must select a from and a to place with optional waypoints (locations) on the map.",
      },
      {
        label: "Import KML/KMZ/GPX/TCX file",
        method: inputKmlFile,
        icon: null,
        visible: true,
        checked: false,
        info: "Read a KML, KMZ, GPX or TCX file and create places from it",
      },
      {
        label: "Create Places from Photos",
        method: CreatePlacesFromPhotos,
        icon: null,
        visible: true,
        checked: false,
        info: "Create Places from Photos that have location info",
      },
      {
        label: "Show Directions Menu",
        method: ShowBottomMenu,
        icon: null,
        visible: true,
        checked: false,
        info: "Read a KML file and create places from it",
      },
      // {
      //   label: "Show/hide Info Window",
      //   method: displayInfoWindow,
      //   icon: null,
      //   visible: true,
      //   checked: false,
      //   info: "Display info window for each marker",
      // },
      // {
      //   label: "Map screen",
      //   method: MapScreen,
      //   icon: null,
      //   visible: true,
      // },
    ];
    // const _MenuModalArgs = {
    //   Show: true,
    //   Image: <i className="fa fa-user"></i>,
    //   List: menuList,
    //   Heading: "Map Menu",
    //   Place: null,
    // };
    //dispatch(slice1Actions.setMenuModalArgs({ value: _MenuModalArgs }));

    setMenuModalList(menuList);
    setMenuModalHeading("Map Menu");
    setMenuModalImage(<i className="fa fa-user"></i>);
    setMenuModalPlace(null);
    setMenuModalShow(true);
  };

  const CreatePlacesFromPhotos = () => {
    setImageDialogShow(true);
  };

  const updateImageCallBack = (images) => {
    //console.log(images);
    let schedule = "Photo1";
    let scheds = window.guide_obj.schedule.geScheduleNames();
    for (let s = 0; s < scheds.length; s++) {
      schedule = "Photo" + s;
      if (!scheds.includes(schedule)) break;
    }
    let num_exif = 0;
    for (let i = 0; i < images.length; i++) {
      if (typeof images[i].exif_data === "undefined") continue;

      if (
        typeof images[i].exif_data.date === "undefined" ||
        typeof images[i].exif_data.longitude === "undefined" ||
        typeof images[i].exif_data.latitude === "undefined"
      )
        continue;
      num_exif++;
      // Add Place
      let place = addPlaceToMap(
        "Icon",
        { Lat: images[i].exif_data.latitude, Longi: images[i].exif_data.longitude },
        null,
        {
          name: images[i].name === "" ? "New Place" : images[i].name,
          info: "",
          imgURL: images[i].url_m,
          imgURL_t: images[i].url,
          poi_img: "208",
          // Group: "21",
          // Category: "117",
        }
      );

      //   let image_date = DateTime.fromISO(images[i].exif_data.date);
      //   let event = new Appointment(window.guide_obj.schedule);
      //   event.Subject = images[i].name;
      //   event.Body = "";
      //   event.Itinerary = schedule;
      //   event.Start = image_date.toISO();
      //   event.End = image_date.toISO();
      //   if (!event.Places.includes(place.ID)) event.Places = place.ID;
      //   window.guide_obj.schedule.addEvent(event);
    }
    if (num_exif === 0) {
      myAlert("No photos had location and date info", "Message");
      return;
    } else if (num_exif !== images.length) myAlert("Some photos had no location or date info", "Message");
    // dispatch(slice1Actions.setScheduleName({ value: schedule }));
    dispatch(slice1Actions.setGuide({ guide: null, fitToBounds: true }));
  };

  const ShowBottomMenu = () => {
    // setShowOffcanvas(!ShowOffcanvas);
    dispatch(slice1Actions.setShowOffcanvas({ value: true }));
  };

  // const displayInfoWindow = () => {
  //   setShowInfoWindow(!showInfoWindow);
  //   //window.google.maps.infowindow.close();
  //   //console.log("window.google.maps", window.google.maps);
  //   setTimeout(timeoutRender, 1000);
  // };

  // const timeoutRender = () => {
  //   dispatch(slice1Actions.setGuide({ guide: null, fitToBounds: true }));
  // };

  const inputKmlFile = () => {
    setKMLInputShow(true);
  };
  const importKmlCallback = (ret) => {
    //console.log("importKmlCallback", ret);
  };

  //var MapScreenImage = "";

  const MapScreen = () => {
    dispatch(slice1Actions.setMapFullScreen({ value: !MapFullScreen }));
    // MapScreenImage.src = dont_size_to_fit_icon;
    setTimeout(timeoutFit, 1000);
  };

  var SizeToFitImage = size_to_fit_icon;

  const SizeToFitSetting = () => {
    SizeToFitImage.src = SizeMapToFit === true ? dont_size_to_fit_icon : size_to_fit_icon;
    dispatch(slice1Actions.setSizeMapToFit({ value: !SizeMapToFit }));
    // MapScreenImage.src = dont_size_to_fit_icon;
    //setTimeout(timeoutFit, 1000);
  };

  const timeoutFit = () => {
    dispatch(slice1Actions.setGuide({ guide: null, fitToBounds: true }));
  };

  const PlaceModalAction = (action) => {
    //console.log(action, "action");
    if (action === "edit shape") {
      if (selectedShape.place.PType === "Polygon") editPolygon(selectedShape);
      else if (selectedShape.place.PType === "PolyLine") editPolyline(selectedShape);
      else dragMarker(selectedShape.place);
    }
  };

  const FitToBounds = () => {
    // const maxMins = window.guide_obj.places.getMaxMinLatLon();
    // const __MapBounds = {
    //   north: maxMins.max_lat,
    //   south: maxMins.min_lat,
    //   west: maxMins.min_lon,
    //   east: maxMins.max_lon,
    // };
    // console.log("FitToBounds", __MapBounds);

    //if (MapRef !== null && typeof MapRef !== "undefined") MapRef.fitBounds(MapBounds);
    dispatch(slice1Actions.setGuide({ guide: null, fitToBounds: true, override: true }));
    // if (MyMap == null) return;
    // MyMap.fitBounds(MapBounds);
  };

  let directionsMarker = {};
  const createDirectionsMenu = (marker, index) => {
    directionsMarker = { marker: marker, index: index };
    let menuList = [
      {
        label: "Clear direction Marker",
        method: DeleteDirectionMarker,
        icon: null,
        visible: true,
        info: "Remove this direction marker from the map.",
      },
      {
        label: "Clear all direction Markers",
        method: DeleteDirectionsMarkers,
        icon: null,
        visible: true,
        info: "Remove all direction markers from the map.",
      },
      {
        label: "Show Directions dialog",
        method: ShowDirectionsDialog,
        icon: null,
        visible: true,
        info: "Show the Directions dialog",
      },
    ];
    const _MenuModalArgs = {
      Show: true,
      Image: <i className="fa fa-user"></i>,
      List: menuList,
      Heading: "Map Menu",
      Place: null,
    };
    //dispatch(slice1Actions.setMenuModalArgs({ value: _MenuModalArgs }));
    setMenuModalList(menuList);
    setMenuModalHeading("Directions Menu");
    setMenuModalImage(<i className="fa fa-user"></i>);
    setMenuModalPlace(null);
    setMenuModalShow(true);
  };

  const DeleteDirectionMarker = () => {
    if (directionsMarker.marker === "fromMarker") {
      window.DirectionsFromLocation = null;
      dispatch(slice1Actions.setDirectionsFromLocation({ value: null }));
      window.DirectionsFromPlace = [];
    }
    if (directionsMarker.marker === "toMarker") {
      window.DirectionsToLocation = null;
      dispatch(slice1Actions.setDirectionsToLocation({ value: null }));
      window.DirectionsToPlace = [];
    }
    if (directionsMarker.marker === "waypointMarker") {
      let way = JSON.parse(JSON.stringify(window.DirectionsWaypointsLocation));
      let placeLocation = window.DirectionsWaypointsLocation[directionsMarker.index];
      way.splice(directionsMarker.index, 1);
      window.DirectionsWaypointsLocation = way;
      dispatch(slice1Actions.setDirectionsWaypointsLocation({ value: way }));
      let places = window.DirectionsWaypointPlaces;
      for (let i = 0; i < window.DirectionsWaypointPlaces; i++) {
        if (placeLocation.Name === window.DirectionsWaypointPlaces[i].Name) {
          places.splice(i, 1);
          break;
        }
      }
      window.DirectionsWaypointPlaces = places;
    }
  };

  const DeleteDirectionsMarkers = () => {
    window.DirectionsFromLocation = null;
    window.DirectionsToLocation = null;
    window.DirectionsWaypointsLocation = [];
    dispatch(slice1Actions.setDirectionsFromLocation({ value: null }));
    dispatch(slice1Actions.setDirectionsToLocation({ value: null }));
    dispatch(slice1Actions.setDirectionsWaypointsLocation({ value: [] }));
    dispatch(slice1Actions.setGuide({ guide: null }));
    window.DirectionsWaypointPlaces = [];
    window.DirectionsFromPlace = [];
    window.DirectionsToPlace = [];
  };

  const ShowDirectionsDialog = () => {
    //setDirectionsDialogShow(true);
    //setShowOffcanvas(true);
    dispatch(slice1Actions.setShowOffcanvas({ value: true }));
  };

  const createMapClickMenu = (e) => {
    //console.log("_mapCoordinate", _mapCoordinate);
    setClickLocation(_mapCoordinate);
    let menuList = [
      // {
      //   label: "Count search results",
      //   method: getSearchResultsCount,
      //   icon: null,
      //   visible: true,
      // },
      {
        label: "Display coordinates clicked",
        method: getClickedLocation,
        icon: null,
        visible: true,
        info: "Get the latitude and longitude of the location just clicked on the map.",
      },
      {
        label: "Set guide base location",
        method: setBaseLocation,
        icon: null,
        visible: true,
        info: "Set the guides location as the location just clicked on the map. Note: this is default guide location.",
      },
      {
        label: "Add location here",
        method: addLocationToMap,
        icon: null,
        visible: true,
        info: "Adds a location marker at this position on the map",
      },
      {
        label: "Get Weather Forecast",
        method: GetWeatherForecast,
        icon: null,
        visible: true,
        info: "Open a web app with the weather for the location just clicked on the map.",
      },
      {
        label: "Directions from here",
        method: directionsFromHere,
        icon: null,
        visible: true,
        info: "Set the location just clicked on the map as the 'from' location for directions. Note: to get directions you will need to click on 'Get directions' from the map 'Menu'",
      },
      {
        label: "Directions add waypoint",
        method: directionsAddWaypoint,
        icon: null,
        visible: true,
        info: "Set the location just clicked on the map as a waypoint location for directions. Note: to get directions you will need to click on 'Get directions' from the map 'Menu'",
      },
      {
        label: "Directions to here",
        method: directionsToHere,
        icon: null,
        visible: true,
        info: "Set the location just clicked on the map as the 'to' location for directions. Note: to get directions you will need to click on 'Get directions' from the map 'Menu'",
      },
      {
        label: "Ask a travel question",
        method: () => setAiDialogShow(true),
        icon: null,
        visible: true,
        info: "Use artificial intelligence to get the best answer to questions about this location",
      },
    ];
    const _MenuModalArgs = {
      Show: true,
      Image: <i className="fa fa-user"></i>,
      List: menuList,
      Heading: "Map Menu",
      Place: null,
    };
    //dispatch(slice1Actions.setMenuModalArgs({ value: _MenuModalArgs }));
    setMenuModalList(menuList);
    setMenuModalHeading("Map Menu");
    setMenuModalImage(<i className="fa fa-user"></i>);
    setMenuModalPlace(null);
    setMenuModalShow(true);
  };

  const directionsFromHere = () => {
    dispatch(
      slice1Actions.setDirectionsFromLocation({
        value: { Name: "Map location", Lat: _mapCoordinate.Lat, Longi: _mapCoordinate.Longi },
      })
    );
    window.DirectionsFromLocation = { Name: "Map location", Lat: _mapCoordinate.Lat, Longi: _mapCoordinate.Longi };
  };

  const directionsToHere = () => {
    dispatch(
      slice1Actions.setDirectionsToLocation({
        value: { Name: "Map location", Lat: _mapCoordinate.Lat, Longi: _mapCoordinate.Longi },
      })
    );
    window.DirectionsToLocation = { Name: "Map location", Lat: _mapCoordinate.Lat, Longi: _mapCoordinate.Longi };
  };

  const directionsAddWaypoint = () => {
    let wayp = JSON.parse(JSON.stringify(DirectionsWaypointsLocation));
    wayp.push({
      Name: "Map location",
      Lat: _mapCoordinate.Lat,
      Longi: _mapCoordinate.Longi,
    });
    dispatch(slice1Actions.setDirectionsWaypointsLocation({ value: wayp }));
    window.DirectionsWaypointsLocation = wayp;
  };

  const showDirections = () => {
    //setDirectionsDialogShow(true);
    //setShowOffcanvas(true);
    dispatch(slice1Actions.setShowOffcanvas({ value: true }));
    return;
    if (window.DirectionsFromLocation === null) {
      myAlert("Must select 'From' location");
      return;
    }
    if (window.DirectionsToLocation === null) {
      myAlert("Must select 'To' location");
      return;
    }
    let waypoints = "";
    if (DirectionsWaypointsLocation.length !== 0) {
      waypoints = "&waypoints=";
      for (let i = DirectionsWaypointsLocation.length - 1; i >= 0; i--) {
        waypoints += waypoints === "&waypoints=" ? "" : "|";
        waypoints += `${DirectionsWaypointsLocation[i].Lat},${DirectionsWaypointsLocation[i].Longi}`;
      }
    }
    let _url = `https://www.google.com/maps/dir/?api=1&destination=${window.DirectionsToLocation.Lat},${window.DirectionsToLocation.Longi}${waypoints}`;
    if (window.DirectionsFromLocation !== null)
      _url = `https://www.google.com/maps/dir/?api=1&origin=${window.DirectionsFromLocation.Lat},${window.DirectionsFromLocation.Longi}&destination=${window.DirectionsToLocation.Lat},${window.DirectionsToLocation.Longi}${waypoints}`;
    //console.log("showDirections _url:", _url, DirectionsWaypointsLocation, waypoints);
    //console.log("showDirections DirectionsWaypointsLocation", DirectionsWaypointsLocation);
    //console.log("showDirections waypoints", waypoints);
    _url = _url + "&travelmode=walking"; //driving is the other option
    let win = window.open(_url, "_blank");
    win.focus();
    // dispatch(slice1Actions.setWaypointsArray({ value: [] }));
    // dispatch(slice1Actions.setFromLoc({ value: null }));
    // dispatch(slice1Actions.setToLoc({ value: null }));
    dispatch(slice1Actions.setDirectionsToLocation({ value: null }));
    dispatch(slice1Actions.setDirectionsFromLocation({ value: null }));
    dispatch(slice1Actions.setDirectionsWaypointsLocation({ value: [] }));
    window.DirectionsFromLocation = null;
    window.DirectionsToLocation = null;
    window.DirectionsWaypointsLocation = null;
  };

  const GetWeatherForecast = () => {
    const _url = `https://www.wunderground.com/forecast/${_mapCoordinate.Lat},${_mapCoordinate.Longi}`;
    let win = window.open(_url, "_blank");
    win.focus();
  };

  function setBaseLocation() {
    window.guide_obj.attributes.base_location = {
      latitude: _mapCoordinate.Lat,
      longitude: _mapCoordinate.Longi,
    };
    dispatch(slice1Actions.setGuide({ guide: null, fitToBounds: true }));
  }

  function showSatelliteView() {
    map.setMapTypeId("satellite");
  }

  function showRoadmapView() {
    map.setMapTypeId("roadmap");
  }

  function showBikeLayer() {
    setBikeLayer(!BikeLayer);
  }

  function showTransitLayer() {
    setTransitLayer(!_TransitLayer);
  }

  // const getSearchResultsCount = () => {
  //   myAlert(`Search results: ${_SearchResultsPlacesList.length} ${SearchResultsPlacesList.length}`);
  // };

  function centerToCurrentLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(centerPosition, centerToCurrentLocationError, {
        maximumAge: 60000,
        timeout: 5000,
        enableHighAccuracy: true,
      });
    } else {
      myAlert("Geolocation is not supported by this browser.");
    }
  }

  function getCurrentLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition, centerToCurrentLocationError, {
        maximumAge: 60000,
        timeout: 5000,
        enableHighAccuracy: true,
      });
    } else {
      myAlert("Geolocation is not supported by this browser.");
    }
  }
  function centerToCurrentLocationError() {
    myAlert("Error getting current position");
  }
  function centerPosition(position) {
    const _MapCenter = { Lat: position.coords.latitude, Longi: position.coords.longitude };
    dispatch(slice1Actions.centerMapToLoc({ value: _MapCenter }));
  }

  function showPosition(position) {
    const x = "Latitude: " + position.coords.latitude + "<br>Longitude: " + position.coords.longitude;
    // console.log(x);
    myAlert(`Current position (lat/lng) is: ${position.coords.latitude},${position.coords.longitude}`);
  }

  function success(position) {
    //console.log("trackMyLocation", position.coords.latitude, position.coords.longitude);
    setTrackedLocation({ latitude: position.coords.latitude, longitude: position.coords.longitude });
    const _MapCenter = { Lat: position.coords.latitude, Longi: position.coords.longitude };
    dispatch(slice1Actions.centerMapToLoc({ value: _MapCenter }));
  }

  function error() {
    if (window.LocationWatchID != null) {
      navigator.geolocation.clearWatch(window.LocationWatchID);
      window.LocationWatchID = null;
      setTrackedLocation(null);
    }
    myAlert("Sorry, no position available.");
  }

  const options = {
    enableHighAccuracy: true,
    maximumAge: 30000,
    timeout: 27000,
  };

  const trackMyLocation = () => {
    if (window.LocationWatchID !== null && typeof window.LocationWatchID !== "undefined") {
      navigator.geolocation.clearWatch(window.LocationWatchID);
      window.LocationWatchID = null;
      setTrackedLocation(null);
      dispatch(slice1Actions.setGuide({ guide: null }));
      return;
    }
    const watchID = navigator.geolocation.watchPosition(success, error, options);
    window.LocationWatchID = watchID;
  };

  function getClickedLocation() {
    // console.log("_mapCoordinate", _mapCoordinate);
    myAlert(`Location clicked is: ${_mapCoordinate.Lat},${_mapCoordinate.Longi}`);
  }

  const createPlaceFromMap = () => {
    let menuList = [
      {
        label: "Add place here",
        method: addPlaceToMap,
        icon: null,
        visible: true,
        info: "Adds a place at this position on the map",
      },
    ];
    const _MenuModalArgs = {
      Show: true,
      Image: <i className="fa fa-user"></i>,
      List: menuList,
      Heading: "Map Menu",
      Place: null,
    };
    //({ value: _MenuModalArgs }));
    setMenuModalList(menuList);
    setMenuModalHeading("Add Place");
    setMenuModalImage(<i className="fa fa-user"></i>);
    setMenuModalPlace(null);
    setMenuModalShow(true);
  };

  const addLocationToMap = () => {
    //${_mapCoordinate.Lat},${_mapCoordinate.Longi}`);
    addPlaceToMap("Icon", _mapCoordinate, null, { name: "New Place", info: "", imgURL: "" });
  };

  const addPlaceToMap = (PType, Point, Points, params) => {
    let shape = "New Place";
    if (PType === "Polygon") shape = "New Area";
    else if (PType === "PolyLine") shape = "New Path";
    let new_place = new Place(window.guide_obj.places);
    new_place.Name = `${shape}`;
    if (params != null) {
      new_place.Name = params.name;
      new_place.Info = params.info;
      new_place.Photo_m = params.imgURL;
      if (typeof params.imgURL_t !== "undefined") new_place.Photo = params.imgURL_t;
      if (typeof params.poi_img !== "undefined") new_place.poi_img = params.poi_img;
      // if (typeof params.Group !== "undefined") new_place.Group = [params.Group];
      // if (typeof params.Category !== "undefined") new_place.Category = [params.Category];
    }
    // console.log("_mapCoordinate", _mapCoordinate);
    new_place.Lat = Point.Lat; //_mapCoordinate.Lat;
    new_place.Longi = Point.Longi; //_mapCoordinate.Longi;
    new_place.PType = PType;
    new_place.Points = Points;
    if (PType === "Icon") new_place.Points = null;
    // console.log(new_place);
    //this.props.onAddPlace(new_place);
    window.guide_obj.places.addPlace(new_place);

    let _ShowHiddenPlace = JSON.parse(JSON.stringify(showHiddenPlace));
    _ShowHiddenPlace.push(new_place);
    dispatch(slice1Actions.setShowHiddenPlace({ value: _ShowHiddenPlace }));

    //dispatch(slice1Actions.setShowHiddenPlace({ value: [new_place] }));
    dispatch(slice1Actions.setGuide({ guide: null }));
    return new_place;
  };

  const onLoad = (drawingManager) => {
    // console.log(drawingManager);
  };

  const onPolygonComplete = (polygon) => {
    // console.log(polygon);
  };

  const onMarkerComplete = (marker) => {
    // console.log(marker);
  };

  const onPolylineComplete = (polyline) => {
    // console.log(polyline);
  };

  const onRectangleComplete = (rectangle) => {
    // console.log(rectangle);
  };

  const onCircleComplete = (circle) => {
    // console.log(circle);
  };

  const onButtonClick = () => {
    // console.log("onButtonClick");
  };

  const onPlacesChanged = (e) => console.log(e);
  let streetViewService = null;
  const onPOILoad = (streetViewService) => {
    streetViewService = streetViewService;
    //console.log(streetViewService);
    streetViewService.getPanorama(
      {
        location: "center",
        radius: 50,
      },
      (data, status) => console.log("StreetViewService results", data, status)
    );
  };

  let _poi = null;
  const onPoiClick = (e) => {
    //console.log("onPoiClick", e);
    _poi = e;
    createPoiMenu();
  };

  const createPoiMenu = () => {
    let menuList = [
      {
        label: "View POI in google Maps",
        method: viewPOI,
        icon: null,
        visible: true,
        info: "Show this POI (Point of Interest) in google maps and get more details",
      },
      {
        label: "Add POI to Guide",
        method: addPOIToguide,
        icon: null,
        visible: true,
        info: "Add this POI (Point of Interest) to the guide",
      },
      // {
      //   label: "Directions from here",
      //   method: directionsFromPOI,
      //   icon: null,
      //   visible: true,
      //   info: "Get directions from this POI (Point of Interest). Note: you need to select a 'to' location and then from the map menu select 'Get Directions'",
      // },
      // {
      //   label: "Directions add waypoint",
      //   method: directionsAdWaypointPOI,
      //   icon: null,
      //   visible: true,
      //   info: "Add this POI (Point of Interest) as a waypoint. Note: you need to select a 'to' location, optional waypoints, and then from the map menu select 'Get Directions'",
      // },
      // {
      //   label: "Directions to here",
      //   method: directionsToPOI,
      //   icon: null,
      //   visible: true,
      //   info: "Get directions to this POI (Point of Interest). Note: you need to select a 'from' location, optional waypoints, and then from the map menu select 'Get Directions'",
      // },
    ];
    const _MenuModalArgs = {
      Show: true,
      Image: <i className="fa fa-user"></i>,
      List: menuList,
      Heading: "Map Menu",
      Place: null,
    };
    //dispatch(slice1Actions.setMenuModalArgs({ value: _MenuModalArgs }));

    setMenuModalList(menuList);
    setMenuModalHeading("POI Menu");
    setMenuModalImage(<i className="fa fa-user"></i>);
    setMenuModalPlace(null);
    setMenuModalShow(true);
  };

  const directionsFromPOI = () => {
    dispatch(
      slice1Actions.setDirectionsFromLocation({
        value: { Name: "POI", Lat: _poi.coordinate.latitude, Longi: _poi.coordinate.longitude },
      })
    );
    window.DirectionsFromLocation = { Name: "POI", Lat: _poi.coordinate.latitude, Longi: _poi.coordinate.longitude };
  };

  const directionsToPOI = () => {
    dispatch(
      slice1Actions.setDirectionsToLocation({
        value: { Name: "POI", Lat: _poi.coordinate.latitude, Longi: _poi.coordinate.longitude },
      })
    );
    window.DirectionsToLocation = { Name: "POI", Lat: _poi.coordinate.latitude, Longi: _poi.coordinate.longitude };
  };

  const directionsAdWaypointPOI = () => {
    let wayp = JSON.parse(JSON.stringify(DirectionsWaypointsLocation));
    wayp.push({
      Name: "POI location",
      Lat: _poi.coordinate.latitude,
      Longi: _poi.coordinate.longitude,
    });
    dispatch(slice1Actions.setDirectionsWaypointsLocation({ value: wayp }));
    window.DirectionsWaypointsLocation = wayp;
  };

  const showPOIInMap = () => {
    this.centerMapTo(_poi.coordinate.latitude, _poi.coordinate.longitude);
  };

  const showPOIInfo = () => {
    let _at = _GooglePlaces;
    if (_GooglePlaces == null) {
      _at = new GooglePlaces();
      setGooglePlaces(_at);
    }
    _at.getOneDetails(showPOIInfoCallback, { place_id: _poi.placeId });
  };

  const showPOIInfoCallback = (place, details) => {
    //console.log("showPOIInfoCallback", details);
    // let _at = _GooglePlaces;
    // if (_GooglePlaces == null) {
    //   _at = new GooglePlaces();
    //   setGooglePlaces(_at);
    // }
    let new_place = GooglePlaces.parsePOIStatic(_poi, details, []);
    this.props.navigation.navigate("PlaceDetails", { place: new_place });
  };

  const addPOIToguide = () => {
    let _at = new GooglePlaces();
    // if (_GooglePlaces == null || typeof _GooglePlaces === "undefined") {
    //   _at = new GooglePlaces();
    //   setGooglePlaces(_at);
    // }
    _at.getOneDetails(addPOIToguideCallback, { place_id: _poi.placeId });
  };

  const viewPOI = () => {
    let _at = _GooglePlaces;
    if (_GooglePlaces == null) {
      _at = new GooglePlaces();
      setGooglePlaces(_at);
    }
    _at.getOneDetails(viewPOICallback, { place_id: _poi.placeId });
  };

  const viewPOICallback = (place, details) => {
    if (details == null) return;
    // console.log("addPOIToguideCallback", details);
    // let _at = _GooglePlaces;
    // if (_GooglePlaces == null) {
    //   _at = new GooglePlaces();
    //   setGooglePlaces(_at);
    // }

    let new_place = GooglePlaces.parsePOIStatic(_poi, details, []);
    showPlaceMoreInfoIframe(new_place);
  };
  const showPlaceMoreInfoIframe = (place) => {
    //place = window.guide_obj.places.getPlace(urlParts[1]);
    if (place == null) return;
    //console.log(place);
    //dispatch(slice1Actions.setSelectedPlace({ place: place }));
    let link = place.getFirstHLink();
    if (link !== "") {
      //this.props.nav.navigate("StreetView", { place: null, mode: "url", url_string: link, title: "Place Info" });
      let action = link;
      //     let action = "https://andiamotravelapp.com/api/iframe.php?url=" + link;
      //     action =
      //       "https://andiamotravelapp.com/api/iframe.php?url=https://www.wunderground.com/forecast/50.5358651,14.125994";
      //     let iframe = `
      //   <iframe id="inlineFrame"
      //     title="Inline Frame"
      //     width="800"
      //     height="500"
      //     src="${action}">
      // </iframe>`;

      //     // let htmlString = PlaceMenu.handleShowPlaceAttributes(props.i, SearchResultsPlacesList);
      //     // htmlString = urlSchemeToFunctions(htmlString);
      //     // htmlString = urlSchemeToFunctions(htmlString);
      //     dispatch(slice1Actions.setHTMLTitle({ value: "iframe" }));
      //     dispatch(slice1Actions.setHTMLToSHow({ value: iframe }));
      //     dispatch(slice1Actions.setShowHTMLReader({ value: true }));
      //     window.location.href = "#home-start";
      //     var homeDiv = document.getElementById("home-content-start");
      //     if (MobileMode) dispatch(slice1Actions.setCurrentAppScreen({ value: "Home" }));
      //     else homeDiv.scrollIntoView();
      //     return;

      let htmlString = `<!DOCTYPE html>
<html>
  <head>
    <title>StreetView</title>
    <meta http-equiv='Content-Type' content='text/html; charset=utf-8'/>
  </head>
  <body>
    <script type='text/javascript'>
      window.location.href = "${action}";
    </script>
  </body>
</html>`;
      //win = window.open(htmlString, "_blank");
      let win = window.open("about:blank", "", "_blank");
      win.document.write(htmlString);
      win.focus();
    }
  };
  const showPlaceMoreInfo = (place) => {
    //place = window.guide_obj.places.getPlace(urlParts[1]);
    if (place == null) return;
    //console.log(place);
    //dispatch(slice1Actions.setSelectedPlace({ place: place }));
    let link = place.getFirstHLink();
    if (link !== "") {
      //this.props.nav.navigate("StreetView", { place: null, mode: "url", url_string: link, title: "Place Info" });
      let action = link;
      let htmlString = `<!DOCTYPE html>
  <html>
    <head>
      <title>StreetView</title>
      <meta http-equiv='Content-Type' content='text/html; charset=utf-8'/>
    </head>
    <body>
      <script type='text/javascript'>
        window.location.href = "${action}";
      </script>
    </body>
  </html>`;
      //win = window.open(htmlString, "_blank");
      let win = window.open("about:blank", "", "_blank");
      win.document.write(htmlString);
      win.focus();
    }
  };

  const addPOIToguideCallback = (place, details) => {
    console.log("addPOIToguideCallback", details);
    if (details == null) return;
    //console.log("addPOIToguideCallback", _poi, details);
    // let _at = _GooglePlaces;
    // if (_GooglePlaces == null) {
    //   _at = new GooglePlaces();
    //   setGooglePlaces(_at);
    // }
    let new_place = GooglePlaces.parsePOIStatic(_poi, details, []);
    if (new_place === null) return;
    //console.log("addPOIToguideCallback", new_place);

    window.guide_obj.places.addPlace(new_place);

    let _ShowHiddenPlace = JSON.parse(JSON.stringify(showHiddenPlace));
    //console.log("_ShowHiddenPlace", _ShowHiddenPlace);
    _ShowHiddenPlace.push(new_place);
    dispatch(slice1Actions.setShowHiddenPlace({ value: _ShowHiddenPlace }));

    //dispatch(slice1Actions.setShowHiddenPlace({ value: [new_place] }));
    dispatch(slice1Actions.setGuide({ guide: null }));
  };

  const setPOICurrentLocation = () => {
    const location = {
      latitude: parseFloat(_poi.coordinate.latitude),
      longitude: parseFloat(_poi.coordinate.longitude),
    };
    this.props.onSetCurrentLocation(location);
  };

  const placeDrawn = (geometry, x) => {
    //console.log("geometry type: ", geometry.getType()); // Point LineString Polygon
    //console.log("geometry location: ", geometry.get().lat(), geometry.get().lng());
    if (geometry.getType() === "Point") {
      _mapCoordinate = { Lat: geometry.get().lat(), Longi: geometry.get().lng() };
      // console.log("point _mapCoordinate", _mapCoordinate);
      addPlaceToMap("Icon", _mapCoordinate, "");
      return;
    }

    //   if(geometry.getType() === 'Polygon'){
    //     geometry.forEachLatLng(function(latlng){
    //     bounds.extend(latlng);
    //   });
    // }

    if (geometry.getType() === "Polygon") {
      //console.log("polygon", getPointsFromGeometry(geometry));
      const points = getPointsFromGeometry(geometry);
      _mapCoordinate = points._mapCoordinate;
      addPlaceToMap("Polygon", _mapCoordinate, points.points);
      return;
    }
    //_mapCoordinate = { Lat: geometry.g[0].lat(), Longi: geometry.g[0].lng() };
    //console.log("polyline", _mapCoordinate);
    const points = getPointsFromGeometry(geometry);
    _mapCoordinate = points._mapCoordinate;
    addPlaceToMap("PolyLine", _mapCoordinate, points.points);
  };

  const getPointsFromGeometry = (geometry) => {
    let points = "";
    let _mapCoordinate = null;
    geometry.forEachLatLng(function (latlng) {
      _mapCoordinate = { Lat: latlng.lat(), Longi: latlng.lng() };
      if (points !== "") points += ":";
      points += latlng.lng().toFixed(6) + "," + latlng.lat().toFixed(6);
    });
    //console.log({ points: points, _mapCoordinate: _mapCoordinate });
    return { points: points, _mapCoordinate: _mapCoordinate };
  };

  const getPoints = (coordinates) => {
    let points = "";
    for (let i = 0; i < coordinates.length; i++) {
      if (points !== "") points += ":";
      points += coordinates[i].lng().toFixed(6) + "," + coordinates[i].lat().toFixed(6);
    }
    return points;
  };
  const getPointsFromArray = (coordinates) => {
    let points = "";
    for (let i = 0; i < coordinates.length; i++) {
      if (points !== "") points += ":";
      points += coordinates[i].toFixed(6) + "," + coordinates[i].toFixed(6);
    }
    return points;
  };
  const getPointsFromPath = (path) => {
    let points = "";
    for (let i = 0; i < path.length; i++) {
      if (points !== "") points += ":";
      points += path.getAt(i).lng().toFixed(6) + "," + path.getAt(i).lat().toFixed(6);
    }
    return points;
  };

  //console.log("markers", markers);
  // markers.forEach((marker) => {
  //   marker.poi_src = marker.getPOIImage(window.guide_obj);
  // });

  // const get_poi_img = (place) =>
  // {
  //   if(isSearchResult(place.Source)) return ()

  // }
  // const options = {
  //   mapTypeId = google.maps.MapTypeId.SATELLITE,
  // };

  const divStyle = {
    background: "white",
    border: "1px solid #ccc",
    paddingLeft: 15,
    paddingRight: 5,
    paddingTop: 5,
    paddingBottom: 5,
    //height:
  };

  const onClick = () => {
    console.info("I have been clicked!");
  };
  function CenterControl(controlDiv, map) {
    // Set CSS for the control border.
    const controlUI = document.createElement("div");

    controlUI.style.backgroundColor = "#fff";
    controlUI.style.border = "2px solid #fff";
    controlUI.style.borderRadius = "3px";
    controlUI.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
    controlUI.style.cursor = "pointer";
    controlUI.style.marginTop = "8px";
    controlUI.style.marginLeft = "4px";
    controlUI.style.marginBottom = "22px";
    controlUI.style.textAlign = "center";
    controlUI.title = "Map Menu";
    controlDiv.appendChild(controlUI);

    // Set CSS for the control interior.
    const controlText = document.createElement("div");

    controlText.style.color = "rgb(25,25,25)";
    controlText.style.fontFamily = "Roboto,Arial,sans-serif";
    controlText.style.fontSize = "16px";
    controlText.style.lineHeight = "38px";
    controlText.style.paddingLeft = "5px";
    controlText.style.paddingRight = "5px";
    controlText.innerHTML = "Menu";
    controlUI.appendChild(controlText);
    // Setup the click event listeners: simply set the map to Chicago.
    controlUI.addEventListener("click", () => {
      createMapMenu();
      //console.log("Clicked");
      //map.setCenter(chicago);
    });
    // Set CSS for the control border.
    const controlUI2 = document.createElement("div");

    controlUI2.style.backgroundColor = "#fff";
    controlUI2.style.border = "2px solid #fff";
    controlUI2.style.borderRadius = "3px";
    controlUI2.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
    controlUI2.style.cursor = "pointer";
    controlUI2.style.marginTop = "8px";
    controlUI2.style.marginLeft = "4px";
    controlUI2.style.marginBottom = "4px";
    controlUI2.style.textAlign = "center";
    controlUI2.title = "Map Search";
    controlDiv.appendChild(controlUI2);

    // Set CSS for the control interior.
    const controlText2 = document.createElement("div");
    controlText2.style.color = "rgb(25,25,25)";
    controlText2.style.fontFamily = "Roboto,Arial,sans-serif";
    controlText2.style.fontSize = "16px";
    controlText2.style.lineHeight = "38px";
    controlText2.style.paddingLeft = "5px";
    controlText2.style.paddingRight = "5px";
    controlText2.innerHTML = "Search";
    controlUI2.appendChild(controlText2);
    controlUI2.addEventListener("click", () => {
      createSearchMenu(SearchResultsPlacesList.length);
    });
    // const controlUI3 = document.createElement("div");

    // controlUI3.style.backgroundColor = "#fff";
    // controlUI3.style.border = "3px solid #fff";
    // controlUI3.style.borderRadius = "3px";
    // controlUI3.style.boxShadow = "0 3px 6px rgba(0,0,0,.3)";
    // controlUI3.style.cursor = "pointer";
    // controlUI3.style.marginTop = "8px";
    // controlUI3.style.marginLeft = "4px";
    // controlUI3.style.marginBottom = "33px";
    // controlUI3.style.textAlign = "center";
    // controlUI3.title = "Map";
    // controlDiv.appendChild(controlUI3);

    // var img = document.createElement("img");
    // img.src = resize_icon;
    // //img.width = "200px";
    // //img.height = "200px";
    // controlUI3.appendChild(img);

    //Set CSS for the control interior.
    // const controlText3 = document.createElement("div");
    // controlText3.style.color = "rgb(25,25,25)";
    // controlText3.style.fontFamily = "Roboto,Arial,sans-serif";
    // controlText3.style.fontSize = "16px";
    // controlText3.style.lineHeight = "38px";
    // controlText3.style.paddingLeft = "5px";
    // controlText3.style.paddingRight = "5px";
    // controlText3.innerHTML = "Resize Map";
    // controlUI3.appendChild(controlText3);
    // controlUI3.addEventListener("click", () => {
    //   MapScreen();
    // });
    // const controlText3 = document.createElement("div");
    // controlText3.style.color = "rgb(25,25,25)";
    // controlText3.style.fontFamily = "Roboto,Arial,sans-serif";
    // controlText3.style.fontSize = "16px";
    // controlText3.style.lineHeight = "38px";
    // controlText3.style.paddingLeft = "5px";
    // controlText3.style.paddingRight = "5px";
    // controlText3.innerHTML = "Map size";
    // controlUI3.appendChild(controlText3);
    // controlUI3.addEventListener("click", () => {
    //   MapScreen();
    // });
  }

  function CenterControl2(controlDiv, map) {
    // Set CSS for the control border.
    const controlUI = document.createElement("div");

    controlUI.style.backgroundColor = "#fff";
    controlUI.style.border = "2px solid #fff";
    controlUI.style.borderRadius = "3px";
    controlUI.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
    controlUI.style.cursor = "pointer";
    controlUI.style.marginTop = "72px";
    controlUI.style.marginRight = "6px";
    controlUI.style.marginBottom = "4px";
    controlUI.style.textAlign = "center";
    controlUI.title = "Map Resize";
    controlDiv.appendChild(controlUI);

    // // Set CSS for the control interior.
    // const controlText = document.createElement("div");

    // controlText.style.color = "rgb(25,25,25)";
    // controlText.style.fontFamily = "Roboto,Arial,sans-serif";
    // controlText.style.fontSize = "16px";
    // controlText.style.lineHeight = "38px";
    // controlText.style.paddingLeft = "5px";
    // controlText.style.paddingRight = "5px";
    // controlText.innerHTML = "Menu";
    // controlUI.appendChild(controlText);
    // // Setup the click event listeners: simply set the map to Chicago.
    // controlUI.addEventListener("click", () => {
    //   createMapMenu();
    //   //console.log("Clicked");
    //   //map.setCenter(chicago);
    // });
    // // Set CSS for the control border.
    // const controlUI2 = document.createElement("div");

    // controlUI2.style.backgroundColor = "#fff";
    // controlUI2.style.border = "2px solid #fff";
    // controlUI2.style.borderRadius = "3px";
    // controlUI2.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
    // controlUI2.style.cursor = "pointer";
    // controlUI2.style.marginTop = "8px";
    // controlUI2.style.marginLeft = "4px";
    // controlUI2.style.marginBottom = "22px";
    // controlUI2.style.textAlign = "center";
    // controlUI2.title = "Map";
    // controlDiv.appendChild(controlUI2);

    // // Set CSS for the control interior.
    // const controlText2 = document.createElement("div");
    // controlText2.style.color = "rgb(25,25,25)";
    // controlText2.style.fontFamily = "Roboto,Arial,sans-serif";
    // controlText2.style.fontSize = "16px";
    // controlText2.style.lineHeight = "38px";
    // controlText2.style.paddingLeft = "5px";
    // controlText2.style.paddingRight = "5px";
    // controlText2.innerHTML = "Search";
    // controlUI2.appendChild(controlText2);
    // controlUI2.addEventListener("click", () => {
    //   createSearchMenu(SearchResultsPlacesList.length);
    // });
    // const controlUI3 = document.createElement("div");

    // controlUI3.style.backgroundColor = "#fff";
    // controlUI3.style.border = "3px solid #fff";
    // controlUI3.style.borderRadius = "3px";
    // controlUI3.style.boxShadow = "0 3px 6px rgba(0,0,0,.3)";
    // controlUI3.style.cursor = "pointer";
    // controlUI3.style.marginTop = "4px";
    // controlUI3.style.marginRight = "4px";
    // controlUI3.style.marginBottom = "10px";
    // controlUI3.style.textAlign = "center";
    // controlUI3.title = "Map";
    // controlDiv.appendChild(controlUI3);

    var MapScreenImage = document.createElement("img");
    MapScreenImage.src = resize_icon;
    //img.width = "200px";
    //img.height = "200px";
    controlUI.appendChild(MapScreenImage);

    //Set CSS for the control interior.
    // const controlText3 = document.createElement("div");
    // controlText3.style.color = "rgb(25,25,25)";
    // controlText3.style.fontFamily = "Roboto,Arial,sans-serif";
    // controlText3.style.fontSize = "16px";
    // controlText3.style.lineHeight = "38px";
    // controlText3.style.paddingLeft = "5px";
    // controlText3.style.paddingRight = "5px";
    // controlText3.innerHTML = "Resize Map";
    // controlUI3.appendChild(controlText3);
    controlUI.addEventListener("click", () => {
      MapScreen();
    });
    // const controlText3 = document.createElement("div");.
    // controlText3.style.color = "rgb(25,25,25)";
    // controlText3.style.fontFamily = "Roboto,Arial,sans-serif";
    // controlText3.style.fontSize = "16px";
    // controlText3.style.lineHeight = "38px";
    // controlText3.style.paddingLeft = "5px";
    // controlText3.style.paddingRight = "5px";
    // controlText3.innerHTML = "Map size";
    // controlUI3.appendChild(controlText3);
    // controlUI3.addEventListener("click", () => {
    //   MapScreen();
    // });
  }

  function CenterControl3(controlDiv, map) {
    const controlUI = document.createElement("div");
    controlUI.style.backgroundColor = "#fff";
    controlUI.style.border = "2px solid #fff";
    controlUI.style.borderRadius = "3px";
    controlUI.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
    controlUI.style.cursor = "pointer";
    controlUI.style.marginTop = "72px";
    controlUI.style.marginRight = "6px";
    controlUI.style.marginBottom = "4px";
    controlUI.style.textAlign = "center";
    controlUI.title = "Map Resize";
    controlDiv.appendChild(controlUI);
    SizeToFitImage = document.createElement("img");
    //SizeToFitImage.src = size_to_fit_icon;
    SizeToFitImage.src = SizeMapToFit ? size_to_fit_icon : dont_size_to_fit_icon;
    controlUI.appendChild(SizeToFitImage);
    controlUI.addEventListener("click", () => {
      SizeToFitSetting();
    });
  }

  // MapRef.addListener('click', function (event) {
  //   // If the event is a POI
  //   if (event.placeId) {

  //     // Call event.stop() on the event to prevent the default info window from showing.
  //     event.stop();

  //     // do any other stuff you want to do
  //     console.log('You clicked on place:' + event.placeId + ', location: ' + event.latLng);
  //   }
  // };

  const filterPlacesByArea = (place_id) => {
    const _place = window.guide_obj.places.getPlace(place_id);
    if (_place == null) return;
    let coordinates = _place.getCoordinates();
    coordinates.forEach((element) => {
      coordinates.push({ lat: element.latitude, lng: element.longitude });
    });
    // let coordinates = [];
    // selectedShape.coordinates.forEach((element) => {
    //   coordinates.push({ lat: element.latitude, lng: element.longitude });
    // });
    const area = new window.google.maps.Polygon({
      paths: coordinates,
      strokeColor: "#FF0000",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#FF0000",
      fillOpacity: 0.35,
      editable: true,
    });
    //area.setMap(MapRef);
    let containedPlaces = [];
    //const allPlaces = window.guide_obj.places.getPlacesToDisplay(null);
    const allPlaces = window.guide_obj.places.getChoosePlacesList();
    for (let i = 0; i < allPlaces.length; i++) {
      const place = allPlaces[i].value; //window.guide_obj.places.getPlace(_place.ID)
      const latLng = new window.google.maps.LatLng(place.Lat, place.Longi); //{ lat: place.Lat, lng: place.Longi };
      if (window.google.maps.geometry.poly.containsLocation(latLng, area)) {
        containedPlaces.push(place);
      }
    }
    dispatch(slice1Actions.ClearAllFilters({ value: null }));
    dispatch(slice1Actions.SetPlacesFilter({ value: containedPlaces }));
    dispatch(slice1Actions.setGuide({ guide: null }));
  };

  const onMouseOverGuide = (e, name) => {
    setOverlayViewContent(name);
    setOverlayViewPosition(e.latLng);
    setOverlayViewShow(true);
  };

  const onMouseOutGuide = (e, polyline) => {
    setOverlayViewShow(false);
    // if (window.infowindow != null) {
    //   window.infowindow.close();
    //   window.infowindow = null;
    //   return;
    // }
  };

  const onMouseOverMarker = (e, name, marker) => {
    let text = name;
    if (marker === null || typeof marker === "undefined") return;
    text = `${getLabel(marker)} ${name}`;
    setOverlayViewContent(text);
    setOverlayViewPosition(e.latLng);
    setOverlayViewShow(true);
    if (typeof GuideSettings.places_map_hover === "undefined") return;
    if (GuideSettings.places_map_hover.place === true) {
      // Highlight place in places list
      if (marker != null) {
        var placeElement = document.getElementById(`PlaceCard${marker.ID}`);
        if (placeElement !== null) {
          placeElement.scrollIntoView();
          placeElement.style.backgroundColor = "yellow";
        }
      }
      if (GuideSettings.places_map_hover.event === true) {
        var eventElement = document.getElementById(`eventID${marker.ID}`);
        if (eventElement !== null) {
          eventElement.scrollIntoView();
          eventElement.style.backgroundColor = "yellow";
        }
      }
      if (GuideSettings.places_map_hover.info === true) {
        //////
        let htmlString = PlaceMenu.handleShowPlaceAttributes(marker.ID, SearchResultsPlacesList);
        htmlString = urlSchemeToFunctions(htmlString);
        dispatch(slice1Actions.setHTMLTitle({ value: "Place Attributes" }));
        dispatch(slice1Actions.setHTMLToSHow({ value: htmlString }));
        dispatch(slice1Actions.setShowHTMLReader({ value: true }));
        window.location.href = "#home-start";
        let homeDiv = document.getElementById("home-content-start");
        if (MobileMode) dispatch(slice1Actions.setCurrentAppScreen({ value: "Home" }));
        else if (homeDiv !== null) homeDiv.scrollIntoView();
        ///////
      }
    }
  };

  const onMouseOutMarker = (e, name, marker) => {
    setOverlayViewShow(false);

    // Remove Highlight from place in places list
    if (marker != null) {
      var placeElement = document.getElementById(`PlaceCard${marker.ID}`);
      if (placeElement !== null) {
        placeElement.style.backgroundColor = "#ffffff";
      }
      let eventElement = document.getElementById(`eventID${marker.ID}`);
      if (eventElement !== null) {
        eventElement.style.backgroundColor = "#ffffff";
      }
    }
  };

  // const onMouseOverMarker = (e, name) => {
  //   setOverlayViewContent(name);
  //   setOverlayViewPosition(e.latLng);
  //   setOverlayViewShow(true);
  //   // window.infowindow = new window.google.maps.InfoWindow();
  //   // window.infowindow.setPosition(e.latLng);
  //   // window.infowindow.setContent(name);
  //   // window.infowindow.open(map);
  // };

  const Popup = (e, polyline) => {
    return (
      <OverlayView
        position={e.latLng}
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        //getPixelPositionOffset={getPixelPositionOffset(props.markerPixelOffset)}
      >
        <div className="popup-tip-anchor">
          <div className="popup-bubble-anchor">
            <div className="popup-bubble-content">
              <h1>{polyline.place.Name}</h1>
            </div>
          </div>
        </div>
      </OverlayView>
    );
  };

  //console.log("window.LocationWatchID", window.LocationWatchID);
  _SearchResultsPlacesList = SearchResultsPlacesList;
  window.DirectionsFromLocation = DirectionsFromLocation;
  window.DirectionsToLocation = DirectionsToLocation;
  window.DirectionsWaypointsLocation = DirectionsWaypointsLocation;

  let places_to_display = [];
  let guides_to_display = [];
  let polylines_to_display = [];
  if (GuidesOrPlaces === "Places") {
    places_to_display = markers;
    polylines_to_display = polylines;
  } else {
    guides_to_display = Common.filterGuides(
      GuideType === "Featured" ? GuideInfoArray : GuideInfoArrayMine,
      GuideSearchText,
      GuideCountriesSelected,
      GuideCitiesSelected,
      GuideLanguageSelected,
      GuideOrderBy
    );
    //if (typeof guides_to_display.data === "undefined") guides_to_display = [];

    //guides_to_display = GuideInfoArray;
  }
  //console.log("Map guides_to_display", guides_to_display.length);
  //console.log("Map GuideInfoArray", GuideType === "Featured" ? GuideInfoArray.length : GuideInfoArrayMine.length);
  //let scheduledDates = [];
  const getScheduledDates = () => {
    let scheduledDates = [];
    //console.log("window.LocationWatchID", window.LocationWatchID);
    // _SearchResultsPlacesList = SearchResultsPlacesList;
    // window.DirectionsFromLocation = DirectionsFromLocation;
    // window.DirectionsToLocation = DirectionsToLocation;
    // window.DirectionsWaypointsLocation = DirectionsWaypointsLocation;
    if (SchedulesFilter.length === 1) {
      for (let i = 0; i < markers.length; i++) {
        scheduledDates.push({ id: markers[i].ID, date: markers[i].scheduledDates[0] });
      }
      scheduledDates.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
    } else if (DatesFilter.length === 1) {
      for (let i = 0; i < markers.length; i++) {
        scheduledDates.push({ id: markers[i].ID, date: markers[i].scheduledDates[0] });
      }
      scheduledDates.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
    }
    return scheduledDates;
  };

  //setTimeout(delayStart, 1000);

  const getLabel = (marker) => {
    if (DatesFilter.length === 1) {
      if (marker.scheduledDates.length > 0) {
        const parts = marker.scheduledDates[0].split(" ");
        return parts[parts.length - 1];
      }
    } else if (SchedulesFilter.length === 1) {
      if (marker.scheduledDates.length > 0) {
        const parts = marker.scheduledDates[0].split(" ");
        return `${parts[0]} ${parts[1]} ${parts[2]}`;
      }
    }
    return "";
    // let scheduledDates = getScheduledDates();

    // if (scheduledDates.length === 0) return " ";
    // for (let i = 0; i < scheduledDates.length; i++) {
    //   if (scheduledDates[i].id === id) return (i + 1).toString();
    // }
    // return " ";
  };

  let sortedPlaces = [];
  //if (SchedulesFilter.length === 1 || DatesFilter.length === 1) {
  sortedPlaces = window.guide_obj.getAllScheduledPlaces(ScheduleName);
  sortedPlaces.sort((a, b) => new Date(a.start).getTime() - new Date(b.start).getTime());
  //console.log("sortedPlaces", sortedPlaces);
  //}

  const getPlaceIndex = (marker) => {
    let index = sortedPlaces.findIndex((x) => x.ID === marker.ID);
    if (index !== -1) return (index + 1).toString();
    return " ";
  };

  const getIconPosition = (marker) => {
    //return marker.poi_img;
    if (typeof window.google === "undefined" || window.google.maps === null)
      return {
        url: getPOIImage(marker),
      };
    else if (HighlightedPlaces.includes(marker.ID))
      return {
        url: getPOIImage(marker),
        labelOrigin: new window.google.maps.Point(15, -5),
        scaledSize: new window.google.maps.Size(50, 50),
      };
    else
      return {
        url: getPOIImage(marker),
        labelOrigin: new window.google.maps.Point(15, -5),
        // scaledSize: new window.google.maps.Size(100, 100),
      };
  };

  const getPOIImage = (marker) => {
    return Place.getPOIImage(marker, window.guide_obj, null); //marker.getPOIImage(window.guide_obj); //Place.getPlacePOIImage(marker); //
  };

  const getGuidePosition = (marker) => {
    if (typeof marker.data.base_location === "undefined")
      return {
        lat: 0,
        lng: 0,
      };
    else
      return {
        lat: marker.data.base_location.latitude,
        lng: marker.data.base_location.longitude,
      };
  };

  return (
    // <LoadScript googleMapsApiKey="AIzaSyAKxtGIewjOkmnY0FE9jD7hYbsHRVArJJc">
    // <LoadScript googleMapsApiKey="AIzaSyDKhZWRip7E2aNULD0xk-cBeGzMlzr8imQ" libraries={["drawing", "places"]}>
    <LoadScript googleMapsApiKey="AIzaSyDKhZWRip7E2aNULD0xk-cBeGzMlzr8imQ" language="en">
      <GoogleMap
        mapContainerStyle={containerStyle}
        //center={MapCenter}
        //zoom={15}
        onLoad={onMapLoad}
        onClick={mapClicked}
        onBoundsChanged={onBoundsChanged}
        minZoom={2}
        options={{
          zoomControl: true,
          mapTypeControl: true,
          scaleControl: true,
          streetViewControl: true,
          rotateControl: true,
          fullscreenControl: false,
          clickableIcons: true,
        }}

        // scaleControl={true}
        //options={{ mapTypeId: "satellite" }}
        //clickableIcons={true}
        // ref={(ref) => {
        //   setMapRef(ref);
        // }}
      >
        {/* <DrawingManager
          onLoad={onLoad}
          onPolygonComplete={onPolygonComplete}
          onMarkerComplete={onMarkerComplete}
          onPolylineComplete={onPolylineComplete}
          onRectangleComplete={onRectangleComplete}
          onCircleComplete={onCircleComplete}
        /> */}
        {/* <StandaloneSearchBox onLoad={onLoad} onPlacesChanged={onPlacesChanged}>
          <input
            type="text"
            placeholder="Customized your placeholder"
            style={{
              boxSizing: `border-box`,
              border: `1px solid transparent`,
              width: `240px`,
              height: `32px`,
              padding: `0 12px`,
              borderRadius: `3px`,
              boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
              fontSize: `14px`,
              outline: `none`,
              textOverflow: `ellipses`,
              position: "absolute",
              left: "50%",
              marginLeft: "-120px",
            }}
          />
        </StandaloneSearchBox> */}
        {/* <StreetViewService onLoad={onPOILoad} /> */}
        {StreetViewLocation !== null ? <StreetViewPanorama position={StreetViewLocation} visible={true} /> : null}
        {/* <StreetViewPanorama position={StreetViewLocation} visible={true} /> */}
        {OverlayViewShow ? (
          <OverlayView position={OverlayViewPosition} mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
            <div style={divStyle}>
              <h7>{OverlayViewContent}</h7>

              {/* <button onClick={onClick} type="button">
                Click me
              </button> */}
            </div>
          </OverlayView>
        ) : null}

        {/* <OverlayView mapPaneName="floatPane">
          <div className="map-menu">
            <h5>OverlayView</h5>
            <button onClick={onButtonClick} type="button">
              Click me
            </button>
          </div>
        </OverlayView> */}
        {/* <StandaloneSearchBox>
          <div>
            <input type="hidden" className="input-search-map" placeholder="Search..." />
            <button>Click me</button>
          </div>
        </StandaloneSearchBox> */}
        {/* <Marker icon={poi_img} icon={require("../../assets/icons/bar.png")}></Marker> */}

        {guides_to_display.map((marker, index) => (
          <Marker
            key={"g" + index}
            // label={`${index + 1}`}
            // label={ color: '#00aaff', fontWeight: 'bold', fontSize: '14px', text: 'index' }
            icon={letter_g}
            position={getGuidePosition(marker)}
            onClick={(e) => guideClicked(marker, e)}
            //onDblClick={() => markerDblClicked(marker)}
            onRightClick={(e) => guideClicked(marker, e)}
            //title={marker.Name}
            onMouseOver={(e) => onMouseOverGuide(e, marker.data.guide_name, marker.Lat)}
            onMouseOut={(e) => onMouseOutGuide(e, marker.data.guide_name)}
          />
        ))}

        {places_to_display.map((marker, index) =>
          marker.PType === "Icon" ? (
            <Marker
              key={"m" + index + marker.Source}
              // label={`${index + 1}`}
              // label={ color: '#00aaff', fontWeight: 'bold', fontSize: '14px', text: 'index' }
              label={{
                text: getPlaceIndex(marker),
                color: "red",
                fontSize: "18px",
              }}
              //icon={getPOIImage(marker)}
              icon={getIconPosition(marker)}
              position={{
                lat: marker.Lat,
                lng: marker.Longi,
              }}
              // onClick={(e) => markerClicked(marker, e)}
              onDblClick={(e) => markerDblClicked(marker, e)}
              onRightClick={(e) => markerRighClicked(marker, e)}
              //title={marker.Name}
              //onMouseOver={(e) => onMouseOverMarker(e, marker.Name, marker.Lat)}
              onMouseOver={(e) => onMouseOverMarker(e, marker.Name, marker)}
              onMouseOut={(e) => onMouseOutMarker(e, marker.Name, marker)}
              onMouseDown={(e) => onMouseDownMarker(marker, e)}
              onMouseUp={(e) => onMouseUpMarker(marker, e)}
            >
              {/* {showInfoWindow === true && (
                <InfoWindow>
                  <div>{marker.Name}</div>
                </InfoWindow>
              )} */}
            </Marker>
          ) : null
        )}
        {DirectionsFromLocation !== null ? (
          <Marker
            key={"fromMarker"}
            icon={from_icon}
            position={{
              lat: DirectionsFromLocation.Lat,
              lng: DirectionsFromLocation.Longi,
            }}
            onClick={() => createDirectionsMenu("fromMarker")}
            //title={"From"}
            onMouseOver={(e) => onMouseOverMarker(e, "From")}
            onMouseOut={(e) => onMouseOutMarker()}
          />
        ) : null}
        {DirectionsToLocation !== null ? (
          <Marker
            key={"toMarker"}
            icon={to_icon}
            position={{
              lat: DirectionsToLocation.Lat,
              lng: DirectionsToLocation.Longi,
            }}
            onClick={() => createDirectionsMenu("toMarker")}
            //title={"To"}
            onMouseOver={(e) => onMouseOverMarker(e, "To")}
            onMouseOut={(e) => onMouseOutMarker()}
          />
        ) : null}
        {DirectionsWaypointsLocation.map((waypoint, index) => (
          <Marker
            key={"waypointMarker"}
            icon={waypoint_icon}
            label={`${index + 1}`}
            position={{
              lat: waypoint.Lat,
              lng: waypoint.Longi,
            }}
            onClick={() => createDirectionsMenu("waypointMarker", index)}
            //title={`Waypoint ${index + 1}`}
            onMouseOver={(e) => onMouseOverMarker(e, `Waypoint ${index + 1}`)}
            onMouseOut={(e) => onMouseOutMarker()}
          />
        ))}
        {TrackedLocation !== null ? (
          <Marker
            key={"toMarker"}
            icon={here_icon}
            position={{
              lat: TrackedLocation.latitude,
              lng: TrackedLocation.longitude,
            }}
            //onClick={() => createDirectionsMenu("toMarker")}
            //title={"To"}
            onMouseOver={(e) => onMouseOverMarker(e, "Current location")}
            onMouseOut={(e) => onMouseOutMarker()}
          />
        ) : null}

        {polylines_to_display.map((polyline, index) =>
          polyline.place.PType === "Polygon" ? (
            <Polygon
              key={"pg" + index}
              paths={[polyline.path, polyline.path]}
              options={optionsPolygon}
              onClick={() => polygonClicked(polyline)}
              onMouseOver={(e) => onMouseOverMarker(e, polyline.place.Name)}
              onMouseOut={(e) => onMouseOutMarker()}
              // editable={true}
            />
          ) : (
            <Polyline
              key={"pl" + index}
              path={polyline.path}
              options={optionsPolyline}
              onClick={(e) => polylineClicked(polyline, e)}
              onMouseOver={(e) => onMouseOverMarker(e, polyline.place.Name)}
              onMouseOut={(e) => onMouseOutMarker()}
              // editable={true}
            />
          )
        )}
        {_TransitLayer ? <TransitLayer /> : null}
        {BikeLayer === true ? <BicyclingLayer /> : null}
        {/* <StreetViewService /> */}
        <></>
        <Data
          onLoad={onLoad}
          options={{
            controlPosition: window.google ? window.google.maps.ControlPosition.TOP_LEFT : undefined,
            controls: ["Point", "LineString", "Polygon"],
            drawingMode: "", //  "LineString" or "Polygon".
            featureFactory: (geometry) => {
              placeDrawn(geometry);
            },
            // Type:  boolean
            // If true, the marker receives mouse and touch events. Default value is true.
            clickable: true,

            // Type:  string
            // Mouse cursor to show on hover. Only applies to point geometries.
            // cursor: 'cursor',

            // Type:  boolean
            // If true, the object can be dragged across the map and the underlying feature will have its geometry updated. Default value is false.
            draggable: true,

            // Type:  boolean
            // If true, the object can be edited by dragging control points and the underlying feature will have its geometry updated. Only applies to LineString and Polygon geometries. Default value is false.
            editable: true,

            // Type:  string
            // The fill color. All CSS3 colors are supported except for extended named colors. Only applies to polygon geometries.
            fillColor: "#FF0055",

            // Type:  number
            // The fill opacity between 0.0 and 1.0. Only applies to polygon geometries.
            fillOpacity: 1,

            // Type:  string|Icon|Symbol
            // Icon for the foreground. If a string is provided, it is treated as though it were an Icon with the string as url. Only applies to point geometries.
            // icon: 'icon',

            // Type:  MarkerShape
            // Defines the image map used for hit detection. Only applies to point geometries.
            // shape: 'shape',

            // Type:  string
            // The stroke color. All CSS3 colors are supported except for extended named colors. Only applies to line and polygon geometries.
            strokeColor: "#00FF55",

            // Type:  number
            // The stroke opacity between 0.0 and 1.0. Only applies to line and polygon geometries.
            strokeOpacity: 1,

            // Type:  number
            // The stroke width in pixels. Only applies to line and polygon geometries.
            strokeWeight: 2,

            // Type:  string
            // Rollover text. Only applies to point geometries.
            title: "Title",

            // Type:  boolean
            // Whether the feature is visible. Defaults to true.
            visible: true,

            // Type:  number
            // All features are displayed on the map in order of their zIndex, with higher values displaying in front of features with lower values. Markers are always displayed in front of line-strings and polygons.
            zIndex: 2,
          }}
        />
        {/* {MenuModalShow ? (
         
          <MapControl position={window.google.maps.ControlPosition.LEFT_BOTTOM}>
            <
          </MapControl>
        ) : null} */}
        {MenuModalShow ? (
          <MenuModal
            // ref={menuModalRef}
            id="MenuModal"
            show={MenuModalShow}
            onHide={() => setMenuModalShow(false)}
            menu_modal_list={MenuModalList}
            menu_modal_heading={MenuModalHeading}
            menu_model_image={MenuModalImage}
            menu_modal_place={MenuModalPlace}
          />
        ) : null}
      </GoogleMap>
      {placeModal ? (
        <PlaceModal
          show={placeModal}
          onHide={() => setPlaceModal(false)}
          name={selectedPlace == null ? "" : selectedPlace.Name}
          img={selectedPlace == null ? "" : getImageURLString(selectedPlace.Photo)}
          i={selectedPlace == null ? "" : selectedPlace.ID}
          mainIndex={selectedPlace == null ? "" : selectedPlace.ID}
          id={selectedPlace == null ? "" : selectedPlace.ID}
          place={selectedPlace}
          action={PlaceModalAction}
          filter={filterPlacesByArea}
        />
      ) : null}

      <AlertModal
        show={AlertModalShow}
        onHide={() => setAlertModalShow(false)}
        AlertModalTitle={AlertModalTitle}
        AlertModalMessage={AlertModalMessage}
        AlertModalButtons={AlertModalButtons}
      />
      {PromptModalShow ? (
        <PromptModal
          show={PromptModalShow}
          onHide={() => setPromptModalShow(false)}
          PromptModalTitle={PromptModalTitle}
          PromptModalMessage={PromptModalMessage}
          PromptModalButtons={PromptModalButtons}
          PromptModalInputs={PromptModalInputs}
        />
      ) : null}
      {KMLInputShow ? (
        <KMLInput
          show={KMLInputShow}
          onHide={() => setKMLInputShow(false)}
          PromptModalTitle={"KML"}
          PromptModalMessage={"Import KML file"}
          PromptModalButtons={[{ Title: "CANCEL", Method: null }]}
          PromptModalInputs={[{ name: "Read file", value: "" }]}
          add_place_to_map={addPlaceToMap}
        />
      ) : null}
      {DirectionsDialogShow ? (
        <DirectionsDialog
          show={DirectionsDialogShow}
          id={props.i}
          onHide={() => setDirectionsDialogShow(false)}
          create_shape={shapeFromDirections}
        ></DirectionsDialog>
      ) : null}
      {AiDialogShow ? (
        <AiDialog
          show={AiDialogShow}
          onHide={() => setAiDialogShow(false)}
          questions={AppSettings.questions2}
          location={ClickLocation}
          user_info={user_info}
        />
      ) : null}
      {ImageDialogShow ? (
        <ImageDialog
          show={ImageDialogShow}
          onHide={() => setImageDialogShow(false)}
          user_info={user_info}
          mode={"select"}
          modify_only={"true"}
          updateImage={updateImageCallBack}
          size={"sm"}
          multi={true}
          exif={true}
        />
      ) : null}
      {YouTubeDialogShow ? <YouTubeDialog show={YouTubeDialogShow} onHide={() => setYouTubeDialogShow(false)} /> : null}

      <HTMLReader html={HTMLContent} show={HTMLReaderModal} onHide={() => closeHTMLReader()} />

      {AISearchShow ? (
        <AISearch
          show={AISearchShow}
          onHide={() => setAISearchShow(false)}
          user_info={user_info}
          update_ui={updateUI}
          set_schedule_name={SetScheduleName}
          find_search_result={FindSearchResult}
          add_search_result={AddSearchResult}
          questions={AppSettings.AISearch2}
          ai_categories={AppSettings.AICategories}
          ai_base_categories={AppSettings.AIBaseCategories}
        />
      ) : null}
      {/* {this.state.activityIndicator ? ( */}
      {/* <div className="loading"> */}
      {/* <Spinner animation="border" variant="primary" /> */}
      {/* </div> */}
      {/* ) : null}{" "} */}
      {/* {ShowOffcanvas ? (
        <Offcanvas show={ShowOffcanvas} onHide={() => setShowOffcanvas(false)} placement={"end"}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Directions</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <DirectionsDialogComponent>
              show={DirectionsDialogShow}
              id={props.i}
              onHide={() => setDirectionsDialogShow(false)}
              create_shape={shapeFromDirections}
            </DirectionsDialogComponent>
          </Offcanvas.Body>
        </Offcanvas>
      ) : null} */}
    </LoadScript>
  );
}

export default Map;
