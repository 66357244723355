import React, { useCallback, useEffect, useState } from "react";
// import HomeHeader from "../HomeHeader/HomeHeader";
import "./Home.css";
// import MobileFooter from "../MobileFooter/MobileFooter";
import { BrowserRouter as Router } from "react-router-dom";
// import Places from "../../Places/Places";
import { useResizeDetector } from "react-resize-detector";
// import SchedulePanel from "../../SchedulePanel/SchedulePanel";
import ReactHtmlParser from "react-html-parser";
// import HomeContent from "../HomeContent/HomeContent";
import Place from "../../../Models/Place";
import { useDispatch } from "react-redux";
import { slice1Actions } from "../../../store/reducer";
import { useSelector } from "react-redux";
import { urlSchemeToFunctions } from "../../../CommonWeb/Common";
import Common, { Settings, SortByEnum, labelChars, AUTHORIZATION_LEVEL, COLORS } from "../../../Common/Common";
import HTMLReader from "../../modals/HTMLReader";
import ReactLoading from "react-loading";

import DataBase from "../../../dataBase/liquidGuides";
import Guide from "../../../Models/Guide";
import PlaceModal from "../../modals/PlaceModal";
import User from "../../../Models/User";
import GooglePlaces from "../../../Models/GooglePlaces";
import InnerHTML from "dangerously-set-html-content";

//let htmlDivRef = null;
const Home = ({ setDisplayNone }) => {
  const onResize = useCallback(() => {}, []);
  const [HTMLReaderModal, setHTMLReaderModal] = useState(false);
  const [HTMLContent, setHTMLContent] = useState("");
  const dispatch = useDispatch();
  let LoadingGuide = useSelector((state) => state.LoadingGuide);

  let htmlString = useSelector((state) => state.homeScreen);
  let ScheduleName = useSelector((state) => state.ScheduleName);
  let ContentFontZoom = useSelector((state) => state.ContentFontZoom);
  let GuideSettings = useSelector((state) => state.GuideSettings);
  // let ScheduleNames = useSelector((state) => state.ScheduleNames);
  const [placeModal, setPlaceModal] = useState(false);
  const [selectedPlace, setSelectedPlace] = useState(null);

  const { height, ref } = useResizeDetector({
    refreshRate: 1000,
    onResize,
  });

  useEffect(() => {
    //if(htmlDivRef != null) htmlDivRef.current.scrollTo(0, 0);
    // if (height > 550) {
    //   setDisplayNone(true);
    // } else {
    //   setDisplayNone(false);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //console.log(height);
  //   if (htmlString == "")

  //   htmlString = "";
  //if (htmlString == "") htmlString = htmlString1;
  //console.log("htmlString Before", htmlString);
  if (typeof htmlString === "undefined" || htmlString === null) htmlString = "";
  //htmlString = urlSchemeToFunctions(htmlString);

  htmlString = urlSchemeToFunctions(htmlString);
  if (!htmlString.includes("a {color: #1913AB; text-decoration: none !important;} "))
    htmlString =
      `<style>a {color: #1913AB; text-decoration: none !important;} 
    a:visited {color: #661099;} a:active {color: red;}
    a:hover {color: purple; background: #AFB; } 
    .redtext {color: red;}</style>` + htmlString; // body {font-size: 3rem;}</style>

  //console.log("htmlString After", htmlString);

  function openAsPageInNewTab(pageContent) {
    let encoded = encodeURIComponent(pageContent);
    let a = document.createElement(`a`);
    a.target = `_blank`;
    a.href = `data:text/html;charset=utf-8,${encoded}`;
    a.style.display = `none`;
    document.body.appendChild(a); // We need to do this,
    a.click(); // so that we can do this,
    document.body.removeChild(a); // after which we do this.
  }

  window.TimeFromYoutube = (search_time, placeID, state, videoLinkOffset) => {
    const place = window.guide_obj.places.getPlace(placeID.toString());
    //console.log("window.TimeFromYoutube place", place);
    if (place === null) return;
    if (state !== 1) return;
    let time = search_time - videoLinkOffset;
    if (time < 0) time = 0;
    const closest = place.findClosestTimeInPath(time);
    if (closest === null) return;
    console.log("*******************TimeFromYoutube", search_time, placeID, state, videoLinkOffset);
    console.log("closest", closest);
    const _MapCenter = { Lat: closest.latitude, Longi: closest.longitude };
    dispatch(slice1Actions.centerMapToLoc({ value: _MapCenter }));
  };

  window.AnimatePath = (place) => {
    //const place = window.guide_obj.places.getPlace(placeID.toString());
    console.log("window.AnimatePath place", place);
    // if (place === null) return;
    // if (state !== 1) return;
    // let time = search_time - videoLinkOffset;
    // if (time < 0) time = 0;
    // const closest = place.findClosestTimeInPath(time);
    // if (closest === null) return;
    // console.log("*******************TimeFromYoutube", search_time, placeID, state, videoLinkOffset);
    // console.log("closest", closest);
    // const _MapCenter = { Lat: closest.latitude, Longi: closest.longitude };
    // dispatch(slice1Actions.centerMapToLoc({ value: _MapCenter }));
  };

  window.UpdateChartCSS = (search_time, placeID, state, videoLinkOffset) => {
    const place = window.guide_obj.places.getPlace(placeID.toString());
    if (place === null) return;
    if (state !== 1) return;
    let max = -9999999999;
    let min = 99999999999;
    const timed_path = place.getTimedPath();
    for (let i = 0; i < timed_path.length; i++) {
      if (timed_path[i].altitude > max) max = timed_path[i].altitude;
      if (timed_path[i].altitude < min) min = timed_path[i].altitude;
    }
    if (max === -9999999999) return "";
    let current = 0;
    const closest = place.findClosestTimeInPath(search_time - videoLinkOffset);
    if (closest !== null) current = closest.altitude.toFixed(2);
    const spread = max - min;
    let html = `<table class="charts-css line show-heading show-labels show-primary-axis show-5-secondary-axes" style="--color: blue;" id="my-chart">
    <caption> Elevation (meters) min:${min.toFixed(2)}, max:${max.toFixed(2)} current:${current}</caption>
    <tbody style="height:150px">\n`;
    let last = 0;
    for (let i = 0; i < timed_path.length; i++) {
      const size = ((timed_path[i].altitude - min) / spread).toFixed(2);
      html += `<tr><td style="--start: ${last}; --size: ${size}"><span class="tooltip"> Altitude: ${timed_path[i].altitude}m <br> Time: ${timed_path[i].elapsed_time}sec </span></td></tr>\n`;
      if (timed_path[i].index === i) html += `<span class="data">|</span> </td></tr>\n`;
      last = size;
    }
    html += `</tbody>
  </table>`;
    //console.log("window.UpdateChart", max, min, spread);
    //console.log("window.UpdateChart", html);
    return html;
  };

  window.UpdateChart = (search_time, placeID, state, videoLinkOffset, onlyLocation) => {
    const place = window.guide_obj.places.getPlace(placeID.toString());
    let data = {
      cubicInterpolationMode: "monotone",
      labels: [],
      datasets: [
        {
          label: "Altitude (meters)",
          data: [],
          borderWidth: 1,
        },
      ],
    };
    let options = {
      tooltips: {
        enabled: false,
      },
      legend: {
        display: false,
      },
      scales: {
        xAxes: [{ display: false }],
        yAxes: [{ display: false }],
      },
    };
    if (place === null) return { data: data, options: options };
    if (state !== 1) return { data: data, options: options };

    const closest = place.findClosestTimeInPath(search_time - videoLinkOffset);

    if (closest !== null) {
      options = {
        plugins: {
          autocolors: false,
          annotation: {
            annotations: {
              point1: {
                type: "point",
                xValue: closest.index,
                yValue: closest.altitude,
                backgroundColor: "rgba(255, 99, 132, 0.25)",
              },
            },
          },
        },
      };
    }
    if (onlyLocation) return { data: data, options: options };
    const timed_path = place.getTimedPath();
    let labels = [];
    let data_data = [];
    for (let i = 0; i < timed_path.length; i++) {
      labels.push("");
      data_data.push(timed_path[i].altitude);
    }
    data.labels = labels;
    data.datasets[0].data = data_data;

    return { data: data, options: options };
  };

  window.StateFromYoutube = (event) => {
    //console.log("*******************StateFromYoutube", event);
  };
  window.customLink = (url_prop) => {
    console.log("customLink", url_prop);
    if (window.guide_obj == null) return;
    url_prop = url_prop.replace(/%7C/g, "|");
    url_prop = url_prop.replace(";", ":");
    const urlParts = url_prop.split(":");
    console.log("customLink", urlParts);
    const urlType = urlParts[0];
    //setHTMLReaderModal(true);
    let place = null;
    let places = null;
    let placeIDs = null;
    let action = ";";
    let htmlString = "";
    let win = null;
    let _url = "";
    var homeDiv = null;
    switch (urlType) {
      case "https":
        //console.log("https");
        _url = urlParts[0] + ":" + urlParts[1];
        //console.log(_url);
        win = window.open(_url, "_blank");
        win.focus();

        // win = window.open("about:blank", "", "_blank");
        // win.document.write(_url);
        // win.focus();
        break;
      case "Https":
        _url = urlParts[0] + ":" + urlParts[1];
        //console.log(_url);
        win = window.open(_url, "_blank");
        win.focus();
        break;
      case "urllink":
        //console.log("urllink", urlParts[1]);
        if (urlParts[1].startsWith("http")) _url = urlParts[1] + ":" + urlParts[2];
        //console.log("urllink", _url);

        if (_url.includes("place_id=")) {
          const parts = _url.split("=");
          console.log("parts", parts);
          GooglePlaces.getMoreDetails(getMoreDetailsCallback, parts[1]);
          return;
        }
        win = window.open(_url, "_blank");
        //win = window.open("about:blank", "", "_blank");
        //win.document.write(_url);
        win.focus();
        break;
      case "placemenulink":
        place = window.guide_obj.places.getPlace(urlParts[1]);
        if (place == null) return;
        //console.log("placemenulink", urlParts[1]);
        setSelectedPlace(place);
        setPlaceModal(true);
        // dispatch(slice1Actions.setSelectedPlace({ place: place }));
        // dispatch(slice1Actions.setShowPlaceMenu({ value: true }));
        break;
      case "placelink":
        console.log("placelink", urlParts[1]);
        place = window.guide_obj.places.getPlace(urlParts[1]);
        if (place == null) return;
        htmlString = place.createPlaceDetailsHTML(place.Info, null, window.guide_obj, place.scheduledDates);

        htmlString = urlSchemeToFunctions(htmlString);
        dispatch(slice1Actions.setHTMLTitle({ value: "Place Attributes" }));
        dispatch(slice1Actions.setHTMLToSHow({ value: htmlString }));
        dispatch(slice1Actions.setShowHTMLReader({ value: true }));
        window.location.href = "#home-start";
        homeDiv = document.getElementById("home-content-start");
        homeDiv.scrollIntoView();
        break;
      case "placemoreinfolink":
        place = window.guide_obj.places.getPlace(urlParts[1]);
        if (place == null) return;
        //console.log(place);
        dispatch(slice1Actions.setSelectedPlace({ place: place }));
        let link = place.getFirstHLink();
        if (link !== "") {
          //this.props.nav.navigate("StreetView", { place: null, mode: "url", url_string: link, title: "Place Info" });
          action = link;
          htmlString = `<!DOCTYPE html>
      <html>
        <head>
          <title>StreetView</title>
          <meta http-equiv='Content-Type' content='text/html; charset=utf-8'/>
        </head>
        <body>
          <script type='text/javascript'>
            window.location.href = "${action}";
          </script>
        </body>
      </html>`;
          //win = window.open(htmlString, "_blank");
          win = window.open("about:blank", "", "_blank");
          win.document.write(htmlString);
          win.focus();
          break;
        } else {
          const text = place.Info;
          htmlString = place.createPlaceDetailsHTML(text, null, window.guide_obj, place.scheduledDates);

          htmlString = urlSchemeToFunctions(htmlString);
          dispatch(slice1Actions.setHTMLTitle({ value: "Place Info" }));
          dispatch(slice1Actions.setHTMLToSHow({ value: htmlString }));
          dispatch(slice1Actions.setShowHTMLReader({ value: true }));
          window.location.href = "#home-start";
          homeDiv = document.getElementById("home-content-start");
          homeDiv.scrollIntoView();
        }
        break;
      case "placeslink":
        placeIDs = urlParts[1].split(",");
        places = window.guide.obj.places.getPlacesFromIDs(placeIDs);
        //console.log(places);
        break;
      case "maplink":
        const parts1 = urlParts[1].replace("%7C", "|");
        const placeParts = parts1.split("|");
        if (placeParts.length < 3) return;

        placeIDs = placeParts[0].split(",");
        if (placeParts[0] === "") placeIDs = [];

        let GroupIDs = placeParts[1].split(",");
        if (placeParts[1] === "") GroupIDs = [];
        let CategoryIDs = placeParts[2].split(",");
        if (placeParts[2] === "") CategoryIDs = [];
        places = window.guide_obj.getGroupAndCategoryPlaces(placeIDs, [], []);
        //console.log("CategoryIDs", CategoryIDs);

        dispatch(slice1Actions.ClearAllFilters());
        if (places.length > 0) dispatch(slice1Actions.SetPlacesFilter({ value: places }));
        else if (GroupIDs.length > 0) dispatch(slice1Actions.SetGroupsFilter({ value: GroupIDs }));
        else if (CategoryIDs.length > 0) dispatch(slice1Actions.SetCategoriesFilter({ value: CategoryIDs }));

        if (placeParts.length > 3) {
          for (let i = 3; i < placeParts.length; i++) {
            console.log(placeParts[i]);
            if (placeParts[i] === "Favorites") dispatch(slice1Actions.SetFilterFavorites({ value: true }));
            if (placeParts[i] === "Visited") dispatch(slice1Actions.SetFilterVisited({ value: true }));
            if (placeParts[i] === "NotVisited") dispatch(slice1Actions.SetFilterNotVisited({ value: true }));
            if (placeParts[i] === "MustSee") dispatch(slice1Actions.SetFilterMustSee({ value: true }));
            if (placeParts[i].includes("distance")) {
              const distanceParts = placeParts[i].split("=");
              //const dis = parseFloat(distanceParts[1]);
              //console.log("maplink", distanceParts, distanceParts[1], parseFloat(distanceParts[1]));
              dispatch(slice1Actions.SetDistanceFilter({ value: distanceParts[1] }));
            }
            if (placeParts[i].includes("schedule")) {
              const schedulesParts = placeParts[i].split("=");
              const schedule = schedulesParts[1];
              console.log("maplink, schedule ", schedule);
              const AllScheduledPlaces = window.guide_obj.getAllScheduledPlaces(schedule);
              let hiddenPlacesToShow = [];
              AllScheduledPlaces.forEach((p) => {
                if (p.Hidden === true) hiddenPlacesToShow.push(p);
              });
              console.log("hiddenPlacesToShow", hiddenPlacesToShow);
              dispatch(slice1Actions.setShowHiddenPlace({ value: hiddenPlacesToShow }));
              dispatch(slice1Actions.setScheduleName({ value: schedule }));
              dispatch(slice1Actions.SetSchedulesFilter({ value: [{ label: schedule, value: schedule }] }));
            }
            if (placeParts[i].includes("day=") && parts1.includes("|day=")) {
              const dayParts = placeParts[i].split("=");
              const dayTime = parseInt(dayParts[1]);
              console.log("maplink, schedule ", dayTime);
              const date = new Date(dayTime);
              //day = date.getDay();
              const day_string = `${Settings.DAYS[date.getDay()]}, ${
                Settings.MONTHS[date.getMonth()]
              }, ${date.getDate()}, ${date.getFullYear()}`;
              const day_entry = {
                label: day_string,
                value: dayTime,
                // id: "9999999",
              };
              console.log("day_entry", day_entry);
              const AllScheduledPlaces = window.guide_obj.getAllScheduledPlaces(ScheduleName, dayTime);
              let hiddenPlacesToShow = [];
              AllScheduledPlaces.forEach((p) => {
                if (p.Hidden === true) hiddenPlacesToShow.push(p);
              });
              console.log("hiddenPlacesToShow", hiddenPlacesToShow);
              dispatch(slice1Actions.setShowHiddenPlace({ value: hiddenPlacesToShow }));
              dispatch(slice1Actions.SetDatesFilter({ value: [day_entry] }));
            }
          }
        }
        window.location.href = "#map-start";
        dispatch(slice1Actions.setCurrentAppScreen({ value: "Map" }));
        setTimeout(timeoutFunc, 100);
        break;
      case "streetviewlink":
        place = window.guide_obj.places.getPlace(urlParts[1]);
        if (place == null) return;
        action = `http://maps.google.com/maps?q=&layer=c&cbll=${place.Lat},${place.Longi}&cbp=11,0,0,0,0`;
        htmlString = `<!DOCTYPE html>
    <html>
      <head>
        <title>StreetView</title>
        <meta http-equiv='Content-Type' content='text/html; charset=utf-8'/>
      </head>
      <body>
        <script type='text/javascript'>
          window.location.href = "${action}";
        </script>
      </body>
    </html>`;
        win = window.open("about:blank", "", "_blank");
        win.document.write(htmlString);
        win.focus();
        break;
      case "sectionlink":
        let section = window.guide_obj.findSection(urlParts[1]);
        if (section == null) {
          //Alert.alert("section not found");
          return;
        }
        console.log("********************BEFORE", section.html);
        htmlString = urlSchemeToFunctions(section.html);
        console.log("********************AFTER", htmlString);
        dispatch(slice1Actions.setHTMLTitle({ value: section.Title }));
        dispatch(slice1Actions.setHTMLToSHow({ value: htmlString }));
        dispatch(slice1Actions.setShowHTMLReader({ value: true }));
        window.location.href = "#home-start";
        homeDiv = document.getElementById("home-content-start");
        homeDiv.scrollIntoView();
        // setHTMLReaderModal(true);
        break;
      case "imagelink":
        //console.log(urlParts);
        let Image_url = urlParts[1] + ":" + urlParts[2];
        if (url_prop.includes("https://drive.google.com")) {
          const image_parts = url_prop.split("/");
          Image_url = `https://docs.google.com/uc?export=download&id=${image_parts[5]}`;
        }

        Image_url = Image_url.replace(/%2F/g, "/");
        // Image_url = image_parts[5].replace(/%2F/g, "/")
        // let image_path = image_parts[5].replace(/&amp;/g, "&");
        //console.log("Image_url", Image_url);

        /* <img src="https://firebasestorage.googleapis.com/v0/b/liquidguides-2ce60.appspot.com/o/fb_images%2FE2Fqqu9FWDgTdpOzdKQOKEdo6wA3%2FCupola?alt=media&token=440ef5ef-5a31-47e1-9ce6-bf6ba805bcb0" alt="image" style="width:300px; border-radius:10%; margin:10px;"></img>
<img src="https://firebasestorage.googleapis.com/v0/b/liquidguides-2ce60.appspot.com/o/fb_images%2FE2Fqqu9FWDgTdpOzdKQOKEdo6wA3%2FDuomo_m?alt=media&token=4caafe17-21d0-4c74-bf96-529e94cd0cd6" alt="Image" style="width:600px; border-radius:10%"></img> */

        if (Image_url.includes("https://firebasestorage")) {
          let last_slash = Image_url.lastIndexOf("/");
          if (last_slash !== -1) Image_url = Image_url.substr(0, last_slash) + "%2F" + Image_url.substr(last_slash + 1);
          last_slash = Image_url.lastIndexOf("/");
          if (last_slash !== -1) Image_url = Image_url.substr(0, last_slash) + "%2F" + Image_url.substr(last_slash + 1);
        }
        //Image_url = `https://firebasestorage.googleapis.com/v0/b/liquidguides-2ce60.appspot.com/o/fb_images%2FE2Fqqu9FWDgTdpOzdKQOKEdo6wA3%2FCupola_m?alt=media&token=440ef5ef-5a31-47e1-9ce6-bf6ba805bcb0`;
        htmlString = Place.createImageHTML(Image_url, null, "medium");
        //console.log("*******************Imagelink", htmlString);
        //win = window.open(htmlString, "_blank");
        // win = window.open("about:blank", "", "_blank");
        // win.document.write(htmlString);
        // win.focus();
        console.log("***********BEFORE", htmlString);
        htmlString = urlSchemeToFunctions(htmlString);
        console.log("***********AFTER", htmlString);
        dispatch(slice1Actions.setHTMLTitle({ value: "Image" }));
        dispatch(slice1Actions.setHTMLToSHow({ value: htmlString }));
        dispatch(slice1Actions.setShowHTMLReader({ value: true }));
        window.location.href = "#home-start";
        homeDiv = document.getElementById("home-content-start");
        homeDiv.scrollIntoView();
        break;
      case "guidelink":
        console.log("guidelink", urlParts[1]);
        //guideClicked(urlParts[1]);
        window.loadGuide(urlParts[1]);
        dispatch(slice1Actions.setShowHTMLReader({ value: false }));
        break;
      case "schedulelink":
        let scheduleName = urlParts[1];
        scheduleName = scheduleName.replace(/%20/g, " ");
        let schedule = [{ label: scheduleName, value: scheduleName }];
        dispatch(slice1Actions.ClearAllFilters());
        dispatch(slice1Actions.SetSchedulesFilter({ value: schedule }));
        dispatch(slice1Actions.setScheduleName({ value: scheduleName }));
        dispatch(slice1Actions.setGuide({ guide: null, fitToBounds: true }));
        window.location.href = "#schedule-start";
        dispatch(slice1Actions.setCurrentAppScreen({ value: "Schedule" }));
        setTimeout(timeoutFunc, 100);
        break;
      case "itinerarylink":
        let itineraryName = urlParts[1];
        itineraryName = itineraryName.replace(/%20/g, " ");
        const itinerary = [{ label: itineraryName, value: itineraryName }];
        htmlString = window.guide_obj.createHTMLItinerary(itineraryName, GuideSettings.itinerary);

        htmlString = urlSchemeToFunctions(htmlString);
        // dispatch(slice1Actions.setHTMLTitle({ value: "Itinerary" }));
        // dispatch(slice1Actions.setHTMLToSHow({ value: htmlString }));
        // dispatch(slice1Actions.setShowHTMLReader({ value: true }));

        dispatch(slice1Actions.setHomeScreen({ value: htmlString }));

        dispatch(slice1Actions.ClearAllFilters());
        dispatch(slice1Actions.SetSchedulesFilter({ value: itinerary }));
        dispatch(slice1Actions.setScheduleName({ value: itineraryName }));
        dispatch(slice1Actions.setGuide({ guide: null, fitToBounds: true }));

        window.location.href = "#home-start";
        homeDiv = document.getElementById("home-content-start");
        homeDiv.scrollIntoView();
        break;
      case "videolink":
        let video_url = urlParts[1] + ":" + urlParts[2];
        let video_html = "";
        //console.log("videolink: url_prop, video_url", url_prop, video_url);
        if (video_url.includes("https://www.youtu") || video_url.includes("https://youtu")) {
          let video_url_parts = video_url.split("/");
          let yvideo = video_url_parts[video_url_parts.length - 1];
          yvideo = yvideo.replace("watch?v=", "");
          //autoplay does not work on youtube videos
          //<iframe width="560" height="315" src="https://www.youtube.com/embed/-5v8l5eiyOc?start=508" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

          video_html = `<iframe style="max-width: 100%; width: 640px; height: 480px;"
                          src="https://www.youtube.com/embed/${yvideo}?autoplay=1&mute=1" frameborder="0" allowfullscreen="allowfullscreen">
                      </iframe>`;
        } else if (url_prop.includes("https://drive.google.com")) {
          const video_parts = url_prop.split("/");
          video_url = `https://docs.google.com/uc?export=download&id=${video_parts[5]}`;
          video_html = `<video width="640" height="480" controls autoplay muted>
                      <source src="${video_url}" type="video/mp4">
                        Video format not supported use mp4, WebM or Ogg.
                      </video>`;
        } else {
          let videoType = "video/mp4";
          if (video_url.toUpperCase().endsWith("MP4")) videoType = "video/mp4";
          else if (video_url.toUpperCase().endsWith("WEBM")) videoType = "video/webm";
          else if (video_url.toUpperCase().endsWith("OGG")) videoType = "video/ogg";

          // else {
          //   Alert.alert("Video format not supported use mp4, WebM, Youtube or Ogg.");
          //   return false;
          // }
          video_html = `<video width="640" height="480" controls autoplay muted>
                        <source src="${video_url}" type="${videoType}">
                          Video format not supported use mp4, WebM or Ogg.
                      </video>`;
        }
        //console.log(video_html);
        htmlString = urlSchemeToFunctions(htmlString);
        dispatch(slice1Actions.setHTMLTitle({ value: "Video" }));
        dispatch(slice1Actions.setHTMLToSHow({ value: video_html }));
        dispatch(slice1Actions.setShowHTMLReader({ value: true }));
        window.location.href = "#home-start";
        homeDiv = document.getElementById("home-content-start");
        homeDiv.scrollIntoView();
        // win = window.open("about:blank", "", "_blank");
        // win.document.write(video_html);
        // win.focus();
        break;
      case "audiolink":
        console.log("audiolink", urlParts);
        let audio_url = urlParts[1] + ":" + urlParts[2];
        console.log("audio_url", audio_url);
        if (audio_url.startsWith("https://drive.google.com")) {
          const audio_parts = audio_url.split("/");
          audio_url = `https://docs.google.com/uc?export=download&id=${audio_parts[5]}`;
        }
        console.log("audiolink 2");
        let audioType = "audio/mpeg";
        if (audio_url.toUpperCase().endsWith("WAV")) audioType = "audio/wav";
        else if (audio_url.toUpperCase().endsWith("OGG")) audioType = "audio/ogg";
        else if (audio_url.toUpperCase().endsWith("MP3")) audioType = "audio/mpeg";
        else audioType = "audio/mp4";
        console.log("audio_url", audio_url);
        // const audio_html = `<audio style="max-width: 100%; width: 600px;" controls="controls">
        //   <source src="${audio_url}" type="${audioType}">
        // Audio format not supported use mp3, wav, m4a or ogg.
        // </audio>`;

        const audio_html = `<audio style="max-width: 100%; width: 600px;" controls="controls" controlsList="nodownload" autoplay>
    <source src="${audio_url}" type="${audioType}">
  Audio format not supported use mp3, wav, m4a or ogg.
  </audio>`;

        console.log("audio_html", audio_html);

        dispatch(slice1Actions.setHTMLTitle({ value: "Audio" }));
        dispatch(slice1Actions.setHTMLToSHow({ value: audio_html }));
        dispatch(slice1Actions.setShowHTMLReader({ value: true }));
        window.location.href = "#home-start";
        homeDiv = document.getElementById("home-content-start");
        homeDiv.scrollIntoView();
        break;
      case "directionslink":
        //console.log("urlParts[1]", urlParts[1]);
        let d_places = urlParts[1].split("|");
        let place_to = window.guide_obj.places.getPlace(d_places[1]);
        if (place_to == null) return;
        let place_from = window.guide_obj.places.getPlace(d_places[0]);
        if (place_from == null) return;
        let waypoints = "";
        _url = `https://www.google.com/maps/dir/?api=1&origin=${place_from.Lat},${place_from.Longi}&destination=${place_to.Lat},${place_to.Longi}${waypoints}`;
        win = window.open(_url, "_blank");
        win.focus();
        break;

      case "weatherlink":
        place = window.guide_obj.places.getPlace(urlParts[1]);
        if (place == null) return;
        _url = `https://www.wunderground.com/forecast/${place.Lat},${place.Longi}`;

        win = window.open(_url, "_blank");
        win.focus();

        break;
      default:
      // code block
    }
  };

  const getMoreDetailsCallback = (place_id, details) => {
    if (details === null) return;
    let hlink = "";
    console.log("getMoreDetailsCallback", details);
    if (typeof details.url != "undefined") {
      if (details.url.includes(";")) {
        const urls = details.url.split(";");
        hlink = `${urls[0]}`;
      } else hlink = `${details.url}`;
    }
    console.log("getMoreDetailsCallback", hlink);
    if (hlink !== "") {
      let win = window.open(hlink, "_blank");
      win.focus();
      return null;
    }
  };

  function guideClicked(guide_info_id) {
    //const guide_info_id = "IQrZiedo7oJWa9GgLR6L";
    dispatch(slice1Actions.SetLoadingGuide({ value: true }));
    DataBase.readGuideTransaction(guide_info_id, readGuideDataReturnNew, "guest");
  }

  const timeoutFunc = () => {
    dispatch(slice1Actions.setGuide({ guide: null, fitToBounds: true }));
  };

  const readGuideDataReturnNew = (res) => {
    let GuideObj = null;
    if (res.pass === true) {
      GuideObj = new Guide(res.guide_id);
      const guide_json = res.res.data;
      var guide_obj = JSON.parse(guide_json);
      const decoded = GuideObj.decodeGuideFb(guide_obj, res.infoDoc);
      if (decoded === true) {
        GuideObj.schedule.getPlaces_InSchedule();
        window.guide_obj = GuideObj;
        dispatch(slice1Actions.setGuide({ guide: "new" }));
      } else {
        console.log("decode failed");
      }
    } else {
      //console.log(res.res);
    }
    dispatch(slice1Actions.SetLoadingGuide({ value: false }));
  };

  return (
    <Router>
      <div id="a">
        {/* <HomeHeader />
        <Switch>
          <Route exact path="/"> */}
        {/* <HomeContent></HomeContent> */}

        <div className="home-panel" ref={ref}>
          {LoadingGuide ? (
            <div className="loading">
              <ReactLoading type={"spin"} color="#0000ff" height={"10%"} width={"10%"} />
            </div>
          ) : (
            <div style={{ margin: "10px" }}>
              <div id="home-content-start"></div>
              {/* <div
                style={{ fontSize: `${ContentFontZoom}%` }}
                dangerouslySetInnerHTML={{ __html: htmlString }}
                className="home-resize-panel"
                id="home-content"
                // ref={htmlDivRef}
              >
              </div> */}
              <InnerHTML
                className="home-resize-panel"
                id="home-content"
                style={{ fontSize: `${ContentFontZoom}%` }}
                html={htmlString}
              />
              {/* {ReactHtmlParser(htmlString)} */}
            </div>
          )}
        </div>
        {/* <div className="map-panel none">
              <h1>Map Panel</h1>
            </div> */}
        {/* </Route>
          <Route path="/places">
            <Places />
          </Route>
          <Route path="/schedule">
            <SchedulePanel />
          </Route>
        </Switch>
        <MobileFooter /> */}
        <HTMLReader html={htmlString} show={HTMLReaderModal} onHide={() => setHTMLReaderModal(false)} />
        {placeModal ? (
          <PlaceModal
            show={placeModal}
            onHide={() => setPlaceModal(false)}
            name={selectedPlace == null ? "" : selectedPlace.name}
            img={selectedPlace == null ? "" : selectedPlace.Photo}
            i={selectedPlace == null ? "" : selectedPlace.ID}
            // mainIndex={props.mainIndex}
            id={selectedPlace == null ? "" : selectedPlace.ID}
            place={selectedPlace}
          />
        ) : null}
      </div>
    </Router>
  );
};

export default Home;
