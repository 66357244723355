import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import "./Modal.css";
import { useDispatch } from "react-redux";
import { slice1Actions } from "../../store/reducer";
import { useSelector } from "react-redux";
import MoveEvents from "./MoveEvents";
import PromptModal from "./PromptModal";
import Appointment from "../../Models/Event";
import { DateTime } from "luxon";
import EventEdit from "../modals/EventEdit";
import AlertModal from "./AlertModal";

const ScheduleModal = (props) => {
  const dispatch = useDispatch();
  let ScheduleName = useSelector((state) => state.ScheduleName);
  let ScheduleNames = useSelector((state) => state.ScheduleNames);
  let schedule = useSelector((state) => state.schedule);
  let MobileMode = useSelector((state) => state.MobileMode);
  let GuideSettings = useSelector((state) => state.GuideSettings);

  const [Mode, setMode] = useState("move_day");
  const [ParamDate, setParamDate] = useState(null);
  const [MoveEventsShow, setMoveEventsShow] = useState(false);

  const [PromptModalShow, setPromptModalShow] = useState(false);
  const [PromptModalTitle, setPromptModalTitle] = useState("Enter Value");
  const [PromptModalMessage, setPromptModalMessage] = useState("");
  const [PromptModalButtons, setPromptModalButtons] = useState([]);
  const [PromptModalInputs, setPromptModalInputs] = useState([]);
  const [PromptModalCompact, setPromptModalCompact] = useState(null);
  const [ShowEventEdit, setShowEventEdit] = useState(false);
  const [EventEditMode, setShowEventMode] = useState("MODIFY");
  const [EventEditWhere, setShowEventWhere] = useState("after");

  const [AlertModalShow, setAlertModalShow] = useState(false);
  const [AlertModalTitle, setAlertModalTitle] = useState("");
  const [AlertModalMessage, setAlertModalMessage] = useState("");
  const [AlertModalButtons, setAlertModalButtons] = useState([]);

  const MoveEventsCallback = () => {
    dispatch(slice1Actions.setGuide({ guide: null }));
  };

  const handleMoveOneDay = () => {
    setMode("move_day");
    setMoveEventsShow(true);
    props.onHide();
  };

  const handleDeleteDay = () => {
    setMode("delete_day");
    setMoveEventsShow(true);
    props.onHide();
  };

  const handleMoveAllDays = () => {
    setMode("move_all_days");
    setMoveEventsShow(true);
    props.onHide();
  };

  function handleShowItinerary() {
    const htmlString = window.guide_obj.createHTMLItinerary(ScheduleName, GuideSettings.itinerary);
    dispatch(slice1Actions.setHTMLTitle({ value: "Itinerary" }));
    dispatch(slice1Actions.setHTMLToSHow({ value: htmlString }));
    dispatch(slice1Actions.setShowHTMLReader({ value: true }));
    window.location.href = "#home-start";
    var homeDiv = document.getElementById("home-content-start");
    if (MobileMode) dispatch(slice1Actions.setCurrentAppScreen({ value: "Home" }));
    else homeDiv.scrollIntoView();
    props.onHide();
  }

  const handleChooseSchedule = () => {
    dispatch(slice1Actions.setMultiselectCallback({ value: chooseScheduleCallBack }));
    dispatch(slice1Actions.setMultiselectItems({ value: ScheduleNames }));
    dispatch(slice1Actions.setMultiselectSelectedItems({ value: ScheduleName }));
    dispatch(slice1Actions.setMultiselectMode({ value: "uni" }));
    dispatch(slice1Actions.setMultiselectSearchText({ value: "" }));
    dispatch(slice1Actions.setMultiselectTitle({ value: "Select Schedule" }));
    dispatch(slice1Actions.setShowMultiselect({ value: true }));
  };

  const chooseScheduleCallBack = (schedules) => {
    if (schedules.length > 0) {
      dispatch(slice1Actions.setScheduleName({ value: schedules[0].label }));
      dispatch(slice1Actions.setGuide({ guide: null }));
      props.onHide();
    }
  };

  const handleChangeStartDay = () => {
    setMode("change_start_date");
    setMoveEventsShow(true);
    props.onHide();
  };

  const handleCopySchedule = () => {
    setPromptModalShow(true);
    setPromptModalTitle("Copy");
    setPromptModalMessage("Copy events to Schedule:");
    setPromptModalButtons([
      { Title: "OK", Method: CopyScheduleCallback },
      { Title: "CANCEL", Method: null },
    ]);
    setPromptModalInputs([{ name: "Group Name", value: ScheduleName }]);
  };

  const CopyScheduleCallback = (sched_name) => {
    console.log("sched_name", sched_name);
    window.guide_obj.schedule.copySchedule(ScheduleName, sched_name[0].value);
    dispatch(slice1Actions.setGuide({ guide: null }));
    props.onHide();
  };

  const handleAddSchedule = () => {
    setPromptModalShow(true);
    setPromptModalTitle("Add");
    setPromptModalMessage("Add New Schedule:");
    setPromptModalButtons([
      { Title: "OK", Method: AddScheduleCallback },
      { Title: "CANCEL", Method: null },
    ]);
    setPromptModalInputs([{ name: "Group Name", value: ScheduleName + "2" }]);
  };

  const AddScheduleCallback = (sched_name) => {
    console.log("sched_name", sched_name);
    if (sched_name[0].value === "Main") {
      myAlert("Cannot create another Main menu. Choose a different name", "Message");
      return;
    }
    let new_event = new Appointment();
    new_event.Subject = "New Event";
    new_event.Start = DateTime.now().toISO({ zone: "utc" });
    new_event.End = DateTime.now().toISO({ zone: "utc" });
    new_event.Body = "";
    new_event.Itinerary = sched_name[0].value;
    window.guide_obj.schedule.addEvent(new_event);
    dispatch(slice1Actions.setScheduleName({ value: sched_name[0].value }));
    dispatch(slice1Actions.setGuide({ guide: null }));
    props.onHide();
  };

  const handleRenameSchedule = () => {
    setPromptModalShow(true);
    setPromptModalTitle("Rename");
    setPromptModalMessage("Rename Schedule");
    setPromptModalButtons([
      { Title: "OK", Method: RenameScheduleCallback },
      { Title: "CANCEL", Method: null },
    ]);
    setPromptModalInputs([{ name: "Group Name", value: ScheduleName }]);
  };

  const RenameScheduleCallback = (sched_name) => {
    console.log("sched_name", sched_name);
    window.guide_obj.schedule.renameSchedule(ScheduleName, sched_name[0].value, window.guide_obj);
    dispatch(slice1Actions.setScheduleName({ value: sched_name[0].value }));
    dispatch(slice1Actions.setGuide({ guide: null }));
    props.onHide();
  };

  const handleDeleteSchedule = () => {
    window.guide_obj.schedule.deleteAllEvents(ScheduleName);
    dispatch(slice1Actions.setScheduleName({ value: "Main" }));
    dispatch(slice1Actions.setGuide({ guide: null }));
    props.onHide();
  };

  const addEvent = () => {
    setShowEventMode("ADD");
    setShowEventWhere("after");
    setShowEventEdit(true);
    props.onHide();
  };

  const EventEditCallback = () => {
    dispatch(slice1Actions.resetHomeScreen({ value: null }));
    dispatch(slice1Actions.setGuide({ guide: null }));
  };

  const myAlert = (message, title, buttons) => {
    setAlertModalMessage(message);
    setAlertModalTitle(title == null ? "Message" : title);
    setAlertModalButtons(buttons == null ? [{ Title: "OK", Method: null }] : buttons);
    setAlertModalShow(true);
  };

  if (ShowEventEdit)
    return (
      <EventEdit
        show={ShowEventEdit}
        onHide={() => setShowEventEdit(false)}
        event={null}
        mode={EventEditMode}
        place={null}
        where={EventEditWhere}
        schedule_name={ScheduleName}
        callback={() => EventEditCallback()}
      />
    );
  if (MoveEventsShow)
    return (
      <MoveEvents
        show={MoveEventsShow}
        onHide={() => setMoveEventsShow(false)}
        ScheduleName={ScheduleName}
        ScheduleNames={ScheduleNames}
        mode={Mode}
        param_date={ParamDate}
        callback={MoveEventsCallback}
      />
    );
  else
    return (
      <div className="modal">
        <Modal
          {...props}
          dialogClassName="myModalClass"
          scrollable={true}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          style={{ padding: "50px" }}
        >
          <Modal.Body>
            <div className="sort">
              <h4 style={{ color: "#3598DB" }}>
                <i className="far fa-calendar-alt"></i> Schedule Menu
              </h4>
              {schedule.length > 0 ? (
                <div>
                  {ScheduleNames.length > 0 ? <p onClick={handleChooseSchedule}>Choose Schedule</p> : null}
                  <p onClick={handleShowItinerary}>Show Itinerary</p>
                  <p onClick={handleChangeStartDay}>Change Start Date</p>
                  {/* <p onClick={handleMoveOneDay}>Move One Day</p> */}
                  {/* <p onClick={handleMoveAllDays}>Move All Days</p> */}
                  {/* <p onClick={handleDeleteDay}>Delete One Day</p> */}
                  <p onClick={handleCopySchedule}>Copy Schedule</p>
                  <p onClick={handleDeleteSchedule}>Delete Schedule</p>
                  <p onClick={handleAddSchedule}>Add Schedule</p>
                  {ScheduleName !== "Main" ? (
                    <div>
                      <p onClick={handleRenameSchedule}>Rename Schedule</p>
                    </div>
                  ) : null}
                </div>
              ) : null}
              {/* <div>
                <p onClick={addEvent}>Add Event</p>
              </div> */}
            </div>
          </Modal.Body>
        </Modal>
        <PromptModal
          show={PromptModalShow}
          onHide={() => setPromptModalShow(false)}
          PromptModalTitle={PromptModalTitle}
          PromptModalMessage={PromptModalMessage}
          PromptModalButtons={PromptModalButtons}
          PromptModalInputs={PromptModalInputs}
        />
        {AlertModalShow ? (
          <AlertModal
            show={AlertModalShow}
            onHide={() => setAlertModalShow(false)}
            attributes={{
              onHide: () => setAlertModalShow(false),
              AlertModalTitle: AlertModalTitle,
              AlertModalMessage: AlertModalMessage,
              AlertModalButtons: AlertModalButtons,
            }}
          />
        ) : null}
      </div>
    );
};

export default ScheduleModal;
