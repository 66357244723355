import React from "react";
import { Modal } from "react-bootstrap";
import "./Modal.css";
import { useDispatch } from "react-redux";
import { slice1Actions } from "../../store/reducer";
import { useSelector } from "react-redux";
import Common, { Settings, SortByEnum, AUTHORIZATION_LEVEL, DISTANCES, COLORS } from "../../Common/Common";

const UserInfoModal = (props) => {
  const dispatch = useDispatch();
  let user_info = useSelector((state) => state.user_info);
  let uid = typeof user_info.uid == "undefined" ? "" : user_info.uid;
  let distance_unit = typeof user_info.distance_unit == "undefined" ? "miles" : user_info.distance_unit;
  let date_format = typeof user_info.date_format == "undefined" ? "Month first" : user_info.date_format;
  let firstName = typeof user_info.first_name == "undefined" ? "" : user_info.first_name;
  let lastName = typeof user_info.last_name == "undefined" ? "" : user_info.last_name;
  let email = typeof user_info.email == "undefined" ? "" : user_info.email;
  let travel_payouts_marker =
    typeof user_info.travel_payouts_marker == "undefined" ? "" : user_info.travel_payouts_marker;

  //console.log(user_info, uid);
  return (
    <div className="modal">
      <Modal
        {...props}
        dialogClassName="myModalClass"
        aria-labelledby="contained-modal-title-vcenter"
        size={window.innerWidth < 450 ? "lg" : "md"}
        scrollable={true}
        // style={{ padding: "50px" }}
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">User Info</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="font-class">
            <div>
              <p className="attribute">User ID</p>
              <p>{uid}</p>
            </div>
            <div>
              <p className="attribute">email</p>
              <p>{email}</p>
            </div>
            <div>
              <p className="attribute">First Name</p>
              <p>{firstName}</p>
            </div>
            <div>
              <p className="attribute">Last Name</p>
              <p>{lastName}</p>
            </div>
            {/* <div>
              <p className="attribute">TravelPayouts marker</p>
              <p>{travel_payouts_marker}</p>
            </div> */}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default UserInfoModal;
