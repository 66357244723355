import React, { useState, useEffect, useRef } from "react";
import { Modal } from "react-bootstrap";
import "./Modal.css";
import { Editor } from "@tinymce/tinymce-react";
//import "../../../node_modules/tinymce/icons/default";
import { urlSchemeToFunctions, htmlScaleAdjust, htmlScaleFactor } from "../../CommonWeb/Common";
import HTMLLinksModalClass from "./HTMLLinksModalClass";
import HTMLInsertDialog from "./HTMLInsertDialog";
import { useSelector } from "react-redux";

const HTMLEditorComponent = (props) => {
  //console.log("HTMLReader", props);
  const [ShowHTML, setShowHTML] = useState(false);
  const [ShowCSS, setShowCSS] = useState(true);

  const [HTMLText, setHTMLText] = useState("");
  const [ContentStyle, setContentStyle] = useState("");

  const [HTMLtoShow, setHTMLtoShow] = useState("");
  //const [CSStoApply, setCSStoApply] = useState("");
  const [FullScreen, setFullScreen] = useState("md");
  const [ShowHTMLLinks, setShowHTMLLinks] = useState(false);
  const [ShowHTMLInsertDialog, setShowHTMLInsertDialog] = useState(false);
  const [SavedHTML, setSavedHTML] = useState(false);

  let user_info = useSelector((state) => state.user_info);
  let guide_meta = useSelector((state) => state.guide_meta);
  let GuideType = useSelector((state) => state.GuideType);
  const editorRef = useRef(null);

  useEffect(() => {
    console.log("useEffect");
    let html = props.html;
    //html = html.replace("</style><br>", "</style>");
    //if (html.indexOf(`<div class="home-resize-panel">`, 0) === -1) html =

    if (!html.includes("a {color: #1913AB; text-decoration: none !important;} "))
      html =
        `<style>a {color: #1913AB; text-decoration: none !important;} 
      a:visited {color: #661099;} a:active {color: red;}
      a:hover {color: purple; background: #AFB; } 
      .redtext {color: red;} </style>` + html; // body {font-size: 3rem;}</style>

    //html = htmlScaleAdjust(html);
    //html = html.replace(/<div>/g, `<div class="scalefix">`);

    //html = html.replace(/ class="scalefix"/g, ``);

    // html = html.replace(/&nbsp;/g, " ");
    //html = html.replace(/<img style="width:/g, `<img style="max-width: 100%; width:`);

    //html = html.replace(/body {  font-size: 3em;}/, "body {  font-size: 1.4em;}");
    //html = html.replace(/strong/>g, "b>");
    setHTMLtoShow(html);
    setSavedHTML(html);
    setContentStyle(getCSS(html));
    setShowCSS(false);
    // setTimeout(() => {
    //   setShowCSS(false);
    // }, 10);
  }, [props.html]);

  const handleClose = () => {
    if (ShowCSS) {
      setShowCSS(false);
      return;
    }
    props.onHide();
  };

  const handleEditorChange = (e) => {
    //console.log("Content was updated:", e.target.getContent());
  };

  const insertLink = () => {
    console.log("insertLink");
    setShowHTMLLinks(true);
    //const link = `<a href="urllink:https://www.google.com">google.com<\a>`;
    //let link = `<a href="urllink:https://www.google.com"><button style="border: none; padding:32px; margin:10px; color:white; border-radius:10%; font-size:36px; background-color: #008CBA;">google.com</button></a>&nbsp;`;

    //editorRef.current.insertContent(link);
  };

  const insertLinkCallback = (link) => {
    let content = "";
    if (link.linkUI === "Text only") {
      content = `<a href="${link.url}">${link.text}<\a>`;
    } else if (link.linkUI === "With Image" && link.text !== "") {
      content = `<a href="${link.url}"><button style="border: none; background: none; border-radius: 10%; font-size: 110%; padding:5px;background-size:cover;"><img style="border-radius: 10%;" src="${link.imgURL}" alt="image" width="350"/><br>${link.text}</button></a>&nbsp;`;
    } else if (link.linkUI === "With Image") {
      content = `<a href="${link.url}"><img src="${link.imgURL}" alt="image" style="max-width: 100%; width:${link.imageWidth}px; border-radius:10%; margin:10px;"></a>&nbsp;&nbsp;`;
    } else if (link.linkUI === "With Icon") {
      content = `<a href="${link.url}"><img src="${link.imgURL}" alt="icon" style="max-width: 100%; margin:10px;"></a>&nbsp;&nbsp;`;
    } else {
      content = `<a href="${link.url}"><button style="border: none; padding:32px; margin:10px; color:white; border-radius:10%; font-size:200%; background-color: #008CBA;">${link.text}</button></a>&nbsp;`;
    }
    console.log("insertLinkCallback", content, link.text, link.url);
    editorRef.current.insertContent(content);
  };

  const insertContent = () => {
    setShowHTMLInsertDialog(true);
  };

  const insertContentCallback = (content) => {
    if (editorRef.current) {
      console.log(content.payload.html);
      editorRef.current.insertContent(content.payload.html);
    }
  };

  const getContent = () => {
    if (editorRef.current) {
      return editorRef.current.getContent();
    }
  };

  const setContent = (content) => {
    if (editorRef.current) {
      //console.log("setContent", content);
      return editorRef.current.setContent(content);
    }
  };

  const getCSS = (html) => {
    let index1 = html.indexOf("<style>", 0);
    let index2 = html.indexOf("</style>", index1 + 1);
    let inline_style = "";
    if (index1 !== -1 && index2 !== -1) {
      inline_style = html.substring(index1 + 7, index2);
    }
    const scale = htmlScaleFactor();
    // let content_style = `a {color: #1913AB; text-decoration: none !important;}
    // a:visited {color: #661099;} a:active {color: red;}
    // a:hover {color: purple; background: #AFB; }
    // .redtext {color: red;} .home-resize-panel {transform-origin: top left; transform: scale(${scale.div}); font-size: ${scale.font};}`;
    // content_style = content_style + inline_style;
    return inline_style;
  };

  // useEffect(() => {
  //let html = props.html;
  //let content_style = getCSS(html);
  //console.log("content_style", content_style);
  //if (html.indexOf(`<div class="home-resize-panel">`, 0) === -1) html = < 450 ? "lg" : "md"(html);
  //setHTMLtoShow(html);
  //setCSStoApply(content_style);
  //console.log("content_style", content_style);
  // }, []);

  const handleOK = () => {
    if (ShowCSS) {
      setShowCSS(false);
      return;
    }
    props.onHide();
    const content = getContent();
    //console.log("content", content);
    let html = content;
    if (content !== "") html = "<style>" + ContentStyle + "</style>" + content;
    // html = html.replace(/&nbsp;/g, " ");
    if (props.callback !== null) {
      //html = html.replace("</style><br>", "</style>");
      //html = html.replace(/body {  font-size: 1.4em;}/, "body {  font-size: 3em;}");
      //html = html.replace(/strong>/g, "b>");
      props.callback(html);
    }
    console.log(html);
  };

  const handleHTMLChanged = (text) => {
    let html = "<style>" + ContentStyle + "</style>" + text;
    if (props.callback_rt !== null) {
      //("</style><br>", "</style>");
      props.callback_rt(html);
    }
    setSavedHTML(text);
    //console.log(text);
    //setHTMLText(text);
  };

  const handleCSSChanged = (event) => {
    console.log("handleCSSChanged");
    const content_style = event.target.value;
    setContentStyle(content_style);
    if (props.callback_rt !== null) {
      props.callback_rt("<style>" + content_style + "</style>" + SavedHTML);
      //console.log("handleCSSChanged", content);
    }
    //console.log(content_style);
  };

  const editCSS = () => {
    console.log("editCSS");
    if (!ShowCSS) setHTMLtoShow(getContent());
    setShowCSS(!ShowCSS);
  };

  const handleHTML = () => {
    if (ShowHTML === false) {
      var content = getContent();
      var formatted = formatHTML(unformatHTML(content));
      //console.log("content", content);
      //console.log("formatted", formatted);
      setHTMLText("<style>" + ContentStyle + "</style>" + formatted);
      setShowHTML(true);
    } else {
      setShowHTML(false);
      let html = HTMLText;
      ContentStyle = getCSS(html);
      setContent(html);
    }
  };

  // function handleFullScreen(breakpoint) {
  //   setShowModal(false);
  //   setFullScreen(breakpoint);
  //   setShowModal(true);
  //   //setShow(true);
  // }
  const handleFullScreen = () => {
    const _FullScreen = FullScreen === "md" ? "lg" : "md";
    setFullScreen(_FullScreen);
  };

  function formatHTML(html) {
    var indent = "\n";
    var tab = "\t";
    var i = 0;
    var pre = [];

    html = html
      .replace(new RegExp("<pre>((.|\\t|\\n|\\r)+)?</pre>"), function (x) {
        pre.push({ indent: "", tag: x });
        return "<--TEMPPRE" + i++ + "/-->";
      })
      .replace(new RegExp("<[^<>]+>[^<]?", "g"), function (x) {
        var ret;
        var tag = /<\/?([^\s/>]+)/.exec(x)[1];
        var p = new RegExp("<--TEMPPRE(\\d+)/-->").exec(x);

        if (p) pre[p[1]].indent = indent;

        if (
          [
            "area",
            "base",
            "br",
            "col",
            "command",
            "embed",
            "hr",
            "img",
            "input",
            "keygen",
            "link",
            "menuitem",
            "meta",
            "param",
            "source",
            "track",
            "wbr",
          ].indexOf(tag) >= 0
        )
          // self closing tag
          ret = indent + x;
        else {
          if (x.indexOf("</") < 0) {
            //open tag
            if (x.charAt(x.length - 1) !== ">")
              ret = indent + x.substr(0, x.length - 1) + indent + tab + x.substr(x.length - 1, x.length);
            else ret = indent + x;
            !p && (indent += tab);
          } else {
            //close tag
            indent = indent.substr(0, indent.length - 1);
            if (x.charAt(x.length - 1) !== ">")
              ret = indent + x.substr(0, x.length - 1) + indent + x.substr(x.length - 1, x.length);
            else ret = indent + x;
          }
        }
        return ret;
      });

    for (i = pre.length; i--; ) {
      html = html.replace(
        "<--TEMPPRE" + i + "/-->",
        pre[i].tag.replace("<pre>", "<pre>\n").replace("</pre>", pre[i].indent + "</pre>")
      );
    }

    let temp = html.charAt(0) === "\n" ? html.substr(1, html.length - 1) : html;
    return temp.replace(/(^[ \t]*\n)/gm, "");
  }

  function unformatHTML(html) {
    var i = 0;
    var pre = [];

    html = html
      .replace(new RegExp("<pre>((.|\\t|\\n|\\r)+)?</pre>"), function (x) {
        pre.push(x);
        return "<--TEMPPRE" + i++ + "/-->";
      })
      .replace(/\n/g, "")
      .replace(/\t/g, "");

    for (i = pre.length; i--; ) {
      html = html.replace("<--TEMPPRE" + i + "/-->", pre[i]);
    }

    html = html.replace(new RegExp("<pre>\\n"), "<pre>").replace(new RegExp("\\n\\t*</pre>"), "</pre>");
    return html;
  }

  // if (ShowHTMLLinks)
  //   return (
  //     <HTMLLinksModalClass show={ShowHTMLLinks} onHide={() => setShowHTMLLinks(false)} callback={insertLinkCallback} />
  //   );
  // else
  //console.log("ContentStyle", ContentStyle);
  return (
    <div className="html-reader-modal">
      <div className="sort">
        <div className="dialog-heading">
          <div>
            <h4 style={{ color: "#3598DB" }}>{props.title}</h4>
          </div>
          <div onClick={editCSS}>
            <h5 style={{ color: "black", cursor: "pointer" }}>{ShowCSS ? "Edit Content" : "Edit Style"}</h5>
          </div>
          {/* <div>
                <i className={`fa fa-code`} onClick={editCSS}></i>
              </div> */}
          {/* <div className="search">
              <i className={`fas fa-expand-arrows-alt`} onClick={handleFullScreen}></i>
            </div> */}
          {props.embedded !== true || ShowCSS === true ? (
            <div>
              <i className={`fa fa-save`} onClick={handleOK}></i>
            </div>
          ) : null}
          {props.embedded !== true || ShowCSS === true ? (
            <div>
              <i className={`fas fa-times`} onClick={handleClose}></i>
            </div>
          ) : null}
        </div>
      </div>
      {/* {ShowHTML ? (
            <textarea
              rows={20}
              onChange={handleHTMLChanged}
              value={HTMLText}
              style={{ width: "100%", height: "100%", fontSize: "16px" }}
            />
          ) : ( */}
      {ShowCSS ? (
        <textarea
          rows={20}
          onChange={handleCSSChanged}
          value={ContentStyle}
          style={{ width: "100%", height: "100%", fontSize: "16px" }}
        />
      ) : (
        <Editor
          //ref={editor}
          onInit={(evt, editor) => (editorRef.current = editor)}
          apiKey="5vj239p1d73x250y3bb80ir7lqqgtygpdzohvi8n7nme9a2b"
          initialValue={HTMLtoShow}
          onEditorChange={(newText) => handleHTMLChanged(newText)}
          init={{
            force_br_newlines: true,
            force_p_newlines: false,
            forced_root_block: "", // Needed for 3.x

            fontsize_formats: "60% 80% 100% 120% 140% 160% 180% 200% 220% 240% 260% 280% 300% 350% 400%",
            content_css: "/HTMLEditor.css,HTMLEditor.css",
            content_style: ContentStyle,
            height: 500,
            menubar: false,
            toolbar_mode: "wrap",
            //output: "/tinymce/icons/",
            plugins: [
              "advlist  lists  image", //autolink link
              "charmap print preview anchor ", //help
              "searchreplace visualblocks code",
              "insertdatetime media table paste wordcount fullscreen emoticons ",
            ],
            setup: function (editor) {
              editor.ui.registry.addButton("linksBTN", {
                text: "Link",
                onAction: insertLink,
              });
              editor.ui.registry.addButton("embedBTN", {
                text: "Embed",
                onAction: insertContent,
              });
              // editor.ui.registry.addButton("cssBTN", {
              //   text: "CSS",
              //   onAction: editCSS,
              // });
            },
            toolbar:
              "undo redo |  bold italic underline strikethrough | \
                  alignleft aligncenter alignright alignjustify | \
          bullist numlist checklist | forecolor backcolor casechange | outdent indent | fontselect fontsizeselect formatselect | charmap emoticons | linksBTN | embedBTN | fullscreen |code ",
          }}
          onChange={handleEditorChange}
        />
      )}
      <HTMLLinksModalClass
        show={ShowHTMLLinks}
        onHide={() => setShowHTMLLinks(false)}
        callback={insertLinkCallback}
        user_info={user_info}
        guide_meta={guide_meta}
        guide_type={GuideType}
      />
      <HTMLInsertDialog
        show={ShowHTMLInsertDialog}
        onHide={() => setShowHTMLInsertDialog(false)}
        callback={insertContentCallback}
        user_info={user_info}
        section={null}
      />
    </div>
  );
};

export default HTMLEditorComponent;
