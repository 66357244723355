import React, { useState } from "react";
import "./GuideCard.css";
// import "../Modal.css";
import Common from "../../../Common/Common";
import { useSelector } from "react-redux";
import ReviewsDialog from "../ReviewsDialog";
import GuideEditModal from "../GuideEditModal";

const GuideCard = (props) => {
  let user_info = useSelector((state) => state.user_info);
  //let guide_meta = useSelector((state) => state.guide_meta);
  const [ReviewsDialogShow, setReviewsDialogShow] = useState(false);
  const [GuideEditModalShow, setGuideEditModalShow] = useState(false);
  const [InfoCall, setInfoCall] = useState(false);

  const getImageURLString = (photo, photo_m) => {
    let str = photo_m;
    if (str === "" || typeof str === "undefined") str = photo;
    let img = Common.getImageURL(str, Common.getFBPath(window.guide_obj.guide_id, user_info), false);
    //if (!img.startsWith("data")) console.log(img);
    return img;
  };
  //console.log(`rating:${props.rating}:`);

  const downloadGuide = () => {
    if (InfoCall) {
      setInfoCall(false);
      return;
    }
    if (props.callback !== null) props.callback(props.id, props.guide_info.guide_id, props.name);
    if (props.type !== "component") props.onHide();
  };

  const deleteGuide = (e) => {
    e.stopPropagation();
    if (props.delete_callback !== null) props.delete_callback(props.id, props.guide_info.guide_id);
  };
  const CopyFromGuide = (e) => {
    e.stopPropagation();
    if (props.copy_callback !== null) props.copy_callback(props.id, props.guide_info.guide_id);
  };

  const GuideInfo = (e) => {
    setInfoCall(true);
    e.stopPropagation();
    setGuideEditModalShow(true);
  };

  const showReviews = () => {
    setReviewsDialogShow(true);
  };
  //console.log("props.mode", props.mode);
  //console.log("props.access", props.access);

  return (
    <div className="place-card" title="Select Guide" onClick={downloadGuide}>
      <div>
        <img
          src={getImageURLString(props.image, props.imag_m)}
          style={{ objectFit: "cover", minWidth: 80, maxWidth: 100 }}
          alt=""
        />
        <p>
          <span>Rating: </span>
          {props.rating.toFixed(2)}
        </p>
        <p>
          {props.reviews > 0 ? (
            <span style={{ color: "#3598DB" }} onClick={showReviews}>
              Reviews:{" "}
            </span>
          ) : (
            <span>Reviews: </span>
          )}
          {`${props.reviews.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
        </p>
        <p>
          <span>Read: </span>
          {`${props.read.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}
        </p>
      </div>
      <div>
        <div>
          <p style={{ fontWeight: "700" }}>{props.name}</p>
          {/* <p>
            <span>ID: </span>
            {props.id}
          </p> */}
          <p>
            <span>Language: </span>
            {props.language}
          </p>
          <p>
            <span>Countries: </span>
            {Common.shortenText(props.countries.join(", "), 50)}
          </p>
          <p>
            <span>Cities: </span>
            {Common.shortenText(props.cities.join(", "), 50)}
          </p>
          <p>
            <span>Access: </span>
            {props.access}
          </p>
        </div>
        {props.type !== "component" ? (
          <div className="flex-container">
            {props.mode === "list" && props.type !== "component" ? (
              <div style={{ fontSize: "160%" }}>
                <i title="Select Guide" className={`fas fa-check color-blue`} onClick={downloadGuide}></i>
              </div>
            ) : null}
            {props.mode === "download" && props.type !== "component" ? (
              <div style={{ fontSize: "160%" }}>
                <i title="Download Guide" className={`fas fa-download color-blue`} onClick={downloadGuide}></i>
              </div>
            ) : null}
            {props.mode === "download" && props.type !== "component" ? (
              <div style={{ fontSize: "160%" }}>
                <i title="Delete Guide" className={`fa fa-minus-circle color-blue`} onClick={(e) => deleteGuide(e)}></i>
              </div>
            ) : null}

            <div style={{ fontSize: "160%" }}>
              <i title="Guide Info" className={`fas fa-info color-blue`} onClick={(e) => GuideInfo(e)}></i>
            </div>
          </div>
        ) : (
          <div className="flex-container">
            <div style={{ fontSize: "160%" }}>
              <i title="Guide Info" className={`fas fa-info color-blue`} onClick={(e) => GuideInfo(e)}></i>
            </div>
            {props.guide_info.user_id === user_info.uid ? (
              <div style={{ fontSize: "160%" }}>
                <i title="Delete Guide" className={`fa fa-minus-circle color-blue`} onClick={(e) => deleteGuide(e)}></i>
              </div>
            ) : null}
            {props.access.includes("Read") ? null : (
              <div style={{ fontSize: "160%" }}>
                <i title="Copy from Guide" className={`fa fa-clone color-blue`} onClick={(e) => CopyFromGuide(e)}></i>
              </div>
            )}
            {props.mode === "list" ? (
              <div style={{ fontSize: "160%" }}>
                <i title="Select Guide" className={`fas fa-check color-blue`} onClick={downloadGuide}></i>
              </div>
            ) : null}
            {/* <div style={{ fontSize: "160%" }}>
              <i title="Copy from Guide" className={`fa fa-clone color-blue`} onClick={(e) => CopyFromGuide(e)}></i>
            </div> */}
          </div>
        )}
      </div>
      {ReviewsDialogShow ? (
        <ReviewsDialog show={ReviewsDialogShow} onHide={setReviewsDialogShow} id={props.id} />
      ) : null}
      {GuideEditModalShow ? (
        <GuideEditModal
          show={GuideEditModalShow}
          onHide={() => setGuideEditModalShow(false)}
          //guide_meta={guide_meta}
          guide_info={props.guide_info}
          guide_info_id={props.id}
          user_info={user_info}
          mode={"VIEW"}
          // update_display={update_display}
        />
      ) : null}
    </div>
  );
};

export default GuideCard;
