import { query, where } from "firebase/firestore";
import { DateTime } from "luxon";

//////////////////////Mobile//////////////////
// // Firebase version 8
// import firebase from "firebase/app";
// import "firebase/auth";
// import "firebase/database";
// import "firebase/firestore";
// import "firebase/functions";
// import "firebase/storage";
// import { Analytics, Event } from "expo-analytics";
// import { Platform } from "react-native";
/////////////////////////////////

////////////////////Web/////////////////////
// Firebase version 9
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/database";
import "firebase/compat/firestore";
import "firebase/compat/functions";
import "firebase/compat/storage";
//import ReactGA from "react-ga";
import ReactGA from "react-ga4";
////////////////////////////////////////////

// YellowBox.ignoreWarnings([
//   "Setting a timer for a long period of time", // TODO: Remove when fixed
// ]);
//console.disableYellowBox = true;
//LogBox.ignoreLogs(["Setting a timer for a long period of time"]);
// Optionally import the services that you want to use
//import "firebase/auth";
//import "firebase/database";
//import "firebase/firestore";
//import "firebase/functions";
//import "firebase/storage";

// Initialize Firebase
const firebaseConfig = {
  apiKey: "AIzaSyClEXGdfie0SJWyJNuj0gkPHZidmVPhr8g",
  authDomain: "liquidguides-2ce60.firebaseapp.com",
  databaseURL: "https://liquidguides-2ce60.firebaseio.com",
  projectId: "liquidguides-2ce60",
  storageBucket: "liquidguides-2ce60.appspot.com",
  messagingSenderId: "146934822866",
  appId: "1:146934822866:web:242e8852b05ea7bbb6fa0b",
  measurementId: "G-93YRF595B3",
};

if (!firebase.apps.length) {
  const app = firebase.initializeApp(firebaseConfig);
}

export default class DataBase {
  static onAuthStateChanged = (callback) => {
    firebase.auth().onAuthStateChanged((user) => {
      //if (user) {
      callback(user);
      //}
    });
  };

  static SignUp = (email, password, callback) => {
    // try {
    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then((user) => {
        callback({ pass: true, res: user });
      })
      .catch((error) => {
        callback({ pass: false, res: error });
      });

    // } catch (error) {
    //console.log(error.toString(error));
    //   callback(error);
    // }
  };

  static SignUpWithLink = (email, callback) => {
    const actionCodeSettings = {
      // URL you want to redirect back to. The domain (www.example.com) for this
      // URL must be in the authorized domains list in the Firebase Console.
      url: "https://andiamotravelapp.page.link/register",
      // This must be true.
      handleCodeInApp: true,
      iOS: {
        bundleId: "com.andiamoplaces.andiamotravelapp",
      },
      android: {
        packageName: "com.andiamoplaces.andiamotravelapp",
        installApp: true,
        minimumVersion: "1.0.0",
      },
      //dynamicLinkDomain: "example.page.link",
    };
    firebase
      .auth()
      .sendSignInLinkToEmail(email, actionCodeSettings)
      .then(() => {
        callback({ pass: true, res: true });
        // ...
      })
      .catch((error) => {
        callback({ pass: false, res: error });
      });
  };

  static Login = (email, password, callback) => {
    // try {
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((res) => {
        //console.log(res);
        callback({ pass: true, res: res });
      })
      .catch((error) => {
        //console.log(error.message);
        callback({ pass: false, res: error });
      });
  };

  static DeleteAuthUser = (callback) => {
    var user = firebase.auth().currentUser;
    //callback({ pass: false, user: user });
    user.delete().then(
      function () {
        callback({ pass: true, error: "" });
      },
      function (error) {
        console.log(error.message);
        callback({ pass: false, error: error.message });
      }
    );
  };

  static AnonymousLogin = (callback) => {
    firebase
      .auth()
      .signInAnonymously()
      .then((res) => {
        callback({ pass: true, res: res });
      })
      .catch((error) => {
        // var errorCode = error.code;
        // var errorMessage = error.message;
        callback({ pass: false, res: error });
      });
  };

  static Logout = (callback) => {
    firebase
      .auth()
      .signOut()
      .then(
        function () {
          callback({ pass: true, res: true });
        },
        function (error) {
          callback({ pass: false, res: error });
        }
      );
  };

  static forgotPassword = (Email, callback) => {
    firebase
      .auth()
      .sendPasswordResetEmail(Email)
      .then(function (user) {
        //console.log(user);
        callback(true);
      })
      .catch(function (e) {
        console.log("forgotPassword error: ", e);
        callback(e);
      });
  };

  static readSettings = (callback) => {
    //console.log("readSettings", user_id);
    const db = firebase.firestore();
    var docRef = db.collection("settings").doc("settings");
    docRef
      .get()
      .then(function (doc) {
        if (doc.exists) {
          //console.log("Read Settings from FB");
          callback({ pass: true, res: doc.data() });
        } else {
          //console.log("Could not read Settings from FB!");
          callback({ pass: false, res: "No user found" });
        }
      })
      .catch(function (error) {
        console.log("Error getting settings from Firebase:", error.message);
        callback({ pass: false, res: error });
      });
  };

  static readUser = (user_id, callback) => {
    //console.log("readUser", user_id);
    const db = firebase.firestore();
    var docRef = db.collection("users").doc(user_id);
    docRef
      .get()
      .then(function (doc) {
        if (doc.exists) {
          //console.log("Read user info from FB");
          callback({ pass: true, res: doc.data() });
        } else {
          //console.log("No such user in FB!");
          callback({ pass: false, res: "No user found" });
        }
      })
      .catch(function (error) {
        console.log("Error getting user from Firebase:", error.message);
        callback({ pass: false, res: error });
      });
  };

  static findUserByUID = (uid, callback) => {
    //console.log("findUserByUID", uid);
    const db = firebase.firestore();
    db.collection("users")
      .where("uid", "==", uid)
      .get()
      .then((querySnapshot) => {
        if (querySnapshot.empty) {
          callback({ pass: false, res: "No user found", doc_id: null });
          // return;
        } else {
          var doc = querySnapshot.docs[0];
          //console.log("findUserByUID doc", doc);
          callback({ pass: true, res: doc.data(), doc_id: doc.id });
          // return;
        }
      })
      .catch(function (error) {
        console.log("Error finding User By UID:", error.message);
        callback({ pass: false, res: error, doc_id: null });
      });
  };

  static findUserByFBID = (fb_id, callback) => {
    //console.log("findUserByFBID", fb_id);
    const db = firebase.firestore();
    db.collection("users")
      .where("fb_id", "==", fb_id)
      .get()
      .then((querySnapshot) => {
        if (querySnapshot.empty) {
          callback({ pass: false, res: "No user found", doc_id: null });
          // return;
        } else {
          var doc = querySnapshot.docs[0];
          //console.log("findUserByFBID doc", doc);
          callback({ pass: true, res: doc.data(), doc_id: doc.id });
          // return;
        }
      })
      .catch(function (error) {
        console.log("Error finding User By FB ID:", error.message);
        callback({ pass: false, res: error, doc_id: null });
      });
  };

  static findUserByEmail = (email, callback) => {
    //console.log("readUser", user_id);
    const db = firebase.firestore();
    var docRef = db.collection("users");
    docRef
      .where("email", "==", email)
      .get()
      .then((querySnapshot) => {
        if (querySnapshot.empty) {
          callback({ pass: false, res: "No user found", doc_id: null });
          // return;
        } else {
          var doc = querySnapshot.docs[0];
          callback({ pass: true, res: doc.data(), doc_id: doc.id });
          // return;
        }
      })
      .catch(function (error) {
        console.log("Error getting user from Firebase:", error.message);
        callback({ pass: false, res: error, doc_id: null });
      });
  };

  static getAllUsers = (callback) => {
    let users = [];
    //console.log("getAllUsers");
    const db = firebase.firestore();
    db.collection("users")
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          users.push({ data: doc.data() });
        });
        callback({ pass: true, res: users });
      })
      .catch(function (error) {
        console.log("Error getting users from Firebase:", error.message);
        callback({ pass: false, res: error });
      });
  };

  static updateUser = (_user, user_id, callback) => {
    //console.log("updateUser", _user);
    // if (user.uid == "guest") {
    //   callback({ pass: true, res: user.uid });
    //   return;
    // }
    if (_user == null || user_id == null) callback({ pass: false, res: "user is null" });
    var user = JSON.parse(JSON.stringify(_user));
    user.modify_date = DateTime.now().toISO({ zone: "utc" });
    var db = firebase.firestore();
    db.collection("users")
      .doc(user_id)
      .update(user)
      .then(() => {
        callback({ pass: true, res: user_id });
      })
      .catch(function (error) {
        callback({ pass: false, res: error });
      });
  };

  static DeleteUser = (user_id, callback) => {
    if (user_id == null) callback({ pass: false, res: "user is null" });
    var db = firebase.firestore();
    db.collection("users")
      .doc(user_id)
      .delete()
      .then(() => {
        callback({ pass: true, res: user_id });
      })
      .catch(function (error) {
        callback({ pass: false, res: error });
      });
  };

  static addUser = (_user, callback) => {
    //console.log("user", _user);
    var user = JSON.parse(JSON.stringify(_user));
    user.create_date = DateTime.now().toISO({ zone: "utc" });
    user.modify_date = DateTime.now().toISO({ zone: "utc" });

    var db = firebase.firestore();
    db.collection("users")
      .add(user)
      .then((docRef) => {
        callback({ pass: true, res: docRef.id });
      })
      .catch((error) => {
        callback({ pass: false, res: error });
      });
  };

  static getUserImages = (uid, callback) => {
    let images = [];
    const db = firebase.firestore();
    db.collection("images")
      .where("user_id", "==", uid)
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          images.push({ id: doc.id, data: doc.data() });
        });
        callback({ pass: true, res: images });
      })
      .catch(function (error) {
        console.log("Error getting user images from Firebase:", error.message);
        callback({ pass: false, res: error });
      });
  };

  static deleteFromStorage = (storageURLs, callback) => {
    if (storageURLs.length === 0) {
      callback({ pass: true, error: "" });
      return;
    }
    let error_flag = false;
    for (let i = 0; i < storageURLs.length && !error_flag; i++) {
      var desertRef = firebase.storage().refFromURL(storageURLs[i]);
      desertRef
        .delete()
        .then(function () {
          if (i === storageURLs.length - 1) callback({ pass: true, error: "" });
        })
        .catch(function (error) {
          //console.log("Error deleting image", error.message);
          //Alert.alert("Error Cancelling Account, check internet and try again");
          error_flag = true;
          callback({ pass: false, error: error });
          return;
        });
    }
  };

  static addImage = async (image_meta, callback) => {
    var db = firebase.firestore();
    db.collection("images")
      .add(image_meta)
      .then((docRef) => {
        callback({ pass: true, res: docRef.id });
      })
      .catch((error) => {
        console.log("Error adding user image to Firebase:", error.message);
        callback({ pass: false, res: error });
      });
  };

  static updateImage = async (image_id, image_meta, callback) => {
    var db = firebase.firestore();
    await db
      .collection("images")
      .doc(image_id)
      .update(image_meta)
      .then(() => {
        if (callback !== null) callback({ pass: true, image_id: image_id, image_meta: image_meta });
      })
      .catch(function (error) {
        console.log("Error updating user image in Firebase:", error.message);
        if (callback !== null) callback({ pass: false, res: error });
      });
  };

  static deleteImage = async (image_id, callback) => {
    const db = firebase.firestore();
    await db
      .collection("images")
      .doc(image_id)
      .delete()
      .then(function () {
        //console.log("*** call cleanup***", callback);
        if (callback !== null) callback({ pass: true, res: image_id });
      })
      .catch(function (error) {
        console.error("Error deleting Image from Firebase: ", error.message);
        if (callback !== null) callback({ pass: false, res: error });
      });
  };

  static deleteImageMeta = (ids, callback) => {
    if (ids.length === 0) {
      callback({ pass: true, error: "" });
      return;
    }
    let error_flag = false;
    const db = firebase.firestore();
    for (let i = 0; i < ids.length && !error_flag; i++) {
      db.collection("images")
        .doc(ids[i])
        .delete()
        .then(function () {
          if (i === ids.length - 1) callback({ pass: true, error: "" });
        })
        .catch(function (error) {
          error_flag = true;
          callback({ pass: true, error: "" });
          return;
        });
    }
  };

  deleteFileFromStorage = (file_name, callback1, callback2) => {
    const storageRef = firebase.storage().ref();
    var desertRef = firebase.storage().refFromURL(this._selected_file.url);
    desertRef
      .delete()
      .then(function () {
        callback1(file_name, callback2);
      })
      .catch(function (error) {
        console.log("Error deleting storage file", error.message);
        callback1(null, null);
      });
  };

  static updateGuideData = (guide_data, guide_data_id, uid, callback) => {
    //console.log("updateGuideData", guide_data_id, uid, callback);
    const _data = {
      data: JSON.stringify(guide_data),
      user_id: uid,
    };
    //console.log("updateGuideData", _data);
    var db = firebase.firestore();
    // var col = db.collection("guide_data").doc(guide_data_id);
    // //console.log(col);
    // return;
    db.collection("guide_data")
      .doc(guide_data_id)
      .set(_data)
      .then(() => {
        callback({ pass: true, res: true });
      })
      .catch(function (error) {
        console.log("Error updating guide data", error.message);
        callback({ pass: false, res: error });
      });
  };

  static updateGuideInfo = (guide_id, guide_info, guide_info_id, user_id, callback) => {
    guide_info.user_id = user_id;
    guide_info.guide_id = guide_id;
    guide_info.mod = DateTime.now().toISO({ zone: "utc" });

    delete guide_info.start_date;
    delete guide_info.end_date;
    delete guide_info.ratings;
    delete guide_info.price;
    delete guide_info.shared;
    delete guide_info.group_read;
    delete guide_info.group_copy;
    delete guide_info.group_modify;
    delete guide_info.categories;

    var db = firebase.firestore();
    db.collection("guide_info")
      .doc(guide_info_id)
      .update(guide_info)
      .then(() => {
        callback({ pass: true, res: true });
      })
      .catch(function (error) {
        callback({ pass: false, res: error });
      });
  };

  static addGuideInfo = (guide_info, guide_id, user_info, callback) => {
    guide_info.user_id = user_info.uid;
    guide_info.owner_name = `${user_info.first_name} ${user_info.last_name}`;
    guide_info.guide_id = guide_id;
    guide_info.mod = DateTime.now().toISO({ zone: "utc" });
    guide_info.num_ratings = 0;

    // delete guide_info.start_date;
    // delete guide_info.end_date;
    // delete guide_info.ratings;
    // delete guide_info.price;
    // delete guide_info.shared;
    // delete guide_info.group_read;
    // delete guide_info.group_copy;
    // delete guide_info.group_modify;
    // delete guide_info.categories;

    //console.log("addGuideInfo", guide_info);
    //console.log("_info", _info);
    var db = firebase.firestore();
    db.collection("guide_info")
      .add({ ...guide_info })
      .then((docRef) => {
        callback({ pass: true, res: docRef.id });
      })
      .catch((error) => {
        callback({ pass: false, res: error });
      });
  };

  static copyGuideInfo = (guide_info, guide_id, user_info, callback) => {
    guide_info.access = [user_info.uid];
    guide_info.guide_name = guide_info.guide_name + "_C";
    this.addGuideInfo(guide_info, guide_id, user_info, callback);
  };

  static addGuideData = (guide_data, callback) => {
    //console.log("guide_data", guide_data);
    const _data = {
      data: JSON.stringify(guide_data),
      user_id: guide_data.user_id,
    };
    var db = firebase.firestore();
    db.collection("guide_data")
      .add(_data)
      .then((docRef) => {
        callback({ pass: true, res: docRef.id });
      })
      .catch((error) => {
        callback({ pass: false, res: error });
      });
  };

  static batchedWriteGuide = (
    _guide_data,
    _guide_id,
    _guide_info_id,
    user_info,
    type,
    callback,
    to_guide_id,
    to_guide_info_id
  ) => {
    //console.log("batchedWriteGuide", guide_id, guide_info_id, user_info, type);
    var guide_data = JSON.parse(JSON.stringify(_guide_data));
    var guide_id = JSON.parse(JSON.stringify(_guide_id));
    var guide_info_id = JSON.parse(JSON.stringify(_guide_info_id));
    if (to_guide_id != null && to_guide_info_id != null && type == "overwrite") {
      guide_id = to_guide_id;
      guide_info_id = to_guide_info_id;
    }
    var guide_info = guide_data.attributes;
    console.log("guide_info access:", guide_info.access);
    console.log("guide_info type:", guide_info.type);
    if (type === "new") {
      guide_data.creator_id = user_info.uid;
      guide_info.creator_id = user_info.uid;
    }
    const _data = {
      data: JSON.stringify(guide_data),
    };
    if (type === "copy" || type === "new") _data.user_id = guide_info.user_id;

    //console.log("batchedWriteGuide 1");
    // Prepare batch
    var db = firebase.firestore();
    var batch = db.batch();
    var guide_data_ref = null;
    let guide_id_to_log = "";
    if (type === "modify" || type === "overwrite") {
      guide_data_ref = db.collection("guide_data").doc(guide_id);
      batch.update(guide_data_ref, _data);
      guide_id_to_log = guide_id;
    } else {
      guide_data_ref = db.collection("guide_data").doc();
      batch.set(guide_data_ref, _data);
      guide_id_to_log = guide_data_ref.id;
    }

    //console.log("batchedWriteGuide 2");
    guide_info.mod = DateTime.now().toISO({ zone: "utc" });
    if (type === "copy") guide_info.guide_name = guide_info.guide_name + "_C";
    if (type === "copy" || type === "new") {
      //guide_data.user_id = user_info.uid;
      guide_info.user_id = user_info.uid;
      guide_info.owner_name = `${user_info.first_name} ${user_info.last_name}`;
      guide_info.guide_id = guide_data_ref.id;
      guide_info.access = [user_info.uid];
      guide_info.created = guide_info.mod;
      guide_info.read = guide_info.mod;
      guide_info.copy = guide_info.mod;
      guide_info.rating = 0;
      guide_info.num_ratings = 0;
      guide_info.times_read = 0;
      guide_info.times_copied = 0;
      guide_info.type = "";
    }
    if (type !== "copy" && type !== "new") {
      delete guide_info.rating;
      delete guide_info.created;
      delete guide_info.read;
      delete guide_info.copy;
      delete guide_info.num_ratings;
      delete guide_info.times_read;
      delete guide_info.times_copied;
    }
    if (type === "overwrite") {
      delete guide_info.access;
      delete guide_info.guide_id;
      delete guide_info.owner_name;
      delete guide_info.user_id;
    }
    //guide_info.locked_until = DateTime.now().toISO({ zone: "utc" });

    delete guide_info.categories;
    delete guide_info.start_date;
    delete guide_info.end_date;
    delete guide_info.ratings;
    delete guide_info.price;
    delete guide_info.shared;
    delete guide_info.group_read;
    delete guide_info.group_copy;
    delete guide_info.group_modify;

    //console.log("guide_info end:", guide_info);
    var guide_info_ref = null;
    if (type === "modify" || type === "overwrite") {
      guide_info_ref = db.collection("guide_info").doc(guide_info_id);
      batch.update(guide_info_ref, { ...guide_info });
    } else {
      guide_info_ref = db.collection("guide_info").doc();
      batch.set(guide_info_ref, { ...guide_info });
    }
    //console.log("batchedWriteGuide 4");
    // Commit the batch

    ///////////////////////////Mobile//////////////////
    // let tracking_id = Platform.OS === "ios" ? "UA-252264346-3" : "UA-252264346-2";
    // const analytics = new Analytics(tracking_id);
    // analytics
    //   .event(new Event("Guide Access", `Guide ${type}`, guide_id_to_log, 0))
    //   .then(() => console.log("success"))
    //   .catch((e) => console.log(e.message));
    /////////////////////////////////////////////

    /////////////////Web///////////////////
    ReactGA.event({
      category: "Guide Access",
      action: `Guide ${type}`,
      label: guide_id_to_log,
    });
    ///////////////////////////////////////////////
    batch
      .commit()
      .then(() => {
        console.log("batchedWriteGuide 5");
        callback({ pass: true, new_doc_ref: guide_data_ref, new_info_ref: guide_info_ref });
      })
      .catch((error) => {
        console.log("batchedWriteGuide error", error.message);
        callback({ pass: false, new_doc_ref: null, new_info_ref: null, error: error });
      });
  };

  static batchedUpdateGuide = (guide_data, guide_id, guide_info_id, user_info, type, callback) => {
    // Get a new write batch
    var guide_info = guide_data.attributes;
    //console.log("guide_info start:", guide_info);
    guide_data.user_id = user_info.uid;
    const _data = {
      data: JSON.stringify(guide_data),
      user_id: user_info.uid,
    };

    // Prepare batch
    var db = firebase.firestore();
    var batch = db.batch();
    var addd_guide_data_ref = db.collection("guide_data").doc();
    if (type === "modify") addd_guide_data_ref = db.collection("guide_data").doc(guide_id);
    //console.log("addd_guide_data_ref.id", addd_guide_data_ref.id);
    // Add Write the guide_data to batch
    batch.set(addd_guide_data_ref, _data);

    // write the guide_info
    guide_info.user_id = user_info.uid;
    guide_info.owner_name = `${user_info.first_name} ${user_info.last_name}`;
    guide_info.guide_id = addd_guide_data_ref.id;
    guide_info.mod = DateTime.now().toISO({ zone: "utc" });
    if (type === "copy") guide_info.guide_name = guide_info.guide_name + "_C";
    if (type !== "modify") {
      guide_info.created = guide_info.mod;
      guide_info.read = guide_info.mod;
      guide_info.copy = guide_info.mod;
      guide_info.num_ratings = 0;
      guide_info.times_read = 0;
      guide_info.times_copied = 0;
    }
    // else {
    //   guide_info.access = [user_info.uid];
    // }
    //guide_info.locked_until = DateTime.now().toISO({ zone: "utc" });

    delete guide_info.categories;
    delete guide_info.start_date;
    delete guide_info.end_date;
    delete guide_info.ratings;
    delete guide_info.price;
    delete guide_info.shared;
    delete guide_info.group_read;
    delete guide_info.group_copy;
    delete guide_info.group_modify;
    delete guide_info.categories;
    //console.log("guide_info end:", guide_info);

    //batch.set(db.collection('col').doc(), doc);
    var addd_guide_info_ref = db.collection("guide_info").doc();
    if (type == "modify") addd_guide_info_ref = db.collection("guide_info").doc(guide_info_id);
    //console.log("addd_guide_info_ref", addd_guide_info_ref.id);
    batch.set(addd_guide_info_ref, { ...guide_info });

    // Commit the batch
    batch
      .commit()
      .then(() => {
        callback({ pass: true, new_doc_ref: addd_guide_data_ref, new_info_ref: addd_guide_info_ref });
      })
      .catch((error) => {
        callback({ pass: false, new_doc_ref: null, new_info_ref: null, error: error });
      });
  };

  static copyGuideData = (guide_data, user_id, callback) => {
    guide_data.user_id = user_id;
    this.addGuideData(guide_data, callback);
  };

  static readGuideInfo = (guide_info_id, callback) => {
    //console.log("readGuideInfo", guide_info_id);
    const db = firebase.firestore();
    var docRef = db.collection("guide_info").doc(guide_info_id);
    docRef
      .get()
      .then(function (doc) {
        if (doc.exists) {
          //console.log("Read guide info from FB");
          callback({ pass: true, res: doc.data() });
        } else {
          //console.log("No such guide info in FB!");
          callback({ pass: false, res: "No guide info found" });
        }
      })
      .catch(function (error) {
        console.log("Error getting guide info from Firebase:", error.message);
        callback({ pass: false, res: error });
      });
  };

  static readGuideData = (guide_id, callback) => {
    //console.log("readGuideData", guide_id);
    const db = firebase.firestore();
    var docRef = db.collection("guide_data").doc(guide_id);
    docRef
      .get()
      .then(function (doc) {
        if (doc.exists) {
          //console.log("Guide Downloaded from FB");
          callback({ pass: true, guide_id: guide_id, res: doc.data() });
        } else {
          //console.log("No such guide in FB!");
          callback({ pass: false, res: "No Guide found" });
        }
      })
      .catch(function (error) {
        console.log("Error getting guide from Firebase:", error.message);
        callback({ pass: false, res: error });
      });
  };

  static xxxgetAllGuideInfo = (user_id, callback) => {
    if (user_id == null) user_id = "XXXXXXXXXXX";

    let search_field = "access";
    let compare_op = "array-contains-any";
    let access_array = [user_id, `${user_id}:R`, `${user_id}:C`, `${user_id}:M`, "All_R", "All_C", "All_M"];

    let search_field2 = "type";
    let compare_op2 = "!=";
    let access_array2 = "Featured";

    //console.log("access_array", access_array);
    let guides = [];
    const db = firebase.firestore();
    db.collection("guide_info")
      .where(search_field, compare_op, access_array)
      //.where(search_field2, compare_op2, access_array2)
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          // doc.data() is never undefined for query doc snapshots
          guides.push({ id: doc.id, data: doc.data() });
        });
        guides.forEach((guide_info) => {
          if (typeof guide_info.data.mod == "undefined") guide_info.data.mod = DateTime.now().toISO({ zone: "utc" });
          if (typeof guide_info.data.created == "undefined") guide_info.data.created = guide_info.mod;
          if (typeof guide_info.data.read == "undefined") guide_info.data.read = guide_info.mod;
          if (typeof guide_info.data.copy == "undefined") guide_info.data.copy = guide_info.mod;
          if (typeof guide_info.data.rating == "undefined") guide_info.data.rating = 0;
          if (isNaN(guide_info.data.rating)) guide_info.data.rating = 0;
          if (typeof guide_info.data.num_ratings == "undefined") guide_info.data.num_ratings = 0;
          if (isNaN(guide_info.data.num_ratings)) guide_info.data.num_ratings = 0;
          if (typeof guide_info.data.times_copied == "undefined") guide_info.data.times_copied = 0;
          if (isNaN(guide_info.data.times_copied)) guide_info.data.times_copied = 0;
          if (typeof guide_info.data.times_read == "undefined") guide_info.data.times_read = 0;
          if (isNaN(guide_info.data.times_read)) guide_info.data.times_read = 0;
        });
        callback({ pass: true, res: guides });
      })
      .catch(function (error) {
        console.log("Error getting all guide infos from Firebase:", error.message);
        callback({ pass: false, res: error });
      });
  };

  static getAllGuideInfo = (user_id, callback, type) => {
    if (user_id == null) user_id = "XXXXXXXXXXX";

    // let compare_op2 = "!=";
    // let search_field2 = "type";
    // let access_array2 = "Featured";

    let compare_op = "array-contains-any";
    let search_field = "access";
    let access_array = [user_id, `${user_id}:R`, `${user_id}:C`, `${user_id}:M`, "All_R", "All_C", "All_M"];
    if (type === "Featured") {
      compare_op = "==";
      access_array = "Featured";
      search_field = "type";
    }
    if (type === "Shared") {
      compare_op = "array-contains-any";
      search_field = "access";
      access_array = [`${user_id}:R`, `${user_id}:C`, `${user_id}:M`, "All_R", "All_C", "All_M"];
    }
    if (type === "Mine") {
      compare_op = "array-contains-any";
      search_field = "access";
      access_array = [user_id];
    }

    //console.log("access_array", access_array);
    let guides = [];
    const db = firebase.firestore();
    db.collection("guide_info")
      //.where(search_field2, compare_op2, access_array2)
      .where(search_field, compare_op, access_array)
      .limit(5000)
      //.where("user_id", "==", user_id)
      //.where("share_all", "in", ["Read", "Copy", "Modify"])
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          // doc.data() is never undefined for query doc snapshots
          guides.push({ id: doc.id, data: doc.data() });
        });
        guides.forEach((guide_info) => {
          if (typeof guide_info.data.mod == "undefined") guide_info.data.mod = DateTime.now().toISO({ zone: "utc" });
          if (typeof guide_info.data.created == "undefined") guide_info.data.created = guide_info.mod;
          if (typeof guide_info.data.read == "undefined") guide_info.data.read = guide_info.mod;
          if (typeof guide_info.data.copy == "undefined") guide_info.data.copy = guide_info.mod;
          if (typeof guide_info.data.rating == "undefined") guide_info.data.rating = 0;
          if (isNaN(guide_info.data.rating)) guide_info.data.rating = 0;
          if (typeof guide_info.data.num_ratings == "undefined") guide_info.data.num_ratings = 0;
          if (isNaN(guide_info.data.num_ratings)) guide_info.data.num_ratings = 0;
          if (typeof guide_info.data.times_copied == "undefined") guide_info.data.times_copied = 0;
          if (isNaN(guide_info.data.times_copied)) guide_info.data.times_copied = 0;
          if (typeof guide_info.data.times_read == "undefined") guide_info.data.times_read = 0;
          if (isNaN(guide_info.data.times_read)) guide_info.data.times_read = 0;
        });
        callback({ pass: true, res: guides });
      })
      .catch(function (error) {
        console.log("Error getting all guide infos from Firebase:", error.message);
        callback({ pass: false, res: error });
      });
  };

  static deleteGuideData = (guide_id, callback) => {
    const db = firebase.firestore();
    db.collection("guide_data")
      .doc(guide_id)
      .delete()
      .then(function () {
        //console.log("Guide data successfully deleted!");
        callback({ pass: true, res: guide_id });
      })
      .catch(function (error) {
        console.error("Error removing Guide data: ", error.message);
        callback({ pass: false, res: error });
      });
  };

  static deleteGuideInfo = (guide_info_id, callback) => {
    const db = firebase.firestore();
    db.collection("guide_info")
      .doc(guide_info_id)
      .delete()
      .then(function () {
        //console.log("Guide info successfully deleted!");
        callback({ pass: true, res: guide_info_id });
      })
      .catch(function (error) {
        console.error("Error removing Guide info: ", error.message);
        callback({ pass: false, res: error });
      });
  };

  // static deleteGuideTransaction = (guide_info_id, guide_id, callback) => {
  //   const db = firebase.firestore();
  //   db.collection("guide_info")
  //     .doc(guide_info_id)
  //     .delete()
  //     .then(function () {
  //       //console.log("Guide info successfully deleted!");
  //       callback({ pass: true, res: guide_info_id });
  //     })
  //     .catch(function (error) {
  //       console.error("Error removing Guide info: ", error.message);
  //       callback({ pass: false, res: error });
  //     });
  // };

  // static deleteGuideInfos = (uid, callback) => {
  //   const db = firebase.firestore();
  //   var guide_info_Ref = db.collection("guide_info");
  //   var guide_data_Ref = db.collection("guide_data");

  //   db.runTransaction(function (transaction) {
  //     var info_count = 0;
  //     var data_count = 0;
  //     let data_docs = [];
  //     return transaction.get(guide_info_Ref).then(function (querySnapshot) {
  //       querySnapshot.forEach(function (doc) {
  //         if (doc.data().user_id === id) {
  //           //transaction.delete(doc.ref);
  //           info_count = info_count + 1;
  //           data_docs.push(doc.data().guide_id)
  //         }
  //       });
  //     });
  //   })
  //     .then(function (info_count) {
  //       console.log("guide info ", info_count);
  //       console.log("guide data ", data_count);
  //       callback({ pass: true, guide_info: info_count, guide_data: data_count, data_docs: data_docs });
  //     })
  //     .catch(function (err) {
  //       console.error(err);
  //       callback({ pass: false, guide_info: info_count, guide_data: data_count, data_docs: data_docs });
  //     });
  // };

  static getUserGuideDocIDs = (uid, callback) => {
    const db = firebase.firestore();
    var guide_info_Ref = db.collection("guide_info").where("user_id", "==", uid);
    let guide_data_ids = [];
    let guide_info_ids = [];
    guide_info_Ref
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          guide_data_ids.push(doc.data().guide_id);
          guide_info_ids.push(doc.id);
        });
        callback({ pass: true, guide_info_ids: guide_info_ids, guide_data_ids: guide_data_ids, error: "" });
      })
      .catch((error) => {
        console.log("Error getting all guide infos from Firebase:", error.message);
        callback({ pass: false, guide_info_ids: guide_info_ids, guide_data_ids: guide_data_ids, error: error });
      });
  };

  static removeGuidesFromIDs(guide_info_ids, guide_data_ids, callback) {
    if (guide_info_ids.length === 0 && guide_data_ids === 0) {
      callback({ pass: true, error: "" });
      return;
    }
    const db = firebase.firestore();
    let batch = db.batch();
    // let guide_info_Ref = db.collection("guide_info");
    // let guide_data_Ref = db.collection("guide_data");
    guide_info_ids.forEach((guide_info_id) => {
      batch.delete(db.collection("guide_info").doc(guide_info_id));
    });
    guide_data_ids.forEach((guide_data_id) => {
      batch.delete(db.collection("guide_data").doc(guide_data_id));
    });

    batch
      .commit()
      .then(() => {
        callback({ pass: true, error: "" });
      })
      .catch((error) => {
        console.log("removeGuidesFromIDs error", error.message);
        callback({ pass: false, error: error });
      });
  }

  // static addReview = (guide_info_id, review, callback) => {
  //   // this.rating = rating;
  //   // this.comment = comment;
  //   // this.user_id = user_info.uid;
  //   // this.user_name = `${user_info.first_name} ${user_info.last_name}`;
  //   // this.date = DateTime.now().toISO({ zone: "utc" });
  //   review.date = DateTime.now().toISO({ zone: "utc" });

  //   var db = firebase.firestore();
  //   db.collection("guide_info")
  //     .doc(guide_info_id)
  //     .collection("reviews")
  //     .doc(review.user_id)
  //     .set({ ...review })
  //     .then((docRef) => {
  //       callback({ pass: true, res: docRef.id });
  //     })
  //     .catch((error) => {
  //       callback({ pass: false, res: error });
  //     });
  // };

  static reviewExists = (guide_info_id, user_id, callback) => {
    var db = firebase.firestore();
    var DocRef = db.collection("guide_info").doc(guide_info_id).collection("reviews").doc(user_id);
    DocRef.get().then((docSnapshot) => {
      if (docSnapshot.exists) {
        DocRef.onSnapshot((doc) => {
          //console.log(doc.data());
          callback({ pass: true, review: doc.data() });
        });
      } else {
        //console.log("Review does not exist");
        callback({ pass: false, review: null });
        //DocRef.set(); // create the document
      }
    });
  };

  static addReviewTransaction = (guide_info_id, review, callback) => {
    //console.log(review);
    var db = firebase.firestore();
    var infoDocRef = db.collection("guide_info").doc(guide_info_id);
    var reviewDocRef = db.collection("guide_info").doc(guide_info_id).collection("reviews").doc(review.user_id);
    return db
      .runTransaction((transaction) => {
        return transaction.get(infoDocRef).then((infoDoc) => {
          return transaction.get(reviewDocRef).then((reviewDoc) => {
            var num_ratings = infoDoc.data().num_ratings;
            if (isNaN(num_ratings)) num_ratings = 0;
            var rating = infoDoc.data().rating;
            if (isNaN(rating)) rating = 0;
            if (!reviewDoc.exists) {
              //new rating
              rating = (rating * num_ratings + review.rating) / (num_ratings + 1);
              num_ratings = num_ratings + 1;
            } else {
              //update existing review
              rating = (rating * num_ratings - reviewDoc.data().rating + review.rating) / num_ratings;
            }
            transaction.update(infoDocRef, { rating: rating, num_ratings: num_ratings });
            transaction.set(reviewDocRef, { ...review });
          });
        });
      })
      .then(() => {
        //console.log("Add Review Transaction successfully committed!");
        callback({ pass: true, error: "" });
      })
      .catch((error) => {
        //console.log("Add Review Transaction failed: ", error.message);
        callback({ pass: false, error: error });
      });
  };

  static incrementCopiesTransaction = (guide_info_id, callback) => {
    var db = firebase.firestore();
    var infoDocRef = db.collection("guide_info").doc(guide_info_id);
    return db
      .runTransaction((transaction) => {
        return transaction.get(infoDocRef).then((infoDoc) => {
          var times_copied = infoDoc.data().times_copied;
          if (isNaN(times_copied)) times_copied = 0;
          times_copied += 1;
          transaction.update(infoDocRef, { times_copied: times_copied });
        });
      })
      .then(() => {
        //console.log("Increment Copies Transaction successful!, times_copied:", times_copied);
        callback({ pass: true, error: "" });
      })
      .catch((error) => {
        //console.log("Increment Copies Transaction failed: ", error.message);
        callback({ pass: false, error: error });
      });
  };

  static lockGuideTransaction = (guide_info_id, user_id, hours, callback) => {
    var db = firebase.firestore();
    var infoDocRef = db.collection("guide_info").doc(guide_info_id);
    return db
      .runTransaction((transaction) => {
        return transaction.get(infoDocRef).then((infoDoc) => {
          var locked_until = infoDoc.data().locked_until;
          var locked_by = infoDoc.data().locked_by;
          if (typeof locked_until == "undefined") locked_until = DateTime.now().toISO({ zone: "utc" });
          if (typeof locked_by == "undefined") locked_by = "";
          if (locked_until < DateTime.now().toISO({ zone: "utc" })) {
            locked_by = user_id;
            locked_until = DateTime.now().toISO({ zone: "utc" }).plus({ hours: hours });
          }
          transaction.update(infoDocRef, { locked_until: locked_until, locked_by: locked_by });
        });
      })
      .then(() => {
        //console.log("Lock Guide Transaction successful!, locked_until:", locked_until, " by: ", locked_by);
        callback({ pass: true, error: "" });
      })
      .catch((error) => {
        //console.log("Lock Guide Transaction failed: ", error.message);
        callback({ pass: false, error: error });
      });
  };

  static unlockGuideTransaction = (guide_info_id, user_id, hours, callback) => {
    var db = firebase.firestore();
    var infoDocRef = db.collection("guide_info").doc(guide_info_id);
    return db
      .runTransaction((transaction) => {
        return transaction.get(infoDocRef).then((infoDoc) => {
          var locked_until = infoDoc.data().locked_until;
          var locked_by = infoDoc.data().locked_by;
          if (typeof locked_until == "undefined") locked_until = DateTime.now().toISO({ zone: "utc" });
          if (typeof locked_by == "undefined") locked_by = "";
          if (locked_by === user_id) {
            locked_until = DateTime.now().toISO({ zone: "utc" });
            locked_by = "";
          } else {
            callback({ pass: false, error: "This guide is not locked by you" });
            return;
          }
          transaction.update(infoDocRef, { locked_until: locked_until, locked_by: locked_by });
        });
      })
      .then(() => {
        //console.log("Unlock Guide Transaction successful!, locked_until:", locked_until, " by: ", locked_by);
        callback({ pass: true, error: "" });
      })
      .catch((error) => {
        //console.log("Unlock Guide Transaction failed: ", error.message);
        callback({ pass: false, error: error });
      });
  };

  static doesGuideInfoExist = (guide_info_id, callback) => {
    var db = firebase.firestore();
    var docRef = db.collection("guide_info").doc(guide_info_id);
    docRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          callback({ pass: true, error: "" });
        } else {
          callback({ pass: false, error: "" });
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error.message);
        callback({ pass: false, error: error });
      });
  };

  static readGuideTransaction = (guide_info_id, callback, uid) => {
    console.log("readGuideTransaction", guide_info_id, uid);
    var db = firebase.firestore();
    var _dataDoc = null;
    var _infoDoc = null;
    var _ownerDoc = null;
    var _creatorDoc = null;
    var dataDocRef = null;
    var ownerDocRef = null;
    var creatorDocRef = null;
    var infoDocRef = db.collection("guide_info").doc(guide_info_id);
    return db
      .runTransaction((transaction) => {
        return transaction.get(infoDocRef).then((infoDoc) => {
          _infoDoc = infoDoc;
          dataDocRef = db.collection("guide_data").doc(infoDoc.data().guide_id);
          return transaction.get(dataDocRef).then((dataDoc) => {
            //console.log("infoDoc.data()", infoDoc.data());
            _dataDoc = dataDoc;

            db.collection("users")
              .where("uid", "==", infoDoc.data().user_id)
              .get()
              .then((querySnapshot) => {
                if (!querySnapshot.empty) _ownerDoc = querySnapshot.docs[0];
                console.log("_ownerDoc", _ownerDoc);
              });

            if (typeof infoDoc.data().creator_id != "undefined" && infoDoc.data().creator_id != "") {
              db.collection("users")
                .where("uid", "==", infoDoc.data().creator_id)
                .get()
                .then((querySnapshot) => {
                  if (!querySnapshot.empty) _creatorDoc = querySnapshot.docs[0];
                  else _creatorDoc = _ownerDoc;
                  console.log("_creatorDoc", _creatorDoc);
                });
            }

            // ownerDocRef = db.collection("users").doc(infoDoc.data().user_id);
            // transaction.get(ownerDocRef).then((ownerDoc) => {
            //   _ownerDoc = ownerDoc;
            //   console.log("ownerDoc.data()", ownerDoc.data());
            // });
            // if (typeof infoDoc.data().creator_id != "undefined" && infoDoc.data().creator_id != "") {
            //   creatorDocRef = db.collection("users").doc(infoDoc.data().creator_id);
            //   transaction.get(creatorDocRef).then((creatorDoc) => {
            //     _creatorDoc = creatorDoc;
            //     console.log("_creatorDoc.data()", _creatorDoc.data());
            //   });
            // } else _creatorDoc = _ownerDoc;

            if (uid !== "guest") {
              var times_read = infoDoc.data().times_read;
              if (isNaN(times_read)) times_read = 0;
              times_read = times_read + 1;
              transaction.update(infoDocRef, { times_read: times_read });
            }
          });
        });
      })
      .then(() => {
        //console.log("readGuideTransaction successful!");
        callback({
          pass: true,
          error: "",
          guide_id: dataDocRef.id,
          res: _dataDoc.data(),
          infoDoc: _infoDoc.data(),
          guide_info_id: guide_info_id,
          owner_info: _ownerDoc == null ? null : _ownerDoc.data(),
          creator_info: _creatorDoc == null ? null : _creatorDoc.data(),
        });
        ///////////////////////////////////////////Mobile/////////////////////
        // let tracking_id = Platform.OS === "ios" ? "UA-252264346-3" : "UA-252264346-2";
        // const analytics = new Analytics(tracking_id);
        // analytics
        //   .event(new Event("Guide Access", "Guide Read", dataDocRef.id, 0))
        //   .then(() => console.log("success"))
        //   .catch((e) => console.log(e.message));
        ////////////////////////////////////////

        /////////////////////////Web//////////////////
        ReactGA.event({
          category: "Guide Access",
          action: "Guide Read",
          label: dataDocRef.id,
        });
        ////////////////////////////////////////////////////
      })
      .catch((error) => {
        console.log("readGuideTransaction failed: ", error.message);
        callback({ pass: false, error: error, res: null, infoDoc: null });
      });
  };

  static getAllGuideReviews = (guide_info_id, callback) => {
    //console.log("getAllGuideReviews", guide_info_id);
    let reviews = [];
    const db = firebase.firestore();
    var docRef = db.collection("guide_info").doc(guide_info_id).collection("reviews");
    docRef
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          //console.log("querySnapshot.forEach:", doc.data());
          reviews.push({ id: doc.id, data: doc.data() });
        });
        callback({ pass: true, reviews: reviews });
      })
      .catch((error) => {
        console.log("Error getting all guide infos from Firebase:", error.message);
        callback({ pass: false, reviews: error });
      });
  };

  static DownloadOldGuide = (guide_id, callback, loadGuide, showGuide) => {
    var request = new XMLHttpRequest();
    request.onreadystatechange = function () {
      if (request.readyState == 4) {
        // The request is done; did it work?
        if (request.status === 200) {
          if (request.responseText != null) {
            callback(request.responseText, loadGuide, showGuide);
          } else {
            console.log("Error in DownloadGuide");
            callback(null);
          }
        }
      }
      // else {
      //   console.warn("error downloading guide!", request.status);
      //   callback(null);
      // }
    };
    request.open("GET", `http://www.liquidguides.com/database/download_GuideSections.php?guide_id=${guide_id}`);
    request.send();
  };
}
