// import React, { Component } from "react";
import salesman from "salesman.js";
//import Places, { calculateDistance } from "../Models/Places";
import { getDistance, getPreciseDistance } from "geolib";
import { DateTime } from "luxon";
import poi from "../assets/images/poi.png";
import airport from "../assets/icons/airport.png";
import arbol from "../assets/icons/arbol.png";
import arch from "../assets/icons/arch.png";
import art_museum_2 from "../assets/icons/art-museum-2.png";
import atm_2 from "../assets/icons/atm-2.png";
import bags from "../assets/icons/bags.png";
import bank from "../assets/icons/bank.png";
import bar from "../assets/icons/bar.png";
import barber from "../assets/icons/barber.png";
import bar_coktail from "../assets/icons/bar_coktail.png";
import beach from "../assets/icons/beach.png";
import beautifulview from "../assets/icons/beautifulview.png";
import bed_breakfast1_2 from "../assets/icons/bed_breakfast1-2.png";
import bicycle_shop from "../assets/icons/bicycle_shop.png";
import bigcity from "../assets/icons/bigcity.png";
import binoculars from "../assets/icons/binoculars.png";
import boat from "../assets/icons/boat.png";
import bus from "../assets/icons/bus.png";
import bustour from "../assets/icons/bustour.png";
import cablecar from "../assets/icons/cablecar.png";
import campingcar from "../assets/icons/campingcar.png";
import car from "../assets/icons/car.png";
import carrental from "../assets/icons/carrental.png";
import castle_2 from "../assets/icons/castle-2.png";
import cathedral from "../assets/icons/cathedral.png";
import caution from "../assets/icons/caution.png";
import cave_2 from "../assets/icons/cave-2.png";
import cemetary from "../assets/icons/cemetary.png";
import church_2 from "../assets/icons/church-2.png";
import citysquare from "../assets/icons/citysquare.png";
import climbing_bentgate_carabiner from "../assets/icons/climbing-bentgate_carabiner.png";
import coffee from "../assets/icons/coffee.png";
import congress from "../assets/icons/congress.png";
import conveniencestore from "../assets/icons/conveniencestore.png";
import cruiseship from "../assets/icons/cruiseship.png";
import currencyexchange from "../assets/icons/currencyexchange.png";
import dentist from "../assets/icons/dentist.png";
import departmentstore from "../assets/icons/departmentstore.png";
import desert_2 from "../assets/icons/desert-2.png";
import doctor from "../assets/icons/doctor.png";
import dogs_leash from "../assets/icons/dogs_leash.png";
import family from "../assets/icons/family.png";
import firstaid from "../assets/icons/firstaid.png";
import home_2 from "../assets/icons/home-2.png";
import hospital_building from "../assets/icons/hospital-building.png";
import hospital from "../assets/icons/hospital.png";
import japanese_food from "../assets/icons/japanese-food.png";
import kayaking from "../assets/icons/kayaking.png";
import lake from "../assets/icons/lake.png";
import laundromat from "../assets/icons/laundromat.png";
import letter_a from "../assets/icons/letter_a.png";
import letter_b from "../assets/icons/letter_b.png";
import letter_c from "../assets/icons/letter_c.png";
import letter_d from "../assets/icons/letter_d.png";
import letter_e from "../assets/icons/letter_e.png";
import letter_f from "../assets/icons/letter_f.png";
import letter_g from "../assets/icons/letter_g.png";
import letter_h from "../assets/icons/letter_h.png";
import letter_i from "../assets/icons/letter_i.png";
import letter_j from "../assets/icons/letter_j.png";
import letter_k from "../assets/icons/letter_k.png";
import letter_l from "../assets/icons/letter_l.png";
import letter_m from "../assets/icons/letter_m.png";
import letter_n from "../assets/icons/letter_n.png";
import letter_o from "../assets/icons/letter_o.png";
import letter_p from "../assets/icons/letter_p.png";
import letter_q from "../assets/icons/letter_q.png";
import letter_r from "../assets/icons/letter_r.png";
import letter_s from "../assets/icons/letter_s.png";
import letter_t from "../assets/icons/letter_t.png";
import letter_u from "../assets/icons/letter_u.png";
import letter_v from "../assets/icons/letter_v.png";
import letter_w from "../assets/icons/letter_w.png";
import letter_x from "../assets/icons/letter_x.png";
import letter_y from "../assets/icons/letter_y.png";
import letter_z from "../assets/icons/letter_z.png";
import library from "../assets/icons/library.png";
import lighthouse_2 from "../assets/icons/lighthouse-2.png";
import lodging_2 from "../assets/icons/lodging-2.png";
import mall from "../assets/icons/mall.png";
import marina_2 from "../assets/icons/marina-2.png";
import medicine from "../assets/icons/medicine.png";
import memorial from "../assets/icons/memorial.png";
import monument from "../assets/icons/monument.png";
import mosquee from "../assets/icons/mosquee.png";
import mountains from "../assets/icons/mountains.png";
import museum_archeological from "../assets/icons/museum_archeological.png";
import museum_art from "../assets/icons/museum_art.png";
import museum_crafts from "../assets/icons/museum_crafts.png";
import museum_industry from "../assets/icons/museum_industry.png";
import museum_naval from "../assets/icons/museum_naval.png";
import museum_war from "../assets/icons/museum_war.png";
import number_0 from "../assets/icons/number_0.png";
import number_1 from "../assets/icons/number_1.png";
import number_2 from "../assets/icons/number_2.png";
import number_3 from "../assets/icons/number_3.png";
import number_4 from "../assets/icons/number_4.png";
import number_5 from "../assets/icons/number_5.png";
import number_6 from "../assets/icons/number_6.png";
import number_7 from "../assets/icons/number_7.png";
import number_8 from "../assets/icons/number_8.png";
import number_9 from "../assets/icons/number_9.png";
import parkinggarage from "../assets/icons/parkinggarage.png";
import pets from "../assets/icons/pets.png";
import playground from "../assets/icons/playground.png";
import recycle from "../assets/icons/recycle.png";
import regroup from "../assets/icons/regroup.png";
import repair from "../assets/icons/repair.png";
import resort from "../assets/icons/resort.png";
import restaurant_chinese from "../assets/icons/restaurant_chinese.png";
import restaurant_fish from "../assets/icons/restaurant_fish.png";
import restaurant_greek from "../assets/icons/restaurant_greek.png";
import restaurant_indian from "../assets/icons/restaurant_indian.png";
import restaurant_italian from "../assets/icons/restaurant_italian.png";
import restaurant_korean from "../assets/icons/restaurant_korean.png";
import restaurant_mediterranean from "../assets/icons/restaurant_mediterranean.png";
import restaurant_mexican from "../assets/icons/restaurant_mexican.png";
import restaurant_thai from "../assets/icons/restaurant_thai.png";
import restaurant_vegetarian from "../assets/icons/restaurant_vegetarian.png";
import river_2 from "../assets/icons/river-2.png";
import road from "../assets/icons/road.png";
import sandwich_2 from "../assets/icons/sandwich-2.png";
import school from "../assets/icons/school.png";
import skiing from "../assets/icons/skiing.png";
import stadium from "../assets/icons/stadium.png";
import supermarket from "../assets/icons/supermarket.png";
import taxi from "../assets/icons/taxi.png";
import temple_2 from "../assets/icons/temple-2.png";
import toilets_inclusive from "../assets/icons/toilets_inclusive.png";
import town from "../assets/icons/townhouse.png";
import train from "../assets/icons/train.png";
import tramway from "../assets/icons/tramway.png";
import tree from "../assets/icons/tree.png";
import veterinary from "../assets/icons/veterinary.png";
import villa from "../assets/icons/villa.png";
import walkingtour from "../assets/icons/walkingtour.png";
import winebar from "../assets/icons/winebar.png";
import zoo from "../assets/icons/zoo.png";
import restaurant from "../assets/icons/restaurant.png";
import hotel from "../assets/icons/hotel.png";
import theater from "../assets/icons/theater.png";
import bridge_modern from "../assets/icons/bridge_modern.png";
import icecream from "../assets/icons/icecream.png";
import soccer from "../assets/icons/soccer.png";
import golf_2 from "../assets/icons/golf-2.png";
import area from "../assets/icons/area.png";
import excursion from "../assets/icons/excursion.png";
import fast_food from "../assets/icons/fast_food.png";
import kids from "../assets/icons/kids.png";
import leisure from "../assets/icons/leisure.png";
import nearby from "../assets/icons/nearby.png";
import neighborhood from "../assets/icons/neighborhood.png";
import nightlife from "../assets/icons/nightlife.png";
import outdoors from "../assets/icons/outdoors.png";
import restaurant_french from "../assets/icons/restaurant_french.png";
import restaurant_japanese from "../assets/icons/restaurant_japanese.png";
import restaurant_pizza from "../assets/icons/restaurant_pizza.png";
import restaurant_vegan from "../assets/icons/restaurant_vegan.png";
import shopping from "../assets/icons/shopping.png";
import sports from "../assets/icons/sports.png";
import tour from "../assets/icons/tour.png";
import trail from "../assets/icons/trail.png";
import trip from "../assets/icons/trip.png";
import country from "../assets/icons/country.png";
import search from "../assets/icons/search.png";
import bakery from "../assets/icons/bakery.png";
import restaurant_steakhouse from "../assets/icons/restaurant_steakhouse.png";
import restaurant_venezuela from "../assets/icons/restaurant_venezuela.png";

import color_green from "../assets/icons/color_green.png";
import color_teal from "../assets/icons/color_teal.png";
import color_blue from "../assets/icons/color_blue.png";
import color_purple from "../assets/icons/color_purple.png";
import color_brown from "../assets/icons/color_brown.png";
import color_red from "../assets/icons/color_red.png";
import color_orange from "../assets/icons/color_orange.png";
import color_light_green from "../assets/icons/color_light_green.png";

import google_places_logo from "../assets/images/google-places-logo.jpg";
import place_png from "../assets/images/place.png";
import bear from "../assets/icons/bear.png";
import bridge_old from "../assets/icons/bridge_old.png";
import canyon_2 from "../assets/icons/canyon-2.png";
import cinema from "../assets/icons/cinema.png";
import dancinghall from "../assets/icons/dancinghall.png";
import deer from "../assets/icons/deer.png";
import fishing from "../assets/icons/fishing.png";
import geyser_2 from "../assets/icons/geyser-2.png";
import glacier_2 from "../assets/icons/glacier-2.png";
import historical_museum from "../assets/icons/historical_museum.png";
import hotspring from "../assets/icons/hotspring.png";
import moose from "../assets/icons/moose.png";
import museum_science from "../assets/icons/museum_science.png";
import music_classical from "../assets/icons/music_classical.png";
import palace_2 from "../assets/icons/palace-2.png";
import panoramicview from "../assets/icons/panoramicview.png";
import ruins_2 from "../assets/icons/ruins-2.png";
import sight_2 from "../assets/icons/sight-2.png";
import smallcity from "../assets/icons/smallcity.png";
import state from "../assets/icons/state.png";
import statue_2 from "../assets/icons/statue-2.png";
import synagogue_2 from "../assets/icons/synagogue-2.png";
import volcano_2 from "../assets/icons/volcano-2.png";
import waterfall_2 from "../assets/icons/waterfall-2.png";
import wetlands from "../assets/icons/wetlands.png";
import world from "../assets/icons/world.png";
import star_3 from "../assets/icons/star-3.png";
import island_icon from "../assets/icons/Island_icon.png";
import building from "../assets/icons/building.png";
import casino_2 from "../assets/icons/casino-2.png";
import photo from "../assets/icons/photo.png";
import audio from "../assets/icons/audio.png";
import video from "../assets/icons/video.png";
import information from "../assets/icons/information.png";
import garden from "../assets/icons/garden.png";
import forest from "../assets/icons/forest2.png";
import aquarium from "../assets/icons/aquarium.png";
import themepark from "../assets/icons/themepark.png";
import climbing from "../assets/icons/climbing.png";
import horse from "../assets/icons/horseriding.png";
import surfing from "../assets/icons/surfing.png";
import farm from "../assets/icons/farm-2.png";
import factory from "../assets/icons/factory.png";
import science from "../assets/icons/museum_science.png";
import art from "../assets/icons/artgallery.png";
import observatory from "../assets/icons/observatory.png";
import brewery from "../assets/icons/brewery1.png";

export default class Common {
  // constructor(props) {
  //   super(props);
  // }
  init() {
    window.directions_shape = null;
    window.app_images = "../../common/app_files/img/";
    window.places_viewModel = new Array();
    window.map = null;
    window.searchMarkerLoc = null;
    window.webSite = "http://liquidguides.com/";
    window.ImageLoc = "/Media/Photos/";
    window.maploaded = false;
    window.MapCommand = "init";
    window.map_initialized = false;
    window.ImageSize = "M";
    window.bing_init = false;
    window.gmap3_init = false;
  }

  static isMirror = (arr1, arr2) => {
    const alength = arr1.length;
    if (alength !== arr2.length) return false;
    for (let i = 0; i < alength; i++) {
      if (arr1[i] !== arr2[alength - 1 - i]) return false;
    }

    // Given array is mirror-inverse
    return true;
  };

  static shortenText = (_text, textLength) => {
    let text = _text;
    if (text.length > textLength) {
      text = text.substring(0, textLength) + "...";
    }
    return text;
  };

  static permute(permutation) {
    var length = permutation.length,
      result = [permutation.slice()],
      c = new Array(length).fill(0),
      i = 1,
      k,
      p;

    while (i < length) {
      if (c[i] < i) {
        k = i % 2 && c[i];
        p = permutation[i];
        permutation[i] = permutation[k];
        permutation[k] = p;
        ++c[i];
        i = 1;
        result.push(permutation.slice());
      } else {
        c[i] = 0;
        ++i;
      }
    }
    return result;
  }

  static calculateDistance(p1, p2) {
    //console.log("calculateDistance", p1, p2);
    if (p1 == "undefined" || p2 == "undefined") return 0;
    if (p1.latitude == "undefined" || p2.latitude == "undefined") return 0;
    var dis = getDistance(
      { latitude: parseFloat(p1.latitude), longitude: parseFloat(p1.longitude) },
      { latitude: parseFloat(p2.latitude), longitude: parseFloat(p2.longitude) }
    );

    //console.log(p1, p2, dis);
    if (dis == "undefined") return 0;

    //console.log("calculateDistance", dis / 1000);
    return dis / 1000;
  }

  static BruteForce = (places) => {
    let perm_arg = [];
    for (let p = 0; p < places.length; p++) {
      perm_arg.push(p);
    }
    let paths = Common.permute(perm_arg);
    let pathData = [];
    let distance = 0;
    for (let i = 0; i < paths.length; i++) {
      let totalDistance = 0;
      for (let j = 0; j < paths[i].length; j++) {
        if (j < paths[i].length - 1) {
          const p1 = { latitude: places[paths[i][j]].Lat, longitude: places[paths[i][j]].Longi };
          const p2 = { latitude: places[paths[i][j + 1]].Lat, longitude: places[paths[i][j + 1]].Longi };
          distance = Common.calculateDistance(p1, p2);
          totalDistance += distance;
        }
      }
      pathData.push({ nodes: paths[i], distance: totalDistance });
    }
    let min_distance = 99999999999999999;
    let index = -1;
    for (let i = 1; i < pathData.length; i++) {
      if (pathData[i].distance < min_distance) {
        min_distance = pathData[i].distance;
        index = i;
      }
    }
    let ordered_places = [];
    if (index === -1) return ordered_places;
    for (let j = 0; j < pathData[index].nodes.length; j++) ordered_places.push(places[pathData[index].nodes[j]]);
    console.log("BruteForce: ordered_places", ordered_places);
    return ordered_places;
  };

  static salesman_js = (places, paths) => {
    //const salesman = require("salesman.js");
    var points = [];
    for (let i = 0; i < places.length; i++) {
      points.push(new salesman.Point(places[i].Longi, places[i].Lat));
    }
    console.log("salesman_js: points", points);
    var solution = salesman.solve(points, 0.9999);
    console.log("salesman_js: solution", solution);
    var ordered_points = solution.map((i) => points[i]);
    let ordered_places = [];
    for (let j = 0; j < ordered_points.length; j++) {
      const index = places.findIndex((p) => p.Longi === ordered_points[j].x && p.Lat === ordered_points[j].y);
      if (index !== -1) ordered_places.push(places[index]);
    }
    console.log("salesman_js: ordered_places", ordered_places);
    return ordered_places;
    //return ordered_points;
  };

  static getPlaceOrderByDistance = (place_ids, guide_obj, _places) => {
    //if (place_ids === null) return [];
    let places = [];
    if (typeof _places !== "undefined" && _places !== null) places = _places;
    else {
      place_ids.forEach((place_id) => {
        let place = guide_obj.places.getPlace(place_id.value.ID);
        places.push(place);
      });
    }

    if (places.length < 9) return Common.BruteForce(places);
    else return Common.salesman_js(places);

    //console.log("pathData", pathData);
    //return ordered_places;
  };

  static getFBPath(guide_id, user_info, folder) {
    //if (guide_id == null || user_info.uid == null) return null;
    if (folder != null) return `${folder}/${user_info.uid}/`;
    return `${user_info.uid}/${guide_id}/`;
  }

  static getImageURL(imgURL, xx, _html) {
    // console.log("getImageURL", typeof imgURL);
    // return;
    let html = _html;
    if (window.web_app === true) html = false;
    if (typeof imgURL === "undefined" || imgURL == null || imgURL == "") {
      if (html === true) return "http://liquidguides.com/Media/Images/place.png";
      //console.log("imgURL 5");
      return place_png;
      //Settings.IMAGE_ASSETS + Settings.PLACE_IMAGE_PLACE_HOLDER;
    }
    if (imgURL === "http://liquidguides.com/Media/Images/google-places-logo.jpg")
      if (html === false) return google_places_logo;
      else return imgURL;
    //console.log("imgURL 2");

    if (imgURL === "http://liquidguides.com/Media/Images/place.png")
      if (html === false) return place_png;
      else return imgURL;
    //console.log("imgURL 3");
    // if (imgURL.startsWith("http:")) {
    //   console.log("imgURL startsWith http");
    //   return `https://www.google.com/search?q=%${imgURL}`;
    // }
    //console.log("imgURL 4");
    if (typeof imgURL === "object") return imgURL;
    if (typeof imgURL === "string" && imgURL.indexOf("http") != -1) return imgURL;
    //console.log("imgURL 6");
    if (imgURL === "")
      if (html === false) return place_png;
      else return "http://liquidguides.com/Media/Images/place.png";
    //console.log("imgURL 7");
    //return place_png;
    return Settings.WEB_SITE + Settings.IMAGE_LOCATION + imgURL;
    // if (imgURL != "") {
    //   if (img.indexOf("http:") == -1) imgURL = Settings.WEB_SITE + Settings.IMAGE_LOCATION + imgURL;
    //   else imgURL = img;
    // } else imgURL = Settings.IMAGE_ASSETS + Settings.PLACE_IMAGE_PLACE_HOLDER;
    //console.log("imgURL", imgURL);
  }

  static adjustDate(date) {
    date = new Date(date);
    var userTimezoneOffset = date.getTimezoneOffset() * 60000;
    date = new Date(date.getTime() - userTimezoneOffset);
    return date;
  }

  static adjustForTimeZone(date, add) {
    //console.log("adjustForTimeZone date1", date);
    date = new Date(date);
    var userTimezoneOffset = date.getTimezoneOffset() * 60000;
    if (add) date = new Date(date.getTime() - userTimezoneOffset);
    else date = new Date(date.getTime() + userTimezoneOffset);
    //console.log("adjustForTimeZone date2", date);
    return date;
  }

  static dateToString(date) {
    //writes string without timezone so that when parsed, it is not adjusted for timezone
    //return `${date.getFullYear()},${date.getMonth()},${date.getDate()},${date.getHours()},${date.getMinutes()}`;
    //2020-12-06T18:00:20.989Z

    // in case it's already a string
    let date2 = new Date(date);

    //console.log("dateToString", date, date2);
    let month = date2.getMonth() + 1;
    month = month < 10 ? `0${month}` : `${month}`;
    let day = date2.getDate();
    day = day < 10 ? `0${day}` : `${day}`;
    let hours = date2.getHours();
    hours = hours < 10 ? `0${hours}` : `${hours}`;
    let minutes = date2.getMinutes();
    minutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
    return `${date2.getFullYear()}-${month}-${day}T${hours}:${minutes}:00`;
  }

  static LuxonDateTimeToString(date) {
    const dateString = date.toISO({ zone: "utc" });
    const jsdate = new Date(dateString);
    //console.log("LuxonDateTimeToString:", date, dateString, jsdate);
    return jsdate;
  }

  static dateFromString(dateString) {
    return new Date(dateString);
    //var dateParts = dateString.split(",");
    //new Date(dateParts[0], dateParts[1], dateParts[2], dateParts[3], dateParts[4], 0, 0);
  }

  static formatDate(date, noYear, adjust) {
    //console.log("typeof date:", typeof date);
    // if (typeof date == "object") {
    //   this.formatDateLuxon(date, noYear, adjust);
    //   return;
    // }
    date = new Date(date);
    //var userTimezoneOffset = date.getTimezoneOffset() * 60000;
    //date = new Date(date.getTime() - userTimezoneOffset);
    //console.log("date2", date);

    let day = date.getDate();
    day += adjust == true ? 1 : 0;
    if (noYear) return `${Settings.DAYS[date.getDay()]}, ${Settings.MONTHS[date.getMonth()]} ${day}}`;
    else return `${Settings.DAYS[date.getDay()]}, ${Settings.MONTHS[date.getMonth()]} ${day}, ${date.getFullYear()}`;
  }

  static formatDateLuxon(date, noYear, adjust) {
    var dateTime = DateTime.fromISO(date, { zone: "utc" });
    let day = dateTime.day;
    day += adjust == true ? 1 : 0;

    let returnVal = `${dateTime.toFormat("ccc")}, ${Settings.MONTHS[dateTime.month - 1]} ${day}`;
    if (!noYear) returnVal += `, ${dateTime.year}`;
    //console.log("formatDateLuxon date, dateTime, value:", date, dateTime, returnVal);
    return returnVal;
  }

  // static JSDateToLuxonDateTime(date) {
  //   // Create a JS Date from a Luxon DateTime
  //   const JSDate = new Date(date.valueOf()); // 1516717417146
  //   return JSDate;
  // }

  static JSDateFromLuxonDate(date) {
    const JSDate = new Date(date.year, date.month - 1, date.day, date.hour, date.minute, 0);
    return JSDate;
  }

  static LuxonDateFromJSDate(date) {
    const LuxonDate = DateTime.fromObject(
      {
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day: date.getDate(),
        hour: date.getHours(),
        minute: date.getMinutes(),
        second: 0,
      },
      { zone: "utc" }
    );
    return LuxonDate;
  }

  static JSDateFromLuxonDateTime(date) {
    // Create a JS Date from a Luxon DateTime
    const JSDate = new Date(date.valueOf()); // 1516717417146
    return JSDate;
  }

  static LuxonDateTimeFromJSDate(date) {
    // Create a Luxon DateTime from a JS Date
    const LuxonDateTime = DateTime.fromMillis(date.getTime(), { zone: "utc" });
    return LuxonDateTime;
  }

  static formatDateYMD(date) {
    //console.log("date1", date);
    date = new Date(date);
    //var userTimezoneOffset = date.getTimezoneOffset() * 60000;
    //date = new Date(date.getTime() - userTimezoneOffset);
    //console.log("date2", date);
    let month = date.getMonth() + 1;
    if (month < 10) month = "0" + month;

    let day = date.getDate();
    if (day < 10) day = "0" + day;
    return `${date.getFullYear()}-${month}-${day}`;
  }

  static formatTime(date, add, seconds) {
    //console.log("formatTime date1", date, add);
    //if (typeof date == "string")
    date = new Date(date);
    if (add == true || add == false) {
      var userTimezoneOffset = date.getTimezoneOffset() * 60000;
      if (date == true) date = new Date(date.getTime() - userTimezoneOffset);
      else date = new Date(date.getTime() + userTimezoneOffset);
    }
    //console.log("formatTime date2", date);
    var minutes = date.getMinutes();
    // if (half == true) {
    //   if (minutes < 15) minutes = 0;
    //   else if (minutes > 45) minutes = 0;
    //   else minutes = 30;
    // }
    if (minutes < 10) minutes = `0${minutes}`;
    if (seconds) {
      var seconds = date.getMinutes();
      if (seconds < 10) seconds = `0${seconds}`;
      return `${date.getHours()}:${minutes}:${seconds}`;
    }
    return `${date.getHours()}:${minutes}`;
  }

  static formatTimeLuxon(date) {
    var dateTime = DateTime.fromISO(date, { zone: "utc" });
    var minutes = dateTime.minute;
    if (minutes < 10) minutes = `0${minutes}`;
    //console.log("formatTimeLuxon date, dateTime, time", date, dateTime, `${dateTime.hour}:${minutes}`);
    return `${dateTime.hour}:${minutes}`;
  }

  static createTextFromHTML(html) {
    // Temporary
    return html;
    if (html == null) return "";
    let text = html.replace(/<style([\s\S]*?)<\/style>/gi, "");
    text = text.replace(/<script([\s\S]*?)<\/script>/gi, "");
    text = text.replace(/<\/div>/gi, "\n");
    text = text.replace(/<\/li>/gi, "\n");
    text = text.replace(/<li>/gi, "  *  ");
    text = text.replace(/<\/ul>/gi, "\n");
    text = text.replace(/<\/p>/gi, "\n");
    text = text.replace(/<br\s*[\/]?>/gi, "\n");
    text = text.replace(/<[^>]+>/gi, "");
    text = text.replace(/&amp/g, "");
    text = text.replace(/&quot/g, '"');
    text = text.replace("&#x200b;", "");
    return text.trim();
  }

  static createHTMLFromText(input_str) {
    var input_str; //store input
    var text_input; //store input after being trim()med
    var output_html = ""; //store output
    var counter;

    text_input = input_str.trim(); //trim() input
    if (text_input.length > 0) {
      output_html += "<p>"; //begin by creating paragraph
      for (counter = 0; counter < text_input.length; counter++) {
        switch (text_input[counter]) {
          case "\n":
            if (text_input[counter + 1] === "\n") {
              output_html += "</p>\n<p>";
              counter++;
            } else output_html += "<br>";
            break;

          case " ":
            if (text_input[counter - 1] != " " && text_input[counter - 1] != "\t") output_html += " ";
            break;

          case "\t":
            if (text_input[counter - 1] != "\t") output_html += " ";
            break;

          case "&":
            output_html += "&amp;";
            break;

          case '"':
            output_html += "&quot;";
            break;

          case ">":
            output_html += "&gt;";
            break;

          case "<":
            output_html += "&lt;";
            break;

          default:
            output_html += text_input[counter];
        }
      }
      output_html += "</p>"; //finally close paragraph
    }
    return output_html; // return output html
  }

  static addCommas(nStr) {
    nStr += "";
    var x = nStr.split(".");
    var x1 = x[0];
    var x2 = x.length > 1 ? "." + x[1] : "";
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, "$1" + "," + "$2");
    }
    return x1 + x2;
  }

  static isNumeric(str) {
    if (typeof str != "string") return false; // we only process strings!
    return (
      !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
      !isNaN(parseFloat(str))
    ); // ...and ensure strings of whitespace fail
  }

  static getMaxMinLatLon(allPlaces) {
    let min_lat = 90;
    let min_lon = 180;
    let max_lat = -90;
    let max_lon = -180;
    for (let i = 0; i < allPlaces.length; i++) {
      let lat = 0;
      let lon = 0;
      if (typeof allPlaces[i].data.base_location !== "undefined") {
        lat = allPlaces[i].data.base_location.latitude;
        lon = allPlaces[i].data.base_location.longitude;
      }
      if (lat < min_lat) min_lat = lat;
      if (lat > max_lat) max_lat = lat;
      if (lon < min_lon) min_lon = lon;
      if (lon > max_lon) max_lon = lon;
    }
    const delta = 0.03;
    if (max_lat === min_lat) {
      min_lat -= delta;
      min_lon -= delta;
      max_lat += delta;
      max_lon += delta;
    }
    //console.log("max_lat:", max_lat, "max_lon:", max_lon, "min_lat:", min_lat, "min_lon:", min_lon);
    const max_mins = { max_lat: max_lat, max_lon: max_lon, min_lat: min_lat, min_lon: min_lon };
    return max_mins;
  }

  static filterGuides = (
    guides,
    guide_search_text,
    guide_countries_selected,
    guide_cities_selected,
    guide_language_selected,
    guide_order_by
  ) => {
    let guides_to_display = [];
    let found = false;
    const searchUpper = guide_search_text.toUpperCase();
    const languageUpper = guide_language_selected.toUpperCase();
    for (let i = 0; i < guides.length; i++) {
      if (!guides[i].data.language.toUpperCase().includes(languageUpper)) continue;
      if (searchUpper !== "") if (!guides[i].data.guide_name.toUpperCase().includes(searchUpper)) continue;
      if (guide_countries_selected.length !== 0) {
        found = false;
        for (let j = 0; j < guides[i].data.countries.length; j++) {
          for (let k = 0; k < guide_countries_selected.length; k++) {
            if (guide_countries_selected[k].label.toUpperCase() === guides[i].data.countries[j].toUpperCase()) {
              found = true;
              break;
            }
          }
          if (found === false) break;
        }
        if (found === false) continue;
      }
      if (guide_cities_selected.length !== 0) {
        found = false;
        for (let j = 0; j < guides[i].data.cities.length; j++) {
          for (let k = 0; k < guide_cities_selected.length; k++) {
            if (guide_cities_selected[k].label.toUpperCase() === guides[i].data.cities[j].toUpperCase()) {
              found = true;
              break;
            }
          }
          if (found === false) break;
        }
        if (found === false) continue;
      }
      guides_to_display.push(guides[i]);
    }
    if (guide_order_by === "Country") {
      if (typeof guides_to_display.data !== "undefined")
        guides_to_display = guides_to_display.sort((a, b) =>
          a.data.countries[0].toUpperCase() > b.data.countries[0].toUpperCase() ? 1 : -1
        );
    }
    if (guide_order_by === "City") {
      if (typeof guides_to_display.data !== "undefined")
        guides_to_display = guides_to_display.sort((a, b) =>
          a.data.cities[0].toUpperCase() > b.data.cities[0].toUpperCase() ? 1 : -1
        );
    }
    if (guide_order_by === "Name") {
      if (typeof guides_to_display.data !== "undefined")
        guides_to_display = guides_to_display.sort((a, b) =>
          a.data.guide_name.toUpperCase() > b.data.guide_name.toUpperCase() ? 1 : -1
        );
    }
    return guides_to_display;
  };
  static purchaseTicketsHTML(AppSettings, creator_info, owner_info, user_info) {
    //console.log("owner_info", owner_info);
    //console.log("user_info", user_info);
    //if (creator_info == null) if (owner_info != null) creator_info = owner_info;
    // if (owner_info == null) if (creator_info != null) owner_info = creator_info;
    // if (user_info == null) if (owner_info != null) user_info = owner_info;
    if (creator_info == null) creator_info = { uid: "no_creator_info", affiliate_program: true };
    //console.log("creator_info", creator_info);
    let marker = AppSettings.travel_payouts_marker;
    if (marker == null) marker = "292121";
    let sid = creator_info.affiliate_program === true ? creator_info.uid : "non_participating";
    // This is for tracking sub accounts i.e users
    //let travel_payouts_marker_sid = `${marker}.${sid}`;
    let travel_payouts_marker_sid = `${marker}`;
    let html = AppSettings.tickets;
    html = html.replace(/292121/g, travel_payouts_marker_sid);
    //console.log(html);
    // console.log(
    //   `purchaseTicketsHTML creator: ${creator}/${percent_1}, owner: ${owner}/${percent_2}, user: ${user}/${percent_3}, andiamo: ${andiamo}, travel_payouts_marker: ${travel_payouts_marker}`
    // );

    return html;

    // let html = AppSettings.tickets;
    let travel_payouts_marker = AppSettings.travel_payouts_marker_2;
    let percent_1 = AppSettings.percent_1;
    let percent_2 = AppSettings.percent_2;
    let percent_3 = AppSettings.percent_3;

    let creator = 0;
    let owner = 0;
    let user = 0;
    if (creator_info != null) {
      if (creator_info.travel_payouts_marker != "") creator = percent_1;
    }
    if (
      owner_info.travel_payouts_marker != "" &&
      owner_info.travel_payouts_marker != creator_info.travel_payouts_marker
    )
      owner = percent_2;
    if (
      user_info.travel_payouts_marker != "" &&
      user_info.travel_payouts_marker != owner_info.travel_payouts_marker &&
      user_info.travel_payouts_marker != creator_info.travel_payouts_marker
    )
      user = percent_3;
    let andiamo = 100 - creator - owner - user;
    if (andiamo != 100) {
      let random = Math.floor(Math.random() * 100 + 1);
      if (creator != 0)
        if (random < creator) travel_payouts_marker = creator_info.travel_payouts_marker;
        else if (owner != 0)
          if (random >= creator && random < creator + owner) travel_payouts_marker = owner_info.travel_payouts_marker;
          else if (user != 0)
            if (random >= creator + owner && random < creator + owner + user)
              travel_payouts_marker = user_info.travel_payouts_marker;
    }
  }
}

export var Settings = {
  APP_IMAGES: "../../common/app_files/img/",
  WEB_SITE: "http://liquidguides.com",
  IMAGE_LOCATION: "/Media/Photos/",
  IMAGE_ASSETS: "../assets/icons/",
  PLACE_IMAGE_PLACE_HOLDER: "place.png",
  MONTHS: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
  DAYS: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
};

export const COLORS = {
  BLUE: "#3598DB",
  DARK_BLUE: "#0645AD",
  DARKER_BLUE: "#0B0080",
  WHITE: "#ffffff",
  GRAY: "#707070",
  LIGHT_GRAY: "#E5E5E5",
  VERY_LIGHT_GRAY: "#F0F0F0",
  GREEN: "#42BC42",
  YELLOW: "#FFD153",
  ORANGE: "#FFA021",
  RED: "#FF3F3E",
  BLACK: "#000000",
};

export const AUTHORIZATION_LEVEL = {
  NONE: 0,
  READ: 1,
  GUEST: 2,
  COPY: 3,
  MODIFY: 4,
};

export const ICON_NAMES = [
  "airport",
  "arbol",
  "arch",
  "art-museum-2",
  "atm-2",
  "bags",
  "bank",
  "bar",
  "barber",
  "bar_coktail",
  "beach",
  "beautifulview",
  "bed_breakfast1-2",
  "bicycle_shop",
  "bigcity",
  "binoculars",
  "boat",
  "bus",
  "bustour",
  "cablecar",
  "campingcar",
  "car",
  "carrental",
  "castle-2",
  "cathedral",
  "caution",
  "cave-2",
  "cemetary",
  "church-2",
  "citysquare",
  "climbing-bentgate_carabiner",
  "coffee",
  "color_green",
  "color_teal",
  "color_blue",
  "color_purple",
  "color_brown",
  "color_red",
  "color_orange",
  "color_light_green",
  "congress",
  "conveniencestore",
  "cruiseship",
  "currencyexchange",
  "dentist",
  "departmentstore",
  "desert-2",
  "doctor",
  "dogs_leash",
  "family",
  "firstaid",
  "home-2",
  "hospital-building",
  "hospital",
  "japanese-food",
  "kayaking",
  "lake",
  "laundromat",
  "letter_a",
  "letter_b",
  "letter_c",
  "letter_d",
  "letter_e",
  "letter_f",
  "letter_g",
  "letter_h",
  "letter_i",
  "letter_j",
  "letter_k",
  "letter_l",
  "letter_m",
  "letter_n",
  "letter_o",
  "letter_p",
  "letter_q",
  "letter_r",
  "letter_s",
  "letter_t",
  "letter_u",
  "letter_v",
  "letter_w",
  "letter_x",
  "letter_y",
  "letter_z",
  "library",
  "lighthouse-2",
  "lodging-2",
  "mall",
  "marina-2",
  "medicine",
  "memorial",
  "monument",
  "mosquee",
  "mountains",
  "museum_archeological",
  "museum_art",
  "museum_crafts",
  "museum_industry",
  "museum_naval",
  "museum_war",
  "number_0",
  "number_1",
  "number_10",
  "number_100",
  "number_11",
  "number_12",
  "number_13",
  "number_14",
  "number_15",
  "number_16",
  "number_17",
  "number_18",
  "number_19",
  "number_2",
  "number_20",
  "number_21",
  "number_22",
  "number_23",
  "number_24",
  "number_25",
  "number_26",
  "number_27",
  "number_28",
  "number_29",
  "number_3",
  "number_30",
  "number_31",
  "number_32",
  "number_33",
  "number_34",
  "number_35",
  "number_36",
  "number_37",
  "number_38",
  "number_39",
  "number_4",
  "number_40",
  "number_41",
  "number_42",
  "number_43",
  "number_44",
  "number_45",
  "number_46",
  "number_47",
  "number_48",
  "number_49",
  "number_5",
  "number_50",
  "number_51",
  "number_52",
  "number_53",
  "number_54",
  "number_55",
  "number_56",
  "number_57",
  "number_58",
  "number_59",
  "number_6",
  "number_60",
  "number_61",
  "number_62",
  "number_63",
  "number_64",
  "number_65",
  "number_66",
  "number_67",
  "number_68",
  "number_69",
  "number_7",
  "number_70",
  "number_71",
  "number_72",
  "number_73",
  "number_74",
  "number_75",
  "number_76",
  "number_77",
  "number_78",
  "number_79",
  "number_8",
  "number_80",
  "number_81",
  "number_82",
  "number_83",
  "number_84",
  "number_85",
  "number_86",
  "number_87",
  "number_88",
  "number_89",
  "number_9",
  "number_90",
  "number_91",
  "number_92",
  "number_93",
  "number_94",
  "number_95",
  "number_96",
  "number_97",
  "number_98",
  "number_99",
  "parkinggarage",
  "pets",
  "playground",
  "recycle",
  "regroup",
  "repair",
  "resort",
  "restaurant_chinese",
  "restaurant_fish",
  "restaurant_greek",
  "restaurant_indian",
  "restaurant_italian",
  "restaurant_korean",
  "restaurant_mediterranean",
  "restaurant_mexican",
  "restaurant_thai",
  "restaurant_vegetarian",
  "river-2",
  "road",
  "sandwich-2",
  "school",
  "skiing",
  "stadium",
  "supermarket",
  "taxi",
  "temple-2",
  "toilets_inclusive",
  "train",
  "tramway",
  "tree",
  "veterinary",
  "villa",
  "walkingtour",
  "winebar",
  "zoo",
];

// export const POI_IMAGESXXX = {
//   0: { name: "_None", req: null },
//   1: { name: "_Default", req: require("../assets/images/poi.png") },
//   2: { name: "Accomodations", req: require("../assets/icons/hotel.png") },
//   3: { name: "Architecture", req: require("../assets/icons/arch.png") },
//   4: { name: "Area", req: require("../assets/icons/citysquare.png") },
//   5: { name: "Attraction", req: require("../assets/icons/congress.png") },
//   6: { name: "City", req: require("../assets/icons/bigcity.png") },
//   7: { name: "Entertainment", req: require("../assets/icons/theater.png") },
//   8: { name: "Family & Kids", req: require("../assets/icons/family.png") },
//   9: { name: "Historical", req: require("../assets/icons/historical_museum.png") },
//   10: { name: "Landmark", req: require("../assets/icons/landmark.png") },
//   11: { name: "Museum", req: require("../assets/icons/temple-2.png") },
//   12: { name: "Nearby", req: require("../assets/icons/sight-2.png") },
//   13: { name: "Neighborhood", req: require("../assets/icons/citysquare.png") },
//   14: { name: "Religion", req: require("../assets/icons/cathedral.png") },
//   15: { name: "Restaurants", req: require("../assets/icons/restaurant.png") },
//   16: { name: "Shopping", req: require("../assets/icons/bags.png") },
//   17: { name: "Sport", req: require("../assets/icons/stadium.png") },
//   18: { name: "Transport", req: require("../assets/icons/bus.png") },
// };
export const POI_IMAGES = {
  0: { name: "None", req: null },
  1: { name: "Default", req: poi },
  2: { name: "airport", req: airport },
  3: { name: "tree", req: arbol },
  4: { name: "arch", req: arch },
  5: { name: "art museum", req: art_museum_2 },
  6: { name: "atm", req: atm_2 },
  7: { name: "bags", req: bags },
  8: { name: "bank", req: bank },
  9: { name: "bar", req: bar },
  10: { name: "barber", req: barber },
  11: { name: "bar cocktail", req: bar_coktail },
  12: { name: "beach", req: beach },
  13: { name: "beautiful view", req: beautifulview },
  14: { name: "bed and breakfast", req: bed_breakfast1_2 },
  15: { name: "bicycle", req: bicycle_shop },
  16: { name: "big city", req: bigcity },
  17: { name: "binoculars", req: binoculars },
  18: { name: "boat", req: boat },
  19: { name: "bus", req: bus },
  20: { name: "bus tour", req: bustour },
  21: { name: "cable car", req: cablecar },
  22: { name: "camping", req: campingcar },
  23: { name: "car", req: car },
  24: { name: "car rental", req: carrental },
  25: { name: "castle", req: castle_2 },
  26: { name: "cathedral", req: cathedral },
  27: { name: "caution", req: caution },
  28: { name: "cave", req: cave_2 },
  29: { name: "cemetery", req: cemetary },
  30: { name: "church", req: church_2 },
  31: { name: "city square", req: citysquare },
  32: { name: "climbing", req: climbing_bentgate_carabiner },
  33: { name: "coffee", req: coffee },
  34: { name: "congress", req: congress },
  35: { name: "convenience store", req: conveniencestore },
  36: { name: "cruise ship", req: cruiseship },
  37: { name: "currency exchange", req: currencyexchange },
  38: { name: "dentist", req: dentist },
  39: { name: "department store", req: departmentstore },
  40: { name: "desert", req: desert_2 },
  41: { name: "doctor", req: doctor },
  42: { name: "dogs", req: dogs_leash },
  43: { name: "family", req: family },
  44: { name: "first aid", req: firstaid },
  45: { name: "home", req: home_2 },
  46: { name: "hospital building", req: hospital_building },
  47: { name: "hospital", req: hospital },
  48: { name: "japanese food", req: japanese_food },
  49: { name: "kayaking", req: kayaking },
  50: { name: "lake", req: lake },
  51: { name: "laundromat", req: laundromat },
  52: { name: "letter_a", req: letter_a },
  53: { name: "letter_b", req: letter_b },
  54: { name: "letter_c", req: letter_c },
  55: { name: "letter_d", req: letter_d },
  56: { name: "letter_e", req: letter_e },
  57: { name: "letter_f", req: letter_f },
  58: { name: "letter_g", req: letter_g },
  59: { name: "letter_h", req: letter_h },
  60: { name: "letter_i", req: letter_i },
  61: { name: "letter_j", req: letter_j },
  62: { name: "letter_k", req: letter_k },
  63: { name: "letter_l", req: letter_l },
  64: { name: "letter_m", req: letter_m },
  65: { name: "letter_n", req: letter_n },
  66: { name: "letter_o", req: letter_o },
  67: { name: "letter_p", req: letter_p },
  68: { name: "letter_q", req: letter_q },
  69: { name: "letter_r", req: letter_r },
  70: { name: "letter_s", req: letter_s },
  71: { name: "letter_t", req: letter_t },
  72: { name: "letter_u", req: letter_u },
  73: { name: "letter_v", req: letter_v },
  74: { name: "letter_w", req: letter_w },
  75: { name: "letter_x", req: letter_x },
  76: { name: "letter_y", req: letter_y },
  77: { name: "letter_z", req: letter_z },
  78: { name: "library", req: library },
  79: { name: "lighthouse", req: lighthouse_2 },
  80: { name: "lodging", req: lodging_2 },
  81: { name: "mall", req: mall },
  82: { name: "marina", req: marina_2 },
  83: { name: "medicine", req: medicine },
  84: { name: "memorial", req: memorial },
  85: { name: "monument", req: monument },
  86: { name: "mosque", req: mosquee },
  87: { name: "mountains", req: mountains },
  88: { name: "museum archeological", req: museum_archeological },
  89: { name: "museum art", req: museum_art },
  90: { name: "museum crafts", req: museum_crafts },
  91: { name: "museum industry", req: museum_industry },
  92: { name: "museum naval", req: museum_naval },
  93: { name: "museum war", req: museum_war },
  94: { name: "number_0", req: number_0 },
  95: { name: "number_1", req: number_1 },
  96: { name: "number_2", req: number_2 },
  97: { name: "number_3", req: number_3 },
  98: { name: "number_4", req: number_4 },
  99: { name: "number_5", req: number_5 },
  100: { name: "number_6", req: number_6 },
  101: { name: "number_7", req: number_7 },
  102: { name: "number_8", req: number_8 },
  103: { name: "number_9", req: number_9 },
  104: { name: "parking garage", req: parkinggarage },
  105: { name: "pets", req: pets },
  106: { name: "playground", req: playground },
  107: { name: "recycle", req: recycle },
  108: { name: "regroup", req: regroup },
  109: { name: "repair", req: repair },
  110: { name: "resort", req: resort },
  111: { name: "restaurant chinese", req: restaurant_chinese },
  112: { name: "restaurant fish", req: restaurant_fish },
  113: { name: "restaurant greek", req: restaurant_greek },
  114: { name: "restaurant indian", req: restaurant_indian },
  115: { name: "restaurant italian", req: restaurant_italian },
  116: { name: "restaurant korean", req: restaurant_korean },
  117: { name: "restaurant mediterranean", req: restaurant_mediterranean },
  118: { name: "restaurant mexican", req: restaurant_mexican },
  119: { name: "restaurant thai", req: restaurant_thai },
  120: { name: "restaurant vegetarian", req: restaurant_vegetarian },
  121: { name: "river", req: river_2 },
  122: { name: "road", req: road },
  123: { name: "sandwich", req: sandwich_2 },
  124: { name: "school", req: school },
  125: { name: "skiing", req: skiing },
  126: { name: "stadium", req: stadium },
  127: { name: "supermarket", req: supermarket },
  128: { name: "taxi", req: taxi },
  129: { name: "temple", req: temple_2 },
  130: { name: "toilets", req: toilets_inclusive },
  131: { name: "train", req: train },
  132: { name: "tramway", req: tramway },
  133: { name: "tree", req: tree },
  134: { name: "veterinary", req: veterinary },
  135: { name: "villa", req: villa },
  136: { name: "walking tour", req: walkingtour },
  137: { name: "wine bar", req: winebar },
  138: { name: "zoo", req: zoo },
  139: { name: "restaurant", req: restaurant },
  140: { name: "hotel", req: hotel },
  141: { name: "theater", req: theater },
  142: { name: "bridge", req: bridge_modern },
  143: { name: "ice cream", req: icecream },
  144: { name: "soccer", req: soccer },
  145: { name: "golf", req: golf_2 },
  146: { name: "area", req: area },
  147: { name: "excursion", req: excursion },
  148: { name: "fast food", req: fast_food },
  149: { name: "kids", req: kids },
  150: { name: "leisure", req: leisure },
  151: { name: "nearby", req: nearby },
  152: { name: "neighborhood", req: neighborhood },
  153: { name: "nightlife", req: nightlife },
  154: { name: "outdoors", req: outdoors },
  155: { name: "restaurant french", req: restaurant_french },
  156: { name: "restaurant japanese", req: restaurant_japanese },
  157: { name: "restaurant pizza", req: restaurant_pizza },
  158: { name: "restaurant vegan", req: restaurant_vegan },
  159: { name: "shopping", req: shopping },
  160: { name: "sports", req: sports },
  161: { name: "tour", req: tour },
  162: { name: "trail", req: trail },
  163: { name: "trip", req: trip },
  164: { name: "country", req: country },
  165: { name: "search", req: search },
  166: { name: "bakery", req: bakery },
  167: { name: "restaurant meat", req: restaurant_steakhouse },
  168: { name: "restaurant venezuela", req: restaurant_venezuela },
  169: { name: "color green", req: color_green },
  170: { name: "color teal", req: color_teal },
  171: { name: "color blue", req: color_blue },
  172: { name: "color purple", req: color_purple },
  173: { name: "color brown", req: color_brown },
  174: { name: "color red", req: color_red },
  175: { name: "color orange", req: color_orange },
  176: { name: "color light green", req: color_light_green },
  177: { name: "town", req: town },
  178: { name: "bear", req: bear },
  179: { name: "bridge old", req: bridge_old },
  180: { name: "canyon", req: canyon_2 },
  181: { name: "cinema", req: cinema },
  182: { name: "dancinghall", req: dancinghall },
  183: { name: "deer", req: deer },
  184: { name: "fishing", req: fishing },
  185: { name: "geyser", req: geyser_2 },
  186: { name: "glacier", req: glacier_2 },
  187: { name: "historical_museum", req: historical_museum },
  188: { name: "hotspring", req: hotspring },
  189: { name: "moose", req: moose },
  190: { name: "museum_science", req: museum_science },
  191: { name: "music_classical", req: music_classical },
  192: { name: "palace", req: palace_2 },
  193: { name: "panoramicview", req: panoramicview },
  194: { name: "ruins", req: ruins_2 },
  195: { name: "attraction", req: sight_2 },
  196: { name: "smallcity", req: smallcity },
  197: { name: "state", req: state },
  198: { name: "statue", req: statue_2 },
  199: { name: "synagogue", req: synagogue_2 },
  200: { name: "volcano", req: volcano_2 },
  201: { name: "waterfall", req: waterfall_2 },
  202: { name: "wetlands", req: wetlands },
  203: { name: "world", req: world },
  204: { name: "star", req: star_3 },
  205: { name: "island", req: island_icon },
  206: { name: "building", req: building },
  207: { name: "casino", req: casino_2 },
  208: { name: "photo", req: photo },
  209: { name: "audio", req: audio },
  210: { name: "video", req: video },
  211: { name: "info", req: information },
  212: { name: "garden", req: garden },
  213: { name: "forest", req: forest },
  214: { name: "aquarium", req: aquarium },
  215: { name: "themepark", req: themepark },
  216: { name: "climbing", req: climbing },
  217: { name: "horse", req: horse },
  218: { name: "boat", req: boat },
  219: { name: "surfing", req: surfing },
  220: { name: "farm", req: farm },
  221: { name: "factory", req: factory },
  222: { name: "science", req: science },
  223: { name: "art", req: art },
  224: { name: "observatory", req: observatory },
  225: { name: "brewery", req: brewery },
};

export const AICategories = [
  { category: "Museum", id: "22" },
  { category: "Park", id: "28" },
  { category: "Historic Site", id: "15" },
  { category: "Zoo", id: "101" },
  { category: "Beach", id: "5" },
  { category: "Garden", id: "121" },
  { category: "Outdoors", id: "26" },
  { category: "Sightseeing", id: "143" },
  { category: "Monument", id: "21" },
  { category: "Church", id: "8" },
  { category: "Temple", id: "8" },
  { category: "Cathedral", id: "81" },
  { category: "Basilica", id: "81" },
  { category: "Castle", id: "80" },
  { category: "Palace", id: "27" },
  { category: "Square", id: "44" },
  { category: "Amusement Park", id: "124" },
  { category: "Nature Center", id: "28" },
  { category: "Wildlife Sanctuary", id: "62" },
  { category: "Art Gallery", id: "145" },
  { category: "Art Tour", id: "145" },
  { category: "Library", id: "88" },
  { category: "Bridge", id: "6" },
  { category: "Botanical Garden", id: "121" },
  { category: "National Park", id: "28" },
  { category: "Nature Reserve", id: "28" },
  { category: "Waterfall", id: "75" },
  { category: "Cave", id: "82" },
  { category: "Lighthouse", id: "89" },
  { category: "Observatory", id: "146" },
  { category: "Planetarium", id: "147" },
  { category: "Memorial", id: "21" },
  { category: "Fort", id: "153" },
  { category: "Monorail", id: "154" },
  { category: "Scenic Drive", id: "155" },
  { category: "Scenic", id: "68" },
  { category: "Ski Resort", id: "116" },
  { category: "Shopping Center", id: "110" },
  { category: "Theater", id: "46" },
  { category: "Concert Hall", id: "108" },
  { category: "Sports Venue", id: "156" },
  { category: "Sports", id: "43" },
  { category: "Casino", id: "115" },
  { category: "Wine", id: "148" },
  { category: "Winery", id: "141" },
  { category: "Wine Tasting", id: "148" },
  { category: "Brewery", id: "149" },
  { category: "Distillery", id: "150" },
  { category: "Race Track", id: "142" },
  { category: "Golf Course", id: "17" },
  { category: "Hiking Trail", id: "151" },
  { category: "Hiking", id: "152" },
  { category: "Horseback Riding", id: "129" },
  { category: "Boating", id: "130" },
  { category: "Fishing", id: "63" },
  { category: "Swimming", id: "131" },
  { category: "Surfing", id: "132" },
  { category: "Kayaking", id: "126" },
  { category: "Canoeing", id: "126" },
  { category: "Rafting", id: "126" },
  { category: "Hot Air Ballooning", id: "43" },
  { category: "Zip Lining", id: "43" },
  { category: "Rock Climbing", id: "127" },
  { category: "Biking", id: "128" },
  { category: "Cycling", id: "128" },
  { category: "Camping", id: "79" },
  { category: "ATV Riding", id: "43" },
  { category: "Paintball", id: "43" },
  { category: "Go-Karting", id: "43" },
  { category: "Ice Skating", id: "43" },
  { category: "Horse Racing", id: "133" },
  { category: "Miniature Golf", id: "17" },
  { category: "Water Park", id: "124" },
  { category: "Airport", id: "1" },
  { category: "Marina", id: "90" },
  { category: "Farm", id: "134" },
  { category: "Factory", id: "135" },
  { category: "University", id: "111" },
  { category: "College", id: "111" },
  { category: "School", id: "111" },
  { category: "Library", id: "88" },
  { category: "Restaurant", id: "29" },
  { category: "Bar", id: "55" },
  { category: "Nightclub", id: "25" },
  { category: "Nightlife", id: "25" },
  { category: "Coffee Shop", id: "83" },
  { category: "Brewpub", id: "55" },
  { category: "Music Venue", id: "67" },
  { category: "Cinema", id: "60" },
  { category: "Art Museum", id: "145" },
  { category: "Science Museum", id: "137" },
  { category: "Children's Museum", id: "22" },
  { category: "Village Tour", id: "59" },
  { category: "Viewpoint", id: "68" },
  { category: "Villa Tour", id: "27" },
  { category: "Promontory", id: "112" },
  { category: "Mountain", id: "92" },
  { category: "City", id: "9" },
  { category: "City Sightseeing", id: "9" },
  { category: "Village", id: "59" },
  { category: "Town", id: "59" },
  { category: "Historical Site", id: "15" },
  { category: "Spa", id: "163" },
  { category: "Food", id: "31" },
  { category: "Shopping", id: "110" },
  { category: "Entertainment", id: "12" },
  { category: "Accomodation", id: "0" },
  { category: "Accommodation", id: "0" },
  { category: "Arts", id: "3" },
  { category: "Bank", id: "4" },
  { category: "Cemetery", id: "7" },
  { category: "Hotel", id: "16" },
  { category: "Leisure", id: "19" },
  { category: "Market", id: "20" },
  { category: "Neighborhood", id: "24" },
  { category: "Street", id: "45" },
  { category: "Landmark", id: "8" },
  { category: "Religious", id: "109" },
  { category: "Religious Site", id: "109" },
  { category: "Historic", id: "15" },
  { category: "Historical", id: "15" },
  { category: "Art", id: "144" },
  { category: "Nature", id: "26" },
  { category: "Natural", id: "26" },
  { category: "Theme Park", id: "124" },
  { category: "Natural Wonder", id: "26" },
  { category: "Festival", id: "159" },
  { category: "Parade", id: "138" },
  { category: "Carnival", id: "139" },
  { category: "Fair", id: "140" },
  { category: "Lake", id: "78" },
  { category: "Stadium", id: "125" },
  { category: "Ancient Ruin", id: "69" },
  { category: "Adventure", id: "13" },
  { category: "Culture", id: "51" },
  { category: "Cultural", id: "51" },
  { category: "Relaxation", id: "19" },
  { category: "Island", id: "113" },

  //{ category: "Military Bases", id: "" },
];

export const AIBaseCategories = [
  { category: "Wildlife", id: "62" },
  { category: "Aquarium", id: "123" },
  { category: "Forest", id: "122" },
  { category: "Garden", id: "121" },
  { category: "Island", id: "113" },
  { category: "Museum", id: "22" },
  { category: "Park", id: "28" },
  { category: "Histor", id: "15" },
  { category: "Zoo", id: "101" },
  { category: "Beach", id: "5" },
  { category: "Garden", id: "121" },
  { category: "Outdoor", id: "26" },
  { category: "Sight", id: "143" },
  { category: "Monument", id: "21" },
  { category: "Church", id: "8" },
  { category: "Cathedral", id: "81" },
  { category: "Basilica", id: "81" },
  { category: "Castle", id: "80" },
  { category: "Palace", id: "27" },
  { category: "Square", id: "44" },
  { category: "Natur", id: "160" },
  { category: "Wild", id: "62" },
  { category: "Art", id: "144" },
  { category: "Library", id: "88" },
  { category: "Bridge", id: "6" },
  { category: "Botanical", id: "167" },
  { category: "Waterfall", id: "75" },
  { category: "Cave", id: "82" },
  { category: "Lighthouse", id: "89" },
  { category: "Memorial", id: "21" },
  { category: "Scenic", id: "68" },
  { category: "Ski", id: "116" },
  { category: "Shop", id: "110" },
  { category: "Theater", id: "46" },
  { category: "Concert", id: "108" },
  { category: "Sport", id: "43" },
  { category: "Casino", id: "115" },
  { category: "Wine", id: "148" },
  { category: "Brewery", id: "149" },
  { category: "Race", id: "142" },
  { category: "Golf", id: "17" },
  { category: "Hik", id: "152" },
  { category: "Horse", id: "129" },
  { category: "Boat", id: "130" },
  { category: "Fish", id: "63" },
  { category: "Swim", id: "131" },
  { category: "Surf", id: "5" },
  { category: "Kayak", id: "126" },
  { category: "Canoe", id: "165" },
  { category: "Raft", id: "166" },
  { category: "Rock", id: "127" },
  { category: "Bik", id: "128" },
  { category: "Cycl", id: "128" },
  { category: "Camp", id: "79" },
  { category: "Farm", id: "134" },
  { category: "Factory", id: "135" },
  { category: "School", id: "111" },
  { category: "Library", id: "88" },
  { category: "Restaurant", id: "29" },
  { category: "Bar", id: "55" },
  { category: "Coffee", id: "83" },
  { category: "Café", id: "83" },
  { category: "Music", id: "67" },
  { category: "Cinema", id: "60" },
  { category: "Science", id: "22" },
  { category: "Children", id: "22" },
  { category: "Village", id: "59" },
  { category: "Villa", id: "27" },
  { category: "Mountain", id: "92" },
  { category: "City", id: "9" },
  { category: "Town", id: "59" },
  { category: "Spa", id: "163" },
  { category: "Food", id: "162" },
  { category: "Culinary", id: "161" },
  { category: "Bank", id: "4" },
  { category: "Cemetery", id: "7" },
  { category: "Hotel", id: "16" },
  { category: "Leisure", id: "19" },
  { category: "Market", id: "20" },
  { category: "Neighborhood", id: "24" },
  { category: "Street", id: "45" },
  { category: "Landmark", id: "106" },
  { category: "Religi", id: "109" },
  { category: "Natur", id: "150" },
  { category: "Festival", id: "149" },
  { category: "Fair", id: "140" },
  { category: "Lake", id: "78" },
  { category: "Stadium", id: "125" },
  { category: "Ancient", id: "69" },
  { category: "Ruin", id: "69" },
  { category: "Landscape", id: "157" },
  { category: "Night", id: "25" },
  { category: "Road", id: "96" },
  //{ category: "Military Bases", id: "" },
];
export const DISTANCES = [
  0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 2, 3, 4, 5, 10, 20, 30, 40, 50, 100, 200, 300, 400, 500, 1000,
  2000, 5000, 10000,
];

function range(start, stop) {
  var result = [];
  for (var idx = start.charCodeAt(0), end = stop.charCodeAt(0); idx <= end; ++idx) {
    result.push(String.fromCharCode(idx));
  }
  return result;
}
export var SortByEnum = { NAME: 0, CATEGORY: 1, GROUP: 2, RATING: 3, DISTANCE: 4, ZONE: 5, LEVEL: 6, DATE: 7 };
var nums = [1, 2, 3, 4, 5, 6, 7, 8, 9];
export var labelChars = nums.concat(range("A", "Z"));
export var webSite = "http://liquidguides.com/";

global.HTMLContentName = "HTMLViewer";

export function MyHashTable(obj) {
  this.length = 0;
  this.items = {};
  for (var p in obj) {
    if (obj.hasOwnProperty(p)) {
      this.items[p] = obj[p];
      this.length++;
    }
  }

  this.setItem = function (key, value) {
    var previous = "undefined";
    if (this.hasItem(key)) {
      previous = this.items[key];
    } else {
      this.length++;
    }
    this.items[key] = value;
    return previous;
  };

  this.getItem = function (key) {
    return this.hasItem(key) ? this.items[key] : "undefined";
  };

  this.hasItem = function (key) {
    return this.items.hasOwnProperty(key);
  };

  this.removeItem = function (key) {
    if (this.hasItem(key)) {
      let previous = this.items[key];
      this.length--;
      delete this.items[key];
      return previous;
    } else {
      return "undefined";
    }
  };

  this.keys = function () {
    var keys = [];
    for (var k in this.items) {
      if (this.hasItem(k)) {
        keys.push(k);
      }
    }
    return keys;
  };

  this.values = function () {
    var values = [];
    for (var k in this.items) {
      if (this.hasItem(k)) {
        values.push(this.items[k]);
      }
    }
    return values;
  };

  this.each = function (fn) {
    for (var k in this.items) {
      if (this.hasItem(k)) {
        fn(k, this.items[k]);
      }
    }
  };

  this.clear = function () {
    this.items = {};
    this.length = 0;
  };
}
// function mapStateToProps(state) {
//   return {
//     Guide: state.theGuideReducer.Guide,
//     user_info: state.theGuideReducer.user_info,
//   };
// }
// function mapDispatchToProps(dispatch) {
//   return {};
// }
// export default connect(mapStateToProps, mapDispatchToProps)(Common);

export function isSearchResult(source) {
  var Place_Source = {
    GUIDE: 0,
    USER: 1,
    SEARCH: 2,
    ATTRACTION: 3,
    WIKIPEDIA: 4,
    YOUTUBE: 5,
    PANORAMIO: 6,
    OPENTRIPMAP: 7,
    GOOGLEPLACES: 8,
    FLICKR: 9,
  };
  if (source != null)
    return (
      source === Place_Source.OPENTRIPMAP ||
      source === Place_Source.GOOGLEPLACES ||
      source === Place_Source.FLICKR ||
      source === Place_Source.WIKIPEDIA ||
      source === Place_Source.ATTRACTION
    );
}
