import Place, { Place_Source } from "./Place";

export default class GoogleSearch {
  constructor() {
    this.places = [];
  }

  //https://www.googleapis.com/customsearch/v1?key=AIzaSyDKhZWRip7E2aNULD0xk-cBeGzMlzr8imQ&searchType=image&cx=14c575c2602954647&q=riva%20ligure&start=1

  static searchImages = (title, callback, place_id) => {
    const gsrsearch = title.replace(/ /g, "%20");
    var url = `https://www.googleapis.com/customsearch/v1?key=AIzaSyDKhZWRip7E2aNULD0xk-cBeGzMlzr8imQ&searchType=image&cx=14c575c2602954647&q=${gsrsearch}&start=1`;
    //const url = `https://andiamotravelapp.com/api/wikimedia_get_images.php?gsrsearch=${gsrsearch}`;
    fetch(url)
      .then(function (response) {
        return response.json();
      })
      .then(function (response) {
        //console.log("response", JSON.stringify(response));
        var images = GoogleSearch.processImages(response);
        callback(images, place_id);
      })

      .catch(function (error) {
        console.log("Error in Google search", error);
        callback([]);
      });
  };

  static processImages = (response) => {
    let images = [];
    if (typeof response.items === "undefined") return [];
    //if (typeof response.query.pages === "undefined") return [];
    for (let i = 0; i < response.items.length; i++) {
      const photo = response.items[i];
      images.push({
        name: photo.title,
        url: photo.image.thumbnailLink,
        url_m: photo.link,
        folder: "",
        id: "",
        date: "",
        type: 0,
      });
    }
    return images;
  };
}
