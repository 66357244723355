import React, { useState } from "react";
import "./ScheduleHeader.css";
import ScheduleModal from "../../modals/ScheduleModal";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { slice1Actions } from "../../../store/reducer";
import EventEdit from "../../modals/EventEdit";
import MenuModal from "../../modals/MenuModal";
import MoveEvents from "../../modals/MoveEvents";
import PromptModal from "../../modals/PromptModal";
import AlertModal from "../../modals/AlertModal";
import { DateTime, Settings } from "luxon";
import Appointment from "../../../Models/Event";
import MultiSelectModal from "../../modals/MultiSelectModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid, regular, brands, icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { urlSchemeToFunctions } from "../../../CommonWeb/Common";
import PlaceMenu from "../../../CommonWeb/PlaceMenu";

const ScheduleHeader = (props) => {
  const [scheduleModal, setScheduleModal] = useState(false);
  const [ShowEventEdit, setShowEventEdit] = useState(false);
  const [EventEditMode, setShowEventMode] = useState("MODIFY");
  const [EventEditWhere, setShowEventWhere] = useState("after");
  const [MenuModalShow, setMenuModalShow] = useState(false);
  const [MenuModalImage, setMenuModalImage] = useState(null);
  const [MenuModalList, setMenuModalList] = useState([]);
  const [MenuModalHeading, setMenuModalHeading] = useState("");
  const [MenuModalPlace, setMenuModalPlace] = useState(null);

  const [PromptModalShow, setPromptModalShow] = useState(false);
  const [PromptModalTitle, setPromptModalTitle] = useState("Enter Value");
  const [PromptModalMessage, setPromptModalMessage] = useState("");
  const [PromptModalButtons, setPromptModalButtons] = useState([]);
  const [PromptModalInputs, setPromptModalInputs] = useState([]);
  const [PromptModalCompact, setPromptModalCompact] = useState(null);

  const [AlertModalShow, setAlertModalShow] = useState(false);
  const [AlertModalTitle, setAlertModalTitle] = useState("");
  const [AlertModalMessage, setAlertModalMessage] = useState("");
  const [AlertModalButtons, setAlertModalButtons] = useState([]);

  const [Mode, setMode] = useState("move_day");
  const [ParamDate, setParamDate] = useState(null);
  const [MoveEventsShow, setMoveEventsShow] = useState(false);

  const [MultiselectItems, setMultiselectItems] = useState([]);
  const [MultiselectSelectedItems, setMultiselectSelectedItems] = useState([]);
  const [MultiselectMode, setMultiselectMode] = useState("uni");
  const [MultiselectTitle, setMultiselectTitle] = useState("Select");
  const [MultiselectSearchText, setMultiselectSearchText] = useState("");
  const [ShowMultiselect, setShowMultiselect] = useState(null);
  const [ScheduleAction, setScheduleAction] = useState("select");

  const [playEvents, setPlayEvents] = useState([]);
  const [playIndex, setPlayIndex] = useState(0);
  const [playState, setPlayState] = useState("Stopped");
  const [playTimer, setPlayTimer] = useState(null);

  const dispatch = useDispatch();
  let ScheduleName = useSelector((state) => state.ScheduleName);
  let ScheduleNames = useSelector((state) => state.ScheduleNames);
  let MobileMode = useSelector((state) => state.MobileMode);
  let GuideEditMode = useSelector((state) => state.GuideEditMode);
  let GuideSettings = useSelector((state) => state.GuideSettings);
  let EventSearchText = useSelector((state) => state.EventSearchText);
  let HideSchedule = useSelector((state) => state.HideSchedule);

  let SearchResultsPlacesList = useSelector((state) => state.SearchResultsPlacesList);
  let PlayEventsIndex = useSelector((state) => state.PlayEventsIndex);
  let schedule = useSelector((state) => state.schedule);
  let user_info = useSelector((state) => state.user_info);

  const handleSearChanged = (event) => {
    //console.log(event.target.value);
    dispatch(slice1Actions.setEventSearchText({ value: event.target.value }));
    dispatch(slice1Actions.setGuide({ guide: null }));
  };

  function handleAddEventA() {
    setShowEventMode("ADD");
    setShowEventWhere("end");
    setShowEventEdit(true);
  }

  const scheduleNameClicked = (event) => {
    if (GuideSettings.mode !== "custom_view" || event.detail === 2) {
      scheduleNameClickedMenu();
      return;
    }
    if (GuideSettings.schedule.menu === true) scheduleNameClickedMenu();
    if (GuideSettings.schedule.itinerary === true) {
      const htmlString = window.guide_obj.createHTMLItinerary(ScheduleName, GuideSettings.itinerary);
      dispatch(slice1Actions.setHTMLTitle({ value: "Itinerary" }));
      dispatch(slice1Actions.setHTMLToSHow({ value: htmlString }));
      dispatch(slice1Actions.setShowHTMLReader({ value: true }));
      window.location.href = "#home-start";
      var homeDiv = document.getElementById("home-content-start");
      if (MobileMode) dispatch(slice1Actions.setCurrentAppScreen({ value: "Home" }));
      else homeDiv.scrollIntoView();
    }
    if (GuideSettings.schedule.center === true) {
      const AllScheduledPlaces = window.guide_obj.getAllScheduledPlaces(ScheduleName);
      if (AllScheduledPlaces.length !== 0) {
        dispatch(slice1Actions.ClearAllFilters());
        dispatch(slice1Actions.centerMapToPlaces({ value: AllScheduledPlaces }));
        if (MobileMode) dispatch(slice1Actions.setCurrentAppScreen({ value: "Map" }));
        else window.location.href = "#map-start";
      }
      dispatch(slice1Actions.setGuide({ guide: null, fitToBounds: true }));
    }
    if (GuideSettings.schedule.filter === true) {
      filterBySchedule(ScheduleName);
      // const AllScheduledPlaces = window.guide_obj.getAllScheduledPlaces(ScheduleName);
      // let hiddenPlacesToShow = [];
      // AllScheduledPlaces.forEach((p) => {
      //   if (p.Hidden === true) hiddenPlacesToShow.push(p);
      // });
      // dispatch(slice1Actions.ClearAllFilters());
      // dispatch(slice1Actions.setShowHiddenPlace({ value: hiddenPlacesToShow }));
      // dispatch(slice1Actions.SetPlacesFilter({ value: AllScheduledPlaces }));
      dispatch(slice1Actions.setGuide({ guide: null, fitToBounds: true }));
      window.location.href = "#map-start";
    }
  };

  const filterBySchedule = (_ScheduleName) => {
    dispatch(slice1Actions.ClearAllFilters());
    dispatch(slice1Actions.SetSchedulesFilter({ value: [{ label: _ScheduleName }] }));
    //dispatch(slice1Actions.setGuide({ guide: null, fitToBounds: true }));
    //window.location.href = "#map-start";
  };

  const scheduleNameClickedMenu = () => {
    let menuList = [
      {
        label: "Choose Schedule",
        method: () => handleChooseSchedule("select"),
        icon: null,
        visible: ScheduleNames.length > 1,
        info: "Choose a different schedule to display. A guide can have multiple schedules, e.g. 'Paris in One Day', 'Paris in Two Days', etc.",
      },
      {
        label: "Delete Schedule",
        method: () => handleChooseSchedule("delete"), //handleDeleteSchedule,
        icon: null,
        visible: true, //GuideEditMode && ScheduleNames.length > 1,
        info: "Delete the current schedule",
      },
      {
        label: "Add New Schedule",
        method: handleAddSchedule,
        icon: null,
        visible: GuideEditMode,
        info: "Add a new schedule",
      },
    ];

    //({ value: _MenuModalArgs }));
    setMenuModalList(menuList);
    setMenuModalHeading("Schedule Menu");
    setMenuModalImage(<i className="fa fa-user"></i>);
    setMenuModalPlace(null);
    setMenuModalShow(true);
  };

  const scheduleMenu = () => {
    let menuList = [
      {
        label: "Show Itinerary",
        method: () => handleShowItinerary(ScheduleName),
        icon: null,
        visible: true,
        info: "Show the selected itinerary in document format (HTML).",
      },
      {
        label: "Change Start Date",
        method: handleChangeStartDay,
        icon: null,
        visible: GuideEditMode,
        info: "Changes the starting date/time of the entire schedule, i.e. all events for all days.",
      },
      {
        label: "Add Events to Schedule",
        method: () => handleChooseSchedule("copy"), //handleCopySchedule,
        icon: null,
        visible: GuideEditMode && ScheduleNames.length > 1,
        info: "Add all events from this schedule to another",
      },
      {
        label: "Add Events from Schedule(s)",
        method: () => handleChooseSchedule("copyFrom"), //handleCopySchedule,
        icon: null,
        visible: GuideEditMode && ScheduleNames.length > 1,
        info: "Add all events from other schedule(s) to this one",
      },
      {
        label: "Rename Schedule",
        method: handleRenameSchedule,
        icon: null,
        visible: GuideEditMode,
        info: "Rename the current schedule",
      },
      {
        label: "Recalculate Schedule",
        method: () => recalculateSchedule(false),
        icon: null,
        visible: GuideEditMode,
        info: "Recalculate the current schedule based on duration and travel time.",
      },
      {
        label: "Optimize Schedule by location",
        method: () => recalculateSchedule(true),
        icon: null,
        visible: GuideEditMode,
        info: "Optimize the current schedule based on geographical location, duration and travel time.",
      },
    ];

    //({ value: _MenuModalArgs }));
    setMenuModalList(menuList);
    setMenuModalHeading("Schedule Menu");
    setMenuModalImage(<i className="fa fa-user"></i>);
    setMenuModalPlace(null);
    setMenuModalShow(true);
  };

  function handleShowItinerary(_ScheduleName) {
    const htmlString = window.guide_obj.createHTMLItinerary(_ScheduleName, GuideSettings.itinerary);
    dispatch(slice1Actions.setHTMLTitle({ value: "Itinerary" }));
    dispatch(slice1Actions.setHTMLToSHow({ value: htmlString }));
    dispatch(slice1Actions.setShowHTMLReader({ value: true }));
    window.location.href = "#home-start";
    var homeDiv = document.getElementById("home-content-start");
    if (MobileMode) dispatch(slice1Actions.setCurrentAppScreen({ value: "Home" }));
    else homeDiv.scrollIntoView();
  }

  function _handleShowItinerary(e, _ScheduleName) {
    e.stopPropagation();
    handleShowItinerary(_ScheduleName);
  }

  const handleChooseSchedule = (action) => {
    setScheduleAction(action);
    let items = ScheduleNames;
    // if (action === "select" || action === "copy") {
    //   items = ScheduleNames.filter((x) => x.label !== ScheduleName);
    // }
    // if (action === "delete") {
    //   items = ScheduleNames.filter((x) => x.label !== "Main");
    // }
    setMultiselectItems(items);
    setMultiselectSelectedItems(ScheduleName);
    setMultiselectMode(action === "copyFrom" ? "multi" : "uni");
    setMultiselectTitle(`Select Itinerary`);
    setShowMultiselect(true);
  };

  const chooseScheduleCallBack = (schedules) => {
    if (ScheduleAction === "copyFrom") {
      for (let i = 0; i < schedules.length; i++) {
        if (ScheduleName === schedules[i].label) continue;
        window.guide_obj.schedule.copySchedule(schedules[i].label, ScheduleName);
      }
      dispatch(slice1Actions.setGuide({ guide: null }));
    } else if (ScheduleAction === "select") {
      if (schedules.length > 0) {
        dispatch(slice1Actions.setScheduleName({ value: schedules[0].label }));
        filterBySchedule(schedules[0].label);
        handleShowItinerary(schedules[0].label);
        dispatch(slice1Actions.setGuide({ guide: null, fitToBounds: true }));
      }
    } else if (ScheduleAction === "delete") {
      handleDeleteSchedule(schedules[0].label);
    } else if (ScheduleAction === "copy") {
      if (ScheduleName === schedules[0].label) {
        MyAlert("You cannot copy to the same schedule.");
        return;
      }
      window.guide_obj.schedule.copySchedule(ScheduleName, schedules[0].label);
      dispatch(slice1Actions.setScheduleName({ value: schedules[0].label }));
      dispatch(slice1Actions.setGuide({ guide: null }));
    }
  };

  const handleDeleteSchedule = (schedule) => {
    // if (schedule === "Main") {
    //   MyAlert("You cannot delete the 'Main' schedule.");
    //   return;
    // }
    myAlert("Confirmation", "Confirm Delete schedule?", [
      { Title: "OK", Method: () => _deleteSchedule(schedule) },
      { Title: "CANCEL", Method: null },
    ]);
  };

  const _scheduleNameClicked = (e, shed) => {
    e.stopPropagation();
    scheduleNameClicked(shed);
  };

  const _deleteSchedule = (schedule) => {
    window.guide_obj.schedule.deleteAllEvents(schedule);
    dispatch(slice1Actions.setScheduleName({ value: "Main" }));
    dispatch(slice1Actions.setGuide({ guide: null }));
  };

  const handleChangeStartDay = () => {
    setMode("change_start_date");
    setMoveEventsShow(true);
  };

  const handleCopyScheduleOLD = () => {
    setPromptModalShow(true);
    setPromptModalTitle("Copy");
    setPromptModalMessage("Copy events to Schedule:");
    setPromptModalButtons([
      { Title: "OK", Method: CopyScheduleCallback },
      { Title: "CANCEL", Method: null },
    ]);
    setPromptModalInputs([{ name: "Group Name", value: ScheduleName }]);
  };

  const CopyScheduleCallbackOLD = (sched_name) => {
    console.log("sched_name", sched_name);
    window.guide_obj.schedule.copySchedule(ScheduleName, sched_name[0].value);
    dispatch(slice1Actions.setScheduleName({ value: sched_name[0].value }));
    dispatch(slice1Actions.setGuide({ guide: null }));
  };

  const handleCopySchedule = (schedule) => {
    // if (ScheduleName === "Main") {
    //   MyAlert("You cannot delete the 'Main' schedule.");
    //   return;
    // }
    myAlert("Confirmation", "Confirm Delete schedule?", [
      { Title: "OK", Method: () => _deleteSchedule(schedule) },
      { Title: "CANCEL", Method: null },
    ]);
  };

  const CopyScheduleCallback = (schedule) => {
    window.guide_obj.schedule.deleteAllEvents(schedule);
    dispatch(slice1Actions.setScheduleName({ value: "Main" }));
    dispatch(slice1Actions.setGuide({ guide: null }));
  };

  const handleAddSchedule = () => {
    setPromptModalShow(true);
    setPromptModalTitle("Add");
    setPromptModalMessage("Add New Schedule:");
    setPromptModalButtons([
      { Title: "OK", Method: AddScheduleCallback },
      { Title: "CANCEL", Method: null },
    ]);
    setPromptModalInputs([{ name: "Group Name", value: ScheduleName + "2" }]);
  };

  const AddScheduleCallback = (sched_name) => {
    console.log("sched_name", sched_name);
    if (sched_name[0].value === "Main") {
      MyAlert("Cannot create another Main menu. Choose a different name.");
      return;
    }
    let new_event = new Appointment();
    new_event.Subject = "New Event";
    new_event.Start = DateTime.now().toISO({ zone: "utc" });
    new_event.End = DateTime.now().toISO({ zone: "utc" });
    new_event.Body = "";
    new_event.Itinerary = sched_name[0].value;
    window.guide_obj.schedule.addEvent(new_event);
    dispatch(slice1Actions.setScheduleName({ value: sched_name[0].value }));
    dispatch(slice1Actions.setGuide({ guide: null }));
  };

  const handleRenameSchedule = () => {
    if (ScheduleName === "Main") {
      MyAlert("You cannot rename the 'Main' schedule, but you can create a copy.");
      return;
    }
    setPromptModalShow(true);
    setPromptModalTitle("Rename");
    setPromptModalMessage("Rename Schedule");
    setPromptModalButtons([
      { Title: "OK", Method: RenameScheduleCallback },
      { Title: "CANCEL", Method: null },
    ]);
    setPromptModalInputs([{ name: "Group Name", value: ScheduleName }]);
  };

  const handleReorderSchedule = () => {
    setPromptModalShow(true);
    setPromptModalTitle("Rename");
    setPromptModalMessage("Rename Schedule");
    setPromptModalButtons([
      { Title: "OK", Method: RenameScheduleCallback },
      { Title: "CANCEL", Method: null },
    ]);
    setPromptModalInputs([{ name: "Group Name", value: ScheduleName }]);
  };

  const RenameScheduleCallback = (sched_name) => {
    console.log("sched_name", sched_name);
    window.guide_obj.schedule.renameSchedule(ScheduleName, sched_name[0].value, window.guide_obj);
    dispatch(slice1Actions.setScheduleName({ value: sched_name[0].value }));
    dispatch(slice1Actions.setGuide({ guide: null }));
  };

  const MoveEventsCallback = () => {
    dispatch(slice1Actions.setGuide({ guide: null }));
  };

  const MyAlert = (message) => {
    setAlertModalTitle("Message");
    setAlertModalMessage(message);
    setAlertModalButtons([{ Title: "OK", Method: null }]);
    setAlertModalShow(true);
  };

  const myAlert = (title, message, buttons) => {
    setAlertModalTitle(title);
    setAlertModalMessage(message);
    setAlertModalButtons(buttons);
    setAlertModalShow(true);
  };

  const EventEditCallback = () => {
    dispatch(slice1Actions.resetHomeScreen({ value: null }));
    dispatch(slice1Actions.setGuide({ guide: null }));
  };

  // const showSchedules = (e) => {
  //   e.stopPropagation();
  //   dispatch(slice1Actions.setMultiselectCallback({ value: chooseScheduleCallBack2 }));
  //   dispatch(slice1Actions.setMultiselectItems({ value: ScheduleNames }));
  //   dispatch(slice1Actions.setMultiselectSelectedItems({ value: ScheduleName }));
  //   dispatch(slice1Actions.setMultiselectMode({ value: "uni" }));
  //   dispatch(slice1Actions.setMultiselectSearchText({ value: "" }));
  //   dispatch(slice1Actions.setMultiselectTitle({ value: "Select Schedule" }));
  //   dispatch(slice1Actions.setShowMultiselect({ value: true }));
  // };

  // const chooseScheduleCallBack2 = (schedules) => {
  //   if (schedules.length > 0) {
  //     dispatch(slice1Actions.setScheduleName({ value: schedules[0].label }));
  //     dispatch(slice1Actions.setGuide({ guide: null }));
  //   }
  // };

  const startPlayingItinerary = () => {
    let events = window.guide_obj.schedule.getEventsFromSchedule(ScheduleName);
    if (events.length === 0) return;
    if (playState === "Stopped") setPlayIndex(0);
    setPlayEvents(events);
    setPlayState("Playing");
    setPlayTimer(setInterval(playEvent, 3000));
    playEvent();
  };

  const stopPlayingItinerary = () => {
    setPlayEvents([]);
    dispatch(slice1Actions.setPlayEventsIndex({ value: 0 }));
    setPlayState("Stopped");
    setPlayIndex(0);
    clearInterval(playTimer);
    unHighlightAll();
  };

  const unHighlightAll = () => {
    const places = window.guide_obj.places.placesList;
    for (let j = 0; j < places.length; j++) {
      var placeElement = document.getElementById(`PlaceCard${places[j].ID}`);
      if (placeElement !== null) {
        placeElement.style.backgroundColor = "#ffffff";
      }
      let eventElement = document.getElementById(`eventID${places[j].ID}`);
      if (eventElement !== null) {
        eventElement.style.backgroundColor = "#ffffff";
      }
    }
    dispatch(slice1Actions.setHighlightedPlaces({ value: [] }));
  };

  const pausePlayingItinerary = () => {
    setPlayState("Paused");
    clearInterval(playTimer);
  };

  const playEvent = () => {
    let events = window.guide_obj.schedule.getEventsFromSchedule(ScheduleName);
    // if (events.length === 0) {
    //   stopPlayingItinerary();
    //   return;
    // }
    // if (events.length !== playEvents.length) {
    //   setPlayEvents(events);
    //   setPlayIndex(0);
    // }
    // unhighlight last event places
    let last_playIndex = PlayEventsIndex === 0 ? events.length - 1 : PlayEventsIndex - 1;
    if (typeof events[last_playIndex] === "undefined") {
      stopPlayingItinerary();
      return;
    }
    let places = events[last_playIndex].Places;
    if (!Array.isArray(places)) places = places.split(",");
    dispatch(slice1Actions.setHighlightedPlaces({ value: [] }));
    if (places.length !== 0) highlightPlace(places[0], false);

    if (PlayEventsIndex >= events.length) {
      //console.log("events.length !== playEvents.length");
      stopPlayingItinerary();
      return;
    }
    // highlight current event places
    places = events[PlayEventsIndex].Places;
    if (!Array.isArray(places)) places = places.split(",");
    dispatch(slice1Actions.setHighlightedPlaces({ value: places }));
    if (places.length !== 0) highlightPlace(places[0], true);

    // set next event index
    //let next_playIndex = PlayEventsIndex >= events.length - 1 ? 0 : PlayEventsIndex + 1;
    dispatch(slice1Actions.setPlayEventsIndex({ value: PlayEventsIndex++ }));
  };

  const recalculateSchedule = (geography) => {
    window.guide_obj.schedule.recalculateSchedule(
      ScheduleName,
      user_info.event_time_rounding,
      user_info.default_duration,
      window.guide_obj.places,
      geography
    );
    dispatch(slice1Actions.setGuide({ guide: null }));
  };

  const highlightPlace = (placeID, enter) => {
    const place = window.guide_obj.places.getPlace(placeID);
    if (place === null) return;
    const placeElement = document.getElementById(`PlaceCard${placeID}`);
    const eventElement = document.getElementById(`eventID${placeID}`);
    if (enter === true) {
      if (eventElement !== null) {
        eventElement.scrollIntoView();
        eventElement.style.backgroundColor = "yellow";
      }
      if (placeElement !== null) {
        placeElement.scrollIntoView();
        placeElement.style.backgroundColor = "yellow";
      }
      let htmlString = PlaceMenu.handleShowPlaceAttributes(place.ID, SearchResultsPlacesList);
      htmlString = urlSchemeToFunctions(htmlString);
      dispatch(slice1Actions.setHTMLTitle({ value: "Place Attributes" }));
      dispatch(slice1Actions.setHTMLToSHow({ value: htmlString }));
      dispatch(slice1Actions.setShowHTMLReader({ value: true }));
      window.location.href = "#home-start";
      let homeDiv = document.getElementById("home-content-start");
      if (MobileMode) dispatch(slice1Actions.setCurrentAppScreen({ value: "Home" }));
      else if (homeDiv !== null) {
        dispatch(slice1Actions.centerMapToPlaces({ value: [place] }));
        homeDiv.scrollIntoView();
      }
    } else {
      dispatch(slice1Actions.setHighlightedPlaces({ value: [] }));
      if (placeElement !== null) {
        placeElement.style.backgroundColor = "#ffffff";
      }
      if (eventElement !== null) {
        eventElement.style.backgroundColor = "#ffffff";
      }
    }
  };

  if (GuideSettings.hide_schedule === true || HideSchedule === true) return null;
  // if (ShowMultiselect)
  //   return (
  //     <MultiSelectModal
  //       show={ShowMultiselect}
  //       onHide={() => setShowMultiselect(false)}
  //       MultiselectItems={MultiselectItems}
  //       MultiselectSelectedItems={MultiselectSelectedItems}
  //       MultiselectMode={MultiselectMode}
  //       MultiselectCallback={chooseScheduleCallBack}
  //       MultiselectTitle={MultiselectTitle}
  //       MultiselectSearchText={MultiselectSearchText}
  //     />
  //   );
  // else
  return (
    <div style={{ marginLeft: 10 }}>
      <div className="schedule-header">
        <input
          type="text"
          value={EventSearchText}
          placeholder="Filter"
          onChange={(event) => {
            //dispatch(slice1Actions.setEventSearchText({ value: event.target.value }));
            handleSearChanged(event);
          }}
        />
        <div
          style={{
            display: "flex",
            // width: "100%",
            // margin: "0px",
            justifyContent: "space-between",
            alignItems: "center",
            fontSize: "1rem",
          }}
        >
          {GuideEditMode ? <i className="fa fa-plus" onClick={() => handleAddEventA()}></i> : null}

          <span style={{ cursor: "default" }}>&nbsp;&nbsp;&nbsp;&nbsp;</span>
          <i className="fa fa-bars" onClick={(e) => scheduleNameClickedMenu()}></i>
          <span style={{ cursor: "default" }}>&nbsp;&nbsp;</span>
        </div>
        {/* <i className="fas fa-filter"></i> */}
      </div>
      <span className="color-blue cursor-pointer" onClick={(e) => scheduleNameClicked(e)}>
        {ScheduleName}
        <span style={{ cursor: "default" }}>&nbsp;&nbsp;&nbsp;</span>
        {/* <i title="Menu" className="fa fa-ellipsis-h color-blue" onClick={(e) => scheduleNameClickedMenu()}></i>
        <span>&nbsp;&nbsp;&nbsp;&nbsp;</span> */}
      </span>
      {ScheduleNames.length > 1 ? (
        <i
          title="Schedules"
          className="far fa-calendar-alt color-blue cursor-pointer"
          onClick={(e) => handleChooseSchedule("select")}
        ></i>
      ) : null}
      <span style={{ cursor: "default" }}>&nbsp;&nbsp;&nbsp;&nbsp;</span>
      <FontAwesomeIcon
        icon={solid("square-caret-down")}
        className="color-blue cursor-pointer"
        style={{ cursor: "pointer" }}
        onClick={(e) => scheduleMenu()}
      ></FontAwesomeIcon>
      <span style={{ cursor: "default" }}>&nbsp;&nbsp;&nbsp;</span>
      {(playState === "Stopped" || playState === "Paused") && schedule.length && !MobileMode > 0 ? (
        <FontAwesomeIcon
          icon={solid("play")}
          className="color-blue cursor-pointer"
          onClick={(e) => startPlayingItinerary()}
        ></FontAwesomeIcon>
      ) : null}
      {playState === "Playing" && schedule.length > 0 && !MobileMode ? (
        <FontAwesomeIcon
          icon={solid("pause")}
          className="color-blue cursor-pointer"
          onClick={(e) => pausePlayingItinerary()}
        ></FontAwesomeIcon>
      ) : null}
      {/* {playState === "Paused" ? (
        <FontAwesomeIcon
          icon={solid("play")}
          className="color-blue cursor-pointer"
          onClick={(e) => pausePlayingItinerary()}
        ></FontAwesomeIcon>
      ) : null} */}
      <span style={{ cursor: "default" }}>&nbsp;&nbsp;&nbsp;</span>
      {(playState === "Playing" || playState === "Paused") && schedule.length > 0 && !MobileMode ? (
        <FontAwesomeIcon
          icon={solid("stop")}
          className="color-blue cursor-pointer"
          onClick={(e) => stopPlayingItinerary()}
        ></FontAwesomeIcon>
      ) : null}
      <ScheduleModal show={scheduleModal} onHide={() => setScheduleModal(false)} />
      {MenuModalShow ? (
        <MenuModal
          id="MenuModal"
          show={MenuModalShow}
          onHide={() => setMenuModalShow(false)}
          menu_modal_list={MenuModalList}
          menu_modal_heading={MenuModalHeading}
          menu_model_image={MenuModalImage}
          menu_modal_place={MenuModalPlace}
        />
      ) : null}
      {ShowEventEdit ? (
        <EventEdit
          show={ShowEventEdit}
          onHide={() => setShowEventEdit(false)}
          event={null}
          mode={EventEditMode}
          place={null}
          where={EventEditWhere}
          schedule_name={ScheduleName}
          callback={() => EventEditCallback()}
        />
      ) : null}
      {MoveEventsShow ? (
        <MoveEvents
          show={MoveEventsShow}
          onHide={() => setMoveEventsShow(false)}
          ScheduleName={ScheduleName}
          ScheduleNames={ScheduleNames}
          mode={Mode}
          param_date={ParamDate}
          callback={MoveEventsCallback}
        />
      ) : null}
      {PromptModalShow ? (
        <PromptModal
          show={PromptModalShow}
          onHide={() => setPromptModalShow(false)}
          PromptModalTitle={PromptModalTitle}
          PromptModalMessage={PromptModalMessage}
          PromptModalButtons={PromptModalButtons}
          PromptModalInputs={PromptModalInputs}
        />
      ) : null}
      {AlertModalShow ? (
        <AlertModal
          show={AlertModalShow}
          onHide={() => setAlertModalShow(false)}
          AlertModalTitle={AlertModalTitle}
          AlertModalMessage={AlertModalMessage}
          AlertModalButtons={AlertModalButtons}
        />
      ) : null}
      {ShowMultiselect ? (
        <MultiSelectModal
          show={ShowMultiselect}
          onHide={() => setShowMultiselect(false)}
          MultiselectItems={MultiselectItems}
          MultiselectSelectedItems={MultiselectSelectedItems}
          MultiselectMode={MultiselectMode}
          MultiselectCallback={chooseScheduleCallBack}
          MultiselectTitle={MultiselectTitle}
          MultiselectSearchText={MultiselectSearchText}
        />
      ) : null}
    </div>
  );
};

export default ScheduleHeader;
