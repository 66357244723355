import React from "react";
import { Modal, Button } from "react-bootstrap";
import "./Modal.css";
import { useDispatch } from "react-redux";
import { slice1Actions } from "../../store/reducer";
import { useSelector } from "react-redux";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

const PDFModal = (props) => {
  const dispatch = useDispatch();

  const handleButtonClick = (button) => {
    if (button.Method != null) button.Method(button.Title);
    onHide();
  };

  const SavePDF = () => {
    //     let w=window.open();
    // w.document.write(document.getElementById('PDFDiv').innerH‌​TML);
    // w.print();
    // w.close();
    var divContents = document.getElementById("PDFDiv").innerHTML;
    var printWindow = window.open("", "", "height=400,width=800");
    printWindow.document.write("<html><head><title>Html to PDF</title>");
    printWindow.document.write("</head><body >");
    printWindow.document.write(divContents);
    printWindow.document.write("</body></html>");
    printWindow.document.close();
    printWindow.print();
  };

  const SavePDFxx = () => {
    const input = document.getElementById("PDFDiv");
    html2canvas(input, {
      scrollY: -window.scrollY,
      scale: 0.8,
      useCORS: true,
      etterRendering: 1,
      allowTaint: true,
      onrendered: SavePDFComplete,
    }).then((canvas) => {
      var imgWidth = 200;
      var pageHeight = 2900;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      var heightLeft = imgHeight;
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      var position = 0;
      var heightLeft = imgHeight;
      pdf.addImage(imgData, "JPEG", 0, position, imgWidth, imgHeight);
      pdf.save("Guide.pdf");
    });
  };

  const SavePDFComplete = () => {
    //myAlert("PDF processed", "Message");
  };

  let AlertModalTitle = props.AlertModalTitle;
  let AlertModalMessage = props.AlertModalMessage;
  let onHide = props.onHide;
  let AlertModalButtons = props.AlertModalButtons;

  if (typeof props.attributes != "undefined") {
    AlertModalTitle = props.attributes.AlertModalTitle;
    AlertModalMessage = props.attributes.AlertModalMessage;
    onHide = props.attributes.onHide;
    AlertModalButtons = props.attributes.AlertModalButtons;
  }
  const closeModal = () => {
    onHide();
  };

  return (
    <div className="modal">
      <Modal
        {...props}
        animation={false}
        dialogClassName="myModalClass"
        aria-labelledby="contained-modal-title-vcenter"
        //centered
        size="lg"
        onHide={() => closeModal()}
      >
        <Modal.Header>
          <div style={{ width: "100%" }}>
            <div className="sort">
              <div className="dialog-heading">
                <div className="dialog-heading  color-blue">
                  <i className="fas fa-check" onClick={() => SavePDF()}></i>
                </div>
              </div>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div style={{ padding: "50px" }} dangerouslySetInnerHTML={{ __html: props.htmlString }} id="PDFDiv"></div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default PDFModal;
