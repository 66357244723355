import React from "react";
import { Modal } from "react-bootstrap";
import "./Modal.css";
//import InnerHTML from "dangerously-set-html-content";
import ReactHtmlParser from "react-html-parser";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { slice1Actions } from "../../store/reducer";
import InnerHTML from "dangerously-set-html-content";

const HTMLReader = (props) => {
  //console.log("HTMLReader", props);
  const dispatch = useDispatch();

  // let HTMLToSHow = useSelector((state) => state.HTMLToSHow);
  // let HTMLStack = useSelector((state) => state.HTMLStack);
  // let HTMLTitle = useSelector((state) => state.HTMLTitle);
  //console.log("HTMLReader", HTMLStack.length);
  // const handleLeftArrow = () => {
  //console.log("handleLeftArrow", HTMLStack.length);
  //   if (HTMLStack.length <= 1) {
  //     dispatch(slice1Actions.clearHTMLStack());
  //     dispatch(slice1Actions.setShowHTMLReader({ value: false }));
  //     return;
  //   }
  //   const htmlString = HTMLStack[HTMLStack.length - 2];
  //   dispatch(slice1Actions.popHTMLStack());
  //   dispatch(slice1Actions.setHTMLToSHow({ value: htmlString }));
  //   dispatch(slice1Actions.setShowHTMLReader({ value: true }));
  // };

  // const handleClose = () => {
  //   //console.log("handleClose", HTMLStack.length);
  //   dispatch(slice1Actions.setHTMLTitle({ value: "Content" }));
  //   dispatch(slice1Actions.clearHTMLStack());
  //   dispatch(slice1Actions.setShowHTMLReader({ value: false }));
  // };
  //console.log("HTMLReader", HTMLToSHow);
  return (
    <div className="html-reader-modal">
      <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" onHide={props.onHide}>
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">Andiamo Places</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <div>
            <i className={"fa fa-arrow-left"} onClick={handleLeftArrow}></i>
          </div> */}
          {/* <div dangerouslySetInnerHTML={{ __html: props.html }} className="home-resize-panel html-reader"> */}
          <InnerHTML
            className="home-resize-panel"
            id="home-content"
            // style={{ fontSize: `${ContentFontZoom}%` }}
            html={props.html}
          />
          {/* {ReactHtmlParser(HTMLToSHow)} */}
          {/* <InnerHTML html={props.html} /> */}
          {/* <div dangerouslySetInnerHTML={{ __html: props.html }}></div> */}
          {/* <p>{props.html}</p> */}
          {/* </div> */}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default HTMLReader;
