import React, { useState } from "react";
import LoginModal from "../modals/LoginModal";
import AiDialog from "../modals/AiDialog";
import TripAI from "../modals/TripAI";
import AlertModal from "../modals/AlertModal";
// import GuideInfo from "../modals/GuideInfoXXX";
import "./VerticalSidebar.css";
import Common, { Settings, SortByEnum, AUTHORIZATION_LEVEL, COLORS } from "../../Common/Common";
import { useDispatch } from "react-redux";
import { slice1Actions } from "../../store/reducer";
import { useSelector } from "react-redux";
import Guide, { GuideAttributes } from "../../Models/Guide";
import DataBase from "../../dataBase/liquidGuides";
import GuideSettingsDialog from "../modals/GuideSettingsDialog";
import GuideEditModal from "../modals/GuideEditModal";
import ImageDialog from "../modals/ImageDialog";

import { DateTime } from "luxon";

const VerticalSidebar = () => {
  const [ShowLoginModal, setShowLoginModal] = useState(false);
  const [LoginModalMode, setLoginModalMode] = useState("Login");
  const [guideInfoModal, setGuideInfoModal] = useState(false);
  const [AiDialogShow, setAiDialogShow] = useState(null);
  const [TripAIShow, setTripAIShow] = useState(null);

  const [AlertModalShow, setAlertModalShow] = useState(false);
  const [AlertModalTitle, setAlertModalTitle] = useState("");
  const [AlertModalMessage, setAlertModalMessage] = useState("");
  const [AlertModalButtons, setAlertModalButtons] = useState([]);
  const [GuideSettingsDialogShow, setGuideSettingsDialogShow] = useState(false);
  const [GuideEditModalShow, setGuideEditModalShow] = useState(false);
  const [GuideEditModalMode, setGuideEditModalMode] = useState("VIEW");
  const [ImageDialogShow, setImageDialogShow] = useState(false);

  const dispatch = useDispatch();
  let GuidesOrPlaces = useSelector((state) => state.GuidesOrPlaces);
  let AppSettings = useSelector((state) => state.AppSettings);
  let Authorization = useSelector((state) => state.Authorization);
  let user_info = useSelector((state) => state.user_info);
  let guide_meta = useSelector((state) => state.guide_meta);
  let GuideStack = useSelector((state) => state.GuideStack);
  let ScheduleName = useSelector((state) => state.ScheduleName);
  let ScheduleNames = useSelector((state) => state.ScheduleNames);

  const ShowGuidesOrGuide = (guides_or_places, GuideType) => {
    let temp = guides_or_places === "Guides" ? "Places" : "Guides";
    if (guides_or_places === GuidesOrPlaces) {
      ShowGuidesOrGuideTimeout(temp, GuideType);
      setTimeout(() => ShowGuidesOrGuideTimeout(guides_or_places, GuideType), 100);
    } else ShowGuidesOrGuideTimeout(guides_or_places, GuideType);
  };
  const ShowGuidesOrGuideTimeout = (guides_or_places, GuideType) => {
    let Hide = guides_or_places === "Guides" ? true : false;
    dispatch(slice1Actions.setGuidesOrPlaces({ value: guides_or_places, GuideType: GuideType }));
    //window.GuidesOrPlaces = newValue;
    dispatch(slice1Actions.setHideSchedule({ value: Hide }));
    if (guides_or_places === "Guides") {
      dispatch(slice1Actions.setHideMap({ value: false }));
      dispatch(slice1Actions.setHidePlaces({ value: false }));
      ShowSelectGuide();
    } else {
      dispatch(slice1Actions.resetHomeScreen({ value: null }));
    }
    dispatch(slice1Actions.setGuide({ guide: null, fitToBounds: true }));
    //setTimeout(updateMap, 1000);
  };

  const ShowSelectGuide = () => {
    console.log("ShowSelectGuide");
    let htmlString = `<div>
    <div style="text-align: center;"><br /><strong><span style="font-size: 220%;">Select guide from List or from map</span></strong></div>
    </div>`;
    window.location.href = "#home-start";
    var homeDiv = document.getElementById("home-content-start");
    homeDiv.scrollIntoView();
    dispatch(slice1Actions.setHTMLTitle({ value: "Featured Guides" }));
    dispatch(slice1Actions.setHTMLToSHow({ value: htmlString }));
    dispatch(slice1Actions.setShowHTMLReader({ value: true }));
    window.location.href = "#home-start";
    var homeDiv = document.getElementById("home-content-start");
    homeDiv.scrollIntoView();
  };

  const newGuide = () => {
    if (Authorization === AUTHORIZATION_LEVEL.GUEST) {
      myAlert("Cannot create new guides in guest mode. You must be registered and logged in", "Message");
      return;
    }
    myConfirm("This will create a new guide. Save any wanted changes to this guide first. Continue?", [
      { Title: "OK", Method: () => _newGuide() },
      { Title: "CANCEL", Method: null },
    ]);
  };

  const _newGuide = () => {
    dispatch(slice1Actions.SetLoadingGuide({ value: true }));
    setTimeout(timeoutFunc, 5000);
    let new_guide_attributes = new GuideAttributes("New Guide", "", null, "English", 0, [], []);
    newGuideCallback(new_guide_attributes);
    dispatch(slice1Actions.setHideSchedule({ value: false }));
  };

  let _attributes = null;
  const newGuideCallback = (guideAttributes) => {
    _attributes = guideAttributes;
    let GuideObj = new Guide("new");
    GuideObj.decodeGuideFb(GuideObj, _attributes);
    GuideObj.user_id = user_info.uid;
    DataBase.batchedWriteGuide(GuideObj, null, null, user_info, "new", addGuideDataCallbackNewGuide);
  };

  const addGuideDataCallbackNewGuide = (res) => {
    if (res.pass !== true) {
      dispatch(slice1Actions.SetLoadingGuide({ value: false }));
      return;
    }
    window.record_last_guide_read = true;
    DataBase.readGuideTransaction(res.new_info_ref.id, readGuideDataReturnNew, user_info.uid);
  };

  const readGuideDataReturnNew = (res) => {
    let GuideObj = null;
    if (res.pass === true) {
      GuideObj = new Guide(res.guide_id);
      const guide_json = res.res.data;
      console.log(`Guide Size is: ${guide_json.length}`);
      var guide_obj = JSON.parse(guide_json);
      const decoded = GuideObj.decodeGuideFb(guide_obj, res.infoDoc);
      if (decoded === true) {
        GuideObj.schedule.getPlaces_InSchedule();
        window.guide_obj = GuideObj;
        dispatch(
          slice1Actions.setGuide({
            guide: "new",
            guide_meta: { guide_info: res.infoDoc, guide_info_id: res.guide_info_id, guide_id: res.guide_id },
          })
        );
        dispatch(slice1Actions.setCreatorInfo({ value: res.creator_info }));
        dispatch(slice1Actions.setOwnerInfo({ value: res.owner_info }));
        dispatch(slice1Actions.pushGuideStack({ value: res.guide_info_id }));
        dispatch(slice1Actions.setGuidesOrPlaces({ value: "Places", GuideType: "None" }));
        setTimeout(fitPlaces, 1000);
        if (window.record_last_guide_read === true && user_info.uid !== "guest") {
          window.record_last_guide_read = false;
          recordLastGuideRead(res.guide_info_id);
        }
        // dispatch(slice1Actions.setGuideInfo({ value: res.infoDoc }));
        // dispatch(slice1Actions.setAuthorization({ guide_info: res.infoDoc, uid: user_info.uid }));
        //dispatch(slice1Actions.setOwnerInfo({ value: res.user_info }));
        //console.log(res.owner_info);
      } else {
        console.log("decode failed");
      }
    } else {
      //console.log(res.res);
    }
    window.record_last_guide_read = false;
    dispatch(slice1Actions.SetLoadingGuide({ value: false }));
  };

  const fitPlaces = (user) => {
    dispatch(slice1Actions.setGuide({ guide: null, fitToBounds: true }));
  };

  const recordLastGuideRead = (guide_info_id) => {
    let _user_info = JSON.parse(JSON.stringify(user_info));
    _user_info.last_guide_id = guide_info_id;
    _user_info.last_guide_time = DateTime.now().toISO({ zone: "utc" });
    dispatch(slice1Actions.setUserInfo({ value: _user_info }));
    DataBase.updateUser(_user_info, _user_info.uid, updateUserCallback);
  };

  const updateUserCallback = (res) => {
    console.log("updateUserCallback", res.pass);
  };

  const timeoutFunc = () => {
    dispatch(slice1Actions.SetLoadingGuide({ value: false }));
  };

  const copyGuide = () => {
    if (Authorization === AUTHORIZATION_LEVEL.GUEST) {
      myAlert("Cannot copy guides in guest mode. You must be registered and logged in");
      return;
    }
    if (window.guide_obj.isAuthorized(Authorization, AUTHORIZATION_LEVEL.COPY) === false) {
      myAlert("You are not authorized to copy this guide");
      return;
    }
    myConfirm(
      "Make a copy of this Guide? This will make a copy of the guide, and open the copy. First save any changes that you want in the original copy.",
      [
        { Title: "OK", Method: () => _batchedCopyGuide() },
        { Title: "CANCEL", Method: null },
      ]
    );
  };

  let _copiedGuideID = null;

  const _batchedCopyGuide = () => {
    dispatch(slice1Actions.SetLoadingGuide({ value: true }));
    setTimeout(timeoutFunc, 5000);
    _copiedGuideID = window.guide_obj.guid_id;
    DataBase.batchedWriteGuide(window.guide_obj, null, null, user_info, "copy", batchCopyGuideCallback);
  };

  const batchCopyGuideCallback = (res) => {
    dispatch(slice1Actions.SetLoadingGuide({ value: false }));
    if (res.pass !== true) {
      return;
    }
    // this.setState({
    //   new_guide_id: res.new_doc_ref.id,
    //   new_guide_info: res.new_info_ref.id,
    //   guide_info_id: res.new_info_ref.id,
    // });
    window.record_last_guide_read = true;
    DataBase.readGuideTransaction(res.new_info_ref.id, readGuideDataReturnNew, user_info.uid);
    DataBase.incrementCopiesTransaction(_copiedGuideID, incrementCopiesTransaction);
  };

  const incrementCopiesTransaction = () => {};

  const EditGuideInfo = () => {
    setGuideEditModalMode("VIEW");
    setGuideEditModalShow(true);
  };

  const myAlert = (message, title, buttons) => {
    setAlertModalMessage(message);
    setAlertModalTitle(title == null ? "Notice" : title);
    setAlertModalButtons(buttons == null ? [{ Title: "OK", Method: null }] : buttons);
    setAlertModalShow(true);
  };

  const myConfirm = (message, buttons) => {
    setAlertModalTitle("Confirmation");
    setAlertModalMessage(message);
    setAlertModalButtons(buttons);
    setAlertModalShow(true);
  };

  const GuideEditModalCallback = (guide_attributes, edit_mode) => {
    if (edit_mode === true) {
      setGuideEditModalMode("UPDATE");
      setGuideEditModalShow(true);
      return;
    }
    if (GuideEditModalMode === "UPDATE")
      dispatch(slice1Actions.setGuide({ guide: null, update_guide_attributes: true }));
  };

  const login = () => {
    setLoginModalMode("Login");
    setShowLoginModal(true);
  };

  const logout = () => {
    setAlertModalTitle("Confirmation");
    setAlertModalMessage("Do you want to log out?");
    setAlertModalButtons([
      { Title: "YES", Method: _logout },
      { Title: "NO", Method: _logout },
    ]);
    setAlertModalShow(true);
  };

  const _logout = (resp) => {
    if (resp !== "YES") return;
    dispatch(slice1Actions.SetLoadingGuide({ value: true }));
    DataBase.Logout(logoutCallback);
  };

  const logoutCallback = (res) => {
    dispatch(slice1Actions.SetLoadingGuide({ value: false }));
    if (res.pass === true) {
      const user_info = {
        distance_unit: "kilometers",
        date_format: "Month first",
        edit_mode: false,
        email: "guest@gmail.com",
        first_name: "guest",
        last_name: "user",
        uid: "guest",
        create_date: "",
        modify_date: "",
        travel_payouts_marker: "",
      };
      dispatch(slice1Actions.setUserInfo({ value: user_info }));
      dispatch(slice1Actions.setUserInfoId({ value: null }));
      //dispatch(slice1Actions.setAuthorization({ guide_info: guide_info, uid: user_info.uid }));
    }
  };

  const PreviousGuide = () => {
    if (GuideStack.length < 1) {
      dispatch(slice1Actions.clearGuideStack());
      return;
    }
    if (GuideStack.length < 2) return;
    let guide_id = GuideStack[GuideStack.length - 2];
    dispatch(slice1Actions.popGuideStack({ value: null }));
    loadGuide(guide_id);
  };

  function loadGuide(guide_info_id) {
    window.record_last_guide_read = true;
    dispatch(slice1Actions.SetLoadingGuide({ value: true }));
    setTimeout(timeoutFunc, 5000);
    DataBase.readGuideTransaction(guide_info_id, readGuideDataReturnNew, user_info.uid);
  }

  const ImageDialogCallback = (res) => {
    console.log("ImageDialogCallback", res);
  };

  const ImageUpload = () => {
    if (Authorization === AUTHORIZATION_LEVEL.GUEST) {
      myAlert("Cannot upload images in guest mode. You must be registered and logged in", "Message");
      return;
    }
    setImageDialogShow(true);
  };

  const RefreshGuide = () => {
    myConfirm("Reload this Guide from cloud? This will overwrite any changes.", [
      { Title: "OK", Method: () => _RefreshGuide() },
      { Title: "CANCEL", Method: null },
    ]);
  };

  const _RefreshGuide = () => {
    loadGuide(guide_meta.guide_info_id);
  };

  const handleChooseSchedule = () => {
    dispatch(slice1Actions.setMultiselectCallback({ value: chooseScheduleCallBack }));
    dispatch(slice1Actions.setMultiselectItems({ value: ScheduleNames }));
    dispatch(slice1Actions.setMultiselectSelectedItems({ value: ScheduleName }));
    dispatch(slice1Actions.setMultiselectMode({ value: "uni" }));
    dispatch(slice1Actions.setMultiselectSearchText({ value: "" }));
    dispatch(slice1Actions.setMultiselectTitle({ value: "Select Schedule" }));
    dispatch(slice1Actions.setShowMultiselect({ value: true }));
  };

  const chooseScheduleCallBack = (schedules) => {
    if (schedules.length > 0) {
      dispatch(slice1Actions.setScheduleName({ value: schedules[0].label }));
      dispatch(slice1Actions.setGuide({ guide: null }));
    }
  };

  const SetScheduleName = (scheduleName) => {
    dispatch(slice1Actions.ClearAllFilters());
    dispatch(slice1Actions.setScheduleName({ value: scheduleName }));
    dispatch(slice1Actions.SetSchedulesFilter({ value: [{ label: scheduleName }] }));
    dispatch(slice1Actions.setGuide({ guide: null }));
  };

  const updateGuide = () => {
    dispatch(slice1Actions.setGuide({ guide: null, fitToBounds: true, update_guide_attributes: true }));
    //dispatch(slice1Actions.resetHomeScreen({ value: null }));
  };

  const updateUI = (scheduleName) => {
    if (scheduleName !== null) {
      dispatch(slice1Actions.ClearAllFilters());
      dispatch(slice1Actions.setScheduleName({ value: scheduleName }));
      dispatch(slice1Actions.SetSchedulesFilter({ value: [{ label: scheduleName }] }));
    }
    dispatch(slice1Actions.resetHomeScreen({ value: null }));
    dispatch(slice1Actions.setGuide({ guide: null, fitToBounds: true }));
  };

  return (
    <>
      <div className="vertical-sidebar">
        {user_info.uid === null || user_info.uid === "guest" || Authorization === AUTHORIZATION_LEVEL.GUEST ? (
          <div>
            <p onClick={() => login()}>Login</p>
            <hr />
          </div>
        ) : (
          <div>
            <p onClick={() => logout()}>Logout</p>
            <hr />
          </div>
        )}
        <div>
          <p onClick={() => ShowGuidesOrGuide("Guides", "Mine")}>My Guides</p>
          <hr />
        </div>
        <div>
          <p onClick={() => ShowGuidesOrGuide("Guides", "Featured")}>Featured Guides</p>
          <hr />
        </div>
        <div>
          <p onClick={() => ShowGuidesOrGuide("Guides", "Shared")}>Shared Guides</p>
          <hr />
        </div>
        <div>
          <p onClick={() => ShowGuidesOrGuide("Places", "Featured")}>Current Guide</p>
          <hr />
        </div>
        <div>
          <p onClick={() => setAiDialogShow(true)}>Travel Wizard</p>
          <hr />
        </div>
        <div>
          <p onClick={() => setTripAIShow(true)}>Guide Creator</p>
          <hr />
        </div>
        <div>
          <p onClick={() => dispatch(slice1Actions.resetHomeScreen({ value: null }))}>Home Screen</p>
          <hr />
        </div>
        <div>
          <p onClick={() => RefreshGuide()}>Refresh Guide</p>
          <hr />
        </div>

        {GuideStack.length > 1 ? (
          <div>
            <p onClick={() => PreviousGuide()}>Previous Guide</p>
            <hr />
          </div>
        ) : null}
        <div>
          <p onClick={() => newGuide()}>New Guide</p>
          <hr />
        </div>
        <div>
          <p onClick={() => copyGuide()}>Copy Guide</p>
          <hr />
        </div>
        {ScheduleNames.length > 1 ? (
          <div>
            <p onClick={() => handleChooseSchedule()}>Choose Schedule</p>
            <hr />
          </div>
        ) : null}
        <div>
          <p onClick={() => setGuideSettingsDialogShow(true)}>Guide Settings</p>
          <hr />
        </div>
        <div>
          <p onClick={() => EditGuideInfo()}>Guide Properties</p>
          <hr />
        </div>
        <div>
          <p onClick={() => ImageUpload()}>Image Upload</p>
          <hr />
        </div>
      </div>
      {AiDialogShow ? (
        <AiDialog
          show={AiDialogShow}
          onHide={() => setAiDialogShow(false)}
          questions={AppSettings.questions2}
          user_info={user_info}
        />
      ) : null}
      {TripAIShow ? (
        <TripAI
          show={TripAIShow}
          onHide={() => setTripAIShow(false)}
          user_info={user_info}
          update_ui={updateUI}
          update_guide={updateGuide}
          set_schedule_name={SetScheduleName}
          ai_base_categories={AppSettings.AIBaseCategories}
          ai_categories={AppSettings.AICategories}

          //guide_attributes={guide_meta.guide_attributes}
        />
      ) : null}
      {AlertModalShow ? (
        <AlertModal
          show={AlertModalShow}
          onHide={() => setAlertModalShow(false)}
          AlertModalTitle={AlertModalTitle}
          AlertModalMessage={AlertModalMessage}
          AlertModalButtons={AlertModalButtons}
        />
      ) : null}
      {GuideSettingsDialogShow ? (
        <GuideSettingsDialog show={GuideSettingsDialogShow} onHide={() => setGuideSettingsDialogShow(false)} />
      ) : null}
      {GuideEditModalShow ? (
        <GuideEditModal
          show={GuideEditModalShow}
          onHide={() => setGuideEditModalShow(false)}
          guide_meta={guide_meta}
          guide_info={guide_meta.guide_info}
          guide_info_id={guide_meta.guide_info_id}
          user_info={user_info}
          mode={GuideEditModalMode}
          callback={GuideEditModalCallback}
        />
      ) : null}
      {ShowLoginModal ? (
        <LoginModal show={ShowLoginModal} onHide={() => setShowLoginModal(false)} mode={LoginModalMode} />
      ) : null}
      {ImageDialogShow ? (
        <ImageDialog
          show={ImageDialogShow}
          onHide={() => setImageDialogShow(false)}
          user_info={user_info}
          callback={ImageDialogCallback}
          mode={"upload"}
          modify_only={"true"}
          updateImage={ImageDialogCallback}
          size={"sm"}
          multi={true}
          exif={false}
        />
      ) : null}
      {/* <GuideInfo show={guideInfoModal} onHide={() => setGuideInfoModal(false)} /> */}
    </>
  );
};

export default VerticalSidebar;
