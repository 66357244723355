import { connect } from "react-redux";
import React, { Component, useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import "./Modal.css";
import PromptModal from "./PromptModal";
import AlertModal from "./AlertModal";
import MultiSelectModal from "./MultiSelectModal";
import IconsDialog from "./IconsDialog";
import Place from "../../Models/Place";
import Group from "../../Models/Group";

class PlacesOfInterest extends Component {
  constructor(props) {
    super(props);
    let list_to_display = [];
    if (window.guide_obj !== null && typeof window.guide_obj !== "undefined")
      list_to_display = window.guide_obj.getGroupsToDisplay();
    // this.setState({ list_to_display: list_to_display });
    this.state = {
      group: "",
      list_to_display: list_to_display,
      searchText: "",

      PromptModalShow: false,
      PromptModalTitle: "Enter Value",
      PromptModalMessage: "",
      PromptModalButtons: [],
      PromptModalInputs: [],

      AlertModalShow: false,
      AlertModalTitle: "",
      AlertModalMessage: "",
      AlertModalButtons: [],

      IconsDialogShow: false,
    };
  }

  componentDidMount() {
    console.log("componentDidMount");
    this.getList();
  }

  getList = () => {
    let list_to_display = [];
    if (window.guide_obj !== null && typeof window.guide_obj !== "undefined")
      list_to_display = window.guide_obj.getGroupsToDisplay();
    this.setState({ list_to_display: list_to_display });
  };

  MyAlert = (title, message, buttons) => {
    this.setState({
      AlertModalTitle: title,
      AlertModalMessage: message,
      AlertModalButtons: buttons,
      AlertModalShow: true,
    });
  };

  _group = null;
  _group_obj = null;

  addModifyGroup = (add, group) => {
    this._group_obj = group;
    if (group != null) this._group = group.name;
    this.setState({
      PromptModalShow: true,
      PromptModalTitle: "Group",
      PromptModalMessage: add ? "Enter Group Name" : "Edit Group Name",
      PromptModalButtons: [
        { Title: "OK", Method: add ? this.AddGroupCallback : this.ModifyGroupNameCallback },
        { Title: "CANCEL", Method: null },
      ],
      PromptModalInputs: [{ name: "Group Name", value: add ? "" : group.name }],
    });
  };

  ModifyGroupNameCallback = (inputs) => {
    if (inputs === null) return;

    let group = new Group();
    group.id = this._group_obj.value.id;
    group.name = inputs[0].value;
    group.poi_img = this._group_obj.value.poi_img;

    window.guide_obj.modifyGroup(group, group);
    this.getList();
    if (this.props.update_display !== null) this.props.update_display();
  };

  AddGroupCallback = (inputs) => {
    if (inputs === null) return;
    window.guide_obj.addGroup(inputs[0].value);
    this.getList();
    if (this.props.update_display !== null) this.props.update_display();
  };

  deleteGroup = (group) => {
    this.MyAlert("Confirmation", "Confirm Delete Group?", [
      { Title: "OK", Method: () => this._deleteGroup(group) },
      { Title: "CANCEL", Method: null },
    ]);
  };

  _deleteGroup = (group) => {
    window.guide_obj.deleteGroup(group);
    this.getList();
  };

  chooseGroupIcon = (group) => {
    this._group_obj = group;
    this.props.navigation.navigate("IconsDialog", { callback: this.chooseGroupIconCallback });
  };

  chooseGroupIconCallback = (poi_img) => {
    let group = new Group();
    group.id = this._group_obj.value.id;
    group.name = this._group_obj.value.name;
    group.poi_img = poi_img;

    window.guide_obj.modifyGroup(group, group);
    this.getList();
  };

  handleSearchChanged = (event) => {
    this.setState({ searchText: event.target.value });
    let list_to_display = window.guide_obj.getGroupsToDisplay();
    let temp_list = [];
    const _searchText = event.target.value.toUpperCase();
    for (let i = 0; i < list_to_display.length; i++)
      if (list_to_display[i].label.toUpperCase().includes(_searchText)) temp_list.push(list_to_display[i]);
    // list_to_display.forEach((item) => {
    //  if (item.label.toUpperCase().includes(_searchText)) temp_list.push(item);
    // });
    this.setState({ list_to_display: temp_list });
  };

  UpdateIcon = (icon) => {
    console.log(icon);
    this._group_obj.value.poi_img = icon.id;
    this._group_obj.value.url = icon.value;
    window.guide_obj.modifyGroup(this._group_obj.value, this._group_obj.value);
    this.props.update_display();
  };

  ChangeIcon = (group) => {
    this._group_obj = group;
    if (group != null) this._group = group.name;
    this.setState({ IconsDialogShow: true });
  };

  render() {
    // if (this.state.ShowMultiselect)
    //   return (
    //     <MultiSelectModal
    //       show={this.state.ShowMultiselect}
    //       onHide={() => this.setState({ ShowMultiselect: false })}
    //       MultiselectItems={this.state.MultiselectItems}
    //       MultiselectSelectedItems={this.state.MultiselectSelectedItems}
    //       MultiselectMode={this.state.MultiselectMode}
    //       MultiselectCallback={this.state.MultiselectCallback}
    //       MultiselectTitle={this.state.MultiselectTitle}
    //       MultiselectSearchText={this.state.MultiselectSearchText}
    //     />
    //   );
    // // else if (this.state.list_to_display == []) {
    // //   this.getList();
    // //   return null;
    // // }

    // let list_to_display = null;
    // if (window.guide_obj !== null && typeof window.guide_obj !== "undefined")
    //   list_to_display = window.guide_obj.getGroupsToDisplay();
    // if (list_to_display == null) return null;
    let list_to_display = this.state.list_to_display;
    if (list_to_display == null) {
      if (window.guide_obj !== null && typeof window.guide_obj !== "undefined")
        list_to_display = window.guide_obj.getGroupsToDisplay();
      if (list_to_display == null) return null;
    }
    return (
      <div className="modal">
        <Modal
          {...this.props}
          size={window.innerWidth < 450 ? "lg" : "md"}
          dialogClassName="myModalClass"
          aria-labelledby="contained-modal-title-vcenter"
          centered={false}
          scrollable={true}
        >
          <Modal.Header closeButton>
            <div style={{ width: "100%" }}>
              <div className="sort">
                <div className="dialog-heading">
                  <div>
                    <h4 className="color-blue">Groups</h4>
                  </div>
                </div>
                <div className="dialog-heading">
                  <input
                    type="text"
                    placeholder="Search"
                    value={this.state.searchText}
                    onChange={this.handleSearchChanged}
                  />
                  {/* {MultiselectMode === "multi" ? (
                    <i className={`fas fa-tasks ${show ? "" : "color"}`} onClick={handleSelectAll}></i>
                  ) : null} */}
                  <i title="Add group" className={`fa fa-plus`} onClick={() => this.addModifyGroup(true, null)}></i>
                </div>
                {/* <Modal.Title id="example-modal-sizes-title-sm">{MultiselectTitle}</Modal.Title> */}
              </div>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="multiselect-list">
              {list_to_display.map((item, index) => (
                <div className="list-item-card" key={index}>
                  <div className="flex-container">
                    <div className="flex-items-left" key={index} onClick={() => this.ChangeIcon(item)}>
                      {Place.getItemPOIImage(item.value) == null ? (
                        <span style={{ cursor: "pointer" }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                      ) : (
                        <img
                          style={{ cursor: "pointer", maxHeight: "32px" }}
                          title="Change Icon"
                          src={Place.getItemPOIImage(item.value)}
                        />
                      )}
                    </div>
                    <div className="flex-item-middle truncate">
                      {item.label}
                      {/* <span className="text-size-1-0">{item.label}</span> */}
                    </div>
                    <div className="flex-item-right flex-container-right">
                      <i
                        title="Delete"
                        className={`fa fa-trash-alt`}
                        style={{ color: "red" }}
                        onClick={() => this.deleteGroup(item)}
                      ></i>
                      <span> </span>
                      <i
                        title="Change Name"
                        className={`fa fa-edit color-blue`}
                        onClick={() => this.addModifyGroup(false, item)}
                      ></i>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </Modal.Body>
        </Modal>
        <PromptModal
          show={this.state.PromptModalShow}
          onHide={() => this.setState({ PromptModalShow: false })}
          PromptModalTitle={this.state.PromptModalTitle}
          PromptModalMessage={this.state.PromptModalMessage}
          PromptModalButtons={this.state.PromptModalButtons}
          PromptModalInputs={this.state.PromptModalInputs}
        />
        <AlertModal
          show={this.state.AlertModalShow}
          onHide={() => this.setState({ AlertModalShow: false })}
          AlertModalTitle={this.state.AlertModalTitle}
          AlertModalMessage={this.state.AlertModalMessage}
          AlertModalButtons={this.state.AlertModalButtons}
        />
        <IconsDialog
          show={this.state.IconsDialogShow}
          onHide={() => this.setState({ IconsDialogShow: false })}
          update_icon={this.UpdateIcon}
        />
      </div>
    );
  }
}

export default PlacesOfInterest;
