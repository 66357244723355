export default class CustomIcon {
  constructor(CustomIconList, name) {
    this.id = "0";
    this.name = name;
    this.poi_img = "0";
    this.url = "";
    if (CustomIconList != null) {
      let i = 999999;
      let id = i.toString();
      while (CustomIconList.findIndex((c) => c.id === id) !== -1) {
        i--;
        id = i.toString();
      }
      this.id = id;
    }
  }
}
