import Place, { Place_Source } from "./Place";

export default class GooglePlaces {
  constructor() {
    this.places = [];
  }

  //&input=Museum%20of%20Contemporary%20Art%20Australia
  //&keyword=
  url = `https://maps.googleapis.com/maps/api/place/findplacefromtext/json?
 locationbias=rectangle:48.80,2.27|48.88,2.42
 &inputtype=textquery
 &type=museum
 &fields=photos,formatted_address,name,rating,opening_hours,geometry,icon,place_id,opening_hours
 &rankby
 &key=AIzaSyAKxtGIewjOkmnY0FE9jD7hYbsHRVArJJc`;
  //https://maps.googleapis.com/maps/api/place/findplacefromtext/json?input=Louvre&inputtype=textquery&fields=photos,formatted_address,name,opening_hours,rating&locationbias=rectangle:48.80,2,27|48.88,2.42&key=AIzaSyAKxtGIewjOkmnY0FE9jD7hYbsHRVArJJc
  //https://maps.googleapis.com/maps/api/place/textsearch/json?query=Louvre&location=48.3,2.3&radius=15000&type=museum&key=AIzaSyAKxtGIewjOkmnY0FE9jD7hYbsHRVArJJc

  next_page_token = null;
  AllPlaces = [];
  maxSearches = 3;
  url = "";
  callback = null;

  searchPlaces = (location, radius, type, query, callback, label) => {
    //const callback = this.getDetails;
    this.next_page_token = null;
    this.AllPlaces = [];
    this.maxSearches = 3;
    this.callback = callback;
    var params = {
      location: location,
      radius: radius, //meters up to 50,000
      //format: "json",
      key: "AIzaSyAKxtGIewjOkmnY0FE9jD7hYbsHRVArJJc",
    };
    if (query != null && query !== "") params.query = query.replace(/ /g, "%20");
    if (type != null) params.type = type;

    //console.log(params);
    var url = `https://andiamotravelapp.com/api/google_text_search.php?`;
    if (type != null) url = `https://andiamotravelapp.com/api/google_category_search.php?`;
    //var url = `https://maps.googleapis.com/maps/api/place/textsearch/json?`;
    Object.keys(params).forEach(function (key) {
      url += "&" + key + "=" + params[key];
    });
    this.url = url;
    this.executeSearch(url, this.executeSearchCallback, label);
  };

  executeSearchCallback = (results, next_page_token, label) => {
    if (results === null) {
      this.callback(this.AllPlaces);
      return;
    }
    this.maxSearches--;
    //console.log("this.maxSearches", this.maxSearches);
    if (Array.isArray(this.AllPlaces)) this.AllPlaces.push(...results);
    //console.log("executeSearchCallback", this.AllPlaces);
    //console.log("next_page_token", next_page_token);
    if (this.maxSearches <= 0 || next_page_token == null || typeof next_page_token === "undefined") {
      this.callback(this.AllPlaces, label);
      return;
    }
    let page_url = this.url + "&pagetoken=" + next_page_token;
    //console.log("page_url", page_url);
    setTimeout(() => this.executeSearch(page_url, this.executeSearchCallback, label), 500);
  };

  executeSearch = (url, callback, label) => {
    // url += `"`;
    //console.log("searchPlaces", url);
    fetch(url)
      .then(function (response) {
        return response.json();
      })
      .then(function (response) {
        //console.log("response", JSON.stringify(response));
        //console.log("next_page_token", response.next_page_token);
        callback(response.results, response.next_page_token, label);
      })
      .catch(function (error) {
        console.log(error);
        callback(null);
      });
  };

  _last = 0;
  _index = 0;
  _places = [];
  _results = [];
  _callback = null;

  getQuickDetails = (gplaces, callback, label, places) => {
    let new_places = [];
    gplaces.forEach((place) => {
      let new_place = this.parsePlace(place, {}, null, true, label, places);
      new_places.push(new_place);
    });
    callback(new_places, label);
  };

  getDetails = (places, callback) => {
    this._places = places;
    this._last = places.length - 1;
    this._callback = callback;
    this._index = 0;
    this._results = [];
    if (places.length == 0) callback(this._results);

    //console.log("places:", places.length, places);
    this.getOneDetails(this.getDetailsCallback);
    // for (let i = 0; i < places.length; i++) {
    //   setTimeout(() => {
    //     this.getOneDetails(_places, places, callback, i, this._last, getDetailsCallback);
    //   }, i * 200);
    // }
  };

  getDetailsCallback = (place, details) => {
    //console.log(details);
    if (details != null) {
      let new_place = this.parsePlace(place, details, this._results);
      this._results.push(new_place);
    }
    this._index += 1;
    if (this._places[this._index] == null || this._places[this._index] == "") this._index += 1;
    if (this._index >= this._last) {
      this._callback(this._results);
      return;
    }
    setTimeout(() => {
      this.getOneDetails(this.getDetailsCallback);
    }, 100);
  };
  //&fields=rating,formatted_phone_number,address_component,adr_address,business_status,icon,formatted_address,photo,type,url,price_level,website
  //  , geometry, , permanently_closed (deprecated), photo, place_id, plus_code, type, url, utc_offset, vicinity
  //https://maps.googleapis.com/maps/api/place/details/json?place_id=ChIJgRpG1DLx5UcRxN9rC9PF4KE&fields=rating,formatted_phone_number,address_component,adr_address,business_status,icon,formatted_address,photo,type,url,price_level,website&key=AIzaSyAKxtGIewjOkmnY0FE9jD7hYbsHRVArJJc
  getOneDetails = (callback, place) => {
    //console.log("getOneDetails", place);
    if (place == null) place = this._places[this._index];
    if (typeof place == "undefined") callback(place, null);
    //console.log("place2", place);
    if (place == null) callback(place, null);
    if (place == "") callback(place, null);
    if (typeof place == "undefined") callback(place, null);
    if (typeof place.place_id == "undefined") callback(place, null);
    const url = `https://andiamotravelapp.com/api/google_get_details.php?place_id=${place.place_id}`;
    //const url = `https://maps.googleapis.com/maps/api/place/details/json?place_id=${place.place_id}&fields=name,rating,formatted_phone_number,address_component,adr_address,business_status,icon,formatted_address,photo,type,url,price_level,website&key=AIzaSyAKxtGIewjOkmnY0FE9jD7hYbsHRVArJJc`;
    //console.log("getDetails", url);
    fetch(url)
      .then(function (response) {
        return response.json();
      })
      .then(function (response) {
        //("response", JSON.stringify(response));
        callback(place, response.result);
      })
      .catch(function (error) {
        console.log(error);
        callback(place, null);
      });
  };

  static getMoreDetails = (callback, google_place_id) => {
    //console.log("place1", place);
    const url = `https://andiamotravelapp.com/api/google_get_details.php?place_id=${google_place_id}`;
    fetch(url)
      .then(function (response) {
        return response.json();
      })
      .then(function (response) {
        //console.log("response", JSON.stringify(response));
        callback(google_place_id, response.result);
      })
      .catch(function (error) {
        //console.log(error);
        callback(google_place_id, null);
      });
  };

  parsePlace = (google_place, details, results, getQuickDetails, label, places) => {
    //console.log("details", details);
    //console.log("details", JSON.stringify(details));
    let new_place = null;
    if (typeof places === "undefined" || places === null) new_place = new Place();
    else new_place = new Place();
    if (results != null) new_place.ID = new_place.createIDFromPlacesArray(results);
    else if (places != null) new_place.ID = new_place.createIDFromPlacesArray(places);
    if (typeof google_place.name != "undefined") new_place.Name = google_place.name;
    if (typeof details.name != "undefined") new_place.Name = details.name;
    //if (typeof label != "undefined") new_place.Name = label;
    if (typeof google_place.geometry.location == "undefined") console.log("details.point.lat", details);
    else {
      new_place.Lat = parseFloat(google_place.geometry.location.lat);
      new_place.Longi = parseFloat(google_place.geometry.location.lng);
    }
    if (typeof google_place.formatted_address != "undefined") new_place.Address = google_place.formatted_address;

    // if (typeof details.address.city != "undefined") new_place.City = details.address.city;
    // if (typeof details.address.state != "undefined") new_place.State = details.address.state;
    // if (typeof details.address.country != "undefined") new_place.Country = details.address.country;
    if (typeof details.international_phone_number != "undefined") new_place.Phone = details.international_phone_number;

    new_place.Email = "";
    if (typeof details.rating != "undefined") new_place.Rating = details.rating;
    //if (typeof details.types != "undefined") new_place.Category = details.types.join(",");

    new_place.Category = [];
    if (typeof details.url != "undefined") {
      if (details.url.includes(";")) {
        const urls = details.url.split(";");
        new_place.Hlink = `google|${urls[0]}`;
      } else new_place.Hlink = `google|${details.url}`;
    }
    //if (typeof details.address.suburb != "undefined") new_place.Group = details.address.suburb;
    new_place.Group = [];
    // if (typeof details.icon != "undefined") new_place.Photo = details.icon;
    // if (typeof details.icon != "undefined") new_place.Photo_m = details.icon;
    new_place.Photo = "http://liquidguides.com/Media/Images/google-places-logo.jpg";
    new_place.Photo_m = "http://liquidguides.com/Media/Images/google-places-logo.jpg";
    new_place.Info = "";
    //if (typeof details.url != "undefined") new_place.URL = details.url;
    new_place.PType = "Icon";
    new_place.Points = null;
    new_place.Source = Place_Source.GOOGLEPLACES;

    if (results === null) {
      if (typeof google_place.rating != "undefined") new_place.Rating = google_place.rating;
      if (typeof google_place.place_id != "undefined") {
        if (getQuickDetails) new_place.Hlink = `google|https://maps.google.com/?place_id=${google_place.place_id}`;
        else new_place.Hlink = `google|https://maps.google.com/?cid=${google_place.place_id}`;
      }
    }
    //console.log(new_place);
    return new_place;
  };

  parsePOI = (poi, details, results) => {
    //console.log("details", details);
    //console.log("details", JSON.stringify(details));
    let new_place = new Place();
    new_place.createIDFromPlacesArray(results);
    if (typeof poi.name != "undefined") new_place.Name = poi.name;
    if (typeof details.name != "undefined") new_place.Name = details.name;
    if (typeof poi.coordinate != "undefined") {
      if (typeof poi.coordinate.latitude != "undefined") new_place.Lat = parseFloat(poi.coordinate.latitude);
      if (typeof poi.coordinate.longitude != "undefined") new_place.Longi = parseFloat(poi.coordinate.longitude);
    } else {
      new_place.Lat = poi.latLng.lat();
      new_place.Longi = poi.latLng.lng();
    }
    if (typeof poi.formatted_address != "undefined") new_place.Address = poi.formatted_address;

    // if (typeof details.address.city != "undefined") new_place.City = details.address.city;
    // if (typeof details.address.state != "undefined") new_place.State = details.address.state;
    // if (typeof details.address.country != "undefined") new_place.Country = details.address.country;
    if (typeof details.international_phone_number != "undefined") new_place.Phone = details.international_phone_number;

    new_place.Email = "";
    if (typeof details.rating != "undefined") new_place.Rating = details.rating;
    //if (typeof details.types != "undefined") new_place.Category = details.types.join(",");
    new_place.Category = [];
    if (typeof details.url != "undefined") {
      if (details.url.includes(";")) {
        const urls = details.url.split(";");
        new_place.Hlink = `google|${urls[0]}`;
      } else new_place.Hlink = `google|${details.url}`;
    }

    //if (typeof details.address.suburb != "undefined") new_place.Group = details.address.suburb;
    new_place.Group = [];
    //if (typeof details.icon != "undefined") new_place.Photo = details.icon;
    //if (typeof details.icon != "undefined") new_place.Photo_m = details.icon;
    new_place.Photo = "http://liquidguides.com/Media/Images/google-places-logo.jpg";
    new_place.Photo_m = "http://liquidguides.com/Media/Images/google-places-logo.jpg";
    new_place.Info = "";
    //if (typeof details.url != "undefined") new_place.URL = details.url;
    new_place.PType = "Icon";
    new_place.Points = null;
    new_place.Source = Place_Source.GOOGLEPLACES;
    //console.log(new_place);
    return new_place;
  };

  static parsePOIStatic = (poi, details, results) => {
    //console.log("details", details);
    //console.log("details", JSON.stringify(details));
    let new_place = new Place();
    new_place.createIDFromPlacesArray(results);
    if (typeof poi.name != "undefined") new_place.Name = poi.name;
    if (typeof details.name != "undefined") new_place.Name = details.name;
    if (typeof poi.coordinate != "undefined") {
      if (typeof poi.coordinate.latitude != "undefined") new_place.Lat = parseFloat(poi.coordinate.latitude);
      if (typeof poi.coordinate.longitude != "undefined") new_place.Longi = parseFloat(poi.coordinate.longitude);
    } else {
      new_place.Lat = poi.latLng.lat();
      new_place.Longi = poi.latLng.lng();
    }
    if (typeof poi.formatted_address != "undefined") new_place.Address = poi.formatted_address;

    // if (typeof details.address.city != "undefined") new_place.City = details.address.city;
    // if (typeof details.address.state != "undefined") new_place.State = details.address.state;
    // if (typeof details.address.country != "undefined") new_place.Country = details.address.country;
    if (typeof details.international_phone_number != "undefined") new_place.Phone = details.international_phone_number;

    new_place.Email = "";
    if (typeof details.rating != "undefined") new_place.Rating = details.rating;
    //if (typeof details.types != "undefined") new_place.Category = details.types.join(",");
    new_place.Category = [];
    if (typeof details.url != "undefined") {
      if (details.url.includes(";")) {
        const urls = details.url.split(";");
        new_place.Hlink = `google|${urls[0]}`;
      } else new_place.Hlink = `google|${details.url}`;
    }

    //if (typeof details.address.suburb != "undefined") new_place.Group = details.address.suburb;
    new_place.Group = [];
    //if (typeof details.icon != "undefined") new_place.Photo = details.icon;
    //if (typeof details.icon != "undefined") new_place.Photo_m = details.icon;
    new_place.Photo = "http://liquidguides.com/Media/Images/google-places-logo.jpg";
    new_place.Photo_m = "http://liquidguides.com/Media/Images/google-places-logo.jpg";
    new_place.Info = "";
    //if (typeof details.url != "undefined") new_place.URL = details.url;
    new_place.PType = "Icon";
    new_place.Points = null;
    new_place.Source = Place_Source.GOOGLEPLACES;
    //console.log(new_place);
    return new_place;
  };

  moveToGuide = (guide, place) => {
    guide.places.addPlace(place);
  };

  static getGoogleCategories = () => {
    var google_categories = [
      "accounting",
      "airport",
      "amusement_park",
      "aquarium",
      "art_gallery",
      "atm",
      "bakery",
      "bank",
      "bar",
      "beauty_salon",
      "bicycle_store",
      "book_store",
      "bowling_alley",
      "bus_station",
      "cafe",
      "campground",
      "car_dealer",
      "car_rental",
      "car_repair",
      "car_wash",
      "casino",
      "cemetery",
      "church",
      "city_hall",
      "clothing_store",
      "convenience_store",
      "courthouse",
      "dentist",
      "department_store",
      "doctor",
      "drugstore",
      "electrician",
      "electronics_store",
      "embassy",
      "fire_station",
      "florist",
      "funeral_home",
      "furniture_store",
      "gas_station",
      "gym",
      "hair_care",
      "hardware_store",
      "hindu_temple",
      "home_goods_store",
      "hospital",
      "insurance_agency",
      "jewelry_store",
      "laundry",
      "lawyer",
      "library",
      "light_rail_station",
      "liquor_store",
      "local_government_office",
      "locksmith",
      "lodging",
      "meal_delivery",
      "meal_takeaway",
      "mosque",
      "movie_rental",
      "movie_theater",
      "moving_company",
      "museum",
      "night_club",
      "painter",
      "park",
      "parking",
      "pet_store",
      "pharmacy",
      "physiotherapist",
      "plumber",
      "police",
      "post_office",
      "primary_school",
      "real_estate_agency",
      "restaurant",
      "roofing_contractor",
      "rv_park",
      "school",
      "secondary_school",
      "shoe_store",
      "shopping_mall",
      "spa",
      "stadium",
      "storage",
      "store",
      "subway_station",
      "supermarket",
      "synagogue",
      "taxi_stand",
      "tourist_attraction",
      "train_station",
      "transit_station",
      "travel_agency",
      "university",
      "veterinary_care",
      "zoo",
    ];
    let googleCategories = [];
    google_categories.forEach((cat) => {
      googleCategories.push({
        label: cat,
        value: cat,
      });
    });
    return googleCategories;
  };
}
