import React, { useState } from "react";
import { useSelector } from "react-redux";
import queryString from "query-string";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";

import "./HomeHeader.css";
// import { Link } from "react-router-dom";
// import GuideInfo from "../../modals/GuideInfo";
import logo from "../../../assets/images/logo1.jpg";

import DataBase from "../../../dataBase/liquidGuides";
import Guide, { GuideAttributes } from "../../../Models/Guide";
import Place from "../../../Models/Place";
import { slice1Actions } from "../../../store/reducer";
import Common, { Settings, SortByEnum, AUTHORIZATION_LEVEL, COLORS } from "../../../Common/Common";
import HTMLEditorModal from "../../modals/HTMLEditorModal";
import ImageUploadModal from "../../modals/ImageUploadModal";
import UserInfoModal from "../../modals/UserInfoModal";
import MenuModal from "../../modals/MenuModal";
import LoginModal from "../../modals/LoginModal";
import AlertModal from "../../modals/AlertModal";
import GuideDownload from "../../modals/GuideDownload/GuideDownload";
import ImageDialog from "../../modals/ImageDialog";
import GroupsDialog from "../../modals/GroupsDialog";
import PlacesOfInterest from "../../modals/PlacesOfInterest";
import CustomIcons from "../../modals/CustomIcons";

import CategoriesDialog from "../../modals/CategoriesDialog";
import SectionsDialog from "../../modals/SectionsDialog";
import GuideEditModal from "../../modals/GuideEditModal";
import SettingsDialog from "../../modals/SettingsDialog";
import AiDialog from "../../modals/AiDialog";
import ReviewDialog from "../../modals/ReviewDialog";
import GuideSettingsDialog from "../../modals/GuideSettingsDialog";
import PDFModal from "../../modals/PDFModal";
import HTMLReader from "../../modals/HTMLReader";
import MultiSelectModal from "../../modals/MultiSelectModal";
import GuideTranslate from "../../modals/GuideTranslate";

import { DateTime } from "luxon";

import { urlSchemeToFunctions } from "../../../CommonWeb/Common";
//import { Offcanvas } from "react-bootstrap";

import User from "../../../Models/User";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import Appointment from "../../../Models/Event";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid, regular, brands, icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import KMLInput from "../../modals/KMLInput";
import TripAI from "../../modals/TripAI";

const HomeHeader = (props) => {
  const [guideInfoModal, setGuideInfoModal] = useState(false);

  const [ShowHTMLEditorModal, setHTMLEditorModal] = useState(false);
  const [HTMLEditorModalContent, setHTMLEditorModalContent] = useState("");
  const [HTMLEditorModalCallback, setHTMLEditorModalCallback] = useState(null);

  const [ImageDialogShow, setImageDialogShow] = useState(false);
  const [ImageDialogMode, setImageDialogMode] = useState("upload");
  const [ImageDialogExif, setImageDialogExif] = useState(false);

  const [ShowUserInfoModal, setUserInfoModal] = useState(false);
  const [ShowLoginModal, setShowLoginModal] = useState(false);
  const [LoginModalMode, setLoginModalMode] = useState("Login");

  const [MenuModalShow, setMenuModalShow] = useState(false);
  const [MenuModalImage, setMenuModalImage] = useState(null);
  const [MenuModalList, setMenuModalList] = useState([]);
  const [MenuModalHeading, setMenuModalHeading] = useState("");
  const [MenuModalPlace, setMenuModalPlace] = useState(null);
  const [AlertModalShow, setAlertModalShow] = useState(false);
  const [AlertModalTitle, setAlertModalTitle] = useState("");
  const [AlertModalMessage, setAlertModalMessage] = useState("");
  const [AlertModalButtons, setAlertModalButtons] = useState([]);
  const [activityIndicator, setActivityIndicator] = useState(false);
  const [GuideDownloadMode, setGuideDownloadMode] = useState("list");
  const [GuideDownloadType, setGuideDownloadType] = useState("read");
  const [GuideDownloadShow, setGuideDownloadShow] = useState(false);
  const [GuideDownloadCategory, setGuideDownloadCategory] = useState("Mine");

  const [GroupsDialogShow, setGroupsDialogShow] = useState(false);
  const [CategoriesDialogShow, setCategoriesDialogShow] = useState(false);
  const [PlacesOfInterestShow, setPlacesOfInterestShow] = useState(false);

  const [SectionsDialogShow, setSectionsDialogShow] = useState(false);
  const [GuideEditModalShow, setGuideEditModalShow] = useState(false);
  const [GuideEditModalMode, setGuideEditModalMode] = useState("VIEW");

  const [SettingsDialogShow, setSettingsDialogShow] = useState(null);

  const [AiDialogShow, setAiDialogShow] = useState(null);

  const [ReviewDialogShow, setReviewDialogShow] = useState(null);
  const [CustomIconsShow, setCustomIconsShow] = useState(false);
  const [GuideSettingsDialogShow, setGuideSettingsDialogShow] = useState(false);
  const [PDFModalShow, setPDFModalShow] = useState(false);
  const [HTMLReaderModal, setHTMLReaderModal] = useState(false);
  const [HTMLContent, setHTMLContent] = useState("");

  const [ShowMultiselect, setShowMultiselect] = useState(false);
  const [MultiselectItems, setMultiselectItems] = useState([]);
  const [MultiselectSelectedItems, setMultiselectSelectedItems] = useState([]);
  const [MultiselectMode, setMultiselectMode] = useState("uni");
  const [MultiselectTitle, setMultiselectTitle] = useState("Select");
  const [MultiselectSearchText, setMultiselectSearchText] = useState("");
  //const [guideInfoID, setGuideInfoID] = useState("");
  const [documentHTML, setdocumentHTML] = useState("");
  const [KMLInputShow, setKMLInputShow] = useState(false);
  const [TripAIShow, setTripAIShow] = useState(null);
  const [GuideTranslateShow, setGuideTranslateShow] = useState(false);

  const dispatch = useDispatch();
  let creator_info = useSelector((state) => state.creator_info);
  let owner_info = useSelector((state) => state.owner_info);
  let user_info = useSelector((state) => state.user_info);
  let user_info_id = useSelector((state) => state.user_info_id);
  let AppSettings = useSelector((state) => state.AppSettings);
  let Authorization = useSelector((state) => state.Authorization);
  // let guide_info = useSelector((state) => state.guide_info);
  let guide_meta = useSelector((state) => state.guide_meta);

  let HTMLStack = useSelector((state) => state.HTMLStack);
  let htmlString = useSelector((state) => state.homeScreen);
  let GuideSortOption = useSelector((state) => state.GuideSortOption);
  let MenuModalArgs = useSelector((state) => state.MenuModalArgs);
  let GuideStack = useSelector((state) => state.GuideStack);
  let MobileMode = useSelector((state) => state.MobileMode);
  let GuideEditMode = useSelector((state) => state.GuideEditMode);
  let HomeFullScreen = useSelector((state) => state.HomeFullScreen);
  let GuideSettings = useSelector((state) => state.GuideSettings);
  let ContentFontZoom = useSelector((state) => state.ContentFontZoom);
  let GuidesOrPlaces = useSelector((state) => state.GuidesOrPlaces);
  let HidePlaces = useSelector((state) => state.HidePlaces);
  let HideSchedule = useSelector((state) => state.HideSchedule);
  let HideMap = useSelector((state) => state.HideMap);
  let HideHome = useSelector((state) => state.HideHome);
  let HideSideMenu = useSelector((state) => state.HideSideMenu);

  let GuideType = useSelector((state) => state.GuideType);
  let showHiddenPlace = useSelector((state) => state.showHiddenPlace);

  let SizeMapToFit = useSelector((state) => state.SizeMapToFit);

  let NoAds = useSelector((state) => state.NoAds);
  //console.log("HomeHeader HTMLStack.length:", HTMLStack.length);

  //let search = window.location.search;
  // let params = new URLSearchParams(search);
  // let foo = params.get('query');
  //console.log(foo);

  // const reportWindowSize = (evt) => {
  //   console.log("reportWindowSize", evt.currentTarget.innerWidth, evt.currentTarget.innerHeight);
  // };

  const handleLoad = () => {
    //const parsed = queryString.parse(window.location.search);

    // if (typeof parsed.id != "undefined") loadGuide(parsed.id);
    // else loadGuide("IQrZiedo7oJWa9GgLR6L");

    // const parsed = queryString.parse(window.location.search);
    // console.log("*********parsed", parsed);
    // if (typeof parsed.no_ads != "undefined") dispatch(slice1Actions.setNoAds({ value: true }));
    // if (typeof parsed.featured_guides != "undefined") setTimeout(() => ShowGuidesOrGuide("Guides", "Featured"), 7000);
    // else if (typeof parsed.my_guides != "undefined") setTimeout(() => ShowGuidesOrGuide("Guides", "Mine"), 7000);
    DataBase.onAuthStateChanged(onAuthStateChanged);
    DataBase.readSettings(readSettingsCallback);
    //window.addEventListener("resize", reportWindowSize);
  };

  const readSettingsCallback = (res) => {
    //console.log("readSettingsCallback", res.res);
    if (res.pass === true) {
      if (typeof res.res.announce !== "undefined" && res.res.announce !== "") {
        setHTMLContent(res.res.announce);
        setHTMLReaderModal(true);
      }
      dispatch(slice1Actions.setAppSettings({ value: res.res }));
    }
  };

  window.addEventListener("load", handleLoad);
  window.loadGuide = loadGuide;
  function loadGuide(guide_info_id) {
    //const guide_info_id = "wHvagurBLMmZ6zt4Cw6z"; // Imperia
    //const guide_info_id = "IQrZiedo7oJWa9GgLR6L"; // Florence
    window.record_last_guide_read = true;
    dispatch(slice1Actions.SetLoadingGuide({ value: true }));
    setTimeout(timeoutFunc, 5000);
    DataBase.readGuideTransaction(guide_info_id, readGuideDataReturnNew, user_info.uid);
  }

  const timeoutFunc = () => {
    dispatch(slice1Actions.SetLoadingGuide({ value: false }));
  };

  const setGuideSortOption = (value) => {
    dispatch(slice1Actions.setGuideSortOption({ value: value }));
  };

  const readGuideDataReturnNew = (res) => {
    let GuideObj = null;
    if (res.pass === true) {
      GuideObj = new Guide(res.guide_id);
      const guide_json = res.res.data;
      console.log(`Guide Size is: ${guide_json.length}`);
      var guide_obj = JSON.parse(guide_json);
      const decoded = GuideObj.decodeGuideFb(guide_obj, res.infoDoc);
      if (decoded === true) {
        GuideObj.schedule.getPlaces_InSchedule();
        window.guide_obj = GuideObj;
        dispatch(
          slice1Actions.setGuide({
            guide: "new",
            guide_meta: { guide_info: res.infoDoc, guide_info_id: res.guide_info_id, guide_id: res.guide_id },
          })
        );
        dispatch(slice1Actions.setCreatorInfo({ value: res.creator_info }));
        dispatch(slice1Actions.setOwnerInfo({ value: res.owner_info }));
        dispatch(slice1Actions.pushGuideStack({ value: res.guide_info_id }));
        dispatch(slice1Actions.setGuidesOrPlaces({ value: "Places", GuideType: "None" }));
        setTimeout(fitPlaces, 1000);
        if (window.record_last_guide_read === true && user_info.uid !== "guest") {
          window.record_last_guide_read = false;
          recordLastGuideRead(res.guide_info_id);
        }
        // dispatch(slice1Actions.setGuideInfo({ value: res.infoDoc }));
        // dispatch(slice1Actions.setAuthorization({ guide_info: res.infoDoc, uid: user_info.uid }));
        //dispatch(slice1Actions.setOwnerInfo({ value: res.user_info }));
        //console.log(res.owner_info);
      } else {
        console.log("decode failed");
      }
    } else {
      //console.log(res.res);
    }
    window.record_last_guide_read = false;
    dispatch(slice1Actions.SetLoadingGuide({ value: false }));
  };
  const fitPlaces = (user) => {
    dispatch(slice1Actions.setGuide({ guide: null, fitToBounds: true }));
  };
  const onAuthStateChanged = (user) => {
    console.log("onAuthStateChanged", user);
    if (user) {
      User.findUserByUID(user.uid, findUserByUIDCallback);
    } else {
      const parsed = queryString.parse(window.location.search);
      console.log("*********parsed2", parsed);
      let guide_to_load = "IQrZiedo7oJWa9GgLR6L";
      if (typeof parsed.id != "undefined") guide_to_load = parsed.id;
      loadGuide(guide_to_load);

      if (typeof parsed.no_ads != "undefined" || window.self !== window.top)
        dispatch(slice1Actions.setNoAds({ value: true }));

      if (typeof parsed.featured_guides != "undefined") setTimeout(() => ShowGuidesOrGuide("Guides", "Featured"), 7000);
      else if (typeof parsed.my_guides != "undefined") setTimeout(() => ShowGuidesOrGuide("Guides", "Mine"), 7000);
      // else {
      //   if (typeof parsed.id != "undefined") guide_to_load = parsed.id;
      //   loadGuide(guide_to_load);
      // }
    }
  };

  let user_info_retrieved = null;
  let guide_loaded = false;

  const findUserByUIDCallback = async (res) => {
    console.log("findUserByUIDCallback", res);
    if (res.pass === true) {
      const user_info = res.res;
      user_info_retrieved = user_info;
      dispatch(slice1Actions.setUserInfo({ value: user_info }));
      dispatch(slice1Actions.setUserInfoId({ value: res.doc_id }));
      console.log("findUserByUIDCallback", user_info.last_guide_id);
      if (user_info.last_guide_id !== "" && !guide_loaded) {
        guide_loaded = true;
        DataBase.doesGuideInfoExist(user_info.last_guide_id, doesGuideInfoExistCallback);
        return;
      }
    }
  };

  const doesGuideInfoExistCallback = (res) => {
    let guide_to_load = "IQrZiedo7oJWa9GgLR6L";
    //console.log("doesGuideInfoExistCallback", user_info.last_guide_id);
    if (res.pass === true) {
      guide_to_load = user_info_retrieved.last_guide_id;
    }
    const parsed = queryString.parse(window.location.search);
    console.log("*********parsed3", parsed);
    if (typeof parsed.id != "undefined") guide_to_load = parsed.id;
    loadGuide(guide_to_load);
    if (typeof parsed.no_ads != "undefined" || window.self !== window.top)
      dispatch(slice1Actions.setNoAds({ value: true }));
    if (typeof parsed.featured_guides != "undefined") setTimeout(() => ShowGuidesOrGuide("Guides", "Featured"), 7000);
    else if (typeof parsed.my_guides != "undefined") setTimeout(() => ShowGuidesOrGuide("Guides", "Mine"), 7000);
    // else {
    //   if (typeof parsed.id != "undefined") guide_to_load = parsed.id;
    //   loadGuide(guide_to_load);
    // }
  };

  const purchaseTickets = () => {
    let htmlString = Common.purchaseTicketsHTML(AppSettings, creator_info, owner_info, user_info);
    //htmlString = urlSchemeToFunctions(htmlString);
    //console.log("purchaseTickets", htmlString);
    window.location.href = "#home-start";
    var homeDiv = document.getElementById("home-content-start");
    homeDiv.scrollIntoView();
    dispatch(slice1Actions.setHTMLTitle({ value: "Tickets" }));
    dispatch(slice1Actions.setHTMLToSHow({ value: htmlString }));
    dispatch(slice1Actions.setShowHTMLReader({ value: true }));
    window.location.href = "#home-start";
    var homeDiv = document.getElementById("home-content-start");
    homeDiv.scrollIntoView();
  };

  const goBack = () => {
    //console.log("handleLeftArrow", HTMLStack.length);
    if (HTMLStack.length <= 1) {
      dispatch(slice1Actions.clearHTMLStack());
      //dispatch(slice1Actions.setShowHTMLReader({ value: false }));
      return;
    }
    const htmlString = HTMLStack[HTMLStack.length - 2];
    dispatch(slice1Actions.popHTMLStack());
    dispatch(slice1Actions.setHomeScreen({ value: htmlString }));
  };

  const DisplayHomeScreen = () => {
    console.log("DisplayHomeScreen");
    dispatch(slice1Actions.resetHomeScreen({ value: null }));
    dispatch(slice1Actions.setCurrentAppScreen({ value: "Home" }));

    // if (HTMLStack.length < 1) {
    //   //dispatch(slice1Actions.clearHTMLStack());
    //   return;
    // }
    // const htmlString = HTMLStack[0];
    // dispatch(slice1Actions.clearHTMLStack());
    // // dispatch(slice1Actions.setHTMLStack({ value: [htmlString] }));
    // dispatch(slice1Actions.setHomeScreen({ value: htmlString }));
  };

  const HTMLEdit = () => {
    let html = window.guide_obj.document;
    if (html === null || html === "" || html === "<!-- Comment -->") {
      html = window.guide_obj.createGuideHTML();
    }
    setHTMLEditorModalContent(html);
    //setHTMLEditorModalCallback(HTMLEditCallback);
    setHTMLEditorModal(true);
  };

  const HTMLEditCallback = (html) => {
    console.log("HTMLEditCallback");
    window.guide_obj.document = html;
    dispatch(slice1Actions.clearHTMLStack());
    //dispatch(slice1Actions.popHTMLStack());
    dispatch(slice1Actions.setHomeScreen({ value: html }));
  };

  const ImageUpload = () => {
    if (Authorization === AUTHORIZATION_LEVEL.GUEST) {
      myAlert("Cannot upload images in guest mode. You must be registered and logged in", "Message");
      return;
    }
    setImageDialogMode("upload");
    setImageDialogExif(false);
    setImageDialogShow(true);
  };

  const ImageDialogCallback = (res) => {
    console.log("ImageDialogCallback", res);
    if (ImageDialogMode === "select") updateImageCallBack(res);
  };

  const myAlert = (message, title, buttons) => {
    setAlertModalMessage(message);
    setAlertModalTitle(title == null ? "Notice" : title);
    setAlertModalButtons(buttons == null ? [{ Title: "OK", Method: null }] : buttons);
    setAlertModalShow(true);
  };

  const myConfirm = (message, buttons) => {
    setAlertModalTitle("Confirmation");
    setAlertModalMessage(message);
    setAlertModalButtons(buttons);
    setAlertModalShow(true);
  };

  const ViewMenu = () => {
    const menuList = [
      {
        label: "View My Guides",
        method: MobileMode ? () => DownloadGuide("Mine") : () => ShowGuidesOrGuide("Guides", "Mine"),
        icon: <i className="fa fa-books"></i>,
        visible: MobileMode,
        info: "View guides owned by you",
      },
      {
        label: "View Featured Guides",
        method: MobileMode ? () => DownloadGuide("Featured") : () => ShowGuidesOrGuide("Guides", "Featured"),
        icon: <i className="fa fa-books"></i>,
        visible: MobileMode,
        info: "View ready made guides from Andiamo Places",
      },
      {
        label: "View Shared Guides",
        method: MobileMode ? () => DownloadGuide("Shared") : () => ShowGuidesOrGuide("Guides", "Shared"),
        icon: <i className="fa fa-books"></i>,
        visible: MobileMode,
        info: "View guides shared by community",
      },
      {
        label: "View My Guides",
        method: () => ShowGuidesOrGuide("Guides", "Mine"),
        icon: <i className="fa fa-books"></i>,
        visible: !MobileMode && (GuidesOrPlaces === "Places" || GuideType !== "Mine"),
        info: "View guides owned by you",
      },
      {
        label: "View Featured Guides",
        method: () => ShowGuidesOrGuide("Guides", "Featured"),
        icon: <i className="fa fa-books"></i>,
        visible: !MobileMode && (GuidesOrPlaces === "Places" || GuideType !== "Featured"),
        info: "View ready made guides from Andiamo Places",
      },
      {
        label: "View Shared Guides",
        method: () => ShowGuidesOrGuide("Guides", "Shared"),
        icon: <i className="fa fa-books"></i>,
        visible: !MobileMode && (GuidesOrPlaces === "Places" || GuideType !== "Shared"),
        info: "View guides shared by community",
      },
      {
        label: "View Current Guide",
        method: () => ShowGuidesOrGuide("Places", "Featured"),
        icon: <i className="fa fa-book-open"></i>,
        visible: !MobileMode && GuidesOrPlaces === "Guides",
        info: "View ready made guides from Andiamo Places",
      },
      {
        label: `${HidePlaces === true ? "View" : "Hide"} list of ${GuidesOrPlaces === "Places" ? "Places" : "Guides"}`,
        method: () => dispatch(slice1Actions.setHidePlaces({ value: !HidePlaces })),
        icon: null,
        visible: !MobileMode,
        info: "View a list of the places or guides in the left hand column",
      },
      {
        label: `${HideSchedule === true ? "View" : "Hide"} Schedule`,
        method: () => dispatch(slice1Actions.setHideSchedule({ value: !HideSchedule })),
        icon: null,
        visible: !MobileMode && GuidesOrPlaces === "Places",
        info: "View the schedules for the current guide in the right hand column",
      },
      {
        label: `${HideHome === true ? "View" : "Hide"} Home`,
        method: () => showHome(),
        icon: null,
        visible: !MobileMode,
        info: "Display the Home screen that has html content",
      },
      {
        label: `${HideMap === true ? "View" : "Hide"} Map`,
        method: () => showMap(),
        icon: null,
        visible: !MobileMode,
        info: "Display the places or guides in a map at the bottom",
      },
      {
        label: `${HideSideMenu === true ? "View" : "Hide"} Side Menu`,
        method: () => showSideMenu(),
        icon: null,
        visible: !MobileMode,
        info: "Display the vertical menu on the left side",
      },
      {
        label: "View Travel Wizard",
        method: () => setAiDialogShow(true),
        icon: null,
        visible: true,
        info: "Uses AI to answer common travel questions",
      },
      {
        label: "Content Font Zoom",
        method: handleContentFontZoom,
        icon: null,
        visible: true,
        info: "Set Guide settings to customize how the Guide looks and interacts",
      },
      {
        label: SizeMapToFit ? "Auto Size Map" : "Don't Auto Size Map",
        method: setSizeMapToFit,
        icon: null,
        visible: true,
        info: "Turn auto size map to fit places on/off",
      },
    ];
    setMenuModalList(menuList);
    setMenuModalHeading("View Menu");
    setMenuModalImage(<i className="fa fa-eye"></i>);
    setMenuModalPlace(null);
    setMenuModalShow(true);
  };

  const setSizeMapToFit = () => {
    dispatch(slice1Actions.setSizeMapToFit({ value: !SizeMapToFit }));
  };

  const UserMenu = () => {
    const menuList = [
      {
        label: "Logout",
        method: logout,
        icon: null,
        visible: user_info.uid !== null && user_info.uid !== "guest" && Authorization !== AUTHORIZATION_LEVEL.GUEST,
        info: "Log out from this app",
      },
      {
        label: "Reset Password",
        method: resetPassword,
        icon: null,
        visible: true,
        info: "Reset the password. An email will be sent to allow you to reset the password.",
      },
      {
        label: "User Details",
        method: showUserInfoModal,
        icon: null,
        visible: true,
        info: "Display the details of the logged in user, e.g. name, id etc.",
      },
      {
        label: "Settings",
        method: showSettingsDialog,
        icon: null,
        visible: user_info.uid !== null && user_info.uid !== "guest",
        info: "Change the settings for the logged in user of this app",
      },
      {
        label: "Login",
        method: showLogin,
        icon: null,
        visible: user_info.uid === null || user_info.uid === "guest" || Authorization === AUTHORIZATION_LEVEL.GUEST,
        info: "Login to this app. Note: you need to be logged in to: save changes, copy guide, create a guide, rate a guide or change settings",
      },
      {
        label: "Register",
        method: Register,
        icon: null,
        visible: user_info.uid === null || user_info.uid === "guest" || Authorization === AUTHORIZATION_LEVEL.GUEST,
        info: "Register for the app. Note that you need to register before you can log in and it's free of charge.",
      },
      {
        label: "Upload Images",
        method: ImageUpload,
        icon: null,
        visible: GuidesOrPlaces === "Places",
        info: "This will allow you to upload create a new guide and open the new guide. The new guide name will be the current guide name appended with '_C'",
      },
    ];
    setMenuModalList(menuList);
    setMenuModalHeading("User Menu");
    setMenuModalImage(<i className="fa fa-user"></i>);
    setMenuModalPlace(null);
    setMenuModalShow(true);
  };

  const Register = () => {
    setLoginModalMode("Register");
    setShowLoginModal(true);
  };

  const showLogin = () => {
    setLoginModalMode("Login");
    setShowLoginModal(true);
  };

  const resetPassword = () => {
    setLoginModalMode("Forgot Password");
    setShowLoginModal(true);
  };

  const logout = () => {
    setAlertModalTitle("Confirmation");
    setAlertModalMessage("Do you want to log out?");
    setAlertModalButtons([
      { Title: "YES", Method: _logout },
      { Title: "NO", Method: _logout },
    ]);
    setAlertModalShow(true);
  };

  const _logout = (resp) => {
    if (resp !== "YES") return;
    setActivityIndicator(true);
    DataBase.Logout(logoutCallback);
  };

  const logoutCallback = (res) => {
    //console.log(res);
    setActivityIndicator(false);
    if (res.pass === true) {
      const user_info = {
        distance_unit: "kilometers",
        date_format: "Month first",
        edit_mode: false,
        email: "guest@gmail.com",
        first_name: "guest",
        last_name: "user",
        uid: "guest",
        create_date: "",
        modify_date: "",
        travel_payouts_marker: "",
      };
      dispatch(slice1Actions.setUserInfo({ value: user_info }));
      dispatch(slice1Actions.setUserInfoId({ value: null }));
      //dispatch(slice1Actions.setAuthorization({ guide_info: guide_info, uid: user_info.uid }));
    }
  };

  const showSettingsDialog = () => {
    setSettingsDialogShow(true);
  };

  const SettingsDialogCallback = (user_info) => {
    if (Authorization === AUTHORIZATION_LEVEL.GUEST) {
      myAlert("Cannot save settings in guest mode. You must be registered and logged in", "Message");
      return;
    }
    dispatch(slice1Actions.setUserInfo({ value: user_info }));
    dispatch(slice1Actions.setGuide({ guide: null }));
  };

  const GuideMenu = () => {
    const menuList = [
      // {
      //   label: "View My Guides",
      //   method: MobileMode ? () => DownloadGuide("Mine") : () => ShowGuidesOrGuide("Guides", "Mine"),
      //   icon: <i className="fa fa-books"></i>,
      //   visible: MobileMode && (GuidesOrPlaces === "Places" || GuideType !== "Mine"),
      //   info: "View guides owned by you",
      // },
      // {
      //   label: "View Featured Guides",
      //   method: MobileMode ? () => DownloadGuide("Featured") : () => ShowGuidesOrGuide("Guides", "Featured"),
      //   icon: <i className="fa fa-books"></i>,
      //   visible: MobileMode && (GuidesOrPlaces === "Places" || GuideType !== "Featured"),
      //   info: "View ready made guides from Andiamo Places",
      // },
      // {
      //   label: "View Shared Guides",
      //   method: MobileMode ? () => DownloadGuide("Shared") : () => ShowGuidesOrGuide("Guides", "Shared"),
      //   icon: <i className="fa fa-books"></i>,
      //   visible: MobileMode && (GuidesOrPlaces === "Places" || GuideType !== "Shared"),
      //   info: "View guides shared by community",
      // },
      {
        label: "View Current Guide",
        method: () => ShowGuidesOrGuide("Places", "Featured"),
        icon: <i className="fa fa-book-open"></i>,
        visible: !MobileMode && GuidesOrPlaces === "Guides",
        info: "View ready made guides from Andiamo Places",
      },
      {
        label: "Display Home screen",
        method: DisplayHomeScreen,
        icon: null,
        visible: GuidesOrPlaces === "Places",
        info: "This will remove any HTML in the main screen and display the home screen.",
      },
      {
        label: "Refresh Guide",
        method: RefreshGuide,
        icon: null,
        visible: GuidesOrPlaces === "Places",
        info: "Download the same guide to view. This will overwrite any changes to your guide.",
      },
      // {
      //   label: "Search Guides",
      //   method: DownloadGuide,
      //   icon: null,
      //   visible: true,
      //   info: "Search the available guides and download it to view.",
      // },
      // {
      //   label: "Ask travel questions",
      //   method: () => setAiDialogShow(true),
      //   icon: null,
      //   visible: true,
      //   info: "Ask our travel wizard questions",
      // },
      // {
      //   label: GuidesOrPlaces === "Places" ? "Show Guides in Map" : "Show Places",
      //   method: GuidesInMap,
      //   icon: null,
      //   visible: true,
      //   info:
      //     GuidesOrPlaces === "Places"
      //       ? "Show Guides in Map and download one to view."
      //       : "Show Places from selected Guide",
      // },
      {
        label: "Previous Guide",
        method: PreviousGuide,
        icon: null,
        visible: GuideStack.length > 1,
        info: "If you load more than one guide you can go back to the previous guide loaded",
      },
      // {
      //   label: "New Guide",
      //   method: newGuide,
      //   icon: null,
      //   visible: GuidesOrPlaces === "Places",
      //   info: "This will create a new guide and open the new guide. The new guide name will be 'New Guide'",
      // },
      // {
      //   label: "Copy Guide",
      //   method: copyGuide,
      //   icon: null,
      //   visible: GuidesOrPlaces === "Places",
      //   info: "This will make a copy of the current guide and open the new guide. The new guide name will be the current guide name appended with '_C'",
      // },
      // {
      //   label: "Overwrite Guide",
      //   method: overWriteGuide,
      //   icon: null,
      //   visible: GuideEditMode && GuidesOrPlaces === "Places",
      //   info: "This will overwrite a selected guide with the contents of the current guide. It will preserve the ownership of the overwritten file",
      // },
      // {
      //   label: "Write Guide To File",
      //   method: writeGuideToFile,
      //   icon: null,
      //   visible: user_info.uid === "E2Fqqu9FWDgTdpOzdKQOKEdo6wA3" && GuideEditMode && GuidesOrPlaces === "Places",
      //   info: "This will write the guide to the local computer.",
      // },
      // {
      //   label: "Read Guide From File",
      //   method: readGuideFromFile,
      //   icon: null,
      //   visible: user_info.uid === "E2Fqqu9FWDgTdpOzdKQOKEdo6wA3" && GuideEditMode && GuidesOrPlaces === "Places",
      //   info: "This will read the guide from the local computer.",
      // },
      // {
      //   label: "Export Guide as KML",
      //   method: ExportGuideAsKML,
      //   icon: null,
      //   visible: user_info.uid === "E2Fqqu9FWDgTdpOzdKQOKEdo6wA3" && GuideEditMode && GuidesOrPlaces === "Places",
      //   info: "This will export the guide as kml to the local computer.",
      // },
      {
        label: "Create Journal from Photos",
        method: CreateJournalFromPhotos,
        icon: null,
        visible: true,
        info: "This will Create a Photo Journal from photos that have location and date info. First upload photos.",
      },
      {
        label: "Guide Creator",
        method: () => setTripAIShow(true),
        icon: null,
        visible: GuideEditMode,
        info: "Use artificial intelligence to automatically create a trip",
      },
      {
        label: "Rate this Guide",
        method: ReviewGuide,
        icon: null,
        visible: GuidesOrPlaces === "Places",
        info: "This will allow you to rate the current guide. Note: you need to first download the file. You can only rate the file once, subsequent attempts will allow you to edit the rating",
      },
      {
        label: "Guide Properties",
        method: showGuideInfo,
        icon: null,
        visible: GuidesOrPlaces === "Places",
        info: "Display the properties of the guide, e.g. name",
      },
      {
        label: "Email Guide Link",
        method: EmailGuideLink,
        icon: null,
        visible: GuidesOrPlaces === "Places",
        info: "Email the link to the current guide. This will allow others to view it. They cannot change it unless you have given them permission",
      },
      {
        label: "Display Guide Link",
        method: DisplayGuideLink,
        icon: null,
        visible: GuidesOrPlaces === "Places",
        info: "Display the link to the current guide",
      },
      {
        label: "Print/PDF",
        method: SavePDF,
        icon: null,
        visible: GuidesOrPlaces === "Places",
        info: "Save as PDF or Print",
      },
      {
        label: "Guide Settings",
        method: setGuideSettings,
        icon: null,
        visible: GuidesOrPlaces === "Places",
        info: "Set Guide settings to customize how the Guide looks and interacts",
      },
      {
        label: "Translate Guide",
        method: () => setGuideTranslateShow(true),
        icon: null,
        visible: GuidesOrPlaces === "Places",
        info: "Translate the Guide content to a different language",
      },

      // {
      //   label: "Hide/Show Map",
      //   method: HideShowMap,
      //   icon: null,
      //   visible: !MobileMode,
      //   info: "Hide the map, so that there is more room for the home page content.",
      // },
      // { label: "Whatsapp Guide Link", method: null, icon: null, visible: true },
    ];
    setMenuModalList(menuList);
    setMenuModalHeading("Guide Menu");
    setMenuModalImage(<i className="fa fa-book-open"></i>);
    setMenuModalPlace(null);
    setMenuModalShow(true);
  };

  const SaveMenu = () => {
    const menuList = [
      // {
      //   label: "Refresh Guide",
      //   method: RefreshGuide,
      //   icon: null,
      //   visible: GuidesOrPlaces === "Places",
      //   info: "Download the same guide to view. This will overwrite any changes to your guide.",
      // },
      // {
      //   label: "Previous Guide",
      //   method: PreviousGuide,
      //   icon: null,
      //   visible: GuideStack.length > 1,
      //   info: "If you load more than one guide you can go back to the previous guide loaded",
      // },
      {
        label: "Save Guide to Cloud",
        method: SaveGuide,
        icon: null,
        visible: GuidesOrPlaces === "Places",
        info: "This will create a new guide and open the new guide. The new guide name will be 'New Guide'",
      },
      {
        label: "New Guide",
        method: newGuide,
        icon: null,
        visible: GuidesOrPlaces === "Places",
        info: "This will create a new guide and open the new guide. The new guide name will be 'New Guide'",
      },
      {
        label: "Copy Guide",
        method: copyGuide,
        icon: null,
        visible: GuidesOrPlaces === "Places",
        info: "This will make a copy of the current guide and open the new guide. The new guide name will be the current guide name appended with '_C'",
      },
      {
        label: "Overwrite Guide",
        method: overWriteGuide,
        icon: null,
        visible: GuideEditMode && GuidesOrPlaces === "Places",
        info: "This will overwrite a selected guide with the contents of the current guide. It will preserve the ownership of the overwritten file",
      },
      // {
      //   label: "Write Guide To Device",
      //   method: writeGuideToFile,
      //   icon: null,
      //   visible: user_info.uid === "E2Fqqu9FWDgTdpOzdKQOKEdo6wA3" && GuideEditMode && GuidesOrPlaces === "Places",
      //   info: "This will write the guide to the local computer.",
      // },
      // {
      //   label: "Read Guide From Device",
      //   method: readGuideFromFile,
      //   icon: null,
      //   visible: user_info.uid === "E2Fqqu9FWDgTdpOzdKQOKEdo6wA3" && GuideEditMode && GuidesOrPlaces === "Places",
      //   info: "This will read the guide from the local computer.",
      // },
      {
        label: "Export Places as KML",
        method: ExportGuideAsKML,
        icon: null,
        visible: true,
        checked: false,
        info: "This will export the places as kml to the local computer.",
      },
      {
        label: "Import Places from KML/KMZ/GPX/TCX",
        method: () => setKMLInputShow(true),
        icon: null,
        visible: true,
        checked: false,
        info: "Read a KML, KMZ, GPX or TCX file and create places from it",
      },
      // {
      //   label: "Create Journal from Photos",
      //   method: CreateJournalFromPhotos,
      //   icon: null,
      //   visible: true,
      //   info: "This will Create a Photo Journal from photos that have location and date info. First upload photos.",
      // },
      // {
      //   label: "Rate this Guide",
      //   method: ReviewGuide,
      //   icon: null,
      //   visible: GuidesOrPlaces === "Places",
      //   info: "This will allow you to rate the current guide. Note: you need to first download the file. You can only rate the file once, subsequent attempts will allow you to edit the rating",
      // },
      // {
      //   label: "Guide Properties",
      //   method: showGuideInfo,
      //   icon: null,
      //   visible: GuidesOrPlaces === "Places",
      //   info: "Display the properties of the guide, e.g. name",
      // },
      // {
      //   label: "Email Guide Link",
      //   method: EmailGuideLink,
      //   icon: null,
      //   visible: GuidesOrPlaces === "Places",
      //   info: "Email the link to the current guide. This will allow others to view it. They cannot change it unless you have given them permission",
      // },
      // {
      //   label: "Display Guide Link",
      //   method: DisplayGuideLink,
      //   icon: null,
      //   visible: GuidesOrPlaces === "Places",
      //   info: "Display the link to the current guide",
      // },
      // {
      //   label: "Print/PDF",
      //   method: SavePDF,
      //   icon: null,
      //   visible: GuidesOrPlaces === "Places",
      //   info: "Save as PDF or Print",
      // },
      // {
      //   label: "Guide Settings",
      //   method: setGuideSettings,
      //   icon: null,
      //   visible: GuidesOrPlaces === "Places",
      //   info: "Set Guide settings to customize how the Guide looks and interacts",
      // },

      // {
      //   label: "Hide/Show Map",
      //   method: HideShowMap,
      //   icon: null,
      //   visible: !MobileMode,
      //   info: "Hide the map, so that there is more room for the home page content.",
      // },
      // { label: "Whatsapp Guide Link", method: null, icon: null, visible: true },
    ];
    setMenuModalList(menuList);
    setMenuModalHeading("Read/Write Guide Menu");
    setMenuModalImage(<i className="fa fa-book-open"></i>);
    setMenuModalPlace(null);
    setMenuModalShow(true);
  };

  const CreateJournalFromPhotos = () => {
    setImageDialogMode("select");
    setImageDialogExif(true);
    setImageDialogShow(true);
  };

  const updateImageCallBackOld = (images) => {
    //console.log(images);
    let schedule = "";
    let scheds = window.guide_obj.schedule.geScheduleNames();
    for (let s = 1; s < 99999; s++) {
      schedule = `Photo${s}`;
      let found = false;
      for (let x = 1; x < scheds.length; x++) {
        if (schedule === scheds[x].label) found = true;
      }
      if (found === false) break;
    }
    let num_exif = 0;
    let exif_images = [];
    for (let i = 0; i < images.length; i++) {
      if (typeof images[i].exif_data === "undefined") continue;

      if (
        typeof images[i].exif_data.date === "undefined" ||
        typeof images[i].exif_data.longitude === "undefined" ||
        typeof images[i].exif_data.latitude === "undefined"
      )
        continue;
      exif_images.push(images[i]);
      num_exif++;
      exif_images.sort((a, b) => {
        const at = DateTime.fromISO(a.exif_data.date, { zone: "utc" });
        const bt = DateTime.fromISO(b.exif_data.date, { zone: "utc" });
        return at < bt ? -1 : at > bt ? 1 : 0;
      });
      // Add Place
      let place = addPlaceToMap(
        "Icon",
        { Lat: images[i].exif_data.latitude, Longi: images[i].exif_data.longitude },
        null,
        {
          name: images[i].name === "" ? "New Place" : images[i].name,
          info: "",
          imgURL: images[i].url_m,
          imgURL_t: images[i].url,
          poi_img: "208",
          // Group: "21",
          // Category: "117",
        }
      );
      let d = DateTime.fromISO(images[i].exif_data.date);
      let image_date = DateTime.fromObject(
        { year: d.year, month: d.month, day: d.day, hour: d.hour, minute: d.minute, second: d.second },
        { zone: "utc" }
      );
      d = DateTime.fromISO(images[i].exif_data.date);
      let n = i + 1;
      while (n < images.length - 1 && typeof images[n].exif_data === "undefined") n++;
      if (n < images.length - 1) d = DateTime.fromISO(images[n].exif_data.date);
      let next_image_date = DateTime.fromObject(
        { year: d.year, month: d.month, day: d.day, hour: d.hour, minute: d.minute, second: d.second },
        { zone: "utc" }
      );
      let diff = next_image_date.diff(image_date);
      let event = new Appointment(window.guide_obj.schedule);
      event.Subject = images[i].name;
      event.Body = "";
      event.Itinerary = schedule;
      event.Start = image_date.toISO();
      event.End = image_date.plus({ milliseconds: diff }).toISO();
      if (!event.Places.includes(place.ID)) event.Places = place.ID;
      window.guide_obj.schedule.addEvent(event);
    }
    if (num_exif === 0) {
      myAlert("No photos had location and date info", "Message");
      return;
    } else if (num_exif !== images.length) myAlert("Some photos had no location or date info", "Message");
    dispatch(slice1Actions.setScheduleName({ value: schedule }));
    dispatch(slice1Actions.setGuide({ guide: null, fitToBounds: true }));
  };

  const updateImageCallBack = (images) => {
    //console.log(images);
    let schedule = "";
    let scheds = window.guide_obj.schedule.geScheduleNames();
    for (let s = 1; s < 99999; s++) {
      schedule = `Photo${s}`;
      let found = false;
      for (let x = 1; x < scheds.length; x++) {
        if (schedule === scheds[x].label) found = true;
      }
      if (found === false) break;
    }
    let num_exif = 0;
    let exif_images = [];
    for (let i = 0; i < images.length; i++) {
      if (typeof images[i].exif_data === "undefined") continue;

      if (
        typeof images[i].exif_data.date === "undefined" ||
        typeof images[i].exif_data.longitude === "undefined" ||
        typeof images[i].exif_data.latitude === "undefined"
      )
        continue;
      exif_images.push(images[i]);
      num_exif++;
    }
    exif_images.sort((a, b) => {
      const at = DateTime.fromISO(a.exif_data.date);
      const bt = DateTime.fromISO(b.exif_data.date);
      return at < bt ? -1 : at > bt ? 1 : 0;
    });
    for (let i = 0; i < exif_images.length; i++) {
      // Add Place
      let _name = exif_images[i].name === "" ? "New Place" : exif_images[i].name;
      if (_name.toUpperCase().includes(".JPG")) _name = _name.slice(0, -4);
      let place = addPlaceToMap(
        "Icon",
        { Lat: exif_images[i].exif_data.latitude, Longi: exif_images[i].exif_data.longitude },
        null,
        {
          name: _name,
          info: "",
          imgURL: exif_images[i].url_m,
          imgURL_t: exif_images[i].url,
          poi_img: "208",
          // Group: "21",
          // Category: "117",
        }
      );
      //let image_date = DateTime.fromISO(exif_images[i].exif_data.date);
      let _date = exif_images[i].exif_data.date;
      let _date_parts = _date.split(":");
      if (_date_parts[_date_parts.length - 2].includes("+") || _date_parts[_date_parts.length - 2].includes("-")) {
        _date = _date.substring(0, _date.length - 5);
        _date = _date + "00:00";
      }
      let image_date = DateTime.fromISO(_date);
      let n = i + 1;
      if (i === exif_images.length - 1) n = i;
      //let next_image_date = DateTime.fromISO(exif_images[i].exif_data.date);
      _date = exif_images[i].exif_data.date;
      _date_parts = _date.split(":");
      if (_date_parts[_date_parts.length - 2].includes("+") || _date_parts[_date_parts.length - 2].includes("-")) {
        _date = _date.substring(0, _date.length - 5);
        _date = _date + "00:00";
      }
      let next_image_date = DateTime.fromISO(_date);
      let diff = next_image_date.diff(image_date);
      let diff_mill = diff.valueOf();
      console.log("diff < 0", i, diff_mill, image_date, next_image_date);
      let event = new Appointment(window.guide_obj.schedule);
      event.Subject = _name;
      event.Body = "";
      event.Itinerary = schedule;
      event.Start = image_date.toISO();
      event.End = image_date.plus({ milliseconds: diff_mill }).toISO();
      if (!event.Places.includes(place.ID)) event.Places = place.ID;
      window.guide_obj.schedule.addEvent(event);
    }
    if (num_exif === 0) {
      myAlert("No photos had location and date info", "Message");
      return;
    } else if (num_exif !== exif_images.length) myAlert("Some photos had no location or date info", "Message");

    const main_events = window.guide_obj.schedule.getEventsFromSchedule("Main");
    if (main_events.length === 0) window.guide_obj.schedule.copySchedule(schedule, "Main");

    if (
      window.guide_obj.document == "" ||
      window.guide_obj.document == null ||
      window.guide_obj.document == "<!-- Comment -->"
    ) {
      const html = window.guide_obj.createHTMLItinerary(schedule, GuideSettings.itinerary);
      window.guide_obj.document = html;
      dispatch(slice1Actions.resetHomeScreen({ value: null }));
    }

    dispatch(slice1Actions.setScheduleName({ value: schedule }));
    dispatch(slice1Actions.setGuide({ guide: null, fitToBounds: true }));
  };

  const addPlaceToMap = (PType, Point, Points, params) => {
    let shape = "New Place";
    if (PType === "Polygon") shape = "New Area";
    else if (PType === "PolyLine") shape = "New Path";
    let new_place = new Place(window.guide_obj.places);
    new_place.Name = `${shape}`;
    if (params != null) {
      new_place.Name = params.name;
      new_place.Info = params.info;
      new_place.Photo_m = params.imgURL;
      if (typeof params.imgURL_t !== "undefined") new_place.Photo = params.imgURL_t;
      if (typeof params.poi_img !== "undefined") new_place.poi_img = params.poi_img;
      // if (typeof params.Group !== "undefined") new_place.Group = [params.Group];
      // if (typeof params.Category !== "undefined") new_place.Category = [params.Category];
    }
    // console.log("_mapCoordinate", _mapCoordinate);
    new_place.Lat = Point.Lat; //_mapCoordinate.Lat;
    new_place.Longi = Point.Longi; //_mapCoordinate.Longi;
    new_place.PType = PType;
    new_place.Points = Points;
    if (PType === "Icon") new_place.Points = null;
    // console.log(new_place);
    //this.props.onAddPlace(new_place);
    window.guide_obj.places.addPlace(new_place);

    let _ShowHiddenPlace = JSON.parse(JSON.stringify(showHiddenPlace));
    _ShowHiddenPlace.push(new_place);
    dispatch(slice1Actions.setShowHiddenPlace({ value: _ShowHiddenPlace }));

    //dispatch(slice1Actions.setShowHiddenPlace({ value: [new_place] }));
    dispatch(slice1Actions.setGuide({ guide: null }));
    return new_place;
  };

  const ExportGuideAsKML = () => {
    const kml = window.guide_obj.createKML();
    console.log("kml", kml);
    const file = new Blob([kml], { type: "text/plain" });
    const element = document.createElement("a");
    element.href = URL.createObjectURL(file);
    element.download = "Guide.kml";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  const writeGuideToFile = () => {
    if (window.guide_obj.isAuthorized(Authorization, AUTHORIZATION_LEVEL.MODIFY) === false) {
      myAlert("Not authorized to save this guide");
      return;
    }
    const guide_obj_write = {
      pass: true,
      error: "",
      guide_id: guide_meta.guide_id,
      res: window.guide_obj,
      infoDoc: window.guide_obj.attributes,
      guide_info_id: guide_meta.guide_info_id,
      owner_info: owner_info,
      creator_info: creator_info,
    };
    const file = new Blob([JSON.stringify(guide_obj_write)], { type: "text/plain" });
    const element = document.createElement("a");
    element.href = URL.createObjectURL(file);
    element.download = "Guide.json";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  const readGuideFromFile = () => {
    const element = document.getElementById("guide-file-upload");
    element.click();
  };

  const readLocalFile = (e) => {
    //console.log(e.target.files);
    var fr = new FileReader();
    fr.onload = function () {
      try {
        //console.log(fr.result);
        const guide_obj_read = JSON.parse(fr.result);
        // if(guide_obj_read.owner_info.uid !== user_info.uid) {
        //   myAlert("Not authorized to read this guide");
        //   return;
        // }
        let data = JSON.stringify(guide_obj_read.res);
        let guide_part = { data: data };
        const params = {
          pass: true,
          error: "",
          guide_id: guide_obj_read.guide_id,
          res: guide_part,
          infoDoc: guide_obj_read.infoDoc,
          guide_info_id: guide_obj_read.guide_info_id,
          owner_info: guide_obj_read.owner_info,
          creator_info: guide_obj_read.creator_info,
        };
        readGuideDataReturnNew(params);
      } catch (e) {
        myAlert("Error reading guide");
      }
    };
    fr.readAsText(e.target.files[0]);
  };

  const handleContentFontZoom = () => {
    let zoomList = [];
    for (let i = 50; i <= 300; i += 10) {
      zoomList.push({ id: i.toString(), label: i.toString(), value: i, name: i.toString() });
    }
    setMultiselectItems(zoomList);
    setMultiselectSelectedItems(ContentFontZoom);
    setMultiselectMode("uni");
    setMultiselectTitle(`Select Zoom`);
    setShowMultiselect(true);
  };

  const handleContentFontZoomCallback = (zoom) => {
    dispatch(slice1Actions.setContentFontZoom({ value: zoom[0].value }));
  };

  const setGuideSettings = (value) => {
    setGuideSettingsDialogShow(true);
    return;
    let guide_options = JSON.parse(JSON.stringify(GuideSettings));
    if (guide_options.mode === "edit") guide_options.mode = "view";
    else if (guide_options.mode === "view") guide_options.mode = "custom_view";
    else guide_options.mode = "edit";
    dispatch(slice1Actions.setGuideSettings({ value: guide_options }));
    dispatch(slice1Actions.setGuide({ guide: null }));
  };

  const SavePDFxxx = () => {
    // const doc = new jsPDF();
    var doc = new jsPDF("l", "mm", [1200, 1810]);
    // doc.text("Hello world!", 10, 10);
    const htmlString = HTMLStack[HTMLStack.length - 1];

    // doc.text(htmlString, 10, 10);
    doc.html(htmlString, {
      callback: () => {
        doc.save("guide.pdf");
      },
      x: 10,
      y: 10,
    });
  };

  const SavePDF = () => {
    var printWindow = window.open("", "", "height=400,width=800");
    printWindow.document.write(`<html><head><title>${guide_meta.guide_info.guide_name}</title>`);
    printWindow.document.write("</head><body >");

    var element = document.getElementById("home-content");
    var divContents = "";
    if (element != null) {
      divContents = element.innerHTML;
      printWindow.document.write(divContents);
    }

    // element = document.getElementById("map-start");
    // divContents = "";
    // if (element != null) {
    //   divContents = element.innerHTML;
    //   printWindow.document.write(divContents);
    // }

    // printWindow.document.write("<h3><b>Places</b></h3></br>");
    // element = document.getElementById("PlacesDiv");
    // divContents = "";
    // if (element != null) {
    //   divContents = element.innerHTML;
    //   printWindow.document.write(divContents);
    // }

    // printWindow.document.write("<h3><b>Schedule</b></h3></br>");
    // element = document.getElementById("ScheduleDiv");
    // divContents = "";
    // if (element != null) {
    //   divContents = element.innerHTML;
    //   printWindow.document.write(divContents);
    // }

    printWindow.document.write("</body></html>");
    printWindow.document.close();
    setTimeout(() => printWindow.print(), 2000);

    return;
    // setPDFModalShow(true);
    // return;

    const input = document.getElementById("home-content");
    html2canvas(input, {
      scale: 0.8,
      useCORS: true,
      etterRendering: 1,
      allowTaint: true,
      onrendered: SavePDFComplete,
    }).then((canvas) => {
      var imgWidth = 200;
      var pageHeight = 2900;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      var heightLeft = imgHeight;
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      var position = 0;
      var heightLeft = imgHeight;
      pdf.addImage(imgData, "JPEG", 0, position, imgWidth, imgHeight);
      pdf.save("Guide.pdf");
    });
  };

  const SavePDFComplete = () => {
    myAlert("PDF processed", "Message");
  };

  let _attributes = null;

  const RefreshGuide = () => {
    myConfirm("Reload this Guide from cloud? This will overwrite any changes.", [
      { Title: "OK", Method: () => _RefreshGuide() },
      { Title: "CANCEL", Method: null },
    ]);
  };

  const _RefreshGuide = () => {
    loadGuide(guide_meta.guide_info_id);
  };

  const ReviewGuide = () => {
    if (Authorization === AUTHORIZATION_LEVEL.GUEST) {
      myAlert("Cannot rate a guides in guest mode. You must be registered and logged in", "Message");
      return;
    }
    setReviewDialogShow(true);
  };

  const PreviousGuide = () => {
    if (GuideStack.length < 1) {
      dispatch(slice1Actions.clearGuideStack());
      return;
    }
    if (GuideStack.length < 2) return;
    let guide_id = GuideStack[GuideStack.length - 2];
    dispatch(slice1Actions.popGuideStack({ value: null }));
    loadGuide(guide_id);
  };

  const newGuide = () => {
    if (Authorization === AUTHORIZATION_LEVEL.GUEST) {
      myAlert("Cannot create new guides in guest mode. You must be registered and logged in", "Message");
      return;
    }
    myConfirm("This will create a new guide. Save any wanted changes to this guide first. Continue?", [
      { Title: "OK", Method: () => _newGuide() },
      { Title: "CANCEL", Method: null },
    ]);
  };

  const _newGuide = () => {
    dispatch(slice1Actions.SetLoadingGuide({ value: true }));
    setTimeout(timeoutFunc, 5000);
    let new_guide_attributes = new GuideAttributes("New Guide", "", null, "English", 0, [], []);
    newGuideCallback(new_guide_attributes);
  };

  const newGuideCallback = (guideAttributes) => {
    _attributes = guideAttributes;
    let GuideObj = new Guide("new");
    GuideObj.decodeGuideFb(GuideObj, _attributes);
    GuideObj.user_id = user_info.uid;
    DataBase.batchedWriteGuide(GuideObj, null, null, user_info, "new", addGuideDataCallbackNewGuide);
  };

  const addGuideDataCallbackNewGuide = (res) => {
    if (res.pass !== true) {
      dispatch(slice1Actions.SetLoadingGuide({ value: false }));
      return;
    }
    window.record_last_guide_read = true;
    DataBase.readGuideTransaction(res.new_info_ref.id, readGuideDataReturnNew, user_info.uid);
  };

  const copyGuide = () => {
    if (Authorization === AUTHORIZATION_LEVEL.GUEST) {
      myAlert("Cannot copy guides in guest mode. You must be registered and logged in");
      return;
    }
    if (window.guide_obj.isAuthorized(Authorization, AUTHORIZATION_LEVEL.COPY) === false) {
      myAlert("You are not authorized to copy this guide");
      return;
    }
    myConfirm(
      "Make a copy of this Guide? This will make a copy of the guide, and open the copy. First save any changes that you want in the original copy.",
      [
        { Title: "OK", Method: () => _batchedCopyGuide() },
        { Title: "CANCEL", Method: null },
      ]
    );
  };

  let _copiedGuideID = null;

  const _batchedCopyGuide = () => {
    dispatch(slice1Actions.SetLoadingGuide({ value: true }));
    setTimeout(timeoutFunc, 5000);
    _copiedGuideID = window.guide_obj.guid_id;
    DataBase.batchedWriteGuide(window.guide_obj, null, null, user_info, "copy", batchCopyGuideCallback);
  };

  const batchCopyGuideCallback = (res) => {
    dispatch(slice1Actions.SetLoadingGuide({ value: false }));
    if (res.pass !== true) {
      return;
    }
    // this.setState({
    //   new_guide_id: res.new_doc_ref.id,
    //   new_guide_info: res.new_info_ref.id,
    //   guide_info_id: res.new_info_ref.id,
    // });
    window.record_last_guide_read = true;
    DataBase.readGuideTransaction(res.new_info_ref.id, readGuideDataReturnNew, user_info.uid);
    DataBase.incrementCopiesTransaction(_copiedGuideID, incrementCopiesTransaction);
  };

  const incrementCopiesTransaction = () => {};
  //let download_type = "read";

  const overWriteGuide = () => {
    if (window.guide_obj.isAuthorized(Authorization, AUTHORIZATION_LEVEL.MODIFY) === false) {
      myAlert("Not authorized to modify this guide");
      return;
    }
    if (Authorization === AUTHORIZATION_LEVEL.GUEST) {
      myAlert("Cannot overwrite guides in guest mode. You must be registered and logged in");
      return;
    }
    myConfirm("This will update content of another guide with this guide. Continue?", [
      { Title: "OK", Method: () => selectGuideToOverWrite() },
      { Title: "CANCEL", Method: null },
    ]);
  };

  const selectGuideToOverWrite = () => {
    setGuideDownloadType("overwrite");
    setGuideDownloadMode("list");
    setGuideDownloadShow(true);
  };

  const _overWriteGuide = (guide_info_id, guide_id) => {
    console.log("overwriting guide_id:", guide_id);
    dispatch(slice1Actions.SetLoadingGuide({ value: true }));
    setTimeout(timeoutFunc, 5000);
    DataBase.batchedWriteGuide(
      window.guide_obj,
      guide_id,
      guide_info_id,
      user_info,
      "overwrite",
      overWriteGuideCallback,
      guide_id,
      guide_info_id
    );
  };

  const overWriteGuideCallback = (res) => {
    dispatch(slice1Actions.SetLoadingGuide({ value: false }));
    if (res.pass === true) myAlert("Guide overwritten");
  };

  const ShowGuidesOrGuide = (guides_or_places, GuideType) => {
    let temp = guides_or_places === "Guides" ? "Places" : "Guides";
    if (guides_or_places === GuidesOrPlaces) {
      ShowGuidesOrGuideTimeout(temp, GuideType);
      setTimeout(() => ShowGuidesOrGuideTimeout(guides_or_places, GuideType), 100);
    } else ShowGuidesOrGuideTimeout(guides_or_places, GuideType);
  };

  const ShowGuidesOrGuideTimeout = (guides_or_places, GuideType) => {
    let Hide = guides_or_places === "Guides" ? true : false;
    dispatch(slice1Actions.setGuidesOrPlaces({ value: guides_or_places, GuideType: GuideType }));
    //window.GuidesOrPlaces = newValue;
    dispatch(slice1Actions.setHideSchedule({ value: Hide }));
    if (guides_or_places === "Guides") {
      dispatch(slice1Actions.setHideMap({ value: false }));
      dispatch(slice1Actions.setHidePlaces({ value: false }));
      setTimeout(() => ShowSelectGuide(), 2000);
    } else {
      dispatch(slice1Actions.resetHomeScreen({ value: null }));
    }
    dispatch(slice1Actions.setGuide({ guide: null, fitToBounds: true }));
    //setTimeout(updateMap, 1000);
  };

  const ShowSelectGuide = () => {
    console.log("ShowSelectGuide");
    let htmlString = `<div>
    <div style="text-align: center;"><br /><strong><span style="font-size: 220%;">Select guide from List or from map</span></strong></div>
    </div>`;
    window.location.href = "#home-start";
    var homeDiv = document.getElementById("home-content-start");
    homeDiv.scrollIntoView();
    dispatch(slice1Actions.setHTMLTitle({ value: "Featured Guides" }));
    dispatch(slice1Actions.setHTMLToSHow({ value: htmlString }));
    dispatch(slice1Actions.setShowHTMLReader({ value: true }));
    window.location.href = "#home-start";
    var homeDiv = document.getElementById("home-content-start");
    homeDiv.scrollIntoView();
  };

  const GuidesInMap = () => {
    let newValue = GuidesOrPlaces === "Guides" ? "Places" : "Guides";
    let newType = GuidesOrPlaces === "Guides" ? "None" : GuideType;
    let Hide = newValue === "Guides" ? true : false;
    dispatch(slice1Actions.setGuidesOrPlaces({ value: newValue, GuideType: "Featured" }));
    //window.GuidesOrPlaces = newValue;
    dispatch(slice1Actions.setHideSchedule({ value: Hide }));
    if (newValue === "Guides") {
      dispatch(slice1Actions.setHideMap({ value: false }));
      dispatch(slice1Actions.setHidePlaces({ value: false }));
    }
    dispatch(slice1Actions.setGuide({ guide: null, fitToBounds: true }));
    //setTimeout(updateMap, 1000);
  };

  const HideShowPlaces = () => {
    dispatch(slice1Actions.setHidePlaces({ value: !HidePlaces }));
  };

  const HideShowSchedule = () => {
    dispatch(slice1Actions.setHidePlaces({ value: !HideSchedule }));
  };

  const HideShowMap = () => {
    dispatch(slice1Actions.setHidePlaces({ value: !HideMap }));
  };

  // const HideShowMap = () => {
  //   dispatch(slice1Actions.setHomeFullScreen({ value: !HomeFullScreen }));
  // };

  // const updateMap = () => {
  //   dispatch(slice1Actions.setGuide({ guide: null, fitToBounds: true }));
  // };

  const DownloadGuide = (Category) => {
    setGuideDownloadCategory(Category);
    setGuideDownloadType("read");
    setGuideDownloadMode("download");
    setGuideDownloadShow(true);
  };

  const GuideDownloadCallback = (guide_info_id, guide_id) => {
    //if (GuideDownloadMode === "list") return;
    if (GuideDownloadType === "overwrite") _overWriteGuide(guide_info_id, guide_id);
    else _DownloadGuide(guide_info_id, guide_id);
  };

  const _DownloadGuide = (guide_info_id, guide_id) => {
    console.log("GuideDownloadCallback id:", guide_info_id);
    loadGuide(guide_info_id);

    let _user_info = JSON.parse(JSON.stringify(user_info));
    _user_info.last_guide_id = guide_info_id;
    _user_info.last_guide_time = DateTime.now().toISO({ zone: "utc" });
    dispatch(slice1Actions.setUserInfo({ value: _user_info }));
    DataBase.updateUser(_user_info, _user_info.uid, updateUserCallback);
  };

  const recordLastGuideRead = (guide_info_id) => {
    let _user_info = JSON.parse(JSON.stringify(user_info));
    _user_info.last_guide_id = guide_info_id;
    _user_info.last_guide_time = DateTime.now().toISO({ zone: "utc" });
    dispatch(slice1Actions.setUserInfo({ value: _user_info }));
    DataBase.updateUser(_user_info, _user_info.uid, updateUserCallback);
  };

  const updateUserCallback = (res) => {
    console.log("updateUserCallback", res.pass);
  };

  const showGuideInfo = () => {
    //setGuideInfoModal(true);
    setGuideEditModalMode("VIEW");
    setGuideEditModalShow(true);
  };

  const EmailGuideLink = () => {
    document.location.href =
      "mailto:?subject=" +
      encodeURIComponent(`Link to guide`) +
      "&body=" +
      encodeURIComponent(
        `Use this link for the web version: https://andiamoplaces.com?id=${guide_meta.guide_info_id} or download the mobile app`
      );
  };

  const DisplayGuideLink = () => {
    myAlert(`Guide:link: https://andiamoplaces.com?id=${guide_meta.guide_info_id}`);
  };

  const EditMenu = () => {
    const menuList = [
      {
        label: "Edit Home Content",
        method: HTMLEdit,
        icon: null,
        visible: GuideEditMode,
        info: "Edit the main page of the guide. The content is in HTML",
      },
      {
        label: "Edit Chapters/Sections",
        method: EditSections,
        icon: null,
        visible: GuideEditMode,
        info: "Edit the secondary pages of the guide, which are organized by chapters. These pages are in HTML and can be linked to from other pages",
      },
      {
        label: "Edit Groups",
        method: EditGroups,
        icon: null,
        visible: GuideEditMode,
        info: "Edit the groups of the guide. Groups are used to group places that share a common trait e.g. all places in the same neighborhood",
      },

      {
        label: "Edit Categories",
        method: EditCategories,
        icon: null,
        visible: GuideEditMode,
        info: "Edit the categories of the guide. The categories are used to categorize places, e.g. all restaurants that are vegeterian",
      },
      // {
      //   label: "Edit Places of Interest",
      //   method: EditCategories,
      //   icon: null,
      //   visible: GuideEditMode,
      //   info: "Edit the Places of Interest. This is a list of Places that you may want to visit. The list helps you remember and organize them",
      // },
      {
        label: "Custom Map Markers",
        method: editCustomIcons,
        icon: null,
        visible: GuideEditMode,
        info: "Edit the custom map markers. You can supply the URL to custom images that you can use for map markers",
      },
      // { label: "Set Places Category", method: SetCategory, icon: null, visible: true },
      // { label: "Set Places Group", method: SetGroup, icon: null, visible: true },
      // { label: "Set Favorite Places", method: EditFavorite, icon: null, visible: true },
      // { label: "Set Visited Places", method: EditVisited, icon: null, visible: true },
      // { label: "Set Must See Places", method: EditMustSee, icon: null, visible: true },
      // { label: "Set Hidden", method: EditHidden, icon: null, visible: true },
      // { label: "Delete Places", method: DeletePlaces, icon: null, visible: true },
      {
        label: "Edit Guide Properties",
        method: EditGuideInfo,
        icon: null,
        visible: true,
        info: "Edit the attributes of a guide, e.g. guide name",
      },
    ];
    setMenuModalList(menuList);
    setMenuModalHeading("Edit Menu");
    setMenuModalImage(<i className="fa fa-pencil"></i>);
    setMenuModalPlace(null);
    setMenuModalShow(true);
  };

  const EditGroups = () => {
    setGroupsDialogShow(true);
  };

  const EditPlacesOfInterest = () => {
    setPlacesOfInterestShow(true);
  };

  const editCustomIcons = () => {
    setCustomIconsShow(true);
  };

  const EditCategories = () => {
    setCategoriesDialogShow(true);
  };

  const EditSections = () => {
    setSectionsDialogShow(true);
  };

  let _place_operation = "";

  // const SetCategory = () => {
  //   _place_operation = "Category";
  //   myAlert("Add to or replace Category?", "Add or Replace", [
  //     { Title: "Add", Method: _Add },
  //     { Title: "Replace", Method: _Replace },
  //     { Title: "Cancel", Method: null },
  //   ]);
  // };

  // const SetGroup = () => {
  //   _place_operation = "SetGroup";
  //   myAlert("Add to or replace Group?", "Add or Replace", [
  //     { Title: "Add", Method: _Add },
  //     { Title: "Replace", Method: _Replace },
  //     { Title: "Cancel", Method: null },
  //   ]);
  // };

  // let AddReplaceMode = "Add";

  // const _Add = () => {
  //   AddReplaceMode = "Add";
  //   selectPlaces();
  // };

  // const _Replace = () => {
  //   AddReplaceMode = "Replace";
  //   selectPlaces();
  // };

  // const DeletePlaces = () => {
  //   _place_operation = "Delete Places";
  //   selectPlaces();
  // };

  // const EditFavorite = () => {
  //   _place_operation = "Favorite";
  //   selectPlaces();
  // };

  // const EditVisited = () => {
  //   _place_operation = "Visited";
  //   selectPlaces();
  // };

  // const EditMustSee = () => {
  //   _place_operation = "MustSee";
  //   selectPlaces();
  // };

  // const EditHidden = () => {
  //   _place_operation = "Hidden";
  //   selectPlaces();
  // };

  const EditGuideInfo = () => {
    setGuideEditModalMode("UPDATE");
    setGuideEditModalShow(true);
  };

  // const selectPlaces = () => {
  //   let selectedPlaces = [];
  //   let places = window.guide_obj.places.getPlacesToDisplay(null);
  //   let count = 0;
  //   places.forEach((place) => {
  //     if (
  //       (_place_operation === "Favorite" && place.Favorite === true) ||
  //       (_place_operation === "Visited" && place.Visited === true) ||
  //       (_place_operation === "MustSee" && place.MustSee === true) ||
  //       (_place_operation === "Hidden" && place.Hidden === true) ||
  //       _place_operation === "Category" ||
  //       _place_operation === "SetGroup" ||
  //       _place_operation === "Delete Places"
  //     ) {
  //       selectedPlaces.push({
  //         label: place.name,
  //         name: place.name,
  //         value: place.value,
  //       });
  //       count++;
  //     }
  //   });
  //   //console.log("selectedPlaces, count", selectedPlaces, count);
  //   dispatch(slice1Actions.setMultiselectCallback({ value: selectPlacesCallBack }));
  //   dispatch(slice1Actions.setMultiselectItems({ value: places }));
  //   dispatch(slice1Actions.setMultiselectSelectedItems({ value: selectedPlaces }));
  //   dispatch(slice1Actions.setMultiselectMode({ value: "multi" }));
  //   dispatch(slice1Actions.setMultiselectSearchText({ value: "" }));
  //   dispatch(slice1Actions.setShowMultiselect({ value: true }));
  //   // props.navigation.navigate("MultiSelectDialog", {
  //   //   selectionList: places,
  //   //   selectedItems: selectedPlaces,
  //   //   callBack: selectPlacesCallBack,
  //   //   title: "Places",
  //   //   selectText: "Select Places",
  //   // });
  // };

  // let places_selected = [];

  // const selectPlacesCallBack = (Places) => {
  //   if (Places == null) return;
  //   places_selected = Places;
  //   if (_place_operation === "Category") {
  //     if (places_selected.length > 0) setTimeout(selectCategory, 500);
  //     return;
  //   }
  //   if (_place_operation === "SetGroup") {
  //     if (places_selected.length > 0) setTimeout(selectGroup, 500);
  //     return;
  //   }
  //   if (_place_operation === "Delete Places") {
  //     places_selected.forEach((place_id) => {
  //       window.guide_obj.places.deletePlaceById(place_id.value);
  //     });
  //     dispatch(slice1Actions.setGuide({ guide: null }));
  //     return;
  //   }

  //   window.guide_obj.places.setPlaceAttributes(Places, _place_operation);
  //   UpdateDisplay();
  // };

  // const selectCategory = () => {
  //   let _ChooseCategoriesList = window.guide_obj.getCategoriesToDisplay();
  //   // _ChooseCategoriesList.forEach((cat) => {
  //   //   cat.icon = <img src={Place.getItemPOIImage(cat.icon)} />;
  //   // });
  //   dispatch(slice1Actions.setMultiselectCallback({ value: SelectCategoryCallBack }));
  //   dispatch(slice1Actions.setMultiselectItems({ value: _ChooseCategoriesList }));
  //   dispatch(slice1Actions.setMultiselectSelectedItems({ value: [] }));
  //   dispatch(slice1Actions.setMultiselectMode({ value: "uni" }));
  //   dispatch(slice1Actions.setMultiselectSearchText({ value: "" }));
  //   dispatch(slice1Actions.setShowMultiselect({ value: true }));
  // };

  // const SelectCategoryCallBack = (category) => {
  //   places_selected.forEach((place_id) => {
  //     let place = window.guide_obj.places.getPlace(place_id.value);
  //     if (place == null) return;
  //     if (AddReplaceMode === "Add") place.Category.push(category[0].id);
  //     else place.Category = [category[0].id];
  //   });
  //   console.log("SelectCategoryCallBack", places_selected, category);
  //   dispatch(slice1Actions.setGuide({ guide: null }));
  // };

  // const selectGroup = () => {
  //   let _ChooseGroupsList = window.guide_obj.getGroupsToDisplay();
  //   dispatch(slice1Actions.setMultiselectCallback({ value: SelectGroupCallBack }));
  //   dispatch(slice1Actions.setMultiselectItems({ value: _ChooseGroupsList }));
  //   dispatch(slice1Actions.setMultiselectSelectedItems({ value: [] }));
  //   dispatch(slice1Actions.setMultiselectMode({ value: "uni" }));
  //   dispatch(slice1Actions.setMultiselectSearchText({ value: "" }));
  //   dispatch(slice1Actions.setShowMultiselect({ value: true }));
  // };

  // const SelectGroupCallBack = (groups) => {
  //   places_selected.forEach((place_id) => {
  //     let place = window.guide_obj.places.getPlace(place_id.value);
  //     if (place == null) return;
  //     if (AddReplaceMode === "Add") place.Group.push(groups[0].id);
  //     else place.Group = [groups[0].id];
  //   });
  //   console.log("SelectCategoryCallBack", places_selected, groups);
  //   dispatch(slice1Actions.setGuide({ guide: null }));
  // };

  const UpdateDisplay = () => {
    dispatch(slice1Actions.setGuide({ guide: null }));
  };

  const GuideEditModalCallback = (guide_attributes, edit_mode) => {
    if (edit_mode === true) {
      setGuideEditModalMode("UPDATE");
      setGuideEditModalShow(true);
      return;
    }
    if (GuideEditModalMode === "UPDATE")
      dispatch(slice1Actions.setGuide({ guide: null, update_guide_attributes: true }));
  };

  const InfoMenu = () => {
    const menuList = [
      {
        label: "About",
        method: handleAbout,
        icon: null,
        visible: true,
        info: "List information about this application",
      },
      {
        label: "Help Videos",
        method: handleHelpVideos,
        icon: null,
        visible: true,
        info: "View videos that explain how to perform certain functions with the app",
      },
      {
        label: "FAQs",
        method: handleFAQs,
        icon: null,
        visible: true,
        info: "Lists Frequently asked Questions (FAQs) about this app",
      },
      {
        label: "Help",
        method: handleDocumentation,
        icon: null,
        visible: true,
        info: "Show the help document for this app",
      },
      {
        label: "Guidelines",
        method: handleGuidelines,
        icon: null,
        visible: true,
        info: "Lists how users of the app should behave and what is not allowed.",
      },
      {
        label: "Web Site",
        method: WebSite,
        icon: null,
        visible: true,
        info: "Link to our web site",
      },
      {
        label: "Contact Us",
        method: handleContactUs,
        icon: null,
        visible: true,
        info: "Send us an email with questions or bug reports",
      },
    ];
    setMenuModalList(menuList);
    setMenuModalHeading("Info Menu");
    setMenuModalImage(<i className="fas fa-info"></i>);
    setMenuModalPlace(null);
    setMenuModalShow(true);
  };

  function handleAbout() {
    let htmlString = AppSettings.about;
    //htmlString = urlSchemeToFunctions(htmlString);
    dispatch(slice1Actions.setHTMLTitle({ value: "About" }));
    dispatch(slice1Actions.setHTMLToSHow({ value: htmlString }));
    dispatch(slice1Actions.setShowHTMLReader({ value: true }));
    window.location.href = "#home-start";
    var homeDiv = document.getElementById("home-content-start");
    homeDiv.scrollIntoView();
  }

  function WebSite() {
    const _url = "https://andiamotravelapp.com/";
    let win = window.open(_url, "_blank");
    win.focus();
  }

  function handleHelpVideos() {
    let htmlString = AppSettings.help_videos;
    dispatch(slice1Actions.setHTMLTitle({ value: "Help Videos" }));
    dispatch(slice1Actions.setHTMLToSHow({ value: htmlString }));
    dispatch(slice1Actions.setShowHTMLReader({ value: true }));

    // let win = window.open("about:blank", "", "_blank");
    // win.document.write(htmlString);
    // win.focus();
    window.location.href = "#home-start";
    var homeDiv = document.getElementById("home-content-start");
    homeDiv.scrollIntoView();
  }

  function handleDocumentation() {
    let htmlString = AppSettings.help_document_web;
    //htmlString = urlSchemeToFunctions(htmlString);
    dispatch(slice1Actions.setHTMLTitle({ value: "Creating a Guide" }));
    dispatch(slice1Actions.setHTMLToSHow({ value: htmlString }));
    dispatch(slice1Actions.setShowHTMLReader({ value: true }));
    window.location.href = "#home-start";
    var homeDiv = document.getElementById("home-content-start");
    homeDiv.scrollIntoView();
  }

  function handleFAQs() {
    let htmlString = AppSettings.faqs;
    //htmlString = urlSchemeToFunctions(htmlString);
    dispatch(slice1Actions.setHTMLTitle({ value: "FAQs" }));
    dispatch(slice1Actions.setHTMLToSHow({ value: htmlString }));
    dispatch(slice1Actions.setShowHTMLReader({ value: true }));
    window.location.href = "#home-start";
    var homeDiv = document.getElementById("home-content-start");
    homeDiv.scrollIntoView();
  }

  function handleGuidelines() {
    let htmlString = AppSettings.guidelines;
    //htmlString = urlSchemeToFunctions(htmlString);
    dispatch(slice1Actions.setHTMLTitle({ value: "Guidelines" }));
    dispatch(slice1Actions.setHTMLToSHow({ value: htmlString }));
    dispatch(slice1Actions.setShowHTMLReader({ value: true }));
    window.location.href = "#home-start";
    var homeDiv = document.getElementById("home-content-start");
    homeDiv.scrollIntoView();
  }

  function handleContactUs() {
    window.location.href = "mailto:andiamotravelapp@gmail.com?subject=Feedback&body=";
  }

  const MainMenu = () => {
    const menuList = [
      {
        label: "User",
        method: UserMenu,
        icon: null,
        visible: true,
        info: "Display the User menu",
      },
      {
        label: "Edit",
        method: EditMenu,
        icon: null,
        visible: GuideEditMode,
        info: "Display the Edit menu",
      },
      {
        label: "Guide",
        method: GuideMenu,
        icon: null,
        visible: true,
        info: "Display the Guide menu",
      },
      {
        label: "Tickets",
        method: purchaseTickets,
        icon: null,
        visible: true,
        info: "Display the travel related Tickets that you can purchase from our sponsors. Note that that you can get a commission from the purchase and help this app stay free.",
      },
      {
        label: "Info",
        method: InfoMenu,
        icon: <i className="fas fa-info"></i>,
        visible: true,
        info: "Display the Info menu",
      },
    ];
    setMenuModalList(menuList);
    setMenuModalHeading("Main Menu");
    setMenuModalImage(<i className="fa fa-bars"></i>);
    setMenuModalPlace(null);
    setMenuModalShow(true);
  };

  const updateUIAndHome = () => {
    dispatch(slice1Actions.resetHomeScreen({ value: null }));
    dispatch(slice1Actions.setGuide({ guide: null, fitToBounds: true }));
  };

  // const updateUI = () => {
  //   dispatch(slice1Actions.setGuide({ guide: null, fitToBounds: true }));
  // };

  const updateGuide = () => {
    dispatch(slice1Actions.setGuide({ guide: null, fitToBounds: true, update_guide_attributes: true }));
    //dispatch(slice1Actions.resetHomeScreen({ value: null }));
  };

  const updateUI = (scheduleName) => {
    if (scheduleName !== null) {
      dispatch(slice1Actions.ClearAllFilters());
      dispatch(slice1Actions.setScheduleName({ value: scheduleName }));
      dispatch(slice1Actions.SetSchedulesFilter({ value: [{ label: scheduleName }] }));
    }
    dispatch(slice1Actions.resetHomeScreen({ value: null }));
    dispatch(slice1Actions.setGuide({ guide: null, fitToBounds: true }));
  };

  const SetScheduleName = (scheduleName) => {
    dispatch(slice1Actions.ClearAllFilters());
    dispatch(slice1Actions.setScheduleName({ value: scheduleName }));
    dispatch(slice1Actions.SetSchedulesFilter({ value: [{ label: scheduleName }] }));
    dispatch(slice1Actions.setGuide({ guide: null }));
  };

  const SaveGuide = () => {
    if (Authorization === AUTHORIZATION_LEVEL.GUEST) {
      myAlert("Cannot save guides in guest mode. You must be registered and logged in.");
      return;
    }
    if (window.guide_obj.isAuthorized(Authorization, AUTHORIZATION_LEVEL.MODIFY) === false) {
      myAlert("Not authorized to save this guide");
      return;
    }
    myConfirm("Save this Guide to cloud?", [
      { Title: "OK", Method: () => _saveGuide() },
      { Title: "CANCEL", Method: null },
    ]);
  };

  const _saveGuide = () => {
    setActivityIndicator(true);
    DataBase.batchedWriteGuide(
      window.guide_obj,
      guide_meta.guide_id,
      guide_meta.guide_info_id,
      user_info,
      "modify",
      batchSaveGuideCallback
    );
  };

  const batchSaveGuideCallback = (res) => {
    setActivityIndicator(false);
    if (res.pass !== true) {
      //console.log("batchSaveGuideCallback: Error saving guide", res);
      myAlert("Error saving guide");
      return;
    }
    myAlert("Guide saved");
  };

  const showUserInfoModal = () => {
    setUserInfoModal(true);
  };

  const bookColor = () => {
    //console.log("Authorization", Authorization);
    if (user_info.uid === "guest") return COLORS.GRAY;
    if (Authorization === AUTHORIZATION_LEVEL.MODIFY) return COLORS.GREEN;
    else if (Authorization === AUTHORIZATION_LEVEL.COPY) return COLORS.YELLOW;
    else if (Authorization === AUTHORIZATION_LEVEL.READ) return COLORS.ORANGE;
    else if (Authorization === AUTHORIZATION_LEVEL.GUEST) return COLORS.GRAY;
    else return COLORS.GRAY;
  };

  const modeColor = () => {
    if (GuideSettings.mode === "edit") return COLORS.GREEN;
    else if (GuideSettings.mode === "view") return COLORS.YELLOW;
    else if (GuideSettings.mode === "custom_view") return COLORS.ORANGE;
    else return COLORS.GRAY;
  };

  const userColor = () => {
    if (user_info.uid === "guest") return COLORS.GRAY;
    if (Authorization === AUTHORIZATION_LEVEL.GUEST) return COLORS.GRAY;
    return COLORS.GREEN;
  };
  const hideMenuModal = () => {
    dispatch(slice1Actions.setMenuModalArgs({ value: { ...MenuModalArgs, Show: false } }));
  };
  const showMap = () => {
    dispatch(slice1Actions.setHideMap({ value: !HideMap }));
    setTimeout(fitPlaces, 1000);
  };

  const showSideMenu = () => {
    dispatch(slice1Actions.setHideSideMenu({ value: !HideSideMenu }));
  };
  const showHome = () => {
    dispatch(slice1Actions.setHideHome({ value: !HideHome }));
    setTimeout(fitPlaces, 1000);
  };

  //console.log("******************Authorization", Authorization, user_info, bookColor(), userColor());

  if (MobileMode)
    return (
      <>
        <div
          style={{
            display: "flex",
            width: "100%",
            margin: "0px",
            justifyContent: "space-between",
            alignItems: "center",
            fontSize: "1rem",
          }}
        >
          <div className="mobile-header">
            {HTMLStack.length > 1 ? (
              <div
                onClick={() => goBack()}
                style={{ cursor: "pointer", color: "#3598DB", verticalAlign: "middle", textAlign: "center" }}
              >
                <i className="fas fa-arrow-left"></i>
                <p>Back</p>
              </div>
            ) : null}
            <div onClick={ViewMenu} style={{ cursor: "pointer", color: "#3598DB", verticalAlign: "middle" }}>
              <i className="pointer fa fa-eye"></i>
              <p>View</p>
            </div>

            <div
              style={{
                cursor: "pointer",
                color: userColor(),
                verticalAlign: "middle",
                textAlign: "center",
              }}
              onClick={() => UserMenu()}
            >
              <i className="pointer fa fa-user"></i>
              <p>User</p>
            </div>

            <div
              onClick={() => GuideMenu()}
              style={{
                color: bookColor(),
                verticalAlign: "middle",
                textAlign: "center",
              }}
            >
              <i className="fa fa-book-open"></i>
              <p>Guide</p>
            </div>
            {GuideEditMode ? (
              <div
                style={{ cursor: "pointer", color: "#3598DB", verticalAlign: "middle", textAlign: "center" }}
                onClick={() => EditMenu()}
              >
                <i className="pointer fa fa-pencil"></i>
                <p>Edit</p>
              </div>
            ) : null}

            <div
              style={{ cursor: "pointer", color: "#3598DB", verticalAlign: "middle", textAlign: "center" }}
              onClick={() => SaveMenu()}
            >
              <i className="pointer fas fa-save"></i>
              <p>File</p>
            </div>

            {/* <div className="none" style={{ cursor: "pointer", color: "#3598DB", verticalAlign: "middle" }} onClick={() => ImageUpload()}>
              <i className="pointer fas fa-ticket"></i>
              <p>Upload</p>
            </div> */}

            <div
              style={{ cursor: "pointer", color: modeColor(), verticalAlign: "middle", textAlign: "center" }}
              onClick={() => InfoMenu()}
            >
              <i className="pointer fas fa-info"></i>
              <p>Info</p>
            </div>
            {/* <div className="mobile" style={{ cursor: "pointer", color: "#3598DB", verticalAlign: "middle" }} onClick={() => MainMenu()}>
              <i className="pointer fa fa-bars"></i>
              <p>Menu</p>
            </div> */}
          </div>
        </div>
        {/* <GuideInfo show={guideInfoModal} onHide={() => setGuideInfoModal(false)} /> */}
        <HTMLEditorModal
          show={ShowHTMLEditorModal}
          onHide={() => setHTMLEditorModal(false)}
          html={HTMLEditorModalContent}
          callback={HTMLEditCallback}
          callback_rt={null}
          title={"Home Page"}
        />
        {/* <ImageUploadModal show={ShowImageUploadModal} onHide={() => setImageUploadModal(false)} /> */}
        <UserInfoModal show={ShowUserInfoModal} onHide={() => setUserInfoModal(false)} />
        <LoginModal show={ShowLoginModal} onHide={() => setShowLoginModal(false)} mode={LoginModalMode} />

        <AlertModal
          show={AlertModalShow}
          onHide={() => setAlertModalShow(false)}
          AlertModalTitle={AlertModalTitle}
          AlertModalMessage={AlertModalMessage}
          AlertModalButtons={AlertModalButtons}
        />
        <MenuModal
          show={MenuModalShow}
          onHide={() => setMenuModalShow(false)}
          menu_modal_list={MenuModalList}
          menu_modal_heading={MenuModalHeading}
          menu_model_image={MenuModalImage}
          menu_modal_place={MenuModalPlace}
        />
        <GuideDownload
          show={GuideDownloadShow}
          onHide={() => setGuideDownloadShow(false)}
          user_info={user_info}
          guide_sort_option={GuideSortOption}
          set_guide_sort_option={setGuideSortOption}
          callback={GuideDownloadCallback}
          mode={GuideDownloadMode}
          modify_only={GuideDownloadType === "overwrite" ? "true" : "false"}
          guide_meta={guide_meta}
          guide_download_category={GuideDownloadCategory}
        />
        {ImageDialogShow ? (
          <ImageDialog
            show={ImageDialogShow}
            onHide={() => setImageDialogShow(false)}
            user_info={user_info}
            callback={ImageDialogCallback}
            mode={ImageDialogMode}
            modify_only={"true"}
            updateImage={ImageDialogCallback}
            size={"sm"}
            multi={true}
            exif={ImageDialogExif}
          />
        ) : null}
        {/* <Offcanvas show={CategoriesDialogShow} onHide={() => setCategoriesDialogShow(false)}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Offcanvas</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body> */}

        {SettingsDialogShow ? (
          <SettingsDialog
            show={SettingsDialogShow}
            onHide={() => setSettingsDialogShow(false)}
            user_info={user_info}
            creator_info={creator_info}
            user_info_id={user_info_id}
            callback={SettingsDialogCallback}
          />
        ) : null}

        {AiDialogShow ? (
          <AiDialog
            show={AiDialogShow}
            onHide={() => setAiDialogShow(false)}
            questions={AppSettings.questions2}
            user_info={user_info}
          />
        ) : null}

        {/* </Offcanvas.Body>
        </Offcanvas> */}

        {SectionsDialogShow ? (
          <SectionsDialog
            show={SectionsDialogShow}
            onHide={() => setSectionsDialogShow(false)}
            update_display={UpdateDisplay}
            mode={"edit"}
            callback={null}
          />
        ) : null}
        {ReviewDialogShow ? (
          <ReviewDialog
            show={ReviewDialogShow}
            onHide={() => setReviewDialogShow(false)}
            user_info={user_info}
            guide_info_id={guide_meta.guide_info_id}
            guide_info={guide_meta.guide_info}
          />
        ) : null}
        {GuideEditModalShow ? (
          <GuideEditModal
            show={GuideEditModalShow}
            onHide={() => setGuideEditModalShow(false)}
            guide_meta={guide_meta}
            guide_info={guide_meta.guide_info}
            guide_info_id={guide_meta.guide_info_id}
            user_info={user_info}
            mode={GuideEditModalMode}
            callback={GuideEditModalCallback}
          />
        ) : null}
        {TripAIShow ? (
          <TripAI
            show={TripAIShow}
            onHide={() => setTripAIShow(false)}
            user_info={user_info}
            update_ui={updateUI}
            update_guide={updateGuide}
            set_schedule_name={SetScheduleName}
            ai_base_categories={AppSettings.AIBaseCategories}
            ai_categories={AppSettings.AICategories}
          />
        ) : null}

        {GuideSettingsDialogShow ? (
          <GuideSettingsDialog show={GuideSettingsDialogShow} onHide={() => setGuideSettingsDialogShow(false)} />
        ) : null}
        {CategoriesDialogShow ? (
          <CategoriesDialog
            show={CategoriesDialogShow}
            onHide={() => setCategoriesDialogShow(false)}
            update_display={UpdateDisplay}
          />
        ) : null}
        {GroupsDialogShow ? (
          <GroupsDialog
            show={GroupsDialogShow}
            onHide={() => setGroupsDialogShow(false)}
            update_display={UpdateDisplay}
          />
        ) : null}
        {PlacesOfInterestShow ? (
          <PlacesOfInterest
            show={PlacesOfInterestShow}
            onHide={() => setPlacesOfInterestShow(false)}
            update_display={UpdateDisplay}
          />
        ) : null}
      </>
    );
  return (
    <>
      <div
        id="menu"
        style={{
          display: "flex",
          width: "100%",
          margin: "0px",
          justifyContent: "space-between",
          alignItems: "center",
          fontSize: "1rem",
        }}
      >
        <img
          className="side-none"
          src={logo}
          alt=""
          height="45px"
          width="45px"
          style={{ borderRadius: "50%", marginRight: 10 }}
        />
        <div>
          <h5 className="side-none" style={{ color: "orange", marginLeft: 5, marginRight: 10, width: "100px" }}>
            Andiamo Places
          </h5>
          {/* <br></br>
          <h6 className="side-none" style={{ color: "orange", marginLeft: 5, marginRight: 10 }}>
            Places
          </h6> */}
        </div>
        <div className="home-header">
          {HTMLStack.length > 1 ? (
            <div onClick={() => goBack()} style={{ cursor: "pointer", color: "#3598DB", verticalAlign: "middle" }}>
              <i className="pointer fas fa-arrow-left"></i>
              <p>Back</p>
            </div>
          ) : null}

          {!MobileMode && HTMLStack.length > 1 ? (
            <div
              style={{ cursor: "pointer", color: "#3598DB", verticalAlign: "middle", textAlign: "center" }}
              onClick={() => DisplayHomeScreen()}
            >
              <i className="pointer fa fa-home"></i>
              <p>Home</p>
            </div>
          ) : null}
          {/* <div onClick={() => GuidesInMap()} style={{ cursor: "pointer", color: "#3598DB", verticalAlign: "middle" }}>
            <i className={GuidesOrPlaces === "Places" ? "fa fa-books" : "fa fa-book-open"}></i>
            <p>{GuidesOrPlaces === "Places" ? "Guides" : "Guide"}</p>
          </div> */}
          <div onClick={ViewMenu} style={{ cursor: "pointer", color: "#3598DB", verticalAlign: "middle" }}>
            <i className="pointer fa fa-eye"></i>
            <p>View</p>
          </div>
          {/* <div onclick={() => setAiDialogShow(true)} style={{ cursor: "pointer", color: "#3598DB", verticalAlign: "middle" }}>
            <i className="pointer fa fa-question-circle"></i>
            <p>AI</p>
          </div> */}
          {/* <div onClick={() => showMap()} style={{ cursor: "pointer", color: "#3598DB", verticalAlign: "middle" }}>
            <i className="pointer fas fa-map"></i>
            <p>Map</p>
          </div> */}
          <label for="guide-file-upload" className="custom-file-upload" hidden>
            <i title="Select KML File" className="fa fa-cloud-upload fa-5x"></i>
          </label>
          <input
            ref={(input) => input && input.focus()}
            className="form-item"
            placeholder="Choose file"
            style={{ width: "250px" }}
            type="file"
            accept=".json"
            onChange={readLocalFile}
            id="guide-file-upload"
            multiple
            hidden
          ></input>
          <div
            className="home-nav-item mobile"
            style={{ cursor: "pointer", color: "#3598DB", verticalAlign: "middle" }}
          >
            <a href="#home-start">
              <i className="fas fa-home"></i>
            </a>
            <p>Home</p>
          </div>
          <div
            className="home-nav-item mobile"
            style={{ cursor: "pointer", color: "#3598DB", verticalAlign: "middle" }}
          >
            <a href="#map-start">
              <i className="fas fa-map"></i>
            </a>
            <p>Map</p>
          </div>
          <div
            className="home-nav-item mobile"
            style={{ cursor: "pointer", color: "#3598DB", verticalAlign: "middle" }}
          >
            <a href="#places-start">
              <i className="fa fa-map-marker"></i>
            </a>
            <p>Places</p>
          </div>
          <div
            className="home-nav-item mobile"
            style={{ cursor: "pointer", color: "#3598DB", verticalAlign: "middle" }}
          >
            <a href="#schedule-start">
              <i className="fa fa-calendar"></i>
            </a>
            <p>Sched</p>
          </div>

          <div
            className="none"
            style={{
              cursor: "pointer",
              color: userColor(),
              verticalAlign: "middle",
            }}
            onClick={() => UserMenu()}
          >
            <i className="fa fa-user"></i>
            <p>User</p>
          </div>

          <div
            className="none"
            onClick={() => GuideMenu()}
            style={{
              cursor: "pointer",
              color: bookColor(),
              verticalAlign: "middle",
            }}
          >
            <i className="pointer fa fa-book-open"></i>
            <p>Guide</p>
          </div>

          {GuideEditMode && GuidesOrPlaces === "Places" ? (
            <div
              className="none"
              style={{ cursor: "pointer", color: "#3598DB", verticalAlign: "middle" }}
              onClick={() => EditMenu()}
            >
              <i className="fa fa-pencil"></i>
              <p>Edit</p>
            </div>
          ) : null}

          {GuidesOrPlaces === "Places" ? (
            <div
              className="none"
              style={{ cursor: "pointer", color: "#3598DB", verticalAlign: "middle" }}
              onClick={() => SaveMenu()}
            >
              <i className="fas fa-save"></i>
              <p>File</p>
            </div>
          ) : null}
          {!NoAds ? (
            <div
              className="none"
              style={{ cursor: "pointer", color: "#3598DB", verticalAlign: "middle" }}
              onClick={() => purchaseTickets(true)}
            >
              {/* <i className="fas fa-ticket"></i> */}

              <FontAwesomeIcon icon={solid("ticket")}></FontAwesomeIcon>
              <p>Tickets</p>
            </div>
          ) : null}

          {/* <div className="none" style={{ cursor: "pointer", color: "#3598DB", verticalAlign: "middle" }} onClick={() => ImageUpload()}>
            <i className="fas fa-ticket"></i>
            <p>Upload</p>
          </div> */}

          <div
            className="home-nav-item none"
            style={{ cursor: "pointer", color: modeColor(), verticalAlign: "middle" }}
            onClick={() => InfoMenu()}
          >
            <i className="fas fa-info"></i>
            <p>Info</p>
          </div>
          <div
            className="mobile"
            style={{ cursor: "pointer", color: "#3598DB", verticalAlign: "middle" }}
            onClick={() => MainMenu()}
          >
            <i className="fa fa-bars"></i>
            <p>Menu</p>
          </div>
        </div>
      </div>
      {/* <GuideInfo show={guideInfoModal} onHide={() => setGuideInfoModal(false)} /> */}
      {ShowHTMLEditorModal ? (
        <HTMLEditorModal
          show={ShowHTMLEditorModal}
          onHide={() => setHTMLEditorModal(false)}
          html={HTMLEditorModalContent}
          callback={HTMLEditCallback}
          callback_rt={null}
          title={"Home Page"}
        />
      ) : null}
      {/* <ImageUploadModal show={ShowImageUploadModal} onHide={() => setImageUploadModal(false)} /> */}
      {ShowUserInfoModal ? <UserInfoModal show={ShowUserInfoModal} onHide={() => setUserInfoModal(false)} /> : null}
      {ShowLoginModal ? (
        <LoginModal show={ShowLoginModal} onHide={() => setShowLoginModal(false)} mode={LoginModalMode} />
      ) : null}

      {AlertModalShow ? (
        <AlertModal
          show={AlertModalShow}
          onHide={() => setAlertModalShow(false)}
          AlertModalTitle={AlertModalTitle}
          AlertModalMessage={AlertModalMessage}
          AlertModalButtons={AlertModalButtons}
        />
      ) : null}
      {MenuModalShow ? (
        <MenuModal
          show={MenuModalShow}
          onHide={() => setMenuModalShow(false)}
          menu_modal_list={MenuModalList}
          menu_modal_heading={MenuModalHeading}
          menu_model_image={MenuModalImage}
          menu_modal_place={MenuModalPlace}
        />
      ) : null}
      {GuideDownloadShow ? (
        <GuideDownload
          show={GuideDownloadShow}
          onHide={() => setGuideDownloadShow(false)}
          user_info={user_info}
          guide_sort_option={GuideSortOption}
          set_guide_sort_option={setGuideSortOption}
          callback={GuideDownloadCallback}
          mode={GuideDownloadMode}
          modify_only={GuideDownloadType === "overwrite" ? "true" : "false"}
          guide_meta={guide_meta}
          guide_download_category={GuideDownloadCategory}
        />
      ) : null}
      {ImageDialogShow ? (
        <ImageDialog
          show={ImageDialogShow}
          onHide={() => setImageDialogShow(false)}
          user_info={user_info}
          callback={ImageDialogCallback}
          mode={ImageDialogMode}
          modify_only={"true"}
          updateImage={ImageDialogCallback}
          size={"sm"}
          multi={true}
          exif={ImageDialogExif}
        />
      ) : null}
      {/* <Offcanvas show={CategoriesDialogShow} onHide={() => setCategoriesDialogShow(false)}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Offcanvas</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body> */}
      {SettingsDialogShow ? (
        <SettingsDialog
          show={SettingsDialogShow}
          onHide={() => setSettingsDialogShow(false)}
          user_info={user_info}
          creator_info={creator_info}
          user_info_id={user_info_id}
          callback={SettingsDialogCallback}
        />
      ) : null}

      {AiDialogShow ? (
        <AiDialog
          show={AiDialogShow}
          onHide={() => setAiDialogShow(false)}
          questions={AppSettings.questions2}
          user_info={user_info}
        />
      ) : null}
      {/* </Offcanvas.Body>
      </Offcanvas> */}

      {SectionsDialogShow ? (
        <SectionsDialog
          show={SectionsDialogShow}
          onHide={() => setSectionsDialogShow(false)}
          update_display={UpdateDisplay}
          mode={"edit"}
          callback={null}
        />
      ) : null}
      {ReviewDialogShow ? (
        <ReviewDialog
          show={ReviewDialogShow}
          onHide={() => setReviewDialogShow(false)}
          user_info={user_info}
          guide_info_id={guide_meta.guide_info_id}
          guide_info={guide_meta.guide_info}
        />
      ) : null}
      {GuideEditModalShow ? (
        <GuideEditModal
          show={GuideEditModalShow}
          onHide={() => setGuideEditModalShow(false)}
          guide_meta={guide_meta}
          guide_info={guide_meta.guide_info}
          guide_info_id={guide_meta.guide_info_id}
          user_info={user_info}
          mode={GuideEditModalMode}
          callback={GuideEditModalCallback}
        />
      ) : null}
      {TripAIShow ? (
        <TripAI
          show={TripAIShow}
          onHide={() => setTripAIShow(false)}
          user_info={user_info}
          update_ui={updateUI}
          update_guide={updateGuide}
          set_schedule_name={SetScheduleName}
          ai_base_categories={AppSettings.AIBaseCategories}
          ai_categories={AppSettings.AICategories}
        />
      ) : null}

      {GuideSettingsDialogShow ? (
        <GuideSettingsDialog show={GuideSettingsDialogShow} onHide={() => setGuideSettingsDialogShow(false)} />
      ) : null}
      {CategoriesDialogShow ? (
        <CategoriesDialog
          show={CategoriesDialogShow}
          onHide={() => setCategoriesDialogShow(false)}
          update_display={UpdateDisplay}
        />
      ) : null}
      {GroupsDialogShow ? (
        <GroupsDialog
          show={GroupsDialogShow}
          onHide={() => setGroupsDialogShow(false)}
          update_display={UpdateDisplay}
        />
      ) : null}
      {PlacesOfInterestShow ? (
        <PlacesOfInterest
          show={PlacesOfInterestShow}
          onHide={() => setPlacesOfInterestShow(false)}
          update_display={UpdateDisplay}
        />
      ) : null}
      {CustomIconsShow ? (
        <CustomIcons show={CustomIconsShow} onHide={() => setCustomIconsShow(false)} update_display={UpdateDisplay} />
      ) : null}
      {PDFModalShow ? (
        <PDFModal
          show={PDFModalShow}
          htmlString={document.getElementById("home-content").innerHTML}
          onHide={() => setPDFModalShow(false)}
        />
      ) : null}
      {HTMLReaderModal ? (
        <HTMLReader html={HTMLContent} show={HTMLReaderModal} onHide={() => setHTMLReaderModal(false)} />
      ) : null}
      {ShowMultiselect ? (
        <MultiSelectModal
          show={ShowMultiselect}
          onHide={() => setShowMultiselect(false)}
          MultiselectItems={MultiselectItems}
          MultiselectSelectedItems={MultiselectSelectedItems}
          MultiselectMode={MultiselectMode}
          MultiselectCallback={handleContentFontZoomCallback}
          MultiselectTitle={MultiselectTitle}
          MultiselectSearchText={MultiselectSearchText}
        />
      ) : null}
      {KMLInputShow ? (
        <KMLInput
          show={KMLInputShow}
          onHide={() => setKMLInputShow(false)}
          PromptModalTitle={"KML"}
          PromptModalMessage={"Import KML file"}
          PromptModalButtons={[{ Title: "CANCEL", Method: null }]}
          PromptModalInputs={[{ name: "Read file", value: "" }]}
          add_place_to_map={addPlaceToMap}
        />
      ) : null}
      {GuideTranslateShow ? (
        <GuideTranslate
          show={GuideTranslateShow}
          onHide={() => setGuideTranslateShow(false)}
          update_ui={updateUIAndHome}
        />
      ) : null}
    </>
  );
};

export default HomeHeader;
