import React, { useState, Component } from "react";
import { Modal, ToggleButtonGroup, ToggleButton, ButtonGroup, Button, Form } from "react-bootstrap";
import "./Modal.css";
import MultiSelectModal from "./MultiSelectModal";
import ImageDialog from "./ImageDialog";
import GuideDownload from "./GuideDownload/GuideDownload";

import AlertModal from "./AlertModal";
import PromptModal from "./PromptModal";
import { useDispatch } from "react-redux";
import { slice1Actions } from "../../store/reducer";
import { useSelector } from "react-redux";
import Common, {
  POI_IMAGES,
  DISTANCES,
  Settings,
  SortByEnum,
  labelChars,
  AUTHORIZATION_LEVEL,
  COLORS,
} from "../../Common/Common";
//import { setKey, shorten, expand } from "react-native-google-shortener";
// const [open, setOpen] = useState(false);
// const [value, setValue] = useState(null);
// const [items, setItems] = useState([
//   { label: "Apple", value: "apple" },
//   { label: "Banana", value: "banana" },
// ]);
import SectionsDialog from "./SectionsDialog";
import IconsDialog from "./IconsDialog";
import Place from "../../Models/Place";
import MyToolTip from "./MyToolTip";

class HTMLLinksModalClass extends Component {
  constructor(props) {
    super(props);
    //console.log("Itineraries", Itineraries);
    let items = [
      {
        label: "Place Info",
        name: "Place Info",
        value: "placelink",
        linkText: "Place ",
        icon: () => <i class="fas fa-link"></i>,
      },
      {
        label: "Place More Info",
        name: "Place More Info",
        value: "placemoreinfolink",
        linkText: "More place info",
        icon: () => <i class="fas fa-link"></i>,
      },
      {
        label: "Place Menu",
        name: "Place Menu",
        value: "placemenulink",
        icon: () => <i class="fas fa-link"></i>,
      },
      // { label: "Places Filter", value: "placeslink", icon: () => <i class="fas fa-link"></i> },
      {
        label: "Section",
        name: "Section",
        value: "sectionlink",
        icon: () => <i class="fas fa-link"></i>,
      },
      {
        label: "Schedule",
        name: "Schedule",
        value: "schedulelink",
        icon: () => <i class="fas fa-link"></i>,
      },
      {
        label: "Itinerary",
        name: "Itinerary",
        value: "itinerarylink",
        icon: () => <i class="fas fa-link"></i>,
      },

      { label: "Map", name: "Map", value: "maplink", icon: () => <i class="fas fa-link"></i> },
      {
        label: "Street View",
        name: "Street View",
        value: "streetviewlink",
        icon: () => <i class="fas fa-link"></i>,
      },
      { label: "Guide", name: "Guide", value: "guidelink", icon: () => <i class="fas fa-link"></i> },
      { label: "Video", name: "Video", value: "videolink", icon: () => <i class="fas fa-link"></i> },
      // { label: "Video Path", name: "Video Path", value: "videopathlink", icon: () => <i class="fas fa-link"></i> },
      { label: "Audio", name: "Audio", value: "audiolink", icon: () => <i class="fas fa-link"></i> },
      { label: "URL", name: "URL", value: "urllink", icon: () => <i class="fas fa-link"></i> },
      { label: "Image", name: "Image", value: "imagelink", icon: () => <i class="fas fa-link"></i> },
      {
        label: "Directions",
        name: "Directions",
        value: "directionslink",
        icon: () => <i class="fas fa-link"></i>,
      },
      {
        label: "Weather",
        name: "Weather",
        value: "weatherlink",
        icon: () => <i class="fas fa-link"></i>,
      },
    ];
    items = items.sort((a, b) => (a.label.toUpperCase() > b.label.toUpperCase() ? 1 : -1));

    this.state = {
      linkType: "Place Menu",
      linkName: "placemenulink",
      linkText: "Place Menu",
      // linkTextDisplayed: "Place Menu",
      url: "",
      PlaceIds: [],
      PlaceNames: [],
      Places: [],
      FromPlaceIds: [],
      FromPlaceNames: [],
      FromPlaces: [],
      GroupIds: [],
      GroupNames: [],
      Groups: [],
      CategoryIds: [],
      CategoryNames: [],
      Categories: [],
      sectionLink: "",
      sectionName: "",
      guideID: "",
      guideName: "",
      guide_info_id: "",
      imageURL: "http://liquidguides.com/Media/Images/place.png",
      imageUIURL: "http://liquidguides.com/Media/Images/place.png",
      linkIcon: { id: "1" },
      itineraryName: "Main",
      Itineraries: [],
      scheduleName: "Main",
      ShowOnlyFavorites: false,
      ShowOnlyVisited: false,
      ShowOnlyNotVisited: false,
      ShowOnlyMustSee: false,
      ScheduleFilter: false,
      DayFilter: false,
      day: "",
      distanceFilter: -1,
      DistanceSelected: "",
      imageSource: "Select Image",
      PickerItems: items,
      PickerValue: {
        label: "Place Menu",
        name: "Place Menu",
        value: "placemenulink",
        linkText: "Place Menu ",
        icon: () => <i class="fas fa-link"></i>,
      },
      linkUI: "Text only",
      testWidth: "99%",

      AlertModalShow: false,
      AlertModalTitle: "",
      AlertModalMessage: "",
      AlertModalButtons: [],

      MultiselectItems: [],
      MultiselectSelectedItems: [],
      MultiselectMode: "uni",
      MultiselectCallback: null,
      MultiselectTitle: "Select",
      MultiselectSearchText: "",
      ShowMultiselect: false,

      PromptModalShow: false,
      PromptModalTitle: "Enter Value",
      PromptModalMessage: "",
      PromptModalButtons: [],
      PromptModalInputs: [],
      FilterOptions: [
        { name: "Show Only Favorites", value: "0", selected: false },
        { name: "Show Only Visited", value: "1", selected: false },
        { name: "Show Only Not Visited", value: "2", selected: false },
        { name: "Show Only Must See", value: "3", selected: false },
        { name: "Filter by Schedule", value: "4", selected: false },
        { name: "Filter by Day", value: "5", selected: false },
      ],
      UIOptionSelected: "0",
      ImageDialogShow: false,
      GuideDownloadShow: false,

      IconsDialogShow: false,
      video_start: 0,
    };
    this.handleChangeLinkText = this.handleChangeLinkText.bind(this);
    this.handleChangeURL = this.handleChangeURL.bind(this);
  }

  Itineraries = [];

  componentDidMount() {
    // if (window.guide_obj !== null) this.Itineraries = window.guide_obj.schedule.geScheduleNames();
  }

  handleChangeLinkText = (newText) => {
    console.log("newText", newText);
    this.setState({ ...this.state, linkText: newText });
  };

  handleChangeURL = (event) => {
    this.setState({ ...this.state, url: event.target.value });
  };

  HandleUIOptionSelected = (value) => {
    console.log("HandleUIOptionSelected", value, this.UIOptions[parseInt(value)].name);
    this.setState({ UIOptionSelected: value, linkUI: this.UIOptions[parseInt(value)].name });
    console.log(value, this.UIOptions[parseInt(value)].name);
  };

  MyAlert = (message) => {
    this.setState({
      AlertModalTitle: "Message",
      AlertModalMessage: message,
      AlertModalButtons: [{ Title: "OK", Method: null }],
      AlertModalShow: true,
    });
  };

  AddLink = (_this) => {
    if (this.state.linkText === "") {
      this.MyAlert("Must specify link text");
      return;
    }
    if (
      ["Place Info", "Place More Info", "Place Menu", "Street View", "Directions", "Weather"].includes(
        this.state.linkType
      ) &&
      this.state.PlaceIds.length === 0
    ) {
      this.MyAlert("Must select a place");
      return;
    }
    //console.log(`PlaceIds: ${this.state.PlaceIds} Places: ${this.state.Places}`);
    //console.log(`linkName: ${this.state.linkName} linkType: ${this.state.linkType}`);
    let link = `${this.state.linkName}:`;
    switch (this.state.linkName) {
      case "placemoreinfolink":
        link += this.state.PlaceIds[0];
        break;
      case "placemenulink":
        link += this.state.PlaceIds[0];
        break;
      case "placelink":
        link += this.state.PlaceIds[0];
        break;
      case "placeslink":
        link += this.state.PlaceIds.join(",");
        break;
      case "schedulelink":
        link += this.state.scheduleName;
        break;
      case "itinerarylink":
        link += this.state.itineraryName;
        break;
      case "homelink":
        link += "home";
        break;
      case "maplink":
        let filters = "";
        if (this.state.ScheduleFilter === true) filters += `|schedule=${this.state.scheduleName}`;
        if (this.state.DayFilter === true) {
          const date = new Date(this.state.day);
          const dayTime = date.getTime();
          filters += `|day=${dayTime}`;
        }
        if (this.state.ShowOnlyFavorites === true) filters += "|Favorites";
        if (this.state.ShowOnlyVisited === true) filters += "|Visited";
        if (this.state.ShowOnlyNotVisited === true) filters += "|NotVisited";
        if (this.state.ShowOnlyMustSee === true) filters += "|MustSee";
        if (this.state.distanceFilter != -1) filters += `|distance=${this.state.distanceFilter}`;
        link += `${this.state.PlaceIds.join(",")}|${this.displayIDList(this.state.Groups, ",")}|${this.displayIDList(
          this.state.Categories,
          ","
        )}${filters}`;
        //console.log("link", link);
        break;
      case "streetviewlink":
        link += this.state.PlaceIds[0];
        break;
      case "sectionlink":
        link += this.state.sectionLink;
        break;
      case "guidelink":
        link += this.state.guide_info_id;
        break;
      case "imagelink":
        const api_key = "AIzaSyAKxtGIewjOkmnY0FE9jD7hYbsHRVArJJc";
        if (this.state.imageSource === "Paste URL") link += this.state.url;
        else link += this.state.imageURL;
        break;
      case "videolink":
        let total_time = this.state.video_start;
        if (this.state.video_start.includes(":")) {
          try {
            let time_parts = this.state.video_start.split(":");
            if (time_parts.length === 2) {
              total_time = parseInt(time_parts[0]) * 60 + parseInt(time_parts[1]);
            } else if (time_parts.length === 3) {
              total_time = parseInt(time_parts[0]) * 3600 + parseInt(time_parts[1]) * 60 + parseInt(time_parts[2]);
            }
          } catch {
            let total_time = this.state.video_start;
          }
        }
        if (this.state.url.includes("https://www.youtu") || this.state.url.includes("https://youtu"))
          link += this.state.url + `?start=${total_time}&autoplay=1&mute=1`;
        else link += this.state.url;
        break;
      case "audiolink":
        link += this.state.url;
        break;
      case "urllink":
        if (this.state.url === "") {
          this.MyAlert("Must specify url");
          return;
        }
        link += this.state.url;
        break;
      case "directionslink":
        if (this.state.FromPlaceIds.length === 0) {
          this.MyAlert("Must specify from place");
          return;
        }
        if (this.state.PlaceIds.length === 0) {
          this.MyAlert("Must specify to place");
          return;
        }
        link += this.state.FromPlaceIds[0] + "|" + this.state.PlaceIds[0];
        break;
      case "weatherlink":
        link += this.state.PlaceIds[0];
        break;
      default:
        break;
    }
    //console.log("AddLink", `${this.state.linkText}: ${link}}`);
    const callback = this.props.callback;
    if (callback == null) {
      //console.log("No callback from LinkdDialog");
      return;
    }
    let _linkIcon = "";
    if (typeof this.state.linkIcon.obj !== "undefined") {
      if (typeof this.state.linkIcon.obj.url !== "undefined") _linkIcon = this.state.linkIcon.obj.url;
    } else _linkIcon = POI_IMAGES[this.state.linkIcon.id].req;
    callback({
      text: this.state.linkText,
      url: link,
      linkUI: this.state.linkUI,
      imgURL: this.state.UIOptionSelected === "1" ? this.state.imageUIURL : _linkIcon,
      imageWidth: "300",
    });

    this.props.onHide();
  };

  StartTimeChanged = (event) => {
    const newText = event.target.value;
    if (newText != "") {
      var re = /^[0-9:]+$/;
      if (!re.test(newText)) return;
    }
    this.setState({ video_start: newText });
  };

  selectCategories = () => {
    this.MultiSelect(
      window.guide_obj.getCategoriesToDisplay(),
      this.state.Categories,
      "multi",
      this.selectCategoriesCallBack,
      "Select Categories"
    );
  };

  selectCategoriesCallBack = (Categories) => {
    if (Categories.length > 0) this.setState({ linkText: Categories[0].label });
    this.setState({ Categories: Categories });
  };

  MultiSelect = (Items, SelectedItems, Mode, Callback, Title) => {
    console.log("MultiSelect");
    this.setState({
      MultiselectItems: Items,
      MultiselectSelectedItems: SelectedItems,
      MultiselectMode: Mode,
      MultiselectCallback: Callback,
      MultiselectTitle: Title,
      MultiselectSearchText: "",
      ShowMultiselect: true,
    });
  };

  selectLinkType = () => {
    //console.log("selectLinkType");
    this.MultiSelect(this.state.PickerItems, this.state.PickerValue, "uni", this.selectLinkTypeCallBack, "Link Type");
  };

  selectLinkTypeCallBack = (links) => {
    console.log("selectLinkTypeCallBack", links);
    if (links.length > 0) {
      this.setState({
        PickerValue: links[0],
        linkText: links[0].label,
        // linkTextDisplayed: links[0].label,
        linkName: links[0].value,
        linkType: links[0].label,
      });
      if (
        ["placelink", "placemenulink", "placemoreinfolink", "directionslink", "streetviewlink"].includes(
          links[0].value
        ) &&
        this.state.Places.length > 1
      ) {
        this.setState({
          Places: this.state.Places[0],
          linkText: this.state.Places[0].label,
          // linkTextDisplayed: this.state.Places[0].label,
        });
      }
    }
  };

  filterByDistance = () => {
    let distancesList = [];
    let id = 0;
    DISTANCES.forEach((distance) => {
      distancesList.push({
        label: `${distance.toString()} km/${(distance * 0.621371).toFixed(2)} mi`,
        value: distance,
        id: (id++).toString(),
      });
    });
    let selectedDistance = [];
    if (this.state.distanceFilter !== -1)
      selectedDistance = [
        {
          label: `${this.state.distanceFilter.toString()} km ${(this.state.distanceFilter.distance * 0.621371).toFixed(
            2
          )} mi`,
          value: this.state.distanceFilter,
        },
      ];
    this.MultiSelect(distancesList, selectedDistance, "uni", this.FilterDistanceCallBack, "Select Distance");
  };

  FilterDistanceCallBack = (ret) => {
    this.setState({ distanceFilter: ret[0].value, DistanceSelected: ret[0].label });
  };

  selectGroups = () => {
    this.MultiSelect(
      window.guide_obj.getGroupsToDisplay(),
      this.state.Groups,
      "multi",
      this.selectGroupsCallBack,
      "Select Groups"
    );
  };

  selectGroupsCallBack = (Groups) => {
    // let groups = [];
    // for (let i = 0; i < Groups.length; i++) {
    //   groups.push(Groups[i].label);
    // }
    console.log("Groups", Groups);
    if (Groups.length > 0) this.setState({ linkText: Groups[0].label });
    this.setState({ Groups: Groups });
  };
  selectDay = () => {
    const days = window.guide_obj.getChooseDatesList(this.state.ScheduleName);
    this.MultiSelect(
      days,
      [{ label: this.state.day, value: this.state.day }],
      "uni",
      this.selectDayCallBack,
      "Select day"
    );
  };

  selectDayCallBack = (days) => {
    //console.log("FilterDatesCallBack", days);
    if (days.length > 0) this.setState({ day: days[0].label });
  };

  selectSchedule = () => {
    const schedules = window.guide_obj.schedule.geScheduleNames();
    this.MultiSelect(
      schedules,
      [{ label: this.state.scheduleName, value: this.state.scheduleName }],
      "uni",
      this.selectScheduleCallBack,
      "Select Schedule"
    );
  };

  selectScheduleCallBack = (schedules) => {
    //console.log("selectScheduleCallBack", itineraries[0].label);
    if (schedules.length > 0)
      this.setState({
        scheduleName: schedules[0].label,
        linkText: schedules[0].label,
        // linkTextDisplayed: schedules[0].label,
      });
  };

  selectItinerary = () => {
    const schedules = window.guide_obj.schedule.geScheduleNames();
    this.MultiSelect(
      schedules,
      [{ label: this.state.itineraryName, value: this.state.itineraryName }],
      "uni",
      this.selectItineraryCallBack,
      "Select Itinerary"
    );
  };

  selectItineraryCallBack = (itineraries) => {
    //console.log("selectScheduleCallBack", itineraries[0].label);
    if (itineraries.length > 0)
      this.setState({
        itineraryName: itineraries[0].label,
        linkText: itineraries[0].label,
        // linkTextDisplayed: itineraries[0].label,
      });
  };

  displayIDList(arr, sep) {
    if (sep == null) sep = ", ";
    let str = "";
    for (let i = 0; i < arr.length; i++) {
      if (str != "") str += sep;
      str += arr[i].value.id;
    }
    //console.log("displayIDList", arr, str);
    return str;
  }

  displayValueList(arr, sep) {
    if (sep == null) sep = ", ";
    let str = "";
    for (let i = 0; i < arr.length; i++) {
      if (str != "") str += sep;
      str += arr[i].label;
    }
    //console.log("displayValueList", arr, str);
    return str;
  }

  selectPlacesUni = () => {
    this._fromPlace = false;
    this.setState({ FromPlaces: [], FromPlaceIds: [], FromPlaceNames: [] });
    this.selectPlaces("uni");
  };

  selectPlacesMulti = () => {
    this._fromPlace = false;
    this.setState({ FromPlaces: [], FromPlaceIds: [], FromPlaceNames: [] });
    this.selectPlaces("multi");
  };

  _fromPlace = false;

  selectPlacesUniFrom = () => {
    this._fromPlace = true;
    this.selectPlaces("uni");
  };

  selectPlaces = (mode) => {
    this.MultiSelect(
      window.guide_obj.places.getPlacesToDisplay(null),
      this.state.Places,
      mode,
      this.selectPlacesCallBack,
      "Places"
    );
  };

  selectPlacesCallBack = (Places) => {
    //console.log(Places);
    //if (Places === null) return;
    //if (Places.length === 0) return;
    let placeIds = [];
    let placeNames = [];
    Places.forEach((place) => {
      placeIds.push(place.value);
      placeNames.push(place.label);
    });
    //console.log("placeIds", placeIds);
    if (this._fromPlace === true) {
      this._fromPlace = false;
      this.setState({ FromPlaces: Places, FromPlaceIds: placeIds, FromPlaceNames: placeNames });
      if (Places.length > 0 && this.state.PlaceNames.length > 0)
        this.setState({
          linkText: `From ${Places[0].label} to ${this.state.PlaceNames[0]}`,
          // linkTextDisplayed: `From ${Places[0].label} to ${this.state.PlaceNames[0]}`,
        });
    } else {
      this.setState({ Places: Places, PlaceIds: placeIds, PlaceNames: placeNames });
      if (Places.length > 0 && this.state.FromPlaceNames.length > 0)
        this.setState({
          linkText: `From ${this.state.FromPlaceNames[0]} to ${Places[0].label}`,
          // linkTextDisplayed: `From ${this.state.FromPlaceNames[0]} to ${Places[0].label}`,
        });
      else if (Places.length > 0) this.setState({ linkText: Places[0].label });
    }
  };

  _imageType = "link";

  selectImageLinkURL = () => {
    this.setState({
      PromptModalShow: true,
      PromptModalTitle: "Image URL",
      PromptModalMessage: "Enter Image URL",
      PromptModalButtons: [
        { Title: "OK", Method: this.changeImageURL },
        { Title: "CANCEL", Method: null },
      ],
      PromptModalInputs: [{ name: "Image URL", value: this.state.imageURL }],
    });
  };

  changeImageURL = (inputs) => {
    if (inputs === null) return;
    this.setState({ imageURL: inputs[0].value });
  };

  selectImageUIURL = () => {
    this.setState({
      PromptModalShow: true,
      PromptModalTitle: "Image URL",
      PromptModalMessage: "Enter Image URL",
      PromptModalButtons: [
        { Title: "OK", Method: this.changeImageUIURL },
        { Title: "CANCEL", Method: null },
      ],
      PromptModalInputs: [{ name: "Image URL", value: this.state.imageURL }],
    });
  };

  changeImageUIURL = (inputs) => {
    if (inputs === null) return;
    this.setState({ imageUIURL: inputs[0].value });
  };

  selectImageUI = () => {
    this.selectImage("ui");
  };

  selectIcon = () => {
    this.setState({ IconsDialogShow: true });
  };

  UpdateIcon = (icon) => {
    console.log("UpdateIcon", icon);
    this.setState({ linkIcon: icon });
  };

  selectImageLink = () => {
    console.log("selectImageLink");
    this.selectImage("link");
  };

  selectImage = (type) => {
    console.log("selectImage");
    this._imageType = type;
    this.setState({
      ImageDialogShow: true,
    });
    // this.props.navigation.navigate("ImageDialog", {
    //   mode: "select",
    //   size: "medium",
    //   multi: false,
    //   updateImage: this.updateImageCallBack,
    // });
  };

  updateImageCallBack = (imageInfo) => {
    console.log("updateImageCallBack", imageInfo, this._imageType);
    if (imageInfo.length === 0) return;
    if (this._imageType === "link") {
      if (imageInfo[0].url_ !== "") this.setState({ imageURL: imageInfo[0].url_m });
      else this.setState({ imageURL: imageInfo[0].url });
    } else {
      if (imageInfo[0].url_ !== "") this.setState({ imageURL: imageInfo[0].url });
      else this.setState({ imageURL: imageInfo[0].url_m });
    }
  };

  getImageURLString = (str) => {
    const url = Common.getImageURL(str, null, true);
    //console.log(url);
    return url;
  };

  selectAudio = () => {
    //console.log("selectAudio");
  };

  selectVideo = () => {
    //console.log("selectVideo");
  };

  selectGuide = () => {
    this.setState({ GuideDownloadShow: true });
  };

  selectGuideCallback = (guide_info_id, guide_id, guide_name) => {
    //console.log("selectGuideCallback", guide_id);
    this.setState({
      guideID: guide_id,
      guideName: guide_name,
      guide_info_id: guide_info_id,
    });
  };

  selectSection = () => {
    //console.log("selectSection");
    this.setState({ SectionsDialogShow: true });
    //this.props.navigation.navigate("SectionSelect", { callback: this.selectSectionCallback });
  };

  // selectSectionCallback = (section) => {
  //   let _sectionName = this.state.sectionName;
  //   if (_sectionName == "") _sectionName = section.Title;
  //   this.setState({ sectionLink: section.ID, sectionName: _sectionName });
  // };

  selectSectionCallback = (section) => {
    //console.log("selectSectionCallback", sectionLink, sectionName);
    //this._section = sectionLink;
    let _sectionName = this.state.sectionName;
    if (_sectionName === "") _sectionName = section.Title;
    this.setState({
      sectionLink: section.ID,
      sectionName: _sectionName,
      linkText: _sectionName,
      // linkTextDisplayed: _sectionName,
    });
  };

  updateShowOnlyFavorites = (value) => {
    this.setState({ ShowOnlyFavorites: value });
  };

  updateShowOnlyVisited = (value) => {
    this.setState({ ShowOnlyVisited: value });
  };

  updateShowOnlyNotVisited = (value) => {
    this.setState({ ShowOnlyNotVisited: value });
  };

  updateShowOnlyMustSee = (value) => {
    this.setState({ ShowOnlyMustSee: value });
  };

  updateScheduleFilter = (value) => {
    this.setState({ ScheduleFilter: value });
  };

  updateDayFilter = (value) => {
    this.setState({ DayFilter: value });
  };

  yes_no_props = [
    { label: "Yes  ", value: true },
    { label: "No  ", value: false },
  ];

  imageSourceMode = (value) => {
    this.setState({ imageSource: value });
  };

  selectLinkUI = (value) => {
    //console.log("selectLinkUI", value);
    //this.setState({ linkUI: value });
  };

  openClosePicker = () => {
    this.setState({ DisplayPicker: !this.state.DisplayPicker });
  };

  setPickerValue = (value) => {
    //console.log("setPickerValue", value);
    this.setState({ PickerValue: value });
  };

  setPickerItems = (value) => {
    //console.log("setPickerItems", value);
    this.setState({ PickerItems: value });
  };

  UIOptions = [
    { name: "Text only", value: "0" },
    { name: "With Image", value: "1" },
    { name: "With Button", value: "2" },
    { name: "With Icon", value: "3" },
  ];

  FilterOptionSelected = (index) => {
    let filters = this.state.FilterOptions;
    filters[index].selected = !filters[index].selected;
    //this.setState({ FilterOptions: filters });
    //console.log("FilterOptionSelected", filters);

    let _DayFilter = this.FilterOptionChoosen("Filter by Day", filters);
    //SetFilterByDay(_FilterByDay);
    let _ScheduleFilter = this.FilterOptionChoosen("Filter by Schedule", filters);
    //SetFilterBySchedule(_FilterBySchedule);

    if (_DayFilter) filters = filters.filter((x) => x.name !== "Filter by Schedule");
    else if (_ScheduleFilter) filters = filters.filter((x) => x.name !== "Filter by Day");
    else {
      let index = filters.findIndex((x) => x.name === "Filter by Schedule");
      if (index === -1) filters.push({ name: "Filter by Schedule", value: "4", selected: false });
      index = filters.findIndex((x) => x.name === "Filter by Day");
      if (index === -1) filters.push({ name: "Filter by Day", value: "5", selected: false });
      console.log("FilterOptionSelected", filters);
    }

    let ShowOnlyFavorites = false;
    let ShowOnlyVisited = false;
    let ShowOnlyNotVisited = false;
    let ShowOnlyMustSee = false;
    let ScheduleFilter = false;
    let DayFilter = false;
    filters.forEach((filter) => {
      if (filter.value === "0") ShowOnlyFavorites = filter.selected;
      if (filter.value === "1") ShowOnlyVisited = filter.selected;
      if (filter.value === "2") ShowOnlyNotVisited = filter.selected;
      if (filter.value === "3") ShowOnlyMustSee = filter.selected;
      if (filter.value === "4") ScheduleFilter = filter.selected;
      if (filter.value === "5") DayFilter = filter.selected;
    });
    filters = filters.sort((a, b) => (parseInt(a.value) > parseInt(b.value) ? 1 : -1));
    this.setState({
      FilterOptions: filters,
      DayFilter: _DayFilter,
      ScheduleFilter: _ScheduleFilter,
      ShowOnlyFavorites: ShowOnlyFavorites,
      ShowOnlyVisited: ShowOnlyVisited,
      ShowOnlyNotVisited: ShowOnlyNotVisited,
      ShowOnlyMustSee: ShowOnlyMustSee,
      // ScheduleFilter: ScheduleFilter,
      // DayFilter: DayFilter,
    });

    // setTimeout(() => {
    //   // CheckFilterOptions1();
    // }, 100);
  };

  FilterOptionChoosen = (name, options) => {
    const option = options.findIndex((x) => x.name === name && x.selected === true);
    if (option !== -1) return true;
    else return false;
  };

  closeModal = () => {
    console.log("closeModal");
    this.props.onHide();
  };

  linkTypeChange = (event) => {
    console.log("linkTypeChange", event.target.value);
    this.state.PickerItems.forEach((item) => {
      if (item.label === event.target.value) this.selectLinkTypeCallBack([item]);
    });
  };

  render() {
    //console.log(this.state.Places);
    //const section = this.props.navigation.getParam("section", null);
    let icon_img_src = null;
    if (typeof this.state.linkIcon.obj !== "undefined") {
      if (typeof this.state.linkIcon.obj.url !== "undefined") icon_img_src = this.state.linkIcon.obj.url;
    } else icon_img_src = POI_IMAGES[this.state.linkIcon.id].req;
    if (this.state.ShowMultiselect)
      return (
        <MultiSelectModal
          show={this.state.ShowMultiselect}
          onHide={() => this.setState({ ShowMultiselect: false })}
          MultiselectItems={this.state.MultiselectItems}
          MultiselectSelectedItems={this.state.MultiselectSelectedItems}
          MultiselectMode={this.state.MultiselectMode}
          MultiselectCallback={this.state.MultiselectCallback}
          MultiselectTitle={this.state.MultiselectTitle}
          MultiselectSearchText={this.state.MultiselectSearchText}
        />
      );
    if (this.state.ImageDialogShow)
      return (
        <ImageDialog
          show={this.state.ImageDialogShow}
          onHide={() => this.setState({ ImageDialogShow: false })}
          user_info={this.props.user_info}
          // callback={this.updateImageCallBack}
          mode={"select"}
          modify_only={"true"}
          updateImage={this.updateImageCallBack}
          size={"sm"}
          multi={false}
          exif={false}
        />
      );
    return (
      <div className="modal" style={{ zIndex: 65537 }}>
        <Modal
          {...this.props}
          dialogClassName="myModalClass"
          aria-labelledby="contained-modal-title-vcenter"
          // centered={true}
          scrollable={true}
          size={window.innerWidth < 450 ? "lg" : "md"}
          // style={{ padding: "50px" }}
          onHide={this.closeModal}
        >
          <Modal.Header>
            <div style={{ width: "100%" }}>
              <div className="sort">
                <div className="dialog-heading">
                  <div className="dialog-heading">
                    <div>
                      <h4 style={{ color: "#3598DB" }}>HTML Links</h4>
                    </div>
                    <span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <i className={`fa fa-check`} onClick={this.AddLink}></i>
                    <div>
                      <i className={`fas fa-times`} onClick={this.closeModal}></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="form-item">
              <MyToolTip
                loc="left"
                msg="Select the type of link that you want inserted into the HTML. Clicking on the link will display the corresponding item e.g. a link to a video, audio, place etc. Note that unlike inserted HTML, links have the advantage of being dynamic and the content gets created based on the current state."
              >
                <h5 className="form-item color-blue">Select link Type</h5>
              </MyToolTip>

              {/* <p>
                  <Button variant="primary" size="sm" onClick={this.selectLinkType}>
                    {`Type: ${this.state.linkType}`}
                  </Button>
                </p> */}
              <Form.Control as="select" custom onChange={this.linkTypeChange} defaultValue={this.state.linkType}>
                <option value="Audio">Audio</option>
                <option value="Directions">Directions</option>
                <option value="Guide">Guide</option>
                <option value="Image">Image</option>
                <option value="Itinerary">Itinerary</option>
                <option value="Map">Map/Filter</option>
                <option value="Place Info">Place Info</option>
                <option value="Place Menu">Place Menu</option>
                <option value="Place More Info">Place More Info</option>
                <option value="Section">Section</option>
                <option value="Schedule">Schedule</option>
                <option value="Street View">Street View</option>
                <option value="URL">URL</option>
                <option value="Video">Video</option>
                {/* <option value="Video Path">Video Path</option> */}
                <option value="Weather">Weather</option>
              </Form.Control>
              <br></br>
              <br></br>
              <MyToolTip
                loc="left"
                msg="Select how the link will look like on the page. It can be: text, image, button or map icon."
              >
                <h5 className="form-item color-blue">Select UI Type</h5>
              </MyToolTip>

              <div>
                <ButtonGroup className="form-item">
                  {this.UIOptions.map((radio, idx) => (
                    <ToggleButton
                      key={idx}
                      id={`radio-${idx}`}
                      type="radio"
                      variant={"outline-primary"}
                      name="radio"
                      value={radio.value}
                      // checked={this.selectLinkUI(radio.value)}
                      // onChange={(e) => this.selectLinkUI(e.currentTarget.value)}
                      checked={this.state.UIOptionSelected === radio.value}
                      onChange={(e) => this.HandleUIOptionSelected(e.currentTarget.value)}
                    >
                      {radio.name}
                    </ToggleButton>
                  ))}
                </ButtonGroup>
              </div>
              {this.state.UIOptionSelected === "0" ||
              this.state.UIOptionSelected === "1" ||
              this.state.UIOptionSelected === "2" ? (
                <div>
                  <h5 className="form-item color-blue">Enter Link text</h5>
                  <div className="form-item">
                    <Form.Group>
                      <Form.Control
                        type="text"
                        value={this.state.linkText}
                        onChange={(e) => this.handleChangeLinkText(e.target.value)}
                      />
                    </Form.Group>
                  </div>
                </div>
              ) : null}
              {this.state.UIOptionSelected === "1" ? (
                <div className="form-item">
                  <h5 className="form-item color-blue">Select UI Image</h5>
                  <Button className="form-item" variant="primary" size="sm" onClick={() => this.selectImageUI()}>
                    Select UI Image
                  </Button>
                  <span> or </span>
                  <Button className="form-item" variant="primary" size="sm" onClick={() => this.selectImageUIURL()}>
                    Enter UI Image URL
                  </Button>
                  <div className="image">
                    <img
                      src={this.state.imageUIURL}
                      alt=""
                      className="text-center rounded"
                      // height="100px"
                      width="100px"
                      // overflow="hidden"
                      style={{ objectFit: "cover", maxHeight: "100px" }}
                    />
                  </div>
                  {/* <p>{this.state.imageURL}</p> */}
                </div>
              ) : null}
              {this.state.UIOptionSelected === "3" ? (
                <div className="form-item">
                  <h5 className="form-item color-blue">Select Icon</h5>
                  <Button className="form-item" variant="primary" size="sm" onClick={() => this.selectIcon()}>
                    Select Icon
                  </Button>
                  <div className="image">
                    <img src={icon_img_src} />
                  </div>
                  {/* <p>{this.state.imageURL}</p> */}
                </div>
              ) : null}
              {["Directions"].includes(this.state.linkType) ? (
                <div className="form-item">
                  <h5 className="form-item color-blue">Select From Place</h5>
                  <Button variant="primary" size="sm" onClick={() => this.selectPlacesUniFrom("uni")}>
                    Select From Place
                  </Button>
                  <p>{this.displayValueList(this.state.FromPlaces)}</p>
                </div>
              ) : null}
              {[
                "Place Info",
                "Place More Info",
                "Place Menu",
                "Street View",
                "Directions",
                "Weather",
                "Video Path",
              ].includes(this.state.linkType) ? (
                <div className="form-item">
                  <h5 className="form-item color-blue">Select Place</h5>
                  <Button className="form-item" variant="primary" size="sm" onClick={() => this.selectPlacesUni("uni")}>
                    {["Directions"].includes(this.state.linkType) ? "Select To Place" : "Select Place"}
                  </Button>
                  <p>{this.displayValueList(this.state.Places)}</p>
                </div>
              ) : null}
              {["Schedule"].includes(this.state.linkType) ||
              (["Map"].includes(this.state.linkType) && this.state.ScheduleFilter) ? (
                <div className="form-item">
                  <h5 className="form-item color-blue">Select Schedule</h5>
                  <Button className="form-item" variant="primary" size="sm" onClick={() => this.selectSchedule()}>
                    Select Schedule
                  </Button>
                  <p>{this.state.scheduleName}</p>
                </div>
              ) : null}
              {["Map"].includes(this.state.linkType) && this.state.DayFilter ? (
                <div className="form-item">
                  <h5 className="form-item color-blue">Select Days</h5>
                  <Button className="form-item" variant="primary" size="sm" onClick={() => this.selectDay()}>
                    Select Days
                  </Button>
                  <p>{this.state.day}</p>
                </div>
              ) : null}
              {["Image"].includes(this.state.linkType) ? (
                <div className="form-item">
                  <h5 className="form-item color-blue">Select Image</h5>
                  <Button className="form-item" variant="primary" size="sm" onClick={() => this.selectImageLink()}>
                    Select Image
                  </Button>
                  <span> or </span>
                  <Button className="form-item" variant="primary" size="sm" onClick={() => this.selectImageLinkURL()}>
                    Enter Image URL
                  </Button>
                  <div className="image">
                    <img
                      src={this.state.imageURL}
                      alt=""
                      className="text-center rounded"
                      // height="100px"
                      width="100px"
                      // overflow="hidden"
                      style={{ objectFit: "cover", maxHeight: "100px" }}
                    />
                  </div>
                  {/* <p>{this.state.imageURL}</p> */}
                </div>
              ) : null}
              {["Video", "Video Path", "Audio", "URL"].includes(this.state.linkType) ? (
                <div className="form-item">
                  <div>
                    <h5 className="form-item color-blue">Enter URL</h5>
                  </div>
                  <div style={{ width: "100%" }}>
                    <textarea
                      rows={8}
                      onChange={this.handleChangeURL}
                      placeholder="Enter URL"
                      //value={this.state.url}
                      style={{ width: "100%", height: "100%", fontSize: "16px" }}
                    />
                  </div>
                </div>
              ) : null}
              {["Video", "Video Path"].includes(this.state.linkType) &&
              (this.state.url.includes("https://www.youtu") || this.state.url.includes("https://youtu")) ? (
                <div className="form-item">
                  <div>
                    <h5 className="form-item color-blue">Enter Start Time (sec)</h5>
                  </div>
                  <div style={{ width: "100%" }}>
                    <input type="text" placeholder="Enter start time (seconds)" onChange={this.StartTimeChanged} />
                  </div>
                </div>
              ) : null}
              {["Map", "Places Filter"].includes(this.state.linkType) ? (
                <div className="form-item">
                  <h5 className="form-item color-blue">Select Places</h5>
                  <Button
                    className="form-item"
                    variant="primary"
                    size="sm"
                    onClick={() => this.selectPlacesMulti("multi")}
                  >
                    Select Places
                  </Button>
                  <p>{this.displayValueList(this.state.Places)}</p>
                  <h5 className="form-item color-blue">Select Groups</h5>
                  <Button className="form-item" variant="primary" size="sm" onClick={() => this.selectGroups("multi")}>
                    Select Groups
                  </Button>
                  <p>{`${this.displayValueList(this.state.Groups)}`}</p>
                  <h5 className="form-item color-blue">Select Categories</h5>
                  <Button
                    className="form-item"
                    variant="primary"
                    size="sm"
                    onClick={() => this.selectCategories("multi")}
                  >
                    Select Categories
                  </Button>
                  <p>{`${this.displayValueList(this.state.Categories)}`}</p>

                  {/* <Form>
                      {FilterOptions.map((option, i) => (
                        <div className="mb-3">
                          <Form.Check type={"checkbox"} id={i} label={option.name} />
                        </div>
                      ))}
                    </Form> */}

                  <div className="multiselect-list">
                    {this.state.FilterOptions.map((element, index) => (
                      <div className="multiselect-item" key={index}>
                        <Form.Check
                          type="checkbox"
                          id="autoSizingCheck2"
                          checked={element.selected}
                          onChange={() => this.FilterOptionSelected(index)}
                        />
                        <div className="country">
                          <p>{element.name}</p>
                        </div>
                      </div>
                    ))}
                  </div>

                  {/* <Form>
                      <Form.Control as="select" multiple value={FilterOptions} onChange={onSelectedOptionsChange}>
                        {FilterOptions.map((option) => (
                          <option key={option.name} value={option.value}>
                            {option.name}
                          </option>
                        ))}
                      </Form.Control>
                    </Form> */}
                  {/* <ButtonGroup type="checkbox" vertical={true}>
                      {FilterOptions.map((radio, idx) => (
                        <ToggleButton
                          key={idx}
                          id={`radio-${idx}`}
                          type="checkbox"
                          variant={"outline-primary"}
                          name="radio"
                          value={radio.value}
                          checked={FilterOptionSelected === radio.value}
                          onChange={(e) => SetFilterOptionSelected(e.currentTarget.value)}
                        >
                          {radio.name}
                        </ToggleButton>
                      ))}
                    </ButtonGroup> */}
                </div>
              ) : null}
              {["Itinerary"].includes(this.state.linkType) ? (
                <div className="form-item">
                  <h5 className="form-item color-blue">Select Schedule</h5>
                  <Button className="form-item" variant="primary" size="sm" onClick={() => this.selectItinerary("uni")}>
                    Select Itinerary
                  </Button>
                  <p>{this.state.itineraryName}</p>
                </div>
              ) : null}
              {["Guide"].includes(this.state.linkType) ? (
                <div className="form-item">
                  <h5 className="form-item color-blue">Select Guide</h5>
                  <Button className="form-item" variant="primary" size="sm" onClick={() => this.selectGuide("uni")}>
                    Select Guide
                  </Button>
                  <p>{this.state.guideName}</p>
                  <p>{this.state.guide_info_id}</p>
                </div>
              ) : null}
              {["Section"].includes(this.state.linkType) ? (
                <div className="form-item">
                  <h5 className="form-item color-blue">Select Section</h5>
                  <Button variant="primary" size="sm" onClick={() => this.selectSection("uni")}>
                    Select Section
                  </Button>
                  <p>{this.state.SectionName}</p>
                </div>
              ) : null}
            </div>
          </Modal.Body>
        </Modal>
        {/* <UserInfoModal show={ShowUserInfoModal} onHide={() => setShowUserInfoModal(false)} /> */}

        <AlertModal
          show={this.state.AlertModalShow}
          onHide={() => this.setState({ AlertModalShow: false })}
          AlertModalTitle={this.state.AlertModalTitle}
          AlertModalMessage={this.state.AlertModalMessage}
          AlertModalButtons={this.state.AlertModalButtons}
        />
        {this.state.SectionsDialogShow ? (
          <SectionsDialog
            show={this.state.SectionsDialogShow}
            onHide={() => this.setState({ SectionsDialogShow: false })}
            update_display={null}
            mode={"select"}
            callback={this.selectSectionCallback}
          />
        ) : null}
        {this.state.GuideDownloadShow ? (
          <GuideDownload
            show={this.state.GuideDownloadShow}
            onHide={() => this.setState({ GuideDownloadShow: false })}
            user_info={this.props.user_info}
            guide_sort_option={[]}
            set_guide_sort_option={null}
            callback={this.selectGuideCallback}
            mode={"list"}
            modify_only={"false"}
            guide_meta={this.props.guide_meta}
            guide_type={this.props.guide_type}
          />
        ) : null}
        {this.state.IconsDialogShow ? (
          <IconsDialog
            show={this.state.IconsDialogShow}
            onHide={() => this.setState({ IconsDialogShow: false })}
            update_icon={this.UpdateIcon}
          />
        ) : null}
        <PromptModal
          show={this.state.PromptModalShow}
          onHide={() => this.setState({ PromptModalShow: false })}
          PromptModalTitle={this.state.PromptModalTitle}
          PromptModalMessage={this.state.PromptModalMessage}
          PromptModalButtons={this.state.PromptModalButtons}
          PromptModalInputs={this.state.PromptModalInputs}
          area={true}
        />
      </div>
    );
  }
}
export default HTMLLinksModalClass;
