import { connect } from "react-redux";
import React, { Component, useState, useEffect } from "react";
import { Modal, Form, Button, Spinner } from "react-bootstrap";
import AlertModal from "./AlertModal";
import MyToolTip from "./MyToolTip";
import "./Modal.css";
import PromptModal from "./PromptModal";
import MultiSelectModal from "./MultiSelectModal";
import IconsDialog from "./IconsDialog";
import Place from "../../Models/Place";
import DataBase from "../../dataBase/liquidGuides";
import Common, { SortByEnum, POI_IMAGES, COLORS, AICategories, AIBaseCategories } from "../../Common/Common";
import Appointment from "../../Models/Event";
import { DateTime, Settings } from "luxon";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import GooglePlaces from "../../Models/GooglePlaces";
import GoogleSearch from "../../Models/GoogleSearch";
import { countries } from "../../Data/Countries";

class AISearch extends Component {
  constructor(props) {
    super(props);
    let city = "Florence";
    let country = "Italy";
    let attributes = window.guide_obj.attributes;
    if (attributes.cities.length > 0) {
      city = attributes.cities[0];
    }
    if (attributes.countries.length > 0) {
      country = attributes.countries[0];
    }
    let place = null;
    if (typeof this.props.id !== "undefined") {
      place = window.guide_obj.places.getPlace(this.props.id);
    }
    let location = null;
    if (typeof this.props.location !== "undefined") {
      location = this.props.location;
    }
    //console.log("this.props.location", this.props.location);
    //console.log("location", location);
    let curDate = DateTime.now().toISO({ zone: "utc" });
    curDate = DateTime.fromISO(curDate, { zone: "utc" });
    curDate = curDate.set({ hour: 9 });
    curDate = curDate.set({ minute: 0 });
    this.state = {
      wizardStep: 0,
      selectedQuestion: "When is the best time to visit Florence",
      selectedNumber: "3",
      selectedCity: city,
      selectedCountry: country,
      place: place,
      location: location,
      LatLong: "0,0",
      mood: "standard",
      radius: "50Km",
      proximity: "in",
      Schedule: "",
      ScheduleText: "",
      Places: "",
      PlacesArray: [],
      PlacesFound: [],
      answer: "",
      SearchText: "",
      dateStart: curDate,
      AICategories: [],
      AIBaseCategories: [],

      AlertModalShow: false,
      activityIndicator: false,
      AlertModalTitle: "",
      AlertModalMessage: "",
      AlertModalButtons: [],

      PromptModalShow: false,
      PromptModalTitle: "Enter Value",
      PromptModalMessage: "",
      PromptModalButtons: [],
      PromptModalInputs: [],

      MultiselectItems: [],
      MultiselectSelectedItems: [],
      MultiselectMode: "uni",
      MultiselectCallback: null,
      MultiselectTitle: "Select",
      MultiselectSearchText: "",
      ShowMultiselect: false,
    };
  }
  componentDidMount() {
    let _AICategories = [];
    let _AIBaseCategories = [];
    try {
      _AICategories = JSON.parse(this.props.ai_categories);
    } catch (e) {
      console.log("Error parsing this.props.ai_categories");
      _AICategories = AICategories;
    }
    try {
      _AIBaseCategories = JSON.parse(this.props.ai_base_categories);
    } catch (e) {
      console.log("Error parsing this.props.ai_base_categories");
      _AIBaseCategories = AIBaseCategories;
    }
    this.setState({ AICategories: _AICategories, AIBaseCategories: _AIBaseCategories });
  }

  _user_info = null;
  MyAlert = (title, message) => {
    if (typeof message === "undefined") message = "";
    this.setState({
      AlertModalTitle: title,
      AlertModalMessage: message,
      AlertModalButtons: [{ Title: "OK", Method: null }],
      AlertModalShow: true,
    });
  };

  // modifyCity = () => {
  //   this.setState({
  //     PromptModalShow: true,
  //     PromptModalTitle: "City",
  //     PromptModalMessage: "Enter city Name",
  //     PromptModalButtons: [
  //       { Title: "OK", Method: this.ModifyCityCallback },
  //       { Title: "CANCEL", Method: null },
  //     ],
  //     PromptModalInputs: [{ name: "City Name", value: this.state.selectedCity }],
  //   });
  // };

  // ModifyCityCallback = (inputs) => {
  //   if (inputs === null) return;
  //   let city = inputs[0].value;
  //   this.setState({ selectedCity: city });
  // };

  // modifyCountry = () => {
  //   this.setState({
  //     PromptModalShow: true,
  //     PromptModalTitle: "Country",
  //     PromptModalMessage: "Enter Country Name",
  //     PromptModalButtons: [
  //       { Title: "OK", Method: this.ModifyCountryCallback },
  //       { Title: "CANCEL", Method: null },
  //     ],
  //     PromptModalInputs: [{ name: "Country Name", value: this.state.selectedCountry }],
  //   });
  // };

  // ModifyCountryCallback = (inputs) => {
  //   if (inputs === null) return;
  //   let country = inputs[0].value;
  //   this.setState({ selectedCountry: country });
  // };

  numberChanged = (e) => {
    this.setState({ selectedNumber: e.target.value });
  };

  moodChanged = (e) => {
    this.setState({ mood: e.target.value });
  };

  radiusChanged = (e) => {
    this.setState({ radius: e.target.value });
  };

  LatLongChanged = (e) => {
    this.setState({ LatLong: e.target.value });
  };

  handleChangeSchedule = (e) => {
    this.setState({ Schedule: e.target.value });
  };

  handleChangePlaces = (e) => {
    this.setState({ Places: e.target.value });
  };

  questionChanged = (e) => {
    let quest = e.target.value;
    if (quest === "Choose a question") return;
    this.setState({ selectedQuestion: quest, answer: "", activityIndicator: true });
    this.askQuestion(quest, null);
  };
  askQuestion = (quest, callback) => {
    quest =
      `schedule for ` +
      quest +
      //` Respond in json format with attributes: 'Attraction', 'Category' and 'Description' for each entry`;
      ` Provide the response in JSON, following this format without deviation.
    [{
      'Attraction': 'the name of the attraction',
      'City': 'the city in which the attraction is located',
      'Category': 'the category of the attraction',
      'Description': 'a description of the attraction'
      'Duration': 'the amount of time to visit the attraction, in minutes',
    }]`;
    //console.log("Question", quest);
    //this.MyAlert(`Question: ${quest}`);
    //quest = encodeURIComponent(quest);
    var url = `https://andiamotravelapp.com/api/travel_questionsGPT4.php?q="${quest}"`;
    let _this = this;
    fetch(url)
      .then(function (response) {
        return response.json();
      })
      .then(function (response) {
        if (response === null || typeof response === "undefined") {
          console.log("Error in AI response");
          _this.MyAlert("Error in AI response");
          _this.setState({ activityIndicator: false });
          return;
        }
        if (response.choices === null || typeof response.choices === "undefined") {
          console.log("Error in AI answer, choices", response);
          _this.MyAlert("Error in AI answer, choices");
          _this.setState({ activityIndicator: false });
          return;
        }
        if (response.choices.length === 0) {
          console.log("Error in AI answer, choices length", response);
          _this.MyAlert("Error in AI answer, choices length");
          _this.setState({ activityIndicator: false });
          return;
        }
        if (response.choices[0].message === null || typeof response.choices[0].message === "undefined") {
          console.log("Error in AI answer, message", response);
          _this.MyAlert("Error in AI answer, message");
          _this.setState({ activityIndicator: false });
          return;
        }
        if (
          response.choices[0].message.content === null ||
          typeof response.choices[0].message.content === "undefined"
        ) {
          console.log("Error in AI answer, message content", response);
          _this.MyAlert("Error in AI answer, message content");
          _this.setState({ activityIndicator: false });
          return;
        }
        let answer = response.choices[0].message.content;
        if (answer === null || typeof answer === "undefined") {
          console.log("Error in AI answer, text");
          _this.MyAlert("Error in AI answer, text");
          _this.setState({ activityIndicator: false });
          return;
        }
        if (answer.startsWith("\n")) answer = answer.replace("\n", "");
        if (answer.startsWith("\n")) answer = answer.replace("\n", "");
        if (response.choices !== null) {
          const resp = _this.formatResponse(answer);
          if (resp.sched_json === null) {
            this.setState({ activityIndicator: false });
            this.props.onHide();
          }
          if (resp.sched_json.length === 0) {
            this.setState({ activityIndicator: false });
            this.props.onHide();
          }
          _this.findAllLatLongs(resp.sched_json);
          _this.setState({ answer: resp.sched_tex });
        }
        //console.log("QUESTION: ANSWER:", quest, "***", answer);
        if (callback !== null) callback(answer);
        //if (response.choices !== null) _this.setState({ answer: JSON.stringify(response), activityIndicator: false });
      })
      .catch(function (error) {
        console.log("Error in search", error);
        _this.setState({ answer: error, activityIndicator: false });
      });
  };

  formatResponse = (response) => {
    console.log("formatResponse", response);
    try {
      let sched = JSON.parse(response);
      let sched_tex = "";
      let itinArray = null;
      if (Array.isArray(sched)) itinArray = sched;
      else {
        const keys = Object.keys(sched);
        let _itin = [];
        for (let k = 0; k < keys.length; k++) {
          let _array = sched[keys[k]];
          if (Array.isArray(_array))
            for (let x = 0; x < _array.length; x++) {
              _array[x].Date = keys[k];
              _itin.push(_array[x]);
            }
          else _itin.push(_array);
        }
        itinArray = _itin;
      }
      for (let i = 0; i < itinArray.length; i++) {
        sched_tex += `${itinArray[i].Attraction}\n`;
        sched_tex += `${itinArray[i].Category}\n`;
        //sched_tex += `${itinArray[i].Duration}\n`;
        sched_tex += `${itinArray[i].Description}\n\n`;
      }
      return { sched_tex: sched_tex, sched_json: itinArray };
    } catch (e) {
      this.MyAlert("Alert", "Error retrieving or parsing search results");
      return "";
    }
  };

  findAllLatLongs = (places) => {
    const _at = new GooglePlaces();
    for (let i = 0; i < places.length; i++) {
      this.googleTextSearch(
        `${places[i].Attraction}, near ${this.state.selectedCity} ${this.state.selectedCountry}`,
        i === places.length - 1,
        places[i]
      );
    }
  };

  items = [
    // {
    //   label: "Florence in Italy.",
    //   params: "City",
    // },
    {
      label: "Top 10 golf courses to visit in Florence Italy.",
      params: "City",
    },
    {
      label: "Top 10 attractions to visit in Florence Italy.",
      params: "City",
    },
    {
      label: "Top 10 viewpoints to visit in Florence Italy.",
      params: "City",
    },
    {
      label: "Top 10 restaurants in Florence Italy.",
      params: "City",
    },
    {
      label: "Top 10 attractions to visit outside of Florence Italy.",
      params: "City",
    },
    {
      label: "Top 10 day trips from Florence Italy.",
      params: "City",
    },
    {
      label: "Top 10 cities to visit near Florence Italy.",
      params: "City",
    },
    {
      label: "Top 10 towns to visit near Florence Italy.",
      params: "City",
    },
    {
      label: "Top 10 beaches near Florence Italy.",
      params: "City",
    },
    {
      label: "Top 10 neighborhoods to stay in Florence Italy.",
      params: "City",
    },
    {
      label: "Top 10 cities to visit in Italy.",
      params: "Country",
    },
    {
      label: "Top 10 towns to visit in Italy.",
      params: "City",
    },
    {
      label: "Top 10 attractions to visit in Italy.",
      params: "City",
    },
    {
      label: "Top 10 scenic drives near Florence Italy.",
      params: "City",
    },
    {
      label: "Top 10 outdoor experiences near Florence Italy.",
      params: "City",
    },
    {
      label: "Top 10 museums in Florence Italy.",
      params: "City",
    },
    {
      label: "Top 10 parks in Florence Italy.",
      params: "City",
    },
    {
      label: "Top 10 historical sites near Florence Italy.",
      params: "City",
    },
    {
      label: "Top 10 parks near Florence Italy.",
      params: "City",
    },
    {
      label: "Top 10 churches near Florence Italy.",
      params: "City",
    },
    {
      label: "Top 10 squares near Florence Italy.",
      params: "City",
    },
    {
      label: "Top 10 hiking trails near Florence Italy.",
      params: "City",
    },
    {
      label: "Top 10 castles near Florence Italy.",
      params: "City",
    },
  ];
  handleSearChanged = (event) => {
    //console.log(event.target.value);
    this.setState({ SearchText: event.target.value });
  };

  handledDestinationChanged = (event) => {
    this.setState({ selectedCity: event.target.value });
  };

  handledCountryChanged = (event) => {
    this.setState({ selectedCountry: event.target.value });
  };

  onChangeStart = (event) => {
    const dateStart = Common.LuxonDateFromJSDate(event);
    this.setState({ ...this.state, dateStart: dateStart });
  };

  Next = (number) => {
    // if (number === 2) {
    //   this.setState({ ...this.state, wizardStep: (number += 1) });
    //   return;
    // }
    if (number === 1) {
      this.setState({ activityIndicator: true });
      // this.askQuestion(
      //   `latitude and longitude for ${this.state.selectedCity}, ${this.state.selectedCountry} in json format`,
      //   this.LatLongReceived
      // );

      const _at = new GooglePlaces();
      _at.searchPlaces(
        `${this.state.LatLong}`,
        50000,
        null,
        `${this.state.selectedCity}, ${this.state.selectedCountry}`,
        this.searchGooglePlaceLatLong
      );

      return;
    }
    if (number === 2) {
      this.setState({ activityIndicator: true });
      let type = this.state.mood === "standard" ? "" : this.state.mood;
      this.askQuestion(
        `${this.state.selectedNumber}-day ${type} schedule for ${this.state.selectedCity}, ${
          this.state.selectedCountry
        } starting on ${this.state.dateStart.toFormat(
          "LLL dd"
        )} in json format, with attributes: 'Date', 'Time', 'Attraction' and 'Description' for each entry`,
        this.scheduleReceived
      );
      // this.askQuestion(
      //   `detailed 48 hour ${type} Itinerary for ${this.state.selectedCity}, ${this.state.selectedCountry}. Your response should in json format, with 3 parameters: 'Hour', 'Place' and 'Description'`,
      //   this.scheduleReceived
      // );
      return;
    }
    if (number === 3) {
      this.setState({ activityIndicator: true });

      let places = [];
      try {
        this.setState({ PlacesFound: [] });
        let sched = JSON.parse(this.state.Schedule);
        let itinArray = null;
        if (Array.isArray(sched)) itinArray = sched;
        else {
          // const keys = Object.keys(sched);
          // const itKey = keys[0];
          // itinArray = sched[itKey];
          // if (!Array.isArray(itinArray)) itinArray = Object.entries(itinArray);
          const keys = Object.keys(sched);
          let _itin = [];
          for (let k = 0; k < keys.length; k++) {
            let _array = sched[keys[k]];
            if (Array.isArray(_array))
              for (let x = 0; x < _array.length; x++) {
                _array[x].Date = keys[k];
                _itin.push(_array[x]);
              }
            else _itin.push(_array);
          }
          itinArray = _itin;
        }
        let places_to_find = [];
        for (let i = 0; i < itinArray.length; i++) {
          if (["Lunch", "Dinner", "Breakfast"].includes(itinArray[i].Attraction)) {
            //this.addEvent(itinArray[i], null)
            continue;
          }
          if (places.includes(itinArray[i].Attraction)) {
            //this.addEvent(itinArray[i], null)
            continue;
          }
          places_to_find.push(itinArray[i].Attraction);
        }
        for (let i = 0; i < places_to_find.length; i++) {
          // if (["Lunch", "Dinner", "Breakfast"].includes(sched[itKey][i].Attraction)) {
          //   if (i === sched[itKey].length - 1) {
          //     this.setState({ wizardStep: 4, PlacesArray: places, activityIndicator: false }); //this.props.onHide();
          //     continue;
          //   }
          // }
          // if (places.includes(sched[itKey][i].Attraction)) {
          //   if (i === sched[itKey].length - 1) {
          //     this.setState({ wizardStep: 4, PlacesArray: places, activityIndicator: false }); //this.props.onHide();
          //     continue;
          //   }
          // }
          places.push(places_to_find[i]);
          this.googleTextSearch(
            `${places_to_find[i]}, near ${this.state.selectedCity} ${this.state.selectedCountry}`,
            i === places_to_find.length - 1,
            places_to_find[i]
          );
        }
        this.setState({ PlacesArray: places_to_find });
      } catch (e) {
        this.MyAlert("Alert", "Error parsing schedule");
      }
      return;
    }
    // if (number === 4) {
    //   for(let i = 0; i < this.state.PlacesFound; i++){
    //     let place = this.state.PlacesFound[0];
    //     place.Category = [];
    //     window.guide_obj.places.addPlace(place);
    //   }
    //   return;
    // }
    this.setState({ ...this.state, wizardStep: (this.state.wizardStep += 1) });
  };

  searchGooglePlaceLatLong = (google_places, label) => {
    if (google_places === null || google_places.length === 0) {
      this.MyAlert("Alert", "Error retrieving latitude, longitude");
      this.setState({ activityIndicator: false });
      return;
    }
    const _at = new GooglePlaces();
    _at.getQuickDetails(google_places, this.searchGooglePlaceLatLongFinal);
  };

  searchGooglePlaceLatLongFinal = (places) => {
    if (places.length < 1) {
      this.setState({ activityIndicator: false });
      return;
    }
    this.setState({ LatLong: `${places[0].Lat},${places[0].Longi}`, wizardStep: 2, activityIndicator: false });
    setTimeout(() => this.Next(2), 200);
  };

  parseSched = () => {
    let scheduleName = `${this.state.selectedNumber}-day ${this.state.selectedCity}`;
    try {
      //let places = this.state.PlacesFound;
      let sched = JSON.parse(this.state.Schedule);
      let itinArray = null;
      if (Array.isArray(sched)) itinArray = sched;
      else {
        // const keys = Object.keys(sched);
        // const itKey = keys[0];
        // itinArray = sched[itKey];
        // if (!Array.isArray(itinArray)) itinArray = Object.entries(itinArray);
        const keys = Object.keys(sched);
        let _itin = [];
        for (let k = 0; k < keys.length; k++) {
          let _array = sched[keys[k]];
          if (Array.isArray(_array))
            for (let x = 0; x < _array.length; x++) {
              _array[x].Date = keys[k];
              _itin.push(_array[x]);
            }
          else _itin.push(_array);
        }
        itinArray = _itin;
      }
      let dayNum = 0;
      let DateLabel = "XXXXX";
      if (itinArray.length > 0) {
        if (
          typeof itinArray[0].Time === "undefined" ||
          typeof itinArray[0].Attraction === "undefined" ||
          typeof itinArray[0].Description === "undefined"
        ) {
          this.MyAlert("Alert", "Data is not in expected format go back and try again");
          this.setState({ activityIndicator: false });
          return;
        }
      }
      for (let i = 0; i < itinArray.length; i++) {
        let event = new Appointment(window.guide_obj.schedule);
        event.Subject = itinArray[i].Attraction;
        event.Body = itinArray[i].Description;

        // const placeIndex = places.findIndex((x) => x.Name === itinArray[i].Attraction);
        // if (placeIndex !== -1) event.Places = places[placeIndex].ID;

        let existing_place = window.guide_obj.places.getPlacesFromName(itinArray[i].Attraction);
        if (existing_place !== null) event.Places = existing_place.ID;
        event.Itinerary = scheduleName;
        if (DateLabel !== itinArray[i].Date) {
          dayNum += 1;
          DateLabel = itinArray[i].Date;
        }
        // let day = itinArray[i].Day;
        // let dayParts = day.split(" ");
        // try {
        //   if (dayParts[0] === "Day") dayNum = parseInt(dayParts[1]);
        // } catch (e) {}

        let time = itinArray[i].Time;
        let timeParts = time.split(":");
        let hour = parseInt(timeParts[0]);
        if (timeParts[1].includes("pm") && hour < 12) hour += 12;
        hour = hour + (dayNum - 1) * 24;
        event.Start = this.state.dateStart;
        event.Start = event.Start.set({ hour: hour });
        let eventDuration = 1;
        if (i < itinArray.length - 1) {
          let time = itinArray[i + 1].Time;
          let timeParts2 = time.split(":");
          let hour2 = parseInt(timeParts2[0]);
          if (timeParts[1].includes("pm") && hour2 < 12) hour2 += 12;
          hour2 = hour2 + (dayNum - 1) * 24;
          eventDuration = hour2 - hour;
        }
        event.End = this.state.dateStart;
        event.End = event.End.set({ hour: hour + eventDuration });
        window.guide_obj.schedule.addEvent(event);
      }
    } catch (e) {
      this.MyAlert("Alert", "Error parsing schedule. Fix json or go back and try again");
      this.setState({ activityIndicator: false });
    }
  };

  addEvent = (schedEntry, ID) => {
    let event = new Appointment(window.guide_obj.schedule);
    event.Subject = schedEntry.Place;
    event.Body = schedEntry.Description;
    let time = schedEntry.Time;
    let timeParts = time.split(":");
    let hour = parseInt(timeParts[0]);
    event.Start = this.state.dateStart;
    event.Start = event.Start.set({ hour: hour });
    event.End = this.state.dateStart;
    event.End = event.End.set({ hour: hour + 1 });
    if (ID !== null) event.Places = ID;
    event.Itinerary = `${this.state.selectedNumber}-day ${this.state.selectedCity}`;
    window.guide_obj.schedule.addEvent(event);
  };

  LatLongReceived = (latLong) => {
    try {
      let ll = JSON.parse(latLong);
      if (typeof ll.lat === "undefined") {
        if (typeof ll.latitude === "undefined") {
          this.MyAlert("Alert", "Error retrieving latitude, longitude");
          this.setState({ activityIndicator: false });
          return;
        } else this.setState({ LatLong: `${ll.latitude},${ll.longitude}`, wizardStep: 2, activityIndicator: false });
      }
      this.setState({ LatLong: `${ll.lat},${ll.lng}`, wizardStep: 2, activityIndicator: false });
      setTimeout(() => this.Next(2), 200);
    } catch (e) {
      this.MyAlert("Alert", "Error retrieving latitude, longitude");
      this.setState({ activityIndicator: false });
    }
  };

  scheduleReceived = (schedule) => {
    try {
      let sched = JSON.parse(schedule);
      let sched_tex = "";
      let date_text = "XXXXXX";
      let itinArray = null;
      if (Array.isArray(sched)) itinArray = sched;
      else {
        const keys = Object.keys(sched);
        let _itin = [];
        for (let k = 0; k < keys.length; k++) {
          let _array = sched[keys[k]];
          if (Array.isArray(_array))
            for (let x = 0; x < _array.length; x++) {
              _array[x].Date = keys[k];
              _itin.push(_array[x]);
            }
          else _itin.push(_array);
        }
        itinArray = _itin;
      }
      for (let i = 0; i < itinArray.length; i++) {
        if (itinArray[i].Date !== date_text) {
          date_text = itinArray[i].Date;
          sched_tex += `${itinArray[i].Date}\n`;
        }
        sched_tex += `${itinArray[i].Time}: `;
        sched_tex += `${itinArray[i].Attraction}\n`;
        sched_tex += `${itinArray[i].Description}\n\n`;
      }
      this.setState({ Schedule: schedule, ScheduleText: sched_tex, wizardStep: 3, activityIndicator: false });
      setTimeout(() => this.Next(3), 200);
    } catch (e) {
      this.MyAlert("Alert", "Error retrieving or parsing schedule");
      this.setState({ activityIndicator: false });
    }
  };

  Previous = (number) => {
    this.setState({ ...this.state, wizardStep: (this.state.wizardStep -= 1) });
  };

  Cancel = () => {
    this.props.onHide();
  };

  Finish = () => {
    this.setState({ activityIndicator: true });
    for (let i = 0; i < this.state.PlacesFound.length; i++) {
      let place = this.state.PlacesFound[i];
      let existing_place = this.props.find_search_result(this.state.PlacesFound[i].Name);
      if (existing_place === null) {
        this.props.add_search_result(place);
        setTimeout(
          () =>
            this.searchGoogleImages(
              `${this.state.PlacesFound[i].Name} near ${this.state.selectedCity}, ${this.state.selectedCountry}`,
              place.ID,
              i === this.state.PlacesFound.length - 1
            ),
          200
        );
      } else {
        if (i === this.state.PlacesFound.length - 1)
          setTimeout(() => {
            this.updateUI();
          }, 3000);
      }
    }
  };

  searchGoogleImages = (searchString, place_id, last) => {
    if (last) setTimeout(() => GoogleSearch.searchImages(searchString, this.processGoogleImagesLast, place_id), 2000);
    else GoogleSearch.searchImages(searchString, this.processGoogleImages, place_id);
  };

  processGoogleImages = (imageList, place_id) => {
    if (imageList.length === 0) {
      console.log("processGoogleImages: error");
      return;
    }
    console.log("processGoogleImages: success");
    //let place = window.guide_obj.places.getPlace(place_id);
    let place = this.props.find_search_result(null, place_id);
    if (place === null) return;
    place.Photo = imageList[0].url;
    place.Photo_m = imageList[0].url_m;
    try {
      place.Info = Place.createImageHTML(place.Photo_m, null, "medium") + place.Info;
    } catch (e) {}
  };

  processGoogleImagesLast = (imageList, place_id) => {
    if (imageList.length === 0) {
      console.log("processGoogleImagesLast: error");
      setTimeout(() => {
        this.updateUI();
      }, 1000);
      return;
    }
    console.log("processGoogleImagesLast: success");
    //let place = window.guide_obj.places.getPlace(place_id);
    let place = this.props.find_search_result(null, place_id);
    if (place !== null) {
      place.Photo = imageList[0].url;
      place.Photo_m = imageList[0].url_m;
      try {
        place.Info = Place.createImageHTML(place.Photo_m, null, "medium") + place.Info;
      } catch (e) {}
    }

    //this.parseSched();
    setTimeout(() => {
      this.updateUI();
    }, 1000);
  };

  updateUI = () => {
    //let scheduleName = `${this.state.selectedNumber}-day ${this.state.selectedCity}`;
    //if (this.props.set_schedule_name !== null) this.props.set_schedule_name(scheduleName);
    //this.props.add_search_results(this.state.PlacesFound);
    this.props.update_ui();
    this.setState({ activityIndicator: false });
    this.props.onHide();
  };

  googleTextSearch = (searchText, last, label) => {
    const _at = new GooglePlaces();
    if (last)
      setTimeout(
        () =>
          _at.searchPlaces(`${this.state.LatLong}`, 10000, null, searchText, this.searchGooglePlaceCallbackLast, label),
        2000
      );
    else _at.searchPlaces(`${this.state.LatLong}`, 10000, null, searchText, this.searchGooglePlaceCallback, label);
  };

  searchGooglePlaceCallback = (google_places, label) => {
    if (google_places == null || google_places.length == 0) {
      this.setState({ PlacesFound: [], Places: "", activityIndicator: false });
      console.log("searchGooglePlaceCallback: error", label.Attraction);
      return;
    }
    console.log("searchGooglePlaceCallback: success", label.Attraction);
    const _at = new GooglePlaces();
    // Change this for quick/long details
    //_at.getDetails(google_places, getGoogleDetailsCallback);
    _at.getQuickDetails(google_places, this.getGoogleDetailsCallback, label);
  };

  searchGooglePlaceCallbackLast = (google_places, label) => {
    if (google_places === null || google_places.length === 0) {
      console.log("searchGooglePlaceCallbackLast: error", label.Attraction);
      setTimeout(() => this.Finish(), 2000);
      return;
    }
    console.log("searchGooglePlaceCallbackLast: success", label.Attraction);
    const _at = new GooglePlaces();
    // Change this for quick/long details
    //_at.getDetails(google_places, getGoogleDetailsCallback);
    setTimeout(() => _at.getQuickDetails(google_places, this.getGoogleDetailsCallbackLast, label), 2000);
  };

  getGoogleDetailsCallback = (places, label) => {
    //console.log("getGoogleDetailsCallback", places);
    if (places.length < 1) {
      console.log("getGoogleDetailsCallback: error", label.Attraction);
      return;
    }
    console.log("getGoogleDetailsCallback: success", label.Attraction);
    let places_found = this.state.PlacesFound;
    let placeFound = places[0];
    try {
      placeFound.Info += label.Description;
      placeFound.Category = ["53"];
      placeFound.City = label.City;
      placeFound.Country = this.state.selectedCountry;
      placeFound.Group = ["20"];
      let found = false;
      for (let i = 0; i < this.state.AICategories.length; i++) {
        if (this.state.AICategories[i].category === label.Category) {
          placeFound.Category = [this.state.AICategories[i].id];
          found = true;
          break;
        }
      }
      if (!found) {
        for (let i = 0; i < this.state.AIBaseCategories.length; i++) {
          if (label.Category.includes(this.state.AIBaseCategories[i].category)) {
            placeFound.Category = [this.state.AIBaseCategories[i].id];
            found = true;
            break;
          }
        }
      }
      if (!found) console.log(`Category not found: ${label.Category}`);
    } catch (e) {}
    placeFound.Duration = label.Duration;
    places_found.push(placeFound);
    this.setState({ PlacesFound: places_found });
  };

  getGoogleDetailsCallbackLast = (places, label) => {
    //console.log("getGoogleDetailsCallback", places);
    if (places.length < 1) {
      setTimeout(() => this.Finish(), 100);
      console.log("getGoogleDetailsCallbackLast: error", label.Attraction);
      return;
    }
    console.log("getGoogleDetailsCallbackLast: success", label.Attraction);
    let places_found = this.state.PlacesFound;
    let placeFound = places[0];
    try {
      placeFound.Info += label.Description;
      placeFound.City = label.City;
      placeFound.Category = ["53"];
      placeFound.Group = ["20"];
      let found = false;
      for (let i = 0; i < this.state.AICategories.length; i++) {
        if (this.state.AICategories[i].category === label.Category) {
          placeFound.Category = [this.state.AICategories[i].id];
          found = true;
          break;
        }
      }
      if (!found) {
        for (let i = 0; i < this.state.AIBaseCategories.length; i++) {
          if (label.Category.includes(this.state.AIBaseCategories[i].category)) {
            placeFound.Category = [this.state.AIBaseCategories[i].id];
            found = true;
            break;
          }
        }
      }
      if (!found) console.log(`Category not found: ${label.Category}`);
      placeFound.Duration = label.Duration;
    } catch (e) {}
    places_found.push(placeFound);
    this.setState({ PlacesFound: places_found, wizardStep: 4 });
    setTimeout(() => this.Finish(), 100);
  };

  selectCountry = () => {
    let _countries = [];
    const countries_sorted = countries.sort();
    let id = 1;
    countries_sorted.forEach((country) =>
      _countries.push({
        label: country,
        name: country,
        value: country,
        id: (id++).toString(),
      })
    );
    this.MultiSelect(_countries, [], "uni", this.selectedCountry, "Select Country");
  };

  selectedCountry = (countries) => {
    let country = "";
    if (countries.length < 1) country = "";
    else country = countries[0].value;
    this.setState({ selectedCountry: country });
  };

  proximityChanged = (e) => {
    this.setState({ proximity: e.target.value });
  };

  MultiSelect = (Items, SelectedItems, Mode, Callback, Title) => {
    //console.log("MultiSelect");
    this.setState({
      MultiselectItems: Items,
      MultiselectSelectedItems: SelectedItems,
      MultiselectMode: Mode,
      MultiselectCallback: Callback,
      MultiselectTitle: Title,
      MultiselectSearchText: "",
      ShowMultiselect: true,
    });
  };

  render() {
    //let questions = JSON.parse(JSON.stringify(this.items));
    //console.log("this.props.questions", this.props.questions);
    let questions = [];
    try {
      questions = JSON.parse(this.props.questions);
    } catch (e) {
      questions = this.items;
      //questions = [];
      console.log("error parsing JSON this.props.questions", e);
    }

    // try {
    //   questions = JSON.parse(this.props.questions);
    // } catch (e) {
    //   questions = [];
    //   console.log("error parsing JSON this.props.questions", e);
    // }
    // if (this.props.user_info.uid === "E2Fqqu9FWDgTdpOzdKQOKEdo6wA3") {
    //   questions.push({
    //     label: "10-day itinerary for Florence Italy in json format with: time, place and description",
    //     params: "City",
    //   });
    //   questions.push({
    //     label: "Which cities to visit in Italy, for how many days and in which order, for a 10-day trip",
    //     params: "City",
    //   });
    // }

    questions = questions.sort((a, b) => (a.label.toUpperCase() > b.label.toUpperCase() ? 1 : -1));
    // let destination = this.state.selectedCity;
    // let country = `${this.state.selectedCountry}`;
    // if (this.state.place !== null) {
    //   destination = `${this.state.place.Name}, ${this.state.selectedCity}`;
    // }
    // if (this.state.location !== null) {
    //   destination = `${this.state.location.Lat.toFixed(6)},${this.state.location.Longi.toFixed(6)}`;
    //   country = "";
    // }
    //console.log("this.state.selectedNumber", this.state.selectedNumber);
    let _questions = [];
    for (let i = 0; i < questions.length; i++) {
      if (this.state.place === null && questions[i].params === "Place") continue;
      if (questions[i].label.includes("Florence"))
        questions[i].label = questions[i].label
          //.replace("Florence ", this.state.selectedCity)
          .replace("Florence", this.state.selectedCity)
          .replace("Italy", this.state.selectedCountry)
          .replace("10", this.state.selectedNumber)
          .replace(" in ", ` ${this.state.proximity} `);
      else
        questions[i].label = questions[i].label
          //.replace("Florence ", this.state.selectedCity)
          .replace("Florence", this.state.selectedCity)
          .replace("Italy", this.state.selectedCountry)
          .replace("10", this.state.selectedNumber);
      if (
        this.state.SearchText !== "" &&
        !questions[i].label.toUpperCase().includes(this.state.SearchText.toUpperCase())
      )
        continue;
      _questions.push(questions[i]);
    }
    if (_questions.findIndex((x) => x.label === "Choose a question") === -1)
      _questions.splice(0, 0, {
        label: "Choose a question",
        params: "None",
      });
    //console.log("_questions", _questions);
    if (this.state.AlertModalShow)
      return (
        <AlertModal
          show={this.state.AlertModalShow}
          onHide={() => this.setState({ AlertModalShow: false })}
          AlertModalTitle={this.state.AlertModalTitle}
          AlertModalMessage={this.state.AlertModalMessage}
          AlertModalButtons={this.state.AlertModalButtons}
        />
      );
    if (this.state.PromptModalShow)
      return (
        <PromptModal
          show={this.state.PromptModalShow}
          onHide={() => this.setState({ PromptModalShow: false })}
          PromptModalTitle={this.state.PromptModalTitle}
          PromptModalMessage={this.state.PromptModalMessage}
          PromptModalButtons={this.state.PromptModalButtons}
          PromptModalInputs={this.state.PromptModalInputs}
        />
      );
    if (this.state.ShowMultiselect)
      return (
        <MultiSelectModal
          show={this.state.ShowMultiselect}
          onHide={() => this.setState({ ShowMultiselect: false })}
          MultiselectItems={this.state.MultiselectItems}
          MultiselectSelectedItems={this.state.MultiselectSelectedItems}
          MultiselectMode={this.state.MultiselectMode}
          MultiselectCallback={this.state.MultiselectCallback}
          MultiselectTitle={this.state.MultiselectTitle}
          MultiselectSearchText={this.state.MultiselectSearchText}
        />
      );
    return (
      <div className="modal">
        <Modal
          {...this.props}
          size="lg"
          minHeight="500px"
          center={false}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          scrollable={true}
        >
          <Modal.Header>
            <div style={{ width: "100%" }}>
              <div className="sort">
                <div className="dialog-heading">
                  <div className="dialog-heading">
                    <div>
                      <h4 style={{ color: "#3598DB" }}>{`Search for Places using AI`}</h4>
                    </div>
                    {/* <i className="fas fa-check" onClick={() => this.saveSettings()}></i> */}

                    <div>
                      <i className={`fas fa-times`} onClick={() => this.props.onHide()}></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Header>
          <Modal.Body>
            {/* <p className="attribute">City</p>
            <Form.Group>
              <Form.Control
                type="text"
                value={this.state.Name}
                onChange={(e) => this.handleChangeName(e.target.value)}
              />
            </Form.Group> */}
            <div>
              {this.state.activityIndicator ? (
                <div className="loading">
                  <Spinner animation="border" variant="primary" />
                </div>
              ) : null}
              <p>
                <div>
                  <h7 style={{ color: "#3598DB" }}>Note: this can take 30+ seconds</h7>
                </div>
                <div className="flex-container-left">
                  <span className="attribute">Number:</span>

                  <Form.Control
                    as="select"
                    custom
                    onChange={this.numberChanged}
                    defaultValue={this.state.selectedNumber}
                  >
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 20].map((quest, idx) => (
                      <option value={quest.label}>{quest.toString()}</option>
                    ))}
                  </Form.Control>
                </div>
              </p>
              <p>
                <div className="flex-container-left">
                  <span className="attribute">Proximity:</span>
                  <Form.Control as="select" custom onChange={this.proximityChanged} defaultValue={this.state.proximity}>
                    {[
                      "in",
                      "near",
                      "in or near",
                      "outside of",
                      "within 1 hour from",
                      "within 2 hours from",
                      "within 3 hours from",
                    ].map((proximity, idx) => (
                      <option value={proximity}>{proximity}</option>
                    ))}
                  </Form.Control>
                </div>
              </p>
              <p>
                <div className="flex-container-left">
                  <span className="attribute">City, place or region: </span>
                  {/* <p>Enter location(s)</p> */}
                  <input
                    type="text"
                    value={this.state.selectedCity}
                    placeholder="Enter location(s)"
                    onChange={(e) => this.handledDestinationChanged(e)}
                  />
                  {/* <span>{`${destination}`}</span>
                <span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                <MyToolTip loc="middle" msg="Select the city you want to ask about">
                  <Button className="mt-3" onClick={() => this.modifyCity()}>
                    Change Location
                  </Button>
                </MyToolTip> */}
                </div>
              </p>
              <p>
                {/* <div className="flex-container-left">
                <span className="attribute">Location: </span>
                <span>{`${this.state.selectedLatLong}`}</span>
                <span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                <MyToolTip loc="middle" msg="Select the city you want to ask about">
                  <Button className="mt-3" onClick={() => this.modifyLatLong()}>
                    Change Place
                  </Button>
                </MyToolTip>
              </div> */}
                {/* <div className="flex-container-left">
                  <span className="attribute">Country/region: </span>
                  <input
                    type="text"
                    value={this.state.selectedCountry}
                    placeholder="Enter country"
                    onChange={(e) => this.handledCountryChanged(e)}
                  />
                </div> */}

                <p>
                  <div className="flex-container-left">
                    {/* <span className="attribute">Country: </span>
                      <input
                        type="text"
                        value={this.state.selectedCountry}
                        placeholder="Enter country and/or region"
                        onChange={(e) => this.handledCountryChanged(e)}
                      /> */}
                    <Button className="mt-3" onClick={() => this.selectCountry()}>
                      Country
                    </Button>
                    <span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <span className="attribute">{this.state.selectedCountry}</span>
                  </div>
                </p>
              </p>
              <p className="attribute">Pick a search phrase</p>
              <div>
                <Form.Control as="select" custom onChange={this.questionChanged} value={this.state.selectedQuestion}>
                  {_questions.map((quest, idx) => (
                    <option value={quest.label}>{quest.label}</option>
                  ))}
                </Form.Control>
              </div>
              <div>
                <p className="attribute">Response</p>
                <Form.Group>
                  <Form.Control
                    as="textarea"
                    // type="text"
                    value={this.state.answer}
                    //onChange={(e) => this.handleChangePaymentInfo(e.target.value)}
                    style={{ height: "400px" }}
                  />
                </Form.Group>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default AISearch;
