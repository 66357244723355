import { connect } from "react-redux";
import React, { Component, useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import "./Modal.css";
import PromptModal from "./PromptModal";
import AlertModal from "./AlertModal";
import MultiSelectModal from "./MultiSelectModal";
import IconsDialog from "./IconsDialog";
import Place from "../../Models/Place";

class CitiesDialog extends Component {
  constructor(props) {
    super(props);
    let categories = this.props.cities;
    //console.log("categories", categories);
    // categories = categories.split(",");
    this.state = {
      visiblePrompt: false,
      category: "",
      add: true,
      defaultCategory: "",
      Categories: categories,
      searchText: "",

      PromptModalShow: false,
      PromptModalTitle: "Enter Value",
      PromptModalMessage: "",
      PromptModalButtons: [],
      PromptModalInputs: [],

      AlertModalShow: false,
      AlertModalTitle: "",
      AlertModalMessage: "",
      AlertModalButtons: [],
    };
  }

  componentDidMount() {}

  MyAlert = (title, message, buttons) => {
    this.setState({
      AlertModalTitle: title,
      AlertModalMessage: message,
      AlertModalButtons: buttons,
      AlertModalShow: true,
    });
  };

  _group = null;

  addModifyGroup = (add, group) => {
    if (group != null) this._group = group;
    this.setState({
      PromptModalShow: true,
      PromptModalTitle: "City",
      PromptModalMessage: add ? "Enter City Name" : "Edit City Name",
      PromptModalButtons: [
        { Title: "OK", Method: add ? this.AddGroupCallback : this.ModifyGroupCallback },
        { Title: "CANCEL", Method: null },
      ],
      PromptModalInputs: [{ name: "City Name", value: add ? "" : group }],
    });
  };

  ModifyGroupCallback = (inputs) => {
    let categories = [...this.state.Categories];
    const index = categories.findIndex((c) => c === this._group);
    if (index === -1) return;
    categories[index] = inputs[0].value;
    this.setState({ Categories: categories });
  };

  AddGroupCallback = (inputs) => {
    let categories = [...this.state.Categories];
    const index = categories.findIndex((x) => x.toUpperCase() === inputs[0].value.toUpperCase());
    if (index !== -1) {
      this.myAlert("City is already in list");
      return;
    }
    categories.push(inputs[0].value);
    this.setState({ Categories: categories });
  };

  deleteGroup = (group) => {
    this.MyAlert("Confirmation", "Confirm Delete City?", [
      { Title: "OK", Method: () => this._deleteGroup(group) },
      { Title: "CANCEL", Method: null },
    ]);
  };

  showPrompt = (add, category) => {
    //console.log(`showPrompt category: ${category}`);
    if (add) this.setState({ visiblePrompt: true, add: add, defaultCategory: "" });
    else this.setState({ visiblePrompt: true, add: add, defaultCategory: category });
  };

  _deleteGroup = (category) => {
    let categories = JSON.parse(JSON.stringify(this.state.Categories));
    const index = categories.findIndex((c) => c === category);
    if (index === -1) return;
    categories.splice(index, 1);
    this.setState({ Categories: categories });
  };

  addEditCategory = () => {
    if (this.state.add == true) {
      //console.log(`Add category: ${this.state.category}`);
      let categories = JSON.parse(JSON.stringify(this.state.Categories));
      const index = categories.findIndex((x) => x.toUpperCase() == this.state.category.toUpperCase());
      if (index !== -1) {
        this.myAlert("City is already in list");
        return;
      }
      categories.push(this.state.category);
      this.setState({ Categories: categories });
    } else {
      let categories = JSON.parse(JSON.stringify(this.state.Categories));
      const index = categories.findIndex((c) => c == this.state.defaultCategory);
      if (index == -1) return;
      categories[index] = this.state.category;
      this.setState({ Categories: categories });
    }
    this.setState({ visiblePrompt: false });
  };

  catTextChanged = (event) => {
    this.setState({ searchText: event.target.value });
  };

  returnCities = () => {
    const callback = this.props.callback;
    if (callback != null) callback(this.state.Categories);
    this.props.onHide();
  };

  render() {
    let Categories = [];
    let id = 0;
    let cat = JSON.parse(JSON.stringify(this.state.Categories));
    let _categories = cat.sort();
    console.log("_categories", _categories);
    _categories.forEach((category) => {
      if (category.toUpperCase().includes(this.state.searchText.toUpperCase()) || this.state.searchText === "")
        Categories.push({
          id: (id++).toString(),
          name: category,
          label: category,
          value: category,
        });
    });

    return (
      <div className="modal">
        <Modal
          {...this.props}
          dialogClassName="myModalClass"
          aria-labelledby="contained-modal-title-vcenter"
          centered={false}
          scrollable={true}
        >
          <Modal.Header closeButton>
            <div style={{ width: "100%" }}>
              <div className="sort">
                <div className="dialog-heading">
                  <div>
                    <h4 className="color-blue">Cities</h4>
                  </div>
                </div>
                <div className="dialog-heading">
                  <input type="text" placeholder="Search" onChange={this.catTextChanged} />
                  {/* {MultiselectMode === "multi" ? (
                      <i className={`fas fa-tasks ${show ? "" : "color"}`} onClick={handleSelectAll}></i>
                    ) : null} */}
                  <i className={`fa fa-plus`} onClick={() => this.addModifyGroup(true, null)}></i>
                  <i className="fas fa-check" onClick={() => this.returnCities()}></i>
                </div>
                {/* <Modal.Title id="example-modal-sizes-title-sm">{MultiselectTitle}</Modal.Title> */}
              </div>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="multiselect-list">
              {Categories.map((item, index) => (
                <div className="list-item-card" key={index}>
                  <div className="items-left">
                    {/* <img src={Place.getItemPOIImage(item.value)} onClick={() => this.ChangeIcon(item)} /> */}
                    <span className="text-size-1-0">{item.label}</span>
                    <div className="items-right" key={index}>
                      <i
                        title="Delete"
                        className={`fa fa-trash-alt`}
                        style={{ color: "red" }}
                        onClick={() => this.deleteGroup(item.label)}
                      ></i>
                      <span> </span>
                      <i
                        title="Change Name"
                        className={`fa fa-edit color-blue`}
                        onClick={() => this.addModifyGroup(false, item.label)}
                      ></i>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </Modal.Body>
        </Modal>
        <PromptModal
          show={this.state.PromptModalShow}
          onHide={() => this.setState({ PromptModalShow: false })}
          PromptModalTitle={this.state.PromptModalTitle}
          PromptModalMessage={this.state.PromptModalMessage}
          PromptModalButtons={this.state.PromptModalButtons}
          PromptModalInputs={this.state.PromptModalInputs}
        />
        <AlertModal
          show={this.state.AlertModalShow}
          onHide={() => this.setState({ AlertModalShow: false })}
          AlertModalTitle={this.state.AlertModalTitle}
          AlertModalMessage={this.state.AlertModalMessage}
          AlertModalButtons={this.state.AlertModalButtons}
        />
      </div>
    );
  }
}

export default CitiesDialog;
