import { connect } from "react-redux";
import React, { Component, useState, useEffect } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import AlertModal from "./AlertModal";
import "./Modal.css";
import PromptModal from "./PromptModal";
import MultiSelectModal from "./MultiSelectModal";
import IconsDialog from "./IconsDialog";
import Place from "../../Models/Place";
import DataBase from "../../dataBase/liquidGuides";

class SettingsDialog extends Component {
  constructor(props) {
    super(props);
    let distance_unit = typeof props.user_info.distance_unit == "undefined" ? "miles" : props.user_info.distance_unit;
    let date_format = typeof props.user_info.date_format == "undefined" ? "Month first" : props.user_info.date_format;
    let edit_mode = typeof props.user_info.edit_mode == "undefined" ? "On" : props.user_info.edit_mode;
    let advanced_mode = typeof props.user_info.advanced_mode == "undefined" ? "Off" : props.user_info.advanced_mode;

    let travel_payouts_marker =
      typeof props.user_info.distance_unit == "undefined" ? "" : props.user_info.travel_payouts_marker;

    let payment_info = typeof props.user_info.payment_info == "undefined" ? "" : props.user_info.payment_info;

    let affiliate_program =
      typeof props.user_info.affiliate_program == "undefined" ? false : props.user_info.affiliate_program;

    let default_duration =
      typeof props.user_info.default_duration == "undefined" ? 30 : props.user_info.default_duration;

    let event_time_rounding =
      typeof props.user_info.event_time_rounding == "undefined" ? "5" : `${props.user_info.event_time_rounding}`;

    console.log("props.user_info:", props.user_info);
    this.state = {
      initial_distance_unit: distance_unit,
      initial_date_format: date_format,
      distance_unit: distance_unit,
      date_format: date_format,
      edit_mode: edit_mode,
      advanced_mode: advanced_mode,
      travel_payouts_marker: travel_payouts_marker,
      payment_info: payment_info,
      affiliate_program: affiliate_program,
      default_duration: default_duration,
      event_time_rounding: event_time_rounding,

      AlertModalShow: false,
      AlertModalTitle: "",
      AlertModalMessage: "",
      AlertModalButtons: [],
    };
  }
  componentDidMount() {}

  updateDistanceUnit = (value) => {
    this.setState({ distance_unit: this.state.distance_unit === "kilometers" ? "miles" : "kilometers" });
  };
  updateRounding = (value) => {
    this.setState({ event_time_rounding: value });
  };
  updateDateFormat = (value) => {
    this.setState({ date_format: this.state.date_format === "Day first" ? "Month first" : "Day first" });
  };

  updateEditMode = () => {
    this.setState({ edit_mode: this.state.edit_mode === "On" ? "Off" : "On" });
  };

  updateAffiliateProgram = () => {
    this.setState({ affiliate_program: !this.state.affiliate_program });
  };

  updateAdvancedMode = (value) => {
    this.setState({ advanced_mode: this.state.advanced_mode === "On" ? "Off" : "On" });
  };

  handleChangeTravelPayoutsMarker = (newText) => {
    //console.log("handleChangeTravelPayoutsMarker", newText);
    this.setState({ travel_payouts_marker: newText });
  };

  handleChangePaymentInfo = (newText) => {
    //console.log("handleChangeTravelPayoutsMarker", newText);
    this.setState({ payment_info: newText });
  };

  handleChangeDefaultDuration = (newText) => {
    if (isNaN(newText)) {
      if (newText !== "") newText = "";
    }
    this.setState({ default_duration: newText });
  };

  _user_info = null;

  saveSettings = () => {
    if (this.props.user_info.uid == null) return;
    if (this.props.user_info.uid === "guest") {
      this.MyAlert("Cannot change settings in 'guest' mode");
      // this.props.onHide();
      return;
    }
    let user_info = JSON.parse(JSON.stringify(this.props.user_info));
    //let user_info = {};
    user_info.distance_unit = this.state.distance_unit;
    user_info.date_format = this.state.date_format;
    user_info.edit_mode = this.state.edit_mode;
    user_info.advanced_mode = this.state.advanced_mode;
    user_info.travel_payouts_marker = this.state.travel_payouts_marker;
    user_info.payment_info = this.state.payment_info;
    user_info.affiliate_program = this.state.affiliate_program;
    user_info.default_duration = this.state.default_duration;
    user_info.event_time_rounding = parseInt(this.state.event_time_rounding);
    this._user_info = user_info;
    DataBase.updateUser(user_info, this.props.user_info_id, this.updateUserCallback);
  };

  updateUserCallback = (res) => {
    if (res.pass === true) {
      this.props.callback(this._user_info);
    } else {
      let msg = typeof res.message != "undefined" ? res.message.toString() : "Unknown error";
      this.MyAlert(`Error updating user: ${msg}`);
    }
    this.props.onHide();
  };

  dataChanged = () => {
    if (
      this.state.initial_date_format === this.state.date_format &&
      this.state.initial_distance_unit === this.state.distance_unit
    )
      return false;
    return true;
  };

  MyAlert = (message) => {
    this.setState({
      AlertModalTitle: "Message",
      AlertModalMessage: message,
      AlertModalButtons: [{ Title: "OK", Method: null }],
      AlertModalShow: true,
    });
  };

  render() {
    console.log("this.state.edit_mode", this.state.edit_mode);
    if (this.state.AlertModalShow)
      return (
        <AlertModal
          show={this.state.AlertModalShow}
          onHide={() => this.setState({ AlertModalShow: false })}
          AlertModalTitle={this.state.AlertModalTitle}
          AlertModalMessage={this.state.AlertModalMessage}
          AlertModalButtons={this.state.AlertModalButtons}
        />
      );
    return (
      <div className="modal">
        <Modal {...this.props} size="md" aria-labelledby="contained-modal-title-vcenter" centered scrollable={true}>
          <Modal.Header>
            <div style={{ width: "100%" }}>
              <div className="sort">
                <div className="dialog-heading">
                  <div className="dialog-heading">
                    <div>
                      <h4 style={{ color: "#3598DB" }}>Settings</h4>
                    </div>
                    <i className="fas fa-check" onClick={() => this.saveSettings()}></i>

                    <span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>

                    <div>
                      <i className={`fas fa-times`} onClick={() => this.props.onHide()}></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="font-class">
              {/* <div className="multiselect-item">
                <p className="attribute">Distance Unit</p>
              </div> */}
              <div className="multiselect-item">
                <span className="attribute">Distance Unit&nbsp;&nbsp;&nbsp;</span>
                <Form.Check
                  label="miles"
                  type="checkbox"
                  id="autoSizingCheck2"
                  checked={this.state.distance_unit === "miles"}
                  onChange={() => this.updateDistanceUnit("miles")}
                />
                {/* <div className="country">
                  <p>miles</p>
                </div> */}
                <span>&nbsp;&nbsp;&nbsp;</span>
                <Form.Check
                  label="km"
                  type="checkbox"
                  id="autoSizingCheck2"
                  checked={this.state.distance_unit === "kilometers"}
                  onChange={() => this.updateDistanceUnit("kilometers")}
                />
                {/* <div className="country">
                  <p>km</p>
                </div> */}
              </div>
              <div className="multiselect-item">
                <Form.Check
                  label="Edit Mode"
                  type="checkbox"
                  id="autoSizingCheck2"
                  checked={this.state.edit_mode === "On"}
                  onChange={() => this.updateEditMode()}
                />
                {/* <div className="country">
                  <p>Edit Mode</p>
                </div> */}
              </div>

              <div className="multiselect-item">
                <span className="attribute">Default Place Duration (minutes)&nbsp;&nbsp;&nbsp;</span>
                <Form.Group>
                  <Form.Control
                    type="text"
                    value={this.state.default_duration}
                    onChange={(e) => this.handleChangeDefaultDuration(e.target.value)}
                  />
                </Form.Group>
              </div>
              <div className="multiselect-item">
                <span className="attribute">Round Travel Times (minutes)&nbsp;&nbsp;&nbsp;</span>
                <Form.Check
                  label="0"
                  type="checkbox"
                  id="autoSizingCheck11"
                  checked={this.state.event_time_rounding === "0"}
                  onChange={() => this.updateRounding("0")}
                />
                <span>&nbsp;&nbsp;&nbsp;</span>
                <Form.Check
                  label="5"
                  type="checkbox"
                  id="autoSizingCheck12"
                  checked={this.state.event_time_rounding === "5"}
                  onChange={() => this.updateRounding("5")}
                />
                <span>&nbsp;&nbsp;&nbsp;</span>
                <Form.Check
                  label="10"
                  type="checkbox"
                  id="autoSizingCheck13"
                  checked={this.state.event_time_rounding === "10"}
                  onChange={() => this.updateRounding("10")}
                />
                <span>&nbsp;&nbsp;&nbsp;</span>
                <Form.Check
                  label="15"
                  type="checkbox"
                  id="autoSizingCheck14"
                  checked={this.state.event_time_rounding === "15"}
                  onChange={() => this.updateRounding("15")}
                />
                <span>&nbsp;&nbsp;&nbsp;</span>
                <Form.Check
                  label="30"
                  type="checkbox"
                  id="autoSizingCheck15"
                  checked={this.state.event_time_rounding === "30"}
                  onChange={() => this.updateRounding("30")}
                />
              </div>
              {/* <p className="attribute">TravelPayouts marker</p>
              <Form.Group>
                <Form.Control
                  type="text"
                  value={this.state.travel_payouts_marker}
                  onChange={(e) => this.handleChangeTravelPayoutsMarker(e.target.value)}
                />
              </Form.Group> */}

              {/* <div className="multiselect-item">
                <Form.Check
                  type="checkbox"
                  id="autoSizingCheck2"
                  checked={this.state.affiliate_program}
                  onChange={() => this.updateAffiliateProgram()}
                />
                <div className="country">
                  <p>Participate in Affiliate Program</p>
                </div>
              </div> */}
              {/* {this.state.affiliate_program ? (
                <div>
                  <p className="attribute">Payment Info</p>
                  <Form.Group>
                    <Form.Control
                      as="textarea"
                      // type="text"
                      value={this.state.payment_info}
                      onChange={(e) => this.handleChangePaymentInfo(e.target.value)}
                      style={{ height: "200px" }}
                    />
                  </Form.Group>
                </div>
              ) : null} */}
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default SettingsDialog;
