import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import "./Modal.css";
import { Form, Button } from "react-bootstrap";
import HTMLEditorComponent from "./HTMLEditorComponent";

import AlertModal from "./AlertModal";
import ImageDialog from "./ImageDialog";
import IconsDialog from "./IconsDialog";
import MultiSelectModal from "./MultiSelectModal";
import HTMLEditorModal from "./HTMLEditorModal";

import Place from "../../Models/Place";
import Common, { SortByEnum, POI_IMAGES, COLORS } from "../../Common/Common";
import Appointment from "../../Models/Event";
import { DateTime, Settings } from "luxon";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// Configure the time zone
Settings.defaultZoneName = "UTC";

class EventEdit extends Component {
  constructor(props) {
    super(props);
    const event = this.props.event;
    const mode = this.props.mode;
    const place = this.props.place;
    const dateTime = this.props.dateTime;
    const where = this.props.where;

    //console.log("event", event);
    //console.log("mode", mode);
    //console.log("place", place);
    let initialSubject = "";
    let initialStart = window.guide_obj.schedule.getFirsEventStartDate(this.props.schedule_name); //this.props.initDate;

    console.log("initialStart", initialStart.toJSDate());
    //initialStart.setMinutes(0);
    initialStart.set({ minute: 0 });
    let initialEnd = DateTime.fromISO(initialStart, { zone: "utc" });
    //initialEnd.setTime(initialEnd.getTime() + 1 * 60 * 60 * 1000);
    let initialItinerary = this.props.schedule_name;
    if (where == "after" && event != null) {
      initialStart = DateTime.fromISO(event.End, { zone: "utc" });
      initialEnd = DateTime.fromISO(event.End, { zone: "utc" }).plus({ minutes: 60 });
    } else if (where == "before" && event != null) {
      initialStart = DateTime.fromISO(event.Start, { zone: "utc" }).minus({ minutes: 60 });
      initialEnd = DateTime.fromISO(event.Start, { zone: "utc" });
    } else if (where === "end" && mode == "ADD") {
      let start_end = window.guide_obj.schedule.getStartEndDate(this.props.schedule_name);
      initialStart = start_end.endDate;
      console.log("initialStart", initialStart.toJSDate());
      initialStart.set({ minute: 0 });
      initialEnd = DateTime.fromISO(initialStart.set({ hour: initialStart.hour + 1 }), { zone: "utc" });
    }
    if (where == "" && mode == "ADD" && dateTime != null) {
      initialStart = dateTime;
      initialEnd = dateTime;
    }
    let initialNotes = "";
    let initialPlaces = [];
    if (place != null) {
      initialPlaces = [{ Name: place.Name, ID: place.ID }];
      initialSubject = place.Name;
    }
    if (mode != "ADD" && event != null) {
      initialSubject = event.Subject;

      initialStart = DateTime.fromISO(event.Start, { zone: "utc" });
      initialEnd = DateTime.fromISO(event.End, { zone: "utc" });
      initialNotes = event.Body;
      initialItinerary = typeof event.Itinerary == "undefined" ? "Main" : event.Itinerary;
      //let _places = Places.getPlaceNames(event.Places);

      const _places = event.get_places(window.guide_obj.places);
      for (let i = 0; i < _places.length; i++) {
        if (typeof _places[i].Name !== "undefined") initialPlaces.push({ Name: _places[i].Name, ID: _places[i].ID });
      }
    }
    const Itineraries = window.guide_obj.schedule.geScheduleNames();
    //Itineraries.splice(0, 0, { label: "NEW", value: "NEW" });
    //console.log(initialStart, initialEnd);
    this.state = {
      Subject: initialSubject,
      Start: initialStart,
      End: initialEnd,
      Notes: initialNotes,
      // tempNotes: initialNotes,
      notesUpdated: initialNotes,
      Places: initialPlaces,

      dateStart: initialStart,
      modeStart: "date",
      showStart: false,

      dateEnd: initialEnd,
      modeEnd: "date",
      showEnd: false,
      Itineraries: Itineraries,
      Itinerary: initialItinerary,
      visiblePrompt: false,
      newScheduleName: "",
      scheduleLabel: "Choose different schedule...",

      AlertModalShow: false,
      AlertModalTitle: "",
      AlertModalMessage: "",
      AlertModalButtons: [],

      MultiselectItems: [],
      MultiselectSelectedItems: [],
      MultiselectMode: "uni",
      MultiselectCallback: null,
      MultiselectTitle: "Select",
      MultiselectSearchText: "",
      ShowMultiselect: false,
    };
    this.handleChangeSubject = this.handleChangeSubject.bind(this);
    //this.handleChangeStart = this.handleChangeStart.bind(this);
    //this.handleChangeEnd = this.handleChangeEnd.bind(this);
    this.handleChangeNotes = this.handleChangeNotes.bind(this);
    this.handleChangePlaces = this.handleChangePlaces.bind(this);
  }

  MyAlert = (message) => {
    this.setState({
      AlertModalTitle: "Message",
      AlertModalMessage: message,
      AlertModalButtons: [{ Title: "OK", Method: null }],
      AlertModalShow: true,
    });
  };

  handleChangeSubject = (newText) => {
    if (newText != "") {
      var re = /^[a-zA-Z0-9 +(),-]+$/;
      //console.log(re.test(newText));
      if (!re.test(newText)) return;
    }
    this.setState({ ...this.state, Subject: newText });
  };
  NotesUpdated = (newText) => {
    console.log("NotesUpdated", newText);
    this.setState({ ...this.state, notesUpdated: newText });
  };
  handleChangeNotes = (newText) => {
    this.setState({ ...this.state, Notes: newText });
  };
  handleChangePlaces = (newText) => {
    this.setState({ ...this.state, Places: newText });
  };
  editInfo = () => {
    this.props.navigation.navigate("HTMLEditor", { html: this.state.Notes, callback: this.editInfoCallback });
  };
  editInfoCallback = (html) => {
    this.setState({ Notes: html });
  };
  AddEvent = () => {
    //console.log("AddEvent");
    //this.props.onAddEvent("AddEvent");

    if (this.state.dateStart > this.state.dateEnd) {
      this.MyAlert("End date/time must be greater than Start date/time");
      return;
    }
    if (this.state.Itinerary === "") {
      this.MyAlert("Must specify Schedule Name");
      return;
    }
    if (this.state.Subject === "") {
      this.MyAlert("Must specify Subject");
      return;
    }
    if (this.state.dateStart > this.state.dateEnd) {
      //console.log("this.state.dateStart, this.state.dateEnd", this.state.dateStart, this.state.dateEnd);
      this.MyAlert("End time must be greater than start time Latitude");
      return;
    }
    const mode = this.props.mode;
    const old_event = this.props.event;
    const callback = this.props.callback;
    let new_event = new Appointment();
    let _placeIDs = "";
    if (mode == "ADD") {
      new_event.Subject = this.state.Subject;
      new_event.Start = this.state.dateStart.toISO({ zone: "utc" }); //this.state.dateStart;
      new_event.End = this.state.dateEnd.toISO({ zone: "utc" }); //this.state.dateEnd;
      new_event.Body = this.state.notesUpdated;
      new_event.Itinerary = this.state.Itinerary;
      this.state.Places.forEach((place) => {
        _placeIDs += _placeIDs != "" ? "," + place.ID : place.ID;
      });
      new_event.Places = _placeIDs;
      //console.log("new_event", new_event);
      window.guide_obj.schedule.addEvent(new_event);
    } else {
      old_event.Subject = this.state.Subject;
      old_event.Start = this.state.dateStart.toISO({ zone: "utc" }); //this.state.dateStart;
      old_event.End = this.state.dateEnd.toISO({ zone: "utc" }); //this.state.dateEnd;
      old_event.Body = this.state.notesUpdated;
      old_event.Itinerary = this.state.Itinerary;
      this.state.Places.forEach((place) => {
        _placeIDs += _placeIDs != "" ? "," + place.ID : place.ID;
      });
      //console.log("old_event", old_event);
      old_event.Places = _placeIDs;
      //console.log("old_event", old_event);
      window.guide_obj.schedule.modifyEvent(old_event);
    }
    if (callback != null) callback();
    this.props.onHide();
  };

  onChangeStart = (event) => {
    const dateStart = Common.LuxonDateFromJSDate(event);
    this.setState({ ...this.state, dateStart: dateStart });
    // let dateEnd = this.state.dateEnd;
    // if (dateStart > dateEnd) {
    //   dateEnd = dateStart;
    //   dateEnd = dateEnd.plus({ hours: 1 });
    // }
    // this.setState({ ...this.state, dateStart: dateStart, dateEnd: dateEnd });
  };

  onChangeEnd = (event) => {
    const dateEnd = Common.LuxonDateFromJSDate(event);
    this.setState({ ...this.state, dateEnd: dateEnd });
    // let dateStart = this.state.dateStart;
    // if (dateStart > dateEnd) {
    //   dateStart = dateEnd;
    //   dateStart = dateStart.minus({ hours: 1 });
    // }
    // this.setState({ ...this.state, dateStart: dateStart, dateEnd: dateEnd });
  };

  showModeStart = (currentMode) => {
    this.setState({ ...this.state, showStart: true, modeStart: currentMode });
  };

  showDatepickerStart = () => {
    console.log(this.state.modeStart, this.state.showStart);
    this.showModeStart("date");
  };

  showTimepickerStart = () => {
    console.log(this.state.modeStart, this.state.showStart);
    this.showModeStart("time");
  };

  showModeEnd = (currentMode) => {
    this.setState({ ...this.state, showEnd: true, modeEnd: currentMode });
  };

  showDatepickerEnd = () => {
    // setTimeout(() => {
    //   this.showModeEnd("time");
    // }, 500);
    this.showModeEnd("date");
  };

  showTimepickerEnd = () => {
    this.showModeEnd("time");
  };

  selectGroups = () => {
    this.MultiSelect(
      window.guide_obj.getGroupsToDisplay(null),
      [],
      "multi",
      this.selectGroupsCallBack,
      "Select Groups"
    );
  };

  selectGroupsCallBack = (Groups) => {
    let groupIDs = [];
    Groups.forEach((group) => {
      groupIDs.push(group.id);
    });
    let group_places = window.guide_obj.getGroupAndCategoryPlaces([], groupIDs, []);
    let _places = [];
    group_places.forEach((place) => {
      _places.push({
        Name: place.Name,
        ID: place.ID,
      });
    });
    let unique_array = this.state.Places.concat(_places);
    unique_array = Array.from(new Set(unique_array));
    this.setState({ ...this.state, Places: unique_array });
  };

  selectPlaces = () => {
    //this.setState({ ...this.state, tempNotes: this.notesUpdated });
    let selectedPlaces = [];
    this.state.Places.forEach((place) =>
      selectedPlaces.push({
        label: place.Name,
        name: place.Name,
        value: place.ID,
      })
    );
    //console.log("selectedPlaces", selectedPlaces);
    this.MultiSelect(
      window.guide_obj.places.getPlacesToDisplay(null),
      selectedPlaces,
      "multi",
      this.selectPlacesCallBack,
      "Select Places"
    );
  };

  selectPlacesCallBack = (Places) => {
    let _places = [];
    Places.forEach((place) =>
      _places.push({
        Name: place.label,
        ID: place.value,
      })
    );
    let title = this.state.Subject;
    if (_places.length > 0) if (title == "") title = _places[0].Name;
    this.setState({ ...this.state, Places: _places, Subject: title });
    // setTimeout(() => {
    //   this.setState({ ...this.state, notesUpdated: this.tempNotes });
    // }, 200);
  };

  createPlace = () => {
    this.props.navigation.navigate("PlaceEdit", {
      place: null,
      mode: "ADD",
      type: "Icon",
      callback: this.createPlaceCallback,
    });
  };

  createPath = () => {
    this.props.navigation.navigate("PlaceEdit", {
      place: null,
      mode: "ADD",
      type: "PolyLine",
      callback: this.createPlaceCallback,
    });
  };

  createArea = () => {
    this.props.navigation.navigate("PlaceEdit", {
      place: null,
      mode: "ADD",
      type: "Polygon",
      callback: this.createPlaceCallback,
    });
  };

  createPlaceCallback = (place) => {
    if (place === null) return;
    let new_place = { label: place.Name, value: place.ID };
    let _places = this.state.Places;
    _places.push(new_place);
    let unique_array = Array.from(new Set(_places));
    this.selectPlacesCallBack(unique_array);
  };

  placeSelected = (label, value) => {
    let Places = this.state.Places;
    if (Places != "") Places += ",";
    Places += label;

    let place_ids = this.state.places == "" ? "" : this.state.places + ",";
    place_ids = place_ids + value.ID;
    this.setState({ ...this.state, Places: Places });
  };

  displayPlaces = () => {
    let _places = "";
    this.state.Places.forEach((place) => {
      _places += _places != "" ? "," + place.Name : place.Name;
    });
    return _places;
  };

  newScheduleName = () => {
    this.setState({ visiblePrompt: true });
  };

  addNewScheduleName = () => {
    //console.log(this.state.newScheduleName);
    this.setState({ visiblePrompt: false, Itinerary: this.state.newScheduleName });
  };

  textChanged = (text) => {
    this.setState({ newScheduleName: text });
  };

  ScheduleSelected = (item) => {
    //console.log(item);
    this.setState({ Itinerary: item.label });
  };

  chooseSchedule = () => {
    //console.log("selectLinkType");
    this.props.navigation.navigate("MultiSelectDialog", {
      selectionList: this.props.ScheduleNames,
      selectedItems: this.props.schedule_name,
      callBack: this.chooseScheduleCallBack,
      edit_type: "Schedule",
      title: "Schedule",
      selectText: "Select Schedule",
      mode: "uni",
    });
  };

  // JSDateFromLuxonDate(date) {
  //   const JSDate = new Date(date.year, date.month - 1, date.day, date.hour, date.minute, 0);
  //   return JSDate;
  // }

  // LuxonDateFromJSDate(date) {
  //   const LuxonDate = DateTime.fromObject(
  //     {
  //       year: date.getFullYear(),
  //       month: date.getMonth() + 1,
  //       day: date.getDate(),
  //       hour: date.getHours(),
  //       minute: date.getMinutes(),
  //       second: 0,
  //     },
  //     { zone: "utc" }
  //   );
  //   return LuxonDate;
  // }

  chooseScheduleCallBack = (schedules) => {
    if (schedules.length > 0) {
      this.setState({ Itinerary: schedules[0].label });
    }
  };
  displayList(arr) {
    let str = "";
    for (let i = 0; i < arr.length; i++) {
      if (str != "") str += ", ";
      str += arr[i];
    }
    return str;
  }

  displayValueList(arr) {
    console.log("displayValueList", arr);
    let str = "";
    for (let i = 0; i < arr.length; i++) {
      if (str != "") str += ", ";
      str += arr[i].Name;
    }
    return str;
  }

  // handleChangetime = (event, which) => {
  //   const hour_min = event.target.value.split(":");
  //   let newDate = this.state.dateStart.set({ hour: parseInt(hour_min[0]), minutes: parseInt(hour_min[1]) });
  //   if (which === "Start") {
  //     let newDate = this.state.dateStart.set({ hour: parseInt(hour_min[0]), minutes: parseInt(hour_min[1]) });
  //     this.setState({ start_time: event.target.value, dateStart: newDate });
  //   } else {
  //     newDate = this.state.dateEnd.set({ hour: parseInt(hour_min[0]), minutes: parseInt(hour_min[1]) });
  //     this.setState({ end_time: event.target.value, dateEnd: newDate });
  //   }
  //   console.log("newDate", newDate.toString());
  // };

  MultiSelect = (Items, SelectedItems, Mode, Callback, Title) => {
    console.log("MultiSelect");
    this.setState({
      MultiselectItems: Items,
      MultiselectSelectedItems: SelectedItems,
      MultiselectMode: Mode,
      MultiselectCallback: Callback,
      MultiselectTitle: Title,
      MultiselectSearchText: "",
      ShowMultiselect: true,
    });
  };

  render() {
    let startDateTime = "";
    let endDateTime = "";
    if (this.state.dateStart != "") {
      startDateTime = `${Common.formatDateLuxon(this.state.dateStart)} ${Common.formatTimeLuxon(
        this.state.dateStart,
        false
      )}`;
    }
    if (this.state.dateEnd != "") {
      endDateTime = `${Common.formatDateLuxon(this.state.dateEnd)} ${Common.formatTimeLuxon(
        this.state.dateEnd,
        false
      )}`;
    }
    // if (this.state.ShowMultiselect)
    //   return (
    //     <MultiSelectModal
    //       show={this.state.ShowMultiselect}
    //       onHide={() => this.setState({ ShowMultiselect: false })}
    //       MultiselectItems={this.state.MultiselectItems}
    //       MultiselectSelectedItems={this.state.MultiselectSelectedItems}
    //       MultiselectMode={this.state.MultiselectMode}
    //       MultiselectCallback={this.state.MultiselectCallback}
    //       MultiselectTitle={this.state.MultiselectTitle}
    //       MultiselectSearchText={this.state.MultiselectSearchText}
    //     />
    //   );
    // if (this.state.AlertModalShow)
    //   return (
    //     <AlertModal
    //       show={this.state.AlertModalShow}
    //       onHide={() => this.setState({ AlertModalShow: false })}
    //       AlertModalTitle={this.state.AlertModalTitle}
    //       AlertModalMessage={this.state.AlertModalMessage}
    //       AlertModalButtons={this.state.AlertModalButtons}
    //     />
    //   );

    return (
      <div className="modal">
        <Modal
          {...this.props}
          enforceFocus={false}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          scrollable={true}
        >
          <Modal.Header>
            <div style={{ width: "100%" }}>
              <div className="sort">
                <div className="dialog-heading">
                  <div className="dialog-heading color-blue">
                    <div>
                      <h4>{this.mode === "ADD" ? "Add Event" : "Edit Event"}</h4>
                    </div>
                    <i className="fas fa-check" onClick={() => this.AddEvent()}></i>

                    <span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>

                    <div>
                      <i className={`fas fa-times`} onClick={() => this.props.onHide()}></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div>
              <p className="attribute">Subject</p>
              <Form.Group>
                <Form.Control
                  type="text"
                  value={this.state.Subject}
                  onChange={(e) => this.handleChangeSubject(e.target.value)}
                />
              </Form.Group>
            </div>
            <div style={{ zIndex: 99999999 }}>
              <div>
                <p className="attribute">Event Start</p>
                <DatePicker
                  selected={Common.JSDateFromLuxonDate(this.state.dateStart)}
                  onChange={(date) => this.onChangeStart(date)}
                  showTimeSelect
                  // locale="en-GB"
                  timeFormat="HH:mm"
                  timeIntervals={5}
                  timeCaption="time"
                  dateFormat="MMMM d, yyyy HH:mm"
                />
              </div>
            </div>
            <div>
              <p className="attribute">Event End</p>
              <DatePicker
                selected={Common.JSDateFromLuxonDate(this.state.dateEnd)}
                onChange={(date) => this.onChangeEnd(date)}
                showTimeSelect
                // locale="en-GB"
                timeFormat="HH:mm"
                timeIntervals={5}
                timeCaption="time"
                dateFormat="MMMM d, yyyy HH:mm"
              />
            </div>
            <br></br>
            <div className="form-item">
              <Button className="form-item" variant="primary" size="sm" onClick={() => this.selectPlaces()}>
                Select Places
              </Button>
              <span>&nbsp;&nbsp;</span>
              <Button className="form-item" variant="primary" size="sm" onClick={() => this.selectGroups()}>
                Select Places from Groups
              </Button>
              <p>{this.displayValueList(this.state.Places)}</p>
            </div>
            {/* <div className="form-item">
              <Button className="form-item" variant="primary" size="sm" onClick={() => this.selectGroups()}>
                Select Groups
              </Button>
            </div> */}
            <HTMLEditorComponent
              show={true}
              onHide={() => null}
              html={this.state.Notes}
              callback={null}
              callback_rt={this.NotesUpdated}
              embedded={true}
              title={"Info"}
            />
          </Modal.Body>
        </Modal>
        {this.state.ShowMultiselect ? (
          <MultiSelectModal
            show={this.state.ShowMultiselect}
            onHide={() => this.setState({ ShowMultiselect: false })}
            MultiselectItems={this.state.MultiselectItems}
            MultiselectSelectedItems={this.state.MultiselectSelectedItems}
            MultiselectMode={this.state.MultiselectMode}
            MultiselectCallback={this.state.MultiselectCallback}
            MultiselectTitle={this.state.MultiselectTitle}
            MultiselectSearchText={this.state.MultiselectSearchText}
          />
        ) : null}
        {this.state.AlertModalShow ? (
          <AlertModal
            show={this.state.AlertModalShow}
            onHide={() => this.setState({ AlertModalShow: false })}
            AlertModalTitle={this.state.AlertModalTitle}
            AlertModalMessage={this.state.AlertModalMessage}
            AlertModalButtons={this.state.AlertModalButtons}
          />
        ) : null}
      </div>
    );
  }
}

export default EventEdit;
