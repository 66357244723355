import React, { useState, useEffect, useRef } from "react";
import "./Places.css";
import "./PlaceCard/PlaceCard.css";
import PlaceCard from "./PlaceCard/PlaceCard";
// import PlacesHeader from "../PlacesHeader/PlacesHeader";
import store from "../../store/store";
import { useSelector } from "react-redux";
import Common from "../../Common/Common";
import { useDispatch } from "react-redux";
import { slice1Actions } from "../../store/reducer";
//import { useHover } from "usehooks-ts";
//import google_places_logo from "../../assets/images/google-places-logo.jpg";
import { urlSchemeToFunctions } from "../../CommonWeb/Common";
import PlaceMenu from "../../CommonWeb/PlaceMenu";

const Places = () => {
  // const hoverRef = useRef(null);
  // const isHover = useHover(hoverRef);
  const dispatch = useDispatch();
  const [search, setSearch] = useState(false);
  const [searchedPlace, setSearchedPlace] = useState("");
  const [places, setPlaces] = useState([]);
  // const [places, setPlaces] = useState([]);
  const [found, setFound] = useState();
  const [index, setIndex] = useState();
  const [mainIndex, setmainIndex] = useState();

  let arr1 = useSelector((state) => state.places);
  let user_info = useSelector((state) => state.user_info);
  let noFiltering = useSelector((state) => state.noFiltering);
  let LoadingGuide = useSelector((state) => state.LoadingGuide);
  let SearchText = useSelector((state) => state.SearchText);
  let MapBounds = useSelector((state) => state.MapBounds);

  let SearchResultsPlacesList = useSelector((state) => state.SearchResultsPlacesList);
  let MobileMode = useSelector((state) => state.MobileMode);

  useEffect(() => {
    // let arr = store
    //   .getState()
    //   .slice()
    let arr = [...arr1];
    // arr = arr.sort(function (a, b) {
    //   return a.name > b.name ? 1 : b.name > a.name ? -1 : 0;
    // });

    // let new_arr = [];
    // arr.forEach((section) => {
    //   let new_data = [];
    //   for (let p = 0; p < section.data.length; p++)
    //     new_data.push({ id: section.data[p].id, place: window.guide_obj.places.getPlace(section.data[p]) });
    //   new_arr.push({ Title: section.Title, data: new_data });
    // });

    // console.log("new_arr", new_arr);

    setPlaces([...arr]);
  }, [arr1]);
  // places.length > 0 && console.log("Places", places);

  function handleSortSubmit(sort) {
    if (sort === "section") {
      // let arr = store
      //   .getState()
      //   .slice()
      //   .sort(function (a, b) {
      //     return a[sort] > b[sort] ? 1 : b[sort] > a[sort] ? -1 : 0;
      //   });
      let arr = [...arr1];
      setPlaces([...arr]);
    } else {
      let newArr = [];

      store.getState().map((section) => {
        let arr = section.places.slice().sort(function (a, b) {
          return a[sort] > b[sort] ? 1 : b[sort] > a[sort] ? -1 : 0;
        });
        newArr.push({ section: section.section, places: arr });
        return newArr;
      });
      setPlaces([...newArr]);
    }
  }

  function handleSearchCancel() {
    setSearch(false);
    setSearchedPlace("");
    setFound();
  }

  function handleSubmit(e) {
    e.preventDefault();
    for (let i = 0; i < places.length; i++) {
      for (let j = 0; j < places[i].places.length; j++) {
        if (places[i].places[j].name.toLowerCase() === searchedPlace.toLocaleLowerCase()) {
          setFound(places[i].places[j]);
          setIndex(j);
          setmainIndex(i);
          break;
        }
      }
    }
  }

  const getImageURLString = (place) => {
    //console.log(`**************getImageURLString, ${place.Name}`);
    //console.log(`Photo:${place.Photo}:, Photo_m:${place.Photo_m}:`);
    //console.log(`Photo_m:${place.Photo_m}:`);
    let str = place.Photo;
    if (str === "" || typeof str === "undefined") str = place.Photo_m;
    let img = Common.getImageURL(str, Common.getFBPath(window.guide_obj.guide_id, user_info), false);
    //if (!img.startsWith("data")) console.log(img);
    //if (img === "google_places_logo") img = google_places_logo;
    //if (!img.startsWith("data:image")) console.log(img);
    return img;
  };

  const sectionClicked = (section) => {
    //console.log("section", section);
    let places = [];
    section.data.forEach((data) => {
      places.push(data.place);
    });
    dispatch(slice1Actions.SetPlacesFilter({ value: places }));
    dispatch(slice1Actions.setGuide({ guide: null }));
  };
  //console.log("places", places);

  const clearAllFilters = () => {
    dispatch(slice1Actions.ClearAllFilters());
    dispatch(slice1Actions.SetSearchText({ value: "" }));
    dispatch(slice1Actions.setGuide({ guide: null, fitToBounds: true }));
  };

  const enterLeave = (place, value) => {
    // if (value === true) console.log("Enter", place.Name);
    // else console.log("Leave", place.Name);
    if (value === true) {
      dispatch(slice1Actions.setHighlightedPlaces({ value: [place.ID] }));
      if (place !== null) {
        var eventElement = document.getElementById(`eventID${place.ID}`);
        if (eventElement !== null) {
          eventElement.scrollIntoView();
          eventElement.style.backgroundColor = "yellow";
        }
        //////
        let htmlString = PlaceMenu.handleShowPlaceAttributes(place.ID, SearchResultsPlacesList);
        htmlString = urlSchemeToFunctions(htmlString);
        dispatch(slice1Actions.setHTMLTitle({ value: "Place Attributes" }));
        dispatch(slice1Actions.setHTMLToSHow({ value: htmlString }));
        dispatch(slice1Actions.setShowHTMLReader({ value: true }));
        window.location.href = "#home-start";
        let homeDiv = document.getElementById("home-content-start");
        if (MobileMode) dispatch(slice1Actions.setCurrentAppScreen({ value: "Home" }));
        else if (homeDiv !== null) {
          dispatch(slice1Actions.centerMapToPlaces({ value: [place] }));
          homeDiv.scrollIntoView();
        }
        ///////
      }
    } else {
      dispatch(slice1Actions.setHighlightedPlaces({ value: [] }));
      if (place !== null) {
        eventElement = document.getElementById(`eventID${place.ID}`);
        if (eventElement !== null) {
          eventElement.style.backgroundColor = "#ffffff";
        }
      }
    }
  };

  return (
    <div
      id="PlacesDiv"
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        // width: "20%",
        //maxHeight: "100vh",
        overflowY: "auto",
      }}
    >
      {(!noFiltering || SearchText !== "") && !LoadingGuide ? (
        <div
          onClick={() => clearAllFilters()}
          style={{ background: "#fdcb6e" }}
          className="place-card background-color-yellow"
        >
          <p className="background-color-yellow">Some places may be filtered. Click to show all</p>
        </div>
      ) : null}
      {places.length === 0 && noFiltering && !LoadingGuide && SearchText === "" ? (
        <div className="place-card background-color-yellow">
          <p>No places in guide.</p> <p>Add places from the map.</p>
        </div>
      ) : null}
      {places.map((_section, i) => (
        <div key={i}>
          <div onClick={() => sectionClicked(_section)} style={{ cursor: "pointer" }}>
            <p className="alpha-order">{_section.Title}</p>
          </div>
          {/* <div onClick={() => sectionClicked(_section);}>
            <p className="alpha-order">{_section.Title}</p>
          </div> */}
          {_section.data.map((_place, index) => (
            <PlaceCard
              id={`PlaceCard${_place.place.ID}`}
              mouse_enter={() => enterLeave(_place.place, true)}
              mouse_leave={() => enterLeave(_place.place, false)}
              // ref={hoverRef}
              key={index}
              name={_place.place.Name}
              rating={_place.place.Rating}
              groups={window.guide_obj == null ? "" : window.guide_obj.groupNamesFromIDs(_place.place.Group)}
              distance={_place.place.Distance}
              category={window.guide_obj == null ? "" : window.guide_obj.categoryNamesFromIDs(_place.place.Category)}
              img={getImageURLString(_place.place)}
              place={_place.place}
              i={_place.place.ID}
              user_info={user_info}
              map_bounds={MapBounds}
            />
          ))}
        </div>
      ))}
    </div>
  );
};

export default Places;
