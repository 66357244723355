import { connect } from "react-redux";
import React, { Component, useState, useEffect } from "react";
import { Modal, Form, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import AlertModal from "./AlertModal";
import "./Modal.css";
import PromptModal from "./PromptModal";
import MultiSelectModal from "./MultiSelectModal";
import IconsDialog from "./IconsDialog";
import Place from "../../Models/Place";
import DataBase from "../../dataBase/liquidGuides";
import Review from "../../Models/Review";
import Rating from "react-rating";

const MyToolTip = (props) => {
  const [AlertModalShow, setAlertModalShow] = useState(false);
  const [AlertModalTitle, setAlertModalTitle] = useState("");
  const [AlertModalMessage, setAlertModalMessage] = useState("");
  const [AlertModalButtons, setAlertModalButtons] = useState([]);

  const myAlert = (message, title, buttons) => {
    setAlertModalMessage(message);
    setAlertModalTitle(title == null ? "Message" : title);
    setAlertModalButtons(buttons == null ? [{ Title: "OK", Method: null }] : buttons);
    setAlertModalShow(true);
  };

  // const renderTooltip = (menu_item) => <Tooltip id="button-tooltip">{menu_item.info}</Tooltip>;

  const displayMenuInfo = (msg) => {
    myAlert(msg, "Info");
  };
  if (AlertModalShow)
    return (
      <AlertModal
        show={AlertModalShow}
        onHide={() => setAlertModalShow(false)}
        AlertModalTitle={AlertModalTitle}
        AlertModalMessage={AlertModalMessage}
        AlertModalButtons={AlertModalButtons}
      />
    );
  if (props.no_div === true)
    return (
      <OverlayTrigger
        placement="right"
        delay={{ show: 250, hide: 400 }}
        overlay={<Tooltip id="button-tooltip-2">{props.msg}</Tooltip>}
      >
        <i className={`fa fa-info`} style={{ color: "#3598DB" }} onClick={() => displayMenuInfo(props.msg)}></i>
      </OverlayTrigger>
    );
  if (props.loc === "middle")
    return (
      <div>
        <div className="flex-container">
          <div className="flex-items-left">{props.children}</div>
          <div className="flex-items-right">
            <OverlayTrigger
              placement="right"
              delay={{ show: 250, hide: 400 }}
              overlay={<Tooltip id="button-tooltip-2">{props.msg}</Tooltip>}
            >
              <i className={`fa fa-info`} style={{ color: "#3598DB" }} onClick={() => displayMenuInfo(props.msg)}></i>
            </OverlayTrigger>
          </div>
          <AlertModal
            show={AlertModalShow}
            onHide={() => setAlertModalShow(false)}
            AlertModalTitle={AlertModalTitle}
            AlertModalMessage={AlertModalMessage}
            AlertModalButtons={AlertModalButtons}
          />
        </div>
      </div>
      // </div>
    );

  return (
    <div key={`container`} className={`flex-container-${props.loc}`}>
      {props.children}
      <span>&nbsp;&nbsp;</span>
      {/* <div className={`flex-items-right flex-container-${props.loc}`} key={`r3`}> */}
      <OverlayTrigger
        placement="right"
        delay={{ show: 250, hide: 400 }}
        overlay={<Tooltip id="button-tooltip-2">{props.msg}</Tooltip>}
      >
        <i className={`fa fa-info`} style={{ color: "#3598DB" }} onClick={() => displayMenuInfo(props.msg)}></i>
      </OverlayTrigger>
      <AlertModal
        show={AlertModalShow}
        onHide={() => setAlertModalShow(false)}
        AlertModalTitle={AlertModalTitle}
        AlertModalMessage={AlertModalMessage}
        AlertModalButtons={AlertModalButtons}
      />
    </div>
    // </div>
  );
};

export default MyToolTip;
