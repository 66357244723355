import { connect } from "react-redux";
import React, { Component, useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import "./Modal.css";
import PromptModal from "./PromptModal";
import AlertModal from "./AlertModal";
import MultiSelectModal from "./MultiSelectModal";
import IconsDialog from "./IconsDialog";
import Place from "../../Models/Place";
import Category from "../../Models/Category";

class CategoriesDialog extends Component {
  constructor(props) {
    super(props);
    let list_to_display = [];
    if (window.guide_obj !== null && typeof window.guide_obj !== "undefined")
      list_to_display = window.guide_obj.getCategoriesToDisplay();
    // this.setState({ list_to_display: list_to_display });
    this.state = {
      category: "",
      list_to_display: list_to_display,
      searchText: "",

      PromptModalShow: false,
      PromptModalTitle: "Enter Value",
      PromptModalMessage: "",
      PromptModalButtons: [],
      PromptModalInputs: [],

      AlertModalShow: false,
      AlertModalTitle: "",
      AlertModalMessage: "",
      AlertModalButtons: [],

      IconsDialogShow: false,
    };
  }

  componentDidMount() {
    console.log("componentDidMount");
    this.getList();
  }

  getList = () => {
    let list_to_display = [];
    if (window.guide_obj !== null && typeof window.guide_obj !== "undefined")
      list_to_display = window.guide_obj.getCategoriesToDisplay();
    this.setState({ list_to_display: list_to_display });
  };

  MyAlert = (title, message, buttons) => {
    this.setState({
      AlertModalTitle: title,
      AlertModalMessage: message,
      AlertModalButtons: buttons,
      AlertModalShow: true,
    });
  };

  _category = null;
  _category_obj = null;

  addModifyCategory = (add, category) => {
    this._category_obj = category;
    if (category != null) this._category = category.name;
    this.setState({
      PromptModalShow: true,
      PromptModalTitle: "Category",
      PromptModalMessage: add ? "Enter Category Name" : "Edit Category Name",
      PromptModalButtons: [
        { Title: "OK", Method: add ? this.AddCategoryCallback : this.ModifyCategoryNameCallback },
        { Title: "CANCEL", Method: null },
      ],
      PromptModalInputs: [{ name: "Category Name", value: add ? "" : category.name }],
    });
  };

  ModifyCategoryNameCallback = (inputs) => {
    if (inputs === null) return;

    let cat = new Category();
    cat.id = this._category_obj.value.id;
    cat.name = inputs[0].value;
    cat.poi_img = this._category_obj.value.poi_img;

    window.guide_obj.modifyCategory(cat, cat);
    this.getList();
    if (this.props.update_display !== null) this.props.update_display();
  };

  AddCategoryCallback = (inputs) => {
    if (inputs === null) return;
    window.guide_obj.addCategory(inputs[0].value);
    this.getList();
    if (this.props.update_display !== null) this.props.update_display();
  };

  deleteCategory = (category) => {
    this.MyAlert("Confirmation", "Confirm Delete Category?", [
      { Title: "OK", Method: () => this._deleteCategory(category) },
      { Title: "CANCEL", Method: null },
    ]);
  };

  _deleteCategory = (category) => {
    window.guide_obj.deleteCategory(category);
    this.getList();
  };

  chooseCategoryIcon = (category) => {
    this._category_obj = category;
    this.props.navigation.navigate("IconsDialog", { callback: this.chooseCategoryIconCallback });
  };

  chooseCategoryIconCallback = (poi_img) => {
    this._category_obj.value.poi_img = poi_img;
    window.guide_obj.modifyCategory(this._category_obj.value, this._category_obj.value);
    this.getList();
  };

  handleSearchChanged = (event) => {
    this.setState({ searchText: event.target.value });
    let list_to_display = window.guide_obj.getCategoriesToDisplay();
    let temp_list = [];
    const _searchText = event.target.value.toUpperCase();
    list_to_display.forEach((item) => {
      if (item.label.toUpperCase().includes(_searchText)) temp_list.push(item);
    });
    this.setState({ list_to_display: temp_list });
  };

  UpdateIcon = (icon) => {
    // console.log(icon);
    // let cat = new Category();
    // cat.id = this._category_obj.value.id;
    // cat.name = this._category_obj.value.name;
    // cat.poi_img = icon.id;
    // cat.url = icon.value;
    // window.guide_obj.modifyCategory(cat, cat);
    // this.props.update_display();
    console.log(icon);
    this._category_obj.value.poi_img = icon.id;
    this._category_obj.value.url = icon.value;
    window.guide_obj.modifyGroup(this._category_obj.value, this._category_obj.value);
    this.props.update_display();
  };

  ChangeIcon = (category) => {
    this._category_obj = category;
    if (category != null) this._category = category.name;
    this.setState({ IconsDialogShow: true });
  };

  render() {
    // if (this.state.ShowMultiselect)
    //   return (
    //     <MultiSelectModal
    //       show={this.state.ShowMultiselect}
    //       onHide={() => this.setState({ ShowMultiselect: false })}
    //       MultiselectItems={this.state.MultiselectItems}
    //       MultiselectSelectedItems={this.state.MultiselectSelectedItems}
    //       MultiselectMode={this.state.MultiselectMode}
    //       MultiselectCallback={this.state.MultiselectCallback}
    //       MultiselectTitle={this.state.MultiselectTitle}
    //       MultiselectSearchText={this.state.MultiselectSearchText}
    //     />
    //   );
    // // else if (this.state.list_to_display == []) {
    // //   this.getList();
    // //   return null;
    // // }

    // let list_to_display = null;
    // if (window.guide_obj !== null && typeof window.guide_obj !== "undefined")
    //   list_to_display = window.guide_obj.getCategoriesToDisplay();
    // if (list_to_display == null) return null;
    let list_to_display = this.state.list_to_display;
    if (list_to_display == null) {
      if (window.guide_obj !== null && typeof window.guide_obj !== "undefined")
        list_to_display = window.guide_obj.getCategoriesToDisplay();
      if (list_to_display == null) return null;
    }
    return (
      <div className="modal">
        <Modal
          {...this.props}
          size={window.innerWidth < 450 ? "lg" : "md"}
          dialogClassName="myModalClass"
          aria-labelledby="contained-modal-title-vcenter"
          centered={false}
          scrollable={true}
        >
          <Modal.Header closeButton>
            <div style={{ width: "100%" }}>
              <div className="sort">
                <div className="dialog-heading">
                  <div>
                    <h4 className="color-blue">Categories</h4>
                  </div>
                </div>
                <div className="dialog-heading">
                  <input type="text" placeholder="Search" onChange={this.handleSearchChanged} />
                  {/* {MultiselectMode === "multi" ? (
                    <i className={`fas fa-tasks ${show ? "" : "color"}`} onClick={handleSelectAll}></i>
                  ) : null} */}
                  <i
                    title="Add category"
                    className={`fa fa-plus`}
                    onClick={() => this.addModifyCategory(true, null)}
                  ></i>
                </div>
                {/* <Modal.Title id="example-modal-sizes-title-sm">{MultiselectTitle}</Modal.Title> */}
              </div>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="multiselect-list">
              {this.state.list_to_display.map((item, index) => (
                <div className="list-item-card" key={index}>
                  <div className="flex-container">
                    <div className="flex-items-left" key={index} onClick={() => this.ChangeIcon(item)}>
                      {Place.getItemPOIImage(item.value) == null ? (
                        <span style={{ cursor: "pointer" }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                      ) : (
                        <img
                          style={{ cursor: "pointer", maxHeight: "32px" }}
                          title="Change Icon"
                          src={Place.getItemPOIImage(item.value)}
                        />
                      )}
                    </div>
                    <div className="flex-items-middle truncate" key={index}>
                      {item.label}
                      {/* <span className="text-size-1-0">{item.label}</span> */}
                    </div>
                    <div className="flex-items-right flex-container-right" key={index}>
                      <i
                        title="Delete"
                        className={`fa fa-trash-alt`}
                        style={{ color: "red" }}
                        onClick={() => this.deleteCategory(item)}
                      ></i>
                      <i
                        title="Change Name"
                        className={`fa fa-edit color-blue`}
                        onClick={() => this.addModifyCategory(false, item)}
                      ></i>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </Modal.Body>
        </Modal>
        <PromptModal
          show={this.state.PromptModalShow}
          onHide={() => this.setState({ PromptModalShow: false })}
          PromptModalTitle={this.state.PromptModalTitle}
          PromptModalMessage={this.state.PromptModalMessage}
          PromptModalButtons={this.state.PromptModalButtons}
          PromptModalInputs={this.state.PromptModalInputs}
        />
        <AlertModal
          show={this.state.AlertModalShow}
          onHide={() => this.setState({ AlertModalShow: false })}
          AlertModalTitle={this.state.AlertModalTitle}
          AlertModalMessage={this.state.AlertModalMessage}
          AlertModalButtons={this.state.AlertModalButtons}
        />
        <IconsDialog
          show={this.state.IconsDialogShow}
          onHide={() => this.setState({ IconsDialogShow: false })}
          update_icon={this.UpdateIcon}
        />
      </div>
    );
  }
}

export default CategoriesDialog;
