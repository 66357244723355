import React, { useState } from "react";
import "../MobileHeader/MobileHeader.css";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { slice1Actions } from "../../../store/reducer";
import Common, { Settings, SortByEnum, AUTHORIZATION_LEVEL, COLORS } from "../../../Common/Common";
//import ReactGA from "react-ga";
import ReactGA from "react-ga4";

const MobileFooter = () => {
  const dispatch = useDispatch();
  let CurrentAppScreen = useSelector((state) => state.CurrentAppScreen);
  let creator_info = useSelector((state) => state.creator_info);
  let owner_info = useSelector((state) => state.owner_info);
  let user_info = useSelector((state) => state.user_info);
  let AppSettings = useSelector((state) => state.AppSettings);
  let MobileMode = useSelector((state) => state.MobileMode);
  let NoAds = useSelector((state) => state.NoAds);

  const NavigateToScreen = (screen) => {
    console.log("NavigateToScreen", screen, CurrentAppScreen);
    if (screen === "Home" && CurrentAppScreen === "Home") dispatch(slice1Actions.resetHomeScreen({ value: null }));
    dispatch(slice1Actions.setCurrentAppScreen({ value: screen }));
    // ReactGA.event({
    //   category: "navigation",
    //   action: "mobile-footer-navigate",
    //   label: screen,
    // });
  };

  const userColor = (screen) => {
    return CurrentAppScreen === screen ? "#fdcb6e" : "#3598DB";
  };

  const purchaseTickets = () => {
    let htmlString = Common.purchaseTicketsHTML(AppSettings, creator_info, owner_info, user_info);
    //htmlString = urlSchemeToFunctions(htmlString);
    //console.log("purchaseTickets", htmlString);
    window.location.href = "#home-start";
    var homeDiv = document.getElementById("home-content-start");
    if (MobileMode) dispatch(slice1Actions.setCurrentAppScreen({ value: "Home" }));
    else homeDiv.scrollIntoView();
    dispatch(slice1Actions.setHTMLTitle({ value: "Tickets" }));
    dispatch(slice1Actions.setHTMLToSHow({ value: htmlString }));
    dispatch(slice1Actions.setShowHTMLReader({ value: true }));
    //window.location.href = "#home-start";
    //var homeDiv = document.getElementById("home-content-start");
    if (MobileMode) dispatch(slice1Actions.setCurrentAppScreen({ value: "Home" }));
    else homeDiv.scrollIntoView();
  };

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        margin: "0px",
        justifyContent: "space-between",
        alignItems: "center",
        fontSize: "1rem",
      }}
    >
      <div className="mobile-header">
        <div
          style={{
            color: userColor("Home"),
            verticalAlign: "middle",
            textAlign: "center",
          }}
          onClick={() => NavigateToScreen("Home")}
        >
          <i className="fa fa-home"></i>
          <p>Home</p>
        </div>

        <div
          style={{
            color: userColor("Places"),
            verticalAlign: "middle",
            textAlign: "center",
          }}
          onClick={() => NavigateToScreen("Places")}
        >
          <i className="fa fa-map-marker"></i>
          <p>Places</p>
        </div>

        <div
          style={{
            color: userColor("Map"),
            verticalAlign: "middle",
            textAlign: "center",
          }}
          onClick={() => NavigateToScreen("Map")}
        >
          <i className="fa fa-map"></i>
          <p>Map</p>
        </div>
        <div
          style={{
            color: userColor("Schedule"),
            verticalAlign: "middle",
            textAlign: "center",
          }}
          onClick={() => NavigateToScreen("Schedule")}
        >
          <i className="fa fa-calendar"></i>
          <p>Schedule</p>
        </div>
        {!NoAds ? (
          <div
            style={{ color: "#3598DB", verticalAlign: "middle", textAlign: "center" }}
            onClick={() => purchaseTickets(true)}
          >
            <i className="fas fa-ticket"></i>
            <p>Tickets</p>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default MobileFooter;
