import React, { Component } from "react";
import { Form, Button, Modal } from "react-bootstrap";
import "./Modal.css";
import { useSelector } from "react-redux";
import { GuideAttributes } from "../../Models/Guide";
import DataBase from "../../dataBase/liquidGuides";
import Common, { Settings, SortByEnum, COLORS } from "../../Common/Common";
import { languages } from "../../Data/Languages";
import { countries } from "../../Data/Countries";
import { DateTime } from "luxon";
import MultiSelectModal from "./MultiSelectModal";
import CitiesDialog from "./CitiesDialog";
import ImageDialog from "./ImageDialog";
import UsersDialog from "./UsersDialog";

class GuideEditModal extends Component {
  constructor(props) {
    super(props);
    //const guide_meta = this.props.guide_meta;
    const guideAttributes = this.props.guide_info;
    const mode = this.props.mode;
    const guide_info_id = this.props.guide_info_id;
    //console.log("mode", mode);
    let initialName = "New Guide";
    let initialDescription = "";
    let initialRating = 0;
    let initialLanguage = "English";
    let initialImage = "";
    let initialCountries = [];
    let initialCities = [];
    let initialAccess = [];
    if (mode !== "ADD") {
      initialName = guideAttributes["guide_name"];
      initialDescription = guideAttributes["description"];
      initialRating = guideAttributes["rating"];
      initialLanguage = guideAttributes["language"];
      initialImage = guideAttributes["image"];

      if (typeof guideAttributes["access"] != "undefined") initialAccess = guideAttributes["access"];

      if (typeof guideAttributes["countries"] == "undefined") initialCountries = [];
      else initialCountries = guideAttributes["countries"];
      if (typeof guideAttributes["cities"] == "undefined") initialCities = [];
      else initialCities = guideAttributes["cities"];
    }
    let processed_guide_infos = window.guide_obj.classifyAuthorizationInfo(guideAttributes, this.props.user_info.uid);
    const processed_guide_info = processed_guide_infos;
    let Featured = "No";
    if (guideAttributes.type === "Featured") Featured = "Yes";
    this.state = {
      guideAttributes: guideAttributes,
      Name: initialName,
      Description: initialDescription,
      Rating: initialRating,
      image: initialImage,
      Language: initialLanguage,
      Countries: initialCountries,
      Cities: initialCities,
      CitiesShow: false,
      cities_sorted: [],
      Users: [],
      AccessNames: [],
      Access: initialAccess,
      ShareAll: "None",
      Group_Read: [],
      Group_Copy: [],
      Group_Modify: [],
      Group_Read_Names: [],
      Group_Copy_Names: [],
      Group_Modify_Names: [],
      Group_Read_List: [],
      Group_Copy_List: [],
      Group_Modify_List: [],
      Auth: "None",
      Featured: Featured,
      textValueLat:
        typeof guideAttributes.base_location === "undefined" ? 0 : guideAttributes.base_location.latitude.toFixed(6),
      textValueLongi:
        typeof guideAttributes.base_location === "undefined" ? 0 : guideAttributes.base_location.longitude.toFixed(6),
      processed_guide_info: processed_guide_info,
      guide_info_id: guide_info_id,

      MultiselectItems: [],
      MultiselectSelectedItems: [],
      MultiselectMode: "uni",
      MultiselectCallback: null,
      MultiselectTitle: "Select",
      MultiselectSearchText: "",
      ShowMultiselect: false,

      UsersDialogShow: false,
    };
    this.handleChangeName = this.handleChangeName.bind(this);
    this.handleChangeDescription = this.handleChangeDescription.bind(this);
    this.handleChangeRating = this.handleChangeRating.bind(this);
  }

  componentDidMount() {
    this.processAccess();
  }

  _processAccess = false;
  processAccess = () => {
    //console.log(this.state.Access);
    let Featured = "No";
    let ShareAll = "None";
    let Auth = "None";
    let Group_Read = [];
    let Group_Copy = [];
    let Group_Modify = [];
    if (this.state.guideAttributes.type === "Featured") Featured = "Yes";
    if (this.state.Access.length != 0) {
      this.state.Access.forEach((access) => {
        //if (access == "All_R" || access == "All_C" || access == "All_M") ShareAll = access;
        //if (access == "Featured") Featured = "Yes";
        if (access == "All_R") ShareAll = "Read";
        else if (access == "All_C") ShareAll = "Copy";
        else if (access == "All_M") ShareAll = "Modify";
        if (access == this.props.user_info.uid) Auth = "Modify";
        if (access.includes(":")) {
          let access_parts = access.split(":");
          if (access_parts[1] == "R") {
            Group_Read.push(access_parts[0]);
            if (access_parts[0] == this.props.user_info.uid) Auth = "Read";
          } else if (access_parts[1] == "C") {
            Group_Copy.push(access_parts[0]);
            if (access_parts[0] == this.props.user_info.uid) Auth = "Copy";
          } else if (access_parts[1] == "M") {
            Group_Modify.push(access_parts[0]);
            if (access_parts[0] == this.props.user_info.uid) Auth = "Modify";
          }
        }
      });
    }
    if (Auth == "None") {
      if (ShareAll == "Read") Auth = "Read";
      if (ShareAll == "Copy") Auth = "Copy";
      if (ShareAll == "Modify") Auth = "Modify";
    }
    this.setState({
      Featured: Featured,
      ShareAll: ShareAll,
      Auth: Auth,
      Group_Read: Group_Read,
      Group_Copy: Group_Copy,
      Group_Modify: Group_Modify,
    });
    if (Group_Read.length > 0 || Group_Copy.length > 0 || Group_Modify.length > 0) {
      //Use this line if not reading all users
      this.getUserDetails(Group_Read, Group_Copy, Group_Modify);
      //Use this if reading all users alo change the 3 methods called from the group shares
      // if (this.state.Users.length == 0) {
      //   this._processAccess = true;
      //   this.getUsers();
      // } else this.processGroups(this.state.Users);
    }
  };

  getUserDetails = (Group_Read, Group_Copy, Group_Modify) => {
    Group_Read.forEach((gr) => {
      DataBase.findUserByUID(gr, this.findUserByUIDCallbackRead);
    });
    Group_Copy.forEach((gr) => {
      DataBase.findUserByUID(gr, this.findUserByUIDCallbackCopy);
    });
    Group_Modify.forEach((gr) => {
      DataBase.findUserByUID(gr, this.findUserByUIDCallbackModify);
    });
  };

  findUserByUIDCallbackRead = async (res) => {
    if (res.pass === true) {
      const user_info = res.res;
      let Group_Read = this.state.Group_Read;
      let Group_Read_Names = this.state.Group_Read_Names;
      let Group_Read_List = this.state.Group_Read_List;
      Group_Read.push(user_info.uid);
      Group_Read_Names.push(`${user_info.first_name}, ${user_info.last_name}`);
      Group_Read_List.push({
        name: user_info.uid,
        value: user_info.uid,
        label: `${user_info.first_name}, ${user_info.last_name}`,
      });
      this.setState({ Group_Read: Group_Read, Group_Read_Names: Group_Read_Names, Group_Read_List: Group_Read_List });
    }
  };

  findUserByUIDCallbackCopy = async (res) => {
    if (res.pass === true) {
      const user_info = res.res;
      let Group_Copy = this.state.Group_Copy;
      let Group_Copy_Names = this.state.Group_Copy_Names;
      let Group_Copy_List = this.state.Group_Copy_List;
      Group_Copy.push(user_info.uid);
      Group_Copy_Names.push(`${user_info.first_name}, ${user_info.last_name}`);
      Group_Copy_List.push({
        name: user_info.uid,
        value: user_info.uid,
        label: `${user_info.first_name}, ${user_info.last_name}`,
      });
      this.setState({ Group_Copy: Group_Copy, Group_Copy_Names: Group_Copy_Names, Group_Copy_List: Group_Copy_List });
    }
  };

  findUserByUIDCallbackModify = async (res) => {
    if (res.pass === true) {
      const user_info = res.res;
      let Group_Modify = this.state.Group_Modify;
      let Group_Modify_Names = this.state.Group_Modify_Names;
      let Group_Modify_List = this.state.Group_Modify_List;
      Group_Modify.push(user_info.uid);
      Group_Modify_Names.push(`${user_info.first_name}, ${user_info.last_name}`);
      Group_Modify_List.push({
        name: user_info.uid,
        value: user_info.uid,
        label: `${user_info.first_name}, ${user_info.last_name}`,
      });
      this.setState({
        Group_Modify: Group_Modify,
        Group_Modify_Names: Group_Modify_Names,
        Group_Modify_List: Group_Modify_List,
      });
    }
  };

  processGroups = (Users) => {
    let Group_Read_Names = [];
    this.state.Group_Read.forEach((user_id) => {
      const index = Users.findIndex((User) => User.value == user_id);
      if (index != -1) Group_Read_Names.push(Users[index].label);
    });
    let Group_Copy_Names = [];
    this.state.Group_Copy.forEach((user_id) => {
      const index = Users.findIndex((User) => User.value == user_id);
      if (index != -1) Group_Copy_Names.push(Users[index].label);
    });
    let Group_Modify_Names = [];
    this.state.Group_Modify.forEach((user_id) => {
      const index = Users.findIndex((User) => User.value == user_id);
      if (index != -1) Group_Modify_Names.push(Users[index].label);
    });
    this.setState({
      Group_Read_Names: Group_Read_Names,
      Group_Copy_Names: Group_Copy_Names,
      Group_Modify_Names: Group_Modify_Names,
    });
  };

  handleChangeName = (newText) => {
    if (newText != "") {
      var re = /^[a-zA-Z0-9 +(),-_]+$/;
      //console.log(re.test(newText));
      if (!re.test(newText)) return;
    }
    this.setState({ ...this.state, Name: newText });
  };
  handleChangeDescription = (newText) => {
    this.setState({ ...this.state, Description: newText });
  };
  handleChangeRating = (newText) => {
    this.setState({ ...this.state, Rating: newText });
  };

  addGuideAttributes = () => {
    const mode = this.props.mode;
    if (mode != "ADD") {
      this._addGuideAttributes();
      return;
    }
    this.MyAlert("Confirmation", "This will erase any unsaved changes to the current guide. Continue?", [
      { Title: "OK", Method: () => this._addGuideAttributes() },
      { Title: "CANCEL", Method: null },
    ]);
  };

  _addGuideAttributes = () => {
    // console.log(window.guide_obj.attributes);
    // return;
    if (this.state.Name == "") {
      this.MyAlert("Must specify Name");
      return;
    }
    const mode = this.props.mode;
    let modified_guide_attributes = JSON.parse(JSON.stringify(window.guide_obj.attributes)); //guideAttributes", null);

    const callback = this.props.callback;
    let Access = [];
    Access.push(this.props.user_info.uid);
    // if (this.state.Featured == "Yes") Access.push("Featured");
    if (this.state.ShareAll == "Read") Access.push("All_R");
    else if (this.state.ShareAll == "Copy") Access.push("All_C");
    else if (this.state.ShareAll == "Modify") Access.push("All_M");
    this.state.Group_Read.forEach((user_id) => {
      Access.push(user_id + ":R");
    });
    this.state.Group_Copy.forEach((user_id) => {
      Access.push(user_id + ":C");
    });
    this.state.Group_Modify.forEach((user_id) => {
      Access.push(user_id + ":M");
    });

    //console.log("Access", Access);
    if (mode == "ADD") {
      let new_guide_attributes = new GuideAttributes(
        this.state.Name,
        this.state.Description,
        this.state.image,
        this.state.Language,
        parseInt(this.state.Rating),
        this.state.Countries,
        this.state.Cities
      );
      new_guide_attributes.access = Access;
      new_guide_attributes.user_id = this.props.user_info.uid;
      new_guide_attributes.mod = DateTime.now().toISO({ zone: "utc" });
      new_guide_attributes.base_location = {
        latitude: parseFloat(this.state.textValueLat),
        longitude: parseFloat(this.state.textValueLongi),
      };
      //this.props.onSetCurrentLocation(new_guide_attributes.base_location);
      //console.log("new_guide_attributes", new_guide_attributes);
      if (callback != null) callback(new_guide_attributes);
    } else {
      modified_guide_attributes.guide_name = this.state.Name;
      modified_guide_attributes.description = this.state.Description;
      modified_guide_attributes.image = this.state.image;
      modified_guide_attributes.language = this.state.Language;
      modified_guide_attributes.rating = parseInt(this.state.Rating);
      modified_guide_attributes.countries = this.state.Countries;
      modified_guide_attributes.cities = this.state.Cities;
      modified_guide_attributes.access = Access;
      if (this.state.Featured === "Yes") modified_guide_attributes.type = "Featured";
      else modified_guide_attributes.type = "";

      // window.guide_obj.attributes.guide_name = this.state.Name;
      // window.guide_obj.attributes.description = this.state.Description;
      // window.guide_obj.attributes.image = this.state.image;
      // window.guide_obj.attributes.language = this.state.Language;
      // window.guide_obj.attributes.rating = parseInt(this.state.Rating);
      // window.guide_obj.attributes.countries = this.state.Countries;
      // window.guide_obj.attributes.cities = this.state.Cities;
      // window.guide_obj.attributes.access = Access;

      // modified_guide_attributes.base_location = {
      //   latitude: parseFloat(this.state.textValueLat),
      //   longitude: parseFloat(this.state.textValueLongi),
      // };
      //this.props.onSetCurrentLocation(modified_guide_attributes.base_location);
      //console.log("modified_guide_attributes", modified_guide_attributes);

      window.guide_obj.attributes.modifyGuideAttributes(modified_guide_attributes);
      if (callback != null) callback();
    }
    this.props.onHide();
  };

  changeImage = () => {
    this.setState({
      ImageDialogShow: true,
      mode: "select",
      size: "medium",
      multi: false,
      updateImage: this.updateImageCallBack,
      name: this.state.textValueName,
      Photo: this.state.Photo,
      Photo_m: this.state.Photo_m,
    });
  };

  updateImageCallBack = (imageInfo) => {
    let attr = this.state.guideAttributes;
    this.setState({ guideAttributes: attr, image: imageInfo[0].url_m });
  };

  selectLanguage = () => {
    let _languages = [];
    const languages_sorted = languages.sort();
    let id = 0;
    languages_sorted.forEach((language) =>
      _languages.push({
        label: language,
        name: language,
        value: language,
        id: (id++).toString(),
      })
    );
    const _selected = this.state.Language.split(",");
    let selected_languages = [];
    _selected.forEach((language) => {
      const index = _languages.findIndex((x) => x.name == language);
      if (index != -1) selected_languages.push(_languages[index]);
    });

    this.MultiSelect(_languages, [this.state.Language], "uni", this.selectedLanguage, "Select Language");
  };

  selectedLanguage = (language) => {
    if (language.length < 1) return;
    this.setState({ ...this.state, Language: language[0].label });
  };

  selectCountries = () => {
    let _countries = [];
    const countries_sorted = countries.sort();
    let id = 1;
    countries_sorted.forEach((country) =>
      _countries.push({
        label: country,
        name: country,
        value: country,
        id: (id++).toString(),
      })
    );
    this.MultiSelect(_countries, this.state.Countries, "multi", this.selectedCountry, "Select Countries");
  };

  selectedCountry = (countries) => {
    if (countries.length < 1) return;
    let _countries = [];
    countries.forEach((country) => {
      _countries.push(country.label);
    });
    this.setState({ ...this.state, Countries: _countries });
  };

  //cities_sorted = null;

  selectCities = () => {
    let _cities = JSON.parse(JSON.stringify(this.state.Cities));
    //console.log("_cities", _cities);
    let cities_sorted = _cities.sort();
    console.log("cities_sorted", cities_sorted);
    this.setState({ CitiesShow: true, cities_sorted: cities_sorted });
  };

  updateCities = (cities) => {
    this.setState({ ...this.state, Cities: cities });
  };

  selectAccess = () => {
    let _access = [];
    let id = 1;
    ["None", "Read", "Copy", "Modify"].forEach((access) =>
      _access.push({
        label: access,
        name: access,
        value: access,
        id: (id++).toString(),
      })
    );
    this.MultiSelect(_access, [this.state.ShareAll], "uni", this.selectedAccess, "Select Access Right");
  };

  selectedAccess = (access) => {
    if (access.length < 1) return;
    this.setState({ ...this.state, ShareAll: access[0].label });
  };

  getUsers = () => {
    DataBase.getAllUsers(this.getAllUsersCallback);
  };

  getAllUsersCallback = (res) => {
    //console.log("getAllUsersCallback", res);
    if (res.pass == false) return;
    let _users = [];
    let id = 0;
    res.res.forEach((user) => {
      if (user.data.uid == this.props.user_info.uid) return;
      _users.push({
        label: `${user.data.first_name} ${user.data.last_name}`, //, ${user.data.email}`,
        name: user.data.uid,
        value: user.data.uid,
        id: (id++).toString(),
      });
    });

    if (this._processAccess == true) {
      this._processAccess = false;
      this.processGroups(_users);
      return;
    }

    _users.sort((a, b) => (a.label.toUpperCase() > b.label.toUpperCase() ? 1 : -1));
    this.setState({ Users: _users });
    if (this._usersRead == false) {
      this._usersRead = true;
      this.displayUsers(_users);
    }
  };

  _access = "";
  _usersRead = false;

  findUsers = (access) => {
    this._access = access;
    this.setState({ UsersDialogShow: true });
  };

  updateUsers = (users) => {
    console.log("users: ", users);
    let _users = [];
    let _user_names = [];
    users.forEach((user) => {
      _users.push(user.value);
      _user_names.push(user.label);
    });
    //console.log("selectedUsers", _user_names);
    if (this._access === "Read")
      this.setState({ Group_Read: _users, Group_Read_Names: _user_names, Group_Read_List: users });
    else if (this._access === "Copy")
      this.setState({ Group_Copy: _users, Group_Copy_Names: _user_names, Group_Copy_List: users });
    else if (this._access === "Modify")
      this.setState({ Group_Modify: _users, Group_Modify_Names: _user_names, Group_Modify_List: users });
  };

  selectUsers = (access) => {
    this._access = access;
    if (this.state.Users.length === 0) {
      this._usersRead = false;
      this.getUsers();
      return;
    }
    this.displayUsers(this.state.Users, access);
  };

  displayUsers = (users) => {
    //console.log("displayUsers", users);
    let selected = this.state.Group_Read;
    if (this._access === "Copy") selected = this.state.Group_Copy;
    else if (this._access === "Modify") selected = this.state.Group_Modify;
    console.log("displayUsers selected:", selected);
    console.log("displayUsers users:", users);

    let selectedUsers = [];
    selected.forEach((user_id) => {
      const index = users.findIndex((x) => x.value == user_id);
      if (index != -1)
        selectedUsers.push({
          label: users[index].label,
          name: user_id,
          value: user_id,
        });
    });
    console.log("displayUsers selectedUsers:", selectedUsers);

    this.MultiSelect(users, selectedUsers, "uni", this.selectedUsers, "Select Users");
  };

  selectedUsers = (users) => {
    //console.log("selectedUsers", users);
    let _users = [];
    let _user_names = [];
    users.forEach((user) => {
      _users.push(user.value);
      _user_names.push(user.label);
    });
    //console.log("selectedUsers", _user_names);
    if (this._access === "Read") this.setState({ Group_Read: _users, Group_Read_Names: _user_names });
    else if (this._access === "Copy") this.setState({ Group_Copy: _users, Group_Copy_Names: _user_names });
    else if (this._access === "Modify") this.setState({ Group_Modify: _users, Group_Modify_Names: _user_names });
  };

  showMapDialog = () => {
    let initialCoordinate = {
      latitude: parseFloat(this.state.textValueLat),
      longitude: parseFloat(this.state.textValueLongi),
    };
    // if (
    //   !isNaN(this.state.textValueLat) &&
    //   !isNaN(this.state.textValueLongi) &&
    //   this.state.textValueLat != "0" &&
    //   this.state.textValueLongi != "0"
    // ) {
    //   initialCoordinate = {
    //     latitude: parseFloat(this.state.textValueLat),
    //     longitude: parseFloat(this.state.textValueLongi),
    //   };
    // }
    this.props.onGetMapToDisplay(this.props.guide_id);
    this.props.navigation.navigate("MapDialog", {
      updateCoordinate: this.updateCoordinate,
      initialCoordinate: initialCoordinate,
      type: "Icon",
      initialPoints: this.state.Points,
    });
  };

  updateCoordinate = (coordinate, path) => {
    if (path == null)
      this.setState({ textValueLat: coordinate.latitude.toFixed(6), textValueLongi: coordinate.longitude.toFixed(6) });
  };

  selectPlace = () => {
    let places = window.guide_obj.places.getPlacesToDisplay(null);

    this.MultiSelect(places, [], "uni", this.selectPlaceCallBack, "Select Place");
  };

  selectPlaceCallBack = (Places) => {
    let place = null;
    if (Places.length > 0) place = window.guide_obj.places.getPlace(Places[0].value);
    //console.log(Places);
    if (place != null) this.setState({ textValueLat: place.Lat, textValueLongi: place.Longi });
  };

  MyAlert = (title, message, buttons) => {
    this.setState({
      AlertModalTitle: title,
      AlertModalMessage: message,
      AlertModalButtons: buttons,
      AlertModalShow: true,
    });
  };

  getImageURLString = (str) => {
    // console.log("BEFORE", str);
    const img = Common.getImageURL(str, Common.getFBPath(null, this.props.user_info), true);
    // console.log("AFTER", img);
    return img;
  };

  MultiSelect = (Items, SelectedItems, Mode, Callback, Title) => {
    console.log("MultiSelect");
    this.setState({
      MultiselectItems: Items,
      MultiselectSelectedItems: SelectedItems,
      MultiselectMode: Mode,
      MultiselectCallback: Callback,
      MultiselectTitle: Title,
      MultiselectSearchText: "",
      ShowMultiselect: true,
    });
  };

  updateFeatured = () => {
    this.setState({ Featured: this.state.Featured === "Yes" ? "No" : "Yes" });
  };

  switchToEdit = () => {
    this.props.callback(null, true);
  };

  render() {
    let _countries = this.state.Countries.join(", ");
    let _cities = this.state.Cities.join(", ");
    const mode = this.props.mode;
    //console.log("this.state.processed_guide_info.access_summary", this.state.processed_guide_info.access_summary);
    if (this.state.CitiesShow)
      return (
        <CitiesDialog
          show={this.state.CitiesShow}
          onHide={() => this.setState({ CitiesShow: false })}
          cities={this.state.cities_sorted}
          callback={this.updateCities}
          update_display={null}
        />
      );
    if (this.state.ShowMultiselect)
      return (
        <MultiSelectModal
          show={this.state.ShowMultiselect}
          onHide={() => this.setState({ ShowMultiselect: false })}
          MultiselectItems={this.state.MultiselectItems}
          MultiselectSelectedItems={this.state.MultiselectSelectedItems}
          MultiselectMode={this.state.MultiselectMode}
          MultiselectCallback={this.state.MultiselectCallback}
          MultiselectTitle={this.state.MultiselectTitle}
          MultiselectSearchText={this.state.MultiselectSearchText}
        />
      );
    if (this.state.ImageDialogShow)
      return (
        <ImageDialog
          show={this.state.ImageDialogShow}
          onHide={() => this.setState({ ImageDialogShow: false })}
          user_info={this.props.user_info}
          // callback={this.updateImageCallBack}
          mode={"select"}
          modify_only={"true"}
          updateImage={this.updateImageCallBack}
          size={"sm"}
          photo={this.state.Photo}
          photo_m={this.state.Photo_m}
          multi={false}
          exif={false}
        />
      );
    if (mode === "VIEW")
      return (
        <div className="modal">
          <Modal
            {...this.props}
            dialogClassName="myModalClass"
            aria-labelledby="contained-modal-title-vcenter"
            size={window.innerWidth < 450 ? "lg" : "md"}
            scrollable={true}
          >
            <Modal.Header>
              <div style={{ width: "100%" }}>
                <div className="sort">
                  <div className="dialog-heading">
                    <div className="dialog-heading">
                      <div>
                        <h4 style={{ color: "#3598DB" }}>Guide Properties</h4>
                      </div>
                      <p onClick={() => this.switchToEdit()}>Edit</p>

                      <span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>

                      <div>
                        <i className={`fas fa-times`} onClick={() => this.props.onHide()}></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Header>

            <Modal.Body>
              <div className="font-class">
                <div>
                  <p className="attribute">Name</p>
                  <p>{this.state.guideAttributes.guide_name}</p>
                </div>
                <div>
                  <p className="attribute">Description</p>
                  <p>{this.state.guideAttributes.description}</p>
                </div>
                <div>
                  <p className="attribute">URL link</p>
                  <p>{`https://andiamoplaces.com?id=${this.state.guide_info_id}`}</p>
                </div>

                <div>
                  <p>
                    <span className="attribute">Size: </span>
                    <span className="color-black">{`${JSON.stringify(window.guide_obj)
                      .length.toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} bytes`}</span>
                  </p>
                </div>
                <div>
                  <p>
                    <span className="attribute">Featured: </span>
                    <span className="color-black">{this.state.Featured}</span>
                  </p>
                </div>
                <div>
                  <p>
                    <span className="attribute">Language: </span>
                    <span className="color-black">{this.state.guideAttributes.language}</span>
                  </p>
                </div>
                <div>
                  <p className="attribute">Countries</p>
                  <p>{_countries}</p>
                </div>
                <div>
                  <p className="attribute">Cities</p>
                  <p>{_cities}</p>
                </div>
                <div>
                  <p>
                    <span className="attribute">Base Location: </span>
                    <span className="color-black">{`lat: ${this.state.textValueLat}, lon: ${this.state.textValueLongi}`}</span>
                  </p>
                </div>
                <div>
                  <p>
                    <span className="attribute">Average Rating: </span>
                    <span className="color-black">{this.state.guideAttributes.rating}</span>
                  </p>
                </div>
                <div>
                  <p>
                    <span className="attribute">Total Ratings Received: </span>
                    <span className="color-black">
                      {this.state.guideAttributes.num_ratings.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </span>
                  </p>
                </div>
                <div>
                  <p>
                    <span className="attribute">Times Read: </span>
                    <span className="color-black">
                      {this.state.guideAttributes.times_read.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                    </span>
                  </p>
                </div>
                <div>
                  <p>
                    <span className="attribute">Created: </span>
                    <span className="color-black">{`${Common.formatDateLuxon(
                      this.state.guideAttributes["created"]
                    )} ${Common.formatTimeLuxon(this.state.guideAttributes["created"])}`}</span>
                  </p>
                </div>
                <div>
                  <p>
                    <span className="attribute">Last Read: </span>
                    <span className="color-black">{`${Common.formatDateLuxon(
                      this.state.guideAttributes["read"]
                    )} ${Common.formatTimeLuxon(this.state.guideAttributes["read"])}`}</span>
                  </p>
                </div>
                <div>
                  <p>
                    <span className="attribute">Last Modified: </span>
                    <span className="color-black">{`${Common.formatDateLuxon(
                      this.state.guideAttributes["read"]
                    )} ${Common.formatTimeLuxon(this.state.guideAttributes["mod"])}`}</span>
                  </p>
                </div>
                <div>
                  <p className="attribute">Owner</p>
                  <p>{this.state.guideAttributes.owner_name}</p>
                </div>
                <div>
                  <p className="attribute">Owner ID</p>
                  <p>{this.state.guideAttributes.user_id}</p>
                </div>
                <div>
                  <p className="attribute">Creator ID</p>
                  <p>{this.state.guideAttributes.creator_id}</p>
                </div>
                <div>
                  <p className="attribute">Guide ID</p>
                  <p>{this.state.guide_info_id}</p>
                </div>
                <div>
                  <p className="attribute">Guide Access</p>
                  <p>{this.state.processed_guide_info.access_summary}</p>
                </div>
                <div>
                  <p className="attribute">Users with Read access</p>
                  {this.state.Group_Read_Names.map((user, index) => {
                    return <p key={index}>{user}</p>;
                  })}
                </div>
                <div>
                  <p className="attribute">Users with Copy access</p>
                  {this.state.Group_Copy_Names.map((user, index) => {
                    return <p key={index}>{user}</p>;
                  })}
                </div>
                <div>
                  <p className="attribute">Users with Modify access</p>
                  {this.state.Group_Modify_Names.map((user, index) => {
                    return <p key={index}>{user}</p>;
                  })}
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      );
    else
      return (
        <div className="modal">
          <Modal
            {...this.props}
            dialogClassName="myModalClass"
            aria-labelledby="contained-modal-title-vcenter"
            // centered
            scrollable={true}
            // style={{ padding: "50px" }}
          >
            <Modal.Header>
              <div style={{ width: "100%" }}>
                <div className="sort">
                  <div className="dialog-heading">
                    <div className="dialog-heading">
                      <div>
                        <h4 style={{ color: "#3598DB" }}>Edit Guide Properties</h4>
                      </div>
                      <i className="fas fa-check" onClick={() => this._addGuideAttributes()}></i>

                      <span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>

                      <div>
                        <i className={`fas fa-times`} onClick={() => this.props.onHide()}></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Header>
            <Modal.Body>
              <p className="attribute">Name</p>
              <Form.Group>
                <Form.Control
                  type="text"
                  value={this.state.Name}
                  onChange={(e) => this.handleChangeName(e.target.value)}
                />
              </Form.Group>

              {this.props.user_info.uid === "E2Fqqu9FWDgTdpOzdKQOKEdo6wA3" ? (
                <div className="multiselect-item">
                  {/* <p className="attribute">Featured</p>
                  <span>&nbsp;&nbsp;&nbsp;</span> */}
                  <Form>
                    <Form.Group className="mb-3" controlId="Featured">
                      <Form.Check
                        label="Featured"
                        type="checkbox"
                        id="Featured"
                        checked={this.state.Featured === "Yes"}
                        onChange={() => this.updateFeatured()}
                      />
                    </Form.Group>
                  </Form>
                </div>
              ) : null}

              <div className="image">
                <img
                  src={this.getImageURLString(this.state.image)}
                  alt=""
                  className="text-center rounded"
                  style={{ objectFit: "cover" }}
                  width={150}
                  height={150}
                />
              </div>
              <div className="text-center">
                <div>
                  <Button className="mt-3" onClick={() => this.changeImage()}>
                    Change Image
                  </Button>
                </div>
              </div>
              <p className="attribute">Description</p>
              <Form.Group>
                <Form.Control
                  as="textarea"
                  style={{ height: "150px" }}
                  value={this.state.Description}
                  onChange={(e) => this.handleChangeDescription(e.target.value)}
                />
              </Form.Group>
              {/* <div>
                <p className="attribute">Featured</p>
                <p>{this.state.Featured}</p>
              </div> */}
              <div className="text-center">
                <div onClick={this.selectLanguage}>
                  <Button style={{ minWidth: "140px" }} className="mt-3">
                    Language{" "}
                  </Button>
                </div>
                <span>{this.state.Language}</span>
                <div onClick={this.selectCountries}>
                  <Button style={{ minWidth: "140px" }} className="mt-3">
                    Countries{" "}
                  </Button>
                </div>
                <span>{_countries}</span>
                <div onClick={this.selectCities}>
                  <Button style={{ minWidth: "140px" }} className="mt-3">
                    Cities{" "}
                  </Button>
                </div>
                <span>{_cities}</span>
                <div className="text-center" onClick={this.selectAccess}>
                  <Button style={{ minWidth: "140px" }} className="mt-3">
                    All Users Can
                  </Button>
                </div>
                <p key={"ShareAll"}>{this.state.ShareAll}</p>
              </div>
              {/* <div>
                <p className="attribute">Base Location</p>
                <p>{`lat: ${this.state.textValueLat}, lon: ${this.state.textValueLongi}`}</p>
              </div> */}
              {/* <div>
                <p className="attribute">Access:</p>
                <p>{this.state.processed_guide_info.access_summary}</p>
              </div> */}

              <div className="text-center" onClick={() => this.findUsers("Read")}>
                <Button style={{ minWidth: "140px" }} className="mt-3">
                  Users with Read access
                </Button>
              </div>
              {this.state.Group_Read_Names.map((user, index) => {
                return <p key={index}>{user}</p>;
              })}

              <div className="text-center" onClick={() => this.findUsers("Copy")}>
                <Button style={{ minWidth: "140px" }} className="mt-3">
                  Users with Copy access
                </Button>
              </div>
              {this.state.Group_Copy_Names.map((user, index) => {
                return <p key={index}>{user}</p>;
              })}

              <div className="text-center" onClick={() => this.findUsers("Modify")}>
                <Button style={{ minWidth: "140px" }} className="mt-3">
                  Users with Modify access
                </Button>
              </div>
              {this.state.Group_Modify_Names.map((user, index) => {
                return <p key={index}>{user}</p>;
              })}
            </Modal.Body>
          </Modal>
          {this.state.UsersDialogShow ? (
            <UsersDialog
              show={this.state.UsersDialogShow}
              onHide={() => this.setState({ UsersDialogShow: false })}
              users={
                this._access === "Read"
                  ? this.state.Group_Read_List
                  : this._access === "Copy"
                  ? this.state.Group_Copy_List
                  : this.state.Group_Modify_List
              }
              callback={this.updateUsers}
              user_info={this.props.user_info}
              update_display={null}
            />
          ) : null}
        </div>
      );
  }
}

export default GuideEditModal;
