import Place, { Place_Source } from "./Place";

export default class WikiPlaces {
  constructor() {
    this.places = [];
  }

  // https://en.wikipedia.org/w/api.php?action=query&list=geosearch&gscoord=48.7891838|2.4033522&gsradius=10000&format=json&gslimit=50

  // https://en.wikipedia.org/w/api.php?action=query&prop=extracts|pageimages|coordinates&format=json&exintro=1&pithumbsize=250&titles=Uffizi

  // https://en.wikipedia.org/?curid=18630637

  searchPlaces = (bbox, limit, callback) => {
    //(location, radius, limit, callback) => {
    //const callback = this.getDetails;
    var params = {
      //gscoord: location, //lat|lon
      gsbbox: bbox, //top|left|bottom|right order max top-bottom = 0.48, max right-left = 0.1
      //gsradius: radius, //meters 10-10,000
      gslimit: limit, //max 500
    };

    var url = `https://andiamotravelapp.com/api/wikipedia_text_search.php?action=query`;
    //var url = "https://en.wikipedia.org/w/api.php?action=query&list=geosearch&format=json";
    Object.keys(params).forEach(function (key) {
      url += "&" + key + "=" + params[key];
    });
    console.log(" Wikipedia searchPlaces", url);
    fetch(url)
      .then(function (response) {
        return response.json();
      })
      .then(function (response) {
        //console.log("response", JSON.stringify(response));
        callback(response);
      })
      .catch(function (error) {
        console.log("Error in Wikipedia search", error);
        callback(null);
      });
  };
  res = {
    batchcomplete: "",
    query: {
      geosearch: [
        {
          pageid: 53992389,
          ns: 0,
          title: "Cantons of Vitry-sur-Seine",
          lat: 48.79,
          lon: 2.4,
          dist: 261.8,
          primary: "",
        },
        {
          pageid: 458871,
          ns: 0,
          title: "Vitry-sur-Seine",
          lat: 48.7875,
          lon: 2.3928,
          dist: 795.4,
          primary: "",
        },
      ],
    },
  };
  _last = 0;
  _index = 0;
  _places = [];
  _results = [];
  _callback = null;

  res2 = {
    batchcomplete: "",
    warnings: {
      extracts: {
        "*": "HTML may be malformed and/or unbalanced and may omit inline images. Use at your own risk. Known problems are listed at https://www.mediawiki.org/wiki/Special:MyLanguage/Extension:TextExtracts#Caveats.",
      },
    },
    query: {
      pages: {
        458871: {
          pageid: 458871,
          ns: 0,
          title: "Vitry-sur-Seine",
          extract:
            '<p><b>Vitry-sur-Seine</b> (<small>French pronunciation: ​</small><span title="Representation in the International Phonetic Alphabet (IPA)">[vi.tʁi.syʁ.sɛn]</span>) is a commune in the southeastern suburbs of Paris, France. It is located 7.5 km (4.7 mi) from the centre of Paris.\n</p>',
          thumbnail: {
            source:
              "https://upload.wikimedia.org/wikipedia/commons/thumb/c/c1/Vitry_Hotel_de_Ville_DSCN1793.JPG/250px-Vitry_Hotel_de_Ville_DSCN1793.JPG",
            width: 250,
            height: 188,
          },
          pageimage: "Vitry_Hotel_de_Ville_DSCN1793.JPG",
          coordinates: [
            {
              lat: 48.7875,
              lon: 2.3928,
              primary: "",
              globe: "earth",
            },
          ],
        },
        53992389: {
          pageid: 53992389,
          ns: 0,
          title: "Cantons of Vitry-sur-Seine",
          extract:
            "<p>The <b>cantons of Vitry-sur-Seine</b> are administrative divisions of the Val-de-Marne department, Île-de-France region, northern France. Since the French canton reorganisation which came into effect in March 2015, the city of Vitry-sur-Seine is subdivided into 2 cantons. Their seat is in Vitry-sur-Seine.</p>",
          coordinates: [
            {
              lat: 48.79,
              lon: 2.4,
              primary: "",
              globe: "earth",
            },
          ],
        },
      },
    },
  };
  // https://en.wikipedia.org/w/api.php?action=query&prop=extracts|pageimages|coordinates&format=json&exintro=1&pithumbsize=250&pageids=53992389|458871

  getDetails = (places, callback) => {
    console.log("Wikipedia getDetails", places);
    if (places === null) {
      console.log("Wikipedia GetDetails, places === null");
    }
    let pageids = "";
    places.forEach((place) => {
      pageids += pageids === "" ? place.pageid : `|${place.pageid}`;
    });

    const url = `https://andiamotravelapp.com/api/wikipedia_get_details.php?pageids=${pageids}`;
    //const url = `https://en.wikipedia.org/w/api.php?action=query&prop=extracts|pageimages|coordinates&format=json&exintro=1&exlimit=1&exchars=200&pithumbsize=250&pageids=${pageids}`;
    console.log("getDetails", url);
    fetch(url)
      .then(function (response) {
        return response.json();
      })
      .then(function (response) {
        //console.log("response", JSON.stringify(response));
        callback(response);
      })
      .catch(function (error) {
        console.log("Wikipedia error in GetDetails", error);
        callback(null);
      });
  };

  parsePlaces = (wikipedia_geosearch_results, places, searchText) => {
    //console.log("searchText", searchText);
    let results = [];
    if (places == null) {
      return results;
    }
    if (typeof places["query"] == "undefined") {
      return results;
    }
    if (typeof places["query"]["pages"] == "undefined") {
      return results;
    }
    let details = places["query"]["pages"];
    //console.log("parsePlaces", details);
    const keys = Object.keys(details);
    //console.log("keys", keys);
    keys.forEach((key) => {
      let geoplace = wikipedia_geosearch_results.find((x) => x.pageid == key);
      if (geoplace == null || geoplace == "undefined") return;
      if (typeof details[key].thumbnail == "undefined") return;
      let title = "";
      let extract = "";
      if (typeof details[key].extract != "undefined") extract = details[key].extract;
      if (typeof details[key].title != "undefined") title = details[key].title;
      if (searchText != "")
        if (
          !title.toUpperCase().includes(searchText.toUpperCase()) &&
          !extract.toUpperCase().includes(searchText.toUpperCase())
        )
          return;
      let new_place = new Place();
      new_place.createIDFromPlacesArray(results);
      if (typeof title != "undefined") new_place.Name = title;
      if (typeof extract != "undefined") new_place.Info = extract;
      //if (details[key].title == "Île Saint-Germain")//console.log("Île Saint-Germain", details);
      if (isNaN(geoplace.lat || isNaN(geoplace.lon))) return;
      if (typeof geoplace.lat != "undefined") new_place.Lat = geoplace.lat;
      if (typeof geoplace.lon != "undefined") new_place.Longi = geoplace.lon;
      if (typeof details[key].extract != "undefined") new_place.Info = details[key].extract.replace("...", "");
      if (typeof details[key].thumbnail != "undefined") {
        console.log("details[key])", details[key]);
        if (typeof details[key].thumbnail.source != "undefined") {
          new_place.Photo = details[key].thumbnail.source;
          new_place.Photo_m = details[key].thumbnail.source;
        }
      }
      new_place.Hlink = `wikipedia|https://en.m.wikipedia.org/?curid=${key}`;
      const url_link = `https://en.m.wikipedia.org/?curid=${key}`;
      if (new_place.Photo_m !== "")
        new_place.Info = `<p style="text-align:center;"><img src="${new_place.Photo_m}" alt="Image" style="width:100%; max-width:700px; height:auto; border-radius:10%" ></img></p>${new_place.Info}<a href="urllink:${url_link}"> ... See More</a>`;
      new_place.PType = "Icon";
      new_place.Points = null;
      new_place.Source = Place_Source.WIKIPEDIA;
      results.push(new_place);
    });
    //console.log(results);
    return results;
  };

  moveToGuide = (guide, place) => {
    guide.places.addPlace(place);
  };
}
