import React, { useState, useEffect } from "react";
import MenuModal from "../modals/MenuModal";
import FilterModal from "../modals/FilterModal";
import "./GuidesHeader.css";
import { useDispatch } from "react-redux";
import { slice1Actions } from "../../store/reducer";
import { useSelector } from "react-redux";
import Common, { Settings, SortByEnum, AUTHORIZATION_LEVEL, DISTANCES, COLORS } from "../../Common/Common";
import Places from "../../Models/Places";
import GooglePlaces from "../../Models/GooglePlaces";
import Attractions from "../../Models/Attractions";
import Wikipedia from "../../Models/Wikipedia";

import AlertModal from "../modals/AlertModal";
import PromptModal from "../modals/PromptModal";
import MultiSelectModal from "../modals/MultiSelectModal";
import { convertNodeToElement } from "react-html-parser";
import GroupsDialog from "../modals/GroupsDialog";
import CategoriesDialog from "../modals/CategoriesDialog";

const GuidesHeader = ({ setSearch, setSortBy, handleSortSubmit }) => {
  const [shouldSort, setShouldSort] = useState(false);
  const [shouldFilter, setShouldFilter] = useState(false);
  const dispatch = useDispatch();
  let SortBy = useSelector((state) => state.SortBy);
  let noFiltering = useSelector((state) => state.noFiltering);
  let SearchText = useSelector((state) => state.SearchText);
  let SearchResultsPlacesList = useSelector((state) => state.SearchResultsPlacesList);
  let MobileMode = useSelector((state) => state.MobileMode);
  let CurrentAppScreen = useSelector((state) => state.CurrentAppScreen);
  let GuideEditMode = useSelector((state) => state.GuideEditMode);

  const [MenuModalShow, setMenuModalShow] = useState(false);
  const [MenuModalImage, setMenuModalImage] = useState(null);
  const [MenuModalList, setMenuModalList] = useState([]);
  const [MenuModalHeading, setMenuModalHeading] = useState("");
  const [MenuModalPlace, setMenuModalPlace] = useState(null);
  const [MenuModalInfo, setMenuModalInfo] = useState("");

  const [PromptModalShow, setPromptModalShow] = useState(false);
  const [PromptModalTitle, setPromptModalTitle] = useState("Enter Value");
  const [PromptModalMessage, setPromptModalMessage] = useState("");
  const [PromptModalButtons, setPromptModalButtons] = useState([]);
  const [PromptModalInputs, setPromptModalInputs] = useState([]);
  const [PromptModalCompact, setPromptModalCompact] = useState(null);
  const [SavedTextSearch, setSavedTextSearch] = useState("");
  const [AlertModalShow, setAlertModalShow] = useState(false);
  const [AlertModalTitle, setAlertModalTitle] = useState("");
  const [AlertModalMessage, setAlertModalMessage] = useState("");
  const [AlertModalButtons, setAlertModalButtons] = useState([]);

  let MapBounds = useSelector((state) => state.MapBoundsValue);
  const [_GooglePlaces, setGooglePlaces] = useState(null);
  const [_Attractions, setAttractions] = useState(null);
  const [_Wikipedia, setWikipedia] = useState(null);

  const [MultiselectItems, setMultiselectItems] = useState([]);
  const [MultiselectSelectedItems, setMultiselectSelectedItems] = useState([]);
  const [MultiselectMode, setMultiselectMode] = useState("uni");
  const [MultiselectCallback, setMultiselectCallback] = useState(null);
  const [MultiselectTitle, setMultiselectTitle] = useState("Select");
  const [MultiselectSearchText, setMultiselectSearchText] = useState("");
  const [ShowMultiselect, setShowMultiselect] = useState(null);

  const [GroupsDialogShow, setGroupsDialogShow] = useState(false);
  const [CategoriesDialogShow, setCategoriesDialogShow] = useState(false);
  let filtered_places = useSelector((state) => state.places);
  let GuideInfoArray = useSelector((state) => state.GuideInfoArray);
  let GuideInfoArrayMine = useSelector((state) => state.GuideInfoArrayMine);
  let GuideType = useSelector((state) => state.GuideType);

  let GuideSearchText = useSelector((state) => state.GuideSearchText);
  let GuideCities = useSelector((state) => state.GuideCities);
  let GuideCountries = useSelector((state) => state.GuideCountries);
  let GuideLanguages = useSelector((state) => state.GuideLanguages);
  let GuideCitiesMine = useSelector((state) => state.GuideCitiesMine);
  let GuideCountriesMine = useSelector((state) => state.GuideCountriesMine);
  let GuideLanguagesMine = useSelector((state) => state.GuideLanguagesMine);

  let GuideCitiesSelected = useSelector((state) => state.GuideCitiesSelected);
  let GuideCountriesSelected = useSelector((state) => state.GuideCountriesSelected);
  let GuideLanguageSelected = useSelector((state) => state.GuideLanguageSelected);
  let GuideCitiesMineSelected = useSelector((state) => state.GuideCitiesMineSelected);
  let GuideCountriesMineSelected = useSelector((state) => state.GuideCountriesMineSelected);
  let GuideLanguageMineSelected = useSelector((state) => state.GuideLanguageMineSelected);

  const [language, setLanguage] = useState("English");
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);

  useEffect(() => {
    setGooglePlaces(new GooglePlaces());
    setAttractions(new Attractions());
    setWikipedia(new Wikipedia());
  }, []);

  const handleSearChanged = (event) => {
    //console.log(event.target.value);
    dispatch(slice1Actions.SetGuideSearchText({ value: event.target.value }));
    dispatch(slice1Actions.setGuide({ guide: null }));
  };
  function handleSort(_SortBy) {
    switch (_SortBy) {
      case "Name":
        dispatch(slice1Actions.setGuideOrderBy({ value: "Name" }));
        dispatch(slice1Actions.setGuide({ guide: null }));
        break;
      case "Country":
        dispatch(slice1Actions.setGuideOrderBy({ value: "Country" }));
        dispatch(slice1Actions.setGuide({ guide: null }));
        break;
      case "City":
        dispatch(slice1Actions.setGuideOrderBy({ value: "City" }));
        dispatch(slice1Actions.setGuide({ guide: null }));
        break;
      case "Rating":
        dispatch(slice1Actions.SetSortBy({ value: SortByEnum.RATING }));
        dispatch(slice1Actions.setGuide({ guide: null }));
        break;
      case "Distance":
        dispatch(slice1Actions.SetSortBy({ value: SortByEnum.DISTANCE }));
        dispatch(slice1Actions.setGuide({ guide: null }));
        break;
      default:
        break;
    }
  }

  const EditMenu = () => {
    const menuList = [
      // { label: "Edit Groups", method: EditGroups, icon: null, visible: true },
      // { label: "Edit Categories", method: EditCategories, icon: null, visible: true },
      // { label: "**SEPARATOR***", method: null, icon: null, visible: true },
      {
        label: "Add to Groups",
        method: AddGroups,
        icon: null,
        visible: true,
        info: "Add the places in the the places list to one or more groups. Note that the places in the places list can be filtered.",
      },
      {
        label: "Remove from Groups",
        method: RemoveGroups,
        icon: null,
        visible: true,
        info: "Remove the places in the places list from selected groups. Note that the places in the places list can be filtered.",
      },
      {
        label: "Set to Groups",
        method: ReplaceGroups,
        icon: null,
        visible: true,
        info: "Remove the places in the places list from all groups and then add them only to the selected groups. Note that the places in the places list can be filtered.",
      },
      { label: "**SEPARATOR***", method: null, icon: null, visible: true },
      {
        label: "Add to Categories",
        method: AddCategories,
        icon: null,
        visible: true,
        info: "Add the places in the places list to the selected categories. Note that the places in the places list can be filtered.",
      },
      {
        label: "Remove from Categories",
        method: RemoveCategories,
        icon: null,
        visible: true,
        info: "Remove the places in the places list from selected categories. Note that the places in the places list can be filtered.",
      },
      {
        label: "Set to Categories",
        method: ReplaceCategories,
        icon: null,
        visible: true,
        info: "Remove the places in the places list from all categories and add them only to the selected categories. Note that the places in the places list can be filtered.",
      },
      { label: "**SEPARATOR***", method: null, icon: null, visible: true },
      // { label: "Set Places Group", method: SetGroup, icon: null, visible: true },
      {
        label: "Mark Places Favorites",
        method: EditFavorite,
        icon: null,
        visible: true,
        info: "Set the 'Favorite' attribute for the places in the places list, i.e. Favorite or not Favorite",
      },
      {
        label: "Mark Places Visited",
        method: EditVisited,
        icon: null,
        visible: true,
        info: "Set the 'Visited' attribute for the places in the places list, i.e. Visited or not Visited",
      },
      {
        label: "Mark Places as 'Must See'",
        method: EditMustSee,
        icon: null,
        visible: true,
        info: "Set the 'Must See' attribute for the places in the places list, i.e. Must See or not Must See",
      },
      {
        label: "Show/Hide Places",
        method: EditHidden,
        icon: null,
        visible: true,
        info: "Set the 'Hidden' attribute for the places in the places list, i.e. Hidden or not Hidden. Hidden places are not shown on map or places list.",
      },
      {
        label: "Delete Places",
        method: DeletePlaces,
        icon: null,
        visible: true,
        info: "Select which places should be deleted. Note: once deleted they cannot be recovered.",
      },
    ];
    setMenuModalList(menuList);
    setMenuModalHeading("Edit Places");
    setMenuModalImage(<i className="fa fa-pencil"></i>);
    setMenuModalPlace(null);
    setMenuModalInfo(
      "This applies to all filtered places i.e. all places shown in the places list. Apply or remove filters to select desired places."
    );
    setMenuModalShow(true);
  };
  const EditGroups = () => {
    setGroupsDialogShow(true);
  };
  const EditCategories = () => {
    setCategoriesDialogShow(true);
  };

  let AddReplaceMode = "Add";

  const AddCategories = (category) => {
    AddReplaceMode = "Add";
    selectCategories();
  };

  const RemoveCategories = (category) => {
    AddReplaceMode = "Remove";
    selectCategories();
  };

  const ReplaceCategories = (category) => {
    AddReplaceMode = "Replace";
    selectCategories();
  };

  const selectCategories = () => {
    let _ChooseCategoriesList = window.guide_obj.getCategoriesToDisplay();
    dispatch(slice1Actions.setMultiselectCallback({ value: SelectCategoryCallBack }));
    dispatch(slice1Actions.setMultiselectItems({ value: _ChooseCategoriesList }));
    dispatch(slice1Actions.setMultiselectSelectedItems({ value: [] }));
    dispatch(slice1Actions.setMultiselectMode({ value: "uni" }));
    dispatch(slice1Actions.setMultiselectSearchText({ value: "" }));
    dispatch(slice1Actions.setMultiselectTitle({ value: "Select Category" }));
    dispatch(slice1Actions.setShowMultiselect({ value: true }));
  };

  const SelectCategoryCallBack = (categories) => {
    filtered_places.forEach((section) => {
      section.data.forEach((place_m) => {
        let place = window.guide_obj.places.getPlace(place_m.place.ID);
        if (place != null) {
          if (AddReplaceMode === "Replace") place.Category = [];
          categories.forEach((category) => {
            if (AddReplaceMode === "Add" || AddReplaceMode === "Replace") {
              const index = place.Category.indexOf(category.id);
              if (index === -1) place.Category.push(category.id);
            } else if (AddReplaceMode === "Remove") {
              const index = place.Category.indexOf(category.id);
              if (index !== -1) place.Category.splice(index, 1);
            }
          });
        }
      });
    });
    dispatch(slice1Actions.setGuide({ guide: null }));
  };

  const AddGroups = (category) => {
    AddReplaceMode = "Add";
    selectGroups();
  };

  const RemoveGroups = (category) => {
    AddReplaceMode = "Remove";
    selectGroups();
  };

  const ReplaceGroups = (category) => {
    AddReplaceMode = "Replace";
    selectGroups();
  };

  const selectGroups = () => {
    let _ChooseCategoriesList = window.guide_obj.getGroupsToDisplay();
    dispatch(slice1Actions.setMultiselectCallback({ value: SelectGroupsCallBack }));
    dispatch(slice1Actions.setMultiselectItems({ value: _ChooseCategoriesList }));
    dispatch(slice1Actions.setMultiselectSelectedItems({ value: [] }));
    dispatch(slice1Actions.setMultiselectMode({ value: "multi" }));
    dispatch(slice1Actions.setMultiselectSearchText({ value: "" }));
    dispatch(slice1Actions.setMultiselectTitle({ value: "Select Groups" }));
    dispatch(slice1Actions.setShowMultiselect({ value: true }));
  };

  const SelectGroupsCallBack = (groups) => {
    filtered_places.forEach((section) => {
      section.data.forEach((place_m) => {
        let place = window.guide_obj.places.getPlace(place_m.place.ID);
        if (place != null) {
          if (AddReplaceMode === "Replace") place.Group = [];
          groups.forEach((group) => {
            if (AddReplaceMode === "Add" || AddReplaceMode === "Replace") {
              const index = place.Group.indexOf(group.id);
              if (index === -1) place.Group.push(group.id);
            } else if (AddReplaceMode === "Remove") {
              const index = place.Group.indexOf(group.id);
              if (index !== -1) place.Group.splice(index, 1);
            }
          });
        }
      });
    });
    dispatch(slice1Actions.setGuide({ guide: null }));
  };

  const DeletePlaces = () => {
    _place_operation = "Delete Places";
    selectPlaces();
  };

  const EditFavorite = () => {
    _place_operation = "Favorite";
    selectPlaces();
  };

  const EditVisited = () => {
    _place_operation = "Visited";
    selectPlaces();
  };

  const EditMustSee = () => {
    _place_operation = "MustSee";
    selectPlaces();
  };

  const EditHidden = () => {
    _place_operation = "Hidden";
    selectPlaces();
  };

  let AllPlacesSubList = [];
  const selectPlaces = () => {
    let selectedPlaces = [];
    let places = [];
    filtered_places.forEach((section) => {
      section.data.forEach((place_m) => {
        let place = window.guide_obj.places.getPlace(place_m.place.ID);
        places.push({
          label: place.Name,
          name: place.Name,
          value: place,
        });
      });
    });
    AllPlacesSubList = places;
    places.forEach((place) => {
      if (
        (_place_operation === "Favorite" && place.value.Favorite === true) ||
        (_place_operation === "Visited" && place.value.Visited === true) ||
        (_place_operation === "MustSee" && place.value.MustSee === true) ||
        (_place_operation === "Hidden" && place.value.Hidden === true) ||
        _place_operation === "Delete Places"
      ) {
        selectedPlaces.push({
          label: place.label,
          name: place.name,
          value: place.value,
        });
      }
    });
    //console.log("selectedPlaces, count", selectedPlaces, count);
    dispatch(slice1Actions.setMultiselectCallback({ value: selectPlacesCallBack }));
    dispatch(slice1Actions.setMultiselectItems({ value: places }));
    dispatch(slice1Actions.setMultiselectSelectedItems({ value: selectedPlaces }));
    dispatch(slice1Actions.setMultiselectMode({ value: "multi" }));
    dispatch(slice1Actions.setMultiselectSearchText({ value: "" }));
    dispatch(slice1Actions.setMultiselectTitle({ value: "Select Places" }));
    dispatch(slice1Actions.setShowMultiselect({ value: true }));
    // props.navigation.navigate("MultiSelectDialog", {
    //   selectionList: places,
    //   selectedItems: selectedPlaces,
    //   callBack: selectPlacesCallBack,
    //   title: "Places",
    //   selectText: "Select Places",
    // });
  };

  let places_selected = [];

  let _place_operation = "";

  const selectPlacesCallBack = (Places) => {
    if (Places == null) return;
    places_selected = Places;
    if (_place_operation === "Delete Places") {
      places_selected.forEach((place_id) => {
        window.guide_obj.places.deletePlaceById(place_id.value.ID);
      });
      dispatch(slice1Actions.setGuide({ guide: null }));
      return;
    }

    window.guide_obj.places.setPlaceAttributes(AllPlacesSubList, Places, _place_operation);
    UpdateDisplay();
  };

  const SortMenu = () => {
    //console.log("SortBy", SortBy);
    const menuList = [
      // {
      //   label: "Name",
      //   method: handleSort,
      //   icon: <i className="fas fa-check"></i>,
      //   checked: false,
      //   visible: true,
      //   info: "Sort the guides by name",
      // },
      {
        label: "Country",
        method: () => handleSort("Country"),
        icon: <i className="fa fa-check"></i>,
        checked: false,
        visible: true,
        info: "Sort the guides by country",
      },
      {
        label: "City",
        method: () => handleSort("City"),
        icon: <i className="fa fa-check"></i>,
        checked: false,
        visible: true,
        info: "Sort the guides by city",
      },
      // {
      //   label: "Rating",
      //   method: handleSort,
      //   icon: <i className="fa fa-check"></i>,
      //   checked: SortBy === SortByEnum.RATING,
      //   visible: true,
      //   info: "Sort the guides by rating",
      // },
    ];
    //console.log("menuList", menuList);
    setMenuModalList(menuList);
    setMenuModalHeading("Sort Guides");
    setMenuModalImage(<i className="fas fa-tasks"></i>);
    setMenuModalPlace(null);
    setMenuModalShow(true);
    setMenuModalInfo("");
  };

  const FilterMenu = () => {
    //console.log("SortBy", SortBy);
    const menuList = [
      // {
      //   label: "Access Rights",
      //   method: handleSort,
      //   icon: <i className="fas fa-check"></i>,
      //   checked: false,
      //   visible: true,
      //   info: "Filter the guides by access rights",
      // },
      {
        label: "Language",
        method: selectLanguage,
        icon: <i className="fa fa-check"></i>,
        checked: false,
        visible: true,
        info: "Filter the guides by language",
      },
      {
        label: "Country",
        method: selectCountry,
        icon: <i className="fa fa-check"></i>,
        checked: false,
        visible: true,
        info: "Filter the guides by language",
      },
      {
        label: "City",
        method: selectCity,
        icon: <i className="fa fa-check"></i>,
        checked: false,
        visible: true,
        info: "Filter the guides by city",
      },
      {
        label: "Clear filter",
        method: clearFilters,
        icon: <i className="fa fa-check"></i>,
        checked: false,
        visible: true,
        info: "Clear all filters",
      },
    ];
    //console.log("menuList", menuList);
    setMenuModalList(menuList);
    setMenuModalHeading("Filter Guides");
    setMenuModalImage(<i className="fas fa-tasks"></i>);
    setMenuModalPlace(null);
    setMenuModalShow(true);
    setMenuModalInfo("");
  };

  const selectCountry = () => {
    let countries = GuideType === "Featured" ? GuideCountries : GuideCountriesMine;
    let countries_selected = GuideType === "Featured" ? GuideCountriesSelected : GuideCountriesMineSelected;
    dispatch(slice1Actions.setMultiselectCallback({ value: selectedCountries }));
    dispatch(slice1Actions.setMultiselectItems({ value: countries }));
    dispatch(slice1Actions.setMultiselectSelectedItems({ value: countries_selected }));
    dispatch(slice1Actions.setMultiselectMode({ value: "multi" }));
    dispatch(slice1Actions.setMultiselectSearchText({ value: "" }));
    dispatch(slice1Actions.setMultiselectTitle({ value: "Select Countries" }));
    dispatch(slice1Actions.setShowMultiselect({ value: true }));
  };

  const selectedCountries = (countries) => {
    if (GuideType === "Featured") {
      if (countries.length === 0) dispatch(slice1Actions.setGuideCountriesSelected({ value: [] }));
      else dispatch(slice1Actions.setGuideCountriesSelected({ value: countries }));
    } else {
      if (countries.length === 0) dispatch(slice1Actions.setGuideCountriesMineSelected({ value: [] }));
      else dispatch(slice1Actions.setGuideCountriesMineSelected({ value: countries }));
    }
    setTimeout(timeoutFit, 1000);
  };

  const timeoutFit = () => {
    dispatch(slice1Actions.setGuide({ guide: null, fitToBounds: true }));
  };

  const selectCity = () => {
    let cities = GuideType === "Featured" ? GuideCities : GuideCitiesMine;
    let cities_selected = GuideType === "Featured" ? GuideCitiesSelected : GuideCitiesMineSelected;
    dispatch(slice1Actions.setMultiselectCallback({ value: selectedCities }));
    dispatch(slice1Actions.setMultiselectItems({ value: cities }));
    dispatch(slice1Actions.setMultiselectSelectedItems({ value: cities_selected }));
    dispatch(slice1Actions.setMultiselectMode({ value: "multi" }));
    dispatch(slice1Actions.setMultiselectSearchText({ value: "" }));
    dispatch(slice1Actions.setMultiselectTitle({ value: "Select Cities" }));
    dispatch(slice1Actions.setShowMultiselect({ value: true }));
    // let cities_array = [];
    // cities.forEach((city) => {
    //   cities_array.push(city.label);
    // });
    // console.log(cities_array.join(", "));
  };

  const selectedCities = (cities) => {
    if (GuideType === "Featured") {
      if (cities.length === 0) dispatch(slice1Actions.setGuideCitiesSelected({ value: [] }));
      else dispatch(slice1Actions.setGuideCitiesSelected({ value: cities }));
    } else {
      if (cities.length === 0) dispatch(slice1Actions.setGuideCitiesmineSelected({ value: [] }));
      else dispatch(slice1Actions.setGuideCitiesMIneSelected({ value: cities }));
    }
    setTimeout(timeoutFit, 1000);
  };

  const selectLanguage = () => {
    let languages = GuideType === "Featured" ? GuideLanguages : GuideLanguagesMine;
    let language_selected = GuideType === "Featured" ? GuideLanguageSelected : GuideLanguageMineSelected;
    dispatch(slice1Actions.setMultiselectCallback({ value: selectedLanguage }));
    dispatch(slice1Actions.setMultiselectItems({ value: languages }));
    dispatch(slice1Actions.setMultiselectSelectedItems({ value: [language_selected] }));
    dispatch(slice1Actions.setMultiselectMode({ value: "uni" }));
    dispatch(slice1Actions.setMultiselectSearchText({ value: "" }));
    dispatch(slice1Actions.setMultiselectTitle({ value: "Select Language" }));
    dispatch(slice1Actions.setShowMultiselect({ value: true }));
  };

  const selectedLanguage = (languages) => {
    if (GuideType === "Featured") {
      dispatch(slice1Actions.setGuideLanguage({ value: "English" }));
      if (languages.length === 0) dispatch(slice1Actions.setGuideLanguageSelected({ value: "" }));
      else dispatch(slice1Actions.setGuideLanguageSelected({ value: languages[0].label }));
    } else {
      dispatch(slice1Actions.setGuideLanguage({ value: "English" }));
      if (languages.length === 0) dispatch(slice1Actions.setGuideLanguageMineSelected({ value: "" }));
      else dispatch(slice1Actions.setGuideLanguageMIneSelected({ value: languages[0].label }));
    }
    setTimeout(timeoutFit, 1000);
  };

  const clearFilters = () => {
    if (GuideType === "Featured") {
      dispatch(slice1Actions.setGuideCountriesSelected({ value: [] }));
      dispatch(slice1Actions.setGuideCitiesSelected({ value: [] }));
      dispatch(slice1Actions.setGuideLanguageSelected({ value: "English" }));
    } else {
      dispatch(slice1Actions.setGuideCountriesMineSelected({ value: [] }));
      dispatch(slice1Actions.setGuideCitiesMineSelected({ value: [] }));
      dispatch(slice1Actions.setGuideLanguageMineSelected({ value: "English" }));
    }
  };

  const myAlert = (message) => {
    setAlertModalTitle("Notice");
    setAlertModalMessage(message);
    setAlertModalButtons([{ Title: "OK", Method: null }]);
    setAlertModalShow(true);
  };

  const UpdateDisplay = () => {
    dispatch(slice1Actions.setGuide({ guide: null }));
  };

  let number_of_guides = GuideInfoArray.length;
  if (GuideType !== "Featured") number_of_guides = GuideInfoArrayMine.length;

  return (
    <div style={{ marginLeft: 10 }}>
      <div className="places-header">
        {/* <i className="fas fa-search" onClick={() => setSearch(true)}></i> */}
        {/* <i className="fas fa-plus-circle"></i> */}
        <input type="text" value={GuideSearchText} placeholder="Filter" onChange={handleSearChanged} />

        <i
          className="fas fa-filter"
          onClick={() => FilterMenu()}
          style={{ color: noFiltering ? "#3598DB" : "#fdcb6e" }}
        ></i>
        {/* <i className="fas fa-search color-blue" onClick={() => searchMenu()}></i> */}
        {/* <div style={{ verticalAlign: "middle" }} onClick={() => EditMenu()}>
          <i className="fa fa-pencil color-blue"></i>
          <span>edit</span>
        </div> */}

        {/* {GuideEditMode ? <i className="fa fa-pencil color-blue" onClick={() => EditMenu()}></i> : null} */}
        {MobileMode === false || CurrentAppScreen === "Places" ? (
          <i className="fas fa-sort-alpha-down color-blue" onClick={() => SortMenu()}></i>
        ) : null}
      </div>
      <p className="color-blue">{`Guides (${GuideType}): ${number_of_guides}`}</p>
      {MenuModalShow ? (
        <MenuModal
          show={MenuModalShow}
          onHide={() => setMenuModalShow(false)}
          menu_modal_list={MenuModalList}
          menu_modal_heading={MenuModalHeading}
          menu_modal_image={MenuModalImage}
          menu_modal_place={MenuModalPlace}
          menu_modal_info={MenuModalInfo}
        />
      ) : null}

      <PromptModal
        show={PromptModalShow}
        onHide={() => setPromptModalShow(false)}
        PromptModalTitle={PromptModalTitle}
        PromptModalMessage={PromptModalMessage}
        PromptModalButtons={PromptModalButtons}
        PromptModalInputs={PromptModalInputs}
      />
      <AlertModal
        show={AlertModalShow}
        onHide={() => setAlertModalShow(false)}
        AlertModalTitle={AlertModalTitle}
        AlertModalMessage={AlertModalMessage}
        AlertModalButtons={AlertModalButtons}
      />
      <FilterModal show={shouldFilter} onHide={() => setShouldFilter(false)} />
      {GroupsDialogShow ? (
        <GroupsDialog
          show={GroupsDialogShow}
          onHide={() => setGroupsDialogShow(false)}
          update_display={UpdateDisplay}
        />
      ) : null}

      {CategoriesDialogShow ? (
        <CategoriesDialog
          show={CategoriesDialogShow}
          onHide={() => setCategoriesDialogShow(false)}
          update_display={UpdateDisplay}
        />
      ) : null}
    </div>
  );
};

export default GuidesHeader;
