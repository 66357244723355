import React, { useState, Component } from "react";
import { Modal, ToggleButtonGroup, ToggleButton, ButtonGroup, Button, Form } from "react-bootstrap";
import "./Modal.css";
import MultiSelectModal from "./MultiSelectModal";
import ImageDialog from "./ImageDialog";

import AlertModal from "./AlertModal";
import PromptModal from "./PromptModal";
import { useDispatch } from "react-redux";
import { slice1Actions } from "../../store/reducer";
import { useSelector } from "react-redux";
import Common, { DISTANCES, Settings, SortByEnum, labelChars, AUTHORIZATION_LEVEL, COLORS } from "../../Common/Common";
import Place from "../../Models/Place";
import SectionsDialog from "./SectionsDialog";
import MyToolTip from "./MyToolTip";

// console.ignoredYellowBox = ["Warning: ..."];

class HTMLInserDialog extends Component {
  constructor(props) {
    super(props);
    let items = [
      { label: "HTML", value: "HTML", icon: () => <i class="fas fa-link"></i> },
      { label: "Itinerary", value: "Itinerary", icon: () => <i class="fas fa-link"></i> },
      { label: "Section", value: "Section", icon: () => <i class="fas fa-link"></i> },
      //   { label: "Center", value: "Center", icon: () => <i class="fas fa-link"></i> },
      //   { label: "New line", value: "New line", icon: () => <i class="fas fa-link"></i> },
      //   {
      //     label: "Strike through",
      //     value: "Strike through",
      //     icon: () => <i class="fas fa-link"></i>,
      //   },
      { label: "Image", value: "Image", icon: () => <i class="fas fa-link"></i> },
      { label: "Map", value: "Map", icon: () => <i class="fas fa-link"></i> },
      {
        label: "Map with Places List",
        value: "Map with Places List",
        icon: () => <i class="fas fa-link"></i>,
      },

      { label: "Places List", value: "Places List", icon: () => <i class="fas fa-link"></i> },
      { label: "Video", value: "Video", icon: () => <i class="fas fa-link"></i> },
      { label: "Audio", value: "Audio", icon: () => <i class="fas fa-link"></i> },
      //   {
      //     label: "Font Size & Color",
      //     value: "FontSize",
      //     icon: () => <i class="fas fa-link"></i>,
      //   },
      // {
      //   label: "Background Color",
      //   value: "Background Color",
      //   icon: () => <i class="fas fa-link"></i>,
      // },
      // {
      //   label: "Background Image",
      //   value: "Background Image",
      //   icon: () => <i class="fas fa-link"></i>,
      // },
    ];
    items = items.sort((a, b) => (a.label.toUpperCase() > b.label.toUpperCase() ? 1 : -1));
    this.state = {
      insertType: "Image",
      linkType: "Image",
      htmlText: "",
      url: "",
      PlaceIds: [],
      PlaceNames: [],
      Places: [],
      GroupIds: [],
      GroupNames: [],
      Groups: [],
      CategoryIds: [],
      CategoryNames: [],
      Categories: [],
      OrderedPlaces: [],
      sectionName: "",
      guideID: "",
      mediaURL: "",
      imageURL: "",
      imageURLs: [],
      FontSize: "44",
      FontColor: "#000000",
      BackgroundColor: "#ffffff",
      BackgroundimageURL: null,
      imageSource: "Image",
      imageSize: "400",
      PickerItems: items,
      PickerValue: { label: "Image", value: "Image", icon: () => <i class="fas fa-link"></i> },
      selectedImageSize: { id: "8", label: "400", value: "400", name: "400" },
      itineraryName: "Main",
      sectionLink: "",
      sectionName: "",

      AlertModalShow: false,
      AlertModalTitle: "",
      AlertModalMessage: "",
      AlertModalButtons: [],
      ImageDialogShow: false,
      ImageDialogMulti: false,
      MultiselectItems: [],
      MultiselectSelectedItems: [],
      MultiselectMode: "uni",
      MultiselectCallback: null,
      MultiselectTitle: "Select",
      MultiselectSearchText: "",
      ShowMultiselect: false,

      PromptModalShow: false,
      PromptModalTitle: "Enter Value",
      PromptModalMessage: "",
      PromptModalButtons: [],
      PromptModalInputs: [],
      FilterOptions: [
        { name: "Show Only Favorites", value: "0", selected: false },
        { name: "Show Only Visited", value: "1", selected: false },
        { name: "Show Only Not Visited", value: "2", selected: false },
        { name: "Show Only Must See", value: "3", selected: false },
        { name: "Filter by Schedule", value: "4", selected: false },
        { name: "Filter by Day", value: "5", selected: false },
      ],
      ShowOnlyFavorites: false,
      ShowOnlyVisited: false,
      ShowOnlyNotVisited: false,
      ShowOnlyMustSee: false,
      ScheduleFilter: false,
      DayFilter: false,
      day: "",
      distanceFilter: -1,
      DistanceSelected: "",

      SectionsDialogShow: false,
    };
    this.handleChangeHTML = this.handleChangeHTML.bind(this);
    this.handleChangeURL = this.handleChangeURL.bind(this);
    this.handleChangeMediaURL = this.handleChangeMediaURL.bind(this);
  }

  componentDidMount() {}

  handleChangeURL = (event) => {
    this.setState({ ...this.state, url: event.target.value });
  };

  handleChangeHTML = (event) => {
    this.setState({ ...this.state, htmlText: event.target.value });
  };

  handleChangeMediaURL = (event) => {
    this.setState({ ...this.state, mediaURL: event.target.value });
  };

  MyAlert = (message) => {
    this.setState({
      AlertModalTitle: "Message",
      AlertModalMessage: message,
      AlertModalButtons: [{ Title: "OK", Method: null }],
      AlertModalShow: true,
    });
  };
  FilterOptionSelected = (index) => {
    let filters = this.state.FilterOptions;
    filters[index].selected = !filters[index].selected;
    //this.setState({ FilterOptions: filters });
    //console.log("FilterOptionSelected", filters);

    let _DayFilter = this.FilterOptionChoosen("Filter by Day", filters);
    //SetFilterByDay(_FilterByDay);
    let _ScheduleFilter = this.FilterOptionChoosen("Filter by Schedule", filters);
    //SetFilterBySchedule(_FilterBySchedule);

    if (_DayFilter) filters = filters.filter((x) => x.name !== "Filter by Schedule");
    else if (_ScheduleFilter) filters = filters.filter((x) => x.name !== "Filter by Day");
    else {
      let index = filters.findIndex((x) => x.name === "Filter by Schedule");
      if (index === -1) filters.push({ name: "Filter by Schedule", value: "4", selected: false });
      index = filters.findIndex((x) => x.name === "Filter by Day");
      if (index === -1) filters.push({ name: "Filter by Day", value: "5", selected: false });
      console.log("FilterOptionSelected", filters);
    }

    filters = filters.sort((a, b) => (parseInt(a.value) > parseInt(b.value) ? 1 : -1));
    this.setState({ FilterOptions: filters, DayFilter: _DayFilter, ScheduleFilter: _ScheduleFilter });

    // setTimeout(() => {
    //   // CheckFilterOptions1();
    // }, 100);
  };

  FilterOptionChoosen = (name, options) => {
    const option = options.findIndex((x) => x.name === name && x.selected === true);
    if (option !== -1) return true;
    else return false;
  };

  AddHTML = () => {
    let placesList = [];
    if (this.state.OrderedPlaces.length != 0) {
      this.state.OrderedPlaces.forEach((place) => {
        placesList.push(place.value);
      });

      this.setState({ GroupIds: [], CategoryIds: [] });
    } else placesList = this.state.PlaceIds;

    let payload = {};
    //console.log("this.state.insertType", this.state.insertType);
    switch (this.state.insertType) {
      case "HTML":
        payload = { type: "html", html: this.state.htmlText };
        break;
      case "Itinerary":
        const Itinerary = window.guide_obj.createHTMLItinerary(this.state.itineraryName, null);
        payload = { type: "html", html: Itinerary };
        break;
      case "Section":
        let section = window.guide_obj.findSection(this.state.sectionLink);
        if (section == null) {
          this.MyAlert("section not found");
          return;
        }
        payload = { type: "html", html: section.html };
        break;
      case "Center":
        payload = { type: "html", html: `<p style="text-align:center;">${this.state.htmlText}</p><br>` };
        break;
      case "New line":
        payload = { type: "html", html: `<br>` };
        break;
      case "Strike through":
        payload = { type: "html", html: `<del>${this.state.htmlText}</del>` };
        break;
      case "Image":
        let image_html = "";
        let Image_url = this.state.mediaURL;
        if (Image_url.includes("https://drive.google.com")) {
          const video_parts = Image_url.split("/");
          Image_url = `https://docs.google.com/uc?export=download&id=${video_parts[5]}`;
          image_html = Place.createImageHTML(Image_url, null, null, this.state.imageSize);
        } else {
          image_html = Place.createImagesHTML(this.state.imageURLs, null, null, this.state.imageSize);
        }
        //let image_link = `<a href = imagelink:${Image_url}>${image_html}</a>`;
        //payload = { type: "html", html: image_link };
        payload = { type: "html", html: image_html };
        break;
      case "Map":
        //console.log("this.state.PlaceIds", placesList);
        payload = {
          type: "html",
          html: window.guide_obj.createMapHtml(placesList, this.state.GroupIds, this.state.CategoryIds, false),
        };
        break;
      case "Map with Places List":
        //console.log("this.state.PlaceIds", placesList);
        payload = {
          type: "html",
          html: window.guide_obj.createMapHtml(placesList, this.state.GroupIds, this.state.CategoryIds, true),
        };
        break;
      case "Places List":
        payload = {
          type: "html",
          html: window.guide_obj.getPlacesListHTML(placesList, this.state.GroupIds, this.state.CategoryIds, true),
        };
        break;
      case "Video":
        let video_html = "";
        let video_url = this.state.mediaURL;
        if (video_url.includes("https://www.youtu") || video_url.includes("https://youtu")) {
          const youtube = video_url.split("/");
          let yvideo = youtube[youtube.length - 1];
          yvideo = yvideo.replace("watch?v=", "");
          video_html = `<iframe style="max-width: 100%; width: 640px; height: 480px;"
                          src="https://www.youtube.com/embed/${yvideo}?autoplay=0" frameborder="0" allowfullscreen="allowfullscreen">
                        </iframe>`;
        } else if (video_url.includes("https://drive.google.com")) {
          const video_parts = video_url.split("/");
          video_url = `https://docs.google.com/uc?export=download&id=${video_parts[5]}`;
          video_html = `<video width="640" height="480" controls>
                        <source src="${video_url}" type="video/mp4">
                          Video format not supported use mp4, WebM or Ogg.
                        </video>`;
        } else {
          let videoType = "video/mp4";
          if (video_url.toUpperCase().endsWith("MP4")) videoType = "video/mp4";
          else if (video_url.toUpperCase().endsWith("WEBM")) videoType = "video/webm";
          else if (video_url.toUpperCase().endsWith("OGG")) videoType = "video/ogg";
          // else {
          //   this.MyAlert("Video format not supported use mp4, WebM, youtube or Ogg.");
          //   return;
          // }
          video_html = `<video width="640" height="480" controls>
                          <source src="${video_url}" type="${videoType}">
                            Video format not supported use mp4, WebM or Ogg.
                      </video>`;
        }
        //console.log("video_html", video_html);
        payload = { type: "html", html: video_html };
        break;
      case "Audio":
        let audioType = "audio/mpeg";

        let audio_url = this.state.mediaURL;
        //https://drive.google.com/file/d/16r6VCiwiT-UT7AUD8aYdwSyhjtyxRS0f/view?usp=sharing
        //audio_url = "https://docs.google.com/uc?export=download&id=16r6VCiwiT-UT7AUD8aYdwSyhjtyxRS0f";
        if (audio_url.startsWith("https://drive.google.com")) {
          const audio_parts = audio_url.split("/");
          audio_url = `https://docs.google.com/uc?export=download&id=${audio_parts[5]}`;
        }

        if (audio_url.toUpperCase().endsWith("WAV")) audioType = "audio/wav";
        else if (audio_url.toUpperCase().endsWith("OGG")) audioType = "audio/ogg";
        else if (audio_url.toUpperCase().endsWith("MP3")) audioType = "audio/mpeg";
        else audioType = "audio/mp4";
        // else {
        //   this.MyAlert("Adio format not supported use mp3, wav, m4a or ogg.");
        //   return;
        // }
        // const audio_html = `<audio controls style="max-width: 100%; width: 600px;">
        // <source src="${audio_url}" type="${audioType}">
        //   Audio format not supported use mp3, wav, m4a or ogg.
        // </audio>`;

        const audio_html = `<audio style="max-width: 100%; width: 600px;" controls="controls" controlsList="nodownload" autoplay>
    <source src="${audio_url}" type="${audioType}">
  Audio format not supported use mp3, wav, m4a or ogg.
  </audio>`;
        payload = { type: "html", html: audio_html };
        break;
      case "Font Size & Color":
        const font_html = `<span style="font-size: ${this.state.FontSize}%; color: ${this.state.FontColor}; background-color: ${this.state.BackgroundColor}; !important; ">${this.state.htmlText}</span>`;
        //font_html = `<font size="${this.state.FontSize}">${this.state.htmlText}</font>`;
        payload = { type: "html", html: font_html };
        break;
      case "Background Color":
        payload = {
          type: "background-color",
          html: `<!--background-color: ${this.state.BackgroundColor}; background-color-->`,
        };
        break;
      case "Background Image":
        payload = {
          type: "background-image",
          html: `<!--background-image: url(${this.state.BackgroundimageURL}); background-size: 100% 100%; background-image-->`,
        };
        break;
      default:
        break;
    }
    //console.log(`${this.state.insertType}:`, payload);
    const callback = this.props.callback;
    if (callback == null) {
      console.log("No callback from InsertDialog");
      return;
    }
    callback({ type: this.state.insertType, payload: payload });
    this.props.onHide();
  };

  selectItinerary = () => {
    const schedules = window.guide_obj.schedule.geScheduleNames();
    this.MultiSelect(
      schedules,
      [{ label: this.state.itineraryName, value: this.state.itineraryName }],
      "uni",
      this.selectItineraryCallBack,
      "Select Itinerary"
    );
  };

  selectItineraryCallBack = (itineraries) => {
    //console.log("selectScheduleCallBack", itineraries[0].label);
    if (itineraries.length > 0)
      this.setState({
        itineraryName: itineraries[0].label,
        linkText: itineraries[0].label,
        linkTextDisplayed: itineraries[0].label,
      });
  };
  //   selectItinerary = () => {
  //     this.props.navigation.navigate("MultiSelectDialog", {
  //       selectionList: this.state.Itineraries,
  //       selectedItems: [{ label: this.state.itineraryName, value: this.state.itineraryName }],
  //       mode: "uni",
  //       callBack: this.selectItineraryCallBack,
  //       title: "Itinerary",
  //       selectText: "Select Itinerary",
  //     });
  //   };

  //   selectItineraryCallBack = (itineraries) => {
  //     //console.log("selectScheduleCallBack", itineraries[0].label);
  //     if (itineraries.length > 0) this.setState({ itineraryName: itineraries[0].label, linkText: itineraries[0].label });
  //   };

  selectSection = () => {
    //console.log("selectSection");
    this.setState({ SectionsDialogShow: true });
    //this.props.navigation.navigate("SectionSelect", { callback: this.selectSectionCallback });
  };

  selectSectionCallback = (section) => {
    let _sectionName = this.state.sectionName;
    if (_sectionName == "") _sectionName = section.Title;
    this.setState({ sectionLink: section.ID, sectionName: _sectionName });
  };

  displayList(arr) {
    let str = "";
    for (let i = 0; i < arr.length; i++) {
      if (str != "") str += ", ";
      str += arr[i];
    }
    return str;
  }

  displayValueList(arr) {
    let str = "";
    for (let i = 0; i < arr.length; i++) {
      if (str != "") str += ", ";
      str += arr[i].label;
    }
    return str;
  }

  selectPlaces = () => {
    this.MultiSelect(
      window.guide_obj.places.getPlacesToDisplay(null),
      this.state.Places,
      "multi",
      this.selectPlacesCallBack,
      "Places"
    );
  };

  selectPlacesCallBack = (Places) => {
    //console.log(Places);
    let placeIds = [];
    let placeNames = [];
    Places.forEach((place) => {
      placeIds.push(place.value);
      placeNames.push(place.label);
    });
    //console.log("placeIds", placeIds);
    this.setState({ Places: Places, PlaceIds: placeIds, PlaceNames: placeNames });
  };

  selectGroups = () => {
    let selectedGroups = [];
    let groups = this.state.Groups;
    groups.forEach((group) =>
      selectedGroups.push({
        label: group,
        value: group,
      })
    );
    this.MultiSelect(
      window.guide_obj.getGroupsToDisplay(null),
      selectedGroups,
      "multi",
      this.selectGroupsCallBack,
      "Select Groups"
    );
  };

  selectGroupsCallBack = (Groups) => {
    //console.log(Groups);
    let GroupIds = [];
    let GroupNames = [];
    Groups.forEach((group) => {
      GroupIds.push(group.value.id);
      GroupNames.push(group.label);
    });
    this.setState({ Groups: Groups, GroupIds: GroupIds, GroupNames: GroupNames });
    if (this.state.OrderedPlaces.length != 0) this.orderPlaces();
  };

  selectCategories = () => {
    //console.log("selectCategories");
    let selectedCategories = [];
    let cats = this.state.Categories;
    cats.forEach((cat) =>
      selectedCategories.push({
        label: cat.label,
        value: cat.value.id,
      })
    );
    this.MultiSelect(
      window.guide_obj.getCategoriesToDisplay(null),
      selectedCategories,
      "multi",
      this.selectCategoriesCallBack,
      "Select Categories"
    );
  };

  selectCategoriesCallBack = (Categories) => {
    let CategoryIds = [];
    let CategoryNames = [];
    Categories.forEach((cat) => {
      CategoryIds.push(cat.value.id);
      CategoryNames.push(cat.label);
    });
    this.setState({
      Categories: Categories,
      CategoryIds: CategoryIds,
      CategoryNames: CategoryNames,
    });
    if (this.state.OrderedPlaces.length != 0) this.orderPlaces();
  };

  MultiSelect = (Items, SelectedItems, Mode, Callback, Title) => {
    console.log("MultiSelect");
    this.setState({
      MultiselectItems: Items,
      MultiselectSelectedItems: SelectedItems,
      MultiselectMode: Mode,
      MultiselectCallback: Callback,
      MultiselectTitle: Title,
      MultiselectSearchText: "",
      ShowMultiselect: true,
    });
  };

  selectLinkType = () => {
    //console.log("selectLinkType");
    this.MultiSelect(this.state.PickerItems, this.state.PickerValue, "uni", this.selectLinkTypeCallBack, "Link Type");
  };

  selectLinkTypeCallBack = (links) => {
    //console.log(links);
    if (links.length > 0) this.setState({ insertType: links[0].label, PickerValue: links[0] });
  };

  selectImageWidth = () => {
    //console.log("selectImageSize");
    const sizes = [
      { id: "1", label: "50", value: "50", name: "50" },
      { id: "2", label: "100", value: "100", name: "100" },
      { id: "3", label: "150", value: "150", name: "150" },
      { id: "4", label: "200", value: "200", name: "200" },
      { id: "5", label: "300", value: "300", name: "3000" },
      { id: "6", label: "400", value: "400", name: "400" },
      { id: "7", label: "500", value: "500", name: "500" },
      { id: "8", label: "600", value: "600", name: "600" },
      { id: "9", label: "700", value: "700", name: "700" },
      { id: "10", label: "800", value: "800", name: "800" },
      { id: "11", label: "900", value: "900", name: "900" },
      { id: "12", label: "1000", value: "1000", name: "1000" },
    ];
    this.props.navigation.navigate("MultiSelectDialog", {
      selectionList: sizes,
      selectedItems: this.state.selectedImageSize,
      callBack: this.selectImageWidthCallBack,
      edit_type: "Image Size",
      title: "Image Size",
      selectText: "Select Image Size",
      mode: "uni",
    });
  };

  selectImageWidthCallBack = (sizes) => {
    //console.log(sizes);
    if (sizes.length > 0) this.setState({ imageSize: sizes[0].label });
  };

  orderPlaces = () => {
    //console.log("orderPlaces");
    const places = window.guide_obj.getGroupAndCategoryPlaces(
      this.state.PlaceIds,
      this.state.GroupNames,
      this.state.CategoryNames
    );
    let list = [];
    let id = 0;
    for (let i = 0; i < places.length; i++) {
      list.push({
        id: (id++).toString(),
        name: places[i].Name,
        label: places[i].Name,
        value: places[i].ID,
      });
    }
    //console.log(list);
    this.props.navigation.navigate("OrderList", {
      list_items: list,
      callBack: this.orderPlacesCallBack,
    });
  };

  orderPlacesCallBack = (Places) => {
    //console.log(Places);
    this.setState({ OrderedPlaces: Places });
  };

  getImageURL = () => {
    this.setState({
      PromptModalShow: true,
      PromptModalTitle: "Image URL",
      PromptModalMessage: "Enter Image URL",
      PromptModalButtons: [
        { Title: "OK", Method: this.changeImageURL },
        { Title: "CANCEL", Method: null },
      ],
      PromptModalInputs: [{ name: "Image URL", value: this.state.imageURL }],
    });
  };

  changeImageURL = (inputs) => {
    if (inputs === null) return;
    this.setState({ imageURL: inputs[0].value });
  };

  selectImage = () => {
    console.log("selectImage");
    this.setState({
      ImageDialogShow: true,
      mode: "select",
      size: "medium",
      ImageDialogMulti: true,
      updateImage: this.updateImageCallBack,
    });
  };

  updateImageCallBack = (imageInfo) => {
    //("updateImageCallBack", imageInfo);
    if (imageInfo.length === 0) return;
    if (imageInfo[0].url_m !== "") this.setState({ imageURL: imageInfo[0].url_m, imageURLs: imageInfo });
    else this.setState({ imageURL: imageInfo[0].url, imageURLs: imageInfo });
  };

  selectBackgroundImage = () => {
    //console.log("selectImage");
    this.setState({
      ImageDialogShow: true,
      mode: "select",
      size: "medium",
      ImageDialogMulti: false,
    });
  };

  updateBackgroundImageCallBack = (imageInfo) => {
    this.setState({ BackgroundimageURL: imageInfo[0].url_m });
  };

  getImageURLString = (str) => {
    const url = Common.getImageURL(str, null, true);
    //console.log(url);
    return url;
  };

  selectAudio = () => {
    //console.log("selectAudio");
  };

  selectVideo = () => {
    //console.log("selectVideo");
  };

  selectGuide = () => {
    //console.log("selectGuide");
  };

  // selectSection = () => {
  //   //console.log("selectSection");
  // };

  selectFontSize = () => {
    let fontSizes = [];
    for (let i = 16; i < 97; i += 4) {
      fontSizes.push({
        label: `${i.toString()}%`,
        name: i.toString(),
        value: i.toString(),
      });
    }
    this.MultiSelect(fontSizes, [this.state.FontSize], "uni", this.selectFontSizeCallBack, "Font Size");
  };

  selectFontSizeCallBack = (fontSize) => {
    this.setState({ FontSize: fontSize[0].value });
  };

  selectFontColor = () => {
    this.props.navigation.navigate("ColorDialog", {
      initial_color: this.state.FontColor,
      callBack: this.selectFontColorCallBack,
    });
  };

  selectFontColorCallBack = (FontColor) => {
    this.setState({ FontColor: FontColor });
  };
  selectBackgroundColor = () => {
    this.props.navigation.navigate("ColorDialog", {
      initial_color: this.state.BackgroundColor,
      callBack: this.selectBackgroundColorCallBack,
    });
  };

  selectBackgroundColorCallBack = (FontColor) => {
    this.setState({ BackgroundColor: FontColor });
  };

  selectDay = () => {
    const days = window.guide_obj.getChooseDatesList(this.state.ScheduleName);
    this.MultiSelect(
      days,
      [{ label: this.state.day, value: this.state.day }],
      "uni",
      this.selectDayCallBack,
      "Select day"
    );
  };

  selectDayCallBack = (days) => {
    //console.log("FilterDatesCallBack", days);
    if (days.length > 0) this.setState({ day: days[0].label });
  };

  imageSourceMode = (value) => {
    this.setState({ imageSource: value });
  };

  changeImageSize = (size) => {
    //console.log("changeImageSize", size);
    this.setState({ imageSize: size.value });
  };

  closeModal = () => {
    console.log("closeModal");
    this.props.onHide();
  };

  UpdateDisplay = () => {};

  linkTypeChange = (event) => {
    console.log("linkTypeChange", event.target.value);
    this.state.PickerItems.forEach((item) => {
      if (item.label === event.target.value) this.selectLinkTypeCallBack([item]);
    });
  };

  render() {
    //console.log(this.state.Places);
    const section = this.props.section;
    const text_color = `${this.state.FontColor}`;
    const text_background_color = `${this.state.BackgroundColor}`;
    //console.log("text_color", text_color);
    if (this.state.ShowMultiselect)
      return (
        <MultiSelectModal
          show={this.state.ShowMultiselect}
          onHide={() => this.setState({ ShowMultiselect: false })}
          MultiselectItems={this.state.MultiselectItems}
          MultiselectSelectedItems={this.state.MultiselectSelectedItems}
          MultiselectMode={this.state.MultiselectMode}
          MultiselectCallback={this.state.MultiselectCallback}
          MultiselectTitle={this.state.MultiselectTitle}
          MultiselectSearchText={this.state.MultiselectSearchText}
        />
      );

    if (this.state.ImageDialogShow)
      return (
        <ImageDialog
          show={this.state.ImageDialogShow}
          onHide={() => this.setState({ ImageDialogShow: false })}
          user_info={this.props.user_info}
          // callback={this.updateImageCallBack}
          mode={"select"}
          modify_only={"true"}
          updateImage={this.updateImageCallBack}
          size={"sm"}
          multi={this.state.ImageDialogMulti}
          exif={false}
        />
      );

    return (
      <div className="modal" style={{ zIndex: 65537 }}>
        <Modal
          {...this.props}
          size={window.innerWidth < 450 ? "lg" : "md"}
          dialogClassName="myModalClass"
          aria-labelledby="contained-modal-title-vcenter"
          // centered
          scrollable={true}
          // style={{ padding: "50px" }}
          onHide={this.closeModal}
        >
          <Modal.Header>
            <div style={{ width: "100%" }}>
              <div className="sort">
                <div className="dialog-heading">
                  <div className="dialog-heading">
                    <div>
                      <h4 style={{ color: "#3598DB" }}>HTML Insert</h4>
                    </div>
                    <span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <i className={`fa fa-check`} onClick={this.AddHTML}></i>
                    <div>
                      <i className={`fas fa-times`} onClick={this.closeModal}></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="form-item">
              <div>
                <MyToolTip
                  loc="left"
                  msg="Select the type of html that will be inserted into the page. Unlike a link, the HTML will be on the page and not link to another page. Note that links have the advantage of being dynamic and the content gets created based on the current state."
                >
                  <h5 className="color-blue">Select HTML Type</h5>
                </MyToolTip>
              </div>
              {/* <p>
                  <Button variant="primary" size="sm" onClick={this.selectLinkType}>
                    {`Type: ${this.state.insertType}`}
                  </Button>
                </p> */}
              <Form.Control as="select" custom onChange={this.linkTypeChange} defaultValue={this.state.insertType}>
                <option value="Audio">Audio</option>
                <option value="HTML">Custom</option>
                <option value="Image">Image</option>
                <option value="Itinerary">Itinerary</option>
                <option value="Map">Map</option>
                <option value="Map with Places List">Map with Places List</option>
                <option value="Places List">Places List</option>
                <option value="Section">Section</option>
                <option value="Video">Video</option>
              </Form.Control>
            </div>
            <br></br>
            {["Video", "Audio"].includes(this.state.insertType) ? (
              <div className="form-item">
                <div>
                  <h5 className="color-blue">Enter URL</h5>
                </div>
                <div style={{ width: "300px" }}>
                  <textarea
                    rows={8}
                    onChange={this.handleChangeMediaURL}
                    placeholder="Enter URL"
                    //value={this.state.url}
                    style={{ width: "100%", height: "100%", fontSize: "16px" }}
                  />
                </div>
              </div>
            ) : null}
            {["HTML"].includes(this.state.insertType) ? (
              <div className="form-item">
                <div>
                  <h5 className="color-blue">Enter HTML</h5>
                </div>
                <div style={{ width: "300px" }}>
                  <textarea
                    rows={8}
                    onChange={this.handleChangeHTML}
                    placeholder="Enter URL"
                    //value={this.state.url}
                    style={{ width: "100%", height: "100%", fontSize: "16px" }}
                  />
                </div>
              </div>
            ) : null}
            {["Image"].includes(this.state.insertType) ? (
              <div className="form-item">
                <div>
                  <h5 className="color-blue">Select Image</h5>
                </div>
                <Button className="form-item" variant="primary" size="sm" onClick={() => this.selectImage()}>
                  Select Image
                </Button>
                <span> or </span>
                <Button className="form-item" variant="primary" size="sm" onClick={() => this.getImageURL()}>
                  Enter Image URL
                </Button>

                <div className="image">
                  <img
                    src={this.state.imageURL}
                    alt=""
                    className="text-center rounded"
                    // height="100px"
                    width="150px"
                    // overflow="hidden"
                    style={{ objectFit: "cover", maxHeight: "100px" }}
                  />
                </div>
                {/* <p>{this.state.imageURL}</p> */}
              </div>
            ) : null}
            {/* {["Itinerary"].includes(this.state.insertType) ? (
                <div className="form-item">
                  <Button
                    className="form-item"
                    className="form-item"
                    variant="primary"
                    size="sm"
                    onClick={() => this.selectItinerary("uni")}
                  >
                    Select Schedule
                  </Button>
                  <p>{this.state.itineraryName}</p>
                </div>
              ) : null} */}
            {["Itinerary"].includes(this.state.insertType) ||
            (["Map", "Map with Places List", "Places List"].includes(this.state.insertType) &&
              this.state.ScheduleFilter) ? (
              <div className="form-item">
                <div>
                  <h5 className="color-blue">Select Schedule</h5>
                </div>
                <Button className="form-item" variant="primary" size="sm" onClick={() => this.selectItinerary("uni")}>
                  Select Schedule
                </Button>
                <p>{this.state.itineraryName}</p>
              </div>
            ) : null}
            {["Section"].includes(this.state.insertType) ? (
              <div className="form-item">
                <div>
                  <h5 className="color-blue">Select Section</h5>
                </div>
                <Button className="form-item" variant="primary" size="sm" onClick={() => this.selectSection()}>
                  Select Section
                </Button>
                <p>{this.state.sectionName}</p>
              </div>
            ) : null}

            {["Map", "Map with Places List", "Places List"].includes(this.state.insertType) && this.state.DayFilter ? (
              <div className="form-item">
                <div>
                  <h5 className="color-blue">Select Days</h5>
                </div>
                <Button className="form-item" variant="primary" size="sm" onClick={() => this.selectDay()}>
                  Select Days
                </Button>
                <p>{this.state.day}</p>
              </div>
            ) : null}
            {["Map", "Map with Places List", "Places List"].includes(this.state.insertType) ? (
              <div className="form-item">
                <div>
                  <h5 className="color-blue">Select Places</h5>
                </div>
                <Button className="form-item" variant="primary" size="sm" onClick={() => this.selectPlaces()}>
                  Select Places
                </Button>
                <div>
                  <h5 className="color-blue">Select Groups</h5>
                </div>
                <p>{this.displayValueList(this.state.Places)}</p>
                <Button className="form-item" variant="primary" size="sm" onClick={() => this.selectGroups("multi")}>
                  Select Groups
                </Button>
                <p>{`${this.displayValueList(this.state.Groups)}`}</p>
                <div>
                  <h5 className="color-blue">Select Categories</h5>
                </div>
                <Button
                  className="form-item"
                  variant="primary"
                  size="sm"
                  onClick={() => this.selectCategories("multi")}
                >
                  Select Categories
                </Button>
                <p>{`${this.displayValueList(this.state.Categories)}`}</p>
                {/* <div className="multiselect-list">
                    {this.state.FilterOptions.map((element, index) => (
                      <div className="multiselect-item" key={index}>
                        <Form.Check
                          type="checkbox"
                          id="autoSizingCheck2"
                          checked={element.selected}
                          onChange={() => this.FilterOptionSelected(index)}
                        />
                        <div className="country">
                          <p>{element.name}</p>
                        </div>
                      </div>
                    ))}
                  </div> */}
              </div>
            ) : null}
          </Modal.Body>
        </Modal>
        <AlertModal
          show={this.state.AlertModalShow}
          onHide={() => this.setState({ AlertModalShow: false })}
          AlertModalTitle={this.state.AlertModalTitle}
          AlertModalMessage={this.state.AlertModalMessage}
          AlertModalButtons={this.state.AlertModalButtons}
        />

        <SectionsDialog
          show={this.state.SectionsDialogShow}
          onHide={() => this.setState({ SectionsDialogShow: false })}
          update_display={this.UpdateDisplay}
          mode={"select"}
          callback={this.selectSectionCallback}
        />
        <PromptModal
          show={this.state.PromptModalShow}
          onHide={() => this.setState({ PromptModalShow: false })}
          PromptModalTitle={this.state.PromptModalTitle}
          PromptModalMessage={this.state.PromptModalMessage}
          PromptModalButtons={this.state.PromptModalButtons}
          PromptModalInputs={this.state.PromptModalInputs}
          area={true}
        />
      </div>
    );
  }
}
export default HTMLInserDialog;
