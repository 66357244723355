import Place, { Place_Source } from "./Place";

export default class GoogleTranslate {
  // constructor() {
  // }

  //https://translation.googleapis.com/language/translate/v2?key=AIzaSyD86bkyL-hGojPrpGIRW2aCivHsKgiGaGs&q=cheese&target=es
  static translate = (text, target, callback, obj, type, last) => {
    if (typeof text === "undefined" || text === null) return;
    const orig_text = text.replace(/ /g, "%20");
    var url = `https://translation.googleapis.com/language/translate/v2?key=AIzaSyD86bkyL-hGojPrpGIRW2aCivHsKgiGaGs&q=${orig_text}&target=${target}`;
    //const url = `https://andiamotravelapp.com/api/wikimedia_get_images.php?gsrsearch=${gsrsearch}`;
    fetch(url)
      .then(function (response) {
        return response.json();
      })
      .then(function (response) {
        //console.log("response", JSON.stringify(response));
        callback(response, obj, type, last);
      })

      .catch(function (error) {
        console.log("Error in Google translate", error);
        callback([]);
      });
  };
}
