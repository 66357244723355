import { connect } from "react-redux";
import React, { Component, useState, useEffect } from "react";
import { Modal, Form, Button, Spinner } from "react-bootstrap";
import "./Modal.css";
import PromptModal from "./PromptModal";
import AlertModal from "./AlertModal";
import MultiSelectModal from "./MultiSelectModal";
import IconsDialog from "./IconsDialog";
import Place from "../../Models/Place";
import { languages, googleLanguages } from "../../Data/Languages";
import GoogleTranslate from "../../Models/GoogleTranslate";
import { useDispatch } from "react-redux";

import User from "../../Models/User";

class GuideTranslate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visiblePrompt: false,
      Users: this.props.users,
      uidText: "",
      selectedLanguage: "English",
      selectedLanguageId: "en",
      schedules: true,
      event_subject: false,
      places: true,
      home_screen: true,
      sections: true,
      place_name: false,
      activityIndicator: false,

      PromptModalShow: false,
      PromptModalTitle: "Enter Value",
      PromptModalMessage: "",
      PromptModalButtons: [],
      PromptModalInputs: [],

      AlertModalShow: false,
      AlertModalTitle: "",
      AlertModalMessage: "",
      AlertModalButtons: [],

      MultiselectItems: [],
      MultiselectSelectedItems: [],
      MultiselectMode: "uni",
      MultiselectCallback: null,
      MultiselectTitle: "Select",
      MultiselectSearchText: "",
      ShowMultiselect: false,
    };
  }

  componentDidMount() {}

  MyAlert = (title, message, buttons) => {
    this.setState({
      AlertModalTitle: title,
      AlertModalMessage: message,
      AlertModalButtons: buttons,
      AlertModalShow: true,
    });
  };

  uidChanged = (event) => {
    this.setState({ uidText: event.target.value });
  };

  selectLanguage = () => {
    let _languages = [];
    const languages_sorted = googleLanguages.sort((a, b) => (a.language > b.language ? 1 : -1));
    let id = 1;
    languages_sorted.forEach((language) =>
      _languages.push({
        label: language.language,
        name: language.language,
        value: language.id,
        id: (id++).toString(),
      })
    );
    this.MultiSelect(_languages, [], "uni", this.selectedLanguage, "Select Language");
  };

  selectedLanguage = (languages) => {
    let language = "English";
    let language_id = "en";
    if (languages.length > 0) {
      language = languages[0].label;
      language_id = languages[0].value;
    }
    this.setState({ selectedLanguage: language, selectedLanguageId: language_id });
  };

  translate = () => {
    console.log("translate start", this.state.schedules, this.state.selectedLanguageId);
    this.setState({ activityIndicator: true });
    if (this.state.schedules) {
      for (let i = 0; i < window.guide_obj.schedule.events.length; i++) {
        let event = window.guide_obj.schedule.events[i];
        if (typeof event.Body === "undefined" || event.Body === null || event.Body === "") continue;
        console.log("translate:", event.Body, this.state.selectedLanguageId);
        GoogleTranslate.translate(
          event.Body,
          this.state.selectedLanguageId,
          this.translateCallback,
          event,
          "event",
          false
        );
      }
    }
    if (this.state.event_subject) {
      for (let i = 0; i < window.guide_obj.schedule.events.length; i++) {
        let event = window.guide_obj.schedule.events[i];
        if (typeof event.Subject === "undefined" || event.Subject === null || event.Subject === "") continue;
        console.log("translate event_subject:", event.Subject, this.state.selectedLanguageId);
        GoogleTranslate.translate(
          event.Subject,
          this.state.selectedLanguageId,
          this.translateCallback,
          event,
          "event_subject",
          false
        );
      }
    }
    if (this.state.places) {
      for (let i = 0; i < window.guide_obj.places.placesList.length; i++) {
        const place = window.guide_obj.places.placesList[i];
        if (typeof place.Info === "undefined" || place.Info === null || place.Info === "") continue;
        GoogleTranslate.translate(
          place.Info,
          this.state.selectedLanguageId,
          this.translateCallback,
          place,
          "place",
          false
        );
      }
    }
    if (this.state.place_name) {
      for (let i = 0; i < window.guide_obj.places.placesList.length; i++) {
        const place = window.guide_obj.places.placesList[i];
        if (typeof place.Name === "undefined" || place.Name === null || place.Name === "") continue;
        GoogleTranslate.translate(
          place.Name,
          this.state.selectedLanguageId,
          this.translateCallback,
          place,
          "place_name",
          false
        );
      }
    }
    if (this.state.sections) {
      for (let i = 0; i < window.guide_obj.chapters.length; i++) {
        const section = window.guide_obj.chapters[i];
        if (typeof section.html === "undefined" || section.html === null || section.html === "") continue;
        GoogleTranslate.translate(
          section.html,
          this.state.selectedLanguageId,
          this.translateCallback,
          section,
          "section",
          false
        );
      }
    }
    if (this.state.home_screen) {
      if (
        typeof window.guide_obj.document !== "undefined" &&
        window.guide_obj.document !== null &&
        window.guide_obj.document !== ""
      )
        GoogleTranslate.translate(
          window.guide_obj.document,
          this.state.selectedLanguageId,
          this.translateCallback,
          null,
          "home_screen",
          true
        );
    }
    setTimeout(() => this.finish(), 7000);
  };
  translateCallback = (result, obj, type, last) => {
    try {
      if (typeof result.data.translations === "undefined") return;
      console.log("translateCallback:", result.data.translations[0].translatedText);
      if (type === "event") obj.Body = result.data.translations[0].translatedText;
      if (type === "event_subject") obj.Subject = result.data.translations[0].translatedText;
      if (type === "place") obj.Info = result.data.translations[0].translatedText;
      if (type === "place_name") obj.Name = result.data.translations[0].translatedText;
      if (type === "section") obj.html = result.data.translations[0].translatedText;
      if (type === "home_screen") window.guide_obj.document = result.data.translations[0].translatedText;
    } catch (e) {
      console.log("translateCallback: Error in translation");
    }
  };

  finish = () => {
    let guide_attributes = JSON.parse(JSON.stringify(window.guide_obj.attributes));
    guide_attributes.language = this.state.selectedLanguage;
    window.guide_obj.attributes.modifyGuideAttributes(guide_attributes);
    this.setState({ activityIndicator: false });
    this.props.update_ui();
    this.props.onHide();
  };

  MultiSelect = (Items, SelectedItems, Mode, Callback, Title) => {
    //console.log("MultiSelect");
    this.setState({
      MultiselectItems: Items,
      MultiselectSelectedItems: SelectedItems,
      MultiselectMode: Mode,
      MultiselectCallback: Callback,
      MultiselectTitle: Title,
      MultiselectSearchText: "",
      ShowMultiselect: true,
    });
  };

  render() {
    if (this.state.ShowMultiselect)
      return (
        <MultiSelectModal
          show={this.state.ShowMultiselect}
          onHide={() => this.setState({ ShowMultiselect: false })}
          MultiselectItems={this.state.MultiselectItems}
          MultiselectSelectedItems={this.state.MultiselectSelectedItems}
          MultiselectMode={this.state.MultiselectMode}
          MultiselectCallback={this.state.MultiselectCallback}
          MultiselectTitle={this.state.MultiselectTitle}
          MultiselectSearchText={this.state.MultiselectSearchText}
        />
      );
    return (
      <div className="modal">
        <Modal
          {...this.props}
          dialogClassName="myModalClass"
          aria-labelledby="contained-modal-title-vcenter"
          centered={false}
          scrollable={false}
        >
          <Modal.Header closeButton>
            <div style={{ width: "100%" }}>
              <div className="sort">
                <div className="dialog-heading">
                  <div>
                    <h4 className="color-blue">Translate Guide</h4>
                  </div>
                </div>
                {this.state.activityIndicator ? (
                  <div className="loading">
                    <Spinner animation="border" variant="primary" />
                  </div>
                ) : null}
                {/* <div className="dialog-heading">
                  <input type="text" placeholder="Enter User ID" onChange={this.uidChanged} />
                  <i className={`fa fa-plus`} onClick={() => this.addUser(true, null)}></i>
                  <i className="fas fa-check" onClick={() => this.returnUsers()}></i>
                </div> */}
                {/* <Modal.Title id="example-modal-sizes-title-sm">{MultiselectTitle}</Modal.Title> */}
              </div>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="multiselect-list">
              {/* <span className="attribute">Schedules&nbsp;&nbsp;&nbsp;</span> */}
              <Form.Check
                label="Event Info"
                type="checkbox"
                id="autoSizingCheck2"
                checked={this.state.schedules}
                onChange={() => this.setState({ schedules: !this.state.schedules })}
              />
              <Form.Check
                label="Event Subject"
                type="checkbox"
                id="autoSizingCheck2"
                checked={this.state.event_subject}
                onChange={() => this.setState({ event_subject: !this.state.event_subject })}
              />
              <Form.Check
                label="Place Info"
                type="checkbox"
                id="autoSizingCheck2"
                checked={this.state.places}
                onChange={() => this.setState({ places: !this.state.places })}
              />
              <Form.Check
                label="Place Name"
                type="checkbox"
                id="autoSizingCheck2"
                checked={this.state.place_name}
                onChange={() => this.setState({ place_name: !this.state.place_name })}
              />
              <Form.Check
                label="Home Screen"
                type="checkbox"
                id="autoSizingCheck2"
                checked={this.state.home_screen}
                onChange={() => this.setState({ home_screen: !this.state.home_screen })}
              />
              <Form.Check
                label="Sections"
                type="checkbox"
                id="autoSizingCheck2"
                checked={this.state.sections}
                onChange={() => this.setState({ sections: !this.state.sections })}
              />
              <Button className="mt-3" onClick={() => this.selectLanguage()}>
                Language
              </Button>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span className="attribute">{this.state.selectedLanguage}</span>
              <p>
                <center>
                  <Button className="mt-3" onClick={() => this.translate()}>
                    Translate
                  </Button>
                </center>
              </p>
            </div>
          </Modal.Body>
        </Modal>
        <PromptModal
          show={this.state.PromptModalShow}
          onHide={() => this.setState({ PromptModalShow: false })}
          PromptModalTitle={this.state.PromptModalTitle}
          PromptModalMessage={this.state.PromptModalMessage}
          PromptModalButtons={this.state.PromptModalButtons}
          PromptModalInputs={this.state.PromptModalInputs}
        />
        <AlertModal
          show={this.state.AlertModalShow}
          onHide={() => this.setState({ AlertModalShow: false })}
          AlertModalTitle={this.state.AlertModalTitle}
          AlertModalMessage={this.state.AlertModalMessage}
          AlertModalButtons={this.state.AlertModalButtons}
        />
      </div>
    );
  }
}

export default GuideTranslate;
