import React, { useState } from "react";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import "./Modal.css";
import ScheduleModal from "./ScheduleModal";
import EventEdit from "./EventEdit";
import AlertModal from "./AlertModal";
import MoveEvents from "../modals/MoveEvents";

import { useDispatch } from "react-redux";
import { slice1Actions } from "../../store/reducer";
import { useSelector } from "react-redux";
import { DateTime, Settings } from "luxon";

const EventModal = (props) => {
  const [scheduleModal, setScheduleModal] = useState(false);
  const [ShowEventEdit, setShowEventEdit] = useState(false);
  const [EventEditMode, setShowEventMode] = useState("MODIFY");
  const [EventEditWhere, setShowEventWhere] = useState("after");
  const [AlertModalShow, setAlertModalShow] = useState(false);
  const [AlertModalTitle, setAlertModalTitle] = useState("");
  const [AlertModalMessage, setAlertModalMessage] = useState("");
  const [AlertModalButtons, setAlertModalButtons] = useState([]);
  const [Mode, setMode] = useState("shift_events");
  const [ParamDate, setParamDate] = useState(null);
  const [MoveEventsShow, setMoveEventsShow] = useState(false);

  const dispatch = useDispatch();
  let ScheduleName = useSelector((state) => state.ScheduleName);
  let ScheduleNames = useSelector((state) => state.ScheduleNames);

  let event = useSelector((state) => state.SelectedEvent);
  let MobileMode = useSelector((state) => state.MobileMode);
  let GuideEditMode = useSelector((state) => state.GuideEditMode);
  let GuideSettings = useSelector((state) => state.GuideSettings);

  function eventContainsPlaces() {
    if (event == null) return true;
    return event.get_places(window.guide_obj.places).length > 0;
  }

  function handleShowOnMap() {
    const places = event.get_places(window.guide_obj.places);
    if (places.length === 0) {
      myAlert("No places in event", "Info");
      props.onHide();
      return;
    }
    //if (places.length === 1) {
    dispatch(slice1Actions.ClearAllFilters());
    dispatch(slice1Actions.setShowHiddenPlace({ value: places }));
    dispatch(slice1Actions.centerMapToPlaces({ value: places }));
    dispatch(slice1Actions.setGuide({ guide: null }));
    //dispatch(slice1Actions.setMapCenterPlace({ place: places[0] }));
    // } else {
    //   dispatch(slice1Actions.ClearAllFilters());
    //   if (places.length > 0) dispatch(slice1Actions.SetPlacesFilter({ value: places }));
    //   dispatch(slice1Actions.setGuide({ guide: null }));
    // }
    window.location.href = "#map-start";
    props.onHide();
  }

  function FilterPlaces() {
    const places = event.get_places(window.guide_obj.places);
    if (places.length === 0) {
      myAlert("No places in event", "Info");
      props.onHide();
      return;
    }
    dispatch(slice1Actions.ClearAllFilters());
    dispatch(slice1Actions.setShowHiddenPlace({ value: places }));
    dispatch(slice1Actions.SetPlacesFilter({ value: places }));
    dispatch(slice1Actions.setGuide({ guide: null, fitToBounds: true }));
    window.location.href = "#map-start";
    props.onHide();
  }

  function handleShowDetails() {
    const htmlString = event.createHTML(window.guide_obj.places);

    dispatch(slice1Actions.setHTMLTitle({ value: "Event Details" }));
    dispatch(slice1Actions.setHTMLToSHow({ value: htmlString }));
    dispatch(slice1Actions.setShowHTMLReader({ value: true }));
    window.location.href = "#home-start";
    var homeDiv = document.getElementById("home-content-start");
    if (MobileMode) dispatch(slice1Actions.setCurrentAppScreen({ value: "Home" }));
    else homeDiv.scrollIntoView();
    props.onHide();
  }

  function handleEditEvent() {
    setShowEventMode("MODIFY");
    setShowEventWhere("after");
    setShowEventEdit(true);
    props.onHide();
  }

  function handleDeleteEvent() {
    myConfirm("This will delete the event. Continue?", [
      { Title: "OK", Method: () => _handleDeleteEvent() },
      { Title: "CANCEL", Method: null },
    ]);
    props.onHide();
  }

  const myConfirm = (message, buttons) => {
    setAlertModalTitle("Confirmation");
    setAlertModalMessage(message);
    setAlertModalButtons(buttons);
    setAlertModalShow(true);
  };

  function _handleDeleteEvent() {
    window.guide_obj.schedule.deleteEvent(event);
    dispatch(slice1Actions.setGuide({ guide: null }));
    props.onHide();
  }

  function handleAddEventA() {
    setShowEventMode("ADD");
    setShowEventWhere("after");
    setShowEventEdit(true);
    props.onHide();
  }

  function handleAddEventB() {
    setShowEventMode("ADD");
    setShowEventWhere("before");
    setShowEventEdit(true);
    props.onHide();
  }

  const EventEditCallback = () => {
    dispatch(slice1Actions.resetHomeScreen({ value: null }));
    dispatch(slice1Actions.setGuide({ guide: null }));
  };

  const myAlert = (message, title, buttons) => {
    setAlertModalMessage(message);
    setAlertModalTitle(title == null ? "Message" : title);
    setAlertModalButtons(buttons == null ? [{ Title: "OK", Method: null }] : buttons);
    setAlertModalShow(true);
  };

  const displayMenuInfo = (menu_item_info) => {
    myAlert(menu_item_info, "Info");
  };

  // const combineEventBefore = () => {
  //   window.guide_obj.schedule.combineWithPreviousEvent(this._event, ScheduleName);
  //   this.props.onGetScheduleToDisplay(this.props.guide_id);
  // };

  const combineEvents = (action) => {
    let items = window.guide_obj.schedule.getEventsToDisplay(ScheduleName);
    dispatch(slice1Actions.setMultiselectCallback({ value: combineEventsCallBack }));
    dispatch(slice1Actions.setMultiselectItems({ value: items }));
    dispatch(slice1Actions.setMultiselectSelectedItems({ value: [] }));
    dispatch(slice1Actions.setMultiselectMode({ value: "multi" }));
    dispatch(slice1Actions.setMultiselectSearchText({ value: "" }));
    dispatch(slice1Actions.setMultiselectTitle({ value: "Select Events" }));
    dispatch(slice1Actions.setShowMultiselect({ value: true }));
  };

  const combineEventsCallBack = (events) => {
    if (events.length === 0) return;
    console.log("combineEventsCallBack", events);
    window.guide_obj.schedule.combineWithEvents(event, events);
    dispatch(slice1Actions.setGuide({ guide: null }));
    updateMainScheduleAndDoc();
  };

  const updateMainScheduleAndDoc = () => {
    myAlert("Update Main Schedule and Guide document with these changes?", "Update", [
      { Title: "OK", Method: _updateMainScheduleAndDoc },
      { Title: "CANCEL", Method: () => props.onHide() },
    ]);
  };
  const ShiftEvents = () => {
    setMode("shift_events");
    //Common.LuxonDateFromJSDate(event.Start)
    setParamDate(DateTime.fromISO(event.Start, { zone: "utc" }));
    setMoveEventsShow(true);
  };

  const MoveEventsCallback = () => {
    dispatch(slice1Actions.setGuide({ guide: null }));
    props.onHide();
  };

  const moveThisAndSubsequentDays = () => {};
  const _updateMainScheduleAndDoc = () => {
    if (ScheduleName !== "Main") window.guide_obj.schedule.copySchedule(ScheduleName, "Main");
    const html = window.guide_obj.createHTMLItinerary(ScheduleName, GuideSettings.itinerary);
    //window.guide_obj.document = html;
    //dispatch(slice1Actions.setGuide({ guide: null }));

    window.guide_obj.document = html;
    dispatch(slice1Actions.clearHTMLStack());
    //dispatch(slice1Actions.popHTMLStack());
    dispatch(slice1Actions.setHomeScreen({ value: html }));
    props.onHide();
  };

  if (ShowEventEdit)
    return (
      <EventEdit
        show={ShowEventEdit}
        onHide={() => setShowEventEdit(false)}
        event={event}
        mode={EventEditMode}
        place={null}
        where={EventEditWhere}
        schedule_name={ScheduleName}
        callback={() => EventEditCallback()}
      />
    );
  else
    return (
      <div className="modal">
        <Modal
          {...props}
          dialogClassName="myModalClass"
          aria-labelledby="contained-modal-title-vcenter"
          scrollable={true}
          centered
          // style={{ padding: "50px" }}
        >
          <Modal.Body>
            <div className="sort">
              <div className="flex-container-middle">
                <h4 className="color-blue">
                  <i className="far fa-calendar-alt color-blue" onClick={() => setScheduleModal(true)}></i> Event Menu
                </h4>
                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                <i title="Close" className={`fas fa-times color-black`} onClick={props.onHide}></i>
              </div>
              <div className="multiselect-list">
                {eventContainsPlaces ? (
                  <div key={`container`} className="flex-container">
                    <div className="flex-items-left" key={`left`}>
                      <p onClick={handleShowOnMap}>Center in Map</p>
                    </div>
                    <div className="flex-items-right flex-container-right" key={`r1`}>
                      <OverlayTrigger
                        // zIndex="9999"
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={
                          <Tooltip id="button-tooltip-2">{"Centers the places from the event on the map"}</Tooltip>
                        }
                      >
                        <i
                          // title="Info"
                          className={`fa fa-info`}
                          style={{ color: "#3598DB" }}
                          onClick={() => displayMenuInfo("Centers the places from the event on the map")}
                        ></i>
                      </OverlayTrigger>
                    </div>
                  </div>
                ) : null}
                {eventContainsPlaces ? (
                  <div key={`container`} className="flex-container">
                    <div className="flex-items-left" key={`left`}>
                      <p onClick={FilterPlaces}>Filter places in event</p>
                    </div>
                    <div className="flex-items-right flex-container-right" key={`r1`}>
                      <OverlayTrigger
                        // zIndex="9999"
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={
                          <Tooltip id="button-tooltip-2">{"Filters to show only the places from the event"}</Tooltip>
                        }
                      >
                        <i
                          // title="Info"
                          className={`fa fa-info`}
                          style={{ color: "#3598DB" }}
                          onClick={() => displayMenuInfo("Filters to show only the places from the event")}
                        ></i>
                      </OverlayTrigger>
                    </div>
                  </div>
                ) : null}

                <div key={`container`} className="flex-container">
                  <div className="flex-items-left" key={`left`}>
                    <p onClick={handleShowDetails}>Show Details</p>
                  </div>
                  <div className="flex-items-right flex-container-right" key={`r1`}>
                    <OverlayTrigger
                      // zIndex="9999"
                      placement="right"
                      delay={{ show: 250, hide: 400 }}
                      overlay={
                        <Tooltip id="button-tooltip-2">
                          {"Show the event details in document foramt. i.e. HTML"}
                        </Tooltip>
                      }
                    >
                      <i
                        // title="Info"
                        className={`fa fa-info`}
                        style={{ color: "#3598DB" }}
                        onClick={() => displayMenuInfo("Show the event details in document format. i.e. HTML")}
                      ></i>
                    </OverlayTrigger>
                  </div>
                </div>
                {GuideEditMode ? (
                  <div key={`container`} className="flex-container">
                    <div className="flex-items-left" key={`left`}>
                      <p onClick={ShiftEvents}>Shift Events</p>
                    </div>
                    <div className="flex-items-right flex-container-right" key={`r1`}>
                      <OverlayTrigger
                        // zIndex="9999"
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={<Tooltip id="button-tooltip-2">{"Shift this and subsequent events"}</Tooltip>}
                      >
                        <i
                          // title="Info"
                          className={`fa fa-info`}
                          style={{ color: "#3598DB" }}
                          onClick={() => displayMenuInfo("Shift this and subsequent events")}
                        ></i>
                      </OverlayTrigger>
                    </div>
                  </div>
                ) : null}
                {GuideEditMode ? (
                  <div key={`container`} className="flex-container">
                    <div className="flex-items-left" key={`left`}>
                      <p onClick={handleEditEvent}>Edit Event</p>
                    </div>
                    <div className="flex-items-right flex-container-right" key={`r1`}>
                      <OverlayTrigger
                        // zIndex="9999"
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={
                          <Tooltip id="button-tooltip-2">
                            {"Edit the event details i.e. subject, times, info and places"}
                          </Tooltip>
                        }
                      >
                        <i
                          // title="Info"
                          className={`fa fa-info`}
                          style={{ color: "#3598DB" }}
                          onClick={() => displayMenuInfo("Edit the event details i.e. subject, times, info and places")}
                        ></i>
                      </OverlayTrigger>
                    </div>
                  </div>
                ) : null}

                {GuideEditMode ? (
                  <div key={`container`} className="flex-container">
                    <div className="flex-items-left" key={`left`}>
                      <p onClick={handleDeleteEvent}>Delete Event</p>
                    </div>
                    <div className="flex-items-right flex-container-right" key={`r1`}>
                      <OverlayTrigger
                        // zIndex="9999"
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={
                          <Tooltip id="button-tooltip-2">
                            {"Delete the event from the schedule. Note you cannot recover it."}
                          </Tooltip>
                        }
                      >
                        <i
                          // title="Info"
                          className={`fa fa-info`}
                          style={{ color: "#3598DB" }}
                          onClick={() =>
                            displayMenuInfo("Delete the event from the schedule. Note you cannot recover it.")
                          }
                        ></i>
                      </OverlayTrigger>
                    </div>
                  </div>
                ) : null}

                {GuideEditMode ? (
                  <div key={`container`} className="flex-container">
                    <div className="flex-items-left" key={`left`}>
                      <p onClick={handleAddEventA}>Add Event After</p>
                    </div>
                    <div className="flex-items-right flex-container-right" key={`r1`}>
                      <OverlayTrigger
                        // zIndex="9999"
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={<Tooltip id="button-tooltip-2">{"Add a new event after this event"}</Tooltip>}
                      >
                        <i
                          // title="Info"
                          className={`fa fa-info`}
                          style={{ color: "#3598DB" }}
                          onClick={() => displayMenuInfo("Add a new event after this event")}
                        ></i>
                      </OverlayTrigger>
                    </div>
                  </div>
                ) : null}

                {GuideEditMode ? (
                  <div key={`container`} className="flex-container">
                    <div className="flex-items-left" key={`left`}>
                      <p onClick={handleAddEventB}>Add Event Before</p>
                    </div>
                    <div className="flex-items-right flex-container-right" key={`r1`}>
                      <OverlayTrigger
                        // zIndex="9999"
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={<Tooltip id="button-tooltip-2">{"Add a new event before this event"}</Tooltip>}
                      >
                        <i
                          // title="Info"
                          className={`fa fa-info`}
                          style={{ color: "#3598DB" }}
                          onClick={() => displayMenuInfo("Add a new event before this event")}
                        ></i>
                      </OverlayTrigger>
                    </div>
                  </div>
                ) : null}
                {GuideEditMode ? (
                  <div key={`container`} className="flex-container">
                    <div className="flex-items-left" key={`left`}>
                      <p onClick={combineEvents}>Combine Events</p>
                    </div>
                    <div className="flex-items-right flex-container-right" key={`r1`}>
                      <OverlayTrigger
                        // zIndex="9999"
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={
                          <Tooltip id="button-tooltip-2">{"Combine places and info from multiple events"}</Tooltip>
                        }
                      >
                        <i
                          // title="Info"
                          className={`fa fa-info`}
                          style={{ color: "#3598DB" }}
                          onClick={() => displayMenuInfo("Combine places and info from multiple events")}
                        ></i>
                      </OverlayTrigger>
                    </div>
                  </div>
                ) : null}

                {/* <p>Edit Event</p>
            <p>Delete Event</p>
            <p>Add Event After</p>
            <p>Add Event Before</p> */}
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <ScheduleModal show={scheduleModal} onHide={() => setScheduleModal(false)} />
        {MoveEventsShow ? (
          <MoveEvents
            show={MoveEventsShow}
            onHide={() => setMoveEventsShow(false)}
            ScheduleName={ScheduleName}
            ScheduleNames={ScheduleNames}
            mode={Mode}
            param_date={ParamDate}
            callback={MoveEventsCallback}
          />
        ) : null}
        <AlertModal
          show={AlertModalShow}
          onHide={() => setAlertModalShow(false)}
          AlertModalTitle={AlertModalTitle}
          AlertModalMessage={AlertModalMessage}
          AlertModalButtons={AlertModalButtons}
        />
      </div>
    );
};

export default EventModal;
