import React from "react";
import { Modal, Button } from "react-bootstrap";
import "./Modal.css";
import { useDispatch } from "react-redux";
import { slice1Actions } from "../../store/reducer";
import { useSelector } from "react-redux";

const AlertModal = (props) => {
  const dispatch = useDispatch();

  const handleButtonClick = (button) => {
    if (button.Method != null) button.Method(button.Title);
    onHide();
  };

  let AlertModalTitle = props.AlertModalTitle;
  let AlertModalMessage = props.AlertModalMessage;
  let onHide = props.onHide;
  let AlertModalButtons = props.AlertModalButtons;

  if (typeof props.attributes != "undefined") {
    AlertModalTitle = props.attributes.AlertModalTitle;
    AlertModalMessage = props.attributes.AlertModalMessage;
    onHide = props.attributes.onHide;
    AlertModalButtons = props.attributes.AlertModalButtons;
  }
  const closeModal = () => {
    onHide();
  };

  return (
    <div className="modal">
      <Modal
        {...props}
        animation={false}
        dialogClassName="myModalClass"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        // style={{ padding: "50px" }}
        onHide={() => closeModal()}
      >
        <Modal.Body>
          <div key="title" className="sort color-blue">
            <h4 key="title-heading">{AlertModalTitle}</h4>
            {/* <h6 style={{ color: "black" }}>
              {props.message}
            </h6> */}

            <p key="message">{AlertModalMessage}</p>
            {AlertModalButtons.map((button, i) => (
              <Button
                key={`button${i}`}
                variant="primary"
                style={{ width: "100%" }}
                className="my-2"
                onClick={() => handleButtonClick(button)}
              >
                {button.Title}
              </Button>
            ))}
            {/* <p>Change Start date</p>
            <p>Move one day</p>
            <p>Add one day</p>
            <p>Delete Schedule</p>
            <p>Rename Schedule</p>
            <p>Edit Schedule</p> */}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AlertModal;
