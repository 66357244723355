import { DateTime } from "luxon";

export default class Review {
  constructor(rating, comment, user_info) {
    this.rating = rating;
    this.comment = comment;
    this.user_id = user_info.uid;
    this.user_name = `${user_info.first_name} ${user_info.last_name}`;
    this.date = DateTime.now().toISO({ zone: "utc" });
  }
}
