import { connect } from "react-redux";
import React, { Component, useState, useEffect } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import AlertModal from "./AlertModal";
import "./Modal.css";
import PromptModal from "./PromptModal";
import MultiSelectModal from "./MultiSelectModal";
import IconsDialog from "./IconsDialog";
import Place from "../../Models/Place";
import DataBase from "../../dataBase/liquidGuides";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { slice1Actions } from "../../store/reducer";

const GuideSettingsDialog = (props) => {
  const dispatch = useDispatch();
  let _GuideSettings = useSelector((state) => state.GuideSettings);
  const [GuideSettings, setGuideSettings] = useState(_GuideSettings);

  const saveSettings = () => {
    dispatch(slice1Actions.setGuideSettings({ value: GuideSettings }));
    props.onHide();
  };

  const MyAlert = (message) => {
    this.setState({
      AlertModalTitle: "Message",
      AlertModalMessage: message,
      AlertModalButtons: [{ Title: "OK", Method: null }],
      AlertModalShow: true,
    });
  };

  //console.log("this.state.edit_mode", this.state.edit_mode);
  // if (this.state.AlertModalShow)
  //   return (
  //     <AlertModal
  //       show={this.state.AlertModalShow}
  //       onHide={() => this.setState({ AlertModalShow: false })}
  //       AlertModalTitle={this.state.AlertModalTitle}
  //       AlertModalMessage={this.state.AlertModalMessage}
  //       AlertModalButtons={this.state.AlertModalButtons}
  //     />
  //   );
  let GuideSettings_copy = JSON.parse(JSON.stringify(GuideSettings));
  return (
    <div className="modal">
      <Modal {...props} size="md" aria-labelledby="contained-modal-title-vcenter" centered scrollable={true}>
        <Modal.Header>
          <div style={{ width: "100%" }}>
            <div className="sort">
              <div className="dialog-heading">
                <div className="dialog-heading">
                  <div>
                    <h4 style={{ color: "#3598DB" }}>Guide Options</h4>
                  </div>
                  <i className="fas fa-check" onClick={() => saveSettings()}></i>

                  <span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>

                  <div>
                    <i className={`fas fa-times`} onClick={() => props.onHide()}></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="font-class">
            {" "}
            <div className="multiselect-item">
              <Form.Check
                label="Hide Schedule pane"
                type="checkbox"
                id="autoSizingCheck2"
                checked={GuideSettings.hide_schedule}
                onChange={() => {
                  GuideSettings_copy.hide_schedule = !GuideSettings_copy.hide_schedule;
                  setGuideSettings(GuideSettings_copy);
                }}
              />
              {/* <div className="country">
                <p>Hide Schedule pane</p>
              </div> */}
              {/* <Form.Check
                type="checkbox"
                id="autoSizingCheck2"
                checked={GuideSettings.wrap_text}
                onChange={() => {
                  GuideSettings_copy.wrap_text = !GuideSettings_copy.wrap_text;
                  setGuideSettings(GuideSettings_copy);
                }}
              />
              <div className="country">
                <p>Hide Schedule pane</p>
              </div> */}
            </div>
            <div className="multiselect-item">
              <p className="attribute">Guide Mode</p>
            </div>
            <div className="multiselect-item">
              <Form.Check
                label="Default"
                type="checkbox"
                id="autoSizingCheck2"
                checked={GuideSettings.mode === "edit"}
                onChange={() => {
                  GuideSettings_copy.mode = "edit";
                  setGuideSettings(GuideSettings_copy);
                }}
              />
              {/* <div className="country">
                <p>Default</p>
              </div> */}
              {/* <span>&nbsp;&nbsp;&nbsp;</span>
              <Form.Check
                type="checkbox"
                id="autoSizingCheck2"
                checked={GuideSettings.mode === "view"}
                onChange={() => {
                  GuideSettings_copy.mode = "view";
                  setGuideSettings(GuideSettings_copy);
                }}
              />
              <div className="country">
                <p>View</p>
              </div> */}
              <span>&nbsp;&nbsp;&nbsp;</span>
              <Form.Check
                label="Custom"
                type="checkbox"
                id="autoSizingCheck2"
                checked={GuideSettings.mode === "custom_view"}
                onChange={() => {
                  GuideSettings_copy.mode = "custom_view";
                  setGuideSettings(GuideSettings_copy);
                }}
              />
              {/* <div className="country">
                <p>Custom</p>
              </div> */}
            </div>
            {GuideSettings.mode === "custom_view" ? (
              <div>
                <div className="multiselect-item">
                  <p className="attribute">Places in List</p>
                </div>
                <div className="multiselect-item">
                  <Form.Check
                    label="Display Menu"
                    type="checkbox"
                    id="autoSizingCheck2"
                    checked={GuideSettings.places_list.menu}
                    onChange={() => {
                      GuideSettings_copy.places_list.menu = !GuideSettings_copy.places_list.menu;
                      setGuideSettings(GuideSettings_copy);
                    }}
                  />
                  {/* <div className="country">
                    <p>Display Menu</p>
                  </div> */}
                </div>
                <div className="multiselect-item">
                  <Form.Check
                    label="Display Info"
                    type="checkbox"
                    id="autoSizingCheck2"
                    checked={GuideSettings.places_list.info}
                    onChange={() => {
                      GuideSettings_copy.places_list.info = !GuideSettings_copy.places_list.info;
                      setGuideSettings(GuideSettings_copy);
                    }}
                  />
                  {/* <div className="country">
                    <p>Display Info</p>
                  </div> */}
                </div>
                <div className="multiselect-item">
                  <Form.Check
                    label="Display URL"
                    type="checkbox"
                    checked={GuideSettings.places_list.url}
                    onChange={() => {
                      GuideSettings_copy.places_list.url = !GuideSettings_copy.places_list.url;
                      setGuideSettings(GuideSettings_copy);
                    }}
                  />
                  {/* <div className="country">
                    <p>Display URL</p>
                  </div> */}
                </div>
                <div className="multiselect-item">
                  <Form.Check
                    label="Display Attributes"
                    type="checkbox"
                    id="autoSizingCheck2"
                    checked={GuideSettings.places_list.attributes}
                    onChange={() => {
                      GuideSettings_copy.places_list.attributes = !GuideSettings_copy.places_list.attributes;
                      setGuideSettings(GuideSettings_copy);
                    }}
                  />
                  {/* <div className="country">
                    <p>Display Attributes</p>
                  </div> */}
                </div>
                <div className="multiselect-item">
                  <Form.Check
                    label="Show on map"
                    type="checkbox"
                    id="autoSizingCheck2"
                    checked={GuideSettings.places_list.center}
                    onChange={() => {
                      GuideSettings_copy.places_list.center = !GuideSettings_copy.places_list.center;
                      setGuideSettings(GuideSettings_copy);
                    }}
                  />
                  {/* <div className="country">
                    <p>Show on map</p>
                  </div> */}
                </div>
                <div className="multiselect-item">
                  <Form.Check
                    label="Filter places"
                    type="checkbox"
                    id="autoSizingCheck2"
                    checked={GuideSettings.places_list.filter}
                    onChange={() => {
                      GuideSettings_copy.places_list.filter = !GuideSettings_copy.places_list.filter;
                      setGuideSettings(GuideSettings_copy);
                    }}
                  />
                </div>

                <div className="multiselect-item">
                  <Form.Check
                    label="Place link"
                    type="checkbox"
                    id="autoSizingCheck2"
                    checked={GuideSettings.places_list.path_link}
                    onChange={() => {
                      GuideSettings_copy.places_list.path_link = !GuideSettings_copy.places_list.path_link;
                      setGuideSettings(GuideSettings_copy);
                    }}
                  />
                </div>
                <div className="multiselect-item">
                  <p className="attribute">Places in Map Click</p>
                </div>
                <div className="multiselect-item">
                  <Form.Check
                    label="Display Menu"
                    type="checkbox"
                    id="autoSizingCheck2"
                    checked={GuideSettings.places_map.menu}
                    onChange={() => {
                      GuideSettings_copy.places_map.menu = !GuideSettings_copy.places_map.menu;
                      setGuideSettings(GuideSettings_copy);
                    }}
                  />
                  {/* <div className="country">
                    <p>Display Menu</p>
                  </div> */}
                </div>
                <div className="multiselect-item">
                  <Form.Check
                    label="Display Info"
                    type="checkbox"
                    id="autoSizingCheck2"
                    checked={GuideSettings.places_map.info}
                    onChange={() => {
                      GuideSettings_copy.places_map.info = !GuideSettings_copy.places_map.info;
                      setGuideSettings(GuideSettings_copy);
                    }}
                  />
                  {/* <div className="country">
                    <p>Display Info</p>
                  </div> */}
                </div>
                <div className="multiselect-item">
                  <Form.Check
                    label="Display URL"
                    type="checkbox"
                    id="autoSizingCheck2"
                    checked={GuideSettings.places_map.url}
                    onChange={() => {
                      GuideSettings_copy.places_map.url = !GuideSettings_copy.places_map.url;
                      setGuideSettings(GuideSettings_copy);
                    }}
                  />
                  {/* <div className="country">
                    <p>Display URL</p>
                  </div> */}
                </div>
                <div className="multiselect-item">
                  <Form.Check
                    label="Display Attributes"
                    type="checkbox"
                    id="autoSizingCheck2"
                    checked={GuideSettings.places_map.attributes}
                    onChange={() => {
                      GuideSettings_copy.places_map.attributes = !GuideSettings_copy.places_map.attributes;
                      setGuideSettings(GuideSettings_copy);
                    }}
                  />
                  {/* <div className="country">
                    <p>Display Attributes</p>
                  </div> */}
                </div>
                <div className="multiselect-item">
                  <Form.Check
                    label="Show on map"
                    type="checkbox"
                    id="autoSizingCheck2"
                    checked={GuideSettings.places_map.center}
                    onChange={() => {
                      GuideSettings_copy.places_map.center = !GuideSettings_copy.places_map.center;
                      setGuideSettings(GuideSettings_copy);
                    }}
                  />
                  {/* <div className="country">
                    <p>Show on map</p>
                  </div> */}
                </div>
                <div className="multiselect-item">
                  <Form.Check
                    label="Filter places"
                    type="checkbox"
                    id="autoSizingCheck2"
                    checked={GuideSettings.places_map.filter}
                    onChange={() => {
                      GuideSettings_copy.places_map.filter = !GuideSettings_copy.places_map.filter;
                      setGuideSettings(GuideSettings_copy);
                    }}
                  />
                </div>

                <div className="multiselect-item">
                  <Form.Check
                    label="Place link"
                    type="checkbox"
                    id="autoSizingCheck2"
                    checked={GuideSettings.places_map.path_link}
                    onChange={() => {
                      GuideSettings_copy.places_map.path_link = !GuideSettings_copy.places_map.path_link;
                      setGuideSettings(GuideSettings_copy);
                    }}
                  />
                </div>
                <div className="multiselect-item">
                  <p className="attribute">Places in Map Hover</p>
                </div>
                <div className="multiselect-item">
                  <Form.Check
                    label="Display Menu"
                    type="checkbox"
                    id="autoSizingCheck2"
                    checked={GuideSettings.places_map_hover.menu}
                    onChange={() => {
                      GuideSettings_copy.places_map_hover.menu = !GuideSettings_copy.places_map_hover.menu;
                      setGuideSettings(GuideSettings_copy);
                    }}
                  />
                </div>
                <div className="multiselect-item">
                  <Form.Check
                    label="Highlight Place"
                    type="checkbox"
                    id="autoSizingCheck2"
                    checked={GuideSettings.places_map_hover.place}
                    onChange={() => {
                      GuideSettings_copy.places_map_hover.place = !GuideSettings_copy.places_map_hover.place;
                      setGuideSettings(GuideSettings_copy);
                    }}
                  />
                </div>
                <div className="multiselect-item">
                  <Form.Check
                    label="Display Info"
                    type="checkbox"
                    id="autoSizingCheck2"
                    checked={GuideSettings.places_map_hover.info}
                    onChange={() => {
                      GuideSettings_copy.places_map_hover.info = !GuideSettings_copy.places_map_hover.info;
                      setGuideSettings(GuideSettings_copy);
                    }}
                  />
                </div>
                <div className="multiselect-item">
                  <Form.Check
                    label="Highlight event"
                    type="checkbox"
                    id="autoSizingCheck2"
                    checked={GuideSettings.places_map_hover.event}
                    onChange={() => {
                      GuideSettings_copy.places_map_hover.event = !GuideSettings_copy.places_map_hover.event;
                      setGuideSettings(GuideSettings_copy);
                    }}
                  />
                </div>
                {/* <div className="multiselect-item">
                  <Form.Check
                    label="Place link"
                    type="checkbox"
                    id="autoSizingCheck2"
                    checked={GuideSettings.places_map_hover.path_link}
                    onChange={() => {
                      GuideSettings_copy.places_map_hover.path_link = !GuideSettings_copy.places_map_hover.path_link;
                      setGuideSettings(GuideSettings_copy);
                    }}
                  />
                </div> */}

                <div className="multiselect-item">
                  <p className="attribute">Event in List</p>
                </div>
                <div className="multiselect-item">
                  <Form.Check
                    label="Display Menu"
                    type="checkbox"
                    id="autoSizingCheck2"
                    checked={GuideSettings.event.menu}
                    onChange={() => {
                      GuideSettings_copy.event.menu = !GuideSettings_copy.event.menu;
                      setGuideSettings(GuideSettings_copy);
                    }}
                  />
                </div>
                <div className="multiselect-item">
                  <Form.Check
                    label="Display Details"
                    type="checkbox"
                    id="autoSizingCheck2"
                    checked={GuideSettings.event.details}
                    onChange={() => {
                      GuideSettings_copy.event.details = !GuideSettings_copy.event.details;
                      setGuideSettings(GuideSettings_copy);
                    }}
                  />
                </div>
                <div className="multiselect-item">
                  <Form.Check
                    label="Show on map"
                    type="checkbox"
                    id="autoSizingCheck2"
                    checked={GuideSettings.event.center}
                    onChange={() => {
                      GuideSettings_copy.event.center = !GuideSettings_copy.event.center;
                      setGuideSettings(GuideSettings_copy);
                    }}
                  />
                </div>
                <div className="multiselect-item">
                  <Form.Check
                    label="Filter places"
                    type="checkbox"
                    id="autoSizingCheck2"
                    checked={GuideSettings.event.filter}
                    onChange={() => {
                      GuideSettings_copy.event.filter = !GuideSettings_copy.event.filter;
                      setGuideSettings(GuideSettings_copy);
                    }}
                  />
                </div>
                <div className="multiselect-item">
                  <p className="attribute">Schedule</p>
                </div>
                <div className="multiselect-item">
                  <Form.Check
                    label="Display Menu"
                    type="checkbox"
                    id="autoSizingCheck2"
                    checked={GuideSettings.schedule.menu}
                    onChange={() => {
                      GuideSettings_copy.schedule.menu = !GuideSettings_copy.schedule.menu;
                      setGuideSettings(GuideSettings_copy);
                    }}
                  />
                  {/* <div className="country">
                    <p>Display Menu</p>
                  </div> */}
                </div>
                <div className="multiselect-item">
                  <Form.Check
                    label="Display Itinerary"
                    type="checkbox"
                    id="autoSizingCheck2"
                    checked={GuideSettings.schedule.details}
                    onChange={() => {
                      GuideSettings_copy.schedule.details = !GuideSettings_copy.schedule.details;
                      setGuideSettings(GuideSettings_copy);
                    }}
                  />
                  {/* <div className="country">
                    <p>Display Itinerary</p>
                  </div> */}
                </div>
                <div className="multiselect-item">
                  <Form.Check
                    label="Show on map"
                    type="checkbox"
                    id="autoSizingCheck2"
                    checked={GuideSettings.schedule.center}
                    onChange={() => {
                      GuideSettings_copy.schedule.center = !GuideSettings_copy.schedule.center;
                      setGuideSettings(GuideSettings_copy);
                    }}
                  />
                  {/* <div className="country">
                    <p>Show on map</p>
                  </div> */}
                </div>
                <div className="multiselect-item">
                  <Form.Check
                    label="Filter places"
                    type="checkbox"
                    id="autoSizingCheck2"
                    checked={GuideSettings.schedule.filter}
                    onChange={() => {
                      GuideSettings_copy.schedule.filter = !GuideSettings_copy.schedule.filter;
                      setGuideSettings(GuideSettings_copy);
                    }}
                  />
                  {/* <div className="country">
                    <p>Filter places</p>
                  </div> */}
                </div>
                <div className="multiselect-item">
                  <p className="attribute">Day</p>
                </div>
                <div className="multiselect-item">
                  <Form.Check
                    label="Display Menu"
                    type="checkbox"
                    id="autoSizingCheck2"
                    checked={GuideSettings.day.menu}
                    onChange={() => {
                      GuideSettings_copy.day.menu = !GuideSettings_copy.day.menu;
                      setGuideSettings(GuideSettings_copy);
                    }}
                  />
                  {/* <div className="country">
                    <p>Display Menu</p>
                  </div> */}
                </div>
                <div className="multiselect-item">
                  <Form.Check
                    label="Show on map"
                    type="checkbox"
                    id="autoSizingCheck2"
                    checked={GuideSettings.day.center}
                    onChange={() => {
                      GuideSettings_copy.day.center = !GuideSettings_copy.day.center;
                      setGuideSettings(GuideSettings_copy);
                    }}
                  />
                  {/* <div className="country">
                    <p>Show on map</p>
                  </div> */}
                </div>
                <div className="multiselect-item">
                  <Form.Check
                    label="Filter places"
                    type="checkbox"
                    id="autoSizingCheck2"
                    checked={GuideSettings.day.filter}
                    onChange={() => {
                      GuideSettings_copy.day.filter = !GuideSettings_copy.day.filter;
                      setGuideSettings(GuideSettings_copy);
                    }}
                  />
                </div>
                <div className="multiselect-item">
                  <p className="attribute">Itinerary</p>
                </div>
                <div className="multiselect-item">
                  <Form.Check
                    label="Display info"
                    type="checkbox"
                    id="autoSizingCheck2"
                    checked={GuideSettings.itinerary.info}
                    onChange={() => {
                      GuideSettings_copy.itinerary.info = !GuideSettings_copy.itinerary.info;
                      setGuideSettings(GuideSettings_copy);
                    }}
                  />
                </div>
                <div className="multiselect-item">
                  <Form.Check
                    label="Display photo"
                    type="checkbox"
                    id="autoSizingCheck2"
                    checked={GuideSettings.itinerary.photo}
                    onChange={() => {
                      GuideSettings_copy.itinerary.photo = !GuideSettings_copy.itinerary.photo;
                      setGuideSettings(GuideSettings_copy);
                    }}
                  />
                </div>
                <div className="multiselect-item">
                  <Form.Check
                    label="Directions link"
                    type="checkbox"
                    id="autoSizingCheck2"
                    checked={GuideSettings.itinerary.directions}
                    onChange={() => {
                      GuideSettings_copy.itinerary.directions = !GuideSettings_copy.itinerary.directions;
                      setGuideSettings(GuideSettings_copy);
                    }}
                  />
                </div>
                <div className="multiselect-item">
                  <Form.Check
                    label="Place menu link"
                    type="checkbox"
                    id="autoSizingCheck2"
                    checked={GuideSettings.itinerary.place_menu}
                    onChange={() => {
                      GuideSettings_copy.itinerary.place_menu = !GuideSettings_copy.itinerary.place_menu;
                      setGuideSettings(GuideSettings_copy);
                    }}
                  />
                </div>
              </div>
            ) : null}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default GuideSettingsDialog;
