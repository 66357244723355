export default class Category {
  constructor(categoryList, name) {
    this.id = "0";
    this.name = name;
    this.poi_img = "0";
    if (categoryList != null) {
      let i = 1000;
      let id = i.toString();
      while (categoryList.findIndex((c) => c.id == id) != -1) {
        i++;
        id = i.toString();
      }
      this.id = id;
    }
  }
}
