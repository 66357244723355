export const faqs_html = `<!DOCTYPE html>
<html>
  <head>
    <meta http-equiv="content-type" content="text/html; charset=UTF-8">
    <title>FAQs</title>
  </head>
  <body>
    <p><b>Is Andiamo Places really free?</b> - Yes</p>
    <p><b>Are we obligated to purchase travel tickets</b> - No</p>
    <p><b>Does the app include ads? </b>- No, there are no ads. There is a
      dedicated page for purchasing tickets, but you have to specifically go to
      that page. There are no plans to add ads, but that could change if we see
      that this voluntary approach is not working.&nbsp; Since the app is
      flexible, a content creator could add ads, but that is a violation of the
      rules and subject to loosing the account. </p>
    <p><b>How do content creators make money?</b> - They get 70% of the
      commissions from tickets purchased. The commissions get paid monthly, with
      a 2 month delay, when they reach a minimum of $25 USD.</p>
    <b>How does Andiamo Places make money?</b> - We get 30% of the commissions
    from tickets
    <p><b>Why should I buy tickets through Andiamo Places?</b> - It keeps the
      app free and motivates guide creators to write more and better guides<br>
    </p>
    <p><b>Why is there a 2 month delay?</b> - It take 1 month for the sponsors
      to pay Andiamo Places and then 1 month for Andiamo Places to pay the
      content creators.</p>
    <p><b>How do I get the money?</b> - The money will be paid monthly (but
      delayed by 2 months) via bank transfer or paypal, as long as the minimum
      payout amount of $25 USD is reached. Make sure that you select the
      "Participate in Affiliate Program" option, and specify the payment method
      and info in user settings. </p>
    <br>
    <p><br>
    </p>
    <p><br>
    </p>
  </body>
</html>`;
