import Place, { Place_Source } from "./Place";
import { webSite } from "../Common/Common";

export default class Attractions {
  constructor() {
    this.places = [];
  }

  searchPlaces = (bbox, limit, callback) => {
    //const callback = this.getDetails;
    var params = {
      longitude_min: bbox.lon_min,
      longitude_max: bbox.lon_max,
      latitude_min: bbox.lat_min,
      latitude_max: bbox.lat_max,
    };

    //console.log("searchPlaces params ", params);
    var url = "https://liquidguides.com/database/get_attractions.php?language=1&from=0&maxResults=200";
    Object.keys(params).forEach(function (key) {
      url += "&" + key + "=" + params[key];
    });
    console.log("searchPlaces url ", url);
    fetch(url)
      .then(function (response) {
        //console.log("response1", JSON.stringify(response.json()));
        // let start = response.indexOf("[");
        // if (start < 0) {
        //   display_message("No attractions to display");
        //   return;
        // }
        // response = response.substring(start);
        //console.log("response2", response);
        return response.json();
      })
      .then(function (response) {
        //console.log("response3", response);
        // let start = response.indexOf("[");
        // if (start < 0) {
        //   display_message("No attractions to display");
        //   return;
        // }
        // response = response.substring(start);
        //console.log("response4", response);
        console.log("response", JSON.stringify(response));
        callback(response);
      })
      .catch(function (error) {
        //console.log(error);
        callback(null);
      });
  };

  _last = 0;
  _index = 0;
  _places = [];
  _results = [];
  _callback = null;

  getDetails = (places, callback) => {
    this._places = places;
    this._last = places.length - 1;
    this._callback = callback;
    this._index = 0;
    this._results = [];
    if (places.length == 0) callback(this._results);
    //this.getOneDetails(this.getDetailsCallback);
    for (let i = 0; i < places.length; i++) {
      let new_place = this.parsePlace(places[i], this._results);
      this._results.push(new_place);
    }
    //console.log("this._results", this._results);
    this._callback(this._results);
  };

  parsePlace = (details, results) => {
    //console.log("details", details);
    let new_place = new Place();
    new_place.createIDFromPlacesArray(results);
    if (typeof details.name != "undefined") new_place.Name = details.name;
    if (typeof details.description_short != "undefined") new_place.Info = details.description_short;
    new_place.Lat = parseFloat(details.latitude);
    new_place.Longi = parseFloat(details.longitude);
    //if (typeof details.address.city != "undefined") new_place.City = details.address.city;
    //if (typeof details.address.state != "undefined") new_place.State = details.address.state;
    //if (typeof details.address.country != "undefined") new_place.Country = details.address.country;
    new_place.Phone = "";
    new_place.Email = "";
    //new_place.Rating = place.properties.rate;
    //new_place.Category = place.categories;
    //if (typeof details.wikipedia != "undefined") new_place.Hlink = `wikipedia|${details.wikipedia}`;
    new_place.Hlink = `More info|${webSite + "/database/get_attraction_description.php?language=1&id=" + details.id}`;
    new_place.Group = [];
    if (details.photo_t_url == "") new_place.Photo = "";
    else new_place.Photo = webSite + "/Attractions/Photos/" + details.photo_t_url;
    if (details.photo_m_url == "") new_place.Photo_m = "";
    else new_place.Photo_m = webSite + "/Attractions/Photos/" + details.photo_m_url;
    new_place.PType = "Icon";
    new_place.Points = null;
    new_place.Source = Place_Source.ATTRACTION;
    return new_place;
  };

  moveToGuide = (guide, place) => {
    guide.places.addPlace(place);
  };
}
