export const guidelines_html = `<!DOCTYPE html>
<html>
  <head>
    <meta http-equiv="content-type" content="text/html; charset=UTF-8">
    <title>Guidelines</title>
  </head>
  <body>
    <h2><b></b>Community Guidelines</b></h2>
    <p>In order to maintain the highest quality content, all content creators
      need to review and accept the following content policy:</p>
    <h3></b>Content Policies</b></h3>
    <ul>
      <li>Do not spam.</li>
      <li>Do not embed or link to content that is abusive, hateful, or
        offensive.</li>
      <li>Do not embed or link to content that threatens or harasses others.</li>
      <li>Do not embed or link to content that discloses private or personal
        matters concerning any person.</li>
      <li>Do not embed or link to content that is sexually explicit or contains
        profanity.</li>
      <li>Do not embed or link to content that is political or controversial.</li>
      <li>Do not embed, link to, or otherwise direct users to advertisements or
        solicitations.</li>
      <li>Do not sell or buy anything on this platform.</li>
      <li>Do not embed any material that violates the copyrights or other
        intellectual property rights of others.</li>
      <li>Do not impersonate any person, or falsely state or otherwise
        misrepresent your affiliation with a person or entity.</li>
      <li>Do not violate the laws, rules, or regulations of the U.S. Securities
        and Exchange Commission or other regulatory agencies, including rules
        against providing misleading information in order to manipulate the
        price of any security;.</li>
      <li>Do not violate any other applicable law or regulation.</li>
    </ul>
    <h3><b>Community Conduct Guidelines</b></h3>
    <ul>
      <li>Create clear, valuable, honest information.</li>
      <li>Be nice to others; do not attack others in the group.</li>
      <li>Use good grammar and check your spelling.</li>
      <li>Make your content and comments useful and informative.</li>
      <li>Do not use excessive capitalization and punctuation.</li>
    </ul>
    <p>Failure to abide by the policy can result in suspension of your usage of
      the app and deletion of all your content.</p>
    <p></p>
  </body>
</html>

`;
