export const languages = [
  "Afrikaans",
  "Albanian",
  "Amharic",
  "Arabic",
  "Armenian",
  "Assamese",
  "Aymara",
  "Azerbaijani",
  "Bambara",
  "Basque",
  "Belarusian",
  "Bengali",
  "Bhojpuri",
  "Bosnian",
  "Bulgarian",
  "Catalan",
  "Cebuano",
  "Chinese (Simplified)",
  "Chinese (Traditional)",
  "Corsican",
  "Croatian",
  "Czech",
  "Danish",
  "Dhivehi",
  "Dogri",
  "Dutch",
  "English",
  "Esperanto",
  "Estonian",
  "Ewe",
  "Filipino",
  "Finnish",
  "French",
  "Frisian",
  "Galician",
  "Georgian",
  "German",
  "Greek",
  "Guarani",
  "Gujarati",
  "Haitian Creole",
  "Hausa",
  "Hawaiian",
  "Hebrew",
  "Hindi",
  "Hmong",
  "Hungarian",
  "Icelandic",
  "Igbo",
  "Ilocano",
  "Indonesian",
  "Irish",
  "Italian",
  "Japanese",
  "Javanese",
  "Kannada",
  "Kazakh",
  "Khmer",
  "Kinyarwanda",
  "Konkani",
  "Korean",
  "Krio",
  "Kurdish",
  "Kurdish",
  "Kyrgyz",
  "Lao",
  "Latin",
  "Latvian",
  "Lingala",
  "Lithuanian",
  "Luganda",
  "Luxembourgish",
  "Macedonian",
  "Maithili",
  "Malagasy",
  "Malay",
  "Malayalam",
  "Maltese",
  "Maori",
  "Marathi",
  "Meiteilon",
  "Mizo",
  "Mongolian",
  "Myanmar",
  "Nepali",
  "Norwegian",
  "Nyanja",
  "Odia",
  "Oromo",
  "Pashto",
  "Persian",
  "Polish",
  "Portuguese",
  "Punjabi",
  "Quechua",
  "Romanian",
  "Russian",
  "Samoan",
  "Sanskrit",
  "Scots Gaelic",
  "Sepedi",
  "Serbian",
  "Sesotho",
  "Shona",
  "Sindhi",
  "Sinhala",
  "Slovak",
  "Slovenian",
  "Somali",
  "Spanish",
  "Sundanese",
  "Swahili",
  "Swedish",
  "Tagalog",
  "Tajik",
  "Tamil",
  "Tatar",
  "Telugu",
  "Thai",
  "Tigrinya",
  "Tsonga",
  "Turkish",
  "Turkmen",
  "Twi",
  "Ukrainian",
  "Urdu",
  "Uyghur",
  "Uzbek",
  "Vietnamese",
  "Welsh",
  "Xhosa",
  "Yiddish",
  "Yoruba",
  "Zulu",
];

export const languagesOpenAI = [
  "Albanian",
  "Arabic",
  "Armenian",
  "Awadhi",
  "Azerbaijani",
  "Bashkir",
  "Basque",
  "Belarusian",
  "Bengali",
  "Bhojpuri",
  "Bosnian",
  "Brazilian Portuguese",
  "Bulgarian",
  "Cantonese (Yue)",
  "Catalan",
  "Chhattisgarhi",
  "Chinese",
  "Croatian",
  "Czech",
  "Danish",
  "Dogri",
  "Dutch",
  "English",
  "Estonian",
  "Faroese",
  "Finnish",
  "French",
  "Galician",
  "Georgian",
  "German",
  "Greek",
  "Gujarati",
  "Haryanvi",
  "Hindi",
  "Hungarian",
  "Indonesian",
  "Irish",
  "Italian",
  "Japanese",
  "Javanese",
  "Kannada",
  "Kashmiri",
  "Kazakh",
  "Konkani",
  "Korean",
  "Kyrgyz",
  "Latvian",
  "Lithuanian",
  "Macedonian",
  "Maithili",
  "Malay",
  "Maltese",
  "Mandarin",
  "Mandarin Chinese",
  "Marathi",
  "Marwari",
  "Min Nan",
  "Moldovan",
  "Mongolian",
  "Montenegrin",
  "Nepali",
  "Norwegian",
  "Oriya",
  "Pashto",
  "Persian (Farsi)",
  "Polish",
  "Portuguese",
  "Punjabi",
  "Rajasthani",
  "Romanian",
  "Russian",
  "Sanskrit",
  "Santali",
  "Serbian",
  "Sindhi",
  "Sinhala",
  "Slovak",
  "Slovene",
  "Slovenian",
  "Spanish",
  "Swahili",
  "Swedish",
  "Tajik",
  "Tamil",
  "Tatar",
  "Telugu",
  "Thai",
  "Turkish",
  "Turkmen",
  "Ukrainian",
  "Urdu",
  "Uzbek",
  "Vietnamese",
  "Welsh",
  "Wu",
];

export const googleLanguages = [
  { language: "Afrikaans", id: "af" },
  { language: "Albanian", id: "sq" },
  { language: "Amharic", id: "am" },
  { language: "Arabic", id: "ar" },
  { language: "Armenian", id: "hy" },
  { language: "Assamese", id: "as" },
  { language: "Aymara", id: "ay" },
  { language: "Azerbaijani", id: "az" },
  { language: "Bambara", id: "bm" },
  { language: "Basque", id: "eu" },
  { language: "Belarusian", id: "be" },
  { language: "Bengali", id: "bn" },
  { language: "Bhojpuri", id: "bho" },
  { language: "Bosnian", id: "bs" },
  { language: "Bulgarian", id: "bg" },
  { language: "Catalan", id: "ca" },
  { language: "Cebuano", id: "ceb" },
  { language: "Chinese (Simplified)", id: "zh-CN" },
  { language: "Chinese (Traditional)", id: "zh-TW" },
  { language: "Corsican", id: "co" },
  { language: "Croatian", id: "hr" },
  { language: "Czech", id: "cs" },
  { language: "Danish", id: "da" },
  { language: "Dhivehi", id: "dv" },
  { language: "Dogri", id: "doi" },
  { language: "Dutch", id: "nl" },
  { language: "English", id: "en" },
  { language: "Esperanto", id: "eo" },
  { language: "Estonian", id: "et" },
  { language: "Ewe", id: "ee" },
  { language: "Filipino", id: "fil" },
  { language: "Finnish", id: "fi" },
  { language: "French", id: "fr" },
  { language: "Frisian", id: "fy" },
  { language: "Galician", id: "gl" },
  { language: "Georgian", id: "ka" },
  { language: "German", id: "de" },
  { language: "Greek", id: "el" },
  { language: "Guarani", id: "gn" },
  { language: "Gujarati", id: "gu" },
  { language: "Haitian Creole", id: "ht" },
  { language: "Hausa", id: "ha" },
  { language: "Hawaiian", id: "haw" },
  { language: "Hebrew", id: "he or iw" },
  { language: "Hindi", id: "hi" },
  { language: "Hmong", id: "hmn" },
  { language: "Hungarian", id: "hu" },
  { language: "Icelandic", id: "is" },
  { language: "Igbo", id: "ig" },
  { language: "Ilocano", id: "ilo" },
  { language: "Indonesian", id: "id" },
  { language: "Irish", id: "ga" },
  { language: "Italian", id: "it" },
  { language: "Japanese", id: "ja" },
  { language: "Javanese", id: "jv or jw" },
  { language: "Kannada", id: "kn" },
  { language: "Kazakh", id: "kk" },
  { language: "Khmer", id: "km" },
  { language: "Kinyarwanda", id: "rw" },
  { language: "Konkani", id: "gom" },
  { language: "Korean", id: "ko" },
  { language: "Krio", id: "kri" },
  { language: "Kurdish", id: "ku" },
  { language: "Kurdish", id: "ckb" },
  { language: "Kyrgyz", id: "ky" },
  { language: "Lao", id: "lo" },
  { language: "Latin", id: "la" },
  { language: "Latvian", id: "lv" },
  { language: "Lingala", id: "ln" },
  { language: "Lithuanian", id: "lt" },
  { language: "Luganda", id: "lg" },
  { language: "Luxembourgish", id: "lb" },
  { language: "Macedonian", id: "mk" },
  { language: "Maithili", id: "mai" },
  { language: "Malagasy", id: "mg" },
  { language: "Malay", id: "ms" },
  { language: "Malayalam", id: "ml" },
  { language: "Maltese", id: "mt" },
  { language: "Maori", id: "mi" },
  { language: "Marathi", id: "mr" },
  { language: "Meiteilon", id: "mni-Mtei" },
  { language: "Mizo", id: "lus" },
  { language: "Mongolian", id: "mn" },
  { language: "Myanmar", id: "my" },
  { language: "Nepali", id: "ne" },
  { language: "Norwegian", id: "no" },
  { language: "Nyanja", id: "ny" },
  { language: "Odia", id: "or" },
  { language: "Oromo", id: "om" },
  { language: "Pashto", id: "ps" },
  { language: "Persian", id: "fa" },
  { language: "Polish", id: "pl" },
  { language: "Portuguese", id: "pt" },
  { language: "Punjabi", id: "pa" },
  { language: "Quechua", id: "qu" },
  { language: "Romanian", id: "ro" },
  { language: "Russian", id: "ru" },
  { language: "Samoan", id: "sm" },
  { language: "Sanskrit", id: "sa" },
  { language: "Scots Gaelic", id: "gd" },
  { language: "Sepedi", id: "nso" },
  { language: "Serbian", id: "sr" },
  { language: "Sesotho", id: "st" },
  { language: "Shona", id: "sn" },
  { language: "Sindhi", id: "sd" },
  { language: "Sinhala", id: "si" },
  { language: "Slovak", id: "sk" },
  { language: "Slovenian", id: "sl" },
  { language: "Somali", id: "so" },
  { language: "Spanish", id: "es" },
  { language: "Sundanese", id: "su" },
  { language: "Swahili", id: "sw" },
  { language: "Swedish", id: "sv" },
  { language: "Tagalog", id: "tl" },
  { language: "Tajik", id: "tg" },
  { language: "Tamil", id: "ta" },
  { language: "Tatar", id: "tt" },
  { language: "Telugu", id: "te" },
  { language: "Thai", id: "th" },
  { language: "Tigrinya", id: "ti" },
  { language: "Tsonga", id: "ts" },
  { language: "Turkish", id: "tr" },
  { language: "Turkmen", id: "tk" },
  { language: "Twi", id: "ak" },
  { language: "Ukrainian", id: "uk" },
  { language: "Urdu", id: "ur" },
  { language: "Uyghur", id: "ug" },
  { language: "Uzbek", id: "uz" },
  { language: "Vietnamese", id: "vi" },
  { language: "Welsh", id: "cy" },
  { language: "Xhosa", id: "xh" },
  { language: "Yiddish", id: "yi" },
  { language: "Yoruba", id: "yo" },
  { language: "Zulu", id: "zu" },
];

export const languagesOLD = [
  "Afaraf",
  "аҧсуа бызшәа, аҧсшәа",
  "avesta",
  "Afrikaans",
  "Akan",
  "አማርኛ",
  "aragonés",
  "العربية",
  "অসমীয়া",
  "авар мацӀ, магӀарул мацӀ",
  "aymar aru",
  "azərbaycan dili",
  "تورکجه‎",
  "башҡорт теле",
  "беларуская мова",
  "български език",
  "भोजपुरी",
  "Bislama",
  "bamanankan",
  "বাংলা",
  "བོད་ཡིག",
  "brezhoneg",
  "bosanski jezik",
  "català",
  "нохчийн мотт",
  "Chamoru",
  "corsu",
  "ᓀᐦᐃᔭᐍᐏᐣ",
  "čeština, český jazyk",
  "ѩзыкъ словѣньскъ",
  "чӑваш чӗлхи",
  "Cymraeg",
  "dansk",
  "Deutsch",
  "ދިވެހި",
  "རྫོང་ཁ",
  "Eʋegbe",
  "ελληνικά",
  "English",
  "Esperanto",
  "español",
  "eesti, eesti keel",
  "euskara, euskera",
  "فارسی",
  "Fulfulde, Pulaar, Pular",
  "suomi, suomen kieli",
  "vosa Vakaviti",
  "føroyskt",
  "français",
  "Frysk",
  "Gaeilge",
  "Gàidhlig",
  "galego",
  "Avañe'ẽ",
  "ગુજરાતી",
  "Gaelg, Gailck",
  "Hausa, هَوُسَ",
  "עברית",
  "हिन्दी, हिंदी",
  "Hiri Motu",
  "hrvatski jezik",
  "Kreyòl ayisyen",
  "magyar",
  "Հայերեն",
  "Otjiherero",
  "Interlingua",
  "Bahasa Indonesia",
  "Asụsụ Igbo",
  "ꆈꌠ꒿ Nuosuhxop",
  "Iñupiaq, Iñupiatun",
  "Ido",
  "Íslenska",
  "italiano",
  "ᐃᓄᒃᑎᑐᑦ",
  "日本語 (にほんご)",
  "basa Jawa",
  "ქართული",
  "KiKongo",
  "Gĩkũyũ",
  "Kuanyama",
  "қазақ тілі",
  "kalaallisut, kalaallit oqaasii",
  "ខ្មែរ, ខេមរភាសា, ភាសាខ្មែរ",
  "ಕನ್ನಡ",
  "한국어 (韓國語), 조선어 (朝鮮語)",
  "Kanuri",
  "कश्मीरी, كشميري‎",
  "Kurdî, كوردی‎",
  "коми кыв",
  "Kernewek",
  "Кыргызча, Кыргыз тили",
  "latine, lingua latina",
  "Lëtzebuergesch",
  "Luganda",
  "Limburgs",
  "Lingála",
  "ພາສາລາວ",
  "lietuvių kalba",
  "Tshiluba",
  "latviešu valoda",
  "fiteny malagasy",
  "Kajin M̧ajeļ",
  "te reo Māori",
  "македонски јазик",
  "മലയാളം",
  "монгол",
  "मराठी",
  "bahasa Melayu, بهاس ملايو‎",
  "Malti",
  "ဗမာစာ",
  "Ekakairũ Naoero",
  "Norsk bokmål",
  "isiNdebele",
  "नेपाली",
  "Owambo",
  "Nederlands, Vlaams",
  "Norsk nynorsk",
  "Norsk",
  "isiNdebele",
  "Diné bizaad, Dinékʼehǰí",
  "chiCheŵa, chinyanja",
  "occitan, lenga d'òc",
  "ᐊᓂᔑᓈᐯᒧᐎᓐ",
  "Afaan Oromoo",
  "ଓଡ଼ିଆ",
  "ирон æвзаг",
  "ਪੰਜਾਬੀ, پنجابی‎",
  "पाऴि",
  "język polski, polszczyzna",
  "پښتو",
  "português",
  "Runa Simi, Kichwa",
  "rumantsch grischun",
  "Ikirundi",
  "limba română",
  "русский язык",
  "Ikinyarwanda",
  "संस्कृतम्",
  "sardu",
  "सिन्धी, سنڌي، سندھی‎",
  "Davvisámegiella",
  "yângâ tî sängö",
  "සිංහල",
  "slovenčina, slovenský jazyk",
  "slovenski jezik, slovenščina",
  "gagana fa'a Samoa",
  "chiShona",
  "Soomaaliga, af Soomaali",
  "gjuha shqipe",
  "српски језик",
  "SiSwati",
  "Sesotho",
  "Basa Sunda",
  "Svenska",
  "Kiswahili",
  "தமிழ்",
  "తెలుగు",
  "тоҷикӣ, toğikī, تاجیکی‎",
  "ไทย",
  "ትግርኛ",
  "Türkmen, Түркмен",
  "Wikang Tagalog",
  "Setswana",
  "faka Tonga",
  "Türkçe",
  "Xitsonga",
  "татар теле, tatar tele",
  "Twi",
  "Reo Tahiti",
  "Uyƣurqə, ئۇيغۇرچە‎",
  "українська мова",
  "اردو",
  "O‘zbek, Ўзбек, أۇزبېك‎",
  "Tshivenḓa",
  "Tiếng Việt",
  "Volapük",
  "walon",
  "Wollof",
  "isiXhosa",
  "ייִדיש",
  "Yorùbá",
  "Saɯ cueŋƅ, Saw cuengh",
  "中文 (Zhōngwén), 汉语, 漢語",
  "isiZulu",
];
