import React, { useState, useEffect } from "react";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import PlaceEditModal from "./PlaceEditModal";
import DirectionsDialog from "./DirectionsDialog";
import "./Modal.css";
import HTMLReader from "./HTMLReader";
import { useDispatch } from "react-redux";
import { slice1Actions } from "../../store/reducer";
import PlaceMenu from "../../CommonWeb/PlaceMenu";
import { urlSchemeToFunctions } from "../../CommonWeb/Common";
import { useSelector } from "react-redux";
import { propTypes } from "react-bootstrap/esm/Image";
import Common, { isSearchResult } from "../../Common/Common";
import AlertModal from "./AlertModal";
import Place from "../../Models/Place";
import EventEdit from "./EventEdit";
import MyToolTip from "./MyToolTip";
import GooglePlaces from "../../Models/GooglePlaces";
import AiDialog from "./AiDialog";
import Offcanvas from "react-bootstrap/Offcanvas";
import DirectionsDialogComponent from "../modals/DirectionsDialogComponent";
import MultiSelectModal from "./MultiSelectModal";

const PlaceModal = (props) => {
  const [PlaceEditModalShow, setPlaceEditModalShow] = useState(false);
  const [DirectionsDialogShow, setDirectionsDialogShow] = useState(false);
  const [HTMLReaderModal, setHTMLReaderModal] = useState(false);
  const [HTMLContent, setHTMLContent] = useState("");
  const [SelectedPlace, setSelectedPlace] = useState(null);
  const [ShowEventEdit, setShowEventEdit] = useState(false);
  const [EventEditMode, setShowEventMode] = useState("MODIFY");
  const [EventEditWhere, setShowEventWhere] = useState("after");

  const [AlertModalShow, setAlertModalShow] = useState(false);
  const [AlertModalTitle, setAlertModalTitle] = useState("");
  const [AlertModalMessage, setAlertModalMessage] = useState("");
  const [AlertModalButtons, setAlertModalButtons] = useState([]);
  const [AiDialogShow, setAiDialogShow] = useState(null);
  const [ShowOffcanvas, setShowOffcanvas] = useState(false);
  const [MultiselectItems, setMultiselectItems] = useState([]);
  const [MultiselectSelectedItems, setMultiselectSelectedItems] = useState([]);
  const [MultiselectMode, setMultiselectMode] = useState("uni");
  //const [MultiselectCallback, setMultiselectCallback] = useState(null);
  const [MultiselectTitle, setMultiselectTitle] = useState("Select");
  const [MultiselectSearchText, setMultiselectSearchText] = useState("");
  const [ShowMultiselect, setShowMultiselect] = useState(null);

  const dispatch = useDispatch();
  const waypointsArray = useSelector((state) => state.waypointsArray);
  const from_loc = useSelector((state) => state.fromLoc);
  let user_info = useSelector((state) => state.user_info);
  let SearchResultsPlacesList = useSelector((state) => state.SearchResultsPlacesList);
  let MobileMode = useSelector((state) => state.MobileMode);
  let GuideEditMode = useSelector((state) => state.GuideEditMode);
  let ScheduleName = useSelector((state) => state.ScheduleName);
  let AppSettings = useSelector((state) => state.AppSettings);
  let showHiddenPlace = useSelector((state) => state.showHiddenPlace);

  // useEffect(() => {
  //   if (window.guide_obj != null && typeof window.guide_obj !== "undefined") {
  //     const place = window.guide_obj.places.getPlace(props.i);
  //     setSelectedPlace(place);
  //   }
  // }, [props.i]);

  function handleCenterInMap() {
    const place = window.guide_obj.places.getPlace(props.i);
    if (place == null) return;
    dispatch(slice1Actions.centerMapToPlaces({ value: [place] }));
    //dispatch(slice1Actions.setShowHiddenPlace({ value: [place] }));
    //dispatch(slice1Actions.setGuide({ guide: null }));
    //dispatch(slice1Actions.setMapCenterPlace({ place: place }));

    //const _MapCenter = { lat: place.Lat, lng: place.Longi };
    //dispatch(slice1Actions.setMapCenter({ value: _MapCenter }));

    if (MobileMode) dispatch(slice1Actions.setCurrentAppScreen({ value: "Map" }));
    else window.location.href = "#map-start";
    props.onHide();
  }

  function handleShowStreetView() {
    // const place = window.guide_obj.places.getPlace(props.i);
    // if (place == null) return;
    // const StreetViewLocation = { lat: place.Lat, lng: place.Longi };

    // dispatch(slice1Actions.setStreetViewLocation({ value: StreetViewLocation }));

    PlaceMenu.handleShowStreetView(props.i);
    props.onHide();
  }

  function handleWeatherForecast() {
    PlaceMenu.handleWeatherForecast(props.i);
    props.onHide();
  }

  function handleShowInGoogleMaps() {
    PlaceMenu.handleShowInGoogleMaps(props.i);
    props.onHide();
  }

  function handleShowPlaceAttributes() {
    let htmlString = PlaceMenu.handleShowPlaceAttributes(props.i, SearchResultsPlacesList);
    htmlString = urlSchemeToFunctions(htmlString);
    htmlString = urlSchemeToFunctions(htmlString);
    dispatch(slice1Actions.setHTMLTitle({ value: "Place Attributes" }));
    dispatch(slice1Actions.setHTMLToSHow({ value: htmlString }));
    dispatch(slice1Actions.setShowHTMLReader({ value: true }));
    window.location.href = "#home-start";
    var homeDiv = document.getElementById("home-content-start");
    if (MobileMode) dispatch(slice1Actions.setCurrentAppScreen({ value: "Home" }));
    else homeDiv.scrollIntoView();
    props.onHide();
  }

  const closeHTMLReader = () => {
    setHTMLReaderModal(false);
    window.getTime = function () {};
    props.onHide();
  };

  const handleShowPlaceUrl = () => {
    props.onHide();
    let place = window.guide_obj.places.getPlace(props.i);
    if (place === null) {
      const index = SearchResultsPlacesList.findIndex((x) => x.ID === props.i);
      if (index !== -1) place = SearchResultsPlacesList[index];
    }
    if (place == null) return "";
    let link = place.getFirstHLink();
    if (link.includes("place_id=")) {
      const parts = link.split("=");
      //console.log("parts", parts);
      GooglePlaces.getMoreDetails(getMoreDetailsCallback, parts[1]);
      props.onHide();
      return;
    }
    if (link !== "") {
      let win = window.open(link, "_blank");
      win.focus();
      props.onHide();
      return null;
    }
  };

  const copyPhotoToPlace = () => {
    setMultiselectItems(window.guide_obj.places.getPlacesToDisplay(null));
    setMultiselectSelectedItems([]);
    setMultiselectMode("uni");
    setMultiselectTitle(`Select place`);
    setShowMultiselect(true);
  };

  const selectPlacesCallBack = (Places) => {
    if (Places.length < 1) return;
    const index = SearchResultsPlacesList.findIndex((x) => x.ID === props.i);
    if (index === -1) return;
    let new_place = SearchResultsPlacesList[index];
    let old_place = window.guide_obj.places.getPlace(Places[0].value);
    if (old_place === null) return;
    old_place.Photo_m = new_place.Photo_m;
    old_place.Photo = new_place.Photo;
    window.guide_obj.places.modifyPlace(old_place, old_place.ID);
    props.onHide();
    dispatch(slice1Actions.setGuide({ guide: null }));
  };

  const handleShowPathLink = () => {
    //props.onHide();
    setTimeout(_handleShowPathLink, 300);
  };

  const _handleShowPathLink = () => {
    let place = window.guide_obj.places.getPlace(props.i);
    if (place == null) return "";
    if (place.Link.type === "none") return;
    if (place.Link.type === "video") testVideo(place, 0);
    else if (place.Link.type === "audio") testAudio(place.Link.value);
    else if (place.Link.type === "section") testSection(place.Link.value);
    else if (place.Link.type === "url") testURL(place.Link.value);
    else if (place.Link.type === "path") {
      let pathlink_place = window.guide_obj.places.getPlace(place.Link.value);
      if (pathlink_place === null) return;
      //console.log("pathlink_place", pathlink_place);
      const closest = pathlink_place.findClosestPointInPath(place.Lat, place.Longi);
      //console.log("closest", closest);
      if (closest !== null) {
        testVideo(pathlink_place, closest.elapsed_time);
      }
    }
  };
  const testVideo = (pathlink_place, elapsed_time) => {
    // setYouTubeDialogShow(true);
    // return;
    // if(typeof pathlink_place.Link === "undefined") return;
    if (pathlink_place.Link.type !== "video") return;
    let video_url = pathlink_place.Link.value;
    if (typeof video_url === "undefined") return;
    if (video_url === "") return;

    let video_start = 0;
    let videoLinkOffset = 0;
    try {
      videoLinkOffset = pathlink_place.Link.offset;
      video_start = parseInt(videoLinkOffset);
    } catch (e) {}
    //console.log("=========================testVideo", video_start);
    video_start += elapsed_time;
    //console.log("testVideo", video_start, elapsed_time);
    //if (video_url.includes("https://www.youtu") || video_url.includes("https://youtu")) {
    let video_url_parts = video_url.split("/");
    let yvideo = video_url_parts[video_url_parts.length - 1];
    yvideo = yvideo.replace("watch?v=", "");

    // let video_html = `<iframe id=MyYoutubeIframe" style="max-width: 100%; width: 640px; height: 480px;"
    //                       src="https://www.youtube.com/embed/${yvideo}?start=${video_start}&autoplay=1&mute=1" frameborder="0" allowfullscreen="allowfullscreen">
    //                   </iframe>`;

    // let video_html = `<div>This wil be rendered</div>
    //                   <script>
    //                     alert('testing')
    //                   </script>`;
    let random = Math.random().toString();
    let video_html = `<style>.container {
      position: relative;
      width: 100%;
      height: 0;
      padding-bottom: 56.25%;
  }
  .video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
  }
  
    </style>
    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/charts.css/dist/charts.min.css">
    <div class="container">
      <div id="player${random}" class="video"></div>
    </div>
    <!-- <div class="chart-container" style="position: relative; height:150px; width:100%;">
      <canvas id="myChart" style="width: 700; height: 150px;" height="150" width="800"></canvas>
    </div>-->
    <script src="https://www.youtube.com/iframe_api"></script>
    <!-- Write your comments here 
    <script src="https://cdn.jsdelivr.net/npm/chart.js"></script>
    <script src="https://cdnjs.cloudflare.com/ajax/libs/chartjs-plugin-annotation/2.1.0/chartjs-plugin-annotation.min.js"></script> -->
    <script id='ytscript${random}'>
          var player;
          if(window.YouTubeIframeAPIReady === true) {
            player = new YT.Player('player${random}', {
            height: '390',
            width: 'auto',
            videoId: '${yvideo}',
        playerVars: { 'autoplay': 1, 'controls': 1, 'start': ${video_start}, 'mute': 1 },
            events: {
              'onReady': onPlayerReady,
              'onStateChange': onPlayerStateChange
            }
          });
          }
        
          function onYouTubeIframeAPIReady() {
            window.YouTubeIframeAPIReady = true;
            player = new YT.Player('player${random}', {
              height: '390',
              width: 'auto',
              videoId: '${yvideo}',
          playerVars: { 'autoplay': 1, 'controls': 1, 'start': ${video_start}, 'mute': 1 },
              events: {
                'onReady': onPlayerReady,
                'onStateChange': onPlayerStateChange
              }
            });
          }
          function onPlayerReady(event) {
            event.target.playVideo();
            // const ctx = document.getElementById('myChart');
            // window.LatitudeChart = new Chart(ctx, {
            //   type: 'line',
            //   data: {
            //     labels: [],
            //     datasets: [{
            //       label: '',
            //       data: [],
            //       borderWidth: 1,
            //       tension: 0.1
            //     }]
            //   },
            // options: {
            //   tooltips: {
            //     enabled: false,
            //   },
            //   legend: {
            //     display: false
            //   },
            //   scales: {
            //     xAxes: [{display: false}],
            //     yAxes: [{display: false}],
            //   }
            // }
            // });
            // const time = player.getCurrentTime();
            // const state = player.getPlayerState();
            // window.TimeFromYoutube(time, ${pathlink_place.ID}, state, ${videoLinkOffset});
            // const new_vals = window.UpdateChart(time, ${pathlink_place.ID}, state, ${videoLinkOffset}, false);
            // chart.data = new_vals.data
            // chart.options = new_vals.options
            // chart.update("none");
            setTimeout(() => getTime(false), 1000);
          }

        function getTime(onlyLoc) {
            const time = player.getCurrentTime();
            const state = player.getPlayerState();
            window.TimeFromYoutube(time, ${pathlink_place.ID}, state, ${videoLinkOffset});
            // const new_vals = window.UpdateChart(time, ${pathlink_place.ID}, state, ${videoLinkOffset}, false);
            // window.LatitudeChart.data = new_vals.data
            // window.LatitudeChart.options = new_vals.options
            // window.LatitudeChart.update("none");
            setTimeout(() => getTime(true), 1000);
          }

          function onPlayerStateChange(event) {
            window.StateFromYoutube(event);
          }

    </script>`;

    // dispatch(slice1Actions.resetHomeScreen({ value: null }));
    // dispatch(slice1Actions.setHTMLTitle({ value: "Video" }));
    // dispatch(slice1Actions.setHTMLToSHow({ value: video_html }));
    // dispatch(slice1Actions.setShowHTMLReader({ value: true }));
    // window.location.href = "#home-start";
    // let homeDiv = document.getElementById("home-content-start");
    // homeDiv.scrollIntoView();

    setHTMLContent(video_html);
    setHTMLReaderModal(true);
  };

  const testAudio = (audio_url) => {
    let audioType = "audio/mpeg";
    if (audio_url.toUpperCase().endsWith("WAV")) audioType = "audio/wav";
    else if (audio_url.toUpperCase().endsWith("OGG")) audioType = "audio/ogg";
    else if (audio_url.toUpperCase().endsWith("MP3")) audioType = "audio/mpeg";
    else audioType = "audio/mp4";
    const audio_html = `<audio style="max-width: 100%; width: 600px;" controls="controls" controlsList="nodownload" autoplay>
          <source src="${audio_url}" type="${audioType}">
        Audio format not supported use mp3, wav, m4a or ogg.
        </audio>`;
    // dispatch(slice1Actions.setHTMLTitle({ value: "Audio" }));
    // dispatch(slice1Actions.setHTMLToSHow({ value: audio_html }));
    // dispatch(slice1Actions.setShowHTMLReader({ value: true }));
    // window.location.href = "#home-start";
    // let homeDiv = document.getElementById("home-content-start");
    // homeDiv.scrollIntoView();
    setHTMLContent(audio_html);
    setHTMLReaderModal(true);
  };

  const testSection = (section_id) => {
    let section = window.guide_obj.findSection(section_id);
    if (section == null) {
      //Alert.alert("section not found");
      return;
    }
    let htmlString = urlSchemeToFunctions(section.html);
    dispatch(slice1Actions.setHTMLTitle({ value: section.Title }));
    dispatch(slice1Actions.setHTMLToSHow({ value: htmlString }));
    dispatch(slice1Actions.setShowHTMLReader({ value: true }));
    window.location.href = "#home-start";
    let homeDiv = document.getElementById("home-content-start");
    homeDiv.scrollIntoView();
    props.onHide();

    // setHTMLContent(htmlString);
    // setHTMLReaderModal(true);
  };

  const testURL = (url) => {
    let win = window.open(url, "_blank");
    //win = window.open("about:blank", "", "_blank");
    //win.document.write(_url);
    win.focus();
  };

  const getMoreDetailsCallback = (place_id, details) => {
    if (details === null) return;
    let hlink = "";
    //console.log("getMoreDetailsCallback", details);
    if (typeof details.url != "undefined") {
      if (details.url.includes(";")) {
        const urls = details.url.split(";");
        hlink = `${urls[0]}`;
      } else hlink = `${details.url}`;
    }
    //console.log("getMoreDetailsCallback", hlink);
    if (hlink !== "") {
      let win = window.open(hlink, "_blank");
      win.focus();
      return null;
    }
  };

  // function handleShowPlaceUrl() {
  //   //console.log("handleShowPlaceURL");
  //   let htmlString = PlaceMenu.handleShowPlaceUrl(props.i);
  //   // if (htmlString == null) return;
  //   // htmlString = urlSchemeToFunctions(htmlString);
  //   // dispatch(slice1Actions.setHTMLTitle({ value: "Place Info" }));
  //   // dispatch(slice1Actions.setHTMLToSHow({ value: htmlString }));
  //   // dispatch(slice1Actions.setShowHTMLReader({ value: true }));
  //   // window.location.href = "#home-start";
  //   // var homeDiv = document.getElementById("home-content-start");
  //   // if (MobileMode) dispatch(slice1Actions.setCurrentAppScreen({ value: "Home" }));
  //   // else homeDiv.scrollIntoView();
  //   props.onHide();
  // }

  function handleShowPlaceInfo() {
    const place = window.guide_obj.places.getPlace(props.i);
    if (place == null) return;
    let htmlString = place.Info;
    if (htmlString == null) return;
    htmlString = urlSchemeToFunctions(htmlString);
    dispatch(slice1Actions.setHTMLTitle({ value: "Place Info" }));
    dispatch(slice1Actions.setHTMLToSHow({ value: htmlString }));
    dispatch(slice1Actions.setShowHTMLReader({ value: true }));
    window.location.href = "#home-start";
    var homeDiv = document.getElementById("home-content-start");
    if (MobileMode) dispatch(slice1Actions.setCurrentAppScreen({ value: "Home" }));
    else homeDiv.scrollIntoView();
    props.onHide();
  }

  function handleGoToYelp() {
    PlaceMenu.handleGoToYelp(props.i);
    props.onHide();
  }

  function handleGoToFoursquare() {
    PlaceMenu.handleGoToFoursquare(props.i);
    props.onHide();
  }

  function handleSetAsCurrentLocation() {
    const place = window.guide_obj.places.getPlace(props.i);
    if (place == null) return;
    const CurrentLocation = { latitude: place.Lat, longitude: place.Longi };

    dispatch(slice1Actions.setCurrentLocation({ value: CurrentLocation }));
    dispatch(slice1Actions.setGuide({ guide: null }));
    props.onHide();
  }

  function handleEditPlace() {
    setPlaceEditModalShow(true);
    //props.onHide();
  }

  function handleDeletePlace() {
    const place = window.guide_obj.places.getPlace(props.i);
    if (place === null) {
      props.onHide();
      return;
    }
    window.guide_obj.places.deletePlace(place);
    if (showHiddenPlace.length > 0) {
      let hidden_places = [];
      for (let i = 0; i < showHiddenPlace.length; i++) {
        if (showHiddenPlace[i].ID !== place.ID) {
          hidden_places.push(showHiddenPlace[i]);
          break;
        }
      }
      dispatch(slice1Actions.setShowHiddenPlace({ value: hidden_places }));
    }
    //const index = _ShowHiddenPlace.findIndex((x) => x.ID === place.ID);
    dispatch(slice1Actions.setGuide({ guide: null }));
    props.onHide();
  }

  const handleModifyLocation = () => {
    //const place = window.guide_obj.places.getPlace(props.i);
    //if (place == null) return;
    props.action("edit shape");
    props.onHide();
  };

  function showDirectionsPlace() {
    const _place = window.guide_obj.places.getPlace(props.i);
    if (_place == null) return;
    showDirections({ Lat: _place.Lat, Longi: _place.Longi }, null, null);
  }

  function showDirectionsPOI(_poi) {
    showDirections({ Lat: _poi.coordinate.latitude, Longi: _poi.coordinate.longitude });
  }

  function showDirectionsMap(_mapCoordinate) {
    showDirections({ Lat: _mapCoordinate.latitude, Longi: _mapCoordinate.longitude });
  }

  function showDirections(to_loc) {
    let waypoints = "";
    if (waypointsArray.length !== 0) {
      waypoints = "&waypoints=";
      for (let i = waypointsArray.length - 1; i >= 0; i--) {
        waypoints += waypoints === "&waypoints=" ? "" : "|";
        waypoints += `${waypointsArray[i].Lat},${waypointsArray[i].Longi}`;
      }
    }
    let _url = `https://www.google.com/maps/dir/?api=1&destination=${to_loc.Lat},${to_loc.Longi}${waypoints}`;
    if (from_loc !== null)
      _url = `https://www.google.com/maps/dir/?api=1&origin=${from_loc.Lat},${from_loc.Longi}&destination=${to_loc.Lat},${to_loc.Longi}${waypoints}`;
    //console.log(action);

    let win = window.open(_url, "_blank");
    win.focus();

    dispatch(slice1Actions.setFromLoc({ value: null }));
    dispatch(slice1Actions.setWaypointsArray({ value: [] }));
    props.onHide();
  }

  function fromDirectionsPlace() {
    const _place = window.guide_obj.places.getPlace(props.i);
    if (_place == null) return;
    let from_loc = { Name: _place.Name, Lat: _place.Lat, Longi: _place.Longi };
    //dispatch(slice1Actions.setFromLoc({ value: from_loc }));
    //dispatch(slice1Actions.setWaypointsArray({ value: [] }));

    dispatch(
      slice1Actions.setDirectionsFromLocation({
        value: from_loc,
      })
    );
    window.DirectionsFromLocation = from_loc;

    props.onHide();
  }

  function toDirectionsPlace() {
    const _place = window.guide_obj.places.getPlace(props.i);
    if (_place == null) return;
    let to_loc = { Name: _place.Name, Lat: _place.Lat, Longi: _place.Longi };
    dispatch(slice1Actions.setToLoc({ value: to_loc }));
    //dispatch(slice1Actions.setWaypointsArray({ value: [] }));
    dispatch(
      slice1Actions.setDirectionsToLocation({
        value: to_loc,
      })
    );
    window.DirectionsToLocation = to_loc;
    showDirectionsDialog();
    //props.onHide();
  }

  function fromDirectionsPOI(_poi) {
    let from_loc = { Lat: _poi.coordinate.latitude, Longi: _poi.coordinate.longitude };
    dispatch(slice1Actions.setFromLoc({ value: from_loc }));
    dispatch(slice1Actions.setWaypointsArray({ value: [] }));
    props.onHide();
  }

  function fromDirectionsMap(_mapCoordinate) {
    let from_loc = { Lat: _mapCoordinate.latitude, Longi: _mapCoordinate.longitude };
    dispatch(slice1Actions.setFromLoc({ value: from_loc }));
    dispatch(slice1Actions.setWaypointsArray({ value: [] }));
    props.onHide();
  }

  function addWayPointPlace() {
    // const _place = window.guide_obj.places.getPlace(props.i);
    // if (_place == null) return;
    // let _waypointsArray = JSON.parse(JSON.stringify(waypointsArray));
    // _waypointsArray.push({ Lat: _place.Lat, Longi: _place.Longi });
    // dispatch(slice1Actions.setWaypointsArray({ value: _waypointsArray }));
    // props.onHide();

    const _place = window.guide_obj.places.getPlace(props.i);
    if (_place == null) return;
    //let DirectionsWaypointPlaces = JSON.parse(JSON.stringify(window.DirectionsWaypointPlaces));
    let DirectionsWaypointsLocation = JSON.parse(JSON.stringify(window.DirectionsWaypointsLocation));
    //DirectionsWaypointPlaces.push(_place);
    DirectionsWaypointsLocation.push({ Name: _place.Name, Lat: _place.Lat, Longi: _place.Longi });
    //window.DirectionsWaypointPlaces = DirectionsWaypointPlaces;
    window.DirectionsWaypointsLocation = DirectionsWaypointsLocation;
    dispatch(slice1Actions.setDirectionsWaypointsLocation({ value: DirectionsWaypointsLocation }));
    props.onHide();
  }

  function addWayPointPOI() {
    const _place = window.guide_obj.places.getPlace(props.i);
    if (_place == null) return;
    let _waypointsArray = JSON.parse(JSON.stringify(waypointsArray));
    _waypointsArray.push({ Lat: _place.Lat, Longi: _place.Longi });
    dispatch(slice1Actions.setWaypointsArray({ value: _waypointsArray }));
    props.onHide();
  }

  function addWayPointMap() {
    const _place = window.guide_obj.places.getPlace(props.i);
    if (_place == null) return;
    let _waypointsArray = JSON.parse(JSON.stringify(waypointsArray));
    _waypointsArray.push({ Lat: _place.Lat, Longi: _place.Longi });
    dispatch(slice1Actions.setWaypointsArray({ value: _waypointsArray }));
    props.onHide();
  }
  const getSearchPlace = () => {
    const place = SearchResultsPlacesList.find((x) => x.ID === props.i);
    return place;
  };

  const addResultToguide = () => {
    dispatch(slice1Actions.addResultToGuide({ value: props.i }));
    dispatch(slice1Actions.setGuide({ guide: null }));
    props.onHide();
  };

  const removeFromSearchResults = () => {
    dispatch(slice1Actions.removeFromSearchResults({ value: props.i }));
    dispatch(slice1Actions.setGuide({ guide: null }));
    props.onHide();
  };

  const update_display = () => {
    dispatch(slice1Actions.setGuide({ guide: null }));
  };

  const PlaceEditCallback = () => {
    dispatch(slice1Actions.resetHomeScreen({ value: null }));
    dispatch(slice1Actions.setGuide({ guide: null }));
  };

  const _isSearchResult = () => {
    const index = SearchResultsPlacesList.findIndex((x) => x.ID === props.i);
    return index !== -1;
  };

  const showDirectionsDialog = () => {
    // setDirectionsDialogShow(true);
    dispatch(slice1Actions.setShowOffcanvas({ value: true }));
    props.onHide();
  };

  // const filterPlacesByArea = () => {
  //   props.filter(props.i, true);
  // };

  const filterPlacesByArea = () => {
    const _place = window.guide_obj.places.getPlace(props.i);
    if (_place == null) return;
    let coordinates = _place.getCoordinates();
    let path = [];
    coordinates.forEach((element) => {
      path.push({ lat: element.latitude, lng: element.longitude });
    });
    // let coordinates = [];
    // selectedShape.coordinates.forEach((element) => {
    //   coordinates.push({ lat: element.latitude, lng: element.longitude });
    // });
    const area = new window.google.maps.Polygon({
      paths: path,
      strokeColor: "#FF0000",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#FF0000",
      fillOpacity: 0.35,
      editable: true,
    });
    let containedPlaces = [];
    //const allPlaces = window.guide_obj.places.getPlacesToDisplay(null);
    const allPlaces = window.guide_obj.places.getChoosePlacesList();
    for (let i = 0; i < allPlaces.length; i++) {
      const place = allPlaces[i].value; //window.guide_obj.places.getPlace(_place.ID)
      const latLng = new window.google.maps.LatLng(place.Lat, place.Longi); //{ lat: place.Lat, lng: place.Longi };
      if (window.google.maps.geometry.poly.containsLocation(latLng, area)) {
        containedPlaces.push(place);
      }
    }
    dispatch(slice1Actions.ClearAllFilters({ value: null }));
    dispatch(slice1Actions.SetPlacesFilter({ value: containedPlaces }));
    dispatch(slice1Actions.setGuide({ guide: null }));
    props.onHide();
  };

  const myAlert = (message, title, buttons) => {
    setAlertModalMessage(message);
    setAlertModalTitle(title == null ? "Message" : title);
    setAlertModalButtons(buttons == null ? [{ Title: "OK", Method: null }] : buttons);
    setAlertModalShow(true);
  };
  const displayMenuInfo = (menu_item_info) => {
    myAlert(menu_item_info, "Info");
  };

  const _AnimatePath = () => {
    const _place = window.guide_obj.places.getPlace(props.i);
    if (_place == null) {
      props.onHide();
      return;
    }
    props.onHide();
    const latLongs = _place.getLatLongsForPlace();
    console.log("latLongs", latLongs);
    if (latLongs.length === 0) return;
    //const latLng = new window.google.maps.LatLng(latLongs[0].Lat, latLongs[0].Longi);
    //dispatch(slice1Actions.setStreetViewLocation({ guide: latLng }));
    //const _MapCenter = latLongs[0];
    //dispatch(slice1Actions.centerMapToLoc({ value: _MapCenter }));
    AnimatePath1(latLongs, 0);
    //window.AnimatePath(_place);
  };

  const AnimatePath1 = (latLongs, i) => {
    if (i > latLongs.length - 1) return;
    const _MapCenter = latLongs[i];
    dispatch(slice1Actions.centerMapToLoc({ value: _MapCenter }));
    setTimeout(() => AnimatePath2(latLongs, (i += 1)), 1000);
  };

  const AnimatePath2 = (latLongs, i) => {
    if (i > latLongs.length - 1) return;
    const _MapCenter = latLongs[i];
    dispatch(slice1Actions.centerMapToLoc({ value: _MapCenter }));
    setTimeout(() => AnimatePath1(latLongs, (i += 1)), 1000);
  };

  const handleReverseDirection = () => {
    const _place = window.guide_obj.places.getPlace(props.i);
    if (_place == null) {
      props.onHide();
      return;
    }
    _place.reverseLineDirection();
    dispatch(slice1Actions.setGuide({ guide: null }));
    props.onHide();
    return;
    let points = _place.Points;
    if (points === null) {
      props.onHide();
      return;
    }
    let points_ar = points.split(":");
    let points_ar_rev = points_ar.reverse();
    let new_points = points_ar_rev.join(":");
    _place.Points = new_points;
    dispatch(slice1Actions.setGuide({ guide: null }));
    props.onHide();
    // let new_points = "";
    // for (let i = points_ar.length - 1; i >= 0; i--) {
    //   if (new_points !== "") new_points += ":";
    //   new_points += points_ar[i];
    // }
    //_place.Points = new_points;

    //let new_place = JSON.parse(JSON.stringify(_place));
    //let new_place = Object.assign({}, _place);

    // let new_place = new Place(window.guide_obj.places);
    // const id = _place.ID;
    // new_place.copy(_place);
    // new_place.ID = id;
    // new_place.Points = new_points;
    //window.guide_obj.places.deletePlace(_place);
    //window.guide_obj.places.addPlace(_place);
    //setTimeout(() => add_place(new_place), 1000);
  };

  // const add_place = (place) => {
  //   window.guide_obj.places.addPlace(place);
  //   dispatch(slice1Actions.setGuide({ guide: null }));
  // };

  function handleAddEventA() {
    setShowEventMode("ADD");
    setShowEventWhere("end");
    setShowEventEdit(true);
  }
  const showWebImages = () => {
    const _place = window.guide_obj.places.getPlace(props.i);
    if (_place == null) {
      props.onHide();
      return;
    }
    let _url = `https://www.google.com/search?tbm=isch&q=${_place.Name}`;
    if (_place.City !== "") _url += "+" + _place.City;
    if (_place.Country !== "") _url += "+" + _place.Country;
    let win = window.open(_url, "_blank");
    win.focus();
  };

  const showWebVideos = () => {
    const _place = window.guide_obj.places.getPlace(props.i);
    if (_place == null) {
      props.onHide();
      return;
    }
    let _url = `https://www.youtube.com/results?search_query=${_place.Name}`;
    if (_place.City !== "") _url += "+" + _place.City;
    if (_place.Country !== "") _url += "+" + _place.Country;
    let win = window.open(_url, "_blank");
    win.focus();
  };

  const showWebArticles = () => {
    const _place = window.guide_obj.places.getPlace(props.i);
    if (_place == null) {
      props.onHide();
      return;
    }
    let _url = `http://www.google.com/search?q=${_place.Name}`;
    if (_place.City !== "") _url += "+" + _place.City;
    if (_place.Country !== "") _url += "+" + _place.Country;
    let win = window.open(_url, "_blank");
    win.focus();
  };

  const EventEditCallback = () => {
    dispatch(slice1Actions.resetHomeScreen({ value: null }));
    dispatch(slice1Actions.setGuide({ guide: null }));
    props.onHide();
  };

  const combinePlaces = () => {
    const _place = window.guide_obj.places.getPlace(props.i);
    if (_place == null) {
      props.onHide();
      return;
    }
    const items = window.guide_obj.places.getPlacesToDisplay();
    dispatch(slice1Actions.setMultiselectCallback({ value: combinePlacesCallBack }));
    dispatch(slice1Actions.setMultiselectItems({ value: items }));
    dispatch(slice1Actions.setMultiselectSelectedItems({ value: [] }));
    dispatch(slice1Actions.setMultiselectMode({ value: "multi" }));
    dispatch(slice1Actions.setMultiselectSearchText({ value: "" }));
    dispatch(slice1Actions.setMultiselectTitle({ value: "Select Places" }));
    dispatch(slice1Actions.setShowMultiselect({ value: true }));
  };

  const combinePlacesCallBack = (places) => {
    const _place = window.guide_obj.places.getPlace(props.i);
    if (_place == null) {
      props.onHide();
      return;
    }
    if (places.length === 0) return;
    //console.log("combinePlacesCallBack", places);
    window.guide_obj.places.combineWithPlaces(_place, places);
    dispatch(slice1Actions.setGuide({ guide: null }));
    props.onHide();
    // updateMainScheduleAndDoc();
  };

  // const updateMainScheduleAndDoc = () => {
  //   myAlert("Update Main Schedule and Guide document with these changes?", "Update", [
  //     { Title: "OK", Method: _updateMainScheduleAndDoc },
  //     { Title: "CANCEL", Method: () => props.onHide() },
  //   ]);
  // };

  // const _updateMainScheduleAndDoc = () => {
  //   if (ScheduleName !== "Main") window.guide_obj.schedule.copySchedule(ScheduleName, "Main");
  //   const html = window.guide_obj.createHTMLItinerary(ScheduleName, GuideSettings.itinerary);
  //   //window.guide_obj.document = html;
  //   //dispatch(slice1Actions.setGuide({ guide: null }));

  //   window.guide_obj.document = html;
  //   dispatch(slice1Actions.clearHTMLStack());
  //   //dispatch(slice1Actions.popHTMLStack());
  //   dispatch(slice1Actions.setHomeScreen({ value: html }));
  //   props.onHide();
  // };

  if (ShowEventEdit)
    return (
      <EventEdit
        show={ShowEventEdit}
        onHide={() => setShowEventEdit(false)}
        event={null}
        mode={EventEditMode}
        place={window.guide_obj.places.getPlace(props.i)}
        where={EventEditWhere}
        schedule_name={ScheduleName}
        callback={() => EventEditCallback()}
      />
    );
  if (PlaceEditModalShow)
    return (
      <PlaceEditModal
        show={PlaceEditModalShow}
        onHide={() => setPlaceEditModalShow(false)}
        img={props.img}
        name={props.name}
        index={props.i}
        mainIndex={props.mainIndex}
        id={props.id}
        place={props.place}
        groups={window.guide_obj == null ? "" : window.guide_obj.groupNamesFromIDs(props.place.Group)}
        category={window.guide_obj == null ? "" : window.guide_obj.categoryNamesFromIDs(props.place.Category)}
        user_info={user_info}
        mode={"Edit"}
        update_display={PlaceEditCallback}
        onHidePrevious={props.onHide}
      />
    );
  let placeType = props.place.PType === "Icon" ? "Location" : props.place.PType === "PolyLine" ? "Path" : "Area";

  if (AlertModalShow)
    return (
      <AlertModal
        show={AlertModalShow}
        onHide={() => setAlertModalShow(false)}
        // AlertModalTitle={AlertModalTitle}
        // AlertModalMessage={AlertModalMessage}
        // AlertModalButtons={AlertModalButtons}
        attributes={{
          onHide: () => setAlertModalShow(false),
          AlertModalTitle: AlertModalTitle,
          AlertModalMessage: AlertModalMessage,
          AlertModalButtons: AlertModalButtons,
        }}
      />
    );
  else if (HTMLReaderModal)
    return <HTMLReader html={HTMLContent} show={HTMLReaderModal} onHide={() => closeHTMLReader()} />;
  return (
    <div className="modal">
      <Modal
        {...props}
        dialogClassName="myModalClass"
        scrollable={true}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {/* <Modal.Header>
          <div style={{ width: "100%" }}>
            <div className="sort">
              <div className="dialog-heading">
                <div className="dialog-heading color-blue">
                  <div>
                    <h4>{`${placeType} Menu`}</h4>
                  </div>

                  <div>
                    <i className={`fas fa-times`} onClick={() => props.onHide()}></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Header> */}

        <Modal.Body>
          <div className="sort">
            <div className="flex-container-middle color-blue">
              <div>
                <h4>{`${placeType} Menu`}</h4>
              </div>

              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <div>
                <i
                  title="Close"
                  className={`fas fa-times color-black`}
                  style={{ color: "black" }}
                  onClick={() => props.onHide()}
                ></i>
              </div>
            </div>
            {/* <p className="text-center">{props.name}</p> */}
            {/* <div className="image">
            <img
              src={props.img}
              alt=""
              className="text-center rounded"
              // height="100px"
              width="100px"
              // overflow="hidden"
              style={{ objectFit: "cover", maxHeight: "75px" }}
            />
          </div> */}
            <div className="multiselect-list">
              {_isSearchResult() ? (
                <div>
                  <div key={`container`} className="flex-container">
                    <div className="flex-items-left" key={`left`}>
                      <MyToolTip loc="middle" msg="Add this search result to the guide">
                        <p onClick={addResultToguide}>Add to Guide</p>
                      </MyToolTip>
                    </div>
                  </div>

                  {props.place.photo_m !== "" ? (
                    <MyToolTip loc="middle" msg="Copy the photo from this place to another place.">
                      <p onClick={copyPhotoToPlace}>{`Copy Photo to Place`}</p>
                    </MyToolTip>
                  ) : null}

                  <MyToolTip loc="middle" msg="Remove this search result">
                    <p onClick={removeFromSearchResults}>Remove From search results</p>
                  </MyToolTip>

                  <MyToolTip loc="middle" msg="Show the attributes of the selected place, e.g name etc.">
                    <p onClick={handleShowPlaceAttributes}>{`Show ${placeType} Attributes`}</p>
                  </MyToolTip>

                  {props.place.Hlink !== "" ? (
                    <MyToolTip loc="middle" msg="Show the first URL associated with this place, e.g. wikipedia entry">
                      <p onClick={handleShowPlaceUrl}>{`Show ${placeType} URL`}</p>
                    </MyToolTip>
                  ) : null}
                </div>
              ) : (
                <div>
                  <MyToolTip loc="middle" msg="Center the place in the map">
                    <p onClick={handleCenterInMap}>Center in Map</p>
                  </MyToolTip>

                  {/* {props.place.PType === "Icon" ? (
                    <MyToolTip
                      loc="middle"
                      msg="Get directions between 2 places. You can also add waypoints (i.e. places along the way)"
                    >
                      <p onClick={showDirectionsDialog}>Directions</p>
                    </MyToolTip>
                  ) : null} */}
                  {props.place.PType === "Icon" ? (
                    <MyToolTip
                      loc="middle"
                      msg="Get directions from this place. You can also add waypoints (i.e. places along the way)"
                    >
                      <p onClick={fromDirectionsPlace}>Directions from here</p>
                    </MyToolTip>
                  ) : null}

                  {props.place.PType === "Icon" ? (
                    <MyToolTip loc="middle" msg="Add as a waypoint (i.e. place along the way) for directions">
                      <p onClick={addWayPointPlace}>Directions add waypoint</p>
                    </MyToolTip>
                  ) : null}

                  {props.place.PType === "Icon" ? (
                    <MyToolTip
                      loc="middle"
                      msg="Get directions to this 2 place. You can also add waypoints (i.e. places along the way)"
                    >
                      <p onClick={toDirectionsPlace}>Directions to here</p>
                    </MyToolTip>
                  ) : null}

                  {props.place.PType === "Icon" ? (
                    <MyToolTip loc="middle" msg="Show the street view of the place in google maps">
                      <p onClick={handleShowStreetView}>Show Street View</p>
                    </MyToolTip>
                  ) : null}

                  <MyToolTip loc="middle" msg="Launch a web app that shows the weather forecast for the selected place">
                    <p onClick={handleWeatherForecast}>Show Weather Forecast</p>
                  </MyToolTip>

                  <MyToolTip loc="middle" msg="Ask travel related questions for the selected place">
                    <p onClick={() => setAiDialogShow(true)}>Ask questions</p>
                  </MyToolTip>

                  {props.place.PType === "Icon" ? (
                    <MyToolTip loc="middle" msg="Show the selected place in google maps">
                      <p onClick={handleShowInGoogleMaps}>Show in Google Maps</p>
                    </MyToolTip>
                  ) : null}
                  {props.place.PType === "Icon" ? (
                    <MyToolTip loc="middle" msg="Show images of this place from the web">
                      <p onClick={showWebImages}>Show Images from Web</p>
                    </MyToolTip>
                  ) : null}
                  {props.place.PType === "Icon" ? (
                    <MyToolTip loc="middle" msg="Show videos of this place from the web">
                      <p onClick={showWebVideos}>Show Videos from Web</p>
                    </MyToolTip>
                  ) : null}
                  {props.place.PType === "Icon" ? (
                    <MyToolTip loc="middle" msg="Show articles of this place from the web">
                      <p onClick={showWebArticles}>Show Articles from Web</p>
                    </MyToolTip>
                  ) : null}

                  <MyToolTip loc="middle" msg="Show the attributes of the selected place, e.g name etc.">
                    <p onClick={handleShowPlaceAttributes}>{`Show ${placeType} Attributes`}</p>
                  </MyToolTip>

                  {props.place.Info !== "" ? (
                    <MyToolTip loc="middle" msg="Show the information associated with this place.">
                      <p onClick={handleShowPlaceInfo}>{`Show ${placeType} info`}</p>
                    </MyToolTip>
                  ) : null}

                  {props.place.Hlink !== "" ? (
                    <MyToolTip loc="middle" msg="Show the first URL associated with this place, e.g. wikipedia entry">
                      <p onClick={handleShowPlaceUrl}>{`Show ${placeType} URL`}</p>
                    </MyToolTip>
                  ) : null}

                  {props.place.Link.type !== "none" ? (
                    <MyToolTip loc="middle" msg="Show the Place link, e.g. video, audio, path, etc.">
                      <p onClick={handleShowPathLink}>{`Show Place link`}</p>
                    </MyToolTip>
                  ) : null}

                  {props.place.photo_m !== "" ? (
                    <MyToolTip loc="middle" msg="Copy the photo from this place to another place.">
                      <p onClick={copyPhotoToPlace}>{`Copy Photo to Place`}</p>
                    </MyToolTip>
                  ) : null}

                  {props.place.PType === "Icon" ? (
                    <MyToolTip
                      loc="middle"
                      msg="Set the current location to the location of this place. Note this effects distance calculations."
                    >
                      <p onClick={handleSetAsCurrentLocation}>Set as current location</p>
                    </MyToolTip>
                  ) : null}

                  {props.place.PType === "Polygon" ? (
                    <MyToolTip
                      loc="middle"
                      msg="Filter the places in the guide based on which places are inside this area. This is useful when you want to modify attributes of all of those places, e.g. category, group etc."
                    >
                      <p onClick={filterPlacesByArea}>{`Filter by places inside area`}</p>
                    </MyToolTip>
                  ) : null}

                  {GuideEditMode ? (
                    <MyToolTip loc="middle" msg="Create an event to visit this place.">
                      <p onClick={handleAddEventA}>{`Add ${placeType} to schedule`}</p>
                    </MyToolTip>
                  ) : null}

                  {GuideEditMode ? (
                    <MyToolTip loc="middle" msg={`Edit the ${placeType} attributes, e.g name etc.`}>
                      {/* <p onClick={handleEditPlace}>{`Edit ${placeType} Attributes`}</p> */}
                      <p onClick={handleEditPlace}>{`Edit attributes`}</p>
                    </MyToolTip>
                  ) : null}

                  {GuideEditMode ? (
                    <MyToolTip loc="middle" msg={`Combine the ${placeType} info, e.g name etc. from multiple places`}>
                      {/* <p onClick={handleEditPlace}>{`Edit ${placeType} Attributes`}</p> */}
                      <p onClick={combinePlaces}>{`Combine Places info`}</p>
                    </MyToolTip>
                  ) : null}

                  {typeof props.action !== "undefined" && GuideEditMode ? (
                    <MyToolTip
                      loc="middle"
                      msg="Modify the location of this place. Note that you do that by dragging the icon in the map"
                    >
                      <p onClick={handleModifyLocation}>{`Edit position on Map`}</p>
                    </MyToolTip>
                  ) : null}

                  {props.place.PType === "PolyLine" ? (
                    <MyToolTip loc="middle" msg="Reverse the direction of the path">
                      <p onClick={handleReverseDirection}>Reverse Path direction</p>
                    </MyToolTip>
                  ) : null}

                  {/* {props.place.PType === "PolyLine" ? (
                    <MyToolTip loc="middle" msg="Animate path in map">
                      <p onClick={_AnimatePath}>Animate path in map</p>
                    </MyToolTip>
                  ) : null} */}

                  {GuideEditMode ? (
                    <MyToolTip loc="middle" msg="Delete this place from the guide. Note that you cannot recover it.">
                      <p onClick={handleDeletePlace}>{`Delete ${placeType}`}</p>
                    </MyToolTip>
                  ) : null}

                  {/* <p onClick={handleAddPlace}>Add Place</p> */}
                </div>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {DirectionsDialogShow ? (
        <DirectionsDialog
          show={DirectionsDialogShow}
          id={props.i}
          onHide={() => setDirectionsDialogShow(false)}
          onHidePrevious={props.onHide}
        ></DirectionsDialog>
      ) : null}
      {AiDialogShow ? (
        <AiDialog
          show={AiDialogShow}
          onHide={() => setAiDialogShow(false)}
          questions={AppSettings.questions2}
          id={props.i}
          user_info={user_info}
        />
      ) : null}
      {ShowMultiselect ? (
        <MultiSelectModal
          show={ShowMultiselect}
          onHide={() => setShowMultiselect(false)}
          MultiselectItems={MultiselectItems}
          MultiselectSelectedItems={MultiselectSelectedItems}
          MultiselectMode={MultiselectMode}
          MultiselectCallback={selectPlacesCallBack}
          MultiselectTitle={MultiselectTitle}
          MultiselectSearchText={MultiselectSearchText}
        />
      ) : null}
    </div>
  );
};

export default PlaceModal;
