export const tickets_html = `<html>
<head>
  <meta http-equiv="content-type" content="text/html; charset=windows-1252">
  <title>Tickets</title>
  <style type="text/css">
.c0 { font-family: &#39;Arial&#39;; text-decoration: none } .c1 { margin: 0px 0px 10px; padding: 10px 0px; font-size: 16px; text-decoration: none } 
.c2 { font-weight: bold; text-decoration: none; } .c3 { font-weight: bold; text-decoration: none; font-size: 44px; } .c4 { color: Blue; text-decoration: none } .c5 { margin: 0px 0px 10px; text-decoration: none }
</style>
</head>
<body class="c0">
  <p class="c1"> <span class="c2">To keep this app free and to save money on
      travel, consider purchasing tickets through our sponsor links below:</span>
  </p>
  <p><span class="c2"></span>When anyone buys a ticket through the links
    provided by the app, the original content creator gets 70% of the
    commission for that purchase, and Andiamo Places gets 30%. The original
    content creator is the one that created a guide starting with a blank
    guide using the "New Guide" option. If you copy a guide the original
    content creator remains the same.</p>
  <p>The money will be paid monthly (but delayed by 2 months) via bank
    transfer or paypal as long as the minimum payout amount of $25 USD is
    reached. Make sure that you specify the payment method and info in user
    settings. </p>
  <p><b>Note that the commission feature is temporarily not available on iOS,
      but it will be available in the next version (April 2022)</b></p>
  <b>
    <h2>Flights:</h2>
    <p></p>
    <p class="c1"> <span class="c2"> <a href="http://jetradar.com/?marker=292121">Aviasales/JetRadar</a>
      </span> </p>
    <br>
    <p class="c1"> <span class="c2"> <a href="https://tp.media/r?marker=292121&amp;p=4426&amp;u=https%3A%2F%2Fwww.cheapoair.com">cheapOair</a>
      </span> </p>
    <p class="c1"> <a target="_blank" href="https://c146.travelpayouts.com/click?shmarker=292121&amp;promo_id=4424&amp;source_type=banner&amp;type=click">
        <img src="https://c146.travelpayouts.com/content?promo_id=4424&amp;shmarker=292121&amp;type=init"

          alt="Cheap Airline Deals" height="100" width="600"> </a> </p>
    <br>
    <p class="c1"> <span class="c2"> <a href="https://c121.travelpayouts.com/click?shmarker=292121&amp;promo_id=4312&amp;source_type=link&amp;type=click">Trip.com</a>
      </span> </p>
    <p class="c1"> </p>
    <h2>Accomodations:</h2>
    <p></p>
    <p class="c1"> <span class="c2"> <a href="http://search.hotellook.com/?destination=Paris&amp;cityId=15542&amp;marker=292121&amp;language=en">hotellook</a>
      </span> </p>
    <br>
    <p class="c1"> <span class="c2"> <a href="https://c121.travelpayouts.com/click?shmarker=292121&amp;promo_id=4312&amp;source_type=link&amp;type=click">Trip.com</a>
      </span> </p>
    <br>
    <p class="c1"> </p>
    <h2>Package Tours:</h2>
    <p></p>
    <p class="c1"> <span class="c2"> <a href="https://tp.media/r?marker=292121&amp;p=3756&amp;u=https%3A%2F%2Fwww.tourradar.com">tourradar</a>
      </span> </p>
    <p class="c1"> <a target="_blank" href="https://c124.travelpayouts.com/click?shmarker=292121&amp;promo_id=4068&amp;source_type=banner&amp;type=click">
        <img src="https://c124.travelpayouts.com/content?promo_id=4068&amp;shmarker=292121&amp;type=init"

          alt=" TourRadar Online Travel Expo 728x90 | Up To 60% Off Travel Deals!"

          height="90" width="728"> </a> </p>
    <p class="c1"> </p>
    <h2>Tours:</h2>
    <p></p>
    <p class="c1"> <span class="c2"> <a href="https://tp.media/r?marker=292121&amp;p=1922&amp;u=https%3A%2F%2Fwww.viator.com%2F">Viator</a>
      </span> </p>
    <p class="c1"> <a target="_blank" href="https://c47.travelpayouts.com/click?shmarker=292121&amp;promo_id=4635&amp;source_type=banner&amp;type=click">
        <img src="https://c47.travelpayouts.com/content?promo_id=4635&amp;shmarker=292121&amp;type=init"

          alt="728*90" height="90" width="728"> </a> </p>
    <br>
    <p class="c1"> <span class="c2"> <a href="https://tp.media/r?marker=292121&amp;p=2074&amp;u=https%3A%2F%2Fwww.tiqets.com%2Fen%2F">Tiqets</a>
      </span> </p>
    <p class="c1"> <a target="_blank" href="https://c89.travelpayouts.com/click?shmarker=292121&amp;promo_id=2072&amp;source_type=banner&amp;type=click">
        <img src="https://c89.travelpayouts.com/content?promo_id=2072&amp;shmarker=292121&amp;type=init"

          alt="728*90" height="90" width="728"> </a> </p>
    <br>
    <p class="c1"> <span class="c2"> <a href="https://tp.media/r?marker=292121&amp;p=2015&amp;u=https%3A%2F%2Fwww.musement.com%2F">Musement</a>
      </span> </p>
    <p class="c1"> <a target="_blank" href="https://c83.travelpayouts.com/click?shmarker=292121&amp;promo_id=2014&amp;source_type=banner&amp;type=click">
        <img src="https://c83.travelpayouts.com/content?promo_id=2014&amp;shmarker=292121&amp;type=init"

          alt="728*90" height="90" width="728"> </a> </p>
    <br>
    <p class="c1"> <gocity class="c2"> <a href="https://tp.media/r?marker=292121&amp;p=1942&amp;u=https%3A%2F%2Fgocity.com%2Fen-us">gocity</a>
      </gocity> </p>
    <p class="c1"> <a target="_blank" href="https://c62.travelpayouts.com/click?shmarker=292121&amp;promo_id=1989&amp;source_type=banner&amp;type=click">
        <img src="https://c62.travelpayouts.com/content?promo_id=1989&amp;shmarker=292121&amp;type=init"

          alt="728 x 90" height="90" width="728"> </a> </p>
    <p class="c1"> </p>
    <h2>Transfers:</h2>
    <p></p>
    <p class="c1"> <span class="c2"> <a href="https://tp.media/r?marker=292121&amp;p=647&amp;u=https%3A%2F%2Fkiwitaxi.ru">kiwitaxi</a>
      </span> </p>
    <p class="c1"> <a target="_blank" href="https://c1.travelpayouts.com/click?shmarker=292121&amp;promo_id=3406&amp;source_type=banner&amp;type=click">
        <img src="https://c1.travelpayouts.com/content?promo_id=3406&amp;shmarker=292121&amp;type=init"

          alt="728Ñ…90_Ð°Ð½Ð³Ð»." height="90" width="728"> </a> </p>
    <br>
    <p class="c1"> <span class="c2"> <a href="https://tp.media/r?marker=292121&amp;p=4018&amp;u=https%3A%2F%2Fen.intui.travel">intuiTravel</a>
      </span> </p>
    <p class="c1"> <a target="_blank" href="https://c22.travelpayouts.com/click?shmarker=292121&amp;promo_id=3487&amp;source_type=banner&amp;type=click">
        <img src="https://c22.travelpayouts.com/content?promo_id=3487&amp;shmarker=292121&amp;type=init"

          alt="728_90_more_EN" height="90" width="728"> </a> </p>
    <br>
    <p class="c1"> <span class="c2"> <a href="https://c122.travelpayouts.com/click?shmarker=292121&amp;promo_id=3688&amp;source_type=link&amp;type=click">HolidayTaxis</a>
      </span> </p>
    <a target="_blank" href="https://c122.travelpayouts.com/click?shmarker=292121&amp;promo_id=3698&amp;source_type=banner&amp;type=click">
      <img src="https://c122.travelpayouts.com/content?promo_id=3698&amp;shmarker=292121&amp;type=init"

        alt="EN - 728x90" height="90" width="728"> </a> <br>
    <p class="c1"> </p>
    <h2>Car Rental:</h2>
    <p></p>
    <p class="c1"> <span class="c2"> <a href="https://tp.media/r?marker=292121&amp;p=4845&amp;u=https%3A%2F%2Fwww.qeeq.com">QEEQ</a>
      </span> </p>
    <br>
    <p class="c1"> <span class="c2"> <a href="https://tp.media/r?marker=292121&amp;p=2043&amp;u=https%3A%2F%2Fmyrentacar.com%2Fen%2F">MyRentACar</a>
      </span> </p>
    <br>
    <p class="c1"> <span class="c2"> <a href="https://tp.media/r?marker=292121&amp;p=4354&amp;u=https%3A%2F%2Fwww.autoeurope.eu">Auto
          Europe</a> </span> </p>
    <p class="c1"> <span class="c2"> <a target="_blank" href="https://c143.travelpayouts.com/click?shmarker=292121&amp;promo_id=4357&amp;source_type=banner&amp;type=click">
          <img src="https://c143.travelpayouts.com/content?promo_id=4357&amp;shmarker=292121&amp;type=init"

            alt="728x90 Find Your Car" height="90" width="728"> </a> </span>
    </p>
    <br>
    <p class="c1"> <span class="c2"> <a href="https://tp.media/r?marker=292121&amp;p=3814&amp;u=https%3A%2F%2Fwww.rentalcars.com%2F">RentalCars.com</a>
      </span> </p>
    <br>
    <p class="c1"> <a target="_blank" href="https://c130.travelpayouts.com/click?shmarker=292121&amp;promo_id=4523&amp;source_type=banner&amp;type=click">
        <img src="https://c130.travelpayouts.com/content?promo_id=4523&amp;shmarker=292121&amp;type=init"

          alt="728*90 RentalCars English" height="90" width="728"> </a> </p>
    <br>
    <p class="c1"> <span class="c2"> <a href="https://tp.media/r?marker=292121&amp;p=3555&amp;u=https%3A%2F%2Fwww.discovercars.com%2F">Discover
          Cars</a> </span> </p>
    <br>
    <p class="c1"> <a target="_blank" href="https://c117.travelpayouts.com/click?shmarker=292121&amp;promo_id=4342&amp;source_type=banner&amp;type=click">
        <img src="https://c117.travelpayouts.com/content?promo_id=4342&amp;shmarker=292121&amp;type=init"

          alt="Save Money 728x90" height="90" width="728"> </a> </p>
    <br>
    <p class="c1"> <span class="c2"> <a href="https://tp.media/r?marker=292121&amp;p=2018&amp;u=https%3A%2F%2Fwww.economybookings.com%2F">Economy
          Bookings</a> </span> </p>
    <p class="c1"> <a target="_blank" href="https://c10.travelpayouts.com/click?shmarker=292121&amp;promo_id=4325&amp;source_type=banner&amp;type=click">
        <img src="https://c10.travelpayouts.com/content?promo_id=4325&amp;shmarker=292121&amp;type=init"

          alt="EconomyBookings 600x90" height="90" width="600"> </a> </p>
    <p class="c1"> </p>
    <h2>Train Tickets:</h2>
    <p></p>
    <p class="c1"> <span class="c2"> <a href="https://c121.travelpayouts.com/click?shmarker=292121&amp;promo_id=4312&amp;source_type=link&amp;type=click">Trip.com</a>
      </span> </p>
    <br>
    <p class="c1"> <span class="c2"> <a href="https://tp.media/r?marker=292121&amp;p=3767&amp;u=https%3A%2F%2Fwww.italiarail.com">Italia
          Rail</a> </span> </p>
    <br>
    <p class="c1"> <a target="_blank" href="https://c125.travelpayouts.com/click?shmarker=292121&amp;promo_id=3928&amp;source_type=banner&amp;type=click">
        <img src="https://c125.travelpayouts.com/content?promo_id=3928&amp;shmarker=292121&amp;type=init"

          alt="ItaliaRail 728x90" height="90" width="728"> </a> </p>
    <br>
    <p class="c1"> <span class="c2"> <a href="https://tp.media/r?marker=292121&amp;p=1764&amp;u=https%3A%2F%2F12go.co%2F">12GoAsia</a>
      </span> </p>
    <a target="_blank" href="https://c44.travelpayouts.com/click?shmarker=292121&amp;promo_id=2714&amp;source_type=banner&amp;type=click">
      <img src="https://c44.travelpayouts.com/content?promo_id=2714&amp;shmarker=292121&amp;type=init"

        alt="728x90" height="90" width="728"> </a>
    <p class="c1"> </p>
    <h2>Bus Tickets:</h2>
    <p></p>
    <p class="c1"> <span class="c2"> <a href="https://tp.media/r?marker=292121&amp;p=4109&amp;u=https%3A%2F%2Fwww.busbud.com%2Fen">Busbud</a>
      </span> </p>
    <p class="c1"> <a target="_blank" href="https://c138.travelpayouts.com/click?shmarker=292121&amp;promo_id=4100&amp;source_type=banner&amp;type=click">
        <img src="https://c138.travelpayouts.com/content?promo_id=4100&amp;shmarker=292121&amp;type=init"

          alt="Travel everywhere for less" height="90" width="728"> </a> </p>
    <br>
    <p class="c1"> <span class="c2"> <a href="https://c98.travelpayouts.com/click?shmarker=292121&amp;promo_id=2435&amp;source_type=banner&amp;type=click">FlixBus</a>
      </span> </p>
    <p class="c1"> <a target="_blank" href="https://c98.travelpayouts.com/click?shmarker=292121&amp;promo_id=2435&amp;source_type=banner&amp;type=click">
        <img src="https://c98.travelpayouts.com/content?promo_id=2435&amp;shmarker=292121&amp;type=init"

          alt="" height="90" width="728"> </a> </p>
    <br>
    <p class="c1"> </p>
    <h2>Bikes and Scooters:</h2>
    <p></p>
    <p class="c1"> <span class="c2"> <a href="https://tp.media/r?marker=292121&amp;p=1767&amp;u=https%3A%2F%2Fbikesbooking.com">BikesBookings.com</a>
      </span> </p>
    <p class="c1"> <a target="_blank" href="https://c57.travelpayouts.com/click?shmarker=292121&amp;promo_id=1807&amp;source_type=banner&amp;type=click">
        <img src="https://c57.travelpayouts.com/content?promo_id=1807&amp;shmarker=292121&amp;type=init"

          alt="728*90" height="90" width="728"> </a> </p>
    <p class="c1"> </p>
    <h2>Other:</h2>
    <p></p>
    <p class="c1"> <span class="c2"> <a href="https://tp.media/r?marker=292121&amp;p=2762&amp;u=https%3A%2F%2Fdrimsim.com">Drimsim
          SIM card for phone</a> </span> </p>
    <p class="c1"> <a target="_blank" href="https://c102.travelpayouts.com/click?shmarker=292121&amp;promo_id=2896&amp;source_type=banner&amp;type=click">
        <img src="https://c102.travelpayouts.com/content?promo_id=2896&amp;shmarker=292121&amp;type=init"

          alt="728*90" height="90" width="728"> </a> </p>
    <p></p>
  </b>
</body>
</html>`;
