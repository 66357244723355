import React, { useState, useEffect } from "react";
import { Modal, Form, Button, Spinner } from "react-bootstrap";
import MenuModal from "../modals/MenuModal";
import FilterModal from "../modals/FilterModal";
import "./PlacesHeader.css";
import { useDispatch } from "react-redux";
import { slice1Actions } from "../../store/reducer";
import { useSelector } from "react-redux";
import Common, {
  Settings,
  SortByEnum,
  AUTHORIZATION_LEVEL,
  DISTANCES,
  COLORS,
  getPlaceOrderByDistance,
} from "../../Common/Common";
import Places from "../../Models/Places";
import GooglePlaces from "../../Models/GooglePlaces";
import Attractions from "../../Models/Attractions";
import Wikipedia from "../../Models/Wikipedia";

import AlertModal from "../modals/AlertModal";
import PromptModal from "../modals/PromptModal";
import MultiSelectModal from "../modals/MultiSelectModal";
import { convertNodeToElement } from "react-html-parser";
import GroupsDialog from "../modals/GroupsDialog";
import CategoriesDialog from "../modals/CategoriesDialog";
import Appointment from "../../Models/Event";
import { DateTime } from "luxon";
import SectionsDialog from "../modals/SectionsDialog";

const PlacesHeader = ({ setSearch, setSortBy, handleSortSubmit }) => {
  const [shouldSort, setShouldSort] = useState(false);
  const [shouldFilter, setShouldFilter] = useState(false);
  const dispatch = useDispatch();
  let SortBy = useSelector((state) => state.SortBy);
  let noFiltering = useSelector((state) => state.noFiltering);
  let SearchText = useSelector((state) => state.SearchText);
  let SearchResultsPlacesList = useSelector((state) => state.SearchResultsPlacesList);
  let MobileMode = useSelector((state) => state.MobileMode);
  let CurrentAppScreen = useSelector((state) => state.CurrentAppScreen);
  let GuideEditMode = useSelector((state) => state.GuideEditMode);
  let showHiddenPlace = useSelector((state) => state.showHiddenPlace);

  const [MenuModalShow, setMenuModalShow] = useState(false);
  const [MenuModalImage, setMenuModalImage] = useState(null);
  const [MenuModalList, setMenuModalList] = useState([]);
  const [MenuModalHeading, setMenuModalHeading] = useState("");
  const [MenuModalPlace, setMenuModalPlace] = useState(null);
  const [MenuModalInfo, setMenuModalInfo] = useState("");
  let user_info = useSelector((state) => state.user_info);

  const [PromptModalShow, setPromptModalShow] = useState(false);
  const [PromptModalTitle, setPromptModalTitle] = useState("Enter Value");
  const [PromptModalMessage, setPromptModalMessage] = useState("");
  const [PromptModalButtons, setPromptModalButtons] = useState([]);
  const [PromptModalInputs, setPromptModalInputs] = useState([]);
  const [PromptModalCompact, setPromptModalCompact] = useState(null);
  const [SavedTextSearch, setSavedTextSearch] = useState("");
  const [AlertModalShow, setAlertModalShow] = useState(false);
  const [AlertModalTitle, setAlertModalTitle] = useState("");
  const [AlertModalMessage, setAlertModalMessage] = useState("");
  const [AlertModalButtons, setAlertModalButtons] = useState([]);

  let MapBounds = useSelector((state) => state.MapBoundsValue);
  const [_GooglePlaces, setGooglePlaces] = useState(null);
  const [_Attractions, setAttractions] = useState(null);
  const [_Wikipedia, setWikipedia] = useState(null);

  const [MultiselectItems, setMultiselectItems] = useState([]);
  const [MultiselectSelectedItems, setMultiselectSelectedItems] = useState([]);
  const [MultiselectMode, setMultiselectMode] = useState("uni");
  const [MultiselectCallback, setMultiselectCallback] = useState(null);
  const [MultiselectTitle, setMultiselectTitle] = useState("Select");
  const [MultiselectSearchText, setMultiselectSearchText] = useState("");
  const [ShowMultiselect, setShowMultiselect] = useState(null);

  const [GroupsDialogShow, setGroupsDialogShow] = useState(false);
  const [CategoriesDialogShow, setCategoriesDialogShow] = useState(false);
  const [SectionsDialogShow, setSectionsDialogShow] = useState(false);
  let filtered_places = useSelector((state) => state.places);
  const [PlacesSelected, setPlacesSelected] = useState([]);
  let TempValue = useSelector((state) => state.TempValue);

  useEffect(() => {
    setGooglePlaces(new GooglePlaces());
    setAttractions(new Attractions());
    setWikipedia(new Wikipedia());
  }, [PlacesSelected]);

  const handleSearChanged = (event) => {
    //console.log(event.target.value);
    dispatch(slice1Actions.SetSearchText({ value: event.target.value }));
    dispatch(slice1Actions.setGuide({ guide: null }));
  };
  function handleSort(_SortBy) {
    switch (_SortBy) {
      case "Name":
        dispatch(slice1Actions.SetSortBy({ value: SortByEnum.NAME }));
        dispatch(slice1Actions.setGuide({ guide: null }));
        break;
      case "Category":
        dispatch(slice1Actions.SetSortBy({ value: SortByEnum.CATEGORY }));
        dispatch(slice1Actions.setGuide({ guide: null }));
        break;
      case "Group":
        dispatch(slice1Actions.SetSortBy({ value: SortByEnum.GROUP }));
        dispatch(slice1Actions.setGuide({ guide: null }));
        break;
      case "Rating":
        dispatch(slice1Actions.SetSortBy({ value: SortByEnum.RATING }));
        dispatch(slice1Actions.setGuide({ guide: null }));
        break;
      case "Distance":
        dispatch(slice1Actions.SetSortBy({ value: SortByEnum.DISTANCE }));
        dispatch(slice1Actions.setGuide({ guide: null }));
        break;
      case "Date":
        dispatch(slice1Actions.SetSortBy({ value: SortByEnum.DATE }));
        dispatch(slice1Actions.setGuide({ guide: null }));
        break;
      default:
        break;
    }
  }

  //   const searchMenu = () => {
  //     //console.log("SortBy", SortBy);
  //     const menuList = [
  //       {
  //         label: "Attractions",
  //         method: searchAttractions,
  //         icon: <i className="fa fa-check"></i>,
  //         checked: false,
  //         visible: true,
  //       },
  //       {
  //         label: "Google text search",
  //         method: googleTextSearch,
  //         icon: <i className="fas fa-check"></i>,
  //         checked: false,
  //         visible: true,
  //       },
  //       // {
  //       //   label: "Google category search",
  //       //   method: googleCategorySearch,
  //       //   icon: <i className="fa fa-check"></i>,
  //       //   checked: false,
  //       //   visible: true,
  //       // },
  //       // {
  //       //   label: "Wikipedia text search",
  //       //   method: wikipediaTextSearch,
  //       //   icon: <i className="fa fa-check"></i>,
  //       //   checked: false,
  //       //   visible: true,
  //       // },
  //       {
  //         label: "Search in Foursquare",
  //         method: goToFoursquare,
  //         icon: <i className="fa fa-check"></i>,
  //         checked: false,
  //         visible: true,
  //       },
  //       {
  //         label: "Search in Yelp",
  //         method: goToYelp,
  //         icon: <i className="fa fa-check"></i>,
  //         checked: false,
  //         visible: true,
  //       },
  //       {
  //         label: "Search Happy Cow (vegan)",
  //         method: goToHappyCow,
  //         icon: null,
  //         visible: true,
  //       },
  //       {
  //         label: "Add all search results",
  //         method: AddAllSearchResults,
  //         icon: null,
  //         visible: SearchResultsPlacesList.length > 0,
  //       },
  //       {
  //         label: "Remove search results",
  //         method: RemoveSearchResults,
  //         icon: null,
  //         checked: false,
  //         visible: SearchResultsPlacesList.length > 0,
  //       },
  //     ];
  //     //console.log("menuList", menuList);
  //     setMenuModalList(menuList);
  //     setMenuModalHeading("Search Places Menu");
  //     setMenuModalImage(<i className="fas fa-search"></i>);
  //     setMenuModalPlace(null);
  //     setMenuModalShow(true);
  //   };

  //   const RemoveSearchResults = () => {
  //     dispatch(slice1Actions.setSearchResultsPlacesList({ value: [] }));
  //     dispatch(slice1Actions.setGuide({ guide: null }));
  //   };

  //   const AddAllSearchResults = (action) => {
  //     dispatch(slice1Actions.addAllResultsToGuide({ value: null }));
  //     dispatch(slice1Actions.setGuide({ guide: null }));
  //   };

  //   const goToYelp = () => {
  //     const centerRadius = getCenterAndRadius();
  //     let action = `https://www.yelp.com/search?find_loc=${centerRadius.center.latitude},${centerRadius.center.longitude}`;
  //     let htmlString = `<!DOCTYPE html>
  // <html>
  //   <head>
  //     <title>StreetView</title>
  //     <meta http-equiv='Content-Type' content='text/html; charset=utf-8'/>
  //   </head>
  //   <body>
  //     <script type='text/javascript'>
  //       window.location.href = "${action}";
  //     </script>
  //   </body>
  // </html>`;
  //     let win = window.open("about:blank", "", "_blank");
  //     win.document.write(htmlString);
  //     win.focus();
  //   };

  //   const goToFoursquare = () => {
  //     const centerRadius = getCenterAndRadius();
  //     let action = `https://foursquare.com/explore?mode=url&ll=${centerRadius.center.latitude},${centerRadius.center.longitude}&q=Top%20Picks`;

  //     let htmlString = `<!DOCTYPE html>
  // <html>
  //   <head>
  //     <title>StreetView</title>
  //     <meta http-equiv='Content-Type' content='text/html; charset=utf-8'/>
  //   </head>
  //   <body>
  //     <script type='text/javascript'>
  //       window.location.href = "${action}";
  //     </script>
  //   </body>
  // </html>`;
  //     let win = window.open("about:blank", "", "_blank");
  //     win.document.write(htmlString);
  //     win.focus();
  //   };

  //   const goToHappyCow = () => {
  //     const centerRadius = getCenterAndRadius();
  //     const lon_min = MapBounds.west.toFixed(6);
  //     const lon_max = MapBounds.east.toFixed(6);
  //     const lat_min = MapBounds.south.toFixed(6);
  //     const lat_max = MapBounds.north.toFixed(6);
  //     const bb = `${lat_min}%2C${lon_min}%2C${lat_max}%2C${lon_max}`;

  //     const action = `https://www.happycow.net/searchmap?s=3&location=&filters=vegan-vegetarian-vegfriendly&metric=mi&limit=81&order=default&lat=${centerRadius.center.latitude}&lng=${centerRadius.center.longitude}&zoom=15&page=1&bb=${bb}`;

  //     let htmlString = `<!DOCTYPE html>
  //     <html>
  //       <head>
  //         <title>StreetView</title>
  //         <meta http-equiv='Content-Type' content='text/html; charset=utf-8'/>
  //       </head>
  //       <body>
  //         <script type='text/javascript'>
  //           window.location.href = "${action}";
  //         </script>
  //       </body>
  //     </html>`;
  //     let win = window.open("about:blank", "", "_blank");
  //     win.document.write(htmlString);
  //     win.focus();
  //   };

  //   const searchAttractions = () => {
  //     dispatch(slice1Actions.SetLoadingGuide({ value: true }));
  //     _Attractions.searchPlaces(
  //       {
  //         lon_min: MapBounds.west,
  //         lon_max: MapBounds.east,
  //         lat_min: MapBounds.south,
  //         lat_max: MapBounds.north,
  //       },
  //       "20",
  //       searchAttractionsCallback
  //     );
  //   };

  //   const searchAttractionsCallback = (attractions) => {
  //     if (attractions == null || attractions.length === 0) {
  //       //console.log("searchAttractionsCallback attractions", attractions);
  //       dispatch(slice1Actions.SetLoadingGuide({ value: false }));
  //       return;
  //     }
  //     //console.log("searchAttractionsCallback attractions.length", attractions.length);
  //     _Attractions.getDetails(attractions, getAttractionsDetailsCallback);
  //   };

  //   const getAttractionsDetailsCallback = (places) => {
  //     dispatch(slice1Actions.setSearchResultsPlacesList({ value: places }));
  //     dispatch(slice1Actions.setGuide({ guide: null }));
  //     dispatch(slice1Actions.SetLoadingGuide({ value: false }));
  //   };

  //   const googleCategorySearch = () => {
  //     const items = GooglePlaces.getGoogleCategories();
  //     // let id = 0;
  //     // items.forEach((item) => {
  //     //   item.id = (id++).toString();
  //     // });
  //     console.log("items", items);
  //     dispatch(slice1Actions.setMultiselectCallback({ value: googleCategoriesCallBack }));
  //     dispatch(slice1Actions.setMultiselectItems({ value: items }));
  //     dispatch(slice1Actions.setMultiselectSelectedItems({ value: [] }));
  //     dispatch(slice1Actions.setMultiselectMode({ value: "uni" }));
  //     dispatch(slice1Actions.setMultiselectSearchText({ value: "" }));
  //     dispatch(slice1Actions.setShowMultiselect({ value: true }));
  //     // setMultiselectItems(items);
  //     // setMultiselectSelectedItems([]);
  //     // setMultiselectMode("uni");
  //     // //setMultiselectCallback(googleCategoriesCallBack);
  //     // setMultiselectTitle("Select Category");
  //     // setMultiselectSearchText("");
  //     // setShowMultiselect(true);
  //   };

  //   const googleCategoriesCallBack = (google_categories) => {
  //     if (google_categories == null) return;
  //     if (google_categories.length === 0) return;
  //     let cats = google_categories[0].value;
  //     cats = cats.replace(/ /g, "%20");
  //     dispatch(slice1Actions.SetLoadingGuide({ value: true }));
  //     const centerRadius = getCenterAndRadius();
  //     _GooglePlaces.searchPlaces(
  //       `${centerRadius.center.latitude},${centerRadius.center.longitude}`,
  //       centerRadius.radius,
  //       cats,
  //       null,
  //       searchGooglePlaceCallback
  //     );
  //   };
  //   const googleTextSearch = () => {
  //     setPromptModalShow(true);
  //     setPromptModalTitle("Search");
  //     setPromptModalMessage("Google places");
  //     setPromptModalButtons([
  //       { Title: "OK", Method: _googleTextSearch },
  //       { Title: "CANCEL", Method: cancelSearch },
  //     ]);
  //     setPromptModalInputs([{ name: "Search Text", value: SavedTextSearch }]);
  //   };

  //   const cancelSearch = () => {
  //     dispatch(slice1Actions.SetLoadingGuide({ value: false }));
  //   };

  //   const getCenterAndRadius = () => {
  //     const distance_lat =
  //       Places.calculateDistance(
  //         {
  //           latitude: MapBounds.south,
  //           longitude: MapBounds.west,
  //         },
  //         {
  //           latitude: MapBounds.north,
  //           longitude: MapBounds.east,
  //         }
  //       ) * 1000;
  //     const distance_lon =
  //       Places.calculateDistance(
  //         {
  //           latitude: MapBounds.south,
  //           longitude: MapBounds.west,
  //         },
  //         {
  //           latitude: MapBounds.north,
  //           longitude: MapBounds.east,
  //         }
  //       ) * 1000;
  //     const radius = Math.min(distance_lat, distance_lon, 50000);
  //     const center = {
  //       latitude: (MapBounds.north + MapBounds.south) / 2,
  //       longitude: (MapBounds.west + MapBounds.east) / 2,
  //     };
  //     return { center: center, radius: radius };
  //   };

  //   const _googleTextSearch = (inputs) => {
  //     if (inputs === null) return;
  //     const searchText = inputs[0].value;
  //     setSavedTextSearch(searchText);
  //     //this.setState({ visiblePrompt: false, placeProvider: "google" });
  //     // if (this.state.searchText.length < 3) {
  //     //   Alert.alert("Search text must be at least 3 characters");
  //     //   return;
  //     // }
  //     dispatch(slice1Actions.SetLoadingGuide({ value: true }));
  //     //location, radius, type, query, callback;

  //     const distance_lat =
  //       Places.calculateDistance(
  //         {
  //           latitude: MapBounds.south,
  //           longitude: MapBounds.west,
  //         },
  //         {
  //           latitude: MapBounds.north,
  //           longitude: MapBounds.east,
  //         }
  //       ) * 1000;
  //     const distance_lon =
  //       Places.calculateDistance(
  //         {
  //           latitude: MapBounds.south,
  //           longitude: MapBounds.west,
  //         },
  //         {
  //           latitude: MapBounds.north,
  //           longitude: MapBounds.east,
  //         }
  //       ) * 1000;
  //     const radius = Math.min(distance_lat, distance_lon, 50000);
  //     //console.log("googleTextSearch", distance_lat, distance_lon, radius);
  //     //location, radius, type, query, callback
  //     _GooglePlaces.searchPlaces(
  //       `${((MapBounds.north + MapBounds.south) / 2).toString()},${((MapBounds.east + MapBounds.west) / 2).toString()}`,
  //       radius,
  //       null,
  //       searchText,
  //       searchGooglePlaceCallback
  //     );
  //   };

  //   const searchGooglePlaceCallback = (google_places) => {
  //     if (google_places == null || google_places.length == 0) {
  //       //console.log("searchGooglePlaceCallback google_places", google_places);
  //       dispatch(slice1Actions.SetLoadingGuide({ value: false }));
  //       return;
  //     }
  //     //console.log("searchGooglePlaceCallback google_places.length", google_places.length);
  //     //console.log("otm_places", otm_places);
  //     _GooglePlaces.getDetails(google_places, getGoogleDetailsCallback);
  //   };

  //   const getGoogleDetailsCallback = (places) => {
  //     //console.log("getGoogleDetailsCallback", places);
  //     dispatch(slice1Actions.setSearchResultsPlacesList({ value: places }));
  //     dispatch(slice1Actions.setGuide({ guide: null }));
  //     dispatch(slice1Actions.SetLoadingGuide({ value: false }));
  //   };

  //   const wikipediaTextSearch = () => {
  //     setPromptModalShow(true);
  //     setPromptModalTitle("Search");
  //     setPromptModalMessage("Wikipedia text");
  //     setPromptModalButtons([
  //       { Title: "OK", Method: _wikipediaTextSearch },
  //       { Title: "CANCEL", Method: cancelSearch },
  //     ]);
  //     setPromptModalInputs([{ name: "Search Text", value: SavedTextSearch }]);
  //   };

  //   const _wikipediaTextSearch = (inputs) => {
  //     if (inputs === null) return;
  //     const searchText = inputs[0].value;
  //     setSavedTextSearch(searchText);
  //     dispatch(slice1Actions.SetLoadingGuide({ value: true }));

  //     console.log("PlacesHeader MapBounds", MapBounds);

  //     let deltaLat = Math.abs(Math.min(0.25, MapBounds.north - MapBounds.south)) / 2;
  //     let deltaLon = Math.abs(Math.min(0.18, MapBounds.east - MapBounds.west)) / 2;

  //     let centerLat = (MapBounds.north + MapBounds.south) / 2;
  //     let centerLon = (MapBounds.west + MapBounds.east) / 2;

  //     _Wikipedia.searchPlaces(
  //       `${(centerLat + deltaLat).toString()}|${(centerLon - deltaLon).toString()}|${(
  //         centerLat - deltaLat
  //       ).toString()}|${(centerLon + deltaLon).toString()}`,
  //       200,
  //       searchWikipediaCallback
  //     );
  //   };

  //   let _wikipedia_geosearch_results = [];
  //   let _wikepedia_pages = [];
  //   let _wiki_calls = 1;
  //   let _wiki_callbacks = 0;

  //   const searchWikipediaCallback = (wikipedia_places) => {
  //     //console.log("searchWikipediaCallback", wikipedia_places);
  //     if (wikipedia_places === null) {
  //       dispatch(slice1Actions.SetLoadingGuide({ value: false }));
  //       myAlert("Try smaller search area");
  //       return;
  //     }
  //     if (typeof wikipedia_places["query"] == "undefined") {
  //       dispatch(slice1Actions.SetLoadingGuide({ value: false }));
  //       //console.log("searchWikipediaCallback ", wikipedia_places);
  //       const info =
  //         typeof wikipedia_places["error"]["info"] == "undefined"
  //           ? "Try smaller search area"
  //           : wikipedia_places["error"]["info"];
  //       dispatch(slice1Actions.SetLoadingGuide({ value: false }));
  //       myAlert(info);
  //       return;
  //     }

  //     if (wikipedia_places == null || wikipedia_places.length == 0) {
  //       //console.log("searchWikipediaCallback wikipedia_places, null or zero");
  //       dispatch(slice1Actions.SetLoadingGuide({ value: false }));
  //       myAlert("No results try a smaller or bigger area");
  //       return;
  //     }
  //     if (typeof wikipedia_places["query"] == "undefined") {
  //       dispatch(slice1Actions.SetLoadingGuide({ value: false }));
  //       return;
  //     }
  //     if (typeof wikipedia_places["query"]["geosearch"] == "undefined") {
  //       dispatch(slice1Actions.SetLoadingGuide({ value: false }));
  //       return;
  //     }
  //     _wikepedia_pages = [];
  //     _wikipedia_geosearch_results = wikipedia_places["query"]["geosearch"];
  //     //console.log("_wikipedia_geosearch_results.length", _wikipedia_geosearch_results.length);
  //     if (_wikipedia_geosearch_results.length == 0) {
  //       dispatch(slice1Actions.SetLoadingGuide({ value: false }));
  //       myAlert("No results try a smaller area");
  //       return;
  //     }
  //     dispatch(slice1Actions.SetLoadingGuide({ value: false }));
  //     _wiki_calls = _wikipedia_geosearch_results.length / 50;
  //     _wiki_callbacks = 0;
  //     //for (let i = 0; i < _wiki_calls; i++) {
  //     const number = _wikipedia_geosearch_results.length >= 50 ? 50 : _wikipedia_geosearch_results.length;
  //     let batch = _wikipedia_geosearch_results.slice(0, number);
  //     //console.log("batch", _wikipedia_geosearch_results.length, batch.length);
  //     _Wikipedia.getDetails(batch, getWikipediaCallback);
  //     //}
  //   };

  //   const getWikipediaCallback = (places) => {
  //     const _places = _Wikipedia.parsePlaces(_wikipedia_geosearch_results, places, SavedTextSearch);
  //     _wikepedia_pages = _wikepedia_pages.concat(_places);
  //     _wiki_callbacks += 1;
  //     if (_wiki_callbacks >= _wiki_calls) {
  //       processWikepediaPages();
  //       return;
  //     }
  //     const number =
  //       _wikipedia_geosearch_results.length >= 50 * (_wiki_callbacks + 1)
  //         ? 50
  //         : _wikipedia_geosearch_results.length - 50 * _wiki_callbacks;
  //     let batch = _wikipedia_geosearch_results.slice(_wiki_callbacks * 50, _wiki_callbacks * 50 + number);

  //     _Wikipedia.getDetails(batch, getWikipediaCallback);
  //   };

  //   const processWikepediaPages = () => {
  //     dispatch(slice1Actions.setSearchResultsPlacesList({ value: _wikepedia_pages }));
  //     dispatch(slice1Actions.setGuide({ guide: null }));
  //     dispatch(slice1Actions.SetLoadingGuide({ value: false }));
  //   };

  const EditMenu = () => {
    const menuList = [
      // { label: "Edit Groups", method: EditGroups, icon: null, visible: true },
      // { label: "Edit Categories", method: EditCategories, icon: null, visible: true },
      // { label: "**SEPARATOR***", method: null, icon: null, visible: true },
      {
        label: "Add to Groups",
        method: AddGroups,
        icon: null,
        visible: true,
        info: "Add the places in the the places list to one or more groups. Note that the places in the places list can be filtered.",
      },
      {
        label: "Remove from Groups",
        method: RemoveGroups,
        icon: null,
        visible: true,
        info: "Remove the places in the places list from selected groups. Note that the places in the places list can be filtered.",
      },
      {
        label: "Set to Groups",
        method: ReplaceGroups,
        icon: null,
        visible: true,
        info: "Remove the places in the places list from all groups and then add them only to the selected groups. Note that the places in the places list can be filtered.",
      },
      { label: "**SEPARATOR***", method: null, icon: null, visible: true },
      {
        label: "Add to Categories",
        method: AddCategories,
        icon: null,
        visible: true,
        info: "Add the places in the places list to the selected categories. Note that the places in the places list can be filtered.",
      },
      {
        label: "Remove from Categories",
        method: RemoveCategories,
        icon: null,
        visible: true,
        info: "Remove the places in the places list from selected categories. Note that the places in the places list can be filtered.",
      },
      {
        label: "Set to Categories",
        method: ReplaceCategories,
        icon: null,
        visible: true,
        info: "Remove the places in the places list from all categories and add them only to the selected categories. Note that the places in the places list can be filtered.",
      },
      { label: "**SEPARATOR***", method: null, icon: null, visible: true },
      // { label: "Set Places Group", method: SetGroup, icon: null, visible: true },
      {
        label: "Mark Places Favorites",
        method: EditFavorite,
        icon: null,
        visible: true,
        info: "Set the 'Favorite' attribute for the places in the places list, i.e. Favorite or not Favorite",
      },
      {
        label: "Mark Places Visited",
        method: EditVisited,
        icon: null,
        visible: true,
        info: "Set the 'Visited' attribute for the places in the places list, i.e. Visited or not Visited",
      },
      {
        label: "Mark Places as 'Must See'",
        method: EditMustSee,
        icon: null,
        visible: true,
        info: "Set the 'Must See' attribute for the places in the places list, i.e. Must See or not Must See",
      },
      {
        label: "Show/Hide Places",
        method: EditHidden,
        icon: null,
        visible: true,
        info: "Set the 'Hidden' attribute for the places in the places list, i.e. Hidden or not Hidden. Hidden places are not shown on map or places list.",
      },
      {
        label: "Set Place link",
        method: _setPathlink,
        icon: null,
        visible: true,
        info: "Set the place link for the place in order to enable play video, audio etc.",
      },
      {
        label: "Create Events From Places",
        method: CreateEventsFromPlaces,
        icon: null,
        visible: true,
        info: "Select which places should be turned into events and added to the schedule.",
      },

      {
        label: "Delete Places",
        method: DeletePlaces,
        icon: null,
        visible: true,
        info: "Select which places should be deleted. Note: once deleted they cannot be recovered.",
      },
    ];
    setMenuModalList(menuList);
    setMenuModalHeading("Edit Places");
    setMenuModalImage(<i className="fa fa-pencil"></i>);
    setMenuModalPlace(null);
    setMenuModalInfo(
      "This applies to all filtered places i.e. all places shown in the places list. Apply or remove filters to select desired places."
    );
    setMenuModalShow(true);
  };
  const EditGroups = () => {
    setGroupsDialogShow(true);
  };
  const EditCategories = () => {
    setCategoriesDialogShow(true);
  };

  let AddReplaceMode = "Add";

  const AddCategories = (category) => {
    AddReplaceMode = "Add";
    selectCategories();
  };

  const RemoveCategories = (category) => {
    AddReplaceMode = "Remove";
    selectCategories();
  };

  const ReplaceCategories = (category) => {
    AddReplaceMode = "Replace";
    selectCategories();
  };

  const selectCategories = () => {
    let _ChooseCategoriesList = window.guide_obj.getCategoriesToDisplay();
    dispatch(slice1Actions.setMultiselectCallback({ value: SelectCategoryCallBack }));
    dispatch(slice1Actions.setMultiselectItems({ value: _ChooseCategoriesList }));
    dispatch(slice1Actions.setMultiselectSelectedItems({ value: [] }));
    dispatch(slice1Actions.setMultiselectMode({ value: "uni" }));
    dispatch(slice1Actions.setMultiselectSearchText({ value: "" }));
    dispatch(slice1Actions.setMultiselectTitle({ value: "Select Category" }));
    dispatch(slice1Actions.setShowMultiselect({ value: true }));
  };

  const SelectCategoryCallBack = (categories) => {
    filtered_places.forEach((section) => {
      section.data.forEach((place_m) => {
        let place = window.guide_obj.places.getPlace(place_m.place.ID);
        if (place != null) {
          if (AddReplaceMode === "Replace") place.Category = [];
          categories.forEach((category) => {
            if (AddReplaceMode === "Add" || AddReplaceMode === "Replace") {
              const index = place.Category.indexOf(category.id);
              if (index === -1) place.Category.push(category.id);
            } else if (AddReplaceMode === "Remove") {
              const index = place.Category.indexOf(category.id);
              if (index !== -1) place.Category.splice(index, 1);
            }
          });
        }
      });
    });
    dispatch(slice1Actions.setGuide({ guide: null }));
  };

  const selectFirstPlace = (places) => {
    dispatch(slice1Actions.setMultiselectCallback({ value: SelectFirstPlaceCallBack }));
    dispatch(slice1Actions.setMultiselectItems({ value: places }));
    dispatch(slice1Actions.setMultiselectSelectedItems({ value: [] }));
    dispatch(slice1Actions.setMultiselectMode({ value: "uni" }));
    dispatch(slice1Actions.setMultiselectSearchText({ value: "" }));
    dispatch(slice1Actions.setMultiselectTitle({ value: "Select Starting Place" }));
    dispatch(slice1Actions.setShowMultiselect({ value: true }));
  };

  const SelectFirstPlaceCallBack = (places) => {
    let place_id = null;
    if (places.length > 0) place_id = places[0].value.ID;
    createEventNew(place_id);
  };

  const AddGroups = (category) => {
    AddReplaceMode = "Add";
    selectGroups();
  };

  const RemoveGroups = (category) => {
    AddReplaceMode = "Remove";
    selectGroups();
  };

  const ReplaceGroups = (category) => {
    AddReplaceMode = "Replace";
    selectGroups();
  };

  const selectGroups = () => {
    let _ChooseCategoriesList = window.guide_obj.getGroupsToDisplay();
    dispatch(slice1Actions.setMultiselectCallback({ value: SelectGroupsCallBack }));
    dispatch(slice1Actions.setMultiselectItems({ value: _ChooseCategoriesList }));
    dispatch(slice1Actions.setMultiselectSelectedItems({ value: [] }));
    dispatch(slice1Actions.setMultiselectMode({ value: "multi" }));
    dispatch(slice1Actions.setMultiselectSearchText({ value: "" }));
    dispatch(slice1Actions.setMultiselectTitle({ value: "Select Groups" }));
    dispatch(slice1Actions.setShowMultiselect({ value: true }));
  };

  const SelectGroupsCallBack = (groups) => {
    filtered_places.forEach((section) => {
      section.data.forEach((place_m) => {
        let place = window.guide_obj.places.getPlace(place_m.place.ID);
        if (place != null) {
          if (AddReplaceMode === "Replace") place.Group = [];
          groups.forEach((group) => {
            if (AddReplaceMode === "Add" || AddReplaceMode === "Replace") {
              const index = place.Group.indexOf(group.id);
              if (index === -1) place.Group.push(group.id);
            } else if (AddReplaceMode === "Remove") {
              const index = place.Group.indexOf(group.id);
              if (index !== -1) place.Group.splice(index, 1);
            }
          });
        }
      });
    });
    dispatch(slice1Actions.setGuide({ guide: null }));
  };

  const CreateEventsFromPlacesOLD = () => {
    _place_operation = "Create Events";
    selectPlaces();
  };

  const CreateEventsFromPlaces = () => {
    _place_operation = "Create Events New";
    selectPlaces();
  };

  const DeletePlaces = () => {
    _place_operation = "Delete Places";
    selectPlaces();
  };

  const EditFavorite = () => {
    _place_operation = "Favorite";
    selectPlaces();
  };

  const EditVisited = () => {
    _place_operation = "Visited";
    selectPlaces();
  };

  const EditMustSee = () => {
    _place_operation = "MustSee";
    selectPlaces();
  };

  const EditHidden = () => {
    _place_operation = "Hidden";
    selectPlaces();
  };

  const _setPathlink = () => {
    createPlaceLinkMenu();

    // const places = window.guide_obj.places.getPlacesToDisplay(null, "PolyLine");

    // dispatch(slice1Actions.setMultiselectCallback({ value: setPathLinkCallBack }));
    // dispatch(slice1Actions.setMultiselectItems({ value: places }));
    // dispatch(slice1Actions.setMultiselectSelectedItems({ value: [] }));
    // dispatch(slice1Actions.setMultiselectMode({ value: "uni" }));
    // dispatch(slice1Actions.setMultiselectSearchText({ value: "" }));
    // dispatch(slice1Actions.setMultiselectTitle({ value: "Select Places" }));
    // dispatch(slice1Actions.setShowMultiselect({ value: true }));
  };

  const createPlaceLinkMenu = () => {
    let menuList = [
      {
        label: "Remove link",
        method: removeLink,
        icon: null,
        visible: true,
        checked: false,
        info: "Remove link from place",
      },
      {
        label: "Link to Path",
        method: setPathLink,
        icon: null,
        visible: true,
        checked: false,
        info: "Get link to a path which has time location info and associated video",
      },
      {
        label: "Link to Video",
        method: setVideoLink,
        icon: null,
        visible: true,
        checked: false,
        info: "Set a link to a video.",
      },
      {
        label: "Link to Audio",
        method: setAudioLink,
        icon: null,
        visible: true,
        checked: false,
        info: "Set a link to a audio.",
      },
      {
        label: "Link to Section (HTML)",
        method: selectSection,
        icon: null,
        visible: true,
        checked: false,
        info: "Set a link to a section which contains user customized HTML.",
      },
      {
        label: "Link to URL",
        method: setURLLink,
        icon: null,
        visible: true,
        checked: false,
        info: "Set a link to a URL i.e. web page e.g. https://google.com",
      },
    ];
    setMenuModalList(menuList);
    setMenuModalHeading("Place Link Menu");
    setMenuModalImage(<i className="fa fa-pencil"></i>);
    setMenuModalPlace(null);
    setMenuModalInfo(
      "This applies to all filtered places i.e. all places shown in the places list. Apply or remove filters to select desired places."
    );
    setMenuModalShow(true);
    // this.setState({ MenuModalList: menuList, MenuModalHeading: "Place Link Menu", MenuModalShow: true });
  };

  const removeLink = () => {
    _place_operation = "SetLink";
    _link = { type: "none", value: "", offset: "0" };
    setTimeout(selectPlaces, 200);
  };

  const setPathLink = () => {
    const places = window.guide_obj.places.getPlacesToDisplay(null, "PolyLine");
    dispatch(slice1Actions.setMultiselectCallback({ value: setPathLinkCallBack }));
    dispatch(slice1Actions.setMultiselectItems({ value: places }));
    dispatch(slice1Actions.setMultiselectSelectedItems({ value: [] }));
    dispatch(slice1Actions.setMultiselectMode({ value: "uni" }));
    dispatch(slice1Actions.setMultiselectSearchText({ value: "" }));
    dispatch(slice1Actions.setMultiselectTitle({ value: "Select Places" }));
    dispatch(slice1Actions.setShowMultiselect({ value: true }));
  };

  const setPathLinkCallBack = (Places) => {
    if (Places.length < 1) return;
    const place = Places[0];
    _place_operation = "SetLink";
    _link = { type: "path", value: place.value, offset: "0" };
    setTimeout(selectPlaces, 200);
  };

  const setVideoLink = () => {
    setPromptModalShow(true);
    setPromptModalTitle("Video");
    setPromptModalMessage("Enter video URL");
    setPromptModalButtons([
      { Title: "OK", Method: setVideoLinkCallBack },
      { Title: "CANCEL", Method: null },
    ]);
    setPromptModalInputs([{ name: "Video URL", value: "" }]);
  };

  const setVideoLinkCallBack = (inputs) => {
    if (inputs.length < 1) return;
    _place_operation = "SetLink";
    _link = { type: "video", value: inputs[0].value, offset: "0" };
    setTimeout(selectPlaces, 200);
  };

  let _link = { type: "none", value: "", offset: "0" };

  const setAudioLink = () => {
    setPromptModalShow(true);
    setPromptModalTitle("Audio");
    setPromptModalMessage("Enter audio URL");
    setPromptModalButtons([
      { Title: "OK", Method: setAudioLinkCallBack },
      { Title: "CANCEL", Method: null },
    ]);
    setPromptModalInputs([{ name: "Audio URL", value: "" }]);
  };

  const setAudioLinkCallBack = (inputs) => {
    if (inputs.length < 1) return;
    _place_operation = "SetLink";
    _link = { type: "audio", value: inputs[0].value, offset: "0" };
    setTimeout(selectPlaces, 200);
  };

  const setURLLink = () => {
    setPromptModalShow(true);
    setPromptModalTitle("URL");
    setPromptModalMessage("Enter URL");
    setPromptModalButtons([
      { Title: "OK", Method: setURLLinkCallBack },
      { Title: "CANCEL", Method: null },
    ]);
    setPromptModalInputs([{ name: "URL", value: "" }]);
  };

  const setURLLinkCallBack = (inputs) => {
    if (inputs.length < 1) return;
    _place_operation = "SetLink";
    _link = { type: "url", value: inputs[0].value, offset: "0" };
    setTimeout(selectPlaces, 200);
  };

  const selectSection = () => {
    setSectionsDialogShow(true);
  };

  const selectSectionCallBack = (section) => {
    console.log("section", section.ID, section.Title);
    _place_operation = "SetLink";
    _link = { type: "section", value: section.ID, offset: "0" };
    setTimeout(selectPlaces, 200);
  };

  let pathLinkPlaceID = null;

  // const setPathLinkCallBack = (Places) => {
  //   if (Places.length < 1) return;
  //   const place = Places[0];
  //   _place_operation = "PathLink";
  //   pathLinkPlaceID = place.value;
  //   setTimeout(selectPlaces, 200);
  // };

  let AllPlacesSubList = [];
  const selectPlaces = () => {
    let selectedPlaces = [];
    let places = [];
    filtered_places.forEach((section) => {
      section.data.forEach((place_m) => {
        let place = window.guide_obj.places.getPlace(place_m.place.ID);
        places.push({
          label: place.Name,
          name: place.Name,
          value: place,
        });
      });
    });
    AllPlacesSubList = places;
    places.forEach((place) => {
      if (
        (_place_operation === "Favorite" && place.value.Favorite === true) ||
        (_place_operation === "Visited" && place.value.Visited === true) ||
        (_place_operation === "MustSee" && place.value.MustSee === true) ||
        (_place_operation === "Hidden" && place.value.Hidden === true) ||
        _place_operation === "Delete Places" ||
        _place_operation === "Create Events" ||
        _place_operation === "SetLink"
      ) {
        selectedPlaces.push({
          label: place.label,
          name: place.name,
          value: place.value,
        });
      }
    });
    //console.log("selectedPlaces, count", selectedPlaces, count);
    dispatch(slice1Actions.setMultiselectCallback({ value: selectPlacesCallBack }));
    dispatch(slice1Actions.setMultiselectItems({ value: places }));
    dispatch(slice1Actions.setMultiselectSelectedItems({ value: selectedPlaces }));
    dispatch(slice1Actions.setMultiselectMode({ value: "multi" }));
    dispatch(slice1Actions.setMultiselectSearchText({ value: "" }));
    dispatch(slice1Actions.setMultiselectTitle({ value: "Select Places" }));
    dispatch(slice1Actions.setShowMultiselect({ value: true }));
    // props.navigation.navigate("MultiSelectDialog", {
    //   selectionList: places,
    //   selectedItems: selectedPlaces,
    //   callBack: selectPlacesCallBack,
    //   title: "Places",
    //   selectText: "Select Places",
    // });
  };

  let places_selected = [];

  let _place_operation = "";

  const selectPlacesCallBack = (Places) => {
    if (Places == null) return;
    let places_selected = Places;
    if (_place_operation === "Delete Places") {
      places_selected.forEach((place_id) => {
        window.guide_obj.places.deletePlaceById(place_id.value.ID);

        if (showHiddenPlace.length > 0) {
          let hidden_places = [];
          for (let i = 0; i < showHiddenPlace.length; i++) {
            if (showHiddenPlace[i].ID !== place_id.value.ID) {
              hidden_places.push(showHiddenPlace[i]);
              break;
            }
          }
          dispatch(slice1Actions.setShowHiddenPlace({ value: hidden_places }));
        }
      });
      dispatch(slice1Actions.setGuide({ guide: null }));
      return;
    } else if (_place_operation === "Create Events New") {
      let schedule = "";
      let scheds = window.guide_obj.schedule.geScheduleNames();
      for (let s = 1; s < 99999; s++) {
        schedule = `Itinerary${s}`;
        let found = false;
        for (let x = 1; x < scheds.length; x++) {
          if (schedule === scheds[x].label) found = true;
        }
        if (found === false) break;
      }

      let dateGuide = DateTime.now({ zone: "utc" });
      dateGuide = dateGuide.set({ hour: 11 });
      dateGuide = dateGuide.set({ minutes: 0 });
      let dayDate = dateGuide;
      let thisDay = dayDate;
      let firstEventOfDay = true;
      let last_place = null;
      let rounding_to = user_info.event_time_rounding;
      if (typeof rounding_to === "undefined") rounding_to = 30;
      let PlaceOrderByDistance = Common.getPlaceOrderByDistance(places_selected, window.guide_obj);
      PlaceOrderByDistance.forEach((place) => {
        if (place === null) return;
        dayDate = addEvent(place, schedule, dayDate, last_place, rounding_to, firstEventOfDay);
        if (dayDate.day !== thisDay.day) {
          thisDay = dayDate;
          firstEventOfDay = true;
        } else firstEventOfDay = false;
        last_place = place;
      });
      dispatch(slice1Actions.setScheduleName({ value: schedule }));
      dispatch(slice1Actions.setGuide({ guide: null, fitToBounds: true }));
      //setPlacesSelected(places_selected);
      // dispatch(slice1Actions.setTempValue({ value: places_selected }));
      // setTimeout(() => selectFirstPlace(places_selected), 200);
      //createEventNew(null);
      return;
    } else if (_place_operation === "Create Events") {
      let schedule = "";
      let scheds = window.guide_obj.schedule.geScheduleNames();
      for (let s = 1; s < 99999; s++) {
        schedule = `Itinerary${s}`;
        let found = false;
        for (let x = 1; x < scheds.length; x++) {
          if (schedule === scheds[x].label) found = true;
        }
        if (found === false) break;
      }

      let dateGuide = DateTime.now({ zone: "utc" });
      dateGuide = dateGuide.set({ hour: 11 });
      dateGuide = dateGuide.set({ minutes: 0 });
      let dayDate = dateGuide;
      let last_place = null;
      let rounding_to = user_info.event_time_rounding;
      if (typeof rounding_to === "undefined") rounding_to = 30;
      places_selected.forEach((place_id) => {
        let place = window.guide_obj.places.getPlace(place_id.value.ID);
        if (place === null) return;
        dayDate = addEvent(place, schedule, dayDate, last_place, rounding_to);
        last_place = place;
      });
      dispatch(slice1Actions.setScheduleName({ value: schedule }));
      dispatch(slice1Actions.setGuide({ guide: null, fitToBounds: true }));
      return;
    } else if (_place_operation === "SetLink") {
      places_selected.forEach((place_id) => {
        let place = window.guide_obj.places.getPlace(place_id.value.ID);
        if (place === null) return;
        place.Link = _link;
      });
    }

    window.guide_obj.places.setPlaceAttributes(AllPlacesSubList, Places, _place_operation);
    UpdateDisplay();
  };

  const createEventNew = (place_id) => {
    let schedule = "";
    let scheds = window.guide_obj.schedule.geScheduleNames();
    for (let s = 1; s < 99999; s++) {
      schedule = `Itinerary${s}`;
      let found = false;
      for (let x = 1; x < scheds.length; x++) {
        if (schedule === scheds[x].label) found = true;
      }
      if (found === false) break;
    }

    let dateGuide = DateTime.now({ zone: "utc" });
    dateGuide = dateGuide.set({ hour: 11 });
    dateGuide = dateGuide.set({ minutes: 0 });
    let dayDate = dateGuide;
    let last_place = null;
    let rounding_to = user_info.event_time_rounding;
    if (typeof rounding_to === "undefined") rounding_to = 30;
    let PlaceOrderByDistance = Common.getPlaceOrderByDistance(TempValue, window.guide_obj);
    let start_index = 0;
    let end_index = PlaceOrderByDistance.length;
    let thisDay = dayDate;
    let firstEventOfDay = true;
    if (place_id !== null) {
      const index = PlaceOrderByDistance.findIndex((x) => (x.ID = place_id));
      if (index !== -1) {
        start_index = index;
        for (let i = start_index; i < end_index; i++) {
          dayDate = addEvent(PlaceOrderByDistance[i], schedule, dayDate, last_place, rounding_to, firstEventOfDay);
          if (dayDate.day !== thisDay.day) {
            thisDay = dayDate;
            firstEventOfDay = true;
          } else firstEventOfDay = false;
          last_place = PlaceOrderByDistance[i];
        }
        end_index = index - 1;
      }
    }
    for (let i = start_index; i < end_index; i++) {
      dayDate = addEvent(PlaceOrderByDistance[i], schedule, dayDate, last_place, rounding_to, firstEventOfDay);
      if (dayDate.day !== thisDay.day) {
        thisDay = dayDate;
        firstEventOfDay = true;
      } else firstEventOfDay = false;
      last_place = PlaceOrderByDistance[i];
    }
    dispatch(slice1Actions.setScheduleName({ value: schedule }));
    dispatch(slice1Actions.setGuide({ guide: null, fitToBounds: true }));
  };
  const addEvent = (place, Itinerary, dayDate, last_place, rounding_to, firstEventOfDay) => {
    let event = new Appointment(window.guide_obj.schedule);
    event.Subject = place.Name;
    event.Body = "";
    event.Itinerary = Itinerary;
    event.Places = place.ID;
    let travel_time = 0;
    if (last_place !== null) {
      const p1 = { latitude: last_place.Lat, longitude: last_place.Longi };
      const p2 = { latitude: place.Lat, longitude: place.Longi };
      let distance = Places.calculateDistance(p1, p2) * 1.2;
      travel_time = distance * 10;
      if (travel_time > 45) travel_time = distance * 2; //Math.min(distance * 2, 60);
      if (rounding_to === 0) travel_time = 0;
      else travel_time = Math.max(1, Math.round(travel_time / rounding_to)) * rounding_to;
    }
    //let total_time =
    if (!firstEventOfDay || travel_time > 30) dayDate = dayDate.plus({ minutes: travel_time });
    if (dayDate.hour + travel_time / 60 + place.Duration / 60 > 20) {
      dayDate = dayDate.plus({ hours: 24 });
      dayDate = dayDate.set({ hour: 11 });
      dayDate = dayDate.set({ minutes: 0 });
      event.Start = dayDate.toISO();
      dayDate = dayDate.plus({ minutes: place.Duration });
      event.End = dayDate.toISO();
      window.guide_obj.schedule.addEvent(event);
    } else {
      event.Start = dayDate.toISO();
      dayDate = dayDate.plus({ minutes: place.Duration });
      event.End = dayDate.toISO();
      window.guide_obj.schedule.addEvent(event);
    }
    if (dayDate.hour >= 19) {
      dayDate = dayDate.plus({ hours: 24 });
      dayDate = dayDate.set({ hour: 11 });
      dayDate = dayDate.set({ minutes: 0 });
    }
    return dayDate;
  };

  const SortMenu = () => {
    //console.log("SortBy", SortBy);
    const menuList = [
      {
        label: "Name",
        method: handleSort,
        icon: <i className="fas fa-check"></i>,
        checked: SortBy === SortByEnum.NAME,
        visible: true,
        info: "Sort the places based on the place name",
      },
      {
        label: "Category",
        method: handleSort,
        icon: <i className="fa fa-check"></i>,
        checked: SortBy === SortByEnum.CATEGORY,
        visible: true,
        info: "Sort the places based on the place category name. Note: Places with multiple categories will appear multiple times.",
      },
      {
        label: "Group",
        method: handleSort,
        icon: <i className="fa fa-check"></i>,
        checked: SortBy === SortByEnum.GROUP,
        visible: true,
        info: "Sort the places based on the place group name. Note: Places with multiple categories will appear multiple times.",
      },
      {
        label: "Rating",
        method: handleSort,
        icon: <i className="fa fa-check"></i>,
        checked: SortBy === SortByEnum.RATING,
        visible: true,
        info: "Sort the places based on the place rating",
      },
      {
        label: "Distance",
        method: handleSort,
        icon: <i className="fas fa-check"></i>,
        checked: SortBy === SortByEnum.DISTANCE,
        visible: true,
        info: "Sort the places based on the distance from the current selected place.",
      },
      {
        label: "Date",
        method: handleSort,
        icon: <i className="fas fa-check"></i>,
        checked: SortBy === SortByEnum.DATE,
        visible: true,
        info: "Sort the places based on the date if they are in the schedule.",
      },
    ];
    //console.log("menuList", menuList);
    setMenuModalList(menuList);
    setMenuModalHeading("Sort Places");
    setMenuModalImage(<i className="fas fa-tasks"></i>);
    setMenuModalPlace(null);
    setMenuModalShow(true);
    setMenuModalInfo("");
  };

  const myAlert = (message) => {
    setAlertModalTitle("Message");
    setAlertModalMessage(message);
    setAlertModalButtons([{ Title: "OK", Method: null }]);
    setAlertModalShow(true);
  };

  const UpdateDisplay = () => {
    dispatch(slice1Actions.setGuide({ guide: null }));
  };

  let number_of_places = 0;
  filtered_places.forEach((section) => {
    section.data.forEach((place_m) => {
      number_of_places++;
    });
  });

  if (SectionsDialogShow)
    return (
      <SectionsDialog
        show={SectionsDialogShow}
        onHide={() => setSectionsDialogShow(false)}
        update_display={null}
        mode={"select"}
        callback={selectSectionCallBack}
      />
    );
  return (
    <div style={{ marginLeft: 10 }}>
      <div className="places-header">
        {/* <i className="fas fa-search" onClick={() => setSearch(true)}></i> */}
        {/* <i className="fas fa-plus-circle"></i> */}
        <input type="text" value={SearchText} placeholder="Filter" onChange={handleSearChanged} />

        <i
          className="fas fa-filter"
          onClick={() => setShouldFilter(true)}
          style={{ color: noFiltering ? "#3598DB" : "#fdcb6e" }}
        ></i>
        {/* <i className="fas fa-search color-blue" onClick={() => searchMenu()}></i> */}
        {/* <div style={{ verticalAlign: "middle" }} onClick={() => EditMenu()}>
          <i className="fa fa-pencil color-blue"></i>
          <span>edit</span>
        </div> */}

        {GuideEditMode ? <i className="fa fa-pencil color-blue" onClick={() => EditMenu()}></i> : null}
        {MobileMode === false || CurrentAppScreen === "Places" ? (
          <i className="fas fa-sort-alpha-down color-blue" onClick={() => SortMenu()}></i>
        ) : null}
      </div>
      <p className="color-blue">{`Places: ${number_of_places}`}</p>
      {MenuModalShow ? (
        <MenuModal
          show={MenuModalShow}
          onHide={() => setMenuModalShow(false)}
          menu_modal_list={MenuModalList}
          menu_modal_heading={MenuModalHeading}
          menu_modal_image={MenuModalImage}
          menu_modal_place={MenuModalPlace}
          menu_modal_info={MenuModalInfo}
        />
      ) : null}

      <PromptModal
        show={PromptModalShow}
        onHide={() => setPromptModalShow(false)}
        PromptModalTitle={PromptModalTitle}
        PromptModalMessage={PromptModalMessage}
        PromptModalButtons={PromptModalButtons}
        PromptModalInputs={PromptModalInputs}
      />
      <AlertModal
        show={AlertModalShow}
        onHide={() => setAlertModalShow(false)}
        AlertModalTitle={AlertModalTitle}
        AlertModalMessage={AlertModalMessage}
        AlertModalButtons={AlertModalButtons}
      />
      <FilterModal show={shouldFilter} onHide={() => setShouldFilter(false)} />
      {GroupsDialogShow ? (
        <GroupsDialog
          show={GroupsDialogShow}
          onHide={() => setGroupsDialogShow(false)}
          update_display={UpdateDisplay}
        />
      ) : null}

      {CategoriesDialogShow ? (
        <CategoriesDialog
          show={CategoriesDialogShow}
          onHide={() => setCategoriesDialogShow(false)}
          update_display={UpdateDisplay}
        />
      ) : null}
    </div>
  );
};

export default PlacesHeader;
