import { Place_Source } from "./Place";
import Common, { Settings, SortByEnum, COLORS } from "../Common/Common";
import { DateTime } from "luxon";

export default class Appointment {
  constructor(schedule) {
    this.Subject = "New";
    this.Body = "";
    this.Start = "";
    this.End = "";
    this.Places = "";
    this.ID = "";
    this.Source = Place_Source.USER;
    this.Itinerary = "Main";
    if (schedule != null) {
      var r = Math.floor(Math.random() * 1000000000 + 1);
      while (schedule.get_event(r) != null) r = Math.floor(Math.random() * 1000000000 + 1);
      this.ID = r.toString();
    }
  }

  createHTML(placesObj) {
    let isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    let font_size = isMobile ? "100%" : "100%";
    let event = this;
    let html_doc = "";
    if (window.web_app)
      html_doc = `<html><head><style type="text/css">.c0 { font-family: &#39;Arial&#39;; text-decoration: none } .c1 { margin: 0px 0px 10px; padding: 10px 0px; font-size: 100%; text-decoration: none } .c2 { font-weight: bold; text-decoration: none } .c3 { text-decoration: none } .c4 { color: Blue; text-decoration: none } .c5 { margin: 0px 0px 10px; text-decoration: none } </style></head><body class="c0">`;
    else
      html_doc = `<html><head><style type="text/css">.c0 { font-family: &#39;Arial&#39;; text-decoration: none } .c1 { margin: 0px 0px 10px; padding: 10px 0px; font-size: 100%; text-decoration: none } .c2 { font-weight: bold; text-decoration: none } .c3 { text-decoration: none } .c4 { color: Blue; text-decoration: none } .c5 { margin: 0px 0px 10px; text-decoration: none } </style></head><body class="c0">`;
    let startDate = DateTime.fromISO(event.Start, { zone: "utc" });
    let endDate = DateTime.fromISO(event.End, { zone: "utc" });
    html_doc += `<div style="font-size: ${font_size};">`;
    html_doc += `<p class="c1"><span class="c2">${Common.formatDateLuxon(startDate)} </span></p>`;
    html_doc += `<p class="c1"><span class="c2">${Common.formatTimeLuxon(startDate)}-${Common.formatTimeLuxon(
      endDate
    )}<br/></span>`;
    html_doc += `<span class="c1">${event.Subject}<br/></span>`;
    html_doc += `<span class="c3">${event.Body}<br/></span>`;
    const places = event.get_places(placesObj);
    //console.log("places", places);
    for (let j = 0; j < places.length; j++) {
      if (typeof places[j].Name !== "undefined") html_doc += places[j].Info;
    }
    for (let j = 0; j < places.length; j++) {
      if (typeof places[j].Name !== "undefined")
        html_doc += `<a href="placemenulink:${places[j].ID}" class="c4"><span class="c3">${places[j].Name}${
          j === places.length - 1 ? "" : "; "
        } </span></a>`;
    }
    html_doc += `</p></body></div></html>`;
    return html_doc;
  }

  json_to_appointment(schedule, event_json, old) {
    this.Subject = event_json.Subject;
    this.Body = event_json.Body;
    if (old) {
      //console.log("OLD event_json.Start:", event_json.Start);
      this.Start = DateTime.fromISO(Common.LuxonDateTimeFromJSDate(new Date(event_json.Start)), { zone: "utc" });
      //console.log("OLD this.Start:", this.Start);
      this.End = DateTime.fromISO(Common.LuxonDateTimeFromJSDate(new Date(event_json.End)), { zone: "utc" });
    } else {
      //console.log("NEW event_json.Start:", event_json.Start);
      this.Start = DateTime.fromISO(event_json.Start, { zone: "utc" });
      //console.log("NEW this.Start:", this.Start);
      this.End = DateTime.fromISO(event_json.End, { zone: "utc" });
    }
    //console.log("event_json.Start", event_json.Start, this.Start, event_json.Itinerary, event_json.Subject);
    //console.log("event_json.Start_1", this.Start, event_json.Itinerary, event_json.Subject);

    this.Places = event_json.Places;
    if (this.Places === null || typeof this.Places === "undefined") this.Places = "";
    this.ID = "";
    if (typeof event_json.Itinerary != "undefined") this.Itinerary = event_json.Itinerary;
    this.Source = Place_Source.GUIDE;
    if (schedule != null) {
      var r = Math.floor(Math.random() * 1000000000 + 1);
      while (schedule.get_event(r) != null) r = Math.floor(Math.random() * 1000000000 + 1);
      this.ID = r.toString();
    }
  }
  copy(cevent) {
    this.Subject = cevent.Subject;
    this.Body = cevent.Body;
    this.Start = cevent.Start; //Common.dateToString(cevent.Start);
    this.End = cevent.End; //Common.dateToString(cevent.End);
    this.Places = cevent.Places;
    //this.ID = cevent.ID;
    this.Source = Place_Source.USER;
    if (typeof cevent.Itinerary != "undefined") this.Itinerary = cevent.Itinerary;
  }
  get_places(places_obj) {
    var placesList = [];
    //console.log("get_place_ids1");
    if (this.Places == null) return placesList;
    //console.log("get_place_ids2:", this.Places);
    if (this.Places.indexOf(",") != -1) {
      var places = this.Places.split(",");
      //console.log("get_place_ids3:", places);
      for (var key in places) {
        var Place = places_obj.getPlace(places[key].trim());
        if (Place != null) placesList.push(Place);
      }
    } else {
      var Place = places_obj.getPlace(this.Places.trim());
      //console.log("get_place_ids4:", Place);
      if (Place != null) {
        placesList.push(Place);
      }
    }
    return placesList;
  }
  // get_places_old() {
  //   var placeList = [];
  //   if (this.Places == null) return placeList;
  //   if (this.Places.indexOf(",") != -1) {
  //     var places = this.Places.split(",");
  //     for (var key in places) {
  //       var Place = getPlace(guide_obj.places.all_places, $.trim(places[key]));
  //       if (Place != null) placeList.push(Place);
  //     }
  //   } else {
  //     var Place = getPlace(guide_obj.places.all_places, $.trim(this.Places));
  //     if (Place != null) {
  //       placeList.push(Place);
  //     }
  //   }
  //   return placeList;
  // }
  // get_placeIDs() {
  //   var placeList = [];
  //   if (this.Places == null) return placeList;
  //   if (this.Places.indexOf(",") != -1) {
  //     var places = this.Places.split(",");
  //     for (var key in places) {
  //       var Place = getPlace(guide_obj.places.all_places, $.trim(places[key]));
  //       if (Place != null) placeList.push(Place.ID);
  //     }
  //   } else {
  //     var Place = getPlace(guide_obj.places.all_places, $.trim(this.Places));
  //     if (Place != null) {
  //       placeList.push(Place.ID);
  //     }
  //   }
  //   return placeList;
  // }
}
