import React, { useState, useEffect, useRef } from "react";
import { Modal } from "react-bootstrap";
import "./Modal.css";
import { Editor } from "@tinymce/tinymce-react";
import { urlSchemeToFunctions, htmlScaleAdjust, htmlScaleFactor } from "../../CommonWeb/Common";
import HTMLLinksModalClass from "./HTMLLinksModalClass";
import HTMLInsertDialog from "./HTMLInsertDialog";
import HTMLEditorComponent from "./HTMLEditorComponent";

import { useSelector } from "react-redux";

const HTMLEditorModal = (props) => {
  // console.log("HTMLReader", props);
  // const [ShowHTML, setShowHTML] = useState(false);
  // const [ShowCSS, setShowCSS] = useState(false);

  // const [HTMLText, setHTMLText] = useState("");
  // const [ContentStyle, setContentStyle] = useState("");

  // const [HTMLtoShow, setHTMLtoShow] = useState("");
  // //const [CSStoApply, setCSStoApply] = useState("");
  // const [FullScreen, setFullScreen] = useState("md");
  // const [ShowHTMLLinks, setShowHTMLLinks] = useState(false);
  // const [ShowHTMLInsertDialog, setShowHTMLInsertDialog] = useState(false);

  // let user_info = useSelector((state) => state.user_info);
  // const editorRef = useRef(null);

  // useEffect(() => {
  //   let html = props.html;
  //   if (html.indexOf(`<div class="home-resize-panel">`, 0) === -1) html = htmlScaleAdjust(html);
  //   setContentStyle(getCSS(props.html));
  //   setHTMLtoShow(html);
  // }, [props.html]);

  // const handleEditorChange = (e) => {
  //   //console.log("Content was updated:", e.target.getContent());
  // };

  // const insertLink = () => {
  //   console.log("insertLink");
  //   setShowHTMLLinks(true);
  //   //const link = `<a href="urllink:https://www.google.com">google.com<\a>`;
  //   //let link = `<a href="urllink:https://www.google.com"><button style="border: none; padding:32px; margin:10px; color:white; border-radius:10%; font-size:36px; background-color: #008CBA;">google.com</button></a>&nbsp;`;

  //   //editorRef.current.insertContent(link);
  // };

  // const insertLinkCallback = (link) => {
  //   let content = "";
  //   if (link.linkUI === "Text only") {
  //     content = `<a href="${link.url}">${link.text}<\a>`;
  //   } else if (link.linkUI === "With Image") {
  //     content = `<a href="${link.url}"><img src="${link.imgURL}" alt="image" style="width:${link.imageWidth}px; border-radius:10%; margin:10px;"></a>&nbsp;&nbsp;`;
  //   } else {
  //     content = `<a href="${link.url}"><button style="border: none; padding:32px; margin:10px; color:white; border-radius:10%; font-size:36px; background-color: #008CBA;">${link.text}</button></a>&nbsp;`;
  //   }
  //   console.log("insertLinkCallback", content, link.text, link.url);
  //   editorRef.current.insertContent(content);
  // };

  // const insertContent = () => {
  //   setShowHTMLInsertDialog(true);
  // };

  // const insertContentCallback = (content) => {
  //   if (editorRef.current) {
  //     console.log(content.payload.html);
  //     editorRef.current.insertContent(content.payload.html);
  //   }
  // };

  // const getContent = () => {
  //   if (editorRef.current) {
  //     return editorRef.current.getContent();
  //   }
  // };

  // const setContent = (content) => {
  //   if (editorRef.current) {
  //     //console.log("setContent", content);
  //     return editorRef.current.setContent(content);
  //   }
  // };

  // const getCSS = (html) => {
  //   let index1 = html.indexOf("<style>", 0);
  //   let index2 = html.indexOf("</style>", index1 + 1);
  //   let inline_style = "";
  //   if (index1 !== -1 && index2 !== -1) {
  //     inline_style = html.substring(index1 + 7, index2);
  //   }
  //   const scale = htmlScaleFactor();
  //   let content_style = `a {color: #1913AB; text-decoration: none !important;}
  //   a:visited {color: #661099;} a:active {color: red;}
  //   a:hover {color: purple; background: #AFB; }
  //   .redtext {color: red;} .home-resize-panel {transform-origin: top left; transform: scale(${scale.div}); font-size: ${scale.font};} `;
  //   content_style = content_style + inline_style;
  //   return content_style;
  // };

  // // useEffect(() => {
  // //let html = props.html;
  // //let content_style = getCSS(html);
  // //console.log("content_style", content_style);
  // //if (html.indexOf(`<div class="home-resize-panel">`, 0) === -1) html = htmlScaleAdjust(html);
  // //setHTMLtoShow(html);
  // //setCSStoApply(content_style);
  // //console.log("content_style", content_style);
  // // }, []);

  // const handleOK = () => {
  //   if (ShowCSS) {
  //     setShowCSS(false);
  //     return;
  //   }
  //   props.onHide();
  //   const content = getContent();
  //   //console.log("content", content);
  //   if (props.callback !== null) {
  //     props.callback("<style>" + ContentStyle + "</style>" + content);
  //   }
  //   console.log("<style>" + ContentStyle + "</style>" + content);
  // };

  // const handleCSSChanged = (event) => {
  //   const content_style = event.target.value;
  //   setContentStyle(content_style);
  //   //console.log(content_style);
  // };

  // const editCSS = () => {
  //   if (!ShowCSS) setHTMLtoShow(getContent());
  //   setShowCSS(!ShowCSS);
  // };

  // const handleHTML = () => {
  //   if (ShowHTML === false) {
  //     var content = getContent();
  //     var formatted = formatHTML(unformatHTML(content));
  //     //console.log("content", content);
  //     //console.log("formatted", formatted);
  //     setHTMLText("<style>" + ContentStyle + "</style>" + formatted);
  //     setShowHTML(true);
  //   } else {
  //     setShowHTML(false);
  //     let html = HTMLText;
  //     ContentStyle = getCSS(html);
  //     //if (html.indexOf(`<div class="home-resize-panel">`, 0) === -1) html = htmlScaleAdjust(html);
  //     //setCSStoApply(content_style);

  //     //setHTMLtoShow(html);
  //     setContent(html);
  //   }
  // };

  // // function handleFullScreen(breakpoint) {
  // //   setShowModal(false);
  // //   setFullScreen(breakpoint);
  // //   setShowModal(true);
  // //   //setShow(true);
  // // }
  // const handleHTMLChanged = (event) => {
  //   setHTMLText(event.target.value);
  // };
  // const handleFullScreen = () => {
  //   const _FullScreen = FullScreen === "md" ? "lg" : "md";
  //   setFullScreen(_FullScreen);
  // };

  // function formatHTML(html) {
  //   var indent = "\n";
  //   var tab = "\t";
  //   var i = 0;
  //   var pre = [];

  //   html = html
  //     .replace(new RegExp("<pre>((.|\\t|\\n|\\r)+)?</pre>"), function (x) {
  //       pre.push({ indent: "", tag: x });
  //       return "<--TEMPPRE" + i++ + "/-->";
  //     })
  //     .replace(new RegExp("<[^<>]+>[^<]?", "g"), function (x) {
  //       var ret;
  //       var tag = /<\/?([^\s/>]+)/.exec(x)[1];
  //       var p = new RegExp("<--TEMPPRE(\\d+)/-->").exec(x);

  //       if (p) pre[p[1]].indent = indent;

  //       if (
  //         [
  //           "area",
  //           "base",
  //           "br",
  //           "col",
  //           "command",
  //           "embed",
  //           "hr",
  //           "img",
  //           "input",
  //           "keygen",
  //           "link",
  //           "menuitem",
  //           "meta",
  //           "param",
  //           "source",
  //           "track",
  //           "wbr",
  //         ].indexOf(tag) >= 0
  //       )
  //         // self closing tag
  //         ret = indent + x;
  //       else {
  //         if (x.indexOf("</") < 0) {
  //           //open tag
  //           if (x.charAt(x.length - 1) !== ">")
  //             ret = indent + x.substr(0, x.length - 1) + indent + tab + x.substr(x.length - 1, x.length);
  //           else ret = indent + x;
  //           !p && (indent += tab);
  //         } else {
  //           //close tag
  //           indent = indent.substr(0, indent.length - 1);
  //           if (x.charAt(x.length - 1) !== ">")
  //             ret = indent + x.substr(0, x.length - 1) + indent + x.substr(x.length - 1, x.length);
  //           else ret = indent + x;
  //         }
  //       }
  //       return ret;
  //     });

  //   for (i = pre.length; i--; ) {
  //     html = html.replace(
  //       "<--TEMPPRE" + i + "/-->",
  //       pre[i].tag.replace("<pre>", "<pre>\n").replace("</pre>", pre[i].indent + "</pre>")
  //     );
  //   }

  //   let temp = html.charAt(0) === "\n" ? html.substr(1, html.length - 1) : html;
  //   return temp.replace(/(^[ \t]*\n)/gm, "");
  // }

  // function unformatHTML(html) {
  //   var i = 0;
  //   var pre = [];

  //   html = html
  //     .replace(new RegExp("<pre>((.|\\t|\\n|\\r)+)?</pre>"), function (x) {
  //       pre.push(x);
  //       return "<--TEMPPRE" + i++ + "/-->";
  //     })
  //     .replace(/\n/g, "")
  //     .replace(/\t/g, "");

  //   for (i = pre.length; i--; ) {
  //     html = html.replace("<--TEMPPRE" + i + "/-->", pre[i]);
  //   }

  //   html = html.replace(new RegExp("<pre>\\n"), "<pre>").replace(new RegExp("\\n\\t*</pre>"), "</pre>");
  //   return html;
  // }

  // const handleClose = () => {
  //   if (ShowCSS) {
  //     setShowCSS(false);
  //     return;
  //   }
  //   props.onHide();
  // };

  // if (ShowHTMLLinks)
  //   return (
  //     <HTMLLinksModalClass show={ShowHTMLLinks} onHide={() => setShowHTMLLinks(false)} callback={insertLinkCallback} />
  //   );
  // else
  return (
    <div className="html-reader-modal">
      <Modal
        {...props}
        size="lg"
        // show={ShowModal}
        // fullscreen={FullScreen}
        aria-labelledby="contained-modal-title-vcenter"
        onHide={props.onHide}
        enforceFocus={false}
        //enforceFocus={false}
      >
        {/* <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">{HTMLTitle}</Modal.Title>
        </Modal.Header> */}
        <Modal.Body>
          <div className="html-reader-modal">
            {props.show ? (
              <HTMLEditorComponent
                show={props.show}
                onHide={() => props.onHide()}
                html={props.html}
                callback={props.callback}
                callback_rt={props.callback_rt}
                title={props.title}
              />
            ) : null}
          </div>
        </Modal.Body>
      </Modal>
      {/* <HTMLLinksModalClass
        show={ShowHTMLLinks}
        onHide={() => setShowHTMLLinks(false)}
        callback={insertLinkCallback}
        user_info={user_info}
      />
      <HTMLInsertDialog
        show={ShowHTMLInsertDialog}
        onHide={() => setShowHTMLInsertDialog(false)}
        callback={insertContentCallback}
        user_info={user_info}
        section={null}
      /> */}
    </div>
  );
};

export default HTMLEditorModal;
