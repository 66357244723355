export default class WikiMedia {
  constructor() {
    this.media = [];
  }

  //https://commons.wikimedia.org/w/api.php?action=query&generator=search&gsrnamespace=6&gsrsearch=%22riva+ligure%22&gsrlimit=50&gsroffset=20&prop=imageinfo&iiprop=url|canonicaltitle&iilimit=1&iiurlwidth=800

  static searchImages = (title, callback) => {
    const gsrsearch = title.replace(/ /g, "%20");
    //var url = `https://commons.wikimedia.org/w/api.php?action=query&generator=search&gsrnamespace=6&gsrsearch="${gsrsearch}&gsrlimit=50&gsroffset=20&prop=imageinfo&iiprop=url|canonicaltitle&iilimit=1&iiurlwidth=800`;
    const url = `https://andiamotravelapp.com/api/wikimedia_get_images.php?gsrsearch=${gsrsearch}`;
    console.log("WikiMedia", url);
    fetch(url)
      .then(function (response) {
        return response.json();
      })
      .then(function (response) {
        //console.log("response", JSON.stringify(response));
        var images = WikiMedia.processImages(response);
        callback(images);
      })
      .catch(function (error) {
        console.log("Error in Wikimedia search", error);
        callback([]);
      });
  };

  static processImages = (response) => {
    let images = [];
    if (typeof response.query === "undefined") return [];
    if (typeof response.query.pages === "undefined") return [];
    for (var key in response.query.pages) {
      if (response.query.pages.hasOwnProperty(key)) {
        const photo = response.query.pages[key].imageinfo[0];
        images.push({
          name: photo.canonicaltitle.replace("File:", ""),
          url: photo.thumburl,
          url_m: photo.thumburl,
          folder: "",
          id: "",
          date: "",
          type: 0,
        });
      }
    }
    return images;
  };
}
