import { connect } from "react-redux";
import React, { Component, useState, useEffect } from "react";
import { Modal, Form, Button, Spinner } from "react-bootstrap";
import AlertModal from "./AlertModal";
import MyToolTip from "./MyToolTip";
import "./Modal.css";
import PromptModal from "./PromptModal";
import MultiSelectModal from "./MultiSelectModal";
import IconsDialog from "./IconsDialog";
import Place from "../../Models/Place";
import DataBase from "../../dataBase/liquidGuides";
import { countries } from "../../Data/Countries";

class AiDialog extends Component {
  constructor(props) {
    super(props);
    let city = "Florence";
    let country = "Italy";
    let attributes = window.guide_obj.attributes;
    if (attributes.cities.length > 0) {
      city = attributes.cities[0];
    }
    if (attributes.countries.length > 0) {
      country = attributes.countries[0];
    }
    let place = null;
    if (typeof this.props.id !== "undefined") {
      place = window.guide_obj.places.getPlace(this.props.id);
    }
    let location = null;
    if (typeof this.props.location !== "undefined") {
      location = this.props.location;
    }
    //console.log("this.props.location", this.props.location);
    //console.log("location", location);
    this.state = {
      selectedQuestion: "When is the best time to visit Florence",
      selectedNumber: "3",
      selectedCity: city,
      selectedCountry: country,
      place: place,
      location: location,
      answer: "",
      SearchText: "",
      proximity: "in",

      AlertModalShow: false,
      activityIndicator: false,
      AlertModalTitle: "",
      AlertModalMessage: "",
      AlertModalButtons: [],

      PromptModalShow: false,
      PromptModalTitle: "Enter Value",
      PromptModalMessage: "",
      PromptModalButtons: [],
      PromptModalInputs: [],

      MultiselectItems: [],
      MultiselectSelectedItems: [],
      MultiselectMode: "uni",
      MultiselectCallback: null,
      MultiselectTitle: "Select",
      MultiselectSearchText: "",
      ShowMultiselect: false,
    };
  }
  componentDidMount() {}

  _user_info = null;
  MyAlert = (message) => {
    this.setState({
      AlertModalTitle: "Message",
      AlertModalMessage: message,
      AlertModalButtons: [{ Title: "OK", Method: null }],
      AlertModalShow: true,
    });
  };

  modifyCity = () => {
    this.setState({
      PromptModalShow: true,
      PromptModalTitle: "City",
      PromptModalMessage: "Enter city Name",
      PromptModalButtons: [
        { Title: "OK", Method: this.ModifyCityCallback },
        { Title: "CANCEL", Method: null },
      ],
      PromptModalInputs: [{ name: "City Name", value: this.state.selectedCity }],
    });
  };

  ModifyCityCallback = (inputs) => {
    if (inputs === null) return;
    let city = inputs[0].value;
    this.setState({ selectedCity: city });
  };

  modifyCountry = () => {
    this.setState({
      PromptModalShow: true,
      PromptModalTitle: "Country",
      PromptModalMessage: "Enter Country Name",
      PromptModalButtons: [
        { Title: "OK", Method: this.ModifyCountryCallback },
        { Title: "CANCEL", Method: null },
      ],
      PromptModalInputs: [{ name: "Country Name", value: this.state.selectedCountry }],
    });
  };

  ModifyCountryCallback = (inputs) => {
    if (inputs === null) return;
    let country = inputs[0].value;
    this.setState({ selectedCountry: country });
  };

  numberChanged = (e) => {
    this.setState({ selectedNumber: e.target.value });
  };

  questionChanged = (e) => {
    let quest = e.target.value;
    if (quest === "Choose a question") return;
    this.setState({ selectedQuestion: quest, answer: "", activityIndicator: true });

    console.log("Question", quest);
    //this.MyAlert(`Question: ${quest}`);
    //quest = encodeURIComponent(quest);
    var url = `https://andiamotravelapp.com/api/travel_questionsGPT4.php?q="${quest}"`;
    let _this = this;
    fetch(url)
      .then(function (response) {
        return response.json();
      })
      .then(function (response) {
        if (response === null || typeof response === "undefined") {
          console.log("Error in AI response");
          _this.MyAlert("Error in AI response");
          _this.setState({ activityIndicator: false });
          return;
        }
        if (response.choices === null || typeof response.choices === "undefined") {
          console.log("Error in AI answer, choices", response);
          _this.MyAlert("Error in AI answer, choices");
          _this.setState({ activityIndicator: false });
          return;
        }
        if (response.choices.length === 0) {
          console.log("Error in AI answer, choices length", response);
          _this.MyAlert("Error in AI answer, choices length");
          _this.setState({ activityIndicator: false });
          return;
        }
        if (response.choices[0].message === null || typeof response.choices[0].message === "undefined") {
          console.log("Error in AI answer, message", response);
          _this.MyAlert("Error in AI answer, message");
          _this.setState({ activityIndicator: false });
          return;
        }
        if (
          response.choices[0].message.content === null ||
          typeof response.choices[0].message.content === "undefined"
        ) {
          console.log("Error in AI answer, message content", response);
          _this.MyAlert("Error in AI answer, message content");
          _this.setState({ activityIndicator: false });
          return;
        }
        let answer = response.choices[0].message.content;
        if (answer === null || typeof answer === "undefined") {
          console.log("Error in AI answer, text");
          _this.MyAlert("Error in AI answer, text");
          _this.setState({ activityIndicator: false });
          return;
        }
        if (answer.startsWith("\n")) answer = answer.replace("\n", "");
        if (answer.startsWith("\n")) answer = answer.replace("\n", "");
        if (response.choices !== null) _this.setState({ answer: answer, activityIndicator: false });
        if (answer.startsWith("Sorry")) {
          const index = answer.indexOf("[");
          if (index !== -1) {
            answer = answer.substring(index);
          } else {
            _this.setState({ activityIndicator: false });
            _this.MyAlert("Error in AI response", answer);
            return;
          }
        }
        //if (response.choices !== null) _this.setState({ answer: JSON.stringify(response), activityIndicator: false });
      })
      .catch(function (error) {
        console.log("Error in Wikipedia search", error);
        _this.setState({ answer: error, activityIndicator: false });
      });
  };

  items = [
    { label: "10-day itinerary for Florence Italy in json format with: time, place and description", params: "City" },
    { label: "When is the best time to visit Florence", params: "City" },
    { label: "Top 10 Places to Visit in Florence", params: "City" },
    { label: "Top 10 popular foods in Italy", params: "Country" },
    { label: "Top 10 dishes to try in Italy", params: "Country" },
    { label: "Top 10 local dishes to try in Florence", params: "City" },
    { label: "What is the phone number for police in Florence", params: "City" },
    { label: "Top 10 attractions to visit in Florence", params: "City" },
    { label: "Top 10 attractions to visit within 100Km from Florence", params: "City" },
    { label: "What are some phone numbers for taxis in Florence", params: "City" },
    { label: "How to dial the US from Italy", params: "Country2" },
    { label: "What is the current exchange rate from dollar", params: "Currency" },
    { label: "What is the currency in Italy", params: "Country" },
    { label: "Top 10 day trips from Florence", params: "City" },
    { label: "Top 10 cities to visit near Florence", params: "City" },
    { label: "Top 10 beaches near Florence", params: "City" },
    { label: "What are some phone numbers for ambulance in Florence", params: "City" },
    { label: "What are some phone numbers for ambulance in Florence", params: "City" },
    { label: "Top 10 neighborhoods to stay in Florence", params: "City" },
    { label: "What is the local language in Italy", params: "Country" },
    { label: "Top 10 cities to visit in Italy", params: "Country" },
    { label: "Top 10 scenic drives near Florence Italy", params: "City" },
    { label: "Top 10 national holidays in Italy", params: "Country" },
    { label: "Is today a national holiday in Italy", params: "Country" },
    { label: "Tell me about Florence Italy", params: "City" },
    { label: "Top 10 outdoor experiences near Florence Italy", params: "City" },
    { label: "Top 10 restaurants in Florence Italy", params: "City" },
    { label: "What are the open times for Florence Italy", params: "Place" },
    { label: "Describe a 10 day itinerary, with hours, for Florence Italy", params: "City" },
    { label: "Describe a 10 day itinerary, with hours, near Florence Italy", params: "City" },
  ];

  handleSearChanged = (event) => {
    //console.log(event.target.value);
    this.setState({ SearchText: event.target.value });
  };

  handledDestinationChanged = (event) => {
    this.setState({ selectedCity: event.target.value });
  };

  handledCountryChanged = (event) => {
    this.setState({ selectedCountry: event.target.value });
  };

  selectCountry = () => {
    let _countries = [];
    const countries_sorted = countries.sort();
    let id = 1;
    countries_sorted.forEach((country) =>
      _countries.push({
        label: country,
        name: country,
        value: country,
        id: (id++).toString(),
      })
    );
    this.MultiSelect(_countries, [], "uni", this.selectedCountry, "Select Country");
  };

  selectedCountry = (countries) => {
    let country = "";
    if (countries.length < 1) country = "";
    else country = countries[0].value;
    this.setState({ selectedCountry: country });
  };

  MultiSelect = (Items, SelectedItems, Mode, Callback, Title) => {
    //console.log("MultiSelect");
    this.setState({
      MultiselectItems: Items,
      MultiselectSelectedItems: SelectedItems,
      MultiselectMode: Mode,
      MultiselectCallback: Callback,
      MultiselectTitle: Title,
      MultiselectSearchText: "",
      ShowMultiselect: true,
    });
  };

  proximityChanged = (e) => {
    this.setState({ proximity: e.target.value });
  };

  render() {
    //let questions = JSON.parse(JSON.stringify(this.items));
    //console.log("this.props.questions", this.props.questions);
    let questions = [];
    try {
      questions = JSON.parse(this.props.questions);
    } catch (e) {
      questions = this.items;
      //questions = [];
      console.log("error parsing JSON this.props.questions", e);
    }

    // try {
    //   questions = JSON.parse(this.props.questions);
    // } catch (e) {
    //   questions = [];
    //   console.log("error parsing JSON this.props.questions", e);
    // }
    // if (this.props.user_info.uid === "E2Fqqu9FWDgTdpOzdKQOKEdo6wA3") {
    //   questions.push({
    //     label: "10-day itinerary for Florence Italy in json format with: time, place and description",
    //     params: "City",
    //   });
    //   questions.push({
    //     label: "Which cities to visit in Italy, for how many days and in which order, for a 10-day trip",
    //     params: "City",
    //   });
    // }

    questions = questions.sort((a, b) => (a.label.toUpperCase() > b.label.toUpperCase() ? 1 : -1));
    // let destination = this.state.selectedCity;
    // let country = `${this.state.selectedCountry}`;
    // if (this.state.place !== null) {
    //   destination = `${this.state.place.Name}, ${this.state.selectedCity}`;
    // }
    // if (this.state.location !== null) {
    //   destination = `${this.state.location.Lat.toFixed(6)},${this.state.location.Longi.toFixed(6)}`;
    //   country = "";
    // }
    //console.log("this.state.selectedNumber", this.state.selectedNumber);
    let _questions = [];
    for (let i = 0; i < questions.length; i++) {
      if (this.state.place === null && questions[i].params === "Place") continue;
      if (questions[i].label.includes("Florence"))
        questions[i].label = questions[i].label
          //.replace("Florence ", this.state.selectedCity)
          .replace("Florence", this.state.selectedCity)
          .replace("Italy", this.state.selectedCountry)
          .replace("10", this.state.selectedNumber)
          .replace(" in ", ` ${this.state.proximity} `);
      else
        questions[i].label = questions[i].label
          //.replace("Florence ", this.state.selectedCity)
          .replace("Florence", this.state.selectedCity)
          .replace("Italy", this.state.selectedCountry)
          .replace("10", this.state.selectedNumber);
      if (
        this.state.SearchText !== "" &&
        !questions[i].label.toUpperCase().includes(this.state.SearchText.toUpperCase())
      )
        continue;
      _questions.push(questions[i]);
    }
    if (_questions.findIndex((x) => x.label === "Choose a question") === -1)
      _questions.splice(0, 0, {
        label: "Choose a question",
        params: "None",
      });
    //console.log("_questions", _questions);
    if (this.state.AlertModalShow)
      return (
        <AlertModal
          show={this.state.AlertModalShow}
          onHide={() => this.setState({ AlertModalShow: false })}
          AlertModalTitle={this.state.AlertModalTitle}
          AlertModalMessage={this.state.AlertModalMessage}
          AlertModalButtons={this.state.AlertModalButtons}
        />
      );
    if (this.state.PromptModalShow)
      return (
        <PromptModal
          show={this.state.PromptModalShow}
          onHide={() => this.setState({ PromptModalShow: false })}
          PromptModalTitle={this.state.PromptModalTitle}
          PromptModalMessage={this.state.PromptModalMessage}
          PromptModalButtons={this.state.PromptModalButtons}
          PromptModalInputs={this.state.PromptModalInputs}
        />
      );
    if (this.state.ShowMultiselect)
      return (
        <MultiSelectModal
          show={this.state.ShowMultiselect}
          onHide={() => this.setState({ ShowMultiselect: false })}
          MultiselectItems={this.state.MultiselectItems}
          MultiselectSelectedItems={this.state.MultiselectSelectedItems}
          MultiselectMode={this.state.MultiselectMode}
          MultiselectCallback={this.state.MultiselectCallback}
          MultiselectTitle={this.state.MultiselectTitle}
          MultiselectSearchText={this.state.MultiselectSearchText}
        />
      );
    return (
      <div className="modal">
        <Modal
          {...this.props}
          size="lg"
          minHeight="500px"
          center={false}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          scrollable={true}
        >
          <Modal.Header>
            <div style={{ width: "100%" }}>
              <div className="sort">
                <div className="dialog-heading">
                  <div className="dialog-heading">
                    <div>
                      <h4 style={{ color: "#3598DB" }}>Travel wizard</h4>
                    </div>
                    {/* <i className="fas fa-check" onClick={() => this.saveSettings()}></i> */}

                    <input
                      type="text"
                      value={this.state.SearchText}
                      placeholder="Filter"
                      onChange={this.handleSearChanged}
                    />
                    <span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>

                    <div>
                      <i className={`fas fa-times`} onClick={() => this.props.onHide()}></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Header>
          <Modal.Body>
            {/* <p className="attribute">City</p>
            <Form.Group>
              <Form.Control
                type="text"
                value={this.state.Name}
                onChange={(e) => this.handleChangeName(e.target.value)}
              />
            </Form.Group> */}
            <div>
              <p>
                <div>
                  <h7 style={{ color: "#3598DB" }}>Note: this can take 20+ seconds</h7>
                </div>
                <div className="flex-container-left">
                  <span className="attribute">Number:</span>

                  <Form.Control
                    as="select"
                    custom
                    onChange={this.numberChanged}
                    defaultValue={this.state.selectedNumber}
                  >
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 20].map((quest, idx) => (
                      <option value={quest.label}>{quest.toString()}</option>
                    ))}
                  </Form.Control>
                </div>
              </p>
              <p>
                <div className="flex-container-left">
                  <span className="attribute">Proximity:</span>
                  <Form.Control as="select" custom onChange={this.proximityChanged} defaultValue={this.state.proximity}>
                    {[
                      "in",
                      "near",
                      "in or near",
                      "outside of",
                      "within 1 hour from",
                      "within 2 hours from",
                      "within 3 hours from",
                    ].map((proximity, idx) => (
                      <option value={proximity}>{proximity}</option>
                    ))}
                  </Form.Control>
                </div>
              </p>
              <p>
                <div className="flex-container-left">
                  <span className="attribute">City, place or region: </span>
                  {/* <p>Enter location(s)</p> */}
                  <input
                    type="text"
                    value={this.state.selectedCity}
                    placeholder="Enter location(s)"
                    onChange={(e) => this.handledDestinationChanged(e)}
                  />
                  {/* <span>{`${destination}`}</span>
                <span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                <MyToolTip loc="middle" msg="Select the city you want to ask about">
                  <Button className="mt-3" onClick={() => this.modifyCity()}>
                    Change Location
                  </Button>
                </MyToolTip> */}
                </div>
              </p>
              <p>
                {/* <div className="flex-container-left">
                <span className="attribute">Location: </span>
                <span>{`${this.state.selectedLatLong}`}</span>
                <span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                <MyToolTip loc="middle" msg="Select the city you want to ask about">
                  <Button className="mt-3" onClick={() => this.modifyLatLong()}>
                    Change Place
                  </Button>
                </MyToolTip>
              </div> */}
                <div className="flex-container-left">
                  {/* <span className="attribute">Country: </span>
                  <input
                    type="text"
                    value={country}
                    placeholder="Enter country"
                    onChange={(e) => this.handledCountryChanged(e)}
                  /> */}
                  <Button className="mt-3" onClick={() => this.selectCountry()}>
                    Country
                  </Button>
                  <span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                  <span className="attribute">{this.state.selectedCountry}</span>
                </div>
              </p>
              <p className="attribute">Pick a question to ask our travel wizard</p>

              <div>
                <Form.Control as="select" custom onChange={this.questionChanged} value={this.state.selectedQuestion}>
                  {_questions.map((quest, idx) => (
                    <option value={quest.label}>{quest.label}</option>
                  ))}
                </Form.Control>
              </div>
              {this.state.activityIndicator ? (
                <div className="loading">
                  <Spinner animation="border" variant="primary" />
                </div>
              ) : null}
              <div>
                <p className="attribute">Response</p>
                <Form.Group>
                  <Form.Control
                    as="textarea"
                    // type="text"
                    value={this.state.answer}
                    //onChange={(e) => this.handleChangePaymentInfo(e.target.value)}
                    style={{ height: "400px" }}
                  />
                </Form.Group>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default AiDialog;
