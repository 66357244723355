import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import "./Modal.css";
import { useDispatch } from "react-redux";
import { slice1Actions } from "../../store/reducer";
import { useSelector } from "react-redux";
import kmlParser from "js-kml-parser";
import { kml, gpx, tcx } from "@tmcw/togeojson";
import Place from "../../Models/Place";
import Group from "../../Models/Group";
import Appointment from "../../Models/Event";
import Places from "../../Models/Places";
import { DateTime } from "luxon";
//try kml-to-json for creating kml from json

const KMLInput = (props) => {
  let ScheduleNames = useSelector((state) => state.ScheduleNames);
  const dispatch = useDispatch();
  const [Inputs, SetInputs] = useState([]);
  let user_info = useSelector((state) => state.user_info);

  let AllPlaces = useSelector((state) => state.places);

  useEffect(() => {
    SetInputs(props.PromptModalInputs);
  }, [props.PromptModalInputs]);

  const handleButtonClick = (button) => {
    if (button.Method != null) button.Method(result);
    props.onHide();
  };

  const handleInputChanged = (e, input) => {
    const index = Inputs.findIndex((x) => (x.name = input.name));
    if (index === -1) return;
    Inputs[index].value = e.target.value;
    SetInputs(Inputs);
    //console.log(Inputs);
  };

  const onKeyPress = (event) => {
    if (event.charCode === 13) {
      props.PromptModalButtons.forEach((button) => {
        if (button.Title === "OK") handleButtonClick(button);
      });
    }
  };

  //console.log("Inputs", Inputs);
  let file = null;
  const upload = async (e) => {
    // Convert the FileList into an array and iterate
    let files = Array.from(e.target.files).map((file) => {
      // Define a new file reader
      let reader = new FileReader();

      // Create a new promise
      return new Promise((resolve) => {
        // Resolve the promise after reading file
        reader.onload = () => resolve(reader.result);

        // Reade the file as a text
        //reader.readAsText(file);
        parseDocument(file);
      });
    });

    // At this point you'll have an array of results
    let res = await Promise.all(files);
  };

  // const fileChanged = async (e) => {
  //   // file = e.target.files[0];
  //   // parseDocument(file);
  //   Array.from(e.target.files).forEach((file) => {
  //     parseDocument(file);

  //     // // Define a new file reader
  //     // let reader = new FileReader();

  //     // // Function to execute after loading the file
  //     // reader.onload = () => console.log(reader.result);

  //     // // Read the file as a text
  //     // reader.readAsText(file);
  //   });
  // };

  let result = {};

  const parseDocument = async (file) => {
    let fileReader = new FileReader();
    fileReader.onload = async (e) => {
      if (e.target.result.includes(`"guide":`)) {
        parseJSON(e.target.result);
        props.onHide();
        return;
      }
      //const geoJson = kmlParser.toGeoJson(e.target.result);
      let geoJson = {};
      if (file.name.toUpperCase().endsWith(".KML"))
        geoJson = kml(new DOMParser().parseFromString(e.target.result, "text/xml"));
      if (file.name.toUpperCase().endsWith(".GPX")) {
        //const geoJson2 = kmlParser.toGeoJson(e.target.result);
        geoJson = gpx(new DOMParser().parseFromString(e.target.result, "text/xml"));
      }
      if (file.name.toUpperCase().endsWith(".TCX"))
        geoJson = tcx(new DOMParser().parseFromString(e.target.result, "text/xml"));

      //console.log("geoJson", geoJson);
      if (typeof geoJson.features !== "undefined") parseGeoJSON(geoJson);
      props.onHide();
      //result = await extractGoogleCoords(e.target.result);

      //Do something with result object here
      //console.log(result);
    };
    if (file.name.toUpperCase().endsWith(".KMZ")) {
      var JSZip = require("jszip");
      JSZip.loadAsync(file).then(function (zip) {
        Object.keys(zip.files).forEach(function (filename) {
          zip.files[filename].async("string").then(function (fileData) {
            //console.log(fileData); // These are your file contents
            const geoJson = kmlParser.toGeoJson(fileData);
            console.log("geoJson", geoJson);
            parseGeoJSON(geoJson);
            props.onHide();
          });
        });
      });
      //}
      // JSZip.loadAsync(file)
      //   .then(function (content) {
      //     // if you return a promise in a "then", you will chain the two promises
      //     return content.files["css/style.css"].async("text");
      //   })
      //   .then(function (txt) {
      //     alert(txt);
      //   });
    } else fileReader.readAsText(file);
  };

  const sample_json = {
    guide: "Palermo4",
    name: "4 day Palermo itinerary",
    description: "4 day Palermo itinerary",
    schedule: "Palermo 4 days",
    image: "",
    cities: "Palermo",
    countries: "Italy",
    placemarks: [
      {
        name: "Cappella Palatina",
        categories: "Architecture,Castles,Religious sites",
        duration: 105,
        description:
          "The Palatine Chapel is the royal chapel of the Norman Palace in Palermo, Sicily. This building is a mixture of Byzantine, Norman and Fatimid … See more",
        url: "http://en.wikipedia.org/wiki/Cappella_Palatina",
        website: "https://www.cappellapalatinapalermo.it/",
        coordinates: "13.353610,38.110830",
        address: "Piazza del Parlamento, Palermo, Sicily 90134",
        country: "Italy",
        state: "Sicily",
        city: "Palermo",
        day: 1,
        image: "",
        image_t: "",
      },
    ],
  };

  let jsonfile = "";
  let JsonURL = "";
  const handleChangeJsonURL = (event) => {
    jsonfile = event.target.value;
  };
  const handleUploadTextFile = (guide_json) => {
    console.log(jsonfile);
    parseJSON(jsonfile);
    JsonURL = "";
    props.onHide();
  };
  const parseJSON = (guide_json) => {
    let json_guide = JSON.parse(guide_json);
    let guide_attributes = JSON.parse(JSON.stringify(window.guide_obj.attributes));
    let dateGuide = DateTime.now({ zone: "utc" });
    let cur_day = 0;
    let dayDate = "";
    let last_place = null;
    let day_offset = 0;
    const sheduleMain = window.guide_obj.schedule.getScheduleToDisplay("Main");
    if (sheduleMain.length === 0) {
      day_offset = Math.floor(Math.random() * 2500);
      day_offset = day_offset - 1250;
      dateGuide = dateGuide.plus({ days: day_offset });
    } else {
      console.log(sheduleMain[0]);
      dateGuide = DateTime.fromISO(sheduleMain[0].start);
    }
    dateGuide = dateGuide.set({ hour: 11 });
    dateGuide = dateGuide.set({ minutes: 0 });

    let new_guide = false;
    if (guide_attributes.guide_name === "New Guide") {
      new_guide = true;
      guide_attributes.guide_name = json_guide.name;
      guide_attributes.description = json_guide.description;
      guide_attributes.image_m = json_guide.image;
      guide_attributes.image = json_guide.image.replace(".jpg", "_t.jpg");

      //guide_attributes.language = json_guide.Language;
      //guide_attributes.rating = parseInt(json_guide.placemarks[i].Rating);
      if (!guide_attributes.countries.includes(json_guide.countries))
        guide_attributes.countries.push(json_guide.countries);
      if (!guide_attributes.cities.includes(json_guide.cities)) guide_attributes.cities.push(json_guide.cities);
    }
    for (let i = 0; i < json_guide.placemarks.length; i++) {
      if (typeof json_guide.placemarks[i].coordinates === "undefined") continue;
      if (json_guide.placemarks[i].coordinates.length !== 2) continue;
      let place = null;
      let mode = "modify";
      let existing_place = window.guide_obj.places.getPlacesFromName(json_guide.placemarks[i].name);
      if (existing_place === null) {
        place = new Place(window.guide_obj.places);
        mode = "new";
      } else place = existing_place;
      place.Name = json_guide.placemarks[i].name;
      place.Lat = json_guide.placemarks[i].coordinates[1];
      place.Longi = json_guide.placemarks[i].coordinates[0];
      place.Address = json_guide.placemarks[i].address;
      place.City = json_guide.placemarks[i].city !== "" ? json_guide.placemarks[i].city : json_guide.cities;
      place.State = json_guide.placemarks[i].state;
      place.Country = json_guide.placemarks[i].country !== "" ? json_guide.placemarks[i].country : json_guide.countries;
      place.Hlink = json_guide.placemarks[i].url;
      place.Photo = json_guide.placemarks[i].image_t;
      place.Photo_m = json_guide.placemarks[i].image;
      let info = json_guide.placemarks[i].description.replace(" … See more", "").replace("…", "");
      if (info.length > 200) info = info.substring(0, 149);
      place.Info = `<p style="text-align:center;"><img src="${place.Photo_m}" alt="Image" style="width:100%; max-width:700px; height:auto; border-radius:10%" ></img></p>${info}<a href="urllink:${place.Hlink}"> ... See More.</a><br>`;

      // place.Info = `<img src="${place.Photo}" alt="Image" style="float: left;
      // margin: 10px; width:350px; border-radius:10%" ></img><p style="margin: 10px;">${info}<a href="urllink:${place.Hlink}">... See More.</a></p><br style="clear:both;">`;
      place.PType = "Icon";
      for (let j = 0; j < json_guide.placemarks[i].categories.length; j++) {
        const this_category = json_guide.placemarks[i].categories[j];
        if (this_category === "") continue;
        let cat = window.guide_obj.getCategoryByName(this_category, "guide");
        if (cat !== null) {
          if (!place.Category.includes(cat.id)) place.Category.push(cat.id);
        } else {
          cat = window.guide_obj.getCategoryByName(this_category, "initial");
          if (cat !== null) {
            let cat2 = window.guide_obj.getCategoryByName(cat.name, "guide");
            if (cat2 === null) {
              window.guide_obj.addCategory(this_category);
              let catg = window.guide_obj.getCategoryByName(this_category, "guide");
              if (catg !== null) {
                window.guide_obj.modifyCategory(catg, cat);
                if (!place.Category.includes(catg.id)) place.Category.push(catg.id);
              }
            } else {
              if (!place.Category.includes(cat2.id)) place.Category.push(cat2.id);
            }
          } else console.log(`Category not found: ${this_category}`);
        }
      }
      // We don't need a group, each attraction is part of one or more schedules
      // let group = window.guide_obj.getGroupByName(json_guide.guide, "guide");
      // if (group !== null) {
      //   if (!place.Group.includes(group.id)) place.Group.push(group.id);
      // } else {
      //   // if (!place.Group.includes(group.id)) {
      //   window.guide_obj.addGroup(json_guide.guide);
      //   let group = window.guide_obj.getGroupByName(json_guide.guide, "guide");
      //   if (group !== null) {
      //     group.name = json_guide.guide;
      //     group.poi_img = "152";
      //     place.Group.push(group.id);
      //     window.guide_obj.modifyGroup(group, group);
      //   }
      //   // }
      // }
      if (mode === "new") window.guide_obj.places.addPlace(place);
      else window.guide_obj.places.modifyPlace(place, place.ID);
      // Dont add place in schedule
      if (json_guide.placemarks[i].day === 0) continue;
      mode = "modify";
      let event = null;
      let existing_event = window.guide_obj.schedule.findEventByName(json_guide.placemarks[i].name, sample_json.guide);
      if (existing_event === null) {
        event = new Appointment(window.guide_obj.schedule);
        mode = "new";
      } else event = existing_event;
      // let total_minutes = 0
      // for(let d = 0; d < json_guide.placemarks.length && d <= i; d++) total_minutes+=
      if (cur_day !== json_guide.placemarks[i].day) {
        last_place = null;
        dayDate = dateGuide.plus({ days: json_guide.placemarks[i].day });
        cur_day = json_guide.placemarks[i].day;
      }
      event.Subject = json_guide.placemarks[i].name;
      event.Body = ""; //place.Info;
      event.Itinerary = json_guide.schedule;
      let travel_time = 0;
      let rounding_to = user_info.event_time_rounding;
      if (last_place !== null) {
        const p1 = { latitude: last_place.Lat, longitude: last_place.Longi };
        const p2 = { latitude: place.Lat, longitude: place.Longi };
        let distance = Places.calculateDistance(p1, p2) * 1.2;
        travel_time = distance * 10;
        if (travel_time > 45) travel_time = distance * 2;
        if (rounding_to === 0) travel_time = 0;
        else travel_time = Math.max(1, Math.round(travel_time / rounding_to)) * rounding_to;
      }
      last_place = place;
      dayDate = dayDate.plus({ minutes: travel_time });
      event.Start = dayDate.toISO(); //({ zone: "utc" });
      dayDate = dayDate.plus({ minutes: json_guide.placemarks[i].duration });
      event.End = dayDate.toISO(); //({ zone: "utc" });
      if (!event.Places.includes(place.ID)) event.Places = place.ID;
      if (mode === "new") window.guide_obj.schedule.addEvent(event);
      else window.guide_obj.schedule.modifyEvent(event);
    }
    //if (sheduleMain.length === 0) window.guide_obj.schedule.copySchedule(json_guide.schedule, "Main");
    //let GuideSettings = useSelector((state) => state.GuideSettings);
    let GuideSettings = {
      mode: "edit", // "edit" "view" "custom_view"
      zoom: 5,
      hide_schedule: false,
      hide_hidden: false,
      wrap_text: false,
      places_list: {
        menu: false,
        info: true,
        url: false,
        attributes: true,
        center: false,
        filter: false,
        path_link: false,
      },
      places_map: {
        menu: true,
        info: false,
        url: false,
        attributes: false,
        center: false,
        filter: false,
        path_link: false,
      },
      places_map_hover: {
        menu: false,
        place: true,
        info: true,
        event: true,
        path_link: false,
      },
      event: {
        menu: true,
        details: false,
        center: false,
        filter: false,
      },
      schedule: {
        menu: true,
        itinerary: false,
        center: false,
        filter: false,
      },
      day: {
        menu: true,
        center: false,
        filter: false,
      },
      itinerary: {
        directions: true,
        info: true,
        photo: false,
        place_menu: true,
      },
    };
    //GuideSettings.mode = "custom_view";
    dispatch(slice1Actions.setGuideSettings({ value: GuideSettings }));
    let scheds = window.guide_obj.schedule.geScheduleNames(); //JSON.parse(JSON.stringify(ScheduleNames));
    //scheds.push({ label: json_guide.schedule });
    scheds = scheds.sort((a, b) => (a.label.toUpperCase() > b.label.toUpperCase() ? 1 : -1));
    let days = [];
    let labels = [];
    for (let s = 0; s < scheds.length; s++) {
      const scheds_parts = scheds[s].label.split(" ");
      const length = scheds_parts.length;
      if (length >= 3) {
        labels.push({ sched: scheds[s].label, label: `${scheds_parts[length - 2]} ${scheds_parts[length - 1]}` });
        days.push(parseInt(scheds_parts[length - 2]));
      }
    }
    let continuos = true;
    for (let n = 0; n < days.length - 1; n++) {
      if (days[n + 1] !== days[n] + 1) continuos = false;
    }
    let title = `${json_guide.cities} `;

    if (continuos) {
      if (days.length > 1) title = `${json_guide.cities} ${days[0]}-${days[days.length - 1]}`;
      else title = `${json_guide.cities} ${days[0]}`;
    } else
      for (let n = 0; n < days.length; n++) {
        title += `${days[n]}${n < days.length - 1 ? "," : ""}`;
      }
    title += days.length > 2 ? " days" : " day";
    const desc = title + " itinerary";

    let center = { latitude: 0, longitude: 0 };
    if (typeof json_guide.coordinates !== "undefined" && typeof json_guide.coordinates !== "") {
      //MapCenter = { lat: json_guide.coordinates[1], lng: json_guide.coordinates[0] };
      center = { latitude: json_guide.coordinates[1], longitude: json_guide.coordinates[0] };
      guide_attributes.base_location = { latitude: json_guide.coordinates[1], longitude: json_guide.coordinates[0] };
    } else {
      const weighted_center = window.guide_obj.places.getWeightedCenter(null);
      center = { latitude: weighted_center.lat, longitude: weighted_center.lon };
      guide_attributes.base_location = { latitude: weighted_center.lat, longitude: weighted_center.lon };
    }

    window.guide_obj.schedule.deleteAllEvents("Main");
    if (scheds.length > 1) window.guide_obj.schedule.copySchedule(scheds[scheds.length - 2].label, "Main");
    guide_attributes.guide_name = title;
    guide_attributes.description = desc;
    guide_attributes.access = ["Featured", "All_C"];
    guide_attributes.type = "Featured";
    window.guide_obj.attributes.modifyGuideAttributes(guide_attributes);

    let home_page = `<p style="text-align:center;"><img src="${json_guide.image}" alt="Image" style="width:100%; max-width:700px; height:auto; border-radius:10%" ></img></p>`;
    home_page += `<p><h3 style="text-align:center;">${title}</h3></p></br>`;

    if (typeof json_guide.info !== "undefined")
      home_page += `<a href="urllink:${json_guide.info}">General info for ${json_guide.cities}</a><br><br>`;

    //if (labels.length > 0) home_page += `<span><b>Show Schedule for:</b></span><br>`;
    // for (let i = 0; i < labels.length; i++) {
    //   if (labels[i].label === "Main") continue;
    //   home_page += `<a href="schedulelink:${labels[i].sched}"><button style="border: none; padding: 20px; margin: 10px; color: white; border-radius: 10%; font-size: 100%; background-color: #FEC02D;">${labels[i].label}</button></a>`;
    //   //home_page += `<a href="schedulelink:${scheds[i].label}">Show schedule for "${scheds[i].label}"</a><br>`;
    // }
    // home_page += `<br>`;

    if (labels.length > 0) home_page += `<span><b>Show Itinerary for:</b></span><br>`;
    for (let i = 0; i < labels.length; i++) {
      if (labels[i].label === "Main") continue;
      home_page += `<a href="itinerarylink:${labels[i].sched}"><button style="border: none; padding: 20px; margin: 10px; color: white; border-radius: 10%; font-size: 100%; background-color: #36D1E5;">${labels[i].label}</button></a>`;
      // home_page += `<a href="itinerarylink:${scheds[i].label}">Show itinerary for "${scheds[i].label}"</a><br>`;
    }
    home_page += `<br>`;
    home_page += `<span><b>Internet Resources:</b></span><br>`;
    if (typeof json_guide.weather !== "undefined" && last_place !== null)
      //home_page += `<a href="weatherlink:${last_place.ID}">Show weather forecast for "${json_guide.cities}"</a><br><br>`;
      home_page += `<a href="weatherlink:${last_place.ID}"><button style="border: none; padding: 20px; margin: 10px; color: white; border-radius: 10%; font-size: 100%; background-color: #64DB65;">Weather</button></a>`;

    if (typeof json_guide.climate !== "undefined")
      //home_page += `<a href="urllink:${json_guide.climate}">Show yearly climate for ${json_guide.cities}</a><br>`;
      home_page += `<a href="urllink:${json_guide.climate}"><button style="border: none; padding: 20px; margin: 10px; color: white; border-radius: 10%; font-size: 100%; background-color: #64DB65;">Climate</button></a>`;

    const videos = `https://www.youtube.com/results?search_query=${json_guide.cities}+${json_guide.countries}+attractions`;
    home_page += `<a href="urllink:${videos}"><button style="border: none; padding: 20px; margin: 10px; color: white; border-radius: 10%; font-size: 100%; background-color: #64DB65;">Videos</button></a>`;
    const articles = `http://www.google.com/search?q=${json_guide.cities}+${json_guide.countries}+Travel+Itinerary`;
    home_page += `<a href="urllink:${articles}"><button style="border: none; padding: 20px; margin: 10px; color: white; border-radius: 10%; font-size: 100%; background-color: #64DB65;">Articles</button></a>`;

    if (last_place !== null) {
      //home_page += `<a href="urllink:https://www.yelp.com/search?find_loc=${center.latitude},${center.longitude}"><button style="border: none; padding: 20px; margin: 10px; color: white; border-radius: 10%; font-size: 100%; background-color: #64DB65;">Yelp</button></a>`;
      home_page += `<a href="urllink:https://www.happycow.net/searchmap?s=3&location=&filters=vegan-vegetarian-vegfriendly&metric=mi&limit=81&order=default&lat=${center.latitude}&lng=${center.longitude}&zoom=15&page=1"><button style="border: none; padding: 20px; margin: 10px; color: white; border-radius: 10%; font-size: 100%; background-color: #64DB65;">Vegan</button></a>`;
      home_page += `<a href="urllink:http://maps.google.com/maps?q=${center.latitude},${center.longitude}"><button style="border: none; padding: 20px; margin: 10px; color: white; border-radius: 10%; font-size: 100%; background-color: #64DB65;">Google Maps</button></a>`;
    }

    home_page += `<br><br><p><span style="font-size: 60%;">Credits: Itineraries from <a href="urllink:https://www.bing.com/maps/">Bing Maps</a>. Attraction info and images from <a href="urllink:https://www.wikipedia.org/">Wikipedia</a>. Under the CC BY-SA licensing agreement</span></p>`;

    window.guide_obj.document = home_page;
    // if (window.guide_obj.document === "") {
    //   const itinerary = window.guide_obj.createHTMLItinerary(json_guide.schedule, GuideSettings.itinerary);
    //   window.guide_obj.document = itinerary;
    // }
    dispatch(slice1Actions.setGuide({ guide: null, fitToBounds: true, update_guide_attributes: true }));
    dispatch(slice1Actions.resetHomeScreen({ value: null }));
    setTimeout(updateMustSee, 200);
  };
  const updateMustSee = () => {
    const countTimesInSchedule = window.guide_obj.schedule.countTimesInSchedule();
    let scheds = window.guide_obj.schedule.geScheduleNames();
    console.log("countTimesInSchedule", countTimesInSchedule);
    for (let i = 0; i < countTimesInSchedule.length; i++) {
      const place = window.guide_obj.places.getPlace(countTimesInSchedule[i].placeID);
      if (place === null) continue;
      place.MustSee = countTimesInSchedule[i].count >= (scheds.length - 1) / 2;
      window.guide_obj.places.modifyPlace(place, place.ID);
    }

    dispatch(slice1Actions.setGuide({ guide: null, fitToBounds: true, update_guide_attributes: true }));
  };
  const parseGeoJSON = (geoJson) => {
    if (typeof geoJson.features === "undefined") return;
    const base62 = require("base62/lib/ascii");
    const geohash = require("ngeohash");
    let schedule = "";
    let scheds = window.guide_obj.schedule.geScheduleNames();
    for (let s = 1; s < 99999; s++) {
      schedule = `Itinerary${s}`;
      let found = false;
      for (let x = 1; x < scheds.length; x++) {
        if (schedule === scheds[x].label) found = true;
      }
      if (found === false) break;
    }

    let dateGuide = DateTime.now({ zone: "utc" });
    dateGuide = dateGuide.set({ hour: 11 });
    dateGuide = dateGuide.set({ minutes: 0 });
    let dayDate = dateGuide;
    let last_place = null;
    let rounding_to = user_info.event_time_rounding;
    if (typeof rounding_to === "undefined") rounding_to = 30;
    // try {
    for (let i = 0; i < geoJson.features.length; i++) {
      let placeMark = geoJson.features[i];
      let name = placeMark.properties.name;
      let info = placeMark.properties.description;
      if (typeof name === "undefined") name = "";
      if (typeof info === "undefined") info = "";
      let imgURL = "";
      if (placeMark.properties.title != null && typeof placeMark.properties.title !== "undefined")
        name = placeMark.properties.title;
      if (placeMark.properties.time != null && typeof placeMark.properties.time !== "undefined")
        info += "time: " + placeMark.properties.time;
      if (placeMark.properties.ascent != null && typeof placeMark.properties.ascent !== "undefined")
        info += " ascent: " + placeMark.properties.ascent + "m";
      if (placeMark.properties.descent != null && typeof placeMark.properties.descent !== "undefined")
        info += " descent: " + placeMark.properties.descent + "m";
      if (placeMark.properties.imgURL != null && typeof placeMark.properties.imgURL !== "undefined")
        imgURL = placeMark.properties.imgURL;

      if (placeMark.geometry.type === "Point") {
        const point = { Lat: placeMark.geometry.coordinates[1], Longi: placeMark.geometry.coordinates[0] };
        const new_place = props.add_place_to_map("Icon", point, null, { name: name, info: info, imgURL: imgURL });

        if (new_place === null) return;
        dayDate = addEvent(new_place, schedule, dayDate, last_place, rounding_to);
        last_place = new_place;
      } else if (placeMark.geometry.type === "LineString") {
        if (true === false) {
          let points = "";
          let time = null;
          for (let j = 0; j < placeMark.geometry.coordinates.length; j++) {
            if (points !== "") points += ":";
            points += placeMark.geometry.coordinates[j][0] + "," + placeMark.geometry.coordinates[j][1];
          }
          const _mapCoordinate = {
            Lat: placeMark.property.coordinates[0][1],
            Longi: placeMark.geometry.coordinates[0][0],
          };
          if (name === "") name = "New Path";
          props.add_place_to_map("PolyLine", _mapCoordinate, points, { name: name, info: info, imgURL: imgURL });
        } else {
          let points = "";
          let times = [];
          if (typeof placeMark.properties.coordinateProperties !== "undefined")
            if (typeof placeMark.properties.coordinateProperties.times !== "undefined")
              times = placeMark.properties.coordinateProperties.times;
          let time = null;

          let elapsedSeconds = 0;
          let firstTimeStamp = 0;
          for (let j = 0; j < placeMark.geometry.coordinates.length; j++) {
            if (typeof times[j] !== "undefined") {
              time = times[j];
              if (firstTimeStamp === 0) firstTimeStamp = new Date(time).getTime();
              else elapsedSeconds = (new Date(time).getTime() - firstTimeStamp) / 1000;

              //firstTimeStamp = time;
              //console.log("new Date(time)", new Date(time), new Date(time).getTime());
              //if (points !== "") points += ":";
              //points += placeMark.geometry.coordinates[j][0] + "," + placeMark.geometry.coordinates[j][1];
              console.log(placeMark.geometry.coordinates[j]);
              points +=
                ";" +
                geohash.encode(
                  parseFloat(placeMark.geometry.coordinates[j][1]).toFixed(5),
                  parseFloat(placeMark.geometry.coordinates[j][0]).toFixed(5)
                ) +
                "," +
                base62.encode(Math.round(elapsedSeconds));
              // let alt = Math.round(placeMark.geometry.coordinates[j][2] * 10);
              // points += "," + base62.encode(alt);
            } else {
              if (points !== "") points += ":";
              points += placeMark.geometry.coordinates[j][0] + "," + placeMark.geometry.coordinates[j][1];
            }
          }
          const _mapCoordinate = {
            Lat: placeMark.geometry.coordinates[0][1],
            Longi: placeMark.geometry.coordinates[0][0],
          };
          if (name === "") name = "New Path";
          props.add_place_to_map("PolyLine", _mapCoordinate, points, { name: name, info: info, imgURL: imgURL });
        }
      } else if (placeMark.geometry.type === "Polygon") {
        let points = "";

        for (let j = 0; j < placeMark.geometry.coordinates[0].length; j++) {
          if (points !== "") points += ":";
          points += placeMark.geometry.coordinates[0][j][0] + "," + placeMark.geometry.coordinates[0][j][1];
        }
        const _mapCoordinate = {
          Lat: placeMark.geometry.coordinates[0][0][1],
          Longi: placeMark.geometry.coordinates[0][0][0],
        };
        if (name === "") name = "New Area";
        props.add_place_to_map("Polygon", _mapCoordinate, points, { name: name, info: info, imgURL: imgURL });
      }
    }
    dispatch(slice1Actions.setScheduleName({ value: schedule }));
    dispatch(slice1Actions.setGuide({ guide: null, fitToBounds: true }));
    // } catch (e) {
    //   alert(e.message);
    // }
  };

  const addEvent = (place, Itinerary, dayDate, last_place, rounding_to) => {
    let event = new Appointment(window.guide_obj.schedule);
    event.Subject = place.Name;
    event.Body = "";
    event.Itinerary = Itinerary;
    event.Places = place.ID;
    let travel_time = 0;
    if (last_place !== null) {
      const p1 = { latitude: last_place.Lat, longitude: last_place.Longi };
      const p2 = { latitude: place.Lat, longitude: place.Longi };
      let distance = Places.calculateDistance(p1, p2) * 1.2;
      travel_time = distance * 10;
      if (travel_time > 45) travel_time = distance * 2; //Math.min(distance * 2, 60);
      if (rounding_to === 0) travel_time = 0;
      else travel_time = Math.max(1, Math.round(travel_time / rounding_to)) * rounding_to;
    }
    //let total_time =
    dayDate = dayDate.plus({ minutes: travel_time });
    event.Start = dayDate.toISO();
    dayDate = dayDate.plus({ minutes: place.Duration });
    event.End = dayDate.toISO();
    window.guide_obj.schedule.addEvent(event);
    return dayDate;
  };

  const extractGoogleCoords = async (plainText) => {
    let parser = new DOMParser();
    let xmlDoc = parser.parseFromString(plainText, "text/xml");
    let googlePolygons = [];
    let googleMarkers = [];
    let googlePolylines = [];

    if (xmlDoc.documentElement.nodeName == "kml") {
      for (const item of xmlDoc.getElementsByTagName("Placemark")) {
        let placeMarkName = item.getElementsByTagName("name")[0].childNodes[0].nodeValue.trim();
        let LineString = item.getElementsByTagName("LineString");
        let coordinates = item.getElementsByTagName("coordinates");
        let coordinates_inner = coordinates[0].innerHTML;
        let polygons = item.getElementsByTagName("Polygon");
        let markers = item.getElementsByTagName("Point");
        let polylines = item.getElementsByTagName("Polyline");

        /** POLYGONS PARSE **/
        for (const polygon of polygons) {
          let coords = polygon.getElementsByTagName("coordinates")[0].childNodes[0].nodeValue.trim();
          let points = coords.split(" ");

          let googlePolygonsPaths = [];
          for (const point of points) {
            let coord = point.split(",");
            googlePolygonsPaths.push({ name: placeMarkName, lat: +coord[1], lng: +coord[0] });
          }
          googlePolygons.push(googlePolygonsPaths);
        }

        /** POLYLINES PARSE **/
        for (const polyline of polylines) {
          let coords = polyline.getElementsByTagName("coordinates")[0].childNodes[0].nodeValue.trim();
          let points = coords.split(" ");

          let googlePolylinesPaths = [];
          for (const point of points) {
            let coord = point.split(",");
            googlePolylinesPaths.push({ name: placeMarkName, lat: +coord[1], lng: +coord[0] });
          }
          googlePolylines.push(googlePolylinesPaths);
        }

        /** MARKER PARSE **/
        for (const marker of markers) {
          var coords = marker.getElementsByTagName("coordinates")[0].childNodes[0].nodeValue.trim();
          let coord = coords.split(",");
          googleMarkers.push({ name: placeMarkName, lat: +coord[1], lng: +coord[0] });
        }
      }
    } else {
      throw "error while parsing";
    }

    return { markers: googleMarkers, polygons: googlePolygons, polylines: googlePolylines };
    console.log({ markers: googleMarkers, polygons: googlePolygons, polylines: googlePolylines });
  };
  // const writeFile = () => {
  //   const fs = require("fs");
  //   const content = "Some content!";
  //   fs.writeFile("../../Guides/Rome.txt", content, (err) => {
  //     if (err) {
  //       console.error(err);
  //       return;
  //     }
  //     //file written successfully
  //   });
  // };
  return (
    <div className="modal">
      <Modal
        {...props}
        size="sm"
        dialogClassName="myModalClass"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
        // style={{ padding: "50px" }}
        onHide={() => props.onHide()}
      >
        <Modal.Body>
          <div className="sort">
            <h4 style={{ color: "#3598DB" }}>{props.PromptModalTitle}</h4>
            {/* <h6 style={{ color: "black" }}>
              {props.message}
            </h6> */}
            <div>
              <p>{props.PromptModalMessage}</p>
              <label for="file-upload" className="custom-file-upload">
                <i title="Select KML File" className="fa fa-cloud-upload fa-5x"></i>
              </label>
              <input
                ref={(input) => input && input.focus()}
                className="form-item"
                placeholder="Choose file"
                style={{ width: "250px" }}
                type="file"
                accept=".kml,.kmz,.gpx,.tcx"
                onChange={upload}
                id="file-upload"
                multiple
              ></input>
            </div>
            {props.PromptModalButtons.map((button, i) => (
              <Button
                key={i}
                variant="primary"
                style={{ width: "200px" }}
                className="my-2"
                onClick={() => handleButtonClick(button)}
              >
                {button.Title}
              </Button>
            ))}
            {/* {user_info.uid === "E2Fqqu9FWDgTdpOzdKQOKEdo6wA3" ? (
              <div>
                <textarea
                  id={"text-field"}
                  rows={8}
                  onChange={handleChangeJsonURL}
                  placeholder="Enter KML"
                  value={JsonURL}
                  style={{ width: "100%", height: "100%", fontSize: "16px" }}
                />
                <Button
                  key={"upload"}
                  id={"upload-button"}
                  variant="primary"
                  style={{ width: "200px" }}
                  onClick={() => handleUploadTextFile()}
                >
                  Upload
                </Button>
              </div>
            ) : null} */}
            {/* <p>Change Start date</p>
            <p>Move one day</p>
            <p>Add one day</p>
            <p>Delete Schedule</p>
            <p>Rename Schedule</p>
            <p>Edit Schedule</p> */}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default KMLInput;
