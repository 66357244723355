import { connect } from "react-redux";
import React, { Component, useState, useEffect } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import AlertModal from "./AlertModal";
import "./Modal.css";
import PromptModal from "./PromptModal";
import MultiSelectModal from "./MultiSelectModal";
import IconsDialog from "./IconsDialog";
import Place from "../../Models/Place";
import DataBase from "../../dataBase/liquidGuides";
import Review from "../../Models/Review";
import Rating from "react-rating";
import Common, { Settings, SortByEnum, COLORS } from "../../Common/Common";

class ReviewsDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      guide_info_id: props.id,
      reviews: null,
      //maxChars: 60,

      AlertModalShow: false,
      AlertModalTitle: "",
      AlertModalMessage: "",
      AlertModalButtons: [],
    };
  }

  componentDidMount() {
    const guide_info_id = this.props.id;
    //this.setState({ activityIndicator: true, guide_info_id: guide_info_id });
    this.setState({ guide_info_id: guide_info_id });
    DataBase.getAllGuideReviews(this.props.id, this.getAllGuideReviewsCallback);
  }

  getAllGuideReviewsCallback = (res) => {
    //console.log("getAllGuideReviewsCallback", res.pass);
    if (res.pass == false) return;
    // res.reviews.forEach((review) => {
    //console.log("review", review.data);
    // });
    res.reviews.forEach((review) => {
      review.data.maxChars = 60;
    });
    //this.setState({ activityIndicator: false, reviews: res.reviews });
    this.setState({ reviews: res.reviews });
  };

  viewMore = (comment) => {
    this.props.navigation.navigate("HTMLViewer", {
      html: Common.createHTMLFromText(comment),
      title: "Review Comment",
    });
  };

  toggleCommentSize = (item) => {
    //console.log(item);
    let reviews = this.state.reviews;
    const index = reviews.findIndex((x) => x.data.user_id == item.data.user_id);
    if (index == -1) return;
    reviews[index].data.maxChars = reviews[index].data.maxChars > 60 ? 60 : reviews[index].data.comment.length - 1;
    this.setState({ reviews: reviews });
  };

  render() {
    if (this.state.reviews == null) return null;
    //console.log("this.state.reviews", this.state.reviews);
    return (
      <div className="modal">
        <Modal {...this.props} size="md" aria-labelledby="contained-modal-title-vcenter" centered scrollable={true}>
          <Modal.Header>
            <div style={{ width: "100%" }}>
              <div className="sort">
                <div className="dialog-heading">
                  <div className="dialog-heading">
                    <div>
                      <h4 style={{ color: "#3598DB" }}>Reviews</h4>
                    </div>

                    <span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>

                    <div>
                      <i className={`fas fa-times`} onClick={() => this.props.onHide()}></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div>
              {this.state.reviews.map((item, index) => (
                <div className="list-item-card" key={index}>
                  <div className="items-left">
                    <Rating
                      // emptySymbol="fa fa-star-o fa-2x"
                      // fullSymbol="fa fa-star fa-2x"
                      fractions={2}
                      initialRating={item.data.rating}
                    />
                    <span className="attribute">{` Rating: `}</span>
                    <span>{`${item.data.rating.toString()}`}</span>
                    <br></br>
                    <span className="attribute">{`User: `}</span>
                    <span>{`${item.data.user_name}`}</span>
                    <br></br>
                    <span className="attribute">{`Date: `}</span>
                    <span>{`${Common.formatDateLuxon(item.data.date)}`}</span>
                    <p className="attribute">Comment</p>
                    <Form.Group>
                      <Form.Control
                        as="textarea"
                        // type="text"
                        value={item.data.comment}
                        onChange={(e) => this.handleChangeComment(e.target.value)}
                        style={{ height: "200px" }}
                      />
                    </Form.Group>
                  </div>
                </div>
              ))}
            </div>
          </Modal.Body>
        </Modal>
        {this.state.AlertModalShow ? (
          <AlertModal
            show={this.state.AlertModalShow}
            onHide={() => this.setState({ AlertModalShow: false })}
            AlertModalTitle={this.state.AlertModalTitle}
            AlertModalMessage={this.state.AlertModalMessage}
            AlertModalButtons={this.state.AlertModalButtons}
          />
        ) : null}
      </div>
    );
  }
}

export default ReviewsDialog;
