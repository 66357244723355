import Place, { Place_Source } from "./Place";

export default class Flickr {
  constructor() {
    this.places = [];
  }

  // https://api.flickr.com/services/rest/?method=flickr.photos.search&api_key=8d6229d2adfe4da9b404066aee61b083&tags=eiffel&per_page=3&format=json&license=9&public=1&bbox=2.27,48.80,2.42,48.9
  searchPhotos = (bbox, tags, per_page, callback, centerRadius) => {
    //const callback = this.getDetails;
    var params = {
      //bbox: bbox,
      //tags: tags,
      //tag_mode: "all",
      text: tags,
      per_page: per_page,
      format: "json",
      license: "0,1,2,3,4,5,6,7,8,9,10",
      public: "1",
      nojsoncallback: "1",
      api_key: "8d6229d2adfe4da9b404066aee61b083",
      extras: "geo,tags",
      safe_search: "1",
      content_type: "1",
      geo_context: "2",
      accuracy: "14",
      //place_type_id: "8",
    };

    console.log("searchPhotos", params);
    var url = "https://api.flickr.com/services/rest/?method=flickr.photos.search&has_geo&is_commons";
    Object.keys(params).forEach(function (key) {
      url += "&" + key + "=" + params[key];
    });
    if (typeof centerRadius !== "undefined")
      url += `&radius_units=km&radius=${centerRadius.radius}&lat=${centerRadius.center.latitude}&lon=${centerRadius.center.longitude}`;
    else url += "&bbox=" + bbox;
    console.log("searchPhotos", url);
    fetch(url)
      .then(function (response) {
        const resp = response.json();
        console.log("response1", JSON.stringify(resp));
        return resp;
      })
      .then(function (response) {
        console.log("response2", response.photos.photo);
        callback(response.photos.photo);
      })
      .catch(function (error) {
        console.log("Flickr error:", error);
        callback(null);
      });
  };

  _last = 0;
  _index = 0;
  _places = [];
  _results = [];
  _callback = null;

  getDetails = (places, callback) => {
    this._places = places;
    this._last = places.length - 1;
    this._callback = callback;
    this._index = 0;
    this._results = [];
    if (places.length == 0) callback(this._results);
    this.getOneDetails(this.getDetailsCallback);
    // for (let i = 0; i < places.length; i++) {
    //   setTimeout(() => {
    //     this.getOneDetails(_places, places, callback, i, this._last, getDetailsCallback);
    //   }, i * 200);
    // }
  };

  getDetailsCallback = (place, details) => {
    //console.log("getDetailsCallback", this._index, this._last);
    if (details != null) {
      let new_place = this.parsePhotos(place, details, this._results);
      this._results.push(new_place);
    }
    this._index += 1;
    if (this._index >= this._last) {
      this._callback(this._results);
      //console.log(this._results);
      return;
    }
    setTimeout(() => {
      this.getOneDetails(this.getDetailsCallback);
    }, 100);
  };

  getOneDetails = (callback) => {
    const place = this._places[this._index];
    //console.log("place", place);
    if (typeof place.id == "undefined") callback(place, null);
    const url = `https://api.flickr.com/services/rest/?method=flickr.photos.geo.getLocation&api_key=8d6229d2adfe4da9b404066aee61b083&photo_id=${place.id}&format=json&nojsoncallback=1`;
    //console.log("getDetails", url);
    fetch(url)
      .then(function (response) {
        return response.json();
      })
      .then(function (response) {
        //console.log("getOneDetails", response);
        callback(place, response);
      })
      .catch(function (error) {
        //console.log(error);
        callback(place, null);
      });
  };

  parsePhotosNoDetails = (photos) => {
    let places = [];
    for (let i = 0; i < photos.length; i++) {
      let photo = photos[i];
      let new_place = new Place();
      new_place.createIDFromPlacesArray(places);
      const photo_url =
        "http://farm" + photo.farm + ".static.flickr.com/" + photo.server + "/" + photo.id + "_" + photo.secret;
      new_place.Photo = photo_url + "_" + "t.jpg";
      new_place.Photo_m = photo_url + "_" + "m.jpg";
      if (typeof photo.title != "undefined") new_place.Name = photo.title;
      if (typeof photo.latitude != "undefined") new_place.Lat = parseFloat(photo.latitude);
      if (typeof photo.longitude != "undefined") new_place.Longi = parseFloat(photo.longitude);
      new_place.PType = "Icon";
      new_place.Points = null;
      new_place.Source = Place_Source.FLICKR;
      new_place.Phone = "";
      new_place.Email = "";
      new_place.Info = "";
      places.push(new_place);
    }
    return places;
  };

  parsePhotos = (photo, details_obj, results) => {
    const details = details_obj.photo;
    //console.log("parsePhotos", details);
    //console.log("details", details);
    let new_place = new Place();
    new_place.createIDFromPlacesArray(results);
    const photo_url =
      "http://farm" + photo.farm + ".static.flickr.com/" + photo.server + "/" + photo.id + "_" + photo.secret;
    new_place.Photo = photo_url + "_" + "t.jpg";
    new_place.Photo_m = photo_url + "_" + "m.jpg";
    if (typeof photo.title != "undefined") new_place.Name = photo.title;
    if (typeof details.location.country != "undefined") new_place.Country = details.location.country._content;
    if (typeof details.location.locality != "undefined") new_place.City = details.location.locality._content;
    // if (typeof details.location.county != "undefined") new_place.State = details.location.county._content;
    if (typeof details.location.latitude != "undefined") new_place.Lat = parseFloat(details.location.latitude);
    if (typeof details.location.longitude != "undefined") new_place.Longi = parseFloat(details.location.longitude);
    //if (typeof details.location.neighbourhood != "undefined") new_place.Group = details.location.neighbourhood._content;
    //new_place.Group = details.location.county._content;
    new_place.PType = "Icon";
    new_place.Points = null;
    new_place.Source = Place_Source.FLICKR;
    new_place.Phone = "";
    new_place.Email = "";
    new_place.Info = "";
    return new_place;
  };

  moveToGuide = (guide, place) => {
    guide.places.addPlace(place);
  };
}
