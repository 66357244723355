import React, { useCallback, useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import "./Modal.css";
import img from "../../assets/images/logo.jpg";
import DataBase from "../../dataBase/liquidGuides";
import User from "../../Models/User";
import { useDispatch } from "react-redux";
import { slice1Actions } from "../../store/reducer";
import ReactLoading from "react-loading";
import { useSelector } from "react-redux";
import AlertModal from "./AlertModal";

const LoginModal = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [AlertModalShow, setAlertModalShow] = useState(false);
  const [AlertModalTitle, setAlertModalTitle] = useState("");
  const [AlertModalMessage, setAlertModalMessage] = useState("");
  const [AlertModalButtons, setAlertModalButtons] = useState([]);

  const [activityIndicator, setActivityIndicator] = useState(false);
  const dispatch = useDispatch();
  // let guide_info = useSelector((state) => state.guide_info);

  const myAlert = (message) => {
    setAlertModalTitle("Message");
    setAlertModalMessage(message);
    setAlertModalButtons([{ Title: "OK", Method: null }]);
    setAlertModalShow(true);
  };

  const Login = () => {
    //console.log("Login", email, password);
    if (!valid_email(email)) {
      myAlert("Please enter valid email.");
      return false;
    }
    if (password === "") {
      myAlert("Please enter Password.");
      return false;
    }
    setActivityIndicator(true);
    DataBase.Login(email, password, loginCallback);
  };

  let _uid = null;

  const loginCallback = (res) => {
    console.log("loginCallback", res);
    if (res.pass === true) {
      console.log("loginCallback", _uid, res.res.user.uid);
      //console.log("loginCallback", { uid: res.res.user.uid, email: res.res.user.email });
      let user_info = new User();
      let _user_info = user_info.data;
      _user_info.uid = res.res.user.uid;
      _user_info.email = res.res.user.email;
      dispatch(slice1Actions.setUserInfo({ value: _user_info }));
      //if (_uid != null) User.readUser(_uid, readUserCallback);
      DataBase.findUserByUID(res.res.user.uid, readUserCallback);
    } else {
      setActivityIndicator(false);
      myAlert("Login Error");
    }
  };

  const readUserCallback = (res) => {
    console.log("readUserCallback", res);
    setActivityIndicator(false);
    if (res.pass === true) {
      let user_info = res.res;
      // Null for Login, not null when we have just added it during registration,
      if (_uid === null) _uid = res.doc_id;
      dispatch(slice1Actions.setUserInfo({ value: user_info }));
      dispatch(slice1Actions.setUserInfoId({ value: _uid }));
      setTimeout(RefreshAfterLogin, 500);
      // dispatch(slice1Actions.setAuthorization({ guide_info: guide_info, uid: user_info.uid }));
      if (props.callback != null) props.callback();
      props.onHide();
      console.log("readUserCallback, goback");
    } else myAlert("Read User Error");
  };

  const RefreshAfterLogin = () => {
    dispatch(slice1Actions.setGuide({ guide: null }));
  };
  const valid_email = (email) => {
    const reg = /^[A-Z0-9._%+-]+@([A-Z0-9-]+\.)+[A-Z]{2,4}$/i;
    if (reg.test(email)) {
      return true;
    }
    myAlert("Please enter valid email.");
    return false;
  };

  const ForgotPassword = () => {
    const valid = valid_email(email);
    if (valid) DataBase.forgotPassword(email, forgotPasswordCallback);
    else myAlert("Email is not valid");
  };

  const forgotPasswordCallback = (res) => {
    if (res === true) myAlert("Please check your email...");
    else {
      let msg = typeof res.message != "undefined" ? res.message.toString() : "Unknown error";
      myAlert("Error resetting password: ", msg);
    }
  };

  const Register = () => {
    console.log("SignUp", email);
    if (!valid_email(email)) {
      myAlert("Please enter valid email.");
      return false;
    }
    // if (!this.state.registerWithEmailLink) {
    if (password === "") {
      myAlert("Please enter Password.");
      return false;
    }
    if (firstName === "") {
      myAlert("Please enter First Name.");
      return false;
    }
    if (lastName === "") {
      myAlert("Please enter Last Name.");
      return false;
    }
    DataBase.SignUp(email, password, SignUpCallback);
    setActivityIndicator(true);
    // } else DataBase.SignUpWithLink(this.state.email, this.SignUpWithLinkCallback);
  };

  const SignUpCallback = (res) => {
    console.log("SignUpCallback", res.pas);
    if (res.pass == false) {
      setActivityIndicator(false);
      myAlert("Sign Up Error", res.res.toString());
      return;
    } else {
      let user_info = new User();
      let _user_info = user_info.data;
      _user_info.uid = res.res.user.uid;
      _user_info.email = res.res.user.email;
      _user_info.first_name = firstName;
      _user_info.last_name = lastName;
      //console.log("SignUpCallback", _user_info.uid);
      dispatch(slice1Actions.setUserInfo({ value: _user_info }));
      addUser(_user_info);
    }
  };

  const addUser = (_user_info) => {
    console.log("addUser", _user_info);
    if (_user_info.uid == null) {
      setActivityIndicator(false);
      console.log("Add user error");
      return;
    }
    User.addUser(_user_info, addUserCallback);
  };

  const addUserCallback = (res) => {
    console.log("addUserCallback: ", res);
    if (res.pass === true) {
      //console.log("user added, uid: ", res.res);
      // this._fb_user_uid = res.res;
      _uid = res.res;
      setTimeout(LoginAfterAddUser, 1000);
      return;
    }
    setActivityIndicator(false);
    myAlert("Error adding user");
  };

  const LoginAfterAddUser = () => {
    console.log("LoginAfterAddUser");
    //this.state.callback2(this._fb_user_uid);
    // if (this._FBRegistration) {
    //   DataBase.AnonymousLogin(this.anonymousLoginCallback);
    // } else
    DataBase.Login(email, password, loginCallback);
  };

  const emailChanged = (event) => {
    setEmail(event.target.value);
  };

  const PasswordChanged = (event) => {
    setPassword(event.target.value);
  };

  const FirstNameChanged = (event) => {
    setFirstName(event.target.value);
  };

  const LastNameChanged = (event) => {
    setLastName(event.target.value);
  };

  return (
    <div className="modal">
      <Modal
        {...props}
        size={window.innerWidth < 450 ? "lg" : "md"}
        aria-labelledby="contained-modal-title-vcenter"
        scrollable={true}
        // centered
        // style={{ padding: "50px" }}
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">{props.mode}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {activityIndicator ? (
            <div className="loading">
              <ReactLoading type={"spin"} color="#0000ff" height={"10%"} width={"10%"} />
            </div>
          ) : null}
          <div className="image">
            <img src={img} alt="" className="text-center rounded-circle" width={110} />
          </div>
          {/* <h4 className="text-center">Login</h4> */}
          <Form style={{ width: "80%", margin: "auto" }}>
            {props.mode === "Register" ? (
              <div>
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="first_name"
                    placeholder="First Name"
                    onChange={FirstNameChanged}
                  />
                  <label for="first_name">First Name</label>
                </div>
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="last_name"
                    placeholder="Last Name"
                    onChange={LastNameChanged}
                  />
                  <label for="last_name">Last Name</label>
                </div>
              </div>
            ) : null}
            <div className="form-floating mb-3">
              <input
                type="email"
                className="form-control"
                id="email_address"
                placeholder="name@example.com"
                onChange={emailChanged}
              />
              <label for="email_address">Email address</label>
            </div>
            {props.mode !== "Forgot Password" ? (
              <div className="form-floating">
                <input
                  type="password"
                  className="form-control"
                  id="floatingPassword"
                  placeholder="Password"
                  onChange={PasswordChanged}
                />
                <label for="floatingPassword">Password</label>
              </div>
            ) : null}

            {props.mode === "Login" ? (
              <Button variant="primary" style={{ width: "100%" }} className="my-2" onClick={Login}>
                Login
              </Button>
            ) : null}

            {props.mode === "Forgot Password" ? (
              <Button variant="primary" style={{ width: "100%" }} className="my-2" onClick={ForgotPassword}>
                Reset Password
              </Button>
            ) : null}

            {props.mode === "Register" ? (
              <Button variant="primary" style={{ width: "100%" }} className="my-2" onClick={Register}>
                Register
              </Button>
            ) : null}

            {/* <p className="text-center">Or</p> */}
            {/* <Button variant="primary" style={{ width: "100%" }}>
              Login with Facebook
            </Button> */}
          </Form>
          {/* <p className="text-center forgot-password">Forgot Password?</p>
          <p className="register-here">Dont have an account? Register here</p> */}
        </Modal.Body>
        {/* <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer> */}
      </Modal>
      <AlertModal
        show={AlertModalShow}
        onHide={() => setAlertModalShow(false)}
        AlertModalTitle={AlertModalTitle}
        AlertModalMessage={AlertModalMessage}
        AlertModalButtons={AlertModalButtons}
      />
    </div>
  );
};

export default LoginModal;
